<div class="page-content-wrapper" fxLayout="column">

  <!-- Campagne -->
  <h1 fxFlexOffset="40px" class="primary">
    {{ 'admin.campaignCustomerDetails.TITLE' | translate: { value: campaign ? campaign.title : '' } }}
  </h1>

  <bread-crumbs fxFlexOffset="12px" firstLevel="ADMIN_CAMPAIGNS" [secondLevel]="campaign ? campaign.title : ''"
    (secondLevelClick)="goBackToCampaign()" [thirdLevel]="customer ? customer.title : ''">
  </bread-crumbs>

  <div fxFlexOffset="56px" fxLayout="row" fxLayoutAlign="center">
    <div class="client-resume" fxLayout="column">
      <div fxLayout="row" fxLayoutAlign="space-between">
        <svg-icon [applyClass]="true" src="assets/img/icons/note-clients.svg"></svg-icon>

        <div fxFlexOffset="80px" fxFlex fxLayout="row">
          <ng-container *ngIf="customer">
            <div fxLayout="column" fxFlex="50">
              <!-- Limiti familiari -->
              <h5 class="bold to-uppercase" translate="admin.campaignCustomerDetails.FAMILIARS_LIMIT"></h5>
              <!-- Prenotazioni -->
              <h5 class="bold" fxFlexOffset="8px" translate="admin.campaignCustomerDetails.BOOKINGS_LIMIT"></h5>
              <h5>
                {{ campaignCustomerCap || '--' }}
              </h5>
              <!-- Max CAP familiari maggiorenni -->
              <h5 class="bold" fxFlexOffset="8px"
                translate="admin.campaignCustomerDetails.MAX_CAP_MAGGIORENN_FAMILIARS"></h5>
              <h5>
                {{ campaignCustomerMaxCap || '--' }}
              </h5>
            </div>
            <div fxLayout="column" fxFlex="50">
              <!-- Lista di familiari ammessi -->
              <h5 class="bold to-uppercase" translate="admin.campaignCustomerDetails.AMMISS_FAMILIARS"></h5>

              <h5 *ngIf="campaignCustomerAllowedFamilyMembers && campaignCustomerAllowedFamilyMembers.length">
                <span
                  *ngFor="let allowerMember of campaignCustomerAllowedFamilyMembers; let last = last; let first = first;"
                  [style.text-transform]="first ? 'none' : 'lowercase'">{{
                  ('admin.addCampaignCustomerCap.' + allowerMember | translate) + (last ? '' : ', ' ) }}</span>
              </h5>
              <h5 *ngIf="!campaignCustomerAllowedFamilyMembers || !campaignCustomerAllowedFamilyMembers.length">{{
                ('admin.campaignCustomerDetails.NO_ACTIVE_BLOCK' | translate ) }}</h5>

              <!-- Vaccini acquistati -->
              <h5 class="bold" fxFlexOffset="8px" translate="admin.campaignCustomerDetails.ACQUISED_VACCINS"></h5>
              <h5>
                {{ campaignCustomerBoughtShots || '0' }}
              </h5>
            </div>
          </ng-container>
          <div *ngIf="!customer" class="loader spinner-small"></div>
        </div>

      </div>
    </div>
  </div>

  <div fxFlexOffset="14px" fxFlexOffset.xs="12px" class="campaign-customer-details-container" fxLayout="column">

    <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="space-between center">

      <div fxLayout="column" fxLayoutAlign="start center">

        <div fxLayout="row" fxLayoutAlign="start center" class="full-width">
          <!-- Lista clienti -->
          <welion-button width=" 180px" size="medium" type="primary" hasLeftIcon (onClick)="goBackToCampaign()"
            label="admin.campaignCustomerDetails.table.CUSTOMER_LIST" [disabled]="isLoadingBase">
          </welion-button>
          <h3 fxFlexOffset="20px" *ngIf="customer">{{ customer.title }}</h3>
        </div>
        <!-- Descrizioni contatori anagraica e AD -->
        <div fxLayout="column" fxLayoutAlign="start start" class="margin-top18">
          <div class="client-counter">
            <strong *ngIf="totalAttendances && totalAttendances.totalDistinct >= 0">{{ totalAttendances.totalDistinct }}&nbsp;</strong>
            <strong translate="admin.campaignCustomerDetails.N_AD"></strong>&nbsp;
            {{ 'admin.campaignCustomerDetails.N_AD_DESCR' | translate}}
          </div>
          <div class="client-counter">
            <strong *ngIf="totalAttendances && totalAttendances.total >= 0">{{ totalAttendances.total }}&nbsp;</strong>
            <strong translate="admin.campaignCustomerDetails.N_AN"></strong>&nbsp;
            {{ 'admin.campaignCustomerDetails.N_AN_DESCR' | translate}}
          </div>
        </div>
      </div>

      <!-- <div *ngIf="campaignCustomerCap || campaignCustomerCap === 0" fxLayout="column" fxLayoutAlign="start end"
        fxFlexOffset.xs="12px">
        <h5 translate="admin.campaignCustomerDetails.table.LIMIT"></h5>
        <h5 class="bold">{{ campaignCustomerCap }}</h5>
      </div> -->
    </div>

    <div id="admin-campaign-customer-details-anchor" fxFlexOffset="10px"></div>

    <div fxLayout="row" fxFlexOffset="10px" fxLayoutAlign="end center">

      <div fxLayout="row">
        <!-- Rimuovi da campagna -->
        <welion-button *ngIf="!isLimitedAdmin" width="220px" size="medium" type="third" style="margin-right: -60px;"
          (onClick)="openRemoveCustomerFromCampaignModal()"
          label="admin.campaignCustomerDetails.table.REMOVE_FROM_CAMPAIGN">
        </welion-button>

        <!-- Azioni -->
        <welion-select-button *ngIf="!isLimitedAdmin && !isRemovingLimit" fxFlex="200px" label="generic.ACTION"
          [disabled]="isLoading" [dropdownItems]="dropdownAddItems" direction="right">
        </welion-select-button>

        <!-- EMAIL NO SHOW -->
        <!-- <welion-button fxFlexOffset="8px" width="180px" size="medium" type="secondary"
          (onClick)="openEmailNoEmailConfirmModal()" label="admin.campaignCustomerDetails.table.EMAIL_NO_SHOW">
        </welion-button> -->
        <!-- RESET NO SHOW -->
        <!-- <welion-button fxFlexOffset="8px" width="180px" size="medium" type="secondary"
          (onClick)="openResetNoShowConfirmModal()" label="admin.campaignCustomerDetails.table.RESET_NO_SHOW">
        </welion-button> -->
        <!-- RIMUOVI LIMITE -->
        <!-- <ng-container *ngIf="campaignCustomerCap || campaignCustomerCap === 0">
          <welion-button fxFlexOffset="8px" width="180px" size="medium" type="secondary"
            (onClick)="openRemoveCapLimitConfirmModal()" label="admin.campaignCustomerDetails.table.REMOVE_LIMIT">
          </welion-button>
        </ng-container> -->
        <!-- AGGIUNGI LIMITE -->
        <!-- <ng-container *ngIf="!campaignCustomerCap && campaignCustomerCap !== 0">
          <welion-button fxFlexOffset="8px" width="180px" size="medium" type="secondary" (onClick)="addCapLimit()"
            label="admin.campaignCustomerDetails.table.ADD_LIMIT">
          </welion-button>
        </ng-container> -->
      </div>
    </div>

    <div fxFlexOffset="20px" class="campaign-customer-details-divider"></div>

    <!-- Table header -->
    <div fxFlexOffset="40px" fxLayout="row" fxLayoutAlign="start center">
      <!-- Cognome e nome -->
      <h5 class="bold" translate="admin.campaignCustomerDetails.table.NAME_SURNAME" fxFlex="40" fxFlexOffset="16px">
      </h5>
      <!-- Stato -->
      <h5 class="bold" translate="admin.campaignCustomerDetails.table.STATE" fxFlex="20"></h5>
      <!-- Sede -->
      <h5 class="bold" translate="admin.campaignCustomerDetails.table.VENUE" fxFlex="10"></h5>

      <!-- Cerca per nome -->
      <welion-input withTransparency fxFlex [bindValue]="searchText" (onChange)="searchTextChange($event)"
        (onIconClicked)="searchEntitleds()"
        [placeholder]="'admin.campaignCustomerDetails.SEARCH_REFERENCE_PLACEHOLDER' | translate" [disabled]="isLoading"
        iconRight="assets/img/icons/icon-search.svg">
      </welion-input>
    </div>

    <!-- Loader -->
    <div *ngIf="isLoading" class="loader spinner-big"></div>

    <!-- Table rows -->
    <ng-container *ngIf="!isLoading">
      <div *ngFor="let entitled of entitleds; let first = first; let odd = odd" class="campaign-customer-details-row"
        [ngClass]="{'odd': odd}" fxLayout="row" fxLayoutAlign="start center" [fxFlexOffset]="first ? '8px' : '0px'">
        <!-- Cognome e nome -->
        <h6 class="black" fxFlex="40" fxFlexOffset="16px">{{ entitled.surname + ' ' + entitled.name }}</h6>
        <!-- Stato -->
        <h6 class="black" fxFlex="20">{{ ('status.' + entitled.status) | translate }}</h6>
        <!-- Sede -->
        <h6 class="black" fxFlex>{{ entitled.preMatchedVenue ? entitled.preMatchedVenue : '-' }}</h6>

        <!-- Impersonifica -->
        <h6 *ngIf="!isLimitedAdmin" class="black bold underline clickable"
          translate="admin.campaignCustomerDetails.table.IMPER" (click)="onImpersonateEntitled(entitled)"></h6>

        <!-- Scheda -->
        <h6 class="black bold underline clickable" fxFlexOffset="12px"
          translate="admin.campaignCustomerDetails.table.FORM" (click)="openAttendenceCard(entitled)"></h6>

        <div fxFlex="16px"></div>
      </div>
    </ng-container>

    <!-- Paginatore -->
    <welion-paginator fxFlexOffset="20px" [isLoading]="isLoading" [paginationLength]="paginationLength"
      [itemsCount]="entitledsCount" (onClickPage)="getEntitleds($event)"></welion-paginator>

  </div>

</div>

<!-- Modale per il reset no show -->
<modal id="resetNoShow" (onClose)="closeResetNoShowConfirmModal()" (onCancel)="closeResetNoShowConfirmModal()"
  (onConfirm)="confirmResetNoshow()" [cancelLabel]="'generic.CLOSE' | translate | uppercase"
  [confirmLabel]="'generic.CONFIRM' | translate | uppercase" [disableAll]="isLoading">
  <modal-text-content [withScrolledBody]="true" [title]="'admin.campaignCustomerDetails.resetNoShow.TITLE' | translate"
    [text]="'admin.campaignCustomerDetails.resetNoShow.RESET_WARNING' | translate"
    [subtitle]="'admin.campaignCustomerDetails.resetNoShow.SUBTITLE' | translate">
  </modal-text-content>
</modal>

<!-- Modale per l'email no show -->
<modal id="emailNoShow" (onClose)="closeEmailNoShowConfirmModal()" (onCancel)="closeEmailNoShowConfirmModal()"
  (onConfirm)="confirmEmailNoshow()" [cancelLabel]="'generic.CLOSE' | translate | uppercase"
  [confirmLabel]="'generic.CONFIRM' | translate | uppercase" [disableAll]="isLoading">
  <modal-text-content [withScrolledBody]="true" [title]="'admin.campaignCustomerDetails.emailNoShow.TITLE' | translate"
    [text]="'admin.campaignCustomerDetails.emailNoShow.RESET_WARNING' | translate"
    [subtitle]="'admin.campaignCustomerDetails.emailNoShow.SUBTITLE' | translate">
  </modal-text-content>
</modal>

<!-- Modale per rimuovere il limite prenotazioni -->
<modal id="removeCapLimit" (onClose)="closeRemoveCapLimitConfirmModal()" (onCancel)="closeRemoveCapLimitConfirmModal()"
  (onConfirm)="confirmRemoveCapLimit()" [cancelLabel]="'generic.CLOSE' | translate | uppercase"
  [confirmLabel]="'generic.CONFIRM' | translate | uppercase" [disableAll]="isLoading">
  <modal-text-content [withScrolledBody]="true"
    [title]="'admin.campaignCustomerDetails.removeCapLimit.TITLE' | translate"
    [text]="'admin.campaignCustomerDetails.removeCapLimit.SUBTITLE' | translate">
  </modal-text-content>
</modal>

<!-- Modale di conferma per rimuovere il cliente dalla campagna -->
<modal id="removeCustomerFromCampaign" (onClose)="closeRemoveCustomerFromCampaignModal()" [disableAll]="isLoadingBase"
  (onCancel)="closeRemoveCustomerFromCampaignModal()" (onConfirm)="confirmRemoveCustomerFromCampaign()"
  [cancelLabel]="'generic.CLOSE' | translate | uppercase" [confirmLabel]="'generic.CONFIRM' | translate | uppercase">
  <modal-text-content [withScrolledBody]="true" *ngIf="customer"
    [title]="'admin.campaignCustomerDetails.removeCustomerFromRoundModal.TITLE' | translate"
    [text]="'admin.campaignCustomerDetails.removeCustomerFromRoundModal.RESET_WARNING' | translate"
    [subtitle]="'admin.campaignCustomerDetails.removeCustomerFromRoundModal.SUBTITLE' | translate">
  </modal-text-content>
</modal>

<!-- Sidenav con la scheda di dettaglio -->
<sidebar showAppHeader hideCloseButton [sidenavTemplateRef]="navContent" direction="right" [navWidth]="664"
  [duration]="0.3" >
</sidebar>

<!-- Contenuto sidenav -->
<ng-template #navContent>

  <form class="attendance-card-sidenav" fxLayout="column" [formGroup]="attendeeForm" (ngSubmit)="updateAttendee()">

    <div class="full-width" fxLayout="row" fxLayoutAlign="end">
      <!-- Icona chiudi -->
      <div fxLayoutAlign="row" fxLayoutAlign="center center" (click)="closeAttendanceCard()" class="close-container">
        <svg-icon class="small-size" src="/assets/img/icons/x.svg" [applyClass]="true"></svg-icon>
        <h5 fxFlexOffset="8px" class="white">{{ 'generic.CLOSE' | translate | uppercase}}</h5>
      </div>
    </div>

    <!-- Scheda personale -->
    <h5 fxFlexOffset="20px" class="bold" translate="structures.round.attendances.modal.TITLE"></h5>

    <ng-container *ngIf="!isLoading && selectedAttendance">
      <!-- Palerma Leonida -->
      <h3 fxFlexOffset="16px">{{ selectedAttendance.surname }} {{ selectedAttendance.name }}</h3>

      <div fxFlexOffset="16px" class="card-header-divider"></div>

      <!-- Codice fiscale -->
      <h5 fxFlexOffset="24px" class="bold" translate="structures.round.attendances.modal.FISCAL_CODE"></h5>

      <h5 fxFlexOffset="4px">{{ selectedAttendance.fiscalCode }}</h5>

      <div fxFlexOffset="16px" fxLayout="row" fxLayout.xs="column" fxLayoutAlign="space-between">

        <!-- Cognome e nome -->
        <div fxLayout="column" fxFlex="50" fxFlex.xs>
          <h5 class="bold" translate="structures.round.attendances.modal.NAME"></h5>
          <h5 fxFlexOffset="4px">
            {{ selectedAttendance.surname + ' ' + selectedAttendance.name }}
          </h5>
        </div>

        <!-- Società -->
        <div fxFlexOffset="30px" fxLayout="column" fxFlex>
          <h5 class="bold" translate="structures.round.attendances.modal.COMPANY"></h5>
          <h5 fxFlexOffset="4px">{{ selectedAttendance.company || '-' }}</h5>
        </div>

      </div>

      <!-- Recapiti -->
      <div fxFlexOffset="12px" fxLayout="row" fxLayout.xs="column" fxLayoutAlign="space-between">
        <div fxLayout="column">
          <h5 class="bold" translate="structures.round.attendances.modal.CONTACT_DETAILS"></h5>
          <!-- Numero di telefono -->
          <h5 fxFlexOffset="4px">{{ 'structures.round.attendances.modal.PHONE' | translate:
            { value: (selectedAttendance && selectedAttendance.phoneNumber || '-') }
            }}</h5>
          <!-- Email -->
          <h5 fxFlexOffset="4px">{{ 'structures.round.attendances.modal.EMAIL' | translate:
            { value: (selectedAttendance && selectedAttendance.email || '-') }
            }}</h5>
        </div>
        <!-- Stato -->
        <div fxLayout="column" fxFlex="260px" fxFlex.xs="100" fxFlexOffset.xs="0px">
          <h5 class="bold" translate="structures.round.attendances.modal.STATUS"></h5>
          <h5>{{ ('status.' + selectedAttendance.status) | translate }}</h5>
        </div>
      </div>

      <div *ngIf="selectedAttendance.startDate && selectedAttendance.endDate" fxFlexOffset="12px" fxLayout="row"
        fxLayoutAlign="space-between center">
        <!-- Prenotazione slot in sola lettura -->
        <div fxLayout="column" fxFlex="280px" fxFlex.xs="100">
          <!-- Prenotazione giorno e slot -->
          <div fxLayout="row">
            <h5 class="bold primary" translate="structures.round.attendances.modal.RESERVATION"></h5>
            <h5 class="bold" fxFlexOffset="4px" translate="structures.round.attendances.modal.DAY_SLOT"></h5>
          </div>
          <h5 fxFlexOffset="4px">
            {{ (selectedAttendance.startDate | date: 'EEEE dd MMMM' | titlecase) +
            ' | ' +
            (selectedAttendance.startDate | date: 'HH:mm') +
            ' - ' +
            (selectedAttendance.endDate | date: 'HH:mm')}}</h5>

        </div>
      </div>

      <ng-container *ngIf="selectedAttendance.anamnesis">

        <div fxFlexOffset="16px" class="card-anamnesis-divider"></div>

        <div class="card-anamnesis" *ngFor="let anamnesis of selectedAttendance.anamnesis; let first = first"
          [fxFlexOffset]="first ? '24px' : '12px'">
          <!-- Età -->
          <!-- <h5 class="bold">{{ anamnesis.title }}</h5> -->
          <div fxLayout="row">
            <!-- Ha meno di 9 anni? -->
            <h5>{{ ('anamnesis.' + anamnesis.questionKey) | translate }}</h5>
            <!-- SI/NO -->
            <h5 fxFlexOffset="4px" class="primary bold"
              translate="structures.round.attendances.modal.{{ anamnesis.answer ? 'YES' : 'NO' }}">
            </h5>
          </div>
        </div>

      </ng-container>

      <!-- Descrizione -->
      <input-container fxFlexOffset="24px" [isFromTextArea]="true"
        [title]="'structures.round.attendances.modal.DESC' | translate">
        <welion-text-area [placeholder]="'structures.round.attendances.modal.DESC_PLACEHOLDER' | translate"
          [inputValue]="description">
        </welion-text-area>
      </input-container>

    </ng-container>

    <ng-container *ngIf="isLoading">
      <div fxFlex class="loader spinner-big"></div>
    </ng-container>

    <!-- Annulla -->
    <div fxFlexOffset="20px" fxLayout="row" fxLayoutAlign="space-between center">
      <welion-button ngClass.xs="double-button-mobile" (onClick)="closeAttendanceCard()" width="232px"
        label="generic.ANNULL" type="primary bordless" size="medium" [disabled]="isLoading">
      </welion-button>

      <!-- Salva -->
      <welion-button ngClass.xs="double-button-mobile" fxFlexOffset.xs="12px" buttonType="submit" width="186px"
        label="generic.SAVE" type="primary" size="medium" [disabled]="isLoading">
      </welion-button>
    </div>

    <div fxFlexOffset="20px"></div>

  </form>

</ng-template>
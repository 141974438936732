<page-container>

  <div class="sidenav-information">
    <sidenav-informations></sidenav-informations>
  </div>

  <div class="main-content">
    <div class="page-content">
      <svg-icon class="logo-container" [applyClass]="true" src="/assets/img/icons/app-logo.svg">
      </svg-icon>
      <p>
        <span class="small-font-weight" translate="404.WE_ARE_SORRY"></span><br>
        <span translate="genericError.PROBLEM_FOUND" class="bold-text"></span>
      </p>
      <div>
        <p translate="genericError.TRY_AGAIN_LATER"></p>
        <div class="error-code-container" *ngIf="errorCode && errorCode.length">
          <p><span class="bold" translate="genericError.ERROR_CODE"></span> {{errorCode}}</p>
        </div>
      </div>
      <div class="back-button-container">
        <welion-button type="primary" size="large" label="generic.GO_IN_HOME" (onClick)="goToHome()"></welion-button>
      </div>
    </div>
  </div>
</page-container>

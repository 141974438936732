import { Component, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { combineLatest, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ActivatedRoute, Router } from '@angular/router';

import { Lang, User } from 'atfcore-commonclasses/bin/classes/anag';

import * as fromApp from '../../ngrx/app.reducers';
import * as CoreActions from "../../core/ngrx/core.actions";
import * as AuthActions from '../ngrx/auth.actions';
import jwt_decode from "jwt-decode";

import { TranslateService } from '@ngx-translate/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { RedirectService } from 'src/app/shared/services/redirect.service';
import { AuthService } from '../services/auth.service';
import { getModalMessageData, getWarningModal } from 'src/app/utils/utils';
import { JwtPayload } from 'atfcore-commonclasses/bin/classes/auth';

@Component({
  selector: 'app-signin-SSO',
  templateUrl: './signinSSO.component.html',
  styleUrls: ['./signinSSO.component.scss']
})
export class SigninSSOComponent implements OnInit, OnDestroy {
  isFetchingLangs: boolean = false;
  langs?: Lang[];

  signinForm: FormGroup = new FormGroup({
    'vatCode': new FormControl(undefined, [Validators.required, Validators.minLength(16), Validators.maxLength(16)]),
    'name': new FormControl({ value: undefined, disabled: true }),
    'surname': new FormControl({ value: undefined, disabled: true }),
    'email': new FormControl({ value: undefined, disabled: true }),
    'phone': new FormControl(undefined, [
      Validators.required,
      Validators.pattern(/^[-+0-9 ]*$/)
    ]),
    'address': new FormControl(undefined, Validators.required)
  });

  get vatCode() {
    return this.signinForm.get('vatCode') as FormControl;
  }

  get name() {
    return this.signinForm.get('name') as FormControl;
  }

  get surname() {
    return this.signinForm.get('surname') as FormControl;
  }

  get email() {
    return this.signinForm.get('email') as FormControl;
  }

  get phone() {
    return this.signinForm.get('phone') as FormControl;
  }

  get address() {
    return this.signinForm.get('address') as FormControl;
  }

  privacyForm: FormGroup = new FormGroup({
    'privacy': new FormControl(false, Validators.requiredTrue)
  });

  get privacy() {
    return this.privacyForm.get('privacy') as FormControl;
  }

  showLoader: boolean = true;

  stepIndex: number = 0;
  isEnel: boolean = false;

  private tinyToken: string = '';
  private loggedUser: JwtPayload | any;
  private registrationCompleted: boolean = false;

  private _unsubscribeSignal$: Subject<void> = new Subject();

  constructor(
    private store: Store<fromApp.AppState>,
    private redirectService: RedirectService,
    private route: ActivatedRoute,
    private authService: AuthService,
    private router: Router,
    public translate: TranslateService) {

    // Sto in ascolto di quando il globalApplicationData cambia, così da sapere quando ci sono le lingue disponibili poiché, se non ci fossero, il pulsante per il login sarebbe disabilitato
    combineLatest([
      this.store.select(fromApp.getAvailableLangs),
      this.store.select(fromApp.isFetchingLangs)
    ])
      .pipe(takeUntil(this._unsubscribeSignal$.asObservable()))
      .subscribe(
        ([langs, isFetchingLangs]) => {
          this.langs = langs;
          this.isFetchingLangs = isFetchingLangs;
        });
  }

  ngOnInit() {
    const ssortkqp: string = this.route.snapshot.queryParams['ssortkqp'];
    if (!ssortkqp) {
      this.redirectService.goToSignin();
    } else {
      this.retrieveTokenAfterLogin(ssortkqp);
    }
  }

  selectedPlace(place: any) {
    if (!place || place.geometry === undefined || place.geometry === null) {
      return;
    }
    if (place.address_components && place.address_components.length) {
      this.address.setValue(place.formatted_address);
    }
  }

  retrieveTokenAfterLogin(ssortkqp: string) {
    this.authService.retrieveTokenAfterLogin(ssortkqp)
      .pipe(takeUntil(this._unsubscribeSignal$.asObservable()))
      .subscribe((result) => {
        if (!result || result.error) {
          this.dispatchModal('001', result.error || '');
          this.showLoader = false;
          this.redirectService.goToSignin();
        } else {
          this.tinyToken = result.response;
          this.getJWTToken();
        }
      }, (err) => {
        if (err && err.message) {
          this.dispatchModal('002', err.message);
        }
        this.showLoader = false;
        this.redirectService.goToSignin();
      });
  }

  getJWTToken() {
    this.authService.getJWTToken()
      .pipe(takeUntil(this._unsubscribeSignal$.asObservable()))
      .subscribe((result) => {
        if (!result || result.error) {
          this.dispatchModal('001', result.error || '');
          this.showLoader = false;
          this.redirectService.goToSignin();
        } else {
          this.loggedUser = jwt_decode(result.response);

          if (this.loggedUser) {
            // this.fromSSO = this.loggedUser && this.loggedUser.params && this.loggedUser.fromSSO;
            this.registrationCompleted = this.loggedUser && this.loggedUser.user && this.loggedUser.user.registrationCompleted;

            if (this.registrationCompleted) {
              this.loginSSO();
            } else {

              let user: User = this.loggedUser.user;

              if (this.loggedUser.params && this.loggedUser.params.supplier && this.loggedUser.params.supplier.supplierName) {
                this.isEnel = (this.loggedUser.params.supplier.supplierName as string).toLowerCase().indexOf('enel') !== -1;
              }

              if (user) {

                if (user.codFisc) {
                  this.vatCode.setValue(user.codFisc);
                }

                if (user.forename) {
                  this.name.setValue(user.forename);
                }

                if (user.surname) {
                  this.surname.setValue(user.surname);
                }

                if (user.phoneNumber) {
                  this.phone.setValue(user.phoneNumber);
                }

                if (user.email) {
                  this.email.setValue(user.email);
                }
              }

              this.showLoader = false;
            }
          } else {
            this.redirectService.goToSignin();
          }
        }
      }, (err) => {
        if (err && err.message) {
          this.dispatchModal('002', err.message);
        }
        this.redirectService.goToSignin();
        this.showLoader = false;
      });
  }

  loginSSO() {
    this.store.dispatch(AuthActions.DoLoginSSO({
      tinyToken: this.tinyToken
    }));
  }

  ngOnDestroy() {
    this._unsubscribeSignal$.next();
    this._unsubscribeSignal$.unsubscribe();
  }

  completeRegistration() {
    if (this.signinForm.valid) {
      this.stepIndex++;
    }
  }

  confirmPrivacy() {
    if (this.privacyForm.valid) {
      this.showLoader = true;

      this.authService.registerEntitledFromSSO(
        this.tinyToken,
        this.vatCode.value,
        this.phone.value,
        this.address.value,
        this.privacy.value
      )
        .pipe(takeUntil(this._unsubscribeSignal$.asObservable()))
        .subscribe((result) => {
          if (!result || result.error) {
            if (result.error === 'UNEXPECTED_ERROR') {
              this.dispatchModal('038', 'UNEXPECTED_ERROR_LOGIN_SSO');
            } else {
              this.dispatchModal('038', result.error || '');
            }

            this.showLoader = false;
          } else {
            // Ora è gestito tutto da cookie e quindi il register-entitled-from-sso non restituisce piu nulla
            this.loginSSO();
            /* if (result.response) {
              this.tinyToken = result.response;
              this.loginSSO();
            } else {
              this.showLoader = false;
            } */
          }
        }, (err) => {
          if (err && err.message) {
            this.dispatchModal('039', err.message);
          }
          this.showLoader = false;
        });
    }
  }

  onCheckPrivacy(value: boolean) {
    this.privacy.setValue(value);
  }

  goToPrivacy() {
    this.redirectService.goToPrivacyPage(this.isEnel);
  }

  clearText() {
    this.vatCode.setValue(undefined);

    this.name.setValue(undefined);
    this.surname.setValue(undefined);
    this.email.setValue(undefined);
    this.phone.setValue(undefined);
    this.address.setValue(undefined);
    this.privacy.setValue(false);
  }

  // Valida l'email
  // emailValidate() {
  //   let regex = /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{1,4})+$/;
  //   if (regex.test(this.email.value.trim())) {
  //     return true;
  //   }
  //   return false;
  // }

  /**
   * @description Mostra una modale con titolo e messaggio
   * @param modalId
   * @param title
   * @param message
   */
  dispatchMessageModal(title: string, message: string) {
    const messageObj = getModalMessageData(this.translate, title, message);
    this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
  }

  dispatchModal(modalId: string, error: string) {
    const messageObj = getWarningModal(this.translate, modalId, error);
    this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
  }

  goToFAQ() {
    if (this.isEnel) {
      this.router.navigate(['/createTicket']);
    } else {
      this.redirectService.goToUserFAQPage();
    }
  }
}

import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { FormControl, FormGroup } from '@angular/forms';
import { Observable, Subject, of } from 'rxjs';
import { catchError, map, switchMap, takeUntil } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';

import * as CoreActions from "../../../core/ngrx/core.actions";
import * as fromApp from "../../../ngrx/app.reducers";
import { AttendanceStatuses, InfoAttendance, InfoCampaign, InfoRound, InfoVenue } from 'atfcore-commonclasses/bin/classes/campaignmanager';
import { SenecaResponse } from 'atfcore-commonclasses/bin/classes/common';

import { RedirectService } from 'src/app/shared/services/redirect.service';
import { getModalMessageData, getWarningModal } from 'src/app/utils/utils';
import { ScrollTo } from 'src/app/core/services/scroll-to.service';
import { OrderByVenue, RentService } from 'src/app/structure/services/rent.service';
import { DropdownItem } from 'src/app/shared/models/dropdown.model';
import { SidebarService } from 'src/app/shared/services/sidebar.service';
import { ModalService } from 'src/app/shared/components/modal/modal.service';
import { animate, style, transition, trigger } from '@angular/animations';
import { AuthService } from 'src/app/auth/services/auth.service';
import { ExportService } from 'src/app/structure/services/export.service';
import { DropdownButtonItem } from 'src/app/shared/components/welion-select-button/welion-select-button.component';
import * as moment from 'moment';
import { ImportService } from 'src/app/structure/services/import.service';
import { ACCEPTED_MIME_DOCS } from 'src/app/utils';
import { AnagService, UserGroupAdmin } from 'src/app/auth/services/anag.service';

export class InfoAttendanceSchedule extends InfoAttendance {
  canReschedule?: boolean;
  canSchedule?: boolean;
  canCancel?: boolean;
}

@Component({
  selector: 'app-admin-campaign-round-details',
  templateUrl: './campaignRoundDetails.component.html',
  styleUrls: ['./campaignRoundDetails.component.scss'],
  animations: [
    trigger(
      'showIf', [
      transition(':enter', [
        style({ opacity: 0 }),
        animate('300ms', style({ opacity: 1 }))
      ]),
      transition(':leave', [
        style({ opacity: 1 }),
        animate('300ms', style({ opacity: 0 }))
      ])
    ]
    )
  ]
})
export class AdminCampaignRoundDetailsComponent implements OnInit, OnDestroy {

  campaignId: string = '';
  campaign?: InfoCampaign;

  roundId: string = '';
  round?: InfoRound;
  activeRound: boolean = false;

  isLoading: boolean = true;
  isLoadingCount: boolean = true;
  isLoadingBase: boolean = true;

  selectedTab: number = 0;

  venues: (InfoVenue & {
    rounDateLabel?: string;
    reservationDateLabel?: string;
  })[] = [];
  venuesCount: number = 0;

  /**
   * Ordinamento
   * 0 - non attivo
   * 1 - hover
   * 2 - ordine crescente - asc
   * 3 - ordine decrescente - desc
   */
  orderVenueTitle: number = 0;
  orderVenueSaturation: number = 0;

  venueVisibilities: DropdownItem[] = [
    {
      id: '0',
      name: 'admin.campaignRoundDetails.tab.venues.FILTER_ALL'
    }, {
      id: '1',
      name: 'admin.campaignRoundDetails.tab.venues.FILTER_TO_PROCESS'
    }, {
      id: '2',
      name: 'admin.campaignRoundDetails.tab.venues.FILTER_PROCESSED'
    }
  ];

  selectedVenueVisibility: DropdownItem = {
    id: '0',
    name: 'admin.campaignRoundDetails.tab.venues.FILTER_ALL'
  };

  entitleds: InfoAttendanceSchedule[] = [];
  entitledsCount: number = 0;
  hasDataWithoutSearch: boolean = false;

  pageSelectedIndex: number = 0;
  readonly paginationLengthVenues: number = 25;
  readonly paginationLengthEntitleds: number = 50;

  searchText?: string;
  searchTextCF?: string;
  searchTextCompany?: string;

  selectedAttendance?: InfoAttendanceSchedule;

  attendeeForm: FormGroup = new FormGroup({
    'description': new FormControl(undefined)
  });

  get description(): FormControl {
    return this.attendeeForm.get('description') as FormControl;
  }

  selectedVenue?: InfoVenue;
  selectedVenueTab: number = 0;

  venueMapData?: {
    lat: number,
    lng: number
  };

  dropdownVenues: DropdownButtonItem[] = [];
  dropdownEntitleds: DropdownButtonItem[] = [];

  dropdownSelectEntitled: DropdownButtonItem[] = [];

  dropdownSelectVenue: DropdownButtonItem[] = [];

  attendanceToDelete?: InfoAttendanceSchedule;

  private _unsubscribeSignal$: Subject<void> = new Subject();

  maxDeleteBookingDate: string = '';
  maxEditBookingDateAndStructure: string = '';

  isLimitedAdmin: boolean = false;
  today = new Date();
  isAfterMaxReservationDate: boolean = false;
  isFromScheduleEntitled: boolean = false;
  currentSelect: any;

  fileObject: any = null;
  isToResetUploadComponent: boolean = false;

  privacyDoc: any;
  acceptedMimeTypesDoc = ACCEPTED_MIME_DOCS;

  isEditCFView: boolean = false;
  isEditNameView: boolean = false;
  isEditSurnameView: boolean = false;
  isEditPNView: boolean = false;
  fiscalCodeUser: string = '';
  nameUser: string = '';
  surnameUser: string = '';
  phoneNumberUser: string = '';

  isLoadingStructureList: boolean = false;
  structureList: DropdownItem[] = [];
  structureServive$: Subject<string> = new Subject<string>();
  selectedStructure?: DropdownItem = undefined;
  private _structuresLoaded: number = 1;
  private _structureSearchedText: string = '';
  private _resetStructureResultList: boolean = false;
  _isEndStructureList: boolean = false;

  _isEndCompanyList: boolean = false;
  isLoadingCompanyList: boolean = false;
  companiesList: DropdownItem[] = [];
  companyServive$: Subject<string> = new Subject<string>();
  selectedCompany?: DropdownItem = undefined;
  private _companiesLoaded: number = 1;
  private _companiesSearchedText: string = '';
  private _resetCompanyResultList: boolean = false;

  isEditCompanyView: boolean = false;

  isEditStatusView: boolean = false;
  entitledStatuses: DropdownItem[] = [
    {
      id: AttendanceStatuses.RESERVED,
      name: 'status.RESERVED'
    }, {
      id: AttendanceStatuses.APPROVED,
      name: 'status.APPROVED'
    }, {
      id: AttendanceStatuses.YES,
      name: 'status.YES'
    }, {
      id: AttendanceStatuses.NO,
      name: 'status.NO'
    }, {
      id: AttendanceStatuses.RESERVATION_DELETED,
      name: 'status.RESERVATION_DELETED'
    }
  ];

  entitledStatus: DropdownItem | undefined = undefined;
  isEditVenueView: boolean = false;

  isEditPrenotationDateView: boolean = false;
  prenotationDate: Date | undefined = undefined;

  addOfflineEntitledErrors: any[] = [];

  constructor(
    private route: ActivatedRoute,
    private scrollTo: ScrollTo,
    private appStore: Store<fromApp.AppState>,
    private translate: TranslateService,
    private rentService: RentService,
    private redirectService: RedirectService,
    private sidebarService: SidebarService,
    private modalService: ModalService,
    private authService: AuthService,
    private exportService: ExportService,
    private importService: ImportService,
    private anagService: AnagService
  ) {

  }

  ngOnInit() {
    this.scrollTo.header();
    this.campaignId = this.route.snapshot.paramMap.get('campaignId') || '';
    this.roundId = this.route.snapshot.paramMap.get('roundId') || '';

    if (this.redirectService.isThisCurrentPage('entitleds')) {
      this.selectedTab = 1
    }

    if (this.campaignId && this.roundId) {
      this.appStore.select(fromApp.getIsLimitedAdmin)
        .pipe(takeUntil(this._unsubscribeSignal$.asObservable()))
        .subscribe(
          (isLimitedAdmin) => {
            this.isLimitedAdmin = isLimitedAdmin;
          });

      this.initStructures();
      this.getCampaign();
    } else {
      this.redirectService.goToHome();
    }
  }

  goBackToRounds() {
    this.redirectService.goToAdminCampaignTabRounds(this.campaignId);
  }

  goToCustomers() {
    this.redirectService.goToAdminCampaignTabClients(this.campaignId);
  }

  ngOnDestroy() {
    this._unsubscribeSignal$.next();
    this._unsubscribeSignal$.unsubscribe();
  }

  setDropdownActions() {
    this.dropdownVenues = [
      {
        label: 'admin.campaignRoundDetails.ROUND_EXPORT',
        onClick: () => {
          this.exportRound();
        },
        disabled: this.venuesCount === 0,
        icon: 'assets/img/icons/icon-download.svg',
        strokeIcon: true
      }, {
        label: 'admin.campaignRoundDetails.VENUES_EXPORT',
        onClick: () => {
          this.exportVenueList();
        },
        disabled: this.venuesCount === 0,
        icon: 'assets/img/icons/icon-download.svg',
        strokeIcon: true
      }, {
        label: 'admin.campaignRoundDetails.SLOTS_EXPORT',
        onClick: () => {
          this.exportRoundSlots();
        },
        disabled: this.venuesCount === 0,
        icon: 'assets/img/icons/icon-download.svg',
        strokeIcon: true
      }, {
        label: 'admin.campaignRoundDetails.REMOVE_VENUE',
        onClick: () => {
          this.removeVenueFromRound();
        },
        disabled: this.venuesCount === 0,
        icon: 'assets/img/icons/icon-bin.svg',
        strokeIcon: true
      }, {
        label: 'admin.campaignRoundDetails.ADD_VENUE',
        onClick: () => {
          this.assignVenueToRound();
        },
        icon: 'assets/img/icons/icon-plus-square.svg',
        strokeIcon: true
      }
    ];

    this.dropdownEntitleds = [
      {
        label: 'admin.campaignRoundDetails.LIST_EXPORT',
        onClick: () => {
          this.exportEntitleds();
        },
        disabled: this.entitledsCount === 0,
        icon: 'assets/img/icons/icon-download.svg',
        strokeIcon: true
      }, {
        label: 'admin.campaignRoundDetails.IMPORT_AD_OFFLINE',
        onClick: () => {
          this.openImportADOfflineModal();
        },
        icon: 'assets/img/icons/icon-download-reverse.svg',
        strokeIcon: true
      }, {
        label: 'admin.campaignRoundDetails.ADD_AD_OFFLINE',
        onClick: () => {
          this.redirectService.goToAdminAddEntitled(this.campaignId, this.roundId);
        },
        icon: 'assets/img/icons/icon-plus-square.svg',
        strokeIcon: true
      }, {
        label: 'admin.campaignRoundDetails.IMPORT_ROUND',
        onClick: () => {
          this.redirectService.goToImportEntitledsFromRound(this.campaignId, this.roundId);
        },
        icon: 'assets/img/icons/icon-copy.svg',
        strokeIcon: true
      }, {
        label: 'admin.campaignRoundDetails.DELETE_ALL',
        onClick: () => {
          this.openDeleteAllEntitledsModal();
        },
        disabled: this.entitledsCount === 0,
        icon: 'assets/img/icons/icon-bin.svg',
        strokeIcon: true
      }
    ];
  }

  setDropdownSelectEntitledActions() {
    this.dropdownSelectEntitled = [
      {
        label: 'admin.campaignRoundDetails.tab.attendances.table.SCHEDULE',
        onClick: (currentSelect: InfoAttendanceSchedule) => {
          if (this.isAfterMaxReservationDate) {
            this.openMaxReservationDateModal(true, currentSelect);
          } else {
            this.onEntitledSchedule(currentSelect);
          }
        },
        disabledFct: (currentSelect: InfoAttendanceSchedule) => {
          return currentSelect && !currentSelect.canSchedule;
        },
        icon: 'assets/img/icons/icon-contact.svg',
        strokeIcon: true
      },
      {
        label: 'admin.campaignRoundDetails.tab.attendances.table.RESCHEDULE',
        onClick: (currentSelect: InfoAttendanceSchedule) => {
          if (this.isAfterMaxReservationDate) {
            this.openMaxReservationDateModal(false, currentSelect);
          } else {
            this.onEntitledReschedule(currentSelect);
          }
        },
        disabledFct: (currentSelect: InfoAttendanceSchedule) => {
          return currentSelect && !currentSelect.canReschedule;
        },
        icon: 'assets/img/icons/icon-contact.svg',
        strokeIcon: true
      }, {
        label: 'admin.campaignRoundDetails.tab.attendances.table.REMOVE',
        onClick: (currentSelect: InfoAttendanceSchedule) => {
          this.removeTicket(currentSelect);
        },
        disabledFct: (currentSelect: InfoAttendanceSchedule) => {
          return currentSelect && !currentSelect.canCancel;
        },
        icon: 'assets/img/icons/icon-bin.svg',
        strokeIcon: true
      }, {
        label: 'admin.campaignRoundDetails.tab.attendances.table.IMPER',
        onClick: (currentSelect: InfoAttendanceSchedule) => {
          this.onImpersonateEntitled(currentSelect);
        },
        icon: 'assets/img/icons/icon-users.svg',
        strokeIcon: true
      }, {
        label: 'admin.campaignRoundDetails.tab.attendances.table.FORM',
        onClick: (currentSelect: InfoAttendanceSchedule) => {
          this.openAttendenceCard(currentSelect);
        },
        icon: 'assets/img/icons/calendar.svg',
        strokeIcon: true
      }
    ];
  }

  setDropdownSelectVenueActions() {
    this.dropdownSelectVenue = [
      { // Dettagli
        label: 'admin.campaignRoundDetails.tab.venues.table.VENUE_DETAILS',
        onClick: (venue: any) => {
          this.goToVenueRoundDetails(venue);
        },
        icon: 'assets/img/icons/icon-clipboard.svg',
        strokeIcon: true
      }, { // Vedi richieste
        label: 'admin.campaignRoundDetails.tab.venues.table.PROCESS_REQUESTS',
        onClick: (venue: any) => {
          this.goToProcessRequests(venue);
        },
        disabledFct: (venue: any) => {
          return venue && venue.slotToProcessForAdmin === 0;
        },
        icon: 'assets/img/icons/icon-eye.svg',
        strokeIcon: true
      }];

    // Se sono un admin con piene visibilità, aggiungo la possibilità di modificare le date di prenotazione
    if (!this.isLimitedAdmin) {
      this.dropdownSelectVenue.push({
        label: 'admin.campaignRoundDetails.tab.venues.table.EDIT_BOOKING_DATES',
        onClick: (venue: any) => {
          this.goToVenueRoundEditDate(venue);
        },
        icon: 'assets/img/icons/icon-edit.svg',
        strokeIcon: true
      })
    };
  }

  /**
   * @description Mostra una modale con titolo e messaggio
   * @param modalId
   * @param title
   * @param message
   */
  dispatchWarningModal(title: string, message: string) {
    const messageObj = getModalMessageData(this.translate, title, message);
    this.appStore.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
  }

  /**
   * @description Mostra una modale con un messaggio di errore
   * @param modalId
   * @param error
   */
  dispatchModal(modalId: string, error: string) {
    const messageObj = getWarningModal(this.translate, modalId, error);
    this.appStore.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
  }

  getCampaign() {
    this.rentService.getCampaign(this.campaignId)
      .pipe(takeUntil(this._unsubscribeSignal$.asObservable()))
      .subscribe((result) => {
        if (!result || result.error) {
          this.dispatchModal('100', result.error || '');
          this.resetPagination();
        } else {
          this.campaign = result.response;
          this.getRound();
        }
      }, (err) => {
        if (err && err.message) {
          this.dispatchModal('101', err.message);
        }
        this.resetPagination();
      });
  }

  getRound() {

    this.rentService.getRound(this.roundId)
      .pipe(takeUntil(this._unsubscribeSignal$.asObservable()))
      .subscribe((result) => {
        if (!result || result.error) {
          this.dispatchModal('100', result.error || '');
          this.resetPagination();
        } else {
          this.round = result.response;

          this.isAfterMaxReservationDate = moment(this.today).isAfter(moment(this.round.maxReservationEndDate));

          // Calcolo la data limite per annullare le prenotazioni
          let maxDeleteBookingDate = this.round && moment(this.round.maxReservationEndDate);

          // Giorni precedenti alla data dell'appuntamento selezionato dall'utente entro cui può annullare la prenotazione
          const daysBeforeCancelBooking = this.round && this.round.maxAllowedReservationDeletionDate;

          let daysBeforeCancelBookingLabel = '';
          if (daysBeforeCancelBooking) {
            // Da decommentare se si vuole far vedere direttamente la data
            // maxDeleteBookingDate = maxDeleteBookingDate.subtract(daysBeforeCancelBooking, "days");
            if (daysBeforeCancelBooking === 1) {
              daysBeforeCancelBookingLabel = daysBeforeCancelBookingLabel + daysBeforeCancelBooking + ' ' + this.translate.instant('admin.campaignRoundDetails.DAY_BEFORE_USER_BOOKING_DATE').toLowerCase();
            } else {
              daysBeforeCancelBookingLabel = daysBeforeCancelBookingLabel + daysBeforeCancelBooking + ' ' + this.translate.instant('admin.campaignRoundDetails.DAYS_BEFORE_USER_BOOKING_DATE').toLowerCase();
            }
          }

          if (daysBeforeCancelBookingLabel && daysBeforeCancelBookingLabel.length) {
            this.maxDeleteBookingDate = daysBeforeCancelBookingLabel;
          } else {
            if (maxDeleteBookingDate) {
              this.maxDeleteBookingDate = moment(maxDeleteBookingDate).format("DD/MM/YYYY");
            }
          }

          // Calcolo la data limite per la modifica della struttura e della data
          let maxEditBookingDateAndStructure = this.round && moment(this.round.maxReservationEndDate);

          // Giorni precedenti alla data dell'appuntamento selezionato dall'utente entro cui può annullare la prenotazione
          const daysBeforeEditStructureAndDate = this.round && this.round.maxAllowedReservationMovingDate;

          let daysBeforeEditStructureAndDateLabel = '';
          if (daysBeforeEditStructureAndDate) {
            // Da decommentare se si vuole far vedere direttamente la data
            // maxEditBookingDateAndStructure = maxEditBookingDateAndStructure.subtract(daysBeforeEditStructureAndDate, "days");
            if (daysBeforeEditStructureAndDate === 1) {
              daysBeforeEditStructureAndDateLabel = daysBeforeEditStructureAndDateLabel + daysBeforeEditStructureAndDate + ' ' + this.translate.instant('admin.campaignRoundDetails.DAY_BEFORE_USER_BOOKING_DATE').toLowerCase();
            } else {
              daysBeforeEditStructureAndDateLabel = daysBeforeEditStructureAndDateLabel + daysBeforeEditStructureAndDate + ' ' + this.translate.instant('admin.campaignRoundDetails.DAYS_BEFORE_USER_BOOKING_DATE').toLowerCase();
            }
          }

          if (daysBeforeEditStructureAndDateLabel && daysBeforeEditStructureAndDateLabel.length) {
            this.maxEditBookingDateAndStructure = daysBeforeEditStructureAndDateLabel;
          } else {
            if (maxEditBookingDateAndStructure) {
              this.maxEditBookingDateAndStructure = moment(maxEditBookingDateAndStructure).format("DD/MM/YYYY");
            }
          }

          if (this.round && this.round.startDate && this.round.endDate) {
            let now = new Date().getTime();
            const minReservationEndDate = new Date(this.round.minReservationEndDate).getTime();
            const endDate = new Date(this.round.endDate).getTime();
            this.activeRound = now >= minReservationEndDate && endDate >= now;
          }

          if (this.selectedTab === 0) {
            this.countRoundVenues();
          } else {
            this.countRoundEntitleds();
          }
        }
        this.isLoadingBase = false;
      }, (err) => {
        if (err && err.message) {
          this.dispatchModal('101', err.message);
        }
        this.resetPagination();
      });
  }

  openMaxReservationDateModal(isScheduledEntitled?: boolean, currentSelect?: InfoAttendanceSchedule) {
    this.isFromScheduleEntitled = isScheduledEntitled ? true : false;
    this.currentSelect = currentSelect;
    this.modalService.open('maxReservationModal');
  }

  closeMaxReservationDateModal() {
    this.isFromScheduleEntitled = false;
    this.currentSelect = null;
    this.modalService.close('maxReservationModal');
  }

  confirmGoToEntitledReservation() {
    if (this.isFromScheduleEntitled) {
      this.onEntitledSchedule(this.currentSelect);
      this.closeMaxReservationDateModal();
    } else {
      this.onEntitledReschedule(this.currentSelect);
      this.closeMaxReservationDateModal();
    }
  }

  /**
   * @description Apre la modale per confermare la cancellazione della campagna
   */
  openDeleteRoundModal() {
    this.modalService.open('deleteRound');
  }

  /**
   * @description Chiude la modale per confermare la cancellazione della campagna
   */
  closeDeleteRoundModal() {
    this.modalService.close('deleteRound');
  }

  confirmDeleteCampaignRound() {
    this.isLoadingBase = true;
    this.isLoadingCount = true;

    this.rentService.deleteRoundForAdmin([this.roundId])
      .pipe(takeUntil(this._unsubscribeSignal$.asObservable()))
      .subscribe((result) => {
        this.closeDeleteRoundModal();
        if (!result || result.error) {
          this.dispatchModal('200', result.error || '');
          this.isLoadingBase = false;
          this.isLoadingCount = false;
        } else {
          this.redirectService.goToAdminCampaignTabRounds(this.campaignId);
        }
      }, (err) => {
        if (err && err.message) {
          this.closeDeleteRoundModal();
          this.dispatchModal('201', err.message);
        }
        this.isLoadingBase = false;
        this.isLoadingCount = false;
      });
  }

  editRound() {
    this.redirectService.goToAdminNewCampaignRound(this.campaignId, this.roundId);
  }

  onTabClick(index: number) {
    this.selectedTab = index;

    this.resetPagination();
    if (this.selectedTab === 0) {
      this.redirectService.goToAdminCampaignRoundTabVenues(this.campaignId, this.roundId, true);
      this.countRoundVenues();
    } else {
      this.redirectService.goToAdminCampaignRoundTabEntitleds(this.campaignId, this.roundId, true);
      this.countRoundEntitleds();
    }
  }

  //#region Round venues

  assignVenueToRound() {
    this.redirectService.goToAdminAssignVenueToRound(this.campaignId, this.roundId);
  }

  removeVenueFromRound() {
    this.redirectService.goToRemoveVenueFromRound(this.campaignId, this.roundId);
  }

  goToProcessRequests(venue: InfoVenue) {
    this.redirectService.goToAdminPendingRequests(this.campaignId, this.roundId, venue.venueId);
  }

  // Porta alla pagina di edit delle date di una prenotazione un round
  goToVenueRoundEditDate(venue: InfoVenue) {
    this.redirectService.goToVenueRoundEditDate(this.campaignId, this.roundId, venue.venueId);
  }

  goToVenueRoundDetails(venue: InfoVenue) {
    this.redirectService.goToAdminRoundVenueDetails(this.campaignId, this.roundId, venue.venueId);
  }

  onVenueTabClick(index: number) {
    this.selectedVenueTab = index;
  }

  onChangeVenueVisibility(value: DropdownItem) {
    this.selectedVenueVisibility = value;
  }

  countRoundVenues() {
    this.isLoadingCount = true;
    this.isLoading = true;

    this.rentService.countVenueList(this.roundId)
      .subscribe((result) => {

        if (!result || result.error) {
          this.dispatchModal('100', result.error || '');
          this.resetPagination();
        } else {
          this.venuesCount = result.response;
          this.setDropdownActions();

          if (this.venuesCount > 0) {
            this.getRoundVenues();
          } else {
            this.resetPagination();
          }
          this.isLoadingCount = false;
        }
      }, (err) => {
        if (err && err.message) {
          this.dispatchModal('101', err.message);
        }
        this.resetPagination();
      });
  }

  getRoundVenues(index?: number) {

    this.isLoading = true;

    let orderBy: OrderByVenue = 'title_asc';
    if (this.orderVenueTitle === 3) {
      orderBy = 'title_desc';
    } else if (this.orderVenueSaturation === 2) {
      orderBy = 'saturation_asc';
    } else if (this.orderVenueSaturation === 3) {
      orderBy = 'saturation_desc';
    }

    const pageSelectedIndex = index ? index : 0;
    if (index === 0 || index) {
      this.scrollTo.element('admin-campaign-round-details-anchor');
      this.pageSelectedIndex = index;
    }

    this.rentService.getVenueList(this.roundId, (pageSelectedIndex * this.paginationLengthVenues), this.paginationLengthVenues, undefined, undefined, false, orderBy, true)
      .pipe(takeUntil(this._unsubscribeSignal$.asObservable()))
      .subscribe((result) => {

        if (!result || result.error) {
          this.dispatchModal('102', result.error || '');
          this.resetPagination();
        } else {
          this.setDropdownSelectVenueActions();

          // Formatto i dati
          if (result.response && result.response.length) {
            const fromDateLabel = this.translate.instant("generic.FROM_THE_DATE").toLowerCase();
            const toDateLabel = this.translate.instant("generic.TO_THE_DATE").toLowerCase();

            let minRoundReservationEndDate = this.round && this.round.minReservationEndDate;
            let maxRoundReservationEndDate = this.round && this.round.maxReservationEndDate;

            for (let i = 0, resLength = result.response.length; i < resLength; i++) {
              let currentRes: any = result.response[i];

              // Prenotazioni (da XXXX a YYYY)
              let reservationFromDate = currentRes.bookingStartDate || minRoundReservationEndDate;
              let reservationToDate = currentRes.bookingEndDate || maxRoundReservationEndDate;

              if (reservationFromDate) {
                reservationFromDate = moment(reservationFromDate).format("DD/MM/YYYY");
              }

              if (reservationToDate) {
                reservationToDate = moment(reservationToDate).format("DD/MM/YYYY");
              }

              if (reservationFromDate && reservationToDate) {
                currentRes.reservationDateLabel = fromDateLabel + ' ' + reservationFromDate + '<br>' + toDateLabel + ' ' + reservationToDate;
              }

              // Somministrazione (da XXXX a YYYY)
              if (this.round && this.round.startDate && this.round.endDate) {
                currentRes.rounDateLabel = fromDateLabel + ' ' + moment(this.round.startDate).format("DD/MM/YYYY") + '<br>' + toDateLabel + ' ' + moment(this.round.endDate).format("DD/MM/YYYY");
              }
            }
          }

          this.venues = result.response;

          this.isLoading = false;
        }
      }, (err) => {
        if (err && err.message) {
          this.dispatchModal('103', err.message);
        }
        this.resetPagination();
      });
  }

  //#endregion Round venues

  //#region Round attendances

  countRoundEntitleds(selectedAttendance?: InfoAttendanceSchedule) {
    this.isLoadingCount = true;
    this.isLoading = true;

    this.rentService.countEntitledListByRoundForAdmin(this.roundId, this.searchText, this.searchTextCF, this.searchTextCompany)
      .subscribe((result) => {

        if (!result || result.error) {
          this.dispatchModal('100', result.error || '');
          this.resetPagination();
        } else {
          this.entitledsCount = result.response;
          this.setDropdownActions();
          this.setDropdownSelectEntitledActions();

          if (this.entitledsCount > 0) {
            this.hasDataWithoutSearch = true;
            this.getRoundEntitleds(0, selectedAttendance);
          } else {
            this.resetPagination();
          }
          this.isLoadingCount = false;
        }
      }, (err) => {
        if (err && err.message) {
          this.dispatchModal('101', err.message);
        }
        this.resetPagination();
      });
  }

  getRoundEntitleds(index?: number, selectedAttendance?: InfoAttendanceSchedule) {

    this.isLoading = true;

    const pageSelectedIndex = index ? index : 0;
    if (index === 0 || index) {
      this.scrollTo.element('admin-campaign-round-details-anchor');
      this.pageSelectedIndex = index;
    }

    this.rentService.getEntitledListByRoundForAdmin(this.roundId, (pageSelectedIndex * this.paginationLengthEntitleds), this.paginationLengthEntitleds, this.searchText, this.searchTextCF, this.searchTextCompany)
      .pipe(takeUntil(this._unsubscribeSignal$.asObservable()))
      .subscribe((result) => {

        if (!result || result.error) {
          this.dispatchModal('102', result.error || '');
          this.resetPagination();
        } else {
          this.entitleds = result.response;
          if (selectedAttendance) {
            this.entitleds.forEach((x: any) => {
              if (x.userId == selectedAttendance.userId && x.status == selectedAttendance.status) {
                this.openAttendenceCard(x);
              }
            })
          }
          this.isLoading = false;
        }
      }, (err) => {
        if (err && err.message) {
          this.dispatchModal('103', err.message);
        }
        this.resetPagination();
      });
  }

  searchTextChange(searchText?: string) {
    this.searchText = searchText;
  }

  searchTextCFChange(searchText?: string) {
    this.searchTextCF = searchText;
  }

  searchTextCompanyChange(searchText?: string) {
    this.searchTextCompany = searchText;
  }

  searchEntitleds() {
    this.countRoundEntitleds();
  }

  exportEntitleds() {
    this.isLoading = true;

    this.exportService.exportEntitledListForAdmin(this.roundId)
      .pipe(
        switchMap((senecaResponse) => {
          if (senecaResponse && senecaResponse.error) {
            this.dispatchWarningModal('030', senecaResponse.error);
            this.isLoading = false;

            return of(null);
          }
          return this.exportService.getDownloadTempFileUrl(senecaResponse.response, false);
        }),
        takeUntil(this._unsubscribeSignal$.asObservable())
      )
      .subscribe(downloadUrl => {
        if (downloadUrl) {
          const timeout = setTimeout(() => {
            clearTimeout(timeout);
            document.location.assign(downloadUrl);
            this.isLoading = false;
          }, 500);
        }
      }, (err) => {
        this.isLoading = false;
        if (err && err.message) {
          this.dispatchWarningModal('031', err.message);
        }
      });
  }

  //#endregion Round attendances

  resetPagination() {
    this.isLoading = false;
    this.isLoadingCount = false;
    this.isLoadingBase = false;

    this.venues = [];
    this.venuesCount = 0;

    this.entitleds = [];
    this.entitledsCount = 0;
    this.setDropdownActions();
    this.setDropdownSelectEntitledActions();
    this.setDropdownSelectVenueActions();
  }

  //#region Attendance card

  openAttendenceCard(attendance: InfoAttendanceSchedule) {
    this.selectedAttendance = attendance;

    this.structureServive$.next('');

    this.nameUser = '';
    this.surnameUser = '';
    this.phoneNumberUser = '';
    this.fiscalCodeUser = '';
    this.entitledStatus = undefined;
    this.selectedStructure = undefined;
    this.prenotationDate = undefined;

    if (this.selectedAttendance.name) {
      this.nameUser = this.selectedAttendance.name;
    }
    if (this.selectedAttendance.surname) {
      this.surnameUser = this.selectedAttendance.surname;
    }
    if (this.selectedAttendance.phoneNumber) {
      this.phoneNumberUser = this.selectedAttendance.phoneNumber;
    }
    if (this.selectedAttendance.fiscalCode) {
      this.fiscalCodeUser = this.selectedAttendance.fiscalCode;
    }
    if (this.selectedAttendance.status) {
      this.entitledStatus = this.entitledStatuses.filter((x: any) => {
        return x.id == this.selectedAttendance?.status;
      })[0];
    }

    if (this.selectedAttendance.venue) {
      this.selectedStructure = {
        id: this.selectedAttendance.venue.venueId,
        name: this.selectedAttendance.venue.name + ' - ' + this.selectedAttendance.venue.address,
        content: this.selectedAttendance.venue
      }
    }

    if (this.selectedAttendance.startDate) {
      this.prenotationDate = new Date(this.selectedAttendance.startDate);
    }

    this.isEditSurnameView = false;
    this.isEditNameView = false;
    this.isEditPNView = false;
    this.isEditCFView = false;
    this.isEditStatusView = false;
    this.isEditVenueView = false;
    this.isEditPrenotationDateView = false;

    this.description.setValue(attendance.description);

    this.sidebarService.setShowNav(true);

  }

  closeAttendanceCard() {
    this.sidebarService.setShowNav(false);
  }

  /**
   * Aggiorna la descrizione di una prenotazione
   */
  updateAttendee() {

    this.isLoading = true;

    if (this.selectedAttendance) {
      this.rentService.updateAttendanceForAdmin(
        this.selectedAttendance.attendanceId,
        this.description.value)
        .pipe(takeUntil(this._unsubscribeSignal$.asObservable()))
        .subscribe(
          (result: SenecaResponse<null>) => {
            if (result && result.error) {
              this.dispatchWarningModal('014', result.error);
              this.isLoading = false;
            } else {
              this.selectedAttendance = undefined;
              this.closeAttendanceCard();
              this.getRoundEntitleds();
            }
          }, (err) => {
            if (err && err.message) {
              this.dispatchWarningModal('015', err.message);
            }
            this.isLoading = false;
          });
    }
  }

  //#endregion Attendance card

  //#region Hover venues

  onEnterMouseVenueTitle() {
    if (this.orderVenueTitle === 0) {
      this.orderVenueTitle = 1;
    }
  }

  onLeaveMouseVenueTitle() {
    if (this.orderVenueTitle === 1) {
      this.orderVenueTitle = 0;
    }
  }

  onClickVenueTitle() {
    if (this.orderVenueTitle === 0 || this.orderVenueTitle === 1) {
      this.orderVenueTitle = 2;
    } else if (this.orderVenueTitle === 2) {
      this.orderVenueTitle = 3;
    } else if (this.orderVenueTitle === 3) {
      this.orderVenueTitle = 0;
    }
    this.orderVenueSaturation = 0;
    this.pageSelectedIndex = 0;
    this.getRoundVenues(0);
  }

  onEnterMouseVenueSaturation() {
    if (this.orderVenueSaturation === 0) {
      this.orderVenueSaturation = 1;
    }
  }

  onLeaveMouseVenueSaturation() {
    if (this.orderVenueSaturation === 1) {
      this.orderVenueSaturation = 0;
    }
  }

  onClickVenueSaturation() {
    if (this.orderVenueSaturation === 0 || this.orderVenueSaturation === 1) {
      this.orderVenueSaturation = 2;
    } else if (this.orderVenueSaturation === 2) {
      this.orderVenueSaturation = 3;
    } else if (this.orderVenueSaturation === 3) {
      this.orderVenueSaturation = 0;
    }
    this.orderVenueTitle = 0;
    this.pageSelectedIndex = 0;
    this.getRoundVenues(0);
  }

  //#endregion Hover venues

  exportVenueList() {
    this.isLoading = true;

    this.exportService.exportVenueList(this.roundId)
      .pipe(
        switchMap((senecaResponse) => {
          if (senecaResponse && senecaResponse.error) {
            this.dispatchWarningModal('030', senecaResponse.error);
            this.isLoading = false;

            return of(null);
          }
          return this.exportService.getDownloadTempFileUrl(senecaResponse.response, false);
        }),
        takeUntil(this._unsubscribeSignal$.asObservable())
      )
      .subscribe(downloadUrl => {
        if (downloadUrl) {
          const timeout = setTimeout(() => {
            clearTimeout(timeout);
            document.location.assign(downloadUrl);
            this.isLoading = false;
          }, 500);
        }
      }, (err) => {
        this.isLoading = false;
        if (err && err.message) {
          this.dispatchWarningModal('031', err.message);
        }
      });
  }

  exportRound() {
    this.isLoading = true;

    this.rentService.exportRoundInfo(this.roundId)
      .pipe(
        switchMap((senecaResponse) => {
          if (senecaResponse && senecaResponse.error) {
            this.dispatchWarningModal('030', senecaResponse.error);
            this.isLoading = false;

            return of(null);
          }
          return this.exportService.getDownloadTempFileUrl(senecaResponse.response, false);
        }),
        takeUntil(this._unsubscribeSignal$.asObservable())
      )
      .subscribe(downloadUrl => {
        if (downloadUrl) {
          const timeout = setTimeout(() => {
            clearTimeout(timeout);
            document.location.assign(downloadUrl);
            this.isLoading = false;
          }, 500);
        }
      }, (err) => {
        this.isLoading = false;
        if (err && err.message) {
          this.dispatchWarningModal('031', err.message);
        }
      });
  }

  exportRoundSlots() {
    this.isLoading = true;

    this.exportService.exportRoundSlots(this.roundId)
      .pipe(
        switchMap((senecaResponse) => {
          if (senecaResponse && senecaResponse.error) {
            this.dispatchWarningModal('030', senecaResponse.error);
            this.isLoading = false;

            return of(null);
          }
          return this.exportService.getDownloadTempFileUrl(senecaResponse.response, false);
        }),
        takeUntil(this._unsubscribeSignal$.asObservable())
      )
      .subscribe(downloadUrl => {
        if (downloadUrl) {
          const timeout = setTimeout(() => {
            clearTimeout(timeout);
            document.location.assign(downloadUrl);
            this.isLoading = false;
          }, 500);
        }
      }, (err) => {
        this.isLoading = false;
        if (err && err.message) {
          this.dispatchWarningModal('031', err.message);
        }
      });
  }

  /**
   * On click on the AD - go for impersonation
   * @param entitled
   */
  onImpersonateEntitled(entitled: InfoAttendanceSchedule) {
    this.authService.impersonateUser(entitled.userId)
      .pipe(takeUntil(this._unsubscribeSignal$.asObservable()))
      .subscribe((result) => {
        if (!result || result.error) {
          this.dispatchModal('082', result.error || '');
        } else {
          this.redirectService.impersonationLogin(result.response);
        }
      }, (err) => {
        if (err && err.message) {
          this.dispatchModal('084', err.message);
        }
      });
  }

  onEntitledReschedule(entitled: InfoAttendanceSchedule) {
    if (entitled && entitled.canReschedule) {
      this.redirectService.goToAdminCampaignRoundRescheduleEntitled(this.campaignId, this.roundId, entitled.userId);
    }
  }

  onEntitledSchedule(entitled: InfoAttendanceSchedule) {
    if (!this.isEntitledDisabledSchedule(entitled)) {
      this.redirectService.goToAdminCampaignRoundScheduleEntitled(this.campaignId, this.roundId, entitled.userId, entitled.attendanceId);
    }

  }

  isEntitledDisabledSchedule(entitled: InfoAttendanceSchedule): boolean {
    return entitled && !entitled.canSchedule;
  }

  isEntitledDisabled(entitled: InfoAttendanceSchedule): boolean {
    // return entitled && entitled.status !== 'RESERVED' ? true : false;
    return entitled && (!entitled.canReschedule || !entitled.canCancel);
  }

  removeTicket(ticket: InfoAttendanceSchedule) {
    this.attendanceToDelete = ticket;
    this.modalService.open('deleteAdminBooking');
  }

  closeDeleteBookingModal(confirm?: boolean) {
    this.modalService.close('deleteAdminBooking');

    if (confirm && this.attendanceToDelete) {
      this.isLoading = true;

      this.rentService.deleteReservationForAdmin(this.attendanceToDelete.attendanceId)
        .pipe(takeUntil(this._unsubscribeSignal$.asObservable()))
        .subscribe((result) => {
          this.attendanceToDelete = undefined;
          if (!result || result.error) {
            this.dispatchModal('045', result.error || '');
            this.isLoading = false;
          } else {
            this.setDropdownActions();
            this.setDropdownSelectEntitledActions();
            this.getRoundEntitleds();
          }
        }, (err) => {
          if (err && err.message) {
            this.dispatchModal('046', err.message);
          }
          this.attendanceToDelete = undefined;
          this.isLoading = false;
        });

    } else {
      this.attendanceToDelete = undefined;
    }
  }

  /**
   * @description Apre la modale per la cancellazione degli AD in transito
   */
  openDeleteAllEntitledsModal() {
    this.modalService.open('deleteAllEntitleds');
  }

  /**
   * @description Chiude la modale per la cancellazione degli AD in transito
   */
  closeDeleteAllEntitledsModal() {
    this.modalService.close('deleteAllEntitleds');
  }

  confirmDeleteAllEntitleds() {
    this.rentService.deleteUnprocessedEntitledForAdmin(this.roundId)
      .pipe(takeUntil(this._unsubscribeSignal$.asObservable()))
      .subscribe((result) => {
        this.closeDeleteAllEntitledsModal();
        if (!result || result.error) {
          this.dispatchModal('082', result.error || '');
        } else {
          this.countRoundEntitleds();
        }
      }, (err) => {
        if (err && err.message) {
          this.closeDeleteAllEntitledsModal();
          this.dispatchModal('084', err.message);
        }
        this.resetPagination();
      });
  }

  closeImportADOfflineModal() {
    this.modalService.close('importADOffline');
  }

  openImportADOfflineModal() {
    this.resetUploadADOfflineData();
    this.modalService.open('importADOffline');
  }

  uploadADOfflineFile(simulate?: boolean) {
    // Chiudo la modale, mostro il loader e chiamo il servizio di upload
    this.closeImportADOfflineModal();
    this.isLoading = true;

    this.importService.importOfflineEntitledForAdmin(this.fileObject, this.roundId, simulate)
      .subscribe((data: any) => {
        if (data.body && data.body.error) {
          if (data.body.error === '') {
            this.dispatchWarningModal('013', 'USER_WITHOUT_AUTHS_IMPORT');
          } else {
            this.dispatchWarningModal('013', data.body.error);
          }
          this.isLoading = false;
        } else if (data.type != 0 && data.body) {
          if (data.body.response && data.body.response.importRes && data.body.response.importRes.length) {
            this.addOfflineEntitledErrors.length = 0;
            for (let i = 0; i < data.body.response.importRes.length; i++) {
              this.addOfflineEntitledErrors.push(data.body.response.importRes[i]);
            }
            this.openAddEntitledErrorsModal();
            this.isLoading = false;
          } else {
            if (simulate) {
              this.uploadADOfflineFile();
            } else {
              this.dispatchMessageModal('generic.MASSIVE_LOADING', 'generic.PEOPLE_UPLOADED');
              this.countRoundEntitleds();
            }
          }
        }
      },
        (err: string) => {
          this.dispatchWarningModal('015', err);
          this.isLoading = false;
        });
  }

  fileImportPeopleOffline() {
    window.open('/assets/documents/templateImportAventiDirittoOffline.xlsx');
  }

  saveADOfflineFile(file: any) {
    this.fileObject = file;
    this.isToResetUploadComponent = false;
  }

  isConfirmUploadADOfflineBtnDisabled(): boolean {
    return !this.fileObject || (this.fileObject && !this.fileObject.fileExtension);
  }

  resetUploadADOfflineData() {
    this.fileObject = null;
    this.isToResetUploadComponent = true;
  }

  dispatchMessageModal(title: string, message: string) {
    const messageObj = getModalMessageData(this.translate, title, message);
    this.appStore.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
  }

  // Premi sul sfoglia files
  uploadDoc() {
    const element: any = document.querySelector("#uploadFile");
    element.value = "";
    element.click();
  }

  async handleFileInput(file: any) {
    if (file.files && file.files.length) {
      this.resetPrivacyFileData();
      let blob = file.files[0];
      this.privacyDoc.file = blob;
      this.uploadPrivacyDoc();
    }
  }

  resetPrivacyFileData() {
    this.privacyDoc = {};
  }

  // Upload file di privacy per singolo AD
  uploadPrivacyDoc() {
    if (this.privacyDoc && this.privacyDoc.file && this.selectedAttendance) {
      this.closeAttendanceCard();
      this.isLoading = true;
      this.importService.uploadEntitledPrivacyFile(this.selectedAttendance.attendanceId, this.privacyDoc.file)
        .subscribe((data: any) => {
          if (data.body && data.body.error) {
            this.dispatchWarningModal('0135', 'errors.' + data.body.error);
            this.isLoading = false;
          } else if (data.type != 0 && data.body) {
            this.countRoundEntitleds(this.selectedAttendance);
          }
        },
          (err: string) => {
            this.dispatchWarningModal('015', err);
            this.isLoading = false;
          });
    }
  }

  downloadPrivacyFile(url: string) {
    window.open(url);
  }

  deletePrivacyFile(doc: any) {
    if (doc && doc.uploadId && this.selectedAttendance) {
      this.closeAttendanceCard();
      this.isLoading = true;
      this.rentService.deleteEntitledPrivacyFile(this.selectedAttendance.attendanceId, doc.uploadId)
        .subscribe((data: any) => {
          if (data.error) {
            this.dispatchWarningModal('0135', 'errors.' + data.error);
            this.isLoading = false;
          } else {
            this.countRoundEntitleds(this.selectedAttendance);
          }
        },
          (err: string) => {
            this.dispatchWarningModal('015', err);
            this.isLoading = false;
          });
    }
  }

  userNameChanged(text: string) {
    this.nameUser = text;
  }

  userSurnameChanged(text: string) {
    this.surnameUser = text;
  }

  userFCChanged(text: string) {
    this.fiscalCodeUser = text;
  }

  userPNChanged(text: string) {
    this.phoneNumberUser = text;
  }

  userCompanyChanged(company?: DropdownItem) {
    this.selectedCompany = company;
  }

  editName(clearValue?: boolean) {
    this.isEditNameView = !this.isEditNameView;
    if (clearValue && this.selectedAttendance) {
      this.nameUser = this.selectedAttendance.name;
    }
  }

  editSurname(clearValue?: boolean) {
    this.isEditSurnameView = !this.isEditSurnameView;
    if (clearValue && this.selectedAttendance) {
      this.surnameUser = this.selectedAttendance.surname;
    }
  }

  editPN(clearValue?: boolean) {
    this.isEditPNView = !this.isEditPNView;
    if (clearValue && this.selectedAttendance) {
      this.phoneNumberUser = this.selectedAttendance.phoneNumber;
    }
  }

  editCF(clearValue?: boolean) {
    this.isEditCFView = !this.isEditCFView;
    if (clearValue && this.selectedAttendance) {
      this.fiscalCodeUser = this.selectedAttendance.fiscalCode;
    }
  }

  editStatus(clearValue?: boolean) {
    this.isEditStatusView = !this.isEditStatusView;
    if (clearValue && this.selectedAttendance) {
      this.entitledStatus = this.entitledStatuses.filter((x: any) => {
        return x.id == this.selectedAttendance?.status;
      })[0];
    }
  }

  editVenue(clearValue?: boolean) {
    this.isEditVenueView = !this.isEditVenueView;
    if (clearValue && this.selectedAttendance && this.selectedAttendance.venue) {
      this.selectedStructure = {
        id: this.selectedAttendance.venue.venueId,
        name: this.selectedAttendance.venue.name + ' - ' + this.selectedAttendance.venue.address,
        content: this.selectedAttendance.venue
      }
    }
  }

  editPrenotationDate(clearValue?: boolean) {
    this.isEditPrenotationDateView = !this.isEditPrenotationDateView;
    if (clearValue && this.selectedAttendance) {
      this.prenotationDate = new Date(this.selectedAttendance.startDate);
    }
  }

  updateUser(isNameValue?: boolean, isSurnameValue?: boolean, isFCValue?: boolean, isPNValue?: boolean, isStatusValue?: boolean, isPrenotationDateValue?: boolean) {

    this.isLoading = true;

    let userId: string = '';
    if (this.selectedAttendance) {
      userId = this.selectedAttendance.userId;
    }

    let forename: string = '';
    let surname: string = '';
    let fiscalCode: string = '';
    let phoneNumber: string = '';
    let status: string = '';
    let venue: string = '';
    let prenotationDate: Date | undefined = undefined;
    if (isNameValue) {
      forename = this.nameUser;
      // Chiudo il form di edit e aggiorno il dato
      this.isEditNameView = false;
      if (this.selectedAttendance) {
        this.selectedAttendance.name = this.nameUser;
      }
    } else if (isSurnameValue) {
      surname = this.surnameUser;
      // Chiudo il form di edit e aggiorno il dato
      this.isEditSurnameView = false;
      if (this.selectedAttendance) {
        this.selectedAttendance.surname = this.surnameUser;
      }
    } else if (isFCValue) {
      fiscalCode = this.fiscalCodeUser;
      // Chiudo il form di edit e aggiorno il dato
      this.isEditCFView = false;
      if (this.selectedAttendance) {
        this.selectedAttendance.fiscalCode = this.fiscalCodeUser;
      }
    } else if (isPNValue) {
      phoneNumber = this.phoneNumberUser;
      // Chiudo il form di edit e aggiorno il dato
      this.isEditPNView = false;
      if (this.selectedAttendance) {
        this.selectedAttendance.phoneNumber = this.phoneNumberUser;
      }
    } else if (isStatusValue) {
      status = this.entitledStatus?.id || '';
      // Chiudo il form di edit e aggiorno il dato
      this.isEditStatusView = false;
      if (this.selectedAttendance) {
        this.selectedAttendance.status = status;
        this.entitledStatus = this.entitledStatuses.filter((x: any) => {
          return x.id == status;
        })[0];
      }
    }
    // Sede e data prenotazione devono sempre essere passati insieme

    /* else if (isVenueValue) {
      if (this.selectedAttendance) {
        this.selectedAttendance.venue = this.selectedStructure?.content;
      }
      venue = this.selectedStructure?.id || '';
      // Chiudo il form di edit e aggiorno il dato
      this.isEditVenueView = false;
    }  */else if (isPrenotationDateValue) {
      prenotationDate = this.prenotationDate;
      if (prenotationDate && this.selectedAttendance) {
        this.selectedAttendance!.startDate = prenotationDate;
        let endDate = new Date(prenotationDate);
        endDate.setMinutes(endDate.getMinutes() + 10);
        this.selectedAttendance.endDate = endDate;
      }

      if (this.selectedAttendance) {
        this.selectedAttendance.venue = this.selectedStructure?.content;
      }
      venue = this.selectedStructure?.id || '';

      // Chiudo il form di edit e aggiorno il dato
      this.isEditVenueView = false;
      this.isEditPrenotationDateView = false;
    }

    this.rentService.updateUserForAdmin(userId, this.campaignId, this.roundId, fiscalCode, forename, surname, phoneNumber, status, venue, prenotationDate)
      .pipe(takeUntil(this._unsubscribeSignal$.asObservable()))
      .subscribe((result) => {
        if (!result || result.error) {
          this.dispatchWarningModal('082', result.error || '');
          this.isLoading = false;
        } else {
          this.countRoundEntitleds();
        }
      }, (err) => {
        this.isLoading = false;
        if (err && err.message) {
          this.dispatchWarningModal('084', err.message);
        }
      });
  }

  initStructures() {
    this.structureServive$.pipe(
      switchMap(
        (text: string) => {
          if (text !== this._structureSearchedText) {
            this._resetStructureResultList = true;
            this._structuresLoaded = 1;
          } else {
            this._resetStructureResultList = false;
          }
          this._structureSearchedText = text || '';
          this.isLoadingStructureList = true;
          return this.getVenueList((this._structuresLoaded - 1), 10, this._structureSearchedText);
        }
      ),
      catchError((err, caught) => {
        return caught;
      })
    )
      .subscribe({
        next: (eventData: any) => {
          if (this._resetStructureResultList) {
            this.clearStructureResults();
          }
          if (eventData && eventData.length) {
            this._structuresLoaded += eventData.length;
            this.structureList = this.structureList.concat(eventData);
          }

          this.isLoadingStructureList = false;
        },
        error: (err: any) => {
          this.isLoadingStructureList = false;
        }
      });
  }

  /**
   * @description Carica e prepara gli elementi per la select con infinite scroll
   * @param fromRecord
   * @param numRecords
   * @param searchedText
   */
  getVenueList(fromRecord: number, numRecords: number, searchedText: string): Observable<DropdownItem[]> {
    return this.rentService.getVenueList(this.roundId, fromRecord, numRecords, searchedText)
      .pipe(
        takeUntil(this._unsubscribeSignal$.asObservable()),
        map((resultData: SenecaResponse<InfoVenue[]>) => {
          if (resultData.error) {
            this.dispatchWarningModal('007', resultData.error);
          } else if (resultData.response && resultData.response.length) {
            let formattedData: DropdownItem[] = [];

            resultData.response.forEach(structure => {
              let title = structure.name + ' - ' + structure.address;

              formattedData.push({
                id: structure.venueId,
                name: title,
                content: structure
              });
            });

            // Caricando n elementi per volta (itemsToLoad) se
            // ne carica di meno significa che sono a fine lista
            if (resultData.response.length < numRecords) {
              this._isEndStructureList = true;
            }

            return formattedData;
          }
          this._isEndStructureList = true;
          return [];
        })
      );
  }

  clearStructureResults(): void {
    this._structuresLoaded = 0;
    this.structureList = [];
    this._resetStructureResultList = false;
  }

  /**
   * @description Chiamata allo scroll
   * @param scrollEvent
   */
  fetchMoreStructures(scrollEvent: { start: number; end: number }) {
    if (!this._isEndStructureList && scrollEvent.end === this._structuresLoaded) {
      this.structureServive$.next(this._structureSearchedText);
    }
  }

  initCompanies() {
    this.companyServive$.pipe(
      switchMap(
        (text: string) => {
          if (text !== this._companiesSearchedText) {
            this._resetCompanyResultList = true;
            this._companiesLoaded = 1;
          } else {
            this._resetCompanyResultList = false;
          }
          this._companiesSearchedText = text || '';
          this.isLoadingCompanyList = true;
          return this.getCompanyList((this._companiesLoaded - 1), 10, this._companiesSearchedText);
        }
      ),
      catchError((err, caught) => {
        return caught;
      })
    )
      .subscribe({
        next: (eventData: DropdownItem[]) => {
          if (this._resetCompanyResultList) {
            this.clearCompanyResults();
          }
          if (eventData && eventData.length) {
            this._companiesLoaded += eventData.length;
            this.companiesList = this.companiesList.concat(eventData);
          }
          this.isLoadingCompanyList = false;
        },
        error: (err: any) => {
          this.isLoadingCompanyList = false;
        }
      });
  }

  /**
   * @description Carica e prepara gli elementi per la select con infinite scroll
   * @param fromRecord
   * @param numRecords
   * @param searchedText
   */
  getCompanyList(fromRecord: number, numRecords: number, searchedText: string): Observable<DropdownItem[]> {
    return this.anagService.listCustomersForAdmin(fromRecord, numRecords, searchedText, this.campaignId)
      .pipe(
        takeUntil(this._unsubscribeSignal$.asObservable()),
        map((resultData: SenecaResponse<UserGroupAdmin[]>) => {
          if (resultData.error) {
            this.dispatchWarningModal('007', resultData.error);
          } else if (resultData.response && resultData.response.length) {
            let formattedData: DropdownItem[] = [];

            resultData.response.forEach(company => {

              formattedData.push({
                id: company.groupId,
                name: company.title,
                content: company
              });
            });

            // Caricando n elementi per volta (itemsToLoad) se
            // ne carica di meno significa che sono a fine lista
            if (resultData.response.length < numRecords) {
              this._isEndCompanyList = true;
            }

            return formattedData;
          }
          this._isEndCompanyList = true;
          return [];
        })
      );
  }

  clearCompanyResults(): void {
    this._companiesLoaded = 0;
    this.companiesList = [];
    this._resetCompanyResultList = false;
  }

  fetchMoreCompanies(scrollEvent: { start: number; end: number }) {
    if (!this._isEndCompanyList && scrollEvent.end === this._companiesLoaded) {
      this.companyServive$.next(this._companiesSearchedText);
    }
  }

  onEntitledStatusChange(status: DropdownItem) {
    this.entitledStatus = status;
  }

  onStructureSelected(structure?: DropdownItem) {
    this.selectedStructure = structure;
  }

  onPrenotationDateChange(selectedDate: Date) {
    this.prenotationDate = selectedDate;
  }

  closeAddEntitledErrorsModal() {
    this.modalService.close("addEntitledErrorsModal");
  }

  openAddEntitledErrorsModal() {
    this.modalService.open("addEntitledErrorsModal");
  }

}

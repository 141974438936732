/*
 * Servizio che gestisce le chiamate ai servizi per le lingue
*/

import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { User } from 'atfcore-commonclasses/bin/classes/anag';
import { AttendanceStatuses, CampaignManagerCreateAttendeeResponse, CampaignManagerGetActiveAndNextRoundResponse, ConfirmReservationForEntitledResponse, DayAttendances, DaySlots, FamilyMemberTypes, GetAllCampaignsSlotsData, GetCampaignListForHelpDeskResponse, GetReservationEntitledDataResponse, GetValidSlotsForCreateAttendeeResponse, HasUsersAlreadyABookingForSameCampaignResponse, HealthFacilityInfo, InfoAttendance, InfoCampaign, InfoRound, InfoSlot, InfoVenue, RecallStatuses } from 'atfcore-commonclasses/bin/classes/campaignmanager';
import { MapById, SenecaResponse } from 'atfcore-commonclasses/bin/classes/common';
import { Observable, of } from 'rxjs';
import { DaySlotsFe } from 'src/app/user/addReservation/addReservation.component';
import { AttendanceDayMenu } from 'src/app/utils/classes.utils';
import { handleCustomList, handleDay, handleFromRecord, handleNumRecords, handleSearchedText, handleTitle, handleVanueIds } from 'src/app/utils/utils';
import { UrlService } from '../../shared/services/url.service';
import { SupplierPerson } from 'atfcore-commonclasses';

export interface InfoEntitled {
  user: User
  attendance?: InfoAttendance,
  preMatchedVenue?: InfoVenue
}

export type OrderByVenue = 'title_asc' | 'title_desc' | 'saturation_asc' | 'saturation_desc';

export type InfoVenueSlot = InfoVenue & InfoSlot;

export interface SlotInfoDate { startDate: string; endDate: string };

export type VenueDescriptionStatus = 'PENDING' | 'APPROVED' | 'NOT_APPROVED';

export interface GetAllCampaignsSlotsDataAdmin {
  totalOfferedSlotCount: number,
  totalBookedSlotCount: number,
  totalPresentPeopleCount: number,
  totalEntitledCount: number,
  processedEntitleCount: number
};

export interface EntitledByCampaignResponse {
  user: (User & {
    fromSSO: boolean,
    registrationCompleted: boolean
  }),
  slot: InfoSlot,
  status: 'YES' | 'DELIVERED' | 'NO' | 'RESERVED',
  attendanceId?: string
};

@Injectable()
export class RentService {

  _mediatorUrl: string;

  constructor(private http: HttpClient, private urlService: UrlService) {
    this._mediatorUrl = this.urlService.getServiceMediatorUrl();
  }

  /**
   * Crea un utente in uno specifico slot di un round di una campagna
   * @param {string} slotId
   * @param {string} roundId
   * @param {string} attendeeId
   * @param {string} status
   * @param {Date} vaccinationSlotStartDate
   * @param {Date} vaccinationSlotEndDate
   * @param {string} description
   * @return {CampaignManagerCreateAttendeeResponse}
   */
  addAttendeeForStructure(slotId: string, roundId: string, attendeeId: string, status: AttendanceStatuses,
    vaccinationSlotStartDate: string, vaccinationSlotEndDate: string, description: string = ''): Observable<SenecaResponse<CampaignManagerCreateAttendeeResponse>> {
    return this.http.post<SenecaResponse<CampaignManagerCreateAttendeeResponse>>(
      this._mediatorUrl + 'add-attendee-for-structure', {
      slotId,
      roundId,
      attendeeId,
      status,
      vaccinationSlotStartDate,
      vaccinationSlotEndDate,
      description
    }
    );
  }

  /**
   * Crea associazione sede - round
   * @param {string} roundId
   * @param {string[]} venueId
   * @param venueBookingStartDate?: Date;
   * @param venueBookingEndDate?: Date;
   * @return {boolean}
   */
  addVenueAssociationToRoundForAdmin(roundId: string, venueId: string[], venueBookingStartDate?: Date, venueBookingEndDate?: Date): Observable<SenecaResponse<boolean>> {
    const formattedVenueBookingStartDate = venueBookingStartDate && venueBookingStartDate.toISOString();
    const formattedVenueBookingEndDate = venueBookingEndDate && venueBookingEndDate.toISOString();

    return this.http.post<SenecaResponse<boolean>>(
      this._mediatorUrl + 'add-venue-association-to-round-for-admin',
      {
        roundId,
        venueId,
        venueBookingStartDate: formattedVenueBookingStartDate,
        venueBookingEndDate: formattedVenueBookingEndDate
      }
    );
  }

  /**
   * Modifica associazione sede - data prenotazione
   * @param {string} roundId
   * @param {string[]} venueId
   * @param venueBookingStartDate?: Date;
   * @param venueBookingEndDate?: Date;
   * @return {boolean}
   */
  updateVenueInRoundForAdmin(roundId: string, venueId: string, venueBookingStartDate?: Date, venueBookingEndDate?: Date): Observable<SenecaResponse<boolean>> {
    const formattedVenueBookingStartDate = venueBookingStartDate && venueBookingStartDate.toISOString();
    const formattedVenueBookingEndDate = venueBookingEndDate && venueBookingEndDate.toISOString();

    return this.http.post<SenecaResponse<boolean>>(
      this._mediatorUrl + 'update-venue-in-round-for-admin',
      {
        roundId,
        venueId,
        venueBookingStartDate: formattedVenueBookingStartDate,
        venueBookingEndDate: formattedVenueBookingEndDate
      }
    );
  }

  /**
   * Conferma una prenotazione in uno slot per un avente diritto
   * @param {string} roundId
   * @param {string} venueId
   * @param {string} slotId
   * @param {string} familiarUserId
   * @return {boolean} Retrieved data
   */
  confirmReservationForEntitled(roundId: string, venueId: string, slotId: string,
    familiarUserId: string): Observable<SenecaResponse<string>> {
    let httpParams = new HttpParams();

    httpParams = httpParams.append('roundId', roundId);
    httpParams = httpParams.append('venueId', venueId);
    httpParams = httpParams.append('slotId', slotId);
    httpParams = httpParams.append('familiarUserId', familiarUserId);

    return this.http.get<SenecaResponse<string>>(
      this._mediatorUrl + 'confirm-reservation-for-entitled', { params: httpParams }
    );
  }


  /**
   * Conferma una prenotazione in uno slot per un avente diritto
   * @param {string} roundId
   * @param {string} venueId
   * @param {string} slotId
   * @param {string} familiarUserId
   * @return {boolean} Retrieved data
   */
  confirmReservationForEntitledFamily(params: any): Observable<SenecaResponse<string>> {
    return this.http.post<SenecaResponse<string>>(
      this._mediatorUrl + 'confirm-reservation-for-entitled-family',
      {
        roundId: params.roundId,
        venueId: params.venueId,
        usersToReserve: params.usersToReserve
      }
    );
  }

  /**
   * Modifica una prenotazione in uno slot per un avente diritto
   * @param {string} roundId
   * @param {string} venueId
   * @param {string} slotId
   * @param {string} familiarUserId
   * @return {ConfirmReservationForEntitledResponse} Retrieved data
   */
  moveReservationForEntitled(roundId: string, venueId: string, slotId: string, oldAttendanceId: string,
    familiarUserId: string): Observable<SenecaResponse<ConfirmReservationForEntitledResponse>> {
    let httpParams = new HttpParams();

    httpParams = httpParams.append('roundId', roundId);
    httpParams = httpParams.append('venueId', venueId);
    httpParams = httpParams.append('slotId', slotId);
    httpParams = httpParams.append('oldAttendanceId', oldAttendanceId);
    httpParams = httpParams.append('familiarUserId', familiarUserId);

    return this.http.get<SenecaResponse<ConfirmReservationForEntitledResponse>>(
      this._mediatorUrl + 'move-reservation-for-entitled', { params: httpParams }
    );
  }

  /**
   * Conferma una prenotazione in uno slot per un avente diritto
   * @param {string} roundId
   * @param {string} venueId
   * @param {string} slotId
   * @param {string} familiarUserId
   * @return {boolean} Retrieved data
   */
  confirmReservationForAdmin(roundId: string, venueId: string, slotId: string,
    familiarUserId: string, ignoreMailSending?: boolean): Observable<SenecaResponse<boolean>> {
    let httpParams = new HttpParams();

    httpParams = httpParams.append('roundId', roundId);
    httpParams = httpParams.append('venueId', venueId);
    httpParams = httpParams.append('slotId', slotId);
    httpParams = httpParams.append('familiarUserId', familiarUserId);
    if (ignoreMailSending) {
      httpParams = httpParams.append('ignoreMailSending', ignoreMailSending.toString());
    }

    return this.http.get<SenecaResponse<boolean>>(
      this._mediatorUrl + 'confirm-reservation-for-admin', { params: httpParams }
    );
  }


  /**
   * Conferma una prenotazione in uno slot per un avente diritto lato Help Desk
   * @param {string} roundId
   * @param {string} venueId
   * @param {string} slotId
   * @param {string} familiarUserId
   * @return {boolean} Retrieved data
   */
  confirmReservationForHelpDesk(roundId: string, venueId: string, slotId: string,
    familiarUserId: string, ignoreMailSending?: boolean): Observable<SenecaResponse<boolean>> {
    //let httpParams = new HttpParams();
    //
    //httpParams = httpParams.append('roundId', roundId);
    //httpParams = httpParams.append('venueId', venueId);
    //httpParams = httpParams.append('slotId', slotId);
    //httpParams = httpParams.append('familiarUserId', familiarUserId);
    //if (ignoreMailSending) {
    //  httpParams = httpParams.append('ignoreMailSending', ignoreMailSending.toString());
    //}

    return this.http.post<SenecaResponse<boolean>>(
      this._mediatorUrl + 'confirm-reservation-for-help-desk', {
      roundId: roundId,
      venueId: venueId,
      slotId: slotId,
      familiarUserId: familiarUserId,
      ignoreMailSending: ignoreMailSending
    }
    );
  }

  /**
   * Approva la descrizione di una sede
   * @param {string} requestId
   */
  confirmDescriptionRequestByRequestIdForAdmin(requestId: string): Observable<SenecaResponse<boolean>> {
    return this.http.post<SenecaResponse<boolean>>(
      this._mediatorUrl + 'confirm-description-request-by-requestId-for-admin',
      {
        requestId
      }
    );
  }

  /**
   * Copia gli AD da un round ad un altro
   * @returns
   */
  copyEntitledsFromRound(fromRound: string, toRound: string, groupIds: string[]): Observable<SenecaResponse<boolean>> {
    return this.http.post<SenecaResponse<boolean>>(
      this._mediatorUrl + 'copy-entitleds-from-round',
      {
        fromRound,
        toRound,
        groupIds
      }
    );
  }


  /**
   * Recupera una lista di round attivi
   * @return {number} Retrieved data
   */
  countActiveRoundsForAdmin(): Observable<SenecaResponse<number>> {
    return this.http.get<SenecaResponse<number>>(
      this._mediatorUrl + 'count-active-rounds-for-admin'
    );
  }

  /**
   * Recupera una lista di date e slot validi e liberi per essere vaccinato per un avente diritto
   * @param venueId: string;
   * @param roundId: string;
   * @param familiarUserId: string;
   * @param day: Date;
   * @return {DaySlots[]} Retrieved data
   */
  countAvailableDatesToGetVaccinatedForEntitled(venueId: string, roundId: string, familiarUserId: string,
    day: string, forVenueMoving?: boolean): Observable<SenecaResponse<number>> {

    let httpParams = new HttpParams();

    httpParams = httpParams.append('venueId', venueId);
    httpParams = httpParams.append('roundId', roundId);
    httpParams = httpParams.append('familiarUserId', familiarUserId);
    httpParams = httpParams.append('day', day);

    if (forVenueMoving) {
      httpParams = httpParams.append('forVenueMoving', 'true');
    }

    return this.http.get<SenecaResponse<number>>(
      this._mediatorUrl + 'count-available-dates-to-get-vaccinated-for-entitled', { params: httpParams }
    );
  }

  /**
   * Recupera una lista di campagne
   * @param title
   * @return {number} Retrieved data
   */
  countCampaignListForAdmin(title?: string): Observable<SenecaResponse<number>> {
    let httpParams = new HttpParams();

    httpParams = handleTitle(httpParams, title);

    return this.http.get<SenecaResponse<number>>(
      this._mediatorUrl + 'count-campaign-list-for-admin', { params: httpParams }
    );
  }

  //#region Help Desk

  /**
   * Recupera il numero totale di campagne per help desk
   * @param {string} title
   * @return {number} Retrieved data
   */
  countCampaignListForHelpDesk(title?: string): Observable<SenecaResponse<number>> {
    let httpParams = new HttpParams();

    httpParams = handleTitle(httpParams, title);

    return this.http.get<SenecaResponse<number>>(
      this._mediatorUrl + 'count-campaign-list-for-help-desk', { params: httpParams }
    );
  }

  /**
   * Recupera il numero totale di campagne per le campagne
   * @param {string} title
   * @return {number} Retrieved data
   */
  countCampaignListForCustomer(title?: string): Observable<SenecaResponse<number>> {
    let httpParams = new HttpParams();

    httpParams = handleTitle(httpParams, title);

    return this.http.get<SenecaResponse<number>>(
      this._mediatorUrl + 'count-campaign-list-for-customer', { params: httpParams }
    );
  }

  /**
   * Recupera il numero totale di AD legati a una campagna per HD
   * @param {string} campaignId
   * @param {string} searchedText
   * @param {string} codFisc
   * @returns
   */
  countEntitledByCampaignForHelpDesk(campaignId: string, searchedText?: string, codFisc?: string, company?: string): Observable<SenecaResponse<number>> {
    let httpParams = new HttpParams();

    httpParams = httpParams.append('campaignId', campaignId);
    httpParams = handleSearchedText(httpParams, searchedText);

    if (codFisc) {
      httpParams = httpParams.append('codFisc', codFisc);
    }

    if (company) {
      httpParams = httpParams.append('company', company);
    }

    return this.http.get<SenecaResponse<number>>(
      this._mediatorUrl + 'count-entitled-by-campaign-for-help-desk', { params: httpParams }
    );
  }

  /**
   * Recupera il numero totale di AD legati a un round per admin
   * @param roundId
   * @param searchedText
   * @return {number}
   */
  countEntitledListByRoundForAdmin(roundId: string, searchedText?: string, searchedTextCF?: string, searchedTextCompany?: string): Observable<SenecaResponse<number>> {
    let httpParams = new HttpParams();

    httpParams = httpParams.append('roundId', roundId);
    httpParams = handleSearchedText(httpParams, searchedText);

    if (searchedTextCF && searchedTextCF.length) {
      httpParams = httpParams.append('codFisc', searchedTextCF);
    }
    if (searchedTextCompany && searchedTextCompany.length) {
      httpParams = httpParams.append('company', searchedTextCompany);
    }

    return this.http.get<SenecaResponse<number>>(
      this._mediatorUrl + 'count-entitled-list-by-round-for-admin', { params: httpParams }
    );
  }

  /**
   * Recupera il numero totale di AD legati a un round filtrati per Clienti per admin
   * @param fromRound
   * @param toRound
   * @param groupIds
   * @return {number}
   */
  countEntitledListByRoundClientsForAdmin(fromRound: string, toRound: string, groupIds: string[]): Observable<SenecaResponse<number>> {
    let httpParams = new HttpParams();

    httpParams = httpParams.append('fromRound', fromRound);
    httpParams = httpParams.append('toRound', toRound);
    httpParams = handleCustomList(httpParams, 'groupIds', groupIds);

    return this.http.get<SenecaResponse<number>>(
      this._mediatorUrl + 'count-entitled-list-by-round-clients-for-admin', { params: httpParams }
    );
  }

  /**
   * Recupera il numero totale di AD legati a un round per clienti
   * @param roundId
   * @param searchedText
   * @return {number}
   */
  countEntitledListByRoundForCustomer(roundId: string, searchedText?: string): Observable<SenecaResponse<number>> {
    let httpParams = new HttpParams();

    httpParams = httpParams.append('roundId', roundId);
    httpParams = handleSearchedText(httpParams, searchedText);

    return this.http.get<SenecaResponse<number>>(
      this._mediatorUrl + 'count-entitled-list-by-round-for-customer', { params: httpParams }
    );
  }

  /**
   * Recupera il numero totale di AD scarti legati a un round per clienti
   * @param roundId
   * @param searchedText
   * @return {number}
   */
  countEntitledListByRoundForCustomerScraps(roundId: string, searchedText?: string): Observable<SenecaResponse<number>> {
    let httpParams = new HttpParams();

    httpParams = httpParams.append('roundId', roundId);
    httpParams = handleSearchedText(httpParams, searchedText);

    return this.http.get<SenecaResponse<number>>(
      this._mediatorUrl + 'count-client-import-log', { params: httpParams }
    );
  }

  /**
   * Recupera il numero totale di AD legati a una campagna e gruppo per admin
   * @param campaignId
   * @param groupId
   * @param searchedText
   * @return {number}
   */
  countEntitledByCustomersForAdmin(campaignId: string, groupId: string, searchedText?: string): Observable<SenecaResponse<number>> {
    let httpParams = new HttpParams();

    httpParams = httpParams.append('campaignId', campaignId);
    httpParams = httpParams.append('groupId', groupId);
    httpParams = handleSearchedText(httpParams, searchedText);

    return this.http.get<SenecaResponse<number>>(
      this._mediatorUrl + 'count-entitled-by-customers-for-admin', { params: httpParams }
    );
  }

  /**
   * Recupera il numero totale dei round per campagna legata agli admin
   * @param campaignId
   * @return { { attendanceCount: number, roundCount: number } }
   */
  countRoundByCampaignForAdmin(campaignId: string): Observable<SenecaResponse<{ attendanceCount: number, roundCount: number }>> {
    let httpParams = new HttpParams();

    httpParams = httpParams.append('campaignId', campaignId);

    return this.http.get<SenecaResponse<{ attendanceCount: number, roundCount: number }>>(
      this._mediatorUrl + 'count-round-by-campaign-for-admin', { params: httpParams }
    );
  }

  /**
   * Recupera il numero totale dei round per campagna legata ai clienti
   * @param campaignId
   * @return { { attendanceCount: number, roundCount: number } }
   */
  countRoundByCampaignForCustomer(campaignId?: string): Observable<SenecaResponse<{ attendanceCount: number, roundCount: number }>> {
    let httpParams = new HttpParams();

    if (campaignId) {
      httpParams = httpParams.append('campaignId', campaignId);
    }

    return this.http.get<SenecaResponse<{ attendanceCount: number, roundCount: number }>>(
      this._mediatorUrl + 'count-round-by-campaign-for-customer', { params: httpParams }
    );
  }

  /**
   * Recupera il numero di slot per lo spostamento di una prenotazione
   * @param {string} attendanceId
   * @param {string} latitude
   * @param {string} longitude
   * @param {number} distanceKm
   * @param {Date} slotStartDate
   * @param {Date} slotEndDate
   * @param {string} preMatchedVenueId
   * @returns
   */
  countSlotsForReservationMoving(attendanceId: string, latitude: string, longitude: string, distanceKm: string,
    slotStartDate: Date, slotEndDate: Date, preMatchedVenueId?: string): Observable<SenecaResponse<number>> {
    let httpParams = new HttpParams();

    httpParams = httpParams.append('attendanceId', attendanceId);
    httpParams = httpParams.append('latitude', latitude);
    httpParams = httpParams.append('longitude', longitude);
    httpParams = httpParams.append('distanceKm', distanceKm);

    if (preMatchedVenueId) {
      httpParams = httpParams.append('preMatchedVenueId', preMatchedVenueId);
    }

    httpParams = httpParams.append('slotStartDate', new Date(slotStartDate).toISOString());
    httpParams = httpParams.append('slotEndDate', new Date(slotEndDate).toISOString());

    return this.http.get<SenecaResponse<number>>(
      this._mediatorUrl + 'count-slots-for-reservation-moving', { params: httpParams }
    );
  }

  /**
   * Recupera una lista di campagne
   * @param {number} fromRecord
   * @param {number} numRecords
   * @param {string} title
   * @return {GetCampaignListForHelpDeskResponse[]} Retrieved data
   */
  getCampaignListForHelpDesk(fromRecord: number, numRecords: number, title?: string): Observable<SenecaResponse<GetCampaignListForHelpDeskResponse[]>> {
    let httpParams = new HttpParams();

    httpParams = handleFromRecord(httpParams, fromRecord);
    httpParams = handleNumRecords(httpParams, numRecords);
    httpParams = handleTitle(httpParams, title);

    return this.http.get<SenecaResponse<GetCampaignListForHelpDeskResponse[]>>(
      this._mediatorUrl + 'get-campaign-list-for-help-desk', { params: httpParams }
    );
  }

  /**
   * Recupera una lista di campagne
   * @param {number} fromRecord
   * @param {number} numRecords
   * @param {string} title
   * @return {InfoCampaign[]} Retrieved data
   */
  getCampaignListForCustomer(fromRecord: number, numRecords: number, title?: string): Observable<SenecaResponse<InfoCampaign[]>> {
    let httpParams = new HttpParams();

    httpParams = handleFromRecord(httpParams, fromRecord);
    httpParams = handleNumRecords(httpParams, numRecords);
    httpParams = handleTitle(httpParams, title);

    return this.http.get<SenecaResponse<InfoCampaign[]>>(
      this._mediatorUrl + 'get-campaign-list-for-customer', { params: httpParams }
    );
  }

  //#endregion Help Desk

  /**
   * Recupera il numero di sedi di un round
   * @param {string[]} roundId
   * @param {string} searchedText
   * @param {string[]} ignoreVenueForRoundId Ignora gli id dei round passati
   * @return {number}
   */
  countVenueList(roundId?: string, searchedText?: string
    , ignoreVenueForRoundId?: string | string[]): Observable<SenecaResponse<number>> {
    let httpParams = new HttpParams();

    if (roundId) {
      httpParams = httpParams.append('roundId', roundId);
    }
    httpParams = handleSearchedText(httpParams, searchedText);
    httpParams = handleCustomList(httpParams, 'ignoreVenueForRoundId', ignoreVenueForRoundId);

    return this.http.get<SenecaResponse<number>>(
      this._mediatorUrl + 'count-venue-list', { params: httpParams }
    );
  }

  /**
   * Creazione di una campagna lato admin
   * @param {string} campaignTitle Titolo campagna
   * @returns
   */
  createCampaignForAdmin(campaignTitle: string): Observable<SenecaResponse<boolean>> {
    return this.http.post<SenecaResponse<boolean>>(
      this._mediatorUrl + 'create-campaign-for-admin', {
      campaignTitle
    });
  }

  /**
   * Creazione di un round lato admin
   * @param roundTitle
   * @param campaignId
   * @param minReservationEndDate
   * @param maxReservationEndDate
   * @param vaccinationStartDate
   * @param vaccinationEndDate
   * @returns
   */
  createRoundForAdmin(roundTitle: string, campaignId: string, minReservationEnd: Date, maxReservationEnd: Date,
    vaccinationStart: Date, vaccinationEnd: Date, maxAllowedReservationDeletionDate?: number,
    maxAllowedReservationMovingDate?: number, maxAllowedReservationMovingDateOtherVenue?: number): Observable<SenecaResponse<boolean>> {

    const minReservationEndDate: string = minReservationEnd.toISOString();
    const maxReservationEndDate: string = maxReservationEnd.toISOString();
    const vaccinationStartDate: string = vaccinationStart.toISOString();
    const vaccinationEndDate: string = vaccinationEnd.toISOString();

    return this.http.post<SenecaResponse<boolean>>(
      this._mediatorUrl + 'create-round-for-admin', {
      roundTitle,
      campaignId,
      minReservationEndDate,
      maxReservationEndDate,
      vaccinationStartDate,
      vaccinationEndDate,
      maxAllowedReservationDeletionDate,
      maxAllowedReservationMovingDate,
      maxAllowedReservationMovingDateOtherVenue
    });
  }

  /**
   * Creazione di slots per round di una sede
   * @param {string} roundId
   * @param {string} venueId
   * @param {SlotInfoDate[]} dateSlots
   * @return {boolean}
   */
  createSlots(roundId: string, venueId: string, dateSlots: SlotInfoDate[]): Observable<SenecaResponse<boolean>> {
    return this.http.post<SenecaResponse<boolean>>(
      this._mediatorUrl + 'create-slots', {
      roundId,
      venueId,
      dateSlots
    });
  }

  /**
   * Elimina una campagna lato admin
   * @param campaignId
   * @returns
   */
  deleteCampaignForAdmin(campaignId: string): Observable<SenecaResponse<boolean>> {
    return this.http.post<SenecaResponse<boolean>>(
      this._mediatorUrl + 'delete-campaign-for-admin', {
      campaignId
    }
    );
  }

  /**
   * Annullamento di una prenotazione per un avente diritto
   * @param {string} attendanceId
   * @return {boolean} Retrieved data
   */
  deleteReservationForEntitled(attendanceId: string | string[]): Observable<SenecaResponse<boolean>> {
    let httpParams = new HttpParams();
    httpParams = handleCustomList(httpParams, 'attendanceId', attendanceId);

    return this.http.get<SenecaResponse<boolean>>(
      this._mediatorUrl + 'delete-reservation-for-entitled', { params: httpParams }
    );
  }

  /**
   * Annullamento di una prenotazione per un avente diritto
   * @param {string} attendanceId
   * @return {boolean} Retrieved data
   */
  deleteReservationForAdmin(attendanceId: string, forReservationMoving?: boolean): Observable<SenecaResponse<boolean>> {
    let httpParams = new HttpParams();
    httpParams = httpParams.append('attendanceId', attendanceId);
    if (forReservationMoving) {
      httpParams = httpParams.append('forReservationMoving', "true");
    }

    return this.http.get<SenecaResponse<boolean>>(
      this._mediatorUrl + 'delete-reservation-for-admin', { params: httpParams }
    );
  }

  /**
   * Elimina il round di una campagna
   * @param roundId
   * @returns
   */
  deleteRoundForAdmin(roundId: string[]): Observable<SenecaResponse<boolean>> {
    return this.http.post<SenecaResponse<boolean>>(
      this._mediatorUrl + 'delete-round-for-admin', {
      roundId
    }
    );
  }

  /**
   * Cancella gli AD non lavorati per admin
   * @param {string} roundId
   * @return {boolean} Retrieved data
   */
  deleteUnprocessedEntitledForAdmin(roundId: string): Observable<SenecaResponse<boolean>> {
    let httpParams = new HttpParams();
    httpParams = httpParams.append('roundId', roundId);

    return this.http.get<SenecaResponse<boolean>>(
      this._mediatorUrl + 'delete-unprocessed-entitled-for-admin', { params: httpParams }
    );
  }

  /**
   * Cancella gli AD non lavorati per clienti
   * @param {string} roundId
   * @return {boolean} Retrieved data
   */
  deleteUnprocessedEntitledForCustomer(roundId: string): Observable<SenecaResponse<boolean>> {
    let httpParams = new HttpParams();
    httpParams = httpParams.append('roundId', roundId);

    return this.http.get<SenecaResponse<boolean>>(
      this._mediatorUrl + 'delete-unprocessed-entitled-for-customer', { params: httpParams }
    );
  }

  /**
   * Cancella una sede
   * Valido solo se non ci sono prenotazioni
   * @param {string} venueItemId
   * @return {boolean}
   */
  deleteVenueItemById(venueItemId: string): Observable<SenecaResponse<boolean>> {
    return this.http.post<SenecaResponse<boolean>>(
      this._mediatorUrl + 'delete-venue-item-by-id',
      {
        venueItemId
      }
    );
  }

  /**
   * Rifiuta la descrizione di una sede
   * @param {string} requestId
   */
  denyDescriptionRequestByRequestIdForAdmin(requestId: string): Observable<SenecaResponse<boolean>> {
    return this.http.post<SenecaResponse<boolean>>(
      this._mediatorUrl + 'deny-description-request-by-requestId-for-admin',
      {
        requestId
      }
    );
  }

  /**
   * Scarica il link per il report di un round specifico
   * @param roundId
   * @return {string}
   */
  exportRoundInfo(roundId: string): Observable<SenecaResponse<string>> {
    let httpParams = new HttpParams();
    httpParams = httpParams.append('roundId', roundId);

    return this.http.get<SenecaResponse<string>>(
      this._mediatorUrl + 'export-round-info', { params: httpParams }
    );
  }

  /**
   * Scarica il link per il report dei round legato ad una campagna
   * @param campaignId
   * @return {string}
   */
  exportRoundListByCampaignForCustomer(campaignId: string): Observable<SenecaResponse<string>> {
    let httpParams = new HttpParams();
    httpParams = httpParams.append('campaignId', campaignId);

    return this.http.get<SenecaResponse<string>>(
      this._mediatorUrl + 'export-round-list-by-campaign-for-customer', { params: httpParams }
    );
  }

  /**
   * Scarica il link per il report delle strutture sanitarie
   * @return {string}
   */
  exportHealthFacilitySupplierForAdmin(): Observable<SenecaResponse<string>> {
    return this.http.get<SenecaResponse<string>>(
      this._mediatorUrl + 'export-health-facility-supplier-for-admin'
    );
  }

  /**
   * Recupera il round attivo e il prossimo round
   * @param {string[]} venueIds
   * @return {CampaignManagerGetActiveAndNextRoundResponse} Retrieved data
   */
  getActiveAndNextRound(venueIds?: string | string[]): Observable<SenecaResponse<CampaignManagerGetActiveAndNextRoundResponse>> {
    let httpParams = new HttpParams();
    httpParams = handleVanueIds(httpParams, venueIds);

    return this.http.get<SenecaResponse<CampaignManagerGetActiveAndNextRoundResponse>>(
      this._mediatorUrl + 'get-active-and-next-round', { params: httpParams }
    );
  }

  /**
   * Recupera una lista di round attivi
   * @param {number} fromRecord
   * @param {number} numRecords
   * @return {InfoRound[]} Retrieved data
   */
  getActiveRoundsForAdmin(fromRecord: number, numRecords: number): Observable<SenecaResponse<InfoRound[]>> {
    let httpParams = new HttpParams();

    httpParams = handleFromRecord(httpParams, fromRecord);
    httpParams = handleNumRecords(httpParams, numRecords);

    return this.http.get<SenecaResponse<InfoRound[]>>(
      this._mediatorUrl + 'get-active-rounds-for-admin', { params: httpParams }
    );
  }

  /**
   * Servizio per recuperare i dati degli slots di tutte le campagne visibili per admin
   * @return {GetAllCampaignsSlotsDataAdmin} Retrieved data
   */
  getAllCampaignsSlotsDataForAdmin(): Observable<SenecaResponse<GetAllCampaignsSlotsDataAdmin>> {
    return this.http.get<SenecaResponse<GetAllCampaignsSlotsDataAdmin>>(
      this._mediatorUrl + 'get-all-campaigns-slots-data-for-admin'
    );
  }

  /**
   * Servizio per recuperare i dati degli slots di tutte le campagne visibili
   * @param {string[]} venueIds
   * @return {GetAllCampaignsSlotsData} Retrieved data
   */
  getAllCampaignsSlotsData(venueIds?: string | string[]): Observable<SenecaResponse<GetAllCampaignsSlotsData>> {
    let httpParams = new HttpParams();
    httpParams = handleVanueIds(httpParams, venueIds);

    return this.http.get<SenecaResponse<GetAllCampaignsSlotsData>>(
      this._mediatorUrl + 'get-all-campaigns-slots-data', { params: httpParams }
    );
  }

  /**
   * Recupera una lista di persone di un round
   * @param roundId Round id
   * @param venueId Vanue id
   * @param day Giorno di riferimento
   * @param fromRecord
   * @param numRecords
   * @return {DayAttendances[]} Retrieved data
   */
  getAttendanceList(roundId: string, venueId: string, day?: Date,
    fromRecord?: number, numRecords?: number): Observable<SenecaResponse<DayAttendances[]>> {
    let httpParams = new HttpParams();
    httpParams = handleFromRecord(httpParams, fromRecord);
    httpParams = handleNumRecords(httpParams, numRecords);

    httpParams = httpParams.append('roundId', roundId);
    httpParams = httpParams.append('venueId', venueId);
    httpParams = handleDay(httpParams, day);
    return this.http.get<SenecaResponse<DayAttendances[]>>(
      this._mediatorUrl + 'get-attendance-list', { params: httpParams }
    );
  }

  /**
   * Recupera le prenotazioni legate ad un utente su un round
   * @param {string} campaignId Campaign id
   * @param {string} roundId Round id
   * @param {string} userId Id dell'utente
   * @return {InfoAttendance[]} Retrieved data
   */
  getAttendancesForAdmin(campaignId: string, roundId: string, userId: string): Observable<SenecaResponse<InfoAttendance[]>> {
    let httpParams = new HttpParams();

    httpParams = httpParams.append('campaignId', campaignId);
    httpParams = httpParams.append('roundId', roundId);
    httpParams = httpParams.append('userId', userId);

    return this.http.get<SenecaResponse<InfoAttendance[]>>(
      this._mediatorUrl + 'get-attendances-for-admin', { params: httpParams }
    );
  }

  /**
   * Recupera le prenotazioni legate ad un utente su un round
   * @param {string} campaignId Campaign id
   * @param {string} userId Id dell'utente
   * @return {InfoAttendance[]} Retrieved data
   */
  getAttendancesForHelpDesk(campaignId: string, userId: string, ignoreStatus?: boolean, ignoreSlotFilter?: boolean, doNotRetrieveFamily?: boolean): Observable<SenecaResponse<InfoAttendance[]>> {
    let httpParams = new HttpParams();

    httpParams = httpParams.append('campaignId', campaignId);
    httpParams = httpParams.append('userId', userId);
    if (ignoreStatus) {
      httpParams = httpParams.append('ignoreStatus', "true");
    }
    if (ignoreSlotFilter) {
      httpParams = httpParams.append('ignoreSlotFilter', "true");
    }
    if (doNotRetrieveFamily) {
      httpParams = httpParams.append('doNotRetrieveFamily', "true");
    }

    return this.http.get<SenecaResponse<InfoAttendance[]>>(
      this._mediatorUrl + 'get-attendances-for-help-desk', { params: httpParams }
    );
  }

  /**
   * Recupera una lista di campagne
   * @param fromRecord
   * @param numRecords
   * @param title
   * @param campaignId Filtro per campaignId
   * @return {GetCampaignListResponse[]} Retrieved data
   */
  getCampaignListForAdmin(fromRecord?: number, numRecords?: number, title?: string, campaignId?: string): Observable<SenecaResponse<GetCampaignListForHelpDeskResponse[]>> {
    let httpParams = new HttpParams();
    httpParams = handleFromRecord(httpParams, fromRecord);
    httpParams = handleNumRecords(httpParams, numRecords);

    httpParams = handleTitle(httpParams, title);

    if (campaignId) {
      httpParams = httpParams.append('campaignId', campaignId);
    }

    return this.http.get<SenecaResponse<GetCampaignListForHelpDeskResponse[]>>(
      this._mediatorUrl + 'get-campaign-list-for-admin', { params: httpParams }
    );
  }

  /**
   * Recupera una lista di campagne
   * @return {InfoCampaign[]} Retrieved data
   */
  getCampaignListForEntitled(): Observable<SenecaResponse<InfoCampaign[]>> {
    return this.http.get<SenecaResponse<InfoCampaign[]>>(
      this._mediatorUrl + 'get-campaign-list-for-entitled'
    );
  }

  /**
   * Recupera una lista di campagne
   * @param {string[]} venueIds
   * @return {InfoCampaign[]} Retrieved data
   */
  getCampaignList(venueIds?: string | string[]): Observable<SenecaResponse<InfoCampaign[]>> {
    let httpParams = new HttpParams();
    httpParams = handleVanueIds(httpParams, venueIds);

    return this.http.get<SenecaResponse<InfoCampaign[]>>(
      this._mediatorUrl + 'get-campaign-list', { params: httpParams }
    );
  }

  /**
   * Recupera un campagna
   * @param {string} campaignId
   * @param {string[]} venueIds
   * @return {InfoCampaign} Campagna
   */
  getCampaign(campaignId: string, venueIds?: string | string[]): Observable<SenecaResponse<InfoCampaign>> {
    let httpParams = new HttpParams();
    httpParams = httpParams.append('campaignId', campaignId);
    httpParams = handleVanueIds(httpParams, venueIds);

    return this.http.get<SenecaResponse<InfoCampaign>>(
      this._mediatorUrl + 'get-campaign', { params: httpParams }
    );
  }

  /**
   * Recupera una lista di giornate legate ai round con il numero di prenotazioni da lavorare
   * @param roundId Round id
   * @param venueId Venue id
   * @return {AttendanceDayMenu[]} Retrieved data
   */
  getDatesWithToBeProcessedNumber(roundId: string, venueId: string): Observable<SenecaResponse<AttendanceDayMenu[]>> {
    let httpParams = new HttpParams();

    httpParams = httpParams.append('roundId', roundId);
    httpParams = httpParams.append('venueId', venueId);
    return this.http.get<SenecaResponse<AttendanceDayMenu[]>>(
      this._mediatorUrl + 'get-dates-with-to-be-processed-number', { params: httpParams }
    );
  }

  /**
   * Recupera la lista di AD legati ad una campagna per HD
   * @param {string} campaignId
   * @param {number} fromRecord
   * @param {number} numRecords
   * @param {string} searchedText
   * @param {string} codFisc
   * @returns
   */
  getEntitledByCampaignForHelpDesk(campaignId: string, fromRecord: number,
    numRecords: number, searchedText?: string, codFisc?: string, company?: string): Observable<SenecaResponse<EntitledByCampaignResponse[]>> {
    let httpParams = new HttpParams();

    httpParams = httpParams.append('campaignId', campaignId);
    httpParams = handleFromRecord(httpParams, fromRecord);
    httpParams = handleNumRecords(httpParams, numRecords);
    httpParams = handleSearchedText(httpParams, searchedText);

    if (codFisc) {
      httpParams = httpParams.append('codFisc', codFisc);
    }

    if (company) {
      httpParams = httpParams.append('company', company);
    }

    return this.http.get<SenecaResponse<EntitledByCampaignResponse[]>>(
      this._mediatorUrl + 'get-entitled-by-campaign-for-help-desk', { params: httpParams }
    );
  }

  /**
   * Recupera la lista di AD legati ad un round per admin
   * @param {string} campaignId
   * @param fromRecord
   * @param numRecords
   * @param searchedText
   * @return {InfoAttendance[]}
   */
  getEntitledListByRoundForAdmin(roundId: string, fromRecord: number,
    numRecords: number, searchedText?: string, searchedTextCF?: string, searchedTextCompany?: string, attendanceId?: string): Observable<SenecaResponse<InfoAttendance[]>> {
    let httpParams = new HttpParams();

    httpParams = httpParams.append('roundId', roundId);
    httpParams = handleFromRecord(httpParams, fromRecord);
    httpParams = handleNumRecords(httpParams, numRecords);
    httpParams = handleSearchedText(httpParams, searchedText);

    if (searchedTextCF && searchedTextCF.length) {
      httpParams = httpParams.append('codFisc', searchedTextCF);
    }
    if (searchedTextCompany && searchedTextCompany.length) {
      httpParams = httpParams.append('company', searchedTextCompany);
    }
    if (attendanceId && attendanceId.length) {
      httpParams = httpParams.append('attendanceId', attendanceId);
    }

    return this.http.get<SenecaResponse<InfoAttendance[]>>(
      this._mediatorUrl + 'get-entitled-list-by-round-for-admin', { params: httpParams }
    );
  }

  /**
   * Recupera la lista di AD legati ad un round per admin
   * @param {string} campaignId
   * @param fromRecord
   * @param numRecords
   * @param searchedText
   * @return {InfoAttendance[]}
   */
  getEntitledListByRoundForHelpDesk(roundId: string, fromRecord: number,
    numRecords: number, searchedText?: string, searchedTextCF?: string, searchedTextCompany?: string, attendanceId?: string): Observable<SenecaResponse<InfoAttendance[]>> {
    let httpParams = new HttpParams();

    httpParams = httpParams.append('roundId', roundId);
    httpParams = handleFromRecord(httpParams, fromRecord);
    httpParams = handleNumRecords(httpParams, numRecords);
    httpParams = handleSearchedText(httpParams, searchedText);

    if (searchedTextCF && searchedTextCF.length) {
      httpParams = httpParams.append('codFisc', searchedTextCF);
    }
    if (searchedTextCompany && searchedTextCompany.length) {
      httpParams = httpParams.append('company', searchedTextCompany);
    }
    if (attendanceId && attendanceId.length) {
      httpParams = httpParams.append('attendanceId', attendanceId);
    }

    return this.http.get<SenecaResponse<InfoAttendance[]>>(
      this._mediatorUrl + 'get-entitled-list-by-round-for-help-desk', { params: httpParams }
    );
  }

  /**
   * Recupera la lista di AD legati ad un round per clienti
   * @param {string} campaignId
   * @param fromRecord
   * @param numRecords
   * @param searchedText
   * @return {InfoAttendance[]}
   */
  getEntitledListByRoundForCustomer(roundId: string, fromRecord: number,
    numRecords: number, searchedText?: string): Observable<SenecaResponse<InfoAttendance[]>> {
    let httpParams = new HttpParams();

    httpParams = httpParams.append('roundId', roundId);
    httpParams = handleFromRecord(httpParams, fromRecord);
    httpParams = handleNumRecords(httpParams, numRecords);
    httpParams = handleSearchedText(httpParams, searchedText);

    return this.http.get<SenecaResponse<InfoAttendance[]>>(
      this._mediatorUrl + 'get-entitled-list-by-round-for-customer', { params: httpParams }
    );
  }

  /**
   * Recupera la lista di AD scarti legati ad un round per clienti
   * @param {string} campaignId
   * @param fromRecord
   * @param numRecords
   * @param searchedText
   * @return {InfoAttendance[]}
   */
  getEntitledListByRoundForCustomerScraps(roundId: string, fromRecord: number,
    numRecords: number, searchedText?: string): Observable<SenecaResponse<any[]>> {
    let httpParams = new HttpParams();

    httpParams = httpParams.append('roundId', roundId);
    httpParams = handleFromRecord(httpParams, fromRecord);
    httpParams = handleNumRecords(httpParams, numRecords);
    httpParams = handleSearchedText(httpParams, searchedText);

    return this.http.get<SenecaResponse<any[]>>(
      this._mediatorUrl + 'get-client-import-log', { params: httpParams }
    );
  }

  /**
   * Recupera i dati delle prenotazioni per un avente diritto
   * @return {GetReservationEntitledDataResponse} Retrieved data
   */
  getReservationEntitledData(): Observable<SenecaResponse<GetReservationEntitledDataResponse>> {
    return this.http.get<SenecaResponse<GetReservationEntitledDataResponse>>(
      this._mediatorUrl + 'get-reservation-entitled-data'
    );
  }

  /**
 * Recupera la lista di round legati ad una campagna per admin
 * @param {string} campaignId
 * @param fromRecord
 * @param numRecords
 * @param searchedText
 * @return {InfoRound[]}
 */
  getRoundListByCampaignForAdmin(campaignId: string, fromRecord: number,
    numRecords: number): Observable<SenecaResponse<InfoRound[]>> {
    let httpParams = new HttpParams();
    if (campaignId) {
      httpParams = httpParams.append('campaignId', campaignId);
    }
    httpParams = handleFromRecord(httpParams, fromRecord);
    httpParams = handleNumRecords(httpParams, numRecords);

    return this.http.get<SenecaResponse<InfoRound[]>>(
      this._mediatorUrl + 'get-round-list-by-campaign-for-admin', { params: httpParams }
    );
  }

  /**
   * Recupera la lista di round legati ad una campagna per clienti
   * @param {string} campaignId
   * @param fromRecord
   * @param numRecords
   * @return {InfoRound[]}
   */
  getRoundListByCampaignForCustomer(fromRecord: number,
    numRecords: number, campaignId?: string, excludeRoundsWithoutEntitled?: boolean): Observable<SenecaResponse<InfoRound[]>> {
    let httpParams = new HttpParams();

    if (campaignId) {
      httpParams = httpParams.append('campaignId', campaignId);
    }
    httpParams = handleFromRecord(httpParams, fromRecord);
    httpParams = handleNumRecords(httpParams, numRecords);

    if (excludeRoundsWithoutEntitled) {
      httpParams = httpParams.append("excludeRoundsWithoutEntitled", "true");
    }

    return this.http.get<SenecaResponse<InfoRound[]>>(
      this._mediatorUrl + 'get-round-list-by-campaign-for-customer', { params: httpParams }
    );
  }

  /**
   * Recupera un round
   * @param {string} roundId Round id
   * @returns {InfoRound} Round
   */
  getRound(roundId: string): Observable<SenecaResponse<InfoRound>> {
    let httpParams = new HttpParams();
    httpParams = httpParams.append('roundId', roundId);
    return this.http.get<SenecaResponse<InfoRound>>(
      this._mediatorUrl + 'get-round', { params: httpParams }
    );
  }

  /**
   * Recupera una lista di slot per lo spostamento di una prenotazione
   * @param {string} attendanceId
   * @param {number} fromRecord
   * @param {number} numRecords
   * @param {string} latitude
   * @param {string} longitude
   * @param {number} distanceKm
   * @param {Date} slotStartDate
   * @param {Date} slotEndDate
   * @param {string} preMatchedVenueId
   * @returns
   */
  getSlotsForReservationMoving(attendanceId: string, fromRecord: number, numRecords: number, latitude: string, longitude: string,
    distanceKm: string, slotStartDate: Date, slotEndDate: Date, preMatchedVenueId?: string, orderBy?: string[]): Observable<SenecaResponse<InfoVenueSlot[]>> {
    let httpParams = new HttpParams();

    httpParams = handleFromRecord(httpParams, fromRecord);
    httpParams = handleNumRecords(httpParams, numRecords);

    httpParams = httpParams.append('attendanceId', attendanceId);
    httpParams = httpParams.append('distanceKm', distanceKm);
    httpParams = httpParams.append('latitude', latitude);
    httpParams = httpParams.append('longitude', longitude);

    if (preMatchedVenueId) {
      httpParams = httpParams.append('preMatchedVenueId', preMatchedVenueId);
    }

    httpParams = httpParams.append('slotStartDate', new Date(slotStartDate).toISOString());
    httpParams = httpParams.append('slotEndDate', new Date(slotEndDate).toISOString());

    if (orderBy && orderBy.length) {
      for (let i = 0; i < orderBy.length; i++) {
        httpParams = httpParams.append('orderBy', orderBy[i]);
      }
    }

    return this.http.get<SenecaResponse<any[]>>(
      this._mediatorUrl + 'get-slots-for-reservation-moving', { params: httpParams }
    );
  }

  /**
   * Recupera una serie di slots validi (non occupati e approvati) per l'assegnazione di una persona
   * @param {string} roundId
   * @param {string} venueId
   * @param {Date} day
   * @param {number} numRecords
   * @param {number} fromRecord
   * @return {GetValidSlotsForCreateAttendeeResponse[]} Retrieved data
   */
  getValidSlotsForCreateAttendee(venueId: string, roundId: string,
    day?: Date, fromRecord?: number, numRecords?: number): Observable<SenecaResponse<GetValidSlotsForCreateAttendeeResponse[]>> {

    let httpParams = new HttpParams();
    httpParams = httpParams.append('venueId', venueId);
    httpParams = httpParams.append('roundId', roundId);
    httpParams = handleFromRecord(httpParams, fromRecord);
    httpParams = handleNumRecords(httpParams, numRecords);
    httpParams = handleDay(httpParams, day);

    return this.http.get<SenecaResponse<GetValidSlotsForCreateAttendeeResponse[]>>(
      this._mediatorUrl + 'get-valid-slots-for-create-attendee', { params: httpParams }
    );
  }

  /**
   * Recupera una sede
   * @param {string} venueId
   * @return {InfoVenue} Retrieved data
   */
  getVenueById(venueId: string, retrieveCounts?: boolean, roundId?: string, day?: string): Observable<SenecaResponse<InfoVenue>> {
    let httpParams = new HttpParams();

    if (venueId) {
      httpParams = httpParams.append('venueId', venueId);
    }

    if (retrieveCounts) {
      httpParams = httpParams.append('retrieveCounts', 'true');
    }

    if (roundId) {
      httpParams = httpParams.append('roundId', roundId);
    }

    if (day) {
      httpParams = httpParams.append('day', day);
    }

    return this.http.get<SenecaResponse<InfoVenue>>(
      this._mediatorUrl + 'get-venue-by-id', { params: httpParams }
    );
  }

  /**
   * Recupera una sede - admin service
   * @param {string} venueItemId
   * @return {InfoVenue} Retrieved data
   */
  getVenueItemById(venueItemId: string): Observable<SenecaResponse<InfoVenue>> {
    let httpParams = new HttpParams();

    httpParams = httpParams.append('venueItemId', venueItemId);

    return this.http.get<SenecaResponse<InfoVenue>>(
      this._mediatorUrl + 'get-venue-item-by-id', { params: httpParams }
    );
  }

  /**
   * Recupera una lista di strutture di un round per raggio e indirizzo
   * @param {string} locationFullAddress
   * @param {number} distanceKm
   * @param {number} fromRecord
   * @param {number} numRecords
   * @param {string} attendanceId
   * @param {string} searchedText
   * @param {Date} slotStartDate
   * @param {Date} slotEndDate
   * @return {InfoVenue[]} Retrieved data
   */
  getVenueListForEntitledBooking(locationFullAddress: string, distanceKm: string, attendanceId: string | string[],
    fromRecord?: number, numRecords?: number, searchedText?: string, slotStartDate?: Date,
    slotEndDate?: Date, latitude?: string, longitude?: string, forVenueMoving?: boolean): Observable<SenecaResponse<InfoVenue[]>> {
    let httpParams = new HttpParams();

    httpParams = httpParams.append('locationFullAddress', locationFullAddress);

    if (latitude && longitude) {
      httpParams = httpParams.append('latitude', latitude);
      httpParams = httpParams.append('longitude', longitude);
    }

    httpParams = httpParams.append('distanceKm', distanceKm);
    // httpParams = httpParams.append('attendanceId', );
    httpParams = handleCustomList(httpParams, 'attendanceId', attendanceId);

    httpParams = handleFromRecord(httpParams, fromRecord);
    httpParams = handleNumRecords(httpParams, numRecords);
    if (searchedText) {
      httpParams = httpParams.append('searchedText', searchedText);
    }
    if (slotStartDate) {
      const startDate = new Date(slotStartDate);
      httpParams = httpParams.append('slotStartDate', startDate.toISOString());
    }
    if (slotEndDate) {
      const endDate = new Date(slotEndDate);
      httpParams = httpParams.append('slotEndDate', endDate.toISOString());
    }
    if (forVenueMoving) {
      httpParams = httpParams.append('forVenueMoving', 'true');
    }

    return this.http.get<SenecaResponse<InfoVenue[]>>(
      this._mediatorUrl + 'get-venue-list-for-entitled-booking', { params: httpParams }
    );
  }

  /**
   * Recupera una lista di strutture di un round
   * @param {string[]} roundId
   * @param {number} fromRecord
   * @param {number} numRecords
   * @param {string} searchedText
   * @param {string[]} ignoreVenueForRoundId Ignora gli id dei round passati
   * @param {boolean} onlyPrivateVenue Recupera le sedi dello specifico cliente - solo per clienti
   * @param {OrderByVenue} orderBy
   * @param {boolean} getSaturation Per avere il dato di saturazione
   * @return {any[]} Lista di strutture
   */
  getVenueList(roundId?: string, fromRecord?: number, numRecords?: number,
    searchedText?: string, ignoreVenueForRoundId?: string | string[],
    onlyPrivateVenue?: boolean, orderBy?: OrderByVenue, getSaturation?: boolean): Observable<SenecaResponse<any[]>> {

    let httpParams = new HttpParams();
    httpParams = handleFromRecord(httpParams, fromRecord);
    httpParams = handleNumRecords(httpParams, numRecords);

    if (roundId) {
      httpParams = httpParams.append('roundId', roundId);
    }
    httpParams = handleSearchedText(httpParams, searchedText);
    httpParams = handleCustomList(httpParams, 'ignoreVenueForRoundId', ignoreVenueForRoundId);

    if (onlyPrivateVenue) {
      httpParams = httpParams.append('onlyPrivateVenue', 'true');
    }

    if (getSaturation) {
      httpParams = httpParams.append('getSaturation', 'true');
    }

    if (orderBy) {
      httpParams = httpParams.append('orderBy', orderBy);
    }

    return this.http.get<SenecaResponse<any[]>>(
      this._mediatorUrl + 'get-venue-list', { params: httpParams }
    );
  }

  /**
   * Recupera gli slot divisi per giornate di una struttura di un round
   * @param {string} roundId
   * @param {string} venueId
   * @param {boolean} retrieveSlotOnlyForDates
   * @param {Date} day
   * @param {number} numRecords
   * @param {number} fromRecord
   * @return {DaySlots[]} Retrieved data
   */
  getVenueSlots(venueId: string, roundId: string, retrieveSlotOnlyForDates?: boolean,
    day?: Date, fromRecord?: number, numRecords?: number, status?: string[]): Observable<SenecaResponse<DaySlots[]>> {

    let httpParams = new HttpParams();
    httpParams = httpParams.append('venueId', venueId);
    httpParams = httpParams.append('roundId', roundId);

    if (retrieveSlotOnlyForDates) {
      httpParams = httpParams.append('retrieveSlotOnlyForDates', 'true');
    } else {
      httpParams = handleFromRecord(httpParams, fromRecord);
      httpParams = handleNumRecords(httpParams, numRecords);
      httpParams = handleDay(httpParams, day);

      if (status && status.length) {
        httpParams = handleCustomList(httpParams, 'status', status);
      }
    }
    return this.http.get<SenecaResponse<DaySlots[]>>(
      this._mediatorUrl + 'get-venue-slots', { params: httpParams }
    );
  }

  /**
   * Recupera gli slot divisi per giornate di una struttura di un round
   * @param {string} roundId
   * @param {string} venueId
   * @param {boolean} retrieveSlotOnlyForDates
   * @param {Date} day
   * @param {number} numRecords
   * @param {number} fromRecord
   * @return {DaySlots[]} Retrieved data
   */
  getVenueSlotsForAdmin(venueId: string, roundId: string, retrieveSlotOnlyForDates?: boolean,
    day?: Date, fromRecord?: number, numRecords?: number): Observable<SenecaResponse<DaySlots[]>> {

    let httpParams = new HttpParams();
    httpParams = httpParams.append('venueId', venueId);
    httpParams = httpParams.append('roundId', roundId);

    if (retrieveSlotOnlyForDates) {
      httpParams = httpParams.append('retrieveSlotOnlyForDates', 'true');
    } else {
      httpParams = handleFromRecord(httpParams, fromRecord);
      httpParams = handleNumRecords(httpParams, numRecords);
      httpParams = handleDay(httpParams, day);
    }
    return this.http.get<SenecaResponse<DaySlots[]>>(
      this._mediatorUrl + 'get-venue-slots-for-admin', { params: httpParams }
    );
  }

  /**
   * Recupera una richiesta di approvazione o revoca
   * @param venueId
   * @param requestId
   * @param retrieveSlotOnlyForDates
   * @param day
   * @param fromRecord
   * @param numRecords
   * @returns
   */
  getVenueToProcessByRequestIdForAdmin(venueId: string, requestId: string, retrieveSlotOnlyForDates?: boolean,
    day?: Date, fromRecord?: number, numRecords?: number): Observable<SenecaResponse<DaySlots[]>> {

    let httpParams = new HttpParams();
    httpParams = httpParams.append('venueId', venueId);
    httpParams = httpParams.append('requestId', requestId);

    if (retrieveSlotOnlyForDates) {
      httpParams = httpParams.append('retrieveSlotOnlyForDates', 'true');
    } else {
      httpParams = handleFromRecord(httpParams, fromRecord);
      httpParams = handleNumRecords(httpParams, numRecords);
      httpParams = handleDay(httpParams, day);
    }
    return this.http.get<SenecaResponse<DaySlots[]>>(
      this._mediatorUrl + 'get-venue-to-process-by-requestId-for-admin', { params: httpParams }
    );
  }

  /**
   * Verifica se uno o più utenti hanno già una prenotazione nella stessa campagna
   * @param {string} campaignId
   * @param {string} roundId
   * @param {string[]} userIdsToCheckHasReservationsForSamecampaign
   * @return {HasUsersAlreadyABookingForSameCampaignResponse}
   */
  hasUsersAlreadyABookingForSameCampaign(campaignId: string, roundId: string, userIdsToCheckHasReservationsForSamecampaign: string | string[]): Observable<SenecaResponse<HasUsersAlreadyABookingForSameCampaignResponse>> {

    let httpParams = new HttpParams();
    httpParams = httpParams.append('campaignId', campaignId);
    httpParams = httpParams.append('roundId', roundId);

    httpParams = handleCustomList(httpParams, 'userIdsToCheckHasReservationsForSamecampaign', userIdsToCheckHasReservationsForSamecampaign);

    return this.http.get<SenecaResponse<HasUsersAlreadyABookingForSameCampaignResponse>>(
      this._mediatorUrl + 'has-users-already-a-booking-for-same-campaign', { params: httpParams }
    );
  }

  /**
   * Recupera la lista di AD in base a campagna e gruppo per admin
   * @param {string} campaignId
   * @param {string} groupId
   * @param {number} fromRecord
   * @param {number} numRecords
   * @param {string} searchedText
   * @return {InfoAttendance[]}
   */
  listEntitledByCustomersForAdmin(campaignId: string, groupId: string, fromRecord?: number,
    numRecords?: number, searchedText?: string): Observable<SenecaResponse<InfoAttendance[]>> {

    let httpParams = new HttpParams();
    httpParams = httpParams.append('campaignId', campaignId);
    httpParams = httpParams.append('groupId', groupId);

    httpParams = handleFromRecord(httpParams, fromRecord);
    httpParams = handleNumRecords(httpParams, numRecords);

    httpParams = handleSearchedText(httpParams, searchedText);

    return this.http.get<SenecaResponse<InfoAttendance[]>>(
      this._mediatorUrl + 'list-entitled-by-customers-for-admin', { params: httpParams }
    );
  }

  /**
   * Approva una richiesta di approvazione o revoca slot
   * @param requestId
   * @param confirmSlots Approva
   * @param denySlots Non approva
   * @param slotIds se non passato applica a tutti gli slot
   * @param note nota in caso di rifiuto
   * @return {boolean}
   */
  processSlotsApprovationRequestByRequestIdForAdmin(requestId: string, confirmSlots: boolean, denySlots: boolean,
    slotIds?: string[], note?: string): Observable<SenecaResponse<boolean>> {
    return this.http.post<SenecaResponse<boolean>>(
      this._mediatorUrl + 'process-slots-approvation-request-by-requestId-for-admin',
      {
        requestId,
        confirmSlots,
        denySlots,
        slotIds,
        note
      }
    );
  }

  /**
   * Nega una richiesta di approvazione o revoca slot
   * @param requestId
   * @param confirmSlots Approva
   * @param denySlots Non approva
   * @param slotIds se non passato applica a tutti gli slot
   * @param note nota in caso di rifiuto
   * @return {boolean}
   */
  processSlotsRevocationRequestByRequestIdForAdmin(requestId: string, confirmSlots: boolean, denySlots: boolean,
    slotIds?: string[], note?: string): Observable<SenecaResponse<boolean>> {
    return this.http.post<SenecaResponse<boolean>>(
      this._mediatorUrl + 'process-slots-revocation-request-by-requestId-for-admin',
      {
        requestId,
        confirmSlots,
        denySlots,
        slotIds,
        note
      }
    );
  }

  /**
   * Rimuove associazione sede - round
   * Eliminabile solo se non ci sono utenti prenotati
   * @param {string} roundId
   * @param {string[]} venueId
   * @return {boolean}
   */
  removeVenueAssociationToRoundForAdmin(roundId: string, venueId: string[]): Observable<SenecaResponse<boolean>> {
    return this.http.post<SenecaResponse<boolean>>(
      this._mediatorUrl + 'remove-venue-association-to-round-for-admin',
      {
        roundId,
        venueId
      }
    );
  }

  /**
   * Invia una richiesta all'admin di update descrizione
   * @param {string} venueId
   * @param {string} description
   * @return {InfoVenue}
   */
  requestUpdateVenueDescriptionById(venueId: string, description: string, details: any):
    Observable<SenecaResponse<InfoVenue>> {
    let httpParams = new HttpParams();
    httpParams = httpParams.append('venueId', venueId);
    httpParams = httpParams.append('description', description || "");
    // Dettagli
    httpParams = httpParams.append('HANDICAPPED_ACCESS', details["HANDICAPPED_ACCESS"] == true ? "true" : "false");
    httpParams = httpParams.append('ON_SITE_PARKING', details["ON_SITE_PARKING"] == true ? "true" : "false");
    // httpParams.append('PARKING_NEARBY', details["PARKING_NEARBY"] == true ? "true" : "false");
    httpParams = httpParams.append('ON_STREET_PARKING', details["ON_STREET_PARKING"] == true ? "true" : "false");
    httpParams = httpParams.append('CAR_PARKING', details["CAR_PARKING"] == true ? "true" : "false");
    httpParams = httpParams.append('PARKING_FOR_CYCLES_AND_MOTORCYCLES', details["PARKING_FOR_CYCLES_AND_MOTORCYCLES"] == true ? "true" : "false");
    httpParams = httpParams.append('INFORMATION_AND_RECEPTION_SERVICE', details["INFORMATION_AND_RECEPTION_SERVICE"] == true ? "true" : "false");
    httpParams = httpParams.append('PAID_PARKING', details["PAID_PARKING"] == true ? "true" : "false");
    httpParams = httpParams.append('BAR', details["BAR"] == true ? "true" : "false");
    httpParams = httpParams.append('CAR', details["CAR"] == true ? "true" : "false");
    httpParams = httpParams.append('BICYCLES_AND_MOTORCYCLES', details["BICYCLES_AND_MOTORCYCLES"] == true ? "true" : "false");
    httpParams = httpParams.append('BUS', details["BUS"] == true ? "true" : "false");
    httpParams = httpParams.append('SUBWAY', details["SUBWAY"] == true ? "true" : "false");
    httpParams = httpParams.append('STREETCAR', details["STREETCAR"] == true ? "true" : "false");
    httpParams = httpParams.append('TRAIN', details["TRAIN"] == true ? "true" : "false");
    httpParams = httpParams.append('CAB_STATION', details["CAB_STATION"] == true ? "true" : "false");


    return this.http.get<SenecaResponse<InfoVenue>>(
      this._mediatorUrl + 'request-update-venue-description-by-id', { params: httpParams }
    );
  }

  /**
   * Il reset dei no show resetta lo stato di tutti gli AD che non hanno uno stato già di RESET o di VACCINATO, per i round già conclusi, così permettendo delle nuove prenotazioni per i round attivi.
   * Le prenotazioni "vecchie" quindi rimarranno con l'AD in stato di RESET
   */
  resetNoshowForAdmin(campaignId: string, groupId: string): Observable<SenecaResponse<boolean>> {
    return this.http.post<SenecaResponse<boolean>>(
      this._mediatorUrl + 'reset-noshow-for-admin',
      {
        campaignId,
        groupId
      }
    );
  }

  /**
   * Il reset dei no show resetta lo stato di tutti gli AD che non hanno uno stato già di RESET o di VACCINATO, per i round già conclusi, così permettendo delle nuove prenotazioni per i round attivi.
   * Le prenotazioni "vecchie" quindi rimarranno con l'AD in stato di RESET
   * L'email invia i reminder a queste persone
   */
  emailNoshowForAdmin(campaignId: string, groupId: string): Observable<SenecaResponse<boolean>> {
    return this.http.post<SenecaResponse<boolean>>(
      this._mediatorUrl + 'email-noshow-for-admin',
      {
        campaignId,
        groupId
      }
    );
  }

  /**
   * Revoca di uno o più slots
   * @param slotId
   * @param justification
   * @returns {null}
   */
  revokeSlots(slotId: string | string[], justification: string): Observable<SenecaResponse<null>> {
    let httpParams = new HttpParams();
    httpParams = handleCustomList(httpParams, 'slotId', slotId);
    httpParams = httpParams.append('justification', justification);
    return this.http.get<SenecaResponse<null>>(
      this._mediatorUrl + 'revoke-slots', { params: httpParams }
    );
  }

  /**
   * Cancellazione di uno o più slots
   * @param {string | string[]} slotIds
   * @returns {null}
   */
  deleteSlots(slotIds: string | string[]): Observable<SenecaResponse<boolean>> {
    let httpParams = new HttpParams();
    httpParams = handleCustomList(httpParams, 'slotIds', slotIds);
    return this.http.get<SenecaResponse<boolean>>(
      this._mediatorUrl + 'delete-approvation-request-slots', { params: httpParams }
    );
  }

  /**
   * Recupera una lista di date e slot validi e liberi per essere vaccinato per un avente diritto
   * @param venueId: string;
   * @param roundId: string;
   * @param retrieveSlotOnlyForDates?: boolean;
   * @param fromRecord?: number;
   * @param numRecords?: number;
   * @param familiarUserId: string;
   * @param day?: Date;
   * @param slotStartDate?: Date;
   * @param slotEndDate?: Date;
   * @return {DaySlots[]} Retrieved data
   */
  searchAvailableDatesToGetVaccinatedForEntitled(venueId: string, roundId: string, familiarUserId: string,
    fromRecord?: number, numRecords?: number, retrieveSlotOnlyForDates?: boolean,
    slotStartDate?: string, slotEndDate?: string, day?: string, forVenueMoving?: boolean): Observable<SenecaResponse<DaySlotsFe[]>> {

    let httpParams = new HttpParams();

    httpParams = httpParams.append('venueId', venueId);
    httpParams = httpParams.append('roundId', roundId);
    httpParams = httpParams.append('familiarUserId', familiarUserId);
    if (retrieveSlotOnlyForDates) {
      httpParams = httpParams.append('retrieveSlotOnlyForDates', 'true');
    }

    httpParams = handleFromRecord(httpParams, fromRecord);
    httpParams = handleNumRecords(httpParams, numRecords);

    if (day) {
      httpParams = httpParams.append('day', day);
    }

    if (slotStartDate) {
      httpParams = httpParams.append('slotStartDate', slotStartDate);
    }

    if (slotEndDate) {
      httpParams = httpParams.append('slotEndDate', slotEndDate);
    }

    if (forVenueMoving) {
      httpParams = httpParams.append('forVenueMoving', 'true');
    }

    return this.http.get<SenecaResponse<DaySlotsFe[]>>(
      this._mediatorUrl + 'search-available-dates-to-get-vaccinated-for-entitled', { params: httpParams }
    );
  }

  /**
   * Search a entitled by fiscal code
   * @param {string} roundId
   * @param {string} codFisc
   * @param {string} venueId
   * @return {InfoAttendance} Retrieved data
   */
  searchEntitledByCodfisc(roundId: string, codFisc: string, venueId?: string, searchedText?: string): Observable<SenecaResponse<InfoAttendance>> {

    let httpParams = new HttpParams();

    httpParams = httpParams.append('roundId', roundId);
    httpParams = httpParams.append('codFisc', codFisc);
    if (venueId) {
      httpParams = httpParams.append('venueId', venueId);
    }

    if (searchedText) {
      httpParams = httpParams.append('searchedText', searchedText);
    }

    return this.http.get<SenecaResponse<InfoAttendance>>(
      this._mediatorUrl + 'search-entitled-by-codfisc', { params: httpParams }
    );
  }

  /**
   * Manda in approvazione gli attendee
   * @param attendanceIds
   * @return {null}
   */
  sendAttendanceToApprovationForCustomer(attendanceIds: string[]): Observable<SenecaResponse<null>> {

    let httpParams = new HttpParams();

    httpParams = handleCustomList(httpParams, 'attendanceIds', attendanceIds);

    return this.http.get<SenecaResponse<null>>(
      this._mediatorUrl + 'send-attendance-to-approvation-for-customer', { params: httpParams }
    );
  }


  /**
   * Servizio per impostare l'anamnesi di un utente in fase di prenotazione
   * @param {string} attendanceId
   * @param {{ questionType: string, answer: boolean }[]} questionAnswerMap
   * @return {SetAttendanceAnamnesiResponse} Retrieved data
   */
  setAttendanceAnamnesi(attendanceId: string,
    questionAnswerMap: { questionType: string, answer: boolean }[]): Observable<SenecaResponse<boolean>> {
    return this.http.post<SenecaResponse<boolean>>(
      this._mediatorUrl + 'set-attendance-anamnesi', {
      attendanceId,
      questionAnswerMap
    }
    );
  }

  /**
   * Aggiorna degli attendance
   * @param {string} attendanceId
   * @param {string} description
   * @return {CampaignManagerUpdateAttendanceForCustomerResponse} Retrieved data
   */
  updateAttendanceForCustomer(attendanceId: string, description: string): Observable<SenecaResponse<null>> {
    let httpParams = new HttpParams();
    httpParams = httpParams.append('attendanceId', attendanceId);
    httpParams = httpParams.append('description', description);

    return this.http.get<SenecaResponse<null>>(
      this._mediatorUrl + 'update-attendance-for-customer', { params: httpParams }
    );
  }

  /**
   * Aggiorna degli attendance
   * @param {string} attendanceId
   * @param {string} description
   * @return {CampaignManagerUpdateAttendanceForAdminResponse} Retrieved data
   */
  updateAttendanceForAdmin(attendanceId: string, description: string): Observable<SenecaResponse<null>> {
    return this.http.post<SenecaResponse<null>>(
      this._mediatorUrl + 'update-attendance-for-admin', {
      attendanceId,
      description
    })
  }

  /**
   * Update di una campagna lato admin
   * @param campaignId
   * @param campaignTitle
   * @returns
   */
  updateCampaignForAdmin(campaignId: string, campaignTitle: string): Observable<SenecaResponse<boolean>> {
    return this.http.post<SenecaResponse<boolean>>(
      this._mediatorUrl + 'update-campaign-for-admin', {
      campaignId,
      campaignTitle
    }
    );
  }

  /**
   * Aggiorna lo stato di presenza di un AD
   * @param {string} attendanceId
   * @param {string} status
   * @param {string} codFisc
   * @param {string} description
   * @param {Date} vaccinationSlotStartDate
   * @param {Date} vaccinationSlotEndDate
   * @param {string} recallStatus Stato richiamo
   * @param {boolean} isRecallMandatory Richiamo richiesto
   * @param {Date} recallDate Data richiamo
   * @return {GetCampaignListResponse} Retrieved data
   */
  updateAttendance(attendanceId: string, status: AttendanceStatuses, vaccinationSlotStartDate?: string,
    vaccinationSlotEndDate?: string, description?: string, recallStatus?: RecallStatuses,
    isRecallMandatory?: boolean, recallDate?: string): Observable<SenecaResponse<null>> {
    let httpParams = new HttpParams();
    httpParams = httpParams.append('attendanceId', attendanceId);
    httpParams = httpParams.append('status', status as string);
    if (vaccinationSlotStartDate) {
      httpParams = httpParams.append('vaccinationSlotStartDate', vaccinationSlotStartDate);
    }
    if (vaccinationSlotEndDate) {
      httpParams = httpParams.append('vaccinationSlotEndDate', vaccinationSlotEndDate);
    }
    if (description) {
      httpParams = httpParams.append('description', description);
    }
    if (recallStatus) {
      httpParams = httpParams.append('recallStatus', recallStatus as string);
    }
    if (isRecallMandatory) {
      httpParams = httpParams.append('isRecallMandatory', 'true');
    }
    if (recallDate) {
      httpParams = httpParams.append('recallDate', recallDate);
    }
    return this.http.get<SenecaResponse<null>>(
      this._mediatorUrl + 'update-attendance', { params: httpParams }
    );
  }

  /**
   * Update di un round lato admin
   * @param roundId
   * @param roundTitle
   * @param campaignId
   * @param minReservationEndDate
   * @param maxReservationEndDate
   * @param vaccinationStartDate
   * @param vaccinationEndDate
   * @param maxAllowedReservationDeletionDate
   * @param maxAllowedReservationMovingDate
   * @returns
   */
  updateRoundForAdmin(roundId: string, roundTitle: string, campaignId: string, minReservationEnd: Date,
    maxReservationEnd: Date, vaccinationStart: Date, vaccinationEnd: Date,
    maxAllowedReservationDeletionDate?: number, maxAllowedReservationMovingDate?: number,
    maxAllowedReservationMovingDateOtherVenue?: number): Observable<SenecaResponse<boolean>> {

    const minReservationEndDate: string = minReservationEnd.toISOString();
    const maxReservationEndDate: string = maxReservationEnd.toISOString();
    const vaccinationStartDate: string = vaccinationStart.toISOString();
    const vaccinationEndDate: string = vaccinationEnd.toISOString();

    return this.http.post<SenecaResponse<boolean>>(
      this._mediatorUrl + 'update-round-for-admin', {
      roundId,
      roundTitle,
      campaignId,
      minReservationEndDate,
      maxReservationEndDate,
      vaccinationStartDate,
      vaccinationEndDate,
      maxAllowedReservationDeletionDate,
      maxAllowedReservationMovingDate,
      maxAllowedReservationMovingDateOtherVenue
    });
  }

  // filtri dashboard 


  getCampaignListForDashboard(clientId?: string) {
    let httpParams = new HttpParams();

    if (clientId) {
      httpParams = httpParams.append('supplierId', clientId);
    }

    return this.http.get<SenecaResponse<any>>(
      this._mediatorUrl + 'get-campaign-list-for-dashboard', {
      params: httpParams
    })
  }

  getUserPresenceForDashboardForAdmin(supplierId?: string, campaignId?: string, roundId?: string, userType?: string) {
    let httpParams = new HttpParams();
    if (supplierId) {
      httpParams = httpParams.append('supplierId', supplierId);
    }
    if (campaignId) {
      httpParams = httpParams.append('campaignId', campaignId);
    }
    if (roundId) {
      httpParams = httpParams.append('roundId', roundId);
    }
    if (userType && userType.length) {
      httpParams = httpParams.append('userType', userType);
    }
    return this.http.get<SenecaResponse<any>>(
      this._mediatorUrl + 'get-user-presences-for-dashboard', {
      params: httpParams
    })
  }

  getUserRergistrationForDashboardForAdmin(supplierId?: string, campaignId?: string, roundId?: string, userType?: string) {
    let httpParams = new HttpParams();
    if (supplierId) {
      httpParams = httpParams.append('supplierId', supplierId);
    }
    if (campaignId) {
      httpParams = httpParams.append('campaignId', campaignId);
    }
    if (roundId) {
      httpParams = httpParams.append('roundId', roundId);
    }
    if (userType && userType.length) {
      httpParams = httpParams.append('userType', userType);
    }
    return this.http.get<SenecaResponse<any>>(
      this._mediatorUrl + 'get-user-registration-for-dashboard', {
      params: httpParams
    })
  }

  getUserReservationForDashboardForAdmin(supplierId?: string, campaignId?: string, roundId?: string, userType?: string) {
    let httpParams = new HttpParams();
    if (supplierId) {
      httpParams = httpParams.append('supplierId', supplierId);
    }
    if (campaignId) {
      httpParams = httpParams.append('campaignId', campaignId);
    }
    if (roundId) {
      httpParams = httpParams.append('roundId', roundId);
    }
    if (userType && userType.length) {
      httpParams = httpParams.append('userType', userType);
    }
    return this.http.get<SenecaResponse<any>>(
      this._mediatorUrl + 'get-user-reservation-for-dashboard', {
      params: httpParams
    })
  }

  getVaccinationDataForDashboardForAdmin(supplierId?: string, campaignId?: string, roundId?: string, userType?: string) {
    let httpParams = new HttpParams();
    if (supplierId) {
      httpParams = httpParams.append('supplierId', supplierId);
    }
    if (campaignId) {
      httpParams = httpParams.append('campaignId', campaignId);
    }
    if (roundId) {
      httpParams = httpParams.append('roundId', roundId);
    }
    if (userType && userType.length) {
      httpParams = httpParams.append('userType', userType);
    }
    return this.http.get<SenecaResponse<any>>(
      this._mediatorUrl + 'get-vaccination-data-for-dashboard', {
      params: httpParams
    })
  }

  getReportingData(supplierId?: string, campaignId?: string, roundId?: string, userType?: string) {
    let httpParams = new HttpParams();
    if (supplierId) {
      httpParams = httpParams.append('supplierId', supplierId);
    }
    if (campaignId) {
      httpParams = httpParams.append('campaignId', campaignId);
    }
    if (roundId) {
      httpParams = httpParams.append('roundId', roundId);
    }
    if (userType && userType.length) {
      httpParams = httpParams.append('userType', userType);
    }
    return this.http.get<SenecaResponse<any>>(
      this._mediatorUrl + 'get-reporting-data', {
      params: httpParams
    })
  }

  getAgeRangesForDashboard(supplierId?: string, campaignId?: string, roundId?: string, userType?: string) {
    let httpParams = new HttpParams();
    if (supplierId) {
      httpParams = httpParams.append('supplierId', supplierId);
    }
    if (campaignId) {
      httpParams = httpParams.append('campaignId', campaignId);
    }
    if (roundId) {
      httpParams = httpParams.append('roundId', roundId);
    }

    if (userType && userType.length) {
      httpParams = httpParams.append('userType', userType);
    }
    return this.http.get<SenecaResponse<MapById<string>>>(
      this._mediatorUrl + 'get-age-ranges-for-dashboard', {
      params: httpParams
    })
  }

  getDetailByDateForDashboard(fromRecord: number, numRecords: number, supplierId?: string, campaignId?: string, roundId?: string, userType?: string, vaccinationDate?: Date) {
    let httpParams = new HttpParams();
    httpParams = handleFromRecord(httpParams, fromRecord);
    httpParams = handleNumRecords(httpParams, numRecords);
    if (supplierId) {
      httpParams = httpParams.append('supplierId', supplierId);
    }
    if (campaignId) {
      httpParams = httpParams.append('campaignId', campaignId);
    }
    if (roundId) {
      httpParams = httpParams.append('roundId', roundId);
    }

    if (userType && userType.length) {
      httpParams = httpParams.append('userType', userType);
    }

    if (vaccinationDate) {
      httpParams = httpParams.append('vaccinationDate', vaccinationDate.toISOString());
    }

    return this.http.get<SenecaResponse<any>>(
      this._mediatorUrl + 'get-detail-by-date-for-dashboard', {
      params: httpParams
    })
  }

  countDetailByDateForDashboard(supplierId?: string, campaignId?: string, roundId?: string, userType?: string, vaccinationDate?: Date) {
    let httpParams = new HttpParams();
    if (supplierId) {
      httpParams = httpParams.append('supplierId', supplierId);
    }
    if (campaignId) {
      httpParams = httpParams.append('campaignId', campaignId);
    }
    if (roundId) {
      httpParams = httpParams.append('roundId', roundId);
    }

    if (userType && userType.length) {
      httpParams = httpParams.append('userType', userType);
    }

    if (vaccinationDate) {
      httpParams = httpParams.append('vaccinationDate', vaccinationDate.toISOString());
    }

    return this.http.get<SenecaResponse<any>>(
      this._mediatorUrl + 'count-detail-by-date-for-dashboard', {
      params: httpParams
    })
  }


  getStructuresForDashboard(fromRecord: number, numRecords: number, supplierId?: string, campaignId?: string, roundId?: string, userType?: string, searchedText?: string) {
    let httpParams = new HttpParams();
    httpParams = handleFromRecord(httpParams, fromRecord);
    httpParams = handleNumRecords(httpParams, numRecords);
    if (supplierId) {
      httpParams = httpParams.append('supplierId', supplierId);
    }
    if (campaignId) {
      httpParams = httpParams.append('campaignId', campaignId);
    }
    if (roundId) {
      httpParams = httpParams.append('roundId', roundId);
    }

    if (userType && userType.length) {
      httpParams = httpParams.append('userType', userType);
    }

    httpParams = handleSearchedText(httpParams, searchedText);

    return this.http.get<SenecaResponse<any>>(
      this._mediatorUrl + 'get-detail-by-venue-for-dashboard', {
      params: httpParams
    })
  }

  countStructuresForDashboard(supplierId?: string, campaignId?: string, roundId?: string, userType?: string, searchedText?: string) {
    let httpParams = new HttpParams();
    if (supplierId) {
      httpParams = httpParams.append('supplierId', supplierId);
    }
    if (campaignId) {
      httpParams = httpParams.append('campaignId', campaignId);
    }
    if (roundId) {
      httpParams = httpParams.append('roundId', roundId);
    }

    httpParams = handleSearchedText(httpParams, searchedText);

    if (userType && userType.length) {
      httpParams = httpParams.append('userType', userType);
    }
    return this.http.get<SenecaResponse<any>>(
      this._mediatorUrl + 'count-detail-by-venue-for-dashboard', {
      params: httpParams
    })
  }

  getDetailBySocietiesForDashboard(fromRecord: number, numRecords: number, supplierId?: string, campaignId?: string, roundId?: string, userType?: string, searchedText?: string) {
    let httpParams = new HttpParams();
    httpParams = handleFromRecord(httpParams, fromRecord);
    httpParams = handleNumRecords(httpParams, numRecords);
    if (supplierId) {
      httpParams = httpParams.append('supplierId', supplierId);
    }
    if (campaignId) {
      httpParams = httpParams.append('campaignId', campaignId);
    }
    if (roundId) {
      httpParams = httpParams.append('roundId', roundId);
    }

    if (userType && userType.length) {
      httpParams = httpParams.append('userType', userType);
    }

    if (searchedText && searchedText.length) {
      httpParams = httpParams.append('title', searchedText);
    }

    return this.http.get<SenecaResponse<any>>(
      this._mediatorUrl + 'get-detail-by-supplier-for-dashboard', {
      params: httpParams
    })
  }

  countDetailBySocietiesForDashboard(supplierId?: string, campaignId?: string, roundId?: string, userType?: string, searchedText?: string) {
    let httpParams = new HttpParams();
    if (supplierId) {
      httpParams = httpParams.append('supplierId', supplierId);
    }
    if (campaignId) {
      httpParams = httpParams.append('campaignId', campaignId);
    }
    if (roundId) {
      httpParams = httpParams.append('roundId', roundId);
    }

    if (userType && userType.length) {
      httpParams = httpParams.append('userType', userType);
    }

    if (searchedText && searchedText.length) {
      httpParams = httpParams.append('title', searchedText);
    }

    return this.http.get<SenecaResponse<any>>(
      this._mediatorUrl + 'count-detail-by-supplier-for-dashboard', {
      params: httpParams
    })
  }

  getUserTypologyDashboardData(supplierId?: string, campaignId?: string, roundId?: string, userType?: string) {
    let httpParams = new HttpParams();
    if (supplierId) {
      httpParams = httpParams.append('supplierId', supplierId);
    }
    if (campaignId) {
      httpParams = httpParams.append('campaignId', campaignId);
    }
    if (roundId) {
      httpParams = httpParams.append('roundId', roundId);
    }
    if (userType && userType.length) {
      httpParams = httpParams.append('userType', userType);
    }
    return this.http.get<SenecaResponse<any>>(
      this._mediatorUrl + 'get-user-typology-dashboard-data', {
      params: httpParams
    })
  }

  getUserGenderDashboardData(supplierId?: string, campaignId?: string, roundId?: string, userType?: string) {
    let httpParams = new HttpParams();
    if (supplierId) {
      httpParams = httpParams.append('supplierId', supplierId);
    }
    if (campaignId) {
      httpParams = httpParams.append('campaignId', campaignId);
    }
    if (roundId) {
      httpParams = httpParams.append('roundId', roundId);
    }
    if (userType && userType.length) {
      httpParams = httpParams.append('userType', userType);
    }
    return this.http.get<SenecaResponse<any>>(
      this._mediatorUrl + 'get-user-gender-dashboard-data', {
      params: httpParams
    })
  }

  getUserAgesDashboardData(supplierId?: string, campaignId?: string, roundId?: string, userType?: string) {
    let httpParams = new HttpParams();
    if (supplierId) {
      httpParams = httpParams.append('supplierId', supplierId);
    }
    if (campaignId) {
      httpParams = httpParams.append('campaignId', campaignId);
    }
    if (roundId) {
      httpParams = httpParams.append('roundId', roundId);
    }
    if (userType && userType.length) {
      httpParams = httpParams.append('userType', userType);
    }
    return this.http.get<SenecaResponse<any>>(
      this._mediatorUrl + 'get-user-ages-dashboard-data', {
      params: httpParams
    })
  }

  updateAttendanceForHelpDesk(userId: string, forename?: string, surname?: string, fiscalCode?: string, phoneNumber?: string): Observable<SenecaResponse<boolean>> {
    if (forename) {
      return this.http.post<SenecaResponse<boolean>>(
        this._mediatorUrl + 'update-attendance-for-help-desk', {
        userId,
        forename
      });
    } else if (surname) {
      return this.http.post<SenecaResponse<boolean>>(
        this._mediatorUrl + 'update-attendance-for-help-desk', {
        userId,
        surname
      });
    } else if (fiscalCode) {
      return this.http.post<SenecaResponse<boolean>>(
        this._mediatorUrl + 'update-attendance-for-help-desk', {
        userId,
        fiscalCode
      });
    } else if (phoneNumber) {
      return this.http.post<SenecaResponse<boolean>>(
        this._mediatorUrl + 'update-attendance-for-help-desk', {
        userId,
        phoneNumber
      });
    } else {
      return of(new SenecaResponse(null, true));
    }
  }

  createAttendeeForEntitled(roundId: string, forename: string, surname: string, codFisc: string, familyMemberType: FamilyMemberTypes) {
    return this.http.post<SenecaResponse<boolean>>(
      this._mediatorUrl + 'create-attendee-by-entitled', {
      roundId,
      forename,
      surname,
      codFisc,
      familyMemberType
    });
  }

  addEntitled(roundId: string, codFisc: string, parentCodFisc: string, forename: string, surname: string, clientId: string, venueId: string,
    entitledStatus: string, privacy: boolean, slotReservationStartDate: any, privacyFile: any, simulate?: boolean) {

    let formData = new FormData();
    // Chiamo il upload-privacy-doc-file
    //formData.append('privacyFile', privacyFile);

    let httpParams = new HttpParams();
    httpParams = httpParams.append('roundId', roundId);
    httpParams = httpParams.append('codFisc', codFisc);
    if (parentCodFisc) {
      httpParams = httpParams.append('parentCodFisc', parentCodFisc);
    }

    httpParams = httpParams.append('forename', forename);
    httpParams = httpParams.append('surname', surname);
    httpParams = httpParams.append('clientId', clientId);
    httpParams = httpParams.append('venueId', venueId);
    httpParams = httpParams.append('entitledStatus', entitledStatus);
    httpParams = httpParams.append('privacy', privacy.toString());
    httpParams = httpParams.append('slotReservationStartDate', slotReservationStartDate.toISOString());

    if (simulate) {
      httpParams = httpParams.append('simulate', 'true');
    }

    return this.http.post<SenecaResponse<any>>(
      this._mediatorUrl + 'import-offline-entitled-for-admin', formData, {
      params: httpParams
    });
  }

  // Elimina file di privacy di un AD
  deleteEntitledPrivacyFile(attendanceId: string, uploadId: any) {

    return this.http.post<SenecaResponse<any>>(this._mediatorUrl + 'delete-privacy-doc-file-for-entitled', {
      attendanceId,
      uploadId
    });
  }

  // Aggiorna utente
  updateUserForAdmin(userId: string, campaignId: string, roundId: string, codFisc: string, forename: string, surname: string, phoneNumber: string, status: string, venueId: string, startDate: Date | undefined) {
    if (forename) {
      return this.http.post<SenecaResponse<boolean>>(
        this._mediatorUrl + 'update-user-for-admin', {
        userId,
        campaignId,
        roundId,
        forename
      });
    } else if (surname) {
      return this.http.post<SenecaResponse<boolean>>(
        this._mediatorUrl + 'update-user-for-admin', {
        userId,
        campaignId,
        roundId,
        surname
      });
    } else if (codFisc) {
      return this.http.post<SenecaResponse<boolean>>(
        this._mediatorUrl + 'update-user-for-admin', {
        userId,
        campaignId,
        roundId,
        codFisc
      });
    } else if (phoneNumber) {
      return this.http.post<SenecaResponse<boolean>>(
        this._mediatorUrl + 'update-user-for-admin', {
        userId,
        campaignId,
        roundId,
        phoneNumber
      });
    } else if (status) {
      return this.http.post<SenecaResponse<boolean>>(
        this._mediatorUrl + 'update-user-for-admin', {
        userId,
        campaignId,
        roundId,
        status: status
      })
    } else if (venueId && startDate) {
      return this.http.post<SenecaResponse<boolean>>(
        this._mediatorUrl + 'update-user-for-admin', {
        userId,
        campaignId,
        roundId,
        venueId,
        startDate: startDate.toISOString()
      })
    } else {
      return of(new SenecaResponse(null, true));
    }
  }


  /**
   * Recupera una lista di strutture di un round
   * @param {string[]} roundId
   * @param {number} fromRecord
   * @param {number} numRecords
   * @param {string} searchedText
   * @return {InfoAttendance[]} Lista di strutture
   */
  searchEntitledByFullName(roundId: string, venueId: string, searchedText: string, fromRecord: number, numRecords: number): Observable<SenecaResponse<InfoAttendance[]>> {

    let httpParams = new HttpParams();
    httpParams = handleFromRecord(httpParams, fromRecord);
    httpParams = handleNumRecords(httpParams, numRecords);

    httpParams = httpParams.append('roundId', roundId);
    httpParams = httpParams.append('venueId', venueId);
    httpParams = handleSearchedText(httpParams, searchedText);

    return this.http.get<SenecaResponse<any[]>>(
      this._mediatorUrl + 'search-entitled-by-searched-text', { params: httpParams }
    );
  }


  /**
   * Numero di SS per helpdesk
   * @param {string} supplierName
   * @return {number}
   */
  countHealthFacilitySupplierForHelpDesk(supplierName?: string): Observable<SenecaResponse<number>> {
    let httpParams = new HttpParams();

    if (supplierName) {
      httpParams = httpParams.append('supplierName', supplierName);
    }

    return this.http.get<SenecaResponse<number>>(
      this._mediatorUrl + 'count-health-facility-supplier-for-help-desk', { params: httpParams }
    );
  }

  /**
   * Lista SS per help desk
   * @param {number} fromRecord
   * @param {number} numRecords
   * @param {string} supplierName
   * @return {HealthFacilityInfo[]}
   */
  listHealthFacilitySupplierForHelpDesk(fromRecord: number, numRecords: number, supplierName?: string): Observable<SenecaResponse<HealthFacilityInfo[]>> {
    let httpParams = new HttpParams();

    httpParams = handleFromRecord(httpParams, fromRecord);
    httpParams = handleNumRecords(httpParams, numRecords);
    if (supplierName) {
      httpParams = httpParams.append('supplierName', supplierName);
    }

    return this.http.get<SenecaResponse<HealthFacilityInfo[]>>(
      this._mediatorUrl + 'list-health-facility-supplier-for-help-desk', { params: httpParams }
    );
  }

  /**
   * Recupera struttura da ID
   * @param healFacilitySupplierId
   * @return {HealthFacilityInfo}
   */
  getHealthFacilitySupplierForHelpDesk(healFacilitySupplierId: string): Observable<SenecaResponse<HealthFacilityInfo>> {
    let httpParams = new HttpParams();

    httpParams = httpParams.append('healFacilitySupplierId', healFacilitySupplierId);

    return this.http.get<SenecaResponse<HealthFacilityInfo>>(
      this._mediatorUrl + 'get-health-facility-supplier-for-help-desk', { params: httpParams }
    );
  }

  /**
   * Cancella una SS
   * @param supplierId
   * @return {boolean}
   */
  deleteHealthFacilitySupplierForHelpDesk(supplierId: string): Observable<SenecaResponse<boolean>> {
    return this.http.post<SenecaResponse<boolean>>(
      this._mediatorUrl + 'delete-health-facility-supplier-for-help-desk',
      {
        supplierId
      }
    );
  }

  /**
   * Numero di sedi per strutture con referente
   * @param {string} supplierId
   * @param {string} title
   * @param {string[]} roundId
   * @param {string[]} ignoreVenueForRoundId
   * @return {number}
   */
  countVenueByHealthFacilitySupplierForHelpDesk(supplierId?: string, title?: string, roundId?: string | string[],
    ignoreVenueForRoundId?: string | string[]): Observable<SenecaResponse<number>> {
    let httpParams = new HttpParams();

    if (supplierId) {
      httpParams = httpParams.append('supplierId', supplierId);
    }
    httpParams = handleTitle(httpParams, title);

    httpParams = handleCustomList(httpParams, 'roundId', roundId);
    httpParams = handleCustomList(httpParams, 'ignoreVenueForRoundId', ignoreVenueForRoundId);

    return this.http.get<SenecaResponse<number>>(
      this._mediatorUrl + 'count-venue-by-health-facility-supplier-for-help-desk', { params: httpParams }
    );
  }

  /**
   * Lista sedi delle SS
   * @param {string} supplierId
   * @param {number} fromRecord
   * @param {number} numRecords
   * @param {string} title
   * @param {string[]} roundId
   * @param {string[]} ignoreVenueForRoundId
   * @return {InfoVenue[]}
   */
  listVenueByHealthFacilitySupplierForHelpDesk(fromRecord: number, numRecords: number, supplierId?: string, title?: string,
    roundId?: string | string[], ignoreVenueForRoundId?: string | string[]): Observable<SenecaResponse<InfoVenue[]>> {
    let httpParams = new HttpParams();

    if (supplierId) {
      httpParams = httpParams.append('supplierId', supplierId);
    }
    httpParams = handleFromRecord(httpParams, fromRecord);
    httpParams = handleNumRecords(httpParams, numRecords);
    httpParams = handleTitle(httpParams, title);

    httpParams = handleCustomList(httpParams, 'roundId', roundId);
    httpParams = handleCustomList(httpParams, 'ignoreVenueForRoundId', ignoreVenueForRoundId);

    return this.http.get<SenecaResponse<InfoVenue[]>>(
      this._mediatorUrl + 'list-venue-by-health-facility-supplier-for-help-desk', { params: httpParams }
    );
  }

  /**
   * Numero di SS per referente
   * @param {string} supplierId
   * @return {number}
   */
  countHealthFacilitySupplierReferentForHelpDesk(supplierId: string): Observable<SenecaResponse<number>> {
    let httpParams = new HttpParams();

    httpParams = httpParams.append('supplierId', supplierId);

    return this.http.get<SenecaResponse<number>>(
      this._mediatorUrl + 'count-health-facility-supplier-referent-for-help-desk', { params: httpParams }
    );
  }


  /**
   * Lista referenti SS
   * @param {string} supplierId
   * @param {number} fromRecord
   * @param {number} numRecords
   * @return {SupplierPerson[]}
   */
  listHealthFacilitySupplierReferentForHelpDesk(supplierId: string, fromRecord: number, numRecords: number): Observable<SenecaResponse<SupplierPerson[]>> {
    let httpParams = new HttpParams();

    httpParams = httpParams.append('supplierId', supplierId);
    httpParams = handleFromRecord(httpParams, fromRecord);
    httpParams = handleNumRecords(httpParams, numRecords);

    return this.http.get<SenecaResponse<SupplierPerson[]>>(
      this._mediatorUrl + 'list-health-facility-supplier-referent-for-help-desk', { params: httpParams }
    );
  }

  /**
   * Cancella il referente della SS
   * @param referentId
   * @return {boolean}
   */
  deleteHealthFacilitySupplierReferentForHelpDesk(referentId: string): Observable<SenecaResponse<boolean>> {
    return this.http.post<SenecaResponse<boolean>>(
      this._mediatorUrl + 'delete-health-facility-supplier-referent-for-help-desk',
      {
        referentId
      }
    );
  }

  /**
   * Aggiorna il referente di una SS
   * @param {string} referentId
   * @param {string} surname
   * @param {string} forename
   * @param {string} referentMail
   * @return {boolean}
   */
  updateHealthFacilitySupplierReferentForHelpDesk(referentId: string, surname?: string, forename?: string,
    referentMail?: string, ignoreReceiveStructureSetPresenceReminderMail?: boolean): Observable<SenecaResponse<boolean>> {
    return this.http.post<SenecaResponse<boolean>>(
      this._mediatorUrl + 'update-health-facility-supplier-referent-for-help-desk',
      {
        referentId,
        surname,
        forename,
        referentMail,
        ignoreReceiveStructureSetPresenceReminderMail
      }
    );
  }
}

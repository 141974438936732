import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { DatePipe, TitleCasePipe } from "@angular/common";

import * as CoreActions from "../../../core/ngrx/core.actions";
import * as fromApp from "../../../ngrx/app.reducers";
import { DaySlots, InfoCampaign, InfoRound, InfoSlot, InfoSlotStatuses, InfoVenue } from 'atfcore-commonclasses/bin/classes/campaignmanager';

import { RedirectService } from 'src/app/shared/services/redirect.service';
import { getWarningModal } from 'src/app/utils/utils';
import { ScrollTo } from 'src/app/core/services/scroll-to.service';
import { RentService } from 'src/app/structure/services/rent.service';
import { SidebarService } from 'src/app/shared/services/sidebar.service';
import { DropdownItem } from 'src/app/shared/models/dropdown.model';
import { SenecaResponse } from 'atfcore-commonclasses/bin/classes/common';
import { ModalService } from 'src/app/shared/components/modal/modal.service';

@Component({
  selector: 'app-admin-campaign-round-venue-details',
  templateUrl: './campaignRoundVenueDetails.component.html',
  styleUrls: ['./campaignRoundVenueDetails.component.scss']
})
export class AdminCampaignRoundVenueDetailsComponent implements OnInit, OnDestroy {
  ALL_KEYS: string[] = ["HANDICAPPED_ACCESS", "ON_SITE_PARKING", "ON_STREET_PARKING", "CAR_PARKING", "PARKING_FOR_CYCLES_AND_MOTORCYCLES", "PAID_PARKING", "INFORMATION_AND_RECEPTION_SERVICE", "BAR",
    "CAR", "BICYCLES_AND_MOTORCYCLES", "BUS", "SUBWAY", "STREETCAR", "TRAIN", "CAB_STATION"]
  SERVICE_KEYS: string[] = ["HANDICAPPED_ACCESS", "ON_SITE_PARKING", "ON_STREET_PARKING", "CAR_PARKING", "PARKING_FOR_CYCLES_AND_MOTORCYCLES", "PAID_PARKING", "INFORMATION_AND_RECEPTION_SERVICE", "BAR"];
  VEHICLE_KEYS: string[] = ["CAR", "BICYCLES_AND_MOTORCYCLES", "BUS", "SUBWAY", "STREETCAR", "TRAIN", "CAB_STATION",]

  campaignId: string = '';
  campaign?: InfoCampaign;

  roundId: string = '';
  round?: InfoRound;
  activeRound: boolean = false;

  venueId: string = '';
  venue?: any;
  selectedVenueTab: number = 0;

  venueMapData?: {
    lat: number,
    lng: number
  };

  isLoading: boolean = true;
  isLoadingBase: boolean = true;
  private _firstLoad: boolean = true;

  requestsCount: number = 0;

  slots: InfoSlot[] = [];
  selectedSlots: InfoSlot[] = [];
  private _isEndSlotsLoading: boolean = false;

  structureDaySlots: DaySlots[] = [];

  // Dropdown for mobile
  structureDaySlotsDropdown: DropdownItem[] = [];
  selectedStructureDaySlotsDropdown?: DropdownItem;

  indexSelectedStructureDaySlots: number = 0;

  isRevokingSlots: boolean = false;
  isResumingSlots: boolean = false;

  private _unsubscribeSignal$: Subject<void> = new Subject();

  isLimitedAdmin: boolean = false;
  selectedDayVenue: any;

  constructor(
    private route: ActivatedRoute,
    private scrollTo: ScrollTo,
    private appStore: Store<fromApp.AppState>,
    private translate: TranslateService,
    private rentService: RentService,
    private redirectService: RedirectService,
    private sidebarService: SidebarService,
    private datePipe: DatePipe,
    private titleCasePipe: TitleCasePipe,
    private modalService: ModalService
  ) {

  }

  ngOnInit() {
    this.scrollTo.header();
    this.campaignId = this.route.snapshot.paramMap.get('campaignId') || '';
    this.roundId = this.route.snapshot.paramMap.get('roundId') || '';
    this.venueId = this.route.snapshot.paramMap.get('venueId') || '';

    if (this.campaignId && this.roundId && this.venueId) {
      this.appStore.select(fromApp.getIsLimitedAdmin)
        .pipe(takeUntil(this._unsubscribeSignal$.asObservable()))
        .subscribe(
          (isLimitedAdmin) => {
            this.isLimitedAdmin = isLimitedAdmin;
          });

      this.getCampaign();
    } else {
      this.redirectService.goToHome();
    }
  }

  goBackToRounds() {
    this.redirectService.goToAdminCampaignTabRounds(this.campaignId);
  }

  goBackToVenues() {
    this.redirectService.goToAdminCampaignRoundTabVenues(this.campaignId, this.roundId);
  }

  ngOnDestroy() {
    this._unsubscribeSignal$.next();
    this._unsubscribeSignal$.unsubscribe();
  }

  /**
   * @description Mostra una modale con un messaggio di errore
   * @param modalId
   * @param error
   */
  dispatchWarningModal(modalId: string, error: string) {
    const messageObj = getWarningModal(this.translate, modalId, error);
    this.appStore.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
  }

  getCampaign() {

    this.rentService.getCampaign(this.campaignId)
      .pipe(takeUntil(this._unsubscribeSignal$.asObservable()))
      .subscribe((result) => {
        if (!result || result.error) {
          this.dispatchWarningModal('100', result.error || '');
          this.resetLoaders();
        } else {
          this.campaign = result.response;
          this.getRound();
        }
      }, (err) => {
        if (err && err.message) {
          this.dispatchWarningModal('101', err.message);
        }
        this.resetLoaders();
      });
  }

  getRound() {
    this.rentService.getRound(this.roundId)
      .pipe(takeUntil(this._unsubscribeSignal$.asObservable()))
      .subscribe((result) => {
        if (!result || result.error) {
          this.dispatchWarningModal('100', result.error || '');
          this.resetLoaders();
        } else {
          this.round = result.response;

          if (this.round && this.round.startDate && this.round.endDate) {
            let now = new Date().getTime();
            const minReservationEndDate = new Date(this.round.minReservationEndDate).getTime();
            const endDate = new Date(this.round.endDate).getTime();
            this.activeRound = now >= minReservationEndDate && endDate >= now;
          }

          this.getVenue();
        }
      }, (err) => {
        if (err && err.message) {
          this.dispatchWarningModal('101', err.message);
        }
        this.resetLoaders();
      });
  }

  editRound() {
    this.redirectService.goToAdminNewCampaignRound(this.campaignId, this.roundId);
  }

  getVenue(selectedDay?: Date) {

    this.rentService.getVenueById(this.venueId, true, this.roundId, selectedDay?.toString())
      .pipe(takeUntil(this._unsubscribeSignal$.asObservable()))
      .subscribe((result) => {
        if (!result || result.error) {
          this.dispatchWarningModal('102', result.error || '');
          this.resetLoaders();
        } else {
          if (selectedDay) {
            this.selectedDayVenue = {
              offeredSlotsCount: result.response.offeredSlotsCount,
              slotsAvailable: result.response.slotsAvailable,
              bookings: result.response.bookings,
              presents: result.response.presents,
              reportedHeads: result.response.reportedHeads,
              reportableHeads: result.response.reportableHeads,
            }
          } else {
            this.venue = result.response;
            this.getVenueDaysSlots();
          }
        }
      }, (err) => {
        if (err && err.message) {
          this.dispatchWarningModal('103', err.message);
        }
        this.resetLoaders();
      });
  }

  openVenueCard() {
    this.selectedVenueTab = 0;

    if (this.venue && this.venue.latitude && this.venue.longitude) {
      this.venueMapData = {
        lat: parseFloat(this.venue.latitude),
        lng: parseFloat(this.venue.longitude)
      };
    }

    this.sidebarService.setShowNav(true);
  }

  closeVenueCard() {
    this.sidebarService.setShowNav(false);
  }

  onVenueTabClick(index: number) {
    this.selectedVenueTab = index;
  }

  goToRequestList() {
    this.redirectService.goToAdminPendingRequests(this.campaignId, this.roundId, this.venueId);
  }

  //#region Slots

  /**
   * @description Alla selezione di una giornata da schemata mobile
   * @param item
   */
  onSelectDaySlotMobile(item: DropdownItem) {
    this.onSelectDaySlot(Number.parseInt(item.id));
  }

  /**
   * @description Richiamato in caso di scroll o di selezione di una giornata
   * @param firstCall Se richiamato dalla selezione di una giornata
   */
  getDaySlots(firstCall?: boolean) {

    if (this.structureDaySlots && (!this.isLoading || firstCall) && !this._isEndSlotsLoading) {

      this.isLoading = true;

      this.rentService.getVenueSlotsForAdmin(
        this.venueId,
        this.roundId,
        false,
        this.structureDaySlots[this.indexSelectedStructureDaySlots].date,
        this.slots.length,
        96)
        .pipe(takeUntil(this._unsubscribeSignal$.asObservable()))
        .subscribe(
          (result: SenecaResponse<DaySlots[]>) => {
            if (result && result.error) {
              this.dispatchWarningModal('023', result.error);
              this.resetLoaders();
            } else {
              const slots = result.response.length ? result.response[0].slots : [];
              if (slots && slots.length) {
                this.slots = this.slots.concat(slots);

                if (slots.length < 96) {
                  this._isEndSlotsLoading = true;
                }

                this.getVenue(this.structureDaySlots[this.indexSelectedStructureDaySlots].date);

              } else {
                this._isEndSlotsLoading = true;
              }
              this.isLoading = false;
            }
          }, (err) => {
            if (err && err.message) {
              this.dispatchWarningModal('024', err.message);
            }
            this.resetLoaders();
          });
    }
  }

  /**
   * @param date Data da trasformare
   * @returns Stringa con formato Lunedì 1 Marzo
   */
  getDateForSelect(date: Date): string {
    return this.titleCasePipe.transform(this.datePipe.transform(date, 'EEEE dd MMMM') || '');
  }

  /**
  * @description Mostra l'helper delle info sugli slots
  */
  showHelper() {

  }

  /**
   * @description Chiamata alla selezione di una giornata
   * @param indexDaySlot
   */
  onSelectDaySlot(indexDaySlot: number) {
    this.isLoading = true;
    this.indexSelectedStructureDaySlots = indexDaySlot;
    this.selectedStructureDaySlotsDropdown = this.structureDaySlotsDropdown[indexDaySlot];
    this.slots = [];
    this._isEndSlotsLoading = false;

    this.selectedSlots = [];
    this.isResumingSlots = false;
    this.isRevokingSlots = false;

    this.getDaySlots(true);
    if (!this._firstLoad) {
      this.scrollTo.elementWithDelay('admin-round-structure-anchor');
    } else {
      this._firstLoad = false;
    }
  }

  /**
   * @description Recupera gli slots di una struttura suddivisi per giorni
   */
  getVenueDaysSlots() {

    this.indexSelectedStructureDaySlots = 0;
    this.structureDaySlots = [];

    this.structureDaySlotsDropdown = [];
    this.selectedStructureDaySlotsDropdown = undefined;

    this.rentService.getVenueSlotsForAdmin(this.venueId, this.roundId, true)
      .pipe(takeUntil(this._unsubscribeSignal$.asObservable()))
      .subscribe(
        (result: SenecaResponse<DaySlots[]>) => {
          if (result && result.error) {
            this.dispatchWarningModal('008', result.error);
            this.resetLoaders();
            this._isEndSlotsLoading = true;
          } else {
            this.structureDaySlots = result.response;

            if (this.structureDaySlots && this.structureDaySlots.length) {
              // Per la select mobile
              this.structureDaySlots.forEach((daySlot, index) => {
                this.structureDaySlotsDropdown.push({
                  id: index.toString(),
                  name: this.getDateForSelect(daySlot.date)
                });
              });
              this.onSelectDaySlot(0);
            } else {
              this.resetLoaders();
            }
            this.isLoadingBase = false;
          }
        }, (err) => {
          if (err && err.message) {
            this.dispatchWarningModal('009', err.message);
          }
          this.resetLoaders();
        });
  }

  /**
   * @description Chiamata alla selezione di uno slot
   * @param index
   */
  selectSlotClick(index: number) {
    if (this.isLimitedAdmin) {
      return;
    }
    const slotStatus: InfoSlotStatuses = this.slots[index].status;
    if (slotStatus === 'APPROVED' || slotStatus === 'REVOKED') {
      if (!this.isResumingSlots && !this.isRevokingSlots) {
        if (slotStatus === 'APPROVED') {
          this.isRevokingSlots = true;
        } else if (slotStatus === 'REVOKED') {
          this.isResumingSlots = true;
        }
      }
      if ((this.isResumingSlots && slotStatus === 'REVOKED') || (this.isRevokingSlots && slotStatus === 'APPROVED')) {
        this.addSelectedSlot(this.slots[index]);
      }
    }
  }

  private addSelectedSlot(slot: InfoSlot) {
    const selectedIndex = this.selectedSlots.findIndex(x => x.slotId === slot.slotId);
    if (selectedIndex === -1) {
      // Slot da aggiungere
      this.selectedSlots.push(slot);
    } else {
      // Slot da rimuovere
      this.selectedSlots.splice(selectedIndex, 1);
      if (this.selectedSlots.length === 0) {
        this.isRevokingSlots = false;
        this.isResumingSlots = false;
      }
    }
  }

  /**
   * @param currentSlot
   * @returns True se lo slot è stato selezionato
   */
  slotIsSelected(currentSlot: InfoSlot): boolean {
    return this.selectedSlots.filter(x => x.slotId === currentSlot.slotId).length > 0;
  }

  /**
   * @description Apre la modale per confermare la rimozione della sede dal round
   */
  openRevokeSlotsModal() {
    this.modalService.open('revokeSlots');
  }

  /**
   * @description Chiude la modale per confermare la rimozione della sede dal round
   */
  closeRevokeSlotsModal() {
    this.modalService.close('revokeSlots');
  }


  // TODO Funzione di revoca immediata degli slot lato admin, manca logica
  confirmRevokeSlots() {
    this.closeRevokeSlotsModal()
  }

  resumeSlots() {

  }

  //#endregion Slots

  /**
   * @description Apre la modale per confermare la rimozione della sede dal round
   */
  openRemoveVenueFromRoundModal() {
    this.modalService.open('removeVenueFromRound');
  }

  /**
   * @description Chiude la modale per confermare la rimozione della sede dal round
   */
  closeRemoveVenueFromRoundModal(confirm?: boolean) {
    this.modalService.close('removeVenueFromRound');
    if (confirm) {
      this.confirmRemoveVenueFromRound();
    }
  }

  confirmRemoveVenueFromRound() {
    this.isLoadingBase = true;
    this.isLoading = true;

    this.rentService.removeVenueAssociationToRoundForAdmin(this.roundId, [this.venue.itemId])
      .pipe(takeUntil(this._unsubscribeSignal$.asObservable()))
      .subscribe((result) => {
        // this.closeRemoveVenueFromRoundModal();
        if (!result || result.error) {
          this.dispatchWarningModal('200', result.error || '');
          this.resetLoaders();
        } else {
          this.redirectService.goToAdminCampaignRoundTabVenues(this.campaignId, this.roundId);
        }
      }, (err) => {
        if (err && err.message) {
          // this.closeRemoveVenueFromRoundModal();
          this.dispatchWarningModal('201', err.message);
        }
        this.resetLoaders();
      });
  }

  anyNotNull() {
    let test = false;
    this.ALL_KEYS.forEach((key: string) => {
      if (this.venue.details && this.venue.details[key] != null) {
        test = true;
      }
    })
    return test;
  }

  resetLoaders() {
    this.isLoadingBase = false;
    this.isLoading = false;
    this._isEndSlotsLoading = true;
  }

}

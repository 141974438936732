import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { InfoCampaign } from 'atfcore-commonclasses';
import { RedirectService } from '../../services/redirect.service';

import * as moment from 'moment';

export type NavigationDirection =
  null |
  'ACTIVE_CAMPAIGNS';

@Component({
  selector: 'card-campaign',
  templateUrl: 'card-campaign.component.html',
  styleUrls: ['./card-campaign.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CardCampaignComponent {

  _isActive: boolean = false;
  _startDate?: Date;
  _endDate?: Date;

  _campaign?: InfoCampaign;
  @Input() set campaign(value: InfoCampaign) {
    this._campaign = value;

    if (this._campaign && this._campaign.rounds && this._campaign.rounds.length > 0) {
      this._startDate = new Date(this._campaign.rounds[0].minReservationEndDate);
      this._endDate = new Date(this._campaign.rounds[0].endDate);
      this._campaign.rounds.forEach(round => {
        const minReservationEndDate = new Date(round.minReservationEndDate);
        if (minReservationEndDate && this._startDate && minReservationEndDate.getTime() < this._startDate.getTime()) {
          this._startDate = new Date(minReservationEndDate);
        }
        const endDate = new Date(round.endDate);
        if (endDate && this._endDate && endDate.getTime() > this._endDate.getTime()) {
          this._endDate = new Date(endDate);
        }
      });
      const now = new Date().getTime();
      this._isActive = this._startDate.getTime() < now && now < this._endDate.getTime();
    }
  };

  @Input() venueId?: string;

  constructor(private redirectService: RedirectService) {

  }

  goToCampaign() {
    if (this._campaign) {
      this.redirectService.goToCampaignDetails(this._campaign.campaignId, this.venueId);
    }
  }

}

<div class="page-content-wrapper" fxLayout="column">

  <!-- Richieste da gestire -->
  <h1 fxFlexOffset="40px" class="primary" translate="admin.campaignPendingRequests.TITLE"></h1>

  <!-- Pagina specifica per sede -->
  <ng-container *ngIf="withVenue">
    <bread-crumbs fxFlexOffset="12px" firstLevel="ADMIN_CAMPAIGNS" (secondLevelClick)="goBackToRounds()"
      [secondLevel]="campaign ? campaign.title : undefined" [thirdLevel]="round ?
        ((round.startDate | date: 'dd MMMM yyyy' | titlecase) + ' - ' + (round.endDate | date: 'dd MMMM yyyy' | titlecase)) :
        undefined">
    </bread-crumbs>

    <!-- Resume struttura/sede -->
    <div fxFlexOffset="56px" class="pending-requests-venue-resume" fxLayout="row" fxLayoutAlign="space-between center">
      <svg-icon class="note" [applyClass]="true" src="assets/img/icons/note-health-facility-yellow.svg"></svg-icon>

      <div fxFlexOffset="80px" fxFlex fxLayout="column">
        <!-- Struttura/Sede -->
        <ng-container *ngIf="venue">
          <h5 class="bold" translate="admin.campaignPendingRequests.VENUE"></h5>
          <h5>{{ venue.name }}</h5>
        </ng-container>
        <div *ngIf="!venue" class="loader spinner-small"></div>
      </div>

      <!-- Lista round -->
      <welion-button width="168px" size="medium" type="primary" hasLeftIcon (onClick)="goBackToVenues()"
        [label]="'admin.campaignPendingRequests.VENUE_LIST' | translate">
      </welion-button>
    </div>

  </ng-container>

  <!-- Pagina generica -->
  <bread-crumbs *ngIf="!withVenue" [fxFlexOffset]="withVenue ? '24px' : '12px'" firstLevel="PENDING_REQUESTS">
  </bread-crumbs>

  <div fxFlexOffset="24px" class="campaign-pending-requests-divider"></div>

  <div fxFlexOffset="12px" fxLayout="row" fxLayoutAlign="start center">
    <!-- Legenda -->
    <h5 class="legenda-title" translate="admin.campaignPendingRequests.LEGENDA"></h5>
    <!-- Approva slot -->
    <div fxLayout="row" fxFlexOffset="24px" fxLayoutAlign="start center">
      <svg-icon fxFlexOffset="2px" [applyClass]="true" src="assets/img/icons/icon-approve.svg"></svg-icon>
      <h5 fxFlexOffset="8px" translate="admin.campaignPendingRequests.legenda.APPROVE"></h5>
    </div>
    <!-- Revoca slot -->
    <div fxLayout="row" fxFlexOffset="24px" fxLayoutAlign="start center">
      <svg-icon [applyClass]="true" src="assets/img/icons/icon-revoke.svg"></svg-icon>
      <h5 fxFlexOffset="8px" translate="admin.campaignPendingRequests.legenda.REVOKE"></h5>
    </div>
    <!-- Approva descrizione -->
    <div fxLayout="row" fxFlexOffset="24px" fxLayoutAlign="start center">
      <svg-icon [applyClass]="true" src="assets/img/icons/icon-description.svg"></svg-icon>
      <h5 fxFlexOffset="8px" translate="admin.campaignPendingRequests.legenda.DESCRIPTION"></h5>
    </div>
  </div>

  <div fxFlexOffset="12px" class="campaign-pending-requests-divider"></div>

  <div fxFlexOffset="24px" class="pending-requests-container" fxLayout="column">

    <div fxLayout="row" fxLayoutAlign="space-between center" class="search-request">

      <div fxLayout="row">
        <div fxLayout="column" fxFlex="220px">
          <!-- Cerca struttura -->
          <h6 class="black bold" translate="admin.campaignPendingRequests.SEARCH_HEALTH_FACILITY"></h6>
          <!-- Nome struttura -->
          <welion-input withTransparency [bindValue]="searchStructure" (onChange)="searchStructureChange($event)"
            (onIconClicked)="searchRequests()" iconRight="assets/img/icons/icon-search.svg"
            [placeholder]="'admin.campaignPendingRequests.SEARCH_HEALTH_FACILITY_PLACEHOLDER' | translate"
            [disabled]="isLoading">
          </welion-input>
        </div>

        <div fxLayout="column" fxFlexOffset="16px" fxFlex="220px">
          <!-- Cerca sede -->
          <h6 class="black bold" translate="admin.campaignPendingRequests.SEARCH_VENUE"></h6>
          <!-- Nome sede -->
          <welion-input withTransparency [bindValue]="searchVenue" (onChange)="searchVenueChange($event)"
            (onIconClicked)="searchRequests()" iconRight="assets/img/icons/icon-search.svg"
            [placeholder]="'admin.campaignPendingRequests.SEARCH_VENUE_PLACEHOLDER' | translate" [disabled]="isLoading">
          </welion-input>
        </div>
      </div>

      <div fxLayout="column" fxFlex="260px">
        <!-- Mostra -->
        <h6 class="black bold" translate="admin.campaignPendingRequests.visibility.SHOW"></h6>
        <welion-select toTranslate withTransparency [disabled]="isLoading" [items]="requestVisibilities"
          [selectedItem]="selectedRequestVisibility" (change)="onChangeRequestsVisibility($event)"></welion-select>
      </div>

      <div fxLayout="row" fxFlex="300px">
        <!-- Dal -->
        <div fxLayout="column" fxFlex>
          <h6 class="black bold" translate="admin.campaignPendingRequests.SEARCH_DATE_FROM"></h6>
          <welion-datepicker withTransparency class="full-width" [value]="fromDateRequests"
            (dateChanged)="onChangeFromDateRequests($event)" placeholder="generic.DATE_PLACEHOLDER_EXAMPLE">
          </welion-datepicker>
        </div>
        <!-- Al -->
        <div fxLayout="column" fxFlex fxFlexOffset="16px">
          <h6 class="black bold" translate="admin.campaignPendingRequests.SEARCH_DATE_TO"></h6>
          <welion-datepicker withTransparency class="full-width" [value]="toDateRequests"
            (dateChanged)="onChangeToDateRequests($event)" placeholder="generic.DATE_PLACEHOLDER_EXAMPLE">
          </welion-datepicker>
        </div>
      </div>

    </div>

    <div [@showIf] *ngIf="!showAdvancedFilters" fxFlexOffset="20px" class="campaign-pending-requests-divider"></div>

    <div [@showIf] *ngIf="showAdvancedFilters" fxLayout="column" fxFlexOffset="20px"
      class="campaign-pending-requests-advanced-filters">

      <div fxLayout="row">
        <!-- Codice -->
        <div fxLayout="column" fxFlex>
          <h6 class="black bold" translate="admin.campaignPendingRequests.SEARCH_CODE"></h6>
          <welion-input withTransparency [bindValue]="searchCode" (onChange)="searchCodeChange($event)"
            (onIconClicked)="searchRequests()" iconRight="assets/img/icons/icon-search.svg"
            [placeholder]="'admin.campaignPendingRequests.SEARCH_CODE_PLACEHOLDER' | translate" [disabled]="isLoading">
          </welion-input>
        </div>

        <!-- Provincia -->
        <div fxLayout="column" fxFlex fxFlexOffset="16px">
          <h6 class="black bold" translate="admin.campaignPendingRequests.SEARCH_PROVINCE"></h6>
          <welion-select withTransparency [disabled]="isLoading" [items]="provinces"
            (change)="selectedProvinceChange($event)" [onClearInput]="clearSelectedProvince.asObservable()"
            [placeholder]="'admin.campaignPendingRequests.SEARCH_PROVINCE_PLACEHOLDER' | translate"></welion-select>
        </div>

        <!-- Città -->
        <div fxLayout="column" fxFlex fxFlexOffset="16px">
          <h6 class="black bold" translate="admin.campaignPendingRequests.SEARCH_CITY"></h6>
          <welion-select withTransparency
            [disabled]="isLoading || !selectedProvince || !selectedProvince.id || isLoadingCities" [items]="cities"
            (change)="selectedCityChange($event)" [onClearInput]="clearSelectedCity.asObservable()"
            [loading]="isLoadingCities"
            [placeholder]="'admin.campaignPendingRequests.SEARCH_CITY_PLACEHOLDER' | translate"></welion-select>
        </div>
      </div>

      <div fxLayout="row" fxFlexOffset="24px">
        <!-- Campagna -->
        <div fxLayout="column" fxFlex="292px">
          <h6 class="black bold" translate="admin.campaignPendingRequests.SEARCH_CAMPAIGN"></h6>
          <welion-input withTransparency [bindValue]="searchCampaign" (onChange)="searchCampaignChange($event)"
            (onIconClicked)="searchRequests()" iconRight="assets/img/icons/icon-search.svg"
            [placeholder]="'admin.campaignPendingRequests.SEARCH_CAMPAIGN_PLACEHOLDER' | translate"
            [disabled]="isLoading">
          </welion-input>
        </div>
      </div>

      <!-- Elimina tutti i filtri -->
      <div fxLayout="row" fxLayoutAlign="center" fxFlexOffset="24px">
        <div (click)="removeRequestsAdvancedFilters()" fxLayout="row">
          <svg-icon src="./assets/img/icons/icon-bin.svg" class="filter-accordion-size-transition" [applyClass]="true">
          </svg-icon>
          <h5 fxFlexOffset="8px" class="primary bold underline clickable"
            translate="admin.campaignPendingRequests.REMOVE_FILTERS"></h5>
        </div>
      </div>
    </div>

    <!-- Filtri avanzati -->
    <div fxFlexOffset="12px" fxLayout="row" fxLayoutAlign="end">
      <div fxLayout="row" fxLayoutAlign="start center" (click)="openCloseRequestsAdvancedFilters()">
        <h6 class="primary bold underline clickable">
          {{ 'admin.campaignPendingRequests.ADVANCED_FILTERS' | translate: { value: advancedFiltersCount || 0 } }}
        </h6>
        <svg-icon fxFlexOffset="8px" src="./assets/img/icons/chevron-down.svg" class="filter-accordion-size-transition"
          [ngClass]="{'opened': showAdvancedFilters}" [applyClass]="true">
        </svg-icon>
      </div>
    </div>

    <!-- Loader -->
    <div *ngIf="isLoadingCount" class="loader spinner-big"></div>

    <ng-container *ngIf="!isLoadingCount">
      <ng-container *ngIf="requestsCount > 0 else noRequests">

        <div id="admin-requests-anchor" fxFlexOffset="10px"></div>

        <!-- Table header -->
        <div fxFlexOffset="10px" fxLayout="row" fxLayoutAlign="start center">
          <!-- Struttura -->
          <h5 class="bold" translate="admin.campaignPendingRequests.table.HEALTH_FACILITY" fxFlex="35"></h5>
          <!-- Dove -->
          <h5 class="bold center" translate="admin.campaignPendingRequests.table.WHERE" fxFlex="15"></h5>
          <!-- Data -->
          <h5 class="bold center" translate="admin.campaignPendingRequests.table.WHEN" fxFlex="15"></h5>
          <!-- Slot -->
          <h5 class="bold center" translate="admin.campaignPendingRequests.table.SLOTS" fxFlex="10"></h5>
          <!-- Tipologia -->
          <h5 class="bold center" translate="admin.campaignPendingRequests.table.TYPOLOGY" fxFlex="15"></h5>

          <div fxFlex="10"></div>
        </div>

        <!-- Loader -->
        <div *ngIf="isLoading" class="loader spinner-big"></div>

        <!-- Table rows -->
        <ng-container *ngIf="!isLoading">
          <div *ngFor="let request of requests; let first = first" class="request-row" fxLayout="row"
            fxLayoutAlign="start center" [fxFlexOffset]="first ? '8px' : '0px'">
            <!-- Struttura -->
            <div fxFlex="35" fxLayout="column">
              <h6 class="black">{{ request.healthFacilitySupplierName + ' - ' + request.name }}</h6>
              <ng-container *ngIf="request.requestType !== 'EVENT_DESCRIPTION_UPDATE_REQUEST'">
                <h6 class="black bold" fxFlexOffset="4px">{{ request.campaignTitle }}</h6>
              </ng-container>
            </div>
            <!-- Dove -->
            <h6 class="black center" fxFlex="15">{{ request.address | titlecase }}</h6>
            <!-- Data -->
            <h6 class="black center" fxFlex="15">{{ request.requestDate | date: 'dd/MM/yyyy' }}</h6>
            <!-- Slot -->
            <h6 class="black center" fxFlex="10">{{ request.slotToProcess }}</h6>
            <!-- Tipologia -->
            <div fxFlex="15" fxLayout="column" fxLayoutAlign="center center">
              <!-- Approva -->
              <ng-container *ngIf="request.requestType === 'EVENT_APPROVATION_SLOT_REQUEST'">
                <svg-icon [applyClass]="true" src="assets/img/icons/icon-approve.svg"></svg-icon>
              </ng-container>
              <!-- Revoca -->
              <ng-container *ngIf="request.requestType === 'EVENT_REVOKE_SLOT_REQUEST'">
                <svg-icon [applyClass]="true" src="assets/img/icons/icon-revoke.svg"></svg-icon>
              </ng-container>
              <ng-container *ngIf="request.requestType === 'EVENT_DESCRIPTION_UPDATE_REQUEST'">
                <svg-icon [applyClass]="true" src="assets/img/icons/icon-description.svg"></svg-icon>
              </ng-container>
            </div>
            <!-- Vedi Slot -->
            <h6 fxFlex="10" class="black bold underline clickable" (click)="goToSlotRequests(request)">
              <ng-container *ngIf="request.requestType === 'EVENT_DESCRIPTION_UPDATE_REQUEST'">
                {{ 'admin.campaignPendingRequests.table.SHOW_DESCRIPTION' | translate }}
              </ng-container>
              <ng-container *ngIf="request.requestType !== 'EVENT_DESCRIPTION_UPDATE_REQUEST'">
                {{ 'admin.campaignPendingRequests.table.SHOW_SLOT' | translate }}
              </ng-container>
            </h6>
          </div>
        </ng-container>
        <!-- Paginatore -->
        <welion-paginator fxFlexOffset="20px" [selectedIndex]="pageSelectedIndex" [isLoading]="isLoading"
          [paginationLength]="paginationLength" [itemsCount]="requestsCount"
          (onClickPage)="getVenueRequestsToProcess($event)"></welion-paginator>
      </ng-container>

    </ng-container>
  </div>

</div>

<ng-template #noRequests>
  <div fxLayout="column" fxLayoutAlign="start center">
    <svg-icon fxFlexOffset="40px" [applyClass]="true" src="assets/img/icons/note-health-facility-red.svg"></svg-icon>

    <!-- Nessuna richiesta da gestire -->
    <h4 fxFlexOffset="32px" translate="admin.campaignPendingRequests.NO_REQUESTS"></h4>
  </div>
</ng-template>

import { createReducer, on, Action } from '@ngrx/store';
import * as fromApp from '../../ngrx/app.reducers';

export interface FeatureState extends fromApp.AppState {
    admin: AdminState
}

export interface AdminState {
}

// Store iniziale
const initialState: AdminState = {
};


export const createAdminReducer = createReducer(
    initialState
)

export function adminReducer(state: AdminState | undefined, action: Action) {
    return createAdminReducer(state, action);
}
import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';

import * as CoreActions from "../../core/ngrx/core.actions";
import * as fromApp from "../../ngrx/app.reducers";
import { InfoAttendance, InfoCampaign, InfoRound, InfoVenue } from 'atfcore-commonclasses/bin/classes/campaignmanager';

import { RedirectService } from 'src/app/shared/services/redirect.service';
import { getWarningModal } from 'src/app/utils/utils';
import { ScrollTo } from 'src/app/core/services/scroll-to.service';
import { InfoVenueSlot, RentService } from 'src/app/structure/services/rent.service';
import { DropdownItem } from 'src/app/shared/models/dropdown.model';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { SidebarService } from 'src/app/shared/services/sidebar.service';
import { ModalService } from 'src/app/shared/components/modal/modal.service';

@Component({
  selector: 'app-help-desk-campaign-reschedule-reservation',
  templateUrl: './helpDeskCampaignRescheduleReservation.component.html',
  styleUrls: ['./helpDeskCampaignRescheduleReservation.component.scss']
})
export class HelpDeskCampaignRescheduleReservationComponent implements OnInit, OnDestroy {

  campaignId: string = '';
  campaign?: InfoCampaign;

  round?: InfoRound;
  roundStartDate?: Date;
  roundEndDate?: Date;

  userId: string = '';
  attendances: DropdownItem[] = [];

  isLoading: boolean = false;
  isLoadingCount: boolean = false;
  isLoadingBase: boolean = true;

  slots: InfoVenueSlot[] = [];
  slotsCount: number = 0;
  selectedSlot?: InfoVenueSlot;

  pageSelectedIndex: number = 0;
  readonly paginationLength: number = 50;

  selectedVenue?: InfoVenue;
  selectedVenueTab: number = 0;

  venueMapData?: {
    lat: number,
    lng: number
  };

  firstSearchDone: boolean = false;
  userHasPreassignedVenue: boolean = false;

  readonly kmPreferences: DropdownItem[] = [
    {
      id: '10',
      name: '10'
    }, {
      id: '20',
      name: '20'
    }, {
      id: '30',
      name: '30'
    }, {
      id: '50',
      name: '50'
    }, {
      id: '100',
      name: '100'
    }
  ];

  searchForm = new FormGroup({
    'attendance': new FormControl(undefined),
    'address': new FormControl(undefined),
    'disabledAddress': new FormControl(false),
    'latitude': new FormControl(undefined, Validators.required),
    'longitude': new FormControl(undefined, Validators.required),
    'kmPreference': new FormControl(this.kmPreferences[0], Validators.required),
    'day': new FormControl(undefined, Validators.required),
    'fromTime': new FormControl(undefined),
    'toTime': new FormControl(undefined),
  });

  get attendance() {
    return this.searchForm.get('attendance') as FormControl;
  }

  get attendanceValue(): InfoAttendance {
    return this.attendance.value ? this.attendance.value.content : undefined;
  }

  get address() {
    return this.searchForm.get('address') as FormControl;
  }

  get disabledAddress() {
    return this.searchForm.get('disabledAddress') as FormControl;
  }

  get latitude() {
    return this.searchForm.get('latitude') as FormControl;
  }

  get longitude() {
    return this.searchForm.get('longitude') as FormControl;
  }

  get kmPreference() {
    return this.searchForm.get('kmPreference') as FormControl;
  }

  get day() {
    return this.searchForm.get('day') as FormControl;
  }

  get fromTime() {
    return this.searchForm.get('fromTime') as FormControl;
  }

  get toTime() {
    return this.searchForm.get('toTime') as FormControl;
  }

  searchLatitude: string = '';
  searchLongitude: string = '';
  searchRange: string = '';
  searchFromTime: Date = new Date();
  searchToTime: Date = new Date();

  private _unsubscribeSignal$: Subject<void> = new Subject();

  selectedOrders: any = {
    "VENUE_NAME": null,
    "ADDRESS": null,
    "DISTANCE": null,
    "DATE": null,
    "TIME": null,
  }
  orderByFields: string[] = [];

  constructor(
    private route: ActivatedRoute,
    private scrollTo: ScrollTo,
    private appStore: Store<fromApp.AppState>,
    private translate: TranslateService,
    private rentService: RentService,
    private redirectService: RedirectService,
    private sidebarService: SidebarService,
    private modalService: ModalService
  ) { }

  ngOnInit() {
    this.scrollTo.header();
    this.campaignId = this.route.snapshot.paramMap.get('campaignId') || '';
    this.userId = this.route.snapshot.paramMap.get('userId') || '';

    if (this.campaignId && this.userId) {
      this.getCampaign();
    } else {
      this.redirectService.goToHome();
    }
  }

  goBackToCampaign() {
    this.redirectService.goToHelpDeskCampaign(this.campaignId);
  }

  ngOnDestroy() {
    this._unsubscribeSignal$.next();
    this._unsubscribeSignal$.unsubscribe();
  }

  /**
   * @description Mostra una modale con un messaggio di errore
   * @param modalId
   * @param error
   */
  dispatchWarningModal(modalId: string, error: string) {
    const messageObj = getWarningModal(this.translate, modalId, error);
    this.appStore.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
  }

  getCampaign() {
    this.rentService.getCampaign(this.campaignId)
      .pipe(takeUntil(this._unsubscribeSignal$.asObservable()))
      .subscribe((result) => {
        if (!result || result.error) {
          this.dispatchWarningModal('100', result.error || '');
          this.resetPagination();
        } else {
          this.campaign = result.response;
          this.getAttendances();
        }
      }, (err) => {
        if (err && err.message) {
          this.dispatchWarningModal('101', err.message);
        }
        this.resetPagination();
      });
  }

  getAttendances() {
    this.rentService.getAttendancesForHelpDesk(this.campaignId, this.userId)
      .pipe(takeUntil(this._unsubscribeSignal$.asObservable()))
      .subscribe((result) => {
        if (!result || result.error) {
          this.dispatchWarningModal('100', result.error || '');
          this.resetPagination();
        } else {
          this.attendances = [];

          if (result.response && result.response.length) {

            result.response.forEach(attendance => {
              this.attendances.push({
                id: attendance.attendanceId,
                name: (attendance.surname + ' ' + attendance.name),
                content: attendance
              });
            });

            this.onChangeAttendance(this.attendances[0]);

            this.userHasPreassignedVenue = result.response[0].preMatchedVenue ? true : false;

            if (this.userHasPreassignedVenue && this.attendanceValue && this.attendanceValue.venue) {
              this.address.setValue(this.attendanceValue.venue.address);
              this.latitude.setValue(this.attendanceValue.venue.latitude);
              this.longitude.setValue(this.attendanceValue.venue.longitude);
              this.address.disable();
              this.kmPreference.disable();
            } else {
              this.disabledAddress.setValidators(Validators.requiredTrue);
              this.disabledAddress.updateValueAndValidity();
            }

          } else {
            this.isLoadingBase = false;
            this.dispatchWarningModal('555', 'NO_VALID_RESERVATIONS');
          }
        }
      }, (err) => {
        if (err && err.message) {
          this.dispatchWarningModal('101', err.message);
        }
        this.resetPagination();
      });
  }

  getRound(roundId: string) {

    this.rentService.getRound(roundId)
      .pipe(takeUntil(this._unsubscribeSignal$.asObservable()))
      .subscribe((result) => {
        if (!result || result.error) {
          this.dispatchWarningModal('100', result.error || '');
          this.resetPagination();
        } else {
          this.round = result.response;

          if (this.round) {
            this.roundEndDate = new Date(this.round.endDate);
            this.roundEndDate.setHours(23, 59, 59, 0);

            const now = new Date();
            const startDate = new Date(this.round.startDate);
            if (now.getTime() > startDate.getTime()) {
              now.setHours(0, 0, 0, 0);
              this.day.setValue(new Date());
              this.roundStartDate = new Date(now);
            } else {
              startDate.setHours(0, 0, 0, 0);
              this.day.setValue(startDate);
              this.roundStartDate = new Date(startDate);
            }
          }
        }
        this.isLoadingBase = false;
      }, (err) => {
        if (err && err.message) {
          this.dispatchWarningModal('101', err.message);
        }
        this.resetPagination();
      });
  }

  onChangeAttendance(value: DropdownItem) {
    this.attendance.setValue(value);
    if (this.attendanceValue && this.attendanceValue.venue) {

      if (this.attendanceValue.venue) {
        this.address.setValue(this.attendanceValue.venue.address);
        this.latitude.setValue(this.attendanceValue.venue.latitude);
        this.longitude.setValue(this.attendanceValue.venue.longitude);
        this.address.disable();
        this.disabledAddress.setValue(true);
      }

      this.getRound(this.attendanceValue.roundId);
    }

  }

  //#region Round venues

  searchRoundSlots() {
    if (this.searchForm.valid) {

      let from: Date = new Date(this.day.value);
      let fromTime: Date | null = null;
      if (this.fromTime.value) {
        fromTime = new Date(this.fromTime.value);
      }
      if (fromTime) {
        from.setHours(fromTime.getHours(), fromTime.getMinutes(), 0, 0);
      } else {
        from.setHours(0, 0, 0, 0);
      }
      let to: Date = new Date(this.day.value);
      let toTime: Date | null = null;

      if (this.toTime.value) {
        toTime = new Date(this.toTime.value);
      }

      if (toTime) {
        to.setHours(toTime.getHours(), toTime.getMinutes(), 0, 0);
      } else {
        to.setHours(23, 59, 0, 0);
      }


      if (from.getTime() < new Date().getTime()) {
        this.dispatchWarningModal('018', 'FROM_IN_PAST');
        return;
      }

      if (from.getTime() > to.getTime()) {
        this.dispatchWarningModal('018', 'TIME_ERROR');
        return;
      }

      this.searchLatitude = this.latitude.value;
      this.searchLongitude = this.longitude.value;
      if (this.userHasPreassignedVenue) {
        this.searchRange = '0';
      } else {
        this.searchRange = this.kmPreference.value.id;
      }
      this.searchFromTime = new Date(from);
      this.searchToTime = new Date(to);

      this.countRoundSlots();
    }
  }

  countRoundSlots() {
    this.isLoadingCount = true;
    this.isLoading = true;
    this.firstSearchDone = true;

    const preMatchedVenueaId = this.userHasPreassignedVenue ? this.attendanceValue.venueId : undefined;

    this.rentService.countSlotsForReservationMoving(
      this.attendanceValue.attendanceId,
      this.searchLatitude,
      this.searchLongitude,
      this.searchRange,
      this.searchFromTime,
      this.searchToTime,
      preMatchedVenueaId)
      .subscribe((result) => {

        if (!result || result.error) {
          this.dispatchWarningModal('100', result.error || '');
          this.resetPagination();
        } else {
          this.slotsCount = result.response;

          if (this.slotsCount > 0) {
            this.getSlotsForReservationMoving();
          } else {
            this.resetPagination();
          }
          this.isLoadingCount = false;
        }
      }, (err) => {
        if (err && err.message) {
          this.dispatchWarningModal('101', err.message);
        }
        this.resetPagination();
      });
  }

  getSlotsForReservationMoving(index?: number) {

    this.isLoading = true;

    const pageSelectedIndex = index ? index : 0;
    if (index === 0 || index) {
      this.scrollTo.element('admin-campaign-round-reschedule-reservation-anchor');
      this.pageSelectedIndex = index;
    }

    const preMatchedVenueaId = this.userHasPreassignedVenue ? this.attendanceValue.venueId : undefined;

    this.rentService.getSlotsForReservationMoving(
      this.attendanceValue.attendanceId,
      (pageSelectedIndex * this.paginationLength),
      this.paginationLength,
      this.searchLatitude,
      this.searchLongitude,
      this.searchRange,
      this.searchFromTime,
      this.searchToTime,
      preMatchedVenueaId,
      this.orderByFields
    )
      .pipe(takeUntil(this._unsubscribeSignal$.asObservable()))
      .subscribe((result) => {

        if (!result || result.error) {
          this.dispatchWarningModal('102', result.error || '');
          this.resetPagination();
        } else {
          this.slots = result.response;
          this.isLoading = false;
        }
      }, (err) => {
        if (err && err.message) {
          this.dispatchWarningModal('103', err.message);
        }
        this.resetPagination();
      });
  }

  //#endregion Round venues

  resetPagination() {
    this.isLoading = false;
    this.isLoadingCount = false;
    this.isLoadingBase = false;

    this.slots = [];
    this.slotsCount = 0;
  }

  //#region Filters

  onEditAddress() {
    this.disabledAddress.setValue(false);
    this.address.enable();
  }

  selectedPlace(place: any) {
    if (!place || place.geometry === undefined || place.geometry === null) {
      return;
    }
    if (place.address_components && place.address_components.length) {
      this.latitude.setValue(place.geometry.location.lat());
      this.longitude.setValue(place.geometry.location.lng());
      this.address.setValue(place.formatted_address);
      this.address.disable();
      this.disabledAddress.setValue(true);
    }
  }

  onChangeKMPreference(value: DropdownItem) {
    this.kmPreference.setValue(value);
  }

  onDayChange(date: Date) {
    date = new Date(date);
    date.setHours(0, 0, 0, 0);
    this.day.setValue(date);
  }

  //#endregion Filters

  onVenueTabClick(index: number) {
    this.selectedVenueTab = index;
  }

  showVenueModal(slot: InfoVenueSlot) {
    this.selectedVenueTab = 0;

    this.selectedVenue = slot;

    if (this.selectedVenue && this.selectedVenue.latitude && this.selectedVenue.longitude) {
      this.venueMapData = {
        lat: parseFloat(this.selectedVenue.latitude),
        lng: parseFloat(this.selectedVenue.longitude)
      };
    }

    this.sidebarService.setShowNav(true);
  }

  closeVenueCard() {
    this.selectedVenue = undefined;
    this.sidebarService.setShowNav(false);
  }

  confirmAttendanceReschedule() {
    if (this.selectedSlot) {
      this.isLoadingBase = true;
      this.isLoadingCount = true;

      const attendanceId = this.attendanceValue.attendanceId || '';

      this.closeAttendanceRescheduleModal();

      this.rentService.deleteReservationForAdmin(attendanceId, true)
        .pipe(takeUntil(this._unsubscribeSignal$.asObservable()))
        .subscribe((result) => {
          if (!result || result.error) {
            this.dispatchWarningModal('200', result.error || '');
            this.isLoadingBase = false;
            this.isLoadingCount = false;
          } else {
            this.confirmReservation();
          }
        }, (err) => {
          if (err && err.message) {
            this.dispatchWarningModal('201', err.message);
          }
          this.isLoadingBase = false;
          this.isLoadingCount = false;
        });
    }
  }

  confirmReservation() {
    if (this.selectedSlot) {

      const venueId = this.userHasPreassignedVenue ? this.attendanceValue.venueId : this.selectedSlot.venueId;
      const roundId = this.round ? this.round.roundId : '';

      this.rentService.confirmReservationForHelpDesk(roundId, venueId, this.selectedSlot.slotId, this.attendanceValue.userId)
        .pipe(takeUntil(this._unsubscribeSignal$.asObservable()))
        .subscribe((result) => {
          if (!result || result.error) {
            this.dispatchWarningModal('200', result.error || '');
          } else {
            this.redirectService.goToHelpDeskCampaign(this.campaignId);
          }
          this.isLoadingBase = false;
          this.isLoadingCount = false;
        }, (err) => {
          if (err && err.message) {
            this.dispatchWarningModal('201', err.message);
          }
          this.isLoadingBase = false;
          this.isLoadingCount = false;
        });
    }
  }

  /**
   * @description Apre la modale per la richedulazione di un AD
   */
  openAttendanceRescheduleModal(slot: InfoVenueSlot) {
    if (slot) {
      this.selectedSlot = slot;
      this.modalService.open('rescheduleAttendance');
    }
  }

  /**
   * @description Chiude la modale per la richedulazione di un AD
   */
  closeAttendanceRescheduleModal() {
    this.modalService.close('rescheduleAttendance');
  }


  updateOrder(order: any, field: string) {
    this.orderByFields = this.orderByFields.filter((x: string) => !x.includes(field));
    if (order.id != "NO") {
      this.orderByFields.push(field + '_' + order.id);
    }
    this.selectedOrders[field] = order;
    this.getSlotsForReservationMoving()
  }
}

import { ActivatedRoute } from "@angular/router";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { Component, OnDestroy, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Subject } from 'rxjs';
import { Store } from "@ngrx/store";
import { takeUntil } from "rxjs/operators";

import * as fromApp from "../../../ngrx/app.reducers";
import * as CoreActions from "../../../core/ngrx/core.actions";
import { FamilyMemberTypes, InfoCampaign } from "atfcore-commonclasses/bin/classes/campaignmanager";

import { RedirectService } from 'src/app/shared/services/redirect.service';
import { getWarningModal } from "src/app/utils/utils";
import { ScrollTo } from "src/app/core/services/scroll-to.service";
import { RentService } from "src/app/structure/services/rent.service";
import { AnagService, ClientGroup } from "src/app/auth/services/anag.service";
import { DropdownItem } from "src/app/shared/models/dropdown.model";

@Component({
  selector: 'app-admin-add-campaign-customer-cap',
  templateUrl: './addCampaignCustomerCap.component.html',
  styleUrls: ['./addCampaignCustomerCap.component.scss']
})
export class AdminAddCampaignCustomerCapComponent implements OnInit, OnDestroy {

  isLoading: boolean = false;

  campaignId: string = '';
  campaign?: InfoCampaign;

  groupId: string = '';
  customer?: ClientGroup;

  addClientCapLimitForm = new FormGroup({
    'capLimit': new FormControl(undefined, [Validators.pattern(/^[-+0-9 ]*$/)]),
    'selectedFamilyInsertion': new FormControl(undefined, Validators.required),
    'selectedCapMaxFamiliars': new FormControl(undefined),
    'boughtShots': new FormControl(undefined, [Validators.pattern(/^[-+0-9 ]*$/)]),
  });

  get capLimit() {
    return this.addClientCapLimitForm.get('capLimit') as FormControl;
  }

  get selectedFamilyInsertion() {
    return this.addClientCapLimitForm.get('selectedFamilyInsertion') as FormControl;
  }

  get selectedCapMaxFamiliars() {
    return this.addClientCapLimitForm.get('selectedCapMaxFamiliars') as FormControl;
  }

  get boughtShots() {
    return this.addClientCapLimitForm.get('boughtShots') as FormControl;
  }

  private _unsubscribeSignal$: Subject<void> = new Subject();

  familyInsertionOptions: DropdownItem[] = [
    {
      id: 'NO',
      name: 'generic.NO'
    }, {
      id: 'YES',
      name: 'generic.YES'
    }
  ]

  capMaxFamiliarsOptions: DropdownItem[] = [
    {
      id: '1',
      name: '1'
    }, {
      id: '2',
      name: '2'
    }, {
      id: '3',
      name: '3'
    }, {
      id: '4',
      name: '4'
    }, {
      id: '5',
      name: '5'
    }, {
      id: '6',
      name: '6'
    }, {
      id: '7',
      name: '7'
    }, {
      id: '8',
      name: '8'
    }, {
      id: '9',
      name: '9'
    }, {
      id: '10',
      name: '10'
    }
  ]
  
  familiarTypeOptions: DropdownItem[] = [];

  selectedFamiliars: string[] = [];

  constructor(
    private route: ActivatedRoute,
    private appStore: Store<fromApp.AppState>,
    private translate: TranslateService,
    private redirectService: RedirectService,
    private scrollTo: ScrollTo,
    private rentService: RentService,
    private anagService: AnagService
  ) {
  }

  ngOnInit() {
    this.scrollTo.header();
    this.selectedFamilyInsertion.setValue(this.familyInsertionOptions[0]);

    this.translate.get(
      [
        'admin.addCampaignCustomerCap.SPOUSE',
        'admin.addCampaignCustomerCap.MINOR_CHILDREN',
        'admin.addCampaignCustomerCap.ADULT_CHILDREN',
        'admin.addCampaignCustomerCap.SIBLINGS',
        'admin.addCampaignCustomerCap.COHABITANT',
        'admin.addCampaignCustomerCap.FATHER_OR_MOTHER_IN_LAW',
        'admin.addCampaignCustomerCap.BROTHER_OR_SISTER_IN_LAW',
        'admin.addCampaignCustomerCap.PARENT'
      ])
      .pipe(takeUntil(this._unsubscribeSignal$.asObservable()))
      .subscribe(translations => {
        this.familiarTypeOptions = [
          {
            id: FamilyMemberTypes.SPOUSE,
            name: translations['admin.addCampaignCustomerCap.SPOUSE']
          },
          {
            id: FamilyMemberTypes.MINOR_CHILDREN,
            name: translations['admin.addCampaignCustomerCap.MINOR_CHILDREN']
          },
          {
            id: FamilyMemberTypes.ADULT_CHILDREN,
            name: translations['admin.addCampaignCustomerCap.ADULT_CHILDREN']
          },
          {
            id: FamilyMemberTypes.SIBLINGS,
            name: translations['admin.addCampaignCustomerCap.SIBLINGS']
          },
          {
            id: FamilyMemberTypes.COHABITANT,
            name: translations['admin.addCampaignCustomerCap.COHABITANT']
          },
          {
            id: FamilyMemberTypes.FATHER_OR_MOTHER_IN_LAW,
            name: translations['admin.addCampaignCustomerCap.FATHER_OR_MOTHER_IN_LAW']
          },
          {
            id: FamilyMemberTypes.BROTHER_OR_SISTER_IN_LAW,
            name: translations['admin.addCampaignCustomerCap.BROTHER_OR_SISTER_IN_LAW']
          },
          {
            id: FamilyMemberTypes.PARENT,
            name: translations['admin.addCampaignCustomerCap.PARENT']
          }
        ]
      })

    this.campaignId = this.route.snapshot.paramMap.get('campaignId') || '';
    this.groupId = this.route.snapshot.paramMap.get('groupId') || '';

    if (this.campaignId && this.groupId) {
      this.isLoading = true;
      this.getCampaign();
    } else {
      this.redirectService.goToHome();
    }
  }

  getCampaign() {

    this.isLoading = true;

    this.rentService.getCampaign(this.campaignId)
      .pipe(takeUntil(this._unsubscribeSignal$.asObservable()))
      .subscribe((result) => {
        if (!result || result.error) {
          this.dispatchWarningModal('100', result.error || '');
          this.isLoading = false;
        } else {
          this.campaign = result.response;
          this.getCustomer();
        }
      }, (err) => {
        if (err && err.message) {
          this.dispatchWarningModal('101', err.message);
        }
        this.isLoading = false;
      });
  }

  getCustomer() {

    this.anagService.getCustomersForAdmin(this.groupId)
      .pipe(takeUntil(this._unsubscribeSignal$.asObservable()))
      .subscribe((result) => {
        if (!result || result.error) {
          this.dispatchWarningModal('044', result.error || '');
        } else if (result.response) {
          this.customer = result.response;

          if (this.customer) {
            const baseCustomer: any = this.customer;
            const campaignId = this.campaignId.split('-').join('_');
            let campaignCustomerCap = baseCustomer['MAX_BOOKING_CAP_' + campaignId];
            let campaignCustomerAddMembers = baseCustomer['CAN_ADD_FAMILITY_MEMBERS_' + campaignId];
            let campaignCustomerMaxCap = baseCustomer['MAX_FAMILY_MEMBERS_CAP_' + campaignId];
            let campaignCustomerAllowedFamilyMembers = baseCustomer['ALLOWED_FAMILIY_MEMBERS_' + campaignId];
            let campaignCustomerBoughtShots = baseCustomer['BOUGHT_SHOTS_' + campaignId];

            if(campaignCustomerCap) {
              this.capLimit.setValue(campaignCustomerCap);
            }
            if(campaignCustomerAddMembers) {
              this.selectedFamilyInsertion.setValue(this.familyInsertionOptions[1]);
            }
            if(campaignCustomerMaxCap) {
              this.selectedCapMaxFamiliars.setValue(this.capMaxFamiliarsOptions[campaignCustomerMaxCap - 1]);
            }
            if(campaignCustomerBoughtShots) {
              this.boughtShots.setValue(campaignCustomerBoughtShots);
            }
            if(campaignCustomerAllowedFamilyMembers && JSON.parse(campaignCustomerAllowedFamilyMembers) && JSON.parse(campaignCustomerAllowedFamilyMembers).length) {
              let allowedFamiliars = JSON.parse(campaignCustomerAllowedFamilyMembers);
              allowedFamiliars.forEach((x: any) => {
                this.selectedFamiliars.push(x);
              })
              
            }
          }
        }
        this.isLoading = false;
      }, (err) => {
        if (err && err.message) {
          this.dispatchWarningModal('045', err.message);
        }
        this.isLoading = false;
      });
  }

  ngOnDestroy() {
    this._unsubscribeSignal$.next();
    this._unsubscribeSignal$.unsubscribe();
  }

  /**
   * @description Va all'homepage delle SS
   */
  goBack() {
    this.redirectService.goToAdminCampaignClient(this.campaignId, this.groupId);
  }

  goToFAQ() {

  }

  confirmAddCapLimit() {
    if (this.addClientCapLimitForm.valid) {
      this.isLoading = true;

      let canAddFamiliars = this.selectedFamilyInsertion.value.id == 'YES' ? true : false;

      this.anagService.addBookingCapByCampaignAndCustomerForAdmin(this.campaignId, this.groupId, this.capLimit.value, canAddFamiliars, parseInt(this.selectedCapMaxFamiliars.value?.id), this.selectedFamiliars, this.boughtShots.value)
        .pipe(takeUntil(this._unsubscribeSignal$.asObservable()))
        .subscribe((result) => {
          if (!result || result.error) {
            this.dispatchWarningModal('041', result.error || '');
          } else if (result.response) {
            this.redirectService.goToAdminCampaignClient(this.campaignId, this.groupId);
          }
          this.isLoading = false;
        }, (err) => {
          if (err && err.message) {
            this.dispatchWarningModal('042', err.message);
          }
          this.isLoading = false;
        });
    }
  }

  /**
   * @description Mostra una modale con un messaggio di errore
   * @param modalId
   * @param error
   */
  dispatchWarningModal(modalId: string, error: string) {
    const messageObj = getWarningModal(this.translate, modalId, error);
    this.appStore.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
  }

  onSelectedFamilyInsertionChanged(value: DropdownItem) {
    this.selectedFamilyInsertion.setValue(value);
    if(value.id == 'NO') {
      this.selectedCapMaxFamiliars.setValue(null);
      this.selectedFamiliars = [];
    } else {
      this.selectedCapMaxFamiliars.setValue(this.capMaxFamiliarsOptions[0]);
    }
  }

  onSelectedCapMaxFamiliarsChanged(value: DropdownItem) {
    this.selectedCapMaxFamiliars.setValue(value);
  }

  familiarIsChecked(familiar: DropdownItem): boolean {
    return this.selectedFamiliars.filter(selectedFamiliar => selectedFamiliar === familiar.id).length > 0;
  }

  onFamiliarChecked(customer: DropdownItem, checked: boolean) {
    if (checked) {
      this.selectedFamiliars.push(customer.id);
    } else {
      let index = this.selectedFamiliars.findIndex(selectedFamiliar => selectedFamiliar === customer.id);
      this.selectedFamiliars.splice(index, 1);
    }
  }

  isAddClientCapLimitFormInvalid() {
    if(this.selectedCapMaxFamiliars.value && this.selectedCapMaxFamiliars.value.id){
      return !this.selectedFamiliars.length;
    } else {
      return false;
    }
  }

}

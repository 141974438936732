/*
*    Servizio che gestisce i vari redirect
*/

import { Injectable, OnDestroy } from "@angular/core";
import { Router } from "@angular/router";
import { Store } from "@ngrx/store";
import { Observable, Subscription, combineLatest } from "rxjs";
import * as fromApp from '../../ngrx/app.reducers';
import * as CoreActions from '../../core/ngrx/core.actions';
import { UrlService } from "./url.service";
import { Location } from "@angular/common";

@Injectable()
export class RedirectService implements OnDestroy {
  isMainMenuSidebarOpened: boolean = false;
  loggedUser: any;
  isStructure: boolean = false;
  isUser: boolean = false;
  isAdmin: boolean = false;
  isLimitedAdmin: boolean = false;
  isHelpDesk: boolean = false;
  isClient: boolean = false;
  combinedSelectes$: Subscription;

  constructor(
    private store: Store<fromApp.AppState>,
    private router: Router,
    private location: Location,
    private urlService: UrlService
  ) {
    // Recupero dallo Store applicativo le variabili che mi indicano se le sidenav sono aperte
    const isMainMenuSidebarOpened$: Observable<boolean> = this.store.select(fromApp.isMainMenuSidebarOpened);
    const isStructure$: Observable<boolean> = this.store.select(fromApp.getIsStructure)
    const isUser$: Observable<boolean> = this.store.select(fromApp.getIsUser);
    const isAdmin$: Observable<boolean> = this.store.select(fromApp.getIsAdmin);
    const isLimitedAdmin$: Observable<boolean> = this.store.select(fromApp.getIsLimitedAdmin);
    const isHelpDesk$: Observable<boolean> = this.store.select(fromApp.getIsHelpDesk);
    const isClient$: Observable<boolean> = this.store.select(fromApp.getIsClient);
    const loggedUser$: Observable<any> = this.store.select(fromApp.getLoggedUser);
    this.combinedSelectes$ = combineLatest([isMainMenuSidebarOpened$, loggedUser$, isStructure$, isUser$, isAdmin$, isHelpDesk$, isClient$, isLimitedAdmin$])
      .subscribe(
        ([isMainMenuSidebarOpened, loggedUser, isStructure, isUser, isAdmin, isHelpDesk, isClient, isLimitedAdmin]) => {
          if (loggedUser && loggedUser.user) {
            this.loggedUser = loggedUser && loggedUser.user;
            this.isStructure = isStructure;
            this.isUser = isUser;
            this.isAdmin = isAdmin;
            this.isHelpDesk = isHelpDesk;
            this.isClient = isClient;
            this.isMainMenuSidebarOpened = isMainMenuSidebarOpened;
            this.isLimitedAdmin = isLimitedAdmin;
          }
        });
  }

  // Verifica se sono in una particolare pagina
  isThisCurrentPage(page: string): boolean {
    return page && this.router && this.router.url && this.router.url.indexOf(page) !== -1 ? true : false;
  }

  isThisCurrentPages(pages: string[]): boolean {
    if (pages && pages.length && this.router && this.router.url) {
      return pages.filter(x => this.router.url.indexOf(x) !== -1).length > 0;
    } else return false;
  }

  // Chiude la sidebar di sinistra contente il menu principale
  closeSidenavMainMenu() {
    this.store.dispatch(CoreActions.CloseMainMenuSidebar());
  }

  goToSignin() {
    this.router.navigate(['/signin']);
  }

  goToLogin() {
    if (this.isAdmin || this.isLimitedAdmin) {
      this.goToAdminLogin();
    } else if (this.isStructure) {
      this.goToStructureLogin();
    } else if (this.isHelpDesk) {
      this.goToHelpDeskLogin();
    } else if (this.isClient) {
      this.goToClientLogin();
    } else {
      this.goToUserLogin();
    }
  }

  goToAdminLogin() {
    this.router.navigate(['/localLogin']);
  }

  goToStructureLogin() {
    this.router.navigate(['/structureLogin']);
  }

  goToHelpDeskLogin() {
    this.router.navigate(['/helpDeskLogin']);
  }

  goToClientLogin() {
    this.router.navigate(['/clientLogin']);
  }

  goToUserLogin(redirect?: boolean) {
    if (redirect) {
      let applicationContext = this.urlService.getApplicationUrl().url;
      applicationContext = `${applicationContext}/#/login`;
      window.open(applicationContext, '_blank');
    } else {
      this.router.navigate(['/login']);
    }
  }

  // Redirect alla home page dell'utente loggato
  goToHome(venueId?: string) {
    if (this.isAdmin || this.isLimitedAdmin) {
      this.router.navigate(['/admin/home']);
    } else if (this.isStructure) {
      if (venueId) {
        this.router.navigate([`/structure/home/${venueId}`]);
      } else {
        this.router.navigate(['/structure/home']);
      }
    } else if (this.isUser) {
      this.router.navigate(['/user/home']);
    } else if (this.isHelpDesk) {
      this.router.navigate(['/helpDesk/home']);
    } else if (this.isClient) {
      this.router.navigate(['/client/home']);
    } else {
      this.goToUserLogin();
    }
  }

  goToLoginSSO(queryParams?: any): void {
    if (queryParams) {
      this.router.navigate(['/loginSSO'], { queryParams: queryParams });
    } else {
      this.router.navigate(['/loginSSO']);
    }
  }

  // Porta alla pagina per accettare la privacy
  /**
   *
   */
  goToPrivacyPage(isEnel?: boolean) {
    let applicationContext = this.urlService.getApplicationUrl().url;
    if (isEnel) {
      applicationContext = applicationContext + '/#/enelPrivacy';
    } else {
      applicationContext = applicationContext + '/#/privacy';
    }
    window.open(applicationContext, '_blank');
  }

  goToAccessibility() {
    let applicationContext = this.urlService.getApplicationUrl().url + '/#/accessibilita';
    window.open(applicationContext)
  }

  // Porta allla pagina con le campagne attive
  goToActiveCampaigns(venueId?: string) {
    if (venueId) {
      this.router.navigate([`/structure/activeCampaigns/${venueId}`]);
    } else {
      this.router.navigate(['/structure/activeCampaigns']);
    }
  }

  // Vai alla pagina di dettaglio di una campagna (lato Strutture)
  goToCampaignDetails(campaignId: string, venueId?: string) {
    if (venueId) {
      this.router.navigate([`/structure/activeCampaigns/${venueId}/details/${campaignId}`]);
    } else {
      this.router.navigate([`/structure/activeCampaigns/details/${campaignId}`]);
    }
  }

  // Vai alla pagina di dettaglio di una prenotazione (lato Aventi diritto)
  goToBookingDetails(bookingId: string) {
    if (bookingId) {
      this.router.navigate(['/user/bookingDetails/' + bookingId]);
    }
  }

  // Porta alla pagina di dettaglio del round
  goToRoundDetail(roundId: string) {
    if (roundId) {
      this.router.navigate([`/client/round-detail/${roundId}`]);
    }
  }

  /**
   * Pagina per gestire le presenze di un round
   * @param roundId
   * @param venueId optional
   */
  goToRoundAttendances(roundId: string, venueId?: string) {
    if (venueId) {
      this.router.navigate([`/structure/round/attendance/${roundId}/${venueId}`]);
    } else {
      this.router.navigate([`/structure/round/attendance/${roundId}`]);
    }
  }

  /**
   * Pagina per gestire le strutture di un round
   * @param roundId
   * @param venueId optional
   */
  goToRoundSlots(roundId: string, venueId?: string, noReload?: boolean) {
    if (venueId) {
      if (noReload) {
        this.location.go(`/structure/round/slots/${roundId}/${venueId}`);
      } else {
        this.router.navigate([`/structure/round/slots/${roundId}/${venueId}`]);
      }
    } else {
      if (noReload) {
        this.location.go(`/structure/round/slots/${roundId}`);
      } else {
        this.router.navigate([`/structure/round/slots/${roundId}`]);
      }
    }
  }

  /**
   * Pagina per la creazione di slot relativi alla struttura
   * @param roundId
   * @param venueId
   */
  goToRoundStructureNewSlots(roundId: string, venueId: string) {
    this.router.navigate([`/structure/round/slots/add/${roundId}/${venueId}`]);
  }

  // Tora indietro history browser
  goBackBrowser() {
    window.history.back();
  }

  // E' nella pagina passata
  isInThisPage(page: string) {
    if (
      page &&
      this.router &&
      this.router.url &&
      this.router.url.indexOf(page) !== -1
    ) {
      return true;
    }

    return false;
  }

  // Pagina di errore generico
  goToErrorPage(errorCode?: string) {
    if (errorCode) {
      this.router.navigate(['/errorPage/' + errorCode]);
    } else {
      this.router.navigate(['/errorPage']);
    }

  }

  //#region AD

  /**
   * Go to a new reservation
   * @param roundId
   * @param userId
   */
  goToNewReservation(roundId: string, userId: string) {
    this.router.navigate([`/user/reservation/${roundId}/${userId}`]);
  }

  // Redirect alla prenotazione massiva per la famiglia
  goToNewFamilyReservation(roundId: string) {
    this.router.navigate([`/user/reservation/${roundId}`]);
  }

  goToAddFamiliar(campaignId: string, roundId: string) {
    this.router.navigate([`/user/addFamiliar/${campaignId}/${roundId}`]);
  }

  editReservation(roundId: string, userId: string, attendanceId?: string, editStructure?: boolean, editDate?: boolean) {
    this.router.navigate([`/user/reservation/${roundId}/${userId}/${attendanceId}/${editStructure}/${editDate}`]);
  }

  goToHelpDeskCampaign(campaignId: string) {
    this.router.navigate([`/helpDesk/campaign/${campaignId}`]);
  }

  goToHelpDeskRegisterAD(userId: string) {
    this.router.navigate([`/helpDesk/registerAD/${userId}`]);
  }

  goToHelpDeskCampaignRescheduleEntitled(campaignId: string, userId: string) {
    this.router.navigate([`/helpDesk/campaign/${campaignId}/entitleds/${userId}`]);
  }

  goToClientHome() {
    this.router.navigate([`/client/home`]);
  }

  goToClientRound(campaignId: string, roundId: string) {
    this.router.navigate([`/client/campaign/${campaignId}/round/${roundId}`]);
  }
  //#endregion AD

  // Quando il componente è distrutto, eseguo anche l'unsubscribe
  ngOnDestroy() {
    if (this.combinedSelectes$) {
      this.combinedSelectes$.unsubscribe();
    }
  }

  impersonationLogin(impersonationToken: string) {
    // Prima di cambiare route, mi segno quella attuale in modo da poterci ritornare quando sco dall'impersonifica
    const preImpersonificationPage = window.location.href;
    sessionStorage.setItem('preImpersonificationPage', preImpersonificationPage);

    if (window.location.href.includes("localhost")) {
      let localUrl = "http://localhost:4200";
      let context = `${localUrl}/#/impersonationAuth/${impersonationToken}`;

      window.location.href = context;
      window.location.reload();
    } else {

      let applicationContext = this.urlService.getApplicationUrl().url;
      applicationContext = `${applicationContext}/#/impersonationAuth/${impersonationToken}`;

      window.location.href = applicationContext;
      window.location.reload();
    }
  }

  //#region Admin

  goToAdminClients() {
    this.router.navigate(['/admin/clients']);
  }

  goToAdminCampaigns() {
    this.router.navigate(['/admin/campaigns']);
  }

  goToAdminHealthFacilities() {
    this.router.navigate(['/admin/healthFacilities']);
  }

  goToAdminDashboard() {
    this.router.navigate(['/admin/dashboard']);
  }

  goToNewHealthFacilitySupplier(healthFacilityId?: string) {
    if (healthFacilityId) {
      this.router.navigate([`/admin/add/healthFacility/${healthFacilityId}`]);
    } else {
      this.router.navigate(['/admin/add/healthFacility']);
    }
  }

  goToAdminHealthFacility(healthFacilityId: string, noReload?: boolean) {
    if (noReload) {
      this.location.go(`/admin/healthFacility/${healthFacilityId}`);
    } else {
      this.router.navigate([`/admin/healthFacility/${healthFacilityId}`]);
    }
  }

  goToHelpDeskHealthFacility(healthFacilityId: string, noReload?: boolean) {
    if (noReload) {
      this.location.go(`/helpDesk/healthFacility/${healthFacilityId}`);
    } else {
      this.router.navigate([`/helpDesk/healthFacility/${healthFacilityId}`]);
    }
  }

  goToAdminHealthFacilityReferences(healthFacilityId: string, noReload?: boolean) {
    if (noReload) {
      this.location.go(`/admin/healthFacility/${healthFacilityId}/references`);
    } else {
      this.router.navigate([`/admin/healthFacility/${healthFacilityId}/references`]);
    }
  }

  goToHelpDeskHealthFacilityReferences(healthFacilityId: string, noReload?: boolean) {
    if (noReload) {
      this.location.go(`/helpDesk/healthFacility/${healthFacilityId}/references`);
    } else {
      this.router.navigate([`/helpDesk/healthFacility/${healthFacilityId}/references`]);
    }
  }

  goToAdminNewHealthFacilityReference(healthFacilityId: string, supplierPersonId?: string) {
    if (supplierPersonId) {
      this.router.navigate([`/admin/add/healthFacility/${healthFacilityId}/reference/${supplierPersonId}`]);
    } else {
      this.router.navigate([`/admin/add/healthFacility/${healthFacilityId}/reference`]);
    }
  }

  goToAdminNewHealthFacilityVenue(healthFacilityId: string, venueId?: string) {
    if (venueId) {
      this.router.navigate([`/admin/add/healthFacility/${healthFacilityId}/venue/${venueId}`]);
    } else {
      this.router.navigate([`/admin/add/healthFacility/${healthFacilityId}/venue`]);
    }
  }

  goToAdminClient(groupId: string) {
    this.router.navigate([`/admin/client/${groupId}`]);
  }

  goToAdminNewClient(groupId?: string) {
    if (groupId) {
      this.router.navigate([`/admin/add/client/${groupId}`]);
    } else {
      this.router.navigate([`/admin/add/client`]);
    }
  }

  goToAdminNewClientReference(groupId: string, supplierPersonId?: string) {
    if (supplierPersonId) {
      this.router.navigate([`/admin/add/client/${groupId}/reference/${supplierPersonId}`]);
    } else {
      this.router.navigate([`/admin/add/client/${groupId}/reference`]);
    }
  }

  goToAdminNewCampaign(campaignId?: string) {
    if (campaignId) {
      this.router.navigate([`/admin/add/campaign/${campaignId}`]);
    } else {
      this.router.navigate([`/admin/add/campaign`]);
    }
  }

  goToAdminCampaignTabRounds(campaignId: string, noReload?: boolean) {
    if (noReload) {
      this.location.go(`/admin/campaign/${campaignId}`);
    } else {
      this.router.navigate([`/admin/campaign/${campaignId}`]);
    }
  }

  goToAdminCampaignTabClients(campaignId: string, noReload?: boolean) {
    if (noReload) {
      this.location.go(`/admin/campaign/${campaignId}/clients`);
    } else {
      this.router.navigate([`/admin/campaign/${campaignId}/clients`]);
    }
  }

  goToAdminCampaignRoundTabVenues(campaignId: string, roundId: string, noReload?: boolean) {
    if (noReload) {
      this.location.go(`/admin/campaign/${campaignId}/round/${roundId}`);
    } else {
      this.router.navigate([`/admin/campaign/${campaignId}/round/${roundId}`]);
    }
  }

  goToAdminCampaignRoundTabEntitleds(campaignId: string, roundId: string, noReload?: boolean) {
    if (noReload) {
      this.location.go(`/admin/campaign/${campaignId}/round/${roundId}/entitleds`);
    } else {
      this.router.navigate([`/admin/campaign/${campaignId}/round/${roundId}/entitleds`]);
    }
  }

  goToAdminCampaignRoundRescheduleEntitled(campaignId: string, roundId: string, userId: string) {
    this.router.navigate([`/admin/campaign/${campaignId}/round/${roundId}/entitleds/${userId}`]);
  }

  goToAdminCampaignRoundScheduleEntitled(campaignId: string, roundId: string, userId: string, attendanceId: string) {
    this.router.navigate([`/admin/campaign/${campaignId}/round/${roundId}/entitledsSchedule/${userId}/attendance/${attendanceId}`]);
  }

  goToAdminNewCampaignRound(campaignId: string, roundId?: string) {
    if (roundId) {
      this.router.navigate([`/admin/add/campaign/${campaignId}/round/${roundId}`]);
    } else {
      this.router.navigate([`/admin/add/campaign/${campaignId}/round`]);
    }
  }

  goToAdminAssignClientToCampaign(campaignId: string) {
    this.router.navigate([`/admin/assign/campaign/${campaignId}/client`]);
  }

  goToAdminCampaignClient(campaignId: string, groupId: string) {
    this.router.navigate([`/admin/campaign/${campaignId}/client/${groupId}`]);
  }

  goToAdminAddCampaignCustomerCap(campaignId: string, groupId: string) {
    this.router.navigate([`/admin/add/campaign/${campaignId}/client/${groupId}/cap`]);
  }

  goToAdminAddEntitled(campaignId: string, roundId: string) {
    this.router.navigate([`/admin/add/campaign/${campaignId}/round/${roundId}/addEntitled`]);
  }

  goToAdminAssignVenueToRound(campaignId: string, roundId: string) {
    this.router.navigate([`/admin/assign/campaign/${campaignId}/round/${roundId}/venue`]);
  }

  goToRemoveVenueFromRound(campaignId: string, roundId: string) {
    this.router.navigate([`/admin/remove/campaign/${campaignId}/round/${roundId}/venue`]);
  }

  goToImportEntitledsFromRound(campaignId: string, roundId: string) {
    this.router.navigate([`/admin/import/campaign/${campaignId}/round/${roundId}/round`]);
  }

  goToAdminRoundVenueDetails(campaignId: string, roundId: string, venueId: string) {
    this.router.navigate([`/admin/campaign/${campaignId}/round/${roundId}/venue/${venueId}`]);
  }

  goToAdminPendingRequests(campaignId?: string, roundId?: string, venueId?: string, noReload?: boolean) {
    if (noReload) {
      if (campaignId && roundId && venueId) {
        this.location.go(`/admin/pendingRequests/${campaignId}/${roundId}/${venueId}`);
      } else {
        this.location.go(`/admin/pendingRequests`);
      }
    } else if (campaignId && roundId && venueId) {
      this.router.navigate([`/admin/pendingRequests/${campaignId}/${roundId}/${venueId}`]);
    } else {
      this.router.navigate([`/admin/pendingRequests`]);
    }
  }

  goToVenueRoundEditDate(campaignId: string, roundId: string, venueId: string) {
    this.router.navigate([`/admin/editVenueBookingDate/${campaignId}/${roundId}/${venueId}`]);
  }

  goToAdminPendingRequestsToApprove(campaignId?: string, roundId?: string, venueId?: string, noReload?: boolean) {
    if (noReload) {
      if (campaignId && roundId && venueId) {
        this.location.go(`/admin/pendingRequests/toApprove/${campaignId}/${roundId}/${venueId}`);
      } else {
        this.location.go(`/admin/pendingRequests/toApprove`);
      }
    } else if (campaignId && roundId && venueId) {
      this.router.navigate([`/admin/pendingRequests/toApprove/${campaignId}/${roundId}/${venueId}`]);
    } else {
      this.router.navigate([`/admin/pendingRequests/toApprove`]);
    }
  }

  goToAdminPendingRequestsToRevoke(campaignId?: string, roundId?: string, venueId?: string, noReload?: boolean) {
    if (noReload) {
      if (campaignId && roundId && venueId) {
        this.location.go(`/admin/pendingRequests/toRevoke/${campaignId}/${roundId}/${venueId}`);
      } else {
        this.location.go(`/admin/pendingRequests/toRevoke`);
      }
    } else if (campaignId && roundId && venueId) {
      this.router.navigate([`/admin/pendingRequests/toRevoke/${campaignId}/${roundId}/${venueId}`]);
    } else {
      this.router.navigate([`/admin/pendingRequests/toRevoke`]);
    }
  }

  goToAdminPendingRequestsDescriptions(campaignId?: string, roundId?: string, venueId?: string, noReload?: boolean) {
    if (noReload) {
      if (campaignId && roundId && venueId) {
        this.location.go(`/admin/pendingRequests/descriptions/${campaignId}/${roundId}/${venueId}`);
      } else {
        this.location.go(`/admin/pendingRequests/descriptions`);
      }
    } else if (campaignId && roundId && venueId) {
      this.router.navigate([`/admin/pendingRequests/descriptions/${campaignId}/${roundId}/${venueId}`]);
    } else {
      this.router.navigate([`/admin/pendingRequests/descriptions`]);
    }
  }

  goToAdminPendingRequest(venueId: string, requestId: string, roundId?: string) {
    if (roundId) {
      this.router.navigate([`/admin/pendingRequest/${venueId}/${requestId}/${roundId}`]);
    } else {
      this.router.navigate([`/admin/pendingRequest/${venueId}/${requestId}`]);
    }
  }

  //#endregion Admin

  goToPublicFAQPage() {
    let applicationContext = this.urlService.getApplicationUrl().url;
    applicationContext = applicationContext + '/#/faq';
    window.open(applicationContext, '_blank');
  }

  goToUserFAQPage(roundId?: string, userId?: string) {
    if (roundId && userId) {
      this.router.navigate([`/user/faq/${roundId}/${userId}`]);
    } else {
      this.router.navigate([`/user/faq`]);
    }
  }

  goToCreateTicket() {
    this.router.navigate(['/createTicket']);
  }

  goToCreateUserTicket(roundId?: string, userId?: string) {
    if (roundId && userId) {
      this.router.navigate([`/user/createTicket/${roundId}/${userId}`]);
    } else {
      this.router.navigate([`/user/createTicket`]);
    }
  }

  goToPreImpersonificationPage() {
    const preImpersonificationPage = sessionStorage.getItem('preImpersonificationPage');

    if (preImpersonificationPage) {
      // Se trovo un url a cui ero precedentemente, torno lì
      sessionStorage.removeItem('preImpersonificationPage');

      window.location.href = preImpersonificationPage;
      window.location.reload();
    }
    else {
      // Altrimenti vado nella pagina di default e da lì verrò reindirizzato alla relativa home
      if (window.location.href.includes("localhost")) {
        let localUrl = "http://localhost:4200";
        let context = `${localUrl}/#/defaultPage`;

        window.location.href = context;
        window.location.reload();
      } else {

        let applicationContext = this.urlService.getApplicationUrl().url;
        applicationContext = `${applicationContext}/#/defaultPage`;

        window.location.href = applicationContext;
        window.location.reload();
      }
    }
  }
}

import { FormControl, FormGroup, Validators } from "@angular/forms";
import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from "@angular/router";
import { Store } from "@ngrx/store";
import { TranslateService } from '@ngx-translate/core';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from "rxjs/operators";

import * as fromApp from "../../../ngrx/app.reducers";
import * as CoreActions from "../../../core/ngrx/core.actions";
import { CampaignManagerMinorVaccinationType, HealthFacilityInfo, InfoVenue, VenueMinorTypes } from "atfcore-commonclasses/bin/classes/campaignmanager";
import { SenecaResponse } from "atfcore-commonclasses/bin/classes/common";

import { RedirectService } from 'src/app/shared/services/redirect.service';
import { getWarningModal } from "src/app/utils/utils";
import { ScrollTo } from "src/app/core/services/scroll-to.service";
import { SupplierService } from "../../services/supplier.service";
import { RentService } from "src/app/structure/services/rent.service";
import { AnagService } from "src/app/auth/services/anag.service";
import { DropdownItem } from "src/app/shared/models/dropdown.model";

export interface RadioItem {
  id: number,
  value: string
}

@Component({
  selector: 'app-admin-add-health-facility-venue',
  templateUrl: './addHealthFacilityVenue.component.html',
  styleUrls: ['./addHealthFacilityVenue.component.scss']
})
export class AdminAddHealthFacilityVenueComponent implements OnInit, OnDestroy {

  isLoading: boolean = false;

  healthFacilitySupplierId: string = '';
  venueId: string = '';

  healthFacility?: HealthFacilityInfo;
  venue?: InfoVenue;

  addVenueForm = new FormGroup({
    'name': new FormControl(undefined, Validators.required),
    'isActive': new FormControl(true),
    'privateDescription': new FormControl(undefined),
    'publicDescription': new FormControl(undefined),
    'customer': new FormControl(undefined),
    'address': new FormControl(undefined),
    'venueCode': new FormControl(undefined),
    'disabledAddress': new FormControl(false, Validators.requiredTrue),
    'latitude': new FormControl(undefined, Validators.required),
    'longitude': new FormControl(undefined, Validators.required),
    'vaccinationUntilTwelve': new FormControl({
      id: 1,
      value: 'generic.NO'
    }, Validators.required),
    'vaccinationFromTwelveToNine': new FormControl({
      id: 1,
      value: 'generic.NO'
    }, Validators.required),
    'vaccinationFromNineToSix': new FormControl({
      id: 1,
      value: 'generic.NO'
    }, Validators.required),
    'vaccinationUnderSix': new FormControl({
      id: 1,
      value: 'generic.NO'
    }, Validators.required),
    'underNineSecondSomministration': new FormControl(false),
  });

  get name() {
    return this.addVenueForm.get('name') as FormControl;
  }

  get isActive() {
    return this.addVenueForm.get('isActive') as FormControl;
  }

  get privateDescription() {
    return this.addVenueForm.get('privateDescription') as FormControl;
  }

  get publicDescription() {
    return this.addVenueForm.get('publicDescription') as FormControl;
  }

  get customer() {
    return this.addVenueForm.get('customer') as FormControl;
  }

  get disabledAddress() {
    return this.addVenueForm.get('disabledAddress') as FormControl;
  }

  get venueCode() {
    return this.addVenueForm.get('venueCode') as FormControl;
  }

  get address() {
    return this.addVenueForm.get('address') as FormControl;
  }

  get latitude() {
    return this.addVenueForm.get('latitude') as FormControl;
  }

  get longitude() {
    return this.addVenueForm.get('longitude') as FormControl;
  }

  get vaccinationUntilTwelve() {
    return this.addVenueForm.get('vaccinationUntilTwelve') as FormControl;
  }

  get vaccinationFromTwelveToNine() {
    return this.addVenueForm.get('vaccinationFromTwelveToNine') as FormControl;
  }

  get vaccinationFromNineToSix() {
    return this.addVenueForm.get('vaccinationFromNineToSix') as FormControl;
  }

  get vaccinationUnderSix() {
    return this.addVenueForm.get('vaccinationUnderSix') as FormControl;
  }

  get underNineSecondSomministration() {
    return this.addVenueForm.get('underNineSecondSomministration') as FormControl;
  }

  customers: DropdownItem[] = [];
  readonly vaccinationForMinorRanges: RadioItem[] = [{
    id: 1,
    value: 'generic.NO'
  }, {
    id: 2,
    // Consegna alla persona
    value: 'admin.addHealthFacilityVenue.VACCINATION_MANAGEMENT_TYPE_DELIVERY'
  }, {
    id: 3,
    // Somministrazione
    value: 'admin.addHealthFacilityVenue.VACCINATION_MANAGEMENT_TYPE_ADMINISTRATION'
  }];

  private _unsubscribeSignal$: Subject<void> = new Subject();

  constructor(
    private route: ActivatedRoute,
    private appStore: Store<fromApp.AppState>,
    private translate: TranslateService,
    private redirectService: RedirectService,
    private scrollTo: ScrollTo,
    private supplierService: SupplierService,
    private rentService: RentService,
    private anagService: AnagService
  ) {
  }

  ngOnInit() {
    this.scrollTo.header();

    this.healthFacilitySupplierId = this.route.snapshot.paramMap.get('healthFacilitySupplierId') || '';
    this.venueId = this.route.snapshot.paramMap.get('venueId') || '';

    if (this.healthFacilitySupplierId) {
      this.isLoading = true;
      this.getHealthFacility();
    } else {
      this.redirectService.goToAdminHealthFacilities();
    }
  }

  selectedPlace(place: any) {
    if (!place || place.geometry === undefined || place.geometry === null) {
      return;
    }
    if (place.address_components && place.address_components.length) {
      this.latitude.setValue(place.geometry.location.lat());
      this.longitude.setValue(place.geometry.location.lng());
      this.address.setValue(place.formatted_address);
      this.address.disable();
      this.disabledAddress.setValue(true);
    }
  }

  getHealthFacility() {
    this.supplierService.getHealthFacilitySupplierForAdmin(this.healthFacilitySupplierId)
      .pipe(takeUntil(this._unsubscribeSignal$.asObservable()))
      .subscribe((result) => {
        if (!result || result.error) {
          this.dispatchWarningModal('044', result.error || '');
          this.isLoading = false;
        } else {
          this.healthFacility = result.response;
          if (this.venueId) {
            this.getVenue();
          } else {
            this.getCustomers();
          }
        }
      }, (err) => {
        if (err && err.message) {
          this.dispatchWarningModal('045', err.message);
        }
        this.isLoading = false;
      });
  }

  getVenue() {
    this.isLoading = false;
    this.rentService.getVenueItemById(this.venueId)
      .pipe(takeUntil(this._unsubscribeSignal$.asObservable()))
      .subscribe((result) => {
        if (!result || result.error) {
          this.dispatchWarningModal('059', result.error || '');
          this.isLoading = false;
        } else {
          this.venue = result.response;
          this.name.setValue(this.venue.name);
          this.venueCode.setValue(this.venue.venueCode);
          this.address.setValue(this.venue.address);

          this.isActive.setValue((this.venue as any).active);

          if (this.venue.address) {
            this.address.disable();
            this.address.setValue(this.venue.address);
            this.disabledAddress.setValue(true);
            this.latitude.setValue(this.venue.latitude);
            this.longitude.setValue(this.venue.longitude);
          }

          if (this.venue.validMinorTypes && this.venue.validMinorTypes.length) {
            this.venue.validMinorTypes.forEach(validMinorType => {
              // Fascia 12-18
              if (validMinorType.age === 12) {
                if (validMinorType.vaccinationType === CampaignManagerMinorVaccinationType.DELIVERY) {
                  this.vaccinationUntilTwelve.setValue(this.vaccinationForMinorRanges[1]);
                } else {
                  this.vaccinationUntilTwelve.setValue(this.vaccinationForMinorRanges[2]);
                }
              }
              // Fascia 9-12
              else if (validMinorType.age === 9) {
                if (validMinorType.vaccinationType === CampaignManagerMinorVaccinationType.DELIVERY) {
                  this.vaccinationFromTwelveToNine.setValue(this.vaccinationForMinorRanges[1]);
                } else {
                  this.vaccinationFromTwelveToNine.setValue(this.vaccinationForMinorRanges[2]);
                }
              }
              // Fascia 6-9
              else if (validMinorType.age === 6) {
                if (validMinorType.vaccinationType === CampaignManagerMinorVaccinationType.DELIVERY) {
                  this.vaccinationFromNineToSix.setValue(this.vaccinationForMinorRanges[1]);
                } else {
                  this.vaccinationFromNineToSix.setValue(this.vaccinationForMinorRanges[2]);
                }
              }
              // Fascia sotto i 6
              else if (validMinorType.age === 5) {
                if (validMinorType.vaccinationType === CampaignManagerMinorVaccinationType.DELIVERY) {
                  this.vaccinationUnderSix.setValue(this.vaccinationForMinorRanges[1]);
                } else {
                  this.vaccinationUnderSix.setValue(this.vaccinationForMinorRanges[2]);
                }
              }
            });
          }

          if (this.venue.secondVaccinationForMinorNeverVaccinated) {
            this.underNineSecondSomministration.setValue(true);
          }

          this.publicDescription.setValue(this.venue.description);
          this.privateDescription.setValue(this.venue.privateDescription);
          this.getCustomers();
        }
      }, (err) => {
        if (err && err.message) {
          this.dispatchWarningModal('060', err.message);
        }
        this.isLoading = false;
      });
  }

  getCustomers() {
    this.anagService.listCustomersForAdmin(0, 100)
      .pipe(takeUntil(this._unsubscribeSignal$.asObservable()))
      .subscribe((result) => {
        if (!result || result.error) {
          this.dispatchWarningModal('059', result.error || '');
        } else {
          if (result.response) {
            this.customers = [{
              id: '',
              name: ''
            }];

            result.response.forEach(customer => {

              const customerDropdown = {
                id: customer.referenceId,
                name: customer.title
              };

              if (this.venue && this.venue.privateForSupplierId === customer.referenceId) {
                this.customer.setValue(customerDropdown);
              }

              this.customers.push(customerDropdown);
            });
          }
        }
        this.isLoading = false;
      }, (err) => {
        if (err && err.message) {
          this.dispatchWarningModal('060', err.message);
        }
        this.isLoading = false;
      });
  }

  ngOnDestroy() {
    this._unsubscribeSignal$.next();
    this._unsubscribeSignal$.unsubscribe();
  }

  /**
   * @description Va all'homepage delle SS
   */
  goBack() {
    this.redirectService.goToAdminHealthFacility(this.healthFacilitySupplierId);
  }

  goToFAQ() {

  }

  onEditAddress() {
    this.disabledAddress.setValue(false);
    this.address.enable();
  }

  onStatusChange(value: boolean) {
    this.isActive.setValue(value);
  }

  onUnderNineSecondSomministrationChange(value: boolean) {
    this.underNineSecondSomministration.setValue(value);
  }

  onVaccinationUntilTwelveChange(value: any) {
    this.vaccinationUntilTwelve.setValue(value);
  }

  onVaccinationFromTwelveToNineChange(value: any) {
    this.vaccinationFromTwelveToNine.setValue(value);
  }

  onVaccinationFromNineToSixChange(value: any) {
    this.vaccinationFromNineToSix.setValue(value);
  }

  onVaccinationUnderSixChange(value: any) {
    this.vaccinationUnderSix.setValue(value);
  }

  confirmAddVenue() {
    if (this.addVenueForm.valid) {
      this.isLoading = true;

      let customerId: string | undefined = undefined;
      if (this.customer.value && (this.customer.value as DropdownItem).id) {
        customerId = (this.customer.value as DropdownItem).id;
      }

      let underageSecondSomministration: boolean | undefined;
      let minorVaccinations: VenueMinorTypes[] = [];

      // Diverso da No fascia sotto i 6
      if ((this.vaccinationUnderSix.value as RadioItem).id !== this.vaccinationForMinorRanges[0].id) {
        const vaccinationType = (this.vaccinationUnderSix.value as RadioItem).id === 2 ? 'DELIVERY' : 'ADMINISTRATION';
        for (let i = 2; i < 6; i++) {
          minorVaccinations.push({
            age: i,
            vaccinationType: vaccinationType
          });
        }
      }

      // Diverso da No fascia dai 6 ai 9
      if ((this.vaccinationFromNineToSix.value as RadioItem).id !== this.vaccinationForMinorRanges[0].id) {
        const vaccinationType = (this.vaccinationFromNineToSix.value as RadioItem).id === 2 ? 'DELIVERY' : 'ADMINISTRATION';
        for (let i = 6; i < 9; i++) {
          minorVaccinations.push({
            age: i,
            vaccinationType: vaccinationType
          });
        }
      }

      // Diverso da No fascia dai 9 ai 12
      if ((this.vaccinationFromTwelveToNine.value as RadioItem).id !== this.vaccinationForMinorRanges[0].id) {
        const vaccinationType = (this.vaccinationFromTwelveToNine.value as RadioItem).id === 2 ? 'DELIVERY' : 'ADMINISTRATION';
        for (let i = 9; i < 12; i++) {
          minorVaccinations.push({
            age: i,
            vaccinationType: vaccinationType
          });
        }
      }

      // Diverso da No fascia dai 12 ai 18
      if ((this.vaccinationUntilTwelve.value as RadioItem).id !== this.vaccinationForMinorRanges[0].id) {
        const vaccinationType = (this.vaccinationUntilTwelve.value as RadioItem).id === 2 ? 'DELIVERY' : 'ADMINISTRATION';
        for (let i = 12; i < 18; i++) {
          minorVaccinations.push({
            age: i,
            vaccinationType: vaccinationType
          });
        }
      }

      underageSecondSomministration = this.underNineSecondSomministration.value;

      let request: Observable<SenecaResponse<boolean>> = this.venue ?
        this.supplierService.updateVenueByHealthFacilitySupplierForAdmin(
          this.healthFacilitySupplierId,
          this.name.value,
          this.venueCode.value,
          this.address.value,
          this.longitude.value,
          this.latitude.value,
          this.venue.itemId,
          (this.isActive.value as boolean),
          minorVaccinations,
          underageSecondSomministration,
          this.privateDescription.value,
          this.publicDescription.value,
          customerId) :
        this.supplierService.createVenueByHealthFacilitySupplierForAdmin(
          this.healthFacilitySupplierId,
          this.name.value,
          this.venueCode.value,
          this.address.value,
          this.longitude.value,
          this.latitude.value,
          (this.isActive.value as boolean),
          minorVaccinations,
          underageSecondSomministration,
          this.privateDescription.value,
          this.publicDescription.value,
          customerId);

      request
        .pipe(takeUntil(this._unsubscribeSignal$.asObservable()))
        .subscribe((result) => {
          if (!result || result.error) {
            this.dispatchWarningModal('041', result.error || '');
          } else if (result.response) {
            this.redirectService.goToAdminHealthFacility(this.healthFacilitySupplierId);
          }
          this.isLoading = false;
        }, (err) => {
          if (err && err.message) {
            this.dispatchWarningModal('042', err.message);
          }
          this.isLoading = false;
        });
    }
  }

  onChangeCustomer(value: DropdownItem) {
    this.customer.setValue(value);
  }

  /**
   * @description Mostra una modale con un messaggio di errore
   * @param modalId
   * @param error
   */
  dispatchWarningModal(modalId: string, error: string) {
    const messageObj = getWarningModal(this.translate, modalId, error);
    this.appStore.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
  }

}

/*
 * Componente per l'errore 404, pagina richiesta non trovata
*/

import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { RedirectService } from 'src/app/shared/services/redirect.service';

@Component({
  selector: 'generic-error',
  templateUrl: './generic-error.component.html',
  styleUrls: ['./generic-error.component.scss']
})
export class GenericErrorComponent {
  errorCode: string = '';

  constructor(
    public redirectService: RedirectService,
    public route: ActivatedRoute
  ) {
    this.route.params
      .subscribe((params:any) => {
        if (params.errorCode){
          this.errorCode = params.errorCode;
        }
      })
  }
  
  goToHome() {
    this.redirectService.goToHome()
  }
}

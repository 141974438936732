<div class="arrow-top-container" fxLayout="column" fxLayoutAlign="center center">
  <svg-icon class="small-icon-size" [applyClass]="true" src="assets/img/icons/arrow-top.svg"></svg-icon>
</div>
<div class="pop-up-container" fxLayout="column">
  <div class="pop-up-title" fxLayout="row">
    <h5 class="primary bold full-width">{{title}}</h5>
    <div>
      <svg-icon src="assets/img/icons/x.svg" class="cursor-pointer medium-size close-icon-pop-up" [applyClass]="true">
      </svg-icon>
    </div>
  </div>

  <div class="divisor-title"></div>

  <div class="first-subtitle-container" fxLayout="column">
    <h5 class="primary bold">{{firstSubtitleTitle}}</h5>
    <h6 class="small light-grey full-width">{{firstSubtitleDescription}}</h6>
  </div>
  <div class="second-subtitle-container" fxLayout="column">
    <h5 class="primary bold">{{secondSubtitleTitle}}</h5>
    <h6 class="small light-grey full-width" [innerHTML]="secondSubtitleDescription"></h6>
  </div>
</div>

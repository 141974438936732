<div class="page-content-wrapper" fxLayout="column">

  <!-- Campagna x -->
  <h1 fxFlexOffset="40px" class="primary" translate="admin.campaignRoundRescheduleReservation.SCHEDULE"></h1>

  <bread-crumbs fxFlexOffset="12px"
    [customFirstLevel]="campaign ? campaign.title : ('admin.campaignRoundRescheduleReservation.TITLE2' | translate)"
    (firstCustomLevelClick)="goBackToCampaign()" (secondLevelClick)="goBackToCampaign()" [secondLevel]="round ?
      ((round.startDate | date: 'dd MMMM yyyy' | titlecase) + ' - ' + (round.endDate | date: 'dd MMMM yyyy' | titlecase)) :
      ('admin.campaignRoundRescheduleReservation.TITLE3' | translate)"
    [thirdLevel]="'admin.campaignRoundRescheduleReservation.SCHEDULE' | translate">
  </bread-crumbs>

  <!-- Resume round -->
  <div fxFlexOffset="56px" fxLayout="row" fxLayoutAlign="center">
    <div class="attendance-reschedule-resume" fxLayout="row" fxLayoutAlign="space-between">
      <svg-icon class="note" [applyClass]="true" src="assets/img/icons/note-clients.svg"></svg-icon>

      <div fxFlexOffset="80px" fxFlex fxLayout="column">
        <ng-container *ngIf="!isLoadingBase && round">

          <!-- Round -->
          <h5 class="bold" translate="admin.campaignRoundRescheduleReservation.ROUND"></h5>

          <div fxLayout="row" fxFlexOffset="8px">
            <!-- Prenotazioni -->
            <div fxLayout="column" fxFlex>
              <h5 class="sub-title" translate="admin.campaignRoundRescheduleReservation.BOOKINGS"></h5>
              <h5>
                {{ round.minReservationEndDate | date: 'dd MMMM yyyy' | titlecase }}
                {{ ' - ' }}
                {{ round.maxReservationEndDate | date: 'dd MMMM yyyy' | titlecase }}
              </h5>
            </div>
            <!-- Somministrazione -->
            <div fxLayout="column" fxFlex>
              <h5 fxFlexOffset="8px" class="sub-title"
                translate="admin.campaignRoundRescheduleReservation.VISIBILITIES"></h5>
              <h5>
                {{ (round.startDate | date: 'dd MMMM yyyy' | titlecase) || ('generic.TO_SET' | translate) }}
                {{ ' - ' }}
                {{ (round.endDate | date: 'dd MMMM yyyy' | titlecase) || ('generic.TO_SET' | translate) }}
              </h5>
            </div>
          </div>

          <!-- Prenotazione -->
          <h5 fxFlexOffset="8px" class="bold" translate="admin.campaignRoundRescheduleReservation.RESERVATION"></h5>

          <div fxLayout="row" fxLayoutAlign="start center">
            <welion-select fxFlex="40" withTransparency [disabled]="!!attendanceId" [items]="attendances"
              [selectedItem]="attendance.value" (change)="onChangeAttendance($event)">
            </welion-select>

            <!-- Codice fiscale -->
            <div fxLayout="column" fxFlex="50" fxFlexOffset="10">
              <h5 class="sub-title" translate="admin.campaignRoundRescheduleReservation.RESERVATION_CF"></h5>
              <h5>
                <ng-container *ngIf="attendanceValue">
                  {{ attendanceValue.fiscalCode }}
                </ng-container>
                <ng-container *ngIf="!attendanceValue && !fiscalCodeUser">-</ng-container>
                <ng-container *ngIf="fiscalCodeUser && !attendanceValue">{{fiscalCodeUser}}</ng-container>
              </h5>
            </div>
          </div>

          <div fxLayout="row" fxFlexOffset="12px">
            <!-- Orario prenotazione -->
            <div fxLayout="column" fxFlex>
              <h5 class="sub-title" translate="admin.campaignRoundRescheduleReservation.RESERVATION_TIME"></h5>
              <h5>
                <ng-container *ngIf="attendanceValue">
                  {{ attendanceValue.startDate | date: 'dd MMMM HH:mm' | titlecase }}
                  {{ ' - ' }}
                  {{ attendanceValue.endDate | date: 'HH:mm' | titlecase }}
                </ng-container>
                <ng-container *ngIf="!attendanceValue">-</ng-container>
              </h5>
            </div>

            <!-- Struttura prenotazione -->
            <div fxLayout="column" fxFlex>
              <h5 class="sub-title" translate="admin.campaignRoundRescheduleReservation.RESERVATION_STRUCTURE"></h5>
              <h5>
                <ng-container *ngIf="userHasPreassignedVenue && attendances.length">
                  {{ attendances[0].content.venue.healthFacilitySupplierName }}
                </ng-container>
                <ng-container *ngIf="!userHasPreassignedVenue && attendanceValue && attendanceValue.venue">
                  {{ attendanceValue.venue.healthFacilitySupplierName }}
                </ng-container>
                <ng-container *ngIf="!userHasPreassignedVenue && !attendanceValue">-</ng-container>
              </h5>
            </div>
          </div>

          <div fxLayout="row" fxFlexOffset="12px">
            <!-- Indirizzo prenotazione -->
            <div fxLayout="column" fxFlex>
              <h5 class="sub-title" translate="admin.campaignRoundRescheduleReservation.RESERVATION_ADDRESS"></h5>
              <h5>
                <ng-container *ngIf="userHasPreassignedVenue && attendances.length">
                  {{ attendances[0].content.venue.address }}
                </ng-container>
                <ng-container *ngIf="!userHasPreassignedVenue && attendanceValue && attendanceValue.venue">
                  {{ attendanceValue.venue.address }}
                </ng-container>
                <ng-container *ngIf="!userHasPreassignedVenue && !attendanceValue">-</ng-container>
              </h5>
            </div>

            <!-- Sede prenotazione -->
            <div fxLayout="column" fxFlex>
              <h5 class="sub-title" translate="admin.campaignRoundRescheduleReservation.RESERVATION_VENUE"></h5>
              <h5>
                <ng-container *ngIf="userHasPreassignedVenue && attendances.length">
                  {{ attendances[0].content.venue.name }}
                </ng-container>
                <ng-container *ngIf="!userHasPreassignedVenue && attendanceValue && attendanceValue.venue">
                  {{ attendanceValue.venue.name }}
                </ng-container>
                <ng-container *ngIf="!userHasPreassignedVenue && !attendanceValue">-</ng-container>
              </h5>
            </div>
          </div>

          <h5 fxFlexOffset="12px" class="bold" *ngIf="userHasPreassignedVenue"
            translate="admin.campaignRoundRescheduleReservation.RESERVATION_PREASSIGNED_VENUE"></h5>

        </ng-container>
        <div *ngIf="isLoadingBase || !round" class="loader spinner-small"></div>
      </div>
    </div>
  </div>

  <div id="admin-campaign-round-reschedule-reservation-anchor" fxFlexOffset="28px"></div>

  <div fxFlexOffset="28px" class="campaign-round-reschedule-reservation-container" fxLayout="column">

    <form fxLayout="{{isScheduleEntitled ? 'column' : 'row'}}" fxLayoutAlign="space-between center"
      class="search-request" [formGroup]="searchForm" (ngSubmit)="searchRoundSlots()">
      <div fxLayout="row" fxFlex>
        <!-- Indirizzo ricerca -->
        <input-container fxFlex="400px" class="full-width" [inputEditable]="disabledAddress.value"
          (renderInputEditable)="onEditAddress()" isWithoutInfoIcon withTransparency
          [title]="'admin.campaignRoundRescheduleReservation.SEARCH_ADDRESS' | translate">
          <!-- Via Giuseppe Mazzini, 15, Roma -->
          <welion-input [inputValue]="address" hasMapsAutocomplete [mapsOptions]="undefined" withTransparency
            (selectedPlace)="selectedPlace($event)"
            [placeholder]="'admin.campaignRoundRescheduleReservation.SEARCH_ADDRESS_PLACEHOLDER' | translate"
            [disabled]="isLoading || disabledAddress.value || address.disabled">
          </welion-input>
        </input-container>

        <!-- Raggio di ricerca -->
        <input-container fxFlex="160px" fxFlexOffset="16px" isWithoutInfoIcon withTransparency
          [title]="'admin.campaignRoundRescheduleReservation.SEARCH_KM' | translate" fxFlexOffset=" 24px">
          <div fxLayout="row" fxLayoutAlign="start center">
            <welion-select withTransparency fxFlex="92px" [items]="kmPreferences" [selectedItem]="kmPreference.value"
              (change)="onChangeKMPreference( $event)" [disabled]="isLoading || kmPreference.disabled"></welion-select>
            <h5 fxFlexOffset="12px">KM</h5>
          </div>
        </input-container>

        <!-- Quando -->
        <input-container *ngIf="!isScheduleEntitled" fxFlex="180px" fxFlexOffset="16px" isWithoutInfoIcon
          withTransparency [title]="'admin.campaignRoundRescheduleReservation.SEARCH_DATE' | translate">
          <welion-datepicker withTransparency placeholder="generic.DATEPICKER" [value]="day.value"
            (dateChanged)="onDayChange($event)" [minDate]="roundStartDate" [maxDate]="roundEndDate">
          </welion-datepicker>
        </input-container>

        <!-- Dal giorno -->
        <input-container *ngIf="isScheduleEntitled" fxFlex="180px" fxFlexOffset="16px" isWithoutInfoIcon
          withTransparency [title]="'admin.campaignRoundRescheduleReservation.FROM_DATE' | translate">
          <welion-datepicker withTransparency placeholder="generic.DATEPICKER" [value]="fromDay.value"
            (dateChanged)="onFromDayChange($event)" [minDate]="roundStartDate" [maxDate]="roundEndDate">
          </welion-datepicker>
        </input-container>

        <!-- Al giorno -->
        <input-container *ngIf="isScheduleEntitled" fxFlex="180px" fxFlexOffset="16px" isWithoutInfoIcon
          withTransparency [title]="'admin.campaignRoundRescheduleReservation.TO_DATE' | translate">
          <welion-datepicker withTransparency placeholder="generic.DATEPICKER" [value]="toDay.value"
            (dateChanged)="onToDayChange($event)" [minDate]="roundStartDate" [maxDate]="roundEndDate">
          </welion-datepicker>
        </input-container>

        <!-- Dall'ora -->
        <input-container fxFlex="100px" fxFlexOffset="16px" isWithoutInfoIcon withTransparency
          [title]="'admin.campaignRoundRescheduleReservation.SEARCH_TIME_FROM' | translate">
          <welion-input hasTimePicker [placeholder]="'generic.HHMM' | translate" [inputValue]="fromTime"
            withTransparency>
          </welion-input>
        </input-container>

        <!-- All'ora -->
        <input-container fxFlex="100px" fxFlexOffset="16px" isWithoutInfoIcon withTransparency
          [title]="'admin.campaignRoundRescheduleReservation.SEARCH_TIME_TO' | translate">
          <welion-input hasTimePicker [placeholder]="'generic.HHMM' | translate" [inputValue]="toTime" withTransparency>
          </welion-input>
        </input-container>
      </div>

      <!-- Button Cerca -->
      <div fxLayout="row" fxLayoutAlign="end center" [ngClass]="{'margin-top60': isScheduleEntitled}"
        class="full-width">
        <welion-button width="120px" type="primary" size="medium" label="generic.SEARCH" [disabled]="isSearchDisabled()"
          buttonType="submit">
        </welion-button>
      </div>

    </form>

    <div fxFlexOffset="20px" class="campaign-round-reschedule-reservation-divider"></div>

    <ng-container *ngIf="firstSearchDone else firstSearch">
      <ng-container *ngIf="!isLoadingCount && !isLoadingBase">
        <ng-container *ngIf="slotsCount > 0 else noSlots">

          <div fxFlexOffset="32px" fxLayout="row" fxLayoutAlign="space-between center">
            <!-- Sedi -->
            <h3 fxFlexOffset="16px">
              {{ 'admin.campaignRoundRescheduleReservation.TITLE_SLOTS' | translate: { value: slotsCount } }}
            </h3>
          </div>

          <!-- Table header -->
          <div fxFlexOffset="24px" fxLayout="row">
            <!-- Sede -->
            <welion-order-select itemLabel="LETTER" [selectedOrder]="selectedOrders['VENUE_NAME']"
              label="admin.campaignRoundRescheduleReservation.table.VENUE" fxFlex="20" fxFlexOffset="16px"
              (onSelectedOrder)="updateOrder($event, 'VENUE_NAME')"></welion-order-select>
            <!-- Indirizzo -->
            <welion-order-select itemLabel="LETTER" [selectedOrder]="selectedOrders['ADDRESS']"
              label="admin.campaignRoundRescheduleReservation.table.ADDRESS" fxFlex="25"
              (onSelectedOrder)="updateOrder($event, 'ADDRESS')"></welion-order-select>
            <!-- Distanza -->
            <welion-order-select itemLabel="DISTANCE" [selectedOrder]="selectedOrders['DISTANCE']"
              label="admin.campaignRoundRescheduleReservation.table.DISTANCE" fxFlex="12"
              (onSelectedOrder)="updateOrder($event, 'DISTANCE')"></welion-order-select>
            <!-- Data -->
            <welion-order-select itemLabel="DATE" [selectedOrder]="selectedOrders['DATE']"
              label="admin.campaignRoundRescheduleReservation.table.DAY" fxFlex="15"
              (onSelectedOrder)="updateOrder($event, 'DATE')"></welion-order-select>
            <!-- Orario -->
            <welion-order-select itemLabel="DATE" [selectedOrder]="selectedOrders['TIME']"
              label="admin.campaignRoundRescheduleReservation.table.TIME" fxFlex="10"
              (onSelectedOrder)="updateOrder($event, 'TIME')"></welion-order-select>

            <div fxFlex></div>
          </div>

          <!-- Loader -->
          <div *ngIf="isLoading" class="loader spinner-big"></div>

          <!-- Table rows -->
          <ng-container *ngIf="!isLoading">
            <!-- Table rows -->
            <div *ngFor="let slot of slots; let index = index; let odd = odd" class="reschedule-slots-row"
              [ngClass]="{'odd': odd}" fxLayout="row" fxLayoutAlign="start center"
              [fxFlexOffset]="index === 0 ? '16px' : '0px'">
              <!-- Sede -->
              <h6 class="black" fxFlex="20" fxFlexOffset="16px">
                {{ userHasPreassignedVenue ? (attendanceValue.venue ? attendanceValue.venue.name : '') : slot.name }}
              </h6>
              <!-- Indirizzo -->
              <h6 class="black" fxFlex="25">
                {{ userHasPreassignedVenue ? (attendanceValue.venue ? attendanceValue.venue.address : '') : slot.address
                }}
              </h6>
              <!-- Distanza -->
              <h6 class="black center" fxFlex="10">{{ ((slot.distance || 0) | number: '1.0-1') + 'km' }}</h6>
              <!-- Data -->
              <h6 class="black center" fxFlex="15">{{ slot.startDate | date: 'dd MMMM yyyy' | titlecase }}</h6>
              <!-- Orario -->
              <h6 class="black center" fxFlex="10">{{
                (slot.startDate | date: 'HH:mm') +
                ' - ' +
                (slot.endDate | date: 'HH:mm')
                }}</h6>

              <div fxFlex fxLayout="row" fxLayoutAlign="end">
                <!-- Ripianifica/Pianifica -->
                <h6 class="black bold underline cursor-pointer" (click)="openAttendanceRescheduleModal(slot)"
                  [translate]="isScheduleEntitled ?'admin.campaignRoundRescheduleReservation.table.SCHEDULE' : 'admin.campaignRoundRescheduleReservation.table.RESCHEDULE'">
                </h6>
                <!-- Scheda -->
                <h6 fxFlexOffset="12px" class="black bold underline cursor-pointer" (click)="showVenueModal(slot)"
                  translate="admin.campaignRoundRescheduleReservation.table.FORM"></h6>
                <div fxFlex="16px"></div>
              </div>
            </div>

          </ng-container>

          <!-- Paginatore -->
          <welion-paginator fxFlexOffset="20px" [selectedIndex]="pageSelectedIndex" [isLoading]="isLoading"
            [paginationLength]="paginationLength" [itemsCount]="slotsCount"
            (onClickPage)="getSlotsForReservationMoving($event)">
          </welion-paginator>

        </ng-container>
      </ng-container>
    </ng-container>

    <div *ngIf="isLoadingCount || isLoadingBase" class="loader spinner-big"></div>


  </div>

</div>

<ng-template #firstSearch>
  <div fxLayout="column" fxLayoutAlign="start center">
    <svg-icon fxFlexOffset="40px" [applyClass]="true" src="assets/img/icons/note-health-facility-red.svg"></svg-icon>

    <!-- Aggiungi delle sedi a questo round -->
    <h3 fxFlexOffset="32px" translate="admin.campaignRoundRescheduleReservation.FIRST_SEARCH"></h3>

  </div>
</ng-template>

<ng-template #noSlots>
  <div fxLayout="column" fxLayoutAlign="start center">
    <svg-icon fxFlexOffset="40px" [applyClass]="true" src="assets/img/icons/note-health-facility-red.svg"></svg-icon>

    <!-- Aggiungi delle sedi a questo round -->
    <h3 fxFlexOffset="32px" translate="admin.campaignRoundRescheduleReservation.NO_SLOTS"></h3>

  </div>
</ng-template>

<!-- Sidenav con la scheda di dettaglio della sede -->
<sidebar hideCloseButton showAppHeader [sidenavTemplateRef]="navContent" direction="right" [navWidth]="664"
  [duration]="0.3">
</sidebar>

<!-- Contenuto sidenav -->
<ng-template #navContent>

  <div class="venue-card-sidenav" fxLayout="column">

    <div class="full-width" fxLayout="row" fxLayoutAlign="end">
      <!-- Icona chiudi -->
      <div fxLayoutAlign="row" fxLayoutAlign="center center" (click)="closeVenueCard()" class="close-container">
        <svg-icon class="small-size" src="/assets/img/icons/x.svg" [applyClass]="true"></svg-icon>
        <h5 fxFlexOffset="8px" class="white">{{ 'generic.CLOSE' | translate | uppercase}}</h5>
      </div>
    </div>

    <ng-container *ngIf="selectedVenue">
      <!-- Scheda struttura -->
      <h5 fxFlexOffset="20px" class="bold" translate="addReservation.steps.side.TITLE"></h5>

      <h3 fxFlexOffset="16px">{{ selectedVenue.name }}</h3>

      <div fxFlexOffset="16px" class="venue-card-sidenav-divider"></div>

      <div fxFlexOffset="32px" class="venue-card-tabs" fxLayout="row">
        <!-- Mappa -->
        <tab-light [tabTitle]="'addReservation.steps.side.tab.MAP' | translate" [isCurrentTab]="selectedVenueTab === 0"
          [attrAriaLabel]="'addReservation.steps.side.tab.MAP' | translate" (onClick)="onVenueTabClick(0)">
        </tab-light>

        <!-- Dettagli -->
        <tab-light fxFlexOffset="32px" [tabTitle]="'addReservation.steps.side.tab.DETAILS' | translate"
          [isCurrentTab]="selectedVenueTab === 1" [attrAriaLabel]="'addReservation.steps.side.tab.DETAILS' | translate"
          (onClick)="onVenueTabClick(1)">
        </tab-light>
      </div>

      <ng-container *ngIf="selectedVenueTab === 0">

        <!-- Indirizzo struttura -->
        <h5 fxFlexOffset="28px">{{ selectedVenue.address }}</h5>

        <!-- Mappa google -->
        <agm-map fxFlexOffset="8px" *ngIf="venueMapData && venueMapData.lat && venueMapData.lng" class="map"
          [latitude]="venueMapData.lat" [longitude]="venueMapData.lng" [disableDefaultUI]="false" [zoomControl]="false">
          <agm-marker [latitude]="venueMapData.lat" [longitude]="venueMapData.lng" label="A" [markerDraggable]="false">
          </agm-marker>
        </agm-map>

      </ng-container>

      <!-- Dettagli -->
      <ng-container *ngIf="selectedVenueTab === 1">
        <!-- La sede ... è facilmente .. -->
        <h5 fxFlexOffset="28px">{{ 'addReservation.steps.side.tab.DETAILS_INFO' | translate:
          { name: selectedVenue.name } }}</h5>
        <!-- Descrizione sede -->
        <h5 fxFlexOffset="8px" *ngIf="selectedVenue.description">{{ selectedVenue.description }}</h5>
        <!-- Indirizzo -->
        <h3 class="small bold" fxFlexOffset="24px" translate="addReservation.steps.side.tab.DETAILS_ADDRESS"></h3>
        <!-- Nome legale -->
        <h5 fxFlexOffset="4px" *ngIf="selectedVenue.name">{{ selectedVenue.name }}</h5>
        <!-- Indirizzo sede -->
        <h5 fxFlexOffset="4px">{{ selectedVenue.address }}</h5>

        <div fxFlexOffset="28px" class="junior-form" fxLayout="column"
          *ngIf="selectedVenue.validMinorTypes && selectedVenue.validMinorTypes.length && selectedVenue.validMinorTypes[0].age < 18">
          <!-- Gestione del vaccino (nel caso di minori) -->
          <h5 class="bold" translate="addReservation.steps.side.MANAGEMENT"></h5>

          <div *ngFor="let validMinorType of selectedVenue.validMinorTypes">
            <!-- Dai {{ minAge }} ai {{ maxAge }} anni: {{ vaccinationType }} -->
            <h5 *ngIf="validMinorType.age === 12" fxFlexOffset="4px">
              {{ 'addReservation.steps.side.AGE_GROUPS' | translate: {
              minAge: 12,
              maxAge: 18,
              vaccinationType: (('addReservation.steps.side.' + validMinorType.vaccinationType) | translate)
              } }}
            </h5>
            <h5 *ngIf="validMinorType.age === 9" fxFlexOffset="4px">
              {{ 'addReservation.steps.side.AGE_GROUPS' | translate: {
              minAge: 9,
              maxAge: 12,
              vaccinationType: (('addReservation.steps.side.' + validMinorType.vaccinationType) | translate)
              } }}
            </h5>
            <h5 *ngIf="validMinorType.age === 6" fxFlexOffset="4px">
              {{ 'addReservation.steps.side.AGE_GROUPS' | translate: {
              minAge: 6,
              maxAge: 9,
              vaccinationType: (('addReservation.steps.side.' + validMinorType.vaccinationType) | translate)
              } }}
            </h5>
            <!-- Sotto i 6 anni: {{ vaccinationType }} -->
            <h5 *ngIf="validMinorType.age === 5" fxFlexOffset="4px">
              {{ 'addReservation.steps.side.AGE_GROUPS_UNDER_SIX' | translate: {
              vaccinationType: (('addReservation.steps.side.' + validMinorType.vaccinationType) | translate)
              } }}
            </h5>
          </div>
        </div>

      </ng-container>

    </ng-container>


    <div fxFlexOffset="20px"></div>

  </div>

</ng-template>

<!-- Modale per la cancellazione di un cliente -->
<modal id="rescheduleAttendance" (onClose)="closeAttendanceRescheduleModal()"
  (onCancel)="confirmAttendanceReschedule(true)" [disableAll]="isLoadingBase"
  (onConfirm)="confirmAttendanceReschedule()"
  [cancelLabel]="'generic.CONFIRM_AND_NOT_SEND_MAIL_NOTIFY' | translate | uppercase"
  [confirmLabel]="'generic.CONFIRM_AND_SEND_MAIL_NOTIFY' | translate | uppercase">
  <modal-text-content [withScrolledBody]="true"
    [title]="'admin.campaignRoundRescheduleReservation.modal.TITLE' | translate" [text]="'admin.campaignRoundRescheduleReservation.modal.RESET_WARNING' | translate: {
      value1: attendanceValue ? (attendanceValue.surname + ' ' + attendanceValue.name) : '',
      value2: selectedSlot ? (
        (selectedSlot.startDate| date: 'dd MMMM yyyy HH:mm') +
                ' - ' +
        (selectedSlot.endDate| date: 'HH:mm')
      ) : ''
    }" [subtitle]="'admin.campaignRoundRescheduleReservation.modal.SUBTITLE' | translate">
  </modal-text-content>
</modal>
<div class="welion-text-input-login-wrapper" fxLayout="column" fxLayoutAlign="start">
  <label class="label-text" id="welion-minimal-input-label-{{id}}" for="welion-minimal-input-{{id}}">{{text}}</label>

  <input id="welion-minimal-input-{{id}}" name="welion-minimal-input-{{id}}" [placeholder]="placeholder" [attr.aria-label]="placeholder" [type]="_type" [type]="typeInput"
    (keyup.enter)="emitOnIconClicked($event)" [formControl]="_inputValue" [tabindex]="_tabIndex" [required]="required"
    [attr.aria-disabled]="disabled" [disabled]="disabled" [attr.aria-required]="required"
    [attr.aria-labelledby]="ariaLabelledbyLabel ? ariaLabelledbyId ? ('welion-minimal-input-label-' + ariaLabelledbyLabel + ' ' + ariaLabelledbyId) : ('welion-minimal-input-label-' + ariaLabelledbyLabel) : ''">

  <ng-container *ngIf="errorText && _inputValue.errors && _inputValue.errors.length > 0">
    <p tabindex="0" class="label-error-text" *ngIf="errorText">{{errorText}}</p>
  </ng-container>
</div>

<div class="import-entitleds-from-round-container" fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="start start"
  fxLayoutAlign.lt-md="start center">

  <div class="import-entitleds-from-round-side" fxLayout="column" fxLayoutAlign="space-between">

    <div fxLayout="column" fxLayoutAlign="start start">
      <div class="import-entitleds-from-round-back" fxLayout="row" fxLayoutAlign="start center" fxFlexOffset="40px"
        (click)="goBack()">
        <svg-icon fxFlexOffset="40px" [applyClass]="true" src="./assets/img/icons/arrow-white-left.svg"></svg-icon>
        <h5 class="white" fxFlexOffset="12px">{{ 'generic.GO_BACK' | translate | uppercase}}</h5>
      </div>

      <p fxFlexOffset="24px">
        <ng-container *ngIf="!isLoadingBase && campaign && round">
          <span translate="admin.importEntitledsFromRound.SIDE_TITLE"></span>
          <span class="bold">
            {{ round.startDate | date: 'dd MMMM yyyy' | titlecase }}
            {{ ' - ' }}
            {{ round.endDate | date: 'dd MMMM yyyy' | titlecase }}
          </span>
          <span translate="admin.importEntitledsFromRound.SIDE_TITLE2"></span>
          <span class="bold">{{ campaign.title }}</span>
        </ng-container>
        <span *ngIf="isLoadingBase || !campaign || !round" translate="generic.LOADING"></span>
      </p>

      <div fxFlexOffset="24px" class="import-entitleds-from-round-tick"></div>
    </div>

    <svg-icon fxShow fxHide.lt-md class="welion-logo" [applyClass]="true" src="./assets/img/icons/app-logo.svg">
    </svg-icon>

    <div fxShow fxHide.lt-md fxFlexOffset="40px"></div>

  </div>

  <div fxFlex fxLayout="row" fxLayout.xs="column" fxLayoutAlign="center" fxLayoutAlign.xs="start">

    <div fxFlex fxLayout="column" fxLayoutAlign="start center" class="import-entitleds-from-round-body-container">

      <h3 translate="admin.importEntitledsFromRound.TITLE" ngClass.xs="center"></h3>

      <!-- Loader -->
      <div *ngIf="isLoadingCount" class="loader spinner-big"></div>

      <ng-container *ngIf="!isLoadingCount">
        <div *ngIf="roundCounts > 1 else noRounds" class="full-width" fxLayout="column">

          <div *ngIf="clientsCount > 0 else noClients" class="full-width" fxLayout="column">

            <!-- Round -->
            <input-container isWithoutInfoIcon fxFlexOffset="24px" class="full-width"
              [title]="'admin.importEntitledsFromRound.ROUND' | translate">
              <welion-select [placeholder]="'admin.importEntitledsFromRound.ROUND_PLACEHOLDER' | translate"
                [disabled]="isLoading" [items]="rounds" [selectedItem]="selectedRound" (change)="onChangeRound($event)">
              </welion-select>
            </input-container>

            <!-- Table header -->
            <div fxFlexOffset="24px">
              <!-- Checkbox -->
              <welion-check fxFlexOffset="16px" [checked]="selectAllClients" [disabled]="isLoading"
                (onCheck)="onSelectAllClients($event)">
              </welion-check>
              <!-- Cliente -->
              <h5 class="bold" translate="admin.importEntitledsFromRound.CLIENTS" fxFlex fxFlexOffset="16px">
              </h5>
            </div>

            <div *ngIf="isLoading" class="loader spinner-big"></div>

            <ng-container *ngIf="!isLoading">
              <!-- Table rows -->
              <div *ngFor="let client of clients; let index = index" class="client-row" fxLayout="row"
                fxLayoutAlign="start center" [fxFlexOffset]="index === 0 ? '12px' : '0px'">
                <!-- Checkbox -->
                <welion-check fxFlexOffset="16px" [checked]="clientIsChecked(client)"
                  (onCheck)="onClientChecked(client, $event)" [disabled]="selectAllClients">
                </welion-check>
                <!-- Cliente -->
                <h6 class="black" fxFlex fxFlexOffset="16px">{{ client.title }}</h6>
              </div>
            </ng-container>

            <!-- Paginatore -->
            <welion-paginator fxFlexOffset="20px" [isLoading]="isLoading" [paginationLength]="paginationLength"
              [itemsCount]="clientsCount" (onClickPage)="getClients($event)"></welion-paginator>

            <div *ngIf="isVerified" fxFlexOffset="24px" class="copy-preview" fxLayout="column">
              <p>
                <ng-container *ngIf="entitledsCount === 0">
                  <span class="bold" translate="admin.importEntitledsFromRound.NO_PREVIEW"></span>
                </ng-container>
                <ng-container *ngIf="entitledsCount > 0">
                  <span translate="admin.importEntitledsFromRound.PREVIEW1"></span>
                  <span class="bold">
                    {{ 'admin.importEntitledsFromRound.PREVIEW2' | translate: { value: entitledsCount } }}
                  </span>
                </ng-container>

                <span translate="admin.importEntitledsFromRound.PREVIEW3"></span>
                <span class="bold">
                  <ng-container *ngIf="selectedRound">
                    {{ selectedRound.content.startDate | date: 'dd MMMM yyyy' }}
                    {{ ' - ' }}
                    {{ selectedRound.content.endDate | date: 'dd MMMM yyyy' }}
                  </ng-container>
                </span>
                <span translate="admin.importEntitledsFromRound.PREVIEW4"></span>
                <span class="bold">
                  <ng-container *ngIf="round">
                    {{ round.startDate | date: 'dd MMMM yyyy' }}
                    {{ ' - ' }}
                    {{ round.endDate | date: 'dd MMMM yyyy' }}
                  </ng-container>
                </span>
              </p>
            </div>

            <!-- Rimuovi sede -->
            <div fxFlexOffset="24px" class="full-width" fxLayout="row" fxLayoutAlign="space-between">
              <welion-button width="180px" type="secondary" size="large" label="admin.importEntitledsFromRound.VERIFY"
                [disabled]="isLoading || !selectedRound || (!selectAllClients && selectedClients.length === 0) || isVerified"
                buttonType="submit" (onClick)="onVerifyingCopy()">
              </welion-button>

              <welion-button width="264px" type="primary" size="large" label="admin.importEntitledsFromRound.CONFIRM"
                [disabled]="isLoading || entitledsCount === 0" buttonType="submit" (onClick)="confirmCopyEntitleds()">
              </welion-button>
            </div>
          </div>

        </div>
      </ng-container>

    </div>
  </div>
</div>

<ng-template #noRounds>
  <div fxLayout="column" fxLayoutAlign="start center">
    <svg-icon fxFlexOffset="40px" [applyClass]="true" src="assets/img/icons/note-health-facility-red.svg"></svg-icon>
    <!-- Non sono presenti round dal quale importare aventi diritto -->
    <h4 fxFlexOffset="32px" translate="admin.importEntitledsFromRound.NO_ROUNDS"></h4>
  </div>
</ng-template>

<ng-template #noClients>
  <div fxLayout="column" fxLayoutAlign="start center">
    <svg-icon fxFlexOffset="40px" [applyClass]="true" src="assets/img/icons/note-health-facility-red.svg"></svg-icon>
    <!-- Non sono presenti clienti dal quale importare aventi diritto -->
    <h4 fxFlexOffset="32px" translate="admin.importEntitledsFromRound.NO_CLIENTS"></h4>
  </div>
</ng-template>

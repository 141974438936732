<div class="add-round-container" fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="start start"
  fxLayoutAlign.lt-md="start center">

  <div class="add-round-side" fxLayout="column" fxLayoutAlign="space-between">

    <div fxLayout="column" fxLayoutAlign="start start">
      <div class="add-round-back" fxLayout="row" fxLayoutAlign="start center" fxFlexOffset="40px" (click)="goBack()">
        <svg-icon fxFlexOffset="40px" [applyClass]="true" src="./assets/img/icons/arrow-white-left.svg"></svg-icon>
        <h5 class="white" fxFlexOffset="12px">{{ 'generic.GO_BACK' | translate | uppercase}}</h5>
      </div>

      <div fxFlexOffset="24px" *ngIf="campaign">
        <ng-container *ngIf="round">
          <p class="sidenav-title" translate="admin.editVenueBookingDate.EDIT_BOOKING_DATES"></p>

          <div class="currentBookingDatesContainer">
            <div class="datesContent">
              <p translate="admin.editVenueBookingDate.CURRENT_DATES"></p>
              <p>{{ oldBookingDatesLabel }}</p>
            </div>
          </div>
        </ng-container>
      </div>

      <div fxFlexOffset="24px" class="add-round-tick"></div>

      <div class="campaignAndVenueInfoContainer" *ngIf="campaignAndVenueInfoLabel && campaignAndVenueInfoLabel.length">
        <span [innerHtml]="campaignAndVenueInfoLabel"></span>
      </div>

      <!-- <div fxShow fxHide.lt-md class="add-round-faq" fxFlexOffset="48px" fxLayout="row">
        <h5 translate="generic.FAQ"></h5>
        <h5 fxFlexOffset="4px" translate="generic.FAQ2" class="primary bold faq-link" (click)="goToFAQ()"></h5>
      </div> -->
    </div>

    <svg-icon fxShow fxHide.lt-md class="welion-logo" [applyClass]="true" src="./assets/img/icons/app-logo.svg">
    </svg-icon>

    <div fxShow fxHide.lt-md fxFlexOffset="40px"></div>

  </div>

  <div fxFlex fxLayout="row" fxLayout.xs="column" fxLayoutAlign="center" fxLayoutAlign.xs="start"
    class="add-round-body-container">

    <form class="add-round-body" [formGroup]="editDatesForm" fxLayout="column" fxLayoutAlign="start center"
      (ngSubmit)="openConfirmEditBookingDateModal()">

      <h3 translate="admin.editVenueBookingDate.EDIT_DATES" ngClass.xs="center"></h3>

      <!-- Inizio prenotazioni -->
      <input-container fxFlexOffset="24px" class="full-width"
        [title]="'admin.addRound.ROUND_MIN_RESERVATION_END_DATE' | translate">
        <welion-datepicker class="full-width" placeholder="generic.CHOOSE_DATE" [value]="bookingStartDate.value"
          (dateChanged)="onBookingStartDateChange($event)">
        </welion-datepicker>
      </input-container>

      <!-- Fine prenotazioni -->
      <input-container fxFlexOffset="24px" class="full-width"
        [title]="'admin.addRound.ROUND_MAX_RESERVATION_END_DATE' | translate">
        <welion-datepicker class="full-width" placeholder="generic.CHOOSE_DATE" [value]="bookingEndDate.value"
          (dateChanged)="onBookingEndDateChange($event)">
        </welion-datepicker>
      </input-container>

      <div *ngIf="isLoading" class="loader spinner-big"></div>

      <!-- Conferma modifiche -->
      <div fxFlexOffset="24px" class="full-width" fxLayout="row" fxLayoutAlign="end">
        <welion-button width="264px" type="primary" size="large" label="admin.editVenueBookingDate.EDIT_DATES"
          [disabled]="editDatesForm.invalid || isLoading" buttonType="submit">
        </welion-button>
      </div>

      <div fxHide fxShow.xs fxFlex.xs="40px"></div>

    </form>

  </div>

</div>

<!-- Modale per la conferma  -->
<modal id="confirmEditBookingDate" (onClose)="closeConfirmEditBookingDateModal()"
  (onCancel)="closeConfirmEditBookingDateModal()" (onConfirm)="confirmEditBookingDates()"
  [cancelLabel]="'generic.CLOSE' | translate | uppercase" [confirmLabel]="'generic.CONFIRM' | translate | uppercase"
  [disableAll]="isLoading">
  <modal-text-content [withScrolledBody]="true" [title]="'admin.editVenueBookingDate.EDIT_BOOKING_DATE' | translate"
    [text]="confirmEditModalLabel" [subtitle]="'admin.editVenueBookingDate.CONFIRM_EDIT' | translate">
  </modal-text-content>
</modal>
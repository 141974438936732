<div class="page-content-wrapper" fxLayout="column" fxLayoutAlign="start center">

  <div class="round-structures" fxLayout="column">

    <h1 fxFlexOffset="28px" fxFlexOffset.xs="12px">{{ round?.title }}</h1>

    <!-- Lista uffici per round -->
    <div *ngIf="!showLoader" fxFlexOffset="16px" class="round-structures-container" fxLayout="column">

      <!-- Navigazione -->
      <bread-crumbs *ngIf="round" firstLevel="ROUND" [secondLevel]="(round.startDate | date: 'dd MMMM' | titlecase) + ' - '
          + (round.endDate | date: 'dd MMMM yyyy' | titlecase)" [idReference]="round.campaignId">
      </bread-crumbs>

      <!-- Ancoraggio per lo scroll -->
      <div id="round-structure-anchor" fxFlexOffset="16px" fxFlexOffset.xs="12px"></div>
      <!-- Lista di strutture -->
      <welion-select fxFlexOffset="16px" fxFlexOffset.xs="12px" isStructureSelect [bindLabel]="'title'"
        [selectedItem]="selectedStructure" searchable [loading]="isLoadingStructureList"
        (change)="onStructureSelected($event)" [items]="structureList" (scroll)="fetchMoreStructures($event)"
        [placeholder]="'structures.round.select.PLACEHOLDER' | translate"
        [noDataFoundLabel]="'structures.round.select.NO_STRUCTURE' | translate" [typeahead]="structureServive$"
        [attrAriaLabel]="'structures.round.select.PLACEHOLDER' | translate" [disabled]="disableStructureSelect">
      </welion-select>

      <ng-container *ngIf="selectedStructure">
        <!-- Orari -->
        <div class="section-title">
          <p class="number">1</p>
          <h4 translate="structures.round.TIME"> </h4>
        </div>
        <ng-container *ngIf="selectedStructure && structureDaySlots.length; else noDays">

          <div fxFlexOffset="40px" fxLayout="row" fxLayout.xs="column" fxLayoutAlign="space-between center">
            <!-- 15 giornate inserite per un totale di 556 SLOT e 200 PRENOTAZIONI -->
            <h6 class="black" [innerHTML]="'structures.round.RESUME' | translate: {
                  value1: ('structures.round.DAY' + (structureDaySlots.length !== 1 ? 'S' : '')) | translate: { value: structureDaySlots.length || 0 } | boldText,
                  value2: ('structures.round.SEAT' + (selectedStructure.content.totalSeats !== 1 ? 'S' : '')) | translate: { value: selectedStructure.content.totalSeats || 0 } | boldText,
                  value3: ('structures.round.BOOKING' + (selectedStructure.content.totalReservations !== 1 ? 'S' : '')) | translate: { value: selectedStructure.content.totalReservations || 0 } | boldText
                }"></h6>

            <!-- Aggiungi e select mobile -->
            <div fxFlex="232px" fxFlex.xs="100" fxFlexOffset.xs="16px" fxLayout="row" fxLayoutAlign="end"
              fxLayoutAlign.xs="space-between" ngClass.xs="full-width">

              <div fxHide fxShow.xs class="full-width">
                <!-- Lista giorni modalità mobile -->
                <welion-select [items]="structureDaySlotsDropdown" [selectedItem]="selectedStructureDaySlotsDropdown"
                  (change)="onSelectDaySlotMobile($event)">
                </welion-select>
              </div>

              <!-- Aggiungi -->
              <welion-select-button ngClass.gt-xs="full-width" label="generic.ADD" [forceModal]="!hasSavedDetails"
                (onClick)="openModalIfSecondStepIncomplete()" [dropdownItems]="dropdownAddItems" [direction]="'right'"
                fxFlexOffset.xs="8px"></welion-select-button>
              <div fxShow fxHide.xs fxFlexOffset="20px"></div>
            </div>
          </div>

          <div fxFlexOffset="32px" fxLayout="row">

            <!-- Lista giorni - nascosto in modalità mobile -->
            <div fxShow fxHide.xs fxLayout="column" fxLayoutAlign="start start" class="day-slots-cards">
              <div *ngFor="let daySlot of structureDaySlots; let first = first; let index = index"
                [fxFlexOffset]="first ? '0px' : '4px'"
                [ngClass]="{'selected-day': index === indexSelectedStructureDaySlots}" class="day-slots"
                (click)="onSelectDaySlot(index)">
                <!-- Lunedì 1 Marzo -->
                <h5>{{ daySlot.date | date: 'EEEE dd MMMM' | titlecase }}</h5>
              </div>
            </div>

            <!-- Lista slots -->
            <div fxFlexOffset="20px" fxFlexOffset.xs="0px" fxLayout="column" class="slot-list full-width">

              <!-- Indicazioni -->
              <div fxLayout="row" fxLayoutAlign="space-between center">
                <!-- Indicazioni visive - legenda -->
                <div fxLayout="row" class="full-width">

                  <div fxLayout="row" fxLayout.xs="column" fxFlexOffset.xs="6px">
                    <!-- Disponibile -->
                    <ng-container [ngTemplateOutlet]="roundStatus" [ngTemplateOutletContext]="{
                        noOffset: true,
                        noMobileOffset: true,
                        label: 'AVAILABLE',
                        color: 'green',
                        statusId: 'APPROVED'
                      }"></ng-container>

                    <!-- Occupato -->
                    <ng-container [ngTemplateOutlet]="roundStatus" [ngTemplateOutletContext]="{
                        label: 'BUSY',
                        color: 'red',
                        statusId: 'BUSY'
                      }"></ng-container>
                  </div>

                  <div fxLayout="row" fxLayout.xs="column" fxFlexOffset.xs="40px">
                    <!-- In approvazione -->
                    <ng-container [ngTemplateOutlet]="roundStatus" [ngTemplateOutletContext]="{
                        noMobileOffset: true,
                        label: 'PENDING',
                        color: 'black',
                        statusId: 'APPROVATION_REQUEST'
                      }"></ng-container>

                    <!-- In revoca -->
                    <ng-container [ngTemplateOutlet]="roundStatus" [ngTemplateOutletContext]="{
                        label: 'REVOKED',
                        color: 'orange',
                        statusId: 'REVOKE_REQUEST'
                      }"></ng-container>
                  </div>

                </div>

                <!-- Informazioni -->
                <svg-icon class="question-mark cursor-pointer medium-size" [applyClass]="true" (click)="showHelper()"
                  src="assets/img/icons/question-mark-outline.svg">
                </svg-icon>

              </div>

              <!-- Slots -->
              <div fxFlexOffset="4px" fxLayout="row wrap" class="tile-container" infiniteScroll
                [infiniteScrollDistance]="2" [infiniteScrollThrottle]="300" (scrolled)="getDaySlots()">

                <slot-component-tile *ngFor="let slot of slots; let index = index"
                  [fromReservationDeletion]="slot.fromReservationDeletion" fxFlexOffset="8px" fxFlexOffset.xs="4px"
                  [selected]="slotIsSelected(slot)" [slot]="slot"
                  [ngClass]="{'cursor-pointer': slot.status === 'APPROVED' || slot.status === 'APPROVATION_REQUEST'}"
                  (click)="selectSlotClick(index)" [status]="slot.status">
                </slot-component-tile>

                <ng-container *ngIf="isLoadingSlots">
                  <div fxFlex class="loader spinner-medium"></div>
                </ng-container>

                <!-- Placeholder nessuno slot disponibile -->
                <ng-container *ngIf="!isLoadingSlots && !slots.length">
                  <div fxLayout="column">
                    <h6 fxFlexOffset="40px" class="black" translate="structures.round.NO_SLOTS"></h6>
                  </div>
                </ng-container>

              </div>

              <!-- Revoca -->
              <div *ngIf="isLoadingSlots || slots.length > 0" fxFlexOffset="32px" fxLayout="row" fxLayoutAlign="end"
                fxLayoutAlign.xs="center">
                <welion-button fxFlex="180px" label="generic.DELETE" type="primary bordless" size="medium"
                  (onClick)="openDeleteSlotModal()" [disabled]="selectedDeleteSlots.length === 0">
                </welion-button>

                <welion-button fxFlex="180px" fxFlexOffset="12px" label="generic.REVOKE" type="primary bordless"
                  size="medium" (onClick)="openRevokeSlotModal()" [disabled]="selectedSlots.length === 0">
                </welion-button>
              </div>

            </div>
            <!-- Margine a lato -->
            <div fxFlexOffset="20px" fxFlexOffset.xs="0px"></div>

          </div>
        </ng-container>


        <!-- Dettaglio informazioni della struttura -->
        <div class="section-title">
          <p class="number">2</p>
          <h4 translate="structures.round.DETAILS_MANDATORY"> </h4>
        </div>

        <div class="mandatory-question-container"
          *ngIf="selectedStructure && selectedStructure.content && selectedStructure.content.details">
          <!-- Servizi disponibili -->
          <p class="question-section-title" translate="structures.details.CHOOSE_SERVICE"></p>
          <ng-container *ngFor="let key of SERVICE_KEYS">
            <div class="question-row">
              <p class="label" [translate]="('structures.details.' + key) | translate"></p>
              <div class="answer-container">
                <welion-radio toTranslate [isRow]="true" [items]="answers" [bindValue]="getDetailsAnswer(key)"
                  radioName="choice" (onChange)="onDetailsAnswerSelected($event, key)">
                </welion-radio>
              </div>
            </div>
          </ng-container>

          <!-- Mezzi di trasporto -->
          <p class="question-section-title" translate="structures.details.VEHICLE"></p>
          <ng-container *ngFor="let key of VEHICLE_KEYS">
            <div class="question-row">
              <p class="label" [translate]="('structures.details.' + key) | translate"></p>
              <div class="answer-container">
                <welion-radio toTranslate [isRow]="true" [items]="answers" [bindValue]="getDetailsAnswer(key)"
                  radioName="choice" (onChange)="onDetailsAnswerSelected($event, key)">
                </welion-radio>
              </div>
            </div>
          </ng-container>

        </div>
        <!-- Note -->
        <input-container fxFlexOffset="24px" class="full-width" [title]="'structures.round.detail.NOTE' | translate">
          <welion-text-area [inputValue]="venueDescription"></welion-text-area>

          <div fxLayout="column"
            *ngIf="selectedStructure && selectedStructure.content.requestDescriptionUpdateStatus === 'APPROVED'">
            <!-- Approvato -->
            <h6 class="small light-grey" fxFlexOffset="20px" translate="structures.round.detail.APPROVED_NOTE">
            </h6>
          </div>

        </input-container>

        <div fxLayout="row" fxLayoutAlign="center" fxFlexOffset="8px">
          <welion-button width="180px" label="generic.SAVE_EDIT" type="primary" size="medium"
            (onClick)="onSaveNewVenueDescription()" [disabled]="showLoader || !checkIsSecondStepComplete()">
          </welion-button>
        </div>
        <!-- Nota in approvazione -->
        <div fxLayout="column"
          *ngIf="selectedStructure && selectedStructure.content.requestDescriptionUpdateStatus === 'PENDING'">
          <h3 class="small bold" translate="structures.round.detail.PENDING_NOTE"></h3>
          <h5>{{ selectedStructure.content.requestedDescriptionUpdate || '' }}</h5>
        </div>

        <!-- Nota rifiutata -->
        <div fxLayout="column"
          *ngIf="selectedStructure && selectedStructure.content.requestDescriptionUpdateStatus === 'NOT_APPROVED'">
          <h3 class="small bold" translate="structures.round.detail.REJECTED_NOTE"></h3>
          <h5>{{ selectedStructure.content.requestedDescriptionUpdate || '' }}</h5>
        </div>

        <!-- Indirizzo -->
        <div fxLayout="column" fxFlexOffset="16px">
          <h3 class="small bold" translate="structures.round.detail.ADDRESS"></h3>
          <h5>{{ selectedStructure.content.name }}</h5>
          <h5>{{ selectedStructure.content.address }}</h5>
        </div>



      </ng-container>

    </div>

    <!-- Loader di caricamento -->
    <ng-container *ngIf="showLoader">
      <div class="loader spinner-big"></div>
    </ng-container>

  </div>

</div>

<!-- #region Templates -->


<!-- Placeholder nessuna giornata disponibile -->
<ng-template #noDays>
  <h6 fxFlexOffset="60px" class="black" translate="structures.round.NO_DATAS"></h6>
  <div fxFlexOffset="20px" fxLayout="row" class="full-width">
    <!-- Aggiungi -->
    <welion-select-button ngClass.gt-xs="full-width" label="generic.ADD" [dropdownItems]="dropdownAddItems"
      [forceModal]="!hasSavedDetails" (onClick)="openModalIfSecondStepIncomplete()" [direction]="'right'"
      fxFlexOffset.xs="8px"></welion-select-button>
  </div>
</ng-template>

<!-- Template indicatore status degli slot -->
<ng-template #roundStatus let-noOffset='noOffset' let-noMobileOffset='noMobileOffset' let-label='label'
  let-color='color' let-statusId='statusId'>
  <div fxLayout="column" [ngClass]="{'is-active': statusIsSelected(statusId)}" fxLayoutAlign="center center"
    fxFlexOffset="{{noOffset ? 0 : 8}}px" class="cursor-pointer button-filter" (click)="onStatusClick(statusId)"
    fxFlexOffset.xs="{{noOffset || noMobileOffset ? 0 : 4}}px">
    <div fxLayout="row" fxLayoutAlign="start center">
      <div class="round-point {{color}}"></div>
      <h6 fxFlexOffset="4px" class="extra-small" translate="structures.round.status.{{label}}"></h6>
    </div>
    <!-- <div fxFlexOffset="4px" *ngIf="statusIsSelected(statusId)" class="statusIsSelected"></div> -->
  </div>
</ng-template>

<!-- #endregion -->

<!-- #region Modali -->

<!-- Modale di revoca degli slots -->
<modal id="revokeSlot" class="revoke-slot-modal" (onClose)="closeRevokeSlotModal()" (onCancel)="closeRevokeSlotModal()"
  [ngClass]="{'no-reservations': slotReservationList.length === 0, 'second-step': revokeStep === 2 }">

  <form *ngIf="selectedSlots.length" class="revoke-slot" fxLayout="column" (ngSubmit)="onConfirmRevoke()"
    [formGroup]="revokeForm">

    <!-- Richiesta di revoca -->
    <h2 fxFlexOffset="24px" class="small primary" translate="structures.round.revoke.TITLE"></h2>

    <div fxFlexOffset="24px" class="revoke-slot-info" fxLayout="column" fxLayoutAlign="center center">
      <!-- 21 Marzo 2021 -->
      <h1 class="black">{{ selectedSlots[0].startDate | date: 'dd MMMM yyyy' | titlecase }}</h1>
      <!-- 4 SLOT -->
      <h3 class="small">
        {{ 'structures.round.revoke.N_SLOT' | translate: { value: selectedSlots.length } }}
      </h3>
    </div>

    <!-- Step 1 -->
    <ng-container *ngIf="revokeStep === 1">

      <ng-container *ngIf="slotReservationList.length">
        <!-- Attenzione! -->
        <h3 fxFlexOffset="8px" class="small bold" translate="structures.round.revoke.ATTENTION"></h3>
        <!-- Ci sono già delle prenotazioni per lo slot selezionato... -->
        <h5 fxFlexOffset="8px" translate="structures.round.revoke.RESERVATIONS"></h5>

        <div fxFlexOffset="8px" *ngFor="let slot of slotReservationList" class="revoke-reservation"
          [class]="{'even': slot.even}" fxLayout="row" fxLayoutAlign="start center">
          <!-- 10 minuti -->
          <h6 fxFlexOffset="2px" class="black" fxFlex="148px">{{ slot.duration }}</h6>
          <!-- 09:00 -->
          <h6 class="black" fxFlex="48px">{{ slot.startDate | date: 'HH:mm' }}</h6>
          <!-- 09:10 -->
          <h6 fxFlexOffset="20px" class="black" fxFlex="48px">{{ slot.endDate | date: 'HH:mm' }}</h6>
          <!-- 1 -->
          <h6 fxFlexOffset="20px" class="black" fxFlex="20px">1</h6>
        </div>
      </ng-container>

      <!-- Giustificazione -->
      <input-container fxFlexOffset="24px" [isFromTextArea]="true" [numberCharsTextArea]="200"
        [title]="'structures.round.revoke.JUSTIFICATION' | translate">
        <welion-text-area [placeholder]="'structures.round.revoke.REVOKE_DESC' | translate"
          [inputValue]="justification">
        </welion-text-area>
      </input-container>
    </ng-container>

    <!-- Step 2 -->
    <ng-container *ngIf="revokeStep === 2">
      <!-- Sei sicuro? -->
      <h3 fxFlexOffset="24px" class="small bold primary" translate="structures.round.revoke.SURE"></h3>
      <!-- Questa azione NON è reversibile... -->
      <h3 class="small bold primary" translate="structures.round.revoke.SURE_DESC"></h3>
    </ng-container>

    <div [fxFlexOffset]="revokeStep === 1 ? '24px' : '44px'" fxLayout="row" fxLayoutAlign="center">
      <welion-button fxFlex="410px" fxFlex.xs="100" label="generic.CONFIRM" type="primary" size="large"
        buttonType="submit" [disabled]="revokeForm.invalid">
      </welion-button>
    </div>

  </form>

</modal>

<!-- Modale per il caricamento massivo -->
<modal-upload id="massiveLoad" uploadType="STRUCTURE" [isConfirmBtnDisabled]="isConfirmUploadStructuresBtnDisabled()"
  (onConfirm)="uploadStructuresFile()" [isToResetUploadComponent]="isToResetUploadComponent"
  (assignFile)="saveStructuresFile($event)">
</modal-upload>

<!-- Modale per il reset no show -->
<modal id="deleteSlot" (onClose)="closeDeleteSlotModal()" (onCancel)="closeDeleteSlotModal()"
  (onConfirm)="onConfirmDelete()" [cancelLabel]="'generic.CLOSE' | translate | uppercase"
  [confirmLabel]="'generic.CONFIRM' | translate | uppercase" [disableAll]="isLoadingSlots">
  <modal-text-content [withScrolledBody]="true" [title]="'structures.round.delete.TITLE' | translate"
    [text]="'structures.round.delete.RESET_WARNING' | translate"
    [subtitle]="'structures.round.delete.SUBTITLE' | translate">
  </modal-text-content>
</modal>

<!-- #endregion -->
import { Component, OnDestroy } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable, combineLatest, Subscription } from 'rxjs';
import * as fromApp from '../../ngrx/app.reducers';
import { UrlService } from 'src/app/shared/services/url.service';
import { JwtPayload } from '../../../commonclasses';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-default-page',
  template: ''
})
export class DefaultPageComponent implements OnDestroy {
  result$: Subscription;

  constructor(private store: Store<fromApp.AppState>,
    private router: Router,
    private route: ActivatedRoute,
    private urlService: UrlService) {
    const isStructure$: Observable<boolean> = this.store.select(fromApp.getIsStructure)
    const isUser$: Observable<boolean> = this.store.select(fromApp.getIsUser);
    const isAdmin$: Observable<boolean> = this.store.select(fromApp.getIsAdmin);
    const isLimitedAdmin$: Observable<boolean> = this.store.select(fromApp.getIsLimitedAdmin);
    const isHelpDesk$: Observable<boolean> = this.store.select(fromApp.getIsHelpDesk);
    const isClient$: Observable<boolean> = this.store.select(fromApp.getIsClient);
    const loggedUser$: Observable<any> = this.store.select(fromApp.getLoggedUser);

    this.result$ = combineLatest([loggedUser$, isStructure$, isUser$, isAdmin$, isHelpDesk$, isClient$, isLimitedAdmin$])
      .subscribe(
        ([loggedUser, isStructure, isUser, isAdmin, isHelpDesk, isClient, isLimitedAdmin]) => {
          if (loggedUser && loggedUser.user) {
            // Eseguo il redirect in base alle auth dell'utente
            const redirectPage = this.urlService.getDefaultRedirectPage(isStructure, isUser, isAdmin, isHelpDesk, isClient, isLimitedAdmin);
            this.router.navigate([redirectPage]);
          }
        });
  }

  ngOnDestroy() {
    this.result$.unsubscribe();
  }
}

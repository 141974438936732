import { NgModule } from '@angular/core';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { AuthInterceptor } from '../shared/interceptors/auth.interceptor';
import { HelpDeskComponent } from './helpDesk.component';
import { HelpDeskRoutingModule } from './helpDesk-routing.module';
import { SharedModule } from '../shared/shared.module';
import { CoreModule } from '../core/core.module';
import { NgxPaginationModule } from 'ngx-pagination';
import { HelpDeskHomeComponent } from './home/home.component';
import { helpDeskReducer } from './ngrx/helpDesk.reducers';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { HelpDeskEffects } from './ngrx/helpDesk.effects';
import { RentService } from '../structure/services/rent.service';
import { HelpDeskService } from '../shared/services/help-desk.service';
import { HelpDeskCampaignComponent } from './helpDeskCampaign/helpDeskCampaign.component';
import { ExportService } from '../structure/services/export.service';
import { ImportService } from '../structure/services/import.service';
import { HelpDeskCampaignRescheduleReservationComponent } from './helpDeskCampaignRescheduleReservation/helpDeskCampaignRescheduleReservation.component';
import { RegisterADComponent } from './registerAD/registerAD.component';
import { HelpDeskCampaignRoundSchedule } from './helpDeskCampaignRoundSchedule/helpDeskCampaignRoundSchedule.component';
import { HelpDeskHealthFacilityDetailsComponent } from './healthFacilityDetails/healthFacilityDetails.component';

@NgModule({
  declarations: [
    HelpDeskComponent,
    HelpDeskHomeComponent,
    HelpDeskCampaignComponent,
    HelpDeskCampaignRescheduleReservationComponent,
    RegisterADComponent,
    HelpDeskCampaignRoundSchedule,
    HelpDeskHealthFacilityDetailsComponent
  ],
  imports: [
    FormsModule,
    CommonModule,
    ReactiveFormsModule,
    HelpDeskRoutingModule,
    SharedModule,
    CoreModule,
    NgxPaginationModule,
    StoreModule.forFeature('helpDesk', helpDeskReducer),
    EffectsModule.forFeature([HelpDeskEffects])
  ],
  providers: [
    HelpDeskService,
    RentService,
    ExportService,
    ImportService,
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true }
  ]
})
export class HelpDeskModule { }

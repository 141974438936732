<div class="add-ticket-container" fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="start start"
  fxLayoutAlign.lt-md="start center">

  <div class="add-ticket-side" fxLayout="column" fxLayoutAlign="space-between">

    <div fxLayout="column" fxLayoutAlign="start start">
      <div class="add-ticket-back" fxLayout="row" fxLayoutAlign="start center" fxFlexOffset="40px" (click)="goBack()">
        <svg-icon fxFlexOffset="40px" [applyClass]="true" src="./assets/img/icons/arrow-white-left.svg"></svg-icon>
        <h5 class="white" fxFlexOffset="12px">{{ 'generic.GO_BACK' | translate | uppercase}}</h5>
      </div>

      <p fxFlexOffset="24px">
        <ng-container>
          <span translate="registerAd.REGISTER_AD_PAGE_TITLE"></span>
        </ng-container>
      </p>

      <div fxFlexOffset="24px" class="add-ticket-tick"></div>

      <p fxFlexOffset="24px">
        <ng-container>
          <h5>{{ ('registerAd.REGISTER_AD_PAGE_INFO') | translate: { value: userToRegisterForenameAndSurname } }}</h5>
        </ng-container>
    </div>

    <div fxShow fxHide.lt-md fxFlexOffset="40px"></div>
    <div fxShow fxHide.lt-md fxFlexOffset="40px"></div>

  </div>

  <div fxFlex fxLayout="row" fxLayout.xs="column" fxLayoutAlign="center" fxLayoutAlign.xs="start"
    class="add-ticket-body-container">

    <form class="add-ticket-body" [formGroup]="registerADForm" fxLayout="column" fxLayoutAlign="start center"
      (ngSubmit)="openRegisterAdModal()">

      <!-- Nome Utente -->
      <input-container isWithoutInfoIcon fxFlexOffset="24px" class="full-width"
        [title]="'addTicket.NAME_PREFILLED' | translate">
        <welion-input [inputValue]="name" [placeholder]="'addTicket.INSERT_NAME' | translate" [disabled]="true">
        </welion-input>
      </input-container>

      <!-- Cognome -->
      <input-container isWithoutInfoIcon fxFlexOffset="24px" class="full-width"
        [title]="'addTicket.SURNAME_PREFILLED' | translate">
        <welion-input [inputValue]="surname" [placeholder]="'addTicket.INSERT_SURNAME' | translate" [disabled]="true">
        </welion-input>
      </input-container>

      <!-- Codice fiscale -->
      <input-container isWithoutInfoIcon fxFlexOffset="24px" class="full-width"
        [title]="('addTicket.FISCAL_CODE_PREFILLED' | translate)">
        <welion-input [inputValue]="fiscalCode" [placeholder]="'addTicket.INSERT_FISCAL_CODE' | translate"
          [disabled]="true">
        </welion-input>
      </input-container>

      <!-- Azienda -->
      <input-container isWithoutInfoIcon fxFlexOffset="24px" class="full-width"
        [title]="('addTicket.COMPANY_PREFILLED' | translate)">
        <welion-input [inputValue]="company" [placeholder]="'addTicket.COMPANY_NAME' | translate" [disabled]="true">
        </welion-input>
      </input-container>

      <!-- Mail -->
      <input-container isWithoutInfoIcon fxFlexOffset="24px" class="full-width"
        [title]="'registerAd.EMAIL' | translate">
        <welion-input [inputValue]="mail" [placeholder]="'addTicket.EMAIL_PLACEHOLDER' | translate"
          [disabled]="isLoading">
        </welion-input>
      </input-container>

      <!-- Numero di telefono -->
      <input-container isWithoutInfoIcon fxFlexOffset="24px" class="full-width"
        [title]="('addTicket.PHONE_PREFILLED_AND_EDITABLE' | translate)">
        <welion-input [inputValue]="phoneNumber" [placeholder]="'addTicket.PHONE_NUMBER_PLACEHOLDER' | translate"
          [disabled]="isLoading">
        </welion-input>
        <h6 fxFlexOffset="8px" *ngIf="phoneNumber.value && phoneNumber.hasError('pattern')" class="small"
          translate="errors.CHARACTERS_NOT_ALLOWED"></h6>
      </input-container>

      <!-- Consenso privacy -->
      <div fxFlexOffset="24px" class="full-width privacyInfo" fxLayout="column">
        <p translate="registerAd.CONFIRM_PRIVACY" class="confirmPrivacyTitle"></p>
        <p fxFlexOffset="24px">
          <span>{{ 'registerAd.PRIVACY_INFO_FIRST' | translate }}</span>
          <span (click)="openUrl('https://campagnesalute.welion.it/#/privacy')" class="privacyLink">
            https://campagnesalute.welion.it</span>
          <span>{{ 'registerAd.PRIVACY_INFO_SECOND' | translate }}</span>
        </p>
      </div>

      <welion-check fxFlexOffset="24px" [checked]="privacyCheck" (onCheck)="onCheckPrivacy($event)">
        <ng-template #customLabel>
          <p class="check">
            <span>{{ 'registerAd.PRIVACY_TEXT_FIRST' | translate }}</span>
            <span (click)="openUrl('https://campagnesalute.welion.it/#/privacy', $event)" class="privacyLink">{{
              'registerAd.PRIVACY_TEXT_SECOND' | translate }}</span>
            <span>{{ 'registerAd.PRIVACY_INFO_THIRD' | translate }}</span>
          </p>
        </ng-template>
      </welion-check>

      <div *ngIf="isLoading" class="loader spinner-big"></div>

      <!-- Conferma registrazione -->
      <div fxFlexOffset="24px" class="full-width" fxLayout="row" fxLayoutAlign="center center">
        <welion-button width="264px" type="primary" size="large"
          [disabled]="registerADForm.invalid || isLoading || !privacyCheck" [label]="'registerAd.REGISTER_AD'"
          buttonType="submit">
        </welion-button>
      </div>

      <div fxHide fxShow.xs fxFlex.xs="40px"></div>

    </form>

  </div>

</div>

<!-- Modal di conferma registrazione -->
<modal id="confirmRegistrationModal" class="confirm-registration-modal" (onClose)="closeRegisterAdModal()" (onCancel)="closeRegisterAdModal()">
  <div fxLayout="column" fxLayoutAlign="center center" class="full-width text-center">
    <h2 fxFlexOffset="24px" class="primary small bold center">{{
      'registerAd.CONFIRM_REGISTRATION' | translate }}</h2>
  </div>

  <div class="text-wrapper" fxLayout="column">
    <h5 class="bold light-grey">{{ 'registerAd.CONFIRM_THE_REGISTRATION_OF' | translate }}</h5>
  </div>

  <!-- Riassunto dei dati dell'utente da registrare -->
  <div class="text-wrapper" fxLayout="column">
    <!-- Nome e cognome -->
    <p *ngIf="userToRegisterForenameAndSurname">{{
      userToRegisterForenameAndSurname }}</p>
    <!-- Codice fiscale -->
    <p *ngIf="userToRegisterCodFisc">{{ userToRegisterCodFisc }}</p>
    <!-- Azienda -->
    <p *ngIf="userToRegisterCompany">{{ userToRegisterCompany }}</p>
    <!-- Emmail -->
    <p *ngIf="mail && mail.value">{{ mail.value }}</p>
    <!-- Numero di telefono -->
    <p *ngIf="phoneNumber && phoneNumber.value">{{ phoneNumber.value }}</p>
  </div>

  <div class="action-container">
    <!-- Chiudi -->
    <welion-button label="generic.CLOSE" type="primary bordless" (onClick)="closeRegisterAdModal()">
    </welion-button>
    <!-- Conferma -->
    <welion-button label="generic.CONFIRM" type="primary" (onClick)="closeRegisterAdModal(true)">
    </welion-button>
  </div>

</modal>
<div *ngIf="!readonly" class="welion-text-input-wrapper" [ngClass]="{
    'disabled': _inputValue && _inputValue.disabled,
    'invalid': _inputValue && _inputValue.invalid && !_inputValue.pristine,
    'with-transparency': _withTransparency }">

  <!-- TimePicker -->
  <ng-container *ngIf="_hasTimePicker && !_hasMapsAutocomplete">
    <input [placeholder]="placeholder" [attr.aria-label]="placeholder" [type]="_type" [id]="_id" [attr.name]="attrName"
      (keyup.enter)="emitOnIconClicked($event)" [formControl]="_inputValue" [tabindex]="_tabIndex"
      [owlDateTimeTrigger]="dt4" [owlDateTime]="dt4" (dateTimeChange)="updateValueFromTimePicker($event)">
    <owl-date-time pickerType="timer" [hour12Timer]="false" #dt4></owl-date-time>
  </ng-container>
  <!-- Maps Autocomplete -->
  <ng-container *ngIf="_hasMapsAutocomplete && !_hasTimePicker">
    <input ngxAutocomPlace [debounceTime]="debounceTime" [options]="mapsOptions"
      (selectedPlace)="placeChangedCallback($event)" [placeholder]="placeholder" [attr.aria-label]="placeholder"
      [type]="_type" [id]="_id" [attr.name]="attrName" (keyup.enter)="emitOnIconClicked($event)"
      [formControl]="_inputValue" [tabindex]="_tabIndex" [attr.aria-labelledby]="ariaLabelledbyId ? ('welion-input-label-' + ariaLabelledbyLabel + ' ' + ariaLabelledbyId) : ('welion-input-label-' + ariaLabelledbyLabel)"
      [attr.aria-required]="required" [required]="required">
  </ng-container>
  <!-- Input normale -->
  <ng-container *ngIf="!_hasMapsAutocomplete && !_hasTimePicker">
    <input [ngClass]="{'uppercase': uppercase}" [placeholder]="placeholder" [attr.aria-label]="placeholder"
      [type]="_type" [id]="_id" [attr.name]="_id" (keyup.enter)="emitOnIconClicked($event)" [formControl]="_inputValue"
      [tabindex]="_tabIndex" [attr.aria-labelledby]="ariaLabelledbyId ? ('welion-input-label-' + ariaLabelledbyLabel + ' ' + ariaLabelledbyId) : ('welion-input-label-' + ariaLabelledbyLabel)"
      [attr.aria-required]="required" [required]="required"
      (blur)="onBlur($event)" 
      [attr.aria-disabled]="disabledValue"
     >
    <span *ngIf="ariaLabelledbyText" id="{{ariaLabelledbyId}}" class="d-none">{{ariaLabelledbyText}}</span>
    <span *ngIf="error" [id]="'error-'+ _id" role="alert" class="error-message">{{errorText}}</span>
  </ng-container>

  <svg-icon *ngIf="iconRight" [src]="iconRight" (click)="emitOnIconClicked($event)" class="regular-size"
    [applyClass]="true" [ngClass]="{'clickableIcon': clickableIcon, 'disabled': _inputValue && _inputValue.disabled}">
  </svg-icon>

</div>

<label tabindex="0" class="read-only-value" *ngIf="_readonly">{{ _inputValue?.value || '' }}</label>
<div [attr.aria-label]="title" role="dialog" aria-modal="true" class="modal" clickKeyBoard (onEscKeyboard)="emitOnClose()">
  <div class="modal-body" [ngClass]="{'full-page': fullPage}" cdkTrapFocus tabindex="-1">
    <div class="full-width" fxLayout="row" fxLayoutAlign="end">
      
      <!-- Icona chiudi -->
      <div  #modalEl fxLayoutAlign="row" fxLayoutAlign="center center" (click)="emitOnClose()" class="close-container" role="button"
        [ngClass]="{'primary': _primaryClose, 'disabled': disableClose || disableAll}" tabindex="0" clickKeyBoard (onEnterKeyboard)="closeModal($event);">
        <svg-icon class="small-size" src="/assets/img/icons/x.svg" [applyClass]="true"></svg-icon>
        <h5 fxFlexOffset="8px" class="white">{{ 'generic.CLOSE' | translate | uppercase}}</h5>
      </div>
    </div>

    <!-- Contenuto -->
    <p [ngClass]="titleClass || ''" class="title-text" *ngIf="title?.length">{{title}}</p>
    <ng-content></ng-content>
    <div class="action-container"
      [ngClass]="{'single-button': (confirmLabel && !cancelLabel) || (!confirmLabel && cancelLabel)}">
      <welion-button *ngIf="cancelLabel"  [label]="cancelLabel" type="primary bordless" (onClick)="emitOnCancelClicked()"
        [disabled]="disableCancel || disableAll"></welion-button>
      <welion-button id="confirmBtn-{{id}}" *ngIf="confirmLabel" [label]="confirmLabel" type="primary"
        [disabled]="confirmBtnDisabled || disableAll" (onClick)="emitOnConfirmClicked()">
      </welion-button>
    </div>
  </div>
</div>
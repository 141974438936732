import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';

import { RedirectService } from 'src/app/shared/services/redirect.service';
import { ActivatedRoute } from '@angular/router';
import { takeUntil } from 'rxjs/operators';
import * as fromApp from "../../../ngrx/app.reducers";
import { Store } from '@ngrx/store';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-faq',
  templateUrl: './faq.component.html',
  styleUrls: ['./faq.component.scss']
})
export class FAQComponent implements OnInit, OnDestroy {

  private roundId: string = '';
  private userId: string = '';

  userFAQ: boolean = false;
  canSeeTicketBox: boolean = false;

  private _unsubscribeSignal$: Subject<void> = new Subject();

  loggedUser: any;
  supplierName?: string = '';
  isEnel: boolean = false;
  isGenerali: boolean = false;

  constructor(
    private route: ActivatedRoute,
    public translate: TranslateService,
    public redirectService: RedirectService,
    private store: Store<fromApp.AppState>,
    private titleService: Title
  ) {

    this.store.select(fromApp.getLoggedUser)
      .pipe(takeUntil(this._unsubscribeSignal$.asObservable()))
      .subscribe(
        (loggedUser) => {
          if (loggedUser && loggedUser.user) {
            this.loggedUser = loggedUser && loggedUser.user;

            this.supplierName = loggedUser.params && loggedUser.params.supplier && loggedUser.params.supplier.supplierName ?
              (loggedUser.params.supplier.supplierName as string).toLowerCase() : undefined;

            if (this.supplierName) {
              this.isEnel = this.supplierName.indexOf('enel') !== -1;
              this.isGenerali = this.supplierName.indexOf('generali italia') !== -1;
            }
          }
        });
  }

  ngOnInit() {
    this.translate.get(
      [
        "userFAQ.TITLE"
      ]).subscribe(translations => {
        this.titleService.setTitle(translations["userFAQ.TITLE"]);
      });
    this.roundId = this.route.snapshot.paramMap.get('roundId') || '';
    this.userId = this.route.snapshot.paramMap.get('userId') || '';

    if (this.redirectService.isThisCurrentPage('user')) {
      this.userFAQ = true;
    }

    if (!this.redirectService.isThisCurrentPage('admin') && !this.redirectService.isThisCurrentPage('structure') &&
      !this.redirectService.isThisCurrentPage('helpDesk') && !this.redirectService.isThisCurrentPage('client')) {
      this.canSeeTicketBox = true;
    }
  }

  ngOnDestroy() {
    this._unsubscribeSignal$.next();
    this._unsubscribeSignal$.unsubscribe();
  }

  goBack() {
    if (this.roundId && this.userId) {
      this.redirectService.goToNewReservation(this.roundId, this.userId);
    } else {
      this.redirectService.goToHome();
    }
  }

  goToLoginRedirect(event: any) {
    if (event) {
      event.stopPropagation();
    }
    this.redirectService.goToUserLogin(true);
  }

  clickMailTo(event: any) {
    if (event) {
      event.stopPropagation();
    }
  }

  goToTicketPage() {
    if (this.userFAQ) {
      if (this.roundId && this.userId) {
        this.redirectService.goToCreateUserTicket(this.roundId, this.userId);
      } else {
        this.redirectService.goToCreateUserTicket();
      }
    } else {
      this.redirectService.goToCreateTicket();
    }
  }
}

<div fxLayout="column" fxLayoutAlign="start center">
  <h2 *ngIf="title" fxFlexOffset="24px" class="primary small bold center">{{ title }}</h2>
  <p *ngIf="modalId" class="modal-code-wrapper">{{ 'CODICE MODALE: ' + modalId}}</p>
</div>

<div *ngIf="badge" class="badge-container" [ngClass]="{'no-background': noBackground}" fxLayout="column"
  fxLayoutAlign="center center">
  <svg-icon class="badge" [src]="badge"></svg-icon>
  <h5 class="primary bold uppercase">{{ badgeDescr }}</h5>
</div>

<div class="text-wrapper" [ngClass]="{'body-scrolled': withScrolledBody }" fxLayout="column"
  fxLayoutAlign="start center">
  <h5 class="bold primary center">{{ subtitle }}</h5>
  <h5 class="bold light-grey center" [innerHTML]="text"></h5>
</div>

import { Store } from "@ngrx/store";
import { Subscription, combineLatest, Observable } from "rxjs";
import { Injectable } from "@angular/core";
import * as moment from 'moment';

import * as fromApp from '../../ngrx/app.reducers';
import { GlobalApplicationData } from "src/app/shared/models/global-application-data.model";
import { HttpClient, HttpParams } from "@angular/common/http";
import { UrlService } from "./url.service";

@Injectable()
export class HelpDeskService {
  applicationData: any;
  result$: Subscription;
  loggedUser: any;

  serviceMediatorUrl: string;

  constructor(private store: Store<fromApp.AppState>, private urlService: UrlService, private http: HttpClient) {
    this.serviceMediatorUrl = this.urlService.getServiceMediatorUrl();

    let globalApplicationData$: Observable<GlobalApplicationData> = this.store.select(fromApp.getGlobalApplicationData);
    const loggedUser$ = this.store.select(fromApp.getLoggedUser);
    const applicationLang$: Observable<string> = this.store.select(fromApp.getApplicationLang);

    const combinedSelectes$ = combineLatest([globalApplicationData$, loggedUser$, applicationLang$]);
    this.result$ = combinedSelectes$.subscribe(
      ([globalApplicationData, loggedUser, applicationLang]) => {
        this.loggedUser = loggedUser;
        this.applicationData = globalApplicationData;
        if (applicationLang) {
          moment.locale(applicationLang);
        }
      });
  }

  // Conferma la registrazione di un AD
  confirmEntitledRegistrationForHelpDesk(userId: string, email: string, phoneNumber: string, isPrivacyAccepted: boolean): any {

    return this.http.post<any>(
      this.serviceMediatorUrl + 'confirm-entitled-registration-for-help-desk',
      {
        userId,
        email,
        phoneNumber,
        isPrivacyAccepted
      }
    );
  }

  // Recupera le informazioni di un utente da registrare
  getEntitledByIdForHelpDesk(userId: string): Observable<any> {
    let httpParams = new HttpParams();

    httpParams = httpParams.append('userId', userId);

    return this.http.get<any>(
      this.serviceMediatorUrl + 'get-entitled-by-id-for-help-desk', { params: httpParams }
    );
  }
}

import { Component, OnDestroy, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';
import { SenecaResponse } from 'atfcore-commonclasses';
import { RentService } from 'src/app/structure/services/rent.service';
import { Subscription } from 'rxjs';
import { getWarningModal } from 'src/app/utils/utils';
import { Store } from '@ngrx/store';
import * as fromApp from "../../ngrx/app.reducers";
import * as CoreActions from "../../core/ngrx/core.actions";

@Component({
    selector: 'app-client-landing',
    templateUrl: './clientLanding.component.html',
    styleUrls: ['./clientLanding.component.scss']
})
export class ClientLandingComponent implements OnInit, OnDestroy {

    isLoadingCampaigns: boolean = false;
    getCampaignList$?: Subscription;
    yearClientCampaign = new Date().getFullYear() - 1;
    isWithCurrentYearCampaign: boolean = false;

    constructor(
        private router: Router,
        private translate: TranslateService,
        private rentService: RentService,
        private appStore: Store<fromApp.AppState>,
    ) { }

    ngOnInit() {
        this.getCampaignList();
    }


    goToDashboard() {
        this.router.navigate(['client/dashboard']);
    }

    goToList() {
        this.router.navigate(['client/list']);
    }

    dispatchModal(modalId: string, error: string) {
        const messageObj = getWarningModal(this.translate, modalId, error);
        this.appStore.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
    }

    getCampaignList() {
        this.isLoadingCampaigns = true;

        if (this.getCampaignList$) {
            this.getCampaignList$.unsubscribe();
        }

        this.getCampaignList$ = this.rentService.getCampaignListForDashboard()
            .subscribe((data: SenecaResponse<any>) => {
                if (data.error) {
                    this.dispatchModal('06', data.error);
                } else {
                    const ids = Object.keys(data.response);

                    if (ids && ids.length) {
                        this.isWithCurrentYearCampaign = true;
                        // let formattedData = [];
                        // 
                        // for (let i = 0; i < ids.length; i++) {
                        //     formattedData.push({
                        //         id: ids[i],
                        //         name: data.response[ids[i]]
                        //     });
                        // }
                        // for (let i = 0; i < formattedData.length; i++) {
                        //     if (formattedData[i].name.indexOf(this.yearClientCampaign) >= 0) {
                        //         formattedData = [formattedData[i]];
                        //         this.isWithCurrentYearCampaign = true;
                        //         break;
                        //     }
                        // }
                    }
                }
                this.isLoadingCampaigns = false;
            }, (err: any) => {
                if (err && err.message) {
                    this.dispatchModal('077', err.message);
                } else {
                    this.dispatchModal('077', err);
                }
                this.isLoadingCampaigns = false;
            })
    }

    ngOnDestroy() {
        if (this.getCampaignList$) {
            this.getCampaignList$.unsubscribe();
        }
    }

}

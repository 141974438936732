import { createReducer, on, Action } from '@ngrx/store';
import * as fromApp from '../../ngrx/app.reducers';

export interface FeatureState extends fromApp.AppState {
  client: ClientState
}

export interface ClientState {
}

// Store iniziale
const initialState: ClientState = {
};


export const createClientReducer = createReducer(
  initialState
)

export function clientReducer(state: ClientState | undefined, action: Action) {
  return createClientReducer(state, action);
}

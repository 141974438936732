<main class="add-familiar-container" fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="start start"
  fxLayoutAlign.lt-md="start center">

  <div class="add-familiar-side" fxLayout="column" fxLayoutAlign="space-between">

    <div fxLayout="column" fxLayoutAlign="start start">
      <div class="add-familiar-back" fxLayout="row" fxLayoutAlign="start center" role="button" attr.aria-label="{{ 'generic.GO_BACK' | translate }}" fxFlexOffset="40px" tabindex="0" (click)="goBack()" clickKeyBoard (onEnterKeyboard)="goBack()">
        <svg-icon fxFlexOffset="40px" [applyClass]="true" src="./assets/img/icons/arrow-white-left.svg"></svg-icon>
        <h5 class="white" fxFlexOffset="12px">{{ 'generic.GO_BACK' | translate | uppercase}}</h5>
      </div>

      <h1 fxFlexOffset="24px" *ngIf="campaign" translate="users.addFamiliar.CONFIRM"></h1>

      <div fxFlexOffset="24px" class="add-familiar-tick"></div>
      <h5 *ngIf="familiarParentelOptions && familiarParentelOptions.length" class="add-familiar-description-text"
        [innerHTML]="'users.addFamiliar.ADD_FAMILIAR_DESCR' | translate"></h5>
      <ul class="familiar-list" *ngIf="familiarParentelOptions && familiarParentelOptions.length" role="list">
        <li type="button" *ngFor="let familiarParentel of familiarParentelOptions" role="listitem">
          <h5>{{ familiarParentel.name }}</h5>
        </li>
      </ul>
    </div>

    <svg-icon fxShow fxHide.lt-md class="welion-logo" [applyClass]="true" src="./assets/img/icons/app-logo.svg" role="img" aria-label="Logo Welion">
    </svg-icon>

    <div fxShow fxHide.lt-md fxFlexOffset="40px"></div>

  </div>

  <div fxFlex fxLayout="row" fxLayout.xs="column" fxLayoutAlign="center" fxLayoutAlign.xs="start"
    class="add-familiar-body-container">

    <form class="add-familiar-body" [formGroup]="addFamiliarForm" fxLayout="column" fxLayoutAlign="start center"
      (ngSubmit)="confirmAddFamiliarModal()">

      <!-- Nome Familiare -->
      <input-container [for]="'familiar-name'"
        [id]="'familiar-name'" fxFlexOffset="24px" class="full-width" [title]="'users.addFamiliar.NAME' | translate">
        <welion-input [inputValue]="forename" [placeholder]="'users.addFamiliar.NAME_PLACEHOLDER' | translate"
          [disabled]="isLoading" [id]="'familiar-name'"
          [ariaLabelledbyLabel]="'familiar-name'"
          [ariaLabelledbyText]="'users.addFamiliar.NAME_PLACEHOLDER' | translate" [required]="true">
        </welion-input>
      </input-container>

      <!-- Cognome Familiare -->
      <input-container [for]="'familiar-surname'"
        [id]="'familiar-surname'" fxFlexOffset="24px" class="full-width" [title]="'users.addFamiliar.SURNAME' | translate">
        <welion-input [inputValue]="surname" [placeholder]="'users.addFamiliar.SURNAME_PLACEHOLDER' | translate"
          [disabled]="isLoading" [id]="'familiar-surname'"
          [ariaLabelledbyLabel]="'familiar-surname'"
          [ariaLabelledbyText]="'users.addFamiliar.SURNAME_PLACEHOLDER' | translate" [required]="true">
        </welion-input>
      </input-container>

      <!-- Codice fiscale Familiare -->
      <input-container [for]="'familiar-fiscal-code'"
        [id]="'familiar-fiscal-code'" fxFlexOffset="24px" class="full-width" [title]="'users.addFamiliar.FISCAL_CODE' | translate">
        <welion-input [uppercase]="true" [inputValue]="fiscalCode" 
        [placeholder]="'users.addFamiliar.FISCAL_CODE_PLACEHOLDER' | translate"
        [disabled]="isLoading" [id]="'familiar-fiscal-code'"
        [ariaLabelledbyLabel]="'familiar-fiscal-code'"
        [ariaLabelledbyText]="'users.addFamiliar.FISCAL_CODE' | translate" [required]="true">
        </welion-input>
      </input-container>

      <!-- Rapporto di parentela -->
      <input-container *ngIf="familiarParentelOptions && familiarParentelOptions.length" fxFlexOffset="24px"
        class="full-width" [title]="'users.addFamiliar.FAMILIAR_PARENTEL' | translate"
        [for]="'kinship-relationship'" [id]="'kinship-relationship'">
        <welion-select toTranslate [disabled]="isLoading" [ariaLabelledbyLabel]="'kinship-relationship'" [required]="true"
          [attrAriaLabel]="'users.addFamiliar.FAMILIAR_PARENTEL' | translate"
          [labelForId]="'welion-input-kinship-relationship'"
          [placeholder]="'users.addFamiliar.FAMILIAR_PARENTEL' | translate" [items]="familiarParentelOptions"
          [searchable]="false" [selectedItem]="selectedFamiliarMember.value"
          (change)="onSelectedFamiliarMemberChanged($event)">
        </welion-select>
      </input-container>

      <div *ngIf="isLoading" class="loader spinner-big"></div>

      <!-- Aggiungi Familiare -->
      <div fxFlexOffset="24px" class="full-width" fxLayout="row" fxLayoutAlign="end">
        <welion-button width="264px" type="primary" size="large" [label]="'users.addFamiliar.CONFIRM'"
          [disabled]="(addFamiliarForm.invalid || isLoading)" buttonType="submit">
        </welion-button>
      </div>

      <div fxHide fxShow.xs fxFlex.xs="40px"></div>

    </form>

  </div>

</main>

<!-- Modale per la conferma della privacy -->
<modal id="privacyModalAddFamiliar" class="privacy-modal" [ngClass]="{'isUnderage': isUnderageModal}"
  (onClose)="closePrivacyModal()" (onCancel)="closePrivacyModal()"
  [title]="!isUnderageModal ? 'Inserimento maggiorenne' : 'Inserimento minorenne'" [titleClass]="'small'">

  <!-- Prenotazione maggiorenne -->
  <ng-container *ngIf="!isUnderageModal">

    <div fxFlexOffset="24px">
      <span>
        In qualità di <strong>Dipendente dell’Impresa e parente, affine, coniuge, convivente more uxorio del
          maggiorenne</strong> che ho selezionato appartenente al mio nucleo familiare, consapevole delle sanzioni
        penali
        in caso di dichiarazioni mendaci di cui agli articoli 75 e 76 del D.P.R. 445/2000
      </span>
      <p></p>
      <p class="margin-bottom16"></p>
      <span>
        <strong>DICHIARO</strong> di avergli sottoposto l'</span>
      <span class="link" (click)="goToPrivacyPage()">Informativa sul trattamento dei dati personali</span>
      <span>
        e di averlo messo al corrente che i miei e suoi dati personali potranno essere noti all’Impresa sopra indicata e
        comunicati a terzi, qualora necessario per consentire l’erogazione dei Servizi richiesti o in nostro favore
        previsti;
      </span>
      <p></p>
      <p class="margin-bottom16"></p>
      <span>
        <strong>PRESO ATTO</strong> che per le finalità del trattamento come illustrate nell’informativa (i), (ii),
        (iii), (iv) e (v), il conferimento dei dati è obbligatorio e che gli stessi saranno trattati secondo quanto
        indicato per assolvere gli adempimenti correlati agli accordi in essere,
      </span>
      <p></p>
      <p class="margin-bottom16"></p>
      <span>
        <strong>HO OTTENUTO</strong> il suo consenso, come sotto riportato, per il trattamento dei suoi dati relativi
        alla salute e per il quale mi assumo ogni responsabilità. Resto disponibile a dimostrare, a semplice richiesta,
        di averlo informato e di aver acquisito il suo consenso come indicato
      </span>
      <p></p>
      <p class="margin-bottom16"></p>
      <span>
        <strong>CON RIFERIMENTO AL TRATTAMENTO DEI DATI PERSONALI PER L’EROGAZIONE DEL SERVIZIO</strong>
      </span>
    </div>
    <welion-check [attrAriaLabel]="'ESPRIMO il suo consenso al trattamento dei dati personali, appartenenti alle categorie
      particolari dati personali, tra cui quelli relativi alla salute, per le finalità del trattamento illustrate
      nell’informativa privacy sub (i), (ii), (iii), (iv) e (v) per quanto necessario all’erogazione dei Servizi
      richiesti o previsti.'" [checked]="privacyCheck" fxFlexOffset="24px" (onCheck)="onCheckPrivacy($event)">
      <ng-template #customLabel>
        <p class="check">
          <span>
            <strong>ESPRIMO</strong> il suo consenso al trattamento dei dati personali, appartenenti alle categorie
            particolari dati personali, tra cui quelli relativi alla salute, per le finalità del trattamento illustrate
            nell’informativa privacy sub (i), (ii), (iii), (iv) e (v) per quanto necessario all’erogazione dei Servizi
            richiesti o previsti.
          </span>
        </p>
      </ng-template>
    </welion-check>
  </ng-container>

  <!-- Prenotazione minorenne -->
  <ng-container *ngIf="isUnderageModal">

    <div fxFlexOffset="24px">
      <span>
        In qualità di <strong>Dipendente dell’impresa titolare della potestà genitoriale nei confronti del
          minore</strong> che ho selezionato
      </span>
      <p></p>
      <p class="margin-bottom16"></p>
      <span>
        <strong>DICHIARO</strong>, di aver preso visione dell’</span>
      <span class="link" (click)="goToPrivacyPage()">Informativa sul trattamento dei dati personali</span>
      <span>
        e di essere consapevole che i miei e i suoi dati personali potranno essere noti all’Impresa sopra indicata e
        comunicati a terzi qualora necessario per consentire l’erogazione dei Servizi richiesti o in nostro favore
        previsti;
      </span>
      <p></p>
      <p class="margin-bottom16"></p>
      <span>
        <strong>PRENDO ATTO</strong>, che per le finalità del trattamento come illustrate nell’informativa (i), (ii),
        (iii), (iv) e (v), il conferimento dei dati è obbligatorio e che gli stessi saranno trattati secondo quanto
        indicato per assolvere gli adempimenti correlati agli accordi in essere
      </span>
      <p></p>
      <p class="margin-bottom16"></p>
      <span>
        <strong>CON RIFERIMENTO AL TRATTAMENTO DEI DATI PERSONALI PER L’EROGAZIONE DEL SERVIZIO</strong>
      </span>
    </div>
    <welion-check [attrAriaLabel]="'ESPRIMO il mio consenso al trattamento dei suoi dati personali appartenenti alle categorie
      particolari dati personali, tra cui quelli relativi alla salute, per le finalità del trattamento illustrate
      nell’informativa privacy sub (i), (ii), (iii), (iv) e (v) per quanto necessario all’erogazione dei Servizi
      richiesti o previsti'" [checked]="privacyCheck" fxFlexOffset="24px" (onCheck)="onCheckPrivacy($event)">
      <ng-template #customLabel>
        <p class="check">
          <span>
            <strong>ESPRIMO</strong> il mio consenso al trattamento dei suoi dati personali appartenenti alle categorie
            particolari dati personali, tra cui quelli relativi alla salute, per le finalità del trattamento illustrate
            nell’informativa privacy sub (i), (ii), (iii), (iv) e (v) per quanto necessario all’erogazione dei Servizi
            richiesti o previsti
          </span>
        </p>
      </ng-template>
    </welion-check>
  </ng-container>

  <div class="full-width" fxFlexOffset="24px" fxLayout="row" fxLayoutAlign="center">
    <!-- Chiudi -->
    <welion-button fxFlex label="generic.CLOSE" type="third" size="medium" buttonType="button"
      (onClick)="closePrivacyModal()">
    </welion-button>
    <!-- Conferma -->
    <welion-button [disabled]="!privacyCheck" fxFlexOffset="8px" fxFlex label="generic.CONFIRM" type="primary"
      size="medium" (onClick)="closePrivacyModal(true)">
    </welion-button>
  </div>

</modal>

<modal id="warningCID" (onClose)="closeWarningCidModal()" (onCancel)="closeWarningCidModal()"
[title]="'Codice fiscale non valido'" [titleClass]="'small'">
  <!-- Prenotazione maggiorenne -->
  <ng-container>
    <p fxFlexOffset="24px">Attenzione il codice fiscale non è valido.</p>
    <p fxFlexOffset="13px">Perfavore ricontrolla che i dati inseriti siano corretti.</p>
  </ng-container>

  <div class="full-width" fxFlexOffset="24px" fxLayout="row" fxLayoutAlign="center">
    <!-- Conferma -->
    <welion-button fxFlexOffset="8px" fxFlex label="generic.CONFIRM" type="primary"
      size="medium" (onClick)="closeWarningCidModal()">
    </welion-button>
  </div>
</modal>

<!-- Modale per la conferma della registrazione -->
<modal id="confirmAddFamiliarModal" class="privacy-modal no-max-height" (onCancel)="closeConfirmAddFamiliarModal()"
  (onClose)="closeConfirmAddFamiliarModal()" [title]="'Attenzione!'" [titleClass]="'small'">

  <ng-container>

    <div class="privacy-modal-container" fxFlexOffset="24px">
      <span>
        Hai inserito il seguente familiare
      </span>
      <p></p>
      <span>
        {{forename.value}} {{surname.value}}
      </span>
      <p></p>
      <span>
        {{fiscalCode.value}}
      </span>
      <p></p>
      <span>
        {{selectedFamiliarMember && selectedFamiliarMember.value && selectedFamiliarMember.value.name}}
      </span>
      <p></p>
      <p class="margin-bottom14"></p>
      <span>
        Ricorda che, in caso di dichiarazioni mendaci, incorrerai in <strong>sanzioni penali</strong> ai sensi degli
        articoli 75 e 76 del DPR 445/2000.
      </span>
      <p></p>
      <p class="margin-bottom14"></p>
      <span>
        Considera, inoltre, che potrebbero essere realizzati dei <strong>controlli a posteriori</strong> sui dati da te
        caricati. Confermi i dati inseriti?
      </span>
      <p></p>
    </div>
  </ng-container>

  <div class="full-width" fxFlexOffset="24px" fxLayout="row" fxLayoutAlign="center">
    <!-- Chiudi -->
    <welion-button fxFlex label="generic.CLOSE" type="third" size="medium" buttonType="button"
      (onClick)="closeConfirmAddFamiliarModal()">
    </welion-button>
    <!-- Conferma -->
    <welion-button fxFlexOffset="8px" fxFlex label="generic.CONFIRM_REGISTRATION" type="primary" size="medium"
      (onClick)="closeConfirmAddFamiliarModal(true)">
    </welion-button>
  </div>

</modal>
<div class="add-campaign-customer-cap-container" fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="start start"
  fxLayoutAlign.lt-md="start center">

  <div class="add-campaign-customer-cap-side" fxLayout="column" fxLayoutAlign="space-between">

    <div fxLayout="column" fxLayoutAlign="start start">
      <div class="add-campaign-customer-cap-back" fxLayout="row" fxLayoutAlign="start center" fxFlexOffset="40px"
        (click)="goBack()">
        <svg-icon fxFlexOffset="40px" [applyClass]="true" src="./assets/img/icons/arrow-white-left.svg"></svg-icon>
        <h5 class="white" fxFlexOffset="12px">{{ 'generic.GO_BACK' | translate | uppercase }}</h5>
      </div>

      <p fxFlexOffset="24px" class="sidenav-title" translate="admin.addCampaignCustomerCap.MANAGE_LIMITS"></p>

      <div fxFlexOffset="24px" class="add-campaign-customer-cap-tick"></div>

      <p fxFlexOffset="24px">
        <span translate="admin.addCampaignCustomerCap.SIDE_TITLE"></span>
        <span class="bold">{{ customer ? customer.title : '' }}</span>
        <span translate="admin.addCampaignCustomerCap.SIDE_TITLE2"></span>
        <span class="bold">{{ campaign ? campaign.title : '' }}</span>
      </p>
    </div>

    <svg-icon fxShow fxHide.lt-md class="welion-logo" [applyClass]="true" src="./assets/img/icons/app-logo.svg">
    </svg-icon>

    <div fxShow fxHide.lt-md fxFlexOffset="40px"></div>

  </div>

  <div fxFlex fxLayout="row" fxLayout.xs="column" fxLayoutAlign="center" fxLayoutAlign.xs="start"
    class="add-campaign-customer-cap-body-container">

    <form class="add-campaign-customer-cap-body" [formGroup]="addClientCapLimitForm" fxLayout="column"
      fxLayoutAlign="start center" (ngSubmit)="confirmAddCapLimit()">

      <!-- Limite prenotazioni -->
      <input-container fxFlexOffset="24px" class="full-width"
        [title]="'admin.addCampaignCustomerCap.CAP_LIMIT' | translate">
        <welion-input [inputValue]="capLimit"
          [placeholder]="'admin.addCampaignCustomerCap.CAP_LIMIT_PLACEHOLDER' | translate" [disabled]="isLoading">
        </welion-input>
      </input-container>

      <input-container fxFlexOffset="24px" class="full-width"
        [title]="'admin.addCampaignCustomerCap.FAMILY_INSERTION' | translate">
        <welion-select toTranslate [disabled]="isLoading" [items]="familyInsertionOptions" [searchable]="false"
          [selectedItem]="selectedFamilyInsertion.value" (change)="onSelectedFamilyInsertionChanged($event)">
        </welion-select>
      </input-container>

      <input-container fxFlexOffset="24px" class="full-width"
        [title]="'admin.addCampaignCustomerCap.CAP_MAX_FAMILIARS' | translate">
        <welion-select toTranslate [disabled]="isLoading || selectedFamilyInsertion.value.id != 'YES'"
          [items]="capMaxFamiliarsOptions" [searchable]="false" [selectedItem]="selectedCapMaxFamiliars.value"
          (change)="onSelectedCapMaxFamiliarsChanged($event)" [placeholder]="'admin.addCampaignCustomerCap.IMPOST_MAX_NUMBER' | translate">
        </welion-select>
      </input-container>

      <!-- Vaccini acquistati -->
      <input-container fxFlexOffset="24px" class="full-width"
        [title]="'admin.addCampaignCustomerCap.ACQUISED_VACCINS' | translate">
        <welion-input [inputValue]="boughtShots"
          [placeholder]="'admin.addCampaignCustomerCap.CAP_LIMIT_PLACEHOLDER' | translate" [disabled]="isLoading">
        </welion-input>
      </input-container>

      <p fxFlexOffset="24px">
        <span class="bold-text" translate="admin.addCampaignCustomerCap.FAMILIARS_ALLOWED"></span>
      </p>

      <!-- Table rows -->
      <div fxFlexOffset="24px" *ngFor="let familiar of familiarTypeOptions; let index = index" class="familiar-row" fxLayout="row"
      fxLayoutAlign="start center" [fxFlexOffset]="index === 0 ? '32px' : '0px'">
      <!-- Checkbox -->
      <welion-check [disabled]="selectedFamilyInsertion.value.id != 'YES'" fxFlexOffset="16px" [checked]="familiarIsChecked(familiar)"
        (onCheck)="onFamiliarChecked(familiar, $event)">

      </welion-check>
      <!-- Nome cliente -->
      <h6 class="black" fxFlex fxFlexOffset="20px">{{ familiar.name }}</h6>

      <div fxFlex="16px"></div>
    </div>

      <div *ngIf="isLoading" class="loader spinner-big"></div>

      <!-- Imposta limite -->
      <div fxFlexOffset="24px" class="full-width" fxLayout="row" fxLayoutAlign="end">
        <welion-button width="264px" type="primary" size="large" label="admin.addCampaignCustomerCap.CONFIRM"
          [disabled]="addClientCapLimitForm.invalid || isLoading || isAddClientCapLimitFormInvalid()" buttonType="submit">
        </welion-button>
      </div>

      <div fxHide fxShow.xs fxFlex.xs="40px"></div>

    </form>

  </div>

</div>

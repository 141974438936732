import { ChangeDetectionStrategy, Component, Input, OnInit, Output, EventEmitter } from '@angular/core';


@Component({
    selector: 'slot-component-tag',
    templateUrl: 'slot-component-tag.component.html',
    styleUrls: ['./slot-component-tag.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class SlotComponentTagComponent implements OnInit {
    @Input() selected: boolean = false;
    @Input() size: string = 'tiny';
    @Input() text: string = '09:30 · 09:45';
    @Output() onEmitSlock: EventEmitter<any> = new EventEmitter<any>();

    constructor() {
    }

    ngOnInit() {
    }

    // Quando premi lo slot
    onClickSlot() {
        this.selected = !this.selected;
        this.onEmitSlock.emit();
    }
}
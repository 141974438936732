import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HelpDeskComponent } from './helpDesk.component';
import { HelpDeskHomeComponent } from './home/home.component';
import { HelpDeskGuard } from '../shared/services/helpDesk-guard.service';
import { HelpDeskCampaignComponent } from './helpDeskCampaign/helpDeskCampaign.component';
import { HelpDeskCampaignRescheduleReservationComponent } from './helpDeskCampaignRescheduleReservation/helpDeskCampaignRescheduleReservation.component';
import { RegisterADComponent } from './registerAD/registerAD.component';
import { HelpDeskCampaignRoundSchedule } from './helpDeskCampaignRoundSchedule/helpDeskCampaignRoundSchedule.component';
import { HelpDeskHealthFacilityDetailsComponent } from './healthFacilityDetails/healthFacilityDetails.component';

const helpDeskRoutes: Routes = [
  {
    path: '', component: HelpDeskComponent, children: [
      { path: 'home', component: HelpDeskHomeComponent, canActivate: [HelpDeskGuard] },
      { path: 'campaign/:campaignId', component: HelpDeskCampaignComponent, canActivate: [HelpDeskGuard] },
      { path: 'campaign/:campaignId/entitleds/:userId', component: HelpDeskCampaignRescheduleReservationComponent, canActivate: [HelpDeskGuard] },
      { path: 'campaign/:campaignId/round/:roundId/entitledsSchedule/:userId/attendance/:attendanceId', component: HelpDeskCampaignRoundSchedule, canActivate: [HelpDeskGuard] },
      { path: 'registerAD/:userId', component: RegisterADComponent, canActivate: [HelpDeskGuard] },
      { path: 'healthFacility/:healthFacilitySupplierId', component: HelpDeskHealthFacilityDetailsComponent, canActivate: [HelpDeskGuard] },
      { path: 'healthFacility/:healthFacilitySupplierId/references', component: HelpDeskHealthFacilityDetailsComponent, canActivate: [HelpDeskGuard] },
      { path: '', redirectTo: "home", pathMatch: "full" }
    ]
  }
];

@NgModule({
  imports: [
    RouterModule.forChild(helpDeskRoutes)
  ],
  providers: [
  ],
  exports: [RouterModule]
})
export class HelpDeskRoutingModule { }

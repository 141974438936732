import * as AuthActions from './auth.actions';
import { UserAcknowledges } from '../../../commonclasses';
import { createReducer, on, Action } from '@ngrx/store';

export interface AuthState {
  token: string | null;
  authenticated: boolean;
  userAcknowledges: UserAcknowledges | null;
}

// State iniziale
const initialState: AuthState = {
  token: null,
  authenticated: false,
  userAcknowledges: null
};

export const createAuthReducer = createReducer(
  initialState,
  on(AuthActions.SetUserAuthenticated, (state, action) => {
    return {
      ...state,
      authenticated: true
    };
  }),
  on(AuthActions.SetUserAcknowledges, (state, action) => {
    return {
      ...state,
      userAcknowledges: action.payload
    };
  }),
  on(AuthActions.Logout, (state, action) => {
    return {
      ...state
    };
  }),
  on(AuthActions.SessionLogout, (state, action) => {
    sessionStorage.removeItem('token');
    return {
      ...state,
      authenticated: false,
      token: null
    };
  }),
  on(AuthActions.SetToken, (state, action) => {
    return {
      ...state,
      token: action.payload
    };
  })
);

export function authReducer(state: AuthState | undefined, action: Action) {
  return createAuthReducer(state, action);
}

export const getToken = (state: AuthState) => state.token;
export const isAuthenticated = (state: AuthState) => state.authenticated;
export const getUserAcknowledges = (state: AuthState) => state.userAcknowledges;
<div *ngIf="!isLoading" fxLayout="column" class="input-wrapper" [ngClass]="{
    'disabled': disabled,
    'error': errorText && errorText.length,
    'readonly': _readonly,
    'inputEditable': _inputEditable,
    'valued': isValued,
    'with-transparency': _withTransparency
  }">

  <!-- Titolo -->
  <label *ngIf="id" fxLayout="row" class="title-label" id="welion-input-label-{{id}}" for="welion-input-{{for}}">

    <ng-container *ngIf="_fromEuropUpload && !customLabel">
      <h6 class="bold" [translate]="title">{{ _isObbligatory && !isValued ? '*' : '' }}</h6>
    </ng-container>

    <ng-container *ngIf="!_fromEuropUpload && !customLabel">
      <h5 class="bold" [translate]="title">{{ _isObbligatory && !isValued ? '*' : '' }}</h5>
    </ng-container>

    <ng-container *ngIf="customLabel" [ngTemplateOutlet]="customLabel">
    </ng-container>

    <div *ngIf="!_isWithoutInfoIcon" (click)="openModalMessage()" tabindex="0" clickKeyBoard
      (onEnterKeyboard)="openModalMessage()" role="button" aria-label="info" aria-labelledby="info-text">
      <svg-icon fxFlexOffset="8px" src="assets/img/icons/info.svg" class="medium-size cursor-pointer"
        [applyClass]="true" aria-hidden="true">
      </svg-icon>
      <p id="info-text" style="display: none;">info</p>
    </div>

  </label>

  <label *ngIf="!id" fxLayout="row" class="title-label">

    <ng-container *ngIf="_fromEuropUpload && !customLabel">
      <h6 class="bold" [translate]="title">{{ _isObbligatory && !isValued ? '*' : '' }}</h6>
    </ng-container>

    <ng-container *ngIf="!_fromEuropUpload && !customLabel">
      <h5 class="bold" [translate]="title">{{ _isObbligatory && !isValued ? '*' : '' }}</h5>
    </ng-container>

    <ng-container *ngIf="customLabel" [ngTemplateOutlet]="customLabel">
    </ng-container>

    <div *ngIf="!_isWithoutInfoIcon" (click)="openModalMessage()" tabindex="0" clickKeyBoard
      (onEnterKeyboard)="openModalMessage()" role="button" aria-label="info" aria-labelledby="info-text">
      <svg-icon fxFlexOffset="8px" src="assets/img/icons/info.svg" class="medium-size cursor-pointer"
        [applyClass]="true" aria-hidden="true">
      </svg-icon>
      <p id="info-text" style="display: none;">info</p>
    </div>

  </label>

  <div class="input-text-wrapper" fxLayout="column">
    <ng-content></ng-content>
    <div *ngIf="!_onlyLabel" class="instruction" [ngClass]="{'error': errorText}" fxLayout="row">
      <ng-container *ngIf="(instructions || _isFromTextArea) && (!errorText || !errorText.length)">
        <h6 class="small description" *ngIf="instructions" [translate]="instructions" [id]="ariaLabelledbyId"></h6>
        <h6 class="small description" *ngIf="_isFromTextArea && !_disabled && numberCharsTextArea > 0"
          innerHTML="Numero massimo caratteri consentiti {{numberCharsTextArea.toString() | boldText}}."
          [id]="ariaLabelledbyId">
        </h6>
      </ng-container>
      <ng-container *ngIf="errorText">
        <svg-icon class="small-size" src="assets/img/icons/exclamation-circle.svg" [applyClass]="true"></svg-icon>
        <h6 class="small red description">{{ errorText }}</h6>
      </ng-container>
      <ng-container *ngIf="_inputEditable">
        <a (click)="inputToEdit()" translate="component.inputContainer.EDIT"></a>
      </ng-container>
    </div>
  </div>
</div>

<div class="loader-input-container" *ngIf="isLoading">
  <div class="first-gray-container"></div>
  <div class="second-gray-container"></div>
  <div class="third-gray-container">
    <div class="first"></div>
    <div class="second"></div>
  </div>
</div>
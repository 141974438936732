import { BrowserModule } from '@angular/platform-browser';
import { LOCALE_ID, NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { SharedModule } from './shared/shared.module';
import { AuthModule } from './auth/auth.module';
import { CoreModule } from './core/core.module';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { reducers } from './ngrx/app.reducers';
import { CoreEffects } from './core/ngrx/core.effects';
import { AuthEffects } from './auth/ngrx/auth.effects';
import { EffectsModule } from '@ngrx/effects';
import { StoreRouterConnectingModule } from '@ngrx/router-store';
import { environment } from 'src/environments/environment';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { AuthInterceptor } from './shared/interceptors/auth.interceptor';
import { StoreModule, Store } from '@ngrx/store';
import { AuthService } from './auth/services/auth.service';
import { ModalService } from './shared/components/modal/modal.service';
import { ComponentExampleComponent } from './components-example/components-example.component';
import { TranslateService } from '@ngx-translate/core';
import { registerLocaleData } from '@angular/common';
import localeIt from '@angular/common/locales/it';
import { ScrollTo } from './core/services/scroll-to.service';
import { NgxAutocomPlaceService } from './shared/services/ngx-autocom-place.service';
import { UserService } from './shared/services/user.service';
import { A11yModule } from '@angular/cdk/a11y';

import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatNativeDateModule } from '@angular/material/core';

registerLocaleData(localeIt);

@NgModule({
  declarations: [
    AppComponent,
    ComponentExampleComponent
  ],
  imports: [
    A11yModule,
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    AppRoutingModule,
    SharedModule,
    AuthModule,
    MatFormFieldModule,
    MatInputModule,
    MatDatepickerModule,
    MatNativeDateModule,
    CoreModule,
    AngularSvgIconModule.forRoot(),
    StoreModule.forRoot(reducers),
    EffectsModule.forRoot([AuthEffects, CoreEffects]),
    StoreRouterConnectingModule.forRoot(),
    !environment.production ? StoreDevtoolsModule.instrument() : [],

  ],
  providers: [
    Store,
    AuthService,
    ModalService,
    UserService,
    ScrollTo,
    TranslateService,
    NgxAutocomPlaceService,
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    { provide: LOCALE_ID, useValue: 'it' }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }

/*
*  Reducer che gestiscono e modificano lo State applicativo riguardante il profilo dell'utente loggato
*/

import { createReducer, on, Action } from '@ngrx/store';
import * as ProfileActions from './profile.actions';
import jwt_decode from "jwt-decode";
import { JwtPayload, Lang, RequiredAuth } from 'atfcore-commonclasses';

export interface ProfileState {
  loggedUser: any;
  editedUser: any;
  isStructure: boolean;
  isUser: boolean;
  isAdmin: boolean;
  isLimitedAdmin: boolean;
  isHelpDesk: boolean;
  isClient: boolean;
  isImpersonate: boolean;
  langs: Lang[];
}

const initialState: ProfileState = {
  loggedUser: null,
  editedUser: null,
  isStructure: false,
  isUser: false,
  isAdmin: false,
  isLimitedAdmin: false,
  isHelpDesk: false,
  isClient: false,
  isImpersonate: false,
  langs: []
};

export const createProfileReducer = createReducer(
  initialState,
  on(ProfileActions.CancelLoggedUser, (state, action) => {
    // Cancello le informazioni sull'utente loggato
    return {
      ...state,
      loggedUser: null
    };
  }),
  on(ProfileActions.SaveAvailableLangs, (state, action) => {
    // Salva le lingue disponibili a sistema recuperate tramite servizi
    return {
      ...state,
      langs: action.payload
    };
  }),
  on(ProfileActions.DecodeToken, (state, action) => {
    const loggedUser: JwtPayload = jwt_decode(action.payload);
    let isStructure: boolean = false;
    let isUser: boolean = false;
    let isAdmin: boolean = false;
    let isLimitedAdmin: boolean = false;
    let isHelpDesk: boolean = false;
    let isClient: boolean = false;
    let isImpersonate: boolean = loggedUser && loggedUser.params && loggedUser.params.originalUserId;

    if (loggedUser && loggedUser.auths && loggedUser.auths.length) {
      for (let i = 0, authsLength = loggedUser.auths.length; i < authsLength; i++) {
        if (loggedUser.auths[i].includes(RequiredAuth.CAMPAIGN_MANAGER_STRUCTURES)) {
          isStructure = true;
        } else if (loggedUser.auths[i].includes(RequiredAuth.CAMPAIGN_MANAGER_ENTITLED)) {
          isUser = true;
        }
        else if (loggedUser.auths[i] === RequiredAuth.CAMPAIGN_MANAGER_ADMIN) {
          // Amministratore con pieni poteri
          isAdmin = true;
        } else if (loggedUser.auths[i] === RequiredAuth.CAMPAIGN_MANAGER_ADMIN_LIMITED) {
          // Amministratore con poteri limitati
          isLimitedAdmin = true;
        } else if (loggedUser.auths[i] === RequiredAuth.CAMPAIGN_MANAGER_HELP_DESK) {
          isHelpDesk = true;
        } else if (loggedUser.auths[i] === RequiredAuth.CAMPAIGN_MANAGER_CUSTOMER) {
          isClient = true;
        }
      }
    }
    return {
      ...state,
      isStructure: isStructure,
      isUser: isUser,
      isAdmin: isAdmin,
      isLimitedAdmin: isLimitedAdmin,
      isHelpDesk: isHelpDesk,
      isClient: isClient,
      isImpersonate: isImpersonate,
      loggedUser: {
        ...state.loggedUser,
        ...loggedUser
      }
    };
  })
)

export function profileReducer(state: ProfileState | undefined, action: Action) {
  return createProfileReducer(state, action);
}

export const getLoggedUser = (state: ProfileState) => state.loggedUser;
export const getIsStructure = (state: ProfileState) => state.isStructure;
export const getIsHelpDesk = (state: ProfileState) => state.isHelpDesk;
export const getIsUser = (state: ProfileState) => state.isUser;
export const getIsAdmin = (state: ProfileState) => state.isAdmin;
export const getIsAdminOrLimitedAdmin = (state: ProfileState) => state.isAdmin || state.isLimitedAdmin;
export const getIsLimitedAdmin = (state: ProfileState) => state.isLimitedAdmin;
export const getIsClient = (state: ProfileState) => state.isClient;
export const getIsImpersonate = (state: ProfileState) => state.isImpersonate;
export const getAvailableLangs = (state: ProfileState) => state.langs;

import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from "@angular/router";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { TranslateService } from '@ngx-translate/core';
import { Observable, Subject } from 'rxjs';
import { Store } from "@ngrx/store";
import { takeUntil } from "rxjs/operators";

import * as fromApp from "../../../ngrx/app.reducers";
import * as CoreActions from "../../../core/ngrx/core.actions";
import { SenecaResponse } from "atfcore-commonclasses/bin/classes/common";
import { HealthFacilityInfo } from "atfcore-commonclasses/bin/classes/campaignmanager/serviceResponses";

import { RedirectService } from 'src/app/shared/services/redirect.service';
import { getWarningModal } from "src/app/utils/utils";
import { ScrollTo } from "src/app/core/services/scroll-to.service";
import { SupplierService } from "../../services/supplier.service";

@Component({
  selector: 'app-admin-add-health-facility',
  templateUrl: './addHealthFacility.component.html',
  styleUrls: ['./addHealthFacility.component.scss']
})
export class AdminAddHealthFacilityComponent implements OnInit, OnDestroy {

  isLoading: boolean = false;

  healthFacility?: HealthFacilityInfo;
  healthFacilitySupplierId: string = '';

  addHealthFacilityForm = new FormGroup({
    'name': new FormControl(undefined, Validators.required),
    'code': new FormControl(undefined),
    'description': new FormControl(undefined),
    'address': new FormControl(undefined),
    'disabledAddress': new FormControl(false, Validators.requiredTrue),
    'latitude': new FormControl(undefined, Validators.required),
    'longitude': new FormControl(undefined, Validators.required)
  });

  get name() {
    return this.addHealthFacilityForm.get('name') as FormControl;
  }

  get code() {
    return this.addHealthFacilityForm.get('code') as FormControl;
  }

  get description() {
    return this.addHealthFacilityForm.get('description') as FormControl;
  }

  get address() {
    return this.addHealthFacilityForm.get('address') as FormControl;
  }

  get disabledAddress() {
    return this.addHealthFacilityForm.get('disabledAddress') as FormControl;
  }

  get latitude() {
    return this.addHealthFacilityForm.get('latitude') as FormControl;
  }

  get longitude() {
    return this.addHealthFacilityForm.get('longitude') as FormControl;
  }

  private _unsubscribeSignal$: Subject<void> = new Subject();

  constructor(
    private route: ActivatedRoute,
    private appStore: Store<fromApp.AppState>,
    private translate: TranslateService,
    private redirectService: RedirectService,
    private scrollTo: ScrollTo,
    private supplierService: SupplierService
  ) {
  }

  ngOnInit() {
    this.scrollTo.header();

    this.healthFacilitySupplierId = this.route.snapshot.paramMap.get('healthFacilitySupplierId') || '';

    if (this.healthFacilitySupplierId) {
      this.isLoading = true;
      this.getHealthFacility();
    }
  }

  selectedPlace(place: any) {
    if (!place || place.geometry === undefined || place.geometry === null) {
      return;
    }
    if (place.address_components && place.address_components.length) {
      this.latitude.setValue(place.geometry.location.lat());
      this.longitude.setValue(place.geometry.location.lng());
      this.address.setValue(place.formatted_address);
      this.address.disable();
      this.disabledAddress.setValue(true);
    }
  }

  getHealthFacility() {
    this.supplierService.getHealthFacilitySupplierForAdmin(this.healthFacilitySupplierId)
      .pipe(takeUntil(this._unsubscribeSignal$.asObservable()))
      .subscribe((result) => {
        if (!result || result.error) {
          this.dispatchWarningModal('044', result.error || '');
        } else if (result.response) {
          this.healthFacility = result.response;
          this.name.setValue(this.healthFacility.name);
          this.description.setValue(this.healthFacility.description);
          this.code.setValue(this.healthFacility.code);

          if (result.response.address) {
            this.address.disable();
            this.address.setValue(this.healthFacility.address);
            this.disabledAddress.setValue(true);
            this.latitude.setValue(this.healthFacility.latitude);
            this.longitude.setValue(this.healthFacility.longitude);
          }
        }
        this.isLoading = false;
      }, (err) => {
        if (err && err.message) {
          this.dispatchWarningModal('045', err.message);
        }
        this.isLoading = false;
      });
  }

  ngOnDestroy() {
    this._unsubscribeSignal$.next();
    this._unsubscribeSignal$.unsubscribe();
  }

  /**
   * @description Va all'homepage delle SS
   */
  goBack() {
    if (this.healthFacility) {
      this.redirectService.goToAdminHealthFacility(this.healthFacilitySupplierId);
    } else {
      this.redirectService.goToAdminHealthFacilities();
    }
  }

  goToFAQ() {

  }

  onEditAddress() {
    this.disabledAddress.setValue(false);
    this.address.enable();
  }

  confirmAddHealthFacility() {
    if (this.addHealthFacilityForm.valid) {
      this.isLoading = true;

      let latitude: string | undefined;
      let longitude: string | undefined;
      let address: string | undefined;

      if (this.disabledAddress.value) {
        latitude = this.latitude.value;
        longitude = this.longitude.value;
        address = this.address.value;
      }

      let request: Observable<SenecaResponse<boolean>> = this.healthFacility ?
        this.supplierService.updateHealthFacilitySupplierForAdmin(this.name.value, this.healthFacility.supplierId, this.code.value, address, latitude, longitude, this.description.value) :
        this.supplierService.createHealthFacilitySupplierForAdmin(this.name.value, this.code.value, address, latitude, longitude, this.description.value);

      request
        .pipe(takeUntil(this._unsubscribeSignal$.asObservable()))
        .subscribe((result) => {
          if (!result || result.error) {
            this.dispatchWarningModal('041', result.error || '');
          } else if (result.response) {
            if (this.healthFacility) {
              this.redirectService.goToAdminHealthFacility(this.healthFacilitySupplierId);
            } else {
              this.redirectService.goToAdminHealthFacilities();
            }
          }
          this.isLoading = false;
        }, (err) => {
          if (err && err.message) {
            this.dispatchWarningModal('042', err.message);
          }
          this.isLoading = false;
        });
    }
  }

  /**
   * @description Mostra una modale con un messaggio di errore
   * @param modalId
   * @param error
   */
  dispatchWarningModal(modalId: string, error: string) {
    const messageObj = getWarningModal(this.translate, modalId, error);
    this.appStore.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
  }

}

<div class="page-content-wrapper" fxLayout="column" fxLayoutAlign="start center">

  <div class="campaign-details" fxLayout="column">

    <h1 fxFlexOffset="28px">
      {{ 'structures.STRUCTURE_ID' | translate: { value: structureName, value2: structureCode } }}
    </h1>

    <!-- Lista di strutture -->
    <welion-select fxFlexOffset.xs="12px" isStructureSelect mediumText [bindLabel]="'title'"
      [selectedItem]="selectedStructure" searchable [loading]="isLoadingStructureList"
      (change)="onStructureSelected($event)" [items]="structureList" (scroll)="fetchMoreStructures($event)"
      [placeholder]="'structures.round.select.PLACEHOLDER' | translate"
      [noDataFoundLabel]="'structures.round.select.NO_STRUCTURE' | translate" [typeahead]="structureServive$"
      [attrAriaLabel]="'structures.round.select.PLACEHOLDER' | translate" [disabled]="disableStructureSelect">
    </welion-select>

    <bread-crumbs fxFlexOffset="24px" firstLevel="ACTIVE_CAMPAIGNS"
      [secondLevel]="campaign && campaign.title || ('structures.NO_CAMPAIGN' | translate)" [venueId]="venueId">
    </bread-crumbs>

    <ng-container *ngIf="!isLoadingCampaign && campaign">

      <div class="campaign-details-container-background">
        <h3 translate="structures.ROUND"></h3>
      </div>

      <!-- Lista campagne attive -->
      <div class="campaign-details-container" fxLayout="column" fxLayoutAlign="start center">

        <card-round *ngFor="let round of campaign?.rounds; let first = first" [round]="round" [venueId]="venueId"
          [fxFlexOffset]="first ? '0px' : '24px'">
        </card-round>

      </div>
    </ng-container>

    <ng-container *ngIf="isLoadingCampaign">
      <div class="loader spinner-big"></div>
    </ng-container>

  </div>

</div>

<div [id]="_id" fxLayout="row" [fxLayoutAlign]="direction === 'right' ? 'end' : ''">

  <div fxLayout="column" [fxLayoutAlign]="direction === 'right' ? 'start end' : 'start start'"
    class="welion-select-button" [ngClass]="{'right': direction === 'right', 'isLink': _isLink}">

    <!-- Pulsante principale -->
    <ng-container *ngIf="!_isLink">
      <welion-button [label]="label" [type]="type" [size]="size" hasRightIcon [customIcon]="iconRight"
        (onClick)="mainButtonClick()" [disabled]="disabled">
      </welion-button>
    </ng-container>
    <ng-container *ngIf="_isLink">
      <div fxLayout="row" class="cursor-pointer" [ngClass]="{'link-disabled': disabled}" (click)="mainButtonClick()">
        <h6 class="black bold underline">
          {{ label }}
        </h6>
        <svg-icon fxFlexOffset="8px" src="assets/img/icons/chevron-down.svg" class="small-size" [applyClass]="true">
        </svg-icon>
      </div>
    </ng-container>

    <!-- Dropdown -->
    <div *ngIf="_isOpen" [id]="_id + '-pannel'" [@showIf] class="button-dropdown"
      [ngClass]="{'width-200': isFromDropdownClient}" tabindex="0" (blur)="onBlur()">
      <!-- Sezioni dropdown -->
      <div *ngFor="let item of dropdownItems" class="dropdown-select" fxLayout="row" fxLayoutAlign="start center"
        (mousedown)="onItemAction(item, $event)" [ngClass]="{
          'disabled': (item.disabled || (item.disabledFct && item.disabledFct(currentSelect))),
          'strokeIcon': item.strokeIcon,
          'fillIcon': !item.strokeIcon}">
        <svg-icon *ngIf="item.icon" [src]="item.icon" class="small-size" [applyClass]="true">
        </svg-icon>
        <!-- Label -->
        <p [fxFlexOffset]="item.icon ? '8px' : ''" tabindex="-1" ngClass.xs="center">{{ item.label |
          translate }}</p>
      </div>
    </div>
  </div>

</div>
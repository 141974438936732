<div class="box-preview" fxLayout="column" fxLayoutAlign="center center">
  <div class="preview-container">
    <!-- File con estensione sbagliata -->
    <div *ngIf="doc.file && !doc.file.fileExtension" (drop)="handleFileInputOnDrug($event)"
      (dragover)="allowDrag($event)" class="drag-and-drop" fxLayout="column" fxLayoutAlign="center center">
      <div class="file-upload-container">
        <p class="text-big" translate="uploadFile.CHARGED_WRONG_FILE"></p>
        <label for="updateFile" (click)="uploadDoc()">
          <h5 fxFlexOffset="4px" translate="uploadFile.UPDATE_FILE"></h5>
        </label>
      </div>
    </div>

    <!-- File caricato -->
    <div *ngIf="(doc.file && doc.file.fileExtension) && !_isToReset" (drop)="handleFileInputOnDrug($event)"
      (dragover)="allowDrag($event)" class="drag-and-drop" fxLayout="column" fxLayoutAlign="center center">
      <svg-icon class="big-size" [applyClass]="true" src="assets/img/icons/file/{{doc.file.fileExtension}}.svg">
      </svg-icon>
      <div class="file-upload-container">
        <p class="text-big document-name">{{doc.name}}</p>
        <p class="text-small">{{doc.file.fileExtension}} file - {{doc.file.sizeRounded}}kB</p>
        <label for="uploadDoc" (click)="uploadDoc()">
          <h5 fxFlexOffset="4px" translate="uploadFile.UPDATE_FILE"></h5>
        </label>
      </div>
    </div>

    <!-- Carica nuovo file -->
    <div *ngIf="!blob || _isToReset" class="drag-and-drop" (drop)="handleFileInputOnDrug($event)"
      (dragover)="allowDrag($event)" fxLayout="column" fxLayoutAlign="center center">
      <svg-icon class="big-size" [applyClass]="true" src="assets/img/icons/upload-file-cloud.svg"></svg-icon>
      <div class="file-upload-container">
        <!-- Trascina il file in questa area -->
        <h3 fxFlexOffset="24px" class="small bold" translate="component.modalUpload.DRAG"></h3>
        <!-- Oppure -->
        <h6 fxFlexOffset="4px" class="small" translate="component.modalUpload.OR"></h6>
        <label for="fileDrop" (click)="uploadDoc()">
          <h5 fxFlexOffset="4px" translate="component.modalUpload.LOAD"></h5>
        </label>
      </div>
    </div>
  </div>
</div>
<div fxHide fxLayout="row" fxLayoutAlign="start center" (click)="uploadDoc()">
  <input type="file" id="uploadFileDelete" (change)="handleFileInput($event.target)" [accept]="acceptedMimeTypes">
</div>
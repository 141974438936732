import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { InfoAttendance, InfoRound } from 'atfcore-commonclasses/bin/classes/campaignmanager';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { InfoEntitled } from 'src/app/user/home/home.component';
import { checkUnderCustomYearsOld, hasRoundClosedBooking } from 'src/app/utils/utils';

@Component({
  selector: 'card-ticket',
  templateUrl: 'card-ticket.component.html',
  styleUrls: ['./card-ticket.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CardTicketComponent implements OnInit {


  @Input() id?: string;

  isUnder2Years: boolean = false;
  @Input() forceDisableActions: boolean = false;

  _entitled?: InfoEntitled;
  @Input() set entitled(value: InfoEntitled | undefined) {
    this._entitled = value;
    if (this._entitled) {
      this.isParent = (this._entitled.user as any).parentCodFisc ? true : false;
      this.isUnder2Years = (this._entitled.user && this._entitled.user.birthDate) ? checkUnderCustomYearsOld(new Date(this._entitled.user.birthDate), 2) : false;
    }
  };

  @Input() manageBooking?: boolean = false;
  @Input() dropdownOptions?: any;

  @Input() withReservationId?: boolean;

  _supplierName?: string;
  @Input() set supplierName(value: string | undefined) {
    this._supplierName = value;
    this.tacconeRound();
  };

  _round?: InfoRound;
  @Input() set round(value: InfoRound | undefined) {
    this._round = value;
    this.tacconeRound();
  }

  _attendance?: InfoAttendance;
  @Input() set attendance(value: InfoAttendance | undefined) {
    this._attendance = value;
  }

  _readOnly: boolean = false;
  @Input() set readOnly(value: boolean | string) {
    this._readOnly = coerceBooleanProperty(value);
  };

  @Input() isFamiliarToAdd?: boolean;
  @Input() familiarsTypeToChoose?: string;

  @Output() onCancelTicket = new EventEmitter<void>();
  @Output() onOpenEditData = new EventEmitter<void>();
  @Output() onEditTicket = new EventEmitter<void>();
  @Output() onBookingTicket = new EventEmitter<void>();
  @Output() onOpenVenue = new EventEmitter<void>();
  @Output() onGoToAddFamiliar = new EventEmitter<void>();

  isParent: boolean = false;

  get isDisabled(): boolean {
    return !this._attendance || (this._attendance.status === 'YES' || this._attendance.status === 'NO' || this._attendance.status === 'DELIVERED' || this.closedBooking || this.forceClosedBooking) ? true : false;
  }

  get closedBooking() {
    return this._attendance && this._round && (this._round.maxReservationEndDate || this._round.minReservationEndDate) && hasRoundClosedBooking(this._round, this._attendance);
  }

  forceClosedBooking: boolean = false;

  _isDropdownOpen: boolean = false;

  cardId: number = Math.floor((Math.random() * 10000) + 1);

  @Input() closeDropdown?: Observable<void>;
  @Input() openDropdown?: Observable<void>;
  @Input() vaccinationClosed?: boolean;

  private _unsubscribeSignal$: Subject<void> = new Subject();

  constructor() {
  }

  ngOnInit(): void {
    if (this.closeDropdown) {
      this.closeDropdown
        .pipe(takeUntil(this._unsubscribeSignal$.asObservable()))
        .subscribe(() => {
          this.onCloseDrowpdown();
        });
    }

    if (this.openDropdown) {
      this.openDropdown
        .pipe(takeUntil(this._unsubscribeSignal$.asObservable()))
        .subscribe(() => {
          this.onOpenDrowpdown();
        });
    }
  }
  private onOpenDrowpdown() {
    this._isDropdownOpen = true

    if (this.cardId) {
      let timeout = setTimeout(() => {
        if (this._isDropdownOpen) {
          const mainButton = window.document.getElementById(this.cardId + '-pannel');
          if (mainButton) {
            mainButton.focus();
          }
        }
        clearTimeout(timeout);
      }, 400);
    }
  }

  openDropdownMenu() {
    if (this._isDropdownOpen) {
      //  this.onCloseDrowpdown();
    } else {
      this.onOpenDrowpdown();
    }
  }

  onBlur(event?:any) {
    if (!event.currentTarget.contains(event.relatedTarget)) {
      this.onCloseDrowpdown();
    }
  }

  private onCloseDrowpdown() {
    this._isDropdownOpen = false;
  }

  onCancelTicketClick() {
    if (!this.isDisabled) {
      this.onCancelTicket.emit();
    }
  }

  onOpenEditDataClick() {
    this.onOpenEditData.emit();
  }

  onItemAction(item: any, event: any, attendance: any) {
    if (!item.isDisabled) {
      item.onClick(attendance);
      this.onCloseDrowpdown();
    }
    event.stopPropagation();
  }

  /**
   * TACCONE PER PROD
   * Dev b847f4a6-c6d1-427b-9fb3-bb38b4e47c18
   * Prod 541be67a-21ed-40bd-8fa1-288945cd61d6
   */
  tacconeRound() {
    if (this._round && this._round.roundId === 'b847f4a6-c6d1-427b-9fb3-bb38b4e47c18') {
      // if (this._round && this._round.roundId === '541be67a-21ed-40bd-8fa1-288945cd61d6') {
      if (this._supplierName && (this._supplierName === 'accenture' || this._supplierName === 'leroy merlin')) {
        this.forceClosedBooking = true;
      }
    }
  }

  // Vai ad aggiungere un familiare
  goToAddFamiliar() {
    this.onGoToAddFamiliar.emit();
  }

}

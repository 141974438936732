import { Component, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { Subject, of } from 'rxjs';
import { switchMap, takeUntil } from 'rxjs/operators';

import * as CoreActions from "../../../core/ngrx/core.actions";
import * as fromApp from "../../../ngrx/app.reducers";

import { HealthFacilityInfo } from 'atfcore-commonclasses/bin/classes/campaignmanager/serviceResponses';

import { AuthService } from 'src/app/auth/services/auth.service';
import { ScrollTo } from 'src/app/core/services/scroll-to.service';
import { RedirectService } from 'src/app/shared/services/redirect.service';
import { ExportService } from 'src/app/structure/services/export.service';
import { RentService } from 'src/app/structure/services/rent.service';
import { getModalMessageData, getWarningModal } from 'src/app/utils/utils';
import { SupplierService } from '../../services/supplier.service';

@Component({
  selector: 'app-admin-health-facilities',
  templateUrl: './healthFacilities.component.html',
  styleUrls: ['./healthFacilities.component.scss']
})
export class AdminHealthFacilitiesComponent implements OnInit, OnDestroy {

  isLoadingCount: boolean = true;
  isLoading: boolean = true;

  searchText?: string;

  private _unsubscribeSignal$: Subject<void> = new Subject();

  readonly paginationLength: number = 25;

  healthFacilitiesCount: number = 0;
  healthFacilities: HealthFacilityInfo[] = [];

  isLimitedAdmin: boolean = false;

  constructor(
    private appStore: Store<fromApp.AppState>,
    public translate: TranslateService,
    private supplierService: SupplierService,
    public redirectService: RedirectService,
    private authService: AuthService,
    private exportService: ExportService,
    private rentService: RentService,
    private scrollTo: ScrollTo
  ) {
  }

  ngOnInit() {
    this.appStore.select(fromApp.getIsLimitedAdmin)
      .pipe(takeUntil(this._unsubscribeSignal$.asObservable()))
      .subscribe(
        (isLimitedAdmin) => {
          this.isLimitedAdmin = isLimitedAdmin;
        });

    this.getHealthFacilitiesCount();
  }

  ngOnDestroy() {
    this._unsubscribeSignal$.next();
    this._unsubscribeSignal$.unsubscribe();
  }

  /**
   * @description Mostra una modale con titolo e messaggio
   * @param modalId
   * @param title
   * @param message
   */
  dispatchMessageModal(title: string, message: string) {
    const messageObj = getModalMessageData(this.translate, title, message);
    this.appStore.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
  }

  /**
   * @description Mostra una modale con un messaggio di errore
   * @param modalId
   * @param error
   */
  dispatchWarningModal(modalId: string, error: string) {
    const messageObj = getWarningModal(this.translate, modalId, error);
    this.appStore.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
  }

  getHealthFacilitiesCount() {
    this.isLoadingCount = true;
    this.isLoading = true;

    this.supplierService.countHealthFacilitySupplierForAdmin(this.searchText)
      .pipe(takeUntil(this._unsubscribeSignal$.asObservable()))
      .subscribe((result) => {
        if (!result || result.error) {
          this.dispatchWarningModal('082', result.error || '');
          this.resetPagination();
        } else {
          this.healthFacilitiesCount = result.response;

          if (this.healthFacilitiesCount === 0) {
            this.resetPagination();
          } else {
            this.getHealthFacilities();
          }
        }
        this.isLoadingCount = false;
      }, (err) => {
        if (err && err.message) {
          this.dispatchWarningModal('084', err.message);
        }
        this.resetPagination();
      });
  }

  getHealthFacilities(index?: number) {

    this.isLoading = true;

    const pageSelectedIndex = index ? index : 0;
    if (index === 0 || index) {
      this.scrollTo.element('admin-health-facilities-anchor');
    }

    this.supplierService.listHealthFacilitySupplierForAdmin((pageSelectedIndex * this.paginationLength), this.paginationLength, this.searchText)
      .pipe(takeUntil(this._unsubscribeSignal$.asObservable()))
      .subscribe((result) => {
        if (!result || result.error) {
          this.dispatchWarningModal('082', result.error || '');
          this.resetPagination();
        } else {
          this.healthFacilities = result.response;
          this.isLoading = false;
        }
      }, (err) => {
        if (err && err.message) {
          this.dispatchWarningModal('084', err.message);
        }
        this.resetPagination();
      });
  }

  resetPagination() {
    this.isLoadingCount = false;
    this.isLoading = false;

    this.healthFacilitiesCount = 0;
    this.healthFacilities = [];
  }

  newHealthFacility() {
    this.redirectService.goToNewHealthFacilitySupplier();
  }

  onHealthFacilityClick(venue: HealthFacilityInfo) {
    this.redirectService.goToAdminHealthFacility(venue.supplierId);
  }

  searchTextChange(searchText?: string) {
    this.searchText = searchText;
  }

  searchHealthFacility() {
    this.getHealthFacilitiesCount();
  }

  /**
   * Scarico il report delle strutture sanitarie
   */
  exportHealthFacilityList() {
    this.isLoading = true;

    this.rentService.exportHealthFacilitySupplierForAdmin()
      .pipe(
        switchMap((senecaResponse) => {
          if (senecaResponse && senecaResponse.error) {
            this.dispatchWarningModal('030', senecaResponse.error);
            this.isLoading = false;

            return of(null);
          }
          return this.exportService.getDownloadTempFileUrl(senecaResponse.response, false);
        }),
        takeUntil(this._unsubscribeSignal$.asObservable())
      )
      .subscribe(downloadUrl => {
        if (downloadUrl) {
          const timeout = setTimeout(() => {
            clearTimeout(timeout);
            document.location.assign(downloadUrl);
            this.isLoading = false;
          }, 500);
        }
      }, (err) => {
        this.isLoading = false;
        if (err && err.message) {
          this.dispatchWarningModal('031', err.message);
        }
      });
  }

  /**
   * Recupera token dalla chiave dell'url
   * @param callback
   */
  getTokenFromSsortkqp(callback: (value: string) => void) {
    this.authService.crateRetrieveTokenAfterLogin()
      .pipe(takeUntil(this._unsubscribeSignal$.asObservable()))
      .subscribe(result => {
        if (result && result.error) {
          this.isLoading = false;
          this.dispatchWarningModal('028', result.error);
        } else {
          callback(result.response);
        }
      }, (err) => {
        if (err && err.message) {
          this.isLoading = false;
          this.dispatchWarningModal('029', err.message);
        }
      });
  }

}

<div class="add-slots-container" fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="start start"
  fxLayoutAlign.lt-md="start center">

  <div class="add-slots-side" fxLayout="column" fxLayoutAlign="space-between">

    <div fxLayout="column" fxLayoutAlign="start start">
      <div class="add-slots-back" fxLayout="row" fxLayoutAlign="start center" fxFlexOffset="40px" (click)="goBack()">
        <svg-icon fxFlexOffset="40px" [applyClass]="true" src="./assets/img/icons/arrow-white-left.svg"></svg-icon>
        <h5 class="white" fxFlexOffset="12px">{{ 'generic.GO_BACK' | translate | uppercase}}</h5>
      </div>

      <h3 fxFlexOffset="24px" *ngIf="venue">
        {{ 'addSlots.SIDE_TITLE' | translate:
        { name: venue.name, address: venue.address } }}
      </h3>

      <div fxFlexOffset="24px" class="add-slots-tick"></div>

      <div fxShow fxHide.lt-md class="addSlots-faq" fxFlexOffset="48px" fxLayout="row">
        <h5 translate="generic.FAQ"></h5>
        <h5 fxFlexOffset="4px" translate="generic.FAQ2" class="primary bold faq-link" (click)="goToFAQ()"></h5>
      </div>
    </div>

    <svg-icon fxShow fxHide.lt-md class="welion-logo" [applyClass]="true" src="./assets/img/icons/app-logo.svg">
    </svg-icon>

    <div fxShow fxHide.lt-md fxFlexOffset="40px"></div>

  </div>

  <div fxFlex fxLayout="row" fxLayout.xs="column" fxLayoutAlign="center" fxLayoutAlign.xs="start"
    class="add-slots-body-container">

    <div class="add-slots-body" fxLayout="column" fxLayoutAlign="start center">

      <h3 translate="addSlots.CHOOSE" ngClass.xs="center"></h3>

      <input-container fxFlexOffset="24px" class="full-width" [title]="'addSlots.DATE' | translate">
        <welion-datepicker class="full-width" placeholder="addSlots.DATE_PLACEHOLDER"
          (dateChanged)="onDateChange($event)" [minDate]="minRoundDate" [maxDate]="maxRoundDate">
        </welion-datepicker>
      </input-container>

      <h3 translate="addSlots.TIMES" fxFlexOffset="32px"></h3>

      <input-container isWithoutInfoIcon fxFlexOffset="32px" class="full-width"
        [title]="'addSlots.SLOT_LIST' | translate">

        <div fxLayout="column" fxShow fxHide.xs>

          <div fxLayout="row">
            <h5 translate="addSlots.DURATION" fxFlex="56px"></h5>
            <h5 translate="addSlots.FROM" fxFlex="40px" fxFlexOffset="84px"></h5>
            <h5 translate="addSlots.TO" fxFlex="32px" fxFlexOffset="28px"></h5>
            <h5 translate="addSlots.SEATS" class="nowrap" fxFlex fxFlexOffset="36px"></h5>
          </div>

          <div *ngIf="isCreatingNewSlots" fxLayout="row" fxLayoutAlign="center">
            <div class="loader spinner-small"></div>
          </div>

          <div #addSlotsList fxLayout="row wrap" fxFlexOffset="8px" class="add-slots-form-list"
            *ngFor="let slot of slotInfos; let index = index, let last = last" [attr.ready]="last ? false : true">
            <!-- Durata -->
            <h5 fxFlex="132px">{{ 'addSlots.MINUTES' | translate: { value: slot.duration} }}</h5>

            <!-- Dalle -->
            <h5 fxFlex="60px" fxFlexOffset="8px">{{ slot.from | date: 'HH:mm' }}</h5>

            <!-- Alle -->
            <h5 fxFlex="60px" fxFlexOffset="8px">{{ slot.to | date: 'HH:mm' }}</h5>

            <!-- N. Posti -->
            <h5 fxFlex="60px" fxFlexOffset="8px">{{ slot.seats }}</h5>

            <!-- Elimina -->
            <h5 fxFlexOffset="32px" translate="addSlots.DELETE" class="bold underline pointer"
              (click)="deleteSlot(index)"></h5>
          </div>

          <form *ngIf="!isCreatingNewSlots" fxFlexOffset="8px" fxLayout="row" class="add-slots-form">
            <!-- Durata -->
            <welion-select fxFlex="132px" [items]="durations" [selectedItem]="duration.value"
              (change)="onChangeDuration($event)"></welion-select>

            <!-- Dalle -->
            <welion-input fxFlex="60px" fxFlexOffset="8px" placeholder="09:00" [inputValue]="from">
            </welion-input>

            <!-- Alle -->
            <welion-input fxFlex="60px" fxFlexOffset="8px" placeholder="10:00" [inputValue]="to">
            </welion-input>

            <!-- N. Posti -->
            <welion-input fxFlex="60px" fxFlexOffset="8px" placeholder="1" [inputValue]="seats">
            </welion-input>

            <!-- Crea slot -->
            <h5 fxFlexOffset="32px" translate="addSlots.CREATE" class="bold underline"
              [ngClass]="{'grey': slotInfoForm.invalid}" (click)="addSlots()"></h5>
          </form>

        </div>

        <!-- Mobile -->
        <div fxLayout="column" fxHide fxShow.xs>

          <div class="add-slots-form-header" fxLayout="column">
            <div fxLayout="row">
              <h5 translate="addSlots.FROM" fxFlex="60px"></h5>
              <h5 translate="addSlots.DURATION" fxFlex="60px" fxFlexOffset="16px"></h5>
            </div>
            <div fxLayout="row" fxFlexOffset="12px">
              <h5 translate="addSlots.TO" fxFlex="60px"></h5>
              <h5 translate="addSlots.SEATS" fxFlex fxFlexOffset="16px"></h5>
            </div>
          </div>

          <div fxLayout="row" fxLayoutAlign="start center" [fxFlexOffset]="first ? '16px' : '8px'"
            class="add-slots-form-list" *ngFor="let slot of slotInfos; let index = index, let first = first">

            <div fxLayout="column">
              <div fxLayout="row">
                <!-- Dalle -->
                <h5 fxFlex="60px">{{ slot.from | date: 'HH:mm' }}</h5>
                <!-- Durata -->
                <h5 fxFlex="100px" fxFlexOffset="16px">{{ 'addSlots.MINUTES' | translate: { value: slot.duration} }}
                </h5>
              </div>
              <div fxLayout="row" fxFlexOffset="12px">
                <!-- Alle -->
                <h5 fxFlex="60px">{{ slot.to | date: 'HH:mm' }}</h5>
                <!-- N. Posti -->
                <h5 fxFlex="100px" fxFlexOffset="16px">{{ slot.seats }}</h5>
              </div>
            </div>

            <!-- Elimina -->
            <h5 fxFlexOffset="16px" translate="addSlots.DELETE" class="bold underline pointer"
              (click)="deleteSlot(index)"></h5>
          </div>

          <form fxFlexOffset="12px" fxLayout="row" class="add-slots-form">

            <div fxFlex="60px" fxLayout="column">
              <!-- Dalle -->
              <welion-input placeholder="09:00" [inputValue]="from">
              </welion-input>

              <!-- Alle -->
              <welion-input fxFlexOffset="12px" placeholder="09:10" [inputValue]="to">
              </welion-input>
            </div>

            <div fxFlex="132px" fxFlexOffset="16px" fxLayout="column">
              <!-- Durata -->
              <welion-select [items]="durations" [selectedItem]="duration.value" (change)="onChangeDuration($event)">
              </welion-select>

              <!-- N. Posti -->
              <welion-input fxFlexOffset="12px" placeholder="1" [inputValue]="seats">
              </welion-input>
            </div>

            <div fxLayout="row" fxLayoutAlign="center center">
              <!-- Crea slot -->
              <h5 fxFlexOffset="16px" translate="addSlots.CREATE" class="bold underline"
                [ngClass]="{'grey': slotInfoForm.invalid}" (click)="addSlots()"></h5>
            </div>

          </form>

        </div>

      </input-container>

      <div fxFlexOffset="24px" class="full-width" fxLayout="row" fxLayoutAlign="end">
        <welion-button width="264px" [disabled]="slotInfos.length === 0 || slotDate.invalid || isCreatingNewSlots"
          type="primary" size="large" label="addSlots.CONFIRM" buttonType="submit" (onClick)="openAddSlotsModal()">
        </welion-button>
      </div>

      <div fxHide fxShow.xs fxFlex.xs="40px"></div>

    </div>

  </div>

</div>

<!-- Modale di conferma degli slots -->
<modal id="addSlots" class="add-slots-modal" (onClose)="closeAddSlotsModal()" (onCancel)="closeAddSlotsModal()">

  <!-- Conferma la disponibilità -->
  <h2 fxFlexOffset="24px" class="small primary" translate="addSlots.CONFIRM_AVAILABILITY"></h2>

  <div fxFlexOffset="24px" class="add-slots-info" fxLayout="column" fxLayoutAlign="center center">
    <!-- 21 Marzo 2021 -->
    <h1 class="black">{{ slotDate.value | date: 'dd MMMM yyyy' | titlecase }}</h1>
  </div>

  <div [fxFlexOffset]="first ? '32px' : '8px'" *ngFor="let slot of slotInfos; let first = first,let even = even"
    class="add-slots-list" [class]="{'even': even}" fxLayout="row" fxLayoutAlign="start center">
    <!-- 10 minuti -->
    <h6 fxFlexOffset="2px" class="black" fxFlex="148px">
      {{ 'addSlots.MINUTES' | translate: { value: slot.duration} }}
    </h6>
    <!-- 09:00 -->
    <h6 class="black" fxFlex="48px">{{ slot.from | date: 'HH:mm' }}</h6>
    <!-- 09:10 -->
    <h6 fxFlexOffset="20px" class="black" fxFlex="48px">{{ slot.to | date: 'HH:mm' }}</h6>
    <!-- 1 -->
    <h6 fxFlexOffset="20px" class="black" fxFlex="20px">{{ slot.seats }}</h6>
  </div>

  <div fxFlexOffset="24px" class="full-width" fxLayout="row" fxLayoutAlign="center">
    <welion-button fxFlex="410px" fxFlex.xs="100" label="addSlots.ADD_CONFIRM" type="primary" size="large"
      (onClick)="closeAddSlotsModal(true)">
    </welion-button>
  </div>

</modal>

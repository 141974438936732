<div fxLayout="column" class="full-width"
  [ngClass]="{'is-structure-select': _isStructureSelect, 'with-medium-text': _mediumText, 'with-transparency': _withTransparency}">

  <ng-select class="europ" [ngClass]="{'readonly': readonly, 'state-error': forceError}" [clearable]="clearable"
    [multiple]="_multiple" [disabled]="disabled" [bindLabel]="bindLabel" [searchable]="_searchable" [tabIndex]="tabIndex"
    [readonly]="readonly" [placeholder]="placeholder" [(ngModel)]="_selectedItem" [loading]="loading" [items]="_items"
    [appendTo]="appendTo" (change)="emitOnSelectedItem($event)" [virtualScroll]="_virtualScroll" [searchFn]="searchFn"
    (scrollToEnd)="scrollToEnd.emit()"
    [labelForId]="labelForId" (scroll)="scroll.emit($event)" [typeahead]="typeahead" #welionSelect>

    <!-- Elementi selezionati -->
    <ng-template class="europ" ng-label-tmp let-item="item" let-clear="clear">
      <p *ngIf="!_toTranslate">{{ item.name }}</p>
      <p *ngIf="_toTranslate">{{ item.name | translate }}</p>
    </ng-template>

    <!-- Template per l'inizio della ricerca -->
    <ng-template class="europ" ng-typetosearch-tmp>
      <div class="ng-option disabled">{{ startSearchLabel || ('generic.START_SEARCH' | translate) }}</div>
    </ng-template>
    <!-- Template per i dati non trovati -->
    <ng-template class="europ" ng-notfound-tmp let-searchTerm="filter">
      <div class="ng-option disabled">
        <welion-dropdown-item class="full-width" [item]='noDataObject'>
        </welion-dropdown-item>
      </div>
    </ng-template>
    <!-- Template per il caricamento dei risultati -->
    <ng-template class="europ" ng-loadingtext-tmp>
      <div class="ng-option disabled">{{ loadingData || ('generic.LOADING' | translate) }}</div>
    </ng-template>
    <!-- Template per i singoli item nelle options -->
    <ng-template class="europ" ng-option-tmp let-item="item" let-search="filter">
      <welion-dropdown-item [notSeeDescription]="notSeeDescription" (click)="onOptionClicked($event, item.disabled)"
        class="full-width" [isCheckbox]="isCheckbox" [item]="item"></welion-dropdown-item>
    </ng-template>
  </ng-select>

  <div *ngIf="_isStructureSelect" fxFlexOffset="7px" class="red-bottom-border"></div>
</div>
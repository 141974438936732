import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { InfoVenue } from 'src/commonclasses';
import * as moment from 'moment';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'welion-radio',
  templateUrl: 'welion-radio.component.html',
  styleUrls: ['./welion-radio.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class WelionRadioComponent implements OnInit {
  // @Input() layout: string = "row"
  @Input() id?:string;
  @Input() items: any[] = [];
  @Input() withLabel: boolean = true;
  @Input() bindValue: any;
  @Input() disabled: boolean = false;
  @Input() readonly: boolean = false;
  @Input() isRow: boolean = false;

  _daySlot: boolean = false;
  @Input() set daySlot(value: boolean | string) {
    this._daySlot = coerceBooleanProperty(value);
  };

  _isVenue: boolean = false;
  @Input() set isVenue(value: boolean | string) {
    this._isVenue = coerceBooleanProperty(value);
  };

  _isAttendance: boolean = false;
  @Input() set isAttendance(value: boolean | string) {
    this._isAttendance = coerceBooleanProperty(value);
  };

  @Input() venueForJunior?: Date;

  @Output() onVenueDetail: EventEmitter<InfoVenue> = new EventEmitter();

  @Output('onChange') onItemChange: EventEmitter<any> = new EventEmitter();

  constructor(private cdr: ChangeDetectorRef,
    private translate: TranslateService) { }

  ngOnInit() {
    if(this._isAttendance) {
      for(let i = 0; i < this.items.length; i++) {
        let item = this.items[i];
        item.attrAriaLabel = '';
        if(item.attendance) {
          item.attrAriaLabel += moment(item.attendance.startDate).format('dddd EE MMMM HH:mm') + ' ' +
          moment(item.attendance.endDate).format('HH:mm');
          if(item.attendance.venue) {
            item.attrAriaLabel += ' ' + item.attendance.venue.name;
          }
          item.attrAriaLabel += ' Prenotazione per ' + (item.attendance.name + ' ' + item.attendance.surname)
          + ' ' + this.translate.instant('generic.VENUE_FORM');
          if(this.venueForJunior && item.attendance.venue) {
            if(item.disabled) {
              item.attrAriaLabel += ' ' + this.translate.instant('addReservation.steps.side.NOT_SELECTABLE');
            } else if(!item.disabled && item.vaccinationForMinorType) {
              item.attrAriaLabel += ' ' + this.translate.instant('addReservation.steps.side.' + item.vaccinationForMinorType);
            }
          }
        }
      }
    } else if(this._isVenue) {
      for(let i = 0; i < this.items.length; i++) {
        let item = this.items[i];
        item.attrAriaLabel = '';
        if(item.isPrivate) {
          item.attrAriaLabel += this.translate.instant('generic.VENUE');
        }
        item.attrAriaLabel += ' ' + item.venue.name;
        item.attrAriaLabel += ' ' + item.venue.address;
        if(item.venue.distance) {
          item.attrAriaLabel += ' ' + (item.venue.distance) + ' km';
        }
        item.attrAriaLabel += ' ' + this.translate.instant('generic.VENUE_FORM');
        if(this.venueForJunior) {
          if(item.disabled) {
            item.attrAriaLabel += ' ' + this.translate.instant('addReservation.steps.side.NOT_SELECTABLE');
          } else if(!item.disabled && item.vaccinationType) {
            item.attrAriaLabel += ' ' + this.translate.instant('addReservation.steps.side.' + item.vaccinationType);
          }
        }
      }} else if(this._daySlot) {
        for(let i = 0; i < this.items.length; i++) {
          let item = this.items[i];
          item.attrAriaLabel = '';
          item.attrAriaLabel += moment(item.date).format('dddd EE MMMM HH:mm');
          item.attrAriaLabel += ' ' + this.translate.instant(('addReservation.SEAT' + (item.unbookedSlotCount === 1 ? '' : 'S')), {value: item.unbookedSlotCount})
        }
      } else {
        for(let i = 0; i < this.items.length; i++) {
          let item = this.items[i];
          item.attrAriaLabel = '';
          if(this.withLabel) {
            item.attrAriaLabel += ' ' + this.translate.instant(item.value);
          }
        }
      }
    }
      
    
  

  setValue(item: any) {
    if ((!this._isVenue && !this._isAttendance) || ((this._isVenue || this._isAttendance) && item && !item.disabled)) {
      if (this._isVenue) {
        this.bindValue = item.venue;
      } else if (this._isAttendance) {
        this.bindValue = item.attendance;
      } else {
        this.bindValue = item;
      }
      this.cdr.detectChanges();
      this.emitOnItemChange();
    }
  }

  emitOnItemChange() {
    this.onItemChange.emit(this.bindValue);
  }

}

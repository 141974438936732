import { createReducer, on, Action } from '@ngrx/store';
import * as fromApp from '../../ngrx/app.reducers';
import * as StructureActions from './structure.actions';

export interface FeatureState extends fromApp.AppState {
    structure: StructureState
}

export interface StructureState {
}

// Store iniziale
const initialState: StructureState = {
};

export const createStructureReducer = createReducer(
    initialState
)

export function structureReducer(state: StructureState | undefined, action: Action) {
    return createStructureReducer(state, action);
}
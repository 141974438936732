import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';

import * as CoreActions from "../../../core/ngrx/core.actions";
import * as fromApp from "../../../ngrx/app.reducers";
import { InfoCampaign, InfoRound } from 'atfcore-commonclasses/bin/classes/campaignmanager';

import { RedirectService } from 'src/app/shared/services/redirect.service';
import { getModalMessageData, getWarningModal } from 'src/app/utils/utils';
import { ScrollTo } from 'src/app/core/services/scroll-to.service';
import { RentService } from 'src/app/structure/services/rent.service';
import { AnagService, UserGroupAdmin } from 'src/app/auth/services/anag.service';
import { ModalService } from 'src/app/shared/components/modal/modal.service';

@Component({
  selector: 'app-admin-campaign-details',
  templateUrl: './campaignDetails.component.html',
  styleUrls: ['./campaignDetails.component.scss']
})
export class AdminCampaignDetailsComponent implements OnInit, OnDestroy {

  campaignId: string = '';

  isLoading: boolean = true;
  isLoadingCount: boolean = true;
  isLoadingBase: boolean = true;

  campaign?: InfoCampaign;

  selectedTab: number = 0;

  campaignRounds: InfoRound[] = [];
  campaignRoundsCount: number = 0;

  campaignClients: any[] = [];
  campaignClientsCount: number = 0;

  readonly paginationLengthRounds: number = 5;
  readonly paginationLengthClients: number = 25;

  roundIdToDelete: string = '';

  private _unsubscribeSignal$: Subject<void> = new Subject();

  isLimitedAdmin: boolean = false;

  constructor(
    private route: ActivatedRoute,
    private scrollTo: ScrollTo,
    private appStore: Store<fromApp.AppState>,
    private translate: TranslateService,
    private rentService: RentService,
    private anagService: AnagService,
    private redirectService: RedirectService,
    private router: Router,
    private modalService: ModalService
  ) {

  }

  ngOnInit() {
    this.scrollTo.header();
    this.campaignId = this.route.snapshot.paramMap.get('campaignId') || '';

    if (this.redirectService.isThisCurrentPage('clients')) {
      this.selectedTab = 1
    }

    if (this.campaignId) {
      this.appStore.select(fromApp.getIsLimitedAdmin)
        .pipe(takeUntil(this._unsubscribeSignal$.asObservable()))
        .subscribe(
          (isLimitedAdmin) => {
            this.isLimitedAdmin = isLimitedAdmin;
          });

      this.getCampaign();
    } else {
      this.redirectService.goToHome();
    }
  }

  ngOnDestroy() {
    this._unsubscribeSignal$.next();
    this._unsubscribeSignal$.unsubscribe();
  }

  /**
   * @description Mostra una modale con titolo e messaggio
   * @param modalId
   * @param title
   * @param message
   */
  dispatchMessageModal(title: string, message: string) {
    const messageObj = getModalMessageData(this.translate, title, message);
    this.appStore.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
  }

  /**
   * @description Mostra una modale con un messaggio di errore
   * @param modalId
   * @param error
   */
  dispatchModal(modalId: string, error: string) {
    const messageObj = getWarningModal(this.translate, modalId, error);
    this.appStore.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
  }

  // Verifica se sono in una particolare pagina
  isThisCurrentPage(page: string): boolean {
    return page && this.router && this.router.url && this.router.url.indexOf(page) !== -1 ? true : false;
  }

  getCampaign() {

    this.rentService.getCampaign(this.campaignId)
      .pipe(takeUntil(this._unsubscribeSignal$.asObservable()))
      .subscribe((result) => {
        if (!result || result.error) {
          this.dispatchModal('100', result.error || '');
          this.resetPagination();
        } else {
          this.campaign = result.response;
          if (this.selectedTab === 0) {
            this.countCampaignRounds();
          } else {
            this.countCampaignClients();
          }
        }
        this.isLoadingBase = false;
      }, (err) => {
        if (err && err.message) {
          this.dispatchModal('101', err.message);
        }
        this.resetPagination();
      });
  }

  /**
   * @description Apre la modale per confermare la cancellazione della campagna
   */
  openDeleteCampaignModal() {
    this.modalService.open('deleteCampaign');
  }

  /**
   * @description Chiude la modale per confermare la cancellazione della campagna
   */
  closeDeleteCampaignModal() {
    this.modalService.close('deleteCampaign');
  }

  confirmDeleteCampaign() {
    this.isLoadingBase = true;
    this.isLoadingCount = true;

    this.rentService.deleteCampaignForAdmin(this.campaignId)
      .pipe(takeUntil(this._unsubscribeSignal$.asObservable()))
      .subscribe((result) => {
        this.closeDeleteCampaignModal();
        if (!result || result.error) {
          this.dispatchModal('200', result.error || '');
        } else {
          this.redirectService.goToAdminCampaigns();
        }
        this.isLoadingBase = false;
        this.isLoadingCount = false;
      }, (err) => {
        this.closeDeleteCampaignModal();
        if (err && err.message) {
          this.dispatchModal('201', err.message);
        }
        this.isLoadingBase = false;
        this.isLoadingCount = false;
      });
  }

  editCampaign() {
    this.redirectService.goToAdminNewCampaign(this.campaignId);
  }

  onTabClick(index: number) {
    this.selectedTab = index;

    this.resetPagination();
    if (this.selectedTab === 0) {
      this.redirectService.goToAdminCampaignTabRounds(this.campaignId, true);
      this.countCampaignRounds();
    } else {
      this.redirectService.goToAdminCampaignTabClients(this.campaignId, true);
      this.countCampaignClients();
    }
  }

  //#region Campaign rounds

  addNewCampaignRound() {
    this.redirectService.goToAdminNewCampaignRound(this.campaignId);
  }

  countCampaignRounds() {

    this.isLoadingCount = true;
    this.isLoading = true;

    this.rentService.countRoundByCampaignForAdmin(this.campaignId)
      .subscribe((result) => {

        if (!result || result.error) {
          this.dispatchModal('100', result.error || '');
          this.resetPagination();
        } else {
          this.campaignRoundsCount = result.response.roundCount;

          if (this.campaignRoundsCount > 0) {
            this.getCampaignRounds();
          } else {
            this.resetPagination();
          }
          this.isLoadingCount = false;
        }
      }, (err) => {
        if (err && err.message) {
          this.dispatchModal('101', err.message);
        }
        this.resetPagination();
      });
  }

  getCampaignRounds(index?: number) {

    this.isLoading = true;

    const pageSelectedIndex = index ? index : 0;
    if (index === 0 || index) {
      this.scrollTo.element('admin-campaign-details-anchor');
    }

    this.rentService.getRoundListByCampaignForAdmin(this.campaignId, (pageSelectedIndex * this.paginationLengthRounds), this.paginationLengthRounds)
      .pipe(takeUntil(this._unsubscribeSignal$.asObservable()))
      .subscribe((result) => {

        if (!result || result.error) {
          this.dispatchModal('102', result.error || '');
          this.resetPagination();
        } else {
          this.campaignRounds = result.response;
          this.isLoading = false;
        }
      }, (err) => {
        if (err && err.message) {
          this.dispatchModal('103', err.message);
        }
        this.resetPagination();
      });
  }

  //#endregion Campaign rounds

  //#region Campaign clients

  addNewCampaignClientAssociation() {
    this.redirectService.goToAdminAssignClientToCampaign(this.campaignId);
  }

  countCampaignClients() {

    this.isLoadingCount = true;
    this.isLoading = true;

    this.anagService.countCustomersForAdmin(undefined, this.campaignId)
      .subscribe((result) => {

        if (!result || result.error) {
          this.dispatchModal('100', result.error || '');
          this.resetPagination();
        } else {
          this.campaignClientsCount = result.response;

          if (this.campaignClientsCount > 0) {
            this.getCampaignClients();
          } else {
            this.resetPagination();
          }
          this.isLoadingCount = false;
        }
      }, (err) => {
        if (err && err.message) {
          this.dispatchModal('101', err.message);
        }
        this.resetPagination();
      });
  }

  getCampaignClients(index?: number) {

    this.isLoading = true;

    const pageSelectedIndex = index ? index : 0;
    if (index === 0 || index) {
      this.scrollTo.element('admin-campaign-details-anchor');
    }

    this.anagService.listCustomersForAdmin((pageSelectedIndex * this.paginationLengthClients), this.paginationLengthClients, undefined, this.campaignId)
      .pipe(takeUntil(this._unsubscribeSignal$.asObservable()))
      .subscribe((result) => {

        if (!result || result.error) {
          this.dispatchModal('102', result.error || '');
          this.resetPagination();
        } else {
          this.campaignClients = result.response;
          if (this.campaignClients && this.campaignClients.length) {
            let maxBookinCapString = 'MAX_BOOKING_CAP_';
            let campaignIdForCap = this.campaignId;
            campaignIdForCap = campaignIdForCap.replace(/-/g, '_');
            maxBookinCapString = maxBookinCapString + campaignIdForCap;
            for (let i = 0; i < this.campaignClients.length; i++) {
              let campaign = this.campaignClients[i];
              if (campaign.hasOwnProperty(maxBookinCapString)) {
                campaign.actualCap = campaign[maxBookinCapString];
              }
            }
          }
          this.isLoading = false;
        }
      }, (err) => {
        if (err && err.message) {
          this.dispatchModal('103', err.message);
        }
        this.resetPagination();
      });
  }

  clickCampaignClient(campaignClient: UserGroupAdmin) {
    this.redirectService.goToAdminCampaignClient(this.campaignId, campaignClient.groupId);
  }

  //#endregion Campaign clients

  resetPagination() {
    this.isLoading = false;
    this.isLoadingCount = false;
    this.isLoadingBase = false;

    this.campaignRounds = [];
    this.campaignRoundsCount = 0;

    this.campaignClients = [];
    this.campaignClientsCount = 0;
  }
}

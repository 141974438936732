import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { combineLatest, Observable, Subject } from 'rxjs';

import * as fromApp from "../../ngrx/app.reducers";
import * as CoreActions from "../../core/ngrx/core.actions";

import { User } from 'atfcore-commonclasses/bin/classes/anag';
import { CampaignManagerGetActiveAndNextRoundResponse, GetAllCampaignsSlotsData, InfoRound, InfoVenue } from 'atfcore-commonclasses/bin/classes/campaignmanager';
import { JwtPayload } from 'atfcore-commonclasses/bin/classes/auth';
import { SenecaResponse } from 'atfcore-commonclasses/bin/classes/common';

import { ModalService } from 'src/app/shared/components/modal/modal.service';
import { RedirectService } from 'src/app/shared/services/redirect.service';
import { ScrollTo } from 'src/app/core/services/scroll-to.service';
import { catchError, map, switchMap, takeUntil } from 'rxjs/operators';
import { RentService } from '../services/rent.service';
import { getWarningModal } from 'src/app/utils/utils';
import { DropdownItem } from 'src/app/shared/models/dropdown.model';

export interface HealthFacilityInfo {
  name?: string;
  description?: any;
  code?: string;
  address?: string;
  city?: string;
  province?: string;
  latitude?: string;
  longitude?: string;
  type?: string;
  note?: string;
}

@Component({
  selector: 'app-structure-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class StructureHomeComponent implements OnInit {
  loggedUser?: User;
  isImpersonate: boolean = false;
  updateUserAck$: any;
  userAcknowledges: any;

  totalReservedSlotCount: number = 0;
  totalOfferedSlotCount: number = 0;
  totalBookedSlotCount: number = 0;
  totalPresentPeopleCount: number = 0;

  activeRound?: InfoRound;
  nextRound?: InfoRound;

  private _unsubscribeSignal$: Subject<void> = new Subject();

  isLoadingBase: boolean = true;
  isLoading: boolean = true;

  //#region Structures

  private readonly allStructureValue: DropdownItem = {
    id: '-1',
    name: 'Tutte le sedi'
  };

  private _isEndSlotsLoading: boolean = false;
  private _firstLoad: boolean = true;

  private _structuresLoaded: number = 1;
  private _structureSearchedText: string = '';
  private _resetStructureResultList: boolean = false;

  disableStructureSelect: boolean = false;

  /**
   * When the list ended
   */
  private _isEndStructureList: boolean = false;

  isLoadingStructureList: boolean = false;
  structureList: DropdownItem[] = [this.allStructureValue];
  structureServive$: Subject<string> = new Subject<string>();
  selectedStructure?: DropdownItem = this.allStructureValue;
  venueId: string = '';

  isLoadingStructureInfo: boolean = true;
  structureInfo: HealthFacilityInfo = {};

  private _firstInit: boolean = true;

  //#endregion

  constructor(
    private route: ActivatedRoute,
    private appStore: Store<fromApp.AppState>,
    public translate: TranslateService,
    public modalService: ModalService,
    private scrollTo: ScrollTo,
    private rentService: RentService,
    public redirectService: RedirectService
  ) {
    const loggedUser$: Observable<JwtPayload> = this.appStore.select(fromApp.getLoggedUser);
    const userAck$: Observable<any> = this.appStore.select(fromApp.getUserAcknowledges);
    const isImpersonate$: Observable<any> = this.appStore.select(fromApp.getIsImpersonate);

    this.venueId = this.route.snapshot.paramMap.get('officeId') || '';

    combineLatest([loggedUser$, userAck$, isImpersonate$])
      .pipe(takeUntil(this._unsubscribeSignal$.asObservable()))
      .subscribe(
        ([loggedUser, userAck, isImpersonate]) => {
          if (loggedUser && loggedUser.user) {
            this.loggedUser = loggedUser.user;
            this.isImpersonate = isImpersonate;
            this.userAcknowledges = userAck;

            this.getStructureDatas(loggedUser);

            if (this._firstInit) {
              this._firstInit = false;
              this.initStructures();
              // Avvio una prima ricerca
              this.structureServive$.next('');
            }
          }
        });
  }

  getStructureDatas(jwtPayload: JwtPayload) {

    if (jwtPayload && jwtPayload.params && jwtPayload.params.supplier) {
      this.structureInfo.name = jwtPayload.params.supplier.supplierName || '';
      if (jwtPayload.params.supplier.supplierType === 'HEALTH_FACILITY_SUPPLIER') {
        this.structureInfo.type = 'structures.info.TYPE_STRUCTURE';
      } else {
        this.structureInfo.type = '';
      }

      const supplierAttributes: any[] = jwtPayload.params.supplier.supplierAttributes;
      if (supplierAttributes && supplierAttributes.length) {

        for (let i = 0; i < supplierAttributes.length; i++) {
          switch (supplierAttributes[i].attributeType) {
            case 'CODE':
              this.structureInfo.code = supplierAttributes[i].attributeValue || '';
              break;
            case 'CITY':
              this.structureInfo.city = supplierAttributes[i].attributeValue || '';
              break;
            case 'ADDRESS':
              this.structureInfo.address = supplierAttributes[i].attributeValue || '';
              break;
            case 'LONGITUDE':
              this.structureInfo.longitude = supplierAttributes[i].attributeValue || '';
              break;
            case 'LATITUDE':
              this.structureInfo.latitude = supplierAttributes[i].attributeValue;
              break;
            case 'PROVINCE':
              this.structureInfo.province = supplierAttributes[i].attributeValue;
              break;
            case 'TITLE':
              if (supplierAttributes[i].referenceType === 'NOTE') {
                this.structureInfo.note = supplierAttributes[i].attributeValue || '';
              }
              break;
          }
        }
      }
      this.isLoadingStructureInfo = false;
    }
  }

  ngOnInit() {
    this.scrollTo.header();
  }

  ngOnDestroy() {
    this._unsubscribeSignal$.next();
    this._unsubscribeSignal$.unsubscribe();
  }

  getDataCampaigns() {

    this.isLoading = true;

    this.rentService.getAllCampaignsSlotsData(this.venueId)
      .pipe(takeUntil(this._unsubscribeSignal$.asObservable()))
      .subscribe(
        (result: SenecaResponse<GetAllCampaignsSlotsData>) => {
          if (result && result.error) {
            this.dispatchModal('019', result.error);
            this.isLoadingBase = false;
            this.isLoading = false;
          } else {
            const response: GetAllCampaignsSlotsData = result.response;
            if (response) {
              this.totalBookedSlotCount = response.totalBookedSlotCount;
              this.totalOfferedSlotCount = response.totalOfferedSlotCount;
              this.totalPresentPeopleCount = response.totalPresentPeopleCount;
              this.totalReservedSlotCount = this.totalOfferedSlotCount - this.totalBookedSlotCount;
            }
            this.getActiveAndNextRound();
          }
        }, (err) => {
          if (err && err.message) {
            this.dispatchModal('020', err.message);
          }
          this.isLoadingBase = false;
          this.isLoading = false;
        });
  }

  getActiveAndNextRound() {
    this.rentService.getActiveAndNextRound(this.venueId)
      .pipe(takeUntil(this._unsubscribeSignal$.asObservable()))
      .subscribe(
        (result: SenecaResponse<CampaignManagerGetActiveAndNextRoundResponse>) => {
          if (result && result.error) {
            this.dispatchModal('021', result.error);
          } else {
            if (result.response) {
              this.activeRound = result.response.activeRound;
              this.nextRound = result.response.nextRound;
            }
          }
          this.isLoading = false;
          this.isLoadingBase = false;
        }, (err) => {
          if (err && err.message) {
            this.dispatchModal('022', err.message);
          }
          this.isLoading = false;
          this.isLoadingBase = false;
        });
  }

  // Porta alla pagina con le campagne attive
  goToActiveCampaigns() {
    if (this.selectedStructure && this.selectedStructure.id !== '-1') {
      this.redirectService.goToActiveCampaigns(this.selectedStructure.id);
    } else {
      this.redirectService.goToActiveCampaigns();
    }
  }

  goToRoundSlots() {
    this.redirectService.goToRoundSlots('123456789');
  }

  // TEST
  // resetOnboardingAck() {
  //   if (this.updateUserAck$) {
  //     this.updateUserAck$.unsubscribe();
  //   }
  //   let acks = JSON.parse(JSON.stringify(this.userAcknowledges));
  //   acks.data['CAMPAIGN_MANAGER_PRIVACY_ACCEPTED'] = null
  //   this.updateUserAck$ = this.anagService.updateUserAcknowledges(acks)
  //     .subscribe((data: any) => {
  //       if (data && data.error) {
  //         const messageObj: ApplicationModalMessage = {
  //           modalId: "096",
  //           text: this.translate.instant("errors." + data.error),
  //           title: this.translate.instant("generic.WARNING")
  //         }
  //         this.appStore.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
  //       } else {
  //         window.location.reload();
  //       }
  //     })
  // }

  dispatchModal(modalId: string, error: string) {
    const messageObj = getWarningModal(this.translate, modalId, error);
    this.appStore.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
  }

  //#region Structures

  /**
   * @description Carica le strutture e imposta per l'infinite scroll
   */
  initStructures() {
    this.structureList = this.structureList || [this.allStructureValue];
    this.structureServive$.pipe(
      switchMap(
        (text: string) => {
          if (text !== this._structureSearchedText) {
            this._resetStructureResultList = true;
            this._structuresLoaded = 1;
          } else {
            this._resetStructureResultList = false;
          }
          this._structureSearchedText = text || '';
          this.isLoadingStructureList = true;
          return this.getVenueList((this._structuresLoaded - 1), 10, this._structureSearchedText);
        }
      ),
      catchError((err, caught) => {
        return caught;
      })
    )
      .subscribe({
        next: (eventData: Array<DropdownItem>) => {
          if (this._resetStructureResultList) {
            this.clearStructureResults();
          }
          if (eventData && eventData.length) {
            this._structuresLoaded += eventData.length;
            this.structureList = this.structureList.concat(eventData);
            if (this._structuresLoaded === 2 && !this._structureSearchedText) {
              this.disableStructureSelect = true;
              this.selectedStructure = this.structureList[1];
            }
            if (this._firstLoad) {
              if (this.venueId) {
                for (let i = 0; i < this.structureList.length; i++) {
                  if (this.structureList[i].id === this.venueId) {
                    this.onStructureSelected(this.structureList[i]);
                    break;
                  }
                }
              } else {
                this.onStructureSelected(this.structureList[0]);
              }
            }
          } else {
            // Nessun struttura
            this.isLoadingBase = false;
            this.isLoading = false;
          }

          this.isLoadingStructureList = false;
        },
        error: (err: any) => {
          this.isLoadingStructureList = false;
          this._isEndSlotsLoading = true;
        }
      });
  }

  /**
   * @description Carica e prepara gli elementi per la select con infinite scroll
   * @param fromRecord
   * @param numRecords
   * @param searchedText
   */
  getVenueList(fromRecord: number, numRecords: number, searchedText: string): Observable<DropdownItem[]> {
    return this.rentService.getVenueList('', fromRecord, numRecords, searchedText)
      .pipe(
        takeUntil(this._unsubscribeSignal$.asObservable()),
        map((resultData: SenecaResponse<InfoVenue[]>) => {
          if (resultData.error) {
            this.dispatchWarningModal('007', resultData.error);
          } else if (resultData.response && resultData.response.length) {
            let formattedData: DropdownItem[] = [];

            resultData.response.forEach(structure => {
              let title = structure.name + ' - ' + structure.address;

              formattedData.push({
                id: structure.venueId,
                name: title,
                content: structure
              });
            });

            // Caricando n elementi per volta (itemsToLoad) se
            // ne carica di meno significa che sono a fine lista
            if (resultData.response.length < numRecords) {
              this._isEndStructureList = true;
            }

            return formattedData;
          }
          this._isEndStructureList = true;
          return [];
        })
      );
  }

  /**
   * @description Alla selezione di una struttura
   * @param structure
   */
  onStructureSelected(structure?: DropdownItem) {
    this.selectedStructure = structure;
    if (this.selectedStructure) {
      if (this.selectedStructure.id === '-1') {
        this.venueId = '';
      } else {
        this.venueId = (this.selectedStructure.content as InfoVenue).venueId;
      }
      this.getDataCampaigns();
    }
    // this.getStructureDaysSlots();
  }

  /**
   * @description Chiamata quando viene effettuata una nuova ricerca differente
   */
  clearStructureResults(): void {
    this._structuresLoaded = 0;
    this.structureList = [];
    this._resetStructureResultList = false;
  }

  /**
   * @description Chiamata allo scroll
   * @param scrollEvent
   */
  fetchMoreStructures(scrollEvent: { start: number; end: number }) {
    if (!this._isEndStructureList && scrollEvent.end === this._structuresLoaded) {
      this.structureServive$.next(this._structureSearchedText);
    }
  }

  //#endregion Structures

  /**
   * @description Mostra una modale con un messaggio di errore
   * @param modalId
   * @param error
   */
  dispatchWarningModal(modalId: string, error: string) {
    const messageObj = getWarningModal(this.translate, modalId, error);
    this.appStore.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
  }

}

<!-- <div fxLayout="row" class="picker-container" fxLayoutAlign="start center" [ngClass]="{
  'disabled': disabled,
  'with-transparency': _withTransparency}">

  <input #datePickerInput class="datetimepicker-input" attr.aria-label="{{ placeholder | translate }}"
    [owlDateTime]="dt2" [disabled]="disabled" placeholder="{{ placeholder | translate }}" [ngModel]="value"
    [min]="minDate || newDate" (ngModelChange)="updateModelFromInputvalue(datePickerInput);"
    (dateTimeChange)="updateModel(this, $event);" [max]="maxDate"  tabindex="-1" >

  <button [owlDateTimeTrigger]="dt2" [disabled]="disabled"  clickKeyBoard (onEnterKeyboard)="updateModel(this, $event);">
    <svg-icon src="../../../assets/img/icons/calendar.svg"></svg-icon>
  </button>

  <owl-date-time class="date-picker" #dt2 [firstDayOfWeek]="options.firstDayOfWeek" [disabled]="disabled"
    [pickerMode]="pickerMode" [pickerType]="pickerType" (afterPickerClosed)="cleanOpenedStyle()"></owl-date-time>
</div> -->
<mat-form-field appearance="outline">
  <input [attr.aria-label]="attrAriaLabel" tabindex="-1" matInput [min]="minDate || newDate"
  [id]="id" [attr.name]="id" [tabindex]="tabIndex"
  [attr.aria-labelledby]="ariaLabelledbyId ? ('welion-input-label-' + ariaLabelledbyLabel + ' ' + ariaLabelledbyId) : ('welion-input-label-' + ariaLabelledbyLabel)"
  [attr.aria-required]="required" [required]="required" [attr.aria-disabled]="disabled"
  [max]="maxDate" [matDatepicker]="datePickerInput" [(ngModel)]="value" (ngModelChange)="updateModel(this, $event);">
  <mat-datepicker-toggle matSuffix [for]="datePickerInput" ></mat-datepicker-toggle>
  <mat-datepicker #datePickerInput></mat-datepicker>
</mat-form-field>
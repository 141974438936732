<div class="generic-row-wrapper" fxLayout="row" fxLayoutAlign="center center">

  <!-- Checkbox -->
  <div [id]="id" role="checkbox" [attr.aria-checked]="checked" [attr.aria-label]="label || customLabel || attrAriaLabel"
    class="welion-check-wrapper" [ngClass]="{'disabled': disabled, 'readonly': readonly}" fxLayout="row"
    fxLayoutAlign="center center" tabindex="0" (click)="emitOnCheck()" clickKeyBoard (onEnterKeyboard)="emitOnCheck()">
    <svg-icon *ngIf="checked" src="assets/img/icons/check.svg" class="checkmark" [applyClass]="true"></svg-icon>
  </div>

  <!-- Label -->
  <label tabindex="0" fxFlexOffset="8px" class="label-text" *ngIf="label && !customLabel">{{ label }}</label>

  <!-- Custom label -->
  <ng-container *ngIf="customLabel" [ngTemplateOutlet]="customLabel">
  </ng-container>

</div>

import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { RedirectService } from '../../services/redirect.service';

export type NavigationDirection =
  null |
  'ACTIVE_CAMPAIGNS' |
  'ROUND' |
  'CAMPAIGNS' |
  'ADMIN_CAMPAIGNS' |
  'SLOT_TO_APPROVE' |
  'SLOT_TO_REJECT' |
  'DETAILS_TO_APPROVE' |
  'LOADING' |
  'HEALTH_FACILITIES' |
  'CLIENTS' |
  'PENDING_REQUESTS' |
  'FAQ';

@Component({
  selector: 'bread-crumbs',
  templateUrl: 'bread-crumbs.component.html',
  styleUrls: ['./bread-crumbs.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class BreadCrumbsComponent {

  @Input() idReference?: string;

  @Input() firstLevel: NavigationDirection = null;

  @Input() customFirstLevel?: string;

  @Input() secondLevel?: string;

  @Input() thirdLevel?: string;

  @Input() venueId?: string;

  @Output() firstCustomLevelClick = new EventEmitter<void>();
  @Output() secondLevelClick = new EventEmitter<void>();

  constructor(private redirectService: RedirectService) {

  }

  goToHome() {
    this.redirectService.goToHome(this.venueId);
  }

  goToFirstLevel() {
    if (this.customFirstLevel) {
      this.firstCustomLevelClick.emit();
    } else if (this.secondLevel) {
      switch (this.firstLevel) {
        case 'ACTIVE_CAMPAIGNS':
          this.redirectService.goToActiveCampaigns(this.venueId);
          break;
        case 'ROUND':
          this.redirectService.goToCampaignDetails(this.idReference || '', this.venueId);
          break;
        case 'HEALTH_FACILITIES':
          this.redirectService.goToAdminHealthFacilities();
          break;
        case 'CLIENTS':
          this.redirectService.goToAdminClients();
          break;
        case 'ADMIN_CAMPAIGNS':
          this.redirectService.goToAdminCampaigns();
          break;
        case 'CAMPAIGNS':
        case 'SLOT_TO_APPROVE':
          this.redirectService.goToHome();
          break;
        case 'PENDING_REQUESTS':
          this.redirectService.goToAdminPendingRequests();
          break;
        default:
          this.redirectService.goToHome();
          break;
      }
    }
  }

  goToSecondLevel() {
    if (this.thirdLevel) {
      this.secondLevelClick.emit();
    }
  }

}

import { NgModule } from '@angular/core';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { AuthInterceptor } from '../shared/interceptors/auth.interceptor';

import { ClientRoutingModule } from './client-routing.module';
import { SharedModule } from '../shared/shared.module';
import { CoreModule } from '../core/core.module';
import { NgxPaginationModule } from 'ngx-pagination';

import { clientReducer } from './ngrx/client.reducers';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { ClientEffects } from './ngrx/client.effects';
import { RentService } from '../structure/services/rent.service';
import { ClientService } from '../shared/services/client.service';

import { ImportService } from '../structure/services/import.service';

import { ClientComponent } from './client.component';
import { ClientRoundDetailsComponent } from './clientRoundDetails/clientRoundDetails.component';
import { ClientHomeComponent } from './clientHome/clientHome.component';
import { ExportService } from '../structure/services/export.service';
import { ClientLandingComponent } from './clientLanding/clientLanding.component';
import { EngagementService } from '../admin/services/engagement.service';

@NgModule({
  declarations: [
    ClientComponent,
    ClientLandingComponent,
    ClientHomeComponent,
    ClientRoundDetailsComponent
  ],
  imports: [
    FormsModule,
    CommonModule,
    ReactiveFormsModule,
    ClientRoutingModule,
    SharedModule,
    CoreModule,
    NgxPaginationModule,
    StoreModule.forFeature('client', clientReducer),
    EffectsModule.forFeature([ClientEffects])
  ],
  providers: [
    ClientService,
    ImportService,
    RentService,
    ExportService,
    ImportService,
    EngagementService,
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true }
  ]
})
export class ClientModule { }

<div class="page-content-wrapper" fxLayout="column">

  <!-- Campagna -->
  <h1 fxFlexOffset="40px" class="primary">
    {{ 'admin.campaignDetails.TITLE' | translate: { value: campaign ? campaign.title : '' } }}
  </h1>

  <bread-crumbs fxFlexOffset="12px" firstLevel="ADMIN_CAMPAIGNS"
    [secondLevel]="campaign ? campaign.title : ('admin.campaignDetails.TITLE2' | translate)">
  </bread-crumbs>

  <div fxFlexOffset="40px" class="campaign-resume" fxLayout="row" fxLayoutAlign="space-between center">
    <svg-icon class="note" [applyClass]="true" src="assets/img/icons/note-health-facility-yellow.svg"></svg-icon>

    <div fxLayout="column" fxFlexOffset="100px">
      <ng-container *ngIf="!isLoadingBase && campaign">
        <h5 class="bold" translate="admin.campaignDetails.CAMPAIGN"></h5>
        <h5>{{ campaign.title }}</h5>
      </ng-container>
      <div *ngIf="isLoadingBase || !campaign" class="loader spinner-small"></div>
    </div>

    <div fxLayout="row">
      <!-- Elimina campagna -->
      <welion-button *ngIf="!isLimitedAdmin" width="112px" size="medium" type="secondary"
        [label]="'generic.DELETE' | translate | uppercase" (onClick)="openDeleteCampaignModal()"
        [disabled]="isLoadingBase">
      </welion-button>
      <!-- Modifica campagna -->
      <welion-button *ngIf="!isLimitedAdmin" fxFlexOffset="8px" width="112px" size="medium" type="primary"
        [label]="'generic.EDIT' | translate | uppercase" (onClick)="editCampaign()" [disabled]="isLoadingBase">
      </welion-button>
    </div>

  </div>

  <div id="admin-campaign-details-anchor" fxFlexOffset="20px"></div>

  <div fxFlexOffset="20px" class="campaign-details-container" fxLayout="column">

    <div class="campaign-details-tabs" fxLayout="row">
      <!-- Round -->
      <tab-light [isCurrentTab]="selectedTab === 0" (onClick)="onTabClick(0)"
        [tabTitle]="'admin.campaignDetails.tab.rounds.TITLE' | translate"
        [attrAriaLabel]="'admin.campaignDetails.tab.rounds.TITLE' | translate">
      </tab-light>

      <!-- Clienti -->
      <tab-light fxFlexOffset="32px" [isCurrentTab]="selectedTab === 1"
        [tabTitle]="'admin.campaignDetails.tab.clients.TITLE' | translate"
        [attrAriaLabel]="'admin.campaignDetails.tab.clients.TITLE' | translate" (onClick)="onTabClick(1)">
      </tab-light>
    </div>

    <!-- Round -->
    <ng-container *ngIf="selectedTab === 0">

      <ng-container *ngIf="!isLoadingCount">
        <ng-container *ngIf="campaignRoundsCount > 0 else noRounds">

          <div fxFlexOffset="32px" fxLayout="row" fxLayoutAlign="space-between center">
            <!-- 4 round -->
            <h3 fxFlexOffset="16px">
              <ng-container *ngIf="campaignRoundsCount === 1">
                {{ 'admin.campaignDetails.tab.rounds.ROUND_TITLE' | translate }}
              </ng-container>
              <ng-container *ngIf="campaignRoundsCount !== 1">
                {{ 'admin.campaignDetails.tab.rounds.ROUNDS_TITLE' | translate: { value: campaignRoundsCount || 0 }
                }}
              </ng-container>
            </h3>

            <div fxLayout="row">
              <!-- Crea nuovo round -->
              <welion-button *ngIf="!isLimitedAdmin" width="212px" size="medium" type="primary"
                [label]="'admin.campaignDetails.tab.rounds.NEW_ROUND' | translate | uppercase"
                (onClick)="addNewCampaignRound()" [disabled]="isLoading">
              </welion-button>
            </div>
          </div>

          <!-- Loader -->
          <div *ngIf="isLoading" class="loader spinner-big"></div>

          <div *ngIf="!isLoading" fxLayout="column" fxLayoutAlign="start center">
            <card-round adminMood *ngFor="let round of campaignRounds" [campaignId]="campaignId" [round]="round"
              fxFlexOffset="24px">
            </card-round>
          </div>

          <!-- Paginatore -->
          <welion-paginator fxFlexOffset="20px" [isLoading]="isLoading" [paginationLength]="paginationLengthRounds"
            [itemsCount]="campaignRoundsCount" (onClickPage)="getCampaignRounds($event)"></welion-paginator>

        </ng-container>
      </ng-container>

      <div *ngIf="isLoadingCount" class="loader spinner-big"></div>

    </ng-container>

    <!-- Clienti -->
    <ng-container *ngIf="selectedTab === 1">

      <ng-container *ngIf="!isLoadingCount">
        <ng-container *ngIf="campaignClientsCount > 0 else noClients">

          <div fxFlexOffset="32px" fxLayout="row" fxLayoutAlign="space-between center">
            <!-- 4 clienti -->
            <h3 fxFlexOffset="16px">
              <ng-container *ngIf="campaignClientsCount === 1">
                {{ 'admin.campaignDetails.tab.clients.table.CLIENT_TITLE' | translate }}
              </ng-container>
              <ng-container *ngIf="campaignClientsCount !== 1">
                {{ 'admin.campaignDetails.tab.clients.table.CLIENTS_TITLE' | translate:
                { value: campaignClientsCount || 0 }
                }}
              </ng-container>
            </h3>
            <!-- Assegna nuovo cliente -->
            <welion-button *ngIf="!isLimitedAdmin" width="232px" size="medium" type="primary" [disabled]="isLoading"
              [label]="'admin.campaignDetails.tab.clients.ASSIGN_CLIENT' | translate | uppercase"
              (onClick)="addNewCampaignClientAssociation()">
            </welion-button>
          </div>

          <!-- Table header -->
          <div fxFlexOffset="24px">
            <!-- Cliente -->
            <h5 class="bold" translate="admin.campaignDetails.tab.clients.table.CLIENT" fxFlex fxFlexOffset="16px"></h5>
            <!-- Strutture Dedicate -->
            <h5 class="bold margin-right5" translate="admin.campaignDetails.tab.clients.table.DEDICATED_FACILITIES"
              fxFlex="8"></h5>
            <!-- Aventi diritto Totale -->
            <h5 class="bold margin-right5" translate="admin.campaignDetails.tab.clients.table.TOTAL_ENTITLEDS"
              fxFlex="8"></h5>
            <!-- Aventi Diritto Registrati -->
            <h5 class="bold margin-right5" translate="admin.campaignDetails.tab.clients.table.REGISTERED_ENTITLEDS"
              fxFlex="8"></h5>
            <!-- Aventi diritto Prenotati -->
            <h5 class="bold margin-right5" translate="admin.campaignDetails.tab.clients.table.ASSIGNED_ENTITLEDS"
              fxFlex="8"></h5>
            <!-- Aventi diritto Reportizzati -->
            <h5 class="bold margin-right5" translate="admin.campaignDetails.tab.clients.table.PROCESSED_ENTITLEDS"
              fxFlex="8"></h5>
            <!-- Aventi diritto Non processati -->
            <h5 class="bold margin-right5" translate="admin.campaignDetails.tab.clients.table.NOT_ASSIGNED_ENTITLEDS"
              fxFlex="8"></h5>
            <!-- Aventi diritto Reportizzati -->
            <h5 class="bold margin-right5" translate="admin.campaignDetails.tab.clients.table.PRESENT_ENTITLEDS"
              fxFlex="8"></h5>
            <!-- Cap prenotazioni -->
            <h5 class="bold margin-right5" translate="admin.campaignDetails.tab.clients.table.PRENOTATIONS_CAP"
              fxFlex="8"></h5>
            <!-- Prenotazioni over CAP -->
            <h5 class="bold margin-right5" translate="admin.campaignDetails.tab.clients.table.PRENOTATIONS_OVER_CAP"
              fxFlex="8"></h5>

            <div *ngIf="!isLimitedAdmin" fxFlex="5"></div>
          </div>

          <!-- Loader -->
          <div *ngIf="isLoading" class="loader spinner-big"></div>

          <ng-container *ngIf="!isLoading">
            <!-- Table rows -->
            <div *ngFor="let campaignClient of campaignClients; let index = index; let odd = odd" class="campaign-row"
              [ngClass]="{'odd': odd}" fxLayout="row" fxLayoutAlign="start center"
              [fxFlexOffset]="index === 0 ? '16px' : '0px'">
              <!-- Cliente -->
              <h6 class="black" fxFlex fxFlexOffset="16px">{{ campaignClient.title }}</h6>
              <!-- Strutture Dedicate -->
              <h6 class="black margin-right5" fxFlex="8">{{ campaignClient.privateVenuecount || 0 }}</h6>
              <!-- Aventi diritto Totale -->
              <h6 class="black margin-right5" fxFlex="8">{{ campaignClient.totalAttendancesInGroup || 0 }}</h6>
              <!-- Aventi Diritto Registrati -->
              <h6 class="black margin-right5" fxFlex="8">{{ campaignClient.registeredUsersCount || 0 }}</h6>
              <!-- Aventi diritto Prenotati -->
              <h6 class="black margin-right5" fxFlex="8">{{ campaignClient.assignedAttendanceCount || 0 }}</h6>
              <!-- Aventi diritto Reportizzati -->
              <h6 class="black margin-right5" fxFlex="8">{{ campaignClient.processedAttendanceCount || 0 }}</h6>
              <!-- Aventi diritto non processati -->
              <h6 class="black margin-right5" fxFlex="8">{{ campaignClient.nonProcessedAttendanceCount || 0 }}</h6>
              <!-- Aventi diritto Presenti -->
              <h6 class="black margin-right5" fxFlex="8">{{ campaignClient.presentAttendancesCount || 0 }}</h6>
              <!-- CAP prenotazioni -->
              <h6 class="black margin-right5" fxFlex="8">{{ campaignClient.actualCap || '-' }}</h6>
              <!-- Prenotazioni over CAP -->
              <h6 class="black margin-right5" fxFlex="8">{{ campaignClient.exceedingAD || '-' }}</h6>

              <div *ngIf="!isLimitedAdmin" fxFlex="5" fxLayout="row" fxLayoutAlign="end">
                <svg-icon fxFlexOffset="20px" src="assets/img/icons/arrow-right-red.svg" class="cursor-pointer"
                  [applyClass]="true" (click)="clickCampaignClient(campaignClient)">
                </svg-icon>
                <div fxFlex="16px"></div>
              </div>
            </div>
          </ng-container>

          <!-- Paginatore -->
          <welion-paginator fxFlexOffset="20px" [isLoading]="isLoading" [paginationLength]="paginationLengthClients"
            [itemsCount]="campaignClientsCount" (onClickPage)="getCampaignClients($event)"></welion-paginator>

        </ng-container>
      </ng-container>

      <div *ngIf="isLoadingCount" class="loader spinner-big"></div>

    </ng-container>

  </div>

</div>

<ng-template #noRounds>
  <div fxLayout="column" fxLayoutAlign="start center">
    <svg-icon fxFlexOffset="40px" [applyClass]="true" src="assets/img/icons/note-health-facility-red.svg"></svg-icon>

    <!-- Inserisci i round in questa campagna -->
    <h3 fxFlexOffset="32px" translate="admin.campaignDetails.tab.rounds.NO_ROUNDS"></h3>

    <welion-button fxFlexOffset="32px" width="232px" size="large" type="primary"
      [label]="'admin.campaignDetails.tab.rounds.ADD_ROUND' | translate | uppercase" (onClick)="addNewCampaignRound()">
    </welion-button>
  </div>
</ng-template>

<ng-template #noClients>
  <div fxLayout="column" fxLayoutAlign="start center">
    <svg-icon fxFlexOffset="40px" [applyClass]="true" src="assets/img/icons/note-health-facility-red.svg"></svg-icon>

    <!-- Inserisci i clienti per questa campagna -->
    <h3 fxFlexOffset="32px" translate="admin.campaignDetails.tab.clients.NO_CLIENTS"></h3>

    <welion-button fxFlexOffset="32px" width="240px" size="large" type="primary"
      [label]="'admin.campaignDetails.tab.clients.ASSIGN_CLIENT' | translate | uppercase"
      (onClick)="addNewCampaignClientAssociation()">
    </welion-button>
  </div>
</ng-template>

<!-- Modale per la cancellazione della campagna -->
<modal id="deleteCampaign" (onClose)="closeDeleteCampaignModal()" (onCancel)="closeDeleteCampaignModal()"
  (onConfirm)="confirmDeleteCampaign()" [cancelLabel]="'generic.CLOSE' | translate | uppercase"
  [confirmLabel]="'generic.CONFIRM' | translate | uppercase" [disableAll]="isLoadingBase">
  <modal-text-content [withScrolledBody]="true" [title]="'admin.campaignDetails.deleteCampaign.TITLE' | translate"
    [text]="'admin.campaignDetails.deleteCampaign.RESET_WARNING' | translate"
    [subtitle]="'admin.campaignDetails.deleteCampaign.SUBTITLE' | translate">
  </modal-text-content>
</modal>
import { NgModule } from '@angular/core';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { AuthInterceptor } from '../shared/interceptors/auth.interceptor';
import { UserComponent } from './user.component';
import { UserRoutingModule } from './user-routing.module';
import { SharedModule } from '../shared/shared.module';
import { CoreModule } from '../core/core.module';
import { NgxPaginationModule } from 'ngx-pagination';
import { UserHomeComponent } from './home/home.component';
import { userReducer } from './ngrx/user.reducers';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { UserEffects } from './ngrx/user.effects';
import { UserService } from '../shared/services/user.service';
import { RentService } from '../structure/services/rent.service';
import { UserAddReservationComponent } from './addReservation/addReservation.component';
import { UserAddFamiliarComponent } from './addFamiliar/addFamiliar.component';
import { A11yModule } from '@angular/cdk/a11y';

@NgModule({
  declarations: [
    UserComponent,
    UserHomeComponent,
    UserAddReservationComponent,
    UserAddFamiliarComponent
  ],
  imports: [
    A11yModule,
    FormsModule,
    CommonModule,
    ReactiveFormsModule,
    UserRoutingModule,
    SharedModule,
    CoreModule,
    NgxPaginationModule,
    StoreModule.forFeature('user', userReducer),
    EffectsModule.forFeature([UserEffects])
  ],
  providers: [
    UserService,
    RentService,
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true }
  ]
})
export class UserModule { }

import { createReducer, on, Action } from '@ngrx/store';
import * as fromApp from '../../ngrx/app.reducers';

export interface FeatureState extends fromApp.AppState {
    user: UserState
}

export interface UserState {
}

// Store iniziale
const initialState: UserState = {
};


export const createUserReducer = createReducer(
    initialState
)

export function userReducer(state: UserState | undefined, action: Action) {
    return createUserReducer(state, action);
}
import { Component, EventEmitter, Input, OnInit, Output, ChangeDetectionStrategy } from '@angular/core';

@Component({
  selector: 'welion-paginator',
  templateUrl: 'welion-paginator.component.html',
  styleUrls: ['./welion-paginator.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class WelionPaginatorComponent implements OnInit {

  @Input() selectedIndex: number = 0;

  _itemsCount: number = 0;
  @Input() set itemsCount(value: number) {
    this._itemsCount = value;
    if (this._itemsCount > 0 && this.paginationLength > 0) {
      this._totalPages = Math.ceil(this._itemsCount / this.paginationLength);
    } else {
      this._totalPages = 1;
    }
    this.setPaginationPages();
  }

  @Input() paginationLength: number = 25;

  @Input() isLoading: boolean = true;

  @Output() onClickPage: EventEmitter<number> = new EventEmitter();

  _paginationPages: number[] = [0];
  _totalPages: number = 0;

  constructor() { }

  ngOnInit() { }

  /**
   * 7 pagine
   * prima e ultima sempre
   * < (1)  2   3   4    5     5    300  >
   * <  1  (2)  3   4    5     5    300  >
   * <  1   2  (3)  4    5     5    300  >
   * <  1   2   3  (4)   5     6    300  >
   * <  1   3   4  (5)   6     7    300  >
   * <  1   3   4   5  (298)  299   300  >
   * <  1  295 296 297  298  (299)  300  >
   * <  1  295 296 297  298   299  (300) >
   */
  setPaginationPages(selectedIndex?: number) {
    this.selectedIndex = selectedIndex || 0;
    this._paginationPages = [];
    if (this._itemsCount > 0) {

      // Inizializzo il dato per la select
      // Calcolo il numero di pagine
      if (this._totalPages > 7) {
        const lastPage = this._totalPages - 1;
        // Prima pagina
        this._paginationPages.push(0);

        // 5 pagine centrali
        for (let i = 0; i < 5; i++) {
          if (this.selectedIndex < 3) {
            // Prime pagine 1, 2, 3, 4, 5
            this._paginationPages.push(i + 1);
          } else if (this.selectedIndex > (lastPage - 3)) {
            // Ultime pagine lp - 5, lp -4, lp - 3, lp - 2, lp - 1
            this._paginationPages.push(lastPage + i - 5);
          } else {
            // Pagine vicine -2, -1, x, +1, +2
            this._paginationPages.push(this.selectedIndex + i - 2);
          }
        }
        // Ultima pagina
        this._paginationPages.push(lastPage);
      } else {
        for (let i = 0; (i * this.paginationLength) < this._itemsCount; i++) {
          this._paginationPages.push(i);
        }
      }

    } else {
      this._paginationPages = [0];
    }
  }

  goToLeft() {
    if (!this.isLoading && this.selectedIndex > 0) {
      this.selectedIndex--;
      this.goToPage(this.selectedIndex);
    }
  }

  goToRight() {
    if (!this.isLoading && this.selectedIndex < (this._totalPages - 1)) {
      this.selectedIndex++;
      this.goToPage(this.selectedIndex);
    }
  }

  goToPage(index: number) {
    if (!this.isLoading) {
      this.setPaginationPages(index);
      this.onClickPage.emit(index);
    }
  }

}

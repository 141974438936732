<div class="page-content-wrapper" fxLayout="column">

  <!-- Campagna Antinfluenzale 2021 -->
  <h1 *ngIf="!isLoadingBase && campaign" fxFlexOffset="40px" class="primary">
    {{ campaign.title }}
  </h1>
  <h1 *ngIf="isLoadingBase || !campaign" fxFlexOffset="40px" class="primary" translate="generic.LOADING"></h1>

  <bread-crumbs *ngIf="campaign && round" fxFlexOffset="12px" [customFirstLevel]="!isLoadingBase && round ? ('client.round.BREAD_DATE' | translate: {
      date1: round.startDate | date: 'dd MMMM yyyy' | titlecase,
      date2: round.endDate | date: 'dd MMMM yyyy' | titlecase
    }) : undefined">
  </bread-crumbs>

  <div fxFlexOffset="14px" fxFlexOffset.xs="12px" class="campaigns-container" fxLayout="column">

    <div class="campaign-details-tabs" fxLayout="row">
      <!-- Aventi diritto -->
      <tab-light [isDisabled]="isLoadingCount" [isCurrentTab]="selectedTab === 0" (onClick)="onTabClick(0)"
        [tabTitle]="'client.ENTITLEDS' | translate" [attrAriaLabel]="'client.ENTITLEDS' | translate">
      </tab-light>

      <!-- Scarti -->
      <tab-light [isDisabled]="isLoadingCount" fxFlexOffset="32px" [isCurrentTab]="selectedTab === 1"
        [tabTitle]="'client.SCRAPS' | translate" [attrAriaLabel]="'client.SCRAPS' | translate"
        (onClick)="onTabClick(1)">
      </tab-light>
    </div>

    <div fxFlexOffset="26px" fxLayout="row" fxLayoutAlign="space-between">
      <!-- Ricerca per utente -->
      <welion-input *ngIf="selectedTab === 0" withTransparency fxFlex="220px" [bindValue]="searchText"
        (onChange)="searchTextChange($event)" (onIconClicked)="searchEntitled()"
        [placeholder]="'client.round.SEARCH_ENTITLED_PLACAHOLDER' | translate" [disabled]="isLoading"
        iconRight="assets/img/icons/icon-search.svg">
      </welion-input>

      <welion-input *ngIf="selectedTab === 1" withTransparency fxFlex="220px" [bindValue]="searchTextScraps"
        (onChange)="searchTextChangeScraps($event)" (onIconClicked)="searchEntitledScraps()"
        [placeholder]="'client.round.SEARCH_ENTITLED_PLACAHOLDER' | translate" [disabled]="isLoading"
        iconRight="assets/img/icons/icon-search.svg">
      </welion-input>

      <div fxLayout="row">
        <!-- Scarica tracciato -->
        <welion-button *ngIf="selectedTab === 0" width="200px" label="client.round.DOWNLOAD_REPORT" type="third"
          size="medium" (onClick)="exportEntitledsList()"
          [disabled]="!hasCountAtLeastOne && (isLoading || entitleCount === 0)">
        </welion-button>

        <!-- Elimina -->
        <welion-select-button *ngIf="selectedTab === 0" fxFlexOffset="12px" label="generic.DELETE"
          [disabled]="!hasCountAtLeastOne && (isLoading || entitleCount === 0)" [dropdownItems]="dropdownDeleteOptions"
          direction="right" [isFromDropdownClient]="true">
        </welion-select-button>

        <!-- Importa da file -->
        <welion-button *ngIf="selectedTab === 0" width="180px" fxFlexOffset="12px" label="client.round.IMPORT_BY_FILE"
          type="primary" size="medium" (onClick)="openMassiveLoadingModal()" [disabled]="isLoading">
        </welion-button>

        <!-- Report scarti -->
        <welion-button *ngIf="selectedTab === 1" width="180px" fxFlexOffset="12px" label="generic.DOWNLOAD_REPORT"
          type="primary" size="medium" (onClick)="exportScrapsList()" [disabled]="isLoading">
        </welion-button>
      </div>
    </div>

    <!-- Loader -->
    <div *ngIf="isLoadingCount" class="loader spinner-big"></div>

    <ng-container *ngIf="!isLoadingCount">

      <ng-container *ngIf="entitleCount > 0 else noEntitles">
        <!-- Table header -->
        <div fxLayout="row" fxLayoutAlign="start center" fxFlexOffset="20px">
          <!-- Cognome e nome -->
          <h5 class="bold" translate="client.round.table.NAME_SURNAME" fxFlex="20"></h5>
          <!-- Codice fiscale -->
          <h5 class="bold" translate="client.round.table.FISCAL_CODE" fxFlex="15"></h5>
          <!-- Matricola/CID -->
          <h5 class="bold center" translate="client.round.table.CID" fxFlex="15"></h5>
          <!-- Compagnia -->
          <h5 class="bold center" translate="client.round.table.COMPANY" fxFlex="15"></h5>
          <!-- Sede predefinita -->
          <h5 class="bold" translate="client.round.table.VENUE" fxFlex="35"></h5>
        </div>

        <!-- Loader -->
        <div *ngIf="isLoading" class="loader spinner-big"></div>

        <ng-container *ngIf="!isLoading">
          <div *ngFor="let entitle of entitles; let even = even" class="campaign-row" [ngClass]="{'even': even}"
            fxLayout="row" fxLayoutAlign="start center">
            <!-- Cognome e nome -->
            <h6 *ngIf="selectedTab == 0" class="black" fxFlex="20">
              {{ entitle.surname + ' ' + entitle.name }}
            </h6>
            <h6 *ngIf="selectedTab == 1" class="black" fxFlex="20">
              {{ entitle.name }}
            </h6>
            <!-- Codice fiscale -->
            <h6 *ngIf="selectedTab == 0" class="black" fxFlex="15">{{ entitle.fiscalCode || '-' }}</h6>
            <h6 *ngIf="selectedTab == 1" class="black" fxFlex="15">{{ entitle.codFisc || '-' }}</h6>
            <!-- Matricola/CID -->
            <h6 class="black center" fxFlex="15">{{ entitle.cid || '-' }}</h6>
            <!-- Compagnia -->
            <h6 class="black center" fxFlex="15">{{ entitle.socDistacco || '-' }}</h6>
            <!-- Sede predefinita -->
            <h6 class="black" fxFlex="35">{{ entitle.preMatchedVenue || '-' }}</h6>
            <!-- Scheda -->
            <h6 *ngIf="selectedTab == 1" class="black bold underline clickable" fxFlexOffset="12px"
              translate="admin.campaignCustomerDetails.table.FORM" (click)="openAttendenceCard(entitle)"></h6>

          </div>
        </ng-container>

        <!-- Paginatore -->
        <welion-paginator *ngIf="selectedTab === 0" fxFlexOffset="20px" [isLoading]="isLoading"
          [paginationLength]="paginationLength" [itemsCount]="entitleCount"
          (onClickPage)="getEntitledListByRound($event)"></welion-paginator>

        <welion-paginator *ngIf="selectedTab === 1" fxFlexOffset="20px" [isLoading]="isLoading"
          [paginationLength]="paginationLength" [itemsCount]="entitleCount"
          (onClickPage)="getEntitledListByRoundScraps($event)"></welion-paginator>

      </ng-container>

    </ng-container>

  </div>
</div>


<!-- <modal-upload id="massiveLoad" uploadType="PEOPLE" [isConfirmBtnDisabled]="isConfirmUploadAttendancesBtnDisabled()"
  (onConfirm)="uploadAttendancesFile()" [isToResetUploadComponent]="isToResetUploadComponent"
  (assignFile)="saveAttendancesFile($event)" [venues]="venues" [selectedVenue]="selectedVenue"
  (onSelectVenue)="onVenueSelected($event)">
</modal-upload> -->

<!-- Modale per il caricamento massivo degli AD -->
<modal id="massiveLoad" class="modal-upload" (onClose)="closeMassiveLoadingModal()"
  (onCancel)="closeMassiveLoadingModal()" (onConfirm)="uploadAttendancesFile()"
  [confirmLabel]="'generic.IMPORT' | translate" [confirmBtnDisabled]="isConfirmUploadAttendancesBtnDisabled()">

  <div fxLayout="column" class="full-width">

    <h2 fxFlexOffset="20px" class="small center" translate="component.modalUpload.TITLE_PEOPLE"></h2>

    <div class="modal-upload-divider" fxFlexOffset="20px"></div>

    <div fxFlexOffset="20px" fxLayout="row" fxLayoutAlign="center">
      <welion-button fxFlex="200px" label="component.modalUpload.DOWNLOAD" type="primary bordless" size="medium"
        (onClick)="fileImportPeople()">
      </welion-button>
    </div>

    <!-- Carica tracciato con l’elenco delle persone da includere -->
    <div fxFlexOffset="24px" fxLayout="column">
      <input-container fromEuropUpload isObbligatory isWithoutInfoIcon instructions="component.modalUpload.UPLOAD_DESC"
        [title]="('component.modalUpload.UPLOAD_PEOPLE_TITLE')">

        <welion-upload onlyXLSX [isToReset]="isToResetUploadComponent" (onUpload)="saveAttendancesFile($event)">
        </welion-upload>
      </input-container>
    </div>

    <input-container fxFlexOffset="24px" instructions="component.modalUpload.PREASSIGNED_VENUE_DESC"
      title="component.modalUpload.PREASSIGNED_VENUE">
      <welion-select [items]="venues" [selectedItem]="selectedVenue" (change)="onVenueSelected($event)">
      </welion-select>
    </input-container>

    <div fxFlexOffset="20px"></div>

  </div>

</modal>

<!-- Modale per l'eliminazione parziale degli AD -->
<!-- <modal-upload id="partialDelete" uploadType="DELETE_AD_CLIENT_PEOPLE" [isConfirmBtnDisabled]="isConfirmUploadPartialDeleteBtnDisabled()"
  (onConfirm)="uploadPartialDeleteFile()" [isToResetUploadComponent]="isToResetUploadPartialDeleteComponent"
  (assignFileDeletePeople)="saveAttendancesFile($event, true)">
</modal-upload> -->

<!-- Modale per il caricamento massivo -->
<modal id="partialDelete" class="modal-upload" (onCancel)="closePartialDeleteLoadingModal()"
  (onClose)="closePartialDeleteLoadingModal()" (onConfirm)="openConfirmExcelPartialDelete()"
  [confirmLabel]="'generic.IMPORT' | translate" [confirmBtnDisabled]="isConfirmUploadPartialDeleteBtnDisabled()">

  <div fxLayout="column" class="full-width">

    <h2 fxFlexOffset="20px" class="small center" translate="component.modalUpload.TITLE_DELETE_AD_CLIENT_PEOPLE"></h2>

    <div class="modal-upload-divider" fxFlexOffset="20px"></div>

    <div fxFlexOffset="20px" fxLayout="row" fxLayoutAlign="center">
      <welion-button fxFlex="200px" label="component.modalUpload.DOWNLOAD" type="primary bordless" size="medium"
        (onClick)="fileImportDeletePeople()">
      </welion-button>
    </div>

    <!-- Carica tracciato con l’elenco delle persone da includere -->
    <div fxFlexOffset="24px" fxLayout="column">
      <input-container fromEuropUpload isObbligatory isWithoutInfoIcon instructions="component.modalUpload.UPLOAD_DESC"
        [title]="('component.modalUpload.UPLOAD_DELETE_AD_CLIENT_PEOPLE_TITLE')">

        <welion-upload-delete-people onlyXLSX [isToReset]="isToResetUploadPartialDeleteComponent"
          (onUpload)="saveAttendancesFile($event)">
        </welion-upload-delete-people>
      </input-container>
    </div>

    <div fxFlexOffset="20px"></div>

  </div>

</modal>

<!-- Modale per confermare la cancellazione tutti degli AD transitorio - cancella quelli prenotati e in prenotazione -->
<modal id="deleteAllEntitledsFirstModal" (onClose)="closeDeleteAllEntitledsModal()"
  (onCancel)="closeDeleteAllEntitledsModal()"
  (onConfirm)="closeDeleteAllEntitledsModal(); openDeleteAllEntitledsFinalModal()"
  [cancelLabel]="'generic.CLOSE' | translate | uppercase" [confirmLabel]="'generic.PROCEED' | translate | uppercase">
  <modal-text-content [withScrolledBody]="true" [title]="'client.round.deleteAllEntitledsModal.TITLE' | translate"
    [text]="'client.round.deleteAllEntitledsModal.RESET_WARNING' | translate"
    [subtitle]="'client.round.deleteAllEntitledsModal.SUBTITLE' | translate">
  </modal-text-content>
</modal>

<!-- Modale per confermare la cancellazione parziale AD -->
<modal id="deletePartialEntitledsModal" (onClose)="closeDeletePartialEntitledsModal()"
  (onCancel)="closeDeletePartialEntitledsModal()"
  (onConfirm)="closeDeletePartialEntitledsModal(); openModalPartialDelete()"
  [cancelLabel]="'generic.CLOSE' | translate | uppercase" [confirmLabel]="'generic.PROCEED' | translate | uppercase">
  <modal-text-content [withScrolledBody]="true" [title]="'client.round.deletePartialEntitledsModal.TITLE' | translate"
    [subtitle]="'client.round.deleteAllEntitledsModal.SUBTITLE' | translate"
    [text]="'client.round.deletePartialEntitledsModal.SUBTITLE' | translate">
  </modal-text-content>
</modal>

<!-- Modale per confermare la cancellazione di una lista AD tramite excel -->
<modal id="confirmExcelPartialDelete" (onClose)="closeConfirmExcelPartialDelete()"
  (onCancel)="closeConfirmExcelPartialDelete()"
  (onConfirm)="closeConfirmExcelPartialDelete(); uploadPartialDeleteFile()"
  [cancelLabel]="'generic.CLOSE' | translate | uppercase" [confirmLabel]="'generic.PROCEED' | translate | uppercase">
  <modal-text-content [withScrolledBody]="true"
    [title]="'client.round.confirmPartialDeleteFromExcelModal.TITLE' | translate"
    [text]="'client.round.confirmPartialDeleteFromExcelModal.RESET_WARNING' | translate"
    [subtitle]="'client.round.confirmPartialDeleteFromExcelModal.SUBTITLE' | translate">
  </modal-text-content>
</modal>

<!-- Modale per il cancella tutti degli AD - cancella quelli prenotati e in prenotazione -->
<modal id="deleteAllEntitledSecondModal" (onClose)="closeDeleteAllEntitledsFinalModal()"
  (onCancel)="closeDeleteAllEntitledsFinalModal()" (onConfirm)="confirmDeleteAllEntitledsFinal()"
  [cancelLabel]="'generic.CLOSE' | translate | uppercase" [confirmLabel]="'generic.CONFIRM' | translate | uppercase">
  <modal-text-content [withScrolledBody]="true" [title]="'client.round.deleteAllEntitledsModal.TITLE' | translate"
    [text]="'client.round.deleteAllEntitledsFinalModal.RESET_WARNING' | translate"
    [subtitle]="'client.round.deleteAllEntitledsModal.SUBTITLE' | translate">
  </modal-text-content>
</modal>

<!-- Placeholder nessuna anagrafica o scarti disponibile -->
<ng-template #noEntitles>
  <div fxLayout="column" fxLayoutAlign="start center">
    <svg-icon fxFlexOffset="40px" [applyClass]="true" src="assets/img/icons/note-health-facility-red.svg"></svg-icon>

    <!-- Inserisci le sedi di questa struttura sanitaria! -->
    <h3 *ngIf="selectedTab == 0" fxFlexOffset="32px" translate="client.round.NO_ENTITLES"></h3>

    <h3 *ngIf="selectedTab == 1" fxFlexOffset="32px" translate="client.round.NO_SCRAPS"></h3>

    <div *ngIf="selectedTab == 0" fxLayout="row" fxFlexOffset="32px">
      <!-- Importa da file -->
      <welion-button width="200px" size="large" type="primary"
        [label]="'client.round.IMPORT_BY_FILE' | translate | uppercase" (onClick)="openMassiveLoadingModal()"
        [disabled]="isLoading">
      </welion-button>
    </div>
  </div>
</ng-template>

<!-- Sidenav con la scheda di dettaglio -->
<sidebar showAppHeader hideCloseButton [sidenavTemplateRef]="navContent" direction="right" [navWidth]="664"
  [duration]="0.3" >
</sidebar>

<!-- Contenuto sidenav -->
<ng-template #navContent>

  <form class="attendance-card-sidenav" fxLayout="column">

    <div class="full-width" fxLayout="row" fxLayoutAlign="end">
      <!-- Icona chiudi -->
      <div fxLayoutAlign="row" fxLayoutAlign="center center" (click)="closeAttendanceCard()" class="close-container">
        <svg-icon class="small-size" src="/assets/img/icons/x.svg" [applyClass]="true"></svg-icon>
        <h5 fxFlexOffset="8px" class="white">{{ 'generic.CLOSE' | translate | uppercase}}</h5>
      </div>
    </div>

    <!-- Scheda personale -->
    <h5 fxFlexOffset="20px" class="bold" translate="structures.round.attendances.modal.TITLE"></h5>

    <ng-container *ngIf="!isLoading && selectedAttendance">
      <!-- Palerma Leonida -->
      <h3 fxFlexOffset="16px">{{ selectedAttendance.name }}</h3>

      <div fxFlexOffset="16px" class="card-header-divider"></div>

      <!-- Codice fiscale -->
      <h5 fxFlexOffset="24px" class="bold" translate="structures.round.attendances.modal.FISCAL_CODE"></h5>

      <h5 fxFlexOffset="4px">{{ selectedAttendance.codFisc }}</h5>

      <!-- Matricola -->
      <h5 fxFlexOffset="24px" class="bold" translate="client.round.MATRICOLE"></h5>

      <h5 fxFlexOffset="4px">{{ selectedAttendance.cid }}</h5>

      <!-- Scartato il -->
      <h5 fxFlexOffset="24px" class="bold" translate="client.round.SCARTED_AT"></h5>

      <h5 fxFlexOffset="4px">{{ selectedAttendance.date | date: 'dd MMMM yyyy HH:mm' }}</h5>

      <!-- Motivazione -->
      <h5 fxFlexOffset="24px" class="bold" translate="client.round.MOTIVATION"></h5>

      <h5 fxFlexOffset="4px">{{ ('client.round.' + selectedAttendance.desc) | translate }}</h5>

    </ng-container>

    <div fxFlexOffset="20px"></div>

  </form>

</ng-template>
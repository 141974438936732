<div class="page-content-wrapper" fxLayout="column">

  <!-- Campagna x -->
  <h1 fxFlexOffset="40px" class="primary">
    {{ 'admin.campaignRoundDetails.TITLE' | translate: { value: campaign ? campaign.title : '' } }}
  </h1>

  <bread-crumbs fxFlexOffset="12px" firstLevel="ADMIN_CAMPAIGNS" (secondLevelClick)="goBackToRounds()"
    [secondLevel]="campaign ? campaign.title : ('admin.campaignRoundDetails.TITLE2' | translate)" [thirdLevel]="round ?
      ((round.startDate | date: 'dd MMMM yyyy' | titlecase) + ' - ' + (round.endDate | date: 'dd MMMM yyyy' | titlecase)) :
      ('admin.campaignRoundDetails.TITLE3' | translate)">
  </bread-crumbs>

  <!-- Resume round -->
  <div fxFlexOffset="56px" fxLayout="row" fxLayoutAlign="center">
    <div class="campaign-round-resume big-card" fxLayout="column">
      <div fxLayout="row" fxLayoutAlign="space-between">
        <svg-icon class="note" [applyClass]="true" src="assets/img/icons/note-clients.svg"></svg-icon>

        <div fxFlexOffset="80px" fxFlex fxLayout="row">
          <ng-container *ngIf="!isLoadingBase && round">
            <div fxLayout="column" fxFlex="50">
              <!-- Round -->
              <h5 class="bold" translate="admin.campaignRoundDetails.ROUND"></h5>
              <!-- Prenotazioni -->
              <h5 fxFlexOffset="8px" translate="admin.campaignRoundDetails.BOOKINGS"></h5>
              <h5>
                {{ round.minReservationEndDate | date: 'dd MMMM yyyy' | titlecase }}
                {{ ' - ' }}
                {{ round.maxReservationEndDate | date: 'dd MMMM yyyy' | titlecase }}
              </h5>
              <h5></h5>
              <!-- Somministrazione slot -->
              <h5 fxFlexOffset="8px" translate="admin.campaignRoundDetails.VISIBILITIES"></h5>
              <h5>
                {{ (round.startDate | date: 'dd MMMM yyyy' | titlecase) || ('generic.TO_SET' | translate) }}
                {{ ' - ' }}
                {{ (round.endDate | date: 'dd MMMM yyyy' | titlecase) || ('generic.TO_SET' | translate) }}
              </h5>
            </div>
            <div fxLayout="column" fxFlex="50">
              <!-- Stato -->
              <h5 class="bold" translate="admin.campaignRoundDetails.BOOKING_DETAILS"></h5>

              <!-- Annulla la prenotazione entro: -->
              <h5 translate="admin.campaignRoundDetails.CANCEL_BOOKING_WITHIN"></h5>
              <h5 *ngIf="maxDeleteBookingDate">{{ maxDeleteBookingDate }}</h5>

              <!-- Modifica data e ora su stessa struttura entro: -->
              <h5 translate="admin.campaignRoundDetails.EDIT_DATE_AND_STRUCTURE_WITHIN"></h5>
              <h5 *ngIf="maxEditBookingDateAndStructure">{{ maxEditBookingDateAndStructure }}</h5>
            </div>
          </ng-container>
          <div *ngIf="isLoadingBase || !round" class="loader spinner-small"></div>
        </div>

      </div>

      <div class="margin-left82 gray-border-top" fxLayout="row" fxLayoutAlign="space-between center">
        <!-- Stato -->
        <div fxLayout="row">
          <h5 class="bold">{{ ('admin.campaignRoundDetails.STATE' | translate) + ': ' }}</h5>
          <h5>&nbsp;</h5>
          <h5 translate="admin.campaignRoundDetails.{{activeRound ? '' : 'NOT_'}}ACTIVE"></h5>
        </div>
        <div>
          <!-- Elimina round -->
          <welion-button *ngIf="!isLimitedAdmin" width="112px" size="medium" type="secondary"
            [label]="'generic.DELETE' | translate | uppercase" (onClick)="openDeleteRoundModal()"
            [disabled]="isLoadingBase">
          </welion-button>
          <!-- Modifica round -->
          <welion-button *ngIf="!isLimitedAdmin" fxFlexOffset="8px" width="112px" size="medium" type="primary"
            [label]="'generic.EDIT' | translate | uppercase" (onClick)="editRound()" [disabled]="isLoadingBase">
          </welion-button>
        </div>
      </div>
    </div>
  </div>

  <div id="admin-campaign-round-details-anchor" fxFlexOffset="28px"></div>

  <div fxFlexOffset="28px" class="campaign-round-details-container" fxLayout="column">

    <div fxLayout="row" fxLayoutAlign="end">
      <!-- Azioni Venues (sedi) -->
      <welion-select-button *ngIf="selectedTab === 0 && !isLimitedAdmin" fxFlex="200px" label="generic.ACTION"
        [disabled]="isLoading" [dropdownItems]="dropdownVenues" direction="right">
      </welion-select-button>

      <!-- Azioni AD -->
      <welion-select-button *ngIf="selectedTab === 1" fxFlex="228px" label="generic.ACTION" [disabled]="isLoading"
        [dropdownItems]="dropdownEntitleds" direction="right">
      </welion-select-button>

      <!-- Lista round -->
      <welion-button fxFlexOffset="8px" width="168px" size="medium" type="primary" hasLeftIcon
        (onClick)="goBackToRounds()" [label]="'admin.campaignRoundDetails.ROUND_LIST' | translate">
      </welion-button>
    </div>

    <div class="campaign-round-details-tabs" fxLayout="row">
      <!-- Sedi -->
      <tab-light [isCurrentTab]="selectedTab === 0" (onClick)="onTabClick(0)"
        [tabTitle]="'admin.campaignRoundDetails.tab.venues.TITLE' | translate"
        [attrAriaLabel]="'admin.campaignRoundDetails.tab.venues.TITLE' | translate">
      </tab-light>

      <!-- Aventi diritto (solo se non ho visibilità ridotte) -->
      <tab-light *ngIf="!isLimitedAdmin" fxFlexOffset="32px" [isCurrentTab]="selectedTab === 1"
        [tabTitle]="'admin.campaignRoundDetails.tab.attendances.TITLE' | translate"
        [attrAriaLabel]="'admin.campaignRoundDetails.tab.attendances.TITLE' | translate" (onClick)="onTabClick(1)">
      </tab-light>
    </div>

    <!-- Sedi -->
    <ng-container *ngIf="selectedTab === 0">

      <ng-container *ngIf="!isLoadingCount">
        <ng-container *ngIf="venuesCount> 0 else noVenues">

          <div fxFlexOffset="32px" fxLayout="row" fxLayoutAlign="space-between center">
            <!-- Sedi -->
            <h3 fxFlexOffset="16px" translate="admin.campaignRoundDetails.tab.venues.TITLE_MIN"></h3>

            <div fxLayout="column" fxFlex="280px">
              <!-- Mostra -->
              <div fxLayout="row">
                <h6 fxFlexOffset="10px" class="black bold" translate="admin.campaignRoundDetails.tab.venues.SHOW"></h6>
              </div>
              <welion-select fxFlexOffset="4px" toTranslate withTransparency [disabled]="isLoading"
                [items]="venueVisibilities" [selectedItem]="selectedVenueVisibility"
                (change)="onChangeVenueVisibility($event)"></welion-select>
            </div>
          </div>

          <!-- Table header -->
          <div fxFlexOffset="24px" fxLayout="row">
            <!-- Struttura/Sede -->
            <div fxFlex="14" fxFlexOffset="16px" fxLayout="row" (mouseenter)="onEnterMouseVenueTitle()"
              (mouseleave)="onLeaveMouseVenueTitle()" (click)="onClickVenueTitle()" class="filterColumnArrow">
              <h5 class="bold" translate="admin.campaignRoundDetails.tab.venues.table.VENUE_NAME"></h5>

              <svg-icon [@showIf] *ngIf="orderVenueTitle !== 0" fxFlexOffset="6px" [applyClass]="true"
                src="assets/img/icons/icon-arrow-upward.svg"
                [ngClass]="{'activeFilter': orderVenueTitle === 2 || orderVenueTitle === 3, 'flipArrow': orderVenueTitle === 3}">
              </svg-icon>
            </div>

            <!-- Saturazione -->
            <div fxFlex="14" fxLayout="row" fxLayoutAlign="end" (mouseenter)="onEnterMouseVenueSaturation()"
              (mouseleave)="onLeaveMouseVenueSaturation()" (click)="onClickVenueSaturation()" class="filterColumnArrow">
              <h5 class="bold" translate="admin.campaignRoundDetails.tab.venues.table.VENUE_SATURATION"></h5>

              <svg-icon [@showIf] *ngIf="orderVenueSaturation !== 0" fxFlexOffset="6px" [applyClass]="true"
                src="assets/img/icons/icon-arrow-upward.svg"
                [ngClass]="{'activeFilter': orderVenueSaturation === 2 || orderVenueSaturation === 3, 'flipArrow': orderVenueSaturation === 3}">
              </svg-icon>
            </div>

            <!-- Slot totali -->
            <div fxFlex="12" fxLayout="row" fxLayoutAlign="end">
              <h5 class="bold" translate="admin.campaignRoundDetails.tab.venues.table.TOTAL_SLOTS"></h5>
            </div>

            <!-- Da processare -->
            <div fxFlex="14" fxLayout="row" fxLayoutAlign="end">
              <h5 class="bold primary" translate="admin.campaignRoundDetails.tab.venues.table.SLOTS_TO_PROCESS">
              </h5>
            </div>

            <!-- Prenotazioni -->
            <div fxFlex="18" fxLayout="row" fxLayoutAlign="end">
              <h5 class="bold" translate="admin.campaignRoundDetails.tab.venues.table.RESERVATIONS"></h5>
            </div>

            <!-- Somministrazione -->
            <div fxFlex="18" fxLayout="row" fxLayoutAlign="end">
              <h5 class="bold" translate="admin.campaignRoundDetails.tab.venues.table.ADMINISTRATION"></h5>
            </div>

            <!-- Azioni -->
            <div fxFlex="10" fxLayout="row" fxLayoutAlign="end">
            </div>

          </div>

          <!-- Loader -->
          <div *ngIf="isLoading" class="loader spinner-big"></div>

          <!-- Table rows -->
          <ng-container *ngIf="!isLoading">

            <div *ngFor="let venue of venues; let first = first; let odd = odd" class="campaign-round-row"
              [ngClass]="{'odd': odd}" fxLayout="row" fxLayoutAlign="start center"
              [fxFlexOffset]="first ? '8px' : '0px'">
              <!-- Struttura/Sede -->
              <div fxFlex="14" fxFlexOffset="16px" fxLayout="column">
                <h6 class="black bold">{{ venue.healthFacilitySupplierName }}</h6>
                <h6 class="black" fxFlexOffset="8px">{{ venue.name }}</h6>
              </div>

              <!-- Saturazione -->
              <div fxLayout="row" fxFlex="14" fxLayoutAlign="end">
                <h6 class="black">{{ ((venue.saturationPercentage || 0) | number : '1.0-3') + '%' }}</h6>
              </div>

              <!-- Slot totali -->
              <div fxLayout="row" fxFlex="12" fxLayoutAlign="end">
                <h6 class="black">{{ venue.totalSeats || 0 }}</h6>
              </div>

              <!-- Da processare -->
              <div fxLayout="row" fxFlex="14" fxLayoutAlign="end">
                <h6 class="primary bold">{{ venue.slotToProcessForAdmin || 0 }}</h6>
              </div>

              <!-- Prenotazioni -->
              <div fxLayout="row" fxFlex="18" fxLayoutAlign="end">
                <h6 class="black line-height24" [innerHTML]="venue.reservationDateLabel || ''"></h6>
              </div>

              <!-- Somministrazione -->
              <div fxLayout="row" fxFlex="18" fxLayoutAlign="end">
                <h6 class="black line-height24" [innerHTML]="venue.rounDateLabel || ''"></h6>
              </div>

              <!-- Azioni -->
              <div fxFlex="10" fxLayout="row" fxLayoutAlign="end">
                <welion-select-button fxFlex="220px" isLink [currentSelect]="venue" direction="right"
                  [label]="'generic.ACTION_LOWER' | translate" [disabled]="isLoading"
                  [dropdownItems]="dropdownSelectVenue">
                </welion-select-button>
                <div fxFlex="16px"></div>
              </div>
            </div>
          </ng-container>

          <!-- Paginatore -->
          <welion-paginator fxFlexOffset="20px" [selectedIndex]="pageSelectedIndex" [isLoading]="isLoading"
            [paginationLength]="paginationLengthVenues" [itemsCount]="venuesCount"
            (onClickPage)="getRoundVenues($event)"></welion-paginator>

        </ng-container>
      </ng-container>

      <div *ngIf="isLoadingCount" class="loader spinner-big"></div>

    </ng-container>

    <!-- Aventi diritto -->
    <ng-container *ngIf="selectedTab === 1">

      <ng-container *ngIf="!isLoadingCount">
        <ng-container *ngIf="entitledsCount > 0 || hasDataWithoutSearch else noEntitleds">

          <div fxFlexOffset="32px" fxLayout="row" fxLayoutAlign="space-between center">
            <!-- Aventi diritto -->
            <!--h3 fxFlexOffset="16px" translate="admin.campaignRoundDetails.tab.attendances.TITLE_MIN"></h3-->
            <!-- Cerca per nome -->
            <welion-input fxFlex="252px" [bindValue]="searchText" (onChange)="searchTextChange($event)"
              (onIconClicked)="searchEntitleds()" withTransparency
              [placeholder]="'admin.campaignRoundDetails.tab.attendances.table.SEARCH_NAME_PLACEHOLDER' | translate"
              [disabled]="isLoading" iconRight="assets/img/icons/icon-search.svg">
            </welion-input>
            <!-- Cerca per azienda -->
            <welion-input fxFlex="252px" [bindValue]="searchTextCompany" (onChange)="searchTextCompanyChange($event)"
              (onIconClicked)="searchEntitleds()" withTransparency
              [placeholder]="'admin.campaignRoundDetails.tab.attendances.table.SEARCH_COMPANY_PLACEHOLDER' | translate"
              [disabled]="isLoading" iconRight="assets/img/icons/icon-search.svg">
            </welion-input>
            <!-- Cerca per CF -->
            <welion-input fxFlex="252px" [bindValue]="searchTextCF" (onChange)="searchTextCFChange($event)"
              (onIconClicked)="searchEntitleds()" withTransparency
              [placeholder]="'admin.campaignRoundDetails.tab.attendances.table.SEARCH_CF_PLACEHOLDER' | translate"
              [disabled]="isLoading" iconRight="assets/img/icons/icon-search.svg">
            </welion-input>
          </div>

          <!-- Table header -->
          <div fxFlexOffset="24px" fxLayout="row" class="full-width" fxLayoutAlign="start center">
            <!-- Cognome e nome -->
            <h5 fxFlexOffset="16px" class="bold width20"
              translate="admin.campaignRoundDetails.tab.attendances.table.NAME_SURNAME">
            </h5>
            <!-- Tipologia di utente -->
            <h5 class="bold width10" translate="admin.campaignRoundDetails.tab.attendances.table.USER_TYPE">
            </h5>
            <!-- Stato -->
            <h5 class="bold width14" translate="admin.campaignRoundDetails.tab.attendances.table.STATE">
            </h5>
            <!-- Sede -->
            <h5 class="bold width14" translate="admin.campaignRoundDetails.tab.attendances.table.VENUE">
            </h5>
            <!-- Company -->
            <h5 class="bold width14" translate="admin.campaignRoundDetails.tab.attendances.table.COMPANY">
            </h5>
            <!-- Codice fiscale -->
            <h5 class="bold width18" translate="admin.campaignRoundDetails.tab.attendances.table.FISCAL_CODE">
            </h5>
            <div class="width8">
            </div>
          </div>

          <!-- Loader -->
          <div *ngIf="isLoading" class="loader spinner-big"></div>

          <ng-container *ngIf="!isLoading">
            <!-- Table rows -->
            <div *ngFor="let entitled of entitleds; let index = index; let odd = odd"
              class="campaign-round-row entitled full-width" [ngClass]="{'odd': odd}" fxLayout="row"
              fxLayoutAlign="start center" [fxFlexOffset]="index === 0 ? '16px' : '0px'">
              <!-- Cognome e nome -->
              <h6 class="black width20 truncate-with-ellipsis" fxFlexOffset="16px">{{ entitled.surname + ' ' +
                entitled.name }}</h6>
              <!-- Tipologia utente -->
              <h6 class="black width10 truncate-with-ellipsis padding-right5">
                {{ ('admin.campaignRoundDetails.tab.attendances.table.' + (entitled.parentCodFisc ? "FAMILY" :
                "EMPLOYEE")) | translate }}</h6>
              <!-- Stato -->
              <h6 class="black width14 truncate-with-ellipsis">{{ ('status.' + entitled.status) | translate }}</h6>
              <!-- Sede -->
              <h6 class="black padding-right5 width14 truncate-with-ellipsis">{{ (entitled.venue ? entitled.venue.name :
                '-') || '-' }}</h6>
              <!-- Company -->
              <h6 class="black padding-right5 width14 truncate-with-ellipsis">{{ entitled.company || '-' }}</h6>
              <!-- Codice fiscale -->
              <h6 class="black width18 truncate-with-ellipsis">{{ entitled.fiscalCode || '-' }}</h6>

              <div class="width10" fxLayout="row" fxLayoutAlign="end">
                <!-- Azioni -->
                <welion-select-button fxFlex="220px" isLink [currentSelect]="entitled" direction="right"
                  [label]="'generic.ACTION_LOWER' | translate" [disabled]="isLoading"
                  [dropdownItems]="dropdownSelectEntitled">
                </welion-select-button>
                <div fxFlex="16px"></div>
              </div>
            </div>
          </ng-container>

          <!-- Paginatore -->
          <welion-paginator fxFlexOffset="20px" [selectedIndex]="pageSelectedIndex" [isLoading]="isLoading"
            [paginationLength]="paginationLengthEntitleds" [itemsCount]="entitledsCount"
            (onClickPage)="getRoundEntitleds($event)"></welion-paginator>

        </ng-container>
      </ng-container>

      <div *ngIf="isLoadingCount" class="loader spinner-big"></div>

    </ng-container>

  </div>

</div>

<ng-template #noVenues>
  <div fxLayout="column" fxLayoutAlign="start center">
    <svg-icon fxFlexOffset="40px" [applyClass]="true" src="assets/img/icons/note-health-facility-red.svg"></svg-icon>

    <!-- Aggiungi delle sedi a questo round -->
    <h3 *ngIf="!isLimitedAdmin" fxFlexOffset="32px" translate="admin.campaignRoundDetails.tab.venues.NO_VENUES"></h3>
    <h3 *ngIf="isLimitedAdmin" fxFlexOffset="32px" translate="admin.campaignRoundDetails.tab.venues.NO_VENUES_FOUND">
    </h3>

    <!-- Aggiungi sede -->
    <welion-button *ngIf="!isLimitedAdmin" fxFlexOffset="32px" width="232px" size="large" type="primary"
      [label]="'admin.campaignRoundDetails.ADD_VENUE' | translate" (onClick)="assignVenueToRound()">
    </welion-button>
  </div>
</ng-template>

<ng-template #noEntitleds>
  <div fxLayout="column" fxLayoutAlign="start center">
    <svg-icon fxFlexOffset="40px" [applyClass]="true" src="assets/img/icons/note-health-facility-red.svg"></svg-icon>

    <!-- Inserisci i clienti per questa campagna -->
    <h3 fxFlexOffset="32px" translate="admin.campaignRoundDetails.tab.attendances.NO_ENTITLED"></h3>

    <!-- Vai ai clienti -->
    <welion-button fxFlexOffset="32px" width="232px" size="large" type="primary"
      [label]="'admin.campaignRoundDetails.tab.attendances.GO_TO_CUSTOMERS' | translate" (onClick)="goToCustomers()">
    </welion-button>
  </div>
</ng-template>

<!-- Sidenav con la scheda di dettaglio -->
<sidebar showAppHeader hideCloseButton direction="right" [navWidth]="664" [duration]="0.3"
  [sidenavTemplateRef]="navContentAttendanceCard" >
</sidebar>

<!-- Contenuto sidenav -->
<ng-template #navContentAttendanceCard>

  <form class="attendance-card-sidenav" fxLayout="column" [formGroup]="attendeeForm" (ngSubmit)="updateAttendee()">

    <div class="full-width" fxLayout="row" fxLayoutAlign="end">
      <!-- Icona chiudi -->
      <div fxLayoutAlign="row" fxLayoutAlign="center center" (click)="closeAttendanceCard()" class="close-container">
        <svg-icon class="small-size" src="/assets/img/icons/x.svg" [applyClass]="true"></svg-icon>
        <h5 fxFlexOffset="8px" class="white">{{ 'generic.CLOSE' | translate | uppercase}}</h5>
      </div>
    </div>

    <!-- Scheda personale -->
    <h5 fxFlexOffset="20px" class="bold" translate="structures.round.attendances.modal.TITLE"></h5>

    <ng-container *ngIf="!isLoading && selectedAttendance">
      <!-- Palerma Leonida -->
      <h3 fxFlexOffset="16px">{{ selectedAttendance.surname }} {{ selectedAttendance.name }}</h3>

      <div fxFlexOffset="16px" class="card-header-divider"></div>

      <!-- Codice fiscale -->
      <h5 fxFlexOffset="24px" class="bold" translate="structures.round.attendances.modal.FISCAL_CODE"></h5>

      <div fxLayout="row" fxLayoutAlign="space-between center" class="full-width">
        <h5 *ngIf="!isEditCFView">{{ selectedAttendance.fiscalCode }}</h5>
        <welion-input *ngIf="isEditCFView" [bindValue]="fiscalCodeUser" (onChange)="userFCChanged($event)"
          [placeholder]="'helpDesk.campaign.INSERT_FISCAL_CODE' | translate">
        </welion-input>
        <welion-button *ngIf="!isEditCFView" (onClick)="editCF()" noUpperCase direction="right"
          type="on-edit-help-desk-user-data" [label]="'generic.EDIT' | translate">
        </welion-button>
        <welion-button *ngIf="isEditCFView" (onClick)="editCF(true)" noUpperCase direction="right"
          type="on-edit-help-desk-user-data" [label]="'generic.CLOSE' | translate">
        </welion-button>
        <welion-button [disabled]="!fiscalCodeUser || (fiscalCodeUser && fiscalCodeUser.length != 16)"
          *ngIf="isEditCFView" (onClick)="updateUser(false, false, true)" noUpperCase direction="right"
          type="on-edit-help-desk-user-data" [label]="'generic.SAVE' | translate">
        </welion-button>
      </div>

      <div fxFlexOffset="16px" fxLayout="row" fxLayout.xs="column" fxLayoutAlign="space-between">

        <!-- Nome -->
        <div fxLayout="column" class="full-width">
          <h5 class="bold" translate="structures.round.attendances.modal.NAME"></h5>
          <div fxLayout="row" fxLayoutAlign="space-between center" class="full-width">
            <h5 *ngIf="!isEditNameView">
              {{ selectedAttendance.name }}
            </h5>
            <welion-input *ngIf="isEditNameView" [bindValue]="nameUser" (onChange)="userNameChanged($event)"
              [placeholder]="'helpDesk.campaign.INSERT_NAME' | translate">
            </welion-input>
            <welion-button *ngIf="!isEditNameView" (onClick)="editName()" noUpperCase direction="right"
              type="on-edit-help-desk-user-data" [label]="'generic.EDIT' | translate">
            </welion-button>
            <welion-button *ngIf="isEditNameView" (onClick)="editName(true)" noUpperCase direction="right"
              type="on-edit-help-desk-user-data" [label]="'generic.CLOSE' | translate">
            </welion-button>
            <welion-button [disabled]="!nameUser" *ngIf="isEditNameView" (onClick)="updateUser(true)" noUpperCase
              direction="right" type="on-edit-help-desk-user-data" [label]="'generic.SAVE' | translate">
            </welion-button>
          </div>

          <!-- Cognome -->
          <h5 class="bold" fxFlexOffset="16px" translate="structures.round.attendances.modal.SURNAME"></h5>
          <div fxLayout="row" fxLayoutAlign="space-between center" class="full-width">
            <h5 *ngIf="!isEditSurnameView">
              {{ selectedAttendance.surname }}
            </h5>
            <welion-input *ngIf="isEditSurnameView" fxFlexOffset="4px" [bindValue]="surnameUser"
              (onChange)="userSurnameChanged($event)" [placeholder]="'helpDesk.campaign.INSERT_SURNAME' | translate">
            </welion-input>
            <welion-button *ngIf="!isEditSurnameView" (onClick)="editSurname()" noUpperCase direction="right"
              type="on-edit-help-desk-user-data" [label]="'generic.EDIT' | translate">
            </welion-button>
            <welion-button *ngIf="isEditSurnameView" (onClick)="editSurname(true)" noUpperCase direction="right"
              type="on-edit-help-desk-user-data" [label]="'generic.CLOSE' | translate">
            </welion-button>
            <welion-button [disabled]="!surnameUser" *ngIf="isEditSurnameView" (onClick)="updateUser(false, true)"
              noUpperCase direction="right" type="on-edit-help-desk-user-data" [label]="'generic.SAVE' | translate">
            </welion-button>
          </div>
          <!-- Società -->
          <!-- <div fxFlexOffset="16px" fxLayout="column" fxFlex>
            <h5 class="bold" translate="structures.round.attendances.modal.COMPANY"></h5>
            <div fxLayout="row" fxLayoutAlign="space-between center" class="full-width">
              <h5>{{ selectedAttendance.socDistacco || '-' }}</h5>
              <welion-select mediumText [bindLabel]="'title'" [selectedItem]="selectedCompany" searchable
                [loading]="isLoadingCompanyList" (change)="userCompanyChanged($event)" [items]="companiesList"
                (scroll)="fetchMoreCompanies($event)"
                [placeholder]="'admin.addOfflineAd.COMPANY_PLACEHOLDER' | translate"
                [noDataFoundLabel]="'admin.addOfflineAd.NO_COMPANY' | translate" [typeahead]="companyServive$"
                [attrAriaLabel]="'admin.addOfflineAd.COMPANY_PLACEHOLDER' | translate">
              </welion-select>
              <welion-button *ngIf="!isEditCompanyView" (onClick)="editCompany()" noUpperCase direction="right"
                type="on-edit-help-desk-user-data" [label]="'generic.EDIT' | translate">
              </welion-button>
              <welion-button *ngIf="isEditCompanyView" (onClick)="editCompany(true)" noUpperCase direction="right"
                type="on-edit-help-desk-user-data" [label]="'generic.CLOSE' | translate">
              </welion-button>
              <welion-button [disabled]="!selectedCompany" *ngIf="isEditCompanyView" (onClick)="updateUser(false, true)"
                noUpperCase direction="right" type="on-edit-help-desk-user-data" [label]="'generic.SAVE' | translate">
              </welion-button>
            </div>
          </div> -->
        </div>
      </div>

      <div fxFlexOffset="16px" fxLayout="column">
        <!-- Società -->
        <h5 class="bold" translate="structures.round.attendances.modal.COMPANY"></h5>
        <h5>{{ selectedAttendance.company || '-' }}</h5>
      </div>

      <!-- Recapiti -->
      <div fxFlexOffset="12px" fxLayout="row" fxLayout.xs="column" fxLayoutAlign="space-between">
        <div fxLayout="column" class="full-width">
          <h5 class="bold" translate="structures.round.attendances.modal.CONTACT_DETAILS"></h5>
          <!-- Numero di telefono -->
          <div fxLayout="row" fxLayoutAlign="space-between center" class="full-width">
            <h5 *ngIf="!isEditPNView">{{ 'structures.round.attendances.modal.PHONE' | translate:
              { value: (selectedAttendance && selectedAttendance.phoneNumber || '-') }
              }}</h5>
            <welion-input *ngIf="isEditPNView" [bindValue]="phoneNumberUser" (onChange)="userPNChanged($event)"
              [placeholder]="'helpDesk.campaign.INSERT_PHONE_NUMBER' | translate">
            </welion-input>
            <welion-button *ngIf="!isEditPNView" (onClick)="editPN()" noUpperCase direction="right"
              type="on-edit-help-desk-user-data" [label]="'generic.EDIT' | translate">
            </welion-button>
            <welion-button *ngIf="isEditPNView" (onClick)="editPN(true)" noUpperCase direction="right"
              type="on-edit-help-desk-user-data" [label]="'generic.CLOSE' | translate">
            </welion-button>
            <welion-button [disabled]="!phoneNumberUser" *ngIf="isEditPNView"
              (onClick)="updateUser(false, false, false, true)" noUpperCase direction="right"
              type="on-edit-help-desk-user-data" [label]="'generic.SAVE' | translate">
            </welion-button>
          </div>
          <!-- Email -->
          <h5 fxFlexOffset="4px">{{ 'structures.round.attendances.modal.EMAIL' | translate:
            { value: (selectedAttendance && selectedAttendance.email || '-') }
            }}</h5>
        </div>
      </div>

      <!-- Stato -->
      <div fxFlexOffset="12px" fxLayout="column">
        <h5 class="bold" translate="structures.round.attendances.modal.STATUS"></h5>
        <div fxLayout="row" fxLayoutAlign="space-between center" class="full-width">
          <h5 *ngIf="!isEditStatusView">{{ ('status.' + selectedAttendance.status) | translate }}</h5>
          <welion-select *ngIf="isEditStatusView" [placeholder]="'admin.addOfflineAd.COMPANY_PLACEHOLDER' | translate"
            toTranslate [disabled]="isLoading" [items]="entitledStatuses" [searchable]="false"
            [selectedItem]="entitledStatus" (change)="onEntitledStatusChange($event)">
          </welion-select>
          <welion-button *ngIf="!isEditStatusView" (onClick)="editStatus()" noUpperCase direction="right"
            type="on-edit-help-desk-user-data" [label]="'generic.EDIT' | translate">
          </welion-button>
          <welion-button *ngIf="isEditStatusView" (onClick)="editStatus(true)" noUpperCase direction="right"
            type="on-edit-help-desk-user-data" [label]="'generic.CLOSE' | translate">
          </welion-button>
          <welion-button [disabled]="!phoneNumberUser" *ngIf="isEditStatusView"
            (onClick)="updateUser(false, false, false, false, true)" noUpperCase direction="right"
            type="on-edit-help-desk-user-data" [label]="'generic.SAVE' | translate">
          </welion-button>
        </div>
      </div>

      <!-- Sede -->
      <div fxFlexOffset="12px" fxLayout="column">
        <h5 class="bold" translate="structures.round.attendances.modal.VENUE"></h5>
        <div fxLayout="row" fxLayoutAlign="space-between center" class="full-width">
          <h5 *ngIf="!isEditVenueView">{{ (selectedAttendance.venue && selectedAttendance.venue.name) || '-' }}</h5>
          <welion-select *ngIf="isEditVenueView" mediumText [bindLabel]="'title'" [selectedItem]="selectedStructure"
            searchable [loading]="isLoadingStructureList" (change)="onStructureSelected($event)" [items]="structureList"
            (scroll)="fetchMoreStructures($event)" [placeholder]="'admin.addOfflineAd.VENUE_PLACEHOLDER' | translate"
            [noDataFoundLabel]="'structures.round.select.NO_STRUCTURE' | translate" [typeahead]="structureServive$"
            [attrAriaLabel]="'admin.addOfflineAd.VENUE_PLACEHOLDER' | translate">
          </welion-select>
        </div>
      </div>

      <div fxFlexOffset="12px" fxLayout="row" fxLayoutAlign="space-between center">
        <!-- Prenotazione slot in sola lettura -->
        <div fxLayout="column" class="full-width">
          <!-- Prenotazione giorno e slot -->
          <div fxLayout="row">
            <h5 class="bold primary" translate="structures.round.attendances.modal.RESERVATION"></h5>
            <h5 class="bold" fxFlexOffset="4px" translate="structures.round.attendances.modal.DAY_SLOT"></h5>
          </div>
          <div fxLayout="row" fxLayoutAlign="space-between center" class="full-width">
            <h5 *ngIf="!isEditPrenotationDateView && selectedAttendance.startDate && selectedAttendance.endDate">
              {{ (selectedAttendance.startDate | date: 'EEEE dd MMMM' | titlecase) +
              ' | ' +
              (selectedAttendance.startDate | date: 'HH:mm') +
              ' - ' +
              (selectedAttendance.endDate | date: 'HH:mm')}}</h5>
            <h5 *ngIf="!isEditPrenotationDateView && (!selectedAttendance.startDate || !selectedAttendance.endDate)">
              -
            </h5>
            <welion-datepicker *ngIf="isEditPrenotationDateView" class="full-width"
              placeholder="admin.addOfflineAd.PLACEHOLDER_PRENOTATION_DATE" [value]="prenotationDate"
              (dateChanged)="onPrenotationDateChange($event)" pickerType="both"></welion-datepicker>
          </div>
        </div>
      </div>

      <div fxFlexOffset="12px" fxLayout="row" class="full-width">
        <welion-button *ngIf="!isEditPrenotationDateView" (onClick)="editPrenotationDate(); editVenue()" noUpperCase
          direction="right" type="on-edit-help-desk-user-data" [label]="'generic.EDIT' | translate">
        </welion-button>
        <welion-button *ngIf="isEditPrenotationDateView" (onClick)="editPrenotationDate(true); editVenue(true)"
          noUpperCase direction="right" type="on-edit-help-desk-user-data" [label]="'generic.CLOSE' | translate">
        </welion-button>
        <welion-button [disabled]="!prenotationDate || !selectedStructure" *ngIf="isEditPrenotationDateView"
          (onClick)="updateUser(false, false, false, false, false, true)" noUpperCase direction="right"
          type="on-edit-help-desk-user-data" [label]="'generic.SAVE' | translate">
        </welion-button>
      </div>

      <div fxFlexOffset="16px" class="full-width" fxLayout="row" fxLayout.xs="column" fxLayoutAlign="space-between">
        <h5 class="bold" translate="structures.round.attendances.modal.DOCUMENTS"></h5>
        <!-- Carica un documento -->
        <label for="fileDrop" (click)="uploadDoc()">
          <p class="minimum-text primary-color cursor-pointer" fxFlexOffset="4px"
            translate="structures.round.attendances.modal.CHARGE_DOCUMENT"></p>
        </label>
      </div>
      <div fxHide fxLayout="row" fxLayoutAlign="start center" (click)="uploadDoc()">
        <input type="file" id="uploadFile" (change)="handleFileInput($event.target)" [accept]="acceptedMimeTypesDoc">
      </div>

      <div *ngIf="selectedAttendance.privacyDocs && selectedAttendance.privacyDocs.length" fxFlexOffset="16px"
        class="full-width" fxLayout="column">
        <div *ngFor="let privacyDoc of selectedAttendance.privacyDocs" class="full-width" fxLayout="row"
          fxLayout.xs="column" fxLayoutAlign="space-between">
          <h5 class="truncate-with-ellipsis width70">{{ privacyDoc.fileName }}</h5>
          <div fxLayout="row">
            <p (click)="deletePrivacyFile(privacyDoc)" class="minimum-text underlined cursor-pointer"
              translate="generic.DELETE"></p>
            <p (click)="downloadPrivacyFile(privacyDoc.url)" fxFlexOffset="12px"
              class="minimum-text underlined cursor-pointer" translate="generic.DOWNLOAD"></p>
          </div>
        </div>
      </div>

      <ng-container *ngIf="selectedAttendance.anamnesis">

        <div fxFlexOffset="16px" class="card-anamnesis-divider"></div>

        <div class="card-anamnesis" *ngFor="let anamnesis of selectedAttendance.anamnesis; let first = first"
          [fxFlexOffset]="first ? '24px' : '12px'">
          <!-- Età -->
          <!-- <h5 class="bold">{{ anamnesis.title }}</h5> -->
          <div fxLayout="row">
            <!-- Ha meno di 9 anni? -->
            <h5>{{ ('anamnesis.' + anamnesis.questionKey) | translate }}</h5>
            <!-- SI/NO -->
            <h5 fxFlexOffset="4px" class="primary bold"
              translate="structures.round.attendances.modal.{{ anamnesis.answer ? 'YES' : 'NO' }}">
            </h5>
          </div>
        </div>

      </ng-container>

      <!-- Descrizione -->
      <input-container fxFlexOffset="24px" [isFromTextArea]="true"
        [title]="'structures.round.attendances.modal.DESC' | translate">
        <welion-text-area [placeholder]="'structures.round.attendances.modal.DESC_PLACEHOLDER' | translate"
          [inputValue]="description">
        </welion-text-area>
      </input-container>

    </ng-container>

    <ng-container *ngIf="isLoading">
      <div fxFlex class="loader spinner-big"></div>
    </ng-container>

    <!-- Annulla -->
    <div fxFlexOffset="20px" fxLayout="row" fxLayoutAlign="space-between center">
      <welion-button ngClass.xs="double-button-mobile" (onClick)="closeAttendanceCard()" width="232px"
        label="generic.ANNULL" type="primary bordless" size="medium" [disabled]="isLoading">
      </welion-button>

      <!-- Salva -->
      <welion-button ngClass.xs="double-button-mobile" fxFlexOffset.xs="12px" buttonType="submit" width="186px"
        label="generic.SAVE" type="primary" size="medium" [disabled]="isLoading">
      </welion-button>
    </div>

    <div fxFlexOffset="20px"></div>

  </form>


</ng-template>

<!-- Modale per Pianificare/riapinificare ma hai superato data massima di prenotazione -->
<modal id="maxReservationModal" (onClose)="closeMaxReservationDateModal()" (onCancel)="closeMaxReservationDateModal()"
  (onConfirm)="confirmGoToEntitledReservation()" [cancelLabel]="'generic.CLOSE' | translate | uppercase"
  [confirmLabel]="'generic.CONFIRM' | translate | uppercase" [disableAll]="isLoadingBase">
  <modal-text-content [withScrolledBody]="true"
    [title]="'admin.campaignRoundDetails.maxReservationDate.TITLE' | translate"
    [text]="'admin.campaignRoundDetails.maxReservationDate.DESC' | translate">
  </modal-text-content>
</modal>

<!-- Modale per la cancellazione di un round -->
<modal id="deleteRound" (onClose)="closeDeleteRoundModal()" (onCancel)="closeDeleteRoundModal()"
  (onConfirm)="confirmDeleteCampaignRound()" [cancelLabel]="'generic.CLOSE' | translate | uppercase"
  [confirmLabel]="'generic.CONFIRM' | translate | uppercase" [disableAll]="isLoadingBase">
  <modal-text-content [withScrolledBody]="true" [title]="'admin.campaignRoundDetails.deleteRound.TITLE' | translate"
    [text]="'admin.campaignRoundDetails.deleteRound.RESET_WARNING' | translate"
    [subtitle]="'admin.campaignRoundDetails.deleteRound.SUBTITLE' | translate">
  </modal-text-content>
</modal>

<!-- Modale di annulla prenotazione -->
<modal id="deleteAdminBooking" (onClose)="closeDeleteBookingModal()" (onCancel)="closeDeleteBookingModal()"
  (onConfirm)="closeDeleteBookingModal(true)" [cancelLabel]="'generic.BACK' | translate | uppercase"
  [confirmLabel]="'generic.CONFIRM' | translate | uppercase" [disableAll]="isLoadingBase">
  <modal-text-content [withScrolledBody]="true"
    [title]="'admin.campaignRoundDetails.deleteAdminBooking.TITLE' | translate"
    [text]="'admin.campaignRoundDetails.deleteAdminBooking.RESET_WARNING' | translate: {value: attendanceToDelete ? (attendanceToDelete.surname + ' ' + attendanceToDelete.name) : ''}"
    [subtitle]="'admin.campaignRoundDetails.deleteRound.SUBTITLE' | translate">
  </modal-text-content>
</modal>

<!-- Modale per il cancella di tutti gli AD - cancella quelli prenotati e in prenotazione -->
<modal id="deleteAllEntitleds" (onClose)="closeDeleteAllEntitledsModal()" (onCancel)="closeDeleteAllEntitledsModal()"
  (onConfirm)="confirmDeleteAllEntitleds()" [cancelLabel]="'generic.CLOSE' | translate | uppercase"
  [confirmLabel]="'generic.CONFIRM' | translate | uppercase">
  <modal-text-content [withScrolledBody]="true"
    [title]="'admin.campaignRoundDetails.deleteAllEntitledsAdminModal.TITLE' | translate"
    [text]="'admin.campaignRoundDetails.deleteAllEntitledsAdminModal.RESET_WARNING' | translate"
    [subtitle]="'admin.campaignRoundDetails.deleteAllEntitledsAdminModal.SUBTITLE' | translate">
  </modal-text-content>
</modal>

<!-- Modale per importare AD offline -->
<modal id="importADOffline" class="modal-upload" (onClose)="closeImportADOfflineModal()"
  (onCancel)="closeImportADOfflineModal()" (onConfirm)="uploadADOfflineFile(true)"
  [confirmLabel]="'generic.IMPORT_LIST' | translate | uppercase"
  [confirmBtnDisabled]="isConfirmUploadADOfflineBtnDisabled()">

  <div fxLayout="column" class="full-width">

    <h2 fxFlexOffset="20px" class="small center" translate="admin.campaignRoundDetails.importADOffline.TITLE"></h2>

    <p class="modal-desc" translate="admin.campaignRoundDetails.importADOffline.DESC"></p>

    <div fxFlexOffset="10px" fxLayout="row" fxLayoutAlign="center">
      <welion-button fxFlex="200px" label="component.modalUpload.DOWNLOAD" type="primary bordless" size="medium"
        (onClick)="fileImportPeopleOffline()">
      </welion-button>
    </div>

    <!-- Carica tracciato con l’elenco delle persone da includere -->
    <div fxFlexOffset="12px" fxLayout="column">
      <input-container fromEuropUpload isObbligatory isWithoutInfoIcon
        instructions="component.modalUpload.UPLOAD_DESC_OTHER" [title]="('component.modalUpload.UPLOAD_PEOPLE_TITLE')">

        <welion-upload onlyXLSX [isToReset]="isToResetUploadComponent" (onUpload)="saveADOfflineFile($event)">
        </welion-upload>
      </input-container>
    </div>

    <div fxFlexOffset="20px"></div>
  </div>
</modal>

<modal id="addEntitledErrorsModal" (onClose)="closeAddEntitledErrorsModal()" (onCancel)="closeAddEntitledErrorsModal()"
  [cancelLabel]="'generic.CLOSE' | translate | uppercase">
  <div class="modal-entitled-errors" fxLayout="column" fxLayoutAlign="center center">
    <div class="title-container" fxLayout="column" fxLayoutAlign="center center">
      <h3 tabindex="0" translate="admin.addOfflineAd.ERROR_ADD_OFFLINE_ENTITLED_MODAL_TITLE"></h3>
    </div>

    <div fxLayout="column" fxLayoutAlign="start start" class="full-width scrollable-table">
      <div class="full-width">
        <div fxLayout="column" fxLayoutAlign="center center" class="full-width">
          <table class="primary-table modal-errors-add-entitleds">
            <thead>
              <tr>
                <th>
                  <p tabindex="0" class="header-title bold" translate="admin.addOfflineAd.NAME"></p>
                </th>
                <th>
                  <p tabindex="0" class="header-title bold" translate="admin.addOfflineAd.COD_FISC"></p>
                </th>
                <th>
                  <p tabindex="0" class="header-title bold" translate="admin.addOfflineAd.REVELATE_ERROR"></p>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let error of addOfflineEntitledErrors">
                <td>
                  <p tabindex="0" class="header-title">{{ error.name || '-' }}</p>
                </td>
                <td>
                  <p tabindex="0" class="header-title">
                    {{ error.cf || '-'}}</p>
                </td>
                <td>
                  <p tabindex="0" class="header-title">
                    {{('admin.addOfflineAd.addEntitledErrors.' + error.reason | translate) || '-'}}</p>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</modal>
import { Component, OnDestroy } from '@angular/core';
import { Store } from '@ngrx/store';
import { combineLatest, Subject } from 'rxjs';

import * as fromApp from '../../ngrx/app.reducers';
import * as AuthActions from '../ngrx/auth.actions';
import * as CoreActions from "../../core/ngrx/core.actions";

import { TranslateService } from '@ngx-translate/core';
import { RedirectService } from 'src/app/shared/services/redirect.service';
import { Lang } from 'atfcore-commonclasses';
import { ActivatedRoute } from '@angular/router';
import { takeUntil } from 'rxjs/operators';
import { getModalMessageData, getWarningModal } from 'src/app/utils/utils';

@Component({
  selector: 'app-login-impersonation',
  templateUrl: './login-impersonation.component.html',
  styleUrls: ['./login-impersonation.component.scss']
})
export class LoginImpersonationComponent implements OnDestroy {
  isFetchingLangs: boolean = false;
  langs?: Lang[];

  impersonationToken: string = '';

  private _unsubscribeSignal$: Subject<void> = new Subject();

  constructor(
    private store: Store<fromApp.AppState>,
    private redirectService: RedirectService,
    private route: ActivatedRoute,
    public translate: TranslateService) {

    // Sto in ascolto di quando il globalApplicationData cambia, così da sapere quando ci sono le lingue disponibili poiché, se non ci fossero, il pulsante per il login sarebbe disabilitato
    combineLatest([
      this.store.select(fromApp.getAvailableLangs),
      this.store.select(fromApp.isFetchingLangs)
    ])
      .pipe(takeUntil(this._unsubscribeSignal$.asObservable()))
      .subscribe(
        ([langs, isFetchingLangs]) => {
          this.langs = langs;
          this.isFetchingLangs = isFetchingLangs;
        });
  }

  ngOnInit() {
    this.impersonationToken = this.route.snapshot.paramMap.get('impersonationToken') || '';

    if (this.impersonationToken) {
      sessionStorage.setItem("impersonationLogin", this.impersonationToken);
      this.store.dispatch(AuthActions.ImpersonationLogin({
        impersonationToken: this.impersonationToken
      }));
    } else {
      this.redirectService.goToLogin();
    }
  }

  ngOnDestroy() {
    this._unsubscribeSignal$.next();
    this._unsubscribeSignal$.unsubscribe();
  }

  /**
   * @description Mostra una modale con titolo e messaggio
   * @param modalId
   * @param title
   * @param message
   */
  dispatchMessageModal(title: string, message: string) {
    const messageObj = getModalMessageData(this.translate, title, message);
    this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
  }

  dispatchModal(modalId: string, error: string) {
    const messageObj = getWarningModal(this.translate, modalId, error);
    this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
  }
}

import { NgModule, Injectable } from '@angular/core';
import { HeaderComponent } from './header/header.component';
import { SharedModule } from '../shared/shared.module';
import { UrlService } from '../shared/services/url.service';
import { RedirectService } from '../shared/services/redirect.service';
import { AuthService } from '../auth/services/auth.service';
import { AuthGuard } from '../auth/services/auth-guard.service';
import { CompatibilityGuard } from '../shared/services/compatibility-guard.service';
import { LoginGuard } from '../shared/services/login-guard.service';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { CompatibilityErrorComponent } from './compatibility-error/compatibility-error.component';
import { LangsService } from './services/langs.service';
import { AppTranslateModule } from '../translate/translate.module';
import { NgxPaginationModule } from 'ngx-pagination';
import { OwlDateTimeIntl, OWL_DATE_TIME_FORMATS, OWL_DATE_TIME_LOCALE, OwlMomentDateTimeModule } from '@busacca/ng-pick-datetime';
import { PageNotAuthorizedComponent } from './page-not-authorized/page-not-authorized.component';
import { AdminGuard } from '../shared/services/admin-guard.service';
import { UserGuard } from '../shared/services/user-guard.service';
import { StructureGuard } from '../shared/services/structure-guard.service';
import { ProfileComponent } from './profile/profile.component';
import { DefaultPageComponent } from './defaultPage/default-page.component';
import { GenericErrorComponent } from './generic-error/generic-error.component';
import { PrivacyGuard } from '../shared/services/privacy-guard.service';
import { SidebarService } from '../shared/services/sidebar.service';
import { GoogleMapsAPIWrapper } from '@agm/core';
import { DatePipe, TitleCasePipe } from '@angular/common';
import { AnagService } from '../auth/services/anag.service';
import { HelpDeskGuard } from '../shared/services/helpDesk-guard.service';
import { ClientGuard } from '../shared/services/client-guard.service';
import { AdminOrLimitedAdminGuard } from '../shared/services/admin-or-limited-admin-guard.service';
import { A11yModule } from '@angular/cdk/a11y';

export const MY_NATIVE_FORMATS = {
    parseInput: 'DD/MM/YYYY',
    fullPickerInput: 'DD/MM/YYYY HH:mm',
    datePickerInput: 'DD/MM/YYYY',
    timePickerInput: 'HH:mm',
    monthYearLabel: 'MMMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY'
};
@Injectable()
export class DefaultIntl extends OwlDateTimeIntl {
    upSecondLabel = 'Aggiungi un secondo';
    downSecondLabel = 'Un secondo in meno';
    upMinuteLabel = 'Aggiungi un minuto';
    downMinuteLabel = 'Un minuto in meno';
    upHourLabel = 'Aggiungi un\'ora';
    downHourLabel = 'Un\'ora in meno';
    prevMonthLabel = 'Mese precedente';
    nextMonthLabel = 'Prossimo mese';
    prevYearLabel = 'Anno precedente';
    nextYearLabel = 'Prossimo anno';
    prevMultiYearLabel = '21 anni precedenti';
    nextMultiYearLabel = 'Prossimi 21 anni';
    switchToMonthViewLabel = 'Cambia visuale';
    switchToMultiYearViewLabel = 'Scegli mese e anno';
    cancelBtnLabel = 'Annulla';
    setBtnLabel = 'Conferma';
    rangeFromLabel = 'Da';
    rangeToLabel = 'A';
    hour12AMLabel = 'AM';
    hour12PMLabel = 'PM';
}

@NgModule({
    declarations: [
        HeaderComponent,
        PageNotFoundComponent,
        PageNotAuthorizedComponent,
        CompatibilityErrorComponent,
        GenericErrorComponent,
        ProfileComponent,
        DefaultPageComponent
    ],
    imports: [
        A11yModule,
        SharedModule,
        AppTranslateModule,
        NgxPaginationModule,
        OwlMomentDateTimeModule
    ],
    exports: [
        HeaderComponent,
        OwlMomentDateTimeModule
    ],
    providers: [
        UrlService,
        RedirectService,
        AuthService,
        AnagService,
        AuthGuard,
        CompatibilityGuard,
        LoginGuard,
        AdminGuard,
        AdminOrLimitedAdminGuard,
        SidebarService,
        GoogleMapsAPIWrapper,
        UserGuard,
        StructureGuard,
        HelpDeskGuard,
        ClientGuard,
        LangsService,
        PrivacyGuard,
        DatePipe,
        TitleCasePipe,
        { provide: OWL_DATE_TIME_LOCALE, useValue: 'it' },
        { provide: OWL_DATE_TIME_FORMATS, useValue: MY_NATIVE_FORMATS },
        { provide: OwlDateTimeIntl, useClass: DefaultIntl }
    ]
})
export class CoreModule { }

import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-accenture-login-saml-error',
  templateUrl: './accenture-login-saml-error.component.html',
  styleUrls: ['./accenture-login-saml-error.component.scss']
})
export class AccentureLoginSamlErrorComponent {
  error: any;

  constructor(public translate: TranslateService,
    private router: Router) {
    const specificError = this.router.parseUrl(this.router.routerState.snapshot.url).queryParamMap.get("error");
    if (specificError) {
      this.error = {
        specificError: specificError
      };
    }
  }
}

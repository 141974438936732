import { ActivatedRoute } from "@angular/router";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { Component, OnDestroy, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Observable, Subject } from 'rxjs';
import { Store } from "@ngrx/store";
import { takeUntil } from "rxjs/operators";

import * as fromApp from "../../../ngrx/app.reducers";
import * as CoreActions from "../../../core/ngrx/core.actions";

import { RedirectService } from 'src/app/shared/services/redirect.service';
import { getWarningModal } from "src/app/utils/utils";
import { ScrollTo } from "src/app/core/services/scroll-to.service";
import { SenecaResponse } from "atfcore-commonclasses/bin/classes/common";
import { RentService } from "src/app/structure/services/rent.service";
import { InfoCampaign, InfoRound } from "src/commonclasses";
import { DropdownItem } from "src/app/shared/models/dropdown.model";

@Component({
  selector: 'app-admin-add-campaign-round',
  templateUrl: './addCampaignRound.component.html',
  styleUrls: ['./addCampaignRound.component.scss']
})
export class AdminAddCampaignRoundComponent implements OnInit, OnDestroy {
  isLoading: boolean = false;

  campaignId: string = '';
  roundId: string = '';
  campaign?: InfoCampaign;

  round?: InfoRound;

  addRoundForm = new FormGroup({
    'name': new FormControl(undefined, Validators.required),
    'startDate': new FormControl(undefined, Validators.required),
    'endDate': new FormControl(undefined, Validators.required),
    'minReservationEndDate': new FormControl(undefined, Validators.required),
    'maxReservationEndDate': new FormControl(undefined, Validators.required),
    'selectedMaxCancelDate': new FormControl(undefined, Validators.required),
    'selectedStructureMaxEditlDate': new FormControl(undefined, Validators.required),
    'selectedOtherStructureMaxEditDate': new FormControl(undefined, Validators.required)
  });

  get name(): FormControl {
    return this.addRoundForm.get('name') as FormControl;
  }

  get startDate(): FormControl {
    return this.addRoundForm.get('startDate') as FormControl;
  }

  get endDate(): FormControl {
    return this.addRoundForm.get('endDate') as FormControl;
  }

  get minReservationEndDate(): FormControl {
    return this.addRoundForm.get('minReservationEndDate') as FormControl;
  }

  get maxReservationEndDate(): FormControl {
    return this.addRoundForm.get('maxReservationEndDate') as FormControl;
  }

  get selectedMaxCancelDate(): FormControl {
    return this.addRoundForm.get('selectedMaxCancelDate') as FormControl;
  }

  get selectedStructureMaxEditlDate(): FormControl {
    return this.addRoundForm.get('selectedStructureMaxEditlDate') as FormControl;
  }

  get selectedOtherStructureMaxEditDate(): FormControl {
    return this.addRoundForm.get('selectedOtherStructureMaxEditDate') as FormControl;
  }

  private _unsubscribeSignal$: Subject<void> = new Subject();

  maxCancelDateOptions: DropdownItem[] = [
    {
      id: '0',
      name: 'admin.addRound.maxDateOptions.DISABLED'
    }, {
      id: '1',
      name: 'admin.addRound.maxDateOptions.1',
      content: '1'
    }, {
      id: '2',
      name: 'admin.addRound.maxDateOptions.2',
      content: '2'
    }, {
      id: '3',
      name: 'admin.addRound.maxDateOptions.3',
      content: '3'
    }, {
      id: '4',
      name: 'admin.addRound.maxDateOptions.4',
      content: '4'
    }, {
      id: '5',
      name: 'admin.addRound.maxDateOptions.5',
      content: '5'
    }, {
      id: '6',
      name: 'admin.addRound.maxDateOptions.6',
      content: '6'
    }, {
      id: '7',
      name: 'admin.addRound.maxDateOptions.7',
      content: '7'
    }, {
      id: '8',
      name: 'admin.addRound.maxDateOptions.8',
      content: '8'
    }, {
      id: '9',
      name: 'admin.addRound.maxDateOptions.9',
      content: '9'
    }, {
      id: '10',
      name: 'admin.addRound.maxDateOptions.10',
      content: '10'
    }
  ];

  constructor(
    private route: ActivatedRoute,
    private appStore: Store<fromApp.AppState>,
    private translate: TranslateService,
    private redirectService: RedirectService,
    private scrollTo: ScrollTo,
    private rentService: RentService
  ) {
  }

  ngOnInit() {
    this.scrollTo.header();

    this.campaignId = this.route.snapshot.paramMap.get('campaignId') || '';
    this.roundId = this.route.snapshot.paramMap.get('roundId') || '';

    if (this.campaignId) {
      this.isLoading = true;
      this.getCampaign();
    }
  }

  onSelectedMaxCancelDateChanged(value: DropdownItem) {
    this.selectedMaxCancelDate.setValue(value);
  }

  onSelectedStructureMaxEditlDateChanged(value: DropdownItem) {
    this.selectedStructureMaxEditlDate.setValue(value);
  }

  onSelectedOtherStructureMaxEditDateChanged(value: DropdownItem) {
    this.selectedOtherStructureMaxEditDate.setValue(value);
    this.selectedOtherStructureMaxEditDate
  }


  getCampaign() {
    this.rentService.getCampaign(this.campaignId)
      .pipe(takeUntil(this._unsubscribeSignal$.asObservable()))
      .subscribe((result) => {
        if (!result || result.error) {
          this.dispatchWarningModal('044', result.error || '');
          this.isLoading = false;
        } else if (result.response) {
          this.campaign = result.response;

          if (this.roundId) {
            this.getRound();
          } else {
            this.selectedMaxCancelDate.setValue(this.maxCancelDateOptions[0]);
            this.selectedStructureMaxEditlDate.setValue(this.maxCancelDateOptions[0]);
            this.selectedOtherStructureMaxEditDate.setValue(this.maxCancelDateOptions[0]);
            this.isLoading = false;
          }
        }
      }, (err) => {
        if (err && err.message) {
          this.dispatchWarningModal('045', err.message);
        }
        this.isLoading = false;
      });
  }

  getRound() {
    this.rentService.getRound(this.roundId)
      .pipe(takeUntil(this._unsubscribeSignal$.asObservable()))
      .subscribe((result) => {
        if (!result || result.error) {
          this.dispatchWarningModal('044', result.error || '');
        } else if (result.response) {
          this.round = result.response;
          this.name.setValue(this.round.title);
          this.startDate.setValue(new Date(this.round.startDate));
          this.endDate.setValue(new Date(this.round.endDate));
          this.minReservationEndDate.setValue(new Date(this.round.minReservationEndDate));
          this.maxReservationEndDate.setValue(new Date(this.round.maxReservationEndDate));

          // Pre-seleziono i valori delle option della data massima di cancellazione e di modifica
          let preSelectedMaxAllowedReservationDeletionDate = this.maxCancelDateOptions[0];

          if (this.round && this.round.maxAllowedReservationDeletionDate) {
            for (let i = 0, optionsLength = this.maxCancelDateOptions.length; i < optionsLength; i++) {
              if (this.maxCancelDateOptions[i].id === this.round.maxAllowedReservationDeletionDate.toString()) {
                preSelectedMaxAllowedReservationDeletionDate = this.maxCancelDateOptions[i];

                break;
              }
            }
          }

          let preSelectedMaxAllowedReservationMovingDate = this.maxCancelDateOptions[0];

          if (this.round && this.round.maxAllowedReservationMovingDate) {
            for (let i = 0, optionsLength = this.maxCancelDateOptions.length; i < optionsLength; i++) {
              if (this.maxCancelDateOptions[i].id === this.round.maxAllowedReservationMovingDate.toString()) {
                preSelectedMaxAllowedReservationMovingDate = this.maxCancelDateOptions[i];

                break;
              }
            }
          }

          let preselectOtherStructureMaxDate = this.maxCancelDateOptions[0];

          if (this.round && this.round.maxAllowedReservationMovingDateOtherVenue) {
            for (let i = 0, optionsLength = this.maxCancelDateOptions.length; i < optionsLength; i++) {
              if (this.maxCancelDateOptions[i].id === this.round.maxAllowedReservationMovingDateOtherVenue.toString()) {
                preselectOtherStructureMaxDate = this.maxCancelDateOptions[i];
                break;
              }
            }
          }

          this.selectedMaxCancelDate.setValue(preSelectedMaxAllowedReservationDeletionDate);
          this.selectedStructureMaxEditlDate.setValue(preSelectedMaxAllowedReservationMovingDate);
          this.selectedOtherStructureMaxEditDate.setValue(preselectOtherStructureMaxDate)
        }
        this.isLoading = false;
      }, (err) => {
        if (err && err.message) {
          this.dispatchWarningModal('045', err.message);
        }
        this.isLoading = false;
      });
  }

  ngOnDestroy() {
    this._unsubscribeSignal$.next();
    this._unsubscribeSignal$.unsubscribe();
  }

  /**
   * @description Va all'homepage delle SS
   */
  goBack() {
    if (this.roundId) {
      this.redirectService.goToAdminCampaignRoundTabVenues(this.campaignId, this.roundId);
    } else {
      this.redirectService.goToAdminCampaignTabRounds(this.campaignId);
    }
  }

  goToFAQ() {

  }

  confirmAddRound() {
    if (this.addRoundForm.valid) {

      const startDate = (this.startDate.value as Date).getTime();
      const endDate = (this.endDate.value as Date).getTime();
      const minReservationEndDate = (this.minReservationEndDate.value as Date).getTime();
      const maxReservationEndDate = (this.maxReservationEndDate.value as Date).getTime();

      if (startDate > endDate || minReservationEndDate > maxReservationEndDate) {
        this.dispatchWarningModal('555', 'DATE_MISMATCH');
        return;
      }

      if (minReservationEndDate > startDate) {
        this.dispatchWarningModal('555', 'WRONG_DATE');
        return;
      }

      this.isLoading = true;

      let request: Observable<SenecaResponse<boolean>> = this.round ?
        this.rentService.updateRoundForAdmin(
          this.roundId,
          this.name.value,
          this.campaignId,
          this.minReservationEndDate.value,
          this.maxReservationEndDate.value,
          this.startDate.value,
          this.endDate.value,
          parseInt(this.selectedMaxCancelDate.value.id),
          parseInt(this.selectedStructureMaxEditlDate.value.id),
          parseInt(this.selectedOtherStructureMaxEditDate.value.id)
        ) :
        this.rentService.createRoundForAdmin(
          this.name.value,
          this.campaignId,
          this.minReservationEndDate.value,
          this.maxReservationEndDate.value,
          this.startDate.value,
          this.endDate.value,
          parseInt(this.selectedMaxCancelDate.value.id),
          parseInt(this.selectedStructureMaxEditlDate.value.id),
          parseInt(this.selectedOtherStructureMaxEditDate.value.id)
        );

      request
        .pipe(takeUntil(this._unsubscribeSignal$.asObservable()))
        .subscribe((result) => {
          if (!result || result.error) {
            this.dispatchWarningModal('041', result.error || '');
          } else if (result.response) {
            if (this.roundId) {
              this.redirectService.goToAdminCampaignRoundTabVenues(this.campaignId, this.roundId);
            } else {
              this.redirectService.goToAdminCampaignTabRounds(this.campaignId);
            }
          }
          this.isLoading = false;
        }, (err) => {
          if (err && err.message) {
            this.dispatchWarningModal('042', err.message);
          }
          this.isLoading = false;
        });
    }
  }

  onStartDateChange(date: Date) {
    date = new Date(date);
    date.setHours(0, 0, 0, 0);
    this.startDate.setValue(new Date(date));
  }

  onEndDateChange(date: Date) {
    date = new Date(date);
    date.setHours(23, 59, 59, 0);
    this.endDate.setValue(new Date(date));
  }

  onMinReservationEndDateChange(date: Date) {
    date = new Date(date);
    date.setHours(0, 0, 0, 0);
    this.minReservationEndDate.setValue(new Date(date));
  }

  onMaxReservationEndDateChange(date: Date) {
    date = new Date(date);
    date.setHours(23, 59, 59, 0);
    this.maxReservationEndDate.setValue(new Date(date));
  }

  /**
   * @description Mostra una modale con un messaggio di errore
   * @param modalId
   * @param error
   */
  dispatchWarningModal(modalId: string, error: string) {
    const messageObj = getWarningModal(this.translate, modalId, error);
    this.appStore.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
  }

}

import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { DropdownItem } from '../../models/dropdown.model';

@Component({
  selector: 'welion-dropdown-item',
  templateUrl: 'welion-dropdown-item.component.html',
  styleUrls: ['./welion-dropdown-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class WelionDropdownItemComponent implements OnInit, OnChanges {
  @Input() item: DropdownItem = {
    id: '',
    name: '',
    avatar: '',
    description: '',
    disabled: false,
    initials: '',
    icon: '',
    isChecked: false
  };
  @Input() notSeeDescription: boolean = false;
  @Input() isCheckbox: boolean = false;
  @Input() disabled: boolean = false;
  @Input() isMenuItem?: boolean;
  @Input() index!: number;
  @Input() sidenavOpen: boolean | null = false;
  @Output() onItemCheck: EventEmitter<any> = new EventEmitter<any>();

  @ViewChild('firstChild') public firstItem: any;

  constructor() { }

  ngOnChanges(changes: SimpleChanges): void {
    setTimeout(() => {
      if (changes.sidenavOpen.currentValue && this.firstItem != undefined) {
        this.firstItem.nativeElement.focus();
      }
    },50);
  }

  ngOnInit() {
  }

  emitOnItemCheck() {
    this.onItemCheck.emit(this.item);
  }

}


import { createReducer, on, Action } from '@ngrx/store';
import * as CoreActions from './core.actions';

export interface ApplicationModalMessage {
    modalId?: string, // Required for error message
    text: string,
    title: string,
    subtitle?: string
    badge?: string,
    badgeDescr?: string,
    noBackground?: boolean,
    withScrolledBody?: boolean,
    applicationModalButtonCloseText?: string
}

export interface CoreState {
    globalApplicationData: any,
    isFetchingLangs: boolean,
    showApplicationLoader: boolean,
    isMainMenuSidebarOpened: boolean,
    redirectUrl: string,
    applicationLang: string,
    defaultLang: string,
    applicationModalMessage: ApplicationModalMessage
}

const initialState: CoreState = {
    globalApplicationData: null,
    isFetchingLangs: false,
    showApplicationLoader: false,
    isMainMenuSidebarOpened: false,
    redirectUrl: '',
    applicationLang: '',
    defaultLang: 'it',
    applicationModalMessage: { modalId: '', text: '', title: '' }
};

export const createCoreReducer = createReducer(
    initialState,
    on(CoreActions.SetApplicationModalMessage, (state, action) => {
        return {
            ...state,
            applicationModalMessage: action.payload
        };
    }),
    on(CoreActions.DeleteApplicationModalMessage, (state, action) => {
        return {
            ...state,
            applicationModalMessage: { modalId: '', title: '', text: '' }
        };
    }),
    on(CoreActions.SaveRedirectUrl, (state, action) => {
        return {
            ...state,
            redirectUrl: action.payload
        };
    }),
    on(CoreActions.RemoveRedirectUrl, (state, action) => {
        return {
            ...state,
            redirectUrl: ''
        };
    }),
    on(CoreActions.SetCoreApplicationData, (state, action) => {
        return {
            ...state,
            globalApplicationData: action.payload
        };
    }),
    on(CoreActions.SetApplicationLang, (state, action) => {
        return {
            ...state,
            applicationLang: action.payload
        };
    }),
    on(CoreActions.RemoveCoreApplicationData, (state, action) => {
        return {
            ...state,
            globalApplicationData: null
        };
    }),
    on(CoreActions.RemoveApplicationLang, (state, action) => {
        return {
            ...state,
            applicationLang: ''
        };
    }),
    on(CoreActions.GetAvailableLangs, (state, action) => {
        return {
            ...state,
            isFetchingLangs: true
        };
    }),
    on(CoreActions.GetAvailableLangsFinished, (state, action) => {
        return {
            ...state,
            isFetchingLangs: false
        };
    }),
    on(CoreActions.SetDefaultLang, (state, action) => {
        return {
            ...state,
            defaultLang: action.payload
        };
    }),
    on(CoreActions.OpenMainMenuSidebar, (state, action) => {
        return {
            ...state,
            isMainMenuSidebarOpened: true
        };
    }),
    on(CoreActions.CloseMainMenuSidebar, (state, action) => {
        return {
            ...state,
            isMainMenuSidebarOpened: false
        };
    })
)

export function coreReducer(state: CoreState | undefined, action: Action) {
    return createCoreReducer(state, action);
}

export const getGlobalApplicationData = (state: CoreState) => state.globalApplicationData;
export const isFetchingLangs = (state: CoreState) => state.isFetchingLangs;
export const showApplicationLoader = (state: CoreState) => state.showApplicationLoader;
export const isMainMenuSidebarOpened = (state: CoreState) => state.isMainMenuSidebarOpened;
export const getRedirectUrl = (state: CoreState) => state.redirectUrl;
export const getApplicationContext = (state: CoreState) => state.globalApplicationData.applicationContext;
export const getApplicationLang = (state: CoreState) => state.applicationLang;
export const getDefaultLang = (state: CoreState) => state.defaultLang;
export const getApplicationModalMessage = (state: CoreState) => state.applicationModalMessage;

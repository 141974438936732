import { NgModule } from '@angular/core';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';

import { AuthInterceptor } from '../shared/interceptors/auth.interceptor';
import { AdminComponent } from './admin.component';
import { AdminRoutingModule } from './admin-routing.module';
import { SharedModule } from '../shared/shared.module';
import { CoreModule } from '../core/core.module';
import { NgxPaginationModule } from 'ngx-pagination';
import { AdminHomeComponent } from './home/home.component';
import { adminReducer } from './ngrx/admin.reducers';
import { AdminEffects } from './ngrx/admin.effects';
import { RentService } from '../structure/services/rent.service';
import { AdminHealthFacilitiesComponent } from './healthFacility/healthFacilities/healthFacilities.component';
import { SupplierService } from './services/supplier.service';
import { AdminAddHealthFacilityComponent } from './healthFacility/addHealthFacility/addHealthFacility.component';
import { AdminHealthFacilityDetailsComponent } from './healthFacility/healthFacilityDetails/healthFacilityDetails.component';
import { AdminAddHealthFacilityReferenceComponent } from './healthFacility/addHealthFacilityReference/addHealthFacilityReference.component';
import { AdminAddHealthFacilityVenueComponent } from './healthFacility/addHealthFacilityVenue/addHealthFacilityVenue.component';
import { ImportService } from '../structure/services/import.service';
import { AdminClientsComponent } from './client/clients/clients.component';
import { AdminClientDetailsComponent } from './client/clientDetails/clientDetails.component';
import { AdminAddClientComponent } from './client/addClient/addClient.component';
import { AdminAddClientReferenceComponent } from './client/addClientReference/addClientReference.component';
import { AdminCampaignsComponent } from './campaign/campaigns/campaigns.component';
import { AdminAddCampaignComponent } from './campaign/addCampaign/addCampaign.component';
import { AdminCampaignDetailsComponent } from './campaign/campaignDetails/campaignDetails.component';
import { AdminAddCampaignRoundComponent } from './campaign/addCampaignRound/addCampaignRound.component';
import { AdminCampaignRoundDetailsComponent } from './campaign/campaignRoundDetails/campaignRoundDetails.component';
import { AdminAssignClientToCampaignComponent } from './campaign/assignClientToCampaign/assignClientToCampaign.component';
import { AdminCampaignCustomerDetailsComponent } from './campaign/campaignCustomerDetails/campaignCustomerDetails.component';
import { AdminAddCampaignCustomerCapComponent } from './campaign/addCampaignCustomerCap/addCampaignCustomerCap.component';
import { AdminAssignVenueToRoundComponent } from './campaign/assignVenueToRound/assignVenueToRound.component';
import { AdminRemoveVenueFromRoundComponent } from './campaign/removeVenueFromRound/removeVenueFromRound.component';
import { AdminCampaignRoundVenueDetailsComponent } from './campaign/campaignRoundVenueDetails/campaignRoundVenueDetails.component';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { AdminCampaignPendingRequestsComponent } from './campaign/campaignPendingRequests/campaignPendingRequests.component';
import { EngagementService } from './services/engagement.service';
import { AdminCampaignPendingRequestComponent } from './campaign/campaignPendingRequest/campaignPendingRequest.component';
import { ExportService } from '../structure/services/export.service';
import { AdminCampaignRoundRescheduleReservationComponent } from './campaign/campaignRoundRescheduleReservation/campaignRoundRescheduleReservation.component';
import { AdminImportEntitledsFromRoundComponent } from './campaign/importEntitledsFromRound/importEntitledsFromRound.component';
import { AnagService } from '../auth/services/anag.service';
import { EditVenueBookingDateComponent } from './campaign/editVenueBookingDate/editVenueBookingDate.component';
import { AdminAddAdOfflineComponent } from './campaign/addAdOffline/addAdOffline.component';

@NgModule({
  declarations: [
    AdminComponent,
    AdminHomeComponent,
    // Health facilities
    AdminHealthFacilitiesComponent,
    AdminAddHealthFacilityComponent,
    AdminHealthFacilityDetailsComponent,
    AdminAddHealthFacilityReferenceComponent,
    AdminAddHealthFacilityVenueComponent,
    // Clients
    AdminClientsComponent,
    AdminClientDetailsComponent,
    AdminAddClientComponent,
    AdminAddClientReferenceComponent,
    // Campaigns
    AdminCampaignsComponent,
    AdminAddCampaignComponent,
    AdminCampaignDetailsComponent,
    AdminAddCampaignRoundComponent,
    AdminCampaignRoundDetailsComponent,
    AdminCampaignRoundRescheduleReservationComponent,
    AdminAssignClientToCampaignComponent,
    AdminCampaignCustomerDetailsComponent,
    AdminAddCampaignCustomerCapComponent,
    AdminAssignVenueToRoundComponent,
    AdminRemoveVenueFromRoundComponent,
    AdminCampaignRoundVenueDetailsComponent,
    AdminImportEntitledsFromRoundComponent,
    AdminAddAdOfflineComponent,
    // Pending requests
    AdminCampaignPendingRequestsComponent,
    AdminCampaignPendingRequestComponent,
    EditVenueBookingDateComponent
  ],
  imports: [
    FormsModule,
    CommonModule,
    ReactiveFormsModule,
    AdminRoutingModule,
    SharedModule,
    CoreModule,
    NgxPaginationModule,
    InfiniteScrollModule,
    StoreModule.forFeature('admin', adminReducer),
    EffectsModule.forFeature([AdminEffects])
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    RentService,
    SupplierService,
    EngagementService,
    ExportService,
    ImportService,
    AnagService
  ]
})
export class AdminModule { }

import { createReducer, on, Action } from '@ngrx/store';
import * as fromApp from '../../ngrx/app.reducers';

export interface FeatureState extends fromApp.AppState {
  helpDesk: HelpDeskState
}

export interface HelpDeskState {
}

// Store iniziale
const initialState: HelpDeskState = {
};


export const createHelpDeskReducer = createReducer(
  initialState
)

export function helpDeskReducer(state: HelpDeskState | undefined, action: Action) {
  return createHelpDeskReducer(state, action);
}

import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-enel-logout-sso-completed',
  templateUrl: './enel-logout-sso-completed.component.html',
  styleUrls: ['./enel-logout-sso-completed.component.scss']
})
export class EnelLogoutSsoCompletedComponent {
  constructor(public translate: TranslateService) {
  }
}

<div class="page-content-wrapper">
    <!-- Header -->
    <div class="page-content-header">
        <div class="title-container">
            <p *ngIf="!isAdvancedDashboard" class="title" translate="admin.dashboard.PAGE_TITLE"></p>

            <!-- Dashboard avanzata per clienti -->
            <p *ngIf="isAdvancedDashboard" class="title" translate="admin.dashboard.PAGE_TITLE_ADV"></p>

            <p class="subtitle">{{'admin.dashboard.REAL_TIME_DATA' | translate}}</p>
        </div>

        <welion-button (onClick)="exportReport()" [disabled]="isDownloadingReport" type="primary" size="medium"
            label="admin.dashboard.actions.EXPORT_REPORT">
        </welion-button>
    </div>

    <!-- Filtri -->
    <div class="filters-container">
        <div class="title-content">
            <p class="title" translate="admin.dashboard.filters.TITLE"></p>
            <p class="reset" translate="admin.dashboard.actions.RESET_FILTERS" (click)="resetFilters()"></p>
        </div>
        <div class="select-container">
            <!-- Clienti -->
            <div *ngIf="isAdmin" class="filter-content">
                <p class="label" translate="admin.dashboard.filters.CLIENT"></p>
                <welion-select [selectedItem]="selectedFilters.client" [loading]="isLoadingClients"
                    (change)="onSelectClient($event, true)" [items]="filterList.clients" [searchable]="true"
                    [searchFn]="customSearch" [placeholder]="'admin.dashboard.filters.CLIENT_PLACEHOLDER' | translate"
                    [noDataFoundLabel]="'admin.dashboard.filters.NO_CLIENT' | translate"
                    [attrAriaLabel]="'admin.dashboard.filters.CLIENT_PLACEHOLDER' | translate">
                </welion-select>
            </div>

            <!-- Campagne -->
            <div class="filter-content">
                <p class="label" translate="admin.dashboard.filters.CAMPAIGN"></p>
                <welion-select [items]="filterList.campaigns" [selectedItem]="selectedFilters.campaign"
                    (change)="onSelectCampaign($event, true)" [loading]="isLoadingCampaigns" [searchable]="true"
                    [searchFn]="customSearch" [placeholder]="'admin.dashboard.filters.CAMPAIGN_PLACEHOLDER' | translate"
                    [disabled]="isLoadingCampaigns || isWithCurrentYearCampaign"
                    [noDataFoundLabel]="'admin.dashboard.filters.NO_CAMPAIGN' | translate">
                </welion-select>
            </div>

            <!-- Round -->
            <div class="filter-content">
                <p class="label" translate="admin.dashboard.filters.ROUND"></p>
                <welion-select [items]="filterList.rounds" [selectedItem]="selectedFilters.round"
                    (change)="onSelectRound($event, true)" [loading]="isLoadingRounds" [searchable]="true"
                    [searchFn]="customSearch" [placeholder]="'admin.dashboard.filters.ROUND_PLACEHOLDER' | translate"
                    [disabled]="isLoadingRounds || !selectedFilters || !selectedFilters.campaign || !selectedFilters.campaign.id"
                    [noDataFoundLabel]="'admin.dashboard.filters.NO_ROUNDS' | translate">
                </welion-select>
            </div>
        </div>
        <div *ngIf="isAdvancedDashboard || isAdmin" class="select-container">
            <!-- Tipo di utente -->
            <div class="filter-content max-width33">
                <p class="label" translate="admin.dashboard.filters.USER_TYPE"></p>
                <welion-select [items]="filterList.userTypes" [selectedItem]="selectedFilters.userType"
                    (change)="onSelectUserType($event, true)" [loading]="isLoadingUserTypes"
                    [placeholder]="'admin.dashboard.filters.USER_TYPE_PLACEHOLDER' | translate"
                    [disabled]="isLoadingUserTypes" [searchFn]="customSearch">
                </welion-select>
            </div>


        </div>
    </div>

    <ng-container *ngIf="isLoading()">
        <div class="loader-container">
            <div class="loader spinner-big"></div>
        </div>
    </ng-container>

    <ng-container *ngIf="!isLoading()">
        <div class="section-row">
            <!-- Percentuale completamento reporting -->
            <div class="percentage-container">
                <p class="title" translate="admin.dashboard.perc.TITLE"></p>
                <div class="data-container">
                    <div class="percentage-content divisor no-padding">
                        <p class="section-title" translate="admin.dashboard.perc.REGISTRATION"></p>
                        <p class="number">{{ '100%' }}</p>
                    </div>
                    <div class="percentage-content divisor">
                        <p class="section-title" translate="admin.dashboard.perc.BOOKING"></p>
                        <p class="number">{{ '100%' }}</p>
                    </div>
                    <div class="percentage-content">
                        <p class="section-title" translate="admin.dashboard.perc.PRESENCE"></p>
                        <p class="number">{{ getReportingDataPresence() + '%' }}</p>
                    </div>
                </div>
            </div>
        </div>

        <div class="section-row">
            <div class="section" style="position: relative;">
                <svg-icon fxFlexOffset="8px" (click)="openReservationGraphInfoModal()" src="assets/img/icons/info.svg"
                    class="medium-size cursor-pointer" [applyClass]="true">
                </svg-icon>
                <!-- Registrazioni alla piattaforma -->
                <dx-pie-chart #registrationChart id="registration" type="doughnut" [dataSource]="registrationGraph.data"
                    centerTemplate="centerTemplate" [palette]="biColor"
                    (onInitialized)="saveInstance($event, 'registrationGraph')">
                    <dxo-title [text]="'admin.dashboard.graph.REGISTRATION' | translate">
                        <dxo-font color="#333333" family="Metropolis" weight="600" size="20px">
                        </dxo-font>
                        <!--dxo-subtitle [text]="'dashboard.CHALLENGE_DESCR' | translate">
                            <dxo-font color="#727272" family="Lato" weight="300" size="13px">
                            </dxo-font>
                        </dxo-subtitle-->
                    </dxo-title>

                    <dxo-legend hoverMode="none" verticalAlignment="bottom" horizontalAlignment="center"></dxo-legend>

                    <dxi-series argumentField="status" valueField="total">
                        <dxo-small-values-grouping mode="topN" [topCount]="3"></dxo-small-values-grouping>
                    </dxi-series>
                    <dxo-tooltip [enabled]="true" [shared]="true" [customizeTooltip]="customizeTooltipLevel">
                    </dxo-tooltip>
                    <svg *dxTemplate="let pieChart of 'centerTemplate'">
                        <circle cx="131" cy="131" [attr.r]="32" fill="#826B58"></circle>
                        <text text-anchor="middle" style="font-size: 18px; font-weight: 600;" x="131" y="131"
                            fill="#fff">
                            <tspan x="131" y="137" class="pie-inside-text">{{ registrationGraph.total }}</tspan>
                        </text>
                    </svg>

                </dx-pie-chart>
                <!-- <div class="legend-added-registrations" fxLayout="column" fxLayoutAlign="center center">
                    <div class="brown-square"></div>
                    <p>Totale utenti caricati in piattaforma</p>
                </div> -->
            </div>
            <div class="section gray">
                <!-- Prenotazioni -->
                <svg-icon fxFlexOffset="8px" (click)="openBookingGraphInfoModal()" src="assets/img/icons/info.svg"
                    class="medium-size cursor-pointer" [applyClass]="true">
                </svg-icon>
                <dx-bar-gauge id="presence" [values]="bookingGraph.data" [startValue]="0"
                    [endValue]="bookingGraph.total" [palette]="['#BA3C33', '#F66156', '#FFC494']"
                    (onInitialized)="saveInstance($event, 'bookingGraph')">
                    <dxo-title [text]="'admin.dashboard.graph.BOOKING' | translate">
                        <dxo-font color="#333333" family="Metropolis" weight="600" size="20px">
                        </dxo-font>
                    </dxo-title>
                    <dxo-label [visible]="false"></dxo-label>
                    <dxo-tooltip [enabled]="true" [shared]="true" [customizeTooltip]="customizeTooltipBookingGraph">
                    </dxo-tooltip>
                    <dxo-legend [visible]="true" [customizeText]="getLegend" verticalAlignment="bottom"
                        horizontalAlignment="center">
                    </dxo-legend>
                </dx-bar-gauge>
            </div>
        </div>

        <div class="section-row">
            <div class="section">
                <!-- Presenze -->
                <dx-pie-chart id="presence" type="doughnut" [dataSource]="presenceGraph.data"
                    centerTemplate="centerTemplate" [palette]="triColor"
                    (onInitialized)="saveInstance($event, 'presenceGraph')">
                    <dxo-title [text]="'admin.dashboard.graph.PRESENCE' | translate">
                        <dxo-font color="#333333" family="Metropolis" weight="600" size="20px">
                        </dxo-font>
                        <dxo-subtitle [text]="'admin.dashboard.graph.PRESENCE_SUB' | translate">
                            <dxo-font color="#767676" family="Lato" weight="300" size="13px">
                            </dxo-font>
                        </dxo-subtitle>
                    </dxo-title>

                    <dxo-legend hoverMode="none" verticalAlignment="bottom" horizontalAlignment="center"></dxo-legend>

                    <dxi-series argumentField="status" valueField="total">
                        <dxo-small-values-grouping mode="topN" [topCount]="4"></dxo-small-values-grouping>
                    </dxi-series>
                    <dxo-tooltip [enabled]="true" [shared]="true" [customizeTooltip]="customizeTooltipLevel">
                    </dxo-tooltip>
                    <svg *dxTemplate="let pieChart of 'centerTemplate'">
                        <circle cx="131" cy="131" [attr.r]="32" fill="#826B58"></circle>
                        <text text-anchor="middle" style="font-size: 18px; font-weight: 600;" x="131" y="131"
                            fill="#fff">
                            <tspan x="131" y="137" class="pie-inside-text">{{ presenceGraph.total }}</tspan>
                        </text>
                    </svg>

                </dx-pie-chart>
            </div>
            <div class="section gray">
                <!-- Numeriche vaccini -->
                <dx-chart id="vaccine" [dataSource]="vaccineData" [rotated]="true" [palette]="numericColor"
                    (onInitialized)="saveInstance($event, 'vaccineData')">

                    <dxo-title [text]="'admin.dashboard.graph.VACCINE_NUMBER' | translate">
                        <dxo-font color="#333333" family="Metropolis" weight="600" size="20px">
                        </dxo-font>
                    </dxo-title>

                    <dxo-common-series-settings argumentField="status" type="bar" valueField="total"
                        [ignoreEmptyPoints]="true" [barPadding]="0.3">
                    </dxo-common-series-settings>
                    <dxo-series-template nameField="status"></dxo-series-template>

                    <dxi-value-axis [showZero]="true"></dxi-value-axis>

                    <dxo-argument-axis>
                        <dxo-label [visible]="false"></dxo-label>
                    </dxo-argument-axis>

                    <dxo-legend verticalAlignment="bottom" horizontalAlignment="center">
                    </dxo-legend>
                    <dxo-tooltip [enabled]="true" [shared]="true" [customizeTooltip]="customizeVaccineNumberTooltip">
                    </dxo-tooltip>
                </dx-chart>
            </div>
        </div>

        <div class="section-row" *ngIf="isAdvancedDashboard || isAdmin">
            <div class="section">
                <svg-icon fxFlexOffset="8px" (click)="openGenderInfoModal()" src="assets/img/icons/info.svg"
                    class="medium-size cursor-pointer" [applyClass]="true">
                </svg-icon>
                <!-- Genere -->
                <dx-chart id="genderType" [dataSource]="genderGraphData"
                    (onInitialized)="saveInstance($event, 'genderGraph')">
                    <dxo-title [text]="'admin.dashboard.graph.GENDER' | translate">
                        <dxo-font color="#333333" family="Metropolis" weight="600" size="20px">
                        </dxo-font>
                    </dxo-title>

                    <dxi-series valueField="male" [name]="'admin.dashboard.graph.users.MALE' | translate"
                        color="#76604F" [barWidth]="40">
                    </dxi-series>
                    <dxi-series valueField="female" [name]="'admin.dashboard.graph.users.FEMALE' | translate"
                        color="#FEBB8D" [barWidth]="40">
                    </dxi-series>

                    <dxo-common-series-settings argumentField="state" type="stackedBar">
                    </dxo-common-series-settings>

                    <dxo-legend verticalAlignment="bottom" horizontalAlignment="center" itemTextPosition="top">
                    </dxo-legend>
                    <dxo-tooltip [enabled]="true" [shared]="true" [customizeTooltip]="customizeTooltipSplit">
                    </dxo-tooltip>
                </dx-chart>
            </div>
            <!-- Tipologia utenti -->
            <div class="section gray">
                <dx-chart id="userType" [dataSource]="userTypeGraphData"
                    (onInitialized)="saveInstance($event, 'userTypeGraph')">
                    <dxo-title [text]="'admin.dashboard.graph.USER_TYPE' | translate">
                        <dxo-font color="#333333" family="Metropolis" weight="600" size="20px">
                        </dxo-font>
                    </dxo-title>

                    <dxi-series valueField="employee" [name]="'admin.dashboard.graph.users.EMPLOYEE' | translate"
                        color="#ba3c33" [barWidth]="40">
                    </dxi-series>
                    <dxi-series valueField="familiar" [name]="'admin.dashboard.graph.users.FAMILY' | translate"
                        color="#FC7200" [barWidth]="40">
                    </dxi-series>

                    <dxo-common-series-settings argumentField="state" type="stackedBar">
                    </dxo-common-series-settings>

                    <dxo-legend verticalAlignment="bottom" horizontalAlignment="center" itemTextPosition="top">
                    </dxo-legend>
                    <dxo-tooltip [enabled]="true" [shared]="true" [customizeTooltip]="customizeTooltipSplit">
                    </dxo-tooltip>
                </dx-chart>
            </div>
        </div>

        <div class="section-row" *ngIf="isAdvancedDashboard || isAdmin">
            <!-- Fasce d'età -->
            <div class="section gray full-width">
                <dx-chart id="ages" [dataSource]="agesGraphData" (onInitialized)="saveInstance($event, 'ages')">

                    <dxo-title [text]="'admin.dashboard.graph.AGES' | translate">
                        <dxo-font color="#333333" family="Metropolis" weight="600" size="18px">
                        </dxo-font>
                    </dxo-title>

                    <dxi-series valueField="registered"
                        [name]="('admin.dashboard.graph.users.REGISTRATION' | translate)" color="#FEBB8D">
                    </dxi-series>
                    <dxi-series valueField="booked" [name]="('admin.dashboard.graph.users.BOOKING' | translate)"
                        color="#BA3C33">
                    </dxi-series>
                    <dxi-series valueField="present" [name]="('admin.dashboard.graph.users.PRESENCE' | translate)"
                        color="#76604F">
                    </dxi-series>

                    <dxo-common-series-settings argumentField="label" type="bar" [barPadding]="0.2">
                    </dxo-common-series-settings>

                    <dxo-legend verticalAlignment="bottom" horizontalAlignment="center">
                    </dxo-legend>

                    <dxo-tooltip [enabled]="true" [shared]="true" [customizeTooltip]="customizeTooltipSplit">
                    </dxo-tooltip>
                </dx-chart>
            </div>

        </div>
    </ng-container>

    <div class="dashboard-table-container" *ngIf="isAdvancedDashboard || isAdmin">
        <div class="tab-container" *ngIf="selectedTab && selectedTab.id">
            <ng-container *ngFor="let tab of tabList">
                <tab-light [isCurrentTab]="selectedTab.id == tab.id" (onClick)="onTabClick(tab)" [tabTitle]="tab.title"
                    [attrAriaLabel]="tab.title" [isUppercase]="true">
                </tab-light>
            </ng-container>
        </div>

        <div class="table-title-container" *ngIf="selectedTab && selectedTab.id">
            <p class="table-title">{{ selectedTab.title }}</p>
            <!-- Ricerca testuale -->
            <ng-container *ngIf="selectedTab.id == 'structure' || selectedTab.id == 'society'">
                <welion-input fxFlex [bindValue]="tableSearchedText" (onChange)="changeTableSearchedText($event)"
                    (onIconClicked)="getTableData(true, true)"
                    [placeholder]="'admin.dashboard.table.SEARCH_BY_NAME' | translate" [disabled]="isLoadingTableData"
                    iconRight="assets/img/icons/icon-search.svg">
                </welion-input>
            </ng-container>
            <!-- Ricerca tramite data -->
            <ng-container *ngIf="selectedTab.id == 'date'">
                <welion-datepicker class="full-width" placeholder="admin.dashboard.table.SEARCH_BY_DATE"
                    [disabled]="isLoadingTableData" [value]="tableSearchDate"
                    (dateChanged)="changeTableSearchDate($event)">
                </welion-datepicker>
            </ng-container>
        </div>

        <div class="table-container">
            <ng-container *ngIf="!isLoadingTableData && selectedTab && selectedTab.id">
                <ng-container *ngIf="tableData.counter > 0 else noDataTable">
                    <!-- Tabella con le strutture -->
                    <ng-container *ngIf="selectedTab.id == 'structure'">
                        <table>
                            <thead>
                                <tr>
                                    <!--th translate="admin.dashboard.table.REGION"></th-->
                                    <th translate="admin.dashboard.table.PROVINCE"></th>
                                    <th translate="admin.dashboard.table.CITY"></th>
                                    <th translate="admin.dashboard.table.STRUCTURE_NAME"></th>
                                    <th translate="admin.dashboard.table.BOOKED"></th>
                                    <th translate="admin.dashboard.table.PRESENT"></th>
                                    <th translate="admin.dashboard.table.ABSENT"></th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let data of tableData.list">
                                    <!--td>{{ data.region }}</td-->
                                    <td>{{ data.province }}</td>
                                    <td>{{ data.city }}</td>
                                    <td>{{ data.name }}</td>
                                    <td>{{ data.attendances['RESERVED'] || 0 }}</td>
                                    <td>{{ data.attendances['YES'] || 0 }}</td>
                                    <td>{{ data.attendances['NO'] || 0}}</td>
                                </tr>
                            </tbody>
                        </table>
                    </ng-container>
                    <!-- Tabella con le date -->
                    <ng-container *ngIf="selectedTab.id == 'date'">
                        <table>
                            <thead>
                                <tr>
                                    <th translate="admin.dashboard.table.APPOINTMENT_DATE"></th>
                                    <th translate="admin.dashboard.table.BOOKED"></th>
                                    <th translate="admin.dashboard.table.PRESENT"></th>
                                    <th translate="admin.dashboard.table.ABSENT"></th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let data of tableData.list">
                                    <td>{{ data.date }}</td>
                                    <td>{{ data.reserved }}</td>
                                    <td>{{ data.present }}</td>
                                    <td>{{ data.absent }}</td>
                                </tr>
                            </tbody>
                        </table>
                    </ng-container>
                    <!-- Tabella con le società -->
                    <ng-container *ngIf="selectedTab.id == 'society'">
                        <table>
                            <thead>
                                <tr>
                                    <th translate="admin.dashboard.table.NAME"></th>
                                    <th translate="admin.dashboard.table.BOOKED"></th>
                                    <th translate="admin.dashboard.table.PRESENT"></th>
                                    <th translate="admin.dashboard.table.ABSENT"></th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let data of tableData.list">
                                    <td>{{ data.title }}</td>
                                    <td>{{ data.reserved }}</td>
                                    <td>{{ data.present }}</td>
                                    <td>{{ data.absent }}</td>
                                </tr>
                            </tbody>
                        </table>
                    </ng-container>

                </ng-container>
            </ng-container>

            <!-- Loader delle tabelle -->
            <ng-container *ngIf="isLoadingTableData">
                <div class="loader-container">
                    <div class="loader spinner-big"></div>
                </div>
            </ng-container>
        </div>

        <!-- Paginazione delle tabelle  -->
        <welion-paginator *ngIf="tableData" fxFlexOffset="20px" [isLoading]="isLoadingTableData"
            [paginationLength]="tableData.numRecords" [itemsCount]="tableData.counter"
            (onClickPage)="tablePaginationChanged($event)" class="full-width">
        </welion-paginator>
    </div>
</div>

<ng-template #noDataTable>
    <div fxLayout="column" fxLayoutAlign="start center">
        <svg-icon fxFlexOffset="40px" [applyClass]="true" src="assets/img/icons/note-health-facility-red.svg">
        </svg-icon>

        <!-- Nessun dato trovato -->
        <h3 fxFlexOffset="32px" translate="generic.NO_DATA_FOUND"></h3>

    </div>
</ng-template>


<modal id="bookingGrapInfohModal" class="bookingGrapInfohModal" (onClose)="closeBookingGraphInfoModal()"
    (onCancel)="closeBookingGraphInfoModal()" (onConfirm)="closeBookingGraphInfoModal()"
    [confirmLabel]="'generic.CLOSE' | translate | uppercase">
    <div fxLayout="column" fxLayoutAlign="start center" class="full-width">
        <h2 fxFlexOffset="24px" class="small primary"
            translate="admin.dashboard.indicatorsInfo.bookingGrapInfohModal.TITLE"></h2>
        <h5 fxFlexOffset="24px" class="margin-bottom24"
            [innerHTML]="'admin.dashboard.indicatorsInfo.bookingGrapInfohModal.CONTENT' | translate"></h5>
    </div>
</modal>

<modal id="reservationGrapInfohModal" class="bookingGrapInfohModal" (onClose)="closeReservationGraphInfoModal()"
    (onCancel)="closeReservationGraphInfoModal()" (onConfirm)="closeReservationGraphInfoModal()"
    [confirmLabel]="'generic.CLOSE' | translate | uppercase">
    <div fxLayout="column" fxLayoutAlign="start center" class="full-width">
        <h2 fxFlexOffset="24px" class="small primary"
            translate="admin.dashboard.indicatorsInfo.reservationGrapInfohModal.TITLE"></h2>
        <h5 fxFlexOffset="24px" class="margin-bottom24"
            [innerHTML]="'admin.dashboard.indicatorsInfo.reservationGrapInfohModal.CONTENT' | translate"></h5>
    </div>
</modal>

<modal id="genderInfoModal" class="bookingGrapInfohModal" (onClose)="closeGenderInfoModal()"
    (onCancel)="closeGenderInfoModal()" (onConfirm)="closeGenderInfoModal()"
    [confirmLabel]="'generic.CLOSE' | translate | uppercase">
    <div fxLayout="column" fxLayoutAlign="start center" class="full-width">
        <h2 fxFlexOffset="24px" class="small primary" translate="admin.dashboard.graph.GENDER"></h2>
        <h4 class="bold" translate="generic.INFOS"></h4>
        <h5 fxFlexOffset="24px" class="margin-bottom24"
            [innerHTML]="'admin.dashboard.graph.GENDER_MODAL_INFO' | translate"></h5>
    </div>
</modal>
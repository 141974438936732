<table>
  <thead>
    <tr>
      <th class="th-td">
        Col 1
      </th>
      <th class="th-td">
        Col 2
      </th>
      <th class="th-td">
        Col 3
      </th>
      <th *ngIf="isEdit"></th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td class="th-td">
        Dato di test
      </td>
      <td class="th-td">
        Dato di test
      </td>
      <td class="th-td">
        Dato di test
      </td>
      <td *ngIf="isEdit">
        <p class="text-default-link manage" translate="table.MANAGE" (click)="emitOnManageObjective('1')"></p>
      </td>
    </tr>
  </tbody>
</table>
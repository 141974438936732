import { ChangeDetectionStrategy, Component, Input, OnInit, EventEmitter, Output, TemplateRef, ContentChild } from '@angular/core';
import { ApplicationModalMessage } from 'src/app/core/ngrx/core.reducers';
import * as CoreActions from "../../../core/ngrx/core.actions";
import { Store } from '@ngrx/store';
import * as fromApp from "../../../ngrx/app.reducers";
import { coerceBooleanProperty } from '@angular/cdk/coercion';

@Component({
  selector: 'input-container',
  templateUrl: 'input-container.component.html',
  styleUrls: ['./input-container.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class InputContainerComponent implements OnInit {
  @Input() title: string = '';
  @Input() instructions: string = '';
  @Input() errorText: string = '';
  @Input() tooltipModalMessage?: ApplicationModalMessage;
  @Input() isLoading: boolean = false;

  @Input() isValued: any = false;
  @Input() numberCharsTextArea: number = 0;
  @Input() for!: string;
  @Input() id!: string;

  @Input() ariaLabelledbyId?: string;

  _onlyLabel: boolean = false;
  @Input() set onlyLabel(value: boolean | string) {
    this._onlyLabel = coerceBooleanProperty(value);
  };

  _isObbligatory: boolean = false;
  @Input() set isObbligatory(value: boolean | string) {
    this._isObbligatory = coerceBooleanProperty(value);
  };

  _isWithoutInfoIcon: boolean = false;
  @Input() set isWithoutInfoIcon(value: boolean | string) {
    this._isWithoutInfoIcon = coerceBooleanProperty(value);
  };

  _disabled: boolean = false;
  @Input() set disabled(value: boolean | string) {
    this._disabled = coerceBooleanProperty(value);
  };

  _readonly: boolean = false;
  @Input() set readonly(value: boolean | string) {
    this._readonly = coerceBooleanProperty(value);
  };

  _inputEditable: boolean = false;
  @Input() set inputEditable(value: boolean | string) {
    this._inputEditable = coerceBooleanProperty(value);
  };

  _isFromTextArea: boolean = false;
  @Input() set isFromTextArea(value: boolean | string) {
    this._isFromTextArea = coerceBooleanProperty(value);
  };

  _fromEuropUpload: boolean = false;
  @Input() set fromEuropUpload(value: boolean | string) {
    this._fromEuropUpload = coerceBooleanProperty(value);
  };

  _withTransparency: boolean = false;
  @Input() set withTransparency(value: string | boolean) {
    this._withTransparency = coerceBooleanProperty(value);
  }

  @Output() renderInputEditable = new EventEmitter<void>();

  @ContentChild('customLabel', { static: false }) customLabel?: TemplateRef<any>;

  constructor(private store: Store<fromApp.AppState>) { }

  ngOnInit() {
  }

  // Apre la modale info
  openModalMessage() {
    if (this.tooltipModalMessage) {
      this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: this.tooltipModalMessage }));
    }
  }

  // Rende l'input editabile
  inputToEdit() {
    this._inputEditable = false;
    this.renderInputEditable.emit();
  }
}

import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { UrlService } from "../../shared/services/url.service";
import { Observable } from "rxjs";
import { FamilyMemberTypes, MapById, SenecaResponse, UserAcknowledges, UserGroup, UserOptions } from 'atfcore-commonclasses';
import { handleFromRecord, handleNumRecords, handleTitle } from 'src/app/utils/utils';

export interface UserGroupAdmin extends UserGroup {
  totalAttendancesInGroup: number,
  processedAttendanceCount: number,
  assignedAttendanceCount: number,
  privateVenuecount: number,
  nonProcessedAttendanceCount: number,
  presentAttendancesCount: number
}

export interface ClientGroup extends UserGroup {
  campaignIds: string[]
}

@Injectable()
export class AnagService {
  serviceMediatorUrl: string;
  constructor(private urlService: UrlService, private http: HttpClient) {
    this.serviceMediatorUrl = this.urlService.getServiceMediatorUrl();
  }

  /**
   * Aggiunge un booking cap ad una campagna e cliente
   * @param {string} campaignId
   * @param {string} groupId
   * @param {number} capLimit - se non messo resetta il cap
   * @return {boolean}
   */
  addBookingCapByCampaignAndCustomerForAdmin(campaignId: string, groupId: string, capLimit?: number, canAddFamilyMembers?: boolean, familyMembersCap?: number, allowedFamilyMembers?: FamilyMemberTypes[], boughtShots?: number): Observable<SenecaResponse<boolean>> {
    return this.http.post<SenecaResponse<boolean>>(
      this.serviceMediatorUrl + 'add-booking-cap-by-campaign-and-customer-for-admin',
      {
        campaignId,
        groupId,
        capLimit,
        canAddFamilyMembers,
        familyMembersCap,
        allowedFamilyMembers,
        boughtShots
      }
    );
  }

  /**
   * Aggiunge dei clienti ad una campagna
   * @param campaignId
   * @param customerIds
   * @returns
   */
  addCustomersToCampaignForAdmin(campaignId: string, customerIds: string[]): Observable<SenecaResponse<boolean>> {
    return this.http.post<SenecaResponse<boolean>>(
      this.serviceMediatorUrl + 'add-customers-to-campaign-for-admin',
      {
        campaignId,
        customerIds
      }
    );
  }

  /**
   * Recupera una lista di clienti per admin
   * @param campaignId
   * @param ignoreCustomerInCampaignIds
   * @param title
   * @return {number} Retrieved data
   */
  countCustomersForAdmin(title?: string, campaignId?: string,
    ignoreCustomerInCampaignIds?: string): Observable<SenecaResponse<number>> {
    let httpParams = new HttpParams();

    if (campaignId) {
      httpParams = httpParams.append('campaignId', campaignId);
    }
    if (ignoreCustomerInCampaignIds) {
      httpParams = httpParams.append('ignoreCustomerInCampaignIds', ignoreCustomerInCampaignIds);
    }
    httpParams = handleTitle(httpParams, title);

    return this.http.get<SenecaResponse<number>>(
      this.serviceMediatorUrl + 'count-customers-for-admin', { params: httpParams }
    );
  }

  /**
   * Retrieve all userAcknowledges information (privacy acceptance, application tour completed, etc) accessing data by userId field
   * @return {UserAcknowledges} Retrieved data
   */
  getAllUserAcknowledges(): Observable<SenecaResponse<UserAcknowledges>> {
    return this.http.get<SenecaResponse<UserAcknowledges>>(
      this.serviceMediatorUrl + 'get-all-userAcknowledges'
    );
  }

  /**
   * Recupera il cliente dal gruppo con anche il limite del cap di ogni campagna a cui è stato impostato,
   * Formato con prefisso: MAX_BOOKING_CAP + idCampagna (con un replace di - con )
   * Ex: "MAX_BOOKING_CAP_65250aa7_ae6b_11eb_b38c_000d3aade8fd": 20
   * @param groupId Uno dei due è obbligatorio
   * @param supplierId
   * @return {ClientGroup}
   */
  getCustomersForAdmin(groupId?: string, supplierId?: string, withDataForCampaignId?: string): Observable<SenecaResponse<ClientGroup>> {
    let httpParams = new HttpParams();

    if (groupId) {
      httpParams = httpParams.append('groupId', groupId);
    }
    if (supplierId) {
      httpParams = httpParams.append('supplierId', supplierId);
    }
    if (withDataForCampaignId) {
      httpParams = httpParams.append('withDataForCampaignId', withDataForCampaignId);
    }

    return this.http.get<SenecaResponse<any>>(
      this.serviceMediatorUrl + 'get-customers-for-admin', { params: httpParams }
    );
  }

  /**
   * Recupera una lista di campagne
   * @param campaignId
   * @param fromRecord
   * @param numRecords
   * @param title
   * @param ignoreCustomerInCampaignIds
   * @return {UserGroupAdmin[]} Retrieved data
   */
  listCustomersForAdmin(fromRecord?: number, numRecords?: number, title?: string, campaignId?: string,
    ignoreCustomerInCampaignIds?: string): Observable<SenecaResponse<UserGroupAdmin[]>> {
    let httpParams = new HttpParams();
    httpParams = handleFromRecord(httpParams, fromRecord);
    httpParams = handleNumRecords(httpParams, numRecords);

    if (campaignId) {
      httpParams = httpParams.append('campaignId', campaignId);
    }

    if (ignoreCustomerInCampaignIds) {
      httpParams = httpParams.append('ignoreCustomerInCampaignIds', ignoreCustomerInCampaignIds);
    }

    httpParams = handleTitle(httpParams, title);

    return this.http.get<SenecaResponse<UserGroupAdmin[]>>(
      this.serviceMediatorUrl + 'list-customers-for-admin', { params: httpParams }
    );
  }

  /**
   * Recupera una lista di campagne per la dashboard
   * @return {MapById<<string>>} Retrieved data
   */
  getCustomerListForDashboard(): Observable<SenecaResponse<MapById<string>>> {
    let httpParams = new HttpParams();

    return this.http.get<SenecaResponse<MapById<string>>>(
      this.serviceMediatorUrl + 'get-customer-list-for-dashboard', { params: httpParams }
    );
  }

  /**
   * Rimuove dei clienti da una campagna
   * @param campaignId
   * @param customerIds
   * @returns
   */
  removeCustomersFromCampaignForAdmin(campaignId: string, customerIds: string[]): Observable<SenecaResponse<boolean>> {
    return this.http.post<SenecaResponse<boolean>>(
      this.serviceMediatorUrl + 'remove-customers-from-campaign-for-admin',
      {
        campaignId,
        customerIds
      }
    );
  }

  /**
   * Update all userAcknowledges information (privacy acceptance, application tour completed, etc) accessing data by userId field.
   * All fileds that are not specifically set to null are left unchanged: that is this method update the
   * passed fields, append new fileds and left unchanged non passed fields.
   * @param {UserAcknowledges} userAcknowledges
   * @param null
   */
  updateUserAcknowledges(userAcknowledges: UserAcknowledges): Observable<SenecaResponse<null>> {
    return this.http.post<SenecaResponse<null>>(
      this.serviceMediatorUrl + 'update-userAcknowledges', {
      userAcknowledges: userAcknowledges
    });
  }

  /**
   * Update all userOptions information (langCode, timezone, etc) accessing data by userId field.
   * All fileds that are not specifically set to null are left unchanged: that is this method update the
   * passed fields, append new fileds and left unchanged non passed fields.
   * @param {UserOptions} userOptions - Required by the action: The UserOptions object to update
   * @return {string} The new token for the logged user
   */
  updateUserOptions(userOptions: UserOptions): Observable<SenecaResponse<string>> {
    return this.http.post<SenecaResponse<string>>(
      this.serviceMediatorUrl + 'update-userOptions', {
      userOptions: userOptions
    });
  }

}

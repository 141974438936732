import { ActionReducerMap, createFeatureSelector, createSelector } from '@ngrx/store';

import * as fromProfile from '../core/profile/ngrx/profile.reducers';
import * as fromAuth from '../auth/ngrx/auth.reducers';
import * as fromCore from '../core/ngrx/core.reducers';
import * as fromStructure from '../structure/ngrx/structure.reducers';

export interface AppState {
  profile: fromProfile.ProfileState;
  auth: fromAuth.AuthState;
  core: fromCore.CoreState;
  structure: fromStructure.StructureState;
}

export const reducers: ActionReducerMap<AppState> = {
  profile: fromProfile.profileReducer,
  auth: fromAuth.authReducer,
  core: fromCore.coreReducer,
  structure: fromStructure.structureReducer
};

export const getAuthAppState = createFeatureSelector<fromAuth.AuthState>('auth');
export const getCoreAppState = createFeatureSelector<fromCore.CoreState>('core');
export const getProfileAppState = createFeatureSelector<fromProfile.ProfileState>('profile');
export const getStructureAppState = createFeatureSelector<fromStructure.StructureState>('structure');

export const getAuthState = createSelector(
  getAuthAppState,
  (state: fromAuth.AuthState) => state
);
export const getToken = createSelector(
  getAuthState,
  fromAuth.getToken
);
export const getUserAcknowledges = createSelector(
  getAuthState,
  (state: fromAuth.AuthState) => state.userAcknowledges
);

// Strutture
export const getStructureState = createSelector(
  getStructureAppState,
  (state: fromStructure.StructureState) => state
);

// Selettori relativi allo state del core
export const getCoreState = createSelector(
  getCoreAppState,
  (state: fromCore.CoreState) => state
);
export const isAuthenticated = createSelector(
  getAuthState,
  (state: fromAuth.AuthState) => state.authenticated
);
export const isFetchingLangs = createSelector(
  getCoreAppState,
  fromCore.isFetchingLangs
);
export const getApplicationModalMessage = createSelector(
  getCoreAppState,
  fromCore.getApplicationModalMessage
);
export const getRedirectUrl = createSelector(
  getCoreAppState,
  fromCore.getRedirectUrl
);
export const getApplicationContext = createSelector(
  getCoreAppState,
  fromCore.getApplicationContext
);
export const getGlobalApplicationData = createSelector(
  getCoreAppState,
  fromCore.getGlobalApplicationData
);
export const showApplicationLoader = createSelector(
  getCoreAppState,
  fromCore.showApplicationLoader
);
export const isMainMenuSidebarOpened = createSelector(
  getCoreAppState,
  fromCore.isMainMenuSidebarOpened
);
export const getApplicationLang = createSelector(
  getCoreAppState,
  fromCore.getApplicationLang
);
export const getDefaultLang = createSelector(
  getCoreAppState,
  fromCore.getDefaultLang
);

export const getProfileState = createSelector(
  getProfileAppState,
  (state: fromProfile.ProfileState) => state
);
export const getLoggedUser = createSelector(
  getProfileState,
  fromProfile.getLoggedUser
);
export const getIsStructure = createSelector(
  getProfileState,
  fromProfile.getIsStructure
);
export const getIsUser = createSelector(
  getProfileState,
  fromProfile.getIsUser
);
export const getIsAdmin = createSelector(
  getProfileState,
  fromProfile.getIsAdmin
);
export const getIsAdminOrLimitedAdmin = createSelector(
  getProfileState,
  fromProfile.getIsAdminOrLimitedAdmin
);
export const getIsLimitedAdmin = createSelector(
  getProfileState,
  fromProfile.getIsLimitedAdmin
);
export const getIsHelpDesk = createSelector(
  getProfileState,
  fromProfile.getIsHelpDesk
);
export const getIsClient = createSelector(
  getProfileState,
  fromProfile.getIsClient
);
export const getIsImpersonate = createSelector(
  getProfileState,
  fromProfile.getIsImpersonate
);
export const getAvailableLangs = createSelector(
  getProfileState,
  fromProfile.getAvailableLangs
);

import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ModalService } from 'src/app/shared/components/modal/modal.service';
import { DropdownItem } from 'src/app/shared/models/dropdown.model';
import { TabDossier, UserCard } from 'src/app/utils/classes.utils';
import { AuthService } from '../auth/services/auth.service';
import { catchError, debounceTime, map, switchMap, takeUntil } from 'rxjs/operators';
import { SenecaResponse } from "../../commonclasses";
import { Observable, Subject } from 'rxjs';
import { FormControl, Validators } from '@angular/forms';

@Component({
  selector: 'components-example',
  templateUrl: './components-example.component.html',
  styleUrls: ['./components-example.component.scss']
})
export class ComponentExampleComponent implements OnInit {

  textAreaNumberChars: number = 200;
  maxCharsTextArea: number = 200;

  radioList = [
    { id: '1', value: 'Volvo' },
    { id: '2', value: 'Saab' },
    { id: '3', value: 'Opel' },
    { id: '4', value: 'Audi' }
  ];
  radioSelected = {};
  selectedCar?: DropdownItem;
  cars: DropdownItem[] = [
    { id: '1', name: 'Volvo', isChecked: false },
    { id: '2', name: 'Saab', isChecked: false },
    { id: '3', name: 'Opel', isChecked: false },
    { id: '4', name: 'Audi', isChecked: false, disabled: true }
  ];
  noDataPlaceholder: string = '';

  tags = [
    'essere rassicurante',
    'innovation',
    'care',
    'prova tag con titolo lungo',
    'public speaking',
  ]
  tags2 = [
    'prova tag con titolo lungo',
    'care',
    'innovation',
    'essere rassicurante'
  ]
  exmpleUserForHeaderDossier = {
    forename: 'Davide',
    surname: 'Prova'
  }

  careCards = [{
    id: 'c',
    title: 'careCard.C',
    isChecked: true
  },
  {
    id: 'a',
    title: 'careCard.A',
    isChecked: false
  },
  {
    id: 'r',
    title: 'careCard.R',
    isChecked: false
  },
  {
    id: 'e',
    title: 'careCard.E',
    isChecked: false
  }]

  userCardData: UserCard[] = [];

  noData = false;

  tabLightTest = [
    {
      id: 'prova1',
      title: 'Prova1',
      isDisabled: false
    },
    {
      id: 'prova2',
      title: 'Prova2',
      isDisabled: true
    },
    {
      id: 'prova3',
      title: 'Prova3',
      isDisabled: true
    },
    {
      id: 'prova4',
      title: 'Prova4',
      isDisabled: true
    }
  ]

  tabDossier: TabDossier[] = [
    {
      id: '1',
      title: "Tab 1",
      attrAriaLabel: "Tab 1",
      isDisabled: false
    },
    {
      id: '2',
      title: "tab con titolo più lungo",
      attrAriaLabel: "Tab 2",
      isDisabled: true
    },
    {
      id: '3',
      title: "Tab 3",
      attrAriaLabel: "Tab 3",
      isDisabled: true,
      badgeNumberVisible: true,
      badgeNumber: 0
    },
    {
      id: '4',
      title: "Tab 4",
      attrAriaLabel: "Tab 4",
      isDisabled: true,
      badgeNumberVisible: true,
      badgeNumber: 10
    }
  ]

  currentTab = '1';

  leftLabelObject = {
    title: 'Sì'
  };

  rightLabelObject = {
    title: 'No'
  };

  sellersSrvForTaker: any;
  sellerListForTaker: any;
  isLoadingsellerListForTaker: boolean = false;
  selleresLoadedForTaker: number = 0;
  sellerSearchedTextForTaker: string = '';
  sellerSrvSubjectForTaker: any;
  resetsellerResultListForTaker: boolean = false;
  selectedTakerToChangeSellerPerson: any = {
    selectedSellerForTaker: null
  };

  inputTestDisabled: FormControl = new FormControl({ value: undefined, disabled: true });
  inputTestInvalid: FormControl = new FormControl('', Validators.required);

  constructor(
    public translate: TranslateService,
    public modalService: ModalService,
    private authService: AuthService
  ) {
  }

  ngOnInit() {
    this.sellersSrvForTaker = <any>{
      load: (itemsLoaded: number, itemsToLoad: number, filter: string) => {
        return new Observable<SenecaResponse<any[]>>(observable => {
          observable.next({
            error: '',
            response: [
              // Risposta di test
            ]
          });
          observable.complete();
        })
          .pipe(
            map((resultData: SenecaResponse<any[]>) => {
              if (resultData.error) {
                // this.toastr.error(this.translate.instant('errors.' + resultData.error));
              } else {
                if (resultData.response && resultData.response.length) {
                  let formattedData = [];

                  for (let k = 0, resLength = resultData.response.length; k < resLength; k++) {
                    let element = resultData.response[k];

                    let title = element.surname + ' ' + element.forename;
                    if (element.supplier && element.supplier.supplierName) {
                      title += ' - ' + element.supplier.supplierName;
                    }

                    formattedData.push({
                      supplierPersonId: element.supplierPersonId,
                      title: title,
                      reference: element
                    });

                    if (element.supplierPersonId && this.selectedTakerToChangeSellerPerson && this.selectedTakerToChangeSellerPerson.salesPerson && element.supplierPersonId === this.selectedTakerToChangeSellerPerson.salesPerson) {
                      this.selectedTakerToChangeSellerPerson.selectedSellerForTaker = formattedData[k];
                    }
                  };

                  return formattedData;
                }
              }
              return [];
            })
          );
      }
    };

    this.initSellersForTaker();

    // Avvio una prima ricerca
    this.sellerSrvSubjectForTaker.next('');
  }

  initSellersForTaker() {
    if (!this.sellerSrvSubjectForTaker) {
      this.sellerSrvSubjectForTaker = new Subject<any>();
    }
    if (this.sellersSrvForTaker) {
      this.sellerListForTaker = this.sellerListForTaker || [];
      this.sellerSrvSubjectForTaker.pipe(
        debounceTime(600),
        switchMap(
          (text: string) => {
            if (text !== this.sellerSearchedTextForTaker) {
              this.resetsellerResultListForTaker = true;
              this.selleresLoadedForTaker = 0;
            } else {
              this.resetsellerResultListForTaker = false;
            }
            this.sellerSearchedTextForTaker = text || '';
            this.isLoadingsellerListForTaker = true;
            return this.sellersSrvForTaker.load(this.selleresLoadedForTaker, 10, this.sellerSearchedTextForTaker);
          }
        )
        , catchError((err, caught) => {
          return caught;
        })
      )
        .subscribe({
          next: (eventData: Array<any>) => {
            if (this.resetsellerResultListForTaker) {
              this.clearsellerResultsForTaker();
            }
            if (eventData && eventData.length) {
              this.selleresLoadedForTaker += eventData.length;
              this.sellerListForTaker = this.sellerListForTaker.concat(eventData);
            }

            this.isLoadingsellerListForTaker = false;
          },
          error: (err: any) => {
            this.isLoadingsellerListForTaker = false;
          }
        });

    } else {
      this.sellerSrvSubjectForTaker.pipe();
    }
  }

  fetchMoreSellersForTaker(data?: any) {
    this.sellerSrvSubjectForTaker.next(this.sellerSearchedTextForTaker);
  }

  onSellerSelectedForTaker(data?: any) {
    this.selectedTakerToChangeSellerPerson.selectedSellerForTaker = data;
  }

  clearsellerResultsForTaker(): void {
    this.selleresLoadedForTaker = 0;
    this.sellerListForTaker = [];
    this.resetsellerResultListForTaker = false;
  }

  deleteSelectedSellerForTaker() {
    if (this.selectedTakerToChangeSellerPerson) {
      this.selectedTakerToChangeSellerPerson.selectedSellerForTaker = null;
    }
  }

  // Differenza tra limite massimo caratteri textArea e caratteri digitati nella textArea
  changeCountChars(data: string) {
    this.textAreaNumberChars = this.maxCharsTextArea - data.length;
  }

  onItemChecked(item: any) {
    this.selectedCar = item;
  }

  openTestModal() {
    this.modalService.open('test');
  }

  closeTestModal() {
    this.modalService.close('test');
  }

  openTestFullModal() {
    this.modalService.open('testFullPage');
  }

  closeTestFullModal() {
    this.modalService.close('testFullPage');
  }

  openTestBadgeModal() {
    this.modalService.open('badgeModal');
  }

  closeTestBadgeModal() {
    this.modalService.close('badgeModal');
  }

  isSelectValued(item: any) {
    if (item && item.id) {
      return true;
    }
    return false;
  }

  userCardId(index: number, el: UserCard) {
    return el.id;
  }

  changeLightTab(tab: any) {
    for (let i = 0; i < this.tabLightTest.length; i++) {
      this.tabLightTest[i].isDisabled = true;
    }
    tab.isDisabled = false;
  }

  changeCurrentTab(tab: TabDossier) {
    this.currentTab = tab.id;
  }
}

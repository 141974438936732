import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { InfoRound } from 'atfcore-commonclasses';
import { noop } from 'rxjs';
import { RedirectService } from '../../services/redirect.service';


@Component({
  selector: 'card-round',
  templateUrl: 'card-round.component.html',
  styleUrls: ['./card-round.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CardRoundComponent implements OnInit {

  @Input() round?: InfoRound;

  @Input() venueId?: string;

  @Input() campaignId?: string;

  _adminMood: boolean = false;
  @Input() set adminMood(value: boolean | string) {
    this._adminMood = coerceBooleanProperty(value);
  }

  _isActive: boolean = false;

  constructor(private redirectService: RedirectService) {
  }

  ngOnInit() {
    if (this.round && this.round.startDate && this.round.endDate) {
      let now = new Date().getTime();
      const minReservationEndDate = new Date(this.round.minReservationEndDate).getTime();
      const endDate = new Date(this.round.endDate).getTime();
      this._isActive = now >= minReservationEndDate && endDate >= now;
    }
  }

  goToSlots() {
    this.round ? this.redirectService.goToRoundSlots(this.round.roundId, this.venueId) : noop();
  }

  goToAttendances() {
    this.round ? this.redirectService.goToRoundAttendances(this.round.roundId, this.venueId) : noop();
  }

  getPerc(first: number, second: number): string {
    return (Math.round((first / second) * 100) / 100).toFixed(2);
  }

  goToAdminRoundDetails() {
    if (this.campaignId && this.round) {
      this.redirectService.goToAdminCampaignRoundTabVenues(this.campaignId, this.round.roundId);
    }
  }

}

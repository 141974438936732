import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { UrlService } from "../../shared/services/url.service";
import { Observable } from "rxjs";
import { SenecaResponse } from 'atfcore-commonclasses';

@Injectable()
export class AuthService {
  serviceMediatorUrl: string;
  constructor(private urlService: UrlService, private http: HttpClient) {
    this.serviceMediatorUrl = this.urlService.getServiceMediatorUrl();
  }

  /**
   * Servizio per confermare la registrazione di un avente diritto
   * @param {string} userId
   * @param {string} confirmToken
   * @return {boolean}
   */
  confirmEntitledRegistration(userId: string, confirmToken: string): Observable<SenecaResponse<boolean>> {
    return this.http.post<SenecaResponse<boolean>>(
      this.serviceMediatorUrl + 'confirm-entitled-registration', {
      userId,
      confirmToken
    });
  }

  /**
   * Function that create the retrieve for a user token in Redis, which is valid for 1 minute.
   * It is used to keep the token from traveling in the redirect url: a single-use key is inserted in its place
   * which allows the browser to recover the token within a predetermined maximum time. In this way the after login
   * page url or an attacker that capture the link will not be able to use the token after the user and it/he will be sent back to login
   * @return {string} The retrieveKey to use to recover the token
   */
  crateRetrieveTokenAfterLogin(): Observable<SenecaResponse<string>> {
    return this.http.post<SenecaResponse<string>>(
      this.serviceMediatorUrl + 'create-retrieve-token-after-login', null);
  }

  /**
   * Function that returns the JWT given its key (which could be the token itself or, most of the time, the tiny token)
   */
  getJWTToken(token?: string): Observable<SenecaResponse<string>> {
    return this.http.get<SenecaResponse<string>>(this.serviceMediatorUrl + 'get-full-jwt-token', {
      headers: token ? new HttpHeaders().set('Authorization', 'Bearer ' + token) : undefined
    });
  }

  /**
   * Used to impersonate a SS by an admin
   * @param {string} supplierPersonId
   * @return {token} The string of the auth token - tiny token
   */
  impersonateHealthFacilitySupplierForAdmin(supplierPersonId: string): Observable<SenecaResponse<string>> {
    let httpParams = new HttpParams();
    httpParams = httpParams.append('supplierPersonId', supplierPersonId);

    return this.http.get<SenecaResponse<string>>(this.serviceMediatorUrl + 'impersonate-health-facility-supplier-for-admin', { params: httpParams });
  }

  /**
   * Used to impersonate a AD
   * @param {string} userIdToImpersonate
   * @return {token} The string of the auth token - tiny token
   */
  impersonateUser(userIdToImpersonate: string): Observable<SenecaResponse<string>> {
    let httpParams = new HttpParams();
    httpParams = httpParams.append('userIdToImpersonate', userIdToImpersonate);

    return this.http.get<SenecaResponse<string>>(this.serviceMediatorUrl + 'impersonate-user', { params: httpParams });
  }

  /**
   * Service that set supplier's a password setting token in a user's field (the same field used to confirm user)
   * It also sends an email to the user with the link to use to recover the password.
   * @param {string} userName - Required: Username of the supplier who's asking to recover the password
   * @return {boolean} True if everything is ok, false otherwise
   */
  initSupplierPersonPasswordRecovery(userName: string): Observable<SenecaResponse<boolean>> {
    return this.http.post<SenecaResponse<boolean>>(
      this.serviceMediatorUrl + 'init-supplier-person-password-recovery', { userName });
  }

  /**
   * Service that creates the redis key-value pair used to recover the user's password. The password will be recoverable until this token expires.
   * It also sends an email to the user with the link to use to recover the password.
   * @param {string} email - Required: Email of the user who's asking to recover the password
   * @return {boolean} True if everything is ok, false otherwise
   */
  initEntitledPasswordRecovery(email: string): Observable<SenecaResponse<boolean>> {
    return this.http.post<SenecaResponse<boolean>>(
      this.serviceMediatorUrl + 'init-entitled-password-recovery', { email });
  }

  /**
   * Logins a supplier person using its username and password. Only persons associated to an active supplier can perform a login.
   * @param {string} username - Required: The username of the supplier person
   * @param {string} password - Required: The password of the supplier person (stored in the local DB)
   * @param {string} tenant - Optional: The tenant to use for the supplier person's login. By default, it will be used the one specified in the configuration.
   * @param {string} deviceType - Optional: The device type that the user used to login
   * @param {string} userAgent - Optional: The user-agent that the user used to login
   * @return {token} The string of the auth token
   */
  loginStructure(username: string, password: string, deviceType?: string, userAgent?: string, captchaToken?: string): Observable<SenecaResponse<string>> {
    return this.http.post<SenecaResponse<string>>(
      this.serviceMediatorUrl + 'structure-login', {
      username: username,
      password: password,
      deviceType: deviceType,
      userAgent: userAgent,
      gRecaptchaResponse: captchaToken
    });
  }

  /**
   * Function that receive an email and a password to login the user with LOCAL data.
   * This method should be used when no SSO system is available.
   * !! This login is valid only for entitled users !!
   * @param {string} email - Required: The email of the user to login
   * @param {string} password - Required: The password of the user to login
   * @param {string} deviceType - Optional: Type of device used by the user
   * @param {string} userAgent - Optional: UserAgent of the user
   * @param {boolean} createPersistentUserAuth - Optional: If true, the performed authentication will be saved as persistent auth too, which means that the user won't need to perform another login to access the protected resources (until the authentication expires, which usually will be after several time)
   * @return {token} The string of the auth token
   */
  loginEntitledLocalPassword(email: string, password: string, deviceType?: string, userAgent?: string, captchaToken?: string): Observable<SenecaResponse<string>> {
    return this.http.post<SenecaResponse<string>>(
      this.serviceMediatorUrl + 'login-entitled-local-password', {
      email: email,
      password: password,
      deviceType: deviceType,
      userAgent: userAgent,
      gRecaptchaResponse: captchaToken
    });
  }

  /**
   * Logins a supplier person using its username and password. Only persons associated to an active supplier can perform a login.
   * @param {string} username - Required: The username of the supplier person
   * @param {string} password - Required: The password of the supplier person (stored in the local DB)
   * @param {string} tenant - Optional: The tenant to use for the supplier person's login. By default, it will be used the one specified in the configuration.
   * @param {string} deviceType - Optional: The device type that the user used to login
   * @param {string} userAgent - Optional: The user-agent that the user used to login
   * @return {token} The string of the auth token
   */
  loginCustomer(username: string, password: string, deviceType?: string, userAgent?: string, captchaToken?: string): any {
    return this.http.post<SenecaResponse<string>>(
      this.serviceMediatorUrl + 'customer-login', {
      username: username,
      password: password,
      deviceType: deviceType,
      userAgent: userAgent,
      gRecaptchaResponse: captchaToken
    });
  }

  /**
   * Function that receive an email and a password to login the user with LOCAL data.
   * This method should be used when no SSO system is available.
   * @param {string} email - Required: The email of the user to login
   * @param {string} password - Required: The password of the user to login
   * @param {string} deviceType - Optional: Type of device used by the user
   * @param {string} userAgent - Optional: UserAgent of the user
   * @param {boolean} createPersistentUserAuth - Optional: If true, the performed authentication will be saved as persistent auth too, which means that the user won't need to perform another login to access the protected resources (until the authentication expires, which usually will be after several time)
   * @return {token} The string of the auth token
   */
  loginLocalPassword(email: string, password: string, deviceType?: string, userAgent?: string, captchaToken?: string): any {
    return this.http.post<SenecaResponse<string>>(
      this.serviceMediatorUrl + 'login-local-password', {
      email: email,
      password: password,
      deviceType: deviceType,
      userAgent: userAgent,
      gRecaptchaResponse: captchaToken
    },
      //{ headers: headers }
    );
  }

  /**
   * Servizio che tenta il login prod
   * @deprecated Servizio da implementare con url corretta
   * @param email
   * @param password
   * @param deviceType
   * @param userAgent
   * @returns
   */
  loginProd(email: string, password: string, deviceType?: string, userAgent?: string): any {
    return this.http.post<SenecaResponse<string>>(
      this.serviceMediatorUrl + 'login', {
      email: email,
      password: password,
      deviceType: deviceType,
      userAgent: userAgent
    });
  }

  /**
   * Function that destroy the token of the user session.
   * @return null
   */
  logout(): Observable<SenecaResponse<null>> {
    return this.http.get<SenecaResponse<any>>(this.serviceMediatorUrl + 'logout');
  }

  /**
   * Registrazione AD
   *
   * @param {string} codFisc
   * @param {string} email
   * @param {string} forename
   * @param {string} surname
   * @param {string} phoneNumber
   * @param {string} address
   * @param {boolean} isPrivacyAccepted
   * @param {string} password
   * @return {boolean}
   */
  registerEntitled(codFisc: string, email: string, forename: string, surname: string, phoneNumber: string,
    address: string, isPrivacyAccepted: boolean, password: string): Observable<SenecaResponse<boolean>> {
    return this.http.post<SenecaResponse<boolean>>(
      this.serviceMediatorUrl + 'register-entitled', {
      codFisc,
      email,
      forename,
      surname,
      phoneNumber,
      address,
      isPrivacyAccepted,
      password
    });
  }

  /**
   * Registrazione AD per SSO
   *
   * @param {string} tinyToken
   * @param {string} codFisc
   * @param {string} phoneNumber
   * @param {string} address
   * @param {boolean} isPrivacyAccepted
   * @return {string} Nuovo tinyToken
   */
  registerEntitledFromSSO(tinyToken: string, codFisc: string, phoneNumber: string, address: string, isPrivacyAccepted: boolean): Observable<SenecaResponse<string>> {
    return this.http.post<SenecaResponse<string>>(
      this.serviceMediatorUrl + 'register-entitled-from-sso', {
      codFisc,
      phoneNumber,
      address,
      isPrivacyAccepted
    }, { headers: new HttpHeaders().set('Authorization', 'Bearer ' + tinyToken) });
  }

  // Metodo per la gestione dei diritti e autenticazione
  isUserAuthorized(requiredAuthId: string, auths: string[]) {
    let isAuthorized: boolean = false;

    for (let i = 0, authsLength = auths.length; i < authsLength; i++) {
      let currentAuth = auths[i];
      if (currentAuth === requiredAuthId) {
        isAuthorized = true;
        break;
      }
    }
    return isAuthorized;
  }

  /**
   * Function that receive a valid token and renew it. It is used to extend the lifetime of a user session.
   * @return {token} The string of the auth token
   */
  renewToken(forceRefreshUser?: boolean): Observable<SenecaResponse<string>> {
    let impersonationLoginToken;
    if (sessionStorage.getItem("impersonationLogin")) {
      impersonationLoginToken = sessionStorage.getItem("impersonationLogin");
      sessionStorage.removeItem("impersonationLogin");
    }

    return this.http.post<SenecaResponse<string>>(this.serviceMediatorUrl + 'renew-token', {
      forceRefreshUser: forceRefreshUser
    }, {
      headers: impersonationLoginToken
        ? new HttpHeaders().set('Authorization', 'Bearer ' + impersonationLoginToken)
        : undefined
    });
  }

  /**
   * Function that retrieve the user token from Redis after a succesful login via remote service (like SAML).
   * It is used to keep the token from traveling in the redirect url: a single-use key is inserted in its place
   * which allows the browser to recover the token within a predetermined maximum time. In this way the after login
   * page url or an attacker that capture the link will not be able to use the token after the user and it/he will be sent back to login
   * @param {string} retrieveKey - Required by the action: The JWT token or tiny token used as key to find the JWT token
   * @return {string} The JWT token the retrieveKey is valid and never used before, null otherwise
   */
  retrieveTokenAfterLogin(retrieveKey: string): Observable<SenecaResponse<string>> {
    let httpParams = new HttpParams();
    httpParams = httpParams.append('retrieveKey', retrieveKey);
    return this.http.get<SenecaResponse<string>>(
      this.serviceMediatorUrl + 'retrieve-token-after-login', {
      params: httpParams
    });
  }

  /**
   * Update the password of a structure user
   * @param {string} supplierPersonId - Required: supplier person id
   * @param {string} validationToken - Required: supplier person id
   * @param {string} password - Required: User's previous password
   * @return {boolean} True if everything is ok, false otherwise
   */
  updateSupplierPersonPassword(supplierPersonId: string, validationToken: string, password: string): Observable<SenecaResponse<boolean>> {
    return this.http.post<SenecaResponse<boolean>>(
      this.serviceMediatorUrl + 'update-supplier-person-password', {
      supplierPersonId,
      validationToken,
      password
    });
  }

  /**
   * Update the password of a entitled user
   * @param {string} userId - Required: user id
   * @param {string} validationToken - Required
   * @param {string} newPassword - Required: User's new password
   * @return {boolean} True if everything is ok, false otherwise
   */
  updateEntitledPassword(userId: string, validationToken: string, newPassword: string): Observable<SenecaResponse<boolean>> {
    return this.http.post<SenecaResponse<boolean>>(
      this.serviceMediatorUrl + 'update-entitled-password', {
      userId,
      validationToken,
      newPassword
    });
  }

  /**
   * Validates the specified jwt token and, if requested, creates a login engagement
   * @param {string} token - Required: Token to validate, it must be defined as parameter or in the Bearer of the request
   * @param {boolen} createLoginEngagement - Optional: If true, a login engagement will be generated
   * @param {string} deviceType - Optional: Device type used by the user to perform the login, it will be saved in the login engagement's details
   * @param {string} userAgent - Optional: User agent used by the user to perform the login, it will be saved in the login engagement's details
   * @return {boolean} True if no errors were thrown during the execution
   */
  validateJwtToken(token: string, deviceType: string, userAgent: string): Observable<SenecaResponse<boolean>> {
    return this.http.post<SenecaResponse<boolean>>(
      this.serviceMediatorUrl + 'validate-jwt-token', {
      token,
      deviceType,
      userAgent
    });
  }

  loginOriginalUser(): Observable<SenecaResponse<null>> {
    return this.http.post<SenecaResponse<null>>(this.serviceMediatorUrl + 'login-original-user', null);
  }
}

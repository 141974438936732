import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { CampaingManagerUserTypes, MapById, RequiredAuth, SenecaResponse } from 'atfcore-commonclasses';
import { User } from 'atfcore-commonclasses/bin/classes/anag';
import { Subject, Subscription, of, throwError } from 'rxjs';
import { catchError, switchMap, take, takeUntil } from 'rxjs/operators';
import { AnagService } from 'src/app/auth/services/anag.service';
import { AuthService } from 'src/app/auth/services/auth.service';
import { RedirectService } from 'src/app/shared/services/redirect.service';
import { ExportService } from 'src/app/structure/services/export.service';
import { RentService } from 'src/app/structure/services/rent.service';
import { getModalMessageData, getWarningModal } from 'src/app/utils/utils';
import * as CoreActions from "../../core/ngrx/core.actions";
import * as fromApp from "../../ngrx/app.reducers";
import { ModalService } from '../components/modal/modal.service';

interface UserTypologyDashboardData {
  reservation: {
    ["FAMILIAR"]: number;
    ["EMPLOYEE"]: number;
  },
  presences: {
    ["FAMILIAR"]: number;
    ["EMPLOYEE"]: number;
  },
  registrations: {
    ["FAMILIAR"]: number;
    ["EMPLOYEE"]: number;
  }
}

interface UserAgesDashboardData {
  reservation: {
    "0-5": number,
    "18-34": number,
    "6-17": number,
    "35-54": number,
    "55-64": number,
    "65+": number
  },
  presences: {
    "0-5": number,
    "18-34": number,
    "6-17": number,
    "35-54": number,
    "55-64": number,
    "65+": number
  },
  registrations: {
    "0-5": number,
    "18-34": number,
    "6-17": number,
    "35-54": number,
    "55-64": number,
    "65+": number
  }
}

interface UserGenderDashboardData {
  reservation: {
    ["M"]: number;
    ["F"]: number;
  },
  presences: {
    ["M"]: number;
    ["F"]: number;
  },
  registrations: {
    ["M"]: number;
    ["F"]: number;
  }
}


@Component({
  selector: 'app-admin-dashboard',
  templateUrl: './admin-dashboard.component.html',
  styleUrls: ['./admin-dashboard.component.scss']
})
export class AdminDashboardComponent implements OnInit, OnDestroy {
  loggedUser?: User;
  @ViewChild("registrationChart") registrationChart: any;

  private _unsubscribeSignal$: Subject<void> = new Subject();

  filterList: {
    clients: any[];
    campaigns: any[],
    rounds: any[],
    userTypes: any[]
  } = {
      clients: [],
      campaigns: [],
      rounds: [],
      userTypes: []
    }
  selectedFilters: {
    client: any,
    campaign: any,
    round: any,
    userType: any
  } = {
      client: null,
      campaign: null,
      round: null,
      userType: null
    }
  getFilters$: Subscription = new Subscription;
  _campaignSearchedText: any;
  campaignServive$: Subject<any> = new Subject<any>();

  isLoadingCampaigns: boolean = true;
  isLoadingRounds: boolean = true;

  getCampaignList$: Subscription = new Subscription;
  getRoundListByCampaignForAdmin$: Subscription = new Subscription;

  getClientList$: Subscription = new Subscription;
  isLoadingClients: boolean = true;
  isLoadingUserTypes: boolean = true;
  translations: any;

  // grafici
  chartInstances: any = {};
  allowedFormats = ['PNG', 'PDF', 'JPEG'];
  biColor: string[] = ["#FFC494", "#BA3C33"];
  triColor: string[] = ["#FFC494", "#BA3C33", "#76604F"];
  numericColor: string[] = ["#BA3C33", "#FFC494", "#F66156"];

  registrationGraph: any = {};
  bookingGraph: any = {};
  presenceGraph: any = {};
  vaccineData: any = {};
  userTypeGraphData: any[] = [];
  genderGraphData: any[] = [];
  agesGraphData: any[] = [];
  isLoadingBookingGraphData: boolean = true;
  isLoadingRegistrationGraphData: boolean = true;
  isLoadingPresenceGraphData: boolean = true;
  isLoadingVaccineGraphData: boolean = true;
  isLoadingUserTypeGraphData: boolean = true;
  isLoadingAgesGraphData: boolean = true;

  // tabella
  selectedTab: any;
  tabList: any[] = [];

  isLoadingTableData: boolean = false;
  getTableData$: Subscription = new Subscription;
  tableData: any = {};
  getRegistrationGraphData$: Subscription = new Subscription;
  getBookingGraphData$: Subscription = new Subscription;
  getPresenceGraphData$: Subscription = new Subscription;
  getVaccineGraphData$: Subscription = new Subscription;
  tableSearchedText: string = '';
  tableSearchDate: any;
  getAgesGraphData$: any;

  isDownloadingReport: boolean = false;

  exportDashboard$: Subscription = new Subscription;
  getDateTableData$: Subscription = new Subscription;
  getSocietyTableData$: Subscription = new Subscription;

  componentTranslations: string[] = ['admin.dashboard.filters.ALL_CLIENT',
    'admin.dashboard.filters.ALL_CAMPAIGN',
    'admin.dashboard.filters.ALL_ROUND',
    'admin.dashboard.filters.ALL_USER_TYPE',
    'admin.dashboard.graph.REGISTRATION',
    'admin.dashboard.graph.REGISTERED_USER',
    'admin.dashboard.graph.UNREGISTERED_USER',
    'admin.dashboard.graph.BOOKING',
    'admin.dashboard.graph.BOOKING_USERS',
    'admin.dashboard.graph.UNBOOKED_USERS',
    'admin.dashboard.graph.PRESENCE',
    'admin.dashboard.graph.PRESENCE_SUB',
    'admin.dashboard.graph.PRESENCE_YES',
    'admin.dashboard.graph.PRESENCE_NO',
    'admin.dashboard.graph.PRESENCE_WAITING',
    'admin.dashboard.graph.VACCINE_NUMBER',
    'admin.dashboard.graph.VACCINE_BOUGHT',
    'admin.dashboard.graph.VACCINE_BOOKED',
    'admin.dashboard.graph.VACCINE_GIVEN',
    'admin.dashboard.graph.USER_TYPE',
    'admin.dashboard.graph.users.REGISTRATION',
    'admin.dashboard.graph.users.BOOKING',
    'admin.dashboard.graph.users.PRESENCE',
    'admin.dashboard.graph.users.MALE',
    'admin.dashboard.graph.users.FEMALE',
    'admin.dashboard.graph.users.EMPLOYEE',
    'admin.dashboard.graph.users.FAMILY',
    'admin.dashboard.graph.ages.6',
    'admin.dashboard.graph.ages.17',
    'admin.dashboard.graph.ages.34',
    'admin.dashboard.graph.ages.54',
    'admin.dashboard.graph.ages.64',
    'admin.dashboard.graph.ages.65',
    "admin.dashboard.tab.STRUCTURE_DETAIL",
    "admin.dashboard.tab.DATE_DETAIL",
    "admin.dashboard.tab.SOCIETY_DETAIL",
    "admin.dashboard.USERS_ON",
    'admin.dashboard.graph.users.REGISTERED',
    'admin.dashboard.graph.users.PRESENT',
    'admin.dashboard.graph.users.BOOKED',
    'admin.dashboard.filters.userTypes.EMPLOYEES',
    'admin.dashboard.filters.userTypes.FAMILY_MEMBERS',
    'admin.dashboard.graph.TOTAL_USERS_CHARGED',
    'admin.dashboard.graph.TOTAL_USERS_BOOOKING'];

  isAdvancedDashboard: boolean = false;
  isAdmin: boolean = false;
  reportingData: any = {
    registration: 0,
    bookign: 0,
    presence: 0
  };
  yearClientCampaign = new Date().getFullYear();
  isWithCurrentYearCampaign: boolean = false;

  registrations: number = 0;
  reserved: number = 0;
  notBookedRegistered: number = 0;

  constructor(
    private modalService: ModalService,
    private redirectService: RedirectService,
    private authService: AuthService,
    private exportService: ExportService,
    private appStore: Store<fromApp.AppState>,
    private translate: TranslateService,
    private anagService: AnagService,
    private rentService: RentService) {
  }

  ngOnInit() {
    if (this.redirectService.isThisCurrentPage('admin')) {
      this.isAdmin = true;
    }

    this.appStore.select(fromApp.getLoggedUser)
      .pipe(takeUntil(this._unsubscribeSignal$.asObservable()))
      .subscribe((loggedUser) => {
        if (loggedUser && loggedUser.user) {
          this.loggedUser = loggedUser && loggedUser.user;

          if (!this.isAdmin) {
            // Se sono qui significa che sono nella route dei clienti. Verifico quindi se c'è l'auth per vedere la dashboard completa
            if (loggedUser.auths && loggedUser.auths.length) {
              {
                for (let i = 0, authsLength = loggedUser.auths.length; i < authsLength; i++) {
                  if (loggedUser.auths[i] === RequiredAuth.CAMPAIGN_MANAGER_SUPPLIER_FULL_DASHBOARD_ACCESS) {
                    this.isAdvancedDashboard = true;

                    break;
                  }
                }
              }

            }
          }

          this.translate.get(this.componentTranslations).subscribe((translations) => {
            this.translations = translations;
            this.tabList = [
              {
                id: 'structure',
                title: this.translations["admin.dashboard.tab.STRUCTURE_DETAIL"]
              },
              {
                id: 'date',
                title: this.translations["admin.dashboard.tab.DATE_DETAIL"]
              },
              {
                id: 'society',
                title: this.translations["admin.dashboard.tab.SOCIETY_DETAIL"]
              },
            ]
            this.selectedTab = this.tabList[0];

            // Imposto i filtri ed eseguo una prima ricerca dei dati
            this.resetFilters();
          })
        }
      });
  }

  /**
   * @description Mostra una modale con titolo e messaggio
   * @param modalId
   * @param title
   * @param message
   */
  dispatchMessageModal(title: string, message: string) {
    const messageObj = getModalMessageData(this.translate, title, message);
    this.appStore.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
  }

  /**
   * @description Mostra una modale con un messaggio di errore
   * @param modalId
   * @param error
   */
  dispatchModal(modalId: string, error: string) {
    const messageObj = getWarningModal(this.translate, modalId, error);
    this.appStore.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
  }

  // Esporta report dashboard
  exportReport() {
    this.isDownloadingReport = true;

    this.exportDashboard$ = this.exportService.exportDashboard(this.selectedFilters?.client?.id || null, this.selectedFilters?.campaign?.id || null, this.selectedFilters?.round?.id || null)
    .pipe(
      switchMap((senecaResponse) => {
        if (senecaResponse && senecaResponse.error) {
          this.dispatchModal('030', senecaResponse.error);
          this.isDownloadingReport = false;

          return of(null);
        }
        return this.exportService.getDownloadTempFileUrl(senecaResponse.response, false);
      }),
      takeUntil(this._unsubscribeSignal$.asObservable())
    )
    .subscribe(downloadUrl => {
      if (downloadUrl) {
        const timeout = setTimeout(() => {
          clearTimeout(timeout);
          document.location.assign(downloadUrl);
          this.isDownloadingReport = false;
        }, 500);
      }
    }, (err) => {
      this.isDownloadingReport = false;
      if (err && err.message) {
        this.dispatchModal('031', err.message);
      }
    });
  }

  // Resetta le selezioni dei filtri
  resetFilterSelection() {
    this.selectedFilters.client = null;
    this.selectedFilters.campaign = null;
    this.selectedFilters.round = null;
    this.selectedFilters.userType = null;
  }

  // Resetta i filtri di ricerca
  resetFilters() {
    this.resetFilterSelection();
    this.getFilters();
    this.getDashboardContent();
  }

  // Popola tutte le select dei filtri
  getFilters() {
    // Recupero la lista di clienti
    if (this.isAdmin) {
      this.getClientList();
    }

    // Recupero la lista di campagne
    this.getCampaignList();

    // Imposto la dropdown dei round
    this.resetRoundSelect();

    this.isLoadingRounds = false;

    // Costruisco la dropdown della tipologia di utente

    if (this.isAdvancedDashboard || this.isAdmin) {
      this.getUserTypeList();
    }
  }

  // Resetta la scelta dei round
  resetRoundSelect() {
    this.filterList.rounds = [
      {
        id: 0,
        name: this.translations['admin.dashboard.filters.ALL_ROUND']
      }
    ];
    this.onSelectRound(this.filterList.rounds[0]);
  }

  getCampaignList(loadRounds?: boolean) {
    this.isLoadingCampaigns = true;

    if (this.getCampaignList$) {
      this.getCampaignList$.unsubscribe();
    }

    this.getCampaignList$ = this.rentService.getCampaignListForDashboard(this.selectedFilters?.client?.id)
      .subscribe((data: SenecaResponse<any>) => {
        if (data.error) {
          this.dispatchModal('06', data.error);
        } else {
          const ids = Object.keys(data.response);

          if (ids && ids.length) {
            let formattedData: any[] = [
              {
                id: 0,
                name: this.translations['admin.dashboard.filters.ALL_CAMPAIGN']
              }
            ];

            for (let i = 0; i < ids.length; i++) {
              formattedData.push({
                id: ids[i],
                name: data.response[ids[i]]
              });
            }

            this.filterList.campaigns = formattedData;
            // Taccone provvisorio, commentato
            // if (!this.isAdmin) {
            //   for (let i = 0; i < this.filterList.campaigns.length; i++) {
            //     if (this.filterList.campaigns[i].name.indexOf(this.yearClientCampaign) >= 0) {
            //       this.filterList.campaigns = [this.filterList.campaigns[i]];
            //       this.isWithCurrentYearCampaign = true;
            //       break;
            //     }
            //   }
            // }
            this.onSelectCampaign(this.filterList.campaigns[0], (!this.isAdmin || loadRounds));
          } else {
            this.filterList.campaigns = [];
          }

        }
        this.isLoadingCampaigns = false;
      }, (err: any) => {
        if (err && err.message) {
          this.dispatchModal('077', err.message);
        } else {
          this.dispatchModal('077', err);
        }
        this.isLoadingCampaigns = false;
      })
  }

  // Recupera la lista di clienti da usare come filtro
  getClientList() {
    this.isLoadingClients = true;

    this.filterList.clients = [];

    if (this.getClientList$) {
      this.getClientList$.unsubscribe();
    }

    this.getClientList$ = this.anagService.getCustomerListForDashboard()
      .subscribe((resultData: SenecaResponse<MapById<string>>) => {
        if (resultData.error) {
          this.dispatchModal('070', resultData.error);
        } else {
          let formattedData: any[] = [
            {
              id: 0,
              name: this.translations['admin.dashboard.filters.ALL_CLIENT']
            }
          ];

          // Non si sa perché ma i servizi tornano una mappa.. quindi la scorro per recuperarmi la lista dei clienti
          for (let clientKey in resultData.response) {
            if (resultData.response.hasOwnProperty(clientKey)) {
              formattedData.push({
                id: clientKey,
                name: resultData.response[clientKey]
              });

              this.filterList.clients = formattedData;
            } else {
              this.filterList.clients = [];
            }
          }

          if (this.filterList && this.filterList.clients && this.filterList.clients.length) {
            this.onSelectClient(this.filterList.clients[0]);
          }
        }
        this.isLoadingClients = false;
      }, (err: any) => {
        if (err && err.message) {
          this.dispatchModal('070', err.message);
        } else {
          this.dispatchModal('070', err);
        }
        this.isLoadingClients = false;
      })
  }

  onSelectClient(newClient: any, loadCampaign?: boolean) {
    this.selectedFilters.client = newClient;

    if (loadCampaign) {
      // Resetto la scelta della campagna e del round
      this.selectedFilters.campaign = null;
      this.selectedFilters.round = null;
      this.getCampaignList(true);
    }
  }

  onSelectCampaign(newCampaign: any, loadRounds?: boolean) {
    this.selectedFilters.campaign = newCampaign;
    if (loadRounds) {
      // Resetto la scelta del round
      this.selectedFilters.round = null;

      if (!this.selectedFilters.campaign.id) {
        // In questo caso l'utente ha selezionato "Tutte le campagne"
        this.resetRoundSelect();
      } else {
        this.getRoundList();
      }
      this.getDashboardContent();
    }
  }

  getRoundList() {
    this.isLoadingRounds = true;

    if (this.getRoundListByCampaignForAdmin$) {
      this.getRoundListByCampaignForAdmin$.unsubscribe();
    }

    // Se sono un cliente, devo chiamare un servizio differente rispetto all'admin
    let serviceToUse;

    if (this.isAdmin) {
      serviceToUse = this.rentService.getRoundListByCampaignForAdmin(this.selectedFilters?.campaign?.id, 0, 10);
    } else {
      serviceToUse = this.rentService.getRoundListByCampaignForCustomer(0, 10, this.selectedFilters?.campaign?.id, true);
    }

    this.getRoundListByCampaignForAdmin$ = serviceToUse
      .subscribe((data: SenecaResponse<any>) => {
        if (data.error) {
          this.dispatchModal('072', data.error);
        } else {
          let formattedData: any[] = [
            {
              id: 0,
              name: this.translations['admin.dashboard.filters.ALL_ROUND']
            }
          ];
          data.response.forEach((round: any) => {
            formattedData.push({
              id: round.roundId,
              name: round.title
            });
          });
          this.filterList.rounds = formattedData;
          this.onSelectRound(this.filterList.rounds[0]);
        }
        this.isLoadingRounds = false;
      }, (err: any) => {
        if (err && err.message) {
          this.dispatchModal('177', err.message);
        } else {
          this.dispatchModal('177', err);
        }
        this.isLoadingRounds = false;
      })
  }

  onSelectRound(newRound: any, selectedFromDash?: boolean) {
    this.selectedFilters.round = newRound;
    if (selectedFromDash) {
      this.getDashboardContent();
    }
  }

  onSelectUserType(newUserType: any, selectedFromDash?: boolean) {
    this.selectedFilters.userType = newUserType;
    if (selectedFromDash) {
      this.getDashboardContent();
    }
  }

  getUserTypeList() {
    this.filterList.userTypes = [
      {
        id: 0,
        name: this.translations['admin.dashboard.filters.ALL_USER_TYPE']
      },
      {
        id: CampaingManagerUserTypes.EMPLOYEE,
        name: this.translations['admin.dashboard.filters.userTypes.EMPLOYEES']
      },
      {
        id: CampaingManagerUserTypes.RELATIVE,
        name: this.translations['admin.dashboard.filters.userTypes.FAMILY_MEMBERS']
      }
    ];
    this.onSelectUserType(this.filterList.userTypes[0]);
    this.isLoadingUserTypes = false;
  }

  getDashboardContent() {
    this.getGraphData();
    if (this.isAdvancedDashboard || this.isAdmin) {
      this.getTableData(true);
    }
  }

  getGraphData() {
    this.getRegistrationGraphData();
    this.getBookingGraphData();
    this.getPresenceGraphData();
    this.getVaccineGraphData();

    if (this.isAdvancedDashboard || this.isAdmin) {
      this.getUserSplittedData();
    } else {
      this.isLoadingAgesGraphData = false;
      this.isLoadingUserTypeGraphData = false;
    }
  }

  isLoading() {
    if (!this.isLoadingRegistrationGraphData && !this.isLoadingBookingGraphData && !this.isLoadingPresenceGraphData
      && !this.isLoadingVaccineGraphData && !this.isLoadingUserTypeGraphData) {
      document.getElementsByClassName("dxl-marker") && document.getElementsByClassName("dxl-marker")[2]?.firstElementChild?.setAttribute('fill', '#826B58');
      document.getElementsByClassName("dxl-marker") && document.getElementsByClassName("dxl-marker")[9]?.firstElementChild?.setAttribute('fill', '#826B58');
    }
    return this.isLoadingRegistrationGraphData || this.isLoadingBookingGraphData || this.isLoadingPresenceGraphData
      || this.isLoadingVaccineGraphData || this.isLoadingUserTypeGraphData; // || this.isLoadingAgesGraphData;
  }

  getRegistrationGraphData() {
    this.isLoadingRegistrationGraphData = true;

    if (this.getRegistrationGraphData$) {
      this.getRegistrationGraphData$.unsubscribe();
    }

    this.getRegistrationGraphData$ = this.rentService.getUserRergistrationForDashboardForAdmin(this.selectedFilters?.client?.id || null, this.selectedFilters?.campaign?.id || null, this.selectedFilters?.round?.id || null, this.selectedFilters?.userType?.id || null)
      .subscribe((data: SenecaResponse<any>) => {
        if (data.error) {
          this.dispatchModal('062', data.error);
          this.isLoadingRegistrationGraphData = false;
        } else {
          this.registrationGraph.total = data.response && data.response.tot || 0;
          this.registrations = data.response.registrationCompletedCount || 0;
          this.registrationGraph.data = [
            {
              status: this.translations['admin.dashboard.graph.REGISTERED_USER'],
              total: Math.round((data.response.registrationCompletedCount / this.registrationGraph.total) * 100) || 0,
              tooltip: data.response.registrationCompletedCount + this.translations['admin.dashboard.USERS_ON'] + this.registrationGraph.total
            },
            {
              status: this.translations['admin.dashboard.graph.UNREGISTERED_USER'],
              total: Math.round((data.response.registrationNotCompletedCount / this.registrationGraph.total) * 100) || 0,
              tooltip: data.response.registrationNotCompletedCount + this.translations['admin.dashboard.USERS_ON'] + this.registrationGraph.total
            },
            {
              status: this.translations['admin.dashboard.graph.TOTAL_USERS_CHARGED'],
              total: 0
            }
          ];
        }
        this.isLoadingRegistrationGraphData = false;
      }, (err: any) => {
        if (err && err.message) {
          this.dispatchModal('063', err.message);
        } else {
          this.dispatchModal('063', err);
        }
        this.isLoadingRegistrationGraphData = false;
      })
  }

  getBookingGraphData() {
    this.isLoadingBookingGraphData = true;
    if (this.getBookingGraphData$) {
      this.getBookingGraphData$.unsubscribe();
    }

    this.getBookingGraphData$ = this.rentService.getUserReservationForDashboardForAdmin(this.selectedFilters?.client?.id || null, this.selectedFilters?.campaign?.id || null, this.selectedFilters?.round?.id || null, this.selectedFilters?.userType?.id)
      .subscribe((data: SenecaResponse<any>) => {
        if (data.error) {
          this.dispatchModal('064', data.error);
        } else {
          this.reserved = data.response.bookedRegisteredUserCount || 0;
          this.notBookedRegistered = data.response.nonBookedRegisteredUserCount || 0;
          this.bookingGraph.data = [
            data.response.reserved || 0,
            data.response.nonReserved || 0,
            data.response.totalReservations || 0,
          ]

          this.bookingGraph.total = (data.response.reserved || 0) + (data.response.nonReserved || 0);
        }
        this.isLoadingBookingGraphData = false;
      }, (err: any) => {
        if (err && err.message) {
          this.dispatchModal('065', err.message);
        } else {
          this.dispatchModal('066', err);
        }
        this.isLoadingBookingGraphData = false;
      })
  }

  barGaugeText(arg: any, translations: any) {
    if (arg && arg.item) {
      if (arg.item.index == 0) {
        return translations['admin.dashboard.graph.BOOKING_USERS'];
      } else if (arg.item.index == 1) {
        return translations['admin.dashboard.graph.UNBOOKED_USERS'];
      } else if (arg.item.index == 2) {
        return translations['admin.dashboard.graph.BOOKING'];
      }
    }
  }

  getLegend = (arg: any) => this.barGaugeText(arg, this.translations);


  getPresenceGraphData() {
    this.isLoadingPresenceGraphData = true;

    if (this.getPresenceGraphData$) {
      this.getPresenceGraphData$.unsubscribe();
    }

    this.getPresenceGraphData$ = this.rentService.getUserPresenceForDashboardForAdmin(this.selectedFilters?.client?.id || null, this.selectedFilters?.campaign?.id || null, this.selectedFilters?.round?.id || null, this.selectedFilters?.userType?.id)
      .subscribe((data: SenecaResponse<any>) => {
        if (data.error) {
          this.dispatchModal('064', data.error);
        } else {
          this.presenceGraph.total = data.response.tot;
          this.presenceGraph.present = data.response.present;
          this.presenceGraph.nonPresent = data.response.nonPresent;
          this.presenceGraph.waitingForFeedback = data.response.waitingForFeedback;
          this.presenceGraph.data = [
            {
              status: this.translations['admin.dashboard.graph.PRESENCE_YES'],
              total: Math.round((data.response.present / this.presenceGraph.total) * 100),
              tooltip: data.response.present + this.translations['admin.dashboard.USERS_ON'] + this.presenceGraph.total
            },
            {
              status: this.translations['admin.dashboard.graph.PRESENCE_NO'],
              total: Math.round((data.response.nonPresent / this.presenceGraph.total) * 100),
              tooltip: data.response.nonPresent + this.translations['admin.dashboard.USERS_ON'] + this.presenceGraph.total
            },
            {
              status: this.translations['admin.dashboard.graph.PRESENCE_WAITING'],
              total: Math.round((data.response.waitingForFeedback / this.presenceGraph.total) * 100),
              tooltip: data.response.waitingForFeedback + this.translations['admin.dashboard.USERS_ON'] + this.presenceGraph.total
            },
            {
              status: this.translations['admin.dashboard.graph.TOTAL_USERS_BOOOKING'],
              total: 0
            },
          ]
        }
        this.isLoadingPresenceGraphData = false;
      }, (err: any) => {
        if (err && err.message) {
          this.dispatchModal('065', err.message);
        } else {
          this.dispatchModal('066', err);
        }
        this.isLoadingPresenceGraphData = false;
      })
  }

  getVaccineGraphData() {
    this.isLoadingVaccineGraphData = true;

    if (this.getVaccineGraphData$) {
      this.getVaccineGraphData$.unsubscribe();
    }

    this.getVaccineGraphData$ = this.rentService.getVaccinationDataForDashboardForAdmin(this.selectedFilters?.client?.id || null, this.selectedFilters?.campaign?.id || null, this.selectedFilters?.round?.id || null, this.selectedFilters?.userType?.id || null)
      .subscribe((data: SenecaResponse<any>) => {
        if (data.error) {
          this.dispatchModal('067', data.error);
        } else {
          this.vaccineData = [
            {
              status: this.translations['admin.dashboard.graph.VACCINE_BOUGHT'],
              total: data.response.totVaccines,
              tooltip: data.response.totVaccines
            },
            {
              status: this.translations['admin.dashboard.graph.VACCINE_BOOKED'],
              total: data.response.bookedVaccines,
              tooltip: data.response.bookedVaccines
            },
            {
              status: this.translations['admin.dashboard.graph.VACCINE_GIVEN'],
              total: data.response.administeredVaccine,
              tooltip: data.response.administeredVaccine
            },
          ]
        }
        this.isLoadingVaccineGraphData = false;
      }, (err: any) => {
        if (err && err.message) {
          this.dispatchModal('068', err.message);
        } else {
          this.dispatchModal('069', err);
        }
        this.isLoadingVaccineGraphData = false;
      })
  }

  // Recupera le informazioni sulle registrazioni, prenotazioni e presenze. Questo servizio è usato per costruire più grafici
  getUserSplittedData() {
    const promiseArray = [
      this.rentService.getUserGenderDashboardData(this.selectedFilters?.client?.id || null, this.selectedFilters?.campaign?.id || null, this.selectedFilters?.round?.id || null, this.selectedFilters?.userType?.id || null).toPromise(),
      this.rentService.getUserAgesDashboardData(this.selectedFilters?.client?.id || null, this.selectedFilters?.campaign?.id || null, this.selectedFilters?.round?.id || null, this.selectedFilters?.userType?.id || null).toPromise(),
      this.rentService.getUserTypologyDashboardData(this.selectedFilters?.client?.id || null, this.selectedFilters?.campaign?.id || null, this.selectedFilters?.round?.id || null, this.selectedFilters?.userType?.id || null).toPromise()
    ];

    Promise.all(promiseArray).then((results: any) => {
      if (results && results[0].response && results[1].response && results[2].response) {
        // Registrazioni
        const userGenderDashboardData: UserGenderDashboardData = results[0].response;
        // Prenotazioni
        const userAgesDashboardData: UserAgesDashboardData = results[1].response;
        // Presenze
        const userTypologiesDashboardData: UserTypologyDashboardData = results[2].response;

        // Grafico "genere"
        this.getGenderGraphData(userGenderDashboardData);

        // Grafico "fasce d'età"
        this.getAgesGraphData(userAgesDashboardData);

        // Grafico "Tipologia utenti"
        this.getUserTypeGraphData(userTypologiesDashboardData);
      }

    }).catch((err: any) => {
      this.dispatchModal('079', err.message);
    })
  }

  // Costriusce il grafico "Tipologia utenti"
  getUserTypeGraphData(data: UserTypologyDashboardData) {
    this.isLoadingUserTypeGraphData = true;

    let employeeNumberRegistrations = data.registrations?.EMPLOYEE || 0;
    let familiarNumberRegistrations = data.registrations?.FAMILIAR || 0;

    let employeeNumberReservations = data.reservation?.EMPLOYEE || 0;
    let familiarNumberReservations = data.reservation?.FAMILIAR || 0;

    let employeeNumberPresences = data.presences?.EMPLOYEE || 0;
    let familiarNumberPresences = data.presences?.FAMILIAR || 0;

    // tentativo inversione asse
    this.userTypeGraphData = [
      {
        state: this.translations['admin.dashboard.graph.users.REGISTRATION'],
        employee: employeeNumberRegistrations,
        familiar: familiarNumberRegistrations,
        tooltip: this.translations['admin.dashboard.graph.users.EMPLOYEE'] + ': ' + employeeNumberRegistrations + ' (' + this.formatNumberForDashboard(employeeNumberRegistrations, (employeeNumberRegistrations + familiarNumberRegistrations)) + '%)' + ' - ' + this.translations['admin.dashboard.graph.users.FAMILY'] + ': ' + familiarNumberRegistrations + ' (' + this.formatNumberForDashboard(familiarNumberRegistrations, (employeeNumberRegistrations + familiarNumberRegistrations)) + '%)'
      },
      {
        state: this.translations['admin.dashboard.graph.users.BOOKING'],
        employee: employeeNumberReservations,
        familiar: familiarNumberReservations,
        tooltip: this.translations['admin.dashboard.graph.users.EMPLOYEE'] + ': ' + employeeNumberReservations + ' (' + this.formatNumberForDashboard(employeeNumberReservations, (employeeNumberReservations + familiarNumberReservations)) + '%)' + ' - ' + this.translations['admin.dashboard.graph.users.FAMILY'] + ': ' + familiarNumberReservations + ' (' + this.formatNumberForDashboard(familiarNumberReservations, (employeeNumberReservations + familiarNumberReservations)) + '%)'
      },
      {
        state: this.translations['admin.dashboard.graph.users.PRESENCE'],
        employee: employeeNumberPresences,
        familiar: familiarNumberPresences,
        tooltip: this.translations['admin.dashboard.graph.users.EMPLOYEE'] + ': ' + employeeNumberPresences + ' (' + this.formatNumberForDashboard(employeeNumberPresences, (employeeNumberPresences + familiarNumberPresences)) + '%)' + ' - ' + this.translations['admin.dashboard.graph.users.FAMILY'] + ': ' + familiarNumberPresences + ' (' + this.formatNumberForDashboard(familiarNumberPresences, (employeeNumberPresences + familiarNumberPresences)) + '%)'
      }
    ];
    this.isLoadingUserTypeGraphData = false;
  }

  formatNumberForDashboard(num: number, den: number) {
    if (den > 0) {
      if (num <= 0) {
        return 0.00;
      } else {
        return ((num / den) * 100).toFixed(2);
      }
    } else {
      return 0.00;
    }
  }

  // Costruisce il grafico "genere"
  getGenderGraphData(dashboardData: UserGenderDashboardData) {
    let maleNumberRegistrations: number = dashboardData.registrations?.M || 0;
    let femaleNumberRegistrations: number = dashboardData.registrations?.F || 0;

    let maleNumberReservations: number = dashboardData.reservation?.M || 0;
    let femaleNumberReservations: number = dashboardData.reservation?.F || 0;

    let maleNumberPresences: number = dashboardData.presences?.M || 0;
    let femaleNumberPresences: number = dashboardData.presences?.F || 0;

    this.genderGraphData = [{
      state: this.translations['admin.dashboard.graph.users.REGISTRATION'],
      male: maleNumberRegistrations || 0,
      female: femaleNumberRegistrations || 0,
      tooltip: this.translations['admin.dashboard.graph.users.MALE'] + ': ' + maleNumberRegistrations + ' (' + (this.formatNumberForDashboard(maleNumberRegistrations, (maleNumberRegistrations + femaleNumberRegistrations))) + '%)' + ' - ' + this.translations['admin.dashboard.graph.users.FEMALE'] + ': ' + femaleNumberRegistrations + ' (' + this.formatNumberForDashboard(femaleNumberRegistrations, (maleNumberRegistrations + femaleNumberRegistrations)) + '%)',
      maleSeriesName: this.translations['admin.dashboard.graph.users.MALE'],
      femaleSeriesName: this.translations['admin.dashboard.graph.users.FEMALE'],
      maleTooltip: this.translations['admin.dashboard.graph.users.MALE'] + ': ' + maleNumberRegistrations + ' (' + this.formatNumberForDashboard(maleNumberRegistrations, (maleNumberRegistrations + femaleNumberRegistrations)) + '%)',
      femaleTooltip: this.translations['admin.dashboard.graph.users.FEMALE'] + ': ' + femaleNumberRegistrations + ' (' + this.formatNumberForDashboard(femaleNumberRegistrations, (maleNumberRegistrations + femaleNumberRegistrations)) + '%)'
    }, {
      state: this.translations['admin.dashboard.graph.users.BOOKING'],
      male: maleNumberReservations || 0,
      female: femaleNumberReservations || 0,
      tooltip: this.translations['admin.dashboard.graph.users.MALE'] + ': ' + maleNumberReservations + ' (' + this.formatNumberForDashboard(maleNumberReservations, (maleNumberReservations + femaleNumberReservations)) + '%)' + ' - ' + this.translations['admin.dashboard.graph.users.FEMALE'] + ': ' + femaleNumberReservations + ' (' + this.formatNumberForDashboard(femaleNumberReservations, (maleNumberReservations + femaleNumberReservations)) + '%)',
      maleSeriesName: this.translations['admin.dashboard.graph.users.MALE'],
      femaleSeriesName: this.translations['admin.dashboard.graph.users.FEMALE'],
      maleTooltip: this.translations['admin.dashboard.graph.users.MALE'] + ': ' + maleNumberReservations + ' (' + this.formatNumberForDashboard(maleNumberReservations, (maleNumberReservations + femaleNumberReservations)) + '%)',
      femaleTooltip: this.translations['admin.dashboard.graph.users.FEMALE'] + ': ' + femaleNumberReservations + ' (' + this.formatNumberForDashboard(femaleNumberReservations, (maleNumberReservations + femaleNumberReservations)) + '%)'
    }, {
      state: this.translations['admin.dashboard.graph.users.PRESENCE'],
      male: maleNumberPresences || 0,
      female: femaleNumberPresences || 0,
      tooltip: this.translations['admin.dashboard.graph.users.MALE'] + ': ' + maleNumberPresences + ' (' + this.formatNumberForDashboard(maleNumberPresences, (maleNumberPresences + femaleNumberPresences)) + '%)' + ' - ' + this.translations['admin.dashboard.graph.users.FEMALE'] + ': ' + femaleNumberPresences + ' (' + this.formatNumberForDashboard(femaleNumberPresences, (maleNumberPresences + femaleNumberPresences)) + '%)',
      maleSeriesName: this.translations['admin.dashboard.graph.users.MALE'],
      femaleSeriesName: this.translations['admin.dashboard.graph.users.FEMALE'],
      maleTooltip: this.translations['admin.dashboard.graph.users.MALE'] + ': ' + maleNumberPresences + ' (' + this.formatNumberForDashboard(maleNumberPresences, (maleNumberPresences + femaleNumberPresences)) + '%)',
      femaleTooltip: this.translations['admin.dashboard.graph.users.FEMALE'] + ': ' + femaleNumberPresences + ' (' + this.formatNumberForDashboard(femaleNumberPresences, (maleNumberPresences + femaleNumberPresences)) + '%)'
    }];
    console.log("genderGraphData", this.genderGraphData);
  }

  // Recupera le informazioni per costruire il garfico "fasce d'età"
  getAgesGraphData(dashboardData: UserAgesDashboardData) {
    this.isLoadingAgesGraphData = true;

    if (this.getAgesGraphData$) {
      this.getAgesGraphData$.unsubscribe();
    }

    this.getAgesGraphData$ = this.rentService.getAgeRangesForDashboard(this.selectedFilters?.client?.id || null, this.selectedFilters?.campaign?.id || null, this.selectedFilters?.round?.id || null, this.selectedFilters?.userType?.id || null)
      .subscribe((data: SenecaResponse<MapById<string>>) => {
        if (data.error) {
          this.dispatchModal('067', data.error);
        } else {

          let registrations05 = dashboardData.registrations["0-5"] || 0;
          let reservation05 = dashboardData.reservation["0-5"] || 0;
          let presences05 = dashboardData.presences["0-5"] || 0;

          let registrations617 = dashboardData.registrations["6-17"] || 0;
          let reservation617 = dashboardData.reservation["6-17"] || 0;
          let presences617 = dashboardData.presences["6-17"] || 0;

          let registrations1834 = dashboardData.registrations["18-34"] || 0;
          let reservation1834 = dashboardData.reservation["18-34"] || 0;
          let presences1834 = dashboardData.presences["18-34"] || 0;

          let registrations3554 = dashboardData.registrations["35-54"] || 0;
          let reservation3554 = dashboardData.reservation["35-54"] || 0;
          let presences3554 = dashboardData.presences["35-54"] || 0;

          let registrations5564 = dashboardData.registrations["55-64"] || 0;
          let reservation5564 = dashboardData.reservation["55-64"] || 0;
          let presences5564 = dashboardData.presences["55-64"] || 0;

          let registrations65 = dashboardData.registrations["65+"] || 0;
          let reservation65 = dashboardData.reservation["65+"] || 0;
          let presences65 = dashboardData.presences["65+"] || 0;

          this.agesGraphData = [
            {
              label: this.translations['admin.dashboard.graph.ages.6'],
              registered: registrations05,
              booked: reservation05,
              present: presences05,
              tooltip: this.translations['admin.dashboard.graph.users.REGISTERED'] + ': ' + registrations05 + ' (' + this.formatNumberForDashboard(registrations05, (registrations05 + registrations617 + registrations1834 + registrations3554 + registrations5564 + registrations65)) + '%)' + ' - ' + this.translations['admin.dashboard.graph.users.BOOKED'] + ': ' + reservation05 + ' (' + this.formatNumberForDashboard(reservation05, (reservation05 + reservation617 + reservation1834 + reservation3554 + reservation5564 + reservation65)) + '%)' + ' - ' + this.translations['admin.dashboard.graph.users.PRESENT'] + ': ' + presences05 + ' (' + this.formatNumberForDashboard(presences05, (presences05 + presences617 + presences1834 + presences3554 + presences5564 + presences65)) + '%)'
            },
            {
              label: this.translations['admin.dashboard.graph.ages.17'],
              registered: registrations617,
              booked: reservation617,
              present: presences617,
              tooltip: this.translations['admin.dashboard.graph.users.REGISTERED'] + ': ' + registrations617 + ' (' + this.formatNumberForDashboard(registrations617, (registrations05 + registrations617 + registrations1834 + registrations3554 + registrations5564 + registrations65)) + '%)' + ' - ' + this.translations['admin.dashboard.graph.users.BOOKED'] + ': ' + reservation617 + ' (' + this.formatNumberForDashboard(reservation617, (reservation05 + reservation617 + reservation1834 + reservation3554 + reservation5564 + reservation65)) + '%)' + ' - ' + this.translations['admin.dashboard.graph.users.PRESENT'] + ': ' + presences617 + ' (' + this.formatNumberForDashboard(presences617, (presences05 + presences617 + presences1834 + presences3554 + presences5564 + presences65)) + '%)'
            },
            {
              label: this.translations['admin.dashboard.graph.ages.34'],
              registered: registrations1834,
              booked: reservation1834,
              present: presences1834,
              tooltip: this.translations['admin.dashboard.graph.users.REGISTERED'] + ': ' + registrations1834 + ' (' + this.formatNumberForDashboard(registrations1834, (registrations05 + registrations617 + registrations1834 + registrations3554 + registrations5564 + registrations65)) + '%)' + ' - ' + this.translations['admin.dashboard.graph.users.BOOKED'] + ': ' + reservation1834 + ' (' + this.formatNumberForDashboard(reservation1834, (reservation05 + reservation617 + reservation1834 + reservation3554 + reservation5564 + reservation65)) + '%)' + ' - ' + this.translations['admin.dashboard.graph.users.PRESENT'] + ': ' + presences1834 + ' (' + this.formatNumberForDashboard(presences1834, (presences05 + presences617 + presences1834 + presences3554 + presences5564 + presences65)) + '%)'
            },
            {
              label: this.translations['admin.dashboard.graph.ages.54'],
              registered: registrations3554,
              booked: reservation3554,
              present: presences3554,
              tooltip: this.translations['admin.dashboard.graph.users.REGISTERED'] + ': ' + registrations3554 + ' (' + this.formatNumberForDashboard(registrations3554, (registrations05 + registrations617 + registrations1834 + registrations3554 + registrations5564 + registrations65)) + '%)' + ' - ' + this.translations['admin.dashboard.graph.users.BOOKED'] + ': ' + reservation3554 + ' (' + this.formatNumberForDashboard(reservation3554, (reservation05 + reservation617 + reservation1834 + reservation3554 + reservation5564 + reservation65)) + '%)' + ' - ' + this.translations['admin.dashboard.graph.users.PRESENT'] + ': ' + presences3554 + ' (' + this.formatNumberForDashboard(presences3554, (presences05 + presences617 + presences1834 + presences3554 + presences5564 + presences65)) + '%)'
            },
            {
              label: this.translations['admin.dashboard.graph.ages.64'],
              registered: registrations5564,
              booked: reservation5564,
              present: dashboardData.presences["55-64"] || 0,
              tooltip: this.translations['admin.dashboard.graph.users.REGISTERED'] + ': ' + registrations5564 + ' (' + this.formatNumberForDashboard(registrations5564, (registrations05 + registrations617 + registrations1834 + registrations3554 + registrations5564 + registrations65)) + '%)' + ' - ' + this.translations['admin.dashboard.graph.users.BOOKED'] + ': ' + reservation5564 + ' (' + this.formatNumberForDashboard(reservation5564, (reservation05 + reservation617 + reservation1834 + reservation3554 + reservation5564 + reservation65)) + '%)' + ' - ' + this.translations['admin.dashboard.graph.users.PRESENT'] + ': ' + presences5564 + ' (' + this.formatNumberForDashboard(presences5564, (presences05 + presences617 + presences1834 + presences3554 + presences5564 + presences65)) + '%)'
            },
            {
              label: this.translations['admin.dashboard.graph.ages.65'],
              registered: registrations65,
              booked: reservation65,
              present: presences65,
              tooltip: this.translations['admin.dashboard.graph.users.REGISTERED'] + ': ' + registrations65 + ' (' + this.formatNumberForDashboard(registrations65, (registrations05 + registrations617 + registrations1834 + registrations3554 + registrations5564 + registrations65)) + '%)' + ' - ' + this.translations['admin.dashboard.graph.users.BOOKED'] + ': ' + reservation65 + ' (' + this.formatNumberForDashboard(reservation65, (reservation05 + reservation617 + reservation1834 + reservation3554 + reservation5564 + reservation65)) + '%)' + ' - ' + this.translations['admin.dashboard.graph.users.PRESENT'] + ': ' + presences65 + ' (' + this.formatNumberForDashboard(presences65, (presences05 + presences617 + presences1834 + presences3554 + presences5564 + presences65)) + '%)'
            },
          ];
        }
        this.isLoadingAgesGraphData = false;
      }, (err: any) => {
        if (err && err.message) {
          this.dispatchModal('068', err.message);
        } else {
          this.dispatchModal('069', err);
        }
        this.isLoadingAgesGraphData = false;
      })
  }


  saveInstance(chart: any, name: string) {
    this.chartInstances[name] = chart.component;
  }

  customizeTooltipLevel = (info: any) => ({
    html: `<div><div class='tooltip-header'>${info.argumentText}</div>`
      + '<div class=\'tooltip-body\'>'
      + '<div class=\'value-text\'>'
      + `<span class='top-series-value'>${info?.points[0]?.percentText || '0.00%'} - ${info?.point?.data?.tooltip || ''}</span>`
      + '</div></div></div>',
  });

  customizeVaccineNumberTooltip = (info: any) => ({
    html: `<div><div class='tooltip-header'>${info.argumentText}</div>`
      + '<div class=\'tooltip-body\'>'
      + '<div class=\'value-text\'>'
      + `<span class='top-series-value'>${info?.point?.data?.tooltip || ''}</span>`
      + '</div></div></div>',
  });

  customizeTooltipSplit = (info: any) => ({
    html: `<div><div class='tooltip-header'>${info.argumentText}</div>`
      + '<div class=\'tooltip-body\'>'
      + '<div class=\'value-text\'>'
      + `<span class='top-series-value'>${info?.point?.data?.tooltip || ''}</span>`
      + '</div></div></div>',
  });

  /* Tooltip del grafico "genere"
  customizeGenderTooltip(info: any) {
    let tooltip = { html: '' };
    if (info && info.seriesName) {
      const maleTranslation = info.point.data.maleSeriesName;
      const femaleTranslation = info.point.data.femaleSeriesName;

      if (info.seriesName === maleTranslation) {
        tooltip.html = `<div><div class='tooltip-header'>${info.argumentText}</div>`
          + '<div class=\'tooltip-body\'>'
          + '<div class=\'value-text\'>'
          + `<span class='top-series-value'>${info.point.data.maleTooltip}</span>`
          + '</div></div></div>';
      } else if (info.seriesName === femaleTranslation) {
        tooltip.html = `<div><div class='tooltip-header'>${info.argumentText}</div>`
          + '<div class=\'tooltip-body\'>'
          + '<div class=\'value-text\'>'
          + `<span class='top-series-value'>${info.point.data.femaleTooltip}</span>`
          + '</div></div></div>';
      }
    }

    return tooltip;
  } */

  customizeTooltip(arg: any) {
    return {
      text: `${arg.seriesName} years: ${arg.valueText}`,
    };
  }

  customizeTooltipBookingGraph(arg: any) {
    return arg.valueText;
  }

  customizeItems(items: any) {
    const sortedItems: any[] = [];

    items.forEach((item: any) => {
      const startIndex = item.series.stack === 'male' ? 0 : 3;
      sortedItems.splice(startIndex, 0, item);
    });
    return sortedItems;
  }

  onTabClick(tab: any) {
    this.selectedTab = tab;
    this.getTableData(true);
  }

  resetTableData(preventSearchedText?: boolean) {
    this.tableData.fromRecord = 0;
    this.tableData.numRecords = 10;
    this.tableData.page = 0;
    this.tableData.counter = 0;
    this.tableData.list = [];

    if (!preventSearchedText) {
      this.tableSearchedText = '';
      this.tableSearchDate = null;
    }
  }

  getTableData(reset?: boolean, preventSearchedText?: boolean) {
    this.isLoadingTableData = true;
    if (this.getTableData$) {
      this.getTableData$.unsubscribe();
    }
    if (reset) {
      this.resetTableData(preventSearchedText);
    }

    if (this.selectedTab.id === 'structure') {
      this.getStructureTableData();
    } else if (this.selectedTab.id === 'date') {
      this.getDateTableData();
    } else if (this.selectedTab.id === 'society') {
      this.getSocietyTableData();
    }
  }

  // Recupera il conteggio e la lista delle strutture disponibili
  getStructureTableData() {
    this.isLoadingTableData = true;

    if (this.getTableData$) {
      this.getTableData$.unsubscribe();
    }

    this.getTableData$ = this.rentService.countStructuresForDashboard(this.selectedFilters?.client?.id || null, this.selectedFilters?.campaign?.id || null, this.selectedFilters?.round?.id || null, this.selectedFilters?.userType?.id || null, this.tableSearchedText || '')
      .pipe(
        switchMap(
          (counter: SenecaResponse<number>) => {
            if (counter.error) {
              // Torno un observable simulando una senecaResponse per continuare il flusso dello stream
              return of(new SenecaResponse(counter.error, null))
            } else {
              // Salvo il counter
              this.tableData.counter = counter.response;

              // Calcolo la paginazione
              this.tableData.fromRecord = this.tableData.page * this.tableData.numRecords;

              if (this.tableData.counter) {
                return this.rentService.getStructuresForDashboard(this.tableData.fromRecord, this.tableData.numRecords, this.selectedFilters?.client?.id || null, this.selectedFilters?.campaign?.id || null, this.selectedFilters?.round?.id || null, this.selectedFilters?.userType?.id || null, this.tableSearchedText || '')
              } else {
                // Torno un observable simulando una senecaResponse per continuare il flusso dello stream
                return of(new SenecaResponse(null, []));
              }
            }
          }
        ), catchError((err, caught) => {
          if (err && err.message) {
            this.dispatchModal('073', err.message);
          }
          this.isLoadingTableData = false;
          // Torniamo l'Observable di errore, affinché si possa ri-provare l'operazione
          return throwError(new Error(err.message));
        }),
        take(1)
      ).subscribe(
        (data: SenecaResponse<any>) => {
          if (data.error) {
            this.dispatchModal('074', data.error);
          } else {
            this.tableData.list = data.response
          }
          this.isLoadingTableData = false;
        }
        , (err: any) => {
          this.isLoadingTableData = false;
          if (err && err.message) {
            this.dispatchModal('075', err.message);
          }
          return throwError(new Error(err.message));
        }
      );
  }

  // Sta in ascolto del cambio pagina delle tabelle
  tablePaginationChanged(newPage?: number) {
    this.tableData.page = newPage ? newPage : 0;

    // Avvio una nuova ricerca
    if (this.selectedTab.id === 'structure') {
      this.getStructureTableData();
    } else if (this.selectedTab.id === 'date') {
      this.getDateTableData();
    } else if (this.selectedTab.id === 'society') {
      this.getSocietyTableData();
    }
  }

  // Recupera i dati per la tabella contenente le date
  getDateTableData() {
    this.isLoadingTableData = true;

    if (this.getDateTableData$) {
      this.getDateTableData$.unsubscribe();
    }

    this.getDateTableData$ = this.rentService.countDetailByDateForDashboard(this.selectedFilters?.client?.id || null, this.selectedFilters?.campaign?.id || null, this.selectedFilters?.round?.id || null, this.selectedFilters?.userType?.id || null, this.tableSearchDate)
      .pipe(
        switchMap(
          (counter: SenecaResponse<number>) => {
            if (counter.error) {
              // Torno un observable simulando una senecaResponse per continuare il flusso dello stream
              return of(new SenecaResponse(counter.error, null))
            } else {
              // Salvo il counter
              this.tableData.counter = counter.response;

              // Calcolo la paginazione
              this.tableData.fromRecord = this.tableData.page * this.tableData.numRecords;

              if (this.tableData.counter) {
                return this.rentService.getDetailByDateForDashboard(this.tableData.fromRecord, this.tableData.numRecords, this.selectedFilters?.client?.id || null, this.selectedFilters?.campaign?.id || null, this.selectedFilters?.round?.id || null, this.selectedFilters?.userType?.id || null, this.tableSearchDate)
              } else {
                // Torno un observable simulando una senecaResponse per continuare il flusso dello stream
                return of(new SenecaResponse(null, []));
              }
            }
          }
        ), catchError((err, caught) => {
          if (err && err.message) {
            this.dispatchModal('073', err.message);
          }
          this.isLoadingTableData = false;
          // Torniamo l'Observable di errore, affinché si possa ri-provare l'operazione
          return throwError(new Error(err.message));
        }),
        take(1)
      ).subscribe(
        (data: SenecaResponse<any>) => {
          if (data.error) {
            this.dispatchModal('074', data.error);
          } else {
            this.tableData.list = [];
            let dates = Object.keys(data.response);
            for (let i = 0; i < dates.length; i++) {
              let tempDate = {
                date: dates[i],
                present: data.response[dates[i]]['YES'] || 0,
                absent: data.response[dates[i]]['NO'] || 0,
                reserved: data.response[dates[i]]['RESERVED'] || 0,
              }
              this.tableData.list.push(tempDate);
            }
          }
          this.isLoadingTableData = false;
        }
        , (err: any) => {
          this.isLoadingTableData = false;
          if (err && err.message) {
            this.dispatchModal('075', err.message);
          }
          return throwError(new Error(err.message));
        }
      );
  }

  // Recupera i dati della terza tabella, quella con i dettagli per società
  getSocietyTableData() {
    this.isLoadingTableData = true;

    if (this.getSocietyTableData$) {
      this.getSocietyTableData$.unsubscribe();
    }

    this.getSocietyTableData$ = this.rentService.countDetailBySocietiesForDashboard(this.selectedFilters?.client?.id || null, this.selectedFilters?.campaign?.id || null, this.selectedFilters?.round?.id || null, this.selectedFilters?.userType?.id || null, this.tableSearchedText || '')
      .pipe(
        switchMap(
          (counter: SenecaResponse<number>) => {
            if (counter.error) {
              // Torno un observable simulando una senecaResponse per continuare il flusso dello stream
              return of(new SenecaResponse(counter.error, null))
            } else {
              // Salvo il counter
              this.tableData.counter = counter.response;

              // Calcolo la paginazione
              this.tableData.fromRecord = this.tableData.page * this.tableData.numRecords;

              if (this.tableData.counter) {
                return this.rentService.getDetailBySocietiesForDashboard(this.tableData.fromRecord, this.tableData.numRecords, this.selectedFilters?.client?.id || null, this.selectedFilters?.campaign?.id || null, this.selectedFilters?.round?.id || null, this.selectedFilters?.userType?.id || null, this.tableSearchedText || '')
              } else {
                // Torno un observable simulando una senecaResponse per continuare il flusso dello stream
                return of(new SenecaResponse(null, []));
              }
            }
          }
        ), catchError((err, caught) => {
          if (err && err.message) {
            this.dispatchModal('073', err.message);
          }
          this.isLoadingTableData = false;
          // Torniamo l'Observable di errore, affinché si possa ri-provare l'operazione
          return throwError(new Error(err.message));
        }),
        take(1)
      ).subscribe(
        (data: SenecaResponse<any>) => {
          if (data.error) {
            this.dispatchModal('074', data.error);
          } else {
            this.tableData.list = [];

            if (data.response && data.response.length) {
              for (let i = 0; i < data.response.length; i++) {
                data.response[i].present = data.response[i].presentAttendancesCount || 0;
                data.response[i].absent = data.response[i].absentAttendanceCount || 0;
                data.response[i].reserved = data.response[i].reservedAttendancesCount || 0;
              }
            }
            this.tableData.list = data.response || [];
          }

          this.isLoadingTableData = false;
        }
        , (err: any) => {
          this.isLoadingTableData = false;
          if (err && err.message) {
            this.dispatchModal('075', err.message);
          }
          return throwError(new Error(err.message));
        }
      );
  }

  changeTableSearchedText(text: string) {
    this.tableSearchedText = text;
  }

  changeTableSearchDate(value: Date) {
    if (value) {
      this.tableSearchDate = new Date(value);
      this.tableSearchDate.setHours(5, 0, 0, 0);
    } else {
      this.tableSearchDate = undefined;
    }

    // Avvio una nuova ricerca
    this.getTableData(true, true);
  }

  customSearch(text: string, item: any) {
    text = text.toLocaleLowerCase();
    return item.name.toLocaleLowerCase().indexOf(text) > -1;
  }

  /**
   * Recupera token dalla chiave dell'url
   * @param callback
   */
  getTokenFromSsortkqp(callback: (value: string) => void) {
    this.authService.crateRetrieveTokenAfterLogin()
      .pipe(takeUntil(this._unsubscribeSignal$.asObservable()))
      .subscribe(result => {
        if (result && result.error) {
          this.dispatchModal('094', result.error);
          this.isDownloadingReport = false;
        } else {
          callback(result.response);
        }
      }, (err) => {
        this.isDownloadingReport = false;
        if (err && err.message) {
          this.dispatchModal('094', err.message);
        }
      });
  }

  // Apre una modale informativa sul grafico delle prenotazioni
  openBookingGraphInfoModal() {
    this.modalService.open('bookingGrapInfohModal');
  }

  closeBookingGraphInfoModal() {
    this.modalService.close('bookingGrapInfohModal');
  }

  // Apre una modale informativa sul grafico delle prenotazioni
  openReservationGraphInfoModal() {
    this.modalService.open('reservationGrapInfohModal');
  }

  closeReservationGraphInfoModal() {
    this.modalService.close('reservationGrapInfohModal');
  }

  getReportingDataPresence() {
    let result: number = (this.presenceGraph.present + this.presenceGraph.nonPresent) / this.presenceGraph.total || 0;
    let formattedResult: string = (result * 100).toFixed(2);
    return formattedResult;
  }

  openGenderInfoModal() {
    this.modalService.open('genderInfoModal');
  }

  closeGenderInfoModal() {
    this.modalService.close('genderInfoModal');
  }

  ngOnDestroy() {
    this._unsubscribeSignal$.next();
    this._unsubscribeSignal$.unsubscribe();

    if (this.getTableData$) {
      this.getTableData$.unsubscribe();
    }

    if (this.getRegistrationGraphData$) {
      this.getRegistrationGraphData$.unsubscribe();
    }

    if (this.getBookingGraphData$) {
      this.getBookingGraphData$.unsubscribe();
    }

    if (this.getPresenceGraphData$) {
      this.getPresenceGraphData$.unsubscribe();
    }

    if (this.getVaccineGraphData$) {
      this.getVaccineGraphData$.unsubscribe();
    }

    if (this.getClientList$) {
      this.getClientList$.unsubscribe();
    }

    if (this.getCampaignList$) {
      this.getCampaignList$.unsubscribe();
    }

    if (this.getRoundListByCampaignForAdmin$) {
      this.getRoundListByCampaignForAdmin$.unsubscribe();
    }

    if (this.exportDashboard$) {
      this.exportDashboard$.unsubscribe();
    }

    if (this.getDateTableData$) {
      this.getDateTableData$.unsubscribe();
    }

    if (this.getSocietyTableData$) {
      this.getSocietyTableData$.unsubscribe();
    }
  }
}

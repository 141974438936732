/*
 * Servizio che gestisce le chiamate ai servizi per le lingue
*/

import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { UrlService } from "../../shared/services/url.service";
import { Observable } from 'rxjs';
import { SenecaResponse } from 'atfcore-commonclasses/bin/classes/common';
import { handleCustomList, handleFromRecord, handleNumRecords, handleTitle } from 'src/app/utils/utils';
import { SupplierPerson } from 'atfcore-commonclasses/bin/classes/supplier';
import { HealthFacilityInfo, InfoVenue, VenueMinorTypes } from 'atfcore-commonclasses/bin/classes/campaignmanager';

export interface Comune {
  codice: string;
  denominazione: string;
  provincia: string;
}

@Injectable()
export class SupplierService {

  _mediatorUrl: string;

  constructor(private http: HttpClient, private urlService: UrlService) {
    this._mediatorUrl = this.urlService.getServiceMediatorUrl();
  }

  /**
   * Numero di clienti per referente
   * @param {string} groupId
   * @return {number}
   */
  countCustomerReferentForAdmin(groupId: string): Observable<SenecaResponse<number>> {
    let httpParams = new HttpParams();

    httpParams = httpParams.append('groupId', groupId);

    return this.http.get<SenecaResponse<number>>(
      this._mediatorUrl + 'count-customer-referent-for-admin', { params: httpParams }
    );
  }

  /**
   * Numero di SS
   * @param {string} supplierName
   * @return {number}
   */
  countHealthFacilitySupplierForAdmin(supplierName?: string): Observable<SenecaResponse<number>> {
    let httpParams = new HttpParams();

    if (supplierName) {
      httpParams = httpParams.append('supplierName', supplierName);
    }

    return this.http.get<SenecaResponse<number>>(
      this._mediatorUrl + 'count-health-facility-supplier-for-admin', { params: httpParams }
    );
  }

  /**
   * Numero di SS per referente
   * @param {string} supplierId
   * @return {number}
   */
  countHealthFacilitySupplierReferentForAdmin(supplierId: string): Observable<SenecaResponse<number>> {
    let httpParams = new HttpParams();

    httpParams = httpParams.append('supplierId', supplierId);

    return this.http.get<SenecaResponse<number>>(
      this._mediatorUrl + 'count-health-facility-supplier-referent-for-admin', { params: httpParams }
    );
  }

  /**
   * Numero di sedi per strutture con referente
   * @param {string} supplierId
   * @param {string} title
   * @param {string[]} roundId
   * @param {string[]} ignoreVenueForRoundId
   * @return {number}
   */
  countVenueByHealthFacilitySupplierForAdmin(supplierId?: string, title?: string, roundId?: string | string[],
    ignoreVenueForRoundId?: string | string[]): Observable<SenecaResponse<number>> {
    let httpParams = new HttpParams();

    if (supplierId) {
      httpParams = httpParams.append('supplierId', supplierId);
    }
    httpParams = handleTitle(httpParams, title);

    httpParams = handleCustomList(httpParams, 'roundId', roundId);
    httpParams = handleCustomList(httpParams, 'ignoreVenueForRoundId', ignoreVenueForRoundId);

    return this.http.get<SenecaResponse<number>>(
      this._mediatorUrl + 'count-venue-by-health-facility-supplier-for-admin', { params: httpParams }
    );
  }

  /**
   * Create a new customer by name
   * @param customerName
   * @return {boolean}
   */
  createCustomerForAdmin(customerName: string): Observable<SenecaResponse<boolean>> {
    return this.http.post<SenecaResponse<boolean>>(
      this._mediatorUrl + 'create-customer-for-admin',
      {
        customerName
      });
  }

  /**
   * Crea un referente legato ad un cliente
   * @param forename
   * @param surname
   * @param referentMail
   * @param groupId
   * @return {boolean}
   */
  createCustomerReferentForAdmin(forename: string, surname: string, referentMail: string,
    groupId: string): Observable<SenecaResponse<boolean>> {
    return this.http.post<SenecaResponse<boolean>>(
      this._mediatorUrl + 'create-customer-referent-for-admin',
      {
        forename,
        surname,
        referentMail,
        groupId
      });
  }

  /**
   * Creazione di una struttura sanitaria
   * @param healthFacilitySupplierName
   * @param description
   * @param code
   * @param address
   * @param latitude
   * @param longitude
   * @return {boolean}
   */
  createHealthFacilitySupplierForAdmin(healthFacilitySupplierName: string, code?: string, address?: string,
    latitude?: string, longitude?: string, description?: string): Observable<SenecaResponse<boolean>> {
    return this.http.post<SenecaResponse<boolean>>(
      this._mediatorUrl + 'create-health-facility-supplier-for-admin',
      {
        healthFacilitySupplierName,
        description,
        code,
        address,
        latitude,
        longitude
      });
  }

  /**
   * Crea il referente della sede di una SS
   * @param forename
   * @param surname
   * @param referentMail
   * @param supplierId
   * @return {boolean}
   */
  createHealthFacilitySupplierReferentForAdmin(forename: string, surname: string, referentMail: string,
    supplierId: string): Observable<SenecaResponse<boolean>> {
    return this.http.post<SenecaResponse<boolean>>(
      this._mediatorUrl + 'create-health-facility-supplier-referent-for-admin',
      {
        forename,
        surname,
        referentMail,
        supplierId
      }
    );
  }

  /**
   * Crea una nuova sede a partire dalla struttura
   * @param {string} supplierId
   * @param {string} venueName
   * @param {string} venueCode
   * @param {string} address
   * @param {string} active
   * @param {string} privateDescription
   * @param {string} description
   * @param {string} privateVenueForSupplierCustomerId
   * @param {string} contactMail
   * @param {string} contactNumber
   * @param {VenueMinorTypes[]} minorVaccinations
   * @param {boolean} secondVaccinationForMinorNeverVaccinated
   * @param {string} longitude
   * @param {string} latitude
   * @return {boolean}
   */
  createVenueByHealthFacilitySupplierForAdmin(supplierId: string, venueName: string, venueCode: string, address: string, longitude: string,
    latitude: string, active: boolean, minorVaccinations: VenueMinorTypes[], secondVaccinationForMinorNeverVaccinated?: boolean,
    privateDescription?: string, description?: string, privateVenueForSupplierCustomerId?: string, contactMail?: string,
    contactNumber?: string): Observable<SenecaResponse<boolean>> {
    return this.http.post<SenecaResponse<boolean>>(
      this._mediatorUrl + 'create-venue-by-health-facility-supplier-for-admin',
      {
        supplierId,
        venueName,
        venueCode,
        address,
        active,
        privateDescription,
        description,
        privateVenueForSupplierCustomerId,
        contactMail,
        contactNumber,
        minorVaccinations,
        longitude,
        latitude,
        secondVaccinationForMinorNeverVaccinated
      }
    );
  }

  /**
   * Cancella il cliente
   * Eliminabile solo se non ci sono AD con prenotazioni
   * @param groupId
   * @return {boolean}
   */
  deleteCustomerForAdmin(groupId: string): Observable<SenecaResponse<boolean>> {
    return this.http.post<SenecaResponse<boolean>>(
      this._mediatorUrl + 'delete-customer-for-admin',
      {
        groupId
      }
    );
  }

  /**
   * Cancella il referente del cliente
   * @param referentId
   * @return {boolean}
   */
  deleteCustomerReferentForAdmin(referentId: string): Observable<SenecaResponse<boolean>> {
    return this.http.post<SenecaResponse<boolean>>(
      this._mediatorUrl + 'delete-customer-referent-for-admin',
      {
        referentId
      }
    );
  }

  /**
   * Cancella una SS
   * @param supplierId
   * @return {boolean}
   */
  deleteHealthFacilitySupplierForAdmin(supplierId: string): Observable<SenecaResponse<boolean>> {
    return this.http.post<SenecaResponse<boolean>>(
      this._mediatorUrl + 'delete-health-facility-supplier-for-admin',
      {
        supplierId
      }
    );
  }

  /**
   * Cancella il referente della SS
   * @param referentId
   * @return {boolean}
   */
  deleteHealthFacilitySupplierReferentForAdmin(referentId: string): Observable<SenecaResponse<boolean>> {
    return this.http.post<SenecaResponse<boolean>>(
      this._mediatorUrl + 'delete-health-facility-supplier-referent-for-admin',
      {
        referentId
      }
    );
  }

  /**
   * Retrieve all the municipality
   * @params provincia: sigla of provincia
   * @return {Comuni[]} Array with the comuni objects
   */
  getComuniList(provincia: string): Observable<SenecaResponse<Comune[]>> {
    let httpParams = new HttpParams();

    httpParams = httpParams.append('provincia', provincia);

    return this.http.get<SenecaResponse<Comune[]>>(
      this._mediatorUrl + 'get-comuni-list', { params: httpParams }
    );
  }

  /**
   * Recupera il referente di un cliente tramite id
   * @param groupId
   * @param supplierPersonId
   * @return {SupplierPerson}
   */
  getCustomerReferentForAdmin(groupId: string, supplierPersonId: string): Observable<SenecaResponse<SupplierPerson>> {
    let httpParams = new HttpParams();

    httpParams = httpParams.append('groupId', groupId);
    httpParams = httpParams.append('supplierPersonId', supplierPersonId);

    return this.http.get<SenecaResponse<SupplierPerson>>(
      this._mediatorUrl + 'get-customer-referent-for-admin', { params: httpParams }
    );
  }

  /**
   * Recupera struttura da ID
   * @param healFacilitySupplierId
   * @return {HealthFacilityInfo}
   */
  getHealthFacilitySupplierForAdmin(healFacilitySupplierId: string): Observable<SenecaResponse<HealthFacilityInfo>> {
    let httpParams = new HttpParams();

    httpParams = httpParams.append('healFacilitySupplierId', healFacilitySupplierId);

    return this.http.get<SenecaResponse<HealthFacilityInfo>>(
      this._mediatorUrl + 'get-health-facility-supplier-for-admin', { params: httpParams }
    );
  }

  /**
   * Recupera un referente da ID
   * @param supplierPersonId
   * @return {SupplierPerson}
   */
  getHealthFacilitySupplierReferentForAdmin(supplierPersonId: string): Observable<SenecaResponse<SupplierPerson>> {
    let httpParams = new HttpParams();

    httpParams = httpParams.append('supplierPersonId', supplierPersonId);

    return this.http.get<SenecaResponse<SupplierPerson>>(
      this._mediatorUrl + 'get-health-facility-supplier-referent-for-admin', { params: httpParams }
    );
  }

  /**
   * Lista referenti cliente
   * @param {string} groupId
   * @param {number} fromRecord
   * @param {number} numRecords
   * @return {SupplierPerson[]}
   */
  listCustomerReferentForAdmin(groupId: string, fromRecord: number, numRecords: number): Observable<SenecaResponse<SupplierPerson[]>> {
    let httpParams = new HttpParams();

    httpParams = httpParams.append('groupId', groupId);
    httpParams = handleFromRecord(httpParams, fromRecord);
    httpParams = handleNumRecords(httpParams, numRecords);

    return this.http.get<SenecaResponse<SupplierPerson[]>>(
      this._mediatorUrl + 'list-customer-referent-for-admin', { params: httpParams }
    );
  }

  /**
   * Lista SS
   * @param {number} fromRecord
   * @param {number} numRecords
   * @param {string} supplierName
   * @return {HealthFacilityInfo[]}
   */
  listHealthFacilitySupplierForAdmin(fromRecord: number, numRecords: number, supplierName?: string): Observable<SenecaResponse<HealthFacilityInfo[]>> {
    let httpParams = new HttpParams();

    httpParams = handleFromRecord(httpParams, fromRecord);
    httpParams = handleNumRecords(httpParams, numRecords);
    if (supplierName) {
      httpParams = httpParams.append('supplierName', supplierName);
    }

    return this.http.get<SenecaResponse<HealthFacilityInfo[]>>(
      this._mediatorUrl + 'list-health-facility-supplier-for-admin', { params: httpParams }
    );
  }

  /**
   * Lista referenti SS
   * @param {string} supplierId
   * @param {number} fromRecord
   * @param {number} numRecords
   * @return {SupplierPerson[]}
   */
  listHealthFacilitySupplierReferentForAdmin(supplierId: string, fromRecord: number, numRecords: number): Observable<SenecaResponse<SupplierPerson[]>> {
    let httpParams = new HttpParams();

    httpParams = httpParams.append('supplierId', supplierId);
    httpParams = handleFromRecord(httpParams, fromRecord);
    httpParams = handleNumRecords(httpParams, numRecords);

    return this.http.get<SenecaResponse<SupplierPerson[]>>(
      this._mediatorUrl + 'list-health-facility-supplier-referent-for-admin', { params: httpParams }
    );
  }

  /**
   * Lista sedi delle SS
   * @param {string} supplierId
   * @param {number} fromRecord
   * @param {number} numRecords
   * @param {string} title
   * @param {string[]} roundId
   * @param {string[]} ignoreVenueForRoundId
   * @return {InfoVenue[]}
   */
  listVenueByHealthFacilitySupplierForAdmin(fromRecord: number, numRecords: number, supplierId?: string, title?: string,
    roundId?: string | string[], ignoreVenueForRoundId?: string | string[]): Observable<SenecaResponse<InfoVenue[]>> {
    let httpParams = new HttpParams();

    if (supplierId) {
      httpParams = httpParams.append('supplierId', supplierId);
    }
    httpParams = handleFromRecord(httpParams, fromRecord);
    httpParams = handleNumRecords(httpParams, numRecords);
    httpParams = handleTitle(httpParams, title);

    httpParams = handleCustomList(httpParams, 'roundId', roundId);
    httpParams = handleCustomList(httpParams, 'ignoreVenueForRoundId', ignoreVenueForRoundId);

    return this.http.get<SenecaResponse<InfoVenue[]>>(
      this._mediatorUrl + 'list-venue-by-health-facility-supplier-for-admin', { params: httpParams }
    );
  }

  /**
   * Create a new customer by name
   * @param customerName
   * @param customerId
   * @return {boolean}
   */
  updateCustomerForAdmin(customerName: string, customerId: string): Observable<SenecaResponse<boolean>> {
    return this.http.post<SenecaResponse<boolean>>(
      this._mediatorUrl + 'update-customer-for-admin',
      {
        customerName,
        customerId
      });
  }

  /**
   * Aggiorna il referente di un cliente
   * @param {string} referentId
   * @param {string} surname
   * @param {string} forename
   * @param {string} referentMail
   * @return {boolean}
   */
  updateCustomerReferentForAdmin(referentId: string, surname?: string, forename?: string,
    referentMail?: string): Observable<SenecaResponse<boolean>> {
    return this.http.post<SenecaResponse<boolean>>(
      this._mediatorUrl + 'update-customer-referent-for-admin',
      {
        referentId,
        surname,
        forename,
        referentMail
      }
    );
  }

  /**
   * Aggiorna una struttura sanitaria
   * @param healthFacilitySupplierName
   * @param description
   * @param code
   * @param address
   * @param healthFacilitySupplierId
   * @param latitude
   * @param longitude
   * @return {boolean}
   */
  updateHealthFacilitySupplierForAdmin(healthFacilitySupplierName: string, healthFacilitySupplierId: string,
    code?: string, address?: string, latitude?: string, longitude?: string, description?: string): Observable<SenecaResponse<boolean>> {

    return this.http.post<SenecaResponse<boolean>>(
      this._mediatorUrl + 'update-health-facility-supplier-for-admin',
      {
        healthFacilitySupplierName,
        description,
        code,
        address,
        healthFacilitySupplierId,
        latitude,
        longitude
      });
  }

  /**
   * Aggiorna il referente di una SS
   * @param {string} referentId
   * @param {string} surname
   * @param {string} forename
   * @param {string} referentMail
   * @return {boolean}
   */
  updateHealthFacilitySupplierReferentForAdmin(referentId: string, surname?: string, forename?: string,
    referentMail?: string, ignoreReceiveStructureSetPresenceReminderMail?: boolean): Observable<SenecaResponse<boolean>> {
    return this.http.post<SenecaResponse<boolean>>(
      this._mediatorUrl + 'update-health-facility-supplier-referent-for-admin',
      {
        referentId,
        surname,
        forename,
        referentMail,
        ignoreReceiveStructureSetPresenceReminderMail
      }
    );
  }

  /**
   * Crea una nuova sede a partire dalla struttura
   * @param {string} supplierId
   * @param {string} venueName
   * @param {string} venueCode
   * @param {string} address
   * @param {string} active
   * @param {string} privateDescription
   * @param {string} description
   * @param {string} privateVenueForSupplierCustomerId
   * @param {string} contactMail
   * @param {string} contactNumber
   * @param {VenueMinorTypes[]} minorVaccinations
   * @param {boolean} secondVaccinationForMinorNeverVaccinated
   * @param {string} longitude
   * @param {string} latitude
   * @return {boolean}
   */
  updateVenueByHealthFacilitySupplierForAdmin(supplierId: string, venueName: string, venueCode: string, address: string,
    longitude: string, latitude: string, itemId: string, active: boolean, minorVaccinations: VenueMinorTypes[],
    secondVaccinationForMinorNeverVaccinated?: boolean, privateDescription?: string, description?: string,
    privateVenueForSupplierCustomerId?: string, contactMail?: string, contactNumber?: string): Observable<SenecaResponse<boolean>> {
    return this.http.post<SenecaResponse<boolean>>(
      this._mediatorUrl + 'update-venue-by-health-facility-supplier-for-admin',
      {
        supplierId,
        venueName,
        venueCode,
        address,
        active,
        privateDescription,
        description,
        privateVenueForSupplierCustomerId,
        secondVaccinationForMinorNeverVaccinated,
        contactMail,
        contactNumber,
        minorVaccinations,
        longitude,
        latitude,
        itemId
      }
    );
  }

  // Setta l'accesso completo alla dashboard per i clienti (auth admin)
  setSupplierFullDashboardAccess(supplierPersonId: string, fullDashboard: boolean): Observable<SenecaResponse<boolean>> {
    return this.http.post<SenecaResponse<boolean>>(
      this._mediatorUrl + 'set-supplier-full-dashboard-access',
      {
        supplierPersonId,
        fullDashboard
      });
  }
}

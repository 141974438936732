/*
 * Servizio che gestisce le chiamate ai servizi per le lingue
*/

import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { UrlService } from "../../shared/services/url.service";
import { Observable } from 'rxjs';
import { SenecaResponse } from 'atfcore-commonclasses/bin/classes/common';
import { handleCustomList, handleFromRecord, handleNumRecords } from 'src/app/utils/utils';
import { InfoVenue } from 'atfcore-commonclasses/bin/classes/campaignmanager';

// From ReferenceTypes
export type RequestType = 'EVENT_APPROVATION_SLOT_REQUEST' | 'EVENT_REVOKE_SLOT_REQUEST' | 'EVENT_DESCRIPTION_UPDATE_REQUEST';

export interface InfoRequest extends InfoVenue {
  slotToProcess: number,
  hasBookedSlots: boolean,
  requestDate: Date,
  requestId: string,
  requestType: RequestType
}

export interface InfoRequestCount {
  EVENT_APPROVATION_SLOT_REQUEST: number,
  EVENT_REVOKE_SLOT_REQUEST: number,
  EVENT_DESCRIPTION_UPDATE_REQUEST: number
}

@Injectable()
export class EngagementService {

  _mediatorUrl: string;

  constructor(private http: HttpClient, private urlService: UrlService) {
    this._mediatorUrl = this.urlService.getServiceMediatorUrl();
  }

  /**
   * Richieste da processare per admin
   * @param venueId
   * @param roundId
   * @param campaignIds
   * @param requestType
   * @param campaignTitle
   * @param venueTitle
   * @param strutureName
   * @param structureCode
   * @param structureProvince
   * @param structureCity
   * @param requestFromDate
   * @param requestToDate
   * @returns
   */
  countVenueRequestsToProcessForAdmin(venueId?: string | string[], roundId?: string | string[], campaignIds?: string | string[],
    requestType?: string | string[], requestFromDate?: Date, requestToDate?: Date, campaignTitle?: string,
    venueTitle?: string, strutureName?: string, structureCode?: string, structureProvince?: string,
    structureCity?: string): Observable<SenecaResponse<number>> {
    let httpParams = new HttpParams();

    if (campaignTitle) {
      httpParams = httpParams.append('campaignTitle', campaignTitle);
    }

    if (venueTitle) {
      httpParams = httpParams.append('venueTitle', venueTitle);
    }

    if (strutureName) {
      httpParams = httpParams.append('strutureName', strutureName);
    }

    if (structureCode) {
      httpParams = httpParams.append('structureCode', structureCode);
    }

    if (structureProvince) {
      httpParams = httpParams.append('structureProvince', structureProvince);
    }

    if (structureCity) {
      httpParams = httpParams.append('structureCity', structureCity);
    }

    httpParams = handleCustomList(httpParams, 'venueId', venueId);
    httpParams = handleCustomList(httpParams, 'roundId', roundId);
    httpParams = handleCustomList(httpParams, 'campaignIds', campaignIds);
    httpParams = handleCustomList(httpParams, 'requestType', requestType);

    if (requestFromDate) {
      httpParams = httpParams.append('requestFromDate', requestFromDate.toISOString());
    }

    if (requestToDate) {
      httpParams = httpParams.append('requestToDate', requestToDate.toISOString());
    }

    return this.http.get<SenecaResponse<number>>(
      this._mediatorUrl + 'count-venue-requests-to-process-for-admin', { params: httpParams }
    );
  }

  getVenueRequestsToProcessCountsForAdmin(): Observable<SenecaResponse<InfoRequestCount>> {
    return this.http.get<SenecaResponse<InfoRequestCount>>(
      this._mediatorUrl + 'get-venue-requests-to-process-counts-for-admin'
    );
  }

  /**
   * Lista di richieste pendenti
   * @param requestId Filtro per richiesta specifica
   * @param venueId
   * @param roundId
   * @param campaignIds
   * @param fromRecord
   * @param numRecords
   * @param requestType
   * @param requestFromDate
   * @param requestToDate
   * @param campaignTitle
   * @param venueTitle
   * @param strutureName
   * @return {InfoRequest[]}
   */
  getVenueRequestsToProcessForAdmin(requestId?: string, venueId?: string | string[], roundId?: string | string[], campaignIds?: string | string[],
    fromRecord?: number, numRecords?: number, requestType?: string | string[], requestFromDate?: Date, requestToDate?: Date,
    campaignTitle?: string, venueTitle?: string, strutureName?: string, structureCode?: string, structureProvince?: string,
    structureCity?: string): Observable<SenecaResponse<InfoRequest[]>> {
    let httpParams = new HttpParams();

    if (requestId) {
      httpParams = httpParams.append('requestId', requestId);
    }

    httpParams = handleFromRecord(httpParams, fromRecord);
    httpParams = handleNumRecords(httpParams, numRecords);

    httpParams = handleCustomList(httpParams, 'venueId', venueId);
    httpParams = handleCustomList(httpParams, 'roundId', roundId);
    httpParams = handleCustomList(httpParams, 'campaignIds', campaignIds);
    httpParams = handleCustomList(httpParams, 'requestType', requestType);

    if (requestFromDate) {
      httpParams = httpParams.append('requestFromDate', requestFromDate.toISOString());
    }

    if (requestToDate) {
      httpParams = httpParams.append('requestToDate', requestToDate.toISOString());
    }

    if (campaignTitle) {
      httpParams = httpParams.append('campaignTitle', campaignTitle);
    }

    if (venueTitle) {
      httpParams = httpParams.append('venueTitle', venueTitle);
    }

    if (strutureName) {
      httpParams = httpParams.append('strutureName', strutureName);
    }

    if (structureCode) {
      httpParams = httpParams.append('structureCode', structureCode);
    }

    if (structureProvince) {
      httpParams = httpParams.append('structureProvince', structureProvince);
    }

    if (structureCity) {
      httpParams = httpParams.append('structureCity', structureCity);
    }

    return this.http.get<SenecaResponse<InfoRequest[]>>(
      this._mediatorUrl + 'get-venue-requests-to-process-for-admin', { params: httpParams }
    );
  }

  getUserPresenceDashboard(campaignId: string, roundId: string) {
    let httpParams = new HttpParams();

    httpParams = httpParams.append('campaignId', campaignId);
    httpParams = httpParams.append('roundId', roundId);

    return this.http.get<SenecaResponse<InfoRequest[]>>(
      this._mediatorUrl + 'get-venue-requests-to-process-for-admin', { params: httpParams }
    );
  }

}

import { Component, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { JwtPayload } from 'atfcore-commonclasses/bin/classes/auth';
import { combineLatest, Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { RedirectService } from 'src/app/shared/services/redirect.service';

import * as fromApp from "../../ngrx/app.reducers";

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit, OnDestroy {

  isUser: boolean = false;
  isEnel: boolean = false;

  private _unsubscribeSignal$: Subject<void> = new Subject();

  constructor(
    private appStore: Store<fromApp.AppState>,
    private store: Store<fromApp.AppState>,
    private redirectService: RedirectService) {
  }

  ngOnInit() {
    const loggedUser$: Observable<JwtPayload> = this.appStore.select(fromApp.getLoggedUser);
    const isUser$: Observable<boolean> = this.store.select(fromApp.getIsUser);

    combineLatest([loggedUser$, isUser$])
      .pipe(takeUntil(this._unsubscribeSignal$.asObservable()))
      .subscribe(
        ([loggedUser, isUser]) => {
          this.isUser = isUser;
          if (isUser) {
            if (loggedUser && loggedUser.params && loggedUser.params.supplier && loggedUser.params.supplier.supplierName) {
              this.isEnel = (loggedUser.params.supplier.supplierName as string).toLowerCase().indexOf('enel') !== -1;
            }
          }
        });
  }

  ngOnDestroy() {
    this._unsubscribeSignal$.next();
    this._unsubscribeSignal$.unsubscribe();
  }

  goToPrivacy() {
    this.redirectService.goToPrivacyPage(this.isEnel);
  }

  goToAccessibility(){
    this.redirectService.goToAccessibility();
  }
}

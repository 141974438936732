<main aria-label="Contenuto principale della pagina" tabindex="-1">
  <div class="add-ticket-container" fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="start start"
    fxLayoutAlign.lt-md="start center">

    <div class="add-ticket-side" fxLayout="column" fxLayoutAlign="space-between">

      <div fxLayout="column" fxLayoutAlign="start start">
        <div class="add-ticket-back" fxLayout="row" fxLayoutAlign="start center" fxFlexOffset="40px" (click)="goBack()"
          tabindex="0" clickKeyBoard (onEnterKeyboard)="goBack();" role="button">
          <svg-icon fxFlexOffset="40px" [applyClass]="true" src="./assets/img/icons/arrow-white-left.svg"></svg-icon>
          <h5 class="white" fxFlexOffset="12px">{{ 'generic.GO_BACK' | translate | uppercase}}</h5>
        </div>

        <p fxFlexOffset="24px">
          <ng-container>
            <span translate="addTicket.ASK_SUPPORT"></span>
          </ng-container>
        </p>

        <div fxFlexOffset="24px" class="add-ticket-tick"></div>

        <p fxFlexOffset="24px">
          <ng-container>
            <svg-icon src="assets/img/icons/faq-support.svg"></svg-icon>
          </ng-container>
        </p>

        <p fxFlexOffset="24px">
          <ng-container>
            <h5 translate="addTicket.INSTRUCTIONS_CREATE_FAQ"></h5>
          </ng-container>
        </p>
      </div>

      <div fxShow fxHide.lt-md fxFlexOffset="40px"></div>
      <div fxShow fxHide.lt-md fxFlexOffset="40px"></div>

    </div>

    <div fxFlex fxLayout="row" fxLayout.xs="column" fxLayoutAlign="center" fxLayoutAlign.xs="start"
      class="add-ticket-body-container">

      <form class="add-ticket-body" [formGroup]="addTicketForm" fxLayout="column" fxLayoutAlign="start center"
        (ngSubmit)="confirmAddTicket()">

        <!-- Nuovo ticket -->
        <ng-container>
          <h5 class="bold-text full-width" translate="addTicket.TITLE" ngClass.xs="center"></h5>
          <h5 translate="addTicket.DESCRIPTION" class="full-width" ngClass.xs="center"></h5>
        </ng-container>

        <!-- Nome Utente -->
        <input-container isWithoutInfoIcon fxFlexOffset="24px" class="full-width"
          [title]="isUserCreatingTicket() ? ('addTicket.NAME_PREFILLED' | translate) : ('addTicket.NAME' | translate)"
          [readonly]="isUserCreatingTicket() && loggedUser.forename ? name.valid : false" [for]="'name'" [id]="'name'">
          <welion-input [inputValue]="name" [placeholder]="'addTicket.INSERT_NAME' | translate"
            [disabled]="isLoading || isUserCreatingTicket()" [required]="true" [id]="'name'"
            [ariaLabelledbyLabel]="'name'">
          </welion-input>
        </input-container>

        <!-- Cognome -->
        <input-container isWithoutInfoIcon fxFlexOffset="24px" class="full-width"
          [title]="isUserCreatingTicket() ? ('addTicket.SURNAME_PREFILLED' | translate) : ('addTicket.SURNAME' | translate)"
          [readonly]="isUserCreatingTicket() && loggedUser.surname ? surname.valid : false" [for]="'surname'"
          [id]="'surname'">
          <welion-input [inputValue]="surname" [placeholder]="'addTicket.INSERT_SURNAME' | translate"
            [disabled]="isLoading || isUserCreatingTicket()" [required]="true" [id]="'surname'"
            [ariaLabelledbyLabel]="'surname'">
          </welion-input>
        </input-container>

        <!-- Codice fiscale -->
        <input-container isWithoutInfoIcon fxFlexOffset="24px" class="full-width"
          [title]="isUserCreatingTicket() ? ('addTicket.FISCAL_CODE_PREFILLED' | translate) : ('addTicket.FISCAL_CODE' | translate)"
          [readonly]="isUserCreatingTicket() && loggedUser.codFisc ? fiscalCode.valid : false" [for]="'fiscalCode'"
          [id]="'fiscalCode'">
          <welion-input [inputValue]="fiscalCode" [required]="true"
            [placeholder]="'addTicket.INSERT_FISCAL_CODE' | translate" [disabled]="isLoading || isUserCreatingTicket()"
            [id]="'fiscalCode'" [ariaLabelledbyLabel]="'fiscalCode'">
          </welion-input>
        </input-container>

        <!-- Azienda -->
        <input-container isWithoutInfoIcon fxFlexOffset="24px" class="full-width"
          [title]="isUserCreatingTicket() ? ('addTicket.COMPANY_PREFILLED' | translate) : ('addTicket.COMPANY' | translate)"
          [readonly]="(isUserCreatingTicket() && (loggedUser.company || loggedUser.socDistacco)) ? true : false"
          [for]="'company'" [id]="'company'">
          <welion-input [inputValue]="company" [placeholder]="'addTicket.COMPANY_NAME' | translate"
            [disabled]="isLoading || isUserCreatingTicket()" [required]="true" [id]="'company'"
            [ariaLabelledbyLabel]="'company'">
          </welion-input>
        </input-container>

        <!-- Mail -->
        <input-container isWithoutInfoIcon fxFlexOffset="24px" class="full-width" [for]="'mail'" [id]="'mail'"
          [instructions]="'addTicket.EMAIL_INSTRUCTIONS' | translate" [title]="'addTicket.EMAIL' | translate"
          [ariaLabelledbyId]="'mail-suggested'">
          <welion-input [inputValue]="mail" [placeholder]="'addTicket.EMAIL_PLACEHOLDER' | translate"
            [disabled]="isLoading" [required]="true" [id]="'mail'" [ariaLabelledbyLabel]="'mail'"
            [ariaLabelledbyId]="'mail-suggested'">
          </welion-input>
        </input-container>

        <!-- Numero di telefono -->
        <input-container isWithoutInfoIcon fxFlexOffset="24px" class="full-width"
          [title]="isUserCreatingTicket() ? ('addTicket.PHONE_PREFILLED_AND_EDITABLE' | translate) : ('addTicket.PHONE_NUMBER' | translate)"
          [instructions]="'addTicket.PHONE_NUMBER_INSTRUCTIONS' | translate" [for]="'phone'" [id]="'phone'"
          [ariaLabelledbyId]="'phone-suggested'">
          <welion-input [inputValue]="phoneNumber" [placeholder]="'addTicket.PHONE_NUMBER_PLACEHOLDER' | translate"
            [disabled]="isLoading" [id]="'phone'" [ariaLabelledbyLabel]="'phone'" [ariaLabelledbyId]="'phone-suggested'"
            [required]="true" [error]="phoneNumber.invalid && !phoneNumber.pristine"
            [errorText]="'errors.CHARACTERS_NOT_ALLOWED' | translate">
          </welion-input>
        </input-container>

        <!-- Tipologia ticket -->
        <input-container isWithoutInfoIcon fxFlexOffset="24px" class="full-width"
          [title]="'addTicket.TICKET_TYPE' | translate" [for]="'ticket-type'" [id]="'ticket-type'">
          <welion-select [ariaLabelledbyLabel]="'ticket-type'" [required]="true"
            [attrAriaLabel]="'accessibilityAttributes.SELECT_TICKET_TYPE' | translate"
            [labelForId]="'welion-input-ticket-type'" [placeholder]="'addTicket.TICKET_TYPE_PLACEHOLDER' | translate"
            [items]="ticketTypes" [selectedItem]="ticketType.value"
            (change)="onChangeTicketType($event)"></welion-select>
        </input-container>

        <!-- Oggetto ticket -->
        <input-container isWithoutInfoIcon fxFlexOffset="24px" class="full-width"
          [title]="'addTicket.TICKET_OBJECT' | translate" [for]="'ticket-object'" [id]="'ticket-object'">
          <welion-select [ariaLabelledbyLabel]="'ticket-object'" [required]="true"
            [attrAriaLabel]="'addTicket.TICKET_TYPE_PLACEHOLDER' | translate"
            [labelForId]="'welion-input-ticket-object'" [disabled]="ticketType.invalid"
            [placeholder]="'addTicket.TICKET_TYPE_PLACEHOLDER' | translate" [items]="ticketObjectsToUse"
            [selectedItem]="ticketObject.value" (change)="onChangeTicketObject($event)">
          </welion-select>
        </input-container>

        <!-- Testo ticket -->
        <input-container isWithoutInfoIcon fxFlexOffset="24px" class="full-width"
          [title]="'addTicket.TICKET_TEXT' | translate" [for]="'ticket-text'" [id]="'ticket-text'">
          <welion-text-area [ariaLabelledbyLabel]="'ticket-text'" [required]="true"
            [attrAriaLabel]="'addTicket.TICKET_TEXT_PLACEHOLDER' | translate"
            [placeholder]="'addTicket.TICKET_TEXT_PLACEHOLDER' | translate" [inputValue]="ticketText"
            [disabled]="isLoading" [id]="'welion-input-ticket-text'"></welion-text-area>
        </input-container>

        <div *ngIf="isLoading" class="loader spinner-big"></div>

        <!-- Crea Ticket -->
        <div fxFlexOffset="24px" class="full-width" fxLayout="row" fxLayoutAlign="center center">
          <welion-button width="264px" type="primary" size="large" [disabled]="addTicketForm.invalid || isLoading"
            [label]="'addTicket.SEND_REQUEST'" buttonType="submit">
          </welion-button>
        </div>

        <div fxHide fxShow.xs fxFlex.xs="40px"></div>

      </form>

    </div>

  </div>
</main>
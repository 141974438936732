import { Component } from '@angular/core';
import { Store } from '@ngrx/store';

import * as fromApp from '../../ngrx/app.reducers';

import { TranslateService } from '@ngx-translate/core';
import { ActivatedRoute } from '@angular/router';
import { DeviceDetectorService } from 'ngx-device-detector';
import { UrlService } from 'src/app/shared/services/url.service';

@Component({
  selector: 'app-sso-accenture-login',
  templateUrl: './sso-accenture-login.component.html'
})
export class SsoAccentureLoginComponent {
  redirectUrl: string = '';

  constructor(private store: Store<fromApp.AppState>,
    public translate: TranslateService,
    private deviceService: DeviceDetectorService,
    private urlService: UrlService,
    private route: ActivatedRoute) {
    const redirectUrl$: any = this.store.select(
      fromApp.getRedirectUrl
    );
    redirectUrl$.subscribe((redirectUrl: string) => {
      this.redirectUrl = redirectUrl;
    });

    this.route.queryParams.subscribe(params => {

      // Prima di chiamare il servizio per il login, identifico l'userAgent e il tipo di device dell'utente
      const deviceInfo = this.deviceService.getDeviceInfo();
      const userAgent = deviceInfo && deviceInfo.userAgent;
      let deviceType: string = '';
      if (this.deviceService.isMobile()) {
        // Salvo il fatto che è uno smartphone
        deviceType = 'P';
      } else if (this.deviceService.isTablet()) {
        // Salvo il fatto che è un tablet
        deviceType = 'T';
      } else if (this.deviceService.isDesktop()) {
        // Salvo il fatto che è un computer desktop
        deviceType = 'D';
      }

      // Ridireziono l'utente verso il sistema di SSO
      let redirectUrlAfterLogin = '';
      if (this.redirectUrl && this.redirectUrl.length) {
        redirectUrlAfterLogin = '/#' + this.redirectUrl;
      } else {
        redirectUrlAfterLogin = '/#/signinSSO';
      }

      redirectUrlAfterLogin = redirectUrlAfterLogin;

      const redirectUrl = this.urlService.getSSOAccentureUrl(deviceType, userAgent, redirectUrlAfterLogin);
      window.location.href = redirectUrl;
    });
  }
}

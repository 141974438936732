<div class="page-content-wrapper">

    <div class="header-title-container">
        <p class="title" translate="clientLanding.TITLE"></p>
        <p class="subtitle" translate="clientLanding.SUBTITLE"></p>
    </div>

    <div class="tile-container">

        <!-- Campagne -->
        <div class="campaigns-card" fxLayout="column">

            <div fxFlexOffset="24px" fxFlexOffset.xs="0px" fxLayout="row" fxLayoutAlign="start center">
                <svg-icon class="note" [applyClass]="true" src="assets/img/icons/aventi-diritto-icon.svg"></svg-icon>

                <div fxFlexOffset="120px" fxLayout="column">
                    <h3 translate="clientLanding.cards.LIST_TITLE"></h3>
                    <h5 fxFlexOffset="4px" translate="clientLanding.cards.LIST_DESCR"></h5>
                    <div fxShow fxHide.xs fxLayout="row" fxFlexOffset="12px" fxFlexOffset.xs="20px">
                        <ng-container [ngTemplateOutlet]="activeCampaigns"></ng-container>
                    </div>
                </div>

            </div>

            <div fxHide fxShow.xs fxLayout="row" fxLayoutAlign="center" fxFlexOffset="20px">
                <ng-container [ngTemplateOutlet]="activeCampaigns"></ng-container>
            </div>

        </div>

        <!-- Dashboard -->
        <div *ngIf="isWithCurrentYearCampaign" class="campaigns-card" fxLayout="column" fxLayoutAlign="space-between start">

            <div fxFlexOffset="24px" fxFlexOffset.xs="0px" fxLayout="row" fxLayoutAlign="start center">
                <svg-icon class="note" [applyClass]="true" src="assets/img/icons/note-campaign.svg"></svg-icon>

                <div fxFlexOffset="120px" fxLayout="column">
                    <h3 translate="clientLanding.cards.DASHBOARD_TITLE"></h3>
                    <h5 fxFlexOffset="4px" translate="clientLanding.cards.DASHBOARD_DESCR"></h5>
                    <div fxShow fxHide.xs fxLayout="row" fxFlexOffset="12px" fxFlexOffset.xs="20px">
                        <ng-container [ngTemplateOutlet]="activeDashboard"></ng-container>
                    </div>
                </div>

            </div>

            <div fxHide fxShow.xs fxLayout="row" fxLayoutAlign="center" fxFlexOffset="20px">
                <ng-container [ngTemplateOutlet]="activeDashboard"></ng-container>
            </div>

        </div>
    </div>
</div>


<ng-template #activeCampaigns>
    <welion-button (onClick)="goToList()" type="primary bordless" size="medium" label="clientLanding.cards.LIST_ACTION">
    </welion-button>
</ng-template>

<ng-template #activeDashboard>
    <welion-button (onClick)="goToDashboard()" type="primary bordless" size="medium"
        label="clientLanding.cards.DASHBOARD_ACTION">
    </welion-button>
</ng-template>
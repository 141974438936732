import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { Component, EventEmitter, Input, OnInit, Output, ChangeDetectionStrategy } from '@angular/core';

@Component({
  selector: 'tab-light',
  templateUrl: 'tab-light.component.html',
  styleUrls: ['./tab-light.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TabLightComponent implements OnInit {
  @Input() tabTitle: string = '';
  @Input() attrAriaLabel: string = '';
  @Input() isDisabled: boolean = false;
  @Input() isCompleted: boolean = false;
  @Input() isUppercase: boolean = false;

  @Input() tabId!: string;
  @Input() tabPanelId: string = '';

  @Output() onClick: EventEmitter<any> = new EventEmitter();

  _isCurrentTab: boolean = false;
  @Input() set isCurrentTab(value: boolean | string) {
    this._isCurrentTab = coerceBooleanProperty(value);
  };

  _searchStep: boolean = false;
  @Input() set searchStep(value: boolean | string) {
    this._searchStep = coerceBooleanProperty(value);
  };

  constructor() { }

  ngOnInit() { }

  emitOnModelChanged(data?: any) {
    if (!this.isDisabled || this.isCompleted) {
      this.onClick.emit(data);
    }
  }

}

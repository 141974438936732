import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ClientComponent } from './client.component';

import { ClientGuard } from '../shared/services/client-guard.service';

import { ClientHomeComponent } from './clientHome/clientHome.component';
import { ClientRoundDetailsComponent } from './clientRoundDetails/clientRoundDetails.component';
import { ClientLandingComponent } from './clientLanding/clientLanding.component';
import { AdminDashboardComponent } from '../shared/dashboard/admin-dashboard.component';

const clientRoutes: Routes = [
  {
    path: '', component: ClientComponent, children: [
      { path: 'home', component: ClientLandingComponent, canActivate: [ClientGuard] },
      { path: 'dashboard', component: AdminDashboardComponent, canActivate: [ClientGuard] },
      { path: 'list', component: ClientHomeComponent, canActivate: [ClientGuard] },
      { path: 'campaign/:campaignId/round/:roundId', component: ClientRoundDetailsComponent, canActivate: [ClientGuard] },
      { path: '', redirectTo: "home", pathMatch: "full" }
    ]
  }
];

@NgModule({
  imports: [
    RouterModule.forChild(clientRoutes)
  ],
  exports: [RouterModule]
})
export class ClientRoutingModule { }

<div class="avatar-notification-container">

  <div class="avatar-wrapper" [ngClass]="size" fxLayout="row" fxLayoutAlign="center center">
    <svg-icon *ngIf="src" [src]="src" class="avatar" [applyClass]="true"></svg-icon>
    <p *ngIf="!src">{{ initials }}</p>
  </div>

  <ng-container *ngIf="_user && _user.hasNotification">
    <div class="dot"></div>
    <div class="new" [ngClass]="size">
      <p translate="generic.AVATAR_NEW"></p>
    </div>
  </ng-container>

</div>

<page-container>
	<div class="sidenav-information">
		<sidenav-informations></sidenav-informations>
	</div>

	<div class="main-content">
		<div class="page-content">
			<svg-icon class="logo-container" [applyClass]="true" src="/assets/img/icons/app-logo.svg">
			</svg-icon>
			<p>
				<span class="small-font-weight" translate="404.WE_ARE_SORRY" tabindex="0"></span><br>
				<span translate="genericError.PROBLEM_FOUND" class="bold-text"  tabindex="0"></span>
			</p>
			<div>
				<p translate="genericError.TRY_AGAIN_LATER"  tabindex="0"></p>
				<div class="error-code-container" *ngIf="error && error.specificError">
					<p><span class="bold" translate="genericError.ERROR"  tabindex="0"></span> {{error.specificError}}</p>
				</div>
			</div>
		</div>
	</div>
</page-container>
import * as fromApp from "../../ngrx/app.reducers";
import * as CoreActions from "../../core/ngrx/core.actions";

import * as moment from 'moment';

import { Component, OnDestroy, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { RedirectService } from 'src/app/shared/services/redirect.service';
import { ActivatedRoute } from '@angular/router';
import { noop, Observable, Subject } from 'rxjs';
import { catchError, map, switchMap, takeUntil } from 'rxjs/operators';
import { DaySlots, InfoRound, InfoSlot, InfoSlotStatuses, InfoVenue, SenecaResponse } from 'atfcore-commonclasses';
import { Store } from "@ngrx/store";
import { getModalMessageData, getWarningModal } from "src/app/utils/utils";
import { RevokeSlotsInfo } from "src/app/utils/classes.utils";
import { DropdownButtonItem } from "src/app/shared/components/welion-select-button/welion-select-button.component";
import { ModalService } from "src/app/shared/components/modal/modal.service";
import { ScrollTo } from "src/app/core/services/scroll-to.service";
import { DropdownItem } from "src/app/shared/models/dropdown.model";
import { DatePipe, TitleCasePipe } from "@angular/common";
import { ApplicationModalMessage } from "src/app/core/ngrx/core.reducers";
import { ImportService } from "../services/import.service";
import { RentService } from "../services/rent.service";
import { FormControl, FormGroup, Validators } from "@angular/forms";


@Component({
  selector: 'app-round-structures',
  templateUrl: './roundStructures.component.html',
  styleUrls: ['./roundStructures.component.scss']
})
export class RoundStructuresComponent implements OnInit, OnDestroy {

  SERVICE_KEYS: string[] = ["HANDICAPPED_ACCESS", "ON_SITE_PARKING", "ON_STREET_PARKING", "CAR_PARKING", "PARKING_FOR_CYCLES_AND_MOTORCYCLES", "PAID_PARKING", "INFORMATION_AND_RECEPTION_SERVICE", "BAR"];
  VEHICLE_KEYS: string[] = ["CAR", "BICYCLES_AND_MOTORCYCLES", "BUS", "SUBWAY", "STREETCAR", "TRAIN", "CAB_STATION",]

  roundId: string = '';

  round?: InfoRound;
  private _isEndSlotsLoading: boolean = false;

  slots: InfoSlot[] = [];
  selectedSlots: InfoSlot[] = [];
  selectedDeleteSlots: InfoSlot[] = [];

  selectedStatus: string[] = [];

  selectedTab: number = 0

  dropdownAddItems: DropdownButtonItem[] = [
    {
      label: 'structures.round.action.AVAILABILITY',
      onClick: () => {
        this.selectedStructure ?
          this.redirectService.goToRoundStructureNewSlots(this.roundId, this.selectedStructure.id)
          : noop()
      }
    },
    {
      label: 'structures.round.action.MASSIVE_LOADING',
      onClick: () => this.openMassiveLoadingModal()
    }
  ];

  venueDescription: FormControl = new FormControl({ value: undefined, disabled: true });
  isVenueDescriptionEditingMode: boolean = false;

  slotReservationList: RevokeSlotsInfo[] = [];
  revokeStep: number = 1;
  private _firstLoad: boolean = true;

  private _unsubscribeSignal$: Subject<void> = new Subject();

  isToResetUploadComponent: boolean = false;
  showLoader: boolean = true;
  isLoadingSlots: boolean = true;
  fileObject: any = null;
  uploadAreaModalTooltip: ApplicationModalMessage = {
    modalId: '---',
    title: '',
    text: ''
  };

  //#region Structures

  private _structuresLoaded: number = 0;
  private _structureSearchedText: string = '';
  private _resetStructureResultList: boolean = false;

  disableStructureSelect: boolean = false;

  /**
   * When the list ended
   */
  private _isEndStructureList: boolean = false;

  isLoadingStructureList: boolean = false;
  structureList: DropdownItem[] = [];
  structureServive$: Subject<string> = new Subject<string>();
  selectedStructure?: any;

  venueId: string = '';

  structureDaySlots: DaySlots[] = [];

  // Dropdown for mobile
  structureDaySlotsDropdown: DropdownItem[] = [];
  selectedStructureDaySlotsDropdown?: DropdownItem;

  indexSelectedStructureDaySlots: number = 0;

  //#endregion

  revokeForm: FormGroup = new FormGroup({
    'justification': new FormControl(undefined, [Validators.required, Validators.maxLength(200)])
  });
  structureDetailsKeys: string[] = [];
  hasSavedDetails: boolean = false;

  get justification() {
    return this.revokeForm.get('justification') as FormControl;
  }

  answers: any = [
    {
      id: "true",
      value: "Si"
    },
    {
      id: "false",
      value: "No"
    }
  ]

  constructor(
    private route: ActivatedRoute,
    private appStore: Store<fromApp.AppState>,
    private translate: TranslateService,
    private rentService: RentService,
    private importService: ImportService,
    private redirectService: RedirectService,
    private modalService: ModalService,
    private scrollTo: ScrollTo,
    private datePipe: DatePipe,
    private titleCasePipe: TitleCasePipe
  ) { }

  //#region Inizializzazione round

  ngOnInit() {
    this.scrollTo.header();
    const roundId = this.route.snapshot.paramMap.get('roundId');
    this.venueId = this.route.snapshot.paramMap.get('officeId') || '';

    if (!roundId) {
      this.scrollTo.header();
      this.redirectService.goToHome();
    } else {
      this.roundId = roundId;
      this.getRound();
    }
  }

  ngOnDestroy() {
    this._unsubscribeSignal$.next();
    this._unsubscribeSignal$.unsubscribe();
  }

  /**
   * Recupera i dati del round sulla base dell'id
   * @param roundId
   */
  getRound() {
    this.rentService.getRound(this.roundId)
      .pipe(takeUntil(this._unsubscribeSignal$.asObservable()))
      .subscribe(
        (result: SenecaResponse<InfoRound>) => {
          if (result && result.error) {
            this.dispatchWarningModal('005', result.error);
            this.showLoader = false;
            this.isLoadingStructureList = false;
            this.isLoadingSlots = false;
            this._isEndSlotsLoading = true;
          } else {
            this.round = result.response;
            this.initStructures();
            // Avvio una prima ricerca
            this.structureServive$.next('');
          }
        }, (err) => {
          if (err && err.message) {
            this.dispatchWarningModal('006', err.message);
          }
          this.showLoader = false;
          this.isLoadingStructureList = false;
          this.isLoadingSlots = false;
          this._isEndSlotsLoading = true;
        });
  }

  //#endregion

  //#region Inizializzazione struttura e gestione

  /**
   * @description Carica le strutture e imposta per l'infinite scroll
   */
  initStructures() {
    this.structureList = this.structureList || [];
    this.structureServive$.pipe(
      switchMap(
        (text: string) => {
          if (text !== this._structureSearchedText) {
            this._resetStructureResultList = true;
            this._structuresLoaded = 0;
          } else {
            this._resetStructureResultList = false;
          }
          this._structureSearchedText = text || '';
          this.isLoadingStructureList = true;
          return this.getVenueList(this._structuresLoaded, 10, this._structureSearchedText);
        }
      )
      , catchError((err, caught) => {
        return caught;
      })
    )
      .subscribe({
        next: (eventData: Array<any>) => {
          if (this._resetStructureResultList) {
            this.clearStructureResults();
          }
          if (eventData && eventData.length) {
            this._structuresLoaded += eventData.length;
            this.structureList = this.structureList.concat(eventData);
            if (this._structuresLoaded === 1 && !this._structureSearchedText) {
              this.disableStructureSelect = true;
              this.selectedStructure = this.structureList[0];

              if (this.selectedStructure) {
                this.redirectService.goToRoundSlots(this.roundId, this.selectedStructure.id, true);
                this.venueId = (this.selectedStructure.content as InfoVenue).venueId;
                this.venueDescription.setValue((this.selectedStructure.content as InfoVenue).description);
                this.structureDetailsKeys = Object.keys(this.selectedStructure.content.details);
              }
            }
            if (this._structuresLoaded > 0 && this._firstLoad) {
              // Select della sede
              if (this.venueId) {
                let venueFinded: boolean = false;
                for (let i = 0; i < this.structureList.length; i++) {
                  if (this.structureList[i].id === this.venueId) {
                    this.onStructureSelected(this.structureList[i]);
                    venueFinded = true;
                    break;
                  }
                }

                if (!venueFinded) {
                  this.onStructureSelected(this.structureList[0]);
                }
              } else {
                this.onStructureSelected(this.structureList[0]);
              }
            }
            this.hasSavedDetails = this.checkIsSecondStepComplete();
          }

          this.isLoadingStructureList = false;
        },
        error: (err: any) => {
          this.isLoadingStructureList = false;
          this._isEndSlotsLoading = true;
        }
      });
  }

  /**
   * @description Carica e prepara gli elementi per la select con infinite scroll
   * @param fromRecord
   * @param numRecords
   * @param searchedText
   */
  getVenueList(fromRecord: number, numRecords: number, searchedText: string): Observable<DropdownItem[]> {
    return this.rentService.getVenueList(this.roundId, fromRecord, numRecords, searchedText)
      .pipe(
        takeUntil(this._unsubscribeSignal$.asObservable())
        , map((resultData: SenecaResponse<InfoVenue[]>) => {
          if (resultData.error) {
            this.dispatchWarningModal('007', resultData.error);
          } else if (resultData.response && resultData.response.length) {
            let formattedData: DropdownItem[] = [];

            resultData.response.forEach(structure => {
              let title = structure.name + ' - ' + structure.address;

              formattedData.push({
                id: structure.venueId,
                name: title,
                content: structure
              });
            });

            // Caricando n elementi per volta (itemsToLoad) se
            // ne carica di meno significa che sono a fine lista
            if (resultData.response.length < numRecords) {
              this._isEndStructureList = true;
            }

            return formattedData;
          }
          this._isEndStructureList = true;
          return [];
        })
      );
  }

  /**
   * @description Alla selezione di una struttura
   * @param structure
   */
  onStructureSelected(structure?: DropdownItem) {
    this.selectedStructure = structure;
    if (this.selectedStructure) {
      this.redirectService.goToRoundSlots(this.roundId, this.selectedStructure.id, true);
      this.venueId = (this.selectedStructure.content as InfoVenue).venueId;
      this.resetVenueDescriptionEditing();
      this.structureDetailsKeys = Object.keys(this.selectedStructure.content.details);

      this.hasSavedDetails = this.checkIsSecondStepComplete();
    }
    this.getVenueDaysSlots();
  }

  /**
   * @description Chiamata quando viene effettuata una nuova ricerca differente
   */
  clearStructureResults(): void {
    this._structuresLoaded = 0;
    this.structureList = [];
    this._resetStructureResultList = false;
  }

  /**
   * @description Chiamata allo scroll
   * @param scrollEvent
   */
  fetchMoreStructures(scrollEvent: { start: number; end: number }) {
    if (!this._isEndStructureList && scrollEvent.end === this._structuresLoaded) {
      this.structureServive$.next(this._structureSearchedText);
    }
  }

  /**
   * @description Al click del tab della struttura orari/info
   * @param tab
   */
  onTabClick(tab: number) {
    this.selectedTab = tab;
    this.resetVenueDescriptionEditing();
  }

  /**
   * Reset editing mode
   */
  resetVenueDescriptionEditing() {
    this.isVenueDescriptionEditingMode = false;
    if (this.selectedStructure) {
      this.venueDescription.setValue((this.selectedStructure.content as InfoVenue).description);
    }
    if ((this.selectedStructure.content as InfoVenue).requestDescriptionUpdateStatus == InfoSlotStatuses.PENDING) {
      this.venueDescription.disable();
    } else {
      this.venueDescription.enable();
    }
  }

  onVenueDescriptionEditingMode() {
    this.isVenueDescriptionEditingMode = true;
    this.venueDescription.enable();
  }

  onSaveNewVenueDescription() {
    let description = "";
    if (this.venueDescription.value != null) {
      description = this.venueDescription.value;
    }

    if (this.selectedStructure) {
      this.showLoader = true;

      let detailsForService: any = {};
      let allKeys: string[] = [];
      allKeys = allKeys.concat(this.SERVICE_KEYS).concat(this.VEHICLE_KEYS);
      for (let i = 0; i < allKeys.length; i++) {
        detailsForService[allKeys[i].toString()] = this.selectedStructure?.content?.details[allKeys[i]] || null;
      }

      this.rentService.requestUpdateVenueDescriptionById(this.selectedStructure.id, description, detailsForService)
        .pipe(takeUntil(this._unsubscribeSignal$.asObservable()))
        .subscribe((result) => {
          if (result.error) {
            this.dispatchWarningModal('205', result.error);
          } else {
            if (this.selectedStructure) {
              (this.selectedStructure.content as InfoVenue).requestDescriptionUpdateStatus = InfoSlotStatuses.PENDING;
              (this.selectedStructure.content as InfoVenue).requestedDescriptionUpdate = this.venueDescription.value;

              for (let i = 0; i < this.structureList.length; i++) {
                if (this.structureList[i].id === this.selectedStructure.id) {
                  this.structureList[i].content = this.selectedStructure.content;
                  break;
                }
              }
              this.hasSavedDetails = this.checkIsSecondStepComplete();
            }

            this.isVenueDescriptionEditingMode = false;
            this.venueDescription.disable();
            this.venueDescription.setValue(result.response.description);
            this.showLoader = false;
          }
        },
          (err: string) => {
            this.dispatchWarningModal('206', err);
            this.showLoader = false;
          });
    }
  }

  //#endregion

  //#region Raggruppamento giorni e slot

  /**
   * @description Recupera gli slots di una struttura suddivisi per giorni
   */
  getVenueDaysSlots() {

    if (this.selectedStructure) {

      this.indexSelectedStructureDaySlots = 0;
      this.structureDaySlots = [];

      this.structureDaySlotsDropdown = [];
      this.selectedStructureDaySlotsDropdown = undefined;

      this.selectedSlots = [];

      this.rentService.getVenueSlots(this.selectedStructure.id, this.roundId, true)
        .pipe(takeUntil(this._unsubscribeSignal$.asObservable()))
        .subscribe(
          (result: SenecaResponse<DaySlots[]>) => {
            if (result && result.error) {
              this.dispatchWarningModal('008', result.error);
              this.showLoader = false;
              this.isLoadingSlots = false;
              this._isEndSlotsLoading = true;
            } else {
              this.structureDaySlots = result.response;

              if (this.structureDaySlots && this.structureDaySlots.length) {
                // Per la select mobile
                this.structureDaySlots.forEach((daySlot, index) => {
                  this.structureDaySlotsDropdown.push({
                    id: index.toString(),
                    name: this.getDateForSelect(daySlot.date)
                  });
                });
                this.onSelectDaySlot(0);
              } else {
                this.showLoader = false;
                this.isLoadingSlots = false;
                this._isEndSlotsLoading = true;
              }
            }
          }, (err) => {
            if (err && err.message) {
              this.dispatchWarningModal('009', err.message);
            }
            this.showLoader = false;
            this.isLoadingSlots = false;
            this._isEndSlotsLoading = true;
          });
    }
  }

  /**
   * @param date Data da trasformare
   * @returns Stringa con formato Lunedì 1 Marzo
   */
  getDateForSelect(date: Date): string {
    return this.titleCasePipe.transform(this.datePipe.transform(date, 'EEEE dd MMMM') || '');
  }

  /**
   * @description Alla selezione di una giornata da schemata mobile
   * @param item
   */
  onSelectDaySlotMobile(item: DropdownItem) {
    this.onSelectDaySlot(Number.parseInt(item.id));
  }

  /**
   * @description Chiamata alla selezione di una giornata
   * @param indexDaySlot
   */
  onSelectDaySlot(indexDaySlot: number) {
    this.isLoadingSlots = true;
    this.indexSelectedStructureDaySlots = indexDaySlot;
    this.selectedSlots = [];
    this.selectedStructureDaySlotsDropdown = this.structureDaySlotsDropdown[indexDaySlot];
    this.slots = [];
    this.showLoader = false;
    this._isEndSlotsLoading = false;
    this.getDaySlots(true);
    if (!this._firstLoad) {
      this.scrollTo.elementWithDelay('round-structure-anchor');
    } else {
      this._firstLoad = false;
    }
  }

  onStatusClick(label: string) {
    const selectedIndex = this.selectedStatus.findIndex(x => x === label);
    if (selectedIndex === -1) {
      // Status da filtrare
      this.selectedStatus.push(label);
    } else {
      // Status da filtrare da rimuovere
      this.selectedStatus.splice(selectedIndex, 1);
    }
    this.isLoadingSlots = true;
    this.selectedSlots = [];
    this.slots = [];
    this.showLoader = false;
    this._isEndSlotsLoading = false;
    this.getDaySlots(true);
  }

  /**
   * @param currentStatus
   * @returns True se lo status è stato selezionato
   */
  statusIsSelected(currentStatus: string): boolean {
    return this.selectedStatus.filter(x => x === currentStatus).length > 0;
  }

  /**
   * @description Richiamato in caso di scroll o di selezione di una giornata
   * @param firstCall Se richiamato dalla selezione di una giornata
   */
  getDaySlots(firstCall?: boolean) {

    if (this.selectedStructure && this.structureDaySlots && (!this.isLoadingSlots || firstCall) && !this._isEndSlotsLoading) {

      this.isLoadingSlots = true;

      this.rentService.getVenueSlots(
        this.selectedStructure.id,
        this.roundId,
        false,
        this.structureDaySlots[this.indexSelectedStructureDaySlots].date,
        this.slots.length,
        96,
        this.selectedStatus)
        .pipe(takeUntil(this._unsubscribeSignal$.asObservable()))
        .subscribe(
          (result: SenecaResponse<DaySlots[]>) => {
            const response = result.response;

            if (result && result.error) {
              this.dispatchWarningModal('023', result.error);
              this._isEndSlotsLoading = true;
            } else {

              const slots = response.length ? response[0].slots : [];
              if (slots && slots.length) {
                this.slots = this.slots.concat(slots);

                if (slots.length < 96) {
                  this._isEndSlotsLoading = true;
                }

              } else {
                this._isEndSlotsLoading = true;
              }
            }

            this.isLoadingSlots = false;
          }, (err) => {
            if (err && err.message) {
              this.dispatchWarningModal('024', err.message);
            }
            this._isEndSlotsLoading = true;
            this.isLoadingSlots = false;
          });

    }
  }

  /**
   * @description Chiamata alla selezione di uno slot
   * @param index
   */
  selectSlotClick(index: number) {
    const slot = this.slots[index];
    if (slot.status === InfoSlotStatuses.APPROVED) {
      if (slot.isAvailable) {
        const selectedIndex = this.selectedSlots.findIndex(x => x.slotId === this.slots[index].slotId);
        if (selectedIndex === -1) {
          // Slot da aggiungere
          this.selectedSlots.push(this.slots[index]);
        } else {
          // Slot da rimuovere
          this.selectedSlots.splice(selectedIndex, 1);
        }
      } else {
        this.dispatchWarningModal('555', 'NOT_ALLOW_REVOKE_BUSY_SLOT');
      }
    } else if (slot.status === InfoSlotStatuses.APPROVATION_REQUEST) {
      const selectedIndex = this.selectedDeleteSlots.findIndex(x => x.slotId === this.slots[index].slotId);
      if (selectedIndex === -1) {
        // Slot da aggiungere
        this.selectedDeleteSlots.push(this.slots[index]);
      } else {
        // Slot da rimuovere
        this.selectedDeleteSlots.splice(selectedIndex, 1);
      }
    }
  }

  /**
   * @param currentSlot
   * @returns True se lo slot è stato selezionato
   */
  slotIsSelected(currentSlot: InfoSlot): boolean {
    if (currentSlot.status === InfoSlotStatuses.APPROVATION_REQUEST) {
      return this.selectedDeleteSlots.filter(x => x.slotId === currentSlot.slotId).length > 0;
    } else if (currentSlot.status === InfoSlotStatuses.APPROVED) {
      return this.selectedSlots.filter(x => x.slotId === currentSlot.slotId).length > 0;
    } else return false;
  }

  /**
   * @description Mostra l'helper delle info sugli slots
   */
  showHelper() {

  }

  //#endregion

  //#region Caricamento massivo

  /**
   * @description Apre la modale per il caricamento massivo
   */
  openMassiveLoadingModal() {
    this.resetUploadStructureData();
    this.modalService.open('massiveLoad');
  }

  /**
   * @description Chiude la modale per il caricamento massivo
   */
  closeMassiveLoadingModal() {
    this.modalService.close('massiveLoad');
  }

  /**
   * @description Verifica se il pulsante per confermare l'import massivo delle strutture è disabilitato
   */
  isConfirmUploadStructuresBtnDisabled(): boolean {
    return !this.fileObject || (this.fileObject && !this.fileObject.fileExtension);
  }

  /**
   * @description Pulisce i dati del form di upload delle strutture
   */
  resetUploadStructureData() {
    this.fileObject = null;
    this.isToResetUploadComponent = true;
  }

  /**
   * @description Effettua l'upload massivo delle strutture
   */
  uploadStructuresFile() {
    // Chiudo la modale, mostro il loader e chiamo il servizio di upload
    this.closeMassiveLoadingModal();
    this.showLoader = true;

    if (this.selectedStructure) {
      this.importService.importSlots(this.roundId, this.selectedStructure.id, this.fileObject)
        .pipe(takeUntil(this._unsubscribeSignal$.asObservable()))
        .subscribe((data: any) => {
          if (data.error) {
            this.dispatchWarningModal('013', data.error);
            this.showLoader = false;
          } else {
            this.dispatchMessageModal('structures.round.action.MASSIVE_LOADING', 'structures.SLOT_UPLOADED');
            this.getVenueDaysSlots();
          }
        },
          (err: string) => {
            this.dispatchWarningModal('015', err);
            this.showLoader = false;
          });
    }
  }

  /**
   * @description Salva localmente il file contenente tutte le strutture da caricare
   * @param file
   */
  saveStructuresFile(file: any) {
    this.fileObject = file;
    this.isToResetUploadComponent = false;
  }

  //#endregion

  //#region Revoca slot

  /**
   * @description Apre la modale per la revoca di un slot
   */
  openRevokeSlotModal() {
    this.revokeStep = 1;
    this.justification.setValue(undefined);
    this.slotReservationList = [];

    let index = 0;
    this.selectedSlots.forEach(slot => {

      // Se ci sono slot prenotati aggiungo alla lista
      if (!slot.isAvailable) {
        const diffInMinutes: number = moment(slot.endDate).diff(moment(slot.startDate), 'minutes'); // 10
        this.slotReservationList.push({
          index: index,
          startDate: slot.startDate,
          endDate: slot.endDate,
          even: index % 2 !== 0,
          duration: moment.duration(diffInMinutes, 'minutes').humanize() // 10 minuti
        });
        index++;
      }
    });

    this.modalService.open('revokeSlot');
  }

  /**
   * @description Chiude la modale per la revoca di un slot
   */
  closeRevokeSlotModal() {
    this.modalService.close('revokeSlot');
  }

  /**
   * @description Alla conferma della revoca mostra un secondo avvertimento
   */
  onConfirmRevoke() {
    if (this.revokeStep === 1) {
      this.revokeStep = 2;
    } else if (this.selectedSlots) {

      this.rentService.revokeSlots(this.selectedSlots.map(x => x.slotId), this.justification.value)
        .pipe(takeUntil(this._unsubscribeSignal$.asObservable()))
        .subscribe(
          (result: SenecaResponse<null>) => {
            if (result && result.error) {
              this.closeRevokeSlotModal();
              this.dispatchWarningModal('010', result.error);
            } else {
              this.selectedSlots.forEach(slot => {
                slot.status = 'REVOKE_REQUEST';
              });
              this.selectedSlots = [];
              this.closeRevokeSlotModal();
            }
          }, (err) => {
            if (err && err.message) {
              this.closeRevokeSlotModal();
              this.dispatchWarningModal('011', err.message);
            }
          });
    }
  }

  //#endregion

  //#region Elimina slot

  /**
   * @description Apre la modale per la cancellazione di uno slot
   */
  openDeleteSlotModal() {
    if (this.selectedDeleteSlots.length) {
      this.modalService.open('deleteSlot');
    }
  }

  /**
   * @description Chiude la modale per la cancellazione di uno slot
   */
  closeDeleteSlotModal() {
    this.modalService.close('deleteSlot');
    this.isLoadingSlots = false;
  }

  /**
   * @description Alla conferma della revoca mostra un secondo avvertimento
   */
  onConfirmDelete() {
    this.isLoadingSlots = true;

    const selectedDeleteSlots: string[] = this.selectedDeleteSlots.map(x => x.slotId);

    this.rentService.deleteSlots(selectedDeleteSlots)
      .pipe(takeUntil(this._unsubscribeSignal$.asObservable()))
      .subscribe(
        (result: SenecaResponse<boolean>) => {
          if (result && result.error) {
            this.closeDeleteSlotModal();
            this.dispatchWarningModal('010', result.error);
          } else {

            // Da cancellare tutta la giornata
            if (this.slots.length === selectedDeleteSlots.length) {
              this.structureDaySlots.splice(this.indexSelectedStructureDaySlots, 1);
              this.structureDaySlotsDropdown.splice(this.indexSelectedStructureDaySlots, 1);
              this.indexSelectedStructureDaySlots = 0;
            }

            this.selectedDeleteSlots = [];
            this.closeDeleteSlotModal();

            if (this.structureDaySlots.length) {
              // Ricarico la giornata
              this.onSelectDaySlot(this.indexSelectedStructureDaySlots);
            } else {
              // Apparentemente nessun giornata con slot
              this.getVenueDaysSlots();
            }
          }
        }, (err) => {
          this.closeDeleteSlotModal();
          if (err && err.message) {
            this.dispatchWarningModal('011', err.message);
          }
        });
  }

  //#endregion Elimina slot

  //#region Funzioni generali

  /**
   * @description Va all'homepage
   */
  goToHome() {
    this.redirectService.goToHome();
  }

  /**
   * @description Mostra una modale con titolo e messaggio
   * @param modalId
   * @param title
   * @param message
   */
  dispatchMessageModal(title: string, message: string) {
    const messageObj = getModalMessageData(this.translate, title, message);
    this.appStore.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
  }

  /**
   * @description Mostra una modale con un messaggio di errore
   * @param modalId
   * @param error
   */
  dispatchWarningModal(modalId: string, error: string) {
    const messageObj = getWarningModal(this.translate, modalId, error);
    this.appStore.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
  }

  //#endregion
  getDetailsAnswer(key: string) {
    if (this.selectedStructure && this.selectedStructure.content && this.selectedStructure.content.details && this.selectedStructure.content.details[key]) {
      return this.answers[0];
    } else if (this.selectedStructure && this.selectedStructure.content && this.selectedStructure.content.details && this.selectedStructure.content.details[key] == false) {
      return this.answers[1];
    } else {
      return null;
    }
  }


  onDetailsAnswerSelected(event: any, key: string) {
    if (this.selectedStructure && this.selectedStructure.content && this.selectedStructure.content.details) {
      this.selectedStructure.content.details[key] = event.id == "true";
    }
  }

  checkIsSecondStepComplete() {
    let allKeys: string[] = [];
    allKeys = allKeys.concat(this.SERVICE_KEYS).concat(this.VEHICLE_KEYS);
    let check = true;
    for (let i = 0; i < allKeys.length; i++) {
      let key = allKeys[i];
      if (!this.selectedStructure || !this.selectedStructure.content || !this.selectedStructure.content.details || !this.selectedStructure.content || this.selectedStructure.content.details[key] == null) {
        check = false;
        break;
      }
    }
    return check;
  }

  openModalIfSecondStepIncomplete() {
    this.dispatchWarningModal('005', "DETAILS_STEP_ERROR");
  }
}


<div class="page-content-wrapper" fxLayout="column">

  <!-- Clienti -->
  <h1 fxFlexOffset="40px" class="primary" translate="admin.clients.TITLE"></h1>

  <bread-crumbs fxFlexOffset="12px" firstLevel="CLIENTS"></bread-crumbs>

  <div fxFlexOffset="14px" fxFlexOffset.xs="12px" class="clients-container" fxLayout="column">

    <!-- Nome cliente -->
    <input-container [title]="'admin.clients.SEARCH_CLIENT' | translate">
      <welion-input [bindValue]="searchText" (onChange)="searchTextChange($event)" (onIconClicked)="searchClients()"
        [placeholder]="'admin.clients.SEARCH_CLIENT_PLACEHOLDER' | translate" [disabled]="isLoading"
        iconRight="assets/img/icons/icon-search.svg">
      </welion-input>
    </input-container>

    <div id="admin-clients-anchor" fxFlexOffset="10px"></div>

    <div fxLayout="row" fxFlexOffset="10px" fxLayoutAlign="space-between center">
      <!-- 4 clienti -->
      <h3 fxFlexOffset="16px">
        <ng-container *ngIf="!isLoadingCount && clientsCount === 1">
          {{ 'admin.clients.table.CLIENT_TITLE' | translate }}
        </ng-container>
        <ng-container *ngIf="!isLoadingCount && clientsCount !== 1">
          {{ 'admin.clients.table.CLIENTS_TITLE' | translate: { value: clientsCount || 0 }
          }}
        </ng-container>
        <ng-container *ngIf="isLoadingCount">
          {{ 'admin.clients.table.CLIENTS_TITLE_LOADING' | translate }}
        </ng-container>
      </h3>

      <!-- Crea cliente (non visibile se sono admin con visibilità limitata) -->
      <welion-button *ngIf="!isLimitedAdmin" width="160px" size="medium" type="primary"
        label="admin.clients.table.NEW_CLIENT" (onClick)="newClient()">
      </welion-button>
    </div>

    <!-- Table header -->
    <div fxFlexOffset="20px">
      <!-- Cliente -->
      <h5 class="bold" translate="admin.clients.table.CLIENT" fxFlex fxFlexOffset="16px"></h5>
      <!-- Strutture Dedicate -->
      <h5 class="bold" translate="admin.clients.table.DEDICATED_FACILITIES" fxFlex="14"></h5>
      <!-- Aventi diritto Totale -->
      <h5 class="bold" translate="admin.clients.table.TOTAL_ENTITLEDS" fxFlex="13"></h5>
      <!-- Aventi diritto Prenotati -->
      <h5 class="bold" translate="admin.clients.table.ASSIGNED_ENTITLEDS" fxFlex="13"></h5>
      <!-- Aventi diritto Reportizzati -->
      <h5 class="bold" translate="admin.clients.table.PROCESSED_ENTITLEDS" fxFlex="13"></h5>
      <!-- Aventi diritto Non processati -->
      <h5 class="bold" translate="admin.clients.table.NOT_ASSIGNED_ENTITLEDS" fxFlex="13"></h5>
      <!-- Aventi diritto Reportizzati -->
      <h5 class="bold" translate="admin.clients.table.PRESENT_ENTITLEDS" fxFlex="13"></h5>

      <div fxFlex="5"></div>
    </div>

    <!-- Loader -->
    <div *ngIf="isLoading" class="loader spinner-big"></div>

    <!-- Table rows -->
    <ng-container *ngIf="!isLoading">
      <div *ngFor="let client of clients; let first = first; let odd = odd" class="client-row" [ngClass]="{'odd': odd}"
        fxLayout="row" fxLayoutAlign="start center" [fxFlexOffset]="first ? '8px' : '0px'">
        <!-- Cliente -->
        <h6 class="black" fxFlex fxFlexOffset="16px">{{ client.title }}</h6>
        <!-- Strutture Dedicate -->
        <h6 class="black" fxFlex="14">{{ client.privateVenuecount || 0 }}</h6>
        <!-- Aventi diritto Totale -->
        <h6 class="black" fxFlex="13">{{ client.totalAttendancesInGroup || 0 }}</h6>
        <!-- Aventi diritto Prenotati -->
        <h6 class="black" fxFlex="13">{{ client.assignedAttendanceCount || 0 }}</h6>
        <!-- Aventi diritto Reportizzati -->
        <h6 class="black" fxFlex="13">{{ client.processedAttendanceCount || 0 }}</h6>
        <!-- Aventi diritto non processati -->
        <h6 class="black" fxFlex="13">{{ client.nonProcessedAttendanceCount || 0 }}</h6>
        <!-- Aventi diritto Presenti -->
        <h6 class="black" fxFlex="13">{{ client.presentAttendancesCount || 0 }}</h6>

        <div fxFlex="5" fxLayout="row" fxLayoutAlign="end">
          <svg-icon fxFlexOffset="20px" src="assets/img/icons/arrow-right-red.svg" class="cursor-pointer"
            [applyClass]="true" (click)="onClientClick(client)">
          </svg-icon>
          <div fxFlex="16px"></div>
        </div>
      </div>
    </ng-container>

    <welion-paginator fxFlexOffset="20px" [isLoading]="isLoading" [paginationLength]="paginationLength"
      [itemsCount]="clientsCount" (onClickPage)="getCustomers($event)"></welion-paginator>

  </div>

</div>
import { OnDestroy, Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { Store } from '@ngrx/store';
import * as fromApp from "../ngrx/app.reducers";
import * as moment from 'moment';
import { Router } from '@angular/router';

@Component({
  selector: 'app-structure',
  templateUrl: './structure.component.html',
  styleUrls: ['./structure.component.scss']
})
export class StructureComponent implements OnDestroy {
  applicationLang$: Subscription;
  applicationLang: string = '';

  constructor(
    private appStore: Store<fromApp.AppState>,
    private translate: TranslateService,
    public router: Router
  ) {
    // Recupero la lingua dell'appplicazione
    this.applicationLang$ = this.appStore.select(fromApp.getApplicationLang).subscribe((applicationLang) => {
      if (applicationLang) {
        this.applicationLang = applicationLang;
        this.translate.use(applicationLang);
        moment.locale(applicationLang);
      }
    });
  }

  ngOnDestroy(): void {
    if (this.applicationLang$) {
      this.applicationLang$.unsubscribe();
    }
  }
}

import { Component, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

import { User } from 'atfcore-commonclasses/bin/classes/anag';

import * as CoreActions from "../../core/ngrx/core.actions";
import * as fromApp from "../../ngrx/app.reducers";

import { RedirectService } from 'src/app/shared/services/redirect.service';
import { RentService } from 'src/app/structure/services/rent.service';
import { getWarningModal } from 'src/app/utils/utils';
import { GetCampaignListForHelpDeskResponse, HealthFacilityInfo } from 'atfcore-commonclasses/bin/classes/campaignmanager/serviceResponses';
import { ScrollTo } from 'src/app/core/services/scroll-to.service';
import { SenecaResponse } from 'atfcore-commonclasses';

@Component({
  selector: 'app-help-desk-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HelpDeskHomeComponent implements OnInit, OnDestroy {
  loggedUser?: User;

  isLoadingCount: boolean = true;
  isLoading: boolean = true;
  hasCampaignsFirstLoad: boolean = false;

  searchText?: string;

  private _unsubscribeSignal$: Subject<void> = new Subject();

  readonly paginationLength: number = 25;
  readonly structurePaginationLength: number = 25;

  campaignsCount: number = 0;
  campaigns: GetCampaignListForHelpDeskResponse[] = [];

  private _firstInit: boolean = true;

  healthFacilitiesCount: number = 0;
  healthFacilities: HealthFacilityInfo[] = [];
  isLoadingCountStructures: boolean = false;
  isLoadingStructures: boolean = false;

  canAccessCampaigns: boolean = false;
  canAccessStructures: boolean = false;

  constructor(
    private appStore: Store<fromApp.AppState>,
    private translate: TranslateService,
    private rentService: RentService,
    private redirectService: RedirectService,
    private scrollTo: ScrollTo
  ) { }

  ngOnInit() {

    this.scrollTo.header();

    this.appStore.select(fromApp.getLoggedUser)
      .pipe(takeUntil(this._unsubscribeSignal$.asObservable()))
      .subscribe(
        (loggedUser) => {
          if (loggedUser && loggedUser.user) {
            this.loggedUser = loggedUser && loggedUser.user;
            this.canAccessCampaigns = loggedUser.auths.indexOf("CAMPAIGN_MANAGER_HELP_DESK_CAMPAIGN_ACCESS") >= 0
            this.canAccessStructures = loggedUser.auths.indexOf("CAMPAIGN_MANAGER_HELP_DESK_STRUCTURE_ACCESS") >= 0
            if (this._firstInit) {
              this._firstInit = false;
              if (this.canAccessCampaigns) {
                this.getCampaignsCount();
              }
              if (this.canAccessStructures) {
                this.getHealthFacilitiesCount();
              }
            }
          }
        });
  }

  ngOnDestroy() {
    this._unsubscribeSignal$.next();
    this._unsubscribeSignal$.unsubscribe();
  }

  /**
   * @description Mostra una modale con un messaggio di errore
   * @param modalId
   * @param error
   */
  dispatchModal(modalId: string, error: string) {
    const messageObj = getWarningModal(this.translate, modalId, error);
    this.appStore.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
  }

  getCampaignsCount() {

    this.isLoadingCount = true;

    this.rentService.countCampaignListForHelpDesk(this.searchText)
      .pipe(takeUntil(this._unsubscribeSignal$.asObservable()))
      .subscribe((result) => {
        if (!result || result.error) {
          this.dispatchModal('082', result.error || '');
          this.resetPagination();
        } else {
          this.campaignsCount = result.response;

          if (this.campaignsCount === 0) {
            this.resetPagination();
          } else {
            this.hasCampaignsFirstLoad = true;
            this.getCampaigns();
          }
        }

        this.isLoadingCount = false;
      }, (err) => {
        if (err && err.message) {
          this.dispatchModal('084', err.message);
        }
        this.resetPagination();
      });
  }

  getCampaigns(index?: number) {

    this.isLoading = true;

    const pageSelectedIndex = index ? index : 0;
    if (index === 0 || index) {
      this.scrollTo.element('helpdesk-home-anchor');
    }

    this.rentService.getCampaignListForHelpDesk((pageSelectedIndex * this.paginationLength), this.paginationLength, this.searchText)
      .pipe(takeUntil(this._unsubscribeSignal$.asObservable()))
      .subscribe((result) => {
        if (!result || result.error) {
          this.dispatchModal('082', result.error || '');
          this.resetPagination();
        } else {
          this.campaigns = result.response;
        }
        this.isLoading = false;
      }, (err) => {
        if (err && err.message) {
          this.dispatchModal('084', err.message);
        }
        this.resetPagination();
      });
  }

  resetPagination() {
    this.isLoadingCount = false;
    this.isLoading = false;

    this.campaignsCount = 0;
    this.campaigns = [];
  }

  onCampaignClick(campaign: GetCampaignListForHelpDeskResponse) {
    this.redirectService.goToHelpDeskCampaign(campaign.campaignId);
  }

  searchTextChange(searchText?: string) {
    this.searchText = searchText;
  }

  searchCampaign() {
    this.getCampaignsCount();
  }

  searchHealthFacility() {
    this.getHealthFacilitiesCount();
  }

  isLoadingStructuresData() {
    return this.isLoadingCountStructures || this.isLoadingStructures;
  }

  getHealthFacilitiesCount() {
    this.isLoadingCountStructures = true;
    this.isLoadingStructures = true;

    this.rentService.countHealthFacilitySupplierForHelpDesk(this.searchText)
      .pipe(takeUntil(this._unsubscribeSignal$.asObservable()))
      .subscribe((result) => {
        if (!result || result.error) {
          this.dispatchWarningModal('082', result.error || '');
          this.resetPaginationStructures();
        } else {
          this.healthFacilitiesCount = result.response;

          if (this.healthFacilitiesCount === 0) {
            this.resetPaginationStructures();
          } else {
            this.getHealthFacilities();
          }
        }
        this.isLoadingCountStructures = false;
      }, (err) => {
        if (err && err.message) {
          this.dispatchWarningModal('084', err.message);
        }
        this.resetPaginationStructures();
      });
  }



  getHealthFacilities(index?: number) {

    this.isLoadingStructures = true;

    const pageSelectedIndex = index ? index : 0;
    if (index === 0 || index) {
      this.scrollTo.element('admin-health-facilities-anchor');
    }

    this.rentService.listHealthFacilitySupplierForHelpDesk((pageSelectedIndex * this.paginationLength), this.paginationLength, this.searchText)
      .pipe(takeUntil(this._unsubscribeSignal$.asObservable()))
      .subscribe((result: SenecaResponse<any>) => {
        if (!result || result.error) {
          this.dispatchWarningModal('082', result.error || '');
          this.resetPaginationStructures();
        } else {
          this.healthFacilities = result.response;
          this.isLoadingStructures = false;
        }
      }, (err: any) => {
        if (err && err.message) {
          this.dispatchWarningModal('084', err.message);
        }
        this.resetPaginationStructures();
      });

  }

  resetPaginationStructures() {
    this.isLoadingCountStructures = false;
    this.isLoadingStructures = false;

    this.healthFacilitiesCount = 0;
    this.healthFacilities = [];
  }

  onHealthFacilityClick(venue: HealthFacilityInfo) {
    this.redirectService.goToHelpDeskHealthFacility(venue.supplierId);
  }


  dispatchWarningModal(modalId: string, error: string) {
    const messageObj = getWarningModal(this.translate, modalId, error);
    this.appStore.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
  }
}

import { Component, OnDestroy } from '@angular/core';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import * as fromApp from "../ngrx/app.reducers";
import * as moment from 'moment';

import { ProfileState } from '../core/profile/ngrx/profile.reducers';

@Component({
  selector: 'app-admin',
  templateUrl: './admin.component.html',
  styleUrls: ['./admin.component.scss']
})
export class AdminComponent implements OnDestroy {

  applicationLang: string = '';
  loggedUser?: ProfileState;

  private _unsubscribeSignal$: Subject<void> = new Subject();

  constructor(
    public translate: TranslateService,
    private appStore: Store<fromApp.AppState>) {

    // Recupero la lingua dell'appplicazione
    this.appStore.select(fromApp.getApplicationLang)
      .pipe(takeUntil(this._unsubscribeSignal$.asObservable()))
      .subscribe((applicationLang: any) => {
        if (applicationLang) {
          this.applicationLang = applicationLang;
          this.translate.use(applicationLang);
          moment.locale(applicationLang);
        }
      });

    this.appStore.select(fromApp.getLoggedUser)
      .pipe(takeUntil(this._unsubscribeSignal$.asObservable()))
      .subscribe(
        (loggedUser) => {
          if (loggedUser && loggedUser.user) {
            this.loggedUser = loggedUser && loggedUser.user;
          }
        });
  }

  ngOnDestroy(): void {
    this._unsubscribeSignal$.next();
    this._unsubscribeSignal$.unsubscribe();
  }
}

import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { InfoSlot, InfoSlotStatuses } from 'atfcore-commonclasses/bin/classes/campaignmanager';

@Component({
  selector: 'slot-component-tile',
  templateUrl: 'slot-component-tile.component.html',
  styleUrls: ['./slot-component-tile.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SlotComponentTileComponent implements OnInit {

  _slot?: InfoSlot;
  @Input() set slot(value: InfoSlot) {
    this._slot = value;
    if (this._slot) {
      this._startDate = new Date(this._slot.startDate);
      this._endDate = new Date(this._slot.endDate);
    }
  }

  @Input() selected: boolean = false;
  @Input() fromReservationDeletion?: boolean = false;

  _status: InfoSlotStatuses = 'APPROVATION_REQUEST';
  @Input() set status(value: string) {
    this._status = value as InfoSlotStatuses;
  }

  _startDate: Date = new Date();
  _endDate: Date = new Date();

  constructor() {
  }

  ngOnInit() {
  }
}

<main *ngIf="langs && langs.length" class="full-height">
  <div class="main-content full-height">
    <div class="signin-content" fxLayout="column" fxLayoutAlign="center center">

      <div *ngIf="!showLoader" class="signin-wrapper" fxLayout="column" [ngClass]="{'privacy': stepIndex === 2}">

        <form *ngIf="stepIndex === 0" class="qualification-form" fxLayout="column" [formGroup]="vatForm"
          (ngSubmit)="setVatCode()">

          <!-- Logo Welion -->
          <div fxFlexOffset="60px" fxLayout="row" fxLayoutAlign="center" (click)="goBack()">
            <svg-icon src="assets/img/icons/app-logo.svg" class="signin-icon cursor-pointer" [applyClass]="true">
            </svg-icon>
          </div>

          <!-- Accedi -->
          <h3 fxFlexOffset="16px" translate="signin.HAVE_ACCOUNT_LOGIN"></h3>

          <!-- Se hai già portato a termine .. -->
          <h5 fxFlexOffset="12px" translate="signin.HAVE_ACCOUNT_DESC"></h5>

          <!-- Effettua il login -->
          <div fxFlexOffset="20px" fxLayout="row" fxLayoutAlign="center">
            <welion-button width="348px" type="primary" size="large" label="signin.DO_LOGIN" (onClick)="goToLogin()">
            </welion-button>
          </div>

          <!-- Registrati -->
          <h3 fxFlexOffset="40px" translate="signin.SIGNIN_ACCOUNT"></h3>

          <!-- Se non hai ancora effettuato la  .. -->
          <h5 fxFlexOffset="12px" translate="signin.SIGNIN_ACCOUNT_DESC"></h5>

          <!-- Codice fiscale -->
          <input-container isWithoutInfoIcon [title]="'signin.VAT_CODE' | translate" fxFlexOffset="28px"
            [for]="'vat-code'" [id]="'vat-code'">
            <welion-input [inputValue]="vatCode" [placeholder]="'signin.VAT_CODE_PLACEHOLDER' | translate"
              [id]="'vat-code'" [ariaLabelledbyLabel]="'vat-code'"
              [required]="true"
            >
            </welion-input>
          </input-container>

          <!-- Utilizza questo codice -->
          <div fxFlexOffset="24px" fxFlexOffset.xs="40px" fxLayout="row" fxLayoutAlign="center">
            <welion-button width="348px" [disabled]="vatForm.invalid" type="third white" size="large"
              label="signin.USE_CODE" buttonType="submit"></welion-button>
          </div>

          <!-- Serve aiuto? Consulta le FAQ e il supporto -->
          <div fxFlexOffset="35px" fxLayout="row" class="full-width" fxLayoutAlign="center center">
            <div fxLayoutAlign="center center" fxLayout="column" class="faq-support-container" (click)="goToFAQ()"
              tabindex="0" clickKeyBoard (onEnterKeyboard)="goToFAQ()" role="button">
              <svg-icon src="assets/img/icons/faq-support.svg"></svg-icon>
              <p [innerHTML]="'generic.FAQ_SUPPORT' | translate"></p>
            </div>
          </div>

          <div fxFlexOffset="60px"></div>

        </form>

        <form *ngIf="stepIndex === 1" class="signin-form" fxLayout="column" [formGroup]="signinForm"
          (ngSubmit)="setUserInfo()">

          <!-- Logo Welion -->
          <div fxFlexOffset="60px" fxLayout="row" fxLayoutAlign="center">
            <svg-icon src="assets/img/icons/app-logo.svg" class="signin-icon cursor-pointer" [applyClass]="true"
              (click)="goBack()">
            </svg-icon>
          </div>

          <!-- Codice fiscale -->
          <input-container isWithoutInfoIcon [title]="'signin.VAT_CODE' | translate" class="noBorderBottom"  [for]="'vat-code'" [id]="'vat-code'">
            <welion-input [inputValue]="vatCode" [placeholder]="'signin.VAT_CODE_PLACEHOLDER' | translate" [disabled]="vatCode.disabled"
            [id]="'vat-code'"
            [ariaLabelledbyLabel]="'vat-code'">
            </welion-input>
          </input-container>

          <!-- Nome -->
          <input-container isObbligatory isWithoutInfoIcon [title]="'signin.NAME' | translate" class="noBorder"
            [for]="'name'" [id]="'name'">
            <welion-input [inputValue]="name" [placeholder]="'signin.NAME_PLACEHOLDER' | translate" [id]="'name'"
              [ariaLabelledbyLabel]="'name'" [ariaLabelledbyText]="'signin.NAME' | translate" [required]="true">
            </welion-input>
          </input-container>

          <!-- Cognome -->
          <input-container isObbligatory isWithoutInfoIcon [title]="'signin.SURNAME' | translate" class="noBorder"
            [for]="'surname'" [id]="'surname'">
            <welion-input [inputValue]="surname" [placeholder]="'signin.SURNAME_PLACEHOLDER' | translate"
              [id]="'surname'" [ariaLabelledbyLabel]="'surname'" [ariaLabelledbyText]="'signin.SURNAME' | translate"
              [required]="true">
            </welion-input>
          </input-container>

          <!-- Email -->
          <input-container isObbligatory isWithoutInfoIcon [title]="'signin.EMAIL' | translate"
            [instructions]="'signin.EMAIL_DESC' | translate" class="noBorder" [for]="'email'" [id]="'email'" [ariaLabelledbyId]="'email-suggested'">
            <welion-input [inputValue]="email" [placeholder]="'signin.EMAIL_PLACEHOLDER' | translate" [id]="'email'"
              [ariaLabelledbyLabel]="'email'" [ariaLabelledbyId]="'email-suggested'" [required]="true"
              [error]="email.invalid && !email.pristine"
              [errorText]="'errors.ERROR_ACCESSIBILITY' | translate">
            </welion-input>
          </input-container>

          <!-- Telefono -->
          <input-container isObbligatory isWithoutInfoIcon [title]="'signin.PHONE' | translate"
            [instructions]="'signin.PHONE_DESC' | translate" class="noBorder" [for]="'phone'" [id]="'phone'" [ariaLabelledbyId]="'phone-suggested'">
            <welion-input [inputValue]="phone" [placeholder]="'signin.PHONE_PLACEHOLDER' | translate" [id]="'phone'"
            [ariaLabelledbyLabel]="'phone'" [ariaLabelledbyId]="'phone-suggested'" [required]="true"
            [error]="phone.invalid && !phone.pristine"
            [errorText]="'errors.CHARACTERS_NOT_ALLOWED' | translate">
            </welion-input>
            <!-- <h6 fxFlexOffset="8px" *ngIf="phone.value && phone.hasError('pattern')" class="small"
              translate="errors.CHARACTERS_NOT_ALLOWED"></h6> -->
          </input-container>

          <!-- Indirizzo -->
          <input-container isObbligatory isWithoutInfoIcon [title]="'signin.ADDRESS' | translate"
            [instructions]="'signin.ADDRESS_DESC' | translate" class="noBorderTop" [for]="'address'" [id]="'address'"  [ariaLabelledbyId]="'address-suggested'">
            <welion-input [inputValue]="address" hasMapsAutocomplete (selectedPlace)="selectedPlace($event)"
              [placeholder]="'signin.ADDRESS_PLACEHOLDER' | translate" [id]="'address'"
              [ariaLabelledbyLabel]="'address'" [required]="true"
              [ariaLabelledbyId]="'address-suggested'">
            </welion-input>
          </input-container>

          <!-- Completa registrazione -->
          <div fxFlexOffset="24px" fxLayout="row" fxLayoutAlign="center">
            <welion-button width="284px" [disabled]="signinForm.invalid" type="primary" size="large"
              label="signin.CONTINUE" buttonType="submit"></welion-button>
          </div>

          <div fxFlexOffset="60px"></div>

        </form>

        <form *ngIf="stepIndex === 2" class="signin-form" fxLayout="column" [formGroup]="privacyForm"
          (ngSubmit)="confirmPrivacy()">

          <!-- Logo Welion -->
          <div fxFlexOffset="60px" fxLayout="row" fxLayoutAlign="center">
            <svg-icon src="assets/img/icons/app-logo.svg" class="signin-icon cursor-pointer" [applyClass]="true">
            </svg-icon>
          </div>

          <div class="signin-form-container" fxFlexOffset="24px">
            <span><strong>CON RIFERIMENTO AL TRATTAMENTO DEI DATI PERSONALI PER L’EROGAZIONE DEL
                SERVIZIO</strong></span>
            <p></p>
            <p class="margin-bottom14"></p>
            <span><strong>DICHIARO,</strong> di aver preso visione dell’</span>
            <span class="link" (click)="goToPrivacy()">Informativa sul trattamento dei dati personali</span>
            <span> e di essere consapevole che i dati personali potranno essere noti all’Impresa sopra indicata e
              comunicati a terzi qualora necessario per consentire l’erogazione dei Servizi richiesti o in nostro
              favore previsti;</span>
            <p></p>
            <p class="margin-bottom14"></p>
            <span>
              <strong>PRENDO ATTO,</strong> che per le finalità del trattamento come illustrate nell’informativa (i),
              (ii), (iii), (iv) e (v), il conferimento dei dati è obbligatorio e che gli stessi saranno trattati
              secondo quanto indicato per assolvere gli adempimenti correlati agli accordi in essere ed
            </span>
            <p></p>
            <p class="margin-bottom14"></p>
          </div>

          <!-- ESPRIMO il mio consenso al trattamento ... -->
          <welion-check (onCheck)="onCheckPrivacy($event)">
            <ng-template #customLabel>
              <p class="check-privacy">
                <span>
                  <strong>ESPRIMO</strong> il mio consenso al trattamento dei miei dati personali, appartenenti alle
                  categorie particolari, tra cui quelli relativi alla salute, per le finalità del trattamento
                  illustrate nell’informativa privacy sub (i), (ii), (iii), (iv) e (v), per quanto necessario
                  all’erogazione dei Servizi richiesti o in mio favore previsti.
                </span>
              </p>
            </ng-template>
          </welion-check>

          <!-- Completa registrazione -->
          <div fxFlexOffset="24px" fxLayout="row" fxLayoutAlign="center">
            <welion-button width="284px" [disabled]="privacyForm.invalid" type="primary" size="large"
              label="signin.CONTINUE" buttonType="submit">
            </welion-button>
          </div>

          <div fxFlexOffset="60px"></div>

        </form>

        <form *ngIf="stepIndex === 3" class="confirm-form" fxLayout="column" fxLayoutAlign="center"
          [formGroup]="signinPswForm" (ngSubmit)="confirmRegistration()">

          <!-- Logo Welion -->
          <div fxFlexOffset="60px" fxLayout="row" fxLayoutAlign="center">
            <svg-icon src="assets/img/icons/app-logo.svg" class="signin-icon cursor-pointer" [applyClass]="true"
              (click)="goBack()">
            </svg-icon>
          </div>

          <!-- Imposta una nuova password! -->
          <h3 fxFlexOffset="24px" translate="signin.NEW_PASSWORD"></h3>

          <!-- Password -->
          <input-container fxFlexOffset="48px" isWithoutInfoIcon [title]="'signin.PSW' | translate">
            <welion-input [inputValue]="password" type="password" [placeholder]="'signin.PSW_PLACEHOLDER' | translate">
            </welion-input>
            <div fxLayout="column">
              <h6 fxFlexOffset="8px" class="small grey description" translate="signin.PSW_DESC"></h6>
              <ng-container *ngIf="password.dirty">
                <!-- Password troppo corta -->
                <h6 *ngIf="password.hasError('minlength')" fxFlexOffset="8px" class="small red"
                  translate="signin.PSW_ERROR_LENGTH"></h6>

                <!-- Password troppo lunga -->
                <h6 *ngIf="password.hasError('maxlength')" fxFlexOffset="8px" class="small red"
                  translate="signin.PSW_ERROR_MAX_LENGTH"></h6>

                <ng-container *ngIf="!password.hasError('minlength')">
                  <!-- Carattere minuscolo mancante -->
                  <h6 *ngIf="password.hasError('lowerCaseMiss')" fxFlexOffset="8px" class="small red"
                    translate="signin.PSW_ERROR_LOW"></h6>
                  <!-- Carattere maiuscolo mancante -->
                  <h6 *ngIf="password.hasError('upperCaseMiss')" fxFlexOffset="8px" class="small red"
                    translate="signin.PSW_ERROR_UPP"></h6>
                  <!-- Numero mancante -->
                  <h6 *ngIf="password.hasError('numberMiss')" fxFlexOffset="8px" class="small red"
                    translate="signin.PSW_ERROR_NUM"></h6>
                  <ng-container *ngIf="password.hasError('specMiss')">
                    <!-- Carattere speciale mancante -->
                    <h6 fxFlexOffset="8px" class="small red" translate="signin.PSW_ERROR_SPEC"></h6>
                    <h6 fxFlexOffset="8px" class="small red" translate="signin.PSW_ERROR_DESC_SPECIAL"></h6>
                    <h6 fxFlexOffset="8px" class="small red" translate="signin.PSW_ERROR_DESC_SPECIAL_DETAILS"></h6>
                  </ng-container>
                </ng-container>
              </ng-container>
            </div>
          </input-container>

          <!-- Conferma password -->
          <input-container fxFlexOffset="12px" isWithoutInfoIcon [title]="'signin.PSW_CONFIRM' | translate">
            <welion-input [inputValue]="passwordConfirm" type="password"
              [placeholder]="'signin.PSW_PLACEHOLDER' | translate">
            </welion-input>
            <h6 class="small red" fxFlexOffset="8px"
              *ngIf="(password.valid || passwordConfirm.dirty) && passwordConfirm.hasError('passwordNotMatch')"
              translate="errors.PASSWORD_NOT_MATCH"></h6>
          </input-container>

          <div fxFlexOffset="48px" fxLayout="row" fxLayoutAlign="center">
            <welion-button width="348px" [disabled]="signinPswForm.invalid" type="primary" size="large"
              label="signin.COMPLETE_SIGN_IN" buttonType="submit"></welion-button>
          </div>

          <div fxFlexOffset="60px"></div>

        </form>

        <ng-container *ngIf="!showLoader && stepIndex != 0">
          <aside class="support-align-right">
            <div fxLayoutAlign="center center" fxLayout="column" class="faq-support-container" (click)="goToFAQ()"
              tabindex="0" role="button" clickKeyBoard (onEnterKeyboard)="goToFAQ()">
              <svg-icon src="assets/img/icons/faq-support.svg"></svg-icon>
              <p [innerHTML]="'generic.FAQ_SUPPORT' | translate"></p>
            </div>
          </aside>

          <div class="support-icon">
            <div fxLayoutAlign="center center" fxLayout="column" class="faq-support-container responsive"
              (click)="goToFAQ()">
              <svg-icon src="assets/img/icons/faq-support.svg"></svg-icon>
            </div>
          </div>
        </ng-container>

      </div>

      <ng-container *ngIf="showLoader">
        <div class="loader spinner-big"></div>
      </ng-container>
    </div>
  </div>
</main>

<!-- Nessuna lingua disponibile a sistema -->
<div *ngIf="!langs || !langs.length">
  <p translate="errors.NO_AVAILABLE_LANG"></p>
</div>

<!-- Modale di registrazione effettuata -->
<modal id="signinConfirmed" class="signin-confirm-modal" (onClose)="closeSigninConfirmedModal()"
  (onCancel)="closeSigninConfirmedModal()" [title]="'signin.CONFIRM_TITLE' | translate">

  <div fxLayout="column" fxLayoutAlign="start center" class="full-width" (clickOutside)="homeIfModalOpened()">

    <svg-icon fxFlexOffset="24px" src="./assets/img/icons/booking-completed.svg"></svg-icon>

    <!-- Ti abbiamo inviato una mail nella cesella .. -->
    <p fxFlexOffset="24px">
      <span translate="signin.CONFIRM_DESC1"></span>
      <span>
        <strong>
          {{ email.value || 'prova@a.com'}}
        </strong>
      </span>
      <span [innerHTML]="'signin.CONFIRM_DESC2' | translate">
      </span>
      <a class="modal-clickable" (click)='redirectService.goToPublicFAQPage()'>F.A.Q.</a>
      <span [innerHTML]="'signin.CONFIRM_DESC3' | translate">
      </span>
      <a class="modal-clickable" (click)='redirectService.goToCreateTicket()'>qui.</a>
    </p>

    <!-- Chiudi -->
    <div fxFlexOffset="40px" class="full-width" fxLayout="row" fxLayoutAlign="center center">
      <welion-button width="200px" label="generic.CLOSE" type="primary" size="large"
        (onClick)="closeSigninConfirmedModal()">
      </welion-button>
    </div>
  </div>

</modal>
import { createAction, props } from '@ngrx/store';
import { GlobalApplicationData } from 'src/app/shared/models/global-application-data.model';
import { ApplicationModalMessage } from './core.reducers';

export const SetCoreApplicationData = createAction(
    '[core] update user',
    props<{ payload: GlobalApplicationData }>()
);

export const SaveRedirectUrl = createAction(
    '[core] save redirect url',
    props<{ payload: string }>()
);
export const SetApplicationLang = createAction(
    '[core] set application lang',
    props<{ payload: string }>()
);
export const SetDefaultLang = createAction(
    '[core] set default lang',
    props<{ payload: string }>()
);
export const OpenMainMenuSidebar = createAction(
    '[core] open main menu sidebar'
);
export const CloseMainMenuSidebar = createAction(
    '[core] close main menu sidebar'
);
export const GetAvailableLangs = createAction(
    '[core] get available langs'
);
export const RemoveApplicationLang = createAction(
    '[core] remove application lang'
);
export const GetAvailableLangsFinished = createAction(
    '[core] get available langs finished'
);
export const StartRenewTokenPolling = createAction(
    '[core] start renew token polling',
    props<{ payload?: { redirectUrl?: string, forceRefreshUser?: boolean } }>()
);
export const RemoveCoreApplicationData = createAction(
    '[core] remove core application data'
);
export const RemoveRedirectUrl = createAction(
    '[core] remove redirect url'
);
export const ShowApplicationLoader = createAction(
    '[core] show application loader'
);
export const HideApplicationLoader = createAction(
    '[core] hide application loader'
);
export const SetApplicationModalMessage = createAction(
    '[core] set application modal message',
    props<{ payload: ApplicationModalMessage }>()
);
export const DeleteApplicationModalMessage = createAction(
    '[core] delete application modal message'
);
<div class="page-content-wrapper" fxLayout="column">

  <!-- Campagne -->
  <ng-container *ngIf="canAccessCampaigns">
    <h1 fxFlexOffset="40px" class="primary" translate="helpDesk.home.CAMPAIGNS"></h1>

    <div id="helpdesk-home-anchor" fxFlexOffset="20px" fxFlexOffset.xs="6px"></div>

    <div fxFlexOffset="20px" fxFlexOffset.xs="6px" class="campaigns-container" fxLayout="column">

      <!-- Primo count incluso ha dati e mostro il search -->
      <!-- Nome campagna -->
      <input-container *ngIf="hasCampaignsFirstLoad" [title]="'helpDesk.home.SEARCH_CAMPAIGN' | translate">
        <welion-input [bindValue]="searchText" (onChange)="searchTextChange($event)" (onIconClicked)="searchCampaign()"
          [placeholder]="'helpDesk.home.SEARCH_CAMPAIGN_PLACAHOLDER' | translate"
          [disabled]="isLoadingCount || isLoading" iconRight="assets/img/icons/icon-search.svg">
        </welion-input>
      </input-container>

      <div *ngIf="isLoadingCount" class="loader spinner-big"></div>

      <ng-container *ngIf="!isLoadingCount">
        <ng-container *ngIf="campaignsCount > 0 else noCampaigns">

          <!-- Table header -->
          <div fxFlexOffset="24px">
            <!-- Campagna -->
            <h5 class="bold" translate="helpDesk.home.table.CAMPAIGN" fxFlex="30"></h5>
            <!-- Strutture -->
            <h5 class="bold" translate="helpDesk.home.table.VENUES" fxFlex="14" fxFlexOffset="2"></h5>
            <!-- Aventi diritto totali -->
            <h5 class="bold" translate="helpDesk.home.table.TOTAL_AD" fxFlex="16"></h5>
            <!-- Aventi diritto lavorati -->
            <h5 class="bold" translate="helpDesk.home.table.TOTAL_AD_MANAGED" fxFlex="16"></h5>
            <!-- Stato -->
            <h5 class="bold" translate="helpDesk.home.table.STATE" fxFlex="22"></h5>
          </div>

          <!-- Loader -->
          <div *ngIf="isLoading" class="loader spinner-big"></div>

          <!-- Table rows -->
          <ng-container *ngIf="!isLoading">
            <div *ngFor="let campaign of campaigns; let first = first; let odd = odd" class="campaign-row"
              [ngClass]="{'odd': odd}" fxLayout="row" fxLayoutAlign="start center"
              [fxFlexOffset]="first ? '8px' : '0px'">
              <!-- Campagna -->
              <h6 class="black" fxFlex="30" fxFlexOffset="8px">{{ campaign.title }}</h6>
              <!-- Strutture -->
              <h6 class="black" fxFlex="14" fxFlexOffset="2">{{ campaign.venueCount }}</h6>
              <!-- Aventi diritto totali -->
              <h6 class="black" fxFlex="16">{{ campaign.totalAttendance }}</h6>
              <!-- Aventi diritto lavorati -->
              <h6 class="black" fxFlex="16">{{ campaign.workedAttendance }}</h6>
              <!-- Stato -->
              <h6 class="black" fxFlex="14">{{ ('generic.' + (campaign.isCampaignActive ? 'ACTIVE': 'INACTIVE')) |
                translate }}</h6>

              <!-- 2% of margin left -->
              <div fxFlex="4" fxFlexOffset="2" fxLayout="row" fxLayoutAlign="end">
                <svg-icon src="assets/img/icons/arrow-right-red.svg" class="cursor-pointer" [applyClass]="true"
                  (click)="onCampaignClick(campaign)">
                </svg-icon>
              </div>
            </div>
          </ng-container>

          <!-- Paginatore -->
          <welion-paginator fxFlexOffset="20px" [isLoading]="isLoading" [paginationLength]="paginationLength"
            [itemsCount]="campaignsCount" (onClickPage)="getCampaigns($event)"></welion-paginator>

        </ng-container>
      </ng-container>

    </div>
  </ng-container>

  <ng-container *ngIf="canAccessStructures">
    <h1 fxFlexOffset="40px" class="primary" translate="helpDesk.home.STRUCTURES"></h1>

    <div id="helpdesk-home-anchor" fxFlexOffset="20px" fxFlexOffset.xs="6px"></div>

    <div *ngIf="isLoadingStructuresData()" class="loader spinner-big"></div>

    <ng-container *ngIf="!isLoadingStructuresData()">
      <div fxFlexOffset="20px" fxFlexOffset.xs="6px" class="campaigns-container" fxLayout="column">

        <!-- Nome struttura -->
        <input-container [title]="'admin.healthFacilities.SEARCH_HEALTH_FACILITY' | translate">
          <welion-input [bindValue]="searchText" (onChange)="searchTextChange($event)"
            (onIconClicked)="searchHealthFacility()"
            [placeholder]="'admin.healthFacilities.SEARCH_HEALTH_FACILITY_PLACEHOLDER' | translate"
            [disabled]="isLoadingStructuresData()" iconRight="assets/img/icons/icon-search.svg">
          </welion-input>
        </input-container>

        <!-- Table header -->
        <div fxFlexOffset="20px">
          <!-- Nome -->
          <h5 class="bold" translate="admin.healthFacilities.table.NAME" fxFlex fxFlexOffset="16px"></h5>
          <!-- Indirizzo -->
          <h5 class="bold" translate="admin.healthFacilities.table.ADDRESS" fxFlex="35"></h5>
          <!-- Sedi -->
          <h5 class="bold" translate="admin.healthFacilities.table.HEALTH_FACILITIES" fxFlex="10"></h5>
          <!-- Referenti -->
          <h5 class="bold" translate="admin.healthFacilities.table.REFERENCES" fxFlex="15"></h5>
        </div>
        <!-- Table rows -->
        <div *ngFor="let healthFacility of healthFacilities; let first = first; let odd = odd" class="campaign-row"
          [ngClass]="{'odd': odd}" fxLayout="row" fxLayoutAlign="start center" [fxFlexOffset]="first ? '8px' : '0px'">
          <!-- Nome -->
          <h6 class="black" fxFlex fxFlexOffset="16px">
            <ng-container *ngIf="healthFacility.code">
              {{ '[' + healthFacility.code + '] ' + healthFacility.name }}
            </ng-container>
            <ng-container *ngIf="!healthFacility.code">
              {{ healthFacility.name }}
            </ng-container>
          </h6>
          <!-- Indirizzo -->
          <h6 class="black" fxFlex="35">{{ healthFacility.address }}</h6>
          <!-- Sedi -->
          <h6 class="black" fxFlex="10">{{ healthFacility.venueCount }}</h6>
          <!-- Referenti -->
          <h6 class="black" fxFlex="10">{{ healthFacility.referentCount }}</h6>

          <!-- 2% of margin left -->
          <div fxFlex="5" fxLayout="row" fxLayoutAlign="end">
            <svg-icon src="assets/img/icons/arrow-right-red.svg" class="cursor-pointer" [applyClass]="true"
              (click)="onHealthFacilityClick(healthFacility)">
            </svg-icon>
            <div fxFlex="16px"></div>
          </div>
        </div>

        <welion-paginator fxFlexOffset="20px" [isLoading]="isLoadingStructuresData()"
          [paginationLength]="structurePaginationLength" [itemsCount]="healthFacilitiesCount"
          (onClickPage)="getHealthFacilities($event)"></welion-paginator>
      </div>
    </ng-container>

  </ng-container>
</div>

<!-- Placeholder nessuna campagna disponibile -->
<ng-template #noCampaigns>
  <div fxLayout="column" fxLayoutAlign="start center">
    <svg-icon [fxFlexOffset]="hasCampaignsFirstLoad ? '40px' : '20px'" [applyClass]="true"
      src="assets/img/icons/note-health-facility-red.svg"></svg-icon>

    <!-- NON CI SONO CAMPAGNE DISPONIBILI -->
    <h3 fxFlexOffset="32px" translate="helpDesk.home.NO_CAMPAIGNS"></h3>
  </div>
</ng-template>
import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { takeUntil } from 'rxjs/operators';
import { Subject, Subscription } from 'rxjs';

import { User } from 'atfcore-commonclasses/bin/classes/anag';
import { InfoCampaign } from 'atfcore-commonclasses/bin/classes/campaignmanager';

import * as CoreActions from "../../core/ngrx/core.actions";
import * as fromApp from "../../ngrx/app.reducers";

import { RedirectService } from 'src/app/shared/services/redirect.service';
import { RentService } from 'src/app/structure/services/rent.service';
import { getWarningModal } from 'src/app/utils/utils';
import { ScrollTo } from 'src/app/core/services/scroll-to.service';
import { AuthService } from 'src/app/auth/services/auth.service';
import { SidebarService } from 'src/app/shared/services/sidebar.service';
import { DropdownButtonItem } from 'src/app/shared/components/welion-select-button/welion-select-button.component';
import { ModalService } from 'src/app/shared/components/modal/modal.service';
import { SenecaResponse } from 'atfcore-commonclasses';
import * as moment from 'moment';

@Component({
  selector: 'app-help-desk-campaign',
  templateUrl: './helpDeskCampaign.component.html',
  styleUrls: ['./helpDeskCampaign.component.scss']
})
export class HelpDeskCampaignComponent implements OnInit, OnDestroy {
  loggedUser?: User;

  isLoadingBase: boolean = true;
  isLoadingCount: boolean = true;
  isLoading: boolean = true;
  hasEntitledsFirstLoad: boolean = false;

  searchEntitledText?: string;
  searchEntitledCFText?: string;
  searchEntitledCompanyText?: string;

  private _unsubscribeSignal$: Subject<void> = new Subject();

  readonly paginationLength: number = 50;

  campaignId: string = '';
  campaign?: InfoCampaign;

  // Entitled
  allCheckedEntitleds: boolean = false;
  entitleds: any[] = [];
  entitledsCount: number = 0;

  selectedEntitled?: any;

  dropdownSelectEntitled: DropdownButtonItem[] = [];
  attendanceToDelete?: any;

  private _firstInit: boolean = true;

  isEditNameView: boolean = false;
  isEditSurnameView: boolean = false;
  isEditPNView: boolean = false;
  isEditCFView: boolean = false;
  nameUser: string = '';
  surnameUser: string = '';
  fiscalCodeUser: string = '';
  phoneNumberUser: string = '';
  // per gestione pianifica
  isFromScheduleEntitled: boolean = false;
  currentSelect: any;
  selectedUserForBooking: any;
  isLoadingRounds: boolean = false;
  checkRounds: Subscription = new Subscription;
  selectedRoundForBooking: any | null = null;
  roundsForModal: any[] = [];

  constructor(
    private route: ActivatedRoute,
    private appStore: Store<fromApp.AppState>,
    private translate: TranslateService,
    private scrollTo: ScrollTo,
    private rentService: RentService,
    private authService: AuthService,
    private redirectService: RedirectService,
    private sidebarService: SidebarService,
    private modalService: ModalService,
    private router: Router
  ) {

  }

  ngOnInit() {
    this.scrollTo.header();

    this.campaignId = this.route.snapshot.paramMap.get('campaignId') || '';

    if (this.campaignId) {
      this.appStore.select(fromApp.getLoggedUser)
        .pipe(takeUntil(this._unsubscribeSignal$.asObservable()))
        .subscribe(
          (loggedUser) => {
            if (loggedUser && loggedUser.user) {
              this.loggedUser = loggedUser && loggedUser.user;

              if (this._firstInit) {
                this._firstInit = false;
                this.getCampaignData();
              }
            }
          });

    } else {
      this.redirectService.goToHome();
    }
  }

  ngOnDestroy() {
    this._unsubscribeSignal$.next();
    this._unsubscribeSignal$.unsubscribe();
  }

  isEntitledDisabledSchedule(entitled: any): boolean {
    return entitled && !entitled.canSchedule
  }


  openMaxReservationDateModal(isScheduledEntitled?: boolean, currentSelect?: any) {
    this.isFromScheduleEntitled = isScheduledEntitled ? true : false;
    this.currentSelect = currentSelect;
    this.modalService.open('maxReservationModal');
  }

  checkIfScheduleValid(currentSelect: any) {
    if (this.entitleds && this.entitleds.length) {
      let findUserEntries: any[] = [];
      for (let i = 0; i < this.entitleds.length; i++) {
        if (this.entitleds[i].user.userId == currentSelect.user.userId) {
          findUserEntries.push(this.entitleds[i]);
        }
      }
      if (findUserEntries && findUserEntries.length) {
        for (let i = 0; i < findUserEntries.length; i++) {
          if (findUserEntries[i].slot && findUserEntries[i].slot.slotId) {
            return true;
          }
        }
      }
    }
    return currentSelect && currentSelect.status != 'APPROVED'
  }

  setDropdownSelectEntitledActions() {
    this.dropdownSelectEntitled = [
      {
        label: 'admin.campaignRoundDetails.tab.attendances.table.SCHEDULE',
        onClick: (currentSelect: any) => {
          this.checkRoundForBooking(currentSelect);
        },
        disabledFct: (currentSelect: any) => {
          return currentSelect && !currentSelect.canSchedule;
        },
        icon: 'assets/img/icons/icon-contact.svg',
        strokeIcon: true
      },
      {
        label: 'helpDesk.campaign.table.RESCHEDULE',
        onClick: (currentSelect: any) => {
          this.onEntitledReschedule(currentSelect);
        },
        disabledFct: (currentSelect: any) => {
          return currentSelect && !currentSelect.canReschedule;
        },
        icon: 'assets/img/icons/icon-contact.svg',
        strokeIcon: true
      }, {
        label: 'helpDesk.campaign.table.REMOVE',
        onClick: (currentSelect: any) => {
          this.removeTicket(currentSelect);
        },
        disabledFct: (currentSelect: any) => {
          return currentSelect && !currentSelect.canCancel;
        },
        icon: 'assets/img/icons/icon-bin.svg',
        strokeIcon: true
      }, {
        label: 'helpDesk.campaign.table.IMPER',
        onClick: (currentSelect: any) => {
          this.onImpersonateEntitled(currentSelect);
        },
        icon: 'assets/img/icons/icon-users.svg',
        strokeIcon: true
      }, {
        label: 'helpDesk.campaign.table.REGISTER_AD',
        onClick: (currentSelect: any) => {
          this.redirectService.goToHelpDeskRegisterAD(currentSelect.user.userId);
        },
        disabledFct: (currentSelect: any) => {
          return currentSelect.user && currentSelect.user.registrationCompleted;
        },
        icon: 'assets/img/icons/icon-users.svg',
        strokeIcon: true
      }, {
        label: 'helpDesk.campaign.table.FORM',
        onClick: (currentSelect: any) => {
          this.openEntitledCard(currentSelect);
        },
        icon: 'assets/img/icons/calendar.svg',
        strokeIcon: true
      }
    ];
  }

  /**
   * @description Mostra una modale con un messaggio di errore
   * @param modalId
   * @param error
   */
  dispatchWarningModal(modalId: string, error: string) {
    const messageObj = getWarningModal(this.translate, modalId, error);
    this.appStore.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
  }

  getCampaignData() {
    this.rentService.getCampaign(this.campaignId)
      .pipe(takeUntil(this._unsubscribeSignal$.asObservable()))
      .subscribe((result) => {
        if (!result || result.error) {
          this.dispatchWarningModal('080', result.error || '');
          this.resetPagination();
        } else {
          this.campaign = result.response;
          this.countEntitledByCampaign();
        }
        this.isLoadingBase = false;
      }, (err) => {
        if (err && err.message) {
          this.dispatchWarningModal('081', err.message);
        }
        this.resetPagination();
      });
  }

  //#region Entitleds

  countEntitledByCampaign() {

    this.isLoadingCount = true;
    this.isLoading = true;

    this.rentService.countEntitledByCampaignForHelpDesk(this.campaignId, this.searchEntitledText, this.searchEntitledCFText, this.searchEntitledCompanyText)
      .pipe(takeUntil(this._unsubscribeSignal$.asObservable()))
      .subscribe((result) => {
        if (!result || result.error) {
          this.dispatchWarningModal('082', result.error || '');
          this.resetPagination();
        } else {
          this.entitledsCount = result.response;

          if (this.entitledsCount > 0) {
            this.hasEntitledsFirstLoad = true;
            this.getEntitledByCampaign(0);
          } else {
            this.resetPagination();
          }
        }

        this.isLoadingCount = false;
      }, (err) => {
        if (err && err.message) {
          this.dispatchWarningModal('084', err.message);
        }
        this.resetPagination();
      });
  }

  getEntitledByCampaign(index?: number) {

    this.isLoading = true;

    const pageSelectedIndex = index ? index : 0;
    if (index === 0 || index) {
      this.scrollTo.element('helpdesk-campaign-details-anchor');
    }

    this.rentService.getEntitledByCampaignForHelpDesk(this.campaignId, (pageSelectedIndex * this.paginationLength), this.paginationLength, this.searchEntitledText, this.searchEntitledCFText, this.searchEntitledCompanyText)
      .pipe(takeUntil(this._unsubscribeSignal$.asObservable()))
      .subscribe((result) => {

        if (!result || result.error) {
          this.dispatchWarningModal('082', result.error || '');
          this.resetPagination();
        } else {
          this.entitleds = result.response;
          this.setDropdownSelectEntitledActions();
          this.isLoading = false;
        }
      }, (err) => {
        if (err && err.message) {
          this.dispatchWarningModal('084', err.message);
        }
        this.resetPagination();
      });
  }

  /**
   * On click on the AD - go for impersonation
   * @param entitled
   */
  onImpersonateEntitled(entitled: any) {
    this.authService.impersonateUser(entitled.user.userId)
      .pipe(takeUntil(this._unsubscribeSignal$.asObservable()))
      .subscribe((result) => {
        if (!result || result.error) {
          this.dispatchWarningModal('082', result.error || '');
        } else {
          this.redirectService.impersonationLogin(result.response);
        }
      }, (err) => {
        if (err && err.message) {
          this.dispatchWarningModal('084', err.message);
        }
      });
  }

  searchEntitledsTextChange(searchText?: string) {
    this.searchEntitledText = searchText;
  }

  searchEntitledsCFTextChange(searchText?: string) {
    this.searchEntitledCFText = searchText;
  }

  searchEntitledsCompanyTextChange(searchText?: string) {
    this.searchEntitledCompanyText = searchText;
  }

  searchEntitleds() {
    this.countEntitledByCampaign();
  }

  //#endregion Entitleds

  resetPagination() {
    this.isLoadingBase = false;
    this.isLoadingCount = false;
    this.isLoading = false;

    this.entitleds = [];
    this.entitledsCount = 0;
    this.setDropdownSelectEntitledActions();
  }

  openEntitledCard(entitled: any) {
    this.selectedEntitled = entitled;

    this.nameUser = '';
    this.surnameUser = '';
    this.phoneNumberUser = '';
    this.fiscalCodeUser = '';

    if (this.selectedEntitled?.user.forename) {
      this.nameUser = this.selectedEntitled.user.forename;
    }
    if (this.selectedEntitled?.user.surname) {
      this.surnameUser = this.selectedEntitled.user.surname;
    }
    if (this.selectedEntitled?.user.phoneNumber) {
      this.phoneNumberUser = this.selectedEntitled.user.phoneNumber;
    }
    if (this.selectedEntitled?.user.codFisc) {
      this.fiscalCodeUser = this.selectedEntitled.user.codFisc;
    }
    this.isEditSurnameView = false;
    this.isEditNameView = false;
    this.isEditPNView = false;
    this.isEditCFView = false;
    this.sidebarService.setShowNav(true);
  }

  onEntitledReschedule(entitled: any) {
    if (!this.isEntitledDisabled(entitled)) {
      this.redirectService.goToHelpDeskCampaignRescheduleEntitled(this.campaignId, entitled.user.userId);
    }
  }

  isEntitledDisabled(entitled: any): boolean {
    return entitled && (!entitled.canReschedule || !entitled.canCancel);
  }

  closeEntitledCard() {
    this.sidebarService.setShowNav(false);
  }

  removeTicket(ticket: any) {
    this.attendanceToDelete = ticket;
    this.modalService.open('deleteAdminBooking');
  }

  closeDeleteBookingModal(confirm?: boolean) {
    this.modalService.close('deleteAdminBooking');

    if (confirm && this.attendanceToDelete && this.attendanceToDelete.attendanceId) {
      this.isLoading = true;

      this.rentService.deleteReservationForAdmin(this.attendanceToDelete.attendanceId)
        .pipe(takeUntil(this._unsubscribeSignal$.asObservable()))
        .subscribe((result) => {
          this.attendanceToDelete = undefined;
          if (!result || result.error) {
            this.dispatchWarningModal('045', result.error || '');
            this.isLoading = false;
          } else {
            this.getEntitledByCampaign();
          }
        }, (err) => {
          if (err && err.message) {
            this.dispatchWarningModal('046', err.message);
          }
          this.attendanceToDelete = undefined;
          this.isLoading = false;
        });

    } else {
      this.attendanceToDelete = undefined;
    }
  }

  editName(clearValue?: boolean) {
    this.isEditNameView = !this.isEditNameView;
    if (clearValue && this.selectedEntitled) {
      this.nameUser = this.selectedEntitled?.user.forename;
    }
  }

  editSurname(clearValue?: boolean) {
    this.isEditSurnameView = !this.isEditSurnameView;
    if (clearValue && this.selectedEntitled) {
      this.surnameUser = this.selectedEntitled?.user.surname;
    }
  }

  editPN(clearValue?: boolean) {
    this.isEditPNView = !this.isEditPNView;
    if (clearValue && this.selectedEntitled) {
      this.phoneNumberUser = this.selectedEntitled?.user.phoneNumber;
    }
  }

  editCF(clearValue?: boolean) {
    this.isEditCFView = !this.isEditCFView;
    if (clearValue && this.selectedEntitled) {
      this.fiscalCodeUser = this.selectedEntitled?.user.codFisc;
    }
  }

  userNameChanged(text: string) {
    this.nameUser = text;
  }

  userSurnameChanged(text: string) {
    this.surnameUser = text;
  }

  userFCChanged(text: string) {
    this.fiscalCodeUser = text;
  }

  userPNChanged(text: string) {
    this.phoneNumberUser = text;
  }

  saveNewDataAttendance(isNameValue?: boolean, isSurnameValue?: boolean, isFCValue?: boolean, isPNValue?: boolean) {

    let userId: string = '';
    if (this.selectedEntitled) {
      userId = this.selectedEntitled?.user.userId;
    }

    let forename: string = '';
    let surname: string = '';
    let fiscalCode: string = '';
    let phoneNumber: string = '';
    if (isNameValue) {
      forename = this.nameUser;
      // Chiudo il form di edit e aggiorno il dato
      this.isEditNameView = false;
      if (this.selectedEntitled) {
        this.selectedEntitled.user.forename = this.nameUser;
      }
    } else if (isSurnameValue) {
      surname = this.surnameUser;
      // Chiudo il form di edit e aggiorno il dato
      this.isEditSurnameView = false;
      if (this.selectedEntitled) {
        this.selectedEntitled.user.surname = this.surnameUser;
      }
    } else if (isFCValue) {
      fiscalCode = this.fiscalCodeUser;
      // Chiudo il form di edit e aggiorno il dato
      this.isEditCFView = false;
      if (this.selectedEntitled) {
        this.selectedEntitled.user.codFisc = this.fiscalCodeUser;
      }
    } else if (isPNValue) {
      phoneNumber = this.phoneNumberUser;
      // Chiudo il form di edit e aggiorno il dato
      this.isEditPNView = false;
      if (this.selectedEntitled) {
        this.selectedEntitled.user.phoneNumber = this.phoneNumberUser;
      }
    }

    this.rentService.updateAttendanceForHelpDesk(userId, forename, surname, fiscalCode, phoneNumber)
      .pipe(takeUntil(this._unsubscribeSignal$.asObservable()))
      .subscribe((result) => {
        if (!result || result.error) {
          this.dispatchWarningModal('082', result.error || '');
        } else {
          this.countEntitledByCampaign();
        }
      }, (err) => {
        if (err && err.message) {
          this.dispatchWarningModal('084', err.message);
        }
      });
  }

  checkRoundForBooking(currentSelect: any) {
    this.selectedUserForBooking = currentSelect;
    this.isLoadingRounds = true;
    this.modalService.open("chooseBookingRound");
    if (this.checkRounds) {
      this.checkRounds.unsubscribe();
    }
    this.checkRounds = this.rentService.getAttendancesForHelpDesk(this.campaignId, this.selectedUserForBooking.user.userId, true, true)
      .subscribe((data: SenecaResponse<any>) => {
        if (data && data.error) {
          this.modalService.close("chooseBookingRound");
          this.dispatchWarningModal('cr012-1', data.error);
        } else {
          if (data.response && data.response.length == 1) {
            this.selectedRoundForBooking = data.response[0];
            this.closeChooseBookingRound(true);
          } else if (data.response && data.response.length > 1) {
            this.roundsForModal = [];
            // altrimenti mostro i round
            data.response.forEach((round: any) => {
              if (round.userId == this.selectedUserForBooking.user.userId) {
                round.id = round.roundId;
                round.title = moment(round.roundStartDate).format("DD MMMM YYYY") + " - " + moment(round.roundEndDate).format("DD MMMM YYYY")
                round.user = currentSelect.user;

                let alreadyAdded = this.roundsForModal.find((tmp: any) => tmp.roundId == round.roundId);
                if (!alreadyAdded) {
                  this.roundsForModal.push(round);
                }
              }
            });

            if (this.roundsForModal && this.roundsForModal.length == 1) {
              this.selectedRoundForBooking = this.roundsForModal[0];
              this.closeChooseBookingRound(true);
            }
          }
        }
        this.isLoadingRounds = false;
      }, (err) => {
        this.dispatchWarningModal('cr012-2', err.message);
        this.isLoadingRounds = false;
      })
  }

  closeChooseBookingRound(confirm?: boolean, round?: any) {
    this.modalService.close("chooseBookingRound");
    if (confirm) {
      if (round) {
        this.selectedRoundForBooking = round;
      }
      this.router.navigate([`/helpDesk/campaign/${this.campaignId}/round/${this.selectedRoundForBooking?.roundId}/entitledsSchedule/${this.selectedUserForBooking.user.userId}/attendance/${this.selectedRoundForBooking?.attendanceId}`]);
    }
  }

  selectRoundForBooking(round: any) {
    this.selectedRoundForBooking = round;
  }
}

/*
 * Componente per l'errore 404, pagina richiesta non trovata
*/

import { Component } from '@angular/core';
import { RedirectService } from 'src/app/shared/services/redirect.service';

@Component({
  selector: 'app-page-not-found',
  templateUrl: './page-not-found.component.html',
  styleUrls: ['./page-not-found.component.scss']
})
export class PageNotFoundComponent {

  constructor(
    public redirectService: RedirectService
  ) { }

}

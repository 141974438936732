import { Store } from "@ngrx/store";
import { Subscription, combineLatest, Observable } from "rxjs";
import { Injectable } from "@angular/core";
import * as moment from 'moment';

import * as fromApp from '../../ngrx/app.reducers';
import { GlobalApplicationData } from "src/app/shared/models/global-application-data.model";

@Injectable()
export class ClientService {
  applicationData: any;
  result$: Subscription;
  loggedUser: any;

  constructor(private store: Store<fromApp.AppState>) {
    let globalApplicationData$: Observable<GlobalApplicationData> = this.store.select(fromApp.getGlobalApplicationData);
    const loggedUser$ = this.store.select(fromApp.getLoggedUser);
    const applicationLang$: Observable<string> = this.store.select(fromApp.getApplicationLang);

    const combinedSelectes$ = combineLatest([globalApplicationData$, loggedUser$, applicationLang$]);
    this.result$ = combinedSelectes$.subscribe(
      ([globalApplicationData, loggedUser, applicationLang]) => {
        this.loggedUser = loggedUser;
        this.applicationData = globalApplicationData;
        if (applicationLang) {
          moment.locale(applicationLang);
        }
      });
  }

  getCampaingListForCustumer(){

  }



}

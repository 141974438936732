import { Component, ElementRef, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

import { AttendanceStatuses, CampaignManagerMinorVaccinationType, DaySlots, InfoAttendance, InfoRound, InfoSlot, InfoVenue } from 'atfcore-commonclasses/bin/classes/campaignmanager';

import * as CoreActions from "../../core/ngrx/core.actions";
import * as fromApp from "../../ngrx/app.reducers";

import { RedirectService } from 'src/app/shared/services/redirect.service';
import { RentService } from 'src/app/structure/services/rent.service';
import { checkUnderCustomYearsOld, getAgeFromBirthDate, getAgeFromBornDate, getBirthDateFromCF, getModalMessageData, getWarningModal } from 'src/app/utils/utils';
import { InfoEntitled } from '../home/home.component';
import { ScrollTo } from 'src/app/core/services/scroll-to.service';
import { ModalService } from 'src/app/shared/components/modal/modal.service';
import { SidebarService } from 'src/app/shared/services/sidebar.service';
import { DropdownItem } from 'src/app/shared/models/dropdown.model';
import { ApplicationModalMessage } from 'src/app/core/ngrx/core.reducers';
import { showIf } from 'src/app/utils/animation.utils';

import * as moment from 'moment';
import { Title } from '@angular/platform-browser';

export interface InfoVenueCheck {
  venue: InfoVenue,
  disabled: boolean,
  vaccinationType?: CampaignManagerMinorVaccinationType
}

export interface InfoAttendanceCheck {
  attendance: InfoAttendance,
  disabled: boolean,
  vaccinationType?: CampaignManagerMinorVaccinationType
}

declare const google: any;

@Component({
  selector: 'app-user-add-reservation',
  templateUrl: './addReservation.component.html',
  styleUrls: ['./addReservation.component.scss'],
  animations: [showIf]
})
export class UserAddReservationComponent implements OnInit, OnDestroy {
  SERVICE_KEYS: string[] = ["HANDICAPPED_ACCESS", "ON_SITE_PARKING", "ON_STREET_PARKING", "CAR_PARKING", "PARKING_FOR_CYCLES_AND_MOTORCYCLES", "PAID_PARKING", "INFORMATION_AND_RECEPTION_SERVICE", "BAR"];
  VEHICLE_KEYS: string[] = ["CAR", "BICYCLES_AND_MOTORCYCLES", "BUS", "SUBWAY", "STREETCAR", "TRAIN", "CAB_STATION",]
  ALL_KEYS: string[] = ["HANDICAPPED_ACCESS", "ON_SITE_PARKING", "ON_STREET_PARKING", "CAR_PARKING", "PARKING_FOR_CYCLES_AND_MOTORCYCLES", "PAID_PARKING", "INFORMATION_AND_RECEPTION_SERVICE", "BAR",
    "CAR", "BICYCLES_AND_MOTORCYCLES", "BUS", "SUBWAY", "STREETCAR", "TRAIN", "CAB_STATION"]
  isLoadingConfirmReservation: boolean = false;

  round?: InfoRound & {
    entitled?: any[];
    rightMaxReservationDate: Date
  };
  entitled?: InfoEntitled;
  venueId: string = '';
  familiarUserId: string = '';
  roundStartDate?: Date;
  roundEndDate?: Date;

  attendance?: InfoAttendance;

  hasAnamnesisStep: boolean = false;
  isParent: boolean = false;
  recognizedFC: boolean = false;

  private roundId: string = '';
  private userId: string = '';

  steps: { label: string, id: string, panelId: string }[] = [];
  currentStep: number = 0;

  isLoadingStep: boolean = true;
  isLoadingSlots: boolean = true;

  daySlotsPaginated: { days: DaySlotsFe[] }[] = [];
  pageSelectedIndex: number = 0;
  daySlots: DaySlotsFe[] = [];
  daySelected?: DaySlotsFe;
  timeSlotsCount: number = 0;
  timeSlots: InfoSlot[] = [];
  timeSlotSelected?: InfoSlot;

  underNineYearsOld: boolean = false;
  entitledBirthDate?: Date;
  underEighteenBirthday?: Date;
  entitledAge?: number;
  hasDisabledVenues: boolean = false;
  alreadyFluVax: boolean = false;

  hasPastReservation: boolean = false;
  newReservation: boolean = false;

  selectedVenue?: any;
  selectedVenueTab: number = 0;

  venueMapData?: {
    lat: number,
    lng: number
  };

  readonly kmPreferences: DropdownItem[] = [
    {
      id: '10',
      name: '10'
    }, {
      id: '20',
      name: '20'
    }, {
      id: '30',
      name: '30'
    }, {
      id: '50',
      name: '50'
    }, {
      id: '100',
      name: '100'
    }
  ];

  venuesSearch: InfoVenueCheck[] = [];
  venueSearch?: InfoVenue;

  attendancesSearch: InfoAttendanceCheck[] = [];
  attendanceSearch?: InfoAttendance;

  searchForm: FormGroup = new FormGroup({
    'currentAddress': new FormControl(undefined, Validators.required),
    'latitude': new FormControl(undefined),
    'longitude': new FormControl(undefined),
    'kmPreference': new FormControl(this.kmPreferences[2], Validators.required),
    'fromDate': new FormControl(undefined, Validators.required),
    'toDate': new FormControl(undefined, Validators.required)
  });
  reservationErrorTest: any;
  selectedUsers: any;
  familySelectedSlots: any = {};
  attendanceFamily: any = {};
  loggedUser: any;
  minorsForFamily: any;
  userUnderNineYearsOld: any;
  editAttendanceIds: any[] = [];
  listForConfirmBooking: any[] = [];
  selectedUserIds: string[] = [];
  bookingDayFamily: any;
  bookingConfirmed: boolean = false;

  get currentAddress() {
    return this.searchForm.get('currentAddress') as FormControl;
  }

  get latitude() {
    return this.searchForm.get('latitude') as FormControl;
  }

  get longitude() {
    return this.searchForm.get('longitude') as FormControl;
  }

  get kmPreference() {
    return this.searchForm.get('kmPreference') as FormControl;
  }

  get fromDate() {
    return this.searchForm.get('fromDate') as FormControl;
  }

  get toDate() {
    return this.searchForm.get('toDate') as FormControl;
  }

  tooltipModalMessagePreferedAddress?: ApplicationModalMessage;
  tooltipModalMessageWhen?: ApplicationModalMessage;
  tooltipModalMessageAnam2?: ApplicationModalMessage;

  isEnel: boolean = false;

  reservationCode: string = '';

  private _unsubscribeSignal$: Subject<void> = new Subject();
  private _firstInit: boolean = true;

  editStructure: boolean = false;
  editDate: boolean = false;
  attendanceIdToEdit: any;
  hasAlreadyPreselectedStructure: boolean = false;
  hasAlreadyPreselectedDaySlot: boolean = false;
  hasToEditJustDate: boolean = false;

  // Luogo e orario della prenotazione che sto modificando
  alreadySelectedVenue: string = '';
  alreadySelectedDate: string = '';

  maxAllowedReservationMovingDate: number = 0;
  today = new Date();

  welionRadioButtonId!: string;

  constructor(
    private route: ActivatedRoute,
    private appStore: Store<fromApp.AppState>,
    private translate: TranslateService,
    private rentService: RentService,
    private scrollTo: ScrollTo,
    public modalService: ModalService,
    private sidebarService: SidebarService,
    public redirectService: RedirectService,
    private titleService: Title,
    private el: ElementRef
  ) { }

  ngOnInit() {

    this.translate.get(
      [
        "generic.TITLE_SITE"
      ]).subscribe(translations => {
        this.titleService.setTitle(translations["generic.TITLE_SITE"]);
      });

    this.scrollTo.header();

    this.roundId = this.route.snapshot.paramMap.get('roundId') || '';
    this.userId = this.route.snapshot.paramMap.get('userId') || '';
    this.editStructure = (this.route.snapshot.paramMap.get('editStructure') && this.route.snapshot.paramMap.get('editStructure') === "true") || false;
    this.editDate = (this.route.snapshot.paramMap.get('editDate') && this.route.snapshot.paramMap.get('editDate') === "true") || false;
    this.attendanceIdToEdit = this.route.snapshot.paramMap.get('attendanceId');

    // In questo caso l'utente può modificare solo la data 
    this.hasToEditJustDate = this.editDate && !this.editStructure;

    this.selectedUsers = JSON.parse(sessionStorage.getItem('familyMembers') || '') || []
    if (this.selectedUsers && this.selectedUsers.length) {
      if (this.selectedUsers[0].user) {
        this.selectedUserIds = this.selectedUsers.map((userData: any) => userData.user.userId);
      } else {
        this.selectedUserIds = this.selectedUsers.map((userData: any) => userData.userId);
      }
    }

    if (this.selectedUsers && this.selectedUsers.length) {
      if (this.selectedUsers[0].user) {
        this.userId = this.selectedUsers[0].user.userId;
      } else {
        this.userId = this.selectedUsers[0].userId;
      }
    }

    if (!this.roundId || (!this.userId && !this.selectedUsers)) {
      this.scrollTo.header();
      this.redirectService.goToHome();
    } else {
      this.translate.get('addReservation.steps')
        .pipe(takeUntil(this._unsubscribeSignal$.asObservable()))
        .subscribe(value => {
          this.tooltipModalMessagePreferedAddress = getModalMessageData(this.translate, 'generic.INFO', 'addReservation.steps.search.PREFERED_ADDRESS_INFO');
          this.tooltipModalMessageWhen = getModalMessageData(this.translate, 'generic.INFO', 'addReservation.steps.search.WHEN_INFO');
          this.tooltipModalMessageAnam2 = getModalMessageData(this.translate, 'generic.INFO', 'addReservation.steps.anamnesis.ANAM2_INFO');
        });

      // Si aspetta di caricare l'utente
      // In caso di accesso diretto tramite url le chiamate potrebbero avviarsi prima di avere il token
      this.appStore.select(fromApp.getLoggedUser)
        .pipe(takeUntil(this._unsubscribeSignal$.asObservable()))
        .subscribe(
          (loggedUser) => {
            this.loggedUser = loggedUser.user;
            if (loggedUser && loggedUser.user) {
              if (loggedUser.params && loggedUser.params.supplier && loggedUser.params.supplier.supplierName) {
                this.isEnel = (loggedUser.params.supplier.supplierName as string).toLowerCase().indexOf('enel') !== -1;
              }
              if (this._firstInit) {
                this._firstInit = false;
                this.getReservationEntitledData();
              }
            }
          });
    }
  }

  selectedPlace(place: any) {
    if (!place || place.geometry === undefined || place.geometry === null) {
      return;
    }
    if (place.address_components && place.address_components.length) {
      this.currentAddress.setValue(place.formatted_address);

      // Recupero latitudine e longitudine per diminuire le chiamate BE fatte a maps
      if (place.geometry.location) {
        this.latitude.setValue(place.geometry.location.lat());
        this.longitude.setValue(place.geometry.location.lng());
      }
    }
  }

  ngOnDestroy() {
    this._unsubscribeSignal$.next();
    this._unsubscribeSignal$.unsubscribe();
  }

  getReservationEntitledData() {
    this.rentService.getReservationEntitledData()
      .pipe(takeUntil(this._unsubscribeSignal$.asObservable()))
      .subscribe((result) => {
        if (!result || result.error) {
          this.dispatchModal('021', result.error || '');
        } else {

          this.attendancesSearch = [];


          result.response.roundData.forEach(round => {
            if (round.roundId === this.roundId) {
              this.round = round;

              this.maxAllowedReservationMovingDate = this.round.maxAllowedReservationMovingDate || 0;

              round.entitled.forEach((entitled, iEnt) => {
                if (entitled && (entitled.user.userId === this.userId || (this.selectedUserIds && this.selectedUserIds.length && this.selectedUserIds.includes(entitled.user.userId)))) {
                  this.entitled = entitled;

                  if (this.round) {
                    let roundMaxDate;
                    let venueMaxDate;
                    let finalMaxDate;

                    if (round.roundIdMaxVenueBookingEndDateWithOpenBookingIdsMap) {
                      roundMaxDate = new Date(this.round.endDate);
                      venueMaxDate = new Date(round.roundIdMaxVenueBookingEndDateWithOpenBookingIdsMap);

                      finalMaxDate = roundMaxDate > venueMaxDate ? roundMaxDate : venueMaxDate;
                    }

                    round.rightMaxReservationDate = finalMaxDate ? finalMaxDate : this.round.endDate;
                    this.roundEndDate = new Date(round.rightMaxReservationDate);
                    this.roundEndDate.setHours(23, 59, 59, 0);
                    this.toDate.setValue(new Date(this.roundEndDate));

                    const now = new Date();
                    const startDate = new Date(this.round.startDate);
                    if (now.getTime() > startDate.getTime()) {
                      now.setHours(0, 0, 0, 0);
                      this.fromDate.setValue(new Date());
                      this.roundStartDate = new Date(now);
                    } else {
                      startDate.setHours(0, 0, 0, 0);
                      this.fromDate.setValue(startDate);
                      this.roundStartDate = new Date(startDate);
                    }
                  }

                  let hasToEditStructureOrDate = null;
                  if ((this.editDate || this.editStructure) && this.selectedUsers && this.selectedUsers.length) {
                    // let attendanceIds = this.selectedUsers.map((user) => )
                    this.editAttendanceIds = [];
                    for (let i = 0; i < this.selectedUsers.length; i++) {
                      let user = this.selectedUsers[i] && this.selectedUsers[i].user ? this.selectedUsers[i].user : this.selectedUsers[i];
                      this.editAttendanceIds.push(user.attendanceId);
                    }
                    hasToEditStructureOrDate = !this.hasAlreadyPreselectedStructure && this.editAttendanceIds && this.editAttendanceIds.length > 0 && (this.editStructure || this.editDate) && round.entitled && round.entitled.length;
                  } else {
                    hasToEditStructureOrDate = !this.hasAlreadyPreselectedStructure && this.attendanceIdToEdit && (this.editStructure || this.editDate) && round.entitled && round.entitled.length;
                  }

                  if (hasToEditStructureOrDate) {
                    this.hasAlreadyPreselectedStructure = true;
                    // Se sono in edit della struttura o data selezionata, apro il primo step
                    this.currentStep = 1;

                    let alreadySelectedVenue;
                    if (this.selectedUsers && this.selectedUsers[0] && this.selectedUsers[0].venue) {
                      alreadySelectedVenue = this.selectedUsers[0].venue;
                      this.alreadySelectedVenue = alreadySelectedVenue && alreadySelectedVenue.name || '';
                    } else {
                      // Prevalorizzo la data e il luogo della prenotazione che modificando (attualmente serve per il box grigio riepilogativo a sinistra)
                      alreadySelectedVenue = this.getPreselectedVenue(round);
                      this.alreadySelectedVenue = alreadySelectedVenue && alreadySelectedVenue.name || '';
                    }

                    let preSelectedDate;
                    if (this.selectedUsers && this.selectedUsers[0] && this.selectedUsers[0].attendances && this.selectedUsers[0].attendances[0]) {
                      let attendance = this.selectedUsers[0].attendances[0];
                      preSelectedDate = { startDate: attendance.startDate, endDate: attendance.endDate }
                    } else {
                      preSelectedDate = this.getPreselectedDate(round);
                    }

                    if (preSelectedDate && preSelectedDate.startDate && preSelectedDate.endDate) {
                      // Giorno e mese
                      const day = moment(preSelectedDate.startDate).format('D');
                      const month = moment(preSelectedDate.startDate).format('MMMM');

                      // Orario di inizio e fine
                      const startDateTime = moment(preSelectedDate.startDate).format('HH:mm');
                      const endDateTime = moment(preSelectedDate.endDate).format('HH:mm');
                      if (this.selectedUsers && this.selectedUsers.length) {
                        this.alreadySelectedDate = day + ' ' + month;
                      } else {
                        this.alreadySelectedDate = day + ' ' + month + ' - ' + startDateTime + ' - ' + endDateTime;
                      }
                    }

                    /*for (let m = 0, entitlesLength = round.entitled.length; m < entitlesLength; m++) {
                      const currentEntitle = round.entitled[m];

                      if (currentEntitle && currentEntitle.user && currentEntitle.user.userId
                        && currentEntitle.user.userId === this.userId && currentEntitle.attendances && currentEntitle.attendances.length) {
                        for (let r = 0, attendancesLength = currentEntitle.attendances.length; r < attendancesLength; r++) {
                          const currentAttendance = currentEntitle.attendances[r];
                          if (currentAttendance.attendanceId && currentAttendance.attendanceId === this.attendanceIdToEdit && currentAttendance.venue) {
                            this.currentAddress.setValue(currentAttendance.venue.address);
                            this.latitude.setValue(currentAttendance.venue.latitude);
                            this.longitude.setValue(currentAttendance.venue.longitude);
                            this.venueSearch = currentAttendance.venue;
                            this.venueId = currentAttendance.venue.venueId;

                            this.venuesSearch = [];

                            this.venuesSearch.push({
                              venue: currentAttendance.venue,
                              disabled: false
                            });
                            break;
                          }
                        }

                        break;
                      }
                    }*/
                  }
                  if (this.selectedUsers && this.selectedUsers[0] && this.selectedUsers[0].venue) {
                    this.currentAddress.setValue(this.selectedUsers[0].venue.address);
                    this.latitude.setValue(this.selectedUsers[0].venue.latitude);
                    this.longitude.setValue(this.selectedUsers[0].venue.longitude);
                    this.venueSearch = this.selectedUsers[0].venue;
                    this.venueId = this.selectedUsers[0].venue.venueId;
                  } else {
                    if (this.entitled && this.entitled.preMatchedVenue) {
                      this.currentAddress.setValue(this.entitled.preMatchedVenue.address);
                      this.latitude.setValue(this.entitled.preMatchedVenue.latitude);
                      this.longitude.setValue(this.entitled.preMatchedVenue.longitude);
                      this.venueSearch = this.entitled.preMatchedVenue;
                      this.venueId = this.entitled.preMatchedVenue.venueId;
                    }
                  }

                  if (this.selectedUsers && this.selectedUsers.length) {
                    this.minorsForFamily = [];
                    this.isParent = false;
                    // Nel caso di prenotazione massiva cerco se ho dei figli minorenni
                    for (let i = 0; i < this.selectedUsers.length; i++) {
                      if (this.selectedUsers[i].parentCodFisc) {
                        this.isParent = true;
                        this.minorsForFamily.push(this.selectedUsers[i]);
                      }
                    }
                  } else {
                    this.isParent = (entitled.user as any).parentCodFisc ? true : false;
                  }

                  // Se non riconosce il CF mostro la domanda se ha meno di 9 anni..
                  this.recognizedFC = false;

                  if (this.isParent) {
                    // se prenotazione massiva controllo ogni figlio se ha meno di nove anni per l'anamnesi
                    if (this.selectedUsers && this.selectedUsers.length) {
                      for (let j = 0; j < this.minorsForFamily.length; j++) {
                        let entitledBirthDate = getBirthDateFromCF(this.minorsForFamily[j].codFisc);
                        if (entitledBirthDate) {
                          this.recognizedFC = true;
                          this.entitledAge = getAgeFromBirthDate(entitledBirthDate);

                          this.underNineYearsOld = checkUnderCustomYearsOld(entitledBirthDate, 9);
                          if (this.underNineYearsOld) {
                            this.userUnderNineYearsOld = this.minorsForFamily[j];
                          }
                          if (checkUnderCustomYearsOld(entitledBirthDate, 18)) {
                            this.underEighteenBirthday = new Date(entitledBirthDate);
                          } else {
                            this.underNineYearsOld = false;
                          }
                        }
                      }
                    } else {
                      this.entitledBirthDate = getBirthDateFromCF(entitled.user.codFisc);
                      if (this.entitledBirthDate) {

                        this.recognizedFC = true;
                        this.entitledAge = getAgeFromBirthDate(this.entitledBirthDate);

                        this.underNineYearsOld = checkUnderCustomYearsOld(this.entitledBirthDate, 9);
                        if (checkUnderCustomYearsOld(this.entitledBirthDate, 18)) {
                          this.underEighteenBirthday = new Date(this.entitledBirthDate);
                        } else {
                          this.underNineYearsOld = false;
                        }
                      }
                    }
                  } else {
                    this.recognizedFC = true;
                  }

                  if (this.isParent) {
                    if (this.selectedUsers && this.selectedUsers.length && this.userUnderNineYearsOld) {
                      this.familiarUserId = this.userUnderNineYearsOld.userId;
                      this.hasAnamnesisStep = !this.recognizedFC || this.underNineYearsOld;
                    } else {
                      this.familiarUserId = this.entitled.user.userId;
                      this.hasAnamnesisStep = !this.recognizedFC || this.underNineYearsOld;
                    }
                  }

                  this.isLoadingStep = false;

                  if (this.hasAnamnesisStep) {
                    if (!this.steps.find((el) => el.label == 'addReservation.steps.anamnesis.TITLE')) {
                      this.steps.push({ label: 'addReservation.steps.anamnesis.TITLE', id: "anamnesis", panelId: "panel-anamnesis" });
                    }
                  } else {
                    if (this.hasToEditJustDate) {
                      // Sono in edit solo della data, quindi prevalorizzo il nome del luogo scelto (per le info della sidenav di sinistra)
                      this.venueSearch = this.getPreselectedVenue(round);

                      this.currentStep = 3;
                      this.onDateStepEnter();
                    } else if (!hasToEditStructureOrDate) {
                      this.currentStep = 1;
                    }
                  }

                  if (!this.steps.find((el) => el.label == 'addReservation.steps.search.TITLE')) {
                    this.steps.push({ label: 'addReservation.steps.search.TITLE', id: "search", panelId: "panel-search" });
                  }
                  if (!this.steps.find((el) => el.label == 'addReservation.steps.structure.TITLE')) {
                    this.steps.push({ label: 'addReservation.steps.structure.TITLE', id: "structure", panelId: "panel-structure" });
                  }
                  if (!this.steps.find((el) => el.label == 'addReservation.steps.time.TITLE')) {
                    this.steps.push({ label: 'addReservation.steps.time.TITLE', id: "time", panelId: "panel-time" });
                  }
                } else if (!entitled) {
                  // In caso di entitled null lo rimuovo
                  round.entitled.splice(iEnt, 1);
                }
              });
            }
          });

          let preMatchedVenue: any;

          if (this.selectedUsers && this.selectedUsers[0] && this.selectedUsers[0].venue) {
            this.currentAddress.setValue(this.selectedUsers[0].venue.address);
            this.latitude.setValue(this.selectedUsers[0].venue.latitude);
            this.longitude.setValue(this.selectedUsers[0].venue.longitude);
            this.venueSearch = this.selectedUsers[0].venue;
            this.venueId = this.selectedUsers[0].venue.venueId;
            preMatchedVenue = this.selectedUsers[0].venue;
          } else {
            if (this.entitled && this.entitled.preMatchedVenue) {
              preMatchedVenue = this.entitled && this.entitled.preMatchedVenue;
            }
          }


          // Riproduco il ciclo per evitare problemi con i minori
          // underEighteenBirthday è impostato nel ciclo precedente
          if (!(this.editDate && this.editStructure && this.selectedUsers && this.selectedUsers.length)) {
            result.response.roundData.forEach(round => {
              if (round.roundId === this.roundId) {
                round.entitled.forEach((entitled) => {
                  if (entitled && entitled.user.userId !== this.userId && entitled.attendances && entitled.attendances.length) {
                    const attendance = entitled.attendances[0];
                    // Se è impostata la sede predefinita per l'utente inserisco solo quelle relative alla sede predefinita
                    if (!preMatchedVenue || (attendance.venue && preMatchedVenue.venueId === attendance.venue.venueId)) {
                      this.hasPastReservation = true;
                      this.pushAttendanceSearch(attendance);
                    }
                  }
                });
              }
            });
          }
          // Preselezione appuntamento passato con gestione minori
          if (this.hasPastReservation && this.attendancesSearch.length) {
            if (this.underEighteenBirthday) {
              for (let i = 0; i < this.attendancesSearch.length; i++) {
                if (!this.attendancesSearch[i].disabled) {
                  this.onChangeAttendance(this.attendancesSearch[i].attendance);
                  break;
                }
              }
            } else {
              this.onChangeAttendance(this.attendancesSearch[0].attendance);
            }
          }
        }
        this.isLoadingStep = false;
      }, (err) => {
        if (err && err.message) {
          this.dispatchModal('022', err.message);
        }
        this.isLoadingStep = false;
      });
  }

  // Ritorn il luogo già pre-selezionato
  getPreselectedVenue(round: any) {
    if (round && round.entitled && round.entitled.length) {
      for (let m = 0, entitlesLength = round.entitled.length; m < entitlesLength; m++) {
        const currentEntitle = round.entitled[m];
        if (currentEntitle && currentEntitle.user && currentEntitle.user.userId
          && currentEntitle.user.userId === this.userId && currentEntitle.attendances && currentEntitle.attendances.length) {
          for (let r = 0, attendancesLength = currentEntitle.attendances.length; r < attendancesLength; r++) {
            const currentAttendance = currentEntitle.attendances[r];
            if (currentAttendance.attendanceId && currentAttendance.attendanceId === this.attendanceIdToEdit && currentAttendance.venue) {
              return currentAttendance.venue;
            }
          }
        }
      }
    }
  }

  // Ritorn il luogo già pre-selezionato
  getPreselectedDate(round: any) {
    if (round && round.entitled && round.entitled.length) {
      for (let m = 0, entitlesLength = round.entitled.length; m < entitlesLength; m++) {
        const currentEntitle = round.entitled[m];
        if (currentEntitle && currentEntitle.user && currentEntitle.user.userId
          && currentEntitle.user.userId === this.userId && currentEntitle.attendances && currentEntitle.attendances.length) {
          for (let r = 0, attendancesLength = currentEntitle.attendances.length; r < attendancesLength; r++) {
            const currentAttendance = currentEntitle.attendances[r];
            if (currentAttendance.attendanceId && currentAttendance.attendanceId === this.attendanceIdToEdit) {
              return { startDate: currentAttendance.startDate, endDate: currentAttendance.endDate };
            }
          }
        }
      }
    }

    return;
  }

  pushAttendanceSearch(attendance: InfoAttendance) {

    if (this.underEighteenBirthday) {
      // Minore
      this.hasDisabledVenues = false;

      const venue = attendance.venue;
      // Di default tutte le sedi sono disabilitate per i minori
      // Se l'età del minore è presente nel range allora la si abilita
      if (venue && venue.validMinorTypes && venue.validMinorTypes.length > 0) {
        let disabled: boolean = true;
        let vaccinationType: CampaignManagerMinorVaccinationType | undefined = undefined;
        let underEighteenAge = getAgeFromBirthDate(this.underEighteenBirthday);
        venue.validMinorTypes.forEach(validMinorType => {
          if (validMinorType.age === this.entitledAge || validMinorType.age === underEighteenAge) {
            disabled = false;
            vaccinationType = validMinorType.vaccinationType;
          }
        });

        if (disabled) {
          this.hasDisabledVenues = true;
        }
        this.attendancesSearch.push({
          attendance: attendance,
          disabled: disabled,
          vaccinationType: vaccinationType
        });
      } else {
        this.attendancesSearch.push({
          attendance: attendance,
          disabled: true
        });
      }

    } else {
      // Maggiorenne
      this.attendancesSearch.push({
        attendance: attendance,
        disabled: false
      });
    }
  }

  dispatchModal(modalId: string, error: string) {
    const messageObj = getWarningModal(this.translate, modalId, error);
    this.appStore.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
  }

  goBack() {
    if (this.hasToEditJustDate || this.currentStep === 0 || (!this.hasAnamnesisStep && this.currentStep === 1)) {
      this.redirectService.goToHome();
    } else if (this.entitled?.preMatchedVenue || (this.hasPastReservation && !this.newReservation)) {
      // Struttura viene saltata se già impostata
      if (this.currentStep !== 3) {
        this.currentStep--;
      } else {
        this.currentStep = 1;
      }
    } else {
      this.currentStep--;
    }
  }

  goToFAQ() {
    if (this.roundId && this.userId) {
      this.redirectService.goToUserFAQPage(this.roundId, this.userId);
    } else {
      this.redirectService.goToUserFAQPage();
    }
  }

  changeReservationTab(index: number) {
    if (this.hasToEditJustDate && (index === 1 || index === 2)) {
      return;
    } else {
      if (this.entitled?.preMatchedVenue || (this.hasPastReservation && !this.newReservation)) {
        // Struttura viene saltata se già impostata
        if (index !== 2) {
          this.currentStep = index;
        }
      } else {
        this.currentStep = index;
      }
    }
  }

  changeReservationTabFromKeyboard(index: number) {
    if (this.isTabDisabled(index)) {
      return;
    }

    if (this.hasToEditJustDate && (index === 1 || index === 2)) {
      return;
    } else {
      if (this.entitled?.preMatchedVenue || (this.hasPastReservation && !this.newReservation)) {
        // Struttura viene saltata se già impostata
        if (index !== 2) {
          this.currentStep = index;
        }
      } else {
        this.currentStep = index;
      }
    }
  }

  goToNextStep() {
    this.isLoadingStep = true;
    switch (this.currentStep) {
      case 0:
        this.currentStep++;
        this.isLoadingStep = false;
        break;
      case 1:
        // Controllo sulle date inserite
        const fromDate: Date = this.fromDate.value;
        const toDate: Date = this.toDate.value;
        const nowDate = new Date();
        nowDate.setHours(0, 0, 0, 0);
        // Dal antecedente ad oggi
        if (fromDate.getTime() < nowDate.getTime()) {
          this.dispatchModal('038', 'DATE_ERROR_TODAY');
          this.isLoadingStep = false;
          break;
        }
        // Dal maggiore di Al
        if (fromDate.getTime() > toDate.getTime() || fromDate.getTime() === toDate.getTime()) {
          this.dispatchModal('030', 'DATE_ERROR');
          this.isLoadingStep = false;
          break;
        }

        if (this.entitled?.preMatchedVenue || (this.hasPastReservation && !this.newReservation)) {
          // Controllo minore con struttura in stato di consegna
          let venue: InfoVenue | undefined;

          if (this.entitled && this.entitled.preMatchedVenue) {
            venue = this.entitled.preMatchedVenue;
          } else {
            // Se da import di prenotazione passata
            venue = this.venueSearch;
          }

          if (this.hasToShowDeliveryUnderageModal(venue)) {
            this.showDeliveryUnderageModal();
          } else {
            this.currentStep = 3;
            this.getSlotDates();
          }
        } else {
          this.currentStep++;
          this.getVenueListForEntitledBooking();
        }
        break;
      case 2:
        this.onDateStepEnter(true);
        break;
      case 3:
        // Se l'utente è minorenne mostro l'avviso di Flucelvax Tetra
        if (this.entitledAge && this.entitledAge < 18) {
          this.modalService.open("minor-warning");
        } else { // altrimenti vado alla conferma della prenotazione
          this.confirmBookingService();
        }
        this.isLoadingStep = false;
        break;
    }
  }

  confirmBookingService() {
    if (this.selectedUsers && this.selectedUsers.length) {
      this.confirmBookingFamily();
    } else {
      this.confirmBooking();
    }
  }

  // Gestisce l'entrata dentro al terzo step (data e orario)
  onDateStepEnter(increaseStep?: boolean) {
    // Controllo minore con struttura in stato di consegna
    if (this.hasToShowDeliveryUnderageModal(this.venueSearch)) {
      this.showDeliveryUnderageModal();
    } else {
      if (increaseStep) {
        this.currentStep++;
      }

      this.getSlotDates();
    }
  }

  switchAnamnesis(value: boolean, position: number) {
    if (position === 0) {
      // IS_UNDER_NINE_YEARS_OLD
      this.underNineYearsOld = value;
    } else if (position === 1) {
      // HAS_ALREADY_HAD_FLU_VACCINE
      this.alreadyFluVax = value;
    }
  }

  onDateFromChange(date: Date) {
    date = new Date(date);
    date.setHours(0, 0, 0, 0);
    this.fromDate.setValue(date);
  }

  onDateToChange(date: Date) {
    if (date) {
      let toDate = new Date(date);
      toDate.setHours(23);
      toDate.setMinutes(59);
      this.toDate.setValue(toDate);
    } else {
      this.toDate.setValue(undefined);
    }
  }

  showVenueModal(venue?: InfoVenue) {
    this.selectedVenueTab = 0;

    if (venue) {
      this.selectedVenue = venue;
    } else if (this.entitled) {
      this.selectedVenue = this.entitled.preMatchedVenue;
    }

    if (this.selectedVenue && this.selectedVenue.latitude && this.selectedVenue.longitude) {
      this.venueMapData = {
        lat: parseFloat(this.selectedVenue.latitude),
        lng: parseFloat(this.selectedVenue.longitude)
      };
    }
    console.log('showVenueModal', venue);
    this.sidebarService.setShowNav(true);
    setTimeout(() => {
      this.welionRadioButtonId = "welion-radio-button-" + venue?.venueId;
      this.el.nativeElement.querySelector('#sidebar-close-button').focus();
    }, 100);
  }

  onVenueTabClick(index: number) {
    this.selectedVenueTab = index;
  }

  getSlotDates() {
    if (!this.venueId && this.round && this.round.entitled && this.round.entitled.length) {
      // Recupero il venueId scelto
      for (let m = 0, entitlesLength = this.round.entitled.length; m < entitlesLength; m++) {
        const currentEntitle = this.round.entitled[m];

        if (currentEntitle && currentEntitle.user && currentEntitle.user.userId
          && currentEntitle.user.userId === this.userId && currentEntitle.attendances && currentEntitle.attendances.length) {
          for (let r = 0, attendancesLength = currentEntitle.attendances.length; r < attendancesLength; r++) {
            const currentAttendance = currentEntitle.attendances[r];
            if (currentAttendance.attendanceId && currentAttendance.attendanceId === this.attendanceIdToEdit && currentAttendance.venueId) {
              this.venueId = currentAttendance.venueId;
              break;
            }
          }

          break;
        }
      }
    }

    const fromDate = (this.fromDate.value as Date).toISOString();
    const toDate = (this.toDate.value as Date).toISOString();

    this.rentService.searchAvailableDatesToGetVaccinatedForEntitled(
      this.venueId,
      this.roundId,
      this.familiarUserId,
      undefined,
      undefined,
      true,
      fromDate,
      toDate,
      undefined,
      this.editStructure && this.editDate
    )
      .pipe(takeUntil(this._unsubscribeSignal$.asObservable()))
      .subscribe((result) => {
        if (!result || result.error) {
          this.dispatchModal('031', result.error || '');
          this.isLoadingStep = false;
        } else {
          this.daySlots = result.response;
          let noSlots = true;
          if (this.daySlots && this.daySlots.length) {
            noSlots = false;
            this.daySlotsPaginated = [];

            this.daySlots.forEach((x: DaySlotsFe) => {
              if (this.maxAllowedReservationMovingDate && moment(this.today).isBetween(moment(this.round?.startDate), moment(this.round?.endDate))) {
                let dayToControl = new Date();
                dayToControl.setDate(this.today.getDate() + this.maxAllowedReservationMovingDate);
                if (moment(x.date).isBefore(moment(dayToControl))) {
                  x.isDisabled = true;
                }
              }
            })

            for (let i = 0; i < this.daySlots.length; i = i + 5) {
              this.daySlotsPaginated.push({
                days: this.daySlots.slice(i, i + 5)
              });
            }

            let preSelectedAttendanceDay;

            if (!this.hasAlreadyPreselectedDaySlot && this.editDate && this.attendanceIdToEdit) {
              // Pre-seleziono una data specifica se l'avevo già scelta e se la sto modificando
              preSelectedAttendanceDay = this.getAlreadyCreatedAttendance(this.attendanceIdToEdit);
            }

            let activeSlots = this.daySlots.filter((x: DaySlotsFe) => {
              return !x.isDisabled;
            })
            let slotToSelect = activeSlots && activeSlots[0];

            if (preSelectedAttendanceDay && preSelectedAttendanceDay.startDate) {
              for (let q = 0, daysLength = this.daySlots.length; q < daysLength; q++) {
                const currentSlot = this.daySlots[q];

                if (currentSlot.date) {
                  const haveSameYear = moment(preSelectedAttendanceDay.startDate).isSame(currentSlot.date, 'year');
                  const haveSameMonth = moment(preSelectedAttendanceDay.startDate).isSame(currentSlot.date, 'month');
                  const haveSameDay = moment(preSelectedAttendanceDay.startDate).isSame(currentSlot.date, 'day');

                  if (haveSameYear && haveSameMonth && haveSameDay) {
                    slotToSelect = currentSlot;
                    break;
                  }
                }
              }
            }

            if (slotToSelect) {
              this.onChangeDay(slotToSelect);
            } else {
              this.isLoadingStep = false;
            }
          }
          if (noSlots) {
            if (this.isEnel) {
              // this.dispatchModal('034', 'NO_BOOKING_ENEL');
              this.reservationErrorTest = this.translate.instant('errors.NO_BOOKING_ENEL')
            } else {
              // this.dispatchModal('034', 'NO_BOOKING');
              this.reservationErrorTest = this.translate.instant('errors.NO_BOOKING')
            }
            this.modalService.open("reservationError");
            if (!this.editStructure) {
              this.currentStep = 2;
            } else {
              this.currentStep = 1;
            }
          }
          this.isLoadingStep = false;
        }
      }, (err) => {
        if (err && err.message) {
          this.dispatchModal('032', err.message);
        }
        this.isLoadingStep = false;
      });
  }

  // Torna una attendance già creata
  getAlreadyCreatedAttendance(attendanceId: string) {
    let attendance;
    if (attendanceId && this.entitled && this.entitled.attendances && this.entitled.attendances.length) {
      for (let m = 0, attendancesLength = this.entitled.attendances.length; m < attendancesLength; m++) {
        const currentAttendance: any = this.entitled.attendances[m];

        if (currentAttendance.attendanceId && currentAttendance.attendanceId === attendanceId) {
          attendance = currentAttendance;
          break;
        }
      }
    }

    return attendance;
  }

  getVenueListForEntitledBooking() {
    if (this.entitled) {

      if (this.hasAlreadyPreselectedStructure) {
        this.latitude.setValue(undefined);
        this.longitude.setValue(undefined);
      }
      let serviceToCall;
      if (this.selectedUsers && this.selectedUsers.length) {
        let attendanceIds: string[] = [];
        if (this.editDate || this.editStructure) {
          attendanceIds = this.selectedUsers.map((userData: any) => userData.user.attendanceId);
        } else {
          attendanceIds = this.selectedUsers.map((user: any) => user.attendanceId);
        }
        serviceToCall = this.rentService.getVenueListForEntitledBooking(
          this.currentAddress.value,
          this.kmPreference.value.id,
          attendanceIds,
          undefined,
          undefined,
          undefined,
          this.fromDate.value,
          this.toDate.value,
          this.latitude.value,
          this.longitude.value,
          this.editStructure && this.editDate
        )
      } else {
        serviceToCall = this.rentService.getVenueListForEntitledBooking(
          this.currentAddress.value,
          this.kmPreference.value.id,
          (this.entitled.user as any).attendanceId,
          undefined,
          undefined,
          undefined,
          this.fromDate.value,
          this.toDate.value,
          this.latitude.value,
          this.longitude.value,
          this.editStructure && this.editDate
        )
      }

      serviceToCall
        .pipe(takeUntil(this._unsubscribeSignal$.asObservable()))
        .subscribe((result) => {
          if (!result || result.error) {
            this.dispatchModal('041', result.error || '');
          } else {
            this.venuesSearch = [];
            // Nel caso stia facendo un'operazione massiva controllo se un utente è minorenne;
            if (this.selectedUsers && this.selectedUsers.length) {
              let tmpUsers = this.selectedUsers && this.selectedUsers[0] && this.selectedUsers[0].user ? this.selectedUsers.map((userData: any) => userData.user) : this.selectedUsers;
              tmpUsers.forEach((user: any) => {
                let birthDate = getBirthDateFromCF(user.codFisc);
                if (birthDate) {
                  let age = getAgeFromBornDate(birthDate);
                  if (age < 18) {
                    this.underEighteenBirthday = birthDate;
                    this.entitledAge = age;
                  }
                }
              })
            }
            if (this.underEighteenBirthday) {
              // Minore
              this.hasDisabledVenues = false;

              result.response.forEach(venue => {
                // Di default tutte le sedi sono disabilitate per i minori
                // Se l'età del minore è presente nel range allora la si abilita
                if (venue.validMinorTypes && venue.validMinorTypes.length > 0) {
                  let disabled: boolean = true;
                  let vaccinationType: CampaignManagerMinorVaccinationType | undefined = undefined;
                  venue.validMinorTypes.forEach(validMinorType => {
                    if (validMinorType.age === this.entitledAge) {
                      disabled = false;
                      vaccinationType = validMinorType.vaccinationType;
                    }
                  });

                  if (disabled) {
                    this.hasDisabledVenues = true;
                  }

                  this.venuesSearch.push({
                    venue: venue,
                    disabled: disabled,
                    vaccinationType: vaccinationType
                  });
                } else {
                  if (!venue.validMinorTypes || !venue.validMinorTypes.length) {
                    this.hasDisabledVenues = true;
                  }
                  this.venuesSearch.push({
                    venue: venue,
                    disabled: true
                  });
                }

              });

              if (this.venuesSearch && this.venuesSearch.length > 0) {
                for (let i = 0; i < this.venuesSearch.length; i++) {
                  if (!this.venuesSearch[i].disabled) {
                    this.venueSearch = this.venuesSearch[i].venue;
                    this.venueId = this.venueSearch.venueId;
                    break;
                  }
                }
              }

            } else {
              // Maggiorenne
              result.response.forEach(venue => {
                this.venuesSearch.push({
                  venue: venue,
                  disabled: false
                });
              });

              if (this.venuesSearch && this.venuesSearch.length > 0) {
                this.venueSearch = this.venuesSearch[0].venue;
                this.venueId = this.venueSearch.venueId;
              }
            }

          }
          if (this.venuesSearch && !this.venuesSearch.length) {
            if (this.isEnel) {
              this.reservationErrorTest = this.translate.instant('errors.NO_BOOKING_ENEL')
            } else {
              this.reservationErrorTest = this.translate.instant('errors.NO_BOOKING')
            }
            this.modalService.open("reservationError");
          }

          this.isLoadingStep = false;
        }, (err) => {
          if (err && err.message) {
            this.dispatchModal('042', err.message);
          }
          this.isLoadingStep = false;
        });
    } else {
      this.isLoadingStep = false;
    }
  }

  onChangeVenue(venue: InfoVenue) {
    this.venueSearch = venue;
    this.venueId = venue.venueId;
  }

  goToLeft() {
    if (this.pageSelectedIndex > 0) {
      this.pageSelectedIndex--;
    }
  }

  goToRight() {
    if (this.pageSelectedIndex < (this.daySlotsPaginated.length - 1)) {
      this.pageSelectedIndex++;
    }
  }

  goToPage(index: number) {
    this.pageSelectedIndex = index;
  }

  onSelectedTime(time: InfoSlot) {
    if (this.timeSlotSelected && time.slotId === this.timeSlotSelected.slotId) {
      this.timeSlotSelected = undefined;
    } else {
      this.timeSlotSelected = time;
    }
  }

  onSelectedTimeForFamily(time: InfoSlot, userData: any) {
    let tmpUser = userData && userData.user ? userData.user : userData;
    if (this.familySelectedSlots[tmpUser.userId] && time.slotId === this.familySelectedSlots[tmpUser.userId].slotId) {
      this.timeSlotSelected = undefined;
      delete this.familySelectedSlots[tmpUser.userId]
    } else {
      this.familySelectedSlots[tmpUser.userId] = time;
    }
  }

  getTimeslotClass(timeSlot: any, userData: any) {
    let user = userData && userData.user ? userData.user : userData;
    for (let i = 0; i < this.selectedUsers.length; i++) {
      let tmpUser = this.selectedUsers[i] && this.selectedUsers[i].user ? this.selectedUsers[i].user : this.selectedUsers[i];
      if (this.familySelectedSlots[tmpUser.userId] && this.familySelectedSlots[tmpUser.userId].slotId == timeSlot.slotId) {
        if (tmpUser.userId == user.userId) {
          return "selected";
        } else {
          return "disabled";
        }
      }
    }
    return "";
  }

  getIsReserveActionDisabled() {
    if (this.selectedUsers && this.selectedUsers.length) {
      let keys = Object.keys(this.familySelectedSlots);
      if (keys.length == this.selectedUsers.length) {
        return false;
      } else {
        return true
      }
    } else {
      return !this.timeSlotSelected;
    }
  }

  onChangeKMPreference(value: DropdownItem) {
    this.kmPreference.setValue(value);
  }

  onChangeDay(day: DaySlotsFe) {
    this.daySelected = day;
    this.countSlotsOfSpecificDay(new Date(this.daySelected.date));
  }

  countSlotsOfSpecificDay(date: Date) {
    this.isLoadingSlots = true;

    // Filtro sull'orario, per non avere slot già passati
    const now = new Date();
    if (date.getTime() < now.getTime()) {
      date = new Date(now);
    }

    this.rentService.countAvailableDatesToGetVaccinatedForEntitled(
      this.venueId,
      this.roundId,
      this.familiarUserId,
      date.toISOString(),
      this.editStructure && this.editDate
    )
      .pipe(takeUntil(this._unsubscribeSignal$.asObservable()))
      .subscribe((result) => {
        if (!result || result.error) {
          this.dispatchModal('033', result.error || '');
          this.isLoadingStep = false;
          this.isLoadingSlots = false;
        } else {
          this.timeSlotsCount = result.response;
          if (this.timeSlotsCount > 0) {
            this.getSlotsOfSpecificDay(date);
          } else {
            this.isLoadingStep = false;
            this.isLoadingSlots = false;
          }
        }
      }, (err) => {
        if (err && err.message) {
          this.dispatchModal('034', err.message);
        }
        this.isLoadingStep = false;
        this.isLoadingSlots = false;
      });
  }

  getSlotsOfSpecificDay(date: Date) {
    this.rentService.searchAvailableDatesToGetVaccinatedForEntitled(
      this.venueId,
      this.roundId,
      this.familiarUserId,
      0,
      this.timeSlotsCount,
      false,
      undefined,
      undefined,
      date.toISOString(),
      this.editStructure && this.editDate
    )
      .pipe(takeUntil(this._unsubscribeSignal$.asObservable()))
      .subscribe((result) => {
        if (!result || result.error) {
          this.dispatchModal('033', result.error || '');
        } else {
          let noSlots = true;
          if (result.response && result.response.length) {
            this.timeSlots = result.response[0].slots;

            for (let i = 0; i < this.timeSlots.length; i++) {
              noSlots = false;
            }

            // Se sto modificando la data o la struttura (precedentemente selezionati), pre-seleziono l'orario

            let preSelectedAttendanceDay;

            if (!this.hasAlreadyPreselectedDaySlot && this.editDate && this.attendanceIdToEdit && this.timeSlots && this.timeSlots.length) {
              this.hasAlreadyPreselectedDaySlot = true;

              // Pre-seleziono una data specifica se l'avevo già scelta e se la sto modificando
              preSelectedAttendanceDay = this.getAlreadyCreatedAttendance(this.attendanceIdToEdit);

              let slotToSelect: any;

              if (preSelectedAttendanceDay && preSelectedAttendanceDay.startDate) {
                for (let q = 0, daysLength = this.timeSlots.length; q < daysLength; q++) {
                  const currentSlot = this.timeSlots[q];

                  if (currentSlot.startDate && currentSlot.endDate) {
                    const areStartDateSame = moment(preSelectedAttendanceDay.startDate).isSame(currentSlot.startDate);
                    const areEndDateSame = moment(preSelectedAttendanceDay.endDate).isSame(currentSlot.endDate);

                    if (areStartDateSame && areEndDateSame) {
                      slotToSelect = currentSlot;
                      break;
                    }
                  }
                }
              }

              if (slotToSelect) {
                this.onSelectedTime(slotToSelect);
              }
            }
          } else {
            this.timeSlots = [];
          }
          let notEnoughSlot = false;
          if (this.timeSlots && this.timeSlots.length && this.timeSlots.length < this.selectedUsers.length) {
            notEnoughSlot = true;
          }
          if (noSlots) {
            if (this.isEnel) {
              // this.dispatchModal('034', 'NO_BOOKING_ENEL');
              this.reservationErrorTest = this.translate.instant('errors.NO_BOOKING_ENEL')
            } else {
              // this.dispatchModal('034', 'NO_BOOKING');
              this.reservationErrorTest = this.translate.instant('errors.NO_BOOKING')
            }
            this.modalService.open("reservationError");
            this.currentStep = 1;
          } else if (notEnoughSlot) {
            this.modalService.open("not-enough-slots");
            if (!(this.pageSelectedIndex >= 0 && this.daySlotsPaginated[this.pageSelectedIndex] && this.daySlotsPaginated[this.pageSelectedIndex].days && this.daySlotsPaginated[this.pageSelectedIndex].days.length > 1)) {
              this.currentStep = this.steps.length - 1;
            }
          }
        }
        this.isLoadingStep = false;
        this.isLoadingSlots = false;
      }, (err) => {
        if (err && err.message) {
          this.dispatchModal('034', err.message);
        }
        this.isLoadingStep = false;
        this.isLoadingSlots = false;
      });
  }

  isTabDisabled(index?: any) {
    /* return (this.hasToEditJustDate && (index === 0 || index === 1))
       || ((index + (this.hasAnamnesisStep ? 0 : 1)) > this.currentStep);*/
    return (index + (this.hasAnamnesisStep ? 0 : 1)) > this.currentStep;
  }

  // Conferma la prenotazione
  confirmBooking(confirm?: boolean) {
    if (this.timeSlotSelected && this.entitled) {
      this.attendance = {
        venueId: this.venueId,
        roundId: this.roundId,
        slotId: this.timeSlotSelected.slotId,
        attendanceId: (this.entitled.user as any).attendanceId,
        startDate: new Date(this.timeSlotSelected.startDate),
        endDate: new Date(this.timeSlotSelected.endDate),
        name: this.entitled.user.forename,
        surname: this.entitled.user.surname,
        fiscalCode: this.entitled.user.parentCodFisc || this.entitled.user.codFisc,
        birthDate: this.entitledBirthDate,
        fiscalCodeRequired: false,
        status: AttendanceStatuses.RESERVED,
        phoneNumber: '',
        email: '',
        userId: '',
        description: '',
        anamnesis: []
      };

      if (this.entitled && this.entitled.preMatchedVenue) {
        this.entitled.venue = this.entitled.preMatchedVenue;
      } else if (this.entitled && this.hasPastReservation && !this.newReservation && this.attendanceSearch) {
        this.entitled.venue = this.attendanceSearch.venue;
      } else {
        this.entitled.venue = this.venueSearch;
      }

      this.modalService.open('confirmBooking');
    }

  }


  confirmBookingFamily() {
    if (this.familySelectedSlots) {
      let tmpUser = this.selectedUsers && this.selectedUsers[0] && this.selectedUsers[0].user ? this.selectedUsers[0].user : this.selectedUsers[0];

      this.attendanceFamily = {
        venueId: this.venueId,
        roundId: this.roundId,
        attendanceId: tmpUser.attendanceId,
        startDate: new Date(this.familySelectedSlots[tmpUser.userId].startDate),
        endDate: new Date(this.familySelectedSlots[tmpUser.userId].endDate),
        slots: this.familySelectedSlots,
      };
      // let venue = this.selectedUsers && this.selectedUsers[0] && this.selectedUsers[0].venue ? this.selectedUsers[0].venue : null;
      // if (tmpUser && venue) {
      //   this.entitled.venue = this.entitled.preMatchedVenue;
      // } else if (this.entitled && this.hasPastReservation && !this.newReservation && this.attendanceSearch) {
      //   this.entitled.venue = this.attendanceSearch.venue;
      // } else {
      //   this.entitled.venue = this.venueSearch;
      // }
      if (this.selectedUsers && this.selectedUsers[0] && this.selectedUsers[0].user) {
        this.listForConfirmBooking = this.selectedUsers.map((userData: any) => userData.user);
      } else {
        this.listForConfirmBooking = this.selectedUsers
      }
      this.bookingDayFamily = this.familySelectedSlots[this.listForConfirmBooking[0].userId].startDate;
      this.modalService.open('confirmBookingFamily');
    }
  }

  closeConfirmBookingModal(confirm?: boolean) {
    this.modalService.close('confirmBookingFamily');
    if (confirm) {

      this.isLoadingConfirmReservation = true;
      if (this.hasAnamnesisStep) {
        let anamnesis: { questionType: string, answer: boolean }[] = [
          {
            questionType: 'HAS_ALREADY_HAD_FLU_VACCINE',
            answer: this.alreadyFluVax
          }
        ];

        if (this.isParent) {
          anamnesis.push({
            questionType: 'IS_UNDER_NINE_YEARS_OLD',
            answer: this.underNineYearsOld
          });
        }

        let attendanceId: string = '';

        if (this.entitled && this.entitled.user) {
          attendanceId = (this.entitled.user as any).attendanceId;
        }
        // Imposto l'anamnesi
        this.rentService.setAttendanceAnamnesi(attendanceId, anamnesis)
          .subscribe((result) => {
            if (!result || result.error) {
              this.closeConfirmBookingModal();
              this.dispatchModal('035', result.error || '');
            } else {
              this.confirmReservationForEntitled();
            }
          }, (err) => {
            if (err && err.message) {
              this.closeConfirmBookingModal();
              this.dispatchModal('036', err.message);
            }
          });
      } else {
        this.confirmReservationForEntitled();
      }
    } else {
      this.isLoadingConfirmReservation = false;
      this.modalService.close('confirmBooking');
    }
  }


  confirmReservationForEntitled() {
    if (this.timeSlotSelected || (Object.keys(this.familySelectedSlots).length)) {
      let serviceToUse: any = null;
      // Confermo la prenotazione
      let timeSlotIds: any;
      let usersToReserve: Array<{
        userId: string;
        slotId: string;
      }> = [];

      if (this.selectedUsers && this.selectedUsers.length) {
        timeSlotIds = [];
        for (let i = 0; i < this.selectedUsers.length; i++) {
          let user = this.selectedUsers[i];
          if (this.familySelectedSlots[user.userId]) {
            timeSlotIds.push(this.familySelectedSlots[user.userId].slotId);
            usersToReserve.push({
              userId: user.userId,
              slotId: this.familySelectedSlots[user.userId].slotId
            })
          }
        }
      } else if (this.timeSlotSelected && this.entitled) {
        timeSlotIds = this.timeSlotSelected.slotId;
        usersToReserve.push({
          userId: this.entitled?.user?.userId || '',
          slotId: timeSlotIds
        })
      }
      serviceToUse = this.rentService.confirmReservationForEntitledFamily({ roundId: this.roundId, venueId: this.venueId, usersToReserve: usersToReserve })

      if (this.editAttendanceIds && this.editAttendanceIds.length && this.familySelectedSlots) {
        let promises = [];
        for (let j = 0; j < this.selectedUsers.length; j++) {
          let user = this.selectedUsers[j].user ? this.selectedUsers[j].user : this.selectedUsers[j];
          promises.push(this.rentService.moveReservationForEntitled(this.roundId, this.venueId, this.familySelectedSlots[user.userId].slotId, user.attendanceId, this.loggedUser.userId == user.userId ? '' : user.userId).toPromise());
        }

        Promise.all(promises).then((results: any) => {
          this.closeConfirmBookingModal();
          for (let i = 0; i < results.length; i++) {
            if (results[i].error) {
              this.dispatchModal('035', results[i].error || '');
              return;
            }
          }
          this.bookingConfirmed = true;
          this.modalService.open('bookingConfirmed');
        }).catch((err: any) => {
          this.closeConfirmBookingModal();
          this.dispatchModal('035', err.error || err || '');
        })

      } else {
        if (this.attendanceIdToEdit && this.timeSlotSelected) {
          const oldAttendanceId = this.attendanceIdToEdit;
          serviceToUse = this.rentService.moveReservationForEntitled(this.roundId, this.venueId, this.timeSlotSelected.slotId, oldAttendanceId, this.familiarUserId);
        }
        serviceToUse.pipe(takeUntil(this._unsubscribeSignal$.asObservable()))
          .subscribe((result: any) => {
            if (!result || result.error) {
              this.closeConfirmBookingModal();
              this.dispatchModal('035', result.error || '');
            } else {
              this.closeConfirmBookingModal();
              this.reservationCode = result.response;
              this.bookingConfirmed = true;
              this.modalService.open('bookingConfirmed');
            }
          }, (err: any) => {
            this.closeConfirmBookingModal();
            if (err && err.message) {
              this.dispatchModal('036', err.message);
            }
          });
      }
    }
  }

  closeBookingConfirmedModal() {
    this.redirectService.goToHome();
    this.modalService.close('bookingConfirmed');
    this.reservationCode = '';
  }

  closeVenueCard(fromKeyboard?: boolean) {
    this.selectedVenue = undefined;
    this.sidebarService.setShowNav(false);
    if (fromKeyboard) {
      setTimeout(() => {
        console.log(this.welionRadioButtonId);
        this.el.nativeElement.querySelector("#" + this.welionRadioButtonId).focus();
      }, 100);
    }
  }

  oldSearchClick(oldSearch: boolean) {
    this.newReservation = !oldSearch;

    // Preselezione appuntamento passato con gestione minori
    if (!this.newReservation && this.hasPastReservation && this.attendancesSearch.length) {

      if (this.underEighteenBirthday) {
        for (let i = 0; i < this.attendancesSearch.length; i++) {
          if (!this.attendancesSearch[i].disabled) {
            this.onChangeAttendance(this.attendancesSearch[i].attendance);
            break;
          }
        }
      } else {
        this.onChangeAttendance(this.attendancesSearch[0].attendance);
      }

    } else if (this.entitled && this.entitled.preMatchedVenue) {
      // In caso di sede predefinita
      this.currentAddress.setValue(this.entitled.preMatchedVenue.address);
      this.latitude.setValue(this.entitled.preMatchedVenue.latitude);
      this.longitude.setValue(this.entitled.preMatchedVenue.longitude);
      this.venueSearch = this.entitled.preMatchedVenue;
      this.venueId = this.entitled.preMatchedVenue.venueId;
    } else {
      this.currentAddress.setValue(undefined);
      this.latitude.setValue(undefined);
      this.longitude.setValue(undefined);
      this.venueSearch = undefined;
      this.venueId = '';
    }
  }

  onChangeAttendance(attendance: InfoAttendance) {
    this.attendanceSearch = attendance;

    if (attendance.venue) {
      this.currentAddress.setValue(attendance.venue.address);
      this.latitude.setValue(attendance.venue.latitude);
      this.longitude.setValue(attendance.venue.longitude);
      this.venueSearch = attendance.venue;
      this.venueId = attendance.venue.venueId;
    }
  }

  hasToShowDeliveryUnderageModal(venue?: InfoVenue) {
    // Di default tutte le sedi sono disabilitate per i minori
    // Se l'età del minore è presente nel range allora la si abilita
    if (venue && venue.validMinorTypes && venue.validMinorTypes.length > 0) {
      let showModal: boolean = false;
      venue.validMinorTypes.forEach(validMinorType => {
        if (validMinorType.age === this.entitledAge && validMinorType.vaccinationType === CampaignManagerMinorVaccinationType.DELIVERY) {
          showModal = true;
        }
      });
      return showModal;
    } else return false;
  }

  showDeliveryUnderageModal() {
    this.modalService.open('confirmDeliveryUnderage');
  }

  closeDeliveryUnderageModal() {
    this.modalService.close('confirmDeliveryUnderage');
    this.isLoadingStep = false;
  }

  confirmDeliveryUnderageModal() {
    this.modalService.close('confirmDeliveryUnderage');
    this.currentStep = 3;
    this.getSlotDates();
  }

  anyNotNull() {
    let test = false;
    if (this.selectedVenue) {
      this.ALL_KEYS.forEach((key: string) => {
        if (this.selectedVenue.details && this.selectedVenue.details[key] != null) {
          test = true;
        }
      })
    }
    return test;
  }

  closeMinorModal(confirm?: boolean) {
    this.modalService.close('minor-warning');
    if (confirm) {
      this.confirmBookingService();
    }
  }

  closeIfBookingConfirmed() {
    if (this.bookingConfirmed) {
      this.closeBookingConfirmedModal()
    }
  }

}

export interface DaySlotsFe extends DaySlots {
  isDisabled: boolean;
}

/*
 * Servizio che gestisce le chiamate ai servizi per le lingue
*/

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { UrlService } from "../../shared/services/url.service";
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { Lang, SenecaResponse } from 'atfcore-commonclasses';

@Injectable()
export class LangsService {
  constructor(private http: HttpClient,
    private urlService: UrlService,
    private translate: TranslateService) { }

  /**
   * Function that receive all active languages in platform
   * @return {Lang[]} The list of active languages
   */
  getAvailableLangs(): Observable<SenecaResponse<Lang[]>> {
    return this.http.get<any>(this.urlService.getServiceMediatorUrl() + 'get-all-langs');
  }

  // Recupera la lingua di default del browser
  getBrowserLang(): string {
    return this.translate.getBrowserLang()
  }

  // Lingua attualmente in uso recuperata dal Token
  getUserLang = (user: any) => {
    return user && user && user.userOptions && user.userOptions.langCode ? user.userOptions.langCode.substring(0, 2) : "it";
  }

  // Metodo che cambia la lingua e le relative traduzioni all'appplicazione
  useLanguage(language: string) {
    this.translate.use(language);
  }

  // Trova l'indice di una lingua all'interno di un Item
  findItemLangIndex = (langCode: string, item: any) => {
    let index: number = -1;
    if (item && item.itemLangs) {
      item.itemLangs.forEach((one: any, idx: number) => {
        if (one.langCode === langCode) {
          index = idx;
        }
      });
    }
    return index;
  }
}

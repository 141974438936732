// utilities per l'upload di file

// Recupera la stringa riguardante l'estensione del file (welion-upload)
export const GetFileExtensionString = (fileType: string) => {
  if (fileType == '2') {
    return "image"
  } else if (fileType == '0') {
    return "pdf"
  }
  switch (fileType) {
    case "application/pdf":
      return "pdf";
    case "image/png":
      return "png";
    case "image/jpeg" || "image/pjpeg":
      return "jpeg";
    case "application/vnd.openxmlformats-officedocument.presentationml.presentation" || "application/mspowerpoint" || "application/powerpoint" || "application/vnd.ms-powerpoint" || "application/x-mspowerpoint":
      return "ppt";
    case "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" || "application/excel" || "application/vnd.ms-excel" || "application/x-excel" || "application/x-msexcel":
      return "xls";
    case "application/vnd.openxmlformats-officedocument.wordprocessingml.document" || "application/msword":
      return "doc";
  }
  return "";
};

export const ACCEPTED_MIME_TYPES =
  "application/excel, application/vnd.ms-excel, application/x-excel, application/x-msexcel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document, image/jpeg, image/pjpeg, application/pdf, image/png, application/mspowerpoint, application/powerpoint, application/vnd.openxmlformats-officedocument.presentationml.presentation, application/vnd.ms-powerpoint, application/x-mspowerpoint"

export const ACCEPTED_MIME_XLSX = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";

export const ACCEPTED_MIME_DOCS = "image/jpeg, image/pjpeg, application/pdf, image/png";

export enum FileType {
  PDF,
  DOC,
  IMAGE
}

import { ActivatedRoute } from "@angular/router";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { Component, OnDestroy, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Observable, Subject } from 'rxjs';
import { Store } from "@ngrx/store";
import { takeUntil } from "rxjs/operators";

import * as fromApp from "../../../ngrx/app.reducers";
import * as CoreActions from "../../../core/ngrx/core.actions";
import { AnagService, ClientGroup } from "src/app/auth/services/anag.service";

import { RedirectService } from 'src/app/shared/services/redirect.service';
import { getWarningModal } from "src/app/utils/utils";
import { ScrollTo } from "src/app/core/services/scroll-to.service";
import { SupplierService } from "../../services/supplier.service";
import { SenecaResponse } from "atfcore-commonclasses/bin/classes/common";

@Component({
  selector: 'app-admin-add-client',
  templateUrl: './addClient.component.html',
  styleUrls: ['./addClient.component.scss']
})
export class AdminAddClientComponent implements OnInit, OnDestroy {

  isLoading: boolean = false;

  groupId: string = '';
  client?: ClientGroup;

  addClientForm = new FormGroup({
    'name': new FormControl(undefined, Validators.required)
  });

  get name() {
    return this.addClientForm.get('name') as FormControl;
  }

  private _unsubscribeSignal$: Subject<void> = new Subject();

  constructor(
    private route: ActivatedRoute,
    private appStore: Store<fromApp.AppState>,
    private translate: TranslateService,
    private redirectService: RedirectService,
    private scrollTo: ScrollTo,
    private supplierService: SupplierService,
    private anagService: AnagService
  ) {
  }

  ngOnInit() {
    this.scrollTo.header();

    this.groupId = this.route.snapshot.paramMap.get('groupId') || '';

    if (this.groupId) {
      this.isLoading = true;
      this.getClient();
    }
  }

  getClient() {
    this.anagService.getCustomersForAdmin(this.groupId)
      .pipe(takeUntil(this._unsubscribeSignal$.asObservable()))
      .subscribe((result) => {
        if (!result || result.error) {
          this.dispatchWarningModal('044', result.error || '');
        } else if (result.response) {
          this.client = result.response;
          this.name.setValue(result.response.title);
        }
        this.isLoading = false;
      }, (err) => {
        if (err && err.message) {
          this.dispatchWarningModal('045', err.message);
        }
        this.isLoading = false;
      });
  }

  ngOnDestroy() {
    this._unsubscribeSignal$.next();
    this._unsubscribeSignal$.unsubscribe();
  }

  /**
   * @description Va all'homepage delle SS
   */
  goBack() {
    if (this.client) {
      this.redirectService.goToAdminClient(this.groupId);
    } else {
      this.redirectService.goToAdminClients();
    }
  }

  goToFAQ() {

  }

  confirmAddClient() {
    if (this.addClientForm.valid) {
      this.isLoading = true;

      let request: Observable<SenecaResponse<boolean>> = this.client ?
        this.supplierService.updateCustomerForAdmin(this.name.value, this.client.groupId) :
        this.supplierService.createCustomerForAdmin(this.name.value);

      request
        .pipe(takeUntil(this._unsubscribeSignal$.asObservable()))
        .subscribe((result) => {
          if (!result || result.error) {
            this.dispatchWarningModal('041', result.error || '');
          } else if (result.response) {
            if (this.client) {
              this.redirectService.goToAdminClient(this.groupId);
            } else {
              this.redirectService.goToAdminClients();
            }
          }
          this.isLoading = false;
        }, (err) => {
          if (err && err.message) {
            this.dispatchWarningModal('042', err.message);
          }
          this.isLoading = false;
        });
    }
  }

  /**
   * @description Mostra una modale con un messaggio di errore
   * @param modalId
   * @param error
   */
  dispatchWarningModal(modalId: string, error: string) {
    const messageObj = getWarningModal(this.translate, modalId, error);
    this.appStore.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
  }

}

<div class="page-content-wrapper" fxLayout="column" fxLayoutAlign="start center">

  <div class="round-attendances" fxLayout="column">

    <h1 fxFlexOffset="28px" fxFlexOffset.xs="12px">{{ round?.title }}</h1>

    <!-- Navigazione -->
    <bread-crumbs *ngIf="round" fxFlexOffset="16px" firstLevel="ROUND" [idReference]="round.campaignId"
      [secondLevel]="(round.startDate | date: 'dd MMMM' | titlecase) + ' - ' + (round.endDate | date: 'dd MMMM yyyy' | titlecase)">
    </bread-crumbs>

    <!-- Ancoraggio per lo scroll -->
    <div id="round-attendance-anchor" fxFlexOffset="14px" fxFlexOffset.xs="12px"></div>

    <!-- Lista presenze per round -->
    <div fxFlexOffset="14px" fxFlexOffset.xs="12px" class="round-attendances-container" fxLayout="column">

      <!-- Navigazione -->
      <ng-container *ngIf="round">
        <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="space-between center" fxLayoutAlign.xs="start">

          <h3 translate="structures.round.attendances.SLOT"></h3>

          <!-- Seleziona struttura -->
          <welion-select fxFlexOffset="16px" isStructureSelect [bindLabel]="'title'" [selectedItem]="selectedStructure"
            searchable [loading]="isLoadingStructures" (change)="onStructureChange($event)" [items]="structureList"
            (scroll)="fetchMoreStructures($event)" [placeholder]="'structures.round.select.PLACEHOLDER' | translate"
            [noDataFoundLabel]="'structures.round.select.NO_STRUCTURE' | translate" [typeahead]="structureServive$"
            [attrAriaLabel]="'structures.round.select.PLACEHOLDER' | translate" [disabled]="disableStructureSelect">
          </welion-select>

          <div fxLayout="row" fxFlexOffset="44px" fxFlexOffset.xs="8px" fxLayoutAlign.xs="space-between">
            <!-- Gestisci persone -->
            <welion-button *ngIf="false" width="176px" ngClass.xs="double-button-mobile"
              label="structures.round.attendances.MANAGE_PEOPLE" type="primary" size="medium" (onClick)="managePeople()"
              [disabled]="true || isLoadingInfo || attendanceDays.length === 0"></welion-button>
            <!-- Prenotati -->
            <welion-button ngClass.xs="double-button-mobile" label="structures.round.attendances.BOOKED" type="primary"
              size="medium" (onClick)="bookedPeople()" [disabled]="isLoadingInfo || attendanceDays.length === 0">
            </welion-button>
            <!-- Aggiungi -->
            <welion-button ngClass.xs="double-button-mobile" (onClick)="openAttendanceCard()" fxFlexOffset="4px"
              fxFlexOffset.xs="12px" width="160px" label="structures.round.attendances.SEARCH_PEOPLE" type="primary"
              size="medium" [disabled]="isLoadingInfo || attendanceDays.length === 0">
            </welion-button>
          </div>
        </div>
      </ng-container>

      <ng-container *ngIf="isLoadingInfo">
        <div class="loader spinner-big"></div>
      </ng-container>

      <ng-container *ngIf="!isLoadingInfo">
        <ng-container *ngIf="attendanceDays.length else noSlots">

          <div fxHide fxShow.xs class="full-width" fxFlexOffset="16px">
            <!-- Lista giorni modalità mobile -->
            <welion-select [items]="dayDropdown" [selectedItem]="selectedDayDropdown"
              (change)="onSelectDaySlotMobile($event)">
            </welion-select>
          </div>

          <div fxShow fxHide.xs fxFlexOffset="16px" fxLayout="row">
            <div fxLayout="row" fxFlex="256px" fxFlexOffset="8px" fxLayoutAlign="space-between">
              <!-- Data -->
              <h6 class="black bold" translate="structures.round.attendances.table.DATE"></h6>
              <!-- Da elaborare -->
              <h6 class="black bold" translate="structures.round.attendances.table.TO_BE_PRECESSED"></h6>
            </div>
            <!-- Nome e cognome -->
            <h6 fxFlexOffset="44px" fxFlex="184px" class="black bold"
              translate="structures.round.attendances.table.NAME_SURNAME"></h6>
            <!-- Stato -->
            <h6 fxFlex="152px" class="black bold" translate="structures.round.attendances.table.STATE"></h6>
            <!-- Orario -->
            <h6 fxFlex="152px" class="black bold" translate="structures.round.attendances.table.TIME"></h6>
          </div>

          <div fxFlexOffset="16px" fxLayout="row">

            <!-- Lista giorni -->
            <div fxShow fxHide.xs fxLayout="column" fxLayoutAlign="start start" class="day-attendances-cards">
              <div *ngFor="let slotAttendanceDay of attendanceDays; let first = first; let index = index"
                [fxFlexOffset]="first ? '0px' : '4px'" [ngClass]="{'selected-day': index === indexSelectedDaySlots}"
                class="day-attendances" (click)="onSelectDay(index)" fxLayout="row" fxLayoutAlign="space-between">
                <!-- Lunedì 1 Marzo -->
                <h5>{{ slotAttendanceDay.date | date: 'EEEE dd MMMM' | titlecase }}</h5>

                <div *ngIf="slotAttendanceDay.toBeProcessedNumber > 0" class="to-be-processed-number" fxLayout="row"
                  fxLayoutAlign="center center">
                  <h5 class="white">{{ slotAttendanceDay.toBeProcessedNumber }}</h5>
                </div>
              </div>
            </div>

            <!-- Lista presenze -->
            <div fxFlexOffset="24px" fxFlexOffset.xs="0px" fxLayout="column" class="full-width">

              <!-- Seconda sezione con presenze -->
              <!-- Presenze -->
              <div fxFlexOffset="4px" fxLayout="column" infiniteScroll [infiniteScrollDistance]="2"
                [infiniteScrollThrottle]="300" (scrolled)="getDaySlots()">

                <div *ngFor="let attendance of attendances; let first = first" [fxFlexOffset]="first ? '' : '2px'"
                  [fxFlexOffset.xs]="first ? '' : '8px'" class="resume-attendance-card" fxLayout="row"
                  fxLayoutAlign.xs="start">

                  <!-- Pallino prenotato mobile -->
                  <div fxHide fxShow.xs fxLayout="column" fxLayoutAlign="center" fxFlex="12px">
                    <div *ngIf="attendance.status === 'RESERVED'" class="reserved-attendance"></div>
                  </div>

                  <div fxLayout="row" fxLayout.xs="row wrap" fxLayoutAlign="start center" fxLayoutAlign.xs="start">
                    <!-- Cognome e nome -->
                    <div fxFlex="200px" fxFlex.xs="50" fxLayout="row" fxLayoutAlign="start center">
                      <div *ngIf="attendance.status === 'RESERVED'" fxShow fxHide.xs fxFlexOffset="12px"
                        class="reserved-attendance">
                      </div>
                      <!-- Pallino prenotato -->
                      <div [fxFlexOffset]="attendance.status === 'RESERVED' ? '12px' : '36px'" fxFlexOffset.xs="12px">
                        <h6 class="black">{{ attendance.surname + ' ' + attendance.name }}</h6>
                      </div>
                    </div>

                    <!-- Stato -->
                    <h6 class="black" fxFlex="152px" fxFlex.xs="50">
                      {{ ('status.' + attendance.status) | translate }}
                    </h6>

                    <!-- Orario -->
                    <div fxFlex="152px" fxFlex.xs="50">
                      <h6 class="black" fxFlexOffset.xs="12px">
                        {{ attendance.startDate | date: 'HH:mm' }} - {{ attendance.endDate | date: 'HH:mm' }}
                      </h6>
                    </div>

                    <!-- Scheda -->
                    <h6 fxFlex.xs="50" class="black bold underline cursor-pointer"
                      translate="structures.round.attendances.table.CARD" (click)="openAttendanceCard(attendance)">
                    </h6>
                  </div>

                </div>
              </div>

              <ng-container *ngIf="isLoadingAttendances">
                <div fxFlex class="loader spinner-medium"></div>
              </ng-container>

              <ng-container *ngIf="!isLoadingAttendances && !attendances.length" [ngTemplateOutlet]="noAttendances">
              </ng-container>

            </div>

          </div>
        </ng-container>
      </ng-container>

    </div>

  </div>

</div>


<!-- #region Templates -->

<!-- Placeholder nessuna presenza disponibile -->
<ng-template #noAttendances>
  <h6 fxFlexOffset="20px" class="black" translate="structures.round.NO_ATTENDANCES"></h6>
</ng-template>

<!-- Placeholder nessuno slot disponibile -->
<ng-template #noSlots>
  <h6 fxFlexOffset="40px" class="black" translate="structures.round.NO_SLOTS"></h6>
</ng-template>


<!-- #endregion -->

<!-- Sidenav con la scheda di dettaglio -->
<sidebar showAppHeader hideCloseButton [sidenavTemplateRef]="navContent" direction="right" [navWidth]="664"
  [duration]="0.3">
</sidebar>

<!-- Contenuto sidenav -->
<ng-template #navContent>

  <form class="attendance-card-sidenav" fxLayout="column" [formGroup]="attendeeForm" (ngSubmit)="saveAttendee()">

    <div class="full-width" fxLayout="row" fxLayoutAlign="end">
      <!-- Icona chiudi -->
      <div fxLayoutAlign="row" fxLayoutAlign="center center" (click)="closeAttendanceCard()" class="close-container">
        <svg-icon class="small-size" src="/assets/img/icons/x.svg" [applyClass]="true"></svg-icon>
        <h5 fxFlexOffset="8px" class="white">{{ 'generic.CLOSE' | translate | uppercase}}</h5>
      </div>
    </div>

    <!-- Scheda personale -->
    <h5 fxFlexOffset="20px" class="bold" translate="structures.round.attendances.modal.TITLE"></h5>

    <ng-container *ngIf="!isLoadingInfo">
      <!-- Palerma Leonida -->
      <ng-container *ngIf="selectedAttendanceForm">
        <h3 fxFlexOffset="16px">{{ selectedAttendanceForm.surname }} {{ selectedAttendanceForm.name }}</h3>
      </ng-container>
      <!-- Aggiungi utente -->
      <ng-container *ngIf="!selectedAttendanceForm">
        <h3 fxFlexOffset="16px" translate="structures.round.attendances.modal.ADD_USER"></h3>
      </ng-container>

      <div fxFlexOffset="16px" class="card-header-divider"></div>

      <!-- Ricerca utenti -->
      <div class="search-row-wrapper">
        <!-- Ricerca per codice fiscale -->
        <div class="search-row-item">

          <!-- Codice fiscale -->
          <h5 fxFlexOffset="24px" class="bold" translate="structures.round.attendances.modal.FISCAL_CODE"></h5>

          <div fxLayout="column">
            <div *ngIf="fromNewAttendance" fxLayout="row">
              <welion-input [inputValue]="fiscalCode" iconRight="assets/img/icons/icon-search.svg"
                (onIconClicked)="searchEntitledByCodfisc()" (onChange)="searchEntitledChange()"
                [disabled]="isLoadingFiscalCodeSearch"
                [placeholder]="'structures.round.attendances.modal.FISCAL_CODE_DIGITS' | translate">
              </welion-input>

              <div fxFlexOffset="16px" fxFlex="24px">
                <div *ngIf="isLoadingFiscalCodeSearch" class="loader spinner-small fiscalCode"></div>
              </div>

            </div>
            <h5 fxFlexOffset="4px" *ngIf="!fromNewAttendance && selectedAttendanceForm">
              {{ selectedAttendanceForm.fiscalCode}}
            </h5>

            <ng-container *ngIf="fromNewAttendance">
              <!-- Errori codice fiscale -->
              <ng-container *ngIf="fiscalCode.valid && !firstFiscalCodeSearch && validFiscalCode.hasError('required')">
                <!-- L'avente diritto non è presente a sistema oppure ha già prenotato in altra struttura/sede. Si prega di contattare il proprio referente per la Campagna -->
                <h6 fxFlexOffset="12px" class="bold high-line"
                  translate="structures.round.attendances.modal.FISCAL_CODE_NOT_FOUND">
                </h6>
              </ng-container>
              <!-- Formato codice fiscale italiano non valido -->
              <h6 *ngIf="fiscalCode.hasError('pattern')" fxFlexOffset="12px" class="bold"
                translate="structures.round.attendances.modal.FISCAL_CODE_NOT_FOUND2">
              </h6>
              <!-- Il codice fiscale deve essere lungo 16 caratteri -->
              <h6 *ngIf="fiscalCode.hasError('minlength') || fiscalCode.hasError('maxlength')"
                [fxFlexOffset]="fiscalCode.hasError('pattern') ? '8px' : '12px'" class="bold"
                translate="structures.round.attendances.modal.FISCAL_CODE_NOT_FOUND3">
              </h6>
            </ng-container>
          </div>
        </div>

        <!-- Ricerca per nome e cognome -->
        <div class="search-row-item">
          <!-- Codice fiscale -->
          <h5 fxFlexOffset="4px" class="bold" translate="structures.round.attendances.modal.FULL_NAME"></h5>

          <!--welion-input [inputValue]="fullNameSearch" iconRight="assets/img/icons/icon-search.svg"
              (onIconClicked)="searchEntitledByFullName()" (onChange)="searchNameSurnameChange()"
              [disabled]="isLoadingfullNameSearch"
              [placeholder]="'structures.round.attendances.modal.FULL_NAME_PLACEHOLDER' | translate">
            </welion-input-->
          <welion-select fxFlexOffset="16px" [bindLabel]="'title'" [selectedItem]="selectedUserSearch" searchable
            [loading]="isLoadingUsers" (change)="onFullNameChosen($event)" [items]="usersList"
            (scroll)="fetchMoreUsers($event)" [placeholder]="'structures.round.select.PLACEHOLDER_USER' | translate"
            [noDataFoundLabel]="'generic.NO_DATA' | translate" [typeahead]="userService$"
            [attrAriaLabel]="'structures.round.select.PLACEHOLDER' | translate">
          </welion-select>

          <div *ngIf="isLoadingfullNameSearch" class="loader spinner-small fiscalCode"></div>

        </div>

      </div>


      <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="space-between"
        [fxFlexOffset]="selectedAttendanceForm ? '16px' : (!firstFiscalCodeSearch && validFiscalCode.hasError('required') ? '12px' : '20px')">

        <!-- Nome e cognome -->
        <div fxLayout="column" fxFlex="50" fxFlex.xs>
          <h5 class="bold" translate="structures.round.attendances.modal.NAME"></h5>
          <h5 fxFlexOffset="4px">
            {{ selectedAttendanceForm ? (selectedAttendanceForm.surname + ' ' + selectedAttendanceForm.name) : '-' }}
          </h5>
        </div>

        <!-- Società -->
        <div fxFlexOffset="30px" fxLayout="column" fxFlex>
          <h5 class="bold" translate="structures.round.attendances.modal.COMPANY"></h5>
          <h5 fxFlexOffset="4px">
            {{ selectedAttendanceForm && selectedAttendanceForm.company || '-' }}
          </h5>
        </div>

      </div>

      <div *ngIf="underEighteenBirthday" fxFlexOffset="12px" fxLayout="column">
        <h5 class="bold" translate="structures.round.attendances.modal.VACCINATION_MODE"></h5>

        <welion-switch fxFlexOffset="12px" [checked]="hasDeliveryStatus" (onSwitch)="onDeliveryStatusChange($event)"
          [leftLabelObject]="'admin.addHealthFacilityVenue.VACCINATION_MANAGEMENT_TYPE_ADMINISTRATION' | translate | uppercase"
          [rightLabelObject]="'admin.addHealthFacilityVenue.VACCINATION_MANAGEMENT_TYPE_DELIVERY' | translate | uppercase">
        </welion-switch>
      </div>

      <!-- Recapiti -->
      <div fxFlexOffset="12px" fxLayout="row" fxLayout.xs="column" fxLayoutAlign="space-between">
        <div fxLayout="column">
          <h5 class="bold" translate="structures.round.attendances.modal.CONTACT_DETAILS"></h5>
          <!-- Numero di telefono -->
          <h5 fxFlexOffset="4px">{{ 'structures.round.attendances.modal.PHONE' | translate:
            { value: (selectedAttendanceForm && selectedAttendanceForm.phoneNumber || '-') }
            }}</h5>
          <!-- Email -->
          <h5 fxFlexOffset="4px">{{ 'structures.round.attendances.modal.EMAIL' | translate:
            { value: (selectedAttendanceForm && selectedAttendanceForm.email || '-') }
            }}</h5>
        </div>
        <!-- Stato -->
        <ng-container [ngTemplateOutlet]="statusSelect"></ng-container>
      </div>

      <div fxFlexOffset="12px" fxLayout="row" fxLayoutAlign="space-between center">
        <!-- Prenotazione slot in sola lettura -->
        <ng-container [ngTemplateOutlet]="slotSelect" [ngTemplateOutletContext]="{'readOnly': true}"></ng-container>
      </div>

      <div fxFlexOffset="16px"
        *ngIf="selectedStatusDropDown && (selectedStatusDropDown.id === 'YES' || selectedStatusDropDown.id === 'DELIVERED')">
        <!-- Vaccinazione in sola lettura -->
        <ng-container [ngTemplateOutlet]="vaccinationSelect"></ng-container>
      </div>

      <ng-container *ngIf="selectedAttendanceForm && (selectedAttendanceForm.anamnesis || underEighteenBirthday)">

        <div fxFlexOffset="16px" class="card-anamnesis-divider"></div>

        <div *ngIf="underEighteenBirthday" fxLayout="column" fxFlexOffset="24px">
          <!-- Età -->
          <h5 class="bold" translate="structures.round.attendances.modal.AGE"></h5>
          <!-- 17 anni -->
          <h5 fxFlexOffset="4px">
            <ng-container *ngIf="selectedAttendanceAge === 1">
              {{ 'structures.round.attendances.modal.YEAR_OLD' | translate }}
            </ng-container>
            <ng-container *ngIf="selectedAttendanceAge !== 1">
              {{ 'structures.round.attendances.modal.YEARS_OLD' | translate: { value: selectedAttendanceAge } }}
            </ng-container>
          </h5>
        </div>

        <h5 *ngIf="underEighteenBirthday && selectedAttendanceForm.anamnesis && selectedAttendanceForm.anamnesis.length"
          fxFlexOffset="24px" class="bold" translate="structures.round.attendances.modal.ANAMNESI"></h5>

        <ng-container *ngIf="selectedAttendanceForm.anamnesis && selectedAttendanceForm.anamnesis.length">
          <div class="card-anamnesis" *ngFor="let anamnesis of selectedAttendanceForm.anamnesis; let first = first"
            [fxFlexOffset]="first && !underEighteenBirthday ? '24px' : '12px'">
            <!-- Età -->
            <!-- <h5 class="bold">{{ anamnesis.title }}</h5> -->
            <div fxLayout="row">
              <!-- Ha meno di 9 anni? -->
              <h5>{{ ('anamnesis.' + anamnesis.questionKey) | translate }}</h5>
              <!-- SI/NO -->
              <h5 fxFlexOffset="4px" class="primary bold"
                translate="structures.round.attendances.modal.{{ anamnesis.answer ? 'YES' : 'NO' }}">
              </h5>
            </div>
          </div>
        </ng-container>

      </ng-container>

      <!-- Parte per minorenni -->
      <ng-container *ngIf="underNineYearsOld || !recognizedFC">

        <input-container fxFlexOffset="24px" class="full-width"
          [title]="'structures.round.attendances.modal.REQUIRED_RECALL' | translate">
          <welion-switch [readonly]="true" [checked]="requiredRecall.value" (onSwitch)="onRequiredRecallChange($event)"
            [leftLabelObject]="'generic.NO' | translate" [rightLabelObject]="'generic.YES' | translate">
          </welion-switch>
        </input-container>

        <div fxFlexOffset="24px" fxLayout="row">
          <!-- Stato richiamo -->
          <ng-container [ngTemplateOutlet]="recallStatusSelect"></ng-container>
        </div>

        <div fxFlexOffset="24px" fxLayout="row">
          <div fxLayout="column" fxFlex="260px" fxFlex.xs="100" fxFlexOffset.xs="0px">
            <h5 class="bold" translate="structures.round.attendances.modal.RECALL_DATE"></h5>
            <welion-datepicker class="full-width" placeholder="structures.round.attendances.modal.DATE_PLACEHOLDER"
              [value]="selectedRecallDate" (dateChanged)="onRecallDateChange($event)">
            </welion-datepicker>
          </div>
        </div>

      </ng-container>

      <!-- Descrizione -->
      <input-container fxFlexOffset="24px" [isFromTextArea]="true"
        [title]="'structures.round.attendances.modal.DESC' | translate">
        <welion-text-area [placeholder]="'structures.round.attendances.modal.DESC_PLACEHOLDER' | translate"
          [inputValue]="description">
        </welion-text-area>
      </input-container>

    </ng-container>

    <ng-container *ngIf="isLoadingInfo">
      <div fxFlex class="loader spinner-big"></div>
    </ng-container>

    <!-- Annulla -->
    <div fxFlexOffset="20px" fxLayout="row" fxLayoutAlign="space-between center">
      <welion-button ngClass.xs="double-button-mobile" (onClick)="closeAttendanceCard()" width="232px"
        label="generic.ANNULL" type="primary bordless" size="medium" [disabled]="isLoadingInfo">
      </welion-button>

      <!-- Salva -->
      <welion-button ngClass.xs="double-button-mobile" fxFlexOffset.xs="12px" buttonType="submit" width="186px"
        label="generic.SAVE" type="primary" size="medium"
        [disabled]="isLoadingInfo || isLoadingFiscalCodeSearch || attendeeForm.invalid">
      </welion-button>
    </div>

    <div fxFlexOffset="20px"></div>

  </form>

</ng-template>

<!-- Stato / Status -->
<ng-template #statusSelect>
  <div fxLayout="column" fxFlex="260px" fxFlex.xs="100" fxFlexOffset.xs="0px">
    <h5 class="bold" translate="structures.round.attendances.modal.STATUS"></h5>
    <!-- Stato di consegna per minori nella fascia della sede con impostazione di consegna impostata -->
    <ng-container *ngIf="hasDeliveryStatus">
      <welion-select [items]="statusDeliveredDropDown" [selectedItem]="selectedStatusDropDown"
        (change)="onStatusChange($event)">
      </welion-select>
    </ng-container>
    <!-- Stati normali -->
    <ng-container *ngIf="!hasDeliveryStatus">
      <welion-select [items]="statusDropDown" [selectedItem]="selectedStatusDropDown" (change)="onStatusChange($event)">
      </welion-select>
    </ng-container>
  </div>
</ng-template>

<!-- Stato richiamo -->
<ng-template #recallStatusSelect>
  <div fxLayout="column" fxFlex="260px" fxFlex.xs="100" fxFlexOffset.xs="0px">
    <h5 class="bold" translate="structures.round.attendances.modal.RECALL_STATE"></h5>
    <!-- Stato di consegna per minori nella fascia della sede con impostazione di consegna impostata -->
    <ng-container *ngIf="hasDeliveryStatus">
      <welion-select [items]="recallStatusDeliveredDropDown" [selectedItem]="selectedRecallStatusDropDown"
        (change)="onStatusRecallChange($event)">
      </welion-select>
    </ng-container>
    <!-- Stati normali -->
    <ng-container *ngIf="!hasDeliveryStatus">
      <welion-select [items]="recallStatusDropDown" [selectedItem]="selectedRecallStatusDropDown"
        (change)="onStatusRecallChange($event)">
      </welion-select>
    </ng-container>
  </div>
</ng-template>

<ng-template #slotSelect>
  <div fxLayout="column" class="full-width">
    <div fxLayout="column">
      <!-- Prenotazione giorno e slot -->
      <div fxLayout="row">
        <h5 class="bold primary" translate="structures.round.attendances.modal.RESERVATION"></h5>
        <h5 class="bold" fxFlexOffset="4px" translate="structures.round.attendances.modal.DAY_SLOT"></h5>
      </div>
      <ng-container *ngIf="selectedAttendanceForm">
        <h5 fxFlexOffset="4px">
          {{ (selectedAttendanceForm.startDate | date: 'EEEE dd MMMM' | titlecase) +
          ' | ' +
          (selectedAttendanceForm.startDate | date: 'HH:mm') +
          ' - ' +
          (selectedAttendanceForm.endDate | date: 'HH:mm')}}</h5>
      </ng-container>
      <!-- Se è in inserimento e il CF non è corretto -->
      <ng-container *ngIf="!selectedAttendanceForm && fromNewAttendance">
        {{ '-' }}
      </ng-container>

    </div>

    <div *ngIf="selectedAttendanceForm && selectedAttendanceForm.reservationId" fxLayout="column" fxFlexOffset="12px">
      <!-- Codice prenotazione -->
      <div fxLayout="row">
        <h5 class="bold" translate="structures.round.attendances.modal.PREN_CODE"></h5>
      </div>
      <ng-container>
        <h5 fxFlexOffset="4px">
          {{selectedAttendanceForm.reservationId}}
        </h5>
      </ng-container>

    </div>
  </div>
</ng-template>

<ng-template #vaccinationSelect let-readOnly='readOnly'>
  <div fxLayout="column" fxFlex="400px" fxFlex.xs="100">

    <ng-container *ngIf="!readOnly || !selectedSlotDropDown">
      <div fxLayout="row">
        <h5 class="bold primary">
          <ng-container *ngIf="hasDeliveryStatus">
            {{ 'structures.round.attendances.modal.DELIVERY' | translate }}
          </ng-container>
          <ng-container *ngIf="!hasDeliveryStatus">
            {{ 'structures.round.attendances.modal.VACCINATION' | translate }}
          </ng-container>
        </h5>
        <h5 class="bold" fxFlexOffset="4px" translate="structures.round.attendances.modal.DAY_SLOT"></h5>
      </div>

      <div fxLayout="row">
        <!-- Data vaccinazione -->
        <welion-datepicker fxFlex="150px" class="full-width"
          placeholder="structures.round.attendances.modal.DATE_PLACEHOLDER" [value]="selectedVaccinationDate"
          (dateChanged)="onVaccinationDateChange($event)" [minDate]="minVaccinationDate" [maxDate]="maxVaccinationDate">
        </welion-datepicker>

        <!-- Dalle -->
        <div fxLayout="column">
          <welion-input #vaccinationTimeFromInput placeholder="09:00" [inputValue]="vaccinationTimeFrom">
          </welion-input>
          <h6 *ngIf="vaccinationTimeFrom.hasError('pattern')" fxFlexOffset="4px" class="small red"
            translate="errors.WRONG_FORMAT"></h6>
        </div>

        <!-- Alle -->
        <div fxLayout="column">
          <welion-input #vaccinationTimeToInput placeholder="09:10" [inputValue]="vaccinationTimeTo">
          </welion-input>
          <h6 *ngIf="vaccinationTimeTo.hasError('pattern')" class="small red" translate="errors.WRONG_FORMAT"></h6>
        </div>
      </div>

    </ng-container>

    <ng-container *ngIf="readOnly && selectedAttendanceForm && selectedAttendanceForm.vaccinationSlotStartDate">
      <!-- Vaccinazione giorno e slot -->
      <div fxLayout="row">
        <h5 class="bold primary" translate="structures.round.attendances.modal.VACCINATION"></h5>
        <h5 class="bold" fxFlexOffset="4px" translate="structures.round.attendances.modal.DAY_SLOT"></h5>
      </div>
      <h5 fxFlexOffset="4px">
        {{ (selectedAttendanceForm.vaccinationSlotStartDate | date: 'EEEE dd MMMM | ' | titlecase) }}
        {{ (selectedAttendanceForm.vaccinationSlotStartDate | date: 'HH:mm - ') }}
        {{ (selectedAttendanceForm.vaccinationSlotEndDate | date: 'HH:mm') }}
      </h5>
    </ng-container>

  </div>
</ng-template>
import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { Component, OnInit, ViewEncapsulation, Input, ViewChild, OnChanges, SimpleChanges } from '@angular/core';
import { SidebarService } from '../../services/sidebar.service';

@Component({
  selector: 'sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class SidebarComponent implements OnInit {
  showSideNav: any;

  @Input() sidenavTemplateRef: any;

  _hideCloseButton: boolean = false;
  @Input() set hideCloseButton(value: boolean | string) {
    this._hideCloseButton = coerceBooleanProperty(value);
  };

  _showAppHeader: boolean = false;
  @Input() set showAppHeader(value: boolean | string) {
    this._showAppHeader = coerceBooleanProperty(value);
  };
  @Input() id!:string;
  @Input() duration: number = 0.25;
  @Input() navWidth: number = window.innerWidth;
  @Input() direction = 'left';
  @Input() ariaLabel: string = 'Sidebar con la scheda di dettaglio';

  constructor(private sidebarService: SidebarService) { }

  ngOnInit() {
    this.showSideNav = this.sidebarService.getShowNav();
  }

  onSidebarClose() {
    this.sidebarService.setShowNav(false);
  }

  get isSideNavExpanded(): string {
    return this.showSideNav ? 'true' : 'false';
  }

  getSideNavBarStyle(showNav: boolean | null) {
    let navBarStyle: any = {};

    navBarStyle.transition = this.direction + ' ' + this.duration + 's, visibility ' + this.duration + 's';
    navBarStyle.width = this.navWidth + 'px';
    navBarStyle[this.direction] = (showNav ? 0 : (this.navWidth * -1)) + 'px';

    return navBarStyle;
  }

}

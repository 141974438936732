import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

export type ButtonSize = 'medium' | 'large';

@Component({
  selector: 'welion-button',
  templateUrl: 'welion-button.component.html',
  styleUrls: ['./welion-button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class WelionButtonComponent {
  @Input() label: string = '';

  /**
   * @description Componibile: primary/secondary/third + bordless o link
   */
  @Input() type: string = 'primary bordless';

  /**
   * @description Dimensione default a large 56px, medium 40px
   */
  @Input() size: ButtonSize = 'large';

  _hasLeftIcon: boolean = false;
  @Input() set hasLeftIcon(value: string) {
    this._hasLeftIcon = coerceBooleanProperty(value);
  }

  _hasRightIcon: boolean = false;
  @Input() set hasRightIcon(value: string) {
    this._hasRightIcon = coerceBooleanProperty(value);
  }

  @Input() customIcon: string = '';

  _noUpperCase: boolean = false;
  /**
   * @description for not set
   */
  @Input() set noUpperCase(value: string) {
    this._noUpperCase = coerceBooleanProperty(value);
  }

  @Input() disabled: any = false;

  @Input() width: string = '';

  @Input() buttonType: 'button' | 'submit' | 'reset' = 'button';

  // tslint:disable-next-line: no-output-on-prefix
  @Output('onClick') onButtonClicked: EventEmitter<Date> = new EventEmitter();

  constructor() { }

  emitOnClick() {
    if (!this.disabled) {
      this.onButtonClicked.emit();
    }
  }
}

<div class="welion-radio-wrapper" *ngFor="let item of items;index as i" (click)="setValue(item)" [ngClass]="{
    'disabled': disabled || ((_isVenue || _isAttendance) && item.disabled) || item.isDisabled,
    'readonly': readonly,
    'daySlot': _daySlot || _isVenue || _isAttendance,
    'no-margin': isRow,
    'selected':
    (bindValue && !_daySlot && !_isVenue && !_isAttendance && bindValue.id === item.id) ||
    (bindValue && _daySlot && bindValue.date === item.date) ||
    (bindValue && _isVenue && bindValue.venueId === item.venue.venueId) ||
    (bindValue && _isAttendance && bindValue.attendanceId === item.attendance.attendanceId)
  }" fxLayout="row" [fxLayoutAlign]="_daySlot ? 'start center' : 'start center'">

  <!-- Normal radio -->
  <ng-container *ngIf="!_daySlot && !_isVenue && !_isAttendance">
    <div role="radio" [attr.aria-checked]="bindValue && bindValue.id === item.id"
      [attr.aria-label]="item.attrAriaLabel" class="radio-empty" fxLayout="row" fxLayoutAlign="center center">
      <div *ngIf="bindValue && bindValue.id === item.id" class="checked"></div>
    </div>
    <p fxFlexOffset="16px" class="label-text grey-color" *ngIf="withLabel" [translate]="item.value"></p>
  </ng-container>

  <!-- Day slot radio -->
  <ng-container *ngIf="_daySlot">
    <div role="radio" [attr.aria-checked]="bindValue && bindValue.date === item.date"
      [attr.aria-label]="item.attrAriaLabel" class="radio-empty" fxLayout="row" fxLayoutAlign="center center" tabindex="0"  clickKeyBoard (onEnterKeyboard)="setValue(item)">
      <div *ngIf="bindValue && bindValue.date === item.date" class="checked"></div>
    </div>
    <div fxFlexOffset="16px" fxFlex fxLayout="row" fxLayoutAlign="space-between center">
      <p class="label-text">{{ item.date | date: 'EEEE dd MMMM' | titlecase }}</p>
      <p class="label-text">
        {{ ('addReservation.SEAT' + (item.unbookedSlotCount === 1 ? '' : 'S')) | translate: { value:
        item.unbookedSlotCount } }}
      </p>
    </div>
  </ng-container>

  <!-- Venue radio -->
  <ng-container *ngIf="_isVenue">
    <div role="radio" [attr.aria-checked]="bindValue && bindValue.venueId === item.venue.venueId"
      [attr.aria-label]="item.attrAriaLabel" class="radio-empty" fxLayout="row"
      fxLayoutAlign="center center" tabindex="0"  clickKeyBoard (onEnterKeyboard)="setValue(item)">
      <div *ngIf="bindValue && bindValue.venueId === item.venue.venueId" class="checked"></div>
    </div>
    <div fxFlexOffset="20px" fxFlex fxLayout="column" fxLayoutAlign="space-between">
      <p>
        <!-- Sede private -->
        <span *ngIf="item.isPrivate" class="private-venue" translate="generic.VENUE"></span>
        <span class="bold">
          <!-- Nome -->
          {{item.venue.name}}
        </span>
      </p>
      <div fxFlexOffset="5px" fxLayout="row" fxLayoutAlign="space-between">
        <!-- Indirizzo -->
        <p class="label-text-small">{{item.venue.address}}</p>
        <!-- Distanza -->
        <p class="black nowrap label-text-small" *ngIf="item.venue.distance">
          {{ (item.venue.distance | number: '1.0-1') + ' km' }}
        </p>
      </div>
      <div fxFlexOffset="5px" fxLayout="row" fxLayoutAlign="space-between">
        <p>
          <!-- Scheda struttura -->
          <span id="welion-radio-button-{{item.venue.venueId}}" translate="generic.VENUE_FORM" class="link height-24" (click)="onVenueDetail.emit(item.venue)" tabindex="0"
            clickKeyBoard (onEnterKeyboard)="onVenueDetail.emit(item.venue)" role="button"></span>
        </p>
        <!-- Consegna alla persona, somministrazione -->
        <p *ngIf="venueForJunior">
          <ng-container *ngIf="item.disabled">
            <span class="small" translate="addReservation.steps.side.NOT_SELECTABLE"></span>
          </ng-container>
          <ng-container *ngIf="!item.disabled && item.vaccinationType">
            <span class="small">
              {{ ('addReservation.steps.side.' + item.vaccinationType) | translate }}
            </span>
          </ng-container>
        </p>
      </div>

    </div>
  </ng-container>

  <!-- Attendance radio -->
  <ng-container *ngIf="_isAttendance">
    <div role="radio" [attr.aria-checked]="bindValue && bindValue.attendanceId === item.attendance.attendanceId" [attr.aria-label]="item.attrAriaLabel"
      class="radio-empty" fxLayout="row" fxLayoutAlign="center center" tabindex="0" clickKeyBoard (onEnterKeyboard)="setValue(item)">
      <div *ngIf="bindValue && bindValue.attendanceId === item.attendance.attendanceId" class="checked"></div>
    </div>
    <div fxFlexOffset="20px" fxFlex fxLayout="column" fxLayoutAlign="space-between">
      <p class="label-text-attendance">
        {{
        (item.attendance.startDate | date: 'EEEE dd MMMM HH:mm' | titlecase) +
        ' - ' +
        (item.attendance.endDate | date: 'HH:mm')
        }}
      </p>
      <p class="bold label-text-attendance" fxFlexOffset="5px">
        <ng-container *ngIf="item.attendance.venue">
          {{ item.attendance.venue.name }}
        </ng-container>
      </p>
      <p class="label-text-attendance" fxFlexOffset="5px">
        {{ 'Prenotazione per ' + (item.attendance.name + ' ' + item.attendance.surname | titlecase) }}
      </p>
      <div fxFlexOffset="5px" fxLayout="row" fxLayoutAlign="space-between">
        <p>
          <!-- Scheda struttura -->
          <span id="welion-radio-button-{{item.attendance.venueId}}" translate="generic.VENUE_FORM" class="link height-24" (click)="onVenueDetail.emit(item.attendance.venue)" tabindex="0"
          clickKeyBoard (onEnterKeyboard)="onVenueDetail.emit(item.attendance.venue)" role="button"></span>
        </p>
        <!-- Consegna alla persona, somministrazione -->
        <p *ngIf="venueForJunior && item.attendance.venue">
          <ng-container *ngIf="item.disabled">
            <span class="small" translate="addReservation.steps.side.NOT_SELECTABLE"></span>
          </ng-container>
          <ng-container *ngIf="!item.disabled && item.vaccinationForMinorType">
            <span class="small">
              {{ ('addReservation.steps.side.' + item.vaccinationForMinorType) | translate }}
            </span>
          </ng-container>
        </p>
      </div>
    </div>
  </ng-container>
</div>
<!-- DC funziona -->
<div tabindex="0" class="page-content-wrapper" fxLayout="column" id="main-content">

  <!-- Campagne -->
  <h1 tabindex="0" fxFlexOffset="20px" class="primary" translate="admin.home.ACTIVE_CAMPAIGNS"></h1>

  <ng-container *ngIf="!isLoadingBase">

    <div *ngIf="infoRequestCount" class="pending-request-form" fxFlexOffset="16px" fxLayout="column">
      <!-- Attività in sospeso -->
      <h1 class="white" translate="admin.home.pending_activities.TITLE"></h1>

      <div fxLayout="row" fxFlexOffset="24px">

        <div fxLayout="column">
          <h3 class="white">{{ infoRequestCount.EVENT_APPROVATION_SLOT_REQUEST || 0 }}</h3>
          <!-- Slot da approvare -->
          <h5 class="white" fxFlexOffset="4px" translate="admin.home.pending_activities.SLOT_TO_APPROVE"></h5>
          <!-- Vedi lista -->
          <welion-button fxFlexOffset="8px" width="120px" type="third white" size="medium"
            label="admin.home.pending_activities.SHOW_LIST" (onClick)="goToPendingSlots()"></welion-button>
        </div>

        <div class="pending-request-divider" fxFlexOffset="52px">
        </div>

        <div fxLayout="column" fxFlexOffset="52px">
          <h3 class="white">{{ infoRequestCount.EVENT_REVOKE_SLOT_REQUEST || 0 }}</h3>
          <!-- Richieste di revoca -->
          <h5 class="white" fxFlexOffset="4px" translate="admin.home.pending_activities.REVOKE_REQUEST"></h5>
          <!-- Vedi lista -->
          <welion-button fxFlexOffset="8px" width="120px" type="third white" size="medium"
            label="admin.home.pending_activities.SHOW_LIST" (onClick)="goToPendingRevokeRequests()"></welion-button>
        </div>

        <div class="pending-request-divider" fxFlexOffset="52px">
        </div>

        <div fxLayout="column" fxFlexOffset="52px">
          <h3 class="white">{{ infoRequestCount.EVENT_DESCRIPTION_UPDATE_REQUEST || 0 }}</h3>
          <!-- Richieste di revoca -->
          <h5 class="white" fxFlexOffset="4px" translate="admin.home.pending_activities.PENDING_DESCRIPTION"></h5>
          <!-- Vedi lista -->
          <welion-button fxFlexOffset="8px" width="120px" type="third white" size="medium"
            label="admin.home.pending_activities.SHOW_LIST" (onClick)="goToPendingDescription()"></welion-button>
        </div>

      </div>

    </div>

    <!-- Slot offert/prenotati, e persone prenotate/presenti -->
    <div *ngIf="allCampaignsSlotsData" fxFlexOffset="44px" fxFlexOffset.xs="24px" fxLayout="row"
      fxLayoutAlign="start center">
      <!-- Icona mondo -->
      <svg-icon [applyClass]="true" src="assets/img/icons/target-world.svg"></svg-icon>

      <div fxFlex fxFlexOffset="20px" fxLayout="row" fxLayout.xs="column" fxLayoutAlign="space-between center"
        fxLayoutAlign.xs="start stretch">
        <div class="slot-number" fxLayout="column">
          <!-- Slot offerti/prenotati -->
          <h2>{{allCampaignsSlotsData.totalOfferedSlotCount}}/{{allCampaignsSlotsData.totalBookedSlotCount}}</h2>
          <h6 fxFlexOffset="12px" class="black">
            {{ 'admin.home.OFFERED_SLOTS_AND_BOOKED' | translate | uppercase }}
          </h6>
        </div>
        <div fxFlexOffset.xs="12px" class="slot-number" fxLayout="column">
          <!-- Persone prenotate / presenti -->
          <h2>{{allCampaignsSlotsData.totalBookedSlotCount}}/{{allCampaignsSlotsData.totalPresentPeopleCount}}</h2>
          <h6 fxFlexOffset="12px" class="black">
            {{ 'admin.home.BOOKED_PERSONS_AND_PRESENTS' | translate | uppercase }}
          </h6>
        </div>
        <div fxFlexOffset.xs="12px" class="slot-number" fxLayout="column">
          <!-- Aventi diritto totali / lavorati -->
          <h2>{{allCampaignsSlotsData.totalBookedSlotCount}}/{{allCampaignsSlotsData.totalPresentPeopleCount}}</h2>
          <h6 fxFlexOffset="12px" class="black">
            {{ 'admin.home.ATTENDANCES_TOTAL_AND_MANAGED' | translate | uppercase }}
          </h6>
        </div>
      </div>

    </div>

    <div fxFlexOffset="40px" fxFlexOffset.xs="12px" class="campaigns-container"
      [ngClass]="{'noRounds': roundsCount === 0}" fxLayout="column">

      <!-- Round Attivi -->
      <div fxLayout="row">
        <h3 translate="admin.home.rounds.TITLE" fxFlexOffset="8px"></h3>
      </div>

      <div *ngIf="isLoading" class="loader spinner-big"></div>

      <ng-container *ngIf="!isLoading">

        <ng-container *ngIf="roundsCount > 0 else noRounds">
          <!-- Table header -->
          <div fxFlexOffset="8px" fxLayout="row" fxLayoutAlign="start center">
            <!-- Data -->
            <h5 translate="admin.home.rounds.DATA" fxFlex="36" fxFlexOffset="8px"></h5>
            <!-- Strutture -->
            <h5 translate="admin.home.rounds.VENUES" fxFlex="15" fxFlexOffset="1"></h5>
            <!-- Slot offerti/prenotati -->
            <h5 translate="admin.home.rounds.OFFERED_SLOTS" fxFlex="20" fxFlexOffset="1"></h5>
            <!-- Persone prenotati/presenti -->
            <h5 translate="admin.home.rounds.PEOPLE_BOOKED" fxFlex></h5>
          </div>

          <!-- Table rows -->
          <div *ngFor="let activeRound of rounds; let first = first; let odd = odd" class="campaign-row"
            [ngClass]="{'odd': odd}" fxLayout="row" fxLayoutAlign="start center" [fxFlexOffset]="first ? '8px' : '0px'">
            <!-- Data -->
            <div fxFlex="36" fxFlexOffset="8px" fxLayout="column">
              <h6 class="black">{{ activeRound.campaignTitle + ' - ' + activeRound.title }}</h6>
              <h6 class="black bold" fxFlexOffset="16px">
                {{ activeRound.startDate | date: 'dd/MM/yyyy' }}
                {{ ' - '}}
                {{ activeRound.endDate | date: 'dd/MM/yyyy' }}
              </h6>
            </div>
            <!-- Strutture -->
            <h6 class="black" fxFlex="15" fxFlexOffset="1">{{ activeRound.venues }}</h6>
            <!-- Slot offerti/prenotati -->
            <h6 class="black" fxFlex="20" fxFlexOffset="1">
              {{ activeRound.offeredSlots + '/' + activeRound.slotsAvailable }}
            </h6>
            <!-- Persone prenotati/presenti -->
            <h6 class="black" fxFlex="21">
              {{ activeRound.bookings + '/' + activeRound.presents }}
            </h6>

            <!-- 2% of margin left -->
            <div fxFlex fxFlexOffset="2" fxLayout="row" fxLayoutAlign="end">
              <svg-icon src="assets/img/icons/arrow-right-red.svg" class="cursor-pointer" [applyClass]="true"
                (click)="onRoundClick(activeRound)">
              </svg-icon>
            </div>
            <div fxFlex="8px"></div>
          </div>

        </ng-container>
      </ng-container>
      <!-- Paginatore -->
      <welion-paginator [isLoading]="isLoading" [paginationLength]="paginationLength" [itemsCount]="roundsCount"
        (onClickPage)="getActiveRounds($event)">
      </welion-paginator>
    </div>

    <div fxLayout="row" fxFlexOffset="48px">

      <!-- Clienti -->
      <div class="campaigns-card" fxLayout="column">

        <div fxFlexOffset="24px" fxFlexOffset.xs="0px" fxLayout="row" fxLayoutAlign="start center">
          <svg-icon class="note" [applyClass]="true" src="assets/img/icons/note-clients.svg"></svg-icon>

          <div fxFlexOffset="120px" fxLayout="column">
            <h3 translate="admin.home.clients.TITLE"></h3>
            <h5 fxFlexOffset="4px" translate="admin.home.clients.DESCRIPTION"></h5>
            <div fxShow fxHide.xs fxLayout="row" fxLayoutAlign="end" fxFlexOffset="12px" fxFlexOffset.xs="20px">
              <ng-container [ngTemplateOutlet]="clients"></ng-container>
            </div>
          </div>
        </div>

        <div fxHide fxShow.xs fxLayout="row" fxLayoutAlign="center" fxFlexOffset="20px">
          <ng-container [ngTemplateOutlet]="clients"></ng-container>
        </div>

      </div>

      <!-- Strutture -->
      <div fxFlexOffset="24px" class="campaigns-card" fxLayout="column">

        <div fxFlexOffset="24px" fxFlexOffset.xs="0px" fxLayout="row" fxLayoutAlign="start center">
          <svg-icon class="note" [applyClass]="true" src="assets/img/icons/note-venues.svg"></svg-icon>

          <div fxFlexOffset="120px" fxLayout="column">
            <h3 translate="admin.home.venues.TITLE"></h3>
            <h5 fxFlexOffset="4px" translate="admin.home.venues.DESCRIPTION"></h5>
            <div fxShow fxHide.xs fxLayout="row" fxLayoutAlign="end" fxFlexOffset="12px" fxFlexOffset.xs="20px">
              <ng-container [ngTemplateOutlet]="venues"></ng-container>
            </div>
          </div>
        </div>

        <div fxHide fxShow.xs fxLayout="row" fxLayoutAlign="center" fxFlexOffset="20px">
          <ng-container [ngTemplateOutlet]="venues"></ng-container>
        </div>

      </div>

    </div>



    <div fxLayout="row" fxFlexOffset="48px">
      <!-- Campagne -->
      <div class="campaigns-card" fxLayout="column">

        <div fxFlexOffset="24px" fxFlexOffset.xs="0px" fxLayout="row" fxLayoutAlign="start center">
          <svg-icon class="note" [applyClass]="true" src="assets/img/icons/note-campaign.svg"></svg-icon>

          <div fxFlexOffset="120px" fxLayout="column">
            <h3 translate="admin.home.campaigns.TITLE"></h3>
            <h5 fxFlexOffset="4px" translate="admin.home.campaigns.DESCRIPTION"></h5>
            <div fxShow fxHide.xs fxLayout="row" fxFlexOffset="12px" fxFlexOffset.xs="20px">
              <ng-container [ngTemplateOutlet]="activeCampaigns"></ng-container>
            </div>
          </div>

          <svg-icon fxShow fxHide.xs class="triangles" src="assets/img/icons/campaign-triangles.svg"></svg-icon>
        </div>

        <div fxHide fxShow.xs fxLayout="row" fxLayoutAlign="center" fxFlexOffset="20px">
          <ng-container [ngTemplateOutlet]="activeCampaigns"></ng-container>
        </div>

      </div>

      <!-- Dashboard -->
      <div class="campaigns-card" fxLayout="column">

        <div fxFlexOffset="24px" fxFlexOffset.xs="0px" fxLayout="row" fxLayoutAlign="start center">
          <svg-icon class="note" [applyClass]="true" src="assets/img/icons/note-campaign.svg"></svg-icon>

          <div fxFlexOffset="120px" fxLayout="column">
            <h3 translate="admin.home.dashboard.TITLE"></h3>
            <h5 fxFlexOffset="4px" translate="admin.home.dashboard.DESCRIPTION"></h5>
            <div fxShow fxHide.xs fxLayout="row" fxFlexOffset="12px" fxFlexOffset.xs="20px">
              <ng-container [ngTemplateOutlet]="activeDashboard"></ng-container>
            </div>
          </div>

          <svg-icon fxShow fxHide.xs class="triangles" src="assets/img/icons/campaign-triangles.svg"></svg-icon>
        </div>

        <div fxHide fxShow.xs fxLayout="row" fxLayoutAlign="center" fxFlexOffset="20px">
          <ng-container [ngTemplateOutlet]="activeDashboard"></ng-container>
        </div>

      </div>

    </div>

  </ng-container>

  <!-- Loader -->
  <div *ngIf="isLoadingBase" class="loader spinner-big"></div>


</div>

<ng-template #activeCampaigns>
  <welion-button (onClick)="goToActiveCampaigns()" type="primary bordless" size="medium"
    label="admin.home.campaigns.ACTION">
  </welion-button>
</ng-template>

<ng-template #activeDashboard>
  <welion-button (onClick)="goToDashboard()" type="primary bordless" size="medium" label="admin.home.dashboard.ACTION">
  </welion-button>
</ng-template>

<ng-template #clients>
  <welion-button (onClick)="goToClients()" type="primary bordless" size="medium" label="admin.home.clients.ACTION">
  </welion-button>
</ng-template>

<ng-template #venues>
  <welion-button (onClick)="goToVenues()" type="primary bordless" size="medium" label="admin.home.venues.ACTION">
  </welion-button>
</ng-template>

<ng-template #noRounds>
  <div fxLayout="column" fxLayoutAlign="start center">
    <svg-icon fxFlexOffset="40px" [applyClass]="true" src="assets/img/icons/note-health-facility-red.svg"></svg-icon>

    <!-- Non sono presenti round attivi -->
    <h4 fxFlexOffset="32px" translate="admin.home.rounds.NO_ROUNDS"></h4>

  </div>
</ng-template>
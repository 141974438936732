import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'welion-minimal-input',
  templateUrl: 'welion-minimal-input.component.html',
  styleUrls: ['./welion-minimal-input.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class WelionMinimalInputComponent implements OnInit {

  _id?: string;
  @Input() id?: string | number;
  @Input() disabled: boolean = false;
  @Input() ariaLabelledbyLabel?: string = '';
  @Input() ariaLabelledbyId?: string = '';
  @Input() required: boolean =false;

  _tabIndex: string = "0";
  @Input() set tabIndexValue(value: string) {
    if (value) {
      this._tabIndex = value;
      if (this._id) {
        const element = document.getElementById(this._id);
        if (element) {
          element.setAttribute("tabindex", value);
        }
      }
    }
  };

  _inputValue: FormControl = new FormControl();
  @Input() set inputValue(value: FormControl | undefined) {
    if (value) {
      this._inputValue = value;
    }
  };

  _type: string = 'text';
  @Input() set type(value: string) {
    this._type = value || 'text';
  }

  @Input() typeInput: string = 'text';

  @Input() placeholder: string = '';
  @Input() text: string = '';
  @Input() errorText?: string;
  @Output('onClick') onIconClicked: EventEmitter<any> = new EventEmitter();

  constructor() { }

  ngOnInit() {
    if (!this.id) {
      this.id = Math.floor((Math.random() * 10000) + 1);
    }
    this._id = "welion-minimal-input-" + this.id;
  }

  emitOnIconClicked(data?: any) {
    if (this.onIconClicked && this.onIconClicked instanceof EventEmitter) {
      this.onIconClicked.emit(data);
    }
  }
}

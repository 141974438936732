import { FormControl, FormGroup, Validators } from "@angular/forms";
import { DatePipe } from "@angular/common";
import { ActivatedRoute } from '@angular/router';
import { ChangeDetectorRef, Component, OnDestroy, OnInit, QueryList, ViewChildren } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { Store } from "@ngrx/store";

import { InfoRound, InfoVenue } from "atfcore-commonclasses/bin/classes/campaignmanager";
import { SenecaResponse } from "atfcore-commonclasses/bin/classes/common";

import * as fromApp from "../../ngrx/app.reducers";
import * as CoreActions from "../../core/ngrx/core.actions";

import { RedirectService } from 'src/app/shared/services/redirect.service';
import { getWarningModal } from "src/app/utils/utils";
import { ScrollTo } from "src/app/core/services/scroll-to.service";
import { DropdownItem } from "src/app/shared/models/dropdown.model";
import { RentService, SlotInfoDate } from "../services/rent.service";
import { ModalService } from "src/app/shared/components/modal/modal.service";

export interface SlotInfo {
  duration: number,
  from: Date,
  to: Date,
  seats: number
}

@Component({
  selector: 'app-add-slots',
  templateUrl: './addSlots.component.html',
  styleUrls: ['./addSlots.component.scss']
})
export class AddSlotsComponent implements OnInit, OnDestroy {

  roundId: string = '';
  venueId: string = '';

  round?: InfoRound;
  venue?: InfoVenue;
  isLoadingAttendances = true;

  private _unsubscribeSignal$: Subject<void> = new Subject();

  durations: DropdownItem[] = [
    { id: '1', name: '5 minuti' },
    { id: '2', name: '10 minuti' }
  ];

  slotInfos: SlotInfo[] = [];

  isCreatingNewSlots: boolean = false;

  slotInfoForm = new FormGroup({
    'slotDate': new FormControl(undefined, Validators.required),
    'duration': new FormControl(this.durations[0]),
    'from': new FormControl('09:00', [Validators.required, Validators.pattern('([0-1]?[0-9]|2[0-3]):[0-5][0-9]$')]),
    'to': new FormControl('10:00', [Validators.required, Validators.pattern('([0-1]?[0-9]|2[0-3]):[0-5][0-9]$')]),
    'seats': new FormControl(1, [Validators.required, Validators.pattern('[0-9]*'), Validators.min(1)])
  });

  get slotDate() {
    return this.slotInfoForm.get('slotDate') as FormControl;
  }

  get duration() {
    return this.slotInfoForm.get('duration') as FormControl;
  }

  get from() {
    return this.slotInfoForm.get('from') as FormControl;
  }

  get to() {
    return this.slotInfoForm.get('to') as FormControl;
  }

  get seats() {
    return this.slotInfoForm.get('seats') as FormControl;
  }

  minRoundDate?: Date;
  maxRoundDate?: Date;

  @ViewChildren('addSlotsList') addSlotsList?: QueryList<any>;

  constructor(
    private route: ActivatedRoute,
    private appStore: Store<fromApp.AppState>,
    private translate: TranslateService,
    private rentService: RentService,
    private redirectService: RedirectService,
    private scrollTo: ScrollTo,
    private datePipe: DatePipe,
    private cdref: ChangeDetectorRef,
    private modalService: ModalService
  ) { }

  //#region Inizializzazione round

  ngOnInit() {
    this.scrollTo.header();
    const roundId = this.route.snapshot.paramMap.get('roundId');
    const venueId = this.route.snapshot.paramMap.get('venueId');

    if (!roundId || !venueId) {
      this.scrollTo.header();
      this.redirectService.goToHome();
    } else {
      this.roundId = roundId;
      this.venueId = venueId;
      this.getRound();
      this.getVenue();
    }
  }

  ngOnDestroy() {
    this._unsubscribeSignal$.next();
    this._unsubscribeSignal$.unsubscribe();
  }

  getVenue() {
    this.rentService.getVenueById(this.venueId)
      .pipe(takeUntil(this._unsubscribeSignal$.asObservable()))
      .subscribe(
        (result: SenecaResponse<InfoVenue>) => {
          if (result && result.error) {
            this.dispatchWarningModal('010', result.error);
          } else {
            this.venue = result.response;
          }
        }, (err) => {
          if (err && err.message) {
            this.dispatchWarningModal('011', err.message);
          }
        });
  }

  /**
   * Recupera i dati del round sulla base dell'id
   * @param roundId
   */
  getRound() {
    this.rentService.getRound(this.roundId)
      .pipe(takeUntil(this._unsubscribeSignal$.asObservable()))
      .subscribe(
        (result: SenecaResponse<InfoRound>) => {
          if (result && result.error) {
            this.dispatchWarningModal('010', result.error);
          } else {
            this.round = result.response;
            if (this.round && this.round.startDate) {
              this.minRoundDate = new Date(this.round.startDate);
              this.minRoundDate.setHours(0, 0, 0, 0);
            }
            if (this.round && this.round.endDate) {
              this.maxRoundDate = new Date(this.round.endDate);
              this.maxRoundDate.setHours(23, 59, 59, 0);
            }
          }
        }, (err) => {
          if (err && err.message) {
            this.dispatchWarningModal('011', err.message);
          }
        });
  }

  //#endregion

  //#region Funzioni generali

  /**
   * @description Va all'homepage
   */
  goBack() {
    this.redirectService.goToRoundSlots(this.roundId);
  }

  goToFAQ() {

  }

  /**
   * @description Mostra una modale con un messaggio di errore
   * @param modalId
   * @param error
   */
  dispatchWarningModal(modalId: string, error: string) {
    const messageObj = getWarningModal(this.translate, modalId, error);
    this.appStore.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
  }

  onChangeDuration(value: DropdownItem) {
    this.duration.setValue(value);
  }

  deleteSlot(index: number) {
    this.slotInfos.splice(index, 1);
  }

  onDateChange(date: Date) {
    date = new Date(date);
    date.setHours(0, 0, 0, 0);
    this.slotDate.setValue(date);
  }

  resetSlotInfoForm() {
    this.duration.setValue(this.durations[0]);
    this.from.setValue('09:00');
    this.to.setValue('10:00');
    this.seats.setValue(1);
  }

  addSlots() {
    if (this.slotInfoForm.valid) {

      this.isCreatingNewSlots = true;

      let from = new Date(this.getFromDate());
      let to = new Date(this.getToDate());

      if (from.getTime() >= to.getTime()) {
        this.dispatchWarningModal('018', 'TIME_ERROR');
        return;
      }

      if (this.addSlotsList) {
        this.addSlotsList.changes
          .pipe(takeUntil(this._unsubscribeSignal$.asObservable()))
          .subscribe(t => {
            this.isCreatingNewSlots = false;
            this.cdref.detectChanges();
          });
      }

      let timeout = setTimeout(() => {

        clearTimeout(timeout);

        // 5 o 10 minuti
        const minutesToAdd = this.duration.value.id === '1' ? 300000 : 600000;

        for (let date = from; date.getTime() < to.getTime(); date = new Date(date.getTime() + minutesToAdd)) {

          this.slotInfos.push({
            duration: (minutesToAdd / 60000),
            from: date,
            to: new Date(date.getTime() + minutesToAdd),
            seats: parseInt(this.seats.value)
          });
        }

        this.slotInfos.sort((a, b) => a.from.getTime() - b.from.getTime());

        const lastInfo = this.slotInfos[this.slotInfos.length - 1];
        const newFrom = new Date(lastInfo.to.getTime());

        let newFromHours = newFrom.getHours().toString();
        if (newFromHours.length === 1) {
          newFromHours = '0' + newFromHours;
        }

        let newFromMinutes = newFrom.getMinutes().toString();
        if (newFromMinutes.length === 1) {
          newFromMinutes = '0' + newFromMinutes;
        }

        const newFromString = newFromHours + ':' + newFromMinutes;
        this.from.setValue(newFromString);

        const newTo = new Date(newFrom.getTime() + minutesToAdd);
        let newToHours = newTo.getHours().toString();
        if (newToHours.length === 1) {
          newToHours = '0' + newToHours;
        }

        let newToMinutes = newTo.getMinutes().toString();
        if (newToMinutes.length === 1) {
          newToMinutes = '0' + newToMinutes;
        }

        const newToString = newToHours + ':' + newToMinutes;

        this.to.setValue(newToString);
      }, 100);

    }
  }

  getFromDate(): Date {
    let from = new Date();
    if (this.slotDate.valid) {
      from = this.slotDate.value;
    }
    const fromHours = this.getHoursByString(this.from.value);
    const fromMinutes = this.getMinutesByString(this.from.value);
    from.setHours(fromHours, fromMinutes, 0, 0);
    return from;
  }

  getToDate(): Date {
    let to = new Date();
    if (this.slotDate.valid) {
      to = this.slotDate.value;
    }
    const toHours = this.getHoursByString(this.to.value);
    const toMinutes = this.getMinutesByString(this.to.value);
    to.setHours(toHours, toMinutes, 0, 0);
    return to;
  }

  //#endregion

  getDateByString(text: string) {
    let date = new Date();
    return date.setHours(this.getHoursByString(text), this.getMinutesByString(text), 0, 0);
  }

  getHoursByString(text: string) {
    return parseInt(text.slice(0, 2));
  }

  getMinutesByString(text: string) {
    return parseInt(text.slice(3, 5));
  }

  openAddSlotsModal() {
    this.modalService.open('addSlots');
  }

  closeAddSlotsModal(complete?: boolean) {
    if (complete) {
      this.createSlots();
    } else {
      this.modalService.close('addSlots');
    }
  }

  createSlots() {

    let dateSlots: SlotInfoDate[] = [];

    this.slotInfos.forEach(slotInfo => {
      for (let i = 0; i < slotInfo.seats; i++) {
        dateSlots.push({
          startDate: slotInfo.from.toISOString(),
          endDate: slotInfo.to.toISOString()
        });
      }
    });

    this.rentService.createSlots(this.roundId, this.venueId, dateSlots)
      .pipe(takeUntil(this._unsubscribeSignal$.asObservable()))
      .subscribe(
        (result: SenecaResponse<boolean>) => {
          this.closeAddSlotsModal();
          if (result && result.error) {
            this.dispatchWarningModal('019', result.error);
          } else {
            this.redirectService.goToRoundSlots(this.roundId);
          }
        }, (err) => {
          if (err && err.message) {
            this.closeAddSlotsModal();
            this.dispatchWarningModal('020', err.message);
          }
        });
  }

  getTimeFromDate(date: Date): string {
    return this.datePipe.transform(date, 'HH:mm') || '';
  }

}

<div *ngIf="langs && langs.length">
  <div class="main-content">
    <div class="page-login-content" fxLayout="column" fxLayoutAlign="center center">

      <main fxLayout="row" fxFill fxLayoutAlign="center">
        <form *ngIf="!showLoader" class="login-wrapper" fxLayout="column" fxLayoutAlign="start" [formGroup]="loginForm"
          (ngSubmit)="resetPasswordMode ? onResetPassword() : onLogin()">

          <!-- Logo -->
          <div fxLayout="row" fxLayoutAlign="center">
            <svg-icon src="assets/img/icons/app-logo.svg" class="login-icon" [applyClass]="true"></svg-icon>
          </div>

          <div fxLayout="row" fxLayoutAlign="center">
            <h1 class="title-login">Accedi al portale</h1>
          </div>

          <ng-container *ngIf="!resetPasswordSuccess && !resetPasswordMode">
            <!-- Email o Username -->
            <welion-minimal-input [ariaLabelledbyLabel]="'username'" id="username" fxFlexOffset="36px" autocomplete="on"
              [inputValue]="email" typeInput="email" [placeholder]="'login.MAIL_PLACEHOLDER' | translate"
              [text]="'login.MAIL' | translate" [errorText]="'login.EMAIL_ERROR_INSERTED' | translate"
              [required]="true">
            </welion-minimal-input>

            <ng-container *ngIf="!showResetPasswordForm">

              <!-- Password -->
              <welion-minimal-input [ariaLabelledbyLabel]="'password'" id="password" fxFlexOffset="36px"
                autocomplete="on" [inputValue]="password" typeInput="password"
                [placeholder]="'login.PASSWORD_PLACEHOLDER' | translate" [text]="'login.PASSWORD' | translate"
                [required]="true">
              </welion-minimal-input>

              <!-- Reset password -->
              <div *ngIf="showResetPassword" fxFlexOffset="16px" fxLayout="row" (click)="onPasswordForgotClick()"
                tabindex="0" clickKeyBoard (onEnterKeyboard)="onPasswordForgotClick()" role="button"
                attr.aria-label="{{'login.PASSWORD_FORGOT' | translate}}">
                <h6 class="black forgot" translate="login.PASSWORD_FORGOT"></h6>
              </div>

            </ng-container>
            <!-- Captcha solo in cert e prod -->
            <ng-container *ngIf="!isLocalhost">
              <div class="captcha">
                <re-captcha (resolved)="chaptchaSolved($event)" [siteKey]="siteKey"></re-captcha>
              </div>
            </ng-container>

            <!-- Login/Invia -->
            <div [fxFlexOffset]="isUser ? '' : '40px'" fxLayout="row" fxLayoutAlign="center">
              <welion-button width="194px" [disabled]="loginForm.invalid || (!isLocalhost && !canLogIn)" type="primary"
                size="large" [label]="('login.' + (showResetPasswordForm ? 'SEND' : 'SIGN_IN'))"
                buttonType="submit"></welion-button>
            </div>

            <!-- Informativa privacy -->
            <div *ngIf="isUser" fxFlexOffset="8px" fxLayout="row" fxLayoutAlign="center" tabindex="0"
              (click)="openPrivacyPage()" clickKeyBoard (onEnterKeyboard)="openPrivacyPage()" role="button"
              attr.aria-label="{{'privacy.TITLE' | translate}}">
              <h6 class="forgot" translate="privacy.TITLE"></h6>
            </div>
          </ng-container>

          <ng-container *ngIf="resetPasswordMode">

            <!-- Password -->
            <welion-minimal-input [ariaLabelledbyLabel]="'password'" id="password" [required]="true" fxFlexOffset="36px"
              [inputValue]="password" typeInput="password" [placeholder]="'login.PASSWORD_PLACEHOLDER' | translate"
              [text]="'login.PASSWORD' | translate">
            </welion-minimal-input>

            <h6 fxFlexOffset="8px" class="small description" translate="signin.PSW_DESC"></h6>

            <ng-container *ngIf="password.dirty">
              <!-- Password troppo corta -->
              <h6 *ngIf="password.hasError('minlength')" fxFlexOffset="8px" class="small red"
                translate="signin.PSW_ERROR_LENGTH"></h6>

              <!-- Password troppo lunga -->
              <h6 *ngIf="password.hasError('maxlength')" fxFlexOffset="8px" class="small red"
                translate="signin.PSW_ERROR_MAX_LENGTH"></h6>

              <ng-container *ngIf="!password.hasError('minlength')">
                <!-- Carattere minuscolo mancante -->
                <h6 *ngIf="password.hasError('lowerCaseMiss')" fxFlexOffset="8px" class="small red"
                  translate="signin.PSW_ERROR_LOW"></h6>
                <!-- Carattere maiuscolo mancante -->
                <h6 *ngIf="password.hasError('upperCaseMiss')" fxFlexOffset="8px" class="small red"
                  translate="signin.PSW_ERROR_UPP"></h6>
                <!-- Numero mancante -->
                <h6 *ngIf="password.hasError('numberMiss')" fxFlexOffset="8px" class="small red"
                  translate="signin.PSW_ERROR_NUM"></h6>
                <ng-container *ngIf="password.hasError('specMiss')">
                  <!-- Carattere speciale mancante -->
                  <h6 fxFlexOffset="8px" class="small red" translate="signin.PSW_ERROR_SPEC"></h6>
                  <h6 fxFlexOffset="8px" class="small red" translate="signin.PSW_ERROR_DESC_SPECIAL"></h6>
                  <h6 fxFlexOffset="8px" class="small red" translate="signin.PSW_ERROR_DESC_SPECIAL_DETAILS"></h6>
                </ng-container>
              </ng-container>
            </ng-container>

            <!-- Conferma password -->
            <welion-minimal-input [ariaLabelledbyLabel]="'confirm-password'" id="confirm-password" [required]="true"
              fxFlexOffset="12px" [inputValue]="passwordConfirm" typeInput="password"
              [placeholder]="'login.PASSWORD_PLACEHOLDER' | translate" [text]="'login.PASSWORD_CONFIRM' | translate">
            </welion-minimal-input>

            <h6 lass="small red" fxFlexOffset="8px"
              *ngIf="(password.valid || passwordConfirm.dirty) && passwordConfirm.hasError('passwordNotMatch')"
              translate="errors.PASSWORD_NOT_MATCH"></h6>

            <div fxFlexOffset="20px" fxLayout="row" fxLayoutAlign="center">
              <welion-button width="194px" [disabled]="loginForm.invalid" type="primary" size="large"
                label="generic.CONFIRM" buttonType="submit"></welion-button>
            </div>

          </ng-container>

          <!-- Hai resettato con successo la password, ... -->
          <h4 *ngIf="resetPasswordSuccess" fxFlexOffset="60px" translate="login.CHECK_YOUR_EMAIL" class="center"></h4>

        </form>
      </main>

      <aside *ngIf="!showLoader" class="support-align-right" role="complementary"
        attr.aria-label="{{'generic.FAQ_SUPPORT' | translate}}">
        <div fxLayoutAlign="center center" fxLayout="column" class="faq-support-container"
          (click)="redirectService.goToPublicFAQPage()" tabindex="0" role="button" clickKeyBoard
          (onEnterKeyboard)="redirectService.goToPublicFAQPage()"
          attr.aria-label="{{'generic.FAQ_SUPPORT' | translate}}" aria-labelledby="faq-text">
          <svg-icon src="assets/img/icons/faq-support.svg"></svg-icon>
          <p id="faq-text" [innerHTML]="'generic.FAQ_SUPPORT' | translate"></p>
        </div>
      </aside>

      <ng-container *ngIf="showLoader">
        <div class="loader spinner-big"></div>
      </ng-container>
    </div>
  </div>
</div>

<!-- Nessuna lingua disponibile a sistema -->
<div *ngIf="!langs || !langs.length">
  <p translate="errors.NO_AVAILABLE_LANG"></p>
</div>
import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';

import * as CoreActions from "../../../core/ngrx/core.actions";
import * as fromApp from "../../../ngrx/app.reducers";

import { RedirectService } from 'src/app/shared/services/redirect.service';
import { getModalMessageData, getWarningModal } from 'src/app/utils/utils';
import { ScrollTo } from 'src/app/core/services/scroll-to.service';
import { RentService } from 'src/app/structure/services/rent.service';
import { EngagementService, InfoRequest } from '../../services/engagement.service';
import { SenecaResponse } from 'atfcore-commonclasses/bin/classes/common';
import { DaySlots, InfoSlot } from 'atfcore-commonclasses/bin/classes/campaignmanager';
import { DropdownItem } from 'src/app/shared/models/dropdown.model';
import { DatePipe, TitleCasePipe } from '@angular/common';
import { ModalService } from 'src/app/shared/components/modal/modal.service';
import { FormControl, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-admin-campaign-pending-request',
  templateUrl: './campaignPendingRequest.component.html',
  styleUrls: ['./campaignPendingRequest.component.scss']
})
export class AdminCampaignPendingRequestComponent implements OnInit, OnDestroy {

  requestId: string = '';
  venueId: string = '';
  roundId: string = '';

  request?: InfoRequest;

  isLoading: boolean = true;
  isLoadingBase: boolean = true;

  slots: InfoSlot[] = [];
  selectedSlots: InfoSlot[] = [];
  private _isEndSlotsLoading: boolean = false;

  structureDaySlots: DaySlots[] = [];

  // Dropdown for mobile
  structureDaySlotsDropdown: DropdownItem[] = [];
  selectedStructureDaySlotsDropdown?: DropdownItem;

  indexSelectedStructureDaySlots: number = 0;

  private _firstLoad: boolean = true;

  private _unsubscribeSignal$: Subject<void> = new Subject();

  get isApproveSlot(): boolean {
    return this.request && this.request.requestType === 'EVENT_APPROVATION_SLOT_REQUEST' ? true : false;
  }

  get isRevokeSlot(): boolean {
    return this.request && this.request.requestType === 'EVENT_REVOKE_SLOT_REQUEST' ? true : false;
  }

  get isApproveDescription(): boolean {
    return this.request && this.request.requestType === 'EVENT_DESCRIPTION_UPDATE_REQUEST' ? true : false;
  }

  rejectForm: FormGroup = new FormGroup({
    'rejectNote': new FormControl(undefined, [Validators.required, Validators.maxLength(200)])
  });

  get rejectNote(): FormControl {
    return this.rejectForm.get('rejectNote') as FormControl;
  }

  isLimitedAdmin: boolean = false;

  constructor(
    private route: ActivatedRoute,
    private scrollTo: ScrollTo,
    private appStore: Store<fromApp.AppState>,
    private translate: TranslateService,
    private rentService: RentService,
    private engagementService: EngagementService,
    private redirectService: RedirectService,
    private datePipe: DatePipe,
    private titleCasePipe: TitleCasePipe,
    private modalService: ModalService
  ) {

  }

  ngOnInit() {
    this.scrollTo.header();
    this.requestId = this.route.snapshot.paramMap.get('requestId') || '';
    this.venueId = this.route.snapshot.paramMap.get('venueId') || '';
    this.roundId = this.route.snapshot.paramMap.get('roundId') || '';

    if (this.venueId && this.requestId) {
      this.appStore.select(fromApp.getIsLimitedAdmin)
        .pipe(takeUntil(this._unsubscribeSignal$.asObservable()))
        .subscribe(
          (isLimitedAdmin) => {
            this.isLimitedAdmin = isLimitedAdmin;
          });

      this.getVenueRequest();
    } else {
      this.redirectService.goToHome();
    }
  }

  ngOnDestroy() {
    this._unsubscribeSignal$.next();
    this._unsubscribeSignal$.unsubscribe();
  }

  /**
   * @description Mostra una modale con titolo e messaggio
   * @param modalId
   * @param title
   * @param message
   */
  dispatchMessageModal(title: string, message: string) {
    const messageObj = getModalMessageData(this.translate, title, message);
    this.appStore.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
  }

  /**
   * @description Mostra una modale con un messaggio di errore
   * @param modalId
   * @param error
   */
  dispatchModal(modalId: string, error: string) {
    const messageObj = getWarningModal(this.translate, modalId, error);
    this.appStore.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
  }

  getVenueRequest() {

    this.isLoading = true;

    this.engagementService.getVenueRequestsToProcessForAdmin(this.requestId, this.venueId)
      .pipe(takeUntil(this._unsubscribeSignal$.asObservable()))
      .subscribe((result) => {

        if (!result || result.error) {
          this.dispatchModal('102', result.error || '');
          this.isLoading = false;
        } else {
          this.request = result.response[0];
          if (this.isApproveSlot || this.isRevokeSlot) {
            this.getVenueDaysSlots();
          } else {
            this.isLoading = false;
          }
        }
        this.isLoadingBase = false;
      }, (err) => {
        if (err && err.message) {
          this.dispatchModal('103', err.message);
        }
        this.isLoading = false;
        this.isLoadingBase = false;
      });
  }

  //#region Slots

  /**
   * @description Alla selezione di una giornata da schemata mobile
   * @param item
   */
  onSelectDaySlotMobile(item: DropdownItem) {
    this.onSelectDaySlot(Number.parseInt(item.id));
  }

  /**
   * @description Richiamato in caso di scroll o di selezione di una giornata
   * @param firstCall Se richiamato dalla selezione di una giornata
   */
  getDaySlots(firstCall?: boolean) {

    if (this.structureDaySlots && (!this.isLoading || firstCall) && !this._isEndSlotsLoading) {

      this.isLoading = true;

      this.rentService.getVenueToProcessByRequestIdForAdmin(
        this.venueId,
        this.requestId,
        false,
        this.structureDaySlots[this.indexSelectedStructureDaySlots].date,
        this.slots.length,
        96)
        .pipe(takeUntil(this._unsubscribeSignal$.asObservable()))
        .subscribe(
          (result: SenecaResponse<DaySlots[]>) => {
            const response = result.response;

            if (result && result.error) {
              this.dispatchModal('023', result.error);
              this._isEndSlotsLoading = true;
            } else {

              const slots = response.length ? response[0].slots : [];
              if (slots && slots.length) {
                this.slots = this.slots.concat(slots);

                if (slots.length < 96) {
                  this._isEndSlotsLoading = true;
                }

              } else {
                this._isEndSlotsLoading = true;
              }
            }

            this.isLoading = false;
          }, (err) => {
            if (err && err.message) {
              this.dispatchModal('024', err.message);
            }
            this._isEndSlotsLoading = true;
            this.isLoading = false;
          });
    }
  }

  /**
   * @param date Data da trasformare
   * @returns Stringa con formato Lunedì 1 Marzo
   */
  getDateForSelect(date: Date): string {
    return this.titleCasePipe.transform(this.datePipe.transform(date, 'EEEE dd MMMM') || '');
  }

  /**
  * @description Mostra l'helper delle info sugli slots
  */
  showHelper() {

  }

  /**
   * @description Chiamata alla selezione di una giornata
   * @param indexDaySlot
   */
  onSelectDaySlot(indexDaySlot: number) {
    this.isLoading = true;
    this.indexSelectedStructureDaySlots = indexDaySlot;
    this.selectedStructureDaySlotsDropdown = this.structureDaySlotsDropdown[indexDaySlot];
    this.slots = [];
    this._isEndSlotsLoading = false;
    this.getDaySlots(true);
    if (!this._firstLoad) {
      this.scrollTo.elementWithDelay('admin-campaign-pending-anchor');
    } else {
      this._firstLoad = false;
    }
  }

  /**
   * @description Recupera gli slots di una struttura suddivisi per giorni
   */
  getVenueDaysSlots() {

    this.indexSelectedStructureDaySlots = 0;
    this.structureDaySlots = [];

    this.structureDaySlotsDropdown = [];
    this.selectedStructureDaySlotsDropdown = undefined;

    this.rentService.getVenueToProcessByRequestIdForAdmin(this.venueId, this.requestId, true)
      .pipe(takeUntil(this._unsubscribeSignal$.asObservable()))
      .subscribe(
        (result: SenecaResponse<DaySlots[]>) => {
          if (result && result.error) {
            this.dispatchModal('008', result.error);
            this.isLoading = false;
            this._isEndSlotsLoading = true;
          } else {
            this.structureDaySlots = result.response;

            if (this.structureDaySlots && this.structureDaySlots.length) {
              // Per la select mobile
              this.structureDaySlots.forEach((daySlot, index) => {
                this.structureDaySlotsDropdown.push({
                  id: index.toString(),
                  name: this.getDateForSelect(daySlot.date)
                });
              });
              this.onSelectDaySlot(0);
            } else {
              this.isLoading = false;
              this._isEndSlotsLoading = true;
            }
          }
        }, (err) => {
          if (err && err.message) {
            this.dispatchModal('009', err.message);
          }
          this.isLoading = false;
          this._isEndSlotsLoading = true;
        });
  }

  /**
   * @description Chiamata alla selezione di uno slot
   * @param index
   */
  selectSlotClick(index: number) {
    if (this.isLimitedAdmin) {
      return;
    }
    const slot = this.slots[index];
    if ((this.isApproveSlot && slot.status === 'APPROVATION_REQUEST') || (this.isRevokeSlot && slot.status === 'REVOKE_REQUEST')) {
      const selectedIndex = this.selectedSlots.findIndex(x => x.slotId === this.slots[index].slotId);
      if (selectedIndex === -1) {
        // Slot da aggiungere
        this.selectedSlots.push(this.slots[index]);
      } else {
        // Slot da rimuovere
        this.selectedSlots.splice(selectedIndex, 1);
      }
    }
  }

  /**
   * @param currentSlot
   * @returns True se lo slot è stato selezionato
   */
  slotIsSelected(currentSlot: InfoSlot): boolean {
    return this.selectedSlots.filter(x => x.slotId === currentSlot.slotId).length > 0;
  }

  //#endregion Slots

  revokeSlots(confirm: boolean, deny: boolean, slotsIds?: string[], note?: string) {
    this.isLoading = true;

    this.rentService.processSlotsRevocationRequestByRequestIdForAdmin(this.requestId, confirm, deny, slotsIds, note)
      .pipe(takeUntil(this._unsubscribeSignal$.asObservable()))
      .subscribe((result) => {
        this.closeRejectSelectedRequestModal();
        this.closeRejectAllRequestModal();
        if (!result || result.error) {
          this.dispatchModal('102', result.error || '');
        } else {
          this.goBackToPendingRequests();
        }
        this.isLoading = false;
      }, (err) => {
        this.closeRejectSelectedRequestModal();
        this.closeRejectAllRequestModal();
        if (err && err.message) {
          this.dispatchModal('103', err.message);
        }
        this.isLoading = false;
      });
  }

  approveSlots(confirm: boolean, deny: boolean, slotsIds?: string[], note?: string) {
    this.isLoading = true;

    this.rentService.processSlotsApprovationRequestByRequestIdForAdmin(this.requestId, confirm, deny, slotsIds, note)
      .pipe(takeUntil(this._unsubscribeSignal$.asObservable()))
      .subscribe((result) => {
        this.closeRejectSelectedRequestModal();
        this.closeRejectAllRequestModal();
        if (!result || result.error) {
          this.dispatchModal('102', result.error || '');
        } else {
          this.goBackToPendingRequests();
        }
        this.isLoading = false;
      }, (err) => {
        if (err && err.message) {
          this.closeRejectSelectedRequestModal();
          this.closeRejectAllRequestModal();
          this.dispatchModal('103', err.message);
        }
        this.isLoading = false;
      });
  }

  openRejectAllRequestModal() {
    this.modalService.open('rejectAllRequest');
  }

  closeRejectAllRequestModal() {
    this.rejectNote.setValue(undefined);
    this.modalService.close('rejectAllRequest');
  }

  confirmRejectAll() {
    if (this.rejectForm.valid) {
      if (this.isRevokeSlot) {
        this.revokeSlots(false, true, undefined, this.rejectNote.value);
      } else if (this.isApproveSlot) {
        this.approveSlots(false, true, undefined, this.rejectNote.value);
      }
    }
  }

  confirmAll() {
    if (this.isRevokeSlot) {
      this.revokeSlots(true, false);
    } else if (this.isApproveSlot) {
      this.approveSlots(true, false);
    }
  }

  confirmSelected() {

    if (this.selectedSlots.length > 0) {

      this.isLoading = true;

      let slotsIds: string[] = this.selectedSlots.map(x => x.slotId);

      if (this.isRevokeSlot) {
        this.revokeSlots(true, false, slotsIds);
      } else if (this.isApproveSlot) {
        this.approveSlots(true, false, slotsIds);
      }
    }
  }

  openRejectSelectedRequestModal() {
    if (this.selectedSlots.length > 0) {
      this.modalService.open('rejectSelectedRequest');
    }
  }

  closeRejectSelectedRequestModal() {
    this.rejectNote.setValue(undefined);
    this.modalService.close('rejectSelectedRequest');
  }

  confirmRejectSelected() {

    if (this.selectedSlots.length > 0 && this.rejectForm.valid) {

      this.isLoading = true;

      let slotsIds: string[] = this.selectedSlots.map(x => x.slotId);

      if (this.isRevokeSlot) {
        this.revokeSlots(false, true, slotsIds, this.rejectNote.value);
      } else if (this.isApproveSlot) {
        this.approveSlots(false, true, slotsIds, this.rejectNote.value);
      }
    }
  }

  goBackToPendingRequests() {
    if (this.isApproveSlot) {
      if (this.request && this.roundId) {
        this.redirectService.goToAdminPendingRequestsToApprove(this.request.campaignId, this.roundId, this.venueId);
      } else {
        this.redirectService.goToAdminPendingRequestsToApprove();
      }
    } else if (this.isRevokeSlot) {
      if (this.request && this.roundId) {
        this.redirectService.goToAdminPendingRequestsToRevoke(this.request.campaignId, this.roundId, this.venueId);
      } else {
        this.redirectService.goToAdminPendingRequestsToRevoke();
      }
    } else {
      if (this.request && this.roundId) {
        this.redirectService.goToAdminPendingRequestsDescriptions(this.request.campaignId, this.roundId, this.venueId);
      } else {
        this.redirectService.goToAdminPendingRequestsDescriptions();
      }
    }
  }

  approveVenueDescription() {
    this.isLoading = true;

    this.rentService.confirmDescriptionRequestByRequestIdForAdmin(this.requestId)
      .pipe(takeUntil(this._unsubscribeSignal$.asObservable()))
      .subscribe((result) => {
        if (!result || result.error) {
          this.dispatchModal('102', result.error || '');
        } else {
          this.goBackToPendingRequests();
        }
        this.isLoading = false;
      }, (err) => {
        if (err && err.message) {
          this.dispatchModal('103', err.message);
        }
        this.isLoading = false;
      });
  }

  rejectVenueDescription() {
    this.isLoading = true;

    this.rentService.denyDescriptionRequestByRequestIdForAdmin(this.requestId)
      .pipe(takeUntil(this._unsubscribeSignal$.asObservable()))
      .subscribe((result) => {
        if (!result || result.error) {
          this.dispatchModal('102', result.error || '');
        } else {
          this.goBackToPendingRequests();
        }
        this.isLoading = false;
      }, (err) => {
        if (err && err.message) {
          this.dispatchModal('103', err.message);
        }
        this.isLoading = false;
      });
  }

}

import { ActivatedRoute } from "@angular/router";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { Component, OnDestroy, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Observable, Subject, combineLatest } from 'rxjs';
import { Store } from "@ngrx/store";
import { takeUntil } from "rxjs/operators";

import * as CoreActions from "../../core/ngrx/core.actions";
import * as fromApp from "../../ngrx/app.reducers";
import * as AuthActions from "../../auth/ngrx/auth.actions";

import { RedirectService } from 'src/app/shared/services/redirect.service';
import { checkUnderCustomYearsOld, getBirthDateFromCF, getWarningModal } from "src/app/utils/utils";
import { ScrollTo } from "src/app/core/services/scroll-to.service";
import { SenecaResponse } from "atfcore-commonclasses/bin/classes/common";
import { RentService } from "src/app/structure/services/rent.service";
import { InfoCampaign, InfoRound, JwtPayload, User, UserAcknowledges } from "src/commonclasses";
import { DropdownItem } from "src/app/shared/models/dropdown.model";
import { ModalService } from "src/app/shared/components/modal/modal.service";
import { AnagService } from "src/app/auth/services/anag.service";
import * as moment from "moment";

@Component({
  selector: 'app-user-add-familiar',
  templateUrl: './addFamiliar.component.html',
  styleUrls: ['./addFamiliar.component.scss']
})
export class UserAddFamiliarComponent implements OnInit, OnDestroy {
  isLoading: boolean = false;

  campaignId: string = '';
  roundId: string = '';
  campaigns: InfoCampaign[] = [];
  campaign?: InfoCampaign;
  familiarParentelOptions: DropdownItem[] = [];
  privacyCheck: boolean = false;
  isUnderageModal: boolean = false;
  allowedFamiliars: string = '';

  addFamiliarForm = new FormGroup({
    'forename': new FormControl(undefined, Validators.required),
    'surname': new FormControl(undefined, Validators.required),
    'fiscalCode': new FormControl(undefined, { validators: [Validators.required, Validators.minLength(16)] }),
    'selectedFamiliarMember': new FormControl(undefined, Validators.required)
  });

  get forename(): FormControl {
    return this.addFamiliarForm.get('forename') as FormControl;
  }

  get surname(): FormControl {
    return this.addFamiliarForm.get('surname') as FormControl;
  }

  get fiscalCode(): FormControl {
    return this.addFamiliarForm.get('fiscalCode') as FormControl;
  }

  get selectedFamiliarMember(): FormControl {
    return this.addFamiliarForm.get('selectedFamiliarMember') as FormControl;
  }

  private _unsubscribeSignal$: Subject<void> = new Subject();


  familiarsTypeToChoose: string[] = [];

  loggedUser?: User;
  userAcknowledges: UserAcknowledges | null = null;

  constructor(
    private route: ActivatedRoute,
    private appStore: Store<fromApp.AppState>,
    private translate: TranslateService,
    private redirectService: RedirectService,
    private scrollTo: ScrollTo,
    private rentService: RentService,
    private modalService: ModalService,
    private anagService: AnagService
  ) {
    const loggedUser$: Observable<JwtPayload> = this.appStore.select(fromApp.getLoggedUser);
    const userAck$: Observable<any> = this.appStore.select(fromApp.getUserAcknowledges);
    combineLatest([loggedUser$, userAck$])
      .pipe(takeUntil(this._unsubscribeSignal$.asObservable()))
      .subscribe(
        ([loggedUser, userAck]) => {
          if (loggedUser && loggedUser.user) {
            this.loggedUser = loggedUser && loggedUser.user;
            this.userAcknowledges = userAck;
          }
        });
  }

  ngOnInit() {
    this.scrollTo.header();

    this.campaignId = this.route.snapshot.paramMap.get('campaignId') || '';
    this.roundId = this.route.snapshot.paramMap.get('roundId') || '';

    if (this.campaignId && this.roundId) {
      this.isLoading = true;
      this.getCampaigns();
    }
  }

  onSelectedFamiliarMemberChanged(value: DropdownItem) {
    this.selectedFamiliarMember.setValue(value);
  }

  getCampaigns() {
    this.rentService.getCampaignListForEntitled()
      .pipe(takeUntil(this._unsubscribeSignal$.asObservable()))
      .subscribe((result) => {
        if (!result || result.error) {
          this.dispatchWarningModal('044', result.error || '');
          this.isLoading = false;
        } else if (result.response) {
          this.campaigns = result.response;
          if (this.campaigns && this.campaigns.length) {
            for (let i = 0; i < this.campaigns.length; i++) {
              if (this.campaigns[i].campaignId == this.campaignId) {
                this.campaign = this.campaigns[i];
                break;
              }
            }
          }

          if (this.campaign && this.campaign.ALLOWED_FAMILIY_MEMBERS && this.campaign.ALLOWED_FAMILIY_MEMBERS.length) {
            this.allowedFamiliars = '';
            for (let i = 0; i < this.campaign.ALLOWED_FAMILIY_MEMBERS.length; i++) {
              this.allowedFamiliars += this.translate.instant('admin.addCampaignCustomerCap.' + this.campaign.ALLOWED_FAMILIY_MEMBERS[i]) + ', ';
            }
            this.allowedFamiliars = this.allowedFamiliars.substring(0, this.allowedFamiliars.length - 2);
            this.campaign.ALLOWED_FAMILIY_MEMBERS.forEach(element => {
              this.familiarParentelOptions.push(
                {
                  id: element,
                  name: this.translate.instant('admin.addCampaignCustomerCap.' + element)
                }
              )
            });
          }
          this.isLoading = false;
        }
      }, (err) => {
        if (err && err.message) {
          this.dispatchWarningModal('045', err.message);
        }
        this.isLoading = false;
      });
  }

  ngOnDestroy() {
    this._unsubscribeSignal$.next();
    this._unsubscribeSignal$.unsubscribe();
  }

  /**
   * @description Va all'homepage delle SS
   */
  goBack() {
    this.redirectService.goToHome();
  }

  confirmAddFamiliarModal() {
    if (this.addFamiliarForm.valid) {
      const cidIdValid = this.checkCIDValidity(this.fiscalCode.value);
      if (!cidIdValid) {
        this.modalService.open("warningCID");
        return;
      };

      const birthdate = getBirthDateFromCF(this.fiscalCode.value.toUpperCase());
      this.isUnderageModal = birthdate ? checkUnderCustomYearsOld(birthdate, 18) : false;

      this.openPrivacyModal();
    }
  }

  checkCIDValidity(cid: string){
    if (!cid) return false;

    const hasNumber = (str: string) => /\d/.test(str);
    const hasLetter = (str: string) => /[a-zA-Z]/g.test(str);
    const [pt1, pt2, pt3, pt4, pt5, pt6, pt7] = [cid.slice(0, 6), cid.slice(6, 8), cid.slice(8, 9), cid.slice(9, 11), cid.slice(11, 12), cid.slice(12, 15), cid.slice(15)];

    if (cid.length != 16) return false;
    if (hasNumber(pt1)) return false
    if (hasLetter(pt2)) return false
    if (hasNumber(pt3)) return false
    if (hasLetter(pt4)) return false
    if (hasNumber(pt5)) return false
    if (hasLetter(pt6)) return false
    if (hasNumber(pt7)) return false
    return true
  }

  confirmAddFamiliar() {
    if (this.addFamiliarForm.valid) {

      this.isLoading = true;

      let request: Observable<SenecaResponse<boolean>> =
        this.rentService.createAttendeeForEntitled(
          this.roundId,
          this.forename.value,
          this.surname.value,
          this.fiscalCode.value.toUpperCase(),
          this.selectedFamiliarMember.value.id)

      request
        .pipe(takeUntil(this._unsubscribeSignal$.asObservable()))
        .subscribe((result) => {
          if (!result || result.error) {
            let errorToShow = '';
            if (result.error && result.error === "ACTION_NOT_ALLOWED") {
              errorToShow = 'MAXIMUM_NUMBER_OF_FAMILY_MEMBERS_EXCEEDED';
            }
            this.dispatchWarningModal('041', errorToShow);
          } else if (result.response) {
            this.redirectService.goToHome();
          }
          this.isLoading = false;
        }, (err) => {
          if (err && err.message) {
            this.dispatchWarningModal('042', err.message);
          }
          this.isLoading = false;
        });
    }
  }

  /**
   * @description Mostra una modale con un messaggio di errore
   * @param modalId
   * @param error
   */
  dispatchWarningModal(modalId: string, error: string) {
    const messageObj = getWarningModal(this.translate, modalId, error);
    this.appStore.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
  }

  openConfirmAddFamiliarModal() {
    this.modalService.open('confirmAddFamiliarModal');
  }

  closeConfirmAddFamiliarModal(confirm?: boolean) {
    this.closePrivacyModal();
    this.modalService.close('confirmAddFamiliarModal');
    if (confirm) {
      this.confirmAddFamiliar();
    }
  }

  openPrivacyModal() {
    this.privacyCheck = false;
    this.modalService.open('privacyModalAddFamiliar');
  }

  closePrivacyModal(confirm?: boolean) {
    if (confirm) {
      let acks: UserAcknowledges = JSON.parse(JSON.stringify(this.userAcknowledges));
      acks.data[(`CAMPAIGN_MANAGER_PRIVACY_ACCEPTED_${this.fiscalCode.value}`)] = moment().toISOString();

      this.anagService.updateUserAcknowledges(acks)
        .pipe(takeUntil(this._unsubscribeSignal$.asObservable()))
        .subscribe((result: any) => {
          if (result && result.error) {
            this.dispatchModal('sg001', result.error || '');
          } else {
            this.appStore.dispatch(AuthActions.SetUserAcknowledges({ payload: acks }));
          }
        });
      this.openConfirmAddFamiliarModal();
    } else {
      this.modalService.close('privacyModalAddFamiliar');
    }
  }

  closeWarningCidModal() {
    this.modalService.close("warningCID")
  }

  /**
   * @description Mostra una modale con un messaggio di errore
   * @param modalId
   * @param error
   */
  dispatchModal(modalId: string, error: string) {
    const messageObj = getWarningModal(this.translate, modalId, error);
    this.appStore.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
  }

  onCheckPrivacy(value: boolean) {
    this.privacyCheck = value;
  }

  goToPrivacyPage() {
    this.redirectService.goToPrivacyPage();
  }
}

import { Component, EventEmitter, HostListener, Input, OnInit, Output, ElementRef, Renderer2, ViewChild, OnChanges, SimpleChanges, Inject } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';
import * as fromApp from "../../ngrx/app.reducers";
import * as CoreActions from "../../core/ngrx/core.actions";
import { DropdownItem } from 'src/app/shared/models/dropdown.model';
import { SidebarService } from 'src/app/shared/services/sidebar.service';
import { RedirectService } from '../../shared/services/redirect.service';
import { Store } from '@ngrx/store';
import { LangsService } from '../services/langs.service';
import { ApplicationModalMessage } from '../ngrx/core.reducers';
import { Subscription } from 'rxjs';
import { Router } from '@angular/router';
import { DOCUMENT } from '@angular/common';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit, OnChanges {
  @Input() avatar?: string;
  @Input() pageName?: string = '';
  @Input() tooltipModalMessage: ApplicationModalMessage = {
    modalId: '',
    title: '',
    text: '',
    applicationModalButtonCloseText: ''
  };
  initials?: string;
  userName: string = '';
  isMobile: boolean = false;
  @Input() sidenavOpen!: boolean | null;

  @Output() onMenuClicked: EventEmitter<any> = new EventEmitter<any>();
  @Output() onTooltipClicked: EventEmitter<any> = new EventEmitter<any>();

  noDataObject: DropdownItem = {
    id: 'none',
    name: '',
    icon: '/assets/img/icons/coffee.svg'
  }
  applicationLang$: any;
  loggedUser: any;
  languageToUse: any;
  result$: Subscription = new Subscription();

  @ViewChild('menu') public menu: any;

  showSkipContent = false;

  constructor(public translate: TranslateService,
    public redirectService: RedirectService,
    public sidebarService: SidebarService,
    private renderer: Renderer2,
    private el: ElementRef,
    private store: Store<fromApp.AppState>,
    private langsService: LangsService,
    private router: Router
  ) {
    this.store.select(fromApp.getLoggedUser)
      .subscribe(
        (loggedUser) => {
          if (loggedUser && loggedUser.user) {
            this.loggedUser = loggedUser && loggedUser.user;
            this.avatar = this.loggedUser.userOptions && this.loggedUser.userOptions.avatar;
            this.languageToUse = this.langsService.getUserLang(this.loggedUser.user);
            moment.locale(this.languageToUse || 'it');
          }
        });
  }


  ngOnChanges(changes: SimpleChanges): void {
    if (!changes.sidenavOpen.firstChange && !changes.sidenavOpen.currentValue && this.menu != undefined) {
      setTimeout(() => {
        this.menu.nativeElement.focus();
      }, 100);
    }
  }

  ngOnInit() {
    const innerWidth = window.innerWidth;
    if (innerWidth < 600) {
      this.isMobile = true;
    }
    this.initials = this.loggedUser.forename[0] + this.loggedUser.surname[0];
    this.userName = this.loggedUser.surname + ' ' + this.loggedUser.forename;
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    if (event.target.innerWidth < 600) {
      this.isMobile = true;
    } else {
      this.isMobile = false;
    }
  }

  // Salta al contenuto principale della pagina
  skipToMainContent() {
    const mainContentElement = this.el.nativeElement.querySelector('#main-content');
    if (mainContentElement) {
      mainContentElement.focus();
    }
  }

  skipToMainContentKeyDown(event: KeyboardEvent): void {
    if (event.key === 'Enter' || event.key === ' ') {
      // Gestisci l'azione quando viene premuto Invio o Spazio
      const mainContentElement = this.el.nativeElement.querySelector('#main-content');
      if (mainContentElement) {
        mainContentElement.focus();
      }
      // Esegui l'azione desiderata, ad esempio, apri il link
    }
  }

  // Gestione sidebar
  isSidebarOpened() {
    return this.sidebarService.isNavOpen();
  }

  // Toggle della sidebar
  toggleSidebar() {
    this.sidebarService.setShowNav(!this.sidebarService.isNavOpen());
  }

  // Azione se logo viene cliccato o viene premuto il tasto "Invio" o la barra spaziatrice
  onAppLogoClicked() {
    this.redirectService.goToHome();
  }

  onKeyDownAppLogoClicked(event?: KeyboardEvent) {
    // if (event.key === 'Enter' || event.key === ' ') {
    // La tua logica quando viene premuto il tasto "Invio" o la barra spaziatrice
    this.redirectService.goToHome(); // Puoi chiamare la stessa logica del click
    // }
  }

  // Azione se l'icona ? veine cliccata o viene premuto l'eneter della tastiera
  emitOnTooltipClicked(event: any) {
    this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: this.tooltipModalMessage }));
  }

  onKeyDownTooltipClicked(event: KeyboardEvent) {
    if (event.key === 'Enter' || event.key === ' ') {
      // La tua logica quando viene premuto il tasto "Invio" o la barra spaziatrice
      this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: this.tooltipModalMessage })); // Puoi chiamare la stessa logica del click
    }
  }

  setFocus() {
    this.showSkipContent = false;
    this.router.navigate(["/user"], { fragment: "main" });
    document.getElementsByTagName("main")[0]?.focus();
  }

}
<page-container>

  <div class="sidenav-information">
    <sidenav-informations></sidenav-informations>
  </div>

  <div class="main-content">
    <div class="page-content">
      <svg-icon class="logo-container" [applyClass]="true" src="./assets/img/icons/app-logo.svg"></svg-icon>
      <p>
        <span class="small-font-weight" translate="404.WE_ARE_SORRY" ></span><br>
        <span translate="404.PAGE_NOT_FOUND" class="bold-text" ></span>
      </p>
      <p translate="404.RESOURCE_IS_NOT_DISPONIBLE" ></p>
      <div class="back-button-container">
        <welion-button type="primary" size="large" label="generic.GO_IN_HOME" (onClick)="redirectService.goToHome()">
        </welion-button>
      </div>
    </div>
  </div>
</page-container>

import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { Store } from '@ngrx/store';
import { takeUntil } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';

import * as CoreActions from "../../../core/ngrx/core.actions";
import * as fromApp from "../../../ngrx/app.reducers";

import { RedirectService } from 'src/app/shared/services/redirect.service';
import { getModalMessageData, getWarningModal } from 'src/app/utils/utils';
import { AnagService, UserGroupAdmin } from 'src/app/auth/services/anag.service';
import { ScrollTo } from 'src/app/core/services/scroll-to.service';

@Component({
  selector: 'app-admin-clients',
  templateUrl: './clients.component.html',
  styleUrls: ['./clients.component.scss']
})
export class AdminClientsComponent implements OnInit, OnDestroy {

  isLoadingCount: boolean = true;
  isLoading: boolean = true;

  searchText?: string;

  private _unsubscribeSignal$: Subject<void> = new Subject();

  readonly paginationLength: number = 25;

  clientsCount: number = 0;
  clients: UserGroupAdmin[] = [];

  isLimitedAdmin: boolean = false;

  constructor(
    private appStore: Store<fromApp.AppState>,
    private translate: TranslateService,
    private redirectService: RedirectService,
    private anagService: AnagService,
    private scrollTo: ScrollTo
  ) {
  }

  ngOnInit() {
    this.appStore.select(fromApp.getIsLimitedAdmin)
      .pipe(takeUntil(this._unsubscribeSignal$.asObservable()))
      .subscribe(
        (isLimitedAdmin) => {
          this.isLimitedAdmin = isLimitedAdmin;
        });
    this.countCustomers();
  }

  ngOnDestroy() {
    this._unsubscribeSignal$.next();
    this._unsubscribeSignal$.unsubscribe();
  }

  /**
   * @description Mostra una modale con titolo e messaggio
   * @param modalId
   * @param title
   * @param message
   */
  dispatchMessageModal(title: string, message: string) {
    const messageObj = getModalMessageData(this.translate, title, message);
    this.appStore.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
  }

  /**
   * @description Mostra una modale con un messaggio di errore
   * @param modalId
   * @param error
   */
  dispatchModal(modalId: string, error: string) {
    const messageObj = getWarningModal(this.translate, modalId, error);
    this.appStore.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
  }

  countCustomers() {
    this.isLoadingCount = true;
    this.isLoading = true;

    this.anagService.countCustomersForAdmin(this.searchText)
      .pipe(takeUntil(this._unsubscribeSignal$.asObservable()))
      .subscribe((result) => {
        if (!result || result.error) {
          this.dispatchModal('082', result.error || '');
          this.resetPagination();
        } else {
          this.clientsCount = result.response;

          if (this.clientsCount === 0) {
            this.resetPagination();
          } else {
            this.getCustomers();
          }
          this.isLoadingCount = false;
        }

      }, (err) => {
        if (err && err.message) {
          this.dispatchModal('084', err.message);
        }
        this.resetPagination();
      });
  }

  getCustomers(index?: number) {

    this.isLoading = true;

    const pageSelectedIndex = index ? index : 0;

    if (index === 0 || index) {
      this.scrollTo.element('admin-clients-anchor');
    }

    this.anagService.listCustomersForAdmin((pageSelectedIndex * this.paginationLength), this.paginationLength, this.searchText)
      .pipe(takeUntil(this._unsubscribeSignal$.asObservable()))
      .subscribe((result) => {
        if (!result || result.error) {
          this.dispatchModal('082', result.error || '');
          this.resetPagination();
        } else {
          this.clients = result.response;
          this.isLoading = false;
        }
      }, (err) => {
        if (err && err.message) {
          this.dispatchModal('084', err.message);
        }
        this.resetPagination();
      });
  }

  resetPagination() {
    this.isLoadingCount = false;
    this.isLoading = false;

    this.clientsCount = 0;
    this.clients = [];
  }

  newClient() {
    this.redirectService.goToAdminNewClient();
  }

  onClientClick(client: UserGroupAdmin) {
    this.redirectService.goToAdminClient(client.groupId);
  }

  searchTextChange(searchText?: string) {
    this.searchText = searchText;
  }

  searchClients() {
    this.countCustomers();
  }
}

/*
 * Filtro che imposta il bold ad una stringa direttamente nel DOM
 * To use with innerHTML
*/

import { Pipe, PipeTransform, SecurityContext } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Pipe({
  name: 'boldText'
})
export class BoldTextPipe implements PipeTransform {

  constructor(private domSanitizier: DomSanitizer) { }

  transform(value: string): string | null {
    return this.sanitize(`<b>${value}</b>`);
  }

  sanitize(str: string) {
    return this.domSanitizier.sanitize(SecurityContext.HTML, str);
  }
}

import { ActivatedRoute } from "@angular/router";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { Component, OnDestroy, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Observable, Subject } from 'rxjs';
import { Store } from "@ngrx/store";
import { takeUntil } from "rxjs/operators";

import { SenecaResponse } from "atfcore-commonclasses/bin/classes/common";

import * as fromApp from "../../../ngrx/app.reducers";
import * as CoreActions from "../../../core/ngrx/core.actions";

import { RedirectService } from 'src/app/shared/services/redirect.service';
import { getWarningModal } from "src/app/utils/utils";
import { ScrollTo } from "src/app/core/services/scroll-to.service";
import { RentService } from "src/app/structure/services/rent.service";
import { InfoCampaign, InfoRound } from "src/commonclasses";
import * as moment from 'moment';
import { ModalService } from "src/app/shared/components/modal/modal.service";

@Component({
  selector: 'editVenueBookingDate',
  templateUrl: './editVenueBookingDate.component.html',
  styleUrls: ['./editVenueBookingDate.component.scss']
})
export class EditVenueBookingDateComponent implements OnInit, OnDestroy {
  isLoading: boolean = false;
  campaignId: string = '';
  venueId: string = '';
  roundId: string = '';
  campaign?: InfoCampaign;
  venue?: any;

  round?: InfoRound & {
    bookingStartDate?: string,
    bookingEndDate?: string
  };

  editDatesForm = new FormGroup({
    'bookingStartDate': new FormControl(undefined, Validators.required),
    'bookingEndDate': new FormControl(undefined, Validators.required),
  });

  get bookingStartDate(): FormControl {
    return this.editDatesForm.get('bookingStartDate') as FormControl;
  }

  get bookingEndDate(): FormControl {
    return this.editDatesForm.get('bookingEndDate') as FormControl;
  }

  private _unsubscribeSignal$: Subject<void> = new Subject();

  oldBookingDatesLabel: string = '';
  campaignAndVenueInfoLabel: string = '';

  confirmEditModalLabel: string = '';

  constructor(
    private route: ActivatedRoute,
    private appStore: Store<fromApp.AppState>,
    private translate: TranslateService,
    private redirectService: RedirectService,
    private scrollTo: ScrollTo,
    private rentService: RentService,
    private modalService: ModalService
  ) {
  }

  ngOnInit() {
    this.scrollTo.header();

    this.campaignId = this.route.snapshot.paramMap.get('campaignId') || '';
    this.roundId = this.route.snapshot.paramMap.get('roundId') || '';
    this.venueId = this.route.snapshot.paramMap.get('venueId') || '';

    if (this.campaignId) {
      this.isLoading = true;
      this.getCampaign();
    }
  }

  getCampaign() {
    this.rentService.getCampaign(this.campaignId)
      .pipe(takeUntil(this._unsubscribeSignal$.asObservable()))
      .subscribe((result) => {
        if (!result || result.error) {
          this.dispatchWarningModal('044', result.error || '');
          this.isLoading = false;
        } else if (result.response) {
          this.campaign = result.response;

          if (this.roundId) {
            this.getRound();
          } else {
            this.isLoading = false;
          }
        }
      }, (err) => {
        if (err && err.message) {
          this.dispatchWarningModal('045', err.message);
        }
        this.isLoading = false;
      });
  }

  getVenueById() {
    this.rentService.getVenueById(this.venueId)
      .pipe(takeUntil(this._unsubscribeSignal$.asObservable()))
      .subscribe((currentRes: any) => {
        if (!currentRes || currentRes.error) {
          this.dispatchWarningModal('044', currentRes.error || '');
        } else if (currentRes.response) {
          this.venue = currentRes.response;

          const bookingStartDate = this.venue && this.venue.bookingStartDate;
          const bookingEndDate = this.venue && this.venue.bookingEndDate;

          const minReservationEndDate = this.round && this.round.minReservationEndDate;
          const maxReservationEndDate = this.round && this.round.maxReservationEndDate;

          // Salvo l'attuale prenotazione (verrà mostrata nella sidebar di sinistra)
          // const fromDate = bookingStartDate || minReservationEndDate || '';
          // const toDate = bookingEndDate || maxReservationEndDate || '';
          const fromDate = minReservationEndDate;
          const toDate = maxReservationEndDate;

          let formattedFromDate = '';
          let formattedToDate = '';

          if (fromDate) {
            formattedFromDate = moment(fromDate).format("LL");
          }

          if (toDate) {
            formattedToDate = moment(toDate).format("LL");
          }

          this.oldBookingDatesLabel = formattedFromDate + ' - ' + formattedToDate;

          this.campaignAndVenueInfoLabel = this.translate.instant("admin.editVenueBookingDate.EDIT_BOOKING_DATES") + ' <b>' + ((this.campaign && this.campaign.title) || '') + '</b> ' + this.translate.instant("admin.editVenueBookingDate.FOR").toLowerCase() + ' <b>' + ((this.venue && this.venue.name) || '') + '</b>';

          if (bookingStartDate) {
            this.bookingStartDate.setValue(new Date(bookingStartDate));
          }
          if (bookingEndDate) {
            this.bookingEndDate.setValue(new Date(bookingEndDate));
          }
        }

        this.isLoading = false;
      }, (err) => {
        if (err && err.message) {
          this.dispatchWarningModal('045', err.message);
        }
        this.isLoading = false;
      });
  }

  getRound() {
    this.rentService.getRound(this.roundId)
      .pipe(takeUntil(this._unsubscribeSignal$.asObservable()))
      .subscribe((result) => {
        if (!result || result.error) {
          this.dispatchWarningModal('044', result.error || '');
          this.isLoading = false;
        } else if (result.response) {
          this.round = result.response;

          this.getVenueById();
        }
      }, (err) => {
        if (err && err.message) {
          this.dispatchWarningModal('045', err.message);
        }
        this.isLoading = false;
      });
  }

  ngOnDestroy() {
    this._unsubscribeSignal$.next();
    this._unsubscribeSignal$.unsubscribe();
  }

  /**
   * @description Va all'homepage delle SS
   */
  goBack() {
    if (this.roundId) {
      this.redirectService.goToAdminCampaignRoundTabVenues(this.campaignId, this.roundId);
    } else {
      this.redirectService.goToAdminCampaignTabRounds(this.campaignId);
    }
  }

  goToFAQ() {

  }

  openConfirmEditBookingDateModal() {
    if (!this.confirmEditModalLabel) {
      this.confirmEditModalLabel = this.translate.instant('admin.editVenueBookingDate.YOU_ARE_EDITING_DATES_FOR') + ' ' + ((this.venue && this.venue.name) || '') + '.';
    }

    this.modalService.open('confirmEditBookingDate');
  }

  closeConfirmEditBookingDateModal() {
    this.modalService.close('confirmEditBookingDate');
  }

  confirmEditBookingDates() {
    if (this.editDatesForm.valid) {
      this.closeConfirmEditBookingDateModal();

      const bookingStartDate = (this.bookingStartDate.value as Date).getTime();
      const bookingEndDate = (this.bookingEndDate.value as Date).getTime();

      // Date a livello di round
      const minReservationEndDate = this.round && this.round.minReservationEndDate && new Date(this.round.minReservationEndDate);

      if (bookingStartDate > bookingEndDate || (minReservationEndDate && this.bookingStartDate.value < minReservationEndDate)) {
        this.dispatchWarningModal('555', 'DATE_MISMATCH');
        return;
      }

      this.isLoading = true;

      const isUpdating = this.venue && (this.venue.bookingStartDate || this.venue.bookingEndDate);

      let request: Observable<SenecaResponse<boolean>> = isUpdating ?
        this.rentService.updateVenueInRoundForAdmin(
          this.roundId,
          this.venue.itemId,
          this.bookingStartDate.value,
          this.bookingEndDate.value) :
        this.rentService.addVenueAssociationToRoundForAdmin(
          this.roundId,
          [this.venue.itemId],
          this.bookingStartDate.value,
          this.bookingEndDate.value);

      request
        .pipe(takeUntil(this._unsubscribeSignal$.asObservable()))
        .subscribe((result) => {
          if (!result || result.error) {
            this.dispatchWarningModal('041', result.error || '');
          } else {
            this.goBack();
          }
          this.isLoading = false;
        }, (err) => {
          if (err && err.message) {
            this.dispatchWarningModal('042', err.message);
          }
          this.isLoading = false;
        });
    }
  }

  onBookingStartDateChange(date: Date) {
    date = new Date(date);
    date.setHours(0, 0, 0, 0);
    this.bookingStartDate.setValue(new Date(date));
  }

  onBookingEndDateChange(date: Date) {
    date = new Date(date);
    date.setHours(23, 59, 59, 0);
    this.bookingEndDate.setValue(new Date(date));
  }

  /**
   * @description Mostra una modale con un messaggio di errore
   * @param modalId
   * @param error
   */
  dispatchWarningModal(modalId: string, error: string) {
    const messageObj = getWarningModal(this.translate, modalId, error);
    this.appStore.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
  }

}

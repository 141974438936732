<div class="side-nav-bar" role="navigation" [id]="id" [attr.aria-label]="ariaLabel"
  [ngClass]="{ 'side-nav-bar-collapsed': !(showSideNav | async), 'show-app-header': _showAppHeader }">

  <!-- Overlay -->
  <div class="side-nav-bar-overlay"
    [ngStyle]="{ 'transition': 'background-color ' + duration + 's, visibility ' + duration + 's' }"
    [ngClass]="{ 'side-nav-bar-overlay-collapsed': !(showSideNav | async) }" (click)="onSidebarClose()" clickKeyBoard
    (onEscKeyboard)="onSidebarClose()"></div>

  <!-- side bar-->
  <div class="side-nav-bar-menu-container" fxLayout="column" [ngStyle]="getSideNavBarStyle((showSideNav | async))"  [cdkTrapFocus]="(showSideNav | async)">
    <!-- Pulsante di chiusura -->   
    <div *ngIf="!_hideCloseButton">
      <span class="material-icons side-nav-bar-close" (click)="onSidebarClose()" role="button"
        attr.aria-label="{{'generic.CLOSE' | translate}}">
        <img alt="close icon" src="/assets/img/icons/x.svg" />
      </span>
    </div>

    <!-- Contenuto -->
    <div class="side-nav-bar-content-container">
      <ng-container *ngTemplateOutlet="sidenavTemplateRef"></ng-container>
    </div>
  </div>
</div>
import { Directive, ElementRef, HostListener, EventEmitter, Output } from "@angular/core";

@Directive({
    selector: "[clickKeyBoard]"
})
export class ClickKeyboardDirective {

    @Output() onEnterKeyboard = new EventEmitter<KeyboardEvent>();
    @Output() onEscKeyboard = new EventEmitter<KeyboardEvent>();
    @Output() onLeftKeyboard = new EventEmitter<KeyboardEvent>();
    @Output() onRightKeyboard = new EventEmitter<KeyboardEvent>();

    constructor() { }

    @HostListener("keydown", ["$event"])
    onKeyDown(event: KeyboardEvent) {
        if (event.code === "Enter" || event.code === "Space") {
            this.onEnterKeyboard.emit(event);
        }
        if (event.code === "Escape") {
            this.onEscKeyboard.emit(event);
        }

        if (event.code === "ArrowRight") {
            this.onLeftKeyboard.emit(event);
        }

        if (event.code === "ArrowLeft") {
            this.onRightKeyboard.emit(event);
        }
    }
}

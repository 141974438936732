<div class="page-content-wrapper" fxLayout="column" fxLayoutAlign="start center">

  <div class="active-campaigns" fxLayout="column">

    <h1 fxFlexOffset="28px">
      {{ 'structures.STRUCTURE_ID' | translate: { value: structureName, value2: structureCode } }}
    </h1>

    <!-- Lista di strutture -->
    <welion-select fxFlexOffset.xs="12px" isStructureSelect mediumText [bindLabel]="'title'"
      [selectedItem]="selectedStructure" searchable [loading]="isLoadingStructureList"
      (change)="onStructureSelected($event)" [items]="structureList" (scroll)="fetchMoreStructures($event)"
      [placeholder]="'structures.round.select.PLACEHOLDER' | translate"
      [noDataFoundLabel]="'structures.round.select.NO_STRUCTURE' | translate" [typeahead]="structureServive$"
      [attrAriaLabel]="'structures.round.select.PLACEHOLDER' | translate" [disabled]="disableStructureSelect">
    </welion-select>

    <bread-crumbs fxFlexOffset="24px" firstLevel="ACTIVE_CAMPAIGNS" [venueId]="venueId"></bread-crumbs>

    <div class="active-campaigns-container-background"></div>

    <!-- Lista campagne attive -->
    <div class="active-campaigns-container" [ngClass]="{
      'noData': !structureList || structureList.length < 2 || !campaignList || campaignList.length === 0
    }" [fxLayout]="
    (!structureList || structureList.length < 2 || !campaignList || campaignList.length === 0) ?
      'column' : 'row wrap'" fxLayout.xs="column" fxLayoutAlign="start center">

      <ng-container *ngIf="!isLoadingCampaigns">

        <ng-container *ngIf="structureList && structureList.length > 1 else noVenues">

          <ng-container *ngIf="campaignList && campaignList.length > 0 else noCampaigns">

            <card-campaign *ngFor="let campaign of campaignList; let even = even; let index = index"
              [fxFlexOffset]="even ? '0px' : '40px'" fxFlexOffset.xs="20px" [campaign]="campaign" [venueId]="venueId">
            </card-campaign>
          </ng-container>

        </ng-container>

      </ng-container>

      <div *ngIf="isLoadingCampaigns" class="loader spinner-big"></div>
    </div>

  </div>

</div>

<ng-template #noVenues>
  <div fxLayout="column" fxLayoutAlign="start center">
    <svg-icon fxFlexOffset="40px" [applyClass]="true" src="assets/img/icons/note-health-facility-red.svg"></svg-icon>

    <!-- Non ci sono sedi associate all'account -->
    <h3 fxFlexOffset="32px" translate="structures.NO_VENUES"></h3>
  </div>
</ng-template>

<ng-template #noCampaigns>
  <div fxLayout="column" fxLayoutAlign="start center">
    <svg-icon fxFlexOffset="40px" [applyClass]="true" src="assets/img/icons/note-health-facility-red.svg"></svg-icon>

    <!-- Non ci sono campagne associate alle sedi -->
    <h3 fxFlexOffset="32px" translate="structures.NO_CAMPAIGNS"></h3>
  </div>
</ng-template>

import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';

import * as CoreActions from "../../../core/ngrx/core.actions";
import * as fromApp from "../../../ngrx/app.reducers";
import { AnagService, ClientGroup } from 'src/app/auth/services/anag.service';

import { RedirectService } from 'src/app/shared/services/redirect.service';
import { getModalMessageData, getWarningModal } from 'src/app/utils/utils';
import { SupplierService } from '../../services/supplier.service';
import { ScrollTo } from 'src/app/core/services/scroll-to.service';
import { ModalService } from 'src/app/shared/components/modal/modal.service';
import { SenecaResponse } from 'atfcore-commonclasses';

@Component({
  selector: 'app-admin-client-details',
  templateUrl: './clientDetails.component.html',
  styleUrls: ['./clientDetails.component.scss']
})
export class AdminClientDetailsComponent implements OnInit, OnDestroy {

  groupId: string = '';

  isLoading: boolean = true;
  isLoadingCount: boolean = true;
  isLoadingBase: boolean = true;
  clientGroup?: ClientGroup;

  references: any[] = [];
  referencesCount: number = 0;

  readonly paginationLength: number = 50;

  referenceToDelete?: any;

  private _unsubscribeSignal$: Subject<void> = new Subject();

  isLimitedAdmin: boolean = false;

  constructor(
    private route: ActivatedRoute,
    private scrollTo: ScrollTo,
    private appStore: Store<fromApp.AppState>,
    private translate: TranslateService,
    private supplierService: SupplierService,
    private redirectService: RedirectService,
    private modalService: ModalService,
    private anagService: AnagService
  ) {

  }

  ngOnInit() {
    this.scrollTo.header();
    this.groupId = this.route.snapshot.paramMap.get('groupId') || '';

    if (this.groupId) {
      this.appStore.select(fromApp.getIsLimitedAdmin)
        .pipe(takeUntil(this._unsubscribeSignal$.asObservable()))
        .subscribe(
          (isLimitedAdmin) => {
            this.isLimitedAdmin = isLimitedAdmin;
          });

      this.getGroupClient();
    } else {
      this.redirectService.goToAdminClients();
    }
  }

  ngOnDestroy() {
    this._unsubscribeSignal$.next();
    this._unsubscribeSignal$.unsubscribe();
  }

  /**
   * @description Mostra una modale con titolo e messaggio
   * @param modalId
   * @param title
   * @param message
   */
  dispatchMessageModal(title: string, message: string) {
    const messageObj = getModalMessageData(this.translate, title, message);
    this.appStore.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
  }

  /**
   * @description Mostra una modale con un messaggio di errore
   * @param modalId
   * @param error
   */
  dispatchModal(modalId: string, error: string) {
    const messageObj = getWarningModal(this.translate, modalId, error);
    this.appStore.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
  }

  getGroupClient() {
    this.anagService.getCustomersForAdmin(this.groupId)
      .pipe(takeUntil(this._unsubscribeSignal$.asObservable()))
      .subscribe((result) => {
        if (!result || result.error) {
          this.dispatchModal('100', result.error || '');
          this.resetPagination();
        } else {
          this.clientGroup = result.response;
          this.countCustomerReferents();
        }
        this.isLoadingBase = false;
      }, (err) => {
        if (err && err.message) {
          this.dispatchModal('101', err.message);
        }
        this.resetPagination();
      });
  }

  editGroupClient() {
    this.redirectService.goToAdminNewClient(this.groupId);
  }

  /**
   * @description Apre la modale per cancellare un cliente
   */
  openDeleteCustomerModal() {
    this.modalService.open('deleteCustomer');
  }

  /**
   * @description Chiude la modale per cancellare un cliente
   */
  closeDeleteCustomerConfirmModal() {
    this.modalService.close('deleteCustomer');
  }

  confirmDeleteGroupClient() {
    this.isLoadingBase = true;
    this.isLoadingCount = true;

    this.supplierService.deleteCustomerForAdmin(this.groupId)
      .pipe(takeUntil(this._unsubscribeSignal$.asObservable()))
      .subscribe((result) => {
        this.closeDeleteCustomerConfirmModal();
        if (!result || result.error) {
          this.dispatchModal('110', result.error || '');
        } else {
          this.redirectService.goToAdminClients();
        }
        this.isLoadingBase = false;
        this.isLoadingCount = false;
      }, (err) => {
        this.closeDeleteCustomerConfirmModal();
        if (err && err.message) {
          this.dispatchModal('111', err.message);
        }
        this.isLoadingBase = false;
        this.isLoadingCount = false;
      });
  }

  addNewClientReference() {
    this.redirectService.goToAdminNewClientReference(this.groupId);
  }

  countCustomerReferents() {

    this.isLoadingCount = true;
    this.isLoading = true;

    this.supplierService.countCustomerReferentForAdmin(this.groupId)
      .subscribe((result) => {

        if (!result || result.error) {
          this.dispatchModal('100', result.error || '');
          this.resetPagination();
        } else {
          this.referencesCount = result.response;

          if (this.referencesCount > 0) {
            this.getCustomerReferents();
          } else {
            this.resetPagination();
          }
        }
        this.isLoadingCount = false;
      }, (err) => {
        if (err && err.message) {
          this.dispatchModal('101', err.message);
        }
        this.resetPagination();
      });
  }

  getCustomerReferents(index?: number) {

    this.isLoading = true;

    const pageSelectedIndex = index ? index : 0;

    if (index === 0 || index) {
      this.scrollTo.element('admin-client-details-anchor');
    }

    this.supplierService.listCustomerReferentForAdmin(this.groupId, (pageSelectedIndex * this.paginationLength), this.paginationLength)
      .pipe(takeUntil(this._unsubscribeSignal$.asObservable()))
      .subscribe((result) => {

        if (!result || result.error) {
          this.dispatchModal('102', result.error || '');
          this.resetPagination();
        } else {
          this.references = result.response;
          this.isLoading = false;
        }
      }, (err) => {
        if (err && err.message) {
          this.dispatchModal('103', err.message);
        }
        this.resetPagination();
      });
  }

  editReference(reference: any) {
    this.redirectService.goToAdminNewClientReference(this.groupId, reference.supplierPersonId);
  }

  /**
   * @description Apre la modale per cancellare il referente un cliente
   */
  openDeleteCustomerReferenceModal(reference: any) {
    this.referenceToDelete = reference;
    this.modalService.open('deleteCustomerReference');
  }

  /**
   * @description Chiude la modale per cancellare il referente un cliente
   */
  closeDeleteCustomerReferenceConfirmModal() {
    this.referenceToDelete = undefined;
    this.modalService.close('deleteCustomerReference');
  }

  confirmDeleteCustomerReference() {
    if (this.referenceToDelete) {

      this.isLoadingCount = true;

      this.supplierService.deleteCustomerReferentForAdmin(this.referenceToDelete.supplierPersonId)
        .pipe(takeUntil(this._unsubscribeSignal$.asObservable()))
        .subscribe((result) => {
          this.closeDeleteCustomerReferenceConfirmModal();
          if (!result || result.error) {
            this.dispatchModal('104', result.error || '');
            this.isLoadingCount = false;
          } else {
            this.referenceToDelete = undefined;
            this.countCustomerReferents();
          }
        }, (err) => {
          this.closeDeleteCustomerReferenceConfirmModal();
          if (err && err.message) {
            this.dispatchModal('105', err.message);
          }
          this.isLoadingCount = false;
        });
    }
  }

  resetPagination() {
    this.isLoading = false;
    this.isLoadingCount = false;
    this.isLoadingBase = false;

    this.references = [];
    this.referencesCount = 0;
  }

  toggleUserDashboardAccess(event: any, user: any) {
    user.isLoading = true;
    this.supplierService.setSupplierFullDashboardAccess(user.supplierPersonId, event)
      .subscribe((data: SenecaResponse<boolean>) => {
        if (!data || data.error) {
          this.dispatchModal('104', data.error || '');
        }
        user.isLoading = false;
      }, (err) => {
        if (err && err.message) {
          this.dispatchModal('105', err.message);
        }
        user.isLoading = false;
      });
  }
}

<div class="page-content-wrapper" fxLayout="column" fxLayoutAlign="start center">

  <div class="home-structure" fxLayout="column">

    <!-- Campagne -->
    <div fxLayout="column">

      <h1 *ngIf="isLoadingStructureInfo" fxFlexOffset="20px" translate="generic.LOADING"></h1>
      <h1 *ngIf="!isLoadingStructureInfo && structureInfo.name" fxFlexOffset="20px">{{structureInfo.name}}</h1>

      <!-- Resume struttura -->
      <div fxFlexOffset="20px" class="home-resume" fxLayout="column">
        <div fxLayout="row" fxLayoutAlign="space-between center">
          <svg-icon class="note" [applyClass]="true" src="assets/img/icons/note-health-facility-yellow.svg"></svg-icon>

          <div fxFlex fxFlexOffset="80px" fxLayout="column">
            <ng-container *ngIf="!isLoadingStructureInfo">
              <!-- Nome -->
              <h5 class="bold" translate="structures.info.NAME"></h5>
              <h5>{{ structureInfo.name }}</h5>
              <!-- Indirizzo -->
              <h5 class="bold" fxFlexOffset="16px" translate="structures.info.ADDRESS"></h5>
              <h5>{{ structureInfo.address }}</h5>
            </ng-container>
            <div *ngIf="isLoadingStructureInfo" class="loader spinner-small"></div>
          </div>
          <div fxFlex="160px" fxFlexOffset="20px" fxLayout="column">
            <ng-container *ngIf="!isLoadingStructureInfo">
              <!-- Codice -->
              <h5 class="bold" translate="structures.info.CODE"></h5>
              <h5>{{ structureInfo.code }}</h5>
              <!-- Città -->
              <h5 class="bold" fxFlexOffset="16px" translate="structures.info.CITY"></h5>
              <h5>{{ structureInfo.city }}</h5>
            </ng-container>
          </div>
          <div fxFlex="240px" fxLayout="column">
            <ng-container *ngIf="!isLoadingStructureInfo">
              <!-- Tipo struttura -->
              <h5 class="bold" translate="structures.info.TYPE"></h5>
              <h5>{{ structureInfo.type ? (structureInfo.type | translate) : '' }}</h5>
              <!-- Provincia -->
              <h5 class="bold" fxFlexOffset="16px" translate="structures.info.PROVINCE"></h5>
              <h5>{{ structureInfo.province }}</h5>
            </ng-container>
          </div>
        </div>
        <div fxLayout="row" fxFlexOffset="16px" *ngIf="!isLoadingStructureInfo && structureInfo.note">
          <div fxFlexOffset="80px" fxLayout="column">
            <!-- Note -->
            <h5 class="bold" translate="structures.info.NOTE"></h5>
            <h5>{{ structureInfo.note }}</h5>
          </div>
        </div>
      </div>

      <!-- Ancoraggio per lo scroll -->
      <div id="home-structure-anchor" fxFlexOffset="20px"></div>

      <!-- Impersonifica -->
      <div *ngIf="isImpersonate && loggedUser" class="user-impersonate" fxFlexOffset="20px" fxLayout="row">

        <svg-icon [applyClass]="true" src="assets/img/icons/note-impersonate.svg"></svg-icon>

        <div fxFlexOffset="88px" fxLayout="column" fxLayoutAlign="center start">
          <h5 translate="users.IMPERSONATE" class="bold"></h5>
          <p fxFlexOffset="4px">
            <span translate="users.IMPERSONATE_DESC"></span>
            <span class="name">{{ loggedUser.forename + ' ' + loggedUser.surname }}</span>
          </p>
        </div>

      </div>

      <!-- Lista di strutture -->
      <welion-select fxFlexOffset.xs="12px" isStructureSelect mediumText [bindLabel]="'title'"
        [selectedItem]="selectedStructure" searchable [loading]="isLoadingStructureList"
        (change)="onStructureSelected($event)" [items]="structureList" (scroll)="fetchMoreStructures($event)"
        [placeholder]="'structures.round.select.PLACEHOLDER' | translate"
        [noDataFoundLabel]="'structures.round.select.NO_STRUCTURE' | translate" [typeahead]="structureServive$"
        [attrAriaLabel]="'structures.round.select.PLACEHOLDER' | translate" [disabled]="disableStructureSelect">
      </welion-select>

    </div>

    <div *ngIf="isLoadingBase" fxFlexOffset="34px" class="loader spinner-big"></div>

    <ng-container *ngIf="!isLoadingBase">

      <div *ngIf="false" fxFlexOffset="34px" fxFlexOffset="24px" class="availability-management" fxLayout="column"
        fxLayoutAlign="start start">
        <!-- Gestione della disponibilità -->
        <h1 class="white" translate="structures.AVAILABILITY_MANAGEMENT"></h1>
        <!-- Definisci la disponibilità di date e orari... -->
        <h5 fxFlexOffset="2px" class="white" translate="structures.AVAILABILITY_MANAGEMENT_HINT"></h5>
        <!-- Gestisci disponibilità -->
        <welion-button fxFlexOffset="16px" type="third white" (click)="goToRoundSlots()"
          label="structures.AVAILABILITY_MANAGEMENT_SHORT">
        </welion-button>
      </div>

      <!-- Slot offert/prenotati, e persone prenotate/presenti -->
      <div *ngIf="!isLoading" fxFlexOffset="32px" fxFlexOffset.xs="24px" fxLayout="row" fxLayoutAlign="start center">
        <!-- Icona mondo -->
        <svg-icon class="structure-resume-slots-icon" [applyClass]="true" src="assets/img/icons/target-world.svg">
        </svg-icon>

        <div fxFlexOffset="20px" fxLayout="row" fxLayout.xs="column" fxLayoutAlign="start center"
          fxLayoutAlign.xs="start stretch">
          <div class="slot-number" fxLayout="column">
            <!-- Slot offerti/prenotati -->
            <h2>{{totalOfferedSlotCount}}/{{totalReservedSlotCount}}/{{totalBookedSlotCount}}</h2>
            <h6 fxFlexOffset="12px" class="black" translate="structures.OFFERED_SLOTS_AND_BOOKED"></h6>
          </div>
          <div fxFlexOffset.xs="16px" class="slot-number" fxLayout="column">
            <!-- Persone prenotate/presenti -->
            <h2>{{totalBookedSlotCount}}/{{totalPresentPeopleCount}}</h2>
            <h6 fxFlexOffset="12px" class="black" translate="structures.BOOKED_PERSONS_AND_PRESENTS"></h6>
          </div>
        </div>
      </div>

      <div *ngIf="isLoading" fxFlexOffset="34px" class="loader spinner-medium"></div>

      <!-- Lista round -->
      <div fxFlexOffset="32px" fxFlexOffset.xs="24px" class="more-actions-container" fxLayout="column">

        <div fxLayout="row" fxLayout.xs="column">
          <card-round-home *ngIf="nextRound" [round]="nextRound" fxFlex.gt-sm="50" [venueId]="venueId">
          </card-round-home>
          <card-round-home *ngIf="activeRound" [round]="activeRound" fxFlexOffset="12px" fxFlexOffset.xs="24px"
            fxFlex.gt-sm="50" isActive [venueId]="venueId">
          </card-round-home>
        </div>

        <!-- Campagne -->
        <div fxFlexOffset="48px" class="campaigns-card" fxLayout="column">

          <div fxFlexOffset="24px" fxFlexOffset.xs="0px" fxLayout="row" fxLayoutAlign="start center">
            <svg-icon class="note" [applyClass]="true" src="assets/img/icons/note-campaign.svg"></svg-icon>

            <div fxFlexOffset="120px" fxLayout="column">
              <h3 translate="generic.CAMPAIGNS"></h3>
              <h5 fxFlexOffset="4px" translate="structures.SHOW_ALL_CAMPAINS_INVOLED_IN"></h5>
              <div fxShow fxHide.xs fxLayout="row" fxFlexOffset="12px" fxFlexOffset.xs="20px">
                <ng-container [ngTemplateOutlet]="activeCampaigns"></ng-container>
              </div>
            </div>

            <svg-icon fxShow fxHide.xs class="triangles" src="assets/img/icons/campaign-triangles.svg"></svg-icon>
          </div>

          <div fxHide fxShow.xs fxLayout="row" fxLayoutAlign="center" fxFlexOffset="20px">
            <ng-container [ngTemplateOutlet]="activeCampaigns"></ng-container>
          </div>

        </div>

      </div>

    </ng-container>

  </div>

  <div fxFlexOffset="100px" fxFlexOffset.xs="40px"></div>

</div>

<ng-template #activeCampaigns>
  <welion-button (onClick)="goToActiveCampaigns()" type="primary bordless" size="medium"
    label="structures.SHOW_ALL_CAMPAIGNS">
  </welion-button>
</ng-template>

import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { UserGuard } from '../shared/services/user-guard.service';
import { UserComponent } from './user.component';
import { UserHomeComponent } from './home/home.component';
import { UserAddReservationComponent } from './addReservation/addReservation.component';
import { FAQComponent } from '../shared/components/faq/faq.component';
import { AddTicketComponent } from '../shared/addTicket/addTicket.component';
import { UserAddFamiliarComponent } from './addFamiliar/addFamiliar.component';

const userRoutes: Routes = [
  {
    path: '', component: UserComponent, children: [
      { path: 'home', component: UserHomeComponent, canActivate: [UserGuard] },
      { path: 'reservation/:roundId', component: UserAddReservationComponent, canActivate: [UserGuard] },
      { path: 'reservation/:roundId/:userId', component: UserAddReservationComponent, canActivate: [UserGuard] },
      { path: 'reservation/:roundId/:userId/:attendanceId/:editStructure/:editDate', component: UserAddReservationComponent, canActivate: [UserGuard] },
      { path: 'familyReservation/:roundId/:editStructure/:editDate', component: UserAddReservationComponent, canActivate: [UserGuard] },
      { path: 'addFamiliar/:campaignId/:roundId', component: UserAddFamiliarComponent, canActivate: [UserGuard] },
      { path: 'faq', component: FAQComponent, canActivate: [UserGuard] },
      { path: 'faq/:roundId/:userId', component: FAQComponent, canActivate: [UserGuard] },
      { path: 'createTicket', component: AddTicketComponent, canActivate: [UserGuard] },
      { path: 'createTicket/:roundId/:userId', component: AddTicketComponent, canActivate: [UserGuard] },
      { path: "", redirectTo: "home", pathMatch: "full" }
    ]
  },
];

@NgModule({
  imports: [
    RouterModule.forChild(userRoutes)
  ],
  providers: [
  ],
  exports: [RouterModule]
})
export class UserRoutingModule { }

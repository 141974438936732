import { NgModule } from '@angular/core';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { AuthInterceptor } from '../shared/interceptors/auth.interceptor';
import { StructureComponent } from './structure.component';
import { StructureRoutingModule } from './structure-routing.module';
import { SharedModule } from '../shared/shared.module';
import { CoreModule } from '../core/core.module';
import { NgxPaginationModule } from 'ngx-pagination';
import { StructureHomeComponent } from './home/home.component';
import { structureReducer } from './ngrx/structure.reducers';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { StructureEffects } from './ngrx/structure.effects';
import { StructureService } from '../shared/services/structure.service';
import { ActiveCampaignsComponent } from './activeCampaigns/activeCampaigns.component';
import { CampaignDetailsComponent } from './campaignDetails/campaignDetails.component';
import { RoundStructuresComponent } from './roundStructures/roundStructures.component';
import { RoundAttendancesComponent } from './roundAttendances/roundAttendances.component';
import { ImportService } from './services/import.service';
import { RentService } from './services/rent.service';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { AddSlotsComponent } from './addSlots/addSlots.component';
import { ExportService } from './services/export.service';

@NgModule({
    declarations: [
        StructureComponent,
        StructureHomeComponent,
        ActiveCampaignsComponent,
        CampaignDetailsComponent,
        RoundStructuresComponent,
        RoundAttendancesComponent,
        AddSlotsComponent
    ],
    imports: [
        FormsModule,
        CommonModule,
        ReactiveFormsModule,
        StructureRoutingModule,
        SharedModule,
        CoreModule,
        NgxPaginationModule,
        InfiniteScrollModule,
        StoreModule.forFeature('structure', structureReducer),
        EffectsModule.forFeature([StructureEffects])
    ],
    providers: [
        StructureService,
        ImportService,
        RentService,
        ExportService,
        { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true }
    ]
})
export class StructureModule { }

<div class="page-content-wrapper" fxLayout="column">

  <!-- Cliente -->
  <h1 fxFlexOffset="40px" class="primary" translate="admin.clientDetails.TITLE"></h1>

  <bread-crumbs fxFlexOffset="12px" firstLevel="CLIENTS"
    [secondLevel]="clientGroup ? clientGroup.title : ('admin.clientDetails.TITLE' | translate)">
  </bread-crumbs>

  <div fxFlexOffset="40px" class="client-resume" fxLayout="row" fxLayoutAlign="space-between center">
    <svg-icon class="note" [applyClass]="true" src="assets/img/icons/note-health-facility-yellow.svg"></svg-icon>

    <div fxLayout="column" fxFlexOffset="100px">
      <ng-container *ngIf="!isLoadingBase && clientGroup">
        <h5 class="bold" translate="admin.clientDetails.TITLE"></h5>
        <h5>{{ clientGroup.title }}</h5>
        <h5>ID: {{clientGroup.groupId}}</h5>
      </ng-container>
      <div *ngIf="isLoadingBase || !clientGroup" class="loader spinner-small"></div>
    </div>

    <div fxLayout="row">
      <!-- Elimina -->
      <welion-button *ngIf="!isLimitedAdmin" width="112px" size="medium" type="third"
        [label]="'generic.DELETE' | translate | uppercase" (onClick)="openDeleteCustomerModal()"
        [disabled]="isLoadingBase">
      </welion-button>
      <!-- Modifica -->
      <welion-button *ngIf="!isLimitedAdmin" fxFlexOffset="8px" width="112px" size="medium" type="primary"
        [label]="'generic.EDIT' | translate | uppercase" (onClick)="editGroupClient()" [disabled]="isLoadingBase">
      </welion-button>
    </div>

  </div>

  <div id="admin-client-details-anchor" fxFlexOffset="20px"></div>

  <div fxFlexOffset="20px" class="client-details-container" fxLayout="column">

    <ng-container *ngIf="!isLoadingCount">
      <ng-container *ngIf="referencesCount > 0 else noReferences">
        <div fxLayout="row" fxLayoutAlign="space-between center">
          <!-- Referenti -->
          <h3 fxFlexOffset="16px" translate="admin.clientDetails.SUBTITLE"></h3>
          <!-- Aggiungi -->
          <welion-button *ngIf="!isLimitedAdmin" width="124px" size="medium" type="secondary"
            [label]="'generic.ADD' | translate | uppercase" (onClick)="addNewClientReference()" [disabled]="isLoading">
          </welion-button>
        </div>

        <!-- Table header -->
        <div fxFlexOffset="24px">
          <!-- Cognome e nome -->
          <h5 class="bold" translate="admin.clientDetails.table.NAME" fxFlex="30" fxFlexOffset="16px">
          </h5>
          <!-- Email -->
          <h5 class="bold" translate="admin.clientDetails.table.EMAIL" fxFlex="25">
          </h5>
          <!-- Dashboard avanzata -->
          <h5 class="bold" translate="admin.clientDetails.table.DASH" fxFlex="35">
          </h5>
        </div>

        <!-- Loader -->
        <div *ngIf="isLoading" class="loader spinner-big"></div>

        <ng-container *ngIf="!isLoading">
          <!-- Table rows -->
          <div *ngFor="let reference of references; let index = index; let odd = odd" class="client-row"
            [ngClass]="{'odd': odd}" fxLayout="row" fxLayoutAlign="start center"
            [fxFlexOffset]="index === 0 ? '16px' : '0px'">
            <!-- Cognome e nome -->
            <h6 class="header black" fxFlex="30" fxFlexOffset="16px">{{ reference.surname + ' ' + reference.forename }}
            </h6>
            <!-- Email -->
            <h6 class="second black" fxFlex="25">{{ reference.email }}</h6>
            <!-- Toggle dashboard avanzata-->
            <div class="table-toggle" fxFlex="35">
              <welion-switch [checked]="reference.hasFullDashboardAccess" [disabled]="reference.isLoading"
                (onSwitch)="toggleUserDashboardAccess($event, reference)" leftLabelObject=""
                [rightLabelObject]="'admin.clientDetails.table.ENABLE' | translate">
              </welion-switch>
            </div>
            <div fxFlex fxLayout="row" fxLayoutAlign="end">
              <!-- Modifica -->
              <h6 *ngIf="!isLimitedAdmin" fxFlexOffset="12px" class="black bold underline cursor-pointer"
                (click)="editReference(reference)" translate="generic.EDIT"></h6>
              <!-- Elimina -->
              <h6 *ngIf="!isLimitedAdmin" fxFlexOffset="12px" class="black bold underline cursor-pointer"
                (click)="openDeleteCustomerReferenceModal(reference)" translate="generic.DELETE"></h6>
              <div fxFlex="16px"></div>
            </div>
          </div>
        </ng-container>

        <welion-paginator fxFlexOffset="20px" [isLoading]="isLoading" [paginationLength]="paginationLength"
          [itemsCount]="referencesCount" (onClickPage)="getCustomerReferents($event)"></welion-paginator>

      </ng-container>
    </ng-container>

    <div *ngIf="isLoadingCount" class="loader spinner-big"></div>
  </div>

</div>

<!-- Modale per la cancellazione di un cliente -->
<modal id="deleteCustomer" (onClose)="closeDeleteCustomerConfirmModal()" (onCancel)="closeDeleteCustomerConfirmModal()"
  [disableAll]="isLoadingBase" (onConfirm)="confirmDeleteGroupClient()"
  [cancelLabel]="'generic.CLOSE' | translate | uppercase" [confirmLabel]="'generic.CONFIRM' | translate | uppercase">
  <modal-text-content [withScrolledBody]="true" [title]="'admin.clientDetails.deleteModal.TITLE' | translate"
    [text]="'admin.clientDetails.deleteModal.RESET_WARNING' | translate"
    [subtitle]="'admin.clientDetails.deleteModal.SUBTITLE' | translate">
  </modal-text-content>
</modal>

<!-- Modale per la cancellazione di un referente del cliente -->
<modal id="deleteCustomerReference" (onClose)="closeDeleteCustomerReferenceConfirmModal()" [disableAll]="isLoadingCount"
  (onCancel)="closeDeleteCustomerReferenceConfirmModal()" (onConfirm)="confirmDeleteCustomerReference()"
  [cancelLabel]="'generic.CLOSE' | translate | uppercase" [confirmLabel]="'generic.CONFIRM' | translate | uppercase">
  <modal-text-content [withScrolledBody]="true" [title]="'admin.clientDetails.deleteRecerenceModal.TITLE' | translate: {
      value: referenceToDelete ? (referenceToDelete.surname + ' ' + referenceToDelete.forename) : '' }"
    [text]="'admin.clientDetails.deleteRecerenceModal.RESET_WARNING' | translate"
    [subtitle]="'admin.clientDetails.deleteRecerenceModal.SUBTITLE' | translate">
  </modal-text-content>
</modal>

<ng-template #noReferences>
  <div fxLayout="column" fxLayoutAlign="start center">
    <svg-icon fxFlexOffset="32px" [applyClass]="true" src="assets/img/icons/note-health-facility-references.svg">
    </svg-icon>

    <!-- Inserisci i referenti per questo cliente -->
    <h3 fxFlexOffset="32px" translate="admin.clientDetails.NO_REFERENCES"></h3>

    <div fxLayout="row" fxFlexOffset="8px">
      <h5 class="center" fxFlex="748px" fxFlex.xs="100" translate="admin.clientDetails.NO_REFERENCES_DESC"></h5>
    </div>

    <welion-button fxFlexOffset="32px" width="232px" size="large" type="primary"
      [label]="'admin.clientDetails.ADD_REFERENCE' | translate | uppercase" (onClick)="addNewClientReference()">
    </welion-button>

    <div fxFlex="80px"></div>
  </div>
</ng-template>
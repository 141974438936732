<footer class="footer" fxLayout="column" fxLayoutAlign="center start">
  <div class="page-content-wrapper" fxLayout="column">
    <div fxLayout="row" fxLayoutAlign="start center">
      <svg-icon [applyClass]="true" src="/assets/img/icons/logo-white.svg" class="logo"></svg-icon>
    </div>
    <div fxFlexOffset="20px" *ngIf="isUser" fxLayout="collunm" >
      <a [routerLink]="['/user']"  fragment="app-header" class="height-24 center-vertical">Torna su</a>
    </div>
    <div fxFlexOffset="20px" *ngIf="!isUser" fxLayout="collunm" >
      <a [routerLink]="['/faq']"  fragment="app-header" class="height-24 center-vertical">Torna su</a>
    </div>
    <div fxFlexOffset="12px" *ngIf="isUser" role="button" fxLayout="collunm" (click)="goToPrivacy()"  tabindex="0" clickKeyBoard (onEnterKeyboard)="goToPrivacy()" class="height-24">
      <a class="center-vertical">Informativa sulla privacy </a>
    </div>
    <div fxFlexOffset="12px" fxLayout="collunm" role="button" (click)="goToAccessibility()"  tabindex="0" clickKeyBoard (onEnterKeyboard)="goToAccessibility()" class="height-24" >
      <a class="center-vertical">Accessibilità</a>
    </div>
    <div fxFlexOffset="12px" fxLayout="row" fxLayoutAlign="start center">
      <p>Generali Welion S.c.a.r.l. - P.IVA 01333550323 - Codice Fiscale 01202150320 - Uffici della sede operativa: Piazza Tre Torri, 1 - 20145 Milano MI
      </p>
    </div>
  </div>
</footer>
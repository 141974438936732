import { Component, OnDestroy } from '@angular/core';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import * as fromApp from "../ngrx/app.reducers";
import * as moment from 'moment';
import { ProfileState } from '../core/profile/ngrx/profile.reducers';

@Component({
  selector: 'app-helpDesk',
  templateUrl: './helpDesk.component.html',
  styleUrls: ['./helpDesk.component.scss']
})
export class HelpDeskComponent implements OnDestroy {
  applicationLang: string = '';
  applicationLang$: Subscription;
  loggedUser?: ProfileState;

  constructor(
    public translate: TranslateService,
    private appStore: Store<fromApp.AppState>) {
    // Recupero la lingua dell'appplicazione
    this.applicationLang$ = this.appStore.select(fromApp.getApplicationLang).subscribe((applicationLang: any) => {
      if (applicationLang) {
        this.applicationLang = applicationLang;
        this.translate.use(applicationLang);
        moment.locale(applicationLang);
      }
    });

    this.appStore.select(fromApp.getLoggedUser)
      .subscribe(
        (loggedUser) => {
          if (loggedUser && loggedUser.user) {
            this.loggedUser = loggedUser && loggedUser.user;
          }
        });
  }

  ngOnDestroy(): void {
    if (this.applicationLang$) {
      this.applicationLang$.unsubscribe();
    }
  }
}

<div class="page-content-wrapper" fxLayout="column">

  <!-- Campagna XYZ -->
  <h1 *ngIf="!isLoadingBase && campaign" fxFlexOffset="40px" class="primary">
    {{ 'helpDesk.campaign.CAMPAIGN' | translate: { value: campaign.title } }}
  </h1>
  <h1 *ngIf="isLoadingBase || !campaign" fxFlexOffset="40px" class="primary" translate="generic.LOADING"></h1>

  <bread-crumbs fxFlexOffset="12px" [customFirstLevel]="campaign ? campaign.title : 'CAMPAIGNS'">
  </bread-crumbs>

  <div id="helpdesk-home-anchor" fxFlexOffset="20px" fxFlexOffset.xs="6px"></div>

  <div fxFlexOffset="20px" fxFlexOffset.xs="6px" class="campaigns-container" fxLayout="column">

    <!-- Aventi diritto -->
    <h3 *ngIf="hasEntitledsFirstLoad" translate="helpDesk.campaign.TITLE"></h3>

    <!-- Nome campagna -->
    <input-container *ngIf="hasEntitledsFirstLoad" [title]="'helpDesk.campaign.SEARCH_ATTENDANCE' | translate"
      fxFlexOffset="20px">
      <div fxLayout="row">
        <welion-input [bindValue]="searchEntitledText" (onChange)="searchEntitledsTextChange($event)"
          (onIconClicked)="searchEntitleds()" [disabled]="isLoading"
          [placeholder]="'helpDesk.campaign.SEARCH_ATTENDANCE_PLACAHOLDER' | translate"
          iconRight="assets/img/icons/icon-search.svg">
        </welion-input>

        <welion-input fxFlex="360px" fxFlexOffset="40px" [bindValue]="searchEntitledCFText"
          (onChange)="searchEntitledsCFTextChange($event)" (onIconClicked)="searchEntitleds()" [disabled]="isLoading"
          [placeholder]="'helpDesk.campaign.SEARCH_ATTENDANCE_CF_PLACAHOLDER' | translate"
          iconRight="assets/img/icons/icon-search.svg">
        </welion-input>

        <welion-input fxFlex="360px" fxFlexOffset="40px" [bindValue]="searchEntitledCompanyText"
          (onChange)="searchEntitledsCompanyTextChange($event)" (onIconClicked)="searchEntitleds()"
          [disabled]="isLoading" [placeholder]="'helpDesk.campaign.SEARCH_ATTENDANCE_COMPANY_PLACAHOLDER' | translate"
          iconRight="assets/img/icons/icon-search.svg">
        </welion-input>
      </div>
    </input-container>

    <div *ngIf="isLoadingCount" class="loader spinner-big"></div>

    <ng-container *ngIf="!isLoadingCount">
      <ng-container *ngIf="entitledsCount > 0 else noEntitleds">

        <!-- Table header -->
        <div fxFlexOffset="24px">
          <!-- Cognome e nome -->
          <h5 class="bold" translate="helpDesk.campaign.table.NAME_SURNAME" fxFlex="20" fxFlexOffset="12px">
          </h5>
          <!-- Codice fiscale -->
          <h5 class="bold" translate="helpDesk.campaign.table.FISCAL_CODE" fxFlex="18"></h5>
          <!-- Tipo di utente -->
          <h5 class="bold" translate="admin.campaignRoundDetails.tab.attendances.table.USER_TYPE" fxFlex="10"></h5>
          <!-- Stato -->
          <h5 class="bold" translate="helpDesk.campaign.table.STATE" fxFlex="12"></h5>
          <!-- Data -->
          <h5 class="bold" translate="helpDesk.campaign.table.DATE" fxFlex="20"></h5>
          <!-- Azienda cliente -->
          <div fxLayout="row" fxFlex>
            <h5 fxFlex class="bold center" translate="helpDesk.campaign.table.CLIENT_COMPANY"></h5>
            <div fxFlex="140px"></div>
          </div>
        </div>

        <!-- Loader -->
        <div *ngIf="isLoading" class="loader spinner-big"></div>

        <ng-container *ngIf="!isLoading">
          <!-- Table rows -->
          <div *ngFor="let entitled of entitleds; let index = index; let odd = odd" class="campaign-row"
            [ngClass]="{'odd': odd}" fxLayout="row" fxLayoutAlign="start center"
            [fxFlexOffset]="index === 0 ? '12px' : '0px'">

            <!-- Cognome e nome -->
            <h6 class="black" fxFlex="20" fxFlexOffset="12px">
              <ng-container *ngIf="entitled.user">
                {{ (entitled.user.socDistacco ? '[' + entitled.user.socDistacco +'] ' : '') +
                entitled.user.surname + ' '
                +
                entitled.user.forename }}
              </ng-container>
            </h6>

            <!-- Codice fiscale -->
            <h6 class="black" fxFlex="18">
              {{ entitled.user.codFisc ? entitled.user.codFisc : '-' }}
            </h6>
            <!-- Tipo di dipendente -->
            <h6 class="black" fxFlex="10">
              {{ ('admin.campaignRoundDetails.tab.attendances.table.' + (entitled.user?.parentCodFisc ? "FAMILY" :
              "EMPLOYEE")) | translate }}
            </h6>
            <!-- Stato -->
            <h6 class="black" fxFlex="12">
              {{ entitled.status ? (('status.' + entitled.status) | translate) : '-' }}
            </h6>
            <!-- Data -->
            <h6 *ngIf="entitled.slot" class="black" fxFlex="20">
              {{ entitled.slot.startDate | date: 'dd MMMM yyyy HH:mm'}}
              {{ ' - ' }}
              {{entitled.slot.endDate | date: 'HH:mm'}}
            </h6>
            <h6 *ngIf="!entitled.slot" class="black" fxFlex="20">
              {{ '-' }}
            </h6>
            <!-- Azienda cliente -->
            <div fxFlex fxLayout="row" fxLayoutAlign="space-between center">
              <div fxFlex fxLayout="column" fxLayoutAlign="start center" *ngIf="entitled.user.company">
                <h6 class="black">
                  {{ entitled.user.company}}
                </h6>
              </div>
              <h6 fxFlex class="black center" *ngIf="!entitled.user.company">-</h6>

              <div fxFlex="160px" fxLayout="row" fxLayoutAlign="end">
                <!-- Azioni -->
                <welion-select-button isLink [currentSelect]="entitled" fxFlex="220px" direction="right"
                  [label]="'generic.ACTION_LOWER' | translate" [disabled]="isLoading"
                  [dropdownItems]="dropdownSelectEntitled">
                </welion-select-button>
                <div fxFlex="16px"></div>
              </div>
            </div>
          </div>

        </ng-container>

        <!-- Paginatore -->
        <welion-paginator fxFlexOffset="20px" [isLoading]="isLoading" [paginationLength]="paginationLength"
          [itemsCount]="entitledsCount" (onClickPage)="getEntitledByCampaign($event)"></welion-paginator>

      </ng-container>
    </ng-container>

  </div>

</div>

<!-- Sidenav con la scheda di dettaglio -->
<sidebar showAppHeader hideCloseButton direction="right" [navWidth]="664" [duration]="0.3"
  [sidenavTemplateRef]="navContentEntitledCard" >
</sidebar>

<!-- Contenuto sidenav -->
<ng-template #navContentEntitledCard>

  <div class="entitled-card-sidenav" fxLayout="column">

    <div class="full-width" fxLayout="row" fxLayoutAlign="end">
      <!-- Icona chiudi -->
      <div fxLayoutAlign="row" fxLayoutAlign="center center" (click)="closeEntitledCard()" class="close-container">
        <svg-icon class="small-size" src="/assets/img/icons/x.svg" [applyClass]="true"></svg-icon>
        <h5 fxFlexOffset="8px" class="white">{{ 'generic.CLOSE' | translate | uppercase}}</h5>
      </div>
    </div>

    <!-- Scheda personale -->
    <h5 fxFlexOffset="20px" class="bold" translate="structures.round.attendances.modal.TITLE"></h5>

    <ng-container *ngIf="selectedEntitled">
      <!-- Palerma Leonida -->
      <h3 fxFlexOffset="16px">{{ selectedEntitled.user.surname }} {{ selectedEntitled.user.forename }}</h3>

      <div fxFlexOffset="16px" class="card-header-divider"></div>

      <!-- Codice fiscale -->
      <h5 fxFlexOffset="24px" class="bold" translate="structures.round.attendances.modal.FISCAL_CODE"></h5>

      <div fxLayout="row" fxLayoutAlign="space-between center" class="full-width">
        <h5 *ngIf="!isEditCFView">{{ selectedEntitled.user.codFisc }}</h5>
        <welion-input *ngIf="isEditCFView" fxFlexOffset="4px" [bindValue]="fiscalCodeUser"
          (onChange)="userFCChanged($event)" [placeholder]="'helpDesk.campaign.INSERT_FISCAL_CODE' | translate">
        </welion-input>
        <!-- Il codice fiscale non è modificabile se l'utente accede da SSO -->
        <welion-button *ngIf="!isEditCFView && (selectedEntitled.user && !selectedEntitled.user.fromSSO)"
          (onClick)="editCF()" noUpperCase direction="right" type="on-edit-help-desk-user-data"
          [label]="'generic.EDIT' | translate">
        </welion-button>
        <welion-button *ngIf="isEditCFView" (onClick)="editCF(true)" noUpperCase direction="right"
          type="on-edit-help-desk-user-data" [label]="'generic.CLOSE' | translate">
        </welion-button>
        <welion-button [disabled]="!fiscalCodeUser || (fiscalCodeUser && fiscalCodeUser.length != 16)"
          *ngIf="isEditCFView" (onClick)="saveNewDataAttendance(false, false, true)" noUpperCase direction="right"
          type="on-edit-help-desk-user-data" [label]="'generic.SAVE' | translate">
        </welion-button>
      </div>

      <div fxFlexOffset="16px" fxLayout="row" fxLayout.xs="column" fxLayoutAlign="space-between">

        <!-- Nome -->
        <div fxLayout="column" class="full-width">
          <h5 class="bold" translate="structures.round.attendances.modal.NAME"></h5>
          <div fxLayout="row" fxLayoutAlign="space-between center" class="full-width">
            <h5 *ngIf="!isEditNameView">
              {{ selectedEntitled.user.forename }}
            </h5>
            <welion-input *ngIf="isEditNameView" fxFlexOffset="4px" [bindValue]="nameUser"
              (onChange)="userNameChanged($event)" [placeholder]="'helpDesk.campaign.INSERT_NAME' | translate">
            </welion-input>
            <welion-button *ngIf="!isEditNameView" (onClick)="editName()" noUpperCase direction="right"
              type="on-edit-help-desk-user-data" [label]="'generic.EDIT' | translate">
            </welion-button>
            <welion-button *ngIf="isEditNameView" (onClick)="editName(true)" noUpperCase direction="right"
              type="on-edit-help-desk-user-data" [label]="'generic.CLOSE' | translate">
            </welion-button>
            <welion-button [disabled]="!nameUser" *ngIf="isEditNameView" (onClick)="saveNewDataAttendance(true)"
              noUpperCase direction="right" type="on-edit-help-desk-user-data" [label]="'generic.SAVE' | translate">
            </welion-button>
          </div>

          <!-- Cognome -->
          <h5 class="bold" fxFlexOffset="16px" translate="structures.round.attendances.modal.SURNAME"></h5>
          <div fxLayout="row" fxLayoutAlign="space-between center" class="full-width">
            <h5 *ngIf="!isEditSurnameView">
              {{ selectedEntitled.user.surname }}
            </h5>
            <welion-input *ngIf="isEditSurnameView" fxFlexOffset="4px" [bindValue]="surnameUser"
              (onChange)="userSurnameChanged($event)" [placeholder]="'helpDesk.campaign.INSERT_SURNAME' | translate">
            </welion-input>
            <welion-button *ngIf="!isEditSurnameView" (onClick)="editSurname()" noUpperCase direction="right"
              type="on-edit-help-desk-user-data" [label]="'generic.EDIT' | translate">
            </welion-button>
            <welion-button *ngIf="isEditSurnameView" (onClick)="editSurname(true)" noUpperCase direction="right"
              type="on-edit-help-desk-user-data" [label]="'generic.CLOSE' | translate">
            </welion-button>
            <welion-button [disabled]="!surnameUser" *ngIf="isEditSurnameView"
              (onClick)="saveNewDataAttendance(false, true)" noUpperCase direction="right"
              type="on-edit-help-desk-user-data" [label]="'generic.SAVE' | translate">
            </welion-button>
          </div>
          <!-- Società -->
          <div fxFlexOffset="16px" fxLayout="column" fxFlex>
            <h5 class="bold" translate="structures.round.attendances.modal.COMPANY"></h5>
            <h5 fxFlexOffset="4px">{{ selectedEntitled.user.socDistacco || '-' }}</h5>
          </div>
        </div>
      </div>

      <!-- Recapiti -->
      <div fxFlexOffset="12px" fxLayout="row" fxLayout.xs="column" fxLayoutAlign="space-between">
        <div class="full-width" fxLayout="column">
          <h5 class="bold" translate="structures.round.attendances.modal.CONTACT_DETAILS"></h5>
          <!-- Numero di telefono -->
          <div fxLayout="row" fxLayoutAlign="space-between center" class="full-width">
            <h5 *ngIf="!isEditPNView">{{ 'structures.round.attendances.modal.PHONE' | translate:
              { value: (selectedEntitled.user.phoneNumber || '-') }
              }}</h5>
            <welion-input *ngIf="isEditPNView" fxFlexOffset="4px" [bindValue]="phoneNumberUser"
              (onChange)="userPNChanged($event)" [placeholder]="'helpDesk.campaign.INSERT_PHONE_NUMBER' | translate">
            </welion-input>
            <welion-button *ngIf="!isEditPNView" (onClick)="editPN()" noUpperCase direction="right"
              type="on-edit-help-desk-user-data" [label]="'generic.EDIT' | translate">
            </welion-button>
            <welion-button *ngIf="isEditPNView" (onClick)="editPN(true)" noUpperCase direction="right"
              type="on-edit-help-desk-user-data" [label]="'generic.CLOSE' | translate">
            </welion-button>
            <welion-button [disabled]="!phoneNumberUser" *ngIf="isEditPNView"
              (onClick)="saveNewDataAttendance(false, false, false, true)" noUpperCase direction="right"
              type="on-edit-help-desk-user-data" [label]="'generic.SAVE' | translate">
            </welion-button>
          </div>
          <!-- Email -->
          <h5 fxFlexOffset="4px">{{ 'structures.round.attendances.modal.EMAIL' | translate:
            { value: (selectedEntitled.user.email || '-') }
            }}</h5>
          <!-- Stato -->
          <div fxFlexOffset="16px" fxLayout="column" fxFlexOffset.xs="0px">
            <h5 class="bold" translate="structures.round.attendances.modal.STATUS"></h5>
            <h5>{{ ('status.' + selectedEntitled.status) | translate }}</h5>
          </div>
        </div>
      </div>

      <div *ngIf="selectedEntitled.slot && selectedEntitled.slot.startDate && selectedEntitled.slot.endDate"
        fxFlexOffset="16px" fxLayout="row" fxLayoutAlign="space-between center">
        <!-- Prenotazione slot in sola lettura -->
        <div fxLayout="column" fxFlex="280px" fxFlex.xs="100">
          <!-- Prenotazione giorno e slot -->
          <div fxLayout="row">
            <h5 class="bold primary" translate="structures.round.attendances.modal.RESERVATION"></h5>
            <h5 class="bold" fxFlexOffset="4px" translate="structures.round.attendances.modal.DAY_SLOT"></h5>
          </div>
          <h5 fxFlexOffset="4px">
            {{ (selectedEntitled.slot.startDate | date: 'EEEE dd MMMM' | titlecase) +
            ' | ' +
            (selectedEntitled.slot.startDate | date: 'HH:mm') +
            ' - ' +
            (selectedEntitled.slot.endDate | date: 'HH:mm')}}</h5>

        </div>
      </div>
    </ng-container>

    <div fxFlexOffset="20px" fxLayout="row" fxLayoutAlign="end" fxLayoutAlign="space-between center">
      <!-- Chiudi -->
      <welion-button ngClass.xs="double-button-mobile" (onClick)="closeEntitledCard()" width="186px"
        label="generic.CLOSE" type="primary" size="medium">
      </welion-button>
    </div>

    <div fxFlexOffset="20px"></div>

  </div>


</ng-template>

<!-- Placeholder nessun avente diritto disponibile -->
<ng-template #noEntitleds>
  <div fxLayout="column" fxLayoutAlign="start center">
    <svg-icon [fxFlexOffset]="hasEntitledsFirstLoad ? '40px' : '20px'" [applyClass]="true"
      src="assets/img/icons/note-health-facility-red.svg"></svg-icon>

    <!-- NON CI SONO AVENTI DIRITTO DISPONIBILI -->
    <h3 fxFlexOffset="32px" translate="helpDesk.campaign.NO_ENTITLEDS"></h3>
  </div>
</ng-template>

<!-- Modale di annulla prenotazione -->
<modal id="deleteAdminBooking" (onClose)="closeDeleteBookingModal()" (onCancel)="closeDeleteBookingModal()"
  (onConfirm)="closeDeleteBookingModal(true)" [cancelLabel]="'generic.BACK' | translate | uppercase"
  [confirmLabel]="'generic.CONFIRM' | translate | uppercase" [disableAll]="isLoadingBase">
  <modal-text-content [withScrolledBody]="true"
    [title]="'admin.campaignRoundDetails.deleteAdminBooking.TITLE' | translate"
    [text]="'admin.campaignRoundDetails.deleteAdminBooking.RESET_WARNING' | translate: {value: attendanceToDelete && attendanceToDelete.user ? (attendanceToDelete.user.surname + ' ' + attendanceToDelete.user.forename) : ''}"
    [subtitle]="'admin.campaignRoundDetails.deleteRound.SUBTITLE' | translate">
  </modal-text-content>
</modal>

<!-- Modale di scelta round per pianificazione -->
<modal id="chooseBookingRound" (onClose)="closeChooseBookingRound()" (onCancel)="closeChooseBookingRound()"
  [cancelLabel]="'generic.ANNULL' | translate | uppercase"
  [disableAll]="isLoadingRounds && !this.selectedRoundForBooking">

  <div class="default-modal-content">
    <h2 translate="helpDesk.campaign.table.SCHEDULE"></h2>
    <h5 [innerHTML]="'helpDesk.campaign.table.SCHEDULE_DESCR' | translate"></h5>
    <!--p class="def-modal-info" translate="users.familyModal.CHOOSE"></p-->
    <!-- Radio selezione round-->
    <div class="modal-checkbox-content">
      <div *ngIf="isLoadingRounds" class="loader spinner-medium"></div>
      <div class="round-to-choose" *ngIf="!isLoadingRounds && (!roundsForModal || !roundsForModal.length)">
        <p class="no-data" translate="generic.NO_DATA_FOUND"></p>
      </div>
      <ng-container *ngIf="!isLoadingRounds && roundsForModal && roundsForModal.length">
        <div class="round-to-choose">
          <p *ngFor="let round of roundsForModal" (click)="closeChooseBookingRound(true, round)"
            [innerHTML]="round.title"></p>
        </div>
        <!--welion-radio toTranslate [items]="roundsForModal" [bindValue]="this.selectedRoundForBooking" radioName="choice"
          (onChange)="selectRoundForBooking($event)">
        </welion-radio-->
      </ng-container>
    </div>

    <div class="modal-divisor"></div>
  </div>
</modal>
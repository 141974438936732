<div class="sidenav-informations-container" fxLayout="column" fxLayoutAlign="start start">
  <div class="sidenav" fxLayout="column" fxLayoutAlign="space-between start">

    <div *ngIf="isBackButton; else noBack" class="total-info-container" fxLayout="column">

      <div class="back-button-container" fxLayout="column" fxLayoutAlign="start start">
        <welion-button (onClick)="goBack()" label="generic.GO_BACK" type="primary link" size="medium" hasLeftIcon>
        </welion-button>
      </div>
      <div class="data-info" fxLayout="column">
        <h2 class="small bold" [innerHTML]="title"></h2>
        <div class="orange-divisor"></div>
        <h6 class="small black" fxHide.xs fxHide.sm [innerHTML]="description"></h6>
        <div fxHide.xs fxHide.sm class="full-width">
          <kaltura-player *ngIf="true" [playerVisible]="true" [kalturaScript]="kalturaScript" customId="kalturaScript">
          </kaltura-player>
        </div>
        <a *ngIf="false" fxHide fxShow.xs fxShow.sm class="black-link" translate="generic.SEE_TUTORIAL"></a>
      </div>
    </div>

    <ng-template #noBack>
      <div class="container-title" fxLayout="column" fxLayoutAlign="start start">
        <h1>Welion<br>
          <b>Campagne Salute</b>
        </h1>
        <div class="orange-divisor"></div>
      </div>
    </ng-template>

    <div fxHide.xs fxHide.sm class="logo-info-container" fxLayout="column" fxLayoutAlign="start start">
      <div class="logo">
        <svg-icon class="welion-logo" [applyClass]="true" src="/assets/img/icons/app-logo.svg">
        </svg-icon>
      </div>
    </div>
  </div>
</div>

import { ActivatedRoute } from "@angular/router";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { Component, OnDestroy, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Observable, Subject } from 'rxjs';
import { Store } from "@ngrx/store";
import { takeUntil } from "rxjs/operators";

import * as fromApp from "../../../ngrx/app.reducers";
import * as CoreActions from "../../../core/ngrx/core.actions";

import { RedirectService } from 'src/app/shared/services/redirect.service';
import { getWarningModal } from "src/app/utils/utils";
import { ScrollTo } from "src/app/core/services/scroll-to.service";
import { SenecaResponse } from "atfcore-commonclasses/bin/classes/common";
import { RentService } from "src/app/structure/services/rent.service";
import { InfoCampaign } from "src/commonclasses";

@Component({
  selector: 'app-admin-add-campaign',
  templateUrl: './addCampaign.component.html',
  styleUrls: ['./addCampaign.component.scss']
})
export class AdminAddCampaignComponent implements OnInit, OnDestroy {

  isLoading: boolean = false;

  campaignId: string = '';
  campaign?: InfoCampaign;

  addCampaignForm = new FormGroup({
    'name': new FormControl(undefined, Validators.required)
  });

  get name() {
    return this.addCampaignForm.get('name') as FormControl;
  }

  private _unsubscribeSignal$: Subject<void> = new Subject();

  constructor(
    private route: ActivatedRoute,
    private appStore: Store<fromApp.AppState>,
    private translate: TranslateService,
    private redirectService: RedirectService,
    private scrollTo: ScrollTo,
    private rentService: RentService
  ) {
  }

  ngOnInit() {
    this.scrollTo.header();

    this.campaignId = this.route.snapshot.paramMap.get('campaignId') || '';

    if (this.campaignId) {
      this.isLoading = true;
      this.getCampaign();
    }
  }

  getCampaign() {
    this.rentService.getCampaign(this.campaignId)
      .pipe(takeUntil(this._unsubscribeSignal$.asObservable()))
      .subscribe((result) => {
        if (!result || result.error) {
          this.dispatchWarningModal('044', result.error || '');
        } else if (result.response) {
          this.campaign = result.response;
          this.name.setValue(this.campaign.title);
        }
        this.isLoading = false;
      }, (err) => {
        if (err && err.message) {
          this.dispatchWarningModal('045', err.message);
        }
        this.isLoading = false;
      });
  }

  ngOnDestroy() {
    this._unsubscribeSignal$.next();
    this._unsubscribeSignal$.unsubscribe();
  }

  /**
   * @description Va all'homepage delle SS
   */
  goBack() {
    if (this.campaign) {
      this.redirectService.goToAdminCampaignTabRounds(this.campaignId);
    } else {
      this.redirectService.goToAdminCampaigns();
    }
  }

  goToFAQ() {

  }

  confirmAddCampaign() {
    if (this.addCampaignForm.valid) {
      this.isLoading = true;

      let request: Observable<SenecaResponse<boolean>> = this.campaign ?
        this.rentService.updateCampaignForAdmin(this.campaignId, this.name.value) :
        this.rentService.createCampaignForAdmin(this.name.value);

      request
        .pipe(takeUntil(this._unsubscribeSignal$.asObservable()))
        .subscribe((result) => {
          if (!result || result.error) {
            this.dispatchWarningModal('041', result.error || '');
          } else if (result.response) {
            if (this.campaign) {
              this.redirectService.goToAdminCampaignTabRounds(this.campaignId);
            } else {
              this.redirectService.goToAdminCampaigns();
            }
          }
          this.isLoading = false;
        }, (err) => {
          if (err && err.message) {
            this.dispatchWarningModal('042', err.message);
          }
          this.isLoading = false;
        });
    }
  }

  /**
   * @description Mostra una modale con un messaggio di errore
   * @param modalId
   * @param error
   */
  dispatchWarningModal(modalId: string, error: string) {
    const messageObj = getWarningModal(this.translate, modalId, error);
    this.appStore.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
  }

}

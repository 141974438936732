import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';

@Component({
    selector: 'modal-text-content',
    templateUrl: 'modal-text-content.component.html',
    styleUrls: ['./modal-text-content.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ModalTextContentComponent implements OnInit {
    @Input() modalId?: string = ''
    @Input() title?: string = '';
    @Input() subtitle?: string = '';
    @Input() text?: string = '';
    @Input() badge?: string;
    @Input() badgeDescr?: string;
    @Input() noBackground?: boolean;
    @Input() withScrolledBody?: boolean;

    constructor() { }

    ngOnInit() {

    }

}

<div class="page-content-wrapper" fxLayout="column">

  <!-- Strutture sanitarie -->
  <h1 fxFlexOffset="40px" class="primary" translate="admin.healthFacilityDetails.TITLE"></h1>

  <bread-crumbs fxFlexOffset="12px" firstLevel="HEALTH_FACILITIES">
  </bread-crumbs>

  <div fxFlexOffset="40px" class="health-facility-resume" fxLayout="row" fxLayoutAlign="space-between center">
    <svg-icon class="note" [applyClass]="true" src="assets/img/icons/note-health-facility-yellow.svg"></svg-icon>

    <div fxLayout="column" fxFlexOffset="100px">
      <ng-container *ngIf="!isLoadingBase && healthFacilitySupplier">
        <h5 class="bold" translate="admin.healthFacilityDetails.HEALTH_FACILITY"></h5>
        <h5>{{ healthFacilitySupplier.name }}</h5>
      </ng-container>
      <div *ngIf="isLoadingBase || !healthFacilitySupplier" class="loader spinner-small"></div>
    </div>

  </div>

  <div fxFlexOffset="40px" class="health-facility-details-container" fxLayout="column">

    <div class="health-facility-details-tabs" fxLayout="row">
      <!-- Sedi -->
      <tab-light [isCurrentTab]="selectedTab === 0" (onClick)="onTabClick(0)"
        [tabTitle]="'admin.healthFacilityDetails.tab.venues.TITLE' | translate"
        [attrAriaLabel]="'admin.healthFacilityDetails.tab.venues.TITLE' | translate">
      </tab-light>

      <!-- Referenti -->
      <tab-light fxFlexOffset="32px" [isCurrentTab]="selectedTab === 1"
        [tabTitle]="'admin.healthFacilityDetails.tab.references.TITLE' | translate"
        [attrAriaLabel]="'admin.healthFacilityDetails.tab.references.TITLE' | translate" (onClick)="onTabClick(1)">
      </tab-light>
    </div>

    <!-- Sedi -->
    <ng-container *ngIf="selectedTab === 0">

      <ng-container *ngIf="!isLoadingCount">
        <ng-container *ngIf="venuesCount > 0 else noVenues">

          <div id="admin-health-facility-details-anchor" fxFlexOffset="16px"></div>

          <div fxFlexOffset="16px" fxLayout="row" fxLayoutAlign="space-between center">
            <!-- Referenti -->
            <h3 fxFlexOffset="16px" translate="admin.healthFacilityDetails.tab.venues.TITLE_MIN"></h3>
          </div>

          <!-- Table header -->
          <div fxFlexOffset="24px">
            <!-- Nome sede -->
            <h5 class="bold" translate="admin.healthFacilityDetails.tab.venues.table.NAME" fxFlex="40"
              fxFlexOffset="16px">
            </h5>
            <!-- Indirizzo -->
            <h5 class="bold" translate="admin.healthFacilityDetails.tab.venues.table.ADDRESS" fxFlex>
            </h5>
          </div>

          <!-- Loader -->
          <div *ngIf="isLoading" class="loader spinner-big"></div>

          <ng-container *ngIf="!isLoading">
            <!-- Table rows -->
            <div *ngFor="let venue of venues; let index = index; let odd = odd" class="health-facility-row"
              [ngClass]="{'odd': odd}" fxLayout="row" fxLayoutAlign="start center"
              [fxFlexOffset]="index === 0 ? '16px' : '0px'">
              <!-- Nome sede -->
              <h6 *ngIf="!venue.venueCode" class="black" fxFlex="40" fxFlexOffset="16px">{{ venue.name }}</h6>
              <h6 *ngIf="venue.venueCode" class="black" fxFlex="40" fxFlexOffset="16px">{{ '[' + venue.venueCode + '] '
                + venue.name }}</h6>
              <!-- Indirizzo -->
              <h6 class="black" fxFlex="20">{{ venue.address }}</h6>
              <div fxFlex fxLayout="row" fxLayoutAlign="end">
              </div>
            </div>
          </ng-container>

          <!-- Paginatore -->
          <welion-paginator fxFlexOffset="20px" [isLoading]="isLoading" [paginationLength]="paginationLength"
            [itemsCount]="venuesCount" (onClickPage)="getVenuesByHealthFacility($event)"></welion-paginator>

        </ng-container>
      </ng-container>

      <div *ngIf="isLoadingCount" class="loader spinner-big"></div>

    </ng-container>

    <!-- Referenti -->
    <ng-container *ngIf="selectedTab === 1">

      <ng-container *ngIf="!isLoadingCount">
        <ng-container *ngIf="referencesCount > 0 else noReferences">

          <div id="admin-health-facility-details-anchor" fxFlexOffset="16px"></div>

          <div fxFlexOffset="16px" fxLayout="row" fxLayoutAlign="space-between center">
            <!-- Referenti -->
            <h3 fxFlexOffset="16px" translate="admin.healthFacilityDetails.tab.references.TITLE_MIN"></h3>
          </div>

          <!-- Table header -->
          <div fxFlexOffset="24px">
            <!-- Cognome e nome -->
            <h5 class="bold" translate="admin.healthFacilityDetails.tab.references.table.NAME" fxFlex="35"
              fxFlexOffset="16px">
            </h5>
            <!-- Email -->
            <h5 class="bold" translate="admin.healthFacilityDetails.tab.references.table.EMAIL" fxFlex>
            </h5>
          </div>

          <!-- Loader -->
          <div *ngIf="isLoading" class="loader spinner-big"></div>

          <ng-container *ngIf="!isLoading">
            <!-- Table rows -->
            <div *ngFor="let reference of references; let index = index; let odd = odd" class="health-facility-row"
              [ngClass]="{'odd': odd}" fxLayout="row" fxLayoutAlign="start center"
              [fxFlexOffset]="index === 0 ? '16px' : '0px'">
              <!-- Cognome e nome -->
              <h6 class="black" fxFlex="35" fxFlexOffset="16px">{{ reference.surname + ' ' + reference.forename }}</h6>
              <!-- Email -->
              <h6 class="black" fxFlex="20">{{ reference.email }}</h6>

              <div fxFlex fxLayout="row" fxLayoutAlign="end">
                <!-- Impersonifica -->
                <h6 class="black bold underline cursor-pointer" (click)="impersonateReference(reference)"
                  translate="admin.healthFacilityDetails.tab.references.table.IMPER"></h6>
              </div>
            </div>
          </ng-container>

          <!-- Paginatore -->
          <welion-paginator fxFlexOffset="20px" [isLoading]="isLoading" [paginationLength]="paginationLength"
            [itemsCount]="referencesCount" (onClickPage)="getReferencesByHealthFacility($event)"></welion-paginator>

        </ng-container>
      </ng-container>

      <div *ngIf="isLoadingCount" class="loader spinner-big"></div>

    </ng-container>

  </div>

</div>

<ng-template #noVenues>
  <div fxLayout="column" fxLayoutAlign="start center">
    <svg-icon fxFlexOffset="40px" [applyClass]="true" src="assets/img/icons/note-health-facility-red.svg"></svg-icon>

    <!-- Inserisci le sedi di questa struttura sanitaria! -->
    <h3 fxFlexOffset="32px" translate="admin.healthFacilityDetails.tab.venues.NO_VENUES"></h3>

  </div>
</ng-template>

<ng-template #noReferences>
  <div fxLayout="column" fxLayoutAlign="start center">
    <svg-icon fxFlexOffset="32px" [applyClass]="true" src="assets/img/icons/note-health-facility-references.svg">
    </svg-icon>

    <!-- Inserisci i referenti per questa struttura -->
    <h3 fxFlexOffset="32px" translate="admin.healthFacilityDetails.tab.references.NO_REFERENCES"></h3>

    <div fxLayout="row" fxFlexOffset="8px">
      <h5 class="center" fxFlex="748px" fxFlex.xs="100"
        translate="admin.healthFacilityDetails.tab.references.NO_REFERENCES_DESC"></h5>
    </div>


    <div fxFlex="80px"></div>
  </div>
</ng-template>


<!-- Modale per la cancellazione di un referente della SS -->
<modal id="deleteHealthFacilityReference" (onClose)="closeDeleteReferenceConfirmModal()"
  (onCancel)="closeDeleteReferenceConfirmModal()" (onConfirm)="confirmDeleteReference()" [disableAll]="isLoadingCount"
  [cancelLabel]="'generic.CLOSE' | translate | uppercase" [confirmLabel]="'generic.CONFIRM' | translate | uppercase">
  <modal-text-content [withScrolledBody]="true" [title]="'admin.healthFacilityDetails.deleteHealthFacilityReference.TITLE' | translate: {
      value: referenceToDelete ? (referenceToDelete.surname + ' ' + referenceToDelete.forename) : '' }"
    [text]="'admin.healthFacilityDetails.deleteHealthFacilityReference.RESET_WARNING' | translate"
    [subtitle]="'admin.healthFacilityDetails.deleteHealthFacilityReference.SUBTITLE' | translate">
  </modal-text-content>
</modal>
import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { InfoRound } from 'src/commonclasses';
import { RedirectService } from '../../services/redirect.service';


@Component({
  selector: 'card-round-home',
  templateUrl: 'card-round-home.component.html',
  styleUrls: ['./card-round-home.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CardRoundHomeComponent {

  _isActive: boolean = false;
  @Input() set isActive(value: boolean | string) {
    this._isActive = coerceBooleanProperty(value);
  };

  _round?: InfoRound;
  @Input() set round(value: InfoRound | undefined) {
    this._round = value;
  }

  @Input() venueId?: string;

  constructor(private redirectService: RedirectService) {
  }

  manageAttendance() {
    if (this._round) {
      this.redirectService.goToRoundAttendances(this._round.roundId, this.venueId);
    }
  }

  defineAvailability() {
    if (this._round) {
      this.redirectService.goToRoundSlots(this._round.roundId, this.venueId);
    }
  }
}

<div class="add-venue-container" fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="start start"
  fxLayoutAlign.lt-md="start center">

  <div class="add-venue-side" fxLayout="column" fxLayoutAlign="space-between">

    <div fxLayout="column" fxLayoutAlign="start start">
      <div class="add-venue-back" fxLayout="row" fxLayoutAlign="start center" fxFlexOffset="40px" (click)="goBack()">
        <svg-icon fxFlexOffset="40px" [applyClass]="true" src="./assets/img/icons/arrow-white-left.svg"></svg-icon>
        <h5 class="white" fxFlexOffset="12px">{{ 'generic.GO_BACK' | translate | uppercase}}</h5>
      </div>

      <p fxFlexOffset="24px" *ngIf="healthFacility">
        <ng-container *ngIf="!venue">
          <span translate="admin.addHealthFacilityVenue.SIDE_TITLE"></span>
        </ng-container>
        <ng-container *ngIf="venue">
          <span translate="admin.addHealthFacilityVenue.SIDE_TITLE_UPDATE"></span>
          <span class="bold">{{ venue.name }}</span>
          <span translate="admin.addHealthFacilityVenue.SIDE_TITLE_UPDATE2"></span>
        </ng-container>
        <span class="bold">{{ healthFacility.name }}</span>
      </p>

      <div fxFlexOffset="24px" class="add-venue-tick"></div>

      <div fxShow fxHide.lt-md class="add-venue-faq" fxFlexOffset="48px" fxLayout="row">
        <h5 translate="generic.FAQ"></h5>
        <h5 fxFlexOffset="4px" translate="generic.FAQ2" class="primary bold faq-link" (click)="goToFAQ()"></h5>
      </div>
    </div>

    <svg-icon fxShow fxHide.lt-md class="welion-logo" [applyClass]="true" src="./assets/img/icons/app-logo.svg">
    </svg-icon>

    <div fxShow fxHide.lt-md fxFlexOffset="40px"></div>
    <div fxShow fxHide.lt-md fxFlexOffset="40px"></div>

  </div>

  <div fxFlex fxLayout="row" fxLayout.xs="column" fxLayoutAlign="center" fxLayoutAlign.xs="start"
    class="add-venue-body-container">

    <form class="add-venue-body" [formGroup]="addVenueForm" fxLayout="column" fxLayoutAlign="start center"
      (ngSubmit)="confirmAddVenue()">

      <!-- Nuova sede -->
      <ng-container *ngIf="!venue">
        <h3 translate="admin.addHealthFacilityVenue.TITLE" ngClass.xs="center"></h3>
      </ng-container>
      <ng-container *ngIf="venue">
        <h3 translate="admin.addHealthFacilityVenue.TITLE_UPDATE" ngClass.xs="center"></h3>
      </ng-container>

      <!-- Stato struttura -->
      <input-container fxFlexOffset="24px" class="full-width"
        [title]="'admin.addHealthFacilityVenue.VENUE_STATUS' | translate">
        <welion-switch [checked]="isActive.value" (onSwitch)="onStatusChange($event)"
          [leftLabelObject]="'admin.addHealthFacilityVenue.VENUE_STATUS_NOT_ACTIVE' | translate"
          [rightLabelObject]="'admin.addHealthFacilityVenue.VENUE_STATUS_ACTIVE' | translate">
        </welion-switch>
      </input-container>

      <!-- Nome sede -->
      <input-container fxFlexOffset="24px" class="full-width"
        [title]="'admin.addHealthFacilityVenue.VENUE_NAME' | translate">
        <welion-input [inputValue]="name"
          [placeholder]="'admin.addHealthFacilityVenue.VENUE_NAME_PLACEHOLDER' | translate" [disabled]="isLoading">
        </welion-input>
      </input-container>

      <!-- Codice univoco -->
      <input-container fxFlexOffset="24px" class="full-width"
        [title]="'admin.addHealthFacilityVenue.UNIQUE_CODE' | translate">
        <welion-input [inputValue]="venueCode"
          [placeholder]="'admin.addHealthFacilityVenue.UNIQUE_CODE_PLACEHOLDER' | translate" [disabled]="isLoading">
        </welion-input>
      </input-container>

      <!-- Indirizzo -->
      <input-container fxFlexOffset="24px" class="full-width" [inputEditable]="disabledAddress.value"
        (renderInputEditable)="onEditAddress()" [title]="'admin.addHealthFacilityVenue.VENUE_ADDRESS' | translate">
        <welion-input [inputValue]="address" hasMapsAutocomplete (selectedPlace)="selectedPlace($event)"
          [placeholder]="'admin.addHealthFacilityVenue.VENUE_ADDRESS_PLACEHOLDER' | translate"
          [disabled]="isLoading || disabledAddress.value">
        </welion-input>
      </input-container>

      <!-- Descrizione -->
      <input-container fxFlexOffset="24px" class="full-width"
        [title]="'admin.addHealthFacilityVenue.VENUE_DESC_PRIVATE' | translate">
        <welion-text-area [inputValue]="privateDescription" [disabled]="isLoading"></welion-text-area>
      </input-container>

      <!-- Descrizione inserita dalla struttura/sede -->
      <input-container fxFlexOffset="24px" class="full-width"
        [title]="'admin.addHealthFacilityVenue.VENUE_DESC_PUBLIC' | translate">
        <welion-text-area [inputValue]="publicDescription" [disabled]="isLoading"></welion-text-area>
      </input-container>

      <!-- Cliente -->
      <input-container fxFlexOffset="24px" class="full-width"
        [title]="'admin.addHealthFacilityVenue.VENUE_CUSTOMER' | translate">
        <welion-select [placeholder]="'admin.addHealthFacilityVenue.VENUE_CUSTOMER_PLACEHOLDER' | translate"
          [items]="customers" [selectedItem]="customer.value" (change)="onChangeCustomer($event)"></welion-select>
      </input-container>

      <!-- Gestione del vaccino sotto ai 6 anni -->
      <input-container fxFlexOffset="24px" class="full-width"
        [title]="'admin.addHealthFacilityVenue.VACCINATION_MANAGEMENT_UNDER_SIX' | translate">
        <welion-radio toTranslate [items]="vaccinationForMinorRanges" [bindValue]="vaccinationUnderSix.value"
          radioName="choice" (onChange)="onVaccinationUnderSixChange($event)">
        </welion-radio>
      </input-container>

      <!-- Gestione del vaccino dai 6 ai 9 anni -->
      <input-container fxFlexOffset="24px" class="full-width"
        [title]="'admin.addHealthFacilityVenue.VACCINATION_MANAGEMENT_FROM_NINE_TO_SIX' | translate">
        <welion-radio toTranslate [items]="vaccinationForMinorRanges" [bindValue]="vaccinationFromNineToSix.value"
          radioName="choice" (onChange)="onVaccinationFromNineToSixChange($event)">
        </welion-radio>
      </input-container>

      <!-- Gestione del vaccino dai 9 ai 12 anni -->
      <input-container fxFlexOffset="24px" class="full-width"
        [title]="'admin.addHealthFacilityVenue.VACCINATION_MANAGEMENT_FROM_NINE_TO_TWELVE' | translate">
        <welion-radio toTranslate [items]="vaccinationForMinorRanges" [bindValue]="vaccinationFromTwelveToNine.value"
          radioName="choice" (onChange)="onVaccinationFromTwelveToNineChange($event)">
        </welion-radio>
      </input-container>

      <!-- Gestione del vaccino dai 12 ai 18 anni -->
      <input-container fxFlexOffset="24px" class="full-width"
        [title]="'admin.addHealthFacilityVenue.VACCINATION_MANAGEMENT_UNTIL_TWELVE' | translate">
        <welion-radio toTranslate [items]="vaccinationForMinorRanges" [bindValue]="vaccinationUntilTwelve.value"
          radioName="choice" (onChange)="onVaccinationUntilTwelveChange($event)">
        </welion-radio>
      </input-container>

      <!-- Seconda somministrazione per minori di 9 anni mai vaccinati -->
      <input-container fxFlexOffset="24px" class="full-width"
        [title]="'admin.addHealthFacilityVenue.VACCINATION_SECOND_DOSE_UNDERAGE' | translate">
        <welion-switch [checked]="underNineSecondSomministration.value"
          (onSwitch)="onUnderNineSecondSomministrationChange($event)" [leftLabelObject]="'generic.NO' | translate"
          [rightLabelObject]="'generic.YES' | translate">
        </welion-switch>
      </input-container>

      <div *ngIf="isLoading" class="loader spinner-big"></div>

      <!-- Crea sede -->
      <div fxFlexOffset="24px" class="full-width" fxLayout="row" fxLayoutAlign="end">
        <welion-button width="264px" type="primary" size="large" [disabled]="addVenueForm.invalid || isLoading"
          [label]="('admin.addHealthFacilityVenue.CONFIRM' + (venue ? '_UPDATE' : ''))" buttonType="submit">
        </welion-button>
      </div>

      <div fxHide fxShow.xs fxFlex.xs="40px"></div>

    </form>

  </div>

</div>

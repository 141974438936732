import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { map, switchMap, mergeMap, withLatestFrom, tap } from "rxjs/operators";
import { from } from "rxjs";
import { catchError } from "rxjs/operators";
import * as CoreActions from "../../core/ngrx/core.actions";
import * as ProfileActions from "../../core/profile/ngrx/profile.actions";
import { Store } from "@ngrx/store";
import * as AuthActions from "./auth.actions";
import { AuthService } from "../services/auth.service";
import * as fromApp from "../../ngrx/app.reducers";
import { JwtPayload, SenecaResponse, UserAcknowledges } from "../../../commonclasses";
import { DeviceDetectorService } from 'ngx-device-detector';
import { TranslateService } from "@ngx-translate/core";
import { ApplicationModalMessage } from "src/app/core/ngrx/core.reducers";
import { RedirectService } from "src/app/shared/services/redirect.service";
import { AnagService } from "../services/anag.service";
import { ModalService } from "src/app/shared/components/modal/modal.service";

@Injectable()
export class AuthEffects {
  token: string = '';
  tinyToken: string = '';
  redirectUrl: string = '';
  authDataMail: string = '';
  tinyTokenObj = { response: '' };

  constructor(
    private store: Store<fromApp.AppState>,
    private actions$: Actions,
    private anagService: AnagService,
    private authService: AuthService,
    private redirectService: RedirectService,
    private deviceService: DeviceDetectorService,
    private translate: TranslateService,
    private modalService: ModalService
  ) {
    this.store.select(fromApp.getRedirectUrl).subscribe(redirectUrl => {
      this.redirectUrl = redirectUrl;
    });
  }

  authLogin$ = createEffect(() =>
    this.actions$
      .pipe(
        ofType(AuthActions.DoLogin),
        switchMap(
          (authData: {
            email: string;
            password: string;
            isSso?: boolean;
            isUser?: boolean;
            isStructure?: boolean;
            isAdmin?: boolean;
            isHelpDesk?: boolean;
            isClient?: boolean;
            captchaToken?: string;
          }) => {
            this.authDataMail = authData.email;
            const deviceInfo = this.deviceService.getDeviceInfo();
            const userAgent = deviceInfo && deviceInfo.userAgent;
            let deviceType;
            if (this.deviceService.isMobile()) {
              // Salvo il fatto che è uno smartphone
              deviceType = "P";
            } else if (this.deviceService.isTablet()) {
              // Salvo il fatto che è un tablet
              deviceType = "T";
            } else if (this.deviceService.isDesktop()) {
              // Salvo il fatto che è un computer desktop
              deviceType = "D";
            }

            if (authData.isSso) {
              return from(
                this.authService.loginProd(
                  authData.email,
                  authData.password,
                  deviceType,
                  userAgent
                )
              );
            } else {
              if (authData.isUser) {
                return from(
                  this.authService.loginEntitledLocalPassword(
                    authData.email,
                    authData.password,
                    deviceType,
                    userAgent,
                    authData.captchaToken
                  )
                );
              } else if (authData.isStructure) {
                return from(
                  this.authService.loginStructure(
                    authData.email,
                    authData.password,
                    deviceType,
                    userAgent,
                    authData.captchaToken
                  )
                );
              } else if (authData.isHelpDesk || authData.isAdmin) {
                return from(
                  this.authService.loginLocalPassword(
                    authData.email,
                    authData.password,
                    deviceType,
                    userAgent,
                    authData.captchaToken
                  )
                );
              } else if (authData.isClient) {
                return from(
                  this.authService.loginCustomer(
                    authData.email,
                    authData.password,
                    deviceType,
                    userAgent,
                    authData.captchaToken
                  )
                );
              } else {
                return from(
                  this.authService.loginEntitledLocalPassword(
                    authData.email,
                    authData.password,
                    deviceType,
                    userAgent,
                    authData.captchaToken
                  )
                );
              }
            }
          }
        ),
        switchMap((tinyTokenObj: any) => {
          if (tinyTokenObj.error) {
            throw new Error(tinyTokenObj.error);
          }
          this.tinyTokenObj = tinyTokenObj;
          return from(this.authService.getJWTToken());
        }),
        map((tinyTokenObj: SenecaResponse<string>) => {
          return this.store.dispatch(AuthActions.SetToken({ payload: tinyTokenObj.response }));
        }),
        map(() => {
          return this.store.dispatch(AuthActions.SetUserAuthenticated());
        }),
        map(() => {
          return [
            this.store.dispatch(CoreActions.StartRenewTokenPolling({ payload: { redirectUrl: this.redirectUrl || "defaultPage" } })),
            this.store.dispatch(CoreActions.RemoveRedirectUrl())
          ];
        }),
        catchError((err, caught) => {
          if (err && err.message == "ENTITLED_USER_NOT_FOUND") {
            this.modalService.open("user-deleted-home")
          } else if (err && err.message) {
            let messageObj: ApplicationModalMessage = {
              modalId: "023",
              text: this.translate.instant("errors." + err.message),
              title: this.translate.instant("generic.WARNING")
            }
            if (err.message.indexOf("RECAPTCHA_CALL_ERROR") >= 0) {
              messageObj.text = this.translate.instant("errors.RECAPTCHA_CALL_ERROR")
            } else if (err.message.indexOf("NOT_VALID_RECAPTCHA_CODE") >= 0) {
              messageObj.text = this.translate.instant("errors.NOT_VALID_RECAPTCHA_CODE")
            } else if (err.message.indexOf("MISSING_RECAPTCHA_RESULT") >= 0) {
              messageObj.text = this.translate.instant("errors.MISSING_RECAPTCHA_RESULT")
            }
            this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
          }
          return caught;
        })
      ), { dispatch: false }
  )

  authLoginSSO$ = createEffect(() =>
    this.actions$
      .pipe(
        ofType(AuthActions.DoLoginSSO),
        map(
          (authData: {
            tinyToken: string;
          }) => {
            return this.store.dispatch(AuthActions.SetToken({ payload: authData.tinyToken }));
          }
        ),
        map(() => {
          return this.store.dispatch(AuthActions.SetUserAuthenticated());
        }),
        map(() => {
          return [
            this.store.dispatch(CoreActions.StartRenewTokenPolling({ payload: { redirectUrl: this.redirectUrl || "defaultPage" } })),
            this.store.dispatch(CoreActions.RemoveRedirectUrl())
          ];
        }),
        catchError((err, caught) => {
          if (err && err.message) {
            const messageObj: ApplicationModalMessage = {
              modalId: "023",
              text: this.translate.instant("errors." + err.message),
              title: this.translate.instant("generic.WARNING")
            }
            this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
          }
          return caught;
        })
      ), { dispatch: false }
  )

  authImpersonationLogin$ = createEffect(() =>
    this.actions$
      .pipe(
        ofType(AuthActions.ImpersonationLogin),
        switchMap(
          (authData: {
            impersonationToken: string;
          }) => {
            this.tinyTokenObj = { response: authData.impersonationToken };
            return from(this.authService.getJWTToken(authData.impersonationToken));
          }
        ),
        map((tinyTokenObj: SenecaResponse<string>) => {
          return this.store.dispatch(AuthActions.SetToken({ payload: this.tinyTokenObj.response }));
        }),
        map(() => {
          return this.store.dispatch(AuthActions.SetUserAuthenticated());
        }),
        map(() => {
          return [
            this.store.dispatch(CoreActions.StartRenewTokenPolling({ payload: { redirectUrl: this.redirectUrl || "defaultPage", forceRefreshUser: true } })),
            this.store.dispatch(CoreActions.RemoveRedirectUrl())
          ];
        }),
        catchError((err, caught) => {
          if (err && err.message) {
            const messageObj: ApplicationModalMessage = {
              modalId: "023",
              text: this.translate.instant("errors." + err.message),
              title: this.translate.instant("generic.WARNING")
            }
            this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
            this.redirectService.goToLogin();
          }
          return caught;
        })
      ), { dispatch: false }
  )

  // Effect che recupera la lista delle userAcknowledges dell'utente collegato
  acknowledgedGet$ = createEffect(() =>
    this.actions$
      .pipe(
        ofType(AuthActions.RetrieveUserAcknowledges)
        , switchMap(() => {
          return from(this.anagService.getAllUserAcknowledges());
        })
        , map(
          (data: SenecaResponse<UserAcknowledges>) => {
            if (data) {
              if (data.error) {
                throw (new Error(data.error));
              } else {
                const ackAction: any = AuthActions.SetUserAcknowledges({ payload: data.response });
                return ackAction;
              }
            }
          }
        )
        , catchError((err, caught) => {
          if (err && err.message) {
            const messageObj: ApplicationModalMessage = {
              modalId: "024",
              text: this.translate.instant("errors." + err.message),
              title: this.translate.instant("generic.WARNING")
            }
            this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
          }
          AuthActions.SetUserAcknowledges({ payload: null });
          return caught;
        })
      )
  )

  // Effect che aggiorna la lista delle userAcknowledges dell'utente collegato
  acknowledgedUpdate$ = createEffect(() =>
    this.actions$
      .pipe(
        ofType(AuthActions.UpdateUserAcknowledges)
        , switchMap((action: any) => {
          return from(this.anagService.updateUserAcknowledges(action.payload));
        })
        , map(
          (data: any) => {
            if (data) {
              if (data.error) {
                // Catturo l'errore
                throw (new Error(data.error));
              } else {
                const setAckAction: any = AuthActions.RetrieveUserAcknowledges();
                return setAckAction;
              }
            }
          }
        )
        , catchError((err, caught) => {
          if (err && err.message) {
            const messageObj: ApplicationModalMessage = {
              modalId: "025",
              text: this.translate.instant("errors." + err.message),
              title: this.translate.instant("generic.WARNING")
            }
            this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
          }
          AuthActions.SetUserAcknowledges({ payload: null });
          return caught;
        })
      )
  );

  authLogout$ = createEffect(() =>
    this.actions$
      .pipe(
        ofType(AuthActions.Logout)
        , switchMap(() => {
          return from(this.authService.logout());
        })
        , mergeMap(
          (data: SenecaResponse<null>) => {
            if (data.response === null && data.error === null) {
              let actionsContainer = [
                AuthActions.SessionLogout(),
                AuthActions.SetUserAcknowledges({ payload: null }),
                CoreActions.RemoveApplicationLang(),
                ProfileActions.CancelLoggedUser()
              ];
              // Da decommentare se ci sarà l'sso this.router.navigate(['/login']);
              this.redirectService.goToLogin();
              return actionsContainer;
            }
            return [];
          }
        )
        , catchError((err, caught) => {
          if (err && err.message) {
            const messageObj: ApplicationModalMessage = {
              modalId: "026",
              text: this.translate.instant("errors." + err.message),
              title: this.translate.instant("generic.WARNING")
            }
            this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
          }
          return caught;
        })
      )
  );

  authOriginalLogin$ = createEffect(() =>
    this.actions$
      .pipe(
        ofType(AuthActions.DoOriginalLogin),
        switchMap(() => {
          return this.authService.loginOriginalUser();
        }),
        tap((senecaResponse: SenecaResponse<null>) => {
          if (senecaResponse.error) {
            throw new Error(senecaResponse.error);
          }

          return this.redirectService.goToPreImpersonificationPage();
        }),
        catchError((err, caught) => {
          if (err && err.message) {
            let messageObj: ApplicationModalMessage = {
              modalId: "023",
              text: this.translate.instant("errors." + err.message),
              title: this.translate.instant("generic.WARNING")
            }
            this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
          }
          return caught;
        })
      ), { dispatch: false }
  )
}


<div class="assign-client-to-campaign-container" fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="start start"
  fxLayoutAlign.lt-md="start center">

  <div class="assign-client-to-campaign-side" fxLayout="column" fxLayoutAlign="space-between">

    <div fxLayout="column" fxLayoutAlign="start start">
      <div class="assign-client-to-campaign-back" fxLayout="row" fxLayoutAlign="start center" fxFlexOffset="40px"
        (click)="goBack()">
        <svg-icon fxFlexOffset="40px" [applyClass]="true" src="./assets/img/icons/arrow-white-left.svg"></svg-icon>
        <h5 class="white" fxFlexOffset="12px">{{ 'generic.GO_BACK' | translate | uppercase}}</h5>
      </div>

      <p fxFlexOffset="24px">
        <ng-container *ngIf="!isLoadingBase && campaign">
          <span translate="admin.assignClientToCampaign.SIDE_TITLE"></span>
          <span class="bold">{{ campaign.title }}</span>
        </ng-container>
        <ng-container *ngIf="isLoadingBase || !campaign">
          <span translate="generic.LOADING"></span>
        </ng-container>
      </p>

      <div fxFlexOffset="24px" class="assign-client-to-campaign-tick"></div>

    </div>

    <svg-icon fxShow fxHide.lt-md class="welion-logo" [applyClass]="true" src="./assets/img/icons/app-logo.svg">
    </svg-icon>

    <div fxShow fxHide.lt-md fxFlexOffset="40px"></div>

  </div>

  <div fxFlex fxLayout="row" fxLayout.xs="column" fxLayoutAlign="center" fxLayoutAlign.xs="start"
    class="assign-client-to-campaign-body-container">

    <div class="assign-client-to-campaign-body" fxLayout="column" fxLayoutAlign="start center">

      <!-- Assegna cliente -->
      <h3 translate="admin.assignClientToCampaign.TITLE" ngClass.xs="center"></h3>

      <div *ngIf="isLoadingCount" class="loader spinner-big"></div>

      <ng-container *ngIf="!isLoadingCount">
        <div *ngIf="customersCount > 0 else noCustomers" class="full-width" fxLayout="column">

          <ng-container *ngIf="!isLoading">
            <!-- Table rows -->
            <div *ngFor="let customer of customers; let index = index" class="customer-row" fxLayout="row"
              fxLayoutAlign="start center" [fxFlexOffset]="index === 0 ? '32px' : '0px'">
              <!-- Checkbox -->
              <welion-check fxFlexOffset="16px" [checked]="customerIsChecked(customer)"
                (onCheck)="onCustomerChecked(customer, $event)">

              </welion-check>
              <!-- Nome cliente -->
              <h6 class="black" fxFlex fxFlexOffset="20px">{{ customer.title }}</h6>

              <div fxFlex="16px"></div>
            </div>
          </ng-container>

          <div *ngIf="isLoading" class="loader spinner-big"></div>

          <!-- Paginatore -->
          <welion-paginator fxFlexOffset="20px" [isLoading]="isLoading" [paginationLength]="paginationLength"
            [itemsCount]="customersCount" (onClickPage)="listCustomers($event)"></welion-paginator>

          <!-- Assegna cliente -->
          <div fxFlexOffset="24px" class="full-width" fxLayout="row" fxLayoutAlign="end">
            <welion-button width="264px" type="primary" size="large" label="admin.assignClientToCampaign.CONFIRM"
              [disabled]="selectedCustomers.length === 0 || isLoading" buttonType="submit"
              (onClick)="confirmAssignCustomers()">
            </welion-button>
          </div>

        </div>
      </ng-container>

    </div>

  </div>

</div>

<ng-template #noCustomers>
  <div fxLayout="column" fxLayoutAlign="start center">
    <svg-icon fxFlexOffset="40px" [applyClass]="true" src="assets/img/icons/note-health-facility-red.svg"></svg-icon>

    <!-- Non sono presenti sedi assegnabili al round -->
    <h4 fxFlexOffset="32px" translate="admin.assignClientToCampaign.NO_CUSTOMERS"></h4>

  </div>
</ng-template>

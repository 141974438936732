<div class="welion-order-select">
  <div class="column-header-wrapper" (click)="openDropdown()" [ngClass]="{'disabled': disabled}"
    (clickOutside)="isOpen = false">
    <p>{{ label | translate }}</p>
    <svg-icon [applyClass]="true" class="column-header-icon" [src]="getHeaderIcon()"></svg-icon>
  </div>
  <ng-container *ngIf="isOpen">
    <div class="column-dropdown-container">
      <div class="column-drop-item" *ngFor="let item of items" (click)="emitOnSelectedOrder(item)">
        <svg-icon [applyClass]="true" class="column-drop-icon" [src]="item.icon"></svg-icon>
        <p>{{item.label}}</p>
      </div>
    </div>
  </ng-container>
</div>
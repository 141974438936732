import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';

import * as CoreActions from "../../../core/ngrx/core.actions";
import * as fromApp from "../../../ngrx/app.reducers";
import { HealthFacilityInfo, InfoVenue } from 'atfcore-commonclasses/bin/classes/campaignmanager';
import { SupplierPerson } from 'atfcore-commonclasses/bin/classes/supplier';

import { RedirectService } from 'src/app/shared/services/redirect.service';
import { getModalMessageData, getWarningModal } from 'src/app/utils/utils';
import { SupplierService } from '../../services/supplier.service';
import { ScrollTo } from 'src/app/core/services/scroll-to.service';
import { AuthService } from 'src/app/auth/services/auth.service';
import { RentService } from 'src/app/structure/services/rent.service';
import { ApplicationModalMessage } from 'src/app/core/ngrx/core.reducers';
import { ModalService } from 'src/app/shared/components/modal/modal.service';
import { ImportService } from 'src/app/structure/services/import.service';

@Component({
  selector: 'app-admin-health-facility-details',
  templateUrl: './healthFacilityDetails.component.html',
  styleUrls: ['./healthFacilityDetails.component.scss']
})
export class AdminHealthFacilityDetailsComponent implements OnInit, OnDestroy {

  healthFacilitySupplierId: string = '';

  isLoading: boolean = true;
  isLoadingCount: boolean = true;
  isLoadingBase: boolean = true;

  healthFacilitySupplier?: HealthFacilityInfo;

  selectedTab: number = 0;

  venues: InfoVenue[] = [];
  venuesCount: number = 0;

  references: SupplierPerson[] = [];
  referencesCount: number = 0;

  readonly paginationLength: number = 25;

  isToResetUploadComponent: boolean = false;
  fileObject: any = null;
  uploadAreaModalTooltip: ApplicationModalMessage = {
    modalId: '---',
    title: '',
    text: ''
  };

  venueToDelete?: InfoVenue;
  referenceToDelete?: SupplierPerson;

  private _unsubscribeSignal$: Subject<void> = new Subject();

  isLimitedAdmin: boolean = false;
  referenceSelected: any = null;

  constructor(
    private route: ActivatedRoute,
    private scrollTo: ScrollTo,
    private appStore: Store<fromApp.AppState>,
    public translate: TranslateService,
    private rentService: RentService,
    private supplierService: SupplierService,
    private authService: AuthService,
    public redirectService: RedirectService,
    private modalService: ModalService,
    private importService: ImportService,
    private router: Router
  ) {

  }

  ngOnInit() {
    this.scrollTo.header();
    this.healthFacilitySupplierId = this.route.snapshot.paramMap.get('healthFacilitySupplierId') || '';

    if (this.redirectService.isThisCurrentPage('references')) {
      this.selectedTab = 1
    }

    if (this.healthFacilitySupplierId) {
      this.getHealthFacility();

      this.appStore.select(fromApp.getIsLimitedAdmin)
        .pipe(takeUntil(this._unsubscribeSignal$.asObservable()))
        .subscribe(
          (isLimitedAdmin) => {
            this.isLimitedAdmin = isLimitedAdmin;
          });
    } else {
      this.redirectService.goToHome();
    }
  }

  ngOnDestroy() {
    this._unsubscribeSignal$.next();
    this._unsubscribeSignal$.unsubscribe();
  }

  /**
   * @description Mostra una modale con titolo e messaggio
   * @param modalId
   * @param title
   * @param message
   */
  dispatchMessageModal(title: string, message: string) {
    const messageObj = getModalMessageData(this.translate, title, message);
    this.appStore.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
  }

  /**
   * @description Mostra una modale con un messaggio di errore
   * @param modalId
   * @param error
   */
  dispatchModal(modalId: string, error: string) {
    const messageObj = getWarningModal(this.translate, modalId, error);
    this.appStore.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
  }

  // Verifica se sono in una particolare pagina
  isThisCurrentPage(page: string): boolean {
    return page && this.router && this.router.url && this.router.url.indexOf(page) !== -1 ? true : false;
  }

  getHealthFacility() {
    this.supplierService.getHealthFacilitySupplierForAdmin(this.healthFacilitySupplierId)
      .pipe(takeUntil(this._unsubscribeSignal$.asObservable()))
      .subscribe((result) => {
        if (!result || result.error) {
          this.dispatchModal('100', result.error || '');
          this.resetPagination();
        } else {
          this.healthFacilitySupplier = result.response;
          if (this.selectedTab === 0) {
            this.countVenuesByHealthFacility();
          } else {
            this.countReferencesByHealthFacility();
          }
        }
        this.isLoadingBase = false;
      }, (err) => {
        if (err && err.message) {
          this.dispatchModal('101', err.message);
        }
        this.resetPagination();
      });
  }

  editHealthFacilitySupplier() {
    this.redirectService.goToNewHealthFacilitySupplier(this.healthFacilitySupplierId);
  }

  /**
   * @description Apre la modale per la cancellazione di una SS
   */
  openDeleteHealthFacilityModal() {
    this.modalService.open('deleteHealthFacility');
  }

  /**
   * @description Chiude la modale per la cancellazione di una SS
   */
  closeDeleteHealthFacilityConfirmModal() {
    this.modalService.close('deleteHealthFacility');
  }

  /**
   * Conferma e cancella la SS in pagina
   */
  confirmDeleteHealthFacility() {

    this.isLoadingBase = true;
    this.isLoadingCount = true;

    this.supplierService.deleteHealthFacilitySupplierForAdmin(this.healthFacilitySupplierId)
      .pipe(takeUntil(this._unsubscribeSignal$.asObservable()))
      .subscribe((result) => {
        this.closeDeleteHealthFacilityConfirmModal();
        if (!result || result.error) {
          this.dispatchModal('108', result.error || '');
        } else {
          this.redirectService.goToAdminHealthFacilities();
        }
        this.isLoadingBase = false;
        this.isLoadingCount = false;
      }, (err) => {
        this.closeDeleteHealthFacilityConfirmModal();
        if (err && err.message) {
          this.dispatchModal('109', err.message);
        }
        this.isLoadingBase = false;
        this.isLoadingCount = false;
      });
  }

  addNewVenue() {
    this.redirectService.goToAdminNewHealthFacilityVenue(this.healthFacilitySupplierId);
  }

  addNewHealthFacilityReference() {
    this.redirectService.goToAdminNewHealthFacilityReference(this.healthFacilitySupplierId);
  }

  onTabClick(index: number) {
    this.selectedTab = index;

    this.resetPagination();
    if (this.selectedTab === 0) {
      this.redirectService.goToAdminHealthFacility(this.healthFacilitySupplierId, true);
      this.countVenuesByHealthFacility();
    } else {
      this.redirectService.goToAdminHealthFacilityReferences(this.healthFacilitySupplierId, true);
      this.countReferencesByHealthFacility();
    }
  }

  //#region Venue

  countVenuesByHealthFacility() {

    this.isLoadingCount = true;
    this.isLoading = true;

    this.supplierService.countVenueByHealthFacilitySupplierForAdmin(this.healthFacilitySupplierId)
      .subscribe((result) => {

        if (!result || result.error) {
          this.dispatchModal('100', result.error || '');
          this.resetPagination();
        } else {
          this.venuesCount = result.response;

          if (this.venuesCount > 0) {
            this.getVenuesByHealthFacility();
          } else {
            this.resetPagination();
          }
          this.isLoadingCount = false;
        }
      }, (err) => {
        if (err && err.message) {
          this.dispatchModal('101', err.message);
        }
        this.resetPagination();
      });
  }

  getVenuesByHealthFacility(index?: number) {

    this.isLoading = true;

    const pageSelectedIndex = index ? index : 0;
    if (index === 0 || index) {
      this.scrollTo.element('admin-health-facility-details-anchor');
    }

    this.supplierService.listVenueByHealthFacilitySupplierForAdmin((pageSelectedIndex * this.paginationLength), this.paginationLength, this.healthFacilitySupplierId)
      .pipe(takeUntil(this._unsubscribeSignal$.asObservable()))
      .subscribe((result) => {
        if (!result || result.error) {
          this.dispatchModal('102', result.error || '');
          this.resetPagination();
        } else {
          this.venues = result.response;
        }
        this.isLoading = false;
      }, (err) => {
        if (err && err.message) {
          this.dispatchModal('103', err.message);
        }
        this.resetPagination();
      });
  }

  editVenue(venue: InfoVenue) {
    this.redirectService.goToAdminNewHealthFacilityVenue(this.healthFacilitySupplierId, venue.itemId);
  }

  /**
   * @description Apre la modale per la cancellazione di una sede
   */
  openDeleteVenueModal(venue: InfoVenue) {
    this.venueToDelete = venue;
    this.modalService.open('deleteVenue');
  }

  /**
   * @description Chiude la modale per la cancellazione di una sede
   */
  closeDeleteVenueConfirmModal() {
    this.venueToDelete = undefined;
    this.modalService.close('deleteVenue');
  }

  /**
   * Conferma e procede alla cancellazione di una sede
   */
  confirmDeleteVenue() {
    if (this.venueToDelete) {

      this.isLoadingCount = true;

      this.rentService.deleteVenueItemById(this.venueToDelete.itemId)
        .pipe(takeUntil(this._unsubscribeSignal$.asObservable()))
        .subscribe((result) => {
          this.closeDeleteVenueConfirmModal();
          if (!result || result.error) {
            this.dispatchModal('108', result.error || '');
            this.isLoadingCount = false;
          } else {
            this.onTabClick(0);
          }
        }, (err) => {
          this.closeDeleteVenueConfirmModal();
          if (err && err.message) {
            this.dispatchModal('109', err.message);
          }
          this.isLoadingCount = false;
        });
    }
  }

  //#endregion Venue

  //#region References

  countReferencesByHealthFacility() {

    this.isLoadingCount = true;
    this.isLoading = true;

    this.supplierService.countHealthFacilitySupplierReferentForAdmin(this.healthFacilitySupplierId)
      .subscribe((result) => {

        if (!result || result.error) {
          this.dispatchModal('100', result.error || '');
          this.resetPagination();
        } else {
          this.referencesCount = result.response;

          if (this.referencesCount > 0) {
            this.getReferencesByHealthFacility();
          } else {
            this.resetPagination();
          }
        }
        this.isLoadingCount = false;
      }, (err) => {
        if (err && err.message) {
          this.dispatchModal('101', err.message);
        }
        this.resetPagination();
      });
  }

  getReferencesByHealthFacility(index?: number) {

    this.isLoading = true;

    const pageSelectedIndex = index ? index : 0;
    if (index === 0 || index) {
      this.scrollTo.element('admin-health-facility-details-anchor');
    }

    this.supplierService.listHealthFacilitySupplierReferentForAdmin(this.healthFacilitySupplierId, (pageSelectedIndex * this.paginationLength), this.paginationLength)
      .pipe(takeUntil(this._unsubscribeSignal$.asObservable()))
      .subscribe((result) => {

        if (!result || result.error) {
          this.dispatchModal('102', result.error || '');
          this.resetPagination();
        } else {
          this.references = result.response;
          this.isLoading = false;
        }
      }, (err) => {
        if (err && err.message) {
          this.dispatchModal('103', err.message);
        }
        this.resetPagination();
      });
  }

  impersonateReference(reference: SupplierPerson) {
    this.authService.impersonateHealthFacilitySupplierForAdmin(reference.supplierPersonId)
      .pipe(takeUntil(this._unsubscribeSignal$.asObservable()))
      .subscribe((result) => {
        if (!result || result.error) {
          this.dispatchModal('106', result.error || '');
        } else {
          this.redirectService.impersonationLogin(result.response);
        }
      }, (err) => {
        if (err && err.message) {
          this.dispatchModal('107', err.message);
        }
      });
  }

  editReference(reference: SupplierPerson) {
    this.redirectService.goToAdminNewHealthFacilityReference(this.healthFacilitySupplierId, reference.supplierPersonId);
  }

  /**
   * @description Apre la modale per la cancellazione di un referente di una SS
   */
  openDeleteReferenceModal(reference: SupplierPerson) {
    this.referenceToDelete = reference;
    this.modalService.open('deleteHealthFacilityReference');
  }

  /**
   * @description Chiude la modale per la cancellazione di un referente di una SS
   */
  closeDeleteReferenceConfirmModal() {
    this.venueToDelete = undefined;
    this.modalService.close('deleteHealthFacilityReference');
  }

  confirmDeleteReference() {
    if (this.referenceToDelete) {

      this.isLoadingCount = true;

      this.supplierService.deleteHealthFacilitySupplierReferentForAdmin(this.referenceToDelete.supplierPersonId)
        .pipe(takeUntil(this._unsubscribeSignal$.asObservable()))
        .subscribe((result) => {
          this.closeDeleteReferenceConfirmModal();
          if (!result || result.error) {
            this.dispatchModal('104', result.error || '');
            this.isLoadingCount = false;
          } else {
            this.onTabClick(1);
          }
        }, (err) => {
          this.closeDeleteReferenceConfirmModal();
          if (err && err.message) {
            this.dispatchModal('105', err.message);
          }
          this.isLoadingCount = false;
        });
    }
  }

  //#endregion References

  resetPagination() {
    this.isLoading = false;
    this.isLoadingCount = false;
    this.isLoadingBase = false;

    this.venues = [];
    this.venuesCount = 0;

    this.references = [];
    this.referencesCount = 0;
  }

  //#region Caricamento massivo

  /**
   * @description Apre la modale per il caricamento massivo
   */
  openMassiveLoadingModal() {
    this.resetUploadStructureData();
    this.modalService.open('massiveLoad');
  }

  /**
   * @description Chiude la modale per il caricamento massivo
   */
  closeMassiveLoadingModal() {
    this.modalService.close('massiveLoad');
  }

  /**
   * @description Verifica se il pulsante per confermare l'import massivo delle strutture è disabilitato
   */
  isConfirmUploadVenuesBtnDisabled(): boolean {
    return !this.fileObject || (this.fileObject && !this.fileObject.fileExtension);
  }

  /**
   * @description Pulisce i dati del form di upload delle strutture
   */
  resetUploadStructureData() {
    this.fileObject = null;
    this.isToResetUploadComponent = true;
  }

  /**
   * @description Effettua l'upload massivo delle sedi legate ad una SS
   */
  uploadVenuesFile() {
    // Chiudo la modale, mostro il loader e chiamo il servizio di upload
    this.closeMassiveLoadingModal();
    this.isLoadingCount = true;

    this.importService.importVenueByHealthFacilitySupplierForAdmin(this.healthFacilitySupplierId, this.fileObject)
      .subscribe((data: any) => {
        if (data.error) {
          this.dispatchModal('013', data.error);
        } else {
          this.dispatchMessageModal('structures.round.action.MASSIVE_LOADING', 'structures.STRUCTURES_UPLOADED');
          this.countVenuesByHealthFacility();
        }
        this.isLoadingCount = false;
      },
        (err: string) => {
          if (err) {
            this.dispatchModal('015', err);
          }
          this.isLoadingCount = false;
        });
  }

  /**
   * @description Salva localmente il file contenente tutte le strutture da caricare
   * @param file
   */
  saveVenuesFile(file: any) {
    this.fileObject = file;
    this.isToResetUploadComponent = false;
  }

  // Blocca/Sblocca remind mail
  blockRemindMails(reference: any, block?: boolean) {
    this.isLoading = true;
    this.closeRemindReferenceConfirmModal();

    this.supplierService.updateHealthFacilitySupplierReferentForAdmin(reference.supplierPersonId, undefined, undefined, undefined, block)
      .subscribe((data: any) => {
        if (data.error) {
          this.dispatchModal('013', data.error);
        } else {
          this.countReferencesByHealthFacility();
        }
        this.isLoading = false;
      },
        (err: string) => {
          if (err) {
            this.dispatchModal('015', err);
          }
          this.isLoading = false;
        });
  }

  closeRemindReferenceConfirmModal() {
    this.referenceSelected = null;
    this.modalService.close("remindHealthFacilityReference");
  }

  openRemindReferenceConfirmModal(reference: SupplierPerson) {
    this.referenceSelected = reference;
    this.modalService.open("remindHealthFacilityReference");
  }

  //#endregion

}

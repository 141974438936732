/*
 * Modulo per la gestione dell'autenticazione
 */

import { NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { TranslateModule } from "@ngx-translate/core";
import { CommonModule } from "@angular/common";
import { FlexLayoutModule } from "@angular/flex-layout";
import { LocalLoginComponent } from "./login/local-login.component";
import { SharedModule } from '../shared/shared.module';
import { SigninComponent } from "./signin/signin.component";
import { SsoAccentureLoginComponent } from "./sso-accenture-login/sso-accenture-login.component";
import { AccentureLogoutSsoCompletedComponent } from "./accenture-logout-sso-completed/accenture-logout-sso-completed.component";
import { AccentureLoginSamlErrorComponent } from "./accenture-login-saml-error/accenture-login-saml-error.component";
import { LoginImpersonationComponent } from "./login-impersonation/login-impersonation.component";
import { SigninSSOComponent } from "./signinSSO/signinSSO.component";
import { SsoEnelLoginComponent } from "./sso-enel-login/sso-enel-login.component";
import { EnelLogoutSsoCompletedComponent } from "./enel-logout-sso-completed/enel-logout-sso-completed.component";
import { EnelLoginSamlErrorComponent } from "./enel-login-saml-error/enel-login-saml-error.component";
import { SsoLeroyMerlinLoginComponent } from "./sso-leroy-merlin-login/sso-leroy-merlin-login.component";
import { LeroyMerlinLogoutSsoCompletedComponent } from "./leroy-merlin-logout-sso-completed/leroy-merlin-logout-sso-completed.component";
import { LeroyMerlinLoginSamlErrorComponent } from "./leroy-merlin-login-saml-error/leroy-merlin-login-saml-error.component";
import { LoginSSOComponent } from "./login-sso/login-sso.component";

@NgModule({
  declarations: [
    LocalLoginComponent,
    SigninComponent,
    SigninSSOComponent,
    LoginImpersonationComponent,
    // SSO Accenture
    SsoAccentureLoginComponent,
    AccentureLogoutSsoCompletedComponent,
    AccentureLoginSamlErrorComponent,
    // SSO Enel
    SsoEnelLoginComponent,
    EnelLogoutSsoCompletedComponent,
    EnelLoginSamlErrorComponent,
    // SSO Leroy Merlin
    SsoLeroyMerlinLoginComponent,
    LeroyMerlinLogoutSsoCompletedComponent,
    LeroyMerlinLoginSamlErrorComponent,
    LoginSSOComponent
  ],
  imports: [
    FlexLayoutModule,
    CommonModule,
    FormsModule,
    TranslateModule,
    SharedModule]
})
export class AuthModule { }

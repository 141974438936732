<div fxLayout="row" fxLayoutAlign="start" class="bread-crumbs">

  <svg-icon class="medium-size" [applyClass]="true" src="/assets/img/icons/home-icon.svg" ngClass.gt-xs="home-icon"
    (click)="goToHome()"></svg-icon>

  <div fxLayout="row wrap" fxLayoutAlign="start center">
    <div class="primary cursor-pointer"  (click)="goToHome()" clickKeyBoard (onEnterKeyboard)="goToHome()" tabindex="0" role="button">
      <h3 fxFlexOffset="8px" class="primary cursor-pointer" >Home</h3>
    </div>

    <ng-container *ngIf="firstLevel || customFirstLevel">
      <div fxLayout="row" [ngClass]="{'cursor-pointer': secondLevel}" (click)="goToFirstLevel()"  role="button" tabindex="0">
        <h3 fxFlexOffset="8px" [ngClass]="{'primary': secondLevel}">»</h3>
        <ng-container *ngIf="customFirstLevel" tabindex="0">
          <h3 fxFlexOffset="8px" [ngClass]="{'primary': secondLevel}">
            {{ customFirstLevel }}
          </h3>
        </ng-container>
        <ng-container *ngIf="!customFirstLevel">
          <h3 fxFlexOffset="8px" [ngClass]="{'primary': secondLevel}"
            [translate]="'component.breadCrumbs.' + firstLevel" tabindex="0">
          </h3>
        </ng-container>
      </div>
      <ng-container *ngIf="secondLevel">
        <div fxLayout="row" [ngClass]="{'cursor-pointer': thirdLevel}" (click)="goToSecondLevel()" role="button" tabindex="0">
          <h3 fxFlexOffset="8px" [ngClass]="{'primary': thirdLevel}">»</h3>
          <h3 fxFlexOffset="8px" [ngClass]="{'primary': thirdLevel}" tabindex="0">{{ secondLevel }}</h3>
        </div>

        <ng-container *ngIf="thirdLevel">
          <h3 fxFlexOffset="8px">»</h3>
          <h3 fxFlexOffset="8px" tabindex="0">{{ thirdLevel }}</h3>
        </ng-container>
      </ng-container>

    </ng-container>
  </div>
</div>

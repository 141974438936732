import { Component, OnDestroy, OnInit } from '@angular/core';
import { User, UserAcknowledges } from 'atfcore-commonclasses/bin/classes/anag';
import { combineLatest, Observable, Subject } from 'rxjs';

import * as CoreActions from "../../core/ngrx/core.actions";
import * as fromApp from "../../ngrx/app.reducers";

import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { RedirectService } from 'src/app/shared/services/redirect.service';
import { JwtPayload } from 'atfcore-commonclasses/bin/classes/auth';
import { takeUntil } from 'rxjs/operators';
import { getModalMessageData, getWarningModal } from 'src/app/utils/utils';
import { InfoRound } from 'atfcore-commonclasses/bin/classes/campaignmanager';
import { EngagementService, InfoRequestCount } from '../services/engagement.service';
import { GetAllCampaignsSlotsDataAdmin, RentService } from 'src/app/structure/services/rent.service';
import { ModalService } from 'src/app/shared/components/modal/modal.service';

@Component({
  selector: 'app-admin-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class AdminHomeComponent implements OnInit, OnDestroy {

  loggedUser?: User;
  userAcknowledges: UserAcknowledges | null = null;

  isLoading: boolean = true;
  isLoadingBase: boolean = true;

  searchText?: string;

  private _unsubscribeSignal$: Subject<void> = new Subject();

  readonly paginationLength: number = 5;

  roundsCount: number = 0;
  rounds: InfoRound[] = [];

  allCampaignsSlotsData?: GetAllCampaignsSlotsDataAdmin;
  infoRequestCount?: InfoRequestCount;

  private _firstInit: boolean = true;

  constructor(
    private appStore: Store<fromApp.AppState>,
    private translate: TranslateService,
    private engagementService: EngagementService,
    private rentService: RentService,
    public modalService: ModalService,
    public redirectService: RedirectService) {
  }

  ngOnInit() {
    const loggedUser$: Observable<JwtPayload> = this.appStore.select(fromApp.getLoggedUser);
    const userAck$: Observable<any> = this.appStore.select(fromApp.getUserAcknowledges);
    combineLatest([loggedUser$, userAck$])
      .pipe(takeUntil(this._unsubscribeSignal$.asObservable()))
      .subscribe(
        ([loggedUser, userAck]) => {
          if (loggedUser && loggedUser.user) {
            this.loggedUser = loggedUser && loggedUser.user;
            this.userAcknowledges = userAck;

            if (this._firstInit) {
              this._firstInit = false;
              this.getPendingDatas();
            }
          }
        });
  }

  ngOnDestroy() {
    this._unsubscribeSignal$.next();
    this._unsubscribeSignal$.unsubscribe();
  }

  /**
   * @description Mostra una modale con titolo e messaggio
   * @param modalId
   * @param title
   * @param message
   */
  dispatchMessageModal(title: string, message: string) {
    const messageObj = getModalMessageData(this.translate, title, message);
    this.appStore.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
  }

  /**
   * @description Mostra una modale con un messaggio di errore
   * @param modalId
   * @param error
   */
  dispatchModal(modalId: string, error: string) {
    const messageObj = getWarningModal(this.translate, modalId, error);
    this.appStore.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
  }

  getPendingDatas() {
    this.engagementService.getVenueRequestsToProcessCountsForAdmin()
      .pipe(takeUntil(this._unsubscribeSignal$.asObservable()))
      .subscribe((result) => {
        if (!result || result.error) {
          this.dispatchModal('080', result.error || '');
          this.resetPagination();
        } else {
          this.infoRequestCount = result.response;

          this.getAllCampaignsSlotsData();
        }
      }, (err) => {
        if (err && err.message) {
          this.dispatchModal('081', err.message);
        }
        this.resetPagination();
      });
  }

  getAllCampaignsSlotsData() {

    this.rentService.getAllCampaignsSlotsDataForAdmin()
      .pipe(takeUntil(this._unsubscribeSignal$.asObservable()))
      .subscribe((result) => {
        if (!result || result.error) {
          this.dispatchModal('080', result.error || '');
          this.resetPagination();
        } else {
          this.allCampaignsSlotsData = result.response;
          this.countActiveRounds();
        }
        this.isLoadingBase = false;
      }, (err) => {
        if (err && err.message) {
          this.dispatchModal('081', err.message);
        }
        this.resetPagination();
      });
  }

  countActiveRounds() {
    this.rentService.countActiveRoundsForAdmin()
      .subscribe((result) => {

        if (!result || result.error) {
          this.dispatchModal('100', result.error || '');
          this.resetPagination();
        } else {
          this.roundsCount = result.response;

          if (this.roundsCount > 0) {
            this.getActiveRounds();
          } else {
            this.resetPagination();
          }
        }
      }, (err) => {
        if (err && err.message) {
          this.dispatchModal('101', err.message);
        }
        this.resetPagination();
      });
  }

  getActiveRounds(index?: number) {
    this.isLoading = true;

    const pageSelectedIndex = index ? index : 0;

    this.rentService.getActiveRoundsForAdmin((pageSelectedIndex * this.paginationLength), this.paginationLength)
      .pipe(takeUntil(this._unsubscribeSignal$.asObservable()))
      .subscribe((result) => {

        if (!result || result.error) {
          this.dispatchModal('102', result.error || '');
          this.resetPagination();
        } else {
          this.rounds = result.response;
        }
        this.isLoading = false;
      }, (err) => {
        if (err && err.message) {
          this.dispatchModal('103', err.message);
        }
        this.resetPagination();
      });
  }

  resetPagination() {
    this.isLoading = false;
    this.isLoadingBase = false;

    this.rounds = [];
    this.roundsCount = 0;
  }

  goToPendingSlots() {
    this.redirectService.goToAdminPendingRequestsToApprove();
  }

  goToPendingRevokeRequests() {
    this.redirectService.goToAdminPendingRequestsToRevoke();
  }

  goToPendingDescription() {
    this.redirectService.goToAdminPendingRequestsDescriptions();
  }

  goToActiveCampaigns() {
    this.redirectService.goToAdminCampaigns();
  }

  goToClients() {
    this.redirectService.goToAdminClients();
  }

  goToVenues() {
    this.redirectService.goToAdminHealthFacilities();
  }

  goToDashboard() {
    this.redirectService.goToAdminDashboard();
  }

  onRoundClick(round: InfoRound) {
    this.redirectService.goToAdminCampaignRoundTabVenues(round.campaignId, round.roundId);
  }
}

<div class="add-health-facility-container" fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="start start"
  fxLayoutAlign.lt-md="start center">

  <div class="add-health-facility-side" fxLayout="column" fxLayoutAlign="space-between">

    <div fxLayout="column" fxLayoutAlign="start start">
      <div class="add-health-facility-back" fxLayout="row" fxLayoutAlign="start center" fxFlexOffset="40px"
        (click)="goBack()">
        <svg-icon fxFlexOffset="40px" [applyClass]="true" src="./assets/img/icons/arrow-white-left.svg"></svg-icon>
        <h5 class="white" fxFlexOffset="12px">{{ 'generic.GO_BACK' | translate | uppercase}}</h5>
      </div>

      <p fxFlexOffset="24px">
        <ng-container *ngIf="!healthFacility">
          <span translate="admin.addHealthFacility.SIDE_TITLE"></span>
        </ng-container>
        <ng-container *ngIf="healthFacility">
          <span translate="admin.addHealthFacility.SIDE_TITLE_UPDATE"></span>
          <span class="bold">{{ healthFacility.name }}</span>
        </ng-container>
      </p>

      <div fxFlexOffset="24px" class="add-health-facility-tick"></div>

      <div fxShow fxHide.lt-md class="add-health-facility-faq" fxFlexOffset="48px" fxLayout="row">
        <h5 translate="generic.FAQ"></h5>
        <h5 fxFlexOffset="4px" translate="generic.FAQ2" class="primary bold faq-link" (click)="goToFAQ()"></h5>
      </div>
    </div>

    <svg-icon fxShow fxHide.lt-md class="welion-logo" [applyClass]="true" src="./assets/img/icons/app-logo.svg">
    </svg-icon>

    <div fxShow fxHide.lt-md fxFlexOffset="40px"></div>

  </div>

  <div fxFlex fxLayout="row" fxLayout.xs="column" fxLayoutAlign="center" fxLayoutAlign.xs="start"
    class="add-health-facility-body-container">

    <form class="add-health-facility-body" [formGroup]="addHealthFacilityForm" fxLayout="column"
      fxLayoutAlign="start center" (ngSubmit)="confirmAddHealthFacility()">

      <!-- Informazioni generali -->
      <h3 translate="admin.addHealthFacility.TITLE" ngClass.xs="center"></h3>

      <!-- Nome struttura sanitaria -->
      <input-container fxFlexOffset="24px" class="full-width"
        [title]="'admin.addHealthFacility.HEALTH_FACILITY_NAME' | translate">
        <welion-input [inputValue]="name"
          [placeholder]="'admin.addHealthFacility.HEALTH_FACILITY_NAME_PLACEHOLDER' | translate" [disabled]="isLoading">
        </welion-input>
      </input-container>

      <!-- Codice -->
      <input-container fxFlexOffset="24px" class="full-width"
        [title]="'admin.addHealthFacility.HEALTH_FACILITY_CODE' | translate">
        <welion-input [inputValue]="code"
          [placeholder]="'admin.addHealthFacility.HEALTH_FACILITY_CODE_PLACEHOLDER' | translate" [disabled]="isLoading">
        </welion-input>
      </input-container>

      <!-- Indirizzo -->
      <input-container fxFlexOffset="24px" class="full-width" [inputEditable]="disabledAddress.value"
        (renderInputEditable)="onEditAddress()" [title]="'admin.addHealthFacility.HEALTH_FACILITY_ADDRESS' | translate">
        <welion-input [inputValue]="address" hasMapsAutocomplete (selectedPlace)="selectedPlace($event)"
          [placeholder]="'admin.addHealthFacility.HEALTH_FACILITY_ADDRESS_PLACEHOLDER' | translate"
          [disabled]="isLoading || disabledAddress.value">
        </welion-input>
      </input-container>

      <!-- Descrizione -->
      <input-container fxFlexOffset="24px" class="full-width"
        [title]="'admin.addHealthFacility.HEALTH_FACILITY_DESC' | translate">
        <welion-text-area [inputValue]="description" [disabled]="isLoading"></welion-text-area>
      </input-container>

      <div *ngIf="isLoading" class="loader spinner-big"></div>

      <!-- Crea struttura -->
      <div fxFlexOffset="24px" class="full-width" fxLayout="row" fxLayoutAlign="end">
        <welion-button width="264px" type="primary" size="large"
          [label]="'admin.addHealthFacility.CONFIRM' + (this.healthFacility ? '_UPDATE' : '')"
          [disabled]="addHealthFacilityForm.invalid || isLoading" buttonType="submit">
        </welion-button>
      </div>

      <div fxHide fxShow.xs fxFlex.xs="40px"></div>

    </form>

  </div>

</div>

import { ChangeDetectionStrategy, Component, Input, OnInit, Output, EventEmitter, ChangeDetectorRef, ViewChild, Renderer2, AfterViewInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core'

@Component({
  selector: 'welion-order-select',
  templateUrl: 'welion-order-select.component.html',
  styleUrls: ['./welion-order-select.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class WelionOrderSelectComponent implements OnInit {
  @Input() label: string = ''; // Data, sede, indirizzo, ecc
  @Input() itemLabel: string = 'LETTER'; // LETTER, DISTANCE, DATE
  @Input() disabled: boolean = false;
  @Input() selectedOrder: any;
  items: any[] = [];
  selected: any;
  isOpen: boolean = false;

  @Output() onSelectedOrder = new EventEmitter<any>();
  icons = [
    "assets/img/icons/order/arrow-down-up-black.svg",
    "assets/img/icons/order/arrow-down.svg",
    "assets/img/icons/order/arrow-up.svg"
  ]
  ids = [
    "NO",
    "ASC",
    "DESC",
  ]

  constructor(
    public translate: TranslateService
  ) {
  }

  ngOnInit() {
    this.items = [];
    let labels = [
      this.translate.instant('order.NO'),
      (this.translate.instant(this.label) + this.translate.instant('order.' + this.itemLabel + '_ASC')),
      (this.translate.instant(this.label) + this.translate.instant('order.' + this.itemLabel + '_DESC')),
    ]
    for (let i = 0; i < 3; i++) {
      this.items.push({
        id: this.ids[i],
        label: labels[i],
        icon: this.icons[i]
      })
    }
    if (!this.selectedOrder) {
      this.selectedOrder = this.items[0];
    }
  }


  getHeaderIcon() {
    if (this.selectedOrder && this.selectedOrder.id != "NO") {
      return "assets/img/icons/order/arrow-down-up-red.svg";
    } else {
      return "assets/img/icons/order/arrow-down-up-gray.svg";
    }
  }

  openDropdown() {
    this.isOpen = !this.isOpen;
  }

  emitOnSelectedOrder(item: any) {
    this.onSelectedOrder.emit(item)
  }
}

import { Component, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { Observable, Subject } from 'rxjs';
import { catchError, map, switchMap, takeUntil } from 'rxjs/operators';
import { ActivatedRoute } from '@angular/router';

import { InfoCampaign, InfoVenue } from 'atfcore-commonclasses/bin/classes/campaignmanager';
import { SenecaResponse } from 'atfcore-commonclasses/bin/classes/common';

import * as fromApp from "../../ngrx/app.reducers";
import * as CoreActions from "../../core/ngrx/core.actions";

import { RedirectService } from 'src/app/shared/services/redirect.service';
import { getWarningModal, structureCode, structureName } from 'src/app/utils/utils';
import { ScrollTo } from 'src/app/core/services/scroll-to.service';
import { RentService } from '../services/rent.service';
import { DropdownItem } from 'src/app/shared/models/dropdown.model';

@Component({
  selector: 'app-active-campaigns',
  templateUrl: './activeCampaigns.component.html',
  styleUrls: ['./activeCampaigns.component.scss']
})
export class ActiveCampaignsComponent implements OnInit, OnDestroy {

  campaignList?: InfoCampaign[];
  isLoadingCampaigns: boolean = true;

  office?: InfoVenue;
  venueId: string = '';
  isLoadingOffice: boolean = true;

  private _unsubscribeSignal$: Subject<void> = new Subject();

  structureName: string = '';
  structureCode: string = '';

  //#region Structures

  private readonly allStructureValue: DropdownItem = {
    id: '-1',
    name: 'Tutte le sedi'
  };

  private _isEndSlotsLoading: boolean = false;

  private _structuresLoaded: number = 1;
  private _structureSearchedText: string = '';
  private _resetStructureResultList: boolean = false;

  disableStructureSelect: boolean = false;

  /**
   * When the list ended
   */
  private _isEndStructureList: boolean = false;

  isLoadingStructureList: boolean = false;
  structureList: DropdownItem[] = [this.allStructureValue];
  structureServive$: Subject<string> = new Subject<string>();
  selectedStructure?: DropdownItem = this.allStructureValue;

  //#endregion

  private _firstInit: boolean = true;

  constructor(
    private route: ActivatedRoute,
    private appStore: Store<fromApp.AppState>,
    private translate: TranslateService,
    private redirectService: RedirectService,
    private scrollTo: ScrollTo,
    private rentService: RentService) {

  }

  ngOnInit() {
    this.scrollTo.header();

    this.venueId = this.route.snapshot.paramMap.get('officeId') || '';

    this.appStore.select(fromApp.getLoggedUser)
      .pipe(takeUntil(this._unsubscribeSignal$.asObservable()))
      .subscribe(
        (loggedUser) => {
          if (loggedUser) {
            this.structureName = structureName(loggedUser);
            this.structureCode = structureCode(loggedUser);

            if (this._firstInit) {
              this._firstInit = false;
              this.initStructures();
              // Avvio una prima ricerca
              this.structureServive$.next('');
            }

          }
        });

  }

  ngOnDestroy() {
    this._unsubscribeSignal$.next();
    this._unsubscribeSignal$.unsubscribe();
  }

  getCampaignList() {
    this.campaignList = [];
    this.isLoadingCampaigns = true;

    this.rentService.getCampaignList(this.venueId)
      .pipe(takeUntil(this._unsubscribeSignal$.asObservable()))
      .subscribe(
        (result: SenecaResponse<InfoCampaign[]>) => {
          if (result && result.error) {
            this.dispatchModal('003', result.error);
          } else {
            this.campaignList = result.response;
          }
          this.isLoadingCampaigns = false;
        }, (err) => {
          if (err && err.message) {
            this.dispatchModal('004', err.message);
          }
          this.isLoadingCampaigns = false;
        });
  }

  goToHome() {
    this.redirectService.goToHome();
  }

  dispatchModal(modalId: string, error: string) {
    const messageObj = getWarningModal(this.translate, modalId, error);
    this.appStore.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
  }



  //#region Structures

  /**
   * @description Carica le strutture e imposta per l'infinite scroll
   */
  initStructures() {
    this.structureList = this.structureList || [this.allStructureValue];
    this.structureServive$.pipe(
      switchMap(
        (text: string) => {
          if (text !== this._structureSearchedText) {
            this._resetStructureResultList = true;
            this._structuresLoaded = 1;
          } else {
            this._resetStructureResultList = false;
          }
          this._structureSearchedText = text || '';
          this.isLoadingStructureList = true;
          return this.getVenueList((this._structuresLoaded - 1), 10, this._structureSearchedText);
        }
      ),
      catchError((err, caught) => {
        return caught;
      })
    )
      .subscribe({
        next: (eventData: Array<DropdownItem>) => {
          if (this._resetStructureResultList) {
            this.clearStructureResults();
          }
          if (eventData && eventData.length) {
            this._structuresLoaded += eventData.length;
            this.structureList = this.structureList.concat(eventData);
            if (this.venueId) {
              for (let i = 0; i < this.structureList.length; i++) {
                if (this.structureList[i].id === this.venueId) {
                  this.onStructureSelected(this.structureList[i]);
                  break;
                }
              }
            } else if (this._structuresLoaded === 2) {
              this.onStructureSelected(this.structureList[1]);
              this.disableStructureSelect = true;
            } else {
              this.onStructureSelected(this.structureList[0]);
            }
          } else {
            this.isLoadingCampaigns = false;
          }

          this.isLoadingStructureList = false;
        },
        error: (err: any) => {
          this.isLoadingStructureList = false;
          this._isEndSlotsLoading = true;
        }
      });
  }

  /**
   * @description Carica e prepara gli elementi per la select con infinite scroll
   * @param fromRecord
   * @param numRecords
   * @param searchedText
   */
  getVenueList(fromRecord: number, numRecords: number, searchedText: string): Observable<DropdownItem[]> {
    return this.rentService.getVenueList('', fromRecord, numRecords, searchedText)
      .pipe(
        takeUntil(this._unsubscribeSignal$.asObservable()),
        map((resultData: SenecaResponse<InfoVenue[]>) => {
          if (resultData.error) {
            this.dispatchModal('007', resultData.error);
          } else if (resultData.response && resultData.response.length) {
            let formattedData: DropdownItem[] = [];

            resultData.response.forEach(structure => {
              let title = structure.name + ' - ' + structure.address;

              formattedData.push({
                id: structure.venueId,
                name: title,
                content: structure
              });
            });

            // Caricando n elementi per volta (itemsToLoad) se
            // ne carica di meno significa che sono a fine lista
            if (resultData.response.length < numRecords) {
              this._isEndStructureList = true;
            }

            return formattedData;
          }
          this._isEndStructureList = true;
          return [];
        })
      );
  }

  /**
   * @description Alla selezione di una struttura
   * @param structure
   */
  onStructureSelected(structure?: DropdownItem) {
    this.selectedStructure = structure;
    if (this.selectedStructure) {
      if (this.selectedStructure.id === '-1') {
        this.venueId = '';
      } else {
        this.venueId = (this.selectedStructure.content as InfoVenue).venueId;
      }
      this.getCampaignList();
    }
    // this.getStructureDaysSlots();
  }

  /**
   * @description Chiamata quando viene effettuata una nuova ricerca differente
   */
  clearStructureResults(): void {
    this._structuresLoaded = 0;
    this.structureList = [];
    this._resetStructureResultList = false;
  }

  /**
   * @description Chiamata allo scroll
   * @param scrollEvent
   */
  fetchMoreStructures(scrollEvent: { start: number; end: number }) {
    if (!this._isEndStructureList && scrollEvent.end === this._structuresLoaded) {
      this.structureServive$.next(this._structureSearchedText);
    }
  }

  //#endregion Structures

}


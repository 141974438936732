import { ActivatedRoute } from "@angular/router";
import { Component, OnDestroy, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Subject } from 'rxjs';
import { Store } from "@ngrx/store";
import { takeUntil } from "rxjs/operators";

import * as fromApp from "../../../ngrx/app.reducers";
import * as CoreActions from "../../../core/ngrx/core.actions";

import { RedirectService } from 'src/app/shared/services/redirect.service';
import { getWarningModal } from "src/app/utils/utils";
import { ScrollTo } from "src/app/core/services/scroll-to.service";
import { InfoCampaign, InfoRound, InfoVenue } from "src/commonclasses";
import { SupplierService } from "../../services/supplier.service";
import { RentService } from "src/app/structure/services/rent.service";

@Component({
  selector: 'app-admin-assign-venue-to-round',
  templateUrl: './assignVenueToRound.component.html',
  styleUrls: ['./assignVenueToRound.component.scss']
})
export class AdminAssignVenueToRoundComponent implements OnInit, OnDestroy {

  isLoading: boolean = true;
  isLoadingCount: boolean = true;
  isLoadingBase: boolean = true;

  campaignId: string = '';
  campaign?: InfoCampaign;

  roundId: string = '';
  round?: InfoRound;

  venues: InfoVenue[] = [];
  venuesCount: number = 0;
  selectedVenues: string[] = [];

  readonly paginationLength: number = 25;

  private _unsubscribeSignal$: Subject<void> = new Subject();

  constructor(
    private route: ActivatedRoute,
    private appStore: Store<fromApp.AppState>,
    private translate: TranslateService,
    private redirectService: RedirectService,
    private scrollTo: ScrollTo,
    private rentService: RentService,
    private supplierService: SupplierService
  ) {
  }

  ngOnInit() {
    this.scrollTo.header();

    this.campaignId = this.route.snapshot.paramMap.get('campaignId') || '';
    this.roundId = this.route.snapshot.paramMap.get('roundId') || '';

    if (this.campaignId && this.roundId) {
      this.getCampaign();
    } else {
      this.redirectService.goToHome();
    }
  }

  getCampaign() {
    this.rentService.getCampaign(this.campaignId)
      .pipe(takeUntil(this._unsubscribeSignal$.asObservable()))
      .subscribe((result) => {
        if (!result || result.error) {
          this.dispatchWarningModal('044', result.error || '');
          this.resetPagination();
        } else if (result.response) {
          this.campaign = result.response;
          this.getRound();
        }
      }, (err) => {
        if (err && err.message) {
          this.dispatchWarningModal('045', err.message);
        }
        this.resetPagination();
      });
  }

  getRound() {
    this.rentService.getRound(this.roundId)
      .pipe(takeUntil(this._unsubscribeSignal$.asObservable()))
      .subscribe((result) => {
        if (!result || result.error) {
          this.dispatchWarningModal('100', result.error || '');
          this.resetPagination();
        } else {
          this.round = result.response;
          this.countVenueList();
        }
        this.isLoadingBase = false;
      }, (err) => {
        if (err && err.message) {
          this.dispatchWarningModal('101', err.message);
        }
        this.resetPagination();
      });
  }

  countVenueList() {

    this.isLoadingCount = true;

    this.supplierService.countVenueByHealthFacilitySupplierForAdmin(undefined, undefined, undefined, this.roundId)
      .subscribe((result) => {

        if (!result || result.error) {
          this.dispatchWarningModal('100', result.error || '');
          this.resetPagination();
        } else {
          this.venuesCount = result.response;

          if (this.venuesCount > 0) {
            this.getVenueList();
          } else {
            this.resetPagination();
          }
          this.isLoadingCount = false;
        }
      }, (err) => {
        if (err && err.message) {
          this.dispatchWarningModal('101', err.message);
        }
        this.resetPagination();
      });
  }

  getVenueList(index?: number) {

    this.isLoading = true;

    const pageSelectedIndex = index ? index : 0;
    this.scrollTo.header();

    this.supplierService.listVenueByHealthFacilitySupplierForAdmin((pageSelectedIndex * this.paginationLength), this.paginationLength, undefined, undefined, undefined, this.roundId)
      .pipe(takeUntil(this._unsubscribeSignal$.asObservable()))
      .subscribe((result) => {

        if (!result || result.error) {
          this.dispatchWarningModal('102', result.error || '');
          this.resetPagination();
        } else {
          this.venues = result.response;
          this.isLoading = false;
        }
      }, (err) => {
        if (err && err.message) {
          this.dispatchWarningModal('103', err.message);
        }
        this.resetPagination();
      });
  }

  ngOnDestroy() {
    this._unsubscribeSignal$.next();
    this._unsubscribeSignal$.unsubscribe();
  }

  goBack() {
    this.redirectService.goToAdminCampaignRoundTabVenues(this.campaignId, this.roundId);
  }

  confirmAddVenues() {
    if (this.selectedVenues.length > 0) {
      this.isLoading = true;

      this.rentService.addVenueAssociationToRoundForAdmin(this.roundId, this.selectedVenues)
        .pipe(takeUntil(this._unsubscribeSignal$.asObservable()))
        .subscribe((result) => {
          if (!result || result.error) {
            this.dispatchWarningModal('041', result.error || '');
          } else if (result.response) {
            this.redirectService.goToAdminCampaignRoundTabVenues(this.campaignId, this.roundId);
          }
          this.isLoading = false;
        }, (err) => {
          if (err && err.message) {
            this.dispatchWarningModal('042', err.message);
          }
          this.isLoading = false;
        });
    }
  }

  venueIsChecked(venue: InfoVenue): boolean {
    return this.selectedVenues.filter(selectedVenue => selectedVenue === venue.itemId).length > 0;
  }

  onVenueChecked(venue: InfoVenue, checked: boolean) {
    if (checked) {
      this.selectedVenues.push(venue.itemId);
    } else {
      let index = this.selectedVenues.findIndex(selectedVenue => selectedVenue === venue.itemId);
      this.selectedVenues.splice(index, 1);
    }
  }

  /**
   * @description Mostra una modale con un messaggio di errore
   * @param modalId
   * @param error
   */
  dispatchWarningModal(modalId: string, error: string) {
    const messageObj = getWarningModal(this.translate, modalId, error);
    this.appStore.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
  }

  //#region Pagination

  resetPagination() {
    this.isLoadingBase = false;
    this.isLoadingCount = false;
    this.isLoading = false;

    this.venues = [];
    this.venuesCount = 0;
  }

  //#endregion Pagination

}

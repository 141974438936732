<div class="page-content-wrapper" fxLayout="column">

  <!-- Strutture sanitarie -->
  <h1 fxFlexOffset="40px" class="primary" translate="admin.healthFacilities.TITLE"></h1>

  <bread-crumbs fxFlexOffset="12px" firstLevel="HEALTH_FACILITIES"></bread-crumbs>

  <div fxFlexOffset="14px" fxFlexOffset.xs="12px" class="health-facilities-container" fxLayout="column">

    <!-- Nome campagna -->
    <input-container [title]="'admin.healthFacilities.SEARCH_HEALTH_FACILITY' | translate">
      <welion-input [bindValue]="searchText" (onChange)="searchTextChange($event)"
        (onIconClicked)="searchHealthFacility()"
        [placeholder]="'admin.healthFacilities.SEARCH_HEALTH_FACILITY_PLACEHOLDER' | translate" [disabled]="isLoading"
        iconRight="assets/img/icons/icon-search.svg">
      </welion-input>
    </input-container>

    <div id="admin-health-facilities-anchor" fxFlexOffset="10px"></div>

    <div fxLayout="row" fxFlexOffset="10px" fxLayoutAlign="space-between center">
      <!-- 354 strutture sanitarie -->
      <h3>
        <ng-container *ngIf="!isLoadingCount && healthFacilitiesCount === 1">
          {{ 'admin.healthFacilities.table.HEALTH_FACILITY_TITLE' | translate }}
        </ng-container>
        <ng-container *ngIf="!isLoadingCount && healthFacilitiesCount !== 1">
          {{ 'admin.healthFacilities.table.HEALTH_FACILITIES_TITLE' | translate: { value: healthFacilitiesCount || 0 }
          }}
        </ng-container>
        <ng-container *ngIf="isLoadingCount">
          {{ 'admin.healthFacilities.table.HEALTH_FACILITIES_TITLE_LOADING' | translate }}
        </ng-container>
      </h3>

      <div>
        <!-- Scarica report -->
        <welion-button width="180px" label="generic.DOWNLOAD_REPORT" type="secondary" size="medium"
          (onClick)="exportHealthFacilityList()" [disabled]="isLoading || healthFacilitiesCount === 0">
        </welion-button>
        <!-- Crea nuova struttura -->
        <welion-button *ngIf="!isLimitedAdmin" width="232px" fxFlexOffset="8px" size="medium" type="primary"
          label="admin.healthFacilities.table.NEW_HEALTH_FACILITY" (onClick)="newHealthFacility()">
        </welion-button>
      </div>
    </div>

    <!-- Table header -->
    <div fxFlexOffset="20px">
      <!-- Nome -->
      <h5 class="bold" translate="admin.healthFacilities.table.NAME" fxFlex fxFlexOffset="16px"></h5>
      <!-- Indirizzo -->
      <h5 class="bold" translate="admin.healthFacilities.table.ADDRESS" fxFlex="35"></h5>
      <!-- Sedi -->
      <h5 class="bold" translate="admin.healthFacilities.table.HEALTH_FACILITIES" fxFlex="10"></h5>
      <!-- Referenti -->
      <h5 class="bold" translate="admin.healthFacilities.table.REFERENCES" fxFlex="15"></h5>
    </div>

    <!-- Loader -->
    <div *ngIf="isLoading" class="loader spinner-big"></div>

    <!-- Table rows -->
    <ng-container *ngIf="!isLoading">
      <div *ngFor="let healthFacility of healthFacilities; let first = first; let odd = odd" class="health-facility-row"
        [ngClass]="{'odd': odd}" fxLayout="row" fxLayoutAlign="start center" [fxFlexOffset]="first ? '8px' : '0px'">
        <!-- Nome -->
        <h6 class="black" fxFlex fxFlexOffset="16px">
          <ng-container *ngIf="healthFacility.code">
            {{ '[' + healthFacility.code + '] ' + healthFacility.name }}
          </ng-container>
          <ng-container *ngIf="!healthFacility.code">
            {{ healthFacility.name }}
          </ng-container>
        </h6>
        <!-- Indirizzo -->
        <h6 class="black" fxFlex="35">{{ healthFacility.address }}</h6>
        <!-- Sedi -->
        <h6 class="black" fxFlex="10">{{ healthFacility.venueCount }}</h6>
        <!-- Referenti -->
        <h6 class="black" fxFlex="10">{{ healthFacility.referentCount }}</h6>

        <!-- 2% of margin left -->
        <div fxFlex="5" fxLayout="row" fxLayoutAlign="end">
          <svg-icon src="assets/img/icons/arrow-right-red.svg" class="cursor-pointer" [applyClass]="true"
            (click)="onHealthFacilityClick(healthFacility)">
          </svg-icon>
          <div fxFlex="16px"></div>
        </div>
      </div>
    </ng-container>

    <welion-paginator fxFlexOffset="20px" [isLoading]="isLoading" [paginationLength]="paginationLength"
      [itemsCount]="healthFacilitiesCount" (onClickPage)="getHealthFacilities($event)"></welion-paginator>

  </div>

</div>
import { ChangeDetectionStrategy, Component, ContentChild, EventEmitter, Input, OnInit, Output, TemplateRef } from '@angular/core';

@Component({
  selector: 'welion-check',
  templateUrl: 'welion-check.component.html',
  styleUrls: ['./welion-check.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class WelionCheckComponent implements OnInit {
  @Input() checked: boolean = false;
  @Input() disabled: boolean = false;
  @Input() readonly: boolean = false;
  @Input() label?: string;
  @Input() attrAriaLabel?: string;
  @Input() id?: string;
  @Output() onCheck: EventEmitter<boolean> = new EventEmitter();

  @ContentChild('customLabel', { static: false }) customLabel?: TemplateRef<any>;

  constructor() { }

  ngOnInit() { }

  emitOnCheck() {
    if (!this.disabled) {
      this.checked = !this.checked;
      this.onCheck.emit(this.checked);
    }
  }
}

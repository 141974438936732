import { Component, HostListener, Inject, OnDestroy } from '@angular/core';
import { Store } from '@ngrx/store';
import { combineLatest, Observable, Subject } from 'rxjs';

import * as fromApp from '../../ngrx/app.reducers';
import * as AuthActions from '../ngrx/auth.actions';
import * as CoreActions from "../../core/ngrx/core.actions";

import { TranslateService } from '@ngx-translate/core';
import { AbstractControl, FormControl, FormGroup, ValidationErrors, Validators } from '@angular/forms';
import { RedirectService } from 'src/app/shared/services/redirect.service';
import { Lang, SenecaResponse } from 'atfcore-commonclasses';
import { ActivatedRoute } from '@angular/router';
import { takeUntil } from 'rxjs/operators';
import { AuthService } from '../services/auth.service';
import { getModalMessageData, getWarningModal } from 'src/app/utils/utils';
import { environment } from 'src/environments/environment';
import { Title } from '@angular/platform-browser';
import { DOCUMENT } from '@angular/common';

@Component({
  selector: 'app-local-login',
  templateUrl: './local-login.component.html',
  styleUrls: ['./local-login.component.scss']
})
export class LocalLoginComponent implements OnDestroy {
  isFetchingLangs: boolean = false;
  langs?: Lang[];
  siteKey: string = environment.siteKey;
  captchaToken: string = "";
  canLogIn: boolean = false;

  loginForm: FormGroup = new FormGroup({
    'email': new FormControl(undefined, Validators.required),
    'password': new FormControl(undefined, Validators.required)
  });

  get email() {
    return this.loginForm.get('email') as FormControl;
  }

  get password() {
    return this.loginForm.get('password') as FormControl;
  }

  get passwordConfirm() {
    return this.loginForm.get('passwordConfirm') as FormControl;
  }

  showLoader: boolean = false;

  isUser: boolean = false;
  isStructure: boolean = false;
  isAdmin: boolean = false;
  isHelpDesk: boolean = false;
  isClient: boolean = false;

  showResetPassword: boolean = false;
  showResetPasswordForm: boolean = false;
  resetPasswordMode: boolean = false;
  resetPasswordSuccess: boolean = false;

  // Reset della password
  supplierPersonId: string = '';
  token: string = '';

  private _unsubscribeSignal$: Subject<void> = new Subject();

  isLocalhost: boolean = false;

  constructor(
    private store: Store<fromApp.AppState>,
    public redirectService: RedirectService,
    private route: ActivatedRoute,
    private authService: AuthService,
    public translate: TranslateService,
    private titleService: Title,
    @Inject(DOCUMENT) document: Document) {
    this.isLocalhost = window.location.href.indexOf("localhost") >= 0;
    // Sto in ascolto di quando il globalApplicationData cambia, così da sapere quando ci sono le lingue disponibili poiché, se non ci fossero, il pulsante per il login sarebbe disabilitato
    combineLatest([
      this.store.select(fromApp.getAvailableLangs),
      this.store.select(fromApp.isFetchingLangs)
    ])
      .pipe(takeUntil(this._unsubscribeSignal$.asObservable()))
      .subscribe(
        ([langs, isFetchingLangs]) => {
          this.langs = langs;
          this.isFetchingLangs = isFetchingLangs;
        });
  }

  ngOnInit() {
    this.translate.get(
      [
        "generic.LOGIN"
      ]).subscribe(translations => {
        this.titleService.setTitle(translations["generic.LOGIN"]);
      });

    // Può essere login o recupero password
    if (this.redirectService.isThisCurrentPages(['structureLogin', 'emailSupplierPersonConfirmation'])) {
      this.isStructure = true;

      if (this.redirectService.isThisCurrentPage('emailSupplierPersonConfirmation')) {
        this.setPasswordConfirmation();
      } else {
        this.showResetPassword = true;
      }
    } else if (this.redirectService.isThisCurrentPage('localLogin')) {
      // L'admin al momento non ha il recupero password
      this.isAdmin = true;
    } else if (this.redirectService.isThisCurrentPage('helpDeskLogin')) {
      this.isHelpDesk = true;
    } else if (this.redirectService.isThisCurrentPages(['clientLogin', 'emailCustomerSupplierPersonConfirmation'])) {
      this.isClient = true;

      if (this.redirectService.isThisCurrentPage('emailCustomerSupplierPersonConfirmation')) {
        this.setPasswordConfirmation();
      } else {
        this.showResetPassword = true;
      }
    } else {
      // L'AD al momento non ha il recupero password
      this.isUser = true;

      if (this.redirectService.isThisCurrentPage('emailEntitledConfirmation')) {
        this.setPasswordConfirmation();
      } else {
        this.showResetPassword = true;
      }
    }

  }

  setPasswordConfirmation() {
    this.resetPasswordMode = true;
    this.supplierPersonId = this.route.snapshot.paramMap.get('supplierPersonId') || '';
    this.token = this.route.snapshot.paramMap.get('token') || '';

    this.email.clearValidators();
    this.email.updateValueAndValidity();

    this.loginForm.addControl('passwordConfirm', new FormControl(undefined, [
      Validators.required,
      this.passwordConfirmValidate
    ]));

    // Controlli OWASP
    this.password.setValidators([
      this.passwordValidate,
      Validators.minLength(8),
      Validators.maxLength(64),
      this.passwordValidatorLowerCase,
      this.passwordValidatorUpperCase,
      this.passwordValidatorNumber,
      this.passwordValidatorSpecial
    ]);
    this.password.updateValueAndValidity();
  }

  ngOnDestroy() {
    this._unsubscribeSignal$.next();
    this._unsubscribeSignal$.unsubscribe();
  }

  // Da pulsante accedi
  onLogin() {
    if (this.loginForm.valid) {

      if (this.showResetPasswordForm) {
        // Recupera la password - viene inviata una mail con il link di recupero
        this.showLoader = true;
        this.resetPassword();
      } else {
        // Effettua il login
        this.showLoader = true;
        setTimeout(() => {
          this.showLoader = false;
        }, 1300)
        this.store.dispatch(AuthActions.DoLogin({
          email: this.email.value,
          password: this.password.value,
          isUser: this.isUser,
          isStructure: this.isStructure,
          isAdmin: this.isAdmin,
          isHelpDesk: this.isHelpDesk,
          isClient: this.isClient,
          captchaToken: this.captchaToken
        }));
      }

    }
  }

  onResetPassword() {
    if (this.loginForm.valid) {
      this.confirmResetPassword();
    }
  }

  /**
   * @description Al momento il reset password è supportato solo dalle strutture
   */
  resetPassword() {

    let resetPassword$: Observable<SenecaResponse<boolean>> =
      new Observable(observable => observable.error({ message: 'FORGOT_PSW_NOT_SUPPORTED' }));

    if (this.isStructure || this.isClient) {
      resetPassword$ = this.authService.initSupplierPersonPasswordRecovery(this.email.value);
    } else if (this.isUser) {
      resetPassword$ = this.authService.initEntitledPasswordRecovery(this.email.value);
    }

    resetPassword$
      .pipe(takeUntil(this._unsubscribeSignal$.asObservable()))
      .subscribe(
        (result: SenecaResponse<boolean>) => {
          if (result && result.error) {
            this.dispatchModal('030', result.error);
          } else if (result.response) {
            this.resetPasswordSuccess = true;
          }
          this.showLoader = false;
        }, (err) => {
          if (err && err.message) {
            this.dispatchModal('031', err.message);
          }
          this.showLoader = false;
        });
  }

  /**
   * @description Al momento il reset password è supportato solo dalle strutture
   * Resetta la password e aggiorna il token
   */
  confirmResetPassword() {

    this.showLoader = true;

    let confirmResetPassword$: Observable<SenecaResponse<boolean>> =
      new Observable(observable => observable.error('Recupero password non supportato'));

    if (this.isStructure || this.isClient) {
      confirmResetPassword$ = this.authService.updateSupplierPersonPassword(this.supplierPersonId, this.token, this.password.value);
    } else if (this.isUser) {
      confirmResetPassword$ = this.authService.updateEntitledPassword(this.supplierPersonId, this.token, this.password.value);
    }

    confirmResetPassword$
      .pipe(takeUntil(this._unsubscribeSignal$.asObservable()))
      .subscribe(
        (result: SenecaResponse<boolean>) => {
          if (result && result.error) {
            this.dispatchModal('032', result.error);
          } else if (result.response) {
            this.dispatchMessageModal('login.NEW_PASSWORD', 'login.CHANGE_SUCCESS');
            if (this.isStructure) {
              this.redirectService.goToStructureLogin();
            } else if (this.isClient) {
              this.redirectService.goToClientLogin();
            } else {
              this.redirectService.goToUserLogin();
            }
          }
          this.showLoader = false;
        }, (err) => {
          if (err && err.message) {
            this.dispatchModal('033', err.message);
          }
          this.showLoader = false;
        });
  }

  onPasswordForgotClick() {
    this.showResetPasswordForm = true;
    this.password.clearValidators();
    this.password.updateValueAndValidity();
    setTimeout(() => {
      document.getElementById('welion-minimal-input-username')?.focus();
    }, 1000);
    if (this.loginForm.valid) {
      this.onLogin();
    }
  }

  /**
   * Quando viene aggiornata la password i controlli sul passwordConfirm vengono avviati
   * @param control
   */
  passwordValidate = (control: AbstractControl): ValidationErrors | null => {
    this.passwordConfirm.updateValueAndValidity();
    return null;
  }

  /**
   * Verifica la corrispondenza con la password
   * @param control
   */
  passwordConfirmValidate = (control: AbstractControl): ValidationErrors | null => {
    if (control && (this.password.valid || control.dirty) && control.value !== this.password.value) {
      return { 'passwordNotMatch': true };
    } else return null;
  }

  /**
   * Almeno una lettera minuscola, una maiuscola, un numero e un carattere speciale
   * Caratteri speciali ammessi https://owasp.org/www-community/password-special-characters
   * " !"#$%&'()*+,-./:;<=>?@[\]^_`{|}~" quelli tra virgolette
   *
   * Regex completa
   * Validators.pattern(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[ !"#$%&'()*+,-./:;<=>?@[\\\]^_`{|}~])[A-Za-z\d !"#$%&'()*+,-./:;<=>?@[\\\]^_`{|}~]{8,}$/)
   *
   * @param control
   * @returns
   */
  passwordValidatorLowerCase = (control: AbstractControl): ValidationErrors | null => {
    if (control && control.value && !control.value.match(/.*[a-z].*/)) {
      return { 'lowerCaseMiss': true };
    }
    return null;
  }

  passwordValidatorUpperCase = (control: AbstractControl): ValidationErrors | null => {
    if (control && control.value && !control.value.match(/.*[A-Z].*/)) {
      return { 'upperCaseMiss': true };
    }
    return null;
  }

  passwordValidatorNumber = (control: AbstractControl): ValidationErrors | null => {
    if (control && control.value && !control.value.match(/.*\d.*/)) {
      return { 'numberMiss': true };
    }
    return null;
  }

  passwordValidatorSpecial = (control: AbstractControl): ValidationErrors | null => {
    if (control && control.value && !control.value.match(/.*[ !"#$%&'()*+,-./:;<=>?@[\\\]^_`{|}~].*/)) {
      return { 'specMiss': true };
    }
    return null;
  }

  // Valida l'email
  // emailValidate() {
  //   let regex = /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{1,4})+$/;
  //   if (regex.test(this.email.value.trim())) {
  //     return true;
  //   }
  //   return false;
  // }

  /**
   * @description Mostra una modale con titolo e messaggio
   * @param modalId
   * @param title
   * @param message
   */
  dispatchMessageModal(title: string, message: string) {
    const messageObj = getModalMessageData(this.translate, title, message);
    this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
  }

  dispatchModal(modalId: string, error: string) {
    const messageObj = getWarningModal(this.translate, modalId, error);
    this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
  }

  // Porta alla pagina della privacy 
  openPrivacyPage() {
    this.redirectService.goToPrivacyPage();
  }

  chaptchaSolved(captchaResponse: any) {
    if (captchaResponse) {
      this.captchaToken = captchaResponse;
      this.canLogIn = true;
    } else {
      this.canLogIn = false;
    }
  }
}

import { Store } from "@ngrx/store";
import { Subscription, combineLatest, Observable } from "rxjs";
import { HttpClient, HttpParams } from "@angular/common/http";
import * as fromApp from '../../ngrx/app.reducers';
import { Injectable } from "@angular/core";
import { SenecaResponse } from "../../../commonclasses";
import { GlobalApplicationData } from "src/app/shared/models/global-application-data.model";
import * as moment from 'moment';

@Injectable()
export class StructureService {
    applicationData: any;
    result$: Subscription;
    loggedUser: any;

    constructor(private store: Store<fromApp.AppState>,
        private http: HttpClient) {
        let globalApplicationData$: Observable<GlobalApplicationData> = this.store.select(fromApp.getGlobalApplicationData);
        const loggedUser$ = this.store.select(fromApp.getLoggedUser);
        const applicationLang$: Observable<string> = this.store.select(fromApp.getApplicationLang);

        const combinedSelectes$ = combineLatest([globalApplicationData$, loggedUser$, applicationLang$]);
        this.result$ = combinedSelectes$.subscribe(
            ([globalApplicationData, loggedUser, applicationLang]) => {
                this.loggedUser = loggedUser;
                this.applicationData = globalApplicationData;
                if (applicationLang) {
                    moment.locale(applicationLang);
                }
            });
    }
}
import { Component } from '@angular/core';
import { RedirectService } from 'src/app/shared/services/redirect.service';
@Component({
    selector: 'app-compatibility-error',
    templateUrl: './compatibility-error.component.html',
    styleUrls: ['./compatibility-error.component.scss']
})
export class CompatibilityErrorComponent {
    constructor(
        public redirectService: RedirectService
    ) { }

}

<div fxLayout="column">
  <div class="slot-component-tile" [ngClass]="{
    'slot-selected': selected,
    'refused': _status === 'REFUSED',
    'revoking': _status === 'REVOKE_REQUEST',
    'revoked': _status === 'REVOKED',
    'available': _status === 'APPROVED' && _slot && _slot.isAvailable,
    'busy': _status === 'APPROVED' && _slot && !_slot.isAvailable }" fxLayout="column">
    <ng-container *ngIf="_slot">
      <!-- 09:20 · 09:30  -->
      <h6 tabindex="-1" class="black bold" [innerHTML]="
      ((_startDate | date: 'HH:mm')
        + ' · '
        + (_endDate | date: 'HH:mm')
      ) | boldText"></h6>

      <!-- Negato -->
      <h6 *ngIf=" _status==='REFUSED'" tabindex="-1" fxFlexOffset="4px" class="small black"
        translate="component.slotComponentTile.SEATS_REFUSED">
      </h6>

      <!-- In revoca -->
      <h6 *ngIf=" _status==='REVOKE_REQUEST'" tabindex="-1" fxFlexOffset="4px" class="small black"
        translate="component.slotComponentTile.SEATS_REVOKING_{{_slot.isAvailable ? 'AVAILABLE' : 'BUSY'}}">
      </h6>

      <!-- Revocato -->
      <h6 *ngIf=" _status==='REVOKED'" tabindex="-1" fxFlexOffset="4px" class="small black"
        translate="component.slotComponentTile.SEATS_REVOKED">
      </h6>

      <!-- In approvazione -->
      <h6 *ngIf=" _status==='APPROVATION_REQUEST' || _status === 'PENDING'" tabindex="-1" fxFlexOffset="4px"
        class="small black" translate="component.slotComponentTile.SEATS_PENDING">
      </h6>

      <!-- 1  su 3 slot dsponibili -->
      <h6 *ngIf="_status === 'APPROVED'" tabindex="-1" fxFlexOffset="4px" class="small black"
        translate="component.slotComponentTile.SEATS_{{_slot.isAvailable ? 'AVAILABLE' : 'BUSY'}}">
      </h6>

      <!-- Annullato -->
      <h6 *ngIf="fromReservationDeletion" tabindex="-1" fxFlexOffset="4px" class="small black italic-text"
        translate="component.slotComponentTile.PLACE_CANCELED">
      </h6>

    </ng-container>
  </div>
</div>
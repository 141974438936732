<section *ngIf="_entitled || isFamiliarToAdd" class="card-ticket" fxLayout="column"
  [ngClass]="{'with-reservation': _attendance, 'with-reservation-code': _attendance && _attendance.reservationId && withReservationId }">

  <div class="card-ticket-header" fxFlex="116px" fxLayout="column" [fxLayoutAlign]="_attendance ? '' : 'start center'">
    <ng-container *ngIf="_attendance">
      <div fxLayout="column" fxFlexFill>
        <!-- MARTEDì -->
        <p class="small light day-name">{{ _attendance.startDate | date: 'EEEE' | uppercase}}</p>
        <div fxLayout="row" fxLayoutAlign="space-between end" fxFlexOffset="4px">
          <!-- 01 MAR -->
          <p class="big light day-number">{{ _attendance.startDate | date: 'dd MMM' | uppercase}}</p>
          <p class="small light day-time">
            {{ _attendance.startDate | date: 'HH:mm' }} - {{ _attendance.endDate | date: 'HH:mm' }}
          </p>
        </div>
      </div>
    </ng-container>
    <ng-container *ngIf="!_attendance && !isFamiliarToAdd">
      <!-- Prenotazione personale -->
      <h2 *ngIf="!isParent" fxFlexOffset="20px" class="bold" translate="component.cardTicket.BOOKING_SELF"></h2>
      <!-- Prenotazione per familiare -->
      <h2 *ngIf="isParent" fxFlexOffset="20px" class="bold" translate="component.cardTicket.BOOKING_FAMILY"></h2>
    </ng-container>
  </div>

  <div class="card-ticket-divider" fxFlex="16px" fxLayout="row" fxLayoutAlign="start center">
    <div class="round left"></div>
    <div fxFlex class="line"></div>
    <div class="round right"></div>
  </div>

  <div class="card-ticket-body" fxLayout="column">

    <!-- NOMINATIVO -->
    <h3 *ngIf="!isFamiliarToAdd" fxFlexOffset="4px" class="extra-small" translate="component.cardTicket.NOMINATIVE">
    </h3>

    <ng-container *ngIf="_entitled && _entitled.user">
      <!-- MONDELLA LUCIA -->
      <p class="value-data" fxFlexOffset="4px">
        {{ (_entitled.user.surname + ' ' + _entitled.user.forename) | uppercase }}
      </p>
    </ng-container>

    <!-- ID PRENOTAZIONE -->
    <h3 *ngIf="_attendance && _attendance.reservationId && withReservationId" fxFlexOffset="20px" class="extra-small"
      translate="component.cardTicket.PREN_CODE"></h3>
    <ng-container *ngIf="_attendance && _attendance.reservationId && withReservationId">
      <p class="value-data" fxFlexOffset="4px">
        {{ _attendance.reservationId }}
      </p>
    </ng-container>

    <div fxFlexOffset="20px" fxLayout="row" fxLayoutAlign="space-between"
      *ngIf="(_entitled && _entitled.user && _entitled.user.birthDate) || _attendance">
      <!-- DATA DI NASCITA -->
      <div fxFlex="50" fxLayout="column" *ngIf="_entitled && _entitled.user && _entitled.user.birthDate">
        <h3 class="extra-small" translate="component.cardTicket.BIRTHDATE"></h3>

        <!-- 01/01/1996 -->
        <p class="value-data" fxFlexOffset="4px">
          {{ (_entitled.user.birthDate) | date: 'dd/MM/yyyy' }}
        </p>
      </div>
      <!-- STATO -->
      <div fxFlex="50" fxLayout="column" *ngIf="_attendance">
        <h3 class="extra-small" translate="component.cardTicket.STATE"></h3>

        <!-- PRENOTATO -->
        <p class="value-data" fxFlexOffset="4px">
          {{ ('statusTranscoded.' + _attendance.status) | translate }}
        </p>
      </div>
    </div>

    <ng-container *ngIf="_attendance && _entitled && _entitled.venue">
      <div fxFlexOffset="7px" fxLayout="row" fxLayoutAlign="space-between center">
        <!-- STRUTTURA -->
        <h3 class="extra-small height-24 center-vertical" translate="component.cardTicket.STRUCTURE"></h3>
        <!-- SCHEDA -->
        <div *ngIf="!_readOnly" [attr.id]="id" (click)="onOpenVenue.emit()" role="button"
          attr.aria-label="{{ 'component.cardTicket.FORM' | translate }}" tabindex="0" clickKeyBoard
          (onEnterKeyboard)="onOpenVenue.emit()">
          <h3 class="extra-small bold cursor-pointer underline height-24 center-vertical"
            translate="component.cardTicket.FORM"></h3>
        </div>

      </div>
      <!-- POLIAMBULATORIO MEDICINA E .. -->
      <p class="value-data" fxFlexOffset="4px">{{ _entitled.venue.name | uppercase  }}</p>

      <!-- INDIRIZZO -->
      <h3 fxFlexOffset="20px" class="extra-small" translate="component.cardTicket.ADDRESS"></h3>
      <!-- Viale della Repubblica .. -->
      <p class="value-data" fxFlexOffset="4px">{{ _entitled.venue.address  }}</p>

    </ng-container>

    <ng-container *ngIf="!_attendance">
      <div fxFlex fxLayout="column" fxLayoutAlign="center center">
        <!-- Prenotazione chiusa -->
        <welion-button *ngIf="!isFamiliarToAdd && (closedBooking || forceClosedBooking || vaccinationClosed)"
          label="primary" type="primary" size="large" width="196px" label="component.cardTicket.CLOSED_BOOKING"
          [disabled]="true">
        </welion-button>
        <!-- Prenota -->
        <welion-button [disabled]="isUnder2Years"
          *ngIf="!isFamiliarToAdd && !closedBooking && !forceClosedBooking && !vaccinationClosed" label="primary"
          type="primary" size="large" width="196px" label="component.cardTicket.BOOK"
          (onClick)="onBookingTicket.emit()">
        </welion-button>
        <!-- Aggiungi un familiare -->
        <welion-button *ngIf="isFamiliarToAdd" label="primary" type="primary" size="large" width="196px"
          label="component.cardTicket.ADD_FAMILIAR" (onClick)="goToAddFamiliar()">
        </welion-button>
        <p *ngIf="isFamiliarToAdd && familiarsTypeToChoose && familiarsTypeToChoose.length" class="add-familiar-text"
          [innerHTML]="'component.cardTicket.ADD_FAMILIAR_DESCR' | translate: {value: familiarsTypeToChoose}"></p>
      </div>
    </ng-container>

  </div>

  <div *ngIf="!_readOnly && !isFamiliarToAdd && !forceDisableActions" class="card-ticket-footer" fxFlex="44px"
    fxLayout="row" fxLayoutAlign="space-between center">
    <ng-container *ngIf="!manageBooking">
      <!-- Annulla prenotazione -->
      <a role="button" *ngIf="!isParent && !isDisabled" class="text-centered" [ngClass]="{
      'cursor-pointer': !isDisabled,
      'disabled': isDisabled
    }" translate="component.cardTicket.CALCEL_RESERVATION" (click)="onCancelTicketClick()" clickKeyBoard
        (onEnterKeyboard)="onCancelTicketClick()" tabindex="0"></a>
      <a role="button" *ngIf="isParent" class="text-centered important bold underline cursor-pointer" translate="generic.EDIT_DATA"
        (click)="onOpenEditDataClick()" clickKeyBoard (onEnterKeyboard)="onOpenEditDataClick()" tabindex="0"></a>
      <!-- Modifica -->
      <a class="text-centered" *ngIf="false" [ngClass]="{'cursor-pointer': _attendance}"
        translate="component.cardTicket.EDIT" (click)="onEditTicket.emit()" clickKeyBoard
        (onEnterKeyboard)="onEditTicket.emit()" tabindex="0">
      </a>
    </ng-container>

    <!-- Gestisci prenotazione -->
    <ng-container *ngIf="manageBooking">
      <!-- Questo doppio blocco serve ad evitare il fatto che, se il dropdown è già aperto, il click su "Gestisci" lo faccia riaprire. Questo perché la chiusura è già gestita dall'onBlur() -->
      <ng-container>
        <div *ngIf="_isDropdownOpen" fxLayout="row" class="manage-dropdown-container">
          <a class="bold underline">{{ 'component.cardTicket.MANAGER_BOOKING' | translate }}
          </a>
        </div>

        <div *ngIf="!_isDropdownOpen" fxLayout="row" class="manage-dropdown-container" (click)="openDropdownMenu()"
          tabindex="0" role="button" attr.aria-label="{{ 'component.cardTicket.MANAGER_BOOKING' | translate }}"
          clickKeyBoard (onEnterKeyboard)="openDropdownMenu()">
          <a class="bold underline">{{ 'component.cardTicket.MANAGER_BOOKING' | translate }}
          </a>
        </div>
      </ng-container>

      <!-- Dropdown completamente disabilitato
      <div *ngIf="isDisabled" fxLayout="row" class="manage-dropdown-container">
        <h3 class="disabled">{{ 'component.cardTicket.MANAGER_BOOKING' | translate }}
        </h3>
      </div> -->
      <div *ngIf="_isDropdownOpen" [id]="cardId + '-pannel'" class="button-dropdown" tabindex="0" clickKeyBoard
        (onEscKeyboard)="onBlur($event)" (blur)="onBlur($event);">
        <!-- Sezioni dropdown -->
        <div *ngFor="let item of dropdownOptions" class="dropdown-select" fxLayout="row" fxLayoutAlign="start center"
          [ngClass]="{
            'item-disabled': item.isDisabled }" (mousedown)="onItemAction(item, $event, _attendance)">
          <div tabindex="0" clickKeyBoard (onEnterKeyboard)="onItemAction(item, $event, _attendance)"
            (onEscKeyboard)="onBlur($event)" fxLayout="row" fxLayoutAlign="start center">
            <svg-icon *ngIf="item.icon" [src]="item.icon" class="small-size" [applyClass]="true">
            </svg-icon>
            <!-- Label -->
            <div fxLayout="column">
              <p [ngClass]="{
                'item-disabled': item.isDisabled }" [fxFlexOffset]="item.icon ? '8px' : ''" tabindex="-1"
                class="margin-bottom4 bold-text">{{ item.label |
                translate }}</p>
              <p tabindex="-1" *ngIf="item.subtitle" class="italic-text" [ngClass]="{
                'item-disabled': item.isDisabled }">{{ item.subtitle}}</p>
            </div>
          </div>
        </div>
      </div>
    </ng-container>
  </div>

</section>
/*
*    Dichiarazione dei moduli comuni e delle direttive
*/

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AppTranslateModule } from '../translate/translate.module';
import { PaginationComponent } from '../core/pagination/pagination.component';
import { NgxPaginationModule } from 'ngx-pagination';
import { MomentModule } from 'ngx-moment';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FileUploadModule } from 'ng2-file-upload';
import { FlexLayoutModule } from '@angular/flex-layout';
import { NgSelectModule } from '@ng-select/ng-select';
import { SafeHtmlPipe } from './pipes/safe-html.pipe';
import { AgmCoreModule } from '@agm/core';
import { WelionButtonComponent } from './components/welion-button/welion-button.component';
import { InputContainerComponent } from './components/input-container/input-container.component';
import { WelionSelectComponent } from './components/welion-select/welion-select.component';
import { WelionInputComponent } from './components/welion-input/welion-input.component';
import { WelionSwitchComponent } from './components/welion-switch/welion-switch.component';
import { WelionCheckComponent } from './components/welion-check/welion-check.component';
import { WelionTextAreaComponent } from './components/welion-text-area/welion-text-area.component';
import { WelionRadioComponent } from './components/welion-radio/welion-radio.component';
import { WelionUploadComponent } from './components/welion-upload/welion-upload.component';
import { WelionDropdownItemComponent } from './components/welion-dropdown-item/welion-dropdown-item.component';
import { ModalComponent } from './components/modal/modal.component';
import { ModalTextContentComponent } from './components/modal-text-content/modal-text-content.component';
import { WelionDatepickerComponent } from '../core/welion-datepicker/welion-datepicker.component';
import { WelionPopUpComponent } from './components/welion-pop-up/welion-pop-up.component';
import { AvatarComponent } from './components/avatar-img/avatar-img.component';
import { PageContainerComponent } from './components/page-container/page-container.component';
import { SidenavInformationsComponent } from './components/sidenav-informations/sidenav-informations.component';
import { KalturaPlayerComponent } from '../core/kaltura-player/kaltura-player.component';
import { ClickOutsideDirective } from './directive/click-outside.directive';
import { WelionAccordionComponent } from './components/welion-accordion/welion-accordion.component';
import { BadgeNotificationsComponent } from './components/badge-notifications/badge-notifications.component';
import { TruncatePipe } from './directive/truncate.directive';
import { TabDossierComponent } from './components/tab-dossier/tab-dossier.component';
import { ChildContentComponent } from './components/child-content/child-content.component';
import { NoDataPlaceholderComponent } from './components/no-data-placeholder/no-data-placeholder.component';
import { TabLightComponent } from './components/tab-light/tab-light.component';
import { UserInitialPipe } from './pipes/initials.pipe';
import { WelionTableComponent } from './components/welion-table/welion-table.component';
import { PrivacyComponent } from './components/privacy/privacy.component';
import { TooltipDirective } from './directive/welion-tooltip.directive';
import { TabSectionComponent } from './components/tab-section/tab-section.component';
import { SidebarComponent } from './components/sidebar/sidebar.component';
import { SlotComponentFlagComponent } from './components/slot-component-flag/slot-component-flag.component';
import { SlotComponentTagComponent } from './components/slot-component-tag/slot-component-tag.component';
import { WelionMinimalInputComponent } from './components/welion-minimal-input/welion-minimal-input.component';
import { BoldTextPipe } from './pipes/bold-text';
import { CardRoundHomeComponent } from './components/card-round-home/card-round-home.component';
import { BreadCrumbsComponent } from './components/bread-crumbs/bread-crumbs.component';
import { CardCampaignComponent } from './components/card-campaign/card-campaign.component';
import { CardRoundComponent } from './components/card-round/card-round.component';
import { SlotComponentTileComponent } from './components/slot-component-tile/slot-component-tile.component';
import { WelionSelectButtonComponent } from './components/welion-select-button/welion-select-button.component';
import { ModalUploadComponent } from './components/modal-upload/modal-upload.component';
import { CardTicketComponent } from './components/card-ticket/card-ticket.component';
import { EnelPrivacyComponent } from './components/enelPrivacy/enelPrivacy.component';
import { FAQComponent } from './components/faq/faq.component';
import { FooterComponent } from '../core/footer/footer.component';
import { WelionPaginatorComponent } from './components/welion-paginator/welion-paginator.component';
import { NgxAutocomPlaceDirective } from './directive/ngx-autocom-place.directive';
import { AddTicketComponent } from './addTicket/addTicket.component';
import { WelionUploadDeletePeopleComponent } from './components/welion-upload-delete-people/welion-upload-delete-people.component';
import { AdminDashboardComponent } from './dashboard/admin-dashboard.component';
import { DxPieChartModule } from 'devextreme-angular/ui/pie-chart';
import { DxBarGaugeModule } from 'devextreme-angular/ui/bar-gauge';
import { DxChartModule } from 'devextreme-angular/ui/chart';
import { AccessibilityComponent } from './components/accessibility/accessibility.component';
import { WelionOrderSelectComponent } from './components/welion-order-select/welion-order-select.component';
import { RecaptchaModule } from "ng-recaptcha";
import { A11yModule } from '@angular/cdk/a11y';
import { ClickKeyboardDirective } from './directive/click-keyboard.directive';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatNativeDateModule } from '@angular/material/core';
import { OwlDateTimeModule, OwlNativeDateTimeModule } from '@busacca/ng-pick-datetime';
import { RouterModule } from '@angular/router';


@NgModule({
  declarations: [
    FooterComponent,
    SidebarComponent,
    PaginationComponent,
    SafeHtmlPipe,
    WelionButtonComponent,
    InputContainerComponent,
    WelionSelectComponent,
    WelionInputComponent,
    WelionSwitchComponent,
    WelionCheckComponent,
    WelionTextAreaComponent,
    WelionRadioComponent,
    WelionDropdownItemComponent,
    ModalComponent,
    ModalTextContentComponent,
    WelionUploadComponent,
    WelionUploadDeletePeopleComponent,
    WelionDropdownItemComponent,
    WelionDatepickerComponent,
    AvatarComponent,
    TooltipDirective,
    WelionPopUpComponent,
    PageContainerComponent,
    SidenavInformationsComponent,
    KalturaPlayerComponent,
    ClickOutsideDirective,
    PrivacyComponent,
    EnelPrivacyComponent,
    WelionAccordionComponent,
    BadgeNotificationsComponent,
    TruncatePipe,
    TabDossierComponent,
    ChildContentComponent,
    NoDataPlaceholderComponent,
    TabLightComponent,
    UserInitialPipe,
    WelionTableComponent,
    TabSectionComponent,
    SlotComponentFlagComponent,
    SlotComponentTagComponent,
    WelionMinimalInputComponent,
    BoldTextPipe,
    CardRoundHomeComponent,
    BreadCrumbsComponent,
    CardCampaignComponent,
    CardRoundComponent,
    SlotComponentTileComponent,
    WelionSelectButtonComponent,
    ModalUploadComponent,
    CardTicketComponent,
    FAQComponent,
    AddTicketComponent,
    WelionPaginatorComponent,
    NgxAutocomPlaceDirective,
    AdminDashboardComponent,
    AccessibilityComponent,
    WelionOrderSelectComponent,
    ClickKeyboardDirective
  ],
  imports: [
    A11yModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    NgxPaginationModule,
    FlexLayoutModule,
    AppTranslateModule.forRoot(),
    FileUploadModule,
    MomentModule,
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyBZ25MNayUTrWKe9uvvPj3DyoihFuGpCvY'
    }),
    OwlDateTimeModule,
    OwlNativeDateTimeModule,
    AngularSvgIconModule,
    FormsModule,
    NgSelectModule,
    DxPieChartModule,
    DxChartModule,
    DxBarGaugeModule,
    RecaptchaModule,
    MatFormFieldModule, MatInputModule, MatDatepickerModule, MatNativeDateModule,
    RouterModule
  ],
  exports: [
    RouterModule,
    CommonModule,
    AppTranslateModule,
    FormsModule,
    ReactiveFormsModule,
    FlexLayoutModule,
    FileUploadModule,
    AgmCoreModule,
    PaginationComponent,
    MomentModule,
    AngularSvgIconModule,
    NgSelectModule,
    SafeHtmlPipe,
    WelionButtonComponent,
    InputContainerComponent,
    WelionSelectComponent,
    WelionInputComponent,
    WelionSwitchComponent,
    WelionCheckComponent,
    WelionTextAreaComponent,
    WelionRadioComponent,
    WelionDropdownItemComponent,
    ModalComponent,
    ModalTextContentComponent,
    WelionUploadComponent,
    WelionUploadDeletePeopleComponent,
    WelionDropdownItemComponent,
    WelionDatepickerComponent,
    AvatarComponent,
    TooltipDirective,
    FooterComponent,
    WelionPopUpComponent,
    PageContainerComponent,
    SidenavInformationsComponent,
    KalturaPlayerComponent,
    ClickOutsideDirective,
    PrivacyComponent,
    EnelPrivacyComponent,
    WelionAccordionComponent,
    BadgeNotificationsComponent,
    TruncatePipe,
    TabDossierComponent,
    ChildContentComponent,
    NoDataPlaceholderComponent,
    TabLightComponent,
    TabLightComponent,
    UserInitialPipe,
    WelionTableComponent,
    TabSectionComponent,
    SidebarComponent,
    SlotComponentFlagComponent,
    SlotComponentTagComponent,
    WelionMinimalInputComponent,
    BoldTextPipe,
    CardRoundHomeComponent,
    BreadCrumbsComponent,
    CardCampaignComponent,
    CardRoundComponent,
    SlotComponentTileComponent,
    WelionSelectButtonComponent,
    ModalUploadComponent,
    CardTicketComponent,
    FAQComponent,
    AddTicketComponent,
    WelionPaginatorComponent,
    NgxAutocomPlaceDirective,
    AdminDashboardComponent,
    DxPieChartModule,
    DxChartModule,
    DxBarGaugeModule,
    WelionOrderSelectComponent,
    RecaptchaModule,
    ClickKeyboardDirective,
    MatFormFieldModule, MatInputModule, MatDatepickerModule, MatNativeDateModule

  ]
})

export class SharedModule {
}

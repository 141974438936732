<div *ngIf="langs && langs.length">
  <div class="main-content">
    <div class="impersonation-login-content" fxLayout="column" fxLayoutAlign="center center">
      <div class="loader spinner-big"></div>
    </div>
  </div>
</div>

<!-- Nessuna lingua disponibile a sistema -->
<div *ngIf="!langs || !langs.length">
  <p translate="errors.NO_AVAILABLE_LANG"></p>
</div>

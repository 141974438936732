import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'welion-switch',
  templateUrl: 'welion-switch.component.html',
  styleUrls: ['./welion-switch.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class WelionSwitchComponent implements OnInit {
  @Input() leftLabelObject: string = 'NO';
  @Input() rightLabelObject: string = "SI";
  @Input() checked: boolean = false;
  @Input() disabled: boolean = false;
  @Input() readonly: boolean = false;
  @Input() id: string = '';
  @Output() onSwitch: EventEmitter<any> = new EventEmitter();

  constructor() { }

  ngOnInit() { }

  emitOnSwitch(fromKeyBoard?: boolean) {
    if (!this.disabled) {
      this.checked = !this.checked;
      this.onSwitch.emit(this.checked);
    }
  }
}
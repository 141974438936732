import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { showIf } from 'src/app/utils/animation.utils';
import { ButtonSize } from '../welion-button/welion-button.component';

export interface DropdownButtonItem {
  label: string,
  onClick: (currentSelect?: any) => void,
  disabled?: boolean,
  disabledFct?: (currentSelect?: any) => boolean,
  icon?: string,
  strokeIcon?: boolean // fill or stroke
}

@Component({
  selector: 'welion-select-button',
  templateUrl: 'welion-select-button.component.html',
  styleUrls: ['./welion-select-button.component.scss'],
  animations: [showIf],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class WelionSelectButtonComponent implements OnInit {

  _id?: string;
  @Input() id?: string | number;

  @Input() label: string = 'generic.ADD';
  @Input() type: string = 'primary select';
  @Input() size: ButtonSize = 'medium';
  @Input() iconRight: string = 'assets/img/icons/chevron-down.svg';

  @Input() direction: 'left' | 'right' = 'right';

  @Input() dropdownItems: DropdownButtonItem[] = [];

  @Input() disabled: boolean = false;

  _isLink: boolean = false;
  @Input() forceModal: boolean = false;
  @Input() set isLink(value: string | boolean) {
    this._isLink = coerceBooleanProperty(value);
  }

  @Input() currentSelect?: any;

  @Input() closeDropdown?: Observable<void>;
  @Input() openDropdown?: Observable<void>;

  @Input() isFromDropdownClient: boolean = false;

  _isOpen: boolean = false;

  private _unsubscribeSignal$: Subject<void> = new Subject();
  // aggiunto per la visualizzare modale custom al click con prevent default
  @Output() onClick: EventEmitter<any> = new EventEmitter();

  constructor() { }

  ngOnInit() {

    if (!this.id) {
      this.id = Math.floor((Math.random() * 10000) + 1);
    }
    this._id = "welion-select-button-" + this.id;

    if (this.closeDropdown) {
      this.closeDropdown
        .pipe(takeUntil(this._unsubscribeSignal$.asObservable()))
        .subscribe(() => {
          this.onCloseDrowpdown();
        });
    }

    if (this.openDropdown) {
      this.openDropdown
        .pipe(takeUntil(this._unsubscribeSignal$.asObservable()))
        .subscribe(() => {
          this.onOpenDrowpdown();
        });
    }
  }

  mainButtonClick() {
    if (!this.disabled && !this.forceModal) {
      if (this._isOpen) {
        this.onCloseDrowpdown();
      } else {
        this.onOpenDrowpdown();
      }
    } else if (this.forceModal) {
      this.onClick.emit();
    }
  }

  onBlur() {
    this.onCloseDrowpdown();
  }

  private onCloseDrowpdown() {
    this._isOpen = false;
  }

  private onOpenDrowpdown() {
    this._isOpen = true;

    if (this._id) {
      let timeout = setTimeout(() => {
        if (this._isOpen) {
          const mainButton = window.document.getElementById(this._id + '-pannel');
          if (mainButton) {
            mainButton.focus();
          }
        }
        clearTimeout(timeout);
      }, 400);
    }
  }

  onItemAction(item: DropdownButtonItem, event: any) {
    if (!item.disabled && (!item.disabledFct || !item.disabledFct(this.currentSelect))) {
      item.onClick(this.currentSelect);
      this.onCloseDrowpdown();
    }
    event.stopPropagation();
  }
}

import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  Router
} from "@angular/router";
import { Injectable } from "@angular/core";
import { Store, select } from '@ngrx/store';
import { map } from "rxjs/operators";
// Import dello State dell'applicativo
import * as fromApp from "../../ngrx/app.reducers";
// Import delle azioni dell'auth
import * as AuthActions from "../ngrx/auth.actions";
// Import delle azioni del core
import * as CoreActions from "../../core/ngrx/core.actions";
import { AuthService } from './auth.service';
import { Observable } from "rxjs";
import { getTokenFromSsortkqp, removeURLParameter } from "src/commonclasses";
import { ApplicationModalMessage } from "src/app/core/ngrx/core.reducers";
import { TranslateService } from "@ngx-translate/core";
import { RedirectService } from "src/app/shared/services/redirect.service";

@Injectable()
export class AuthGuard implements CanActivate {
  constructor(
    private store: Store<fromApp.AppState>,
    private router: Router,
    private redirectService: RedirectService,
    private authService: AuthService,
    private translate: TranslateService
  ) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> {
    return this.store.pipe(select(fromApp.getAuthAppState))
      .pipe(
        map((authState) => {
          let url = state.url;
          let urlWithoutToken = null;
          let skipSso: boolean = false;

          if (authState.authenticated) {
            // Utente correttamente loggato e può accedere alla risorsa
            return true;
          }

          if (!urlWithoutToken) {
            urlWithoutToken = url;
          }

          const query =
            window.location.search.substring(1) ||
            window.location.hash.substring(1);
          const vars = query.split("&");
          let skipSsoParam: any;
          let ssortkqpParam: any;
          let urlToken = null;
          if (vars && vars.length) {
            for (let i = 0; i < vars.length; i++) {
              let pair = vars[i].split("=");
              if (
                decodeURIComponent(pair[0]) == "skipSso" ||
                pair[0].includes("skipSso")
              ) {
                skipSsoParam = decodeURIComponent(pair[1]);
                break;
              }
              if (
                decodeURIComponent(pair[0]) == "token" ||
                pair[0].includes("token")
              ) {
                urlToken = decodeURIComponent(pair[1]);
                // sessionStorageToken = urlToken;
                break;
              } else if (
                decodeURIComponent(pair[0]) == "ssortkqp" ||
                pair[0].includes("ssortkqp")
              ) {
                ssortkqpParam = decodeURIComponent(pair[1]);
                break;
              }
            }
          }

          if (skipSsoParam) {
            skipSso = true;
            url = removeURLParameter(url, "skipSso");
          }

          // Salvataggio se dell'url di redirect se questo è tra gli entrypoint consentiti
          if (url) {
            if (ssortkqpParam) {
              url = removeURLParameter(url, "ssortkqp");
            }
            this.store.dispatch(CoreActions.SaveRedirectUrl({ payload: url }));
            sessionStorage.setItem("redirectUrl", url);
          }

          if (ssortkqpParam && !urlToken) {
            const getTokenFromKeyPromise = getTokenFromSsortkqp(ssortkqpParam, this.authService);
            getTokenFromKeyPromise.then((token: string | null) => {
              if (token && token.length) {
                sessionStorage.setItem("isFromSSO", 'true');
                this.store.dispatch(AuthActions.SetUserAuthenticated());
                this.store.dispatch(CoreActions.StartRenewTokenPolling({}));
                return true;
              } else {
                let url = state.url;
                if (url) {
                  if (skipSsoParam) {
                    skipSso = true;
                    url = removeURLParameter(url, "skipSso");
                  }
                  if (ssortkqpParam) {
                    url = removeURLParameter(url, "ssortkqp");
                  }
                  this.store.dispatch(CoreActions.SaveRedirectUrl({ payload: url }));
                }

                if (skipSso) {
                  // this.redirectToLogin();
                  this.redirectService.goToLoginSSO();
                } else {
                  // Da decommentare se si vuole il login locale
                  // this.redirectToLogin();
                  this.redirectService.goToLoginSSO();
                }
                return;
              }
            })
              .catch((e) => {
                const messageObj: ApplicationModalMessage = {
                  modalId: "027",
                  text: this.translate.instant("errors." + ((e && e.message) || e)),
                  title: this.translate.instant("generic.WARNING")
                }
                this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));

                // Se l'errore riguarda le auth allora vado nella pagina 403
                if (e && e.indexOf('AUTH') !== -1) {
                  this.router.navigate(["/403-not-allowed"]);
                }
                return;
              })
          } else {
            this.authService.getJWTToken().subscribe(auth => {
              if (auth?.response) {
                this.store.dispatch(AuthActions.SetUserAuthenticated());
                this.store.dispatch(CoreActions.StartRenewTokenPolling({}));
                return true;
              } else {
                // Utente non loggato, quindi redirect alla pagina di Login. Prima però salvo nello Store l'url corrente affinché, una volta eseguito correttamente il login, si esegua il redirect nella pagina richiesta
                let url = state.url;
                if (url) {
                  if (skipSsoParam) {
                    skipSso = true;
                    url = removeURLParameter(url, "skipSso");
                  }
                  if (ssortkqpParam) {
                    url = removeURLParameter(url, "ssortkqp");
                  }
                  this.store.dispatch(CoreActions.SaveRedirectUrl({ payload: url }));
                }
  
                if (skipSso) {
                  // this.redirectToLogin();
                  this.redirectService.goToLoginSSO();
                } else {
                  // this.redirectToLogin();
                  this.redirectService.goToLoginSSO();
                }
              }
              return false;
            })
          }

          return true;
        })
      )
  }

  private checkRouteGuard(page: string) {
    const hash = window.location.hash;
    return page && hash && hash.indexOf(page) !== -1 ? true : false;
  }

  private redirectToLogin() {
    if (this.checkRouteGuard('admin')) {
      this.redirectService.goToAdminLogin();
    } else if (this.checkRouteGuard('structure')) {
      this.redirectService.goToStructureLogin();
    } else if (this.checkRouteGuard('helpDesk')) {
      this.redirectService.goToHelpDeskLogin();
    } else if (this.checkRouteGuard('client')) {
      this.redirectService.goToClientLogin();
    } else {
      this.redirectService.goToUserLogin();
    }
  }
}

import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Router } from '@angular/router';
import { map, switchMap, withLatestFrom, takeWhile, tap } from 'rxjs/operators';
import { catchError } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import * as fromCore from './core.reducers';
import * as fromApp from '../../ngrx/app.reducers';
import { UrlService } from '../../shared/services/url.service';
import * as CoreActions from './core.actions';
import * as ProfileActions from '../../core/profile/ngrx/profile.actions';
import { LangsService } from '../services/langs.service';
import { TranslateService } from '@ngx-translate/core';
import { timer, from, Observable } from 'rxjs';
import * as AuthActions from '../../auth/ngrx/auth.actions';
import { AuthService } from '../../auth/services/auth.service';
import { GlobalApplicationData } from 'src/app/shared/models/global-application-data.model';
import { Lang, SenecaResponse } from 'atfcore-commonclasses';
import { RedirectService } from 'src/app/shared/services/redirect.service';

@Injectable()
export class CoreEffects {
  loggedUser: any;
  constructor(private actions$: Actions,
    private router: Router,
    private authService: AuthService,
    private langsService: LangsService,
    private urlService: UrlService,
    private translate: TranslateService,
    private redirectService: RedirectService,
    private store: Store<fromCore.CoreState>) {
  }

  // Di default, inserisco la lingua del browser dell'utente, recupera grazie ad una funzione
  defaultLang: string = this.langsService.getBrowserLang();
  // Verifica se l'utente è autenticato
  isAuthenticated: boolean = false;
  // Tiny token
  tinyToken: string = "";
  // Token intero
  tokenObj: any;
  authObject: any;
  forceRefreshUser?: boolean;
  redirectUrl?: string;

  startRenewTokenPolling$ = createEffect(() => this.actions$
    .pipe(
      ofType(CoreActions.StartRenewTokenPolling)
      , map((action) => {
        this.redirectUrl = action && action.payload && action.payload.redirectUrl || '';
        this.forceRefreshUser = action && action.payload && action.payload.forceRefreshUser || false;
        return action.payload;
      })
      , switchMap(
        () => timer(0, 3000000)
          .pipe(
            withLatestFrom(this.store.select(fromApp.isAuthenticated))
            , map(([action, isAuthenticated]) => {
              this.isAuthenticated = isAuthenticated;
            })
            , takeWhile(() => this.isAuthenticated),
            switchMap(() => {
              return from(this.authService.renewToken(this.forceRefreshUser));
            }),
              switchMap(
                (token: SenecaResponse<string>) => {
                this.tinyToken = token.response;

                return from(this.authService.getJWTToken());
              })
              , map((tokenObj: SenecaResponse<string>) => {
                this.store.dispatch(ProfileActions.DecodeToken({ payload: tokenObj.response }));
                return tokenObj.response;
              })
            , map((tokenObj: any) => {
              if (tokenObj && tokenObj.error) {
                throw (new Error(tokenObj.error));
              } else {
                this.tokenObj = tokenObj.response;
                return this.store.dispatch(AuthActions.SetToken({ payload: this.tinyToken }));
              }
            })
            , withLatestFrom(
              this.store.select(fromApp.getLoggedUser),
              this.store.select(fromApp.getIsStructure),
              this.store.select(fromApp.getIsAdmin),
              this.store.select(fromApp.getIsUser),
              this.store.select(fromApp.getIsHelpDesk)
              // To avoid errors for overlap - withLatestFrom accept max 5 args
              // ,this.store.select(fromApp.getIsClient)
            )
            , switchMap(([action, loggedUser, isStructure, isAdmin, isUser, isHelpDesk]) => {
                            this.loggedUser = loggedUser;
              if (this.redirectUrl) {
                this.router.navigate([this.redirectUrl]);
              } else {
                // Se non ce l'ho nemmeno nel session storage, allora lo setto per evitare il redirect automatico in home page
                let sessionStorageRedirectUrl = sessionStorage.getItem('redirectUrl');
                if (!sessionStorageRedirectUrl) {
                  let url = this.router.url;
                  if (url) {
                    sessionStorage.setItem('redirectUrl', url);
                  }
                }
              }

              let langToUse = this.langsService.getUserLang(this.loggedUser.user);
              this.langsService.useLanguage(langToUse);
              return [CoreActions.SetApplicationLang({ payload: langToUse }), AuthActions.RetrieveUserAcknowledges()];
            })
          )
      )
      , catchError((err, caught) => {
        this.translate.setDefaultLang(this.defaultLang);
        if (err && err.message) {
          if (err.message == "OLD_TOKEN_NOT_FOUND") {
            const messageObj: fromCore.ApplicationModalMessage = {
              modalId: "068",
              text: this.translate.instant("errors.OLD_TOKEN_NOT_FOUND"),
              title: this.translate.instant("generic.WARNING")
            }
            this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
            this.redirectService.goToLogin();
          } else {
            const messageObj: fromCore.ApplicationModalMessage = {
              modalId: "069",
              text: this.translate.instant("errors." + err.message),
              title: this.translate.instant("generic.WARNING")
            }
            this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
          }
          this.redirectService.goToLogin();
        }
        return caught;
      })
    )
  )

  coreActions$ = createEffect(() => this.actions$
    .pipe(
      ofType(CoreActions.GetAvailableLangs)
      , withLatestFrom(this.store.select(fromApp.getAvailableLangs))
      , switchMap(([action, storeLangs]) => {
        const retrievedLangs: Lang[] = storeLangs;
        if (retrievedLangs && retrievedLangs.length) {
          this.store.dispatch(CoreActions.GetAvailableLangsFinished());
          return new Observable();
        } else {
          return this.langsService.getAvailableLangs();
        }
      })
      , map(
        (senecaResponse: any) => {
          if (senecaResponse.response) {
            for (let i = 0, langsLength = senecaResponse.response.length; i < langsLength; i++) {
              if (senecaResponse.response[i] && senecaResponse.response[i].mandatory && senecaResponse.response[i].langCode) {
                this.defaultLang = senecaResponse.response[i].langCode.substring(0, 2);
                break;
              }
            }

            return this.store.dispatch(ProfileActions.SaveAvailableLangs({ payload: senecaResponse.response }));
          }
        }
      )
      , withLatestFrom(this.store.select(fromApp.getGlobalApplicationData))
      , switchMap(([action, savedGlobalApplicationData]) => {
        this.translate.setDefaultLang(this.defaultLang);
        return this.translate.use(this.defaultLang).pipe(
          map(() => savedGlobalApplicationData)
        );
      }),
      switchMap((savedGlobalApplicationData) => {
        if (!savedGlobalApplicationData) {
          const applicationUrl = this.urlService.getApplicationUrl();

          let newGlobalApplicationData = new GlobalApplicationData(
            applicationUrl.baseUrl,
            '../index.html',
            '../isMaintenance.xml',
            'eTicketing-user/?#/app/eTicketUserApp/eTicketing',
            '',
            false,
            false,
            [],
            [],
            false,
            '',
            ''
          );

          return [CoreActions.SetCoreApplicationData({ payload: newGlobalApplicationData }),
          CoreActions.GetAvailableLangsFinished(),
          CoreActions.SetDefaultLang({ payload: this.defaultLang }),
          ]
        } else {
          return [CoreActions.GetAvailableLangsFinished()];
        }
      })
      , catchError((err, caught) => {
        this.translate.setDefaultLang(this.defaultLang);
        if (err && err.message) {
          const messageObj: fromCore.ApplicationModalMessage = {
            modalId: "072",
            text: this.translate.instant("errors." + err.message),
            title: this.translate.instant("generic.WARNING")
          }
          this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
        }
        this.store.dispatch(CoreActions.GetAvailableLangsFinished());
        return caught;
      })
    )
  )
}

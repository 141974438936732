<div *ngIf="langs && langs.length" class="full-height">
  <div class="main-content full-height">
    <div class="signin-content" fxLayout="column" fxLayoutAlign="center center">

      <div *ngIf="!showLoader" class="signin-wrapper" fxLayout="column" [ngClass]="{'privacy': stepIndex === 1}">

        <form *ngIf="stepIndex === 0" class="signin-form" fxLayout="column" [formGroup]="signinForm"
          (ngSubmit)="completeRegistration()">

          <!-- Logo Welion -->
          <div fxFlexOffset="60px" fxLayout="row" fxLayoutAlign="center">
            <svg-icon src="assets/img/icons/app-logo.svg" class="signin-icon cursor-pointer" [applyClass]="true">
            </svg-icon>
          </div>

          <!-- Benvenuto! -->
          <div fxFlexOffset="20px" fxLayout="row" fxLayoutAlign="center">
            <h1 class="center" translate="users.WELCOME_M"></h1>
          </div>

          <div fxFlexOffset="20px" fxLayout="row" fxLayoutAlign="center">
            <h3 class="center" translate="login.SUBTITLE_SSO"></h3>
          </div>

          <!-- Codice fiscale -->
          <input-container fxFlexOffset="40px" isObbligatory isWithoutInfoIcon [title]="'signin.VAT_CODE' | translate"
            class="noBorderBottom">
            <welion-input [inputValue]="vatCode" [placeholder]="'signin.VAT_CODE_PLACEHOLDER' | translate">
            </welion-input>
            <h6 class="small red" fxFlexOffset="8px"
              *ngIf="vatCode.dirty && (vatCode.hasError('minlength') || vatCode.hasError('maxlength'))"
              translate="errors.CF_LENGTH"></h6>
          </input-container>

          <!-- Nome -->
          <input-container isObbligatory isWithoutInfoIcon [title]="'signin.NAME' | translate" class="noBorder">
            <welion-input [inputValue]="name" [placeholder]="'signin.NAME_PLACEHOLDER' | translate">
            </welion-input>
          </input-container>

          <!-- Cognome -->
          <input-container isObbligatory isWithoutInfoIcon [title]="'signin.SURNAME' | translate" class="noBorder">
            <welion-input [inputValue]="surname" [placeholder]="'signin.SURNAME_PLACEHOLDER' | translate">
            </welion-input>
          </input-container>

          <!-- Email -->
          <input-container isObbligatory isWithoutInfoIcon [title]="'signin.EMAIL' | translate"
            [instructions]="'signin.EMAIL_DESC' | translate" class="noBorder">
            <welion-input [inputValue]="email" [placeholder]="'signin.EMAIL_PLACEHOLDER' | translate">
            </welion-input>
          </input-container>

          <!-- Telefono -->
          <input-container isObbligatory isWithoutInfoIcon [title]="'signin.PHONE' | translate"
            [instructions]="'signin.PHONE_DESC' | translate" class="noBorder">
            <welion-input [inputValue]="phone" [placeholder]="'signin.PHONE_PLACEHOLDER' | translate">
            </welion-input>
            <h6 fxFlexOffset="8px" *ngIf="phone.value && phone.hasError('pattern')" class="small"
              translate="errors.CHARACTERS_NOT_ALLOWED"></h6>
          </input-container>

          <!-- Indirizzo -->
          <input-container isObbligatory isWithoutInfoIcon [title]="'signin.ADDRESS' | translate"
            [instructions]="'signin.ADDRESS_DESC' | translate" class="noBorderTop">
            <welion-input [inputValue]="address" hasMapsAutocomplete (selectedPlace)="selectedPlace($event)"
              [placeholder]="'signin.ADDRESS_PLACEHOLDER' | translate">
            </welion-input>
          </input-container>

          <!-- Completa registrazione -->
          <div fxFlexOffset="24px" fxLayout="row" fxLayoutAlign="center">
            <welion-button width="284px" [disabled]="signinForm.invalid" type="primary" size="large"
              label="signin.CONTINUE" buttonType="submit">
            </welion-button>
          </div>

          <div fxFlexOffset="60px"></div>

        </form>

        <form *ngIf="stepIndex === 1" class="signin-form" fxLayout="column" [formGroup]="privacyForm"
          (ngSubmit)="confirmPrivacy()">

          <!-- Logo Welion -->
          <div fxFlexOffset="60px" fxLayout="row" fxLayoutAlign="center">
            <svg-icon src="assets/img/icons/app-logo.svg" class="signin-icon cursor-pointer" [applyClass]="true">
            </svg-icon>
          </div>

          <ng-container *ngIf="isEnel">
            <p fxFlexOffset="24px">
              <span><strong>DICHIARO,</strong> di aver preso visione dell’</span>
              <span class="link" (click)="goToPrivacy()" clickKeyBoard (onEnterKeyboard)="goToPrivacy()">Informativa sul trattamento dei dati personali</span>
              <span> e di
                essere consapevole che i dati personali potranno essere noti all’Impresa sopra indicata e comunicati a
                terzi qualora necessario per consentire l’erogazione dei Servizi richiesti o in nostro favore
                previsti;</span>
              <br>
              <br>
              <span>
                <strong>PRENDO ATTO,</strong> che per le finalità del trattamento come illustrate nell’informativa (i),
                (ii),
                (iii), (iv) e (v), il conferimento dei dati è obbligatorio e che gli stessi saranno trattati secondo
                quanto
                indicato per assolvere gli adempimenti correlati agli accordi in essere;
              </span>
              <br>
              <br>
              <span><strong>CON RIFERIMENTO AL TRATTAMENTO DEI DATI PERSONALI PER L’EROGAZIONE DEL
                  SERVIZIO</strong></span>
              <br>
              <br>
            </p>

            <!-- ESPRIMO il mio consenso al trattamento ... -->
            <welion-check (onCheck)="onCheckPrivacy($event)">
              <ng-template #customLabel>
                <p>
                  <span>
                    <strong>ESPRIMO</strong> il mio consenso al trattamento dei dati personali, appartenenti alle
                    categorie particolari dati personali, tra cui quelli relativi alla salute, per le finalità del
                    trattamento illustrate nell’informativa privacy sub (i), (ii), (iii), (iv) e (v) per quanto
                    necessario
                    all’erogazione dei Servizi richiesti o previsti.
                  </span>
                </p>
              </ng-template>
            </welion-check>
          </ng-container>

          <ng-container *ngIf="!isEnel">
            <p fxFlexOffset="24px">
              <span><strong>CON RIFERIMENTO AL TRATTAMENTO DEI DATI PERSONALI PER L’EROGAZIONE DEL
                  SERVIZIO</strong></span>
              <br>
              <br>
              <span><strong>DICHIARO,</strong> di aver preso visione dell’</span>
              <span class="link" (click)="goToPrivacy()" clickKeyBoard (onEnterKeyboard)="goToPrivacy()" tabindex="0">Informativa sul trattamento dei dati personali</span>
              <span> e di essere consapevole che i dati personali potranno essere noti all’Impresa sopra indicata e
                comunicati a terzi qualora necessario per consentire l’erogazione dei Servizi richiesti o in nostro
                favore previsti;</span>
              <br>
              <br>
              <span>
                <strong>PRENDO ATTO,</strong> che per le finalità del trattamento come illustrate nell’informativa (i),
                (ii), (iii), (iv) e (v), il conferimento dei dati è obbligatorio e che gli stessi saranno trattati
                secondo quanto indicato per assolvere gli adempimenti correlati agli accordi in essere ed
              </span>
              <br>
              <br>
            </p>

            <!-- ESPRIMO il mio consenso al trattamento ... -->
            <welion-check (onCheck)="onCheckPrivacy($event)">
              <ng-template #customLabel>
                <p>
                  <span>
                    <strong>ESPRIMO</strong> il mio consenso al trattamento dei miei dati personali, appartenenti alle
                    categorie particolari, tra cui quelli relativi alla salute, per le finalità del trattamento
                    illustrate nell’informativa privacy sub (i), (ii), (iii), (iv) e (v), per quanto necessario
                    all’erogazione dei Servizi richiesti o in mio favore previsti.
                  </span>
                </p>
              </ng-template>
            </welion-check>
          </ng-container>


          <!-- Completa registrazione -->
          <div fxFlexOffset="24px" fxLayout="row" fxLayoutAlign="center">
            <welion-button width="284px" [disabled]="privacyForm.invalid" type="primary" size="large"
              label="signin.COMPLETE_SIGN_IN" buttonType="submit">
            </welion-button>
          </div>

          <div fxFlexOffset="60px"></div>

        </form>

        <!-- Serve aiuto? Consulta le FAQ -->
        <div fxLayout="row" class="faq-support-container-fixed" fxLayoutAlign="center center">
          <div fxLayoutAlign="center center" fxLayout="column" class="faq-support-container" tabindex="0" (click)="goToFAQ()" clickKeyBoard (onEnterKeyboard)="goToFAQ()">
            <svg-icon src="assets/img/icons/faq-support.svg"></svg-icon>
            <p class="no-margin" *ngIf="!isEnel" [innerHTML]="'generic.FAQ_SUPPORT' | translate"></p>
            <p class="no-margin" *ngIf="isEnel" [innerHTML]="'generic.SUPPORT' | translate"></p>
          </div>
        </div>

      </div>

      <ng-container *ngIf="showLoader">
        <div class="loader spinner-big"></div>
      </ng-container>
    </div>
  </div>
</div>

<!-- Nessuna lingua disponibile a sistema -->
<div *ngIf="!langs || !langs.length">
  <p translate="errors.NO_AVAILABLE_LANG"></p>
</div>
<page-container>
	<div class="sidenav-information">
		<sidenav-informations></sidenav-informations>
	</div>

	<div class="main-content">
		<div class="page-content">
			<svg-icon class="logo-container" [applyClass]="true" src="/assets/img/icons/app-logo.svg">
			</svg-icon>
			<p>
				<span class="small-font-weight" translate="login.SSO_LOGOUT_COMPLETED"  tabindex="0"></span><br>
			</p>
		</div>
	</div>
</page-container>
import { Component, EventEmitter, Input, OnInit, Output, ChangeDetectionStrategy, HostListener } from '@angular/core';
import * as CoreActions from "../../../core/ngrx/core.actions";
import { Store } from '@ngrx/store';
import * as fromApp from "../../../ngrx/app.reducers";
import { ApplicationModalMessage } from 'src/app/core/ngrx/core.reducers';
import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { showIf } from 'src/app/utils/animation.utils';

@Component({
  selector: 'welion-accordion',
  templateUrl: 'welion-accordion.component.html',
  styleUrls: ['./welion-accordion.component.scss'],
  animations: [showIf],
  changeDetection: ChangeDetectionStrategy.OnPush
})

export class WelionAccordionComponent implements OnInit {
  @Input() title: string = '';
  @Input() statusData: any;
  @Input() badgeNumber: number = 0;
  @Input() isNew: boolean = false;
  @Input() infoIcon: boolean = true;
  @Input() attrAriaLabel: string = '';
  @Input() isDisabled: boolean = false;
  @Input() isAccordionOpened: boolean = false;
  @Input() tooltipModalMessage?: ApplicationModalMessage;
  @Input() isChallengeAccordion?: boolean;
  @Input() isTable?: boolean;

  _noInfo: boolean = false;
  @Input() set noInfo(value: boolean | string) {
    this._noInfo = coerceBooleanProperty(value);
  };

  @Output() onModelChanged: EventEmitter<any> = new EventEmitter();
  isMobile: boolean = false;

  constructor(private store: Store<fromApp.AppState>) {
    if (window.innerWidth < 600) {
      this.isMobile = true;
    } else {
      this.isMobile = false;
    }
  }

  ngOnInit() { }

  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    if (event.target.innerWidth < 600) {
      this.isMobile = true;
    } else {
      this.isMobile = false;
    }
  }

  openAccordion() {
    this.isAccordionOpened = !this.isAccordionOpened;
  }

  // Apre la modale info
  openModalMessage(event: any) {
    event.stopPropagation();
    if (this.tooltipModalMessage) {
      this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: this.tooltipModalMessage }));
    }
  }

  emitOnModelChanged(data?: string) {
    this.onModelChanged.emit(data);
  }
}

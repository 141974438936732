<main class="add-reservation-container" fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="start start"
  fxLayoutAlign.lt-md="start center">

  <div class="add-reservation-side" fxLayout="column" fxLayoutAlign="space-between">

    <div fxLayout="column" fxLayoutAlign="start start" class="full-width">
      <div class="add-reservation-back" fxLayout="row" fxLayoutAlign="start center" fxFlexOffset="40px" role="button"
        attr.aria-label="{{ 'generic.GO_BACK' | translate }}" (click)="goBack()" tabindex="0" clickKeyBoard
        (onEnterKeyboard)="goBack()">
        <svg-icon fxFlexOffset="40px" [applyClass]="true" src="./assets/img/icons/arrow-white-left.svg"></svg-icon>
        <p class="white" fxFlexOffset="12px">{{ 'generic.GO_BACK' | translate | uppercase}}</p>
      </div>

      <!-- Creazione -->
      <ng-container *ngIf="!selectedUsers || !selectedUsers.length">
        <h1 *ngIf="!editStructure && !editDate && entitled" fxFlexOffset="24px">
          {{ 'addReservation.SIDE_TITLE' | translate:
          { name: entitled.user.forename + ' ' + entitled.user.surname } }}
        </h1>
      </ng-container>
      <!-- Titolo sidenav se prenotazione massiva -->
      <ng-container *ngIf="selectedUsers && selectedUsers.length">
        <h1 *ngIf="!editStructure && !editDate && entitled" fxFlexOffset="24px">
          {{ 'addReservation.SIDE_TITLE' | translate:
          { name: (' ' + selectedUsers.length + ' ' + ('generic.PEOPLE' | translate)) }
          }}
        </h1>
      </ng-container>

      <ng-container *ngIf="selectedUsers && selectedUsers.length">
        <h1 *ngIf="entitled && (editStructure || editDate)" fxFlexOffset="24px">
          {{ 'addReservation.YOU_ARE_EDITING_BOOKING_FOR' | translate }}
        </h1>
      </ng-container>
      <ng-container *ngIf="!selectedUsers || !selectedUsers.length">
        <h1 *ngIf="entitled && (editStructure || editDate)" fxFlexOffset="24px">
          {{ 'addReservation.YOU_ARE_EDITING_BOOKING' | translate }}
        </h1>
      </ng-container>

      <!-- Box riassuntivo della prenotazione che si sta modificando -->
      <div *ngIf="(entitled || alreadySelectedVenue) && (editStructure || editDate)" class="edit-booking-resume-box">
        <div class="content-box">
          <p *ngIf="(!selectedUsers || !selectedUsers.length) && entitled">{{ entitled.user.forename + ' ' +
            entitled.user.surname }}</p>

          <p *ngIf="selectedUsers && selectedUsers.length">{{ (' ' + selectedUsers.length + ' ' +('generic.PEOPLE' |
            translate)) }}</p>

          <p *ngIf="alreadySelectedDate">{{ alreadySelectedDate }}</p>
          <p *ngIf="alreadySelectedVenue">
            {{ alreadySelectedVenue }}</p>
        </div>
      </div>

      <div fxFlexOffset="24px" class="add-reservation-tick"></div>

      <!-- Padding corto nel caso di minore nel primo step -->
      <div class="add-reservation-faq"
        [fxFlexOffset]="currentStep === 1 && underEighteenBirthday && !underNineYearsOld ? '12px' : '48px'">
        <!-- Anamnesi -->
        <ng-container *ngIf="currentStep === 0">
          <!-- Alcune informazioni ci aiuteranno a fornire un servizio migliore. -->
          <span translate="addReservation.steps.anamnesis.SIDE1"></span>
        </ng-container>

        <!-- Ricerca -->
        <ng-container *ngIf="currentStep === 1">
          <!-- Scegli la distanza dal tuo indirizzo entro la quale.. -->
          <span translate="addReservation.steps.search.SIDE1"></span>
          <p></p>
          <p class="margin-bottom14"></p>
          <!-- Potrai tornare in ogni momento a questo.. -->
          <span translate="addReservation.steps.search.SIDE2"></span>
        </ng-container>

        <!-- Struttura -->
        <ng-container *ngIf="currentStep === 2">
          <!-- Ecco le strutture disponibili con un raggio di -->
          <span translate="addReservation.steps.structure.SIDE1"></span>
          <span>
            <strong>
              {{ this.kmPreference.value.id }}Km
            </strong>
          </span>
          <!-- dall'indirizzo -->
          <span translate="addReservation.steps.structure.SIDE2"></span>
          <span>
            <strong>
              {{ this.currentAddress.value }}
            </strong>
          </span>
          <!-- tra il -->
          <span translate="addReservation.steps.structure.SIDE3"></span>
          <span>
            <strong>
              {{ this.fromDate.value | date: 'dd MMMM' | titlecase }}
            </strong>
          </span>
          <!-- e il -->
          <span translate="addReservation.steps.structure.SIDE4"></span>
          <span>
            <strong>
              {{ this.toDate.value | date: 'dd MMMM' | titlecase }}</strong>.
          </span>
          <p></p>
          <p class="margin-bottom14"></p>
          <!-- Torna allo step precedente per cambiare questi.. -->
          <span translate="addReservation.steps.structure.SIDE5"></span>
          <p></p>
          <p class="margin-bottom14"></p>
          <!-- Conferma poi la tua scelta per vedere.. -->
          <span translate="addReservation.steps.structure.SIDE6"></span>
        </ng-container>

        <!-- Data e orario -->
        <ng-container *ngIf="currentStep === 3">
          <!-- Ecco gli orari disponibili per la sede scelta: -->
          <span translate="addReservation.steps.time.SIDE1"></span>
          <span>
            <strong *ngIf="venueSearch">
              {{ venueSearch.name }}</strong>,
          </span>
          <span>
            <strong *ngIf="venueSearch">
              {{ venueSearch.address }}
            </strong>
          </span>
          <!-- tra il -->
          <span translate="addReservation.steps.time.SIDE2"></span>
          <span>
            <strong>
              {{ this.fromDate.value | date: 'dd MMMM' | titlecase }}
            </strong>
          </span>
          <!-- e il -->
          <span translate="addReservation.steps.time.SIDE3"></span>
          <span>
            <strong>
              {{ this.toDate.value | date: 'dd MMMM' | titlecase }}</strong>.
          </span>
          <p></p>
          <p class="margin-bottom14"></p>
          <!-- Torna allo step predecedete per cambiare questi.. -->
          <span *ngIf="!editStructure && !editDate && entitled" translate="addReservation.steps.time.SIDE4"></span>
          <span *ngIf="entitled && (editStructure || editDate)" translate="addReservation.steps.time.SIDE5"></span>
        </ng-container>
        <!-- Ricorda che per i minori di 18 anni è necessario presentarsi.. -->
        <!-- Mostro solo al primo step -->
        <ng-container
          *ngIf="!recognizedFC || currentStep === 0 || (currentStep === 1 && underEighteenBirthday && !underNineYearsOld)">
          <p></p>
          <p class="margin-bottom14"></p>
          <span translate="addReservation.steps.anamnesis.SIDE2"></span>
        </ng-container>
        <p></p>
        <p class="margin-bottom14"></p>
        <!-- Solo se sono in modifica della struttura + data, oppure sono in creazione -->
        <span *ngIf="(!editStructure && !editDate && entitled) || (entitled && editStructure)"
          translate="addReservation.steps.SIDE_BACK"></span>
        <p></p>
        <p class="margin-bottom14"></p>
        <!-- Serve aiuto? Consulta le FAQ -->
        <ng-container *ngIf="!isEnel">
          <span translate="generic.FAQ"></span>
          <span translate="generic.FAQ2" class="primary" tabindex="0" (click)="goToFAQ()" clickKeyBoard
            (onEnterKeyboard)="goToFAQ()" role="button" attr.aria-label="{{'generic.FAQ2' | translate}}"></span>
        </ng-container>

        <!-- Serve aiuto? Contatta l’help desk Enel al numero 800.069.629 -->
        <ng-container *ngIf="isEnel">
          <span translate="generic.FAQ_ENEL"></span>
          <span class="primary bold" translate="generic.FAQ_ENEL_CONTACT_NUMBER"></span>
        </ng-container>
      </div>
    </div>

    <svg-icon fxShow fxHide.lt-md class="welion-logo" [applyClass]="true" src="./assets/img/icons/app-logo.svg"
      role="img" aria-label="Logo Welion">
    </svg-icon>

    <div fxFlexOffset="40px"></div>

  </div>

  <div fxFlex fxLayout="row" fxLayout.xs="column" fxLayoutAlign="center" fxLayoutAlign.xs="start"
    class="add-reservation-body-container">
    <div class="add-reservation-body" fxLayout="column" fxLayoutAlign="start center">

      <div fxFlexOffset="32px" fxFlexOffset.xs="0px" class="add-reservation-tabs" fxLayout="row">
        <!-- Anamnesi, Ricerca, Struttura, Data e Orario tabs -->
        <div role="tablist" attr.aria-label="{{'generic.NAME_TAB_ACCESSIBILITY' | translate}}">
          <tab-light *ngFor="let step of steps; let index = index" searchStep fxFlexOffset="16px" fxFlexOffset.xs="8px"
            [tabTitle]="step.label | translate | uppercase" [attrAriaLabel]="step.label | translate | uppercase"
            [isDisabled]="isTabDisabled(index)" [isCompleted]="currentStep > (index + (hasAnamnesisStep ? 0 : 1))"
            (onClick)="changeReservationTab(index + (hasAnamnesisStep ? 0 : 1))" clickKeyBoard
            (onEnterKeyboard)="changeReservationTabFromKeyboard(index + (hasAnamnesisStep ? 0 : 1))" [tabId]="step.id"
            [tabPanelId]="step.panelId" [isCurrentTab]="currentStep == index + (hasAnamnesisStep ? 0 : 1)">
          </tab-light>
        </div>

      </div>

      <div class="full-width" fxLayout="column">
        <!-- Anamnesi -->
        <div id="panel-anamnesis"
          *ngIf="currentStep === 0 && !isLoadingStep && !isLoadingConfirmReservation && hasAnamnesisStep"
          class="full-width" fxLayout="column">
          <!-- Max 9 anni? -->
          <input-container [for]="'anamnesis-under-9-years'" [id]="'anamnesis-under-9-years'" *ngIf="!recognizedFC"
            fxFlexOffset="24px" isWithoutInfoIcon>
            <ng-template #customLabel>
              <p>
                <span translate="addReservation.steps.anamnesis.ANAM1-1"></span>
                <span class="primary margin" translate="addReservation.steps.anamnesis.ANAM1-2"></span>
                <span class="margin">?</span>
              </p>
            </ng-template>
            <welion-switch [checked]="underNineYearsOld" (onSwitch)="switchAnamnesis($event, 0)"
              [id]="'welion-input-anamnesis-under-9-years'"></welion-switch>
          </input-container>

          <!-- Già vaccinato? -->
          <input-container *ngIf="!recognizedFC || underNineYearsOld" fxFlexOffset="24px"
            [tooltipModalMessage]="tooltipModalMessageAnam2"
            [title]="'addReservation.steps.anamnesis.ANAM2' | translate" [for]="'anamnesis-already-vaccinated'"
            [id]="'anamnesis-already-vaccinated'">
            <welion-switch [checked]="alreadyFluVax" (onSwitch)="switchAnamnesis($event, 1)"
              [id]="'welion-input-anamnesis-already-vaccinated'"></welion-switch>
          </input-container>

          <!-- Dove e quando -->
          <div fxFlexOffset="24px" fxLayout="row" fxLayoutAlign="end center">
            <welion-button hasRightIcon label="primary" type="primary" size="large" width="260px"
              label="addReservation.steps.anamnesis.ACTION" (onClick)="goToNextStep()">
            </welion-button>
          </div>
        </div>

        <!-- Ricerca -->
        <div id="panel-search" *ngIf="currentStep === 1 && !isLoadingStep && !isLoadingConfirmReservation"
          [id]="(hasAnamnesisStep ? steps[1].panelId : steps[0].panelId)"
          [attr.aria-labelledby]="(hasAnamnesisStep ? steps[1].id : steps[0].id)" role="tabpanel" class="full-width"
          fxLayout="column">

          <ng-container *ngIf="hasPastReservation">

            <div fxLayout="row" fxFlexOffset="32px">
              <h2 class="bold" fxFlexOffset="16px" translate="addReservation.steps.search.oldSearch.TITLE"></h2>
            </div>

            <div fxLayout="row" fxFlexOffset="32px" fxLayoutAlign="space-between center">
              <!-- Scelte in passato -->
              <div class="select-search-button" fxLayout="column" fxLayoutAlign="space-between center"
                (click)="oldSearchClick(true)" [ngClass]="{'selected': !newReservation}" tabindex="0" clickKeyBoard
                (onEnterKeyboard)="oldSearchClick(true)" role="button">
                <svg-icon [applyClass]="true" src="./assets/img/icons/icon-flake.svg"></svg-icon>
                <p class="small white" translate="addReservation.steps.search.oldSearch.OLD_SEARCH"></p>
              </div>
              <!-- Nuova ricerca -->
              <div class="select-search-button" fxFlexOffset="16px" fxLayout="column"
                fxLayoutAlign="space-between center" (click)="oldSearchClick(false)"
                [ngClass]="{'selected': newReservation}" tabindex="0" clickKeyBoard
                (onEnterKeyboard)="oldSearchClick(false)" role="button">
                <svg-icon [applyClass]="true" src="./assets/img/icons/icon-shuttle.svg"></svg-icon>
                <p class="small white" translate="addReservation.steps.search.oldSearch.NEW_SEARCH"></p>
              </div>
            </div>
          </ng-container>

          <div [@showIf] *ngIf="hasPastReservation && !newReservation" fxLayout="column">
            <!-- Riutilizza i dati di questa prenotazione -->
            <input-container isWithoutInfoIcon [title]="'addReservation.steps.search.oldSearch.REUSE' | translate"
              fxFlexOffset="24px">
              <welion-radio isAttendance [items]="attendancesSearch" [bindValue]="attendanceSearch"
                (onChange)="onChangeAttendance($event)" [venueForJunior]="underEighteenBirthday"
                (onVenueDetail)="showVenueModal($event)">
              </welion-radio>
            </input-container>

            <div fxFlexOffset="24px" fxLayout="row" fxLayoutAlign="end center">
              <!--  -->
              <welion-button hasRightIcon label="primary" type="primary" size="large" width="260px"
                label="addReservation.steps.structure.ACTION" [disabled]="searchForm.invalid"
                (onClick)="goToNextStep()">
              </welion-button>
            </div>
          </div>

          <div [@showIf] *ngIf="!hasPastReservation || newReservation" fxLayout="column">
            <!-- Sede prefissata -->
            <input-container *ngIf="entitled && entitled.preMatchedVenue" isWithoutInfoIcon onlyLabel
              fxFlexOffset=" 24px">
              <ng-template #customLabel>
                <!-- La tua vaccinazione è prevista presso .. -->
                <p>
                  <span translate="addReservation.steps.search.PRECHOICE"></span>
                  <span class="underline margin" (click)="showVenueModal()">
                    {{ entitled.preMatchedVenue.name + ', ' + entitled.preMatchedVenue.address }}
                  </span>
                </p>
              </ng-template>
            </input-container>

            <!-- Ti possiamo proporre tutte le strutture vicino a te, in un raggio di... -->
            <input-container *ngIf="entitled && !entitled.preMatchedVenue" isWithoutInfoIcon
              [title]="'addReservation.steps.search.PROPOSE' | translate" fxFlexOffset=" 24px"
              [for]="'structure-propose'" [id]="'structure-propose'">
              <div fxLayout="row" fxLayoutAlign="start center">
                <welion-select [ariaLabelledbyLabel]="'structure-propose'" [required]="true"
                  [attrAriaLabel]="'accessibilityAttributes.SELECT_KM' | translate"
                  [placeholder]="'accessibilityAttributes.SELECT_KM' | translate"
                  [labelForId]="'welion-input-structure-propose'" fxFlex="92px" [items]="kmPreferences"
                  [selectedItem]="kmPreference.value" (change)="onChangeKMPreference( $event)"></welion-select>
                <p fxFlexOffset="20px" translate="addReservation.steps.search.KM"></p>
              </div>
            </input-container>

            <!-- ...dall’indirizzo che preferisci -->
            <input-container *ngIf="entitled && !entitled.preMatchedVenue"
              [tooltipModalMessage]="tooltipModalMessagePreferedAddress" [for]="'preferred-address'"
              [id]="'preferred-address'" [title]="'addReservation.steps.search.PREFERED_ADDRESS' | translate"
              fxFlexOffset=" 24px">
              <welion-input [inputValue]="currentAddress" hasMapsAutocomplete [mapsOptions]="undefined"
                [required]="true" [id]="'preferred-address'" [ariaLabelledbyLabel]="'preferred-address'"
                (selectedPlace)="selectedPlace($event)" placeholder="Viale della Repubblica, 14, - 31100 Treviso (TV)">
              </welion-input>
            </input-container>

            <input-container [title]="'addReservation.steps.search.WHEN' | translate" fxFlexOffset="24px"
              [tooltipModalMessage]="tooltipModalMessageWhen">
              <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="start center" fxLayoutAlign.xs="start stretch">
                <!-- Da -->
                <div fxLayout="row" fxLayoutAlign="start center">
                  <label for="welion-input-label-startDate" id="startDate">
                    <p translate="addReservation.steps.search.FROM"></p>
                  </label>
                  <welion-datepicker [required]="true" [id]="'welion-input-label-startDate'" fxFlexOffset="16px"
                    [ariaLabelledbyLabel]="'startDate'" placeholder="generic.DATEPICKER" [value]="fromDate.value"
                    (dateChanged)="onDateFromChange($event)" [minDate]="roundStartDate" [maxDate]="roundEndDate">
                  </welion-datepicker>
                </div>
                <!-- A -->
                <div fxLayout="row" fxLayoutAlign="start center" fxFlexOffset.xs="16px">
                  <label for="welion-input-label-endDate" id="endDate">
                    <p fxFlexOffset="20px" fxFlexOffset.xs="9px" translate="addReservation.steps.search.TO"></p>
                  </label>
                  <welion-datepicker [required]="true" [id]="'welion-input-label-endDate'" fxFlexOffset="16px"
                    [ariaLabelledbyLabel]="'endDate'" attrAriaLabel="Scegli data fine" placeholder="generic.DATEPICKER"
                    [value]="toDate.value" (dateChanged)="onDateToChange($event)" [minDate]="roundStartDate"
                    [maxDate]="roundEndDate">
                  </welion-datepicker>
                </div>
              </div>
            </input-container>

            <div fxFlexOffset="24px" fxLayout="row" fxLayoutAlign="end center">
              <!--  -->
              <ng-container *ngIf="entitled && entitled.preMatchedVenue">
                <welion-button hasRightIcon label="primary" type="primary" size="large" width="260px"
                  label="addReservation.steps.structure.ACTION" [disabled]="searchForm.invalid"
                  (onClick)="goToNextStep()">
                </welion-button>
              </ng-container>
              <!-- Scegli struttura / Scegli data e orario -->
              <ng-container *ngIf="!entitled || !entitled.preMatchedVenue">
                <welion-button hasRightIcon label="primary" type="primary" size="large" width="260px"
                  label="addReservation.steps.{{(entitled && entitled.preMatchedVenue) ? 'structure' : 'search'}}.ACTION"
                  [disabled]="searchForm.invalid" (onClick)="goToNextStep()">
                </welion-button>
              </ng-container>
            </div>
          </div>

        </div>

        <!-- Struttura -->
        <div id="panel-structure" *ngIf="currentStep === 2 && !isLoadingStep && !isLoadingConfirmReservation"
          [id]="(hasAnamnesisStep ? steps[2].panelId : steps[1].panelId)"
          [attr.aria-labelledby]="(hasAnamnesisStep ? steps[2].id : steps[1].id)" role="tabpanel" class="full-width"
          fxLayout="column">

          <ng-container *ngIf="venuesSearch && venuesSearch.length > 0 else noVenues">

            <h2 fxFlexOffset="32px">
              {{ ('addReservation.steps.structure.VENUE' + (venuesSearch.length === 1 ? '' : 'S')) | translate:
              { value: venuesSearch.length} }}
            </h2>

            <div *ngIf="hasDisabledVenues" class="disabled-venues" fxFlexOffset="12px" fxLayout="row"
              fxLayout="start center">
              <p translate="addReservation.steps.structure.VENUE_DISABLED"></p>
            </div>

            <p [fxFlexOffset]="hasDisabledVenues ? '12px' : '8px'"
              translate="addReservation.steps.structure.SELECT_VENUE"></p>

            <p *ngIf="underEighteenBirthday && false" translate="addReservation.steps.structure.SELECT_VENUE2"></p>
            <welion-radio isVenue fxFlexOffset="12px" [items]="venuesSearch" [bindValue]="venueSearch"
              (onChange)="onChangeVenue($event)" (onVenueDetail)="showVenueModal($event)"
              [venueForJunior]="underEighteenBirthday">
            </welion-radio>

            <div fxFlexOffset="24px" fxLayout="row" fxLayoutAlign="end center">
              <!-- Scegli orario -->
              <welion-button hasRightIcon label="primary" type="primary" size="large" width="260px"
                label="addReservation.steps.structure.ACTION" [disabled]="!venueSearch" (onClick)="goToNextStep()">
              </welion-button>
            </div>

          </ng-container>

        </div>

        <!-- Data e Orario -->
        <div id="panel-time" *ngIf="currentStep === 3 && !isLoadingStep && !isLoadingConfirmReservation"
          [id]="(hasAnamnesisStep ? steps[3].panelId : steps[2].panelId)"
          [attr.aria-labelledby]="(hasAnamnesisStep ? steps[3].id : steps[2].id)" role="tabpanel" class="full-width"
          fxLayout="column">

          <h2 fxFlexOffset="32px" translate="addReservation.steps.time.AVAILABLE_DATES"></h2>

          <ng-container
            *ngIf="daySlotsPaginated.length && daySlotsPaginated[pageSelectedIndex] && daySlotsPaginated[pageSelectedIndex].days">
            <welion-radio daySlot fxFlexOffset="24px" [items]="daySlotsPaginated[pageSelectedIndex].days"
              [bindValue]="daySelected" radioName="choice" (onChange)="onChangeDay($event)">
            </welion-radio>

            <!-- Paginatore -->
            <div class="day-paginator" fxFlexOffset="24px" *ngIf="daySlots && daySlots.length > 5" fxLayout="row">
              <!-- Sx -->
              <div class="day-block" fxLayout="column" fxLayoutAlign="center center" (click)="goToLeft()">
                <svg-icon [applyClass]="true" src="./assets/img/icons/arrow-left-grey.svg"></svg-icon>
              </div>

              <!-- Numero pagina -->
              <div fxFlexOffset="4px" class="day-block" *ngFor="let days of daySlotsPaginated; let iDay = index"
                fxLayout="column" fxLayoutAlign="center center" [ngClass]="{'selected': iDay === pageSelectedIndex}"
                (click)="goToPage(iDay)">
                <p class="black">{{ iDay + 1 }}</p>
              </div>

              <!-- Dx -->
              <div fxFlexOffset="4px" class="day-block" fxLayout="column" fxLayoutAlign="center center"
                (click)="goToRight()">
                <svg-icon [applyClass]="true" src="./assets/img/icons/arrow-right-grey.svg"></svg-icon>
              </div>
            </div>
          </ng-container>

          <ng-container
            *ngIf="daySelected && (daySlotsPaginated.length === 0 || !daySlotsPaginated[pageSelectedIndex] || !daySlotsPaginated[pageSelectedIndex].days)">
            <div class="loader spinner-big"></div>
          </ng-container>

          <!-- Se sto prenotando per il singolo -->
          <ng-container *ngIf="!selectedUsers || !selectedUsers.length">
            <h2 *ngIf="daySelected" fxFlexOffset="24px" translate="addReservation.steps.time.AVAILABLE_TIMES"></h2>

            <div *ngIf="daySelected" fxFlexOffset="12px" fxLayout="row wrap"
              [fxLayoutAlign]="isLoadingSlots ? 'center' : ''">
              <ng-container *ngIf="isLoadingSlots">
                <div class="loader spinner-medium"></div>
              </ng-container>
              <ng-container *ngIf="!isLoadingSlots">
                <div class="time-block" *ngFor="let timeSlot of timeSlots" fxFlexOffset="8px" fxLayout="row"
                  fxLayoutAlign="center center" (click)="onSelectedTime(timeSlot)"
                  [ngClass]="{'selected': timeSlotSelected ? (timeSlot.slotId === timeSlotSelected.slotId) : false}"
                  tabindex="0" role="button" clickKeyBoard (onEnterKeyboard)="onSelectedTime(timeSlot)">
                  <p class="primary">
                    {{ 'addReservation.steps.time.TIME' | translate: {
                    'start': (timeSlot.startDate | date: 'HH:mm'),
                    'end': (timeSlot.endDate | date: 'HH:mm')
                    } }}
                  </p>
                </div>
              </ng-container>
            </div>
          </ng-container>


          <ng-container *ngIf="selectedUsers && selectedUsers.length">
            <h2 *ngIf="daySelected" fxFlexOffset="24px" translate="addReservation.steps.time.AVAILABLE_TIMES_FAM"></h2>

            <ng-container *ngFor="let userData of selectedUsers">
              <p class="user-name"><b>{{ userData.user ? (userData.user.forename + ' ' +
                  userData.user.surname):(userData.forename + ' ' + userData.surname)}}</b></p>
              <div *ngIf="daySelected" fxFlexOffset="12px" fxLayout="row wrap"
                [fxLayoutAlign]="isLoadingSlots ? 'center' : ''">
                <ng-container *ngIf="isLoadingSlots">
                  <div class="loader spinner-medium"></div>
                </ng-container>
                <ng-container *ngIf="!isLoadingSlots">
                  <div class="time-block" *ngFor="let timeSlot of timeSlots" fxFlexOffset="8px" fxLayout="row"
                    fxLayoutAlign="center center" (click)="onSelectedTimeForFamily(timeSlot, userData)"
                    [ngClass]="getTimeslotClass(timeSlot, userData)" tabindex="0" role="button" clickKeyBoard
                    (onEnterKeyboard)="onSelectedTimeForFamily(timeSlot, userData)">
                    <p class=" primary">
                      {{ 'addReservation.steps.time.TIME' | translate: {
                      'start': (timeSlot.startDate | date: 'HH:mm'),
                      'end': (timeSlot.endDate | date: 'HH:mm')
                      } }}
                    </p>
                  </div>
                </ng-container>
              </div>
            </ng-container>
          </ng-container>


          <div fxFlexOffset="24px" fxLayout="row" fxLayoutAlign="end center">
            <!--h2 (click)="modalService.open('bookingConfirmed')">PROVA CONFERMA FINALE</h2-->
            <!-- Prenota! -->
            <welion-button hasRightIcon label="primary" type="primary" size="large" width="232px"
              label="addReservation.steps.time.ACTION" [disabled]="getIsReserveActionDisabled()"
              (onClick)="goToNextStep()">
            </welion-button>
          </div>
        </div>

        <div *ngIf="isLoadingStep || isLoadingConfirmReservation" class="loader spinner-big"></div>
      </div>

    </div>

  </div>

</main>

<!-- Modale di conferma della per famiglie -->
<modal id="confirmBookingFamily" class="confirm-booking-modal" (onClose)="closeConfirmBookingModal()"
  (onCancel)="closeConfirmBookingModal()" [cancelLabel]="'generic.EDIT' | translate"
  [confirmLabel]="'generic.CONFIRM' | translate" (onConfirm)="closeConfirmBookingModal(true)"
  [disableClose]="isLoadingConfirmReservation" [title]="'clientLanding.modals.RES_TITLE' | translate"
  [titleClass]="'default-modal-content-title'">
  <div class="default-modal-content" *ngIf="attendanceFamily && attendanceFamily.slots">
    <!-- Info generali -->
    <div class="reservation-info">
      <!-- Data -->
      <div class="reservation-row">
        <p class="label">{{ attendanceFamily.startDate | date: 'EEEE' | uppercase}}</p>
        <p class="field">{{ attendanceFamily.startDate | date: 'dd MMM' | uppercase}}</p>
      </div>
      <!-- struttura -->
      <div class="reservation-row">
        <p class="label">Struttura</p>
        <p class="field">{{venueSearch?.name}}</p>
      </div>
      <!-- Indirizzo -->
      <div class="reservation-row">
        <p class="label">Indirizzo</p>
        <p class="field">{{venueSearch?.address}}</p>
      </div>
    </div>
    <div class="modal-divisor"></div>

    <ng-container *ngFor="let user of listForConfirmBooking">
      <!-- Info utente prenotato -->
      <div class="reservation-info">
        <div class="reservation-row">
          <p class="label">Nominativo</p>
          <p class="field">{{ (user.forename + ' ' + user.surname) | uppercase }}</p>
        </div>
        <div class="reservation-row">
          <p class="label">Orario</p>
          <p class="field">
            {{ attendanceFamily.slots[user.userId].startDate | date: 'HH:mm' }} -
            {{ attendanceFamily.slots[user.userId].endDate | date: 'HH:mm' }}</p>
        </div>
      </div>
      <div class="modal-divisor"></div>
    </ng-container>
  </div>
</modal>



<!-- Modale di conferma della prenotazione -->
<modal id="confirmBooking" class="confirm-booking-modal" (onClose)="closeConfirmBookingModal()"
  (onCancel)="closeConfirmBookingModal()" [disableClose]="isLoadingConfirmReservation"
  [title]="'addReservation.steps.modal.TITLE' | translate">

  <div fxLayout="column" fxLayoutAlign="start center" class="full-width">

    <card-ticket readOnly [entitled]="entitled" [attendance]="attendance">
    </card-ticket>

    <div fxFlexOffset="24px" class="full-width" fxLayout="row" fxLayoutAlign="space-between center">
      <welion-button class="secondary" width="132px" label="addReservation.steps.modal.EDIT" type="secondary"
        size="large" (onClick)="closeConfirmBookingModal()" [disabled]="isLoadingConfirmReservation">
      </welion-button>
      <welion-button class="primary" width="300px" fxFlexOffset="8px" fxFlexOffset.xs="16px"
        label="addReservation.steps.modal.ACTION" type="primary" size="large" (onClick)="closeConfirmBookingModal(true)"
        [disabled]="isLoadingConfirmReservation">
      </welion-button>
    </div>
  </div>

</modal>

<!-- Modale di prenotazione effettuata -->
<modal id="bookingConfirmed" class="booking-confirm-modal" (onCancel)="closeBookingConfirmedModal()"
  (onClose)="closeBookingConfirmedModal()" [title]="'addReservation.steps.modal.TITLE_COMPLETE' | translate">

  <div fxLayout="column" fxLayoutAlign="start center" class="full-width" (clickOutside)="closeIfBookingConfirmed()">

    <svg-icon fxFlexOffset="24px" src="./assets/img/icons/booking-completed.svg"></svg-icon>
    <!-- Utente singolo -->
    <ng-container *ngIf="timeSlotSelected && entitled">
      <div class="full-width" fxFlexOffset="24px">
        <!-- Hai prenotato la vaccinazione per .. -->
        <span translate="addReservation.steps.modal.TITLE_DESC1"></span>
        <span *ngIf="entitled && entitled.user">
          <strong>
            {{ entitled.user.forename + ' ' + entitled.user.surname }}
          </strong>
        </span>

        <span *ngIf="reservationCode" translate="addReservation.steps.modal.TITLE_DESC_WITH_CODE"></span>
        <span *ngIf="reservationCode">
          <strong>
            {{ reservationCode }}
          </strong>
        </span>
        <!-- il giorno -->
        <span translate="addReservation.steps.modal.TITLE_DESC2"></span>
        <span *ngIf="timeSlotSelected">
          <strong>
            {{ timeSlotSelected.startDate | date: 'EEEE dd MMMM' | titlecase }}
          </strong>
        </span>
        <!-- alle -->
        <span translate="addReservation.steps.modal.TITLE_DESC3"></span>
        <span *ngIf="timeSlotSelected">
          <strong>
            {{ 'addReservation.steps.modal.TITLE_DESC4' | translate: { value: timeSlotSelected.startDate | date:
            'HH:mm'}
            }}
          </strong>
        </span>
        <!-- presso la struttura -->
        <span translate="addReservation.steps.modal.TITLE_DESC5"></span>
        <span *ngIf="entitled && entitled.venue">
          <strong>
            {{ entitled.venue.name }}
          </strong>
        </span>
        <!-- situata in -->
        <span translate="addReservation.steps.modal.TITLE_DESC6"></span>
        <span *ngIf="entitled && entitled.venue">
          <strong>
            {{ entitled.venue.address }}</strong>.
        </span>
        <p></p>
        <p class="margin-bottom14"></p>
        <span translate="addReservation.steps.modal.TITLE_DESC7"></span>
        <span *ngIf="entitled && entitled.user">
          <strong>
            {{ entitled.user.email }}
          </strong>
        </span>
        <span translate="addReservation.steps.modal.TITLE_DESC8"></span>.
        <span [innerHTML]="'generic.BOOKING_VALIDITY_NOTICE' | translate"></span>
      </div>
    </ng-container>
    <!-- Più utenti -->
    <ng-container *ngIf="!timeSlotSelected && listForConfirmBooking.length && familySelectedSlots">
      <p fxFlexOffset="24px">
        <!-- Hai prenotato la vaccinazione per .. -->
        {{
        ('addReservation.steps.modal.TITLE_DESC1' | translate) + " " +
        ('addReservation.steps.modal.TITLE_DESC2' | translate)+ " "
        }}
        <strong>
          {{ this.bookingDayFamily ? (this.bookingDayFamily | date: 'EEEE dd MMMM' |
          titlecase) : '' }}
        </strong>
        <!-- presso la struttura -->
        {{('addReservation.steps.modal.TITLE_DESC5' | translate) + " "}}
        <strong>{{ venueSearch?.name }}</strong>
        <!-- situata in -->
        {{('addReservation.steps.modal.TITLE_DESC6' | translate) + " "}}
        <strong>{{ venueSearch?.address }}</strong>
        <!-- per-->
        {{('addReservation.steps.modal.TITLE_DESC9' | translate) + " "}}
      </p>
      <div class="confirm-user-list">
        <ng-container *ngFor="let user of listForConfirmBooking">
          <p>
            <span>{{ user.forename + " " + user.surname }}</span> -
            <strong>
              {{ familySelectedSlots[user.userId] ? (familySelectedSlots[user.userId].startDate | date: 'HH:mm') : '' }}
            </strong>
          </p>
        </ng-container>
      </div>
      <p fxFlexOffset="24px">
        {{ ('addReservation.steps.modal.TITLE_DESC7' | translate) + " " }}
        <strong>{{ loggedUser.email }}</strong>
        {{ ('addReservation.steps.modal.TITLE_DESC8' | translate) }}
        <span [innerHTML]="'generic.BOOKING_VALIDITY_NOTICE' | translate"></span>
      </p>
    </ng-container>
    <div fxFlexOffset="40px" class="full-width" fxLayout="row" fxLayoutAlign="center center">
      <welion-button width="200px" label="generic.CLOSE" type="primary" size="large"
        (onClick)="closeBookingConfirmedModal()">
      </welion-button>
    </div>
  </div>

</modal>

<!-- Placeholder nessuna sede -->
<ng-template #noVenues>
  <div fxLayout="column" fxLayoutAlign="start center" class="no-venues">
    <div fxFlexOffset="24px">
      <span translate="addReservation.steps.search.noVenue.SORRY1"></span>
      <p></p>
      <span translate="addReservation.steps.search.noVenue.SORRY2"></span>
      <p></p>
      <p class="margin-bottom14"></p>
      <span translate="addReservation.steps.search.noVenue.SORRY3"></span>
      <p></p>
      <p class="margin-bottom14"></p>
      <ng-container *ngIf="!isEnel">
        <span translate="addReservation.steps.search.noVenue.SORRY4"></span>
        <span class="primary" (click)="goToFAQ()" clickKeyBoard (onEnterKeyboard)="goToFAQ()" tabindex="0">
          <strong>
            {{ 'generic.FAQ2' | translate }}
          </strong>
        </span>
      </ng-container>
      <ng-container *ngIf="isEnel">
        <span translate="addReservation.steps.search.noVenue.SORRY4_ENEL"></span>
        <span class="primary">
          <strong>
            {{ 'generic.FAQ_ENEL_CONTACT_NUMBER' | translate }}
          </strong>
        </span>
      </ng-container>

    </div>
    <!-- 
      Non devo vedere il torna indietro nel caso di una modifica data/orario in cui non trovo sedi perchè potrei non essere autorizzato a farlo.
      Per modificare la struttura quindi serve accedere dall'opzione dedicata nella card in home
    -->
    <welion-button *ngIf="!(!this.editStructure && this.editDate)" fxFlexOffset="24px" width="232px"
      label="generic.GO_BACK" type="primary" size="large" (onClick)="changeReservationTab(1)">
    </welion-button>

  </div>
</ng-template>

<!-- Sidenav con la scheda di dettaglio della sede -->
<sidebar hideCloseButton [sidenavTemplateRef]="navContent" direction="right" [navWidth]="664" [duration]="0.3">
</sidebar>

<!-- Contenuto sidenav -->
<ng-template #navContent>

  <div class="venue-card-sidenav" fxLayout="column" clickKeyBoard (onEscKeyboard)="closeVenueCard(true)">

    <div class="full-width" fxLayout="row" fxLayoutAlign="end">
      <!-- Icona chiudi -->
      <div id="sidebar-close-button" fxLayoutAlign="row" fxLayoutAlign="center center" (click)="closeVenueCard()"
        class="close-container" tabindex="0" clickKeyBoard (onEnterKeyboard)="closeVenueCard(true)" role="button"
        attr.aria-label="{{ 'generic.CLOSE' | translate}}">
        <svg-icon class="small-size" src="/assets/img/icons/x.svg" [applyClass]="true"></svg-icon>
        <p fxFlexOffset="8px" class="white">{{ 'generic.CLOSE' | translate | uppercase}}</p>
      </div>
    </div>
    <ng-container *ngIf="selectedVenue">
      <!-- Scheda struttura -->
      <p fxFlexOffset="20px" class="bold" translate="addReservation.steps.side.TITLE"></p>

      <h2 fxFlexOffset="16px">{{ selectedVenue.name }}</h2>

      <div fxFlexOffset="16px" class="venue-card-sidenav-divider"></div>

      <div fxFlexOffset="32px" class="venue-card-tabs" fxLayout="row">
        <div role="tablist" attr.aria-label="{{'generic.NAME_TAB_ACCESSIBILITY' | translate}}">
          <!-- Mappa -->
          <tab-light [tabTitle]="'addReservation.steps.side.tab.MAP' | translate"
            [isCurrentTab]="selectedVenueTab === 0" [attrAriaLabel]="'addReservation.steps.side.tab.MAP' | translate"
            (onClick)="onVenueTabClick(0)" clickKeyBoard (onEnterKeyboard)="onVenueTabClick(0)" [tabId]="'maps'"
            [tabPanelId]="'panel-maps'">
          </tab-light>

          <!-- Dettagli -->
          <tab-light fxFlexOffset="32px" [tabTitle]="'addReservation.steps.side.tab.DETAILS' | translate"
            [isCurrentTab]="selectedVenueTab === 1"
            [attrAriaLabel]="'addReservation.steps.side.tab.DETAILS' | translate" (onClick)="onVenueTabClick(1)"
            clickKeyBoard (onEnterKeyboard)="onVenueTabClick(1)" [tabId]="'detail'" [tabPanelId]="'panel-detail'">
          </tab-light>
        </div>
      </div>

      <div *ngIf="selectedVenueTab === 0" class="venue-card-sidenav" fxLayout="column" [id]="'panel-maps'"
        [attr.aria-labelledby]="'maps'" role="tabpanel">

        <!-- Indirizzo struttura -->
        <p fxFlexOffset="28px">{{ selectedVenue.address }}</p>

        <!-- Mappa google -->
        <agm-map fxFlexOffset="8px" *ngIf="venueMapData && venueMapData.lat && venueMapData.lng" class="map"
          [latitude]="venueMapData.lat" [longitude]="venueMapData.lng" [disableDefaultUI]="false" [zoomControl]="false">
          <agm-marker [latitude]="venueMapData.lat" [longitude]="venueMapData.lng" label="A" [markerDraggable]="false">
          </agm-marker>
        </agm-map>

      </div>

      <!-- Dettagli -->
      <div *ngIf="selectedVenueTab === 1" class="venue-card-sidenav" fxLayout="column" [id]="'panel-detail'"
        [attr.aria-labelledby]="'detail'" role="tabpanel">
        <!-- La sede ... è facilmente .. -->
        <p fxFlexOffset="28px">{{ 'addReservation.steps.side.tab.DETAILS_INFO' | translate:
          { name: selectedVenue.name } }}</p>
        <!-- Descrizione sede -->
        <h5 fxFlexOffset="8px" *ngIf="selectedVenue.description">{{ selectedVenue.description }}</h5>
        <!-- Indirizzo -->
        <h2 class="small bold" fxFlexOffset="24px" translate="addReservation.steps.side.tab.DETAILS_ADDRESS"></h2>
        <!-- Nome legale -->
        <p fxFlexOffset="4px" *ngIf="selectedVenue.name">{{ selectedVenue.name }}</p>
        <!-- Indirizzo sede -->
        <p fxFlexOffset="4px">{{ selectedVenue.address }}</p>

        <div fxFlexOffset="28px" class="junior-form" fxLayout="column"
          *ngIf="selectedVenue.validMinorTypes && selectedVenue.validMinorTypes.length && selectedVenue.validMinorTypes[0].age < 18">
          <!-- Gestione del vaccino (nel caso di minori) -->
          <p class="bold" translate="addReservation.steps.side.MANAGEMENT"></p>

          <div *ngFor="let validMinorType of selectedVenue.validMinorTypes">
            <!-- Dai {{ minAge }} ai {{ maxAge }} anni: {{ vaccinationType }} -->
            <p *ngIf="validMinorType.age === 12" fxFlexOffset="4px">
              {{ 'addReservation.steps.side.AGE_GROUPS' | translate: {
              minAge: 12,
              maxAge: 18,
              vaccinationType: (('addReservation.steps.side.' + validMinorType.vaccinationType) | translate)
              } }}
            </p>
            <p *ngIf="validMinorType.age === 9" fxFlexOffset="4px">
              {{ 'addReservation.steps.side.AGE_GROUPS' | translate: {
              minAge: 9,
              maxAge: 12,
              vaccinationType: (('addReservation.steps.side.' + validMinorType.vaccinationType) | translate)
              } }}
            </p>
            <p *ngIf="validMinorType.age === 6" fxFlexOffset="4px">
              {{ 'addReservation.steps.side.AGE_GROUPS' | translate: {
              minAge: 6,
              maxAge: 9,
              vaccinationType: (('addReservation.steps.side.' + validMinorType.vaccinationType) | translate)
              } }}
            </p>
            <!-- Sotto i 6 anni: {{ vaccinationType }} -->
            <p *ngIf="validMinorType.age === 5" fxFlexOffset="4px">
              {{ 'addReservation.steps.side.AGE_GROUPS_UNDER_SIX' | translate: {
              vaccinationType: (('addReservation.steps.side.' + validMinorType.vaccinationType) | translate)
              } }}
            </p>
          </div>
        </div>

        <div fxFlexOffset="28px" class="junior-form" fxLayout="column" *ngIf="anyNotNull()">
          <p class="bold" translate="addReservation.steps.side.SERVICES"></p>
          <div class="details-list">

            <ng-container *ngFor="let key of SERVICE_KEYS">
              <ng-container *ngIf="selectedVenue.details[key]">
                <p [translate]="'- ' + (('structures.details.' + key) | translate)"></p>
              </ng-container>
            </ng-container>
          </div>
          <p class="bold margin-top10" translate="addReservation.steps.side.VEHICLES"></p>
          <div class="details-list">
            <ng-container *ngFor="let key of VEHICLE_KEYS">
              <ng-container *ngIf="selectedVenue.details[key]">
                <p [translate]="'- ' + (('structures.details.' + key) | translate)"></p>
              </ng-container>
            </ng-container>

          </div>
        </div>
      </div>

    </ng-container>


    <div fxFlexOffset="20px"></div>

  </div>

</ng-template>

<!-- Modale di conferma consegna vaccino a minore -->
<modal id="confirmDeliveryUnderage" class="confirm-delivery-underage-modal" (onCancel)="closeDeliveryUnderageModal()"
  (onClose)="closeDeliveryUnderageModal()" [title]="'addReservation.confirmDeliveryUnderage.TITLE' | translate">

  <form fxLayout="column" class="full-width" (ngSubmit)="confirmDeliveryUnderageModal()">

    <div fxFlexOffset="24px" class="confirm-delivery-underage-modal-container">
      <span translate="addReservation.confirmDeliveryUnderage.BODY1"></span>
      <p></p>
      <p class="margin-bottom14"></p>
      <span translate="addReservation.confirmDeliveryUnderage.BODY2"></span>
      <p></p>
      <p class="margin-bottom14"></p>
      <span translate="addReservation.confirmDeliveryUnderage.BODY3"></span>
    </div>

    <div fxFlexOffset="24px" fxLayout="row" fxLayoutAlign="center">
      <!-- Chiudi -->
      <welion-button fxFlex label="generic.CLOSE" type="third" size="medium" buttonType="button"
        (onClick)="closeDeliveryUnderageModal()">
      </welion-button>
      <!-- Conferma -->
      <welion-button fxFlexOffset="8px" fxFlex label="generic.CONFIRM" type="primary" size="medium" buttonType="submit">
      </welion-button>
    </div>

  </form>

</modal>

<!-- Nuova modale di errore prenotazione -->
<modal id="reservationError" (onCancel)="modalService.close('reservationError');"
  (onClose)="modalService.close('reservationError');" [title]="'generic.WARNING' | translate"
  [titleClass]="'bold-text'">
  <div class="modal-title-wrapper" fxLayout="column" fxLayoutAlign="start center">
    <p class="modal-code-wrapper">{{ 'CODICE MODALE: 034'}}</p>
  </div>

  <div class="modal-text-wrapper">
    {{reservationErrorTest}}
    <a *ngIf="!isEnel" class="link"
      (click)="modalService.close('reservationError'); redirectService.goToUserFAQPage()">F.A.Q.</a>
  </div>
</modal>

<!-- Modale di avviso per i minorenni -->
<modal id="minor-warning" (onClose)="closeMinorModal()" (onCancel)="closeMinorModal()"
  (onConfirm)="closeMinorModal(true)" [confirmLabel]="'generic.CONFIRM' | translate"
  [cancelLabel]="'generic.CANCEL' | translate" [title]="'clientLanding.modals.RES_TITLE' | translate"
  [titleClass]="'bold-text'">

  <div class="modal-title-wrapper" fxLayout="column" fxLayoutAlign="start center">
    <p class="modal-code-wrapper">{{ 'CODICE MODALE: cr001'}}</p>
  </div>

  <div class="modal-text-wrapper">
    <p class="bold light-grey center" [innerHTML]="'clientLanding.modals.REST_TEXT_1' | translate"></p>
    <p></p>
    <p class="margin-bottom25"></p>
    <p class="bold light-grey center" [innerHTML]="'clientLanding.modals.REST_TEXT_2' | translate"></p>
  </div>
</modal>

<!-- Nuova modale di errore prenotazione -->
<modal id="not-enough-slots" (onCancel)="modalService.close('not-enough-slots');"
  (onClose)="modalService.close('not-enough-slots');" [title]="'generic.WARNING' | translate"
  [titleClass]="'bold-text'">
  <div class="modal-title-wrapper" fxLayout="column" fxLayoutAlign="start center">
    <p class="modal-code-wrapper">{{ 'CODICE MODALE: cr044'}}</p>
  </div>
  <div class="modal-text-wrapper">{{ "errors.NOT_ENOUGH_SLOTS" | translate }}</div>
</modal>
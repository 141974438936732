<div class="add-round-container" fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="start start"
  fxLayoutAlign.lt-md="start center">

  <div class="add-round-side" fxLayout="column" fxLayoutAlign="space-between">

    <div fxLayout="column" fxLayoutAlign="start start">
      <div class="add-round-back" fxLayout="row" fxLayoutAlign="start center" fxFlexOffset="40px" (click)="goBack()">
        <svg-icon fxFlexOffset="40px" [applyClass]="true" src="./assets/img/icons/arrow-white-left.svg"></svg-icon>
        <h5 class="white" fxFlexOffset="12px">{{ 'generic.GO_BACK' | translate | uppercase}}</h5>
      </div>

      <p fxFlexOffset="24px" *ngIf="campaign">
        <ng-container *ngIf="!round">
          <span translate="admin.addRound.SIDE_TITLE"></span>
        </ng-container>
        <ng-container *ngIf="round">
          <span translate="admin.addRound.SIDE_TITLE_UPDATE"></span>
          <span class="bold">{{ round.title }}</span>
          <span translate="admin.addRound.SIDE_TITLE_UPDATE2"></span>
        </ng-container>
        <span class="bold">{{ campaign.title }}</span>
      </p>

      <div fxFlexOffset="24px" class="add-round-tick"></div>

      <div fxShow fxHide.lt-md class="add-round-faq" fxFlexOffset="48px" fxLayout="row">
        <h5 translate="generic.FAQ"></h5>
        <h5 fxFlexOffset="4px" translate="generic.FAQ2" class="primary bold faq-link" (click)="goToFAQ()"></h5>
      </div>
    </div>

    <svg-icon fxShow fxHide.lt-md class="welion-logo" [applyClass]="true" src="./assets/img/icons/app-logo.svg">
    </svg-icon>

    <div fxShow fxHide.lt-md fxFlexOffset="40px"></div>

  </div>

  <div fxFlex fxLayout="row" fxLayout.xs="column" fxLayoutAlign="center" fxLayoutAlign.xs="start"
    class="add-round-body-container">

    <form class="add-round-body" [formGroup]="addRoundForm" fxLayout="column" fxLayoutAlign="start center"
      (ngSubmit)="confirmAddRound()">

      <!-- Nuovo round -->
      <h3 *ngIf="!round" translate="admin.addRound.TITLE" ngClass.xs="center"></h3>
      <h3 *ngIf="round" translate="admin.addRound.TITLE_UPDATE" ngClass.xs="center"></h3>

      <!-- Nome round -->
      <input-container fxFlexOffset="24px" class="full-width" [title]="'admin.addRound.ROUND_NAME' | translate">
        <welion-input [inputValue]="name" [placeholder]="'admin.addRound.ROUND_NAME_PLACEHOLDER' | translate"
          [disabled]="isLoading">
        </welion-input>
      </input-container>

      <!-- Inizio prenotazioni -->
      <input-container fxFlexOffset="24px" class="full-width"
        [title]="'admin.addRound.ROUND_MIN_RESERVATION_END_DATE' | translate">
        <welion-datepicker class="full-width" placeholder="generic.CHOOSE_DATE" [value]="minReservationEndDate.value"
          (dateChanged)="onMinReservationEndDateChange($event)">
        </welion-datepicker>
      </input-container>

      <!-- Fine prenotazioni -->
      <input-container fxFlexOffset="24px" class="full-width"
        [title]="'admin.addRound.ROUND_MAX_RESERVATION_END_DATE' | translate">
        <welion-datepicker class="full-width" placeholder="generic.CHOOSE_DATE" [value]="maxReservationEndDate.value"
          (dateChanged)="onMaxReservationEndDateChange($event)">
        </welion-datepicker>
        <h6 class="select-info" translate="admin.addRound.ROUND_MAX_RESERVATION_END_DATE_TOOLTIP">
        </h6>
      </input-container>

      <!-- Termine di annullamento prenotazione -->
      <input-container fxFlexOffset="24px" class="full-width"
        [title]="'admin.addRound.ROUND_MAX_CANCEL_DATE' | translate">
        <welion-select toTranslate [disabled]="isLoading" [items]="maxCancelDateOptions" [searchable]="false"
          [selectedItem]="selectedMaxCancelDate.value" (change)="onSelectedMaxCancelDateChanged($event)">
        </welion-select>
        <h6 class="select-info" translate="admin.addRound.ROUND_MAX_CANCEL_DATE_TOOLTIP">
        </h6>
      </input-container>

      <!-- Termine di modifica su stessa struttura -->
      <input-container fxFlexOffset="24px" class="full-width"
        [title]="'admin.addRound.ROUND_MAX_STRUCTURE_MODIFICATION_DATE' | translate">
        <welion-select toTranslate [disabled]="isLoading" [items]="maxCancelDateOptions" [searchable]="false"
          [selectedItem]="selectedStructureMaxEditlDate.value"
          (change)="onSelectedStructureMaxEditlDateChanged($event)">
        </welion-select>
        <h6 class="select-info" translate="admin.addRound.ROUND_MAX_STRUCTURE_MODIFICATION_DATE_TOOLTIP">
        </h6>
      </input-container>

      <!-- Termine di modifica su altra struttura -->
      <input-container fxFlexOffset="24px" class="full-width"
        [title]="'admin.addRound.ROUND_OTHER_STRUCTURE_MODIFICATION_DATE' | translate">
        <welion-select toTranslate [disabled]="isLoading" [items]="maxCancelDateOptions" [searchable]="false"
          [selectedItem]="selectedOtherStructureMaxEditDate.value"
          (change)="onSelectedOtherStructureMaxEditDateChanged($event)">
        </welion-select>
        <h6 class="select-info" translate="admin.addRound.ROUND_OTHER_STRUCTURE_MODIFICATION_DATE_TOOLTIP">
        </h6>
      </input-container>

      <!-- Inizio soministrazione -->
      <input-container fxFlexOffset="24px" class="full-width" [title]="'admin.addRound.ROUND_START' | translate">
        <welion-datepicker class="full-width" placeholder="generic.CHOOSE_DATE" [value]="startDate.value"
          (dateChanged)="onStartDateChange($event)">
        </welion-datepicker>
      </input-container>

      <!-- Fine soministrazione -->
      <input-container fxFlexOffset="24px" class="full-width" [title]="'admin.addRound.ROUND_END' | translate">
        <welion-datepicker class="full-width" placeholder="generic.CHOOSE_DATE" [value]="endDate.value"
          (dateChanged)="onEndDateChange($event)">
        </welion-datepicker>
      </input-container>

      <div *ngIf="isLoading" class="loader spinner-big"></div>

      <!-- Crea round -->
      <div fxFlexOffset="24px" class="full-width" fxLayout="row" fxLayoutAlign="end">
        <welion-button width="264px" type="primary" size="large"
          [label]="'admin.addRound.CONFIRM' + (this.round ? '_UPDATE' : '')"
          [disabled]="addRoundForm.invalid || isLoading" buttonType="submit">
        </welion-button>
      </div>

      <div fxHide fxShow.xs fxFlex.xs="40px"></div>

    </form>

  </div>

</div>
<!-- Modale per il caricamento massivo -->
<modal [id]="id" class="modal-upload" [ngClass]="{'people': uploadType === 'PEOPLE'}" (onClose)="closeSelf()"
  (onConfirm)="onConfirm.emit()" [confirmLabel]="'generic.IMPORT' | translate"
  [confirmBtnDisabled]="isConfirmBtnDisabled">

  <div fxLayout="column" class="full-width">

    <h2 fxFlexOffset="20px" class="small center" translate="component.modalUpload.TITLE_{{uploadType}}"></h2>

    <div class="modal-upload-divider" fxFlexOffset="20px"></div>

    <div fxFlexOffset="20px" fxLayout="row" fxLayoutAlign="center">
      <welion-button fxFlex="200px" label="component.modalUpload.DOWNLOAD" type="primary bordless" size="medium"
        (onClick)="downloadImportStructuresTemplate()">
      </welion-button>
    </div>

    <!-- Carica tracciato con l’elenco delle persone da includere -->
    <div fxFlexOffset="24px" fxLayout="column">
      <input-container fromEuropUpload isObbligatory isWithoutInfoIcon instructions="component.modalUpload.UPLOAD_DESC"
        [title]="('component.modalUpload.UPLOAD_' + uploadType + '_TITLE')">
        <welion-upload onlyXLSX [isToReset]="isToResetUploadComponent" (onUpload)="emitAssignFile($event)">
        </welion-upload>
      </input-container>
    </div>

    <input-container *ngIf="uploadType === 'PEOPLE'" fxFlexOffset="24px"
      instructions="component.modalUpload.PREASSIGNED_VENUE_DESC" title="component.modalUpload.PREASSIGNED_VENUE">
      <welion-select [items]="venues" [selectedItem]="selectedVenue" (change)="onSelectVenue.emit($event)">
      </welion-select>
    </input-container>

    <div fxFlexOffset="20px"></div>

  </div>

</modal>

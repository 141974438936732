import { createAction, props } from '@ngrx/store';
import { Lang } from 'atfcore-commonclasses';

export const SaveAvailableLangs = createAction(
    '[auth] save available langs',
    props<{ payload: Lang[] }>()
);

export const CancelLoggedUser = createAction(
    '[auth] cancel logged user'
);

export const DecodeToken = createAction(
    '[auth] decode token',
    props<{ payload: string }>()
);

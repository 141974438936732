import { FormControl, FormGroup, Validators } from "@angular/forms";
import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from "@angular/router";
import { TranslateService } from '@ngx-translate/core';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from "rxjs/operators";
import { Store } from "@ngrx/store";

import * as fromApp from "../../../ngrx/app.reducers";
import * as CoreActions from "../../../core/ngrx/core.actions";
import { SupplierPerson } from "atfcore-commonclasses/bin/classes/supplier";
import { SenecaResponse } from "atfcore-commonclasses/bin/classes/common";

import { RedirectService } from 'src/app/shared/services/redirect.service';
import { getWarningModal } from "src/app/utils/utils";
import { ScrollTo } from "src/app/core/services/scroll-to.service";
import { SupplierService } from "../../services/supplier.service";
import { AnagService, ClientGroup } from "src/app/auth/services/anag.service";

@Component({
  selector: 'app-admin-add-client-reference',
  templateUrl: './addClientReference.component.html',
  styleUrls: ['./addClientReference.component.scss']
})
export class AdminAddClientReferenceComponent implements OnInit, OnDestroy {

  isLoading: boolean = false;

  groupId: string = '';
  supplierPersonId: string = '';

  clientGroup?: ClientGroup;
  supplierPerson?: SupplierPerson;

  addReferenceForm = new FormGroup({
    'forename': new FormControl(undefined, Validators.required),
    'surname': new FormControl(undefined, Validators.required),
    'email': new FormControl(undefined, [Validators.required, Validators.email])
  });

  get forename() {
    return this.addReferenceForm.get('forename') as FormControl;
  }

  get surname() {
    return this.addReferenceForm.get('surname') as FormControl;
  }

  get email() {
    return this.addReferenceForm.get('email') as FormControl;
  }

  private _unsubscribeSignal$: Subject<void> = new Subject();

  constructor(
    private route: ActivatedRoute,
    private appStore: Store<fromApp.AppState>,
    private translate: TranslateService,
    private redirectService: RedirectService,
    private scrollTo: ScrollTo,
    private supplierService: SupplierService,
    private anagService: AnagService
  ) {
  }

  ngOnInit() {
    this.scrollTo.header();

    this.groupId = this.route.snapshot.paramMap.get('groupId') || '';
    this.supplierPersonId = this.route.snapshot.paramMap.get('supplierPersonId') || '';

    if (this.groupId) {
      this.isLoading = true;
      this.getClient();
    } else {
      this.redirectService.goToAdminClients();
    }
  }

  getClient() {
    this.anagService.getCustomersForAdmin(this.groupId)
      .pipe(takeUntil(this._unsubscribeSignal$.asObservable()))
      .subscribe((result) => {
        if (!result || result.error) {
          this.dispatchWarningModal('044', result.error || '');
          this.isLoading = false;
        } else {
          this.clientGroup = result.response;
          if (this.supplierPersonId) {
            this.getReference();
          } else {
            this.isLoading = false;
          }
        }
      }, (err) => {
        if (err && err.message) {
          this.dispatchWarningModal('045', err.message);
        }
        this.isLoading = false;
      });
  }

  getReference() {
    this.isLoading = false;
    this.supplierService.getCustomerReferentForAdmin(this.groupId, this.supplierPersonId)
      .pipe(takeUntil(this._unsubscribeSignal$.asObservable()))
      .subscribe((result) => {
        if (!result || result.error) {
          this.dispatchWarningModal('059', result.error || '');
        } else {
          this.supplierPerson = result.response;
          this.forename.setValue(this.supplierPerson.forename);
          this.surname.setValue(this.supplierPerson.surname);
          this.email.setValue(this.supplierPerson.email);
        }
        this.isLoading = false;
      }, (err) => {
        if (err && err.message) {
          this.dispatchWarningModal('060', err.message);
        }
        this.isLoading = false;
      });
  }

  ngOnDestroy() {
    this._unsubscribeSignal$.next();
    this._unsubscribeSignal$.unsubscribe();
  }

  /**
   * @description Va all'homepage delle SS
   */
  goBack() {
    this.redirectService.goToAdminClient(this.groupId);
  }

  goToFAQ() {

  }

  confirmAddReference() {
    if (this.addReferenceForm.valid) {
      this.isLoading = true;

      let request: Observable<SenecaResponse<boolean>> = this.supplierPersonId ?
        this.supplierService.updateCustomerReferentForAdmin(this.supplierPersonId, this.surname.value, this.forename.value, this.email.value) :
        this.supplierService.createCustomerReferentForAdmin(this.forename.value, this.surname.value, this.email.value, this.groupId);

      request
        .pipe(takeUntil(this._unsubscribeSignal$.asObservable()))
        .subscribe((result) => {
          if (!result || result.error) {
            this.dispatchWarningModal('041', result.error || '');
          } else if (result.response) {
            this.redirectService.goToAdminClient(this.groupId);
          }
          this.isLoading = false;
        }, (err) => {
          if (err && err.message) {
            this.dispatchWarningModal('042', err.message);
          }
          this.isLoading = false;
        });
    }
  }

  /**
   * @description Mostra una modale con un messaggio di errore
   * @param modalId
   * @param error
   */
  dispatchWarningModal(modalId: string, error: string) {
    const messageObj = getWarningModal(this.translate, modalId, error);
    this.appStore.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
  }

}

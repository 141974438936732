<div role="tab" attr.aria-selected="{{ isCurrentTab }}" attr.aria-disabled="{{ isDisabled }}"
  [attr.aria-controls]="_isCurrentTab ? tabPanelId : ''" id="{{tabId}}" tabindex="0" class="tab-light-container" fxLayout="row"
  fxLayoutAlign="center end" [ngClass]="{
    'disabled': isDisabled,
    'search-step': _searchStep,
    'completed': isCompleted,
    'upperCase': isUppercase,
    'isCurrentTab': _isCurrentTab}" (click)="emitOnModelChanged($event)" attr.aria-selected="{{_isCurrentTab}}"
  clickKeyBoard>
  <p class="center">{{ tabTitle }}</p>
</div>
import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-accenture-logout-sso-completed',
  templateUrl: './accenture-logout-sso-completed.component.html',
  styleUrls: ['./accenture-logout-sso-completed.component.scss']
})
export class AccentureLogoutSsoCompletedComponent {
  constructor(public translate: TranslateService) {
  }
}

import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { AutocomplaceOptions } from '../../directive/ngx-autocom-place.directive';

@Component({
  selector: 'welion-input',
  templateUrl: 'welion-input.component.html',
  styleUrls: ['./welion-input.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class WelionInputComponent implements OnInit, OnDestroy {

  clickableIcon: boolean = false;

  _id?: string;
  @Input() id?: string | number;
  @Input() uppercase: boolean = false;
  @Input() attrName?: string;

  @Input() ariaLabelledbyId?: string;
  @Input() ariaLabelledbyText?: string;
  @Input() ariaLabelledbyLabel?: string;
  @Input() required: boolean = false;
  @Input() error: boolean = false;
  @Input() errorText?: string;

  _tabIndex: string = "0";
  @Input() set tabIndexValue(value: string) {
    if (value) {
      this._tabIndex = value;
      if (this._id) {
        const element = document.getElementById(this._id);
        if (element) {
          element.setAttribute("tabindex", value);
        }
      }
    }
  };

  _inputValue: FormControl = new FormControl();
  @Input() set inputValue(value: FormControl | undefined) {
    if (value) {
      this._inputValue = value;
      this._inputValue.valueChanges
        .pipe(takeUntil(this._unsubscribeSignal$.asObservable()))
        .subscribe(value => {
          this.onModelChanged.emit(value);
        });
    }
  };

  @Input() set bindValue(value: string | undefined) {
    this._inputValue.setValue(value);
    this._inputValue.valueChanges
      .pipe(takeUntil(this._unsubscribeSignal$.asObservable()))
      .subscribe(value => {
        this.onModelChanged.emit(value);
      });
  }

  /**
   * Works only with bindValue - otherwise the disabled need to be put inside the FormControl
   */
  @Input() set disabled(value: boolean) {
    if (value) {
      this._inputValue.disable();
    } else {
      this._inputValue.enable();
    }
  }

  get disabledValue(): boolean | undefined {
    return this._inputValue.disabled as boolean;
  }

  get bindValue(): string | undefined {
    return this._inputValue.value;
  }

  @Input() placeholder: string = '';

  @Input() iconRight: string = '';

  _readonly: boolean = false;
  @Input() set readonly(value: boolean | string) {
    this._readonly = coerceBooleanProperty(value);
  }

  _type: string = 'text';
  @Input() set type(value: string) {
    this._type = value || 'text';
  }

  _withTransparency: boolean = false;
  @Input() set withTransparency(value: string | boolean) {
    this._withTransparency = coerceBooleanProperty(value);
  }

  _hasMapsAutocomplete: boolean = false;
  @Input() set hasMapsAutocomplete(value: string | boolean) {
    this._hasMapsAutocomplete = coerceBooleanProperty(value);
  }

  _hasTimePicker: boolean = false;
  @Input() set hasTimePicker(value: string | boolean) {
    this._hasTimePicker = coerceBooleanProperty(value);
  }

  @Input() mapsOptions?: AutocomplaceOptions = {
    types: ['address'] // 'establishment' / 'address' / 'geocode'
  };

  @Input() debounceTime: number = 650;

  /**
   * To avoid build error is any -> google.maps.places.PlaceResult
   */
  @Output('selectedPlace') onSelectedPlace: EventEmitter<any> = new EventEmitter();
  @Output('onChange') onModelChanged: EventEmitter<any> = new EventEmitter();
  @Output() onIconClicked: EventEmitter<any> = new EventEmitter();

  private _unsubscribeSignal$: Subject<void> = new Subject();

  public showError = false;

  constructor() { }

  ngOnInit() {
    if (!this.id) {
      this.id = Math.floor((Math.random() * 10000) + 1);
    }
    this._id = "welion-input-" + this.id;

    if (this.onIconClicked) {
      this.clickableIcon = true;
    }
  }

  ngOnDestroy() {
    this._unsubscribeSignal$.next();
    this._unsubscribeSignal$.unsubscribe();
  }

  emitOnIconClicked(data?: any) {
    if (this.onIconClicked && this.onIconClicked instanceof EventEmitter) {
      this.onIconClicked.emit(data);
    }
  }

  /**
   *
   * @param {google.maps.places.PlaceResult} place
   */
  placeChangedCallback(place: any) {
    if (this.onSelectedPlace && place) {
      this.onSelectedPlace.emit(place);
    }
  }

  updateValueFromTimePicker(time: any) {
    if (time && time.value) {
      this._inputValue.setValue(time.value);
      this._inputValue.updateValueAndValidity();
      this.onModelChanged.emit(time.value);
    }
  }

  private timeout: any;
  onBlur(event: any) {
    if (this.error) {
      event.srcElement.setAttribute("aria-invalid", true);
      if (!event.srcElement.getAttribute("aria-labelledby").includes(" error-" + this._id)) {
        event.srcElement.setAttribute("aria-labelledby", event.srcElement.getAttribute("aria-labelledby") + " error-" + this._id);
      } else {
        if (!event.srcElement.getAttribute("aria-labelledby").includes(" error-" + this._id)) {
          event.srcElement.setAttribute("aria-labelledby", event.srcElement.getAttribute("aria-labelledby").replace(" error-" + this._id, ""));
        }
      }
    } else {
      event.srcElement.removeAttribute("aria-invalid");
    }
  }

}

import { HttpParams } from "@angular/common/http";
import { TranslateService } from "@ngx-translate/core";
import { InfoCampaign, JwtPayload } from "atfcore-commonclasses";
import { ApplicationModalMessage } from "../core/ngrx/core.reducers";
import * as moment from 'moment';

export function userIsFemale(jwtPayload: JwtPayload): boolean {
  return jwtPayload && jwtPayload.user && jwtPayload.user.chiaveSesso === "F";
}

export function structureName(jwtPayload: JwtPayload): string {
  return jwtPayload && jwtPayload.params && jwtPayload.params.supplier && jwtPayload.params.supplier.supplierName || '';
}

export function structureCode(jwtPayload: JwtPayload): string {
  if (!jwtPayload || !jwtPayload.params || !jwtPayload.params.supplier ||
    !jwtPayload.params.supplier.supplierAttributes || jwtPayload.params.supplier.supplierAttributes.length === 0) {
    return '';
  }
  let supplierCode = '';
  const supplierAttributes: any[] = jwtPayload.params.supplier.supplierAttributes;

  for (let i = 0; i < supplierAttributes.length; i++) {
    if (supplierAttributes[i].attributeType === 'CODE') {
      supplierCode = supplierAttributes[i].attributeValue;
    }
  }
  return supplierCode;
}

/**
 * Set the searchedText
 * @param httpParams HttpParams to append the data
 * @param searchedText Searched text
 */
export function handleSearchedText(httpParams: HttpParams, searchedText?: string): HttpParams {
  return searchedText ? httpParams.append('searchedText', searchedText || '') : httpParams;
}

/**
 * Set the title
 * @param httpParams HttpParams to append the data
 * @param title Searched title text
 */
export function handleTitle(httpParams: HttpParams, title?: string): HttpParams {
  return title ? httpParams.append('title', title || '') : httpParams;
}

/**
 * Set the fromRecord
 * @param httpParams HttpParams to append the data
 * @param fromRecord From record
 */
export function handleFromRecord(httpParams: HttpParams, fromRecord?: number): HttpParams {
  return fromRecord || fromRecord === 0 ? httpParams.append('fromRecord', (fromRecord.toString())) : httpParams;
}

/**
 * Set the numRecords
 * @param httpParams HttpParams to append the data
 * @param numRecords Number of records
 */
export function handleNumRecords(httpParams: HttpParams, numRecords?: number): HttpParams {
  return numRecords || numRecords === 0 ? httpParams.append('numRecords', numRecords.toString()) : httpParams;
}

/**
 * Set the day
 * @param httpParams HttpParams to append the data
 * @param day Day of reference
 */
export function handleDay(httpParams: HttpParams, day?: Date) {
  return day ? httpParams.append('day', new Date(day).toISOString()) : httpParams;
}

export function handleVanueIds(httpParams: HttpParams, venueIds?: string | string[]) {
  return handleCustomList(httpParams, 'venueId', venueIds);
}

/**
 * Set the arguments ids in & configuration
 * @param httpParams HttpParams to append the data
 * @param paramName Name of custom parameter
 * @param list List of arguments
 * @returns HttpParams with appended list if the data are presents
 */
export function handleCustomList(httpParams: HttpParams, paramName: string, list?: string | string[]): HttpParams {
  if (list && list.length && Array.isArray(list)) {
    list && list.forEach(type => {
      httpParams = httpParams.append(paramName, type);
    });
  } else if (list && list.length && !Array.isArray(list)) {
    httpParams = httpParams.append(paramName, list);
  }

  return httpParams;
}

export function getWarningModal(translate: TranslateService, modalId: string, error: string): ApplicationModalMessage {
  return {
    modalId: modalId,
    text: translate.instant("errors." + error),
    title: translate.instant("generic.WARNING")
  };
}

export function getModalMessageData(translate: TranslateService, title: string, message: string): ApplicationModalMessage {
  return {
    text: translate.instant(message),
    title: translate.instant(title)
  };
}

/**
 * Control of year, month and day
 * @param date1
 * @param date2
 */
export function areSameDate(date1: Date, date2: Date): boolean {
  return date1.getFullYear() === date2.getFullYear() &&
    date1.getMonth() === date2.getMonth() &&
    date1.getDate() === date2.getDate();
}

export function isActiveCampaign(campaign: InfoCampaign): boolean {
  if (campaign && campaign.rounds && campaign.rounds.length > 0) {
    let _startDate = new Date(campaign.rounds[0].minReservationEndDate);
    let _endDate = new Date(campaign.rounds[0].endDate);
    campaign.rounds.forEach(round => {
      const minReservationEndDate = new Date(round.minReservationEndDate);
      if (minReservationEndDate && _startDate && minReservationEndDate.getTime() < _startDate.getTime()) {
        _startDate = new Date(minReservationEndDate);
      }
      const endDate = new Date(round.endDate);
      if (endDate && _endDate && endDate.getTime() > _endDate.getTime()) {
        _endDate = new Date(endDate);
      }
    });
    const now = new Date().getTime();
    return _startDate.getTime() < now && now < _endDate.getTime();
  } else return false;
}

const MESI = { A: '01', B: '02', C: '03', D: '04', E: '05', H: '06', L: '07', M: '08', P: '09', R: '10', S: '11', T: '12' };

/** Calcola la data di nascita dato il codice fiscale. Copiata da BE */
export function getBirthDateFromCF(codFisc: string): Date | undefined {

  if (!codFisc) {
    return undefined;
  }
  let [anno, giorno] = [Number(codFisc.substring(6, 8)), Number(codFisc.substring(9, 11))];
  if (giorno > 40) {
    giorno -= 40;
    giorno = 0 + giorno;
  }

  // Le ultime due cifre dell'anno potrebbero riferirsi al 2000 o al 1900
  // Di conseguenza provo prima il caso 20YY, e se questa data è nel futuro, per forza di cose ci stiamo riferendo al 19YY
  // NOTA 1: Questo ragionamento non funziona per casi di centenari, che verrebbero trattati come bambini
  // NOTA 2: il controllo < 10 serve solo ad inserire il padding corretto nel caso l'anno abbia una sola cifra
  let fullYear: string = moment.utc(anno < 10 ? `200${anno}` : `20${anno}`).isAfter(moment.utc().startOf('year')) ?
    (anno < 10 ? `190${anno}` : `19${anno}`)
    : anno < 10 ? `200${anno}` : `20${anno}`

  let dateStr = fullYear + "-" + MESI[codFisc.charAt(8) as keyof typeof MESI] + "-" + giorno;
  return moment(dateStr, "YYYY-MM-DD").toDate();
}

/**
 * Check if the birth date is or not under x years old
 * @param {Date} birthDate
 * @param {number} yearsOld 9 to check for 9 years old
 * @return {boolean}
 */
export function checkUnderCustomYearsOld(birthDate: Date, yearsOld: number): boolean {
  const currentYear: number = parseInt(new Date().getFullYear().toString());

  const customYearsOldDate: Date = new Date();
  customYearsOldDate.setFullYear(currentYear - yearsOld);
  customYearsOldDate.setHours(0, 0, 0, 0);

  return customYearsOldDate.getTime() < birthDate.getTime();
}

export function getAgeFromBirthDate(birthDate: Date): number {
  const today = new Date();
  let age = today.getFullYear() - birthDate.getFullYear();
  let m = today.getMonth() - birthDate.getMonth();
  if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
    age--;
  }
  return age;
}

// Verifica se l'utente può modificare la data di prenotazione scelta
export function canUserEditBookingDate(round: any, doneBooking: any): any {
  let canEdit = false;

  if (round && doneBooking) {
    const now = new Date();

    // Data inizio prenotazioni
    const startReservationDate = new Date(round.minReservationEndDate);

    // Data fine prenotazioni
    const endReservationDate = new Date(round.maxReservationEndDate);

    // Calcolo la data massima entro cui l'utente può annullare la prenotazione fatta.
    // La pre-seleziono con la data di prenotazione fatta dall'utente (startDate)
    let maxDeleteBookingDate = doneBooking && moment(doneBooking.startDate);

    // Giorni precedenti alla data dell'appuntamento selezionato dall'utente entro cui può annullare la prenotazione
    const daysBeforeStopEdit = round.maxAllowedReservationMovingDate;

    if (daysBeforeStopEdit) {
      maxDeleteBookingDate = maxDeleteBookingDate.subtract(daysBeforeStopEdit, "days");
    } else {
      // Lato admin non è stato abilitato il termine di annullamento prenotazione, quindi cambio la pre-selezione della data di fine
      maxDeleteBookingDate = moment(round.maxReservationEndDate);
    }

    // Data di fine da prendere in considerazione
    const maxEditDate = (maxDeleteBookingDate && moment(maxDeleteBookingDate).toDate()) || endReservationDate;
    // Verifico se l'utente può annullare la prenotazione
    if (startReservationDate <= now && now <= maxEditDate) {
      canEdit = true;
    }
  }

  return canEdit;
}

// Verifica se l'utente può annullare la prenotazione fatta
export function canUserCancelBooking(round: any, doneBooking: any): any {
  let canCancelBooking = false;

  if (round && doneBooking) {
    const now = new Date();

    // Data inizio prenotazioni
    const startReservationDate = new Date(round.minReservationEndDate);

    // Data fine prenotazioni
    const endReservationDate = new Date(round.maxReservationEndDate);

    // Calcolo la data massima entro cui l'utente può annullare la prenotazione fatta.
    // La pre-seleziono con la data di prenotazione fatta dall'utente (startDate)
    let maxDeleteBookingDate = doneBooking && moment(doneBooking.startDate);

    // Giorni precedenti alla data dell'appuntamento selezionato dall'utente entro cui può annullare la prenotazione
    const daysBeforeCancelBooking = round.maxAllowedReservationDeletionDate;

    if (daysBeforeCancelBooking) {
      maxDeleteBookingDate = maxDeleteBookingDate.subtract(daysBeforeCancelBooking, "days");
    } else {
      // Lato admin non è stato abilitato il termine di annullamento prenotazione, quindi cambio la pre-selezione della data di fine
      maxDeleteBookingDate = moment(round.maxReservationEndDate);
    }

    // Data di fine da prendere in considerazione
    const maxCancelDate = (maxDeleteBookingDate && moment(maxDeleteBookingDate).toDate()) || endReservationDate;

    // Verifico se l'utente può annullare la prenotazione
    if (startReservationDate <= now && now <= maxCancelDate) {
      canCancelBooking = true;
    }
  }

  return canCancelBooking;
}

export function hasRoundClosedBooking(round: any, doneBooking: any): any {
  const now = new Date().getTime();
  let closedBooking = false;

  if (round) {
    if (round.minReservationEndDate && !round.maxReservationEndDate) {
      // Presente solo il minimo
      closedBooking = new Date(round.minReservationEndDate).getTime() > now;
    } else if (round.maxReservationEndDate && !round.minReservationEndDate) {
      // Presente solo il massimo
      closedBooking = new Date(round.maxReservationEndDate).getTime() < now;
    } else {
      // Verifico se l'utente può annullare la prenotazione
      const canCancelBooking = canUserCancelBooking(round, doneBooking);

      closedBooking = !canCancelBooking;
    }
  }

  return closedBooking;
}

export function getAgeFromBornDate(bornDate: Date): number {
  let userAge: number = 0;
  if (!isNaN(moment(bornDate).utc().toDate().getFullYear())) {
    userAge = moment().utc().diff(bornDate, 'year');
  }
  return userAge;
}
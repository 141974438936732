import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TabSection } from 'src/app/utils/classes.utils';

@Component({
  selector: 'tab-section',
  templateUrl: 'tab-section.component.html',
  styleUrls: ['./tab-section.component.scss']
})
export class TabSectionComponent implements OnInit {
  @Input() tab: TabSection = {
    id: '',
    title: '',
    isCompleted: false,
    isDisabled: false
  };
  @Output() onClick: EventEmitter<any> = new EventEmitter();

  constructor() { }

  ngOnInit() { }


  tabClicked() {
    this.onClick.emit(this.tab);
  }
}
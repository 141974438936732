<!-- Sidebar con il menù principale -->
<sidebar [id]="'sidebar-nav-menu'" showAppHeader hideCloseButton [sidenavTemplateRef]="navContent" direction="right"
  [navWidth]="325" [duration]="0.3" [ariaLabel]="'Menu di navigazione'">
</sidebar>

<!-- Template con la sidebar principale (di destra) -->
<ng-template #navContent>
  <welion-dropdown-item (click)="onHeaderOptionClicked(item)" class="full-width"
    *ngFor="let item of basicMenuItems;index as i; trackBy menuId" [isMenuItem]="true" [item]="item" [index]="i"
    [sidenavOpen]="sidebarService.getShowNav() | async" clickKeyBoard (onEscKeyboard)="onEscKeyboard()"
    (onEnterKeyboard)="onHeaderOptionClicked(item);">
  </welion-dropdown-item>

  <!-- Voci menu strutture -->
  <ng-container *ngIf="isStructure">
    <welion-dropdown-item [ngClass]="{'display-on-mobile': structureItem.id=='tutorial'}"
      (click)="onHeaderOptionClicked(structureItem)" class="full-width"
      *ngFor="let structureItem of structureMenuItems; trackBy menuId" [isMenuItem]="true" [item]="structureItem">
    </welion-dropdown-item>
  </ng-container>

</ng-template>

<!-- Header -->
<div id="app-header"></div>
<app-header *ngIf="showHeader()" [tooltipModalMessage]="headerTooltip"
  [sidenavOpen]="this.sidebarService.getShowNav() | async">
</app-header>

<div *ngIf="isImpersonating && loggedUser" class="sectionImpersonification">
  <p>
    {{ "impersonify.YOU_ARE_IMPERSONIFYING" | translate }}
    {{ loggedUser.forename }} {{ loggedUser.surname }}
  </p>
  <welion-button (onClick)="returnToMyProfile()" type="third white" size="medium" label="impersonify.RETURN_TO_MY_PROFILE">
  </welion-button>
</div>

<!-- App -->
<router-outlet *ngIf="!isFetchingLangs" [hidden]="showApplicationLoader"></router-outlet>

<!-- Footer -->
<app-footer *ngIf="showFooter()"></app-footer>

<!-- Modale generica con un messaggio (ad esempio di errore piuttosto che di successo) -->
<modal id="modalMessage" (onClose)="closeModalMessage()" (onCancel)="closeModalMessage()"
  (onConfirm)="closeModalMessage()" [cancelLabel]="applicationModalButtonCloseText" confirmLabel="Ok"
  [title]="applicationModalMessageTitle" [titleClass]="'bold-text'">
  <modal-text-content [withScrolledBody]="withScrolledBody" [modalId]="applicationModalId"
    [text]="applicationModalMessageText" [subtitle]="applicationModalMessageSubTitle"
    [noBackground]="applicationModalMessageNoBackground" [badge]="applicationModalMessageBadge"
    [badgeDescr]="applicationModalMessageBadgeDescr">
  </modal-text-content>
</modal>


<!-- Modale di avviso se utente eliminato -->
<modal id="user-deleted-home" (onClose)="modalService.close('user-deleted')"
  (onCancel)="modalService.close('user-deleted')" (onConfirm)="modalService.close('user-deleted')"
  [confirmLabel]="'generic.CONFIRM' | translate">

  <div class="modal-title-wrapper" fxLayout="column" fxLayoutAlign="start center">
    <h2 fxFlexOffset="24px" class="primary small bold center" translate="generic.WARNING"></h2>
    <p class="modal-code-wrapper">{{ 'CODICE MODALE: cr002'}}</p>
  </div>

  <div class="modal-text-wrapper">
    {{'errors.NO_LONGER_ENABLED_1' | translate}}
    <a class="link" (click)='redirectService.goToCreateUserTicket()'>{{'generic.HERE' | translate}}</a>
    {{'errors.NO_LONGER_ENABLED_2' | translate}}
  </div>
</modal>
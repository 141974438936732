import { FormControl, FormGroup, Validators } from "@angular/forms";
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Store } from "@ngrx/store";
import { TranslateService } from '@ngx-translate/core';
import { Subject } from 'rxjs';
import { takeUntil } from "rxjs/operators";

import { getModalMessageData, getWarningModal } from "src/app/utils/utils";
import { ScrollTo } from "src/app/core/services/scroll-to.service";
import * as fromApp from "../../ngrx/app.reducers";
import * as CoreActions from "../../core/ngrx/core.actions";
import { ActivatedRoute } from "@angular/router";
import { HelpDeskService } from "src/app/shared/services/help-desk.service";
import { ModalService } from "src/app/shared/components/modal/modal.service";

@Component({
  selector: 'app-register-ad',
  templateUrl: './registerAD.component.html',
  styleUrls: ['./registerAD.component.scss']
})
export class RegisterADComponent implements OnInit, OnDestroy {
  isLoading: boolean = false;
  userId: string = '';

  registerADForm = new FormGroup({
    'name': new FormControl(undefined),
    'surname': new FormControl(undefined),
    'fiscalCode': new FormControl(undefined),
    'company': new FormControl(undefined),
    'mail': new FormControl(undefined, [Validators.email]),
    'phoneNumber': new FormControl(undefined, [
      Validators.required,
      Validators.pattern(/^[-+0-9 ]*$/)
    ])
  });

  get name() {
    return this.registerADForm.get('name') as FormControl;
  }

  get surname() {
    return this.registerADForm.get('surname') as FormControl;
  }

  get fiscalCode() {
    return this.registerADForm.get('fiscalCode') as FormControl;
  }

  get company() {
    return this.registerADForm.get('company') as FormControl;
  }

  get mail() {
    return this.registerADForm.get('mail') as FormControl;
  }

  get phoneNumber() {
    return this.registerADForm.get('phoneNumber') as FormControl;
  }

  userToRegisterForenameAndSurname: string = '';
  userToRegisterCodFisc: string = '';
  userToRegisterCompany: string = '';
  userToRegisterEmail: string = '';
  userToRegisterPhone: string = '';

  privacyCheck: boolean = false;

  private _unsubscribeSignal$: Subject<void> = new Subject();

  constructor(
    private modalService: ModalService,
    private helpDeskService: HelpDeskService,
    private translate: TranslateService,
    private scrollTo: ScrollTo,
    private appStore: Store<fromApp.AppState>,
    private route: ActivatedRoute
  ) {
  }

  ngOnInit() {
    this.scrollTo.header();

    this.userId = this.route.snapshot.paramMap.get('userId') || '';

    // Recupero le informazioni dell'utente che sto registrando
    this.getEntitledByIdForHelpDesk();
  }

  ngOnDestroy() {
    this._unsubscribeSignal$.next();
    this._unsubscribeSignal$.unsubscribe();
  }

  // Recupera le informazioni sull'utente da registrare
  getEntitledByIdForHelpDesk() {
    this.isLoading = true;

    this.helpDeskService.getEntitledByIdForHelpDesk(this.userId)
      .pipe(takeUntil(this._unsubscribeSignal$.asObservable()))
      .subscribe((result) => {
        if (!result || result.error) {
          this.dispatchWarningModal('082', result.error || '');
        } else {
          const userData = result.response;

          const forename = userData.forename || '';
          const surname = userData.surname || '';
          const codFisc = userData.codFisc || '';
          const company = userData.company || userData.socDistacco || '';
          const email = userData.email || '';
          const phone = userData.phoneNumber || '';

          this.name.setValue(forename);
          this.surname.setValue(surname);
          this.fiscalCode.setValue(codFisc);
          this.company.setValue(company);
          this.mail.setValue(email);
          this.phoneNumber.setValue(phone);

          this.userToRegisterForenameAndSurname = forename + ' ' + surname;
          this.userToRegisterCodFisc = codFisc;
          this.userToRegisterCompany = company;
          this.userToRegisterEmail = email;
          this.userToRegisterPhone = phone;
        }
        this.isLoading = false;
      }, (err) => {
        this.isLoading = false;
        if (err && err.message) {
          this.dispatchWarningModal('084', err.message);
        }
      });
  }

  /**
   * @description Va all'homepage delle faq
   */
  goBack() {
    window.history.back();
  }

  // Apre la modale di conferma per la registrazione
  openRegisterAdModal() {
    this.modalService.open('confirmRegistrationModal');
  }

  // Chiude la modale di conferma per la registrazione
  closeRegisterAdModal(confirm?: boolean) {
    this.modalService.close('confirmRegistrationModal');

    if (confirm) {
      this.confirmEntitledRegistration();
    }
  }

  // Invia segnalazione
  confirmEntitledRegistration() {
    if (this.registerADForm.valid) {
      this.isLoading = true;

      this.helpDeskService.confirmEntitledRegistrationForHelpDesk(
        this.userId,
        this.mail.value,
        this.phoneNumber.value, this.privacyCheck).pipe(takeUntil(this._unsubscribeSignal$.asObservable()))
        .subscribe((result: any) => {
          if (!result || result.error) {
            this.dispatchWarningModal('041', result.error || '');
          } else {
            // Prima di tornare indietro, mostro un messaggio di conferma all'utente
            const title = this.translate.instant("registerAd.CONFIRM_REGISTRATION");
            let message = this.translate.instant("registerAd.REGISTRATION_DONE");
            const messageObj = getModalMessageData(this.translate, title, message);
            this.appStore.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));

            window.history.back();
          }
          this.isLoading = false;
        }, (err: any) => {
          if (err && err.message) {
            this.dispatchWarningModal('042', err.message);
          }
          this.isLoading = false;
        });
    }
  }

  // Apre un url esterno
  openUrl(url: string, event?: any) {
    window.open(url, '_blank');

    if (event) {
      event.stopPropagation();
      event.preventDefault();
    }
  }

  // Cambia il valore sulla privacy
  onCheckPrivacy(value: boolean) {
    this.privacyCheck = value;
  }

  /**
   * @description Mostra una modale con un messaggio di errore
   * @param modalId
   * @param error
   */
  dispatchWarningModal(modalId: string, error: string) {
    const messageObj = getWarningModal(this.translate, modalId, error);
    this.appStore.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
  }
}

import { Component, OnDestroy } from '@angular/core';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import * as fromApp from "../ngrx/app.reducers";
import * as moment from 'moment';

@Component({
    selector: 'app-user',
    templateUrl: './user.component.html',
    styleUrls: ['./user.component.scss']
})
export class UserComponent implements OnDestroy {
    applicationLang: string = '';
    applicationLang$: Subscription;
    loggedUser: any;

    constructor(
        public translate: TranslateService,
        private appStore: Store<fromApp.AppState>) {
        // Recupero la lingua dell'appplicazione
        this.applicationLang$ = this.appStore.select(fromApp.getApplicationLang).subscribe((applicationLang: any) => {
            if (applicationLang) {
                this.applicationLang = applicationLang;
                this.translate.use(applicationLang);
                moment.locale(applicationLang);
            }
        });

        this.appStore.select(fromApp.getLoggedUser)
            .subscribe(
                (loggedUser) => {
                    if (loggedUser && loggedUser.user) {
                        this.loggedUser = loggedUser && loggedUser.user;
                    }
                });
    }

    ngOnDestroy(): void {
        if (this.applicationLang$) {
            this.applicationLang$.unsubscribe();
        }
    }
}

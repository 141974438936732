<div class="welion-paginator" fxLayout="row" fxLayoutAlign="end">
  <!-- Sx -->
  <div class="block" fxLayout="column" fxLayoutAlign="center center" (click)="goToLeft()"
    [ngClass]="{'disabled': isLoading || selectedIndex === 0}">
    <svg-icon [applyClass]="true" src="./assets/img/icons/arrow-left-grey.svg"></svg-icon>
  </div>

  <!-- Numero pagina -->
  <div fxFlexOffset="4px" class="block" *ngFor="let pageIndex of _paginationPages" fxLayout="column"
    fxLayoutAlign="center center" [ngClass]="{'selected': pageIndex === selectedIndex, 'disabled': isLoading}"
    (click)="goToPage(pageIndex)">
    <h6 class="black">{{ pageIndex + 1 }}</h6>
  </div>

  <!-- Dx -->
  <div fxFlexOffset="4px" class="block" fxLayout="column" fxLayoutAlign="center center" (click)="goToRight()"
    [ngClass]="{'disabled': isLoading || selectedIndex >= (_totalPages - 1)}">
    <svg-icon [applyClass]="true" src="./assets/img/icons/arrow-right-grey.svg"></svg-icon>
  </div>
</div>
import { NgModule } from '@angular/core';
import { Routes, RouterModule, PreloadAllModules } from '@angular/router';
import { PageNotFoundComponent } from './core/page-not-found/page-not-found.component';
import { CompatibilityGuard } from './shared/services/compatibility-guard.service';
import { AuthGuard } from './auth/services/auth-guard.service';
import { CompatibilityErrorComponent } from './core/compatibility-error/compatibility-error.component';
import { LocalLoginComponent } from './auth/login/local-login.component';
import { LoginGuard } from './shared/services/login-guard.service';
import { PageNotAuthorizedComponent } from './core/page-not-authorized/page-not-authorized.component';
import { DefaultPageComponent } from './core/defaultPage/default-page.component';
import { GenericErrorComponent } from './core/generic-error/generic-error.component';
import { SigninComponent } from './auth/signin/signin.component';
import { PrivacyComponent } from './shared/components/privacy/privacy.component';
import { SsoAccentureLoginComponent } from './auth/sso-accenture-login/sso-accenture-login.component';
import { AccentureLogoutSsoCompletedComponent } from './auth/accenture-logout-sso-completed/accenture-logout-sso-completed.component';
import { AccentureLoginSamlErrorComponent } from './auth/accenture-login-saml-error/accenture-login-saml-error.component';
import { LoginImpersonationComponent } from './auth/login-impersonation/login-impersonation.component';
import { SigninSSOComponent } from './auth/signinSSO/signinSSO.component';
import { SsoEnelLoginComponent } from './auth/sso-enel-login/sso-enel-login.component';
import { EnelLoginSamlErrorComponent } from './auth/enel-login-saml-error/enel-login-saml-error.component';
import { EnelLogoutSsoCompletedComponent } from './auth/enel-logout-sso-completed/enel-logout-sso-completed.component';
import { EnelPrivacyComponent } from './shared/components/enelPrivacy/enelPrivacy.component';
import { FAQComponent } from './shared/components/faq/faq.component';
import { SsoLeroyMerlinLoginComponent } from './auth/sso-leroy-merlin-login/sso-leroy-merlin-login.component';
import { LeroyMerlinLoginSamlErrorComponent } from './auth/leroy-merlin-login-saml-error/leroy-merlin-login-saml-error.component';
import { LeroyMerlinLogoutSsoCompletedComponent } from './auth/leroy-merlin-logout-sso-completed/leroy-merlin-logout-sso-completed.component';
import { AddTicketComponent } from './shared/addTicket/addTicket.component';
import { LoginSSOComponent } from './auth/login-sso/login-sso.component';
import { PrivacyGuard } from './shared/services/privacy-guard.service';
import { AccessibilityComponent } from './shared/components/accessibility/accessibility.component';

const appRoutes: Routes = [
  // Autenticazione
  { path: 'structureLogin', component: LocalLoginComponent, canActivate: [LoginGuard, CompatibilityGuard] },
  { path: 'loginSSO', component: LoginSSOComponent, canActivate: [LoginGuard, CompatibilityGuard] },
  { path: 'localLogin', component: LocalLoginComponent, canActivate: [LoginGuard, CompatibilityGuard] },
  { path: 'helpDeskLogin', component: LocalLoginComponent, canActivate: [LoginGuard, CompatibilityGuard] },
  { path: 'clientLogin', component: LocalLoginComponent, canActivate: [LoginGuard, CompatibilityGuard] },
  { path: 'login', component: LocalLoginComponent, canActivate: [LoginGuard, CompatibilityGuard] },
  { path: 'signin', component: SigninComponent, canActivate: [LoginGuard, CompatibilityGuard] },
  { path: 'signinSSO', component: SigninSSOComponent, canActivate: [LoginGuard, CompatibilityGuard] },
  { path: 'privacy', component: PrivacyComponent, canActivate: [LoginGuard, CompatibilityGuard] },
  { path: 'accessibilita', component: AccessibilityComponent, canActivate: [LoginGuard, CompatibilityGuard] },
  { path: 'confirmUserRegistration/:userId/:confirmToken', component: SigninComponent, canActivate: [LoginGuard, CompatibilityGuard] },

  // Login SSO Accenture
  { path: 'ssoAccentureLogin', component: SsoAccentureLoginComponent, canActivate: [LoginGuard, CompatibilityGuard] },
  { path: 'accentureLoginSamlError', component: AccentureLoginSamlErrorComponent, canActivate: [LoginGuard, CompatibilityGuard] },
  { path: 'accentureLogoutSsoCompleted', component: AccentureLogoutSsoCompletedComponent, canActivate: [LoginGuard, CompatibilityGuard] },

  // Login SSO Enel
  { path: 'ssoEnelLogin', component: SsoEnelLoginComponent, canActivate: [LoginGuard, CompatibilityGuard] },
  { path: 'enelLoginSamlError', component: EnelLoginSamlErrorComponent, canActivate: [LoginGuard, CompatibilityGuard] },
  { path: 'enelLogoutSsoCompleted', component: EnelLogoutSsoCompletedComponent, canActivate: [LoginGuard, CompatibilityGuard] },
  { path: 'enelPrivacy', component: EnelPrivacyComponent, canActivate: [LoginGuard, CompatibilityGuard] },

  // Login SSO Leroy Merlin
  { path: 'ssoLeroyMerlinLogin', component: SsoLeroyMerlinLoginComponent, canActivate: [LoginGuard, CompatibilityGuard] },
  { path: 'leroymerlinLoginSamlError', component: LeroyMerlinLoginSamlErrorComponent, canActivate: [LoginGuard, CompatibilityGuard] },
  { path: 'leroymerlinLogoutSsoCompleted', component: LeroyMerlinLogoutSsoCompletedComponent, canActivate: [LoginGuard, CompatibilityGuard] },

  // Impersonation
  { path: 'impersonationAuth/:impersonationToken', component: LoginImpersonationComponent, canActivate: [LoginGuard, CompatibilityGuard] },

  // Pagina di default non è stato specificato nessun URL dall'utente. Viene fatto il redirect in base alle sue auth
  { path: 'defaultPage', component: DefaultPageComponent, canActivate: [AuthGuard, CompatibilityGuard] },

  // FAQ
  { path: 'faq', component: FAQComponent, canActivate: [LoginGuard] },

  // Crea ticket
  { path: 'createTicket', component: AddTicketComponent, canActivate: [LoginGuard] },

  // Profili: strutture, aventi diritto (user), admin
  { path: 'user', loadChildren: () => import('./user/user.module').then(m => m.UserModule), canActivate: [AuthGuard/*, PrivacyGuard*/, CompatibilityGuard] },
  { path: 'admin', loadChildren: () => import('./admin/admin.module').then(m => m.AdminModule), canActivate: [AuthGuard, CompatibilityGuard] },
  { path: 'structure', loadChildren: () => import('./structure/structure.module').then(m => m.StructureModule), canActivate: [AuthGuard, CompatibilityGuard] },
  { path: 'helpDesk', loadChildren: () => import('./helpDesk/helpDesk.module').then(m => m.HelpDeskModule), canActivate: [AuthGuard, CompatibilityGuard] },
  { path: 'client', loadChildren: () => import('./client/client.module').then(m => m.ClientModule), canActivate: [AuthGuard, CompatibilityGuard] },

  // Recupero per utenti strutture
  { path: 'emailSupplierPersonConfirmation/:supplierPersonId/:token', component: LocalLoginComponent, canActivate: [LoginGuard, CompatibilityGuard] },
  { path: 'emailCustomerSupplierPersonConfirmation/:supplierPersonId/:token', component: LocalLoginComponent, canActivate: [LoginGuard, CompatibilityGuard] },
  { path: 'emailEntitledConfirmation/:supplierPersonId/:token', component: LocalLoginComponent, canActivate: [LoginGuard, CompatibilityGuard] },
  // Altre pagine varie
  // { path: 'components', component: ComponentExampleComponent },
  { path: 'errorPage', component: GenericErrorComponent },
  { path: 'errorPage/:errorCode', component: GenericErrorComponent },
  { path: 'compatibilityError', component: CompatibilityErrorComponent },
  { path: '403-not-allowed', component: PageNotAuthorizedComponent },
  { path: '', redirectTo: 'defaultPage', pathMatch: 'full' },
  { path: '404-not-found', component: PageNotFoundComponent },
  { path: '**', redirectTo: '/404-not-found' }
];

@NgModule({
  imports: [
    RouterModule.forRoot(appRoutes, {
      useHash: true, 
      preloadingStrategy: PreloadAllModules,
       anchorScrolling: 'enabled',
       onSameUrlNavigation: 'reload',
    })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }

import { Component, Input, OnInit, ChangeDetectionStrategy, EventEmitter, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'welion-table',
    templateUrl: 'welion-table.component.html',
    styleUrls: ['./welion-table.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class WelionTableComponent implements OnInit {
    @Input() isEdit: boolean = false;
    @Output() onManage: EventEmitter<string> = new EventEmitter<string>();

    constructor(
        public translate: TranslateService
    ) {
    }

    ngOnInit() {
    }

    emitOnManageObjective(id: string) {
        this.onManage.emit(id);
    }
}
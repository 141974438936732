import { Component, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { combineLatest, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ActivatedRoute } from '@angular/router';

import { Lang } from 'atfcore-commonclasses/bin/classes/anag';

import * as fromApp from '../../ngrx/app.reducers';
import * as CoreActions from "../../core/ngrx/core.actions";

import { TranslateService } from '@ngx-translate/core';
import { AbstractControl, FormControl, FormGroup, ValidationErrors, Validators } from '@angular/forms';
import { RedirectService } from 'src/app/shared/services/redirect.service';
import { AuthService } from '../services/auth.service';
import { getModalMessageData, getWarningModal } from 'src/app/utils/utils';
import { UrlService } from 'src/app/shared/services/url.service';
import { ModalService } from 'src/app/shared/components/modal/modal.service';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-signin',
  templateUrl: './signin.component.html',
  styleUrls: ['./signin.component.scss']
})
export class SigninComponent implements OnInit, OnDestroy {
  isFetchingLangs: boolean = false;
  langs?: Lang[];

  stepIndex: number = 0;

  vatForm: FormGroup = new FormGroup({
    'vatCode': new FormControl(undefined, [Validators.required, Validators.minLength(16), Validators.maxLength(16)])
  });
  isSignInConfirmedOpen: boolean = false;

  get vatCode() {
    return this.vatForm.get('vatCode') as FormControl;
  }

  signinForm: FormGroup = new FormGroup({
    'name': new FormControl(undefined, [Validators.required, Validators.minLength(3)]),
    'surname': new FormControl(undefined, [Validators.required, Validators.minLength(3)]),
    'email': new FormControl(undefined, [Validators.required, Validators.email]),
    'phone': new FormControl(undefined, [
      Validators.required,
      Validators.pattern(/^[-+0-9 ]*$/)
    ]),
    'address': new FormControl(undefined, Validators.required)
  });

  get name() {
    return this.signinForm.get('name') as FormControl;
  }

  get surname() {
    return this.signinForm.get('surname') as FormControl;
  }

  get email() {
    return this.signinForm.get('email') as FormControl;
  }

  get phone() {
    return this.signinForm.get('phone') as FormControl;
  }

  get address() {
    return this.signinForm.get('address') as FormControl;
  }

  privacyForm: FormGroup = new FormGroup({
    'privacy': new FormControl(false, Validators.requiredTrue)
  });

  get privacy() {
    return this.privacyForm.get('privacy') as FormControl;
  }

  signinPswForm: FormGroup = new FormGroup({
    'password': new FormControl(undefined),
    'passwordConfirm': new FormControl(undefined)
  });

  get password() {
    return this.signinPswForm.get('password') as FormControl;
  }

  get passwordConfirm() {
    return this.signinPswForm.get('passwordConfirm') as FormControl;
  }

  showLoader: boolean = false;

  private confirmToken: string = '';
  private userId: string = '';

  private _unsubscribeSignal$: Subject<void> = new Subject();

  constructor(
    private store: Store<fromApp.AppState>,
    public redirectService: RedirectService,
    private route: ActivatedRoute,
    private authService: AuthService,
    public modalService: ModalService,
    private urlService: UrlService,
    public translate: TranslateService,
    private titleService: Title) {

    // Sto in ascolto di quando il globalApplicationData cambia, così da sapere quando ci sono le lingue disponibili poiché, se non ci fossero, il pulsante per il login sarebbe disabilitato
    combineLatest([
      this.store.select(fromApp.getAvailableLangs),
      this.store.select(fromApp.isFetchingLangs)
    ])
      .pipe(takeUntil(this._unsubscribeSignal$.asObservable()))
      .subscribe(
        ([langs, isFetchingLangs]) => {
          this.langs = langs;
          this.isFetchingLangs = isFetchingLangs;
        });

    this.translate.get(
      [
        "generic.SIGNIN"
      ]).subscribe(translations => {
        this.titleService.setTitle(translations["generic.SIGNIN"]);
      });
  }

  ngOnInit() {
    if (this.redirectService.isThisCurrentPage('confirmUserRegistration')) {
      this.confirmToken = this.route.snapshot.paramMap.get('confirmToken') || '';
      this.userId = this.route.snapshot.paramMap.get('userId') || '';

      if (!this.confirmToken || !this.userId) {
        this.redirectService.goToSignin();
      } else {
        this.confirmEntitledRegistration();
      }
    } else {
      this.password.setValidators([
        Validators.required,
        this.passwordValidate,
        Validators.minLength(8),
        Validators.maxLength(64),
        this.passwordValidatorLowerCase,
        this.passwordValidatorUpperCase,
        this.passwordValidatorNumber,
        this.passwordValidatorSpecial
      ]);

      this.passwordConfirm.setValidators([
        Validators.required,
        this.passwordConfirmValidate
      ]);
    }

  }

  selectedPlace(place: any) {
    if (!place || place.geometry === undefined || place.geometry === null) {
      return;
    }
    if (place.address_components && place.address_components.length) {
      this.address.setValue(place.formatted_address);
    }
  }

  ngOnDestroy() {
    this._unsubscribeSignal$.next();
    this._unsubscribeSignal$.unsubscribe();
  }

  /**
   * Quando viene aggiornata la password i controlli sul passwordConfirm vengono avviati
   * @param control
   */
  passwordValidate = (control: AbstractControl): ValidationErrors | null => {
    if (this.passwordConfirm) {
      this.passwordConfirm.updateValueAndValidity();
    }
    return null;
  }

  /**
   * Verifica la corrispondenza con la password
   * @param control
   */
  passwordConfirmValidate = (control: AbstractControl): ValidationErrors | null => {
    if (control && (this.password.valid || control.dirty) && control.value !== this.password.value) {
      return { 'passwordNotMatch': true };
    } else return null;
  }

  /**
   * Almeno una lettera minuscola, una maiuscola, un numero e un carattere speciale
   * Caratteri speciali ammessi https://owasp.org/www-community/password-special-characters
   * " !"#$%&'()*+,-./:;<=>?@[\]^_`{|}~" quelli tra virgolette
   *
   * Regex completa
   * Validators.pattern(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[ !"#$%&'()*+,-./:;<=>?@[\\\]^_`{|}~])[A-Za-z\d !"#$%&'()*+,-./:;<=>?@[\\\]^_`{|}~]{8,}$/)
   *
   * @param control
   * @returns
   */
  passwordValidatorLowerCase = (control: AbstractControl): ValidationErrors | null => {
    if (control && control.value && !control.value.match(/.*[a-z].*/)) {
      return { 'lowerCaseMiss': true };
    }
    return null;
  }

  passwordValidatorUpperCase = (control: AbstractControl): ValidationErrors | null => {
    if (control && control.value && !control.value.match(/.*[A-Z].*/)) {
      return { 'upperCaseMiss': true };
    }
    return null;
  }

  passwordValidatorNumber = (control: AbstractControl): ValidationErrors | null => {
    if (control && control.value && !control.value.match(/.*\d.*/)) {
      return { 'numberMiss': true };
    }
    return null;
  }

  passwordValidatorSpecial = (control: AbstractControl): ValidationErrors | null => {
    if (control && control.value && !control.value.match(/.*[ !"#$%&'()*+,-./:;<=>?@[\\\]^_`{|}~].*/)) {
      return { 'specMiss': true };
    }
    return null;
  }

  setVatCode() {
    if (this.vatForm.valid) {
      this.vatCode.disable();
      this.stepIndex = 1;
      // this.setEntitledAddressSearch();
    }
  }

  setUserInfo() {
    if (this.signinForm.valid) {
      this.stepIndex = 2;
    }
  }

  confirmPrivacy() {
    if (this.signinForm.valid) {
      this.stepIndex = 3;
    }
  }

  confirmRegistration() {
    if (this.signinPswForm.valid) {
      this.showLoader = true;

      this.authService.registerEntitled(
        this.vatCode.value,
        this.email.value,
        this.name.value,
        this.surname.value,
        this.phone.value,
        this.address.value,
        this.privacy.value,
        this.password.value
      )
        .pipe(takeUntil(this._unsubscribeSignal$.asObservable()))
        .subscribe((result) => {
          if (!result || result.error) {
            this.dispatchModal('038', result.error || '');
            this.showLoader = false;
          } else {
            this.isSignInConfirmedOpen = true;
            this.modalService.open('signinConfirmed');
          }
        }, (err) => {
          if (err && err.message) {
            this.dispatchModal('039', err.message);
          }
          this.showLoader = false;
        });
    }
  }

  goBack() {
    this.stepIndex = 0;
    this.vatCode.enable();
  }

  goToLogin() {
    this.redirectService.goToLogin();
  }

  onCheckPrivacy(value: boolean) {
    this.privacy.setValue(value);
  }

  goToPrivacy() {
    let applicationContext = this.urlService.getApplicationUrl().url;
    applicationContext = applicationContext + '/#/privacy';
    window.open(applicationContext, '_blank');
  }

  clearText() {
    this.vatCode.setValue(undefined);

    this.name.setValue(undefined);
    this.surname.setValue(undefined);
    this.email.setValue(undefined);
    this.phone.setValue(undefined);
    this.address.setValue(undefined);
    this.privacy.setValue(false);

    this.password.setValue(undefined);
    this.passwordConfirm.setValue(undefined);
  }

  closeSigninConfirmedModal() {
    this.modalService.close('signinConfirmed');
    this.redirectService.goToLogin();
  }

  confirmEntitledRegistration() {
    this.showLoader = true;
    this.authService.confirmEntitledRegistration(this.userId, this.confirmToken)
      .pipe(takeUntil(this._unsubscribeSignal$.asObservable()))
      .subscribe((result) => {
        if (!result || result.error) {
          this.dispatchModal('040', result.error || '');
          this.redirectService.goToSignin();
        } else {
          this.dispatchMessageModal('Attivazione account', 'Profilo abilitato!');
          this.redirectService.goToLogin();
        }
      }, (err) => {
        if (err && err.message) {
          this.dispatchModal('041', err.message);
        }
        this.redirectService.goToSignin();
      });
  }

  /**
   * @description Mostra una modale con titolo e messaggio
   * @param modalId
   * @param title
   * @param message
   */
  dispatchMessageModal(title: string, message: string) {
    const messageObj = getModalMessageData(this.translate, title, message);
    this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
  }

  dispatchModal(modalId: string, error: string) {
    const messageObj = getWarningModal(this.translate, modalId, error);
    this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
  }

  goToFAQ() {
    this.redirectService.goToPublicFAQPage();
  }

  homeIfModalOpened() {
    if (this.isSignInConfirmedOpen) {
      this.closeSigninConfirmedModal();
      this.isSignInConfirmedOpen = false;
    }
  }
}

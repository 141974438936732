import { Store } from "@ngrx/store";
import { Subscription, combineLatest, Observable } from "rxjs";
import { HttpClient } from "@angular/common/http";
import * as fromApp from '../../ngrx/app.reducers';
import { Injectable } from "@angular/core";
import { GlobalApplicationData } from "src/app/shared/models/global-application-data.model";
import * as moment from 'moment';
import { UrlService } from "./url.service";
import { SenecaResponse } from "atfcore-commonclasses";

@Injectable()
export class UserService {
    applicationData: any;
    result$: Subscription;
    loggedUser: any;
    isUser: boolean = false;
    serviceMediatorUrl: string;

    constructor(private store: Store<fromApp.AppState>,
        private http: HttpClient,
        private urlService: UrlService) {

        this.serviceMediatorUrl = this.urlService.getServiceMediatorUrl();

        let globalApplicationData$: Observable<GlobalApplicationData> = this.store.select(fromApp.getGlobalApplicationData);
        const loggedUser$ = this.store.select(fromApp.getLoggedUser);
        const applicationLang$: Observable<string> = this.store.select(fromApp.getApplicationLang);

        const combinedSelectes$ = combineLatest([globalApplicationData$, loggedUser$, applicationLang$]);
        this.result$ = combinedSelectes$.subscribe(
            ([globalApplicationData, loggedUser, applicationLang]) => {
                this.loggedUser = loggedUser;
                this.applicationData = globalApplicationData;
                if (applicationLang) {
                    moment.locale(applicationLang);
                }
            });
    }

    getSupplierByUser() {
        return this.http.post<SenecaResponse<any>>(this.serviceMediatorUrl + `get-supplier-by-user`, {
        });
    }

    // Invia ticket
    sendTicket(forename: string, surname: string,
        codFisc: string, company: string, email: string, phoneNumber: string, reportType: string,
        reportObject: string, description: string): any {

        return this.http.post<SenecaResponse<any>>(
            this.serviceMediatorUrl + 'send-ticket-for-entitled',
            {
                forename,
                surname,
                codFisc,
                company,
                email,
                phoneNumber,
                reportType,
                reportObject,
                description
            }
        );
    }
}

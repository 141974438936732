<div class="page-content-wrapper" fxLayout="column">

  <!-- Campagna x -->
  <h1 fxFlexOffset="40px" class="primary">
    {{ campaign ? campaign.title : ('admin.campaignRoundVenueDetails.TITLE_LOADING' | translate) }}
  </h1>

  <bread-crumbs fxFlexOffset="12px" firstLevel="ADMIN_CAMPAIGNS" (secondLevelClick)="goBackToRounds()"
    [secondLevel]="campaign ? campaign.title : undefined" [thirdLevel]="round ?
      ((round.startDate | date: 'dd MMMM yyyy' | titlecase) + ' - ' + (round.endDate | date: 'dd MMMM yyyy' | titlecase)) :
      undefined">
  </bread-crumbs>

  <div fxFlexOffset="56px" fxLayout="column" fxLayoutAlign="start center">

    <!-- Resume round -->
    <div class="campaign-round-resume" fxLayout="row" fxLayoutAlign="space-between">
      <svg-icon class="note" [applyClass]="true" src="assets/img/icons/note-clients.svg"></svg-icon>

      <div fxFlexOffset="80px" fxFlex fxLayout="row">
        <ng-container *ngIf="round">
          <div fxLayout="column" fxFlex="70">
            <!-- Round -->
            <h5 class="bold" translate="admin.campaignRoundVenueDetails.ROUND"></h5>
            <!-- Prenotazioni -->
            <h5 fxFlexOffset="8px" translate="admin.campaignRoundVenueDetails.BOOKINGS"></h5>
            <h5>
              {{ (round.minReservationEndDate | date: 'dd MMMM yyyy' | titlecase) || ('generic.TO_SET' | translate) }}
              {{ ' - ' }}
              {{ (round.maxReservationEndDate | date: 'dd MMMM yyyy' | titlecase) || ('generic.TO_SET' | translate) }}
            </h5>
            <!-- Visibilità slot -->
            <h5 fxFlexOffset="8px" translate="admin.campaignRoundVenueDetails.VISIBILITIES"></h5>
            <h5>
              {{ round.startDate | date: 'dd MMMM yyyy' | titlecase }}
              {{ ' - ' }}
              {{ round.endDate | date: 'dd MMMM yyyy' | titlecase }}
            </h5>
          </div>
          <div fxLayout="column" fxFlex="30">
            <!-- Stato -->
            <h5 class="bold" translate="admin.campaignRoundVenueDetails.STATE"></h5>
            <h5 translate="admin.campaignRoundVenueDetails.{{activeRound ? '' : 'NOT_'}}ACTIVE"></h5>
          </div>
        </ng-container>
        <div *ngIf="!round" class="loader spinner-small"></div>
      </div>

      <div fxLayout="row" fxLayoutAlign="start end">
        <!-- Modifica round -->
        <welion-button *ngIf="!isLimitedAdmin" width="112px" size="medium" type="primary"
          [label]="'generic.EDIT' | translate | uppercase" (onClick)="editRound()" [disabled]="isLoadingBase">
        </welion-button>
      </div>

    </div>

    <!-- Resume struttura/sede -->
    <div fxFlexOffset="48px" class="campaign-round-resume venue" fxLayout="column">
      <div fxLayout="row" fxLayoutAlign="space-between">
        <svg-icon class="note" [applyClass]="true" src="assets/img/icons/note-health-facility-yellow.svg"></svg-icon>

        <div fxFlexOffset="80px" fxFlex fxLayout="column">
          <ng-container *ngIf="venue">
            <!-- Struttura/Sede -->
            <h5 class="bold" translate="admin.campaignRoundVenueDetails.VENUE"></h5>
            <h5>{{ venue.name}}</h5>
          </ng-container>
          <div *ngIf="!venue" class="loader spinner-small"></div>
        </div>

        <div fxLayout="column">
          <!-- Vedi Scheda -->
          <welion-button width="188px" size="medium" type="primary" (onClick)="openVenueCard()"
            [label]="'admin.campaignRoundVenueDetails.SHOW_FORM' | translate" [disabled]="isLoadingBase">
          </welion-button>
          <!-- Rimuovi da round -->
          <welion-button *ngIf="!isLimitedAdmin" fxFlexOffset="4px" width="188px" size="medium" type="primary"
            (onClick)="openRemoveVenueFromRoundModal()" [disabled]="isLoadingBase"
            [label]="'admin.campaignRoundVenueDetails.REMOVE_FROM_ROUND' | translate">
          </welion-button>
        </div>
      </div>

      <div fxLayout="row" fxFlexOffset="8px">
        <div fxFlexOffset="80px" fxLayout="row">
          <ng-container *ngIf="round && venue">
            <!-- SLOT OFFERTI / PRENOTATI -->
            <div fxLayout="column">
              <h3>{{ (venue.offeredSlotsCount || 0) + '/' + (venue.bookings || 0) }}</h3>
              <h6 class="black" translate="admin.campaignRoundVenueDetails.OFFERED_SLOTS"></h6>
            </div>
            <!-- PERSONE PRENOTATE / PRESENTI -->
            <div fxLayout="column" fxFlexOffset="20px">
              <h3>{{ (venue.bookings || 0) + '/' + (venue.presents || 0) }}</h3>
              <h6 class="black" translate="admin.campaignRoundVenueDetails.PEOPLE_BOOKED"></h6>
            </div>
            <!-- AVENTI DIRITTO TOTALI / LAVORATI -->
            <div fxLayout="column" fxFlexOffset="20px">
              <h3>{{ (venue.reportedHeads || 0) + '/' + (venue.reportableHeads || 0) }}</h3>
              <h6 class="black" translate="admin.campaignRoundVenueDetails.TOTAL_ENTITLEDS"></h6>
            </div>
          </ng-container>
        </div>
      </div>

    </div>
  </div>

  <!-- Ancoraggio per lo scroll -->
  <div id="round-structure-anchor" fxFlexOffset="16px" fxFlexOffset.xs="12px"></div>

  <div fxFlexOffset="56px" class="campaign-round-venue-details-container" fxLayout="column">

    <div fxLayout="row" fxLayoutAlign="space-between">
      <!-- Mostra richieste -->
      <welion-button *ngIf="!isLimitedAdmin" width="220px" size="medium" type="secondary" (onClick)="goToRequestList()"
        [label]="'admin.campaignRoundVenueDetails.SHOW_REQUESTS' | translate: { value: requestsCount }"
        [disabled]="requestsCount < 1">
      </welion-button>

      <!-- Div solo per allineare il pulsante "lista sedi" a sinistra -->
      <div *ngIf="isLimitedAdmin" fxLayout="row">
      </div>

      <div fxLayout="row">
        <!-- Lista sedi -->
        <welion-button hasLeftIcon width="160px" size="medium" type="primary" (onClick)="goBackToVenues()"
          [label]="'admin.campaignRoundVenueDetails.VENUE_LIST' | translate" [disabled]="isLoadingBase">
        </welion-button>
      </div>
    </div>

    <!-- Loader -->
    <div *ngIf="isLoadingBase" class="loader spinner-big"></div>

    <ng-container *ngIf="!isLoadingBase">
      <ng-container *ngIf="structureDaySlots.length; else noDays">

        <div fxFlexOffset="40px" fxLayout="row" fxLayout.xs="column" fxLayoutAlign="space-between center">

          <!-- Indicazioni -->
          <div fxFlex fxLayout="row" fxLayoutAlign="start center">

            <!-- Indicazioni visive -->
            <div fxLayout="row" fxLayoutAlign="end" class="full-width">

              <!-- Approvato -->
              <ng-container [ngTemplateOutlet]="roundStatus" [ngTemplateOutletContext]="{
                noOffset: true,
                label: 'AVAILABLE',
                color: 'green'
              }"></ng-container>

              <!-- Occupato -->
              <ng-container [ngTemplateOutlet]="roundStatus" [ngTemplateOutletContext]="{
                label: 'BUSY',
                color: 'red'
              }"></ng-container>

              <!-- In approvazione -->
              <ng-container [ngTemplateOutlet]="roundStatus" [ngTemplateOutletContext]="{
                label: 'PENDING'
              }"></ng-container>

              <!-- In revoca -->
              <ng-container [ngTemplateOutlet]="roundStatus" [ngTemplateOutletContext]="{
                label: 'REVOKING',
                color: 'orange'
              }"></ng-container>

              <!-- Revocato -->
              <ng-container [ngTemplateOutlet]="roundStatus" [ngTemplateOutletContext]="{
                label: 'REVOKED',
                color: 'revoked'
              }"></ng-container>

            </div>

            <!-- Informazioni -->
            <svg-icon fxFlexOffset="40px" fxFlexOffset.xs="20px" class="cursor-pointer medium-size" [applyClass]="true"
              (click)="showHelper()" src="assets/img/icons/question-mark-outline.svg">
            </svg-icon>

          </div>

          <!-- Select mobile -->
          <div fxHide fxShow.xs fxFlex="232px" fxFlex.xs="100" fxFlexOffset.xs="16px" fxLayout="row" fxLayoutAlign="end"
            fxLayoutAlign.xs="space-between" ngClass.xs="full-width">

            <div fxHide fxShow.xs class="full-width">
              <!-- Lista giorni modalità mobile -->
              <welion-select [items]="structureDaySlotsDropdown" [selectedItem]="selectedStructureDaySlotsDropdown"
                (change)="onSelectDaySlotMobile($event)">
              </welion-select>
            </div>
          </div>
        </div>

        <div fxFlexOffset="32px" fxLayout="row">

          <!-- Lista giorni - nascosto in modalità mobile -->
          <div fxShow fxHide.xs fxLayout="column" fxLayoutAlign="start start" class="day-slots-cards">
            <div *ngFor="let daySlot of structureDaySlots; let first = first; let index = index"
              [fxFlexOffset]="first ? '0px' : '4px'"
              [ngClass]="{'selected-day': index === indexSelectedStructureDaySlots}" class="day-slots"
              (click)="onSelectDaySlot(index)">
              <!-- Lunedì 1 Marzo -->
              <h5>{{ daySlot.date | date: 'EEEE dd MMMM' | titlecase }}</h5>
            </div>
          </div>

          <!-- Lista slots -->
          <div fxFlexOffset="40px" fxFlexOffset.xs="0px" fxLayout="column" class="slot-list full-width">

            <div fxLayout="row">
              <!-- SLOT OFFERTI / PRENOTATI -->
              <div fxLayout="column">
                <h4>{{ selectedDayVenue ? ((selectedDayVenue.offeredSlotsCount || 0) + '/' +
                  (selectedDayVenue.bookings || 0)) : '0/0' }}</h4>
                <h6 class="small black" translate="admin.campaignRoundVenueDetails.OFFERED_SLOTS"></h6>
              </div>
              <!-- PERSONE PRENOTATE / PRESENTI -->
              <div fxLayout="column" fxFlexOffset="12px">
                <h4>{{ selectedDayVenue ? ((selectedDayVenue.bookings || 0) + '/' + (selectedDayVenue.presents || 0)) :
                  '0/0' }}</h4>
                <h6 class="small black" translate="admin.campaignRoundVenueDetails.PEOPLE_BOOKED"></h6>
              </div>
              <!-- AVENTI DIRITTO TOTALI / LAVORATI -->
              <div fxLayout="column" fxFlexOffset="12px">
                <h4>{{ selectedDayVenue ? ((selectedDayVenue.reportedHeads || 0) + '/' +
                  (selectedDayVenue.reportableHeads || 0)) : '0/0' }}</h4>
                <h6 class="small black" translate="admin.campaignRoundVenueDetails.TOTAL_ENTITLEDS"></h6>
              </div>
            </div>

            <!-- Slots -->
            <div fxFlexOffset="4px" fxLayout="row wrap" class="tile-container" infiniteScroll
              [infiniteScrollDistance]="2" [infiniteScrollThrottle]="300" (scrolled)="getDaySlots()">

              <slot-component-tile *ngFor="let slot of slots; let index = index"
                [fromReservationDeletion]="slot.fromReservationDeletion" fxFlexOffset="8px" fxFlexOffset.xs="4px"
                [slot]="slot" [status]="slot.status" [selected]="slotIsSelected(slot)" [ngClass]="{
                  'cursor-pointer': (!isRevokingSlots &&  slot.status === 'REVOKED') ||
                  (!isResumingSlots && slot.status === 'APPROVED'), 'no-pointer': isLimitedAdmin
                }" (click)="selectSlotClick(index)">>
              </slot-component-tile>

              <ng-container *ngIf="!isLoading && !slots.length" [ngTemplateOutlet]="noSlots">
              </ng-container>

            </div>

            <ng-container *ngIf="isLoading">
              <div fxFlex class="loader spinner-medium"></div>
            </ng-container>

            <!-- Revoca immadiata / Ripristina -->
            <div *ngIf="!isLimitedAdmin && (isResumingSlots || isRevokingSlots)" fxFlexOffset="32px" fxLayout="row"
              fxLayoutAlign="end" fxLayoutAlign.xs="center">
              <!-- Revoca immediata -->
              <welion-button *ngIf="isRevokingSlots" fxFlex="232px" (onClick)="openRevokeSlotsModal()"
                label="admin.campaignRoundVenueDetails.slots.IMMEDIATE_REVOKE_SLOTS" type="primary bordless"
                size="medium">
              </welion-button>
              <!-- Ripristina -->
              <welion-button *ngIf="isResumingSlots" fxFlex="232px" (onClick)="resumeSlots()"
                label="admin.campaignRoundVenueDetails.slots.RESUME_SLOTS" type="primary bordless" size="medium">
              </welion-button>
            </div>
          </div>
          <!-- Margine a lato -->
          <div fxFlexOffset="20px" fxFlexOffset.xs="0px"></div>

        </div>
      </ng-container>
    </ng-container>

  </div>

</div>

<!-- Sidenav con la scheda di dettaglio della sede -->
<sidebar showAppHeader hideCloseButton [sidenavTemplateRef]="navContent" direction="right" [navWidth]="664"
  [duration]="0.3">
</sidebar>

<!-- Contenuto sidenav -->
<ng-template #navContent>

  <div class="venue-card-sidenav" fxLayout="column">

    <div class="full-width" fxLayout="row" fxLayoutAlign="end">
      <!-- Icona chiudi -->
      <div fxLayoutAlign="row" fxLayoutAlign="center center" (click)="closeVenueCard()" class="close-container">
        <svg-icon class="small-size" src="/assets/img/icons/x.svg" [applyClass]="true"></svg-icon>
        <h5 fxFlexOffset="8px" class="white">{{ 'generic.CLOSE' | translate | uppercase}}</h5>
      </div>
    </div>

    <ng-container *ngIf="venue">
      <!-- Scheda struttura -->
      <h5 fxFlexOffset="20px" class="bold" translate="admin.campaignRoundVenueDetails.venueCard.TITLE"></h5>

      <h3 fxFlexOffset="16px">{{ venue.name }}</h3>

      <div fxFlexOffset="16px" class="venue-card-sidenav-divider"></div>

      <div fxFlexOffset="32px" class="venue-card-tabs" fxLayout="row">
        <!-- Mappa -->
        <tab-light [tabTitle]="'admin.campaignRoundVenueDetails.venueCard.tab.MAP' | translate"
          [isCurrentTab]="selectedVenueTab === 0"
          [attrAriaLabel]="'admin.campaignRoundVenueDetails.venueCard.tab.MAP' | translate"
          (onClick)="onVenueTabClick(0)">
        </tab-light>

        <!-- Dettagli -->
        <tab-light fxFlexOffset="32px" [tabTitle]="'admin.campaignRoundVenueDetails.venueCard.tab.DETAILS' | translate"
          [isCurrentTab]="selectedVenueTab === 1"
          [attrAriaLabel]="'admin.campaignRoundVenueDetails.venueCard.tab.DETAILS' | translate"
          (onClick)="onVenueTabClick(1)">
        </tab-light>
      </div>

      <ng-container *ngIf="selectedVenueTab === 0">

        <!-- Indirizzo struttura -->
        <h5 fxFlexOffset="28px">{{ venue.address }}</h5>

        <!-- Mappa google -->
        <agm-map fxFlexOffset="8px" *ngIf="venueMapData && venueMapData.lat && venueMapData.lng" class="map"
          [latitude]="venueMapData.lat" [longitude]="venueMapData.lng" [disableDefaultUI]="false" [zoomControl]="false">
          <agm-marker [latitude]="venueMapData.lat" [longitude]="venueMapData.lng" label="A" [markerDraggable]="false">
          </agm-marker>
        </agm-map>

      </ng-container>

      <!-- Dettagli -->
      <ng-container *ngIf="selectedVenueTab === 1">
        <!-- La sede ... è facilmente .. -->
        <h5 fxFlexOffset="28px">{{ 'admin.campaignRoundVenueDetails.venueCard.tab.DETAILS_INFO' | translate:
          { name: venue.name } }}</h5>

        <!-- Indirizzo -->
        <h3 class="small bold" fxFlexOffset="24px"
          translate="admin.campaignRoundVenueDetails.venueCard.tab.DETAILS_ADDRESS"></h3>
        <!-- Nome legale -->
        <h5 fxFlexOffset="4px" *ngIf="venue.name">{{ venue.name }}</h5>
        <!-- Indirizzo sede -->
        <h5 fxFlexOffset="4px">{{ venue.address }}</h5>

        <div fxFlexOffset="28px" class="junior-form" fxLayout="column"
          *ngIf="venue.validMinorTypes && venue.validMinorTypes.length && venue.validMinorTypes[0].age < 18">
          <!-- Gestione del vaccino (nel caso di minori) -->
          <h5 class="bold" translate="addReservation.steps.side.MANAGEMENT"></h5>

          <div *ngFor="let validMinorType of venue.validMinorTypes">
            <!-- Dai {{ minAge }} ai {{ maxAge }} anni: {{ vaccinationType }} -->
            <h5 *ngIf="validMinorType.age === 12" fxFlexOffset="4px">
              {{ 'addReservation.steps.side.AGE_GROUPS' | translate: {
              minAge: 12,
              maxAge: 18,
              vaccinationType: (('addReservation.steps.side.' + validMinorType.vaccinationType) | translate)
              } }}
            </h5>
            <h5 *ngIf="validMinorType.age === 9" fxFlexOffset="4px">
              {{ 'addReservation.steps.side.AGE_GROUPS' | translate: {
              minAge: 9,
              maxAge: 12,
              vaccinationType: (('addReservation.steps.side.' + validMinorType.vaccinationType) | translate)
              } }}
            </h5>
            <h5 *ngIf="validMinorType.age === 6" fxFlexOffset="4px">
              {{ 'addReservation.steps.side.AGE_GROUPS' | translate: {
              minAge: 6,
              maxAge: 9,
              vaccinationType: (('addReservation.steps.side.' + validMinorType.vaccinationType) | translate)
              } }}
            </h5>
            <!-- Sotto i 6 anni: {{ vaccinationType }} -->
            <h5 *ngIf="validMinorType.age === 5" fxFlexOffset="4px">
              {{ 'addReservation.steps.side.AGE_GROUPS_UNDER_SIX' | translate: {
              vaccinationType: (('addReservation.steps.side.' + validMinorType.vaccinationType) | translate)
              } }}
            </h5>
          </div>
        </div>

        <!-- Dettagli della struttura -->
        <div fxFlexOffset="28px" class="junior-form" fxLayout="column" *ngIf="anyNotNull()">
          <h5 class="bold" translate="addReservation.steps.side.SERVICES"></h5>

          <div class="details-list">
            <ng-container *ngFor="let key of SERVICE_KEYS">
              <ng-container *ngIf="venue.details[key]">
                <p [translate]="'- ' + (('structures.details.' + key) | translate)"></p>
              </ng-container>
            </ng-container>
          </div>

          <h5 class="margin-top10 bold" translate="addReservation.steps.side.VEHICLES"></h5>

          <div class="details-list">
            <ng-container *ngFor="let key of VEHICLE_KEYS">
              <ng-container *ngIf="venue.details[key]">
                <p [translate]="'- ' + (('structures.details.' + key) | translate)"></p>
              </ng-container>
            </ng-container>
          </div>

        </div>


      </ng-container>

    </ng-container>


    <div fxFlexOffset="20px"></div>

  </div>

</ng-template>

<!-- Placeholder nessuna giornata disponibile -->
<ng-template #noDays>
  <h6 fxFlexOffset="60px" class="black" translate="admin.campaignRoundVenueDetails.slots.NO_DATAS"></h6>
</ng-template>

<!-- Placeholder nessuno slot disponibile -->
<ng-template #noSlots>
  <div fxLayout="column" fxFlexOffset="10px">
    <h6 fxFlexOffset="40px" class="black" translate="admin.campaignRoundVenueDetails.slots.NO_SLOTS"></h6>
  </div>
</ng-template>

<!-- Template indicatore status degli slot -->
<ng-template #roundStatus let-noOffset='noOffset' let-label='label' let-color='color'>
  <div fxFlexOffset="{{noOffset ? 0 : 40}}px" fxFlexOffset.xs="{{noOffset ? 0 : 20}}px" fxLayout="row"
    fxLayoutAlign="start center">
    <div class="round-point {{color}}"></div>
    <h6 fxFlexOffset="4px" class="extra-small" translate="admin.campaignRoundVenueDetails.slots.status.{{label}}">
    </h6>
  </div>
</ng-template>

<!-- Modale per la cancellazione della campagna -->
<modal id="removeVenueFromRound" (onClose)="closeRemoveVenueFromRoundModal()"
  (onCancel)="closeRemoveVenueFromRoundModal()" (onConfirm)="confirmRemoveVenueFromRound()"
  [cancelLabel]="'generic.CLOSE' | translate | uppercase" [confirmLabel]="'generic.CONFIRM' | translate | uppercase">
  <modal-text-content *ngIf="venue && round && campaign" [withScrolledBody]="true"
    [title]="'admin.campaignRoundVenueDetails.removeVenueFromRound.TITLE' | translate"
    [subtitle]="'admin.campaignRoundVenueDetails.removeVenueFromRound.SUBTITLE' | translate" [text]="'admin.campaignRoundVenueDetails.removeVenueFromRound.RESET_WARNING' | translate:
    {
      value1: venue.name,
      value2: ((round.startDate | date: 'dd MMMM yyyy' | titlecase) + ' - ' + (round.endDate | date: 'dd MMMM yyyy' | titlecase)),
      value3: campaign.title
    }">
  </modal-text-content>
</modal>

<!-- Modale per la revoca immediata degli slot -->
<modal id="revokeSlots" (onClose)="closeRevokeSlotsModal()" (onCancel)="closeRevokeSlotsModal()"
  (onConfirm)="confirmRevokeSlots()" [cancelLabel]="'generic.CLOSE' | translate | uppercase"
  [confirmLabel]="'generic.CONFIRM' | translate | uppercase">
  <modal-text-content *ngIf="venue && round && campaign" [withScrolledBody]="true"
    [title]="'admin.campaignRoundVenueDetails.revokeSlots.TITLE' | translate"
    [subtitle]="'admin.campaignRoundVenueDetails.revokeSlots.SUBTITLE' | translate"
    [text]="'admin.campaignRoundVenueDetails.revokeSlots.RESET_WARNING' | translate">
  </modal-text-content>
</modal>
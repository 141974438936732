<main aria-label="Contenuto principale della pagina" class="page-content-wrapper home-AD" fxLayout="row" tabindex="-1">

  <div fxLayout="column" class="full-width">




    <!-- Gestisci le tue prenotazioni -->
    <h1 fxFlexOffset="40px" class="primary" translate="users.MANAGE_RESERVATIONS"></h1>

    <!-- Impersonifica -->
    <div *ngIf="isImpersonate && loggedUser" class="user-impersonate" fxFlexOffset="40px" fxLayout="row">

      <svg-icon [applyClass]="true" src="assets/img/icons/note-impersonate.svg"></svg-icon>

      <div fxFlexOffset="88px" fxLayout="column" fxLayoutAlign="center start">
        <p translate="users.IMPERSONATE" class="bold-text"></p>
        <p fxFlexOffset="4px">
          <span translate="users.IMPERSONATE_DESC"></span>
          <span class="name">{{ loggedUser.forename + ' ' + loggedUser.surname }}</span>
        </p>
      </div>

    </div>

    <ng-container *ngIf="loggedUser && loggedUser.forename">
      <!-- Benvenuto Simone! -->
      <h2 class="small bold" fxFlexOffset="24px">
        {{ ('users.WELCOME_HI') | translate: { value: loggedUser.forename } }}
      </h2>
    </ng-container>

    <ng-container *ngIf="!loggedUser || !loggedUser.forename">
      <!-- Benvenuto! -->
      <h2 class="small bold" fxFlexOffset="24px">
        {{ ('users.HI') | translate }}
      </h2>
    </ng-container>

    <!-- Loader -->
    <div *ngIf="isLoadingCampaignList" class="loader spinner-big"></div>

    <ng-container *ngIf="!isLoadingCampaignList && reservationEntitledData">

      <div *ngFor="let infoTicket of infoTickets; let first = first" fxLayout="column">
        <!-- Hai tempo fino al .. -->
        <div class="reservation-limit" [fxFlexOffset]="first ? '8px' : '32px'">
          <span translate="users.RESERVATION_LIMIT1"></span>
          <span>
            <strong>
              {{ infoTicket.round.rightMaxReservationDate| date: 'dd MMMM' }}
            </strong>
          </span>
          <span translate="users.RESERVATION_LIMIT2"></span>
          <span>
            {{ infoTicket.campaign.title + '.' }}
          </span>
          <p></p>
          <span [innerHTML]="'users.RESERVATION_LIMIT6' | translate"></span>
          <p></p>
          <span [innerHTML]="'generic.BOOKING_VALIDITY_NOTICE' | translate"></span>
          <p class="margin-bottom20"></p>
          <span translate="users.RESERVATION_LIMIT4"></span>
          <span>
            <strong>
              {{ infoTicket.round.startDate| date: 'dd MMMM' }}
            </strong>
          </span>
          <span translate="users.RESERVATION_LIMIT5"></span>
          <span>
            <strong>
              {{ infoTicket.round.endDate | date: 'dd MMMM yyyy' }}</strong><span>. </span>
          </span>
        </div>
        <!-- a causa degli eventi atmosferici eccezionali che hanno interessato la Regione Toscana... -->

        <div fxFlexOffset="24px" fxLayout="column" fxLayoutAlign="start center">

          <div class="round-campaign-container" fxLayout="column">

            <!-- Prenotazioni attive -->
            <ng-container *ngIf="infoTicket.round.entitledToProcess && infoTicket.round.entitledToProcess.length">
              <h3>{{ 'users.VACCINATION_ACTIVE' | translate: { value: infoTicket.campaign.title } }}</h3>

              <!-- Prenota per tutta la famiglia -->
              <div class="family-reservation-wrapper" *ngIf="canBookForFamily(infoTicket.round.entitledToProcess)">
                <welion-button class="rounded" label="primary" type="primary" size="large" width="196px"
                  label="users.FAMILY_RES_ACTION"
                  (click)="openFamilyReservationModal(infoTicket.campaign, infoTicket.round)">
                </welion-button>
                <div class="divisor">
                  <div class="line"></div>
                </div>
                <p class="description" translate="users.FAMILY_RES_DESCR"></p>
              </div>

              <div fxLayout="row wrap" class="card-wrapper-ad" fxLayout.lt-md="column"
                fxLayoutAlign.lt-md="start center">
                <div class="campaign-ticket" [fxFlexOffset]="even ? '0px' : '32px'"
                  [fxFlexOffset.lt-md]="even ? '16px' : '32px'"
                  *ngFor="let entitled of infoTicket.round.entitledToProcess; index as i;let even = even">
                  <!-- Appuntamenti -->
                  <ng-container *ngIf="entitled && entitled.attendances && entitled.attendances.length">
                    <card-ticket *ngFor="let attendance of entitled.attendances" [entitled]="entitled"
                      [forceDisableActions]="attendance.forceDisable" [attendance]="attendance"
                      [withReservationId]="true" (onCancelTicket)="removeTicket(attendance)" [round]="infoTicket.round"
                      (onEditTicket)="editTicket(attendance)"
                      (onOpenVenue)="openVenue(entitled,'card-ticket-button-open-' + i)" [manageBooking]="true"
                      [dropdownOptions]="attendance.dropdownOptions" [id]="'card-ticket-button-open-'+i">
                    </card-ticket>
                  </ng-container>
                  <!-- Da prenotare -->
                  <ng-container
                    *ngIf="!entitled.isFamiliarToAdd && (!entitled || !entitled.attendances || entitled.attendances.length === 0)">
                    <card-ticket [entitled]="entitled" (onOpenEditData)="openEditDataModal()"
                      (onBookingTicket)="goToNewReservation(infoTicket.campaign, infoTicket.round, entitled)"
                      [round]="infoTicket.round" [supplierName]="supplierName">
                    </card-ticket>
                  </ng-container>
                  <ng-container *ngIf="entitled.isFamiliarToAdd">
                    <card-ticket [familiarsTypeToChoose]="entitled.familiarsTypeToChoose" [isFamiliarToAdd]="true"
                      (onGoToAddFamiliar)="goToAddFamiliar(infoTicket)">
                    </card-ticket>
                  </ng-container>
                </div>
              </div>
            </ng-container>

            <!-- Prenotazioni chiuse -->
            <ng-container
              *ngIf="infoTicket.round.entitledToProcessExpired && infoTicket.round.entitledToProcessExpired.length">
              <h3>{{ 'users.VACCINATION_CLOSED' | translate: { value: infoTicket.campaign.title } }}</h3>

              <div fxLayout="row wrap" class="card-wrapper-ad" fxLayout.lt-md="column"
                fxLayoutAlign.lt-md="start center">
                <div class="campaign-ticket" [fxFlexOffset]="even ? '0px' : '32px'"
                  [fxFlexOffset.lt-md]="even ? '16px' : '32px'"
                  *ngFor="let entitled of infoTicket.round.entitledToProcessExpired; let even = even">

                  <!-- Appuntamenti -->
                  <ng-container *ngIf="entitled && entitled.attendances && entitled.attendances.length">
                    <card-ticket *ngFor="let attendance of entitled.attendances;index as i" [entitled]="entitled"
                      [attendance]="attendance" [withReservationId]="true" (onCancelTicket)="removeTicket(attendance)"
                      [forceDisableActions]="attendance.forceDisable" [round]="infoTicket.round"
                      (onEditTicket)="editTicket(attendance)"
                      (onOpenVenue)="openVenue(entitled,'card-ticket-button-closed' + i )" [manageBooking]="true"
                      [dropdownOptions]="attendance.dropdownOptions" [vaccinationClosed]="true"
                      [id]="'card-ticket-button-closed-'+i">
                    </card-ticket>
                  </ng-container>
                  <!-- Da prenotare -->
                  <ng-container *ngIf="!entitled || !entitled.attendances || entitled.attendances.length === 0">
                    <card-ticket [entitled]="entitled"
                      (onBookingTicket)="goToNewReservation(infoTicket.campaign, infoTicket.round, entitled)"
                      [round]="infoTicket.round" [supplierName]="supplierName" [manageBooking]="true"
                      [vaccinationClosed]="true">
                    </card-ticket>
                  </ng-container>
                </div>
              </div>
            </ng-container>

            <!-- Prenotazioni passate -->
            <ng-container *ngIf="infoTicket.round.entitledProcessed && infoTicket.round.entitledProcessed.length">
              <h3
                [fxFlexOffset]="infoTicket.round.entitledToProcess && infoTicket.round.entitledToProcess.length ? '32px' : ''">
                {{ 'users.VACCINATION_EXPIRED' | translate: { value: infoTicket.campaign.title } }}</h3>

              <div fxLayout="row wrap" class="card-wrapper-ad" fxLayout.lt-md="column"
                fxLayoutAlign.lt-md="start center">
                <div class="campaign-ticket" [fxFlexOffset]="even ? '0px' : '32px'"
                  [fxFlexOffset.lt-md]="even ? '16px' : '32px'"
                  *ngFor="let entitled of infoTicket.round.entitledProcessed;index as i; let even = even">

                  <!-- Appuntamenti -->
                  <ng-container *ngIf="entitled && entitled.attendances && entitled.attendances.length">
                    <card-ticket *ngFor="let attendance of entitled.attendances" [entitled]="entitled"
                      [forceDisableActions]="attendance.forceDisable" [attendance]="attendance"
                      [withReservationId]="true" (onCancelTicket)="removeTicket(attendance)" [round]="infoTicket.round"
                      (onEditTicket)="editTicket(attendance)"
                      (onOpenVenue)="openVenue(entitled,'card-ticket-button-passsed-' + i)" [manageBooking]="true"
                      [dropdownOptions]="attendance.dropdownOptions" [id]="'card-ticket-button-passed-'+i">
                    </card-ticket>
                  </ng-container>
                  <!-- Da prenotare -->
                  <ng-container *ngIf="!entitled || !entitled.attendances || entitled.attendances.length === 0">
                    <card-ticket [entitled]="entitled"
                      (onBookingTicket)="goToNewReservation(infoTicket.campaign, infoTicket.round, entitled)"
                      [round]="infoTicket.round" [manageBooking]="true">
                    </card-ticket>
                  </ng-container>
                </div>
              </div>
            </ng-container>

          </div>

        </div>
      </div>

    </ng-container>

    <div fxFlexOffset="120px" fxLayout="row" fxLayoutAlign="center">

      <div *ngIf="isEnel" class="faq-helper" [ngClass]="{'is-in-column': isEnel}">
        <svg-icon src="assets/img/icons/faq-help.svg" [applyClass]="true"></svg-icon>

        <!-- Serve aiuto? Contatta l’help desk Enel al numero 800.069.629 -->
        <p class="full-width small">
          <span translate="generic.FAQ_ENEL"></span>
          <span class="primary bold" translate="generic.FAQ_ENEL_CONTACT_NUMBER"></span>
        </p>

        <p class="full-width small margin-top15">
          <span class="italic-text font-size18" [innerHTML]="'generic.FAQ_ENEL_HOME_NEW' | translate"></span>
          <a translate="generic.HERE" class="font-size18 italic-text" role="button" aria-label="link"
            onClick="window.open('/assets/documents/RCP_Influvac_S_Tetra.pdf', '_blank')"></a>
          <span class="italic-text font-size18" [innerHTML]="'generic.FAQ_ENEL_HOME_NEW_2' | translate"></span>
        </p>

        <p class="full-width small margin-top10">
          <span class="italic-text font-size14" translate="generic.FAQ_ENEL_HOME_NEW_3"></span>
        </p>

      </div>
    </div>

  </div>

  <!-- Serve aiuto? Consulta le FAQ -->
  <div fxLayout="row" class="faq-support-container-fixed" fxLayoutAlign="center center">
    <div tabindex="0" fxLayoutAlign="center center" fxLayout="column" class="faq-support-container" role="button"
      attr.aria-label="{{'generic.FAQ_ARIA_SUPPORT' | translate}}" (click)="goToFAQ()" clickKeyBoard
      (onEnterKeyboard)="goToFAQ()">
      <svg-icon src="assets/img/icons/faq-support.svg"></svg-icon>
      <p *ngIf="!isEnel" [innerHTML]="'generic.FAQ_SUPPORT' | translate"></p>
      <p *ngIf="isEnel" [innerHTML]="'generic.SUPPORT' | translate"></p>
    </div>
  </div>
</main>

<!-- Sidenav con la scheda di dettaglio della sede -->
<sidebar [id]="'sidebar-nav-detail'" showAppHeader hideCloseButton [sidenavTemplateRef]="navContent" direction="right"
  [navWidth]="664" [duration]="0.3">
</sidebar>



<!-- Contenuto sidenav -->
<ng-template #navContent>

  <div class="venue-card-sidenav" fxLayout="column" clickKeyBoard (onEscKeyboard)="closeVenueCard(true)">

    <div class="full-width" fxLayout="row" fxLayoutAlign="end">
      <!-- Icona chiudi -->
      <div id="sidebar-close-button" fxLayoutAlign="row" fxLayoutAlign="center center" (click)="closeVenueCard()"
        class="close-container" tabindex="0" clickKeyBoard (onEnterKeyboard)="closeVenueCard(true)" role="button"
        attr.aria-label="{{'generic.CLOSE' | translate}}">
        <svg-icon class="small-size" src="/assets/img/icons/x.svg" [applyClass]="true"></svg-icon>
        <h5 fxFlexOffset="8px" class="white">{{ 'generic.CLOSE' | translate | uppercase}}</h5>
      </div>
    </div>

    <ng-container *ngIf="selectedVenue">
      <!-- Scheda struttura -->
      <h5 fxFlexOffset="20px" class="bold" translate="addReservation.steps.side.TITLE"></h5>

      <h3 fxFlexOffset="16px">{{ selectedVenue.name }}</h3>

      <div fxFlexOffset="16px" class="venue-card-sidenav-divider"></div>

      <div fxFlexOffset="32px" class="venue-card-tabs" fxLayout="row">
        <div role="tablist" attr.aria-label="{{'generic.NAME_TAB_ACCESSIBILITY' | translate}}">
          <!-- Mappa -->
          <tab-light [tabTitle]="'addReservation.steps.side.tab.MAP' | translate"
            [isCurrentTab]="selectedVenueTab === 0" [attrAriaLabel]="'addReservation.steps.side.tab.MAP' | translate"
            (onClick)="onVenueTabClick(0)" clickKeyBoard (onEnterKeyboard)="onVenueTabClick(0)" [tabId]="'maps'"
            [tabPanelId]="'panel-maps'">
          </tab-light>

          <!-- Dettagli -->
          <tab-light fxFlexOffset="32px" [tabTitle]="'addReservation.steps.side.tab.DETAILS' | translate"
            [isCurrentTab]="selectedVenueTab === 1"
            [attrAriaLabel]="'addReservation.steps.side.tab.DETAILS' | translate" (onClick)="onVenueTabClick(1)"
            clickKeyBoard (onEnterKeyboard)="onVenueTabClick(1)" [tabId]="'detail'" [tabPanelId]="'panel-detail'">
          </tab-light>
        </div>

      </div>


      <div *ngIf="selectedVenueTab === 0" [id]="'panel-maps'" [attr.aria-labelledby]="'maps'" role="tabpanel"
        fxLayout="column">

        <!-- Indirizzo struttura -->
        <h5 fxFlexOffset="28px">{{ selectedVenue.address }}</h5>

        <!-- Mappa google -->
        <agm-map fxFlexOffset="8px" *ngIf="venueMapData && venueMapData.lat && venueMapData.lng" class="map"
          [latitude]="venueMapData.lat" [longitude]="venueMapData.lng" [disableDefaultUI]="false" [zoomControl]="false">
          <agm-marker [latitude]="venueMapData.lat" [longitude]="venueMapData.lng" label="A" [markerDraggable]="false">
          </agm-marker>
        </agm-map>

      </div>

      <!-- Dettagli -->
      <div *ngIf="selectedVenueTab === 1" [id]="'panel-detail'" [attr.aria-labelledby]="'detail'" role="tabpanel"
        fxLayout="column">
        <!-- La sede ... è facilmente .. -->
        <h5 fxFlexOffset="28px">{{ 'addReservation.steps.side.tab.DETAILS_INFO' | translate:
          { name: selectedVenue.name} }}</h5>
        <!-- Descrizione sede -->
        <h5 fxFlexOffset="8px" *ngIf="selectedVenue.description">{{ selectedVenue.description }}</h5>

        <!-- Indirizzo -->
        <h3 class="small bold" fxFlexOffset="24px" translate="addReservation.steps.side.tab.DETAILS_ADDRESS"></h3>
        <!-- Nome legale -->
        <h5 fxFlexOffset="8px" *ngIf="selectedVenue.name">{{ selectedVenue.name }}</h5>
        <!-- Indirizzo sede -->
        <h5 fxFlexOffset="4px">{{ selectedVenue.address }}</h5>

        <div fxFlexOffset="28px" class="junior-form" fxLayout="column"
          *ngIf="selectedVenue.validMinorTypes && selectedVenue.validMinorTypes.length && selectedVenue.validMinorTypes[0].age < 18">
          <!-- Gestione del vaccino (nel caso di minori) -->
          <h5 class="bold" translate="addReservation.steps.side.MANAGEMENT"></h5>

          <div *ngFor="let validMinorType of selectedVenue.validMinorTypes">
            <!-- Dai {{ minAge }} ai {{ maxAge }} anni: {{ vaccinationType }} -->
            <h5 *ngIf="validMinorType.age === 12" fxFlexOffset="4px">
              {{ 'addReservation.steps.side.AGE_GROUPS' | translate: {
              minAge: 12,
              maxAge: 18,
              vaccinationType: (('addReservation.steps.side.' + validMinorType.vaccinationType) | translate)
              } }}
            </h5>
            <h5 *ngIf="validMinorType.age === 9" fxFlexOffset="4px">
              {{ 'addReservation.steps.side.AGE_GROUPS' | translate: {
              minAge: 9,
              maxAge: 12,
              vaccinationType: (('addReservation.steps.side.' + validMinorType.vaccinationType) | translate)
              } }}
            </h5>
            <h5 *ngIf="validMinorType.age === 6" fxFlexOffset="4px">
              {{ 'addReservation.steps.side.AGE_GROUPS' | translate: {
              minAge: 6,
              maxAge: 9,
              vaccinationType: (('addReservation.steps.side.' + validMinorType.vaccinationType) | translate)
              } }}
            </h5>
            <!-- Sotto i 6 anni: {{ vaccinationType }} -->
            <h5 *ngIf="validMinorType.age === 5" fxFlexOffset="4px">
              {{ 'addReservation.steps.side.AGE_GROUPS_UNDER_SIX' | translate: {
              vaccinationType: (('addReservation.steps.side.' + validMinorType.vaccinationType) | translate)
              } }}
            </h5>
          </div>
        </div>

        <div fxFlexOffset="28px" class="junior-form" fxLayout="column" *ngIf="anyNotNull()">
          <h5 class="bold" translate="addReservation.steps.side.SERVICES"></h5>
          <div class="details-list">
            <ng-container *ngFor="let key of SERVICE_KEYS">
              <ng-container *ngIf="selectedVenue.details[key]">
                <p [translate]="'- ' + (('structures.details.' + key) | translate)"></p>
              </ng-container>
            </ng-container>
          </div>

          <h5 class="margin-top10 bold" translate="addReservation.steps.side.VEHICLES"></h5>
          <div class="details-list">
            <ng-container *ngFor="let key of VEHICLE_KEYS">
              <ng-container *ngIf="selectedVenue.details[key]">
                <p [translate]="'- ' + (('structures.details.' + key) | translate)"></p>
              </ng-container>
            </ng-container>
          </div>


        </div>

      </div>

    </ng-container>


    <div fxFlexOffset="20px"></div>

  </div>

</ng-template>

<!-- Modale di annulla prenotazione -->
<modal id="deleteBooking" class="delete-booking-modal" (onClose)="closeDeleteBookingModal()"
  (onCancel)="closeDeleteBookingModal()" (onConfirm)="closeDeleteBookingModal(true)"
  [title]="('users.DELETE_BOOKING_TITLE' | translate)" [cancelLabel]="'generic.ANNULL' | translate | uppercase"
  [confirmLabel]="'generic.CONFIRM' | translate | uppercase">

  <div fxLayout="column" fxLayoutAlign="start center" class="full-width">
    <!-- Stai per annullare la tua prenotazione -->
    <h5 fxFlexOffset="24px" class="center margin-bottom8" [innerHTML]="'users.DELETE_BOOKING' | translate">
    </h5>
  </div>

</modal>

<!-- Modale per la conferma della privacy -->
<modal id="privacyModal" class="privacy-modal" [ngClass]="{'isUnderage': isUnderageModal}"
  (onCancel)="closePrivacyModal()" (onClose)="closePrivacyModal()"
  [title]="!isUnderageModal ? ('addReservation.steps.privacy.TITLE_ADULT' | translate) : ('addReservation.steps.privacy.TITLE_UNDER' | translate)"
  [titleClass]="'small'">

  <!-- Prenotazione maggiorenne -->
  <ng-container *ngIf="!isUnderageModal">

    <div fxFlexOffset="24px">
      <span>
        In qualità di <strong>Dipendente dell’Impresa e parente, affine, coniuge, convivente more uxorio del
          maggiorenne</strong> che ho selezionato appartenente al mio nucleo familiare, consapevole delle sanzioni
        penali
        in caso di dichiarazioni mendaci di cui agli articoli 75 e 76 del D.P.R. 445/2000
      </span>
      <p></p>
      <p class="margin-bottom16"></p>
      <span>
        <strong>DICHIARO</strong> di avergli sottoposto l'</span>
      <span class="link" (click)="goToPrivacyPage()">Informativa sul trattamento dei dati personali</span>
      <span>
        e di averlo messo al corrente che i miei e suoi dati personali potranno essere noti all’Impresa sopra indicata e
        comunicati a terzi, qualora necessario per consentire l’erogazione dei Servizi richiesti o in nostro favore
        previsti;
      </span>
      <p></p>
      <p class="margin-bottom16"></p>
      <span>
        <strong>PRESO ATTO</strong> che per le finalità del trattamento come illustrate nell’informativa (i), (ii),
        (iii), (iv) e (v), il conferimento dei dati è obbligatorio e che gli stessi saranno trattati secondo quanto
        indicato per assolvere gli adempimenti correlati agli accordi in essere,
      </span>
      <p></p>
      <p class="margin-bottom16"></p>
      <span>
        <strong>HO OTTENUTO</strong> il suo consenso, come sotto riportato, per il trattamento dei suoi dati relativi
        alla salute e per il quale mi assumo ogni responsabilità. Resto disponibile a dimostrare, a semplice richiesta,
        di averlo informato e di aver acquisito il suo consenso come indicato
      </span>
      <p></p>
      <p class="margin-bottom16"></p>
      <span>
        <strong>CON RIFERIMENTO AL TRATTAMENTO DEI DATI PERSONALI PER L’EROGAZIONE DEL SERVIZIO</strong>
      </span>
    </div>
    <welion-check [attrAriaLabel]="'ESPRIMO il suo consenso al trattamento dei dati personali, appartenenti alle categorie
      particolari dati personali, tra cui quelli relativi alla salute, per le finalità del trattamento illustrate
      nell’informativa privacy sub (i), (ii), (iii), (iv) e (v) per quanto necessario all’erogazione dei Servizi
      richiesti o previsti.'" [checked]="privacyCheck" fxFlexOffset="24px" (onCheck)="onCheckPrivacy($event)">
      <ng-template #customLabel>
        <p class="check">
          <span>
            <strong>ESPRIMO</strong> il suo consenso al trattamento dei dati personali, appartenenti alle categorie
            particolari dati personali, tra cui quelli relativi alla salute, per le finalità del trattamento illustrate
            nell’informativa privacy sub (i), (ii), (iii), (iv) e (v) per quanto necessario all’erogazione dei Servizi
            richiesti o previsti.
          </span>
        </p>
      </ng-template>
    </welion-check>
  </ng-container>

  <!-- Prenotazione minorenne -->
  <ng-container *ngIf="isUnderageModal">

    <div fxFlexOffset="24px">
      <span>
        In qualità di <strong>Dipendente dell’impresa titolare della potestà genitoriale nei confronti del
          minore</strong> che ho selezionato
      </span>
      <p></p>
      <p class="margin-bottom16"></p>
      <span>
        <strong>DICHIARO</strong>, di aver preso visione dell’</span>
      <span class="link" (click)="goToPrivacyPage()" tabindex="0">Informativa sul trattamento dei dati personali</span>
      <span>
        e di essere consapevole che i miei e i suoi dati personali potranno essere noti all’Impresa sopra indicata e
        comunicati a terzi qualora necessario per consentire l’erogazione dei Servizi richiesti o in nostro favore
        previsti;
      </span>
      <p></p>
      <p class="margin-bottom16"></p>
      <span>
        <strong>PRENDO ATTO</strong>, che per le finalità del trattamento come illustrate nell’informativa (i), (ii),
        (iii), (iv) e (v), il conferimento dei dati è obbligatorio e che gli stessi saranno trattati secondo quanto
        indicato per assolvere gli adempimenti correlati agli accordi in essere
      </span>
      <p></p>
      <p class="margin-bottom16"></p>
      <span>
        <strong>CON RIFERIMENTO AL TRATTAMENTO DEI DATI PERSONALI PER L’EROGAZIONE DEL SERVIZIO</strong>
      </span>
    </div>
    <welion-check [attrAriaLabel]="'ESPRIMO il mio consenso al trattamento dei suoi dati personali appartenenti alle categorie
      particolari dati personali, tra cui quelli relativi alla salute, per le finalità del trattamento illustrate
      nell’informativa privacy sub (i), (ii), (iii), (iv) e (v) per quanto necessario all’erogazione dei Servizi
      richiesti o previsti'" [checked]="privacyCheck" fxFlexOffset="24px" (onCheck)="onCheckPrivacy($event)">
      <ng-template #customLabel>
        <p class="check">
          <span>
            <strong>ESPRIMO</strong> il mio consenso al trattamento dei suoi dati personali appartenenti alle categorie
            particolari dati personali, tra cui quelli relativi alla salute, per le finalità del trattamento illustrate
            nell’informativa privacy sub (i), (ii), (iii), (iv) e (v) per quanto necessario all’erogazione dei Servizi
            richiesti o previsti
          </span>
        </p>
      </ng-template>
    </welion-check>
  </ng-container>

  <div class="full-width" fxFlexOffset="24px" fxLayout="row" fxLayoutAlign="center">
    <!-- Chiudi -->
    <welion-button fxFlex label="generic.CLOSE" type="third" size="medium" buttonType="button"
      (onClick)="closePrivacyModal()">
    </welion-button>
    <!-- Conferma -->
    <welion-button [disabled]="!privacyCheck" fxFlexOffset="8px" fxFlex label="generic.CONFIRM" type="primary"
      size="medium" (onClick)="closePrivacyModal(true)">
    </welion-button>
  </div>

</modal>

<!-- Modale di modifica dati familiare -->
<modal id="editDataModal" class="delete-booking-modal" (onClose)="closeEditDataModal()"
  (onCancel)="closeEditDataModal()" (onConfirm)="closeEditDataModal()"
  [confirmLabel]="'generic.CLOSE' | translate | uppercase" [title]="'generic.EDIT_DATA' | translate"
  [titleClass]="'bold-text'">

  <div fxLayout="column" fxLayoutAlign="start center" class="full-width">
    <h5 fxFlexOffset="24px" class="center margin-bottom8" [innerHTML]="'users.EDIT_DATA_FAMILIAR_DESC' | translate">
    </h5>
  </div>

</modal>


<!-- Modale di avviso se utente eliminato -->
<modal id="user-deleted" (onClose)="modalService.close('user-deleted')" (onConfirm)="modalService.close('user-deleted')"
  (onCancel)="modalService.close('user-deleted')" [confirmLabel]="'generic.CONFIRM' | translate"
  [titleClass]="'bold-text'">

  <div class="modal-title-wrapper" fxLayout="column" fxLayoutAlign="start center">
    <h2 fxFlexOffset="24px" class="primary small bold center" translate="generic.WARNING"></h2>
    <p class="modal-code-wrapper">{{ 'CODICE MODALE: cr002'}}</p>
  </div>

  <div class="modal-text-wrapper">
    {{'errors.NO_LONGER_ENABLED_1' | translate}}
    <a class="link" (click)='goToTicket()'>{{'generic.HERE' | translate}}</a>
    {{'errors.NO_LONGER_ENABLED_2' | translate}}
  </div>
</modal>


<!-- Modale prenotazione massiva familiari -->
<modal id="familyReservation" class="delete-booking-modal" (onClose)="closeFamilyReservationModal()"
  (onConfirm)="closeFamilyReservationModal(true)" [confirmLabel]="'users.familyModal.TITLE' | translate | uppercase"
  (onCancel)="closeFamilyReservationModal()" [cancelLabel]="'generic.CANCEL' | translate | uppercase"
  [confirmBtnDisabled]="!selectedFamilyForReservation || !selectedFamilyForReservation.length"
  [title]="'users.familyModal.TITLE' | translate" [titleClass]="'default-modal-content-title'">
  <div class="default-modal-content">
    <h5 class="margin-top16" [innerHTML]="'users.familyModal.DESCR' | translate"></h5>
    <p class="def-modal-info" translate="users.familyModal.CHOOSE"></p>
    <!-- Checkbox selezione familiari -->
    <div class="modal-checkbox-content">
      <div *ngIf="isLoadingModalReservationList" class="loader spinner-medium"></div>
      <ng-container *ngIf="!isLoadingModalReservationList">
        <ng-container *ngFor="let person of familyListForModal">
          <div class="checkbox-row">
            <welion-check id="family-reservation-checkbox-{{person.codFisc}}"
              [attrAriaLabel]="person.forename + ' ' + person.surname"
              (onCheck)="toggleFamilyReservationCheckbox(person)" [checked]="person.isChecked">
            </welion-check>
            <label for="family-reservation-checkbox-{{person.codFisc}}">
              <b>{{ person.forename + " " + person.surname }}</b>
            </label>
          </div>
        </ng-container>
      </ng-container>
    </div>

    <div class="modal-divisor"></div>
  </div>
</modal>

<!-- Modale prenotazione massiva familiari -->
<modal id="editFamilyReservation" class="delete-booking-modal"
  (onClose)="isEditFamilyDate ? closeEditDateFamilyReservationModal() : closeEditFamilyReservationModal()"
  (onConfirm)="isEditFamilyDate ? closeEditDateFamilyReservationModal(true) : closeEditFamilyReservationModal(true)"
  [confirmLabel]="'generic.EDIT' | translate | uppercase"
  (onCancel)="isEditFamilyDate ? closeEditDateFamilyReservationModal() : closeEditFamilyReservationModal()"
  [cancelLabel]="'generic.CANCEL' | translate | uppercase"
  [confirmBtnDisabled]="!selectedFamilyForReservation || !selectedFamilyForReservation.length"
  [title]="!isEditFamilyDate ? ('component.cardTicket.manageBookingOptions.EDIT_STRUCTURE_AND_DATE' | translate): ('component.cardTicket.manageBookingOptions.EDIT_DATE' | translate)"
  [titleClass]="'default-modal-content-title'">
  <div class="default-modal-content">
    <h5 *ngIf="!isEditFamilyDate" class="full-width margin-top16"
      [innerHTML]="'users.familyModal.EDIT_DESCR' | translate"></h5>
    <h5 *ngIf="isEditFamilyDate" class="full-width margin-top16"
      [innerHTML]="'users.familyModal.EDIT_DATE_DESCR' | translate"></h5>
    <p class="def-modal-info" translate="users.familyModal.CHOOSE"></p>
    <!-- Checkbox selezione familiari -->
    <div class="modal-checkbox-content">
      <ng-container *ngFor="let personData of familyListForModal">
        <div class="checkbox-row">
          <welion-check id="edit-family-reservation-checkbox-{{personData.codFisc}}"
            [attrAriaLabel]="personData && personData.user ? (personData.user.forename + ' ' + personData.user.surname) : ''"
            (onCheck)="toggleEditFamilyReservationCheckbox(personData)" [checked]="personData.isChecked">
          </welion-check>
          <label for="edit-family-reservation-checkbox-{{personData.codFisc}}">
            <b>{{ personData && personData.user ? (personData.user.forename + " " + personData.user.surname) : '' }}</b>
          </label>
        </div>
      </ng-container>
    </div>

    <div class="modal-divisor"></div>
  </div>
</modal>


<!-- Modale annulla prenotazione massiva familiari -->
<modal id="family-reservation-delete" class="delete-booking-modal" (onClose)="closeDeleteFamilyReservation()"
  (onConfirm)="closeDeleteFamilyReservation(true)" [confirmLabel]="'users.DELETE_BOOKING_TITLE' | translate | uppercase"
  (onCancel)="closeDeleteFamilyReservation()" [cancelLabel]="'generic.CANCEL' | translate | uppercase"
  [confirmBtnDisabled]="!selectedFamilyForDelete || !selectedFamilyForDelete.length"
  [title]="'users.DELETE_BOOKING_TITLE' | translate" [titleClass]="'default-modal-content-title'">
  <div class="default-modal-content">
    <h5 class="full-width margin-top16" [innerHTML]="'users.familyModal.DELETE_DESCR' | translate"></h5>
    <!-- Checkbox selezione familiari -->
    <div class="modal-checkbox-content">
      <ng-container *ngFor="let personData of familyAttendanceToDelete">
        <div class="checkbox-row">
          <welion-check id="delete-family-reservation-checkbox-{{personData.codFisc}}"
            [attrAriaLabel]="personData.user.forename + ' ' + personData.user.surname"
            (onCheck)="toggleFamilyDeleteCheckbox(personData.user)" [checked]="personData.user.isChecked">
          </welion-check>
          <label for="delete-family-reservation-checkbox-{{personData.codFisc}}">
            <b>{{ personData.user.forename + " " + personData.user.surname }}</b>
          </label>
        </div>
      </ng-container>
    </div>
    <div class="modal-divisor"></div>
  </div>

</modal>

<modal id="family-reservation-delete-after-confirm" class="delete-booking-modal"
  (onClose)="closeDeleteFamilyReservationAfterConfirm()" (onConfirm)="closeDeleteFamilyReservationAfterConfirm()"
  [confirmLabel]="'generic.CLOSE' | translate | uppercase" [title]="'users.DELETE_BOOKING_TITLE' | translate"
  [titleClass]="'default-modal-content-title'">
  <div class="default-modal-content">
    <h5 class="full-width margin-top16" [innerHTML]="'users.familyModal.DELETE_DESCR_AFTER_CONFIRM' | translate"></h5>
  </div>
</modal>
<div class="assign-venue-to-round-container" fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="start start"
  fxLayoutAlign.lt-md="start center">

  <div class="assign-venue-to-round-side" fxLayout="column" fxLayoutAlign="space-between">

    <div fxLayout="column" fxLayoutAlign="start start">
      <div class="assign-venue-to-round-back" fxLayout="row" fxLayoutAlign="start center" fxFlexOffset="40px"
        (click)="goBack()">
        <svg-icon fxFlexOffset="40px" [applyClass]="true" src="./assets/img/icons/arrow-white-left.svg"></svg-icon>
        <h5 class="white" fxFlexOffset="12px">{{ 'generic.GO_BACK' | translate | uppercase}}</h5>
      </div>

      <p fxFlexOffset="24px">
        <ng-container *ngIf="!isLoadingBase && campaign && round">
          <span translate="admin.assignVenueToRound.SIDE_TITLE"></span>
          <span class="bold">
            {{ round.startDate | date: 'dd MMMM yyyy' | titlecase }}
            {{ ' - ' }}
            {{ round.endDate | date: 'dd MMMM yyyy' | titlecase }}
          </span>
          <span translate="admin.assignVenueToRound.SIDE_TITLE2"></span>
          <span class="bold">{{ campaign.title }}</span>
        </ng-container>
        <span *ngIf="isLoadingBase || !campaign || !round" translate="generic.LOADING"></span>
      </p>

      <div fxFlexOffset="24px" class="assign-venue-to-round-tick"></div>
    </div>

    <svg-icon fxShow fxHide.lt-md class="welion-logo" [applyClass]="true" src="./assets/img/icons/app-logo.svg">
    </svg-icon>

    <div fxShow fxHide.lt-md fxFlexOffset="40px"></div>

  </div>

  <div fxFlex fxLayout="row" fxLayout.xs="column" fxLayoutAlign="center" fxLayoutAlign.xs="start">

    <div fxFlex fxLayout="column" fxLayoutAlign="start center" class="assign-venue-to-round-body-container">

      <h3 translate="admin.assignVenueToRound.TITLE" ngClass.xs="center"></h3>

      <!-- Loader -->
      <div *ngIf="isLoadingCount" class="loader spinner-big"></div>

      <ng-container *ngIf="!isLoadingCount">
        <div *ngIf="venuesCount > 0 else noVenues" class="full-width" fxLayout="column">

          <ng-container *ngIf="!isLoading">
            <!-- Table rows -->
            <div *ngFor="let venue of venues; let index = index" class="venue-row" fxLayout="row"
              fxLayoutAlign="start center" [fxFlexOffset]="index === 0 ? '32px' : '0px'">
              <!-- Checkbox -->
              <welion-check fxFlexOffset="16px" [checked]="venueIsChecked(venue)"
                (onCheck)="onVenueChecked(venue, $event)">
              </welion-check>
              <!-- Nome struttura -->
              <h6 class="black" fxFlex fxFlexOffset="20px">{{ venue.healthFacilitySupplierName }}</h6>
              <!-- Nome sede -->
              <h6 class="black" fxFlex>{{ venue.name || 0 }}</h6>
              <div fxFlex="16px"></div>
            </div>
          </ng-container>

          <div *ngIf="isLoading" class="loader spinner-big"></div>

          <!-- Paginatore -->
          <welion-paginator fxFlexOffset="20px" [isLoading]="isLoading" [paginationLength]="paginationLength"
            [itemsCount]="venuesCount" (onClickPage)="getVenueList($event)"></welion-paginator>

          <!-- Assegna sede -->
          <div fxFlexOffset="24px" class="full-width" fxLayout="row" fxLayoutAlign="end">
            <welion-button width="264px" type="primary" size="large" label="admin.assignVenueToRound.CONFIRM"
              [disabled]="selectedVenues.length === 0 || isLoading" buttonType="submit" (onClick)="confirmAddVenues()">
            </welion-button>
          </div>

        </div>
      </ng-container>

    </div>
  </div>

</div>

<ng-template #noVenues>
  <div fxLayout="column" fxLayoutAlign="start center">
    <svg-icon fxFlexOffset="40px" [applyClass]="true" src="assets/img/icons/note-health-facility-red.svg"></svg-icon>

    <!-- Non sono presenti sedi assegnabili al round -->
    <h4 fxFlexOffset="32px" translate="admin.assignVenueToRound.NO_VENUES"></h4>

  </div>
</ng-template>

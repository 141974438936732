import { Component, EventEmitter, Input, OnInit, Output, ChangeDetectionStrategy } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'welion-text-area',
  templateUrl: 'welion-text-area.component.html',
  styleUrls: ['./welion-text-area.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class WelionTextAreaComponent implements OnInit {

  _id?: string;
  @Input() id?: string | number;

  _tabIndex: string = "0";

  _inputValue: FormControl = new FormControl();
  @Input() set inputValue(value: FormControl | undefined) {
    if (value) {
      this._inputValue = value;
    }
  };

  @Input() set bindValue(value: string | undefined) {
    if (value) {
      this._inputValue.setValue(value);
      this._inputValue.valueChanges
        .pipe(takeUntil(this._unsubscribeSignal$.asObservable()))
        .subscribe(value => {
          this.onModelChanged.emit(value);
        });
    }
  }

  get bindValue(): string | undefined {
    return this._inputValue.value;
  }

  /**
   * Works only with bindValue - otherwise the disabled need to be put inside the FormControl
   */
  @Input() set disabled(value: boolean) {
    if (value) {
      this._inputValue.disable();
    } else {
      this._inputValue.enable();
    }
  }

  @Input() placeholder: string = '';
  @Input() readonly: boolean = false;
  @Input() attrAriaLabel: string = '';
  @Input() ariaLabelledbyLabel: string = '';
  @Input() ariaLabelledbyId: string = '';
  @Input() required: boolean = false;

  @Input() set maxlength(value: number) {
    if (value !== undefined) {
      this._inputValue.setValidators(Validators.maxLength(value));
      this._inputValue.updateValueAndValidity();
    }
  }

  @Output('onChange') onModelChanged: EventEmitter<any> = new EventEmitter();

  private _unsubscribeSignal$: Subject<void> = new Subject();

  constructor() { }

  ngOnInit() {
  }

  ngOnDestroy() {
    this._unsubscribeSignal$.next();
    this._unsubscribeSignal$.unsubscribe();
  }

  emitOnModelChanged(data?: string) {
    this.onModelChanged.emit(data);
  }
}

<div class="content" fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="16px">

  <!-- #region Button section -->
  <h3 fxFlexOffset="16px" class="primary">Buttons</h3>

  <div fxLayout="row" fxLayoutGap="8px">
    <welion-button label="primary" type="primary" size="large"></welion-button>
    <welion-button label="primary" type="primary" size="large" hasLeftIcon></welion-button>
    <welion-button label="primary" type="primary" size="large" hasRightIcon></welion-button>
  </div>

  <div fxLayout="row" fxLayoutGap="8px">
    <welion-button label="primary" type="primary" size="medium"></welion-button>
    <welion-button label="primary" type="primary" size="medium" hasLeftIcon></welion-button>
    <welion-button label="primary" type="primary" size="medium" hasRightIcon></welion-button>
  </div>

  <div fxLayout="row" fxLayoutGap="8px">
    <welion-button label="secondary" type="secondary" size="large"></welion-button>
    <welion-button label="secondary" type="secondary" size="large" hasLeftIcon></welion-button>
    <welion-button label="secondary" type="secondary" size="large" hasRightIcon></welion-button>
  </div>

  <div fxLayout="row" fxLayoutGap="8px">
    <welion-button label="secondary" type="secondary" size="medium"></welion-button>
    <welion-button label="secondary" type="secondary" size="medium" hasLeftIcon></welion-button>
    <welion-button label="secondary" type="secondary" size="medium" hasRightIcon></welion-button>
  </div>

  <div fxLayout="row" fxLayoutGap="8px">
    <welion-button label="third" type="third" size="large"></welion-button>
    <welion-button label="third" type="third" size="large" hasLeftIcon></welion-button>
    <welion-button label="third" type="third" size="large" hasRightIcon></welion-button>
  </div>

  <div fxLayout="row" fxLayoutGap="8px">
    <welion-button label="third" type="third" size="medium"></welion-button>
    <welion-button label="third" type="third" size="medium" hasLeftIcon></welion-button>
    <welion-button label="third" type="third" size="medium" hasRightIcon></welion-button>
  </div>

  <h3 fxFlexOffset="16px" class="primary">Bordless buttons</h3>

  <div fxLayout="row" fxLayoutGap="8px">
    <welion-button label="primary" type="primary bordless" size="large"></welion-button>
    <welion-button label="primary" type="primary bordless" size="large" hasLeftIcon></welion-button>
    <welion-button label="primary" type="primary bordless" size="large" hasRightIcon></welion-button>
  </div>

  <div fxLayout="row" fxLayoutGap="8px">
    <welion-button label="primary" type="primary bordless" size="medium"></welion-button>
    <welion-button label="primary" type="primary bordless" size="medium" hasLeftIcon></welion-button>
    <welion-button label="primary" type="primary bordless" size="medium" hasRightIcon></welion-button>
  </div>

  <div fxLayout="row" fxLayoutGap="8px">
    <welion-button label="secondary" type="secondary bordless" size="large"></welion-button>
    <welion-button label="secondary" type="secondary bordless" size="large" hasLeftIcon></welion-button>
    <welion-button label="secondary" type="secondary bordless" size="large" hasRightIcon></welion-button>
  </div>

  <div fxLayout="row" fxLayoutGap="8px">
    <welion-button label="secondary" type="secondary bordless" size="medium"></welion-button>
    <welion-button label="secondary" type="secondary bordless" size="medium" hasLeftIcon></welion-button>
    <welion-button label="secondary" type="secondary bordless" size="medium" hasRightIcon></welion-button>
  </div>

  <h3 fxFlexOffset="16px" class="primary">Link buttons</h3>

  <div fxLayout="row" fxLayoutGap="8px">
    <welion-button label="primary" type="primary link" size="large"></welion-button>
    <welion-button label="primary" type="primary link" size="large" hasLeftIcon></welion-button>
    <welion-button label="primary" type="primary link" size="large" hasRightIcon></welion-button>
  </div>

  <div fxLayout="row" fxLayoutGap="8px">
    <welion-button label="primary" type="primary link" size="medium"></welion-button>
    <welion-button label="primary" type="primary link" size="medium" hasLeftIcon></welion-button>
    <welion-button label="primary" type="primary link" size="medium" hasRightIcon></welion-button>
  </div>

  <div fxLayout="row" fxLayoutGap="8px">
    <welion-button label="secondary" type="secondary link" size="large"></welion-button>
    <welion-button label="secondary" type="secondary link" size="large" hasLeftIcon></welion-button>
    <welion-button label="secondary" type="secondary link" size="large" hasRightIcon></welion-button>
  </div>

  <div fxLayout="row" fxLayoutGap="8px">
    <welion-button label="secondary" type="secondary link" size="medium"></welion-button>
    <welion-button label="secondary" type="secondary link" size="medium" hasLeftIcon></welion-button>
    <welion-button label="secondary" type="secondary link" size="medium" hasRightIcon></welion-button>
  </div>

  <h3 fxFlexOffset="16px" class="primary">Buttons with custom icon</h3>

  <div fxLayout="row" fxLayoutGap="8px">
    <welion-button label="primary" type="primary" size="large" hasLeftIcon customIcon="assets/img/icons/bell.svg">
    </welion-button>
    <welion-button label="primary" type="primary" size="large" hasRightIcon customIcon="assets/img/icons/bell.svg">
    </welion-button>
  </div>

  <div fxLayout="row" fxLayoutGap="8px">
    <welion-button label="primary" type="primary" size="medium" hasLeftIcon customIcon="assets/img/icons/bell.svg">
    </welion-button>
    <welion-button label="primary" type="primary" size="medium" hasRightIcon customIcon="assets/img/icons/bell.svg">
    </welion-button>
  </div>

  <div fxLayout="row" fxLayoutGap="8px">
    <welion-button label="secondary" type="secondary" size="large" hasLeftIcon customIcon="assets/img/icons/bell.svg">
    </welion-button>
    <welion-button label="secondary" type="secondary" size="large" hasRightIcon customIcon="assets/img/icons/bell.svg">
    </welion-button>
  </div>

  <div fxLayout="row" fxLayoutGap="8px">
    <welion-button label="secondary" type="secondary" size="medium" hasLeftIcon customIcon="assets/img/icons/bell.svg">
    </welion-button>
    <welion-button label="secondary" type="secondary" size="medium" hasRightIcon customIcon="assets/img/icons/bell.svg">
    </welion-button>
  </div>

  <div fxLayout="row" fxLayoutGap="8px">
    <welion-button label="third" type="third" size="large" hasLeftIcon customIcon="assets/img/icons/bell.svg">
    </welion-button>
    <welion-button label="third" type="third" size="large" hasRightIcon customIcon="assets/img/icons/bell.svg">
    </welion-button>
  </div>

  <div fxLayout="row" fxLayoutGap="8px">
    <welion-button label="third" type="third" size="medium" hasLeftIcon customIcon="assets/img/icons/bell.svg">
    </welion-button>
    <welion-button label="third" type="third" size="medium" hasRightIcon customIcon="assets/img/icons/bell.svg">
    </welion-button>
  </div>

  <!-- #endregion Button section -->

  <div fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="8px">
    <tab-light *ngFor="let tab of tabLightTest" [tabTitle]="tab.title" [isDisabled]="tab.isDisabled"
      (onClick)="changeLightTab(tab)"></tab-light>
  </div>
  <div fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="8px">
    <tab-section *ngFor="let tab of tabLightTest" [tab]="tab" (onClick)="changeLightTab(tab)"></tab-section>
  </div>

  <div class="full-width" fxLayout="column">

    <div class="main-content-wrapper">
      <child-content [tabList]="tabDossier" (onTabChanged)="changeCurrentTab($event)">

        <ng-container *ngIf="currentTab == '1'">
          <div class="button-container">
            <welion-button type="primary" label="Apri modale" (onClick)="openTestModal()"></welion-button>
            <welion-button type="secondary" label="Apri badge" (onClick)="openTestBadgeModal()">
            </welion-button>
          </div>
          <slot-component-flag [status]="'PENDING'"></slot-component-flag>
          <slot-component-flag [status]="'APPROVED'"></slot-component-flag>
          <slot-component-flag [status]="'REVOKED'"></slot-component-flag>
          <slot-component-tag></slot-component-tag>
        </ng-container>

        <ng-container *ngIf="currentTab == '2'">
          <!-- Componente placeholder no data -->
          <no-data></no-data>
          <!-- Classe definita dentro a child-content -->
          <div class="button-container">
            <welion-button type="primary" label="Apri modale" (onClick)="openTestModal()"></welion-button>
            <welion-button type="secondary" label="Apri badge" (onClick)="openTestBadgeModal()">
            </welion-button>
          </div>
        </ng-container>

      </child-content>
    </div>
  </div>

  <div class="content" fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="32px">
    <input-container title="Chi vuoi applaudire?">
      <!-- ricerca -->
      <welion-input></welion-input>
    </input-container>

    <welion-accordion title="Obiettivi 2021" [badgeNumber]="1" [isNew]="true">
      Prova transition
    </welion-accordion>

    <!-- apre modali -->
    <div fxLayout="column" fxLayout.gt-xs="row" fxLayoutAlign="center center" fxLayoutGap="16px grid"
      class="full-width">
      <div fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="16px">
        <welion-button type="primary" label="Apri modale" (onClick)="openTestModal()"></welion-button>
        <welion-button type="primary bordless" label="Apri badge" (onClick)="openTestBadgeModal()">
        </welion-button>
        <welion-button type="secondary" label="Apri modale full" (onClick)="openTestFullModal()"></welion-button>
      </div>
    </div>

    <input-container style="width: 450px;" [fromEuropUpload]="true"
      [instructions]="'Potrai caricare i segunti tipi di file: xls,doc,jpg,jpeg,pdf,png,ppt.'"
      [title]="'Carica il Modulo mensile delle prestazioni e Process Confirmation'">
    </input-container>

    <input-container style="width: 450px;" [title]="'Data di scadenza'"
      [instructions]="'Informazioni contestuali del form, descrizione o indizazioni sull’obbligatorietà. Questo spazio sarà dedicato anche agli errori'">
      <welion-datepicker class="full-width" placeholder="generic.INSERT_DATE"></welion-datepicker>
    </input-container>

    <!-- Select dinamica, con infinite scroll -->
    <div fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="16px">
      <!-- checkbox -->
      <div fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="16px">
        <input-container>
          <welion-select [bindLabel]="'title'" [selectedItem]="selectedTakerToChangeSellerPerson.selectedSellerForTaker"
            [multiple]="false" [loading]="isLoadingsellerListForTaker" (change)="onSellerSelectedForTaker($event)"
            [items]="sellerListForTaker" (scrollToEnd)="fetchMoreSellersForTaker($event)"
            placeholder="{{ 'Seleziona utente' }}" startSearchLabel="{{ 'Inizia una ricerca' }}"
            noDataFoundLabel="{{ 'Nessun dato trovato' }}" loadingData="{{ 'Carico i risultati' }}"
            [typeahead]="sellerSrvSubjectForTaker" [searchable]="true" attrAriaLabel="{{ 'Seleziona utente' }}">
          </welion-select>
        </input-container>
      </div>
    </div>

    <div fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="16px">
      <!-- Select -->
      <div fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="16px">
        <!-- Normale -->
        <input-container [isValued]="isSelectValued(selectedCar)">
          <welion-select [items]="cars" [selectedItem]="selectedCar" (change)="onItemChecked($event)">
          </welion-select>
        </input-container>
        <!-- Disabilitto -->
        <input-container [disabled]="true">
          <welion-select [disabled]="true"></welion-select>
        </input-container>
        <!-- Errore -->
        <input-container
          [errorText]="'Informazioni sull’errore del form, indicare il motivo per cui questo campo non è valido'">
          <!--welion-select [forceError]="true" [items]="cars" [selectedItem]="selectedCar"
                  (onSelectedItem)="onItemChecked($event)"></welion-select-->
          <welion-select [forceError]="true" [noDataFoundLabel]=""></welion-select>
        </input-container>
        <!-- Readonly -->
        <input-container [readonly]="true">
          <welion-select [readonly]="true"></welion-select>
        </input-container>
      </div>
      <!-- Input -->
      <div fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="16px">
        <!-- Normale -->
        <input-container>
          <welion-input></welion-input>
        </input-container>
        <!-- Disabilitto -->
        <input-container [disabled]="true">
          <welion-input [formControl]="inputTestDisabled"></welion-input>
        </input-container>
        <!-- Errore -->
        <input-container
          [errorText]="'Informazioni sull’errore del form, indicare il motivo per cui questo campo non è valido'">
          <welion-input [formControl]="inputTestInvalid"></welion-input>
        </input-container>
        <!-- Readonly -->
        <input-container [readonly]="true">
          <welion-input></welion-input>
        </input-container>
      </div>
    </div>

    <div fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="16px">
      <!-- switch -->
      <div fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="16px">
        <input-container>
          <welion-switch></welion-switch>
        </input-container>
        <input-container [disabled]="true">
          <welion-switch [disabled]="true">
          </welion-switch>
        </input-container>
        <input-container [readonly]="true">
          <welion-switch [readonly]="true">
          </welion-switch>
        </input-container>
      </div>
      <!-- Radio -->
      <div fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="16px">
        <input-container [isValued]="radioSelected">
          <welion-radio [items]="radioList" [bindValue]="radioSelected"></welion-radio>
        </input-container>
        <input-container [disabled]="true">
          <welion-radio [disabled]="true" [items]="radioList" [bindValue]="radioSelected">
          </welion-radio>
        </input-container>
        <input-container [readonly]="true">
          <welion-radio [readonly]="true" [items]="radioList" [bindValue]="radioSelected">
          </welion-radio>
        </input-container>
      </div>
    </div>
    <div fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="16px">
      <!-- checkbox -->
      <div fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="16px">
        <!-- Normale -->
        <input-container [isFromTextArea]="true" [numberCharsTextArea]="textAreaNumberChars" [title]="'Descrizione'">
          <welion-text-area [maxlength]="200" (onChange)="changeCountChars($event)"></welion-text-area>
        </input-container>
        <!-- Disabilitato -->
        <input-container style="width: 450px;" [title]="'Feedback di Francesca'"  [readonly]="true">
          <welion-text-area [readonly]="true"
            [bindValue]="'Tincidunt morbi ultrices nullam vulputate eget eros, fusce integer morbi. Id lectus proin massa euismod tincidunt arcu risus diam. Ut a volutpat tortor nisl purus etiam sed sit in. Tempus, mauris gravida ut arcu parturient eu id diam enim.Tincidunt morbi ultrices nullam vulputate eget eros, fusce integer morbi. Id lectus proin massa euismod tincidunt arcu risus diam. Ut a volutpat tortor nisl purus etiam sed sit in. Tempus, mauris gravida ut arcu parturient eu id diam enim.Tincidunt morbi ultrices nullam vulputate eget eros, fusce integer morbi. Id lectus proin massa euismod tincidunt arcu risus diam. Ut a volutpat tortor nisl purus etiam sed sit in. Tempus, mauris gravida ut arcu parturient eu id diam enim.Tincidunt morbi ultrices nullam vulputate eget eros, fusce integer morbi. Id lectus proin massa euismod tincidunt arcu risus diam. Ut a volutpat tortor nisl purus etiam sed sit in. Tempus, mauris gravida ut arcu parturient eu id diam enim.Tincidunt morbi ultrices nullam vulputate eget eros, fusce integer morbi. Id lectus proin massa euismod tincidunt arcu risus diam. Ut a volutpat tortor nisl purus etiam sed sit in. Tempus, mauris gravida ut arcu parturient eu id diam enim.Tincidunt morbi ultrices nullam vulputate eget eros, fusce integer morbi. Id lectus proin massa euismod tincidunt arcu risus diam. Ut a volutpat tortor nisl purus etiam sed sit in. Tempus, mauris gravida ut arcu parturient eu id diam enim.Tincidunt morbi ultrices nullam vulputate eget eros, fusce integer morbi. Id lectus proin massa euismod tincidunt arcu risus diam. Ut a volutpat tortor nisl purus etiam sed sit in. Tempus, mauris gravida ut arcu parturient eu id diam enim.'">
          </welion-text-area>
        </input-container>
        <!-- Normale -->
        <input-container>
          <welion-select></welion-select>
        </input-container>
        <!-- Disabilitto -->
        <input-container [disabled]="true">
          <welion-select [disabled]="true"></welion-select>
        </input-container>
        <!-- Errore -->
        <input-container
          [errorText]="'Informazioni sull’errore del form, indicare il motivo per cui questo campo non è valido'">
          <welion-select [forceError]="true"></welion-select>
        </input-container>
        <!-- Readonly -->
        <input-container [readonly]="true">
          <welion-select [readonly]="true"></welion-select>
        </input-container>
        <input-container [title]="'Feedback di Francesca'"  [readonly]="true">
          <welion-text-area [readonly]="true"
            [bindValue]="'Tincidunt morbi ultrices nullam vulputate eget eros, fusce integer morbi. Id lectus proin massa euismod tincidunt arcu risus diam. Ut a volutpat tortor nisl purus etiam sed sit in. Tempus, mauris gravida ut arcu parturient eu id diam enim.'">
          </welion-text-area>
        </input-container>
        <input-container>
          <welion-check label="Prova"></welion-check>
        </input-container>
        <input-container [disabled]="true">
          <welion-check label="disabilitato" [disabled]="true"></welion-check>
        </input-container>
        <input-container [readonly]="true">
          <welion-check label="readonly" [readonly]="true"></welion-check>
        </input-container>
      </div>
    </div>
    <!-- Buttons -->
    <welion-button type="primary" hasRightIcon></welion-button>
    <welion-button type="primary bordless"></welion-button>
    <welion-button type="secondary"></welion-button>
    <welion-button type="secondary bordless"></welion-button>

    <!-- Tabelle -->
    <welion-table></welion-table>

    <welion-table [isEdit]="true"></welion-table>

    <div style="width: 100%; background-color: #000; margin: 10px auto" fxLayout="row" fxLayoutAlign="center center">
      <welion-button type="third bordless"></welion-button>
    </div>

  </div>
</div>

<modal id="test" (onClose)="closeTestModal()" confirmLabel="Conferma">
  <modal-text-content></modal-text-content>
</modal>

<modal id="badgeModal" (onClose)="closeTestBadgeModal()" confirmLabel="Chiudi">
  <modal-text-content badge="/assets/img/temp/badge.svg" badgeDescr="Beginner"></modal-text-content>
</modal>

<modal id="testFullPage" (onClose)="closeTestFullModal()" [fullPage]="true" confirmLabel="Conferma"
  cancelLabel="Annulla">
  <modal-text-content></modal-text-content>
</modal>

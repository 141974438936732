import * as CoreActions from "./core/ngrx/core.actions";
import * as fromApp from "./ngrx/app.reducers";
import * as AuthActions from './auth/ngrx/auth.actions';
import * as moment from 'moment';
import { combineLatest, Observable, of } from 'rxjs';

import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';

import { JwtPayload, User } from '../commonclasses';
import { RedirectService } from './shared/services/redirect.service';
import { AuthService } from './auth/services/auth.service';
import { ModalService } from './shared/components/modal/modal.service';
import { ApplicationModalMessage } from './core/ngrx/core.reducers';
import { DropdownItem } from './shared/models/dropdown.model';
import { userIsFemale } from "./utils/utils";
import { SidebarComponent } from "./shared/components/sidebar/sidebar.component";
import { SidebarService } from "./shared/services/sidebar.service";
import { switchMap } from 'rxjs/operators';

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"]
})
export class AppComponent implements OnInit {
  showApplicationLoader: boolean = false;
  applicationModalMessageText: string = '';
  applicationModalMessageTitle: string = '';
  withScrolledBody: boolean = true;
  applicationModalButtonCloseText: string = '';
  isFetchingLangs: boolean = true;
  loggedUser: any;
  initials: string = '';
  isStructure: boolean = false;
  isUser: boolean = false;
  isAdmin: boolean = false;
  isLimitedAdmin: boolean = false;
  isHelpDesk: boolean = false;
  isClient: boolean = false;

  applicationModalMessageSubTitle: string = '';
  applicationModalMessageBadge: string = '';
  applicationModalMessageBadgeDescr: string = '';
  applicationModalMessageNoBackground: boolean = false;
  basicMenuItems: DropdownItem[] = [];
  structureMenuItems: DropdownItem[] = [];
  isLoadingMenuItems: boolean = false;
  applicationModalId: string = '';
  headerTooltip: ApplicationModalMessage = {
    modalId: '---',
    title: '',
    text: ''
  };
  profileDisabledModal: ApplicationModalMessage = {
    modalId: '',
    title: '',
    text: ''
  };
  dashboardDisabledModal: ApplicationModalMessage = {
    modalId: '',
    title: '',
    text: ''
  };

  isImpersonating: boolean = false;

  readonly hideHeaderFooterUrls = [
    '404-not-found',
    '403-not-allowed',
    'bookingDetails',
    'compatibilityError',
    'ssoAccentureLogin',
    'accentureLoginSamlError',
    'accentureLogoutSsoCompleted',
    'ssoEnelLogin',
    'enelLoginSamlError',
    'enelLogoutSsoCompleted',
    'ssoLeroyMerlinLogin',
    'leroymerlinLoginSamlError',
    'leroymerlinLogoutSsoCompleted',
    'errorPage',
    'privacy',
    'enelPrivacy',
    'round/slots/add',
    '/add/',
    'user/reservation',
    'assign/campaign',
    'remove/campaign',
    'import/campaign',
    'createTicket',
    'registerAD',
    'familyReservation'
  ];

  constructor(
    public redirectService: RedirectService,
    private store: Store<fromApp.AppState>,
    public authService: AuthService,
    public modalService: ModalService,
    private translate: TranslateService,
    private router: Router,
    public sidebarService: SidebarService
  ) {
    moment.locale('it');
    this.isLoadingMenuItems = true;

    // Sto in ascolto del potenziale messaggio generico da mostrare nella modale
    const applicationModalMessage$: Observable<ApplicationModalMessage> = this.store.select(fromApp.getApplicationModalMessage);
    const isFetchingLangs$: Observable<boolean> = this.store.select(fromApp.isFetchingLangs);
    combineLatest([applicationModalMessage$, isFetchingLangs$]).subscribe(
      ([applicationModalMessage, isFetchingLangs]) => {
        this.isFetchingLangs = isFetchingLangs;
        this.applicationModalId = applicationModalMessage && applicationModalMessage.modalId || '';
        this.applicationModalMessageText = applicationModalMessage && applicationModalMessage.text;
        this.applicationModalMessageTitle = applicationModalMessage && applicationModalMessage.title;
        this.applicationModalMessageSubTitle = applicationModalMessage && applicationModalMessage.subtitle || '';
        this.applicationModalMessageBadge = applicationModalMessage && applicationModalMessage.badge || '';
        this.applicationModalMessageBadgeDescr = applicationModalMessage && applicationModalMessage.badgeDescr || '';
        this.applicationModalMessageNoBackground = applicationModalMessage && applicationModalMessage.noBackground || false;
        this.withScrolledBody = applicationModalMessage && applicationModalMessage.withScrolledBody || false;
        this.applicationModalButtonCloseText = applicationModalMessage && applicationModalMessage.applicationModalButtonCloseText || '';
        if (this.applicationModalMessageText && this.applicationModalMessageText.length) {
          // Ho un nuovo messaggio da mostrare, quindi apro lo modale per visualizzarlo
          this.modalService.open('modalMessage');
        }
      });
  }

  ngOnInit() {
    const isStructure$: Observable<boolean> = this.store.select(fromApp.getIsStructure)
    const isUser$: Observable<boolean> = this.store.select(fromApp.getIsUser);
    const isAdmin$: Observable<boolean> = this.store.select(fromApp.getIsAdmin);
    const isLimitedAdmin$: Observable<boolean> = this.store.select(fromApp.getIsLimitedAdmin);
    const isHelpDesk$: Observable<boolean> = this.store.select(fromApp.getIsHelpDesk);
    const isClient$: Observable<boolean> = this.store.select(fromApp.getIsClient);
    const loggedUser$: Observable<any> = this.store.select(fromApp.getLoggedUser);

    const combinedSelectes$ = combineLatest([loggedUser$, isStructure$, isUser$, isAdmin$, isHelpDesk$, isClient$, isLimitedAdmin$])
      .subscribe(
        ([loggedUser, isStructure, isUser, isAdmin, isHelpDesk, isClient, isLimitedAdmin]) => {
          this.basicMenuItems = [];
          this.structureMenuItems = [];
          this.isImpersonating = Boolean(loggedUser?.params?.originalUserId);

          if (loggedUser && loggedUser.user) {
            this.loggedUser = loggedUser && loggedUser.user;
            this.isStructure = isStructure;
            this.isUser = isUser;
            this.isAdmin = isAdmin;
            this.isLimitedAdmin = isLimitedAdmin;
            this.isHelpDesk = isHelpDesk;
            this.isClient = isClient;
            if (this.loggedUser) {
              this.initials = this.loggedUser.forename[0] + this.loggedUser.surname[0];
              this.translate.get(
                [
                  'generic.WELCOME_M',
                  'generic.WELCOME_F',
                  'generic.CLOSE'
                ])
                .subscribe(translations => {
                  this.headerTooltip = {
                    modalId: isStructure ? "001" : "002",
                    title: translations[('generic.WELCOME' + (userIsFemale(this.loggedUser) ? '_F' : '_M'))],
                    subtitle: "Lorem ipsum",
                    text: "Lorem ipsum",
                    applicationModalButtonCloseText: translations['generic.CLOSE']
                  }
                })

              // elementi comuni del menù
              this.basicMenuItems = [
                {
                  id: 'home',
                  name: 'menu.HOME',
                  icon: '/assets/img/icons/home-icon.svg'
                }
              ];

              const logout: DropdownItem = {
                id: 'logout',
                name: 'menu.LOGOUT',
                icon: '/assets/img/icons/log-out.svg'
              };

              if (this.isStructure) {
                // elementi del menù per le strutture
                this.structureMenuItems.push(
                  {
                    id: 'profile',
                    name: 'menu.STRUCTURES.PROFILE',
                    avatar: this.loggedUser.userOptions && this.loggedUser.userOptions.avatar,
                    initials: this.initials
                  });

                // Logout
                if (!sessionStorage.getItem('isFromSSO')) {
                  this.structureMenuItems.push(logout);
                }
              } else {
                // Logout
                if (!sessionStorage.getItem('isFromSSO')) {
                  this.basicMenuItems.push(logout);
                }
              }

              this.isLoadingMenuItems = false;
            }
          }
        });
    // Recupero le lingue disponibili
    this.store.dispatch(CoreActions.GetAvailableLangs());

    moment.updateLocale('it', {});
  }

  // Chiude la modale contenente un errore
  closeModalMessage() {
    this.store.dispatch(CoreActions.DeleteApplicationModalMessage())
    this.modalService.close('modalMessage');
  }

  // Gestione redirect sidebar header
  onHeaderOptionClicked(item: DropdownItem) {
    switch (item.id) {
      case 'home':
        this.redirectService.goToHome();
        break;
      case 'profile':

        break;
      case 'logout':
        this.isLoadingMenuItems = true;
        this.store.dispatch(AuthActions.Logout());
        break;
    }
  }

  // Verifica se far vedere l'intestazione
  showHeader() {
    return !this.isLoadingMenuItems && !this.redirectService.isThisCurrentPages(this.hideHeaderFooterUrls);
  }

  // Verifica se far vedere il footer
  showFooter() {
    return !this.isLoadingMenuItems && !this.redirectService.isThisCurrentPages(this.hideHeaderFooterUrls);
  }

  menuId(index: number, el: any) {
    return el.id;
  }

  ngOnDestroy() {
  }

  onEscKeyboard() {
    this.sidebarService.setShowNav(false);

  }

  returnToMyProfile() {
    this.store.dispatch(AuthActions.DoOriginalLogin());
  }
}

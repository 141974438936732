import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { FormControl, FormGroup } from '@angular/forms';
import { Subject } from 'rxjs';
import { Store } from '@ngrx/store';
import { takeUntil } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';

import * as CoreActions from "../../../core/ngrx/core.actions";
import * as fromApp from "../../../ngrx/app.reducers";
import { AnagService, ClientGroup } from 'src/app/auth/services/anag.service';
import { InfoAttendance, InfoCampaign } from 'atfcore-commonclasses/bin/classes/campaignmanager';
import { SenecaResponse } from 'atfcore-commonclasses/bin/classes/common';

import { RedirectService } from 'src/app/shared/services/redirect.service';
import { getModalMessageData, getWarningModal } from 'src/app/utils/utils';
import { RentService } from 'src/app/structure/services/rent.service';
import { ScrollTo } from 'src/app/core/services/scroll-to.service';
import { ModalService } from 'src/app/shared/components/modal/modal.service';
import { SidebarService } from 'src/app/shared/services/sidebar.service';
import { AuthService } from 'src/app/auth/services/auth.service';
import { DropdownButtonItem } from 'src/app/shared/components/welion-select-button/welion-select-button.component';

@Component({
  selector: 'app-admin-campaign-customer-details',
  templateUrl: './campaignCustomerDetails.component.html',
  styleUrls: ['./campaignCustomerDetails.component.scss']
})
export class AdminCampaignCustomerDetailsComponent implements OnInit, OnDestroy {

  isLoading: boolean = true;
  isLoadingCount: boolean = true;
  isLoadingBase: boolean = true;

  searchText?: string;

  private _unsubscribeSignal$: Subject<void> = new Subject();

  readonly paginationLength: number = 50;

  campaignId: string = '';
  campaign?: InfoCampaign;

  groupId: string = '';
  customer?: ClientGroup;
  campaignCustomerCap?: number;

  entitledsCount: number = 0;
  entitleds: InfoAttendance[] = [];

  selectedAttendance?: InfoAttendance;

  attendeeForm: FormGroup = new FormGroup({
    'description': new FormControl(undefined)
  });
  totalAttendances: any;

  get description(): FormControl {
    return this.attendeeForm.get('description') as FormControl;
  }

  dropdownAddItems: DropdownButtonItem[] = [];
  isLimitedAdmin: boolean = false;

  campaignCustomerAddMembers: string = '';
  campaignCustomerMaxCap: number = 0;
  campaignCustomerBoughtShots: number = 0;
  campaignCustomerAllowedFamilyMembers: string[] = [];
  isRemovingLimit: boolean = false;

  constructor(
    private route: ActivatedRoute,
    private scrollTo: ScrollTo,
    private appStore: Store<fromApp.AppState>,
    private translate: TranslateService,
    private redirectService: RedirectService,
    private rentService: RentService,
    private anagService: AnagService,
    private modalService: ModalService,
    private sidebarService: SidebarService,
    private authService: AuthService
  ) {
  }

  ngOnInit() {
    this.scrollTo.header();
    this.campaignId = this.route.snapshot.paramMap.get('campaignId') || '';
    this.groupId = this.route.snapshot.paramMap.get('groupId') || '';

    if (this.campaignId) {
      this.appStore.select(fromApp.getIsLimitedAdmin)
        .pipe(takeUntil(this._unsubscribeSignal$.asObservable()))
        .subscribe(
          (isLimitedAdmin) => {
            this.isLimitedAdmin = isLimitedAdmin;
          });

      this.getCampaign();
    } else {
      this.redirectService.goToHome();
    }
  }

  setDropdownActions() {
    this.dropdownAddItems = [
      {
        // Email no show
        label: 'admin.campaignCustomerDetails.table.EMAIL_NO_SHOW',
        onClick: () => {
          this.openEmailNoEmailConfirmModal();
        },
        disabled: this.entitledsCount === 0,
        icon: 'assets/img/icons/icon-email.svg'
      }, {
        // Reset no show
        label: 'admin.campaignCustomerDetails.table.RESET_NO_SHOW',
        onClick: () => {
          this.openResetNoShowConfirmModal();
        },
        disabled: this.entitledsCount === 0,
        icon: 'assets/img/icons/icon-restart.svg'
      }
    ];

    this.dropdownAddItems.push({
      // Gestisci limiti
      label: 'admin.campaignCustomerDetails.table.MANAGE_LIMITS',
      onClick: () => {
        this.addCapLimit();
      },
      icon: 'assets/img/icons/icon-plus-square.svg',
      strokeIcon: true
    });
  }

  goBackToCampaign() {
    this.redirectService.goToAdminCampaignTabClients(this.campaignId);
  }

  getCampaign() {
    this.rentService.getCampaign(this.campaignId)
      .pipe(takeUntil(this._unsubscribeSignal$.asObservable()))
      .subscribe((result) => {
        if (!result || result.error) {
          this.dispatchModal('100', result.error || '');
          this.resetPagination();
        } else {
          this.campaign = result.response;
          this.getCustomer();
        }
      }, (err) => {
        if (err && err.message) {
          this.dispatchModal('101', err.message);
        }
        this.resetPagination();
      });
  }

  getCustomer() {
    this.anagService.getCustomersForAdmin(this.groupId, undefined, this.campaignId)
      .pipe(takeUntil(this._unsubscribeSignal$.asObservable()))
      .subscribe((result: any) => {
        if (!result || result.error) {
          this.dispatchModal('044', result.error || '');
          this.resetPagination();
        } else if (result.response) {
          this.customer = result.response;
          this.totalAttendances = result.response?.totalAttendances;
          if (this.customer) {
            const baseCustomer: any = this.customer;
            const campaignId = this.campaignId.split('-').join('_');
            this.campaignCustomerCap = baseCustomer['MAX_BOOKING_CAP_' + campaignId];
            this.campaignCustomerAddMembers = baseCustomer['CAN_ADD_FAMILITY_MEMBERS_' + campaignId] ? 'SI' : 'NO';
            this.campaignCustomerMaxCap = baseCustomer['MAX_FAMILY_MEMBERS_CAP_' + campaignId];
            this.campaignCustomerBoughtShots = baseCustomer['BOUGHT_SHOTS_' + campaignId];

            // Limite di familiari
            const allowedFamiliars = baseCustomer['ALLOWED_FAMILIY_MEMBERS_' + campaignId];
            // Si tratta di una stringa di array ('["x", "y"]') quindi devo convertirla in un array vero e proprio
            if (allowedFamiliars && allowedFamiliars.length) {
              this.campaignCustomerAllowedFamilyMembers = JSON.parse(allowedFamiliars);
            }
          }

          this.countEntitleds();
        }
        this.isLoadingBase = false;
      }, (err) => {
        if (err && err.message) {
          this.dispatchModal('045', err.message);
        }
        this.resetPagination();
      });
  }

  ngOnDestroy() {
    this._unsubscribeSignal$.next();
    this._unsubscribeSignal$.unsubscribe();
  }

  /**
   * @description Mostra una modale con titolo e messaggio
   * @param modalId
   * @param title
   * @param message
   */
  dispatchMessageModal(title: string, message: string) {
    const messageObj = getModalMessageData(this.translate, title, message);
    this.appStore.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
  }

  /**
   * @description Mostra una modale con un messaggio di errore
   * @param modalId
   * @param error
   */
  dispatchModal(modalId: string, error: string) {
    const messageObj = getWarningModal(this.translate, modalId, error);
    this.appStore.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
  }

  countEntitleds() {
    this.isLoadingCount = true;
    this.isLoading = true;

    this.rentService.countEntitledByCustomersForAdmin(this.campaignId, this.groupId, this.searchText)
      .pipe(takeUntil(this._unsubscribeSignal$.asObservable()))
      .subscribe((result) => {
        if (!result || result.error) {
          this.dispatchModal('082', result.error || '');
          this.resetPagination();
        } else {
          this.entitledsCount = result.response;

          if (this.entitledsCount === 0) {
            this.resetPagination();
          } else {
            this.setDropdownActions();
            this.getEntitleds();
          }
        }
        this.isLoadingCount = false;
      }, (err) => {
        if (err && err.message) {
          this.dispatchModal('084', err.message);
        }
        this.resetPagination();
      });
  }

  getEntitleds(index?: number) {

    this.isLoading = true;

    const pageSelectedIndex = index ? index : 0;
    if (index === 0 || index) {
      this.scrollTo.element('admin-campaign-customer-details-anchor');
    }

    this.rentService.listEntitledByCustomersForAdmin(this.campaignId, this.groupId, (pageSelectedIndex * this.paginationLength), this.paginationLength, this.searchText)
      .pipe(takeUntil(this._unsubscribeSignal$.asObservable()))
      .subscribe((result) => {
        if (!result || result.error) {
          this.dispatchModal('082', result.error || '');
          this.resetPagination();
        } else {
          this.entitleds = result.response;
        }
        this.isLoading = false;
      }, (err) => {
        if (err && err.message) {
          this.dispatchModal('084', err.message);
        }
        this.resetPagination();
      });
  }

  resetPagination() {
    this.isLoading = false;
    this.isLoadingCount = false;
    this.isLoadingBase = false;

    this.entitledsCount = 0;
    this.entitleds = [];
    this.setDropdownActions();
  }

  addCapLimit() {
    this.redirectService.goToAdminAddCampaignCustomerCap(this.campaignId, this.groupId);
  }

  /**
   * @description Apre la modale per confermare la rimozione del cap limite
   */
  openRemoveCapLimitConfirmModal() {
    this.modalService.open('removeCapLimit');
  }

  /**
   * @description Chiude la modale per confermare la rimozione del cap limite
   */
  closeRemoveCapLimitConfirmModal() {
    this.modalService.close('removeCapLimit');
  }

  /**
   * Rimuove il limite di prenotazioni da un cliente
   */
  confirmRemoveCapLimit() {

    this.isLoading = true;
    this.isRemovingLimit = true;
    this.isLoadingCount = true;
    this.closeRemoveCapLimitConfirmModal();

    if (this.campaignCustomerCap || this.campaignCustomerCap === 0) {
      this.anagService.addBookingCapByCampaignAndCustomerForAdmin(this.campaignId, this.groupId)
        .pipe(takeUntil(this._unsubscribeSignal$.asObservable()))
        .subscribe((result) => {
          if (!result || result.error) {
            this.dispatchModal('082', result.error || '');
          } else if (result.response) {
            this.campaignCustomerCap = undefined;
            this.dropdownAddItems = [];
            this.setDropdownActions();
          }
          this.isLoading = false;
          this.isLoadingCount = false;
          this.isRemovingLimit = false;
        }, (err) => {
          if (err && err.message) {
            this.dispatchModal('084', err.message);
          }
          this.isRemovingLimit = false;
          this.isLoading = false;
          this.isLoadingCount = false;
        });
    }
  }

  searchTextChange(searchText?: string) {
    this.searchText = searchText;
  }

  searchEntitleds() {
    this.countEntitleds();
  }

  //#region Modali

  /**
   * @description Apre la modale per il reset no show
   */
  openResetNoShowConfirmModal() {
    this.modalService.open('resetNoShow');
  }

  /**
   * @description Chiude la modale per il reset no show
   */
  closeResetNoShowConfirmModal() {
    this.modalService.close('resetNoShow');
  }

  /**
   * @description Conferma il reset no show
   */
  confirmResetNoshow() {
    // Chiudo la modale, mostro il loader e chiamo il servizio
    this.isLoading = true;
    this.isLoadingCount = true;

    this.rentService.resetNoshowForAdmin(this.campaignId, this.groupId)
      .subscribe((data: any) => {
        this.closeResetNoShowConfirmModal();
        if (data.error) {
          this.dispatchModal('013', data.error);
          this.isLoading = false;
          this.isLoadingCount = false;
        } else {
          this.dispatchMessageModal('admin.campaignCustomerDetails.resetNoShow.TITLE', 'admin.campaignCustomerDetails.resetNoShow.CONFIRMED');
          this.countEntitleds();
        }
      },
        (err: string) => {
          this.closeResetNoShowConfirmModal();
          if (err) {
            this.dispatchModal('015', err);
          }
          this.isLoading = false;
          this.isLoadingCount = false;
        });
  }

  /**
   * @description Apre la modale per l'email no show
   */
  openEmailNoEmailConfirmModal() {
    this.modalService.open('emailNoShow');
  }

  /**
   * @description Chiude la modale per l'email no show
   */
  closeEmailNoShowConfirmModal() {
    this.modalService.close('emailNoShow');
  }

  /**
   * @description Conferma l'email no show
   */
  confirmEmailNoshow() {
    // Chiudo la modale, mostro il loader e chiamo il servizio
    this.isLoading = true;
    this.isLoadingCount = true;

    this.rentService.emailNoshowForAdmin(this.campaignId, this.groupId)
      .subscribe((data: any) => {
        this.closeEmailNoShowConfirmModal();
        if (data.error) {
          this.dispatchModal('013', data.error);
          this.isLoading = false;
          this.isLoadingCount = false;
        } else {
          this.dispatchMessageModal('admin.campaignCustomerDetails.emailNoShow.TITLE', 'admin.campaignCustomerDetails.emailNoShow.CONFIRMED');
          this.countEntitleds();
        }
      },
        (err: string) => {
          this.closeEmailNoShowConfirmModal();
          if (err) {
            this.dispatchModal('015', err);
          }
          this.isLoading = false;
          this.isLoadingCount = false;
        });
  }

  /**
   * @description Apre la modale per confermare la cancellazione della campagna
   */
  openRemoveCustomerFromCampaignModal() {
    this.modalService.open('removeCustomerFromCampaign');
  }

  /**
   * @description Chiude la modale per confermare la cancellazione della campagna
   */
  closeRemoveCustomerFromCampaignModal() {
    this.modalService.close('removeCustomerFromCampaign');
  }

  confirmRemoveCustomerFromCampaign() {
    this.isLoadingBase = true;
    this.isLoadingCount = true;

    this.anagService.removeCustomersFromCampaignForAdmin(this.campaignId, [this.groupId])
      .pipe(takeUntil(this._unsubscribeSignal$.asObservable()))
      .subscribe((result) => {
        this.closeRemoveCustomerFromCampaignModal();
        if (!result || result.error) {
          this.dispatchModal('200', result.error || '');
          this.isLoadingBase = false;
          this.isLoadingCount = false;
        } else {
          this.redirectService.goToAdminCampaignTabClients(this.campaignId);
        }
      }, (err) => {
        this.closeRemoveCustomerFromCampaignModal();
        if (err && err.message) {
          this.dispatchModal('201', err.message);
        }
        this.isLoadingBase = false;
        this.isLoadingCount = false;
      });
  }

  //#endregion

  //#region Attendance card

  openAttendenceCard(attendance: InfoAttendance) {
    this.selectedAttendance = attendance;

    this.description.setValue(attendance.description);

    this.sidebarService.setShowNav(true);

  }

  closeAttendanceCard() {
    this.sidebarService.setShowNav(false);
  }

  /**
   * Aggiorna la descrizione di una prenotazione
   */
  updateAttendee() {

    this.isLoading = true;

    if (this.selectedAttendance) {
      this.rentService.updateAttendanceForAdmin(
        this.selectedAttendance.attendanceId,
        this.description.value)
        .pipe(takeUntil(this._unsubscribeSignal$.asObservable()))
        .subscribe(
          (result: SenecaResponse<null>) => {
            if (result && result.error) {
              this.dispatchMessageModal('014', result.error);
              this.isLoading = false;
            } else {
              this.selectedAttendance = undefined;
              this.closeAttendanceCard();
              this.getEntitleds();
            }
          }, (err) => {
            if (err && err.message) {
              this.dispatchMessageModal('015', err.message);
            }
            this.isLoading = false;
          });
    }
  }

  //#endregion Attendance card

  /**
   * On click on the AD - go for impersonation
   * @param entitled
   */
  onImpersonateEntitled(entitled: InfoAttendance) {
    this.authService.impersonateUser(entitled.userId)
      .pipe(takeUntil(this._unsubscribeSignal$.asObservable()))
      .subscribe((result) => {
        if (!result || result.error) {
          this.dispatchModal('101', result.error || '');
        } else {
          this.redirectService.impersonationLogin(result.response);
        }
      }, (err) => {
        if (err && err.message) {
          this.dispatchModal('102', err.message);
        }
      });
  }


}

import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { Component, EventEmitter, Output, Input, OnInit } from '@angular/core';
import { ACCEPTED_MIME_DOCS, ACCEPTED_MIME_TYPES, ACCEPTED_MIME_XLSX, FileType, GetFileExtensionString } from 'src/app/utils';

@Component({
  selector: 'welion-upload',
  templateUrl: 'welion-upload.component.html',
  styleUrls: ['./welion-upload.component.scss']
})
export class WelionUploadComponent {
  acceptedMimeTypes = ACCEPTED_MIME_TYPES;
  doc: {
    name?: string;
    file?: any;
    fileType?: FileType;
    type?: string;
    isUpload?: boolean;
  } = {};
  blob?: Blob;
  mbFile: string = '';


  _onlyXLSX: boolean = false;
  @Input() set onlyXLSX(value: boolean | string) {
    this._onlyXLSX = coerceBooleanProperty(value);
    if (this._onlyXLSX) {
      this.acceptedMimeTypes = ACCEPTED_MIME_XLSX;
    }
  }

  _onlyDocs: boolean = false;
  @Input() set onlyDocs(value: boolean | string) {
    this._onlyDocs = coerceBooleanProperty(value);
    if (this._onlyDocs) {
      this.acceptedMimeTypes = ACCEPTED_MIME_DOCS;
    }
  }

  _isToReset: boolean = false;
  @Input() set isToReset(value: boolean) {
    this._isToReset = value;
    if (this._isToReset) {
      this.resetFileData();
    }
  };
  @Output() onUpload: EventEmitter<Blob> = new EventEmitter();

  constructor() { }

  // Gestione del file
  async handleFileInput(file: any) {
    if (file.files && file.files.length) {
      let files = file.files;
      this._isToReset = false;
      this.doc.isUpload = true;
      this.blob = files[0];
      if (
        this.doc.fileType !== FileType.PDF &&
        this.doc.fileType !== FileType.IMAGE
      ) {
        this.doc.file = files[0];
      }
      this.doc.name = files[0].name;
      this.doc.file = this.blob;
      this.doc.file.sizeRounded = Math.round(this.doc.file.size / 1024);
      this.GetFileExt();
    }
  }

  // Permetti il Drag and Drop
  allowDrag(e: any) {
    e.stopPropagation();
    e.preventDefault();
  }

  // Gestione del file drag and drop
  async handleFileInputOnDrug(file: any) {
    this._isToReset = false;
    (event && event.stopPropagation());
    (event && event.preventDefault());

    if (file.dataTransfer.files && file.dataTransfer.files.length) {
      let dt = file.dataTransfer
      this.handleFileInput(dt);
    }
  }

  // Pulisce i dati
  resetFileData() {
    this.doc = {};
    this.blob = undefined;
    this._isToReset = false;
  }

  // Recupera l'estensione del file
  GetFileExt() {
    const type = this.doc.file
      ? this.doc.file.type
      : (this.doc.fileType && this.doc.fileType.toString());
    this.doc.file.fileExtension = GetFileExtensionString(type);
    this.onUpload.emit(this.blob);
  }

  // Premi sul sfoglia files
  uploadDoc() {
    const element: any = document.querySelector("#uploadFile");
    element.value = "";
    element.click();
  }
}

import { ChangeDetectorRef, Component, ElementRef, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { takeUntil } from 'rxjs/operators';
import { combineLatest, Observable, Subject } from 'rxjs';

import { JwtPayload } from 'atfcore-commonclasses/bin/classes/auth';
import { User, UserAcknowledges } from 'atfcore-commonclasses/bin/classes/anag';
import { AttendanceStatuses, GetReservationEntitledDataResponse, InfoAttendance, InfoCampaign, InfoRound, InfoVenue } from 'atfcore-commonclasses/bin/classes/campaignmanager';

import * as CoreActions from "../../core/ngrx/core.actions";
import * as fromApp from "../../ngrx/app.reducers";
import * as AuthActions from "../../auth/ngrx/auth.actions";
import * as moment from 'moment';

import { RedirectService } from 'src/app/shared/services/redirect.service';
import { RentService } from 'src/app/structure/services/rent.service';
import { canUserCancelBooking, canUserEditBookingDate, checkUnderCustomYearsOld, getBirthDateFromCF, getModalMessageData, getWarningModal, hasRoundClosedBooking } from 'src/app/utils/utils';
import { SidebarService } from 'src/app/shared/services/sidebar.service';
import { ModalService } from 'src/app/shared/components/modal/modal.service';
import { AnagService } from 'src/app/auth/services/anag.service';
import { Router } from '@angular/router';
import { Title } from '@angular/platform-browser';

export interface InfoEntitled {
  user: User
  attendances?: any[],
  preMatchedVenue?: InfoVenue,
  venue?: InfoVenue,
  isFamiliarToAdd?: boolean,
  familiarsTypeToChoose?: string
}

export interface InfoRoundTicket {
  campaign: InfoCampaign,
  round: (InfoRound & {
    entitled: InfoEntitled[];
    entitledToProcess: InfoEntitled[]; // To split active and expired
    entitledProcessed: InfoEntitled[];
    entitledToProcessExpired: InfoEntitled[];
    rightMaxReservationDate: Date
  })
};

@Component({
  selector: 'app-user-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class UserHomeComponent implements OnInit, OnDestroy {
  ALL_KEYS: string[] = ["HANDICAPPED_ACCESS", "ON_SITE_PARKING", "ON_STREET_PARKING", "CAR_PARKING", "PARKING_FOR_CYCLES_AND_MOTORCYCLES", "PAID_PARKING", "INFORMATION_AND_RECEPTION_SERVICE", "BAR",
    "CAR", "BICYCLES_AND_MOTORCYCLES", "BUS", "SUBWAY", "STREETCAR", "TRAIN", "CAB_STATION"]
  SERVICE_KEYS: string[] = ["HANDICAPPED_ACCESS", "ON_SITE_PARKING", "ON_STREET_PARKING", "CAR_PARKING", "PARKING_FOR_CYCLES_AND_MOTORCYCLES", "PAID_PARKING", "INFORMATION_AND_RECEPTION_SERVICE", "BAR"];
  VEHICLE_KEYS: string[] = ["CAR", "BICYCLES_AND_MOTORCYCLES", "BUS", "SUBWAY", "STREETCAR", "TRAIN", "CAB_STATION",]

  loggedUser?: User;
  isFemale: boolean = false;
  isImpersonate: boolean = false;
  userAcknowledges: UserAcknowledges | null = null;
  isLoadingCampaignList: boolean = true;

  campaignList: InfoCampaign[] = [];
  reservationEntitledData?: GetReservationEntitledDataResponse;

  infoTickets: InfoRoundTicket[] = [];

  venues: { id: string, positions: { infoTicketIndex: number, entitleIndex: number }[] }[] = [];

  selectedVenue?: any;
  selectedVenueTab: number = 0;
  venueMapData?: {
    lat: number,
    lng: number
  };

  attendanceToDelete?: InfoAttendance;

  isEnel: boolean = false;

  supplierName?: string;

  private _unsubscribeSignal$: Subject<void> = new Subject();
  private _firstInit: boolean = true;

  privacyCheck: boolean = false;
  isUnderageModal: boolean = false;

  newReservationRound?: InfoRound;
  newReservationTicket?: InfoEntitled;
  familyListForModal: any[] = [];
  selectedFamilyForReservation: any;
  familyReservationRoundId: any;
  isLoadingModalReservationList: boolean = false;
  familyAttendanceToDelete: any[] = [];
  selectedFamilyForDelete: any[] = [];
  isEditFamilyDate: boolean = false;
  togglePrivacyUser: any;
  isGenerali: boolean = false;

  cardTicketId!: string;

  constructor(
    private appStore: Store<fromApp.AppState>,
    private translate: TranslateService,
    private rentService: RentService,
    public modalService: ModalService,
    private sidebarService: SidebarService,
    public redirectService: RedirectService,
    private anagService: AnagService,
    public router: Router,
    public cdr: ChangeDetectorRef,
    private titleService: Title,
    private el: ElementRef
  ) {
  }

  ngOnInit() {
    this.translate.get(
      [
        "users.MANAGE_RESERVATIONS"
      ]).subscribe(translations => {
        this.titleService.setTitle(translations["users.MANAGE_RESERVATIONS"]);
      });
    sessionStorage.setItem('familyMembers', JSON.stringify([]));

    const loggedUser$: Observable<JwtPayload> = this.appStore.select(fromApp.getLoggedUser);
    const userAck$: Observable<any> = this.appStore.select(fromApp.getUserAcknowledges);
    const isImpersonate$: Observable<any> = this.appStore.select(fromApp.getIsImpersonate);
    combineLatest([loggedUser$, userAck$, isImpersonate$])
      .pipe(takeUntil(this._unsubscribeSignal$.asObservable()))
      .subscribe(
        ([loggedUser, userAck, isImpersonate]) => {
          if (loggedUser && loggedUser.user) {
            this.loggedUser = loggedUser && loggedUser.user;
            this.supplierName = loggedUser.params && loggedUser.params.supplier && loggedUser.params.supplier.supplierName ?
              (loggedUser.params.supplier.supplierName as string).toLowerCase() : undefined;

            if (this.supplierName) {
              this.isEnel = this.supplierName.indexOf('enel') !== -1;
              this.isGenerali = this.supplierName.indexOf('generali italia') !== -1;
            }

            this.isFemale = this.loggedUser && this.loggedUser.chiaveSesso && this.loggedUser.chiaveSesso === 'F' ? true : false;
            this.userAcknowledges = userAck;
            this.isImpersonate = isImpersonate;

            if (this._firstInit) {
              this._firstInit = false;
              this.getCampaignList();
            }
          }
        });
    // console.log(document.querySelectorAll('.cdk-focus-trap-anchor'));
    // (document.querySelectorAll('.cdk-focus-trap-anchor') as any).forEach((element: HTMLElement) => {
    //   console.log(element);
    //   element.setAttribute('aria-hidden', 'false');
    // })
  }

  ngOnDestroy() {
    this._unsubscribeSignal$.next();
    this._unsubscribeSignal$.unsubscribe();
  }

  // Ritorna il messaggio di benvenuto
  getWelcomeMessage() {
    let welcomeMessage = this.isFemale ? this.translate.instant('generic.WELCOME_F') : this.translate.instant('generic.WELCOME_M');

    if (this.loggedUser && this.loggedUser.forename) {
      welcomeMessage += ' ' + this.loggedUser.forename;
    }

    welcomeMessage += '!';

    return welcomeMessage;
  }

  setDropdownOptions(round: any, attendance: any) {
    if (round && attendance) {
      const now = new Date().getTime();
      const hasClosedBookingStatus = attendance.status && (attendance.status === 'YES' || attendance.status === 'NO' || attendance.status === 'DELIVERED');

      const isClosedBooking = hasRoundClosedBooking(round, attendance);

      // Data di fine prenotazione (impostata dall'admin)
      const maxReservationEndDate = moment(round.endDate);
      const maxReservationEndDateMonth = maxReservationEndDate.format('MMMM');
      const maxReservationEndDateDay = maxReservationEndDate.format('D');

      // Calcolo la data massima entro cui l'utente può modificare la data
      let maxEditReservationDateOfRound = moment(round.maxReservationEndDate);
      let maxEditReservationDate = moment(attendance.startDate);
      if (round.maxAllowedReservationMovingDate) {
        maxEditReservationDate = maxEditReservationDate.subtract((round.maxAllowedReservationMovingDate + 1), "days");
      } else {
        // Lato admin non è stato abilitato il termine di annullamento prenotazione, quindi cambio la pre-selezione della data di fine
        maxEditReservationDate = moment(round.endDate);
      }

      const maxEditReservationDateMonth = maxEditReservationDate.format('MMMM');
      const maxEditReservationDateDay = maxEditReservationDate.format('D');

      const maxEditReservationDateMonthOfRound = maxEditReservationDateOfRound.format('MMMM');
      const maxEditReservationDateDayOfRound = maxEditReservationDateOfRound.format('D');

      // Calcolo la data massima entro cui l'utente può annullare la prenotazione fatta.
      // La pre-seleziono con la data di fine prenotazione oppure
      let maxDeleteBookingDate = moment(attendance.startDate);

      // Giorni precedenti alla data dell'appuntamento selezionato dall'utente entro cui può annullare la prenotazione
      const daysBeforeCancelBooking = round.maxAllowedReservationDeletionDate;
      if (daysBeforeCancelBooking) {
        maxDeleteBookingDate = maxDeleteBookingDate.subtract((daysBeforeCancelBooking + 1), "days");
      } else {
        // Lato admin non è stato abilitato il termine di annullamento prenotazione, quindi cambio la pre-selezione della data di fine
        maxDeleteBookingDate = moment(round.rightMaxReservationDate);
      }

      const maxDeleteBookingDateMonth = maxDeleteBookingDate.format('MMMM');
      const maxDeleteBookingDateDay = maxDeleteBookingDate.format('D');

      const canCancelBooking = canUserCancelBooking(round, attendance);

      // Verifica se la data "Fine prenotazioni" è passata
      let isEndBookingDatePassed = false;

      if (round.rightMaxReservationDate) {
        isEndBookingDatePassed = new Date(round.rightMaxReservationDate).getTime() < now;
      }

      const canEditBookingDate = canUserEditBookingDate(round, attendance);

      // Controllo se sono prima della data di somministrazione - tempo indicato alla creazione del round
      // In questo caso posso modificare data e struttura anche a prenotazioni chiuse
      let isMaxEditOtherStructureDateDisabled = false;
      let bookingDateMaxEdit;
      let today = new Date();
      if (round.maxAllowedReservationMovingDateOtherVenue) {
        let tmpDate = new Date(attendance.startDate);
        bookingDateMaxEdit = new Date(tmpDate.getFullYear(), tmpDate.getMonth(), tmpDate.getDate() - (round.maxAllowedReservationMovingDateOtherVenue + 1));
        if (moment(today.toDateString()).isAfter(moment(bookingDateMaxEdit.toDateString()))) {
          isMaxEditOtherStructureDateDisabled = true;
        }
      }
      if (!bookingDateMaxEdit) {
        // In caso non sia specificata una data di modifica sede e struttura, quella massima dipende dalle info del round
        bookingDateMaxEdit = new Date(round.maxReservationEndDate);
        // bookingDateMaxEdit = new Date(round.maxReservationEndDate)
        if (moment(today.toDateString()).isAfter(moment(bookingDateMaxEdit.toDateString()))) {
          isMaxEditOtherStructureDateDisabled = true;
        }
      }
      attendance.dropdownOptions = [
        {
          label: this.translate.instant('component.cardTicket.manageBookingOptions.EDIT_STRUCTURE_AND_DATE'),
          onClick: (currentSelect: any) => {
            this.editBookingStructureAndDate(currentSelect, round);
          },
          isDisabled: hasClosedBookingStatus || isMaxEditOtherStructureDateDisabled,
          icon: 'assets/img/icons/building.svg',
          subtitle: 'Entro il ' + (bookingDateMaxEdit ? moment(bookingDateMaxEdit).format('D MMMM') : '')
        }, {
          // Modifica data di prenotazione
          label: this.translate.instant('component.cardTicket.manageBookingOptions.EDIT_DATE'),
          onClick: (currentSelect: any) => {
            this.editBookingDate(currentSelect, round);
          },
          isDisabled: hasClosedBookingStatus || !canEditBookingDate,
          icon: 'assets/img/icons/clock.svg',
          subtitle: 'Entro il ' + maxEditReservationDateDay + ' ' + maxEditReservationDateMonth
        }, {
          // Annulla prenotazione
          label: this.translate.instant('component.cardTicket.manageBookingOptions.CANCEL_BOOKING'),
          onClick: (currentSelect: any) => {
            this.removeTicket(currentSelect, round)
          },
          isDisabled: hasClosedBookingStatus || isClosedBooking || !canCancelBooking,
          icon: 'assets/img/icons/trash-2.svg',
          subtitle: 'Entro il ' + maxDeleteBookingDateDay + ' ' + maxDeleteBookingDateMonth
        }
      ];
    }
  }


  getCampaignList() {
    this.isLoadingCampaignList = true;

    this.rentService.getCampaignListForEntitled()
      .pipe(takeUntil(this._unsubscribeSignal$.asObservable()))
      .subscribe((result) => {
        if (!result || result.error) {
          this.dispatchModal('021', result.error || '');
          this.isLoadingCampaignList = false;
        } else {
          this.campaignList = result.response;

          this.getReservationEntitledData();
        }
      }, (err) => {
        if (err && err.message) {
          this.dispatchModal('022', err.message);
        }
        this.isLoadingCampaignList = false;
      });
  }

  getReservationEntitledData() {
    this.rentService.getReservationEntitledData()
      .pipe(takeUntil(this._unsubscribeSignal$.asObservable()))
      .subscribe((result) => {
        if (!result || result.error) {
          this.dispatchModal('021', result.error || '');
          this.isLoadingCampaignList = false;
        } else {
          this.reservationEntitledData = result.response;
          if (!this.reservationEntitledData || (this.reservationEntitledData && (!this.reservationEntitledData.roundData || !this.reservationEntitledData.roundData.length))) {
            this.modalService.open('user-deleted');
            // Se non ci sono dati, mostro un errore
            // this.dispatchModal('091', 'NO_LONGER_ENABLED_TO_BOOKING');
          }

          this.getDatas();
        }
      }, (err) => {
        if (err && err.message) {
          this.dispatchModal('022', err.message);
        }
        this.isLoadingCampaignList = false;
      });
  }

  /**
   * @description Mostra una modale con titolo e messaggio
   * @param modalId
   * @param title
   * @param message
   */
  dispatchMessageModal(title: string, message: string) {
    const messageObj = getModalMessageData(this.translate, title, message);
    this.appStore.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
  }

  /**
   * @description Mostra una modale con un messaggio di errore
   * @param modalId
   * @param error
   */
  dispatchModal(modalId: string, error: string) {
    const messageObj = getWarningModal(this.translate, modalId, error);
    this.appStore.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
  }

  getDatas() {
    this.infoTickets = [];

    if (this.reservationEntitledData && this.reservationEntitledData.roundData) {

      // Flusso Round -> Utente -> Prenotazioni in carico
      this.reservationEntitledData.roundData.forEach((round) => {

        let campaignIndex = -1;
        for (let iCamp = 0; iCamp < this.campaignList.length; iCamp++) {
          if (this.campaignList[iCamp].campaignId === round.campaignId) {
            campaignIndex = iCamp;
            break;
          }
        }


        let roundMaxDate;
        let venueMaxDate;
        let finalMaxDate;

        if (round.roundIdMaxVenueBookingEndDateWithOpenBookingIdsMap) {
          roundMaxDate = new Date(round.maxReservationEndDate);
          venueMaxDate = new Date(round.roundIdMaxVenueBookingEndDateWithOpenBookingIdsMap);

          finalMaxDate = roundMaxDate > venueMaxDate ? roundMaxDate : venueMaxDate;
        }

        // C'è una correlazione round / campagna per evitare dati sporchi
        if (campaignIndex !== -1) {
          // Aggiungo round e campagna

          this.infoTickets.push({
            round: {
              ...round,
              entitledToProcess: [],
              entitledProcessed: [],
              entitledToProcessExpired: [],
              rightMaxReservationDate: finalMaxDate ? finalMaxDate : round.maxReservationEndDate
            },
            campaign: this.campaignList[campaignIndex]
          });
        }

      });

      this.venues = [];

      // Recupero la sede puntuale di prenotazione
      this.infoTickets.forEach((infoTicket, i) => {
        if (infoTicket.round && infoTicket.round.entitled && infoTicket.round.entitled.length) {

          infoTicket.round.entitled.forEach((entitled, iEnt) => {

            if (entitled) {
              if (entitled.user) {
                const birthDate = getBirthDateFromCF(entitled.user.codFisc);
                if (birthDate) {
                  entitled.user.birthDate = birthDate.toString();
                }
              }

              if (entitled.attendances) {
                entitled.attendances.forEach(attendance => {

                  let venueIndex = this.venues.findIndex(a => a.id === attendance.venueId);

                  if (venueIndex !== -1) {
                    this.venues[venueIndex].positions.push({
                      infoTicketIndex: i,
                      entitleIndex: iEnt
                    });
                  } else {
                    this.venues.push({
                      id: attendance.venueId,
                      positions: [{
                        infoTicketIndex: i,
                        entitleIndex: iEnt
                      }]
                    });
                  }
                });
              }
            } else if (!entitled) {
              // In caso di entitled null lo rimuovo
              infoTicket.round.entitled.splice(iEnt, 1);
            }
          });
        }

      });

      let validVenues = this.venues.filter((x: any) => {
        return x.id;
      })

      if (validVenues && validVenues.length > 0) {
        this.getVenues(validVenues);
      } else {
        this.splitEntitleds();
        this.isLoadingCampaignList = false;
      }

    } else {
      this.splitEntitleds();
      this.isLoadingCampaignList = false;
    }
  }

  getVenues(validVenues: any) {
    if (validVenues && validVenues.length > 0) {
      this.rentService.getVenueById(validVenues[0].id)
        .pipe(takeUntil(this._unsubscribeSignal$.asObservable()))
        .subscribe((result) => {
          if (!result || result.error) {
            this.dispatchModal('023', result.error || '');
          } else {

            validVenues[0].positions.forEach((position: any) => {
              this.infoTickets[position.infoTicketIndex].round.entitled[position.entitleIndex].venue = result.response;
            });

            validVenues.splice(0, 1);
            this.getVenues(validVenues);
          }
        }, (err) => {
          if (err && err.message) {
            this.dispatchModal('024', err.message);
          }
          this.isLoadingCampaignList = false;
        });

    } else {
      this.splitEntitleds();
      this.isLoadingCampaignList = false;
    }

  }

  splitEntitleds() {
    this.infoTickets.forEach(infoTicket => {
      if (infoTicket.round.entitled && infoTicket.round.entitled.length) {
        infoTicket.round.entitled.forEach(entitled => {
          // Da prenotare
          if (!entitled || !entitled.attendances || entitled.attendances.length === 0) {
            if (this.isClosedBooking(infoTicket.round) && !infoTicket.round.existsVenueWithOpenBookingIds) {
              infoTicket.round.entitledToProcessExpired.push(entitled);
            } else {
              infoTicket.round.entitledToProcess.push(entitled);
            }
          } else {
            entitled.attendances.forEach(attendance => {
              // Status della prenotazione da verificare
              if (attendance && !infoTicket.round.existsVenueWithOpenBookingIds && (attendance.status === 'YES' || attendance.status === 'NO' || attendance.status === 'DELIVERED' || this.isClosedBooking(infoTicket.round))) {
                infoTicket.round.entitledProcessed.push({
                  ...entitled,
                  attendances: [attendance]
                });
              } else {
                if (this.isClosedBooking(infoTicket.round) && !infoTicket.round.existsVenueWithOpenBookingIds) {
                  infoTicket.round.entitledToProcessExpired.push({
                    ...entitled,
                    attendances: [attendance]
                  });
                } else {
                  infoTicket.round.entitledToProcess.push({
                    ...entitled,
                    attendances: [attendance]
                  });
                }
              }

              if (attendance && attendance.status) {
                if (attendance.status == "RESERVATION_DELETED" || attendance.status == "RESET_MAIL_SENT") {
                  attendance.forceDisable = true;
                }
                // C'è uno status, quindi posso impostare le option del dropdown
                this.setDropdownOptions(infoTicket.round, attendance);
              }
            });

          }
        });
        if (infoTicket.round.entitledToProcess && infoTicket.round.entitledToProcess.length) {
          let familiarNumber = 0;
          infoTicket.round.entitledToProcess.forEach((element: InfoEntitled) => {
            if (element.user.userId != this.loggedUser?.userId) {
              familiarNumber += 1;
            }
          })
          infoTicket.round.entitledToProcess.forEach((x: InfoEntitled) => {
            if (x.user.userId == this.loggedUser?.userId && (infoTicket.round.existsVenueWithOpenBookingIds || !this.isClosedBooking(infoTicket.round))
              && infoTicket.campaign.CAN_ADD_FAMILITY_MEMBERS && familiarNumber < infoTicket.campaign.MAX_FAMILY_MEMBERS_CAP) {
              let allowedFamiliars = '';
              if (infoTicket.campaign && infoTicket.campaign.ALLOWED_FAMILIY_MEMBERS && infoTicket.campaign.ALLOWED_FAMILIY_MEMBERS.length) {
                for (let i = 0; i < infoTicket.campaign.ALLOWED_FAMILIY_MEMBERS.length; i++) {
                  allowedFamiliars += this.translate.instant('admin.addCampaignCustomerCap.' + infoTicket.campaign.ALLOWED_FAMILIY_MEMBERS[i]) + ', ';
                }
                allowedFamiliars = allowedFamiliars.substring(0, allowedFamiliars.length - 2);
              }
              infoTicket.round.entitledToProcess.push(
                {
                  user: x.user,
                  isFamiliarToAdd: true,
                  familiarsTypeToChoose: allowedFamiliars
                }
              );
            }
          })
        }
      }

    });
  }

  editTicket(ticket: InfoAttendance) {

  }

  /**
   * Check if the booking is close - same as the control in card-ticket for cancel a reservation
   * @param round
   * @returns
   */
  isClosedBooking(round: InfoRound) {
    if (round && (round.maxReservationEndDate || round.minReservationEndDate)) {

      const now = new Date().getTime();

      if (round.minReservationEndDate && !round.maxReservationEndDate) {
        // Presente solo il minimo
        return new Date(round.minReservationEndDate).getTime() > now;
      } else if (round.maxReservationEndDate && !round.minReservationEndDate) {
        // Presente solo il massimo
        return new Date(round.maxReservationEndDate).getTime() < now;
      } else {
        // Presente la finestra di prenotazione
        return new Date(round.minReservationEndDate).getTime() > now ||
          new Date(round.maxReservationEndDate).getTime() < now;
      }
    } else return true;
  }

  closeDeleteBookingModal(confirm?: boolean) {
    this.modalService.close('deleteBooking');

    if (confirm && this.attendanceToDelete) {
      this.isLoadingCampaignList = true;

      this.rentService.deleteReservationForEntitled(this.attendanceToDelete.attendanceId)
        .pipe(takeUntil(this._unsubscribeSignal$.asObservable()))
        .subscribe((result) => {
          this.attendanceToDelete = undefined;
          if (!result || result.error) {
            this.dispatchModal('045', result.error || '');
            this.isLoadingCampaignList = false;
          } else {
            this.getCampaignList();
          }
        }, (err) => {
          if (err && err.message) {
            this.dispatchModal('046', err.message);
          }
          this.attendanceToDelete = undefined;
          this.isLoadingCampaignList = false;
        });

    } else {
      this.attendanceToDelete = undefined;
    }
  }

  removeTicket(ticket: InfoAttendance, round?: any) {
    let bookedUser: any[] = [];
    // Partecipanti al round
    let members = round.entitled;
    this.selectedFamilyForReservation = [];
    // C'è anche la card per aggiungere familiari dentro, la filtro e porto fuori lo user
    members.forEach((item: any) => {
      if (!item.isFamiliarToAdd && item.attendances && item.attendances.length) {
        item.isChecked = false;
        // seleziono per la modifica solo le persone nella stessa sede e giorno della persona selezionata
        let currentDate = ticket.startDate ? new Date(ticket.startDate).setHours(0, 0) : null;
        let itemDate = item?.attendances?.length && item.attendances[0] && item.attendances[0].startDate ? new Date(item.attendances[0].startDate).setHours(0, 0) : null;
        if (
          (ticket?.venue?.venueId && item?.venue?.venueId && ticket.venue.venueId == item.venue.venueId) && (currentDate && itemDate && currentDate == itemDate)) {
          bookedUser.push(item)
        }
      }
    });

    this.selectedFamilyForDelete = [];
    if (bookedUser && bookedUser.length) {
      this.familyAttendanceToDelete = JSON.parse(JSON.stringify(bookedUser));
      this.modalService.open('family-reservation-delete');
    } else {
      this.attendanceToDelete = ticket;
      this.modalService.open('deleteBooking');
    }
  }

  openVenue(entitled: InfoEntitled, id: string) {
    this.cardTicketId = id;
    this.selectedVenueTab = 0;
    if (entitled) {
      this.selectedVenue = entitled.venue;
      if (this.selectedVenue && this.selectedVenue.latitude && this.selectedVenue.longitude) {
        this.venueMapData = {
          lat: parseFloat(this.selectedVenue.latitude),
          lng: parseFloat(this.selectedVenue.longitude)
        };
      }
    }

    this.sidebarService.setShowNav(true);

    setTimeout(() => {
      this.el.nativeElement.querySelector('#sidebar-close-button').focus();
    }, 100);
  }

  onVenueTabClick(index: number) {
    this.selectedVenueTab = index;
  }

  goToNewReservation(campaign: InfoCampaign, round: InfoRound, ticket: InfoEntitled) {

    this.isLoadingCampaignList = true;

    this.rentService.hasUsersAlreadyABookingForSameCampaign(campaign.campaignId, round.roundId, ticket.user.userId)
      .pipe(takeUntil(this._unsubscribeSignal$.asObservable()))
      .subscribe((result) => {
        this.isLoadingCampaignList = false;
        if (!result || result.error) {
          this.dispatchModal('045', result.error || '');
        } else {
          if (!result.response || result.response[ticket.user.userId]) {
            this.dispatchModal('045', 'USER_HAS_ALREADY_A_BOOKING_FOR_SAME_CAMPAIGN');
          } else {
            // Controllo privacy familiare
            if ((ticket.user as any).parentCodFisc) {
              this.newReservationRound = round;
              this.newReservationTicket = ticket;
              this.checkParentPrivacy();
            } else {
              this.redirectService.goToNewReservation(round.roundId, ticket.user.userId);
            }
          }
        }
      }, (err) => {
        if (err && err.message) {
          this.dispatchModal('046', err.message);
        }
        this.isLoadingCampaignList = false;
      });
  }

  // controlla se ho più di una prenotazione nel round
  checkMoreReservations(entitled: any[]) {
    let moreReservations: any[] = [];
    let countReservations = 0;
    for (let i = 0; i < entitled.length; i++) {
      if (entitled[i].attendances && entitled[i].attendances.length) {
        countReservations++;
      }
    }
    if (countReservations > 1) {
      return moreReservations;
    }
    return null;
  }

  editBookingStructureAndDate(currentSelect: any, round: any) {
    this.isEditFamilyDate = false;
    let moreReservations = this.checkMoreReservations(round.entitled);
    if (moreReservations) {
      this.openEditFamilyReservationModal(round, currentSelect);
    } else {
      this.redirectService.editReservation(currentSelect.roundId, currentSelect.userId, currentSelect.attendanceId, true, true);
    }
  }

  editBookingDate(currentSelect: any, round?: any) {
    let moreReservations = this.checkMoreReservations(round.entitled);
    if (moreReservations) {
      this.isEditFamilyDate = true;
      this.openEditFamilyReservationModal(round, currentSelect);
    } else {
      this.redirectService.editReservation(currentSelect.roundId, currentSelect.userId, currentSelect.attendanceId, false, true);
    }
  }

  goToFAQ() {
    if (this.isEnel) {
      this.router.navigate(['user/createTicket']);
    } else {
      this.redirectService.goToUserFAQPage();
    }
  }

  closeVenueCard(fromKeyboard?: boolean) {
    this.selectedVenue = undefined;
    this.sidebarService.setShowNav(false);
    if (fromKeyboard) {
      setTimeout(() => {
        this.el.nativeElement.querySelector("#" + this.cardTicketId).focus();
      }, 100);
    }

  }

  checkParentPrivacy() {
    if (this.newReservationTicket) {
      const codFisc = (this.newReservationTicket.user as any).codFisc;
      // Privacy accettata
      if (this.userAcknowledges && this.userAcknowledges.data &&
        this.userAcknowledges.data[`CAMPAIGN_MANAGER_PRIVACY_ACCEPTED_${codFisc}`] &&
        this.userAcknowledges.data[`CAMPAIGN_MANAGER_PRIVACY_ACCEPTED_${codFisc}`].length &&
        this.newReservationRound) {
        this.redirectService.goToNewReservation(this.newReservationRound.roundId, this.newReservationTicket.user.userId);
      } else {
        const birthdate = getBirthDateFromCF(codFisc);
        this.isUnderageModal = birthdate ? checkUnderCustomYearsOld(birthdate, 18) : false;
        this.openPrivacyModal();
      }
    }
  }

  openEditDataModal() {
    this.modalService.open('editDataModal');
  }

  closeEditDataModal() {
    this.modalService.close('editDataModal');
  }

  openPrivacyModal() {
    this.privacyCheck = false;
    this.modalService.open('privacyModal');
  }

  closePrivacyModal(confirm?: boolean) {
    if (confirm) {
      this.setPrivacyAck();
      this.isLoadingModalReservationList = true;
      this.familyListForModal.forEach(element => {
        if (element.codFisc == this.togglePrivacyUser.codFisc) {
          element.isChecked = true;
        }
      });
      this.togglePrivacyUser = null;
      this.isLoadingModalReservationList = false;
    } else {
      if (this.togglePrivacyUser) {
        this.isLoadingModalReservationList = true;
        this.familyListForModal.forEach(element => {
          if (element.codFisc == this.togglePrivacyUser.codFisc) {
            element.isChecked = false;
          }
        });
        this.togglePrivacyUser.isChecked = false;
        this.togglePrivacyUser = null;
        this.cdr.detectChanges();
        this.isLoadingModalReservationList = false;
      }
      this.modalService.close('privacyModal');
    }
  }

  onCheckPrivacy(value: boolean) {
    this.privacyCheck = value;
  }

  goToPrivacyPage() {
    this.redirectService.goToPrivacyPage(this.isEnel);
  }

  // Aggiorna le ack dell'utente salvando il flag della privacy
  setPrivacyAck() {
    if (this.togglePrivacyUser) {
      this.togglePrivacyUser.isChecked = true;
      this.selectedFamilyForReservation.push(this.togglePrivacyUser);
      this.familyListForModal.forEach(element => {
        if (element.codFisc == this.togglePrivacyUser.codFisc) {
          element.isChecked = true;
        }
      });
      let acks: UserAcknowledges = JSON.parse(JSON.stringify(this.userAcknowledges));
      const codFisc = this.togglePrivacyUser.codFisc;
      acks.data[(`CAMPAIGN_MANAGER_PRIVACY_ACCEPTED_${codFisc}`)] = moment().toISOString();

      this.anagService.updateUserAcknowledges(acks)
        .pipe(takeUntil(this._unsubscribeSignal$.asObservable()))
        .subscribe((result: any) => {
          if (result && result.error) {
            this.dispatchModal('133', result.error || '');
          } else {
            this.appStore.dispatch(AuthActions.SetUserAcknowledges({ payload: acks }));
            this.closePrivacyModal();
          }
          this.togglePrivacyUser = null;
        });
    } else if (this.newReservationTicket) {
      this.isLoadingCampaignList = true;

      let acks: UserAcknowledges = JSON.parse(JSON.stringify(this.userAcknowledges));
      const codFisc = (this.newReservationTicket.user as any).codFisc;
      acks.data[(`CAMPAIGN_MANAGER_PRIVACY_ACCEPTED_${codFisc}`)] = moment().toISOString();

      this.anagService.updateUserAcknowledges(acks)
        .pipe(takeUntil(this._unsubscribeSignal$.asObservable()))
        .subscribe((result: any) => {
          if (result && result.error) {
            this.dispatchModal('133', result.error || '');
          } else {
            this.appStore.dispatch(AuthActions.SetUserAcknowledges({ payload: acks }));
            this.isLoadingCampaignList = false;

            if (this.newReservationRound && this.newReservationTicket) {
              this.closePrivacyModal();
              this.redirectService.goToNewReservation(this.newReservationRound.roundId, this.newReservationTicket.user.userId);
            }
          }
        });
    }
  }

  goToAddFamiliar(infoTicket: InfoRoundTicket) {
    this.redirectService.goToAddFamiliar(infoTicket.round.campaignId, infoTicket.round.roundId);
  }

  goToTicket() {
    this.redirectService.goToCreateUserTicket();
  }

  // apri la modale per la prenotazione massiva dei familiari
  openFamilyReservationModal(campaign: any, round: any) {
    this.familyReservationRoundId = round.roundId;
    // Partecipanti al round
    let members = round.entitledToProcess;

    // svuoto i selezionati e la lista
    this.familyListForModal = [];
    this.selectedFamilyForReservation = [];
    let tmpUsersIds: any[] = [];
    // C'è anche la card per aggiungere familiari dentro, la filtro e porto fuori lo user
    members.forEach((item: any) => { if (!item.isFamiliarToAdd) { tmpUsersIds.push(item.user.userId) } });

    this.isLoadingModalReservationList = true;

    this.modalService.open("familyReservation");

    this.rentService.hasUsersAlreadyABookingForSameCampaign(campaign.campaignId, round.roundId, tmpUsersIds)
      .pipe(takeUntil(this._unsubscribeSignal$.asObservable()))
      .subscribe((result) => {
        this.isLoadingCampaignList = false;
        if (!result || result.error) {
          this.dispatchModal('045', result.error || '');
        } else {
          tmpUsersIds.forEach((userId: string) => {
            if (!result.response[userId]) {
              let userData = members.find((item: any) => item.user && item.user.userId == userId);
              userData.user.isChecked = false;
              this.familyListForModal.push(userData.user);
            }
          })

        }
        if (!this.familyListForModal || !this.familyListForModal.length) {
          this.modalService.close("familyReservation");
          this.dispatchWarningModal("CR042", "NO_FAMILY_BOOKABLE");
        }
        this.isLoadingModalReservationList = false;
      }, (err) => {
        if (err && err.message) {
          this.dispatchModal('046', err.message);
        }
        this.isLoadingModalReservationList = false;
      });
  }

  toggleFamilyReservationCheckbox(person: any) {
    // Controllo se è stata accettata la privacy per il familiare, (es familiare aggiunto lato cliente)
    if (person && person.parentCodFisc && this.userAcknowledges && !this.userAcknowledges.data[`CAMPAIGN_MANAGER_PRIVACY_ACCEPTED_${person.codFisc}`]) {
      person.isChecked = false;
      this.selectedFamilyForReservation = this.selectedFamilyForReservation.filter((tmp: any) => tmp.codFisc != person.codFisc);
      const birthdate = getBirthDateFromCF(person.codFisc);
      this.isUnderageModal = birthdate ? checkUnderCustomYearsOld(birthdate, 18) : false;
      this.togglePrivacyUser = person;
      this.openPrivacyModal();
    } else {
      if (person.isChecked) {
        person.isChecked = false;
        this.selectedFamilyForReservation = this.selectedFamilyForReservation.filter((tmp: any) => tmp.codFisc != person.codFisc);
      } else {
        person.isChecked = true;
        this.selectedFamilyForReservation.push(person);
      }
    }
  }

  closeFamilyReservationModal(confirm?: boolean) {
    this.modalService.close("familyReservation");
    if (confirm && this.selectedFamilyForReservation.length) {
      sessionStorage.setItem('familyMembers', JSON.stringify(this.selectedFamilyForReservation));
      if (this.selectedFamilyForReservation.length > 1) {
        this.redirectService.goToNewFamilyReservation(this.familyReservationRoundId);
      } else {
        this.redirectService.goToNewReservation(this.familyReservationRoundId, this.selectedFamilyForReservation[0].userId);
      }
    } else {
      if (this.selectedFamilyForReservation && this.selectedFamilyForReservation.length) {
        for (let i = 0; i < this.selectedFamilyForReservation.length; i++) {
          if (this.selectedFamilyForReservation[i].isChecked) {
            this.selectedFamilyForReservation[i].isChecked = false;
          }
        }
      }
      this.selectedFamilyForReservation = [];
    }
  }


  closeEditDateFamilyReservationModal(confirm?: boolean) {
    this.modalService.close("editFamilyReservation");
    if (confirm && this.selectedFamilyForReservation.length) {
      sessionStorage.setItem('familyMembers', JSON.stringify(this.selectedFamilyForReservation));
      if (this.selectedFamilyForReservation.length > 1) {
        this.router.navigate([`/user/familyReservation/${this.familyReservationRoundId}/false/true`]);
      } else if (this.selectedFamilyForReservation && this.selectedFamilyForReservation[0] && this.selectedFamilyForReservation[0].user.userId) {
        this.redirectService.editReservation(this.familyReservationRoundId, this.selectedFamilyForReservation[0].user.userId, this.selectedFamilyForReservation[0].user.attendanceId, false, true);
      }
    } else {
      if (this.selectedFamilyForReservation && this.selectedFamilyForReservation.length) {
        for (let i = 0; i < this.selectedFamilyForReservation.length; i++) {
          if (this.selectedFamilyForReservation[i].isChecked) {
            this.selectedFamilyForReservation[i].isChecked = false;
          }
        }
      }
      this.selectedFamilyForReservation = [];
    }
  }

  // apri la modale per la prenotazione massiva dei familiari
  openEditFamilyReservationModal(round: any, selectedUser: any) {
    this.familyReservationRoundId = round.roundId;
    // Partecipanti al round
    let members = round.entitled;
    // svuoto i selezionati e la lista
    this.familyListForModal = [];
    this.selectedFamilyForReservation = [];
    // C'è anche la card per aggiungere familiari dentro, la filtro e porto fuori lo user
    members.forEach((item: any) => {
      if (!item.isFamiliarToAdd && item.attendances && item.attendances.length) {
        item.isChecked = false;
        // seleziono per la modifica solo le persone nella stessa sede e giorno della persona selezionata
        let currentDate = selectedUser.startDate ? new Date(selectedUser.startDate).setHours(0, 0) : null;
        let itemDate = item?.attendances?.length && item.attendances[0] && item.attendances[0].startDate ? new Date(item.attendances[0].startDate).setHours(0, 0) : null;
        if ((selectedUser?.venueId && item?.venue?.venueId && selectedUser.venueId == item.venue.venueId) || (selectedUser?.venue?.venueId && item?.venue?.venueId && selectedUser.venue.venueId == item.venue.venueId)
          && (currentDate && itemDate && currentDate == itemDate)) {
          this.familyListForModal.push(item)
        }
      }
    });
    this.modalService.open("editFamilyReservation");
  }

  toggleEditFamilyReservationCheckbox(personData: any) {
    if (personData.isChecked) {
      personData.isChecked = false;
      this.selectedFamilyForReservation = this.selectedFamilyForReservation.filter((tmp: any) => tmp.user.codFisc != personData.user.codFisc);
    } else {
      personData.isChecked = true;
      this.selectedFamilyForReservation.push(personData);
    }
  }

  closeEditFamilyReservationModal(confirm?: boolean) {
    this.modalService.close("editFamilyReservation");
    if (confirm && this.selectedFamilyForReservation.length) {
      sessionStorage.setItem('familyMembers', JSON.stringify(this.selectedFamilyForReservation));

      if (this.selectedFamilyForReservation.length > 1) {
        this.router.navigate([`/user/familyReservation/${this.familyReservationRoundId}/true/true`]);
      } else if (this.selectedFamilyForReservation && this.selectedFamilyForReservation[0] && this.selectedFamilyForReservation[0].user.userId) {
        this.redirectService.editReservation(this.familyReservationRoundId, this.selectedFamilyForReservation && this.selectedFamilyForReservation[0] && this.selectedFamilyForReservation[0].user.userId, this.selectedFamilyForReservation && this.selectedFamilyForReservation[0] && this.selectedFamilyForReservation[0].user.attendanceId, true, true);
      }
    } else {
      if (this.selectedFamilyForReservation && this.selectedFamilyForReservation.length) {
        for (let i = 0; i < this.selectedFamilyForReservation.length; i++) {
          if (this.selectedFamilyForReservation[i].isChecked) {
            this.selectedFamilyForReservation[i].isChecked = false;
          }
        }
      }
      this.selectedFamilyForReservation = [];
    }
  }

  toggleFamilyDeleteCheckbox(person: any) {
    if (person.isChecked) {
      person.isChecked = false;
      this.selectedFamilyForDelete = this.selectedFamilyForDelete.filter((tmp: any) => tmp.userId != person.userId);
    } else {
      person.isChecked = true;
      this.selectedFamilyForDelete.push(person);
    }
  }


  // Eliminazione massiva delle prenotazioni
  closeDeleteFamilyReservation(confirm?: boolean) {
    this.modalService.close('family-reservation-delete');
    if (confirm && this.selectedFamilyForDelete && this.selectedFamilyForDelete.length) {
      this.isLoadingCampaignList = true;
      let attendanceIds = this.selectedFamilyForDelete.map((userData: any) => userData && userData.attendanceId);

      this.rentService.deleteReservationForEntitled(attendanceIds)
        .pipe(takeUntil(this._unsubscribeSignal$.asObservable()))
        .subscribe((result) => {
          this.attendanceToDelete = undefined;
          if (!result || result.error) {
            this.dispatchModal('045', result.error || '');
            this.isLoadingCampaignList = false;
          } else {
            this.getCampaignList();
          }
        }, (err) => {
          if (err && err.message) {
            this.dispatchModal('046', err.message);
          }
          this.attendanceToDelete = undefined;
          this.isLoadingCampaignList = false;
        });
    } else {
      if (this.selectedFamilyForDelete && this.selectedFamilyForDelete.length) {
        for (let i = 0; i < this.selectedFamilyForDelete.length; i++) {
          if (this.selectedFamilyForDelete[i].isChecked) {
            this.selectedFamilyForDelete[i].isChecked = false;
          }
        }
      }
      this.selectedFamilyForDelete = [];
    }
    this.modalService.open('family-reservation-delete-after-confirm');
  }

  closeDeleteFamilyReservationAfterConfirm() {
    this.modalService.close('family-reservation-delete-after-confirm');
  }

  canBookForFamily(entitledToProcess: any[]) {
    let tmp = entitledToProcess.filter((item: any) => !item.isFamiliarToAdd);
    if (tmp.length > 1) {
      return true;
    }
    return false;
  }

  anyNotNull() {
    let test = false;
    this.ALL_KEYS.forEach((key: string) => {
      if (this.selectedVenue.details && this.selectedVenue.details[key] != null) {
        test = true;
      }
    })
    return test;
  }

  /**
   * @description Mostra una modale con un messaggio di errore
   * @param modalId
   * @param error
   */
  dispatchWarningModal(modalId: string, error: string) {
    const messageObj = getWarningModal(this.translate, modalId, error);
    this.appStore.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
  }
}

import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';

import * as CoreActions from "../../../core/ngrx/core.actions";
import * as fromApp from "../../../ngrx/app.reducers";
import { InfoCampaign, InfoRound, InfoVenue } from 'atfcore-commonclasses/bin/classes/campaignmanager';

import { RedirectService } from 'src/app/shared/services/redirect.service';
import { getModalMessageData, getWarningModal } from 'src/app/utils/utils';
import { ScrollTo } from 'src/app/core/services/scroll-to.service';
import { RentService } from 'src/app/structure/services/rent.service';
import { DropdownItem } from 'src/app/shared/models/dropdown.model';
import { EngagementService, InfoRequest } from '../../services/engagement.service';
import { SupplierService } from '../../services/supplier.service';
import { showIf } from 'src/app/utils/animation.utils';

@Component({
  selector: 'app-admin-campaign-pending-requests-details',
  templateUrl: './campaignPendingRequests.component.html',
  styleUrls: ['./campaignPendingRequests.component.scss'],
  animations: [showIf]
})
export class AdminCampaignPendingRequestsComponent implements OnInit, OnDestroy {

  campaignId: string = '';
  campaign?: InfoCampaign;

  venueId: string = '';
  venue?: InfoVenue;

  roundId: string = '';
  round?: InfoRound;

  isLoadingBase: boolean = true;
  isLoadingCount: boolean = true;
  isLoading: boolean = true;

  requests: InfoRequest[] = [];
  requestsCount: number = 0;

  searchVenue?: string;
  searchStructure?: string;
  searchCode?: string;

  selectedProvince?: DropdownItem;
  clearSelectedProvince: Subject<void> = new Subject<void>();

  isLoadingCities: boolean = false;
  selectedCity?: DropdownItem;
  clearSelectedCity: Subject<void> = new Subject<void>();

  searchCampaign?: string;

  pageSelectedIndex: number = 0;
  readonly paginationLength: number = 25;

  private _unsubscribeSignal$: Subject<void> = new Subject();

  get withVenue(): boolean {
    return this.campaignId && this.roundId && this.venueId ? true : false;
  }

  requestVisibilities: DropdownItem[] = [
    {
      id: '0',
      name: 'admin.campaignPendingRequests.visibility.FILTER_ALL'
    }, {
      id: '1',
      name: 'admin.campaignPendingRequests.visibility.ONLY_APPROVE',
      content: 'EVENT_APPROVATION_SLOT_REQUEST'
    }, {
      id: '2',
      name: 'admin.campaignPendingRequests.visibility.ONLY_REVOKE',
      content: 'EVENT_REVOKE_SLOT_REQUEST'
    }, {
      id: '3',
      name: 'admin.campaignPendingRequests.visibility.ONLY_DESCRIPTIONS',
      content: 'EVENT_DESCRIPTION_UPDATE_REQUEST'
    }
  ];

  selectedRequestVisibility: DropdownItem = {
    id: '0',
    name: 'admin.campaignPendingRequests.visibility.FILTER_ALL'
  };

  fromDateRequests?: Date;
  toDateRequests?: Date;

  showAdvancedFilters: boolean = false;
  advancedFiltersCount: number = 0;

  readonly provinces: DropdownItem[] = [
    { id: "", name: "" },
    { id: "AG", name: "Agrigento", description: "Sicilia" },
    { id: "AL", name: "Alessandria", description: "Piemonte" },
    { id: "AN", name: "Ancona", description: "Marche" },
    { id: "AO", name: "Aosta", description: "Valle d'Aosta" },
    { id: "AQ", name: "L'Aquila", description: "Abruzzo" },
    { id: "AR", name: "Arezzo", description: "Toscana" },
    { id: "AP", name: "Ascoli-Piceno", description: "Marche" },
    { id: "AT", name: "Asti", description: "Piemonte" },
    { id: "AV", name: "Avellino", description: "Campania" },
    { id: "BA", name: "Bari", description: "Puglia" },
    { id: "BT", name: "Barletta-Andria-Trani", description: "Puglia" },
    { id: "BL", name: "Belluno", description: "Veneto" },
    { id: "BG", name: "Bergamo", description: "Lombardia" },
    { id: "BI", name: "Biella", description: "Piemonte" },
    { id: "BO", name: "Bologna", description: "Emilia Romagna" },
    { id: "BZ", name: "Bolzano", description: "Trentino Alto Adige" },
    { id: "BS", name: "Brescia", description: "Lombardia" },
    { id: "BR", name: "Brindisi", description: "Puglia" },
    { id: "CA", name: "Cagliari", description: "Sardegna" },
    { id: "CL", name: "Caltanissetta", description: "Sicilia" },
    { id: "CB", name: "Campobasso", description: "Molise" },
    { id: "CI", name: "Carbonia Iglesias", description: "Sardegna" },
    { id: "CE", name: "Caserta", description: "Campania" },
    { id: "CT", name: "Catania", description: "Sicilia" },
    { id: "CZ", name: "Catanzaro", description: "Calabria" },
    { id: "CH", name: "Chieti", description: "Abruzzo" },
    { id: "CO", name: "Como", description: "Lombardia" },
    { id: "CS", name: "Cosenza", description: "Calabria" },
    { id: "CR", name: "Cremona", description: "Lombardia" },
    { id: "KR", name: "Crotone", description: "Calabria" },
    { id: "CN", name: "Cuneo", description: "Piemonte" },
    { id: "EN", name: "Enna", description: "Sicilia" },
    { id: "FM", name: "Fermo", description: "Marche" },
    { id: "FE", name: "Ferrara", description: "Emilia Romagna" },
    { id: "FI", name: "Firenze", description: "Toscana" },
    { id: "FG", name: "Foggia", description: "Puglia" },
    { id: "FC", name: "Forli-Cesena", description: "Emilia Romagna" },
    { id: "FR", name: "Frosinone", description: "Lazio" },
    { id: "GE", name: "Genova", description: "Liguria" },
    { id: "GO", name: "Gorizia", description: "Friuli Venezia Giulia" },
    { id: "GR", name: "Grosseto", description: "Toscana" },
    { id: "IM", name: "Imperia", description: "Liguria" },
    { id: "IS", name: "Isernia", description: "Molise" },
    { id: "SP", name: "La-Spezia", description: "Liguria" },
    { id: "LT", name: "Latina", description: "Lazio" },
    { id: "LE", name: "Lecce", description: "Puglia" },
    { id: "LC", name: "Lecco", description: "Lombardia" },
    { id: "LI", name: "Livorno", description: "Toscana" },
    { id: "LO", name: "Lodi", description: "Lombardia" },
    { id: "LU", name: "Lucca", description: "Toscana" },
    { id: "MC", name: "Macerata", description: "Marche" },
    { id: "MN", name: "Mantova", description: "Lombardia" },
    { id: "MS", name: "Massa-Carrara", description: "Toscana" },
    { id: "MT", name: "Matera", description: "Basilicata" },
    { id: "VS", name: "Medio Campidano", description: "Sardegna" },
    { id: "ME", name: "Messina", description: "Sicilia" },
    { id: "MI", name: "Milano", description: "Lombardia" },
    { id: "MO", name: "Modena", description: "Emilia Romagna" },
    { id: "MB", name: "Monza-Brianza", description: ",Lombardia" },
    { id: "NA", name: "Napoli", description: "Campania" },
    { id: "NO", name: "Novara", description: "Piemonte" },
    { id: "NU", name: "Nuoro", description: "Sardegna" },
    { id: "OG", name: "Ogliastra", description: "Sardegna" },
    { id: "OR", name: "Oristano", description: "Sardegna" },
    { id: "PD", name: "Padova", description: "Veneto" },
    { id: "PA", name: "Palermo", description: "Sicilia" },
    { id: "PR", name: "Parma", description: "Emilia Romagna" },
    { id: "PV", name: "Pavia", description: "Lombardia" },
    { id: "PG", name: "Perugia", description: "Umbria" },
    { id: "PU", name: "Pesaro-Urbino", description: "Marche" },
    { id: "PE", name: "Pescara", description: "Abruzzo" },
    { id: "PC", name: "Piacenza", description: "Emilia Romagna" },
    { id: "PI", name: "Pisa", description: "Toscana" },
    { id: "PT", name: "Pistoia", description: "Toscana" },
    { id: "PN", name: "Pordenone", description: "Friuli Venezia Giulia" },
    { id: "PZ", name: "Potenza", description: "Basilicata" },
    { id: "PO", name: "Prato", description: "Toscana" },
    { id: "RG", name: "Ragusa", description: "Sicilia" },
    { id: "RA", name: "Ravenna", description: "Emilia Romagna" },
    { id: "RC", name: "Reggio-Calabria", description: "Calabria" },
    { id: "RE", name: "Reggio-Emilia", description: "Emilia Romagna" },
    { id: "RI", name: "Rieti", description: "Lazio" },
    { id: "RN", name: "Rimini", description: "Emilia Romagna" },
    { id: "RM", name: "Roma", description: "Lazio" },
    { id: "RO", name: "Rovigo", description: "Veneto" },
    { id: "SA", name: "Salerno", description: "Campania" },
    { id: "SS", name: "Sassari", description: "Sardegna" },
    { id: "SV", name: "Savona", description: "Liguria" },
    { id: "SI", name: "Siena", description: "Toscana" },
    { id: "SO", name: "Sondrio", description: "Lombardia" },
    { id: "TA", name: "Taranto", description: "Puglia" },
    { id: "TE", name: "Teramo", description: "Abruzzo" },
    { id: "TR", name: "Terni", description: "Umbria" },
    { id: "TO", name: "Torino", description: "Piemonte" },
    { id: "TP", name: "Trapani", description: "Sicilia" },
    { id: "TN", name: "Trento", description: "Trentino Alto Adige" },
    { id: "TV", name: "Treviso", description: "Veneto" },
    { id: "TS", name: "Trieste", description: "Friuli Venezia Giulia" },
    { id: "UD", name: "Udine", description: "Friuli Venezia Giulia" },
    { id: "VA", name: "Varese", description: "Lombardia" },
    { id: "VE", name: "Venezia", description: "Veneto" },
    { id: "VC", name: "Vercelli", description: "Piemonte" },
    { id: "VR", name: "Verona", description: "Veneto" },
    { id: "VV", name: "Vibo-Valentia", description: "Calabria" },
    { id: "VI", name: "Vicenza", description: "Veneto" },
    { id: "VT", name: "Viterbo", description: "Lazio" }
  ];

  cities: DropdownItem[] = [];

  constructor(
    private route: ActivatedRoute,
    private scrollTo: ScrollTo,
    private appStore: Store<fromApp.AppState>,
    private translate: TranslateService,
    private rentService: RentService,
    private engagementService: EngagementService,
    private redirectService: RedirectService,
    private supplierService: SupplierService
  ) {

  }

  ngOnInit() {
    this.scrollTo.header();
    this.campaignId = this.route.snapshot.paramMap.get('campaignId') || '';
    this.roundId = this.route.snapshot.paramMap.get('roundId') || '';
    this.venueId = this.route.snapshot.paramMap.get('venueId') || '';

    if (this.redirectService.isThisCurrentPage('toApprove')) {
      this.selectedRequestVisibility = this.requestVisibilities[1];
    } else if (this.redirectService.isThisCurrentPage('toRevoke')) {
      this.selectedRequestVisibility = this.requestVisibilities[2];
    } else if (this.redirectService.isThisCurrentPage('descriptions')) {
      this.selectedRequestVisibility = this.requestVisibilities[3];
    }

    if (this.campaignId && this.roundId && this.venueId) {
      this.getCampaign();
    } else {
      this.countVenueRequestsToProcess();
    }
  }

  goBackToRounds() {
    this.redirectService.goToAdminCampaignTabRounds(this.campaignId);
  }

  goBackToVenues() {
    this.redirectService.goToAdminCampaignRoundTabVenues(this.campaignId, this.roundId);
  }

  ngOnDestroy() {
    this._unsubscribeSignal$.next();
    this._unsubscribeSignal$.unsubscribe();
  }

  /**
   * @description Mostra una modale con titolo e messaggio
   * @param modalId
   * @param title
   * @param message
   */
  dispatchMessageModal(title: string, message: string) {
    const messageObj = getModalMessageData(this.translate, title, message);
    this.appStore.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
  }

  /**
   * @description Mostra una modale con un messaggio di errore
   * @param modalId
   * @param error
   */
  dispatchModal(modalId: string, error: string) {
    const messageObj = getWarningModal(this.translate, modalId, error);
    this.appStore.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
  }

  getCampaign() {

    this.isLoadingBase = true;
    this.isLoading = true;

    this.rentService.getCampaign(this.campaignId)
      .pipe(takeUntil(this._unsubscribeSignal$.asObservable()))
      .subscribe((result) => {
        if (!result || result.error) {
          this.dispatchModal('100', result.error || '');
          this.resetPagination();
        } else {
          this.campaign = result.response;
          this.getRound();
        }
      }, (err) => {
        if (err && err.message) {
          this.dispatchModal('101', err.message);
        }
        this.resetPagination();
      });
  }

  getRound() {

    this.rentService.getRound(this.roundId)
      .pipe(takeUntil(this._unsubscribeSignal$.asObservable()))
      .subscribe((result) => {
        if (!result || result.error) {
          this.dispatchModal('100', result.error || '');
          this.resetPagination();
        } else {
          this.round = result.response;
          this.getVenue();
        }
      }, (err) => {
        if (err && err.message) {
          this.dispatchModal('101', err.message);
        }
        this.resetPagination();
      });
  }

  getVenue() {

    this.rentService.getVenueById(this.venueId)
      .pipe(takeUntil(this._unsubscribeSignal$.asObservable()))
      .subscribe((result) => {
        if (!result || result.error) {
          this.dispatchModal('102', result.error || '');
          this.resetPagination();
        } else {
          this.venue = result.response;
          this.countVenueRequestsToProcess();
        }
        this.isLoadingBase = false;
      }, (err) => {
        if (err && err.message) {
          this.dispatchModal('103', err.message);
        }
        this.resetPagination();
      });
  }

  countVenueRequestsToProcess() {

    this.isLoadingCount = true;
    this.isLoading = true;

    const selectedProvince: string | undefined = this.selectedProvince && this.selectedProvince.id ? this.selectedProvince.id : undefined;
    const selectedCity: string | undefined = this.selectedCity && this.selectedCity.name ? this.selectedCity.name : undefined;

    this.engagementService.countVenueRequestsToProcessForAdmin(
      this.venueId, // venueId
      this.roundId, // roundId
      this.campaignId, // campaignIds
      this.selectedRequestVisibility?.content,
      this.fromDateRequests,
      this.toDateRequests,
      this.searchCampaign,
      this.searchVenue,
      this.searchStructure,
      this.searchCode,
      selectedProvince,
      selectedCity
    )
      .subscribe((result) => {

        if (!result || result.error) {
          this.dispatchModal('100', result.error || '');
          this.resetPagination();
        } else {
          this.requestsCount = result.response;

          if (this.requestsCount > 0) {
            this.getVenueRequestsToProcess();
          } else {
            this.resetPagination();
          }
          this.isLoadingCount = false;
        }
      }, (err) => {
        if (err && err.message) {
          this.dispatchModal('101', err.message);
        }
        this.resetPagination();
      });
  }

  getVenueRequestsToProcess(index?: number) {

    this.isLoading = true;

    const selectedProvince: string | undefined = this.selectedProvince && this.selectedProvince.id ? this.selectedProvince.id : undefined;
    const selectedCity: string | undefined = this.selectedCity && this.selectedCity.name ? this.selectedCity.name : undefined;

    const pageSelectedIndex = index ? index : 0;
    if (index === 0 || index) {
      this.scrollTo.element('admin-requests-anchor');
      this.pageSelectedIndex = index;
    }

    this.engagementService.getVenueRequestsToProcessForAdmin(
      undefined,
      this.venueId, // venueId
      this.roundId, // roundId
      this.campaignId, // campaignIds
      (pageSelectedIndex * this.paginationLength),
      this.paginationLength,
      this.selectedRequestVisibility?.content,
      this.fromDateRequests,
      this.toDateRequests,
      this.searchCampaign,
      this.searchVenue,
      this.searchStructure,
      this.searchCode,
      selectedProvince,
      selectedCity
    )
      .pipe(takeUntil(this._unsubscribeSignal$.asObservable()))
      .subscribe((result) => {

        if (!result || result.error) {
          this.dispatchModal('102', result.error || '');
          this.resetPagination();
        } else {
          this.requests = result.response;
          this.isLoading = false;
        }
      }, (err) => {
        if (err && err.message) {
          this.dispatchModal('103', err.message);
        }
        this.resetPagination();
      });
  }

  resetPagination() {
    this.isLoadingBase = false;
    this.isLoadingCount = false;
    this.isLoading = false;

    this.requests = [];
    this.requestsCount = 0;
  }

  onChangeRequestsVisibility(value: DropdownItem) {
    this.selectedRequestVisibility = value;
    this.pageSelectedIndex = 0;

    if (value) {
      switch (value.id) {
        case '0':
          this.redirectService.goToAdminPendingRequests(this.campaignId, this.roundId, this.venueId, true);
          break;
        case '1':
          this.redirectService.goToAdminPendingRequestsToApprove(this.campaignId, this.roundId, this.venueId, true);
          break;
        case '2':
          this.redirectService.goToAdminPendingRequestsToRevoke(this.campaignId, this.roundId, this.venueId, true);
          break;
        case '3':
          this.redirectService.goToAdminPendingRequestsDescriptions(this.campaignId, this.roundId, this.venueId, true);
          break;
      }
    }
    this.countVenueRequestsToProcess();
  }

  onChangeFromDateRequests(value: Date) {
    if (value) {
      this.fromDateRequests = new Date(value);
      this.fromDateRequests.setHours(0, 0, 0, 0);
    } else {
      this.fromDateRequests = undefined;
    }
    this.pageSelectedIndex = 0;
    this.countVenueRequestsToProcess();
  }

  onChangeToDateRequests(value: Date) {
    if (value) {
      this.toDateRequests = new Date(value);
      this.toDateRequests.setHours(23, 59, 59, 0);
    } else {
      this.toDateRequests = undefined;
    }
    this.pageSelectedIndex = 0;
    this.countVenueRequestsToProcess();
  }

  searchStructureChange(searchText?: string) {
    this.searchStructure = searchText;
  }

  searchVenueChange(searchText?: string) {
    this.searchVenue = searchText;
  }

  searchCodeChange(searchCode?: string) {
    this.searchCode = searchCode;
  }

  selectedProvinceChange(province?: DropdownItem) {
    this.selectedProvince = province;
    if (!this.selectedProvince || !this.selectedProvince.id) {
      this.selectedProvince = undefined;
      this.clearSelectedProvince.next();
    }

    this.getComuniList();
  }

  getComuniList() {
    this.isLoadingCities = true;
    this.selectedCity = undefined;
    this.clearSelectedCity.next();

    if (this.selectedProvince && this.selectedProvince.id) {
      this.supplierService.getComuniList(this.selectedProvince.id)
        .pipe(takeUntil(this._unsubscribeSignal$.asObservable()))
        .subscribe((result) => {
          const cities: DropdownItem[] = [];
          if (!result || result.error) {
            this.dispatchModal('140', result.error || '');
          } else {
            cities.push({
              id: '',
              name: ''
            });
            result.response.forEach(city => {
              cities.push({
                id: city.codice,
                name: city.denominazione
              });
            });
          }
          this.searchRequests();
          this.cities = cities;
          this.isLoadingCities = false;
        }, (err) => {
          if (err && err.message) {
            this.dispatchModal('141', err.message);
          }
          this.cities = [];
          this.isLoadingCities = false;
        });
    } else {
      this.cities = [];
      this.searchRequests();
      this.isLoadingCities = false;
    }
  }

  selectedCityChange(city?: DropdownItem) {
    this.selectedCity = city;
    if (!this.selectedCity || !this.selectedCity.id) {
      this.selectedCity = undefined;
      this.clearSelectedCity.next();
    }
    this.searchRequests();
  }

  searchCampaignChange(searchCampaign?: string) {
    this.searchCampaign = searchCampaign;
  }

  searchRequests() {
    this.updateFiltersCount();
    this.pageSelectedIndex = 0;
    this.countVenueRequestsToProcess();
  }

  goToSlotRequests(request: InfoRequest) {
    this.redirectService.goToAdminPendingRequest(request.venueId, request.requestId, this.roundId);
  }

  //#region Filters

  openCloseRequestsAdvancedFilters() {
    this.showAdvancedFilters = !this.showAdvancedFilters;
  }

  removeRequestsAdvancedFilters() {
    this.advancedFiltersCount = 0;
    this.searchCode = undefined;
    this.selectedProvince = undefined;
    this.clearSelectedProvince.next();
    this.selectedCity = undefined;
    this.clearSelectedCity.next();
    this.searchCampaign = undefined;
    this.searchVenue = undefined;
    this.searchStructure = undefined;
    this.fromDateRequests = undefined;
    this.toDateRequests = undefined;
    this.selectedRequestVisibility = this.requestVisibilities[0];
    this.searchRequests();
  }

  updateFiltersCount() {
    let filtersCount = 0;
    if (this.searchCode) {
      filtersCount++;
    }
    if (this.selectedProvince) {
      filtersCount++;
    }
    if (this.selectedCity) {
      filtersCount++;
    }
    if (this.searchCampaign) {
      filtersCount++;
    }
    this.advancedFiltersCount = filtersCount;
  }

  //#endregion Filters
}

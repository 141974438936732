<div class="welion-switch-container" [ngClass]="{'disabled': disabled, 'readonly': readonly}" fxLayout="row"
  fxLayoutAlign="start center" clickKeyBoard (onEnterKeyboard)="emitOnSwitch(true)">
  <label fxLayout="row">
    <p [attr.aria-hidden]="checked" class="leftSwitch margin-right3">{{ leftLabelObject }}</p>
    <button [ngClass]="{'checked': checked}" [id]="id" tabindex="0" [attr.aria-checked]="checked"
     role="switch" type="button" (click)="emitOnSwitch()"></button>
     <span class="state">
      <span class="container">
        <span class="position"></span>
      </span>
     </span>
     <p [attr.aria-hidden]="!checked" class="rightSwitch margin-left10">{{ rightLabelObject }}</p>
  </label>
</div>

<div class="dropdown-container" [ngClass]="{'disabled': item.disabled, 'menu': isMenuItem}">
  <div #firstChild class="dropdown-container-focus" tabindex="0" *ngIf="index == 0"
    attr.aria-label="{{item.name | translate}}"  role="button" [attr.aria-disabled]="item.disabled ? true : null">
    <ng-container *ngIf="!isCheckbox">
      <div class="image-container" *ngIf="item.icon || item.avatar || item.initials">
        <svg-icon *ngIf="item.icon" class="regular-size blue-primary" [src]="item.icon" [applyClass]="true">
        </svg-icon>
        <avatar-img *ngIf="item.avatar || item.initials" size="small" [src]="item.avatar" [initials]="item.initials">
        </avatar-img>
      </div>
      <div class="text-container">
        <p class="text-default">{{item.name | translate}}</p>
        <p *ngIf="item.description && !notSeeDescription" class="text-small" [translate]="item.description"></p>
      </div>
    </ng-container>
    <ng-container *ngIf="isCheckbox">
      <welion-check [label]="item.name" [checked]="item.isChecked || false"
        (onCheck)="emitOnItemCheck()"></welion-check>
    </ng-container>
  </div>
  <div class="dropdown-container-focus" tabindex="0" *ngIf="index != 0"
    attr.aria-label="{{item.name | translate}}" role="button" [attr.aria-disabled]="item.disabled ? true : null">
    <ng-container *ngIf="!isCheckbox">
      <div class="image-container" *ngIf="item.icon || item.avatar || item.initials">
        <svg-icon *ngIf="item.icon" class="regular-size blue-primary" [src]="item.icon" [applyClass]="true">
        </svg-icon>
        <avatar-img *ngIf="item.avatar || item.initials" size="small" [src]="item.avatar" [initials]="item.initials">
        </avatar-img>
      </div>
      <div class="text-container">
        <p class="text-default">{{item.name | translate}}</p>
        <p *ngIf="item.description && !notSeeDescription" class="text-small" [translate]="item.description"></p>
      </div>
    </ng-container>
    <ng-container *ngIf="isCheckbox">
      <welion-check [label]="item.name" [checked]="item.isChecked || false"
        (onCheck)="emitOnItemCheck()"></welion-check>
    </ng-container>
  </div>
</div>
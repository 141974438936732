<div class="accordion-container" [ngClass]="{'disabled': isDisabled, 'challenge': isChallengeAccordion}"
  fxLayout="column" fxLayoutAlign="start center" (click)="openAccordion()" tabindex="0" clickKeyBoard (onEnterKeyboard)="openAccordion()" role="button">

  <div class="accordion-header" fxLayout="column">

    <div class="accordion" [ngClass]="{'restore-margin': !isAccordionOpened}" fxLayout="row"
      fxLayoutAlign="space-between center" >

      <div class="title-accordion" fxFlexOffset.xs="5px" fxLayout="row" fxLayoutAlign="space-between center">

        <h5 class="title-info bold">{{ title }}</h5>

        <ng-container *ngIf="(badgeNumber > 0 || infoIcon) && !_noInfo">
          <svg-icon *ngIf="infoIcon" fxFlexOffset="8px" src="./assets/img/icons/info.svg"
            class="info-wrapper medium-size blue-primary" [applyClass]="true" (click)="openModalMessage($event)">
          </svg-icon>
          <badge-notifications *ngIf="badgeNumber > 0" [badgeNumber]="badgeNumber" type="active"></badge-notifications>
        </ng-container>

      </div>

      <div fxLayout="row">
        <!-- nuovo -->
        <div *ngIf="isNew" class="new" fxLayout="row" fxLayoutAlign="center">
          <h6 class="small bold white uppercase" translate="generic.NEWS"></h6>
        </div>

        <!-- Icona accordion -->
        <svg-icon [fxFlexOffset]="isNew ? '20px' : '0px'" src="./assets/img/icons/chevron-down.svg"
          class="medium-accordion-size-transition blue-primary"
          [ngClass]="{'gray-gray2': isDisabled, 'opened': isAccordionOpened}" [applyClass]="true">
        </svg-icon>

      </div>
    </div>

    <ng-container *ngIf="isChallengeAccordion && isAccordionOpened">
      <div class="border-divider"></div>
    </ng-container>

  </div>

  <div [@showIf] *ngIf="isAccordionOpened" [ngClass]="{'is-table': isTable}" class="full-width">
    <ng-content></ng-content>
  </div>

</div>

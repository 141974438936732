import { Component, OnDestroy } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { Store } from '@ngrx/store';

import * as fromApp from "../../../ngrx/app.reducers";
import { Lang } from 'atfcore-commonclasses/bin/classes/anag';

@Component({
  selector: 'app-enel-privacy',
  templateUrl: './enelPrivacy.component.html',
  styleUrls: ['./enelPrivacy.component.scss']
})
export class EnelPrivacyComponent implements OnDestroy {
  langs?: Lang[];

  private _unsubscribeSignal$: Subject<void> = new Subject();

  constructor(
    private store: Store<fromApp.AppState>,
    public translate: TranslateService
  ) {

    this.store.select(fromApp.getAvailableLangs)
      .pipe(takeUntil(this._unsubscribeSignal$.asObservable()))
      .subscribe(
        (langs) => {
          this.langs = langs as Lang[];
        });
  }

  ngOnDestroy() {
    this._unsubscribeSignal$.next();
    this._unsubscribeSignal$.unsubscribe();
  }

}

import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AdminGuard } from '../shared/services/admin-guard.service';
import { AdminComponent } from './admin.component';
import { AdminHomeComponent } from './home/home.component';
import { AdminAddHealthFacilityComponent } from './healthFacility/addHealthFacility/addHealthFacility.component';
import { AdminHealthFacilityDetailsComponent } from './healthFacility/healthFacilityDetails/healthFacilityDetails.component';
import { AdminHealthFacilitiesComponent } from './healthFacility/healthFacilities/healthFacilities.component';
import { AdminAddHealthFacilityReferenceComponent } from './healthFacility/addHealthFacilityReference/addHealthFacilityReference.component';
import { AdminAddHealthFacilityVenueComponent } from './healthFacility/addHealthFacilityVenue/addHealthFacilityVenue.component';
import { AdminClientsComponent } from './client/clients/clients.component';
import { AdminClientDetailsComponent } from './client/clientDetails/clientDetails.component';
import { AdminAddClientComponent } from './client/addClient/addClient.component';
import { AdminAddClientReferenceComponent } from './client/addClientReference/addClientReference.component';
import { AdminCampaignsComponent } from './campaign/campaigns/campaigns.component';
import { AdminAddCampaignComponent } from './campaign/addCampaign/addCampaign.component';
import { AdminCampaignDetailsComponent } from './campaign/campaignDetails/campaignDetails.component';
import { AdminAddCampaignRoundComponent } from './campaign/addCampaignRound/addCampaignRound.component';
import { AdminCampaignRoundDetailsComponent } from './campaign/campaignRoundDetails/campaignRoundDetails.component';
import { AdminAssignClientToCampaignComponent } from './campaign/assignClientToCampaign/assignClientToCampaign.component';
import { AdminCampaignCustomerDetailsComponent } from './campaign/campaignCustomerDetails/campaignCustomerDetails.component';
import { AdminAddCampaignCustomerCapComponent } from './campaign/addCampaignCustomerCap/addCampaignCustomerCap.component';
import { AdminAssignVenueToRoundComponent } from './campaign/assignVenueToRound/assignVenueToRound.component';
import { AdminRemoveVenueFromRoundComponent } from './campaign/removeVenueFromRound/removeVenueFromRound.component';
import { AdminCampaignRoundVenueDetailsComponent } from './campaign/campaignRoundVenueDetails/campaignRoundVenueDetails.component';
import { AdminCampaignPendingRequestsComponent } from './campaign/campaignPendingRequests/campaignPendingRequests.component';
import { AdminCampaignPendingRequestComponent } from './campaign/campaignPendingRequest/campaignPendingRequest.component';
import { AdminCampaignRoundRescheduleReservationComponent } from './campaign/campaignRoundRescheduleReservation/campaignRoundRescheduleReservation.component';
import { AdminImportEntitledsFromRoundComponent } from './campaign/importEntitledsFromRound/importEntitledsFromRound.component';
import { AdminDashboardComponent } from '../shared/dashboard/admin-dashboard.component';
import { EditVenueBookingDateComponent } from './campaign/editVenueBookingDate/editVenueBookingDate.component';
import { AdminOrLimitedAdminGuard } from '../shared/services/admin-or-limited-admin-guard.service';
import { AdminAddAdOfflineComponent } from './campaign/addAdOffline/addAdOffline.component';

const adminRoutes: Routes = [
  {
    path: '', component: AdminComponent, children: [
      { path: 'home', component: AdminHomeComponent, canActivate: [AdminOrLimitedAdminGuard] },
      // Health facilities (strutture sanitarie)
      { path: 'healthFacilities', component: AdminHealthFacilitiesComponent, canActivate: [AdminOrLimitedAdminGuard] },
      { path: 'healthFacility/:healthFacilitySupplierId', component: AdminHealthFacilityDetailsComponent, canActivate: [AdminOrLimitedAdminGuard] },
      { path: 'healthFacility/:healthFacilitySupplierId/references', component: AdminHealthFacilityDetailsComponent, canActivate: [AdminOrLimitedAdminGuard] },
      { path: 'add/healthFacility', component: AdminAddHealthFacilityComponent, canActivate: [AdminOrLimitedAdminGuard] },
      { path: 'add/healthFacility/:healthFacilitySupplierId', component: AdminAddHealthFacilityComponent, canActivate: [AdminGuard] },
      { path: 'add/healthFacility/:healthFacilitySupplierId/reference', component: AdminAddHealthFacilityReferenceComponent, canActivate: [AdminOrLimitedAdminGuard] },
      { path: 'add/healthFacility/:healthFacilitySupplierId/reference/:supplierPersonId', component: AdminAddHealthFacilityReferenceComponent, canActivate: [AdminGuard] },
      { path: 'add/healthFacility/:healthFacilitySupplierId/venue', component: AdminAddHealthFacilityVenueComponent, canActivate: [AdminGuard] },
      { path: 'add/healthFacility/:healthFacilitySupplierId/venue/:venueId', component: AdminAddHealthFacilityVenueComponent, canActivate: [AdminGuard] },
      // Clients
      { path: 'clients', component: AdminClientsComponent, canActivate: [AdminOrLimitedAdminGuard] },
      { path: 'client/:groupId', component: AdminClientDetailsComponent, canActivate: [AdminOrLimitedAdminGuard] },
      { path: 'add/client', component: AdminAddClientComponent, canActivate: [AdminGuard] },
      { path: 'add/client/:groupId', component: AdminAddClientComponent, canActivate: [AdminGuard] },
      { path: 'add/client/:groupId/reference', component: AdminAddClientReferenceComponent, canActivate: [AdminGuard] },
      { path: 'add/client/:groupId/reference/:supplierPersonId', component: AdminAddClientReferenceComponent, canActivate: [AdminGuard] },
      // Campaigns
      { path: 'campaigns', component: AdminCampaignsComponent, canActivate: [AdminOrLimitedAdminGuard] },
      { path: 'campaign/:campaignId', component: AdminCampaignDetailsComponent, canActivate: [AdminOrLimitedAdminGuard] },
      { path: 'campaign/:campaignId/round/:roundId', component: AdminCampaignRoundDetailsComponent, canActivate: [AdminOrLimitedAdminGuard] },
      { path: 'campaign/:campaignId/round/:roundId/entitleds', component: AdminCampaignRoundDetailsComponent, canActivate: [AdminGuard] },
      { path: 'add/campaign/:campaignId/round/:roundId/addEntitled', component: AdminAddAdOfflineComponent, canActivate: [AdminGuard] },
      { path: 'campaign/:campaignId/round/:roundId/entitleds/:userId', component: AdminCampaignRoundRescheduleReservationComponent, canActivate: [AdminGuard] },
      { path: 'campaign/:campaignId/round/:roundId/entitledsSchedule/:userId/attendance/:attendanceId', component: AdminCampaignRoundRescheduleReservationComponent, canActivate: [AdminGuard] },
      { path: 'campaign/:campaignId/round/:roundId/venue/:venueId', component: AdminCampaignRoundVenueDetailsComponent, canActivate: [AdminOrLimitedAdminGuard] },
      { path: 'campaign/:campaignId/clients', component: AdminCampaignDetailsComponent, canActivate: [AdminOrLimitedAdminGuard] },
      { path: 'campaign/:campaignId/client/:groupId', component: AdminCampaignCustomerDetailsComponent, canActivate: [AdminGuard] },
      { path: 'add/campaign', component: AdminAddCampaignComponent, canActivate: [AdminGuard] },
      { path: 'add/campaign/:campaignId', component: AdminAddCampaignComponent, canActivate: [AdminGuard] },
      { path: 'add/campaign/:campaignId/client/:groupId/cap', component: AdminAddCampaignCustomerCapComponent, canActivate: [AdminGuard] },
      { path: 'add/campaign/:campaignId/round', component: AdminAddCampaignRoundComponent, canActivate: [AdminGuard] },
      { path: 'add/campaign/:campaignId/round/:roundId', component: AdminAddCampaignRoundComponent, canActivate: [AdminGuard] },
      { path: 'assign/campaign/:campaignId/client', component: AdminAssignClientToCampaignComponent, canActivate: [AdminGuard] },
      { path: 'assign/campaign/:campaignId/round/:roundId/venue', component: AdminAssignVenueToRoundComponent, canActivate: [AdminGuard] },
      { path: 'remove/campaign/:campaignId/round/:roundId/venue', component: AdminRemoveVenueFromRoundComponent, canActivate: [AdminGuard] },
      { path: 'import/campaign/:campaignId/round/:roundId/round', component: AdminImportEntitledsFromRoundComponent, canActivate: [AdminGuard] },
      // Modifica date di prenotazione di una struttura
      { path: 'editVenueBookingDate/:campaignId/:roundId/:venueId', component: EditVenueBookingDateComponent, canActivate: [AdminGuard] },
      // Requests
      { path: 'pendingRequests', component: AdminCampaignPendingRequestsComponent, canActivate: [AdminOrLimitedAdminGuard] },
      { path: 'pendingRequests/:campaignId/:roundId/:venueId', component: AdminCampaignPendingRequestsComponent, canActivate: [AdminOrLimitedAdminGuard] },
      { path: 'pendingRequests/toApprove', component: AdminCampaignPendingRequestsComponent, canActivate: [AdminGuard] },
      { path: 'pendingRequests/toApprove/:campaignId/:roundId/:venueId', component: AdminCampaignPendingRequestsComponent, canActivate: [AdminGuard] },
      { path: 'pendingRequests/toRevoke', component: AdminCampaignPendingRequestsComponent, canActivate: [AdminGuard] },
      { path: 'pendingRequests/toRevoke/:campaignId/:roundId/:venueId', component: AdminCampaignPendingRequestsComponent, canActivate: [AdminGuard] },
      { path: 'pendingRequests/descriptions', component: AdminCampaignPendingRequestsComponent, canActivate: [AdminGuard] },
      { path: 'pendingRequests/descriptions/:campaignId/:roundId/:venueId', component: AdminCampaignPendingRequestsComponent, canActivate: [AdminGuard] },
      { path: 'pendingRequest/:venueId/:requestId', component: AdminCampaignPendingRequestComponent, canActivate: [AdminOrLimitedAdminGuard] },
      { path: 'pendingRequest/:venueId/:requestId/:roundId', component: AdminCampaignPendingRequestComponent, canActivate: [AdminOrLimitedAdminGuard] },
      // dashboard
      { path: 'dashboard', component: AdminDashboardComponent, canActivate: [AdminGuard] },
      { path: "", redirectTo: "home", pathMatch: "full" }
    ]
  }
];

@NgModule({
  imports: [
    RouterModule.forChild(adminRoutes)
  ],
  exports: [RouterModule]
})
export class AdminRoutingModule { }

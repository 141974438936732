import { Component, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

import { User } from 'atfcore-commonclasses/bin/classes/anag';
import { InfoRound } from 'atfcore-commonclasses/bin/classes/campaignmanager';

import * as CoreActions from "../../core/ngrx/core.actions";
import * as fromApp from "../../ngrx/app.reducers";

import { RedirectService } from 'src/app/shared/services/redirect.service';
import { getWarningModal } from 'src/app/utils/utils';
import { RentService } from 'src/app/structure/services/rent.service';
import { ScrollTo } from 'src/app/core/services/scroll-to.service';

@Component({
  selector: 'app-client-home',
  templateUrl: './clientHome.component.html',
  styleUrls: ['./clientHome.component.scss']
})
export class ClientHomeComponent implements OnInit, OnDestroy {

  loggedUser?: User;
  isLoadingCount: boolean = true;
  isLoading: boolean = true;

  readonly paginationLength: number = 25;

  rounds: InfoRound[] = [];
  roundCount: number = 0;
  attendanceCount: number = 0;

  private _unsubscribeSignal$: Subject<void> = new Subject();
  private _firstInit: boolean = true;

  constructor(
    private appStore: Store<fromApp.AppState>,
    private translate: TranslateService,
    private redirectService: RedirectService,
    private rentService: RentService,
    private scrollTo: ScrollTo
  ) {
  }

  ngOnInit() {

    this.appStore.select(fromApp.getLoggedUser)
      .pipe(takeUntil(this._unsubscribeSignal$.asObservable()))
      .subscribe(
        (loggedUser) => {
          if (loggedUser && loggedUser.user) {
            this.loggedUser = loggedUser && loggedUser.user;
            if (this._firstInit) {
              this._firstInit = false;
              this.countRoundByCampaign();
            }
          }
        });
  }

  countRoundByCampaign() {
    this.isLoadingCount = true;

    this.rentService.countRoundByCampaignForCustomer()
      .pipe(takeUntil(this._unsubscribeSignal$.asObservable()))
      .subscribe((result) => {
        if (!result || result.error) {
          this.dispatchWarningModal('082', result.error || '');
          this.resetPagination();
        } else {
          this.attendanceCount = result.response.attendanceCount;
          this.roundCount = result.response.roundCount;

          if (this.roundCount === 0) {
            this.resetPagination();
          } else {
            this.getRoundListByCampaign();
          }
        }
        this.isLoadingCount = false;
      }, (err) => {
        if (err && err.message) {
          this.dispatchWarningModal('084', err.message);
        }
        this.resetPagination();
      });
  }

  getRoundListByCampaign(index?: number) {

    this.isLoading = true;

    const pageSelectedIndex = index ? index : 0;
    if (index === 0 || index) {
      this.scrollTo.header();
    }

    this.rentService.getRoundListByCampaignForCustomer((pageSelectedIndex * this.paginationLength), this.paginationLength)
      .pipe(takeUntil(this._unsubscribeSignal$.asObservable()))
      .subscribe((result) => {
        if (!result || result.error) {
          this.dispatchWarningModal('082', result.error || '');
          this.resetPagination();
        } else {
          this.rounds = result.response;
        }
        this.isLoading = false;
      }, (err) => {
        if (err && err.message) {
          this.dispatchWarningModal('084', err.message);
        }
        this.resetPagination();
      });
  }

  resetPagination() {
    this.isLoadingCount = false;
    this.isLoading = false;

    this.rounds = [];
    this.roundCount = 0;
  }

  goToClientRound(round: InfoRound) {
    this.redirectService.goToClientRound(round.campaignId, round.roundId);
  }

  /**
   * @description Mostra una modale con un messaggio di errore
   * @param modalId
   * @param error
   */
  dispatchWarningModal(modalId: string, error: string) {
    const messageObj = getWarningModal(this.translate, modalId, error);
    this.appStore.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
  }

  ngOnDestroy() {
    this._unsubscribeSignal$.next();
    this._unsubscribeSignal$.unsubscribe();
  }

}

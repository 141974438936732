import { Injectable, Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';

@Injectable()
export class ScrollTo {

  constructor(@Inject(DOCUMENT) private document: Document) {

  }

  /**
   * @description Scroll to an anchor in the document by the element id
   * @param elementId Id of the element where to scroll
   */
  element(elementId: string) {
    const element = this.document.getElementById(elementId);
    if (element !== undefined && element !== null) {
      element.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'nearest' });
    }
  }

  /**
   * @description Scroll to an anchor in the document by the element id with a delay
   * @param elementId Id of the element where to scroll
   * @param delay @default 300
   */
  elementWithDelay(elementId: string, delay: number = 300) {
    let timeout = setTimeout(() => {
      clearTimeout(timeout);
      this.element(elementId);
    }, delay);
  }

  /**
   * @description Scroll to top
   */
  header() {
    this.element('app-header');
  }

}

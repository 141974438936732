import { Component, OnInit, Input, ViewChild, ElementRef, Output, EventEmitter, Renderer2 } from '@angular/core';
import { ModalService } from './modal.service';
import { Store } from '@ngrx/store';
import * as fromApp from "../../../ngrx/app.reducers";
import * as CoreActions from "../../../core/ngrx/core.actions";
import { coerceBooleanProperty } from '@angular/cdk/coercion';

@Component({
  selector: 'modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.scss']
})
export class ModalComponent implements OnInit {
  @Input() id: string = 'modal1';
  @Input() confirmLabel?: string;
  @Input() cancelLabel?: string;
  @Input() title?: string;
  @Input() titleClass?: string;
  @Input() confirmBtnDisabled?: boolean = false;
  @Input() fullPage: boolean = false;

  _primaryClose: boolean = false;
  @Input() set primaryClose(value: boolean | string) {
    this._primaryClose = coerceBooleanProperty(value);
  };

  @Input() disableClose: boolean = false;
  @Input() disableCancel: boolean = false;
  @Input() disableAll: boolean = false;

  @Output() onClose = new EventEmitter<any>();
  @Output() onConfirm = new EventEmitter<any>();
  @Output() onCancel = new EventEmitter<any>();
  @Output() onInfoIconOpenModalFunction = new EventEmitter<any>();

  private element: any;
  @ViewChild('modalEl') modalEl: any;
  focusedElBeforeOpen: any;

  constructor(
    private modalService: ModalService,
    private store: Store<fromApp.AppState>,
    private el: ElementRef) {
    this.element = el.nativeElement;
  }

  ngOnInit(): void {
    const modal = this;
    // Devo assicurarmi di avere l'id della modale
    if (!this.id) {
      return;
    }

    // Affinché venga resa visibile ovunque, la appendo appena dopo la chiusura del </body>
    document.body.appendChild(this.element);

    // Chiudo la modale al click sullo sfondo
    this.element.addEventListener('click', (e: any) => {
      if (e.target.className === 'modal') {
        modal.close();
      }
    });

    // Aggiungo la modale al servizio affinché venga resa accessibile dai vari componenti
    this.modalService.add(this);
  }

  // Apre la modale
  open(): void {
    this.focusedElBeforeOpen = document.activeElement;
    this.element.style.display = 'block';
    document.body.classList.add('modal-open');
    setTimeout(() => {
      this.modalEl.nativeElement.focus();
    },0)
  }

  // Chiude la modale
  close(): void {
    this.element.style.display = 'none';
    document.body.classList.remove('modal-open');
    if (this.focusedElBeforeOpen) {
      this.focusedElBeforeOpen.focus();
    }
    this.store.dispatch(CoreActions.DeleteApplicationModalMessage())
  }

  // Apri modale dall'icona info
  openModalInfoIcon() {
    this.onInfoIconOpenModalFunction.emit();
  }

  emitOnClose() {
    if (!this.disableClose && !this.disableAll) {
      this.onClose.emit()
    }
  }

  emitOnConfirmClicked() {
    this.onConfirm.emit();
  }

  emitOnCancelClicked() {
    console.log('emitOnCancelClicked');
    this.onCancel.emit()
  }

  // Quando la direttiva è distrutta, tolgo la modale dal servizio
  ngOnDestroy(): void {
    this.modalService.remove(this.id);
    if (typeof this.element.remove === 'function') {
      this.element.remove();
    } else {
      this.element.parentNode.removeChild(this.element);
    }
  }

  closeModal(event:any){
    event.preventDefault();
    this.emitOnClose();
  }
}

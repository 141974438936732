import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { InfoSlotStatuses } from 'atfcore-commonclasses/bin/classes/campaignmanager';

@Component({
  selector: 'slot-component-flag',
  templateUrl: 'slot-component-flag.component.html',
  styleUrls: ['./slot-component-flag.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SlotComponentFlagComponent implements OnInit {
  @Input() status: InfoSlotStatuses = 'REVOKED';

  @Input() height: number = 20;

  constructor() {
  }

  ngOnInit() {
  }
}

import { Component } from '@angular/core';

@Component({
    selector: 'page-container',
    templateUrl: './page-container.component.html',
    styleUrls: ['./page-container.component.scss']
})

export class PageContainerComponent {

}
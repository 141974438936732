<div class="page-content-wrapper" fxLayout="column">

  <bread-crumbs fxFlexOffset="12px">
  </bread-crumbs>


  <!-- Date -->
  <h1 fxFlexOffset="40px" class="primary">
    {{ 'clientHome.TITLE' | translate }}
  </h1>

  <div fxFlexOffset="14px" fxFlexOffset.xs="12px" class="campaigns-container" fxLayout="column">

    <ng-container *ngIf="isLoadingCount">
      <h3 translate="generic.LOADING"></h3>
      <div fxFlexOffset="16px" class="campaign-divider"></div>
      <div class="loader spinner-big"></div>
    </ng-container>

    <ng-container *ngIf="!isLoadingCount">
      <div fxLayout="row" fxLayoutAlign="space-between">
        <!-- Round -->
        <h3 translate="clientHome.ROUND"></h3>

        <!-- 170 utenti caricati -->
        <p>
          <span class="userTotal">{{ attendanceCount || 0 }}</span>
          <span class="userText" translate="clientHome.USER_LOADED"></span>
        </p>
      </div>

      <div fxFlexOffset="16px" class="campaign-divider"></div>

      <!-- Table header -->
      <div fxLayout="row" fxLayoutAlign="start center" fxFlexOffset="16px">
        <!-- Round -->
        <h5 class="bold" translate="clientHome.table.ROUND" fxFlex="35" fxFlexOffset="16px"></h5>
        <!-- Caricati -->
        <h5 class="bold center" translate="clientHome.table.LOADED" fxFlex="10"></h5>
      </div>

      <!-- Loader -->
      <div *ngIf="isLoading" class="loader spinner-big"></div>

      <!-- Table rows -->
      <ng-container *ngIf="roundCount > 0 else noRounds">
        <ng-container *ngIf="!isLoading">
          <div *ngFor="let round of rounds; let even = even" class="campaign-row" [ngClass]="{'even': even}"
            fxLayout="row" fxLayoutAlign="start center">
            <div fxFlex="35" fxFlexOffset="16px" fxLayout="column">
              <!-- Campagna -->
              <h6 class="black">{{ round.campaignTitle }}</h6>

              <!-- Round -->
              <h6 fxFlexOffset="4px" class="black">
                {{ round.startDate | date: 'dd/MM/yyyy' }}
                {{ ' - ' }}
                {{ round.endDate | date: 'dd/MM/yyyy' }}
              </h6>
            </div>
            <!-- Caricati -->
            <h6 class="black center" fxFlex="10">{{ round.attendanceCount || '-' }}</h6>

            <div fxFlex fxLayout="row" fxLayoutAlign="end">
              <h6 class="black bold underline clickable" translate="clientHome.table.MANAGE"
                (click)="goToClientRound(round)"></h6>
              <div fxFlexOffset="16px"></div>
            </div>

          </div>
        </ng-container>

        <!-- Paginatore -->
        <welion-paginator fxFlexOffset="20px" [isLoading]="isLoading" [paginationLength]="paginationLength"
          [itemsCount]="roundCount" (onClickPage)="getRoundListByCampaign($event)"></welion-paginator>

      </ng-container>
    </ng-container>

  </div>
</div>

<ng-template #noRounds>
  <div fxLayout="column" fxLayoutAlign="start center">
    <svg-icon fxFlexOffset="40px" [applyClass]="true" src="assets/img/icons/note-health-facility-red.svg"></svg-icon>

    <!-- Nessuna richiesta da gestire -->
    <h4 fxFlexOffset="32px" translate="clientHome.NO_ROUNDS"></h4>
  </div>
</ng-template>
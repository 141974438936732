<header class="header-container">
  <div (focusin)="showSkipContent = true" (focusout)="showSkipContent = false">
    <a class="btn-main" (click)="setFocus()" [routerLink]=""
      [ngClass]="{'btn-main-hidden': showSkipContent == false}">{{'generic.GO_TO_MAIN' | translate}}</a>
  </div>
  <nav id="primary-navigation" aria-label="Primary navigarion" fxLayout="row" fxLayoutAlign="space-between center"
    fxFlexFill>
    <div class="left-side" fxLayout="row" fxLayoutAlign="start center">
      <button role="button" class="logo" (click)="onAppLogoClicked()" clickKeyBoard
        (onEnterKeyboard)="onAppLogoClicked()" attr.aria-label="{{'generic.GO_TO_HOME' | translate}}">
        <svg-icon src="/assets/img/icons/app-logo.svg"></svg-icon>
      </button>
    </div>
    <div class="right-side" fxLayout="row" fxLayoutAlign="start center">
      <!-- Lato destro desktop -->
      <ng-container *ngIf="!isMobile">
        <!--<button class="icon-button" (click)="emitOnTooltipClicked($event)" (keydown)="onKeyDownTooltipClicked($event)"
          aria-label="Support">
          <svg-icon src="/assets/img/icons/help-circle.svg" class="medium-size"></svg-icon>
        </button>-->

        <div class="user-data-container" fxLayout="row" fxLayoutAlign="start center">
          <avatar-img size="small" [src]="avatar" [initials]="initials"></avatar-img>
          <div class="user-name-wrapper">
            <p>{{ userName }}</p>
          </div>

          <button #menu (click)="toggleSidebar()" attr.aria-label="{{'generic.OPEN_MENU' | translate}}">
            <svg-icon class="medium-size"
              [src]="isSidebarOpened() ? '/assets/img/icons/x.svg' : '/assets/img/icons/menu.svg'"></svg-icon>
          </button>
        </div>
      </ng-container>

      <!-- Lato destro mobile -->
      <ng-container *ngIf="isMobile">
        <div class="user-data-container" fxLayout="row" fxLayoutAlign="start center">
          <button (click)="toggleSidebar()" aria-label="Profilo">
            <avatar-img size="small" [src]="avatar" [initials]="initials"></avatar-img>
          </button>
          <button (click)="toggleSidebar()" aria-label="Toggle Sidebar">
            <svg-icon class="medium-size"
              [src]="isSidebarOpened() ? '/assets/img/icons/x.svg' : '/assets/img/icons/menu.svg'"></svg-icon>
          </button>
        </div>
      </ng-container>
    </div>
  </nav>
</header>
<ng-container *ngIf="langs && langs.length">
  <div class="main-content-privacy" fxLayout="column" fxLayoutAlign="center center">
    <div class="privacy-wrapper" fxLayout="column">
      <div fxLayout="row" fxLayoutAlign="center">
        <svg-icon src="assets/img/icons/app-logo.svg" [applyClass]="true"></svg-icon>
      </div>

      <h5 fxFlexOffset="12px" class="primary bold"  tabindex="0">
        INFORMATIVA SUL TRATTAMENTO DEI DATI PERSONALI AI SENSI DEGLI ARTICOLI 13 E 14 DEL REGOLAMENTO UE N. 679/2016
        DEL 27 APRILE 2016
      </h5>

      <div fxFlexOffset="20px" fxLayout="row" class="privacy-box readOnly">

        <div class="privacy-container"  tabindex="0">
          <p>
            <strong>Utilizzo dei dati per l’erogazione dei Servizi</strong>
            <br>
            La informiamo che i Suoi dati personali da Lei forniti o acquisiti da terzi (1) sono trattati da Generali
            Welion S.c.a.r.l. (“Società”) quale Titolare, nell’ambito della propria offerta di servizi di messa a
            disposizione del proprio network di strutture sanitarie/ambulatoriali e simili nell’ambito del Programma
            Vaccini (“Servizi”), per
          </p>
          <ol type="i">
            <li>consentirLe l’accesso a programmi di vaccinazione da effettuarsi presso strutture appartenenti al
              network sanitario della Società;</li>
            <li>fornirLe assistenza e informazioni sulle modalità di accesso ai vaccini e la gestione amministrativa e
              contabile delle Sue richieste di prenotazione; </li>
            <li>mettere a disposizione di Enel Italia per soli fini di rendicontazione contrattuale e fatturazione , una
              reportistica sulle vaccinazioni effettuate. Tali dati verranno prontamente cancellati da Enel al termine
              delle attività di rendicontazione contrattuale;</li>
            <li>prevenire, individuare e/o perseguire eventuali frodi;</li>
            <li>comunicare i Suoi dati personali a terzi che svolgono servizi in outsourcing per conto della Società o
              che con la stessa hanno in essere contratti per l’esecuzione dei Servizi.</li>
          </ol>
          <p>
            La informiamo, inoltre, che il trattamento dei Suoi dati personali per le finalità di cui ai punti (i),
            (ii), (iii) e (v) è necessario e funzionale all’erogazione dei Servizi da parte della Società; mentre per le
            finalità di cui alla lettera (iv) il trattamento dei Suoi dati personali si basa sul legittimo interesse
            della Società a prevenire e individuare eventuali frodi ed a porne in essere una corretta gestione per il
            trattamento dei Suoi dati personali di cui ai punti (i) e (ii), (iii), (iv) e (v) necessitiamo inoltre del
            suo esplicito consenso. La informiamo, quindi, che per le finalità del trattamento come sopra illustrate sub
            (i), (ii), (iii) e (v) il conferimento dei dati personali è obbligatorio ed il loro mancato, parziale o
            inesatto conferimento potrà avere, come conseguenza, l'impossibilità di svolgere le attività richieste e
            preclude alla Società di erogare i Servizi.
            <br>
            <br>
            <strong>Diritti dell’interessato</strong>
            <br>
            Lei potrà conoscere quali sono i Suoi dati personali trattati presso la Società e, ove ne ricorrano le
            condizioni, esercitare i diversi diritti relativi al loro utilizzo (diritto di accesso, rettifica,
            aggiornamento, integrazione, cancellazione, limitazione al trattamento, alla portabilità, alla revoca del
            consenso al trattamento e di ottenere una copia dei propri dati personali laddove questi siano conservati in
            paesi al di fuori dell’Unione Europea, nonché di ottenere indicazione del luogo nel quale tali dati
            personali vengono conservati o trasferiti) nonché opporsi per motivi legittimi ad un loro particolare
            trattamento e comunque al loro uso a fini commerciali, in tutto o in parte anche per quanto riguarda l’uso
            di modalità automatizzate rivolgendosi a: Generali Welion S.c.a.r.l., Via Niccolò Machiavelli 4 - 34132
            Trieste (Trieste), privacy.it@generali.com o al Responsabile della Protezione dei Dati (RPD), contattabile
            via e-mail a “RPD.it@generali.com” e/o via posta ordinaria all’indirizzo “RPD Generali Italia” - Via
            Marocchesa 14, 31021 Mogliano Veneto TV.
            <br>
            <br>
            La informiamo, inoltre, che, qualora ravvisi un trattamento dei Suoi dati personali non coerente con i
            consensi da Lei espressi può sporgere reclamo al Garante per la protezione dei dati personali, con le
            modalità indicate sul sito del Garante stesso.
            <br>
            <br>
            <strong>Tempi di conservazione dei dati</strong>
            <br>
            I Suoi dati personali possono essere conservati per periodi di tempo diversi a seconda della finalità per la
            quale sono trattati dalla Società, in conformità alla normativa privacy tempo per tempo applicabile, in
            particolare con riferimento ai dati raccolti per le finalità contrattuali, per tutta la durata dei rapporti
            in essere e, in conformità alla normativa vigente, per un periodo massimo di 10 anni dal momento della
            cessazione dell'efficacia del contratto o, in caso di contestazioni, per il termine prescrizionale previsto
            dalla normativa per la tutela dei diritti connessi.
            <br>
            <br>
            <strong>Comunicazione dei Dati</strong>
            <br>
            I Suoi dati personali non saranno diffusi e saranno trattati con idonee modalità e procedure anche
            informatizzate, dai nostri dipendenti, collaboratori ed altri soggetti anche esterni, designati
            Responsabili e/o Autorizzati al trattamento o, comunque, operanti quali Titolari, che sono coinvolti nella
            gestione dei rapporti con Lei in essere o che svolgono per nostro conto compiti di natura tecnica,
            organizzativa, operativa anche all’interno e, se del caso, al di fuori della UE2)
            <br>
            <br>
            <strong>Trasferimento dei dati all'estero</strong>
            <br>
            I Suoi dati personali potranno essere inoltre comunicati, ove necessario, a soggetti, privati o pubblici,
            connessi allo specifico rapporto operanti in paesi situati nell’Unione Europea o al di fuori della stessa
            (3) alcuni dei quali potrebbero non fornire garanzie adeguate di protezione dei dati personali (un elenco
            completo dei Paesi che forniscono garanzie adeguate di protezione dei dati è disponibile nel sito web del
            Garante per la Protezione dei Dati Personali). In tali casi, il trasferimento dei Suoi dati personali verrà
            effettuato nel rispetto delle norme e degli accordi internazionali vigenti, nonché a fronte dell'adozione di
            misure adeguate (es. clausole contrattuali standard).
            <br>
            <br>
            <strong>Modifiche e aggiornamenti dell'Informativa</strong>
            <br>
            Anche in considerazione di futuri cambiamenti che potranno intervenire sulla normativa privacy applicabile,
            la Società potrà integrare e/o aggiornare, in tutto o in parte, la presente Informativa. Resta inteso che
            qualsiasi modifica, integrazione o aggiornamento Le sarà comunicato in conformità alla normativa vigente
            anche a mezzo di pubblicazione sul sito internet della Società.
            <br>
            <br>
          </p>
          <p class="underline">
            NOTE:
          </p>
          <ol type="1">
            <li>La Società tratta le seguenti categorie di dati: dati anagrafici e identificativi, dati di contatto,
              dati di prenotazione dei vaccini, dati relativi al suo stato di salute e altri dati personali forniti
              dall’interessato, dati acquisiti da altri soggetti terzi (società clienti, società del Gruppo Generali,
              società di informazione commerciale e società esterne ai fini di ricerche di mercato).</li>
            <li>Trattasi di soggetti, quali società del Gruppo Generali, ed altre società che svolgono, quali
              outsourcer, servizi informatici, telematici, finanziari, amministrativi, di revisione contabile e
              certificazione di bilancio, nonché strutture sanitarie convenzionate con la Società che erogano la
              somministrazione del vaccino ovvero società specializzate in ricerche di mercato e indagini sulla qualità
              dei servizi.
            </li>
            <li>Organismi istituzionali ed enti privati o pubblici a cui i dati devono essere comunicati per obbligo
              normativo.</li>
          </ol>
        </div>

      </div>
    </div>
  </div>
</ng-container>

<!-- Nessuna lingua disponibile a sistema -->
<div *ngIf="!langs || !langs.length">
  <p translate="errors.NO_AVAILABLE_LANG"></p>
</div>

import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { ChangeDetectionStrategy, Component, Input, OnInit, Output, EventEmitter, ChangeDetectorRef, ViewChild, Renderer2, AfterViewInit } from '@angular/core';
import { NgSelectComponent } from '@ng-select/ng-select';
import { TranslateService } from '@ngx-translate/core';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { DropdownItem } from '../../models/dropdown.model';

@Component({
  selector: 'welion-select',
  templateUrl: 'welion-select.component.html',
  styleUrls: ['./welion-select.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class WelionSelectComponent implements OnInit, AfterViewInit {

  _items: DropdownItem[] = [];
  @Input() set items(value: DropdownItem[] | undefined) {
    if (value) {
      this._items = value;
    }
  }
  @Input() notSeeDescription: boolean = false;
  @Input() _selectedItem?: DropdownItem;
  @Input() set selectedItem(value: DropdownItem | undefined) {
    this._selectedItem = value;
  }

  @Input() tabIndex: number = 0;
  @Input() labelForId: string = '';
  @Input() ariaLabelledbyLabel: string = '';
  @Input() ariaLabelledbyId: string = '';
  @Input() required: boolean = false;
  @Input() loadingData: string = '';
  @Input() typeahead?: any;
  @Input() clearable: boolean = false;
  @Input() disabled: boolean = false;
  @Input() readonly: boolean = false;
  @Input() forceError: boolean = false;
  @Input() isCheckbox: boolean = false;
  @Input() placeholder: string = '';

  _searchable: boolean = false;
  @Input() set searchable(value: string | boolean) {
    this._searchable = coerceBooleanProperty(value);
  }

  @Input() bindLabel: string = '';
  // Non serve al momento
  _multiple: boolean = false;
  @Input() set multiple(value: string | boolean) {
    this._multiple = coerceBooleanProperty(value);
  }

  _mediumText: boolean = false;
  @Input() set mediumText(value: string | boolean) {
    this._mediumText = coerceBooleanProperty(value);
  }

  _toTranslate: boolean = false;
  @Input() set toTranslate(value: string | boolean) {
    this._toTranslate = coerceBooleanProperty(value);
  }

  _withTransparency: boolean = false;
  @Input() set withTransparency(value: string | boolean) {
    this._withTransparency = coerceBooleanProperty(value);
  }

  // Aggiunte nuove template
  @Input() startSearchLabel?: string;
  @Input() noDataFoundLabel?: string;

  @Output() scroll = new EventEmitter<{ start: number; end: number }>();
  /**
   * Fired when scrolled to the end of items. Can be used for loading more items in chunks.
   */
  @Output() scrollToEnd = new EventEmitter<void>();
  @Output('change') onSelectedItem = new EventEmitter<any>();
  @Output('add') onAddItem = new EventEmitter<any>();
  @Input() loading: boolean = false;
  @Input() attrAriaLabel: string = '';
  @Input() appendTo: string = 'body';
  @Input() searchFn?: Function;

  _isStructureSelect: boolean = false;
  @Input() set isStructureSelect(value: boolean | string) {
    this._isStructureSelect = coerceBooleanProperty(value);
  };

  @Input() onClearInput?: Observable<void>;

  @ViewChild('welionSelect') welionSelect?: NgSelectComponent;

  noDataObject: DropdownItem = {
    id: 'none',
    name: '',
    icon: '/assets/img/icons/coffee.svg'
  }

  _virtualScroll = false;

  private _unsubscribeSignal$: Subject<void> = new Subject();

  constructor(
    private renderer: Renderer2,
    private cdr: ChangeDetectorRef,
    private translate: TranslateService
  ) {
  }

  ngOnInit() {
    if (this.scroll) {
      this._virtualScroll = true;
    }
    if (this.onClearInput) {
      this.onClearInput.pipe(takeUntil(this._unsubscribeSignal$.asObservable()))
        .subscribe(() => {
          this._selectedItem = undefined;
        });
    }
  }

  emitOnSelectedItem(item: any | any[]) {
    if (item && item.disabled) {
      return;
    }
    if (this._multiple && item && item.length) {
      // non serve al momento
      this.clearList()
      for (let i = 0; i < item.length; i++) {
        for (let j = 0; j < this._items.length; j++) {
          if (this._items[i].id == item.id) {
            this._items[i].isChecked = !this._items[i].isChecked;
          }
        }
      }
    } else {
      this._selectedItem = item;
    }
    this.onSelectedItem.emit(item);
  }

  ngAfterViewInit() {
    let timeout = setTimeout(() => {
      clearTimeout(timeout);
      if (this.welionSelect && this.welionSelect.searchInput) {
        this.attrAriaLabel ? this.renderer.setAttribute(this.welionSelect.searchInput.nativeElement, "aria-label", this.attrAriaLabel) : null;
        this.attrAriaLabel ? this.renderer.setAttribute(this.welionSelect.searchInput.nativeElement.offsetParent, "aria-label", this.attrAriaLabel) : null;
        this.ariaLabelledbyLabel ? this.renderer.setAttribute(this.welionSelect.searchInput.nativeElement, "aria-labelledby", "welion-input-label-" + this.ariaLabelledbyLabel + (this.ariaLabelledbyId ? ' ' + this.ariaLabelledbyId : '')) : null;
        this.renderer.setAttribute(this.welionSelect.searchInput.nativeElement, "aria-required", this.required.toString())
        this.renderer.setAttribute(this.welionSelect.searchInput.nativeElement, "required", '')
        this.renderer.setAttribute(this.welionSelect.searchInput.nativeElement, "aria-disabled", this.disabled.toString());
        this.labelForId ? this.renderer.setAttribute(this.welionSelect.searchInput.nativeElement, "name", this.labelForId || '') : null;
        this.placeholder && !this._searchable ? this.renderer.setAttribute(this.welionSelect.searchInput.nativeElement, "placeholder", this.placeholder || '') : null;
      }
      this.noDataObject.name = this.noDataFoundLabel || this.translate.instant('generic.NO_DATA_FOUND');
    }, 500);
  }

  // Clicco su un option
  onOptionClicked(event: any, isDisabled: boolean) {
    this.welionSelect;
    if (isDisabled) {
      event.preventDefault();
      event.stopPropagation();
    }
  }

  clearList() {
    if (this._items && this._items.length) {
      for (let i = 0; i < this._items.length; i++) {
        this._items[i].isChecked = false;
      }
    }
    this.cdr.detectChanges();
  }
}

import { ActivatedRoute } from "@angular/router";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { Component, OnDestroy, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Observable, Subject } from 'rxjs';
import { Store } from "@ngrx/store";
import { catchError, map, switchMap, takeUntil } from "rxjs/operators";

import * as fromApp from "../../../ngrx/app.reducers";
import * as CoreActions from "../../../core/ngrx/core.actions";

import { RedirectService } from 'src/app/shared/services/redirect.service';
import { getWarningModal } from "src/app/utils/utils";
import { ScrollTo } from "src/app/core/services/scroll-to.service";
import { SenecaResponse } from "atfcore-commonclasses/bin/classes/common";
import { AttendanceStatuses, InfoVenue } from "src/commonclasses";
import { DropdownItem } from "src/app/shared/models/dropdown.model";
import { AnagService, UserGroupAdmin } from "src/app/auth/services/anag.service";
import { RentService } from "src/app/structure/services/rent.service";
import { ImportService } from "src/app/structure/services/import.service";
import { ModalService } from "src/app/shared/components/modal/modal.service";

@Component({
  selector: 'app-admin-add-ad-offline',
  templateUrl: './addAdOffline.component.html',
  styleUrls: ['./addAdOffline.component.scss']
})
export class AdminAddAdOfflineComponent implements OnInit, OnDestroy {
  isLoading: boolean = false;

  campaignId: string = '';
  roundId: string = '';

  privacyFile: any;

  addEntitledForm = new FormGroup({
    'forename': new FormControl(undefined, Validators.required),
    'surname': new FormControl(undefined, Validators.required),
    'codFisc': new FormControl(undefined, Validators.required),
    'codFiscParent': new FormControl(undefined),
    'prenotationDate': new FormControl(undefined, Validators.required),
    'prenotationHour': new FormControl(undefined, Validators.required),
    'entitledStatus': new FormControl(undefined, Validators.required),
    'privacy': new FormControl(undefined, Validators.requiredTrue)
  });

  get forename(): FormControl {
    return this.addEntitledForm.get('forename') as FormControl;
  }

  get surname(): FormControl {
    return this.addEntitledForm.get('surname') as FormControl;
  }

  get codFisc(): FormControl {
    return this.addEntitledForm.get('codFisc') as FormControl;
  }

  get codFiscParent(): FormControl {
    return this.addEntitledForm.get('codFiscParent') as FormControl;
  }

  get prenotationDate(): FormControl {
    return this.addEntitledForm.get('prenotationDate') as FormControl;
  }

  get prenotationHour() {
    return this.addEntitledForm.get('prenotationHour') as FormControl;
  }

  get entitledStatus(): FormControl {
    return this.addEntitledForm.get('entitledStatus') as FormControl;
  }

  get privacy(): FormControl {
    return this.addEntitledForm.get('privacy') as FormControl;
  }

  private _unsubscribeSignal$: Subject<void> = new Subject();

  entitledStatuses: DropdownItem[] = [
    {
      id: AttendanceStatuses.RESERVED,
      name: 'status.RESERVED'
    }, {
      id: AttendanceStatuses.APPROVED,
      name: 'status.APPROVED'
    }, {
      id: AttendanceStatuses.YES,
      name: 'status.YES'
    }, {
      id: AttendanceStatuses.NO,
      name: 'status.NO'
    }
  ];

  private _companiesLoaded: number = 1;
  private _companiesSearchedText: string = '';
  private _resetCompanyResultList: boolean = false;

  private _isEndCompanyList: boolean = false;

  isLoadingCompanyList: boolean = false;
  companiesList: DropdownItem[] = [];
  companyServive$: Subject<string> = new Subject<string>();
  selectedCompany?: DropdownItem = undefined;

  private _firstInit: boolean = true;
  private _isEndStructureList: boolean = false;

  isLoadingStructureList: boolean = false;
  structureList: DropdownItem[] = [];
  structureServive$: Subject<string> = new Subject<string>();
  selectedStructure?: DropdownItem = undefined;
  private _structuresLoaded: number = 1;
  private _structureSearchedText: string = '';
  private _resetStructureResultList: boolean = false;

  addOfflineEntitledErrors: any[] = [];

  constructor(
    private route: ActivatedRoute,
    private appStore: Store<fromApp.AppState>,
    private translate: TranslateService,
    private anagService: AnagService,
    private redirectService: RedirectService,
    private scrollTo: ScrollTo,
    private rentService: RentService,
    private importService: ImportService,
    private modalService: ModalService
  ) {
  }

  ngOnInit() {
    this.scrollTo.header();

    this.campaignId = this.route.snapshot.paramMap.get('campaignId') || '';
    this.roundId = this.route.snapshot.paramMap.get('roundId') || '';

    if (this._firstInit) {
      this._firstInit = false;

      this.initStructures();
      this.structureServive$.next('');
      this.initCompanies();
      this.companyServive$.next('');
    }
  }

  ngOnDestroy() {
    this._unsubscribeSignal$.next();
    this._unsubscribeSignal$.unsubscribe();
  }

  /**
   * @description Va all'homepage degli AD
   */
  goBack() {
    this.redirectService.goToAdminCampaignRoundTabEntitleds(this.campaignId, this.roundId);
  }

  confirmAddEntitled(simulate?: boolean) {
    if (this.addEntitledForm.valid) {

      this.isLoading = true;

      let hour = this.prenotationHour.value?.hour();
      let min = this.prenotationHour.value?.minutes();

      let slotReservationStartDate: Date = this.prenotationDate.value.setHours(hour, min, 0, 0);

      if (slotReservationStartDate) {
        slotReservationStartDate = new Date(slotReservationStartDate);
      }

      this.rentService.addEntitled(
        this.roundId,
        this.codFisc.value,
        this.codFiscParent.value || '',
        this.forename.value,
        this.surname.value,
        this.selectedCompany?.id || '',
        this.selectedStructure?.id || '',
        this.entitledStatus.value?.id,
        this.privacy.value,
        slotReservationStartDate,
        this.privacyFile,
        simulate
      )
        .pipe(takeUntil(this._unsubscribeSignal$.asObservable()))
        .subscribe((result) => {
          if (!result || result.error) {
            this.dispatchWarningModal('041', result.error || '');
          } else if (result.response) {
            if (result.response.importRes && result.response.importRes.length) {
              this.addOfflineEntitledErrors.length = 0;
              for(let i = 0; i < result.response.importRes.length; i++) {
                this.addOfflineEntitledErrors.push(result.response.importRes[i]);
              }
              this.openAddEntitledErrorsModal();
            } else {
              if (simulate) {
                this.confirmAddEntitled();
              } else {
                if (this.privacyFile) {
                  let attendanceId = result.response.attendanceIdsForUpload && result.response.attendanceIdsForUpload[0];
                  this.uploadPrivacyDoc(attendanceId);
                } else {
                  this.redirectService.goToAdminCampaignRoundTabEntitleds(this.campaignId, this.roundId);
                }
              }
            }
          }
          this.isLoading = false;
        }, (err) => {
          if (err && err.message) {
            this.dispatchWarningModal('042', err.message);
          }
          this.isLoading = false;
        });
    }
  }

  // Upload file di privacy per singolo AD
  uploadPrivacyDoc(attendanceId: string) {
    if (this.privacyFile && attendanceId && attendanceId.length) {
      this.isLoading = true;
      this.importService.uploadEntitledPrivacyFile(attendanceId, this.privacyFile)
        .subscribe((data: any) => {
          if (data.body && data.body.error) {
            this.dispatchWarningModal('0135', 'errors.' + data.body.error);
            this.isLoading = false;
          } else if (data.type != 0 && data.body) {
            this.redirectService.goToAdminCampaignRoundTabEntitleds(this.campaignId, this.roundId);
          }
        },
          (err: string) => {
            this.dispatchWarningModal('015', err);
            this.isLoading = false;
          });
    }
  }

  onPrenotationDateChange(date: Date) {
    date = new Date(date);
    date.setHours(0, 0, 0, 0);
    this.prenotationDate.setValue(new Date(date));
  }

  onEntitledStatusChange(status: DropdownItem) {
    this.entitledStatus.setValue(status);
  }

  /**
   * @description Mostra una modale con un messaggio di errore
   * @param modalId
   * @param error
   */
  dispatchWarningModal(modalId: string, error: string) {
    const messageObj = getWarningModal(this.translate, modalId, error);
    this.appStore.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
  }

  initCompanies() {
    this.companyServive$.pipe(
      switchMap(
        (text: string) => {
          if (text !== this._companiesSearchedText) {
            this._resetCompanyResultList = true;
            this._companiesLoaded = 1;
          } else {
            this._resetCompanyResultList = false;
          }
          this._companiesSearchedText = text || '';
          this.isLoadingCompanyList = true;
          return this.getCompanyList((this._companiesLoaded - 1), 10, this._companiesSearchedText);
        }
      ),
      catchError((err, caught) => {
        return caught;
      })
    )
      .subscribe({
        next: (eventData: DropdownItem[]) => {
          if (this._resetCompanyResultList) {
            this.clearCompanyResults();
          }
          if (eventData && eventData.length) {
            this._companiesLoaded += eventData.length;
            this.companiesList = this.companiesList.concat(eventData);
          }
          this.isLoadingCompanyList = false;
        },
        error: (err: any) => {
          this.isLoadingCompanyList = false;
        }
      });
  }

  /**
   * @description Carica e prepara gli elementi per la select con infinite scroll
   * @param fromRecord
   * @param numRecords
   * @param searchedText
   */
  getCompanyList(fromRecord: number, numRecords: number, searchedText: string): Observable<DropdownItem[]> {
    return this.anagService.listCustomersForAdmin(fromRecord, numRecords, searchedText, this.campaignId)
      .pipe(
        takeUntil(this._unsubscribeSignal$.asObservable()),
        map((resultData: SenecaResponse<UserGroupAdmin[]>) => {
          if (resultData.error) {
            this.dispatchWarningModal('007', resultData.error);
          } else if (resultData.response && resultData.response.length) {
            let formattedData: DropdownItem[] = [];

            resultData.response.forEach(company => {

              formattedData.push({
                id: company.groupId,
                name: company.title,
                content: company
              });
            });

            // Caricando n elementi per volta (itemsToLoad) se
            // ne carica di meno significa che sono a fine lista
            if (resultData.response.length < numRecords) {
              this._isEndCompanyList = true;
            }

            return formattedData;
          }
          this._isEndCompanyList = true;
          return [];
        })
      );
  }

  /**
   * @description Alla selezione di un azienda
   * @param company
   */
  onCompanySelected(company?: DropdownItem) {
    this.selectedCompany = company;
  }

  /**
   * @description Chiamata quando viene effettuata una nuova ricerca differente
   */
  clearCompanyResults(): void {
    this._companiesLoaded = 0;
    this.companiesList = [];
    this._resetCompanyResultList = false;
  }

  /**
   * @description Chiamata allo scroll
   * @param scrollEvent
   */
  fetchMoreCompanies(scrollEvent: { start: number; end: number }) {
    if (!this._isEndCompanyList && scrollEvent.end === this._companiesLoaded) {
      this.companyServive$.next(this._companiesSearchedText);
    }
  }

  initStructures() {
    this.structureServive$.pipe(
      switchMap(
        (text: string) => {
          if (text !== this._structureSearchedText) {
            this._resetStructureResultList = true;
            this._structuresLoaded = 1;
          } else {
            this._resetStructureResultList = false;
          }
          this._structureSearchedText = text || '';
          this.isLoadingStructureList = true;
          return this.getVenueList((this._structuresLoaded - 1), 10, this._structureSearchedText);
        }
      ),
      catchError((err, caught) => {
        return caught;
      })
    )
      .subscribe({
        next: (eventData: Array<DropdownItem>) => {
          if (this._resetStructureResultList) {
            this.clearStructureResults();
          }
          if (eventData && eventData.length) {
            this._structuresLoaded += eventData.length;
            this.structureList = this.structureList.concat(eventData);
          }

          this.isLoadingStructureList = false;
        },
        error: (err: any) => {
          this.isLoadingStructureList = false;
        }
      });
  }

  /**
   * @description Carica e prepara gli elementi per la select con infinite scroll
   * @param fromRecord
   * @param numRecords
   * @param searchedText
   */
  getVenueList(fromRecord: number, numRecords: number, searchedText: string): Observable<DropdownItem[]> {
    return this.rentService.getVenueList(this.roundId, fromRecord, numRecords, searchedText)
      .pipe(
        takeUntil(this._unsubscribeSignal$.asObservable()),
        map((resultData: SenecaResponse<InfoVenue[]>) => {
          if (resultData.error) {
            this.dispatchWarningModal('007', resultData.error);
          } else if (resultData.response && resultData.response.length) {
            let formattedData: DropdownItem[] = [];

            resultData.response.forEach(structure => {
              let title = structure.name + ' - ' + structure.address;

              formattedData.push({
                id: structure.venueId,
                name: title,
                content: structure
              });
            });

            // Caricando n elementi per volta (itemsToLoad) se
            // ne carica di meno significa che sono a fine lista
            if (resultData.response.length < numRecords) {
              this._isEndStructureList = true;
            }

            return formattedData;
          }
          this._isEndStructureList = true;
          return [];
        })
      );
  }

  /**
   * @description Alla selezione di una struttura
   * @param structure
   */
  onStructureSelected(structure?: DropdownItem) {
    this.selectedStructure = structure;
  }

  /**
   * @description Chiamata quando viene effettuata una nuova ricerca differente
   */
  clearStructureResults(): void {
    this._structuresLoaded = 0;
    this.structureList = [];
    this._resetStructureResultList = false;
  }

  /**
   * @description Chiamata allo scroll
   * @param scrollEvent
   */
  fetchMoreStructures(scrollEvent: { start: number; end: number }) {
    if (!this._isEndStructureList && scrollEvent.end === this._structuresLoaded) {
      this.structureServive$.next(this._structureSearchedText);
    }
  }

  onCheckPrivacy(value: boolean) {
    this.privacy.setValue(value);
  }

  savePrivacyFile(file: any) {
    this.privacyFile = file;
  }

  closeAddEntitledErrorsModal() {
    this.modalService.close("addEntitledErrorsModal");
  }

  openAddEntitledErrorsModal() {
    this.modalService.open("addEntitledErrorsModal");
  }

}

import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';

@Component({
    selector: 'no-data',
    templateUrl: 'no-data-placeholder.component.html',
    styleUrls: ['./no-data-placeholder.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class NoDataPlaceholderComponent implements OnInit {
    @Input() src: string = '/assets/img/section/goal-setting.svg';
    @Input() title: string = "Goal Setting";
    @Input() inNotifyPage: boolean = false;
    @Input() description: string = "Aggiungi un obiettivo e avvia la fase di Goal setting di <b>Tramaglino Lorenzo<b>";
    constructor() { }

    ngOnInit() { 

    }

}

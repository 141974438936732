import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { Subject, of } from 'rxjs';
import { switchMap, takeUntil } from 'rxjs/operators';

import { User } from 'atfcore-commonclasses/bin/classes/anag';
import { InfoCampaign, InfoRound } from 'atfcore-commonclasses/bin/classes/campaignmanager';

import * as CoreActions from "../../core/ngrx/core.actions";
import * as fromApp from "../../ngrx/app.reducers";

import { AuthService } from 'src/app/auth/services/auth.service';
import { ScrollTo } from 'src/app/core/services/scroll-to.service';
import { ModalService } from 'src/app/shared/components/modal/modal.service';
import { DropdownButtonItem } from 'src/app/shared/components/welion-select-button/welion-select-button.component';
import { DropdownItem } from 'src/app/shared/models/dropdown.model';
import { RedirectService } from 'src/app/shared/services/redirect.service';
import { SidebarService } from 'src/app/shared/services/sidebar.service';
import { ExportService } from 'src/app/structure/services/export.service';
import { ImportService } from 'src/app/structure/services/import.service';
import { RentService } from 'src/app/structure/services/rent.service';
import { getModalMessageData, getWarningModal } from 'src/app/utils/utils';

@Component({
  selector: 'app-client-round-details',
  templateUrl: './clientRoundDetails.component.html',
  styleUrls: ['./clientRoundDetails.component.scss']
})
export class ClientRoundDetailsComponent implements OnInit, OnDestroy {

  loggedUser?: User;

  isLoadingBase: boolean = true;
  isLoadingCount: boolean = true;
  isLoading: boolean = true;

  hasCountAtLeastOne: boolean = false;

  campaign?: InfoCampaign;
  campaignId: string = '';

  readonly paginationLength: number = 50;

  round?: InfoRound;
  roundId: string = '';

  //Filtro per utente
  searchText?: string;
  searchTextScraps?: string;

  entitles: any[] = [];
  entitleCount: number = 0;

  isToResetUploadComponent: boolean = false;
  fileObject: any = null;

  isToResetUploadPartialDeleteComponent: boolean = false;
  partialDeleteFileObject: any = null;

  venues: DropdownItem[] = [];
  selectedVenue?: DropdownItem;

  selectedTab: number = 0;
  dropdownDeleteOptions: DropdownButtonItem[] = [];
  selectedAttendance: any;

  private _unsubscribeSignal$: Subject<void> = new Subject();
  private _firstInit: boolean = true;

  constructor(
    private route: ActivatedRoute,
    private appStore: Store<fromApp.AppState>,
    private translate: TranslateService,
    private redirectService: RedirectService,
    private rentService: RentService,
    private authService: AuthService,
    private modalService: ModalService,
    private importService: ImportService,
    private exportService: ExportService,
    private scrollTo: ScrollTo,
    private sidebarService: SidebarService
  ) {
  }

  ngOnInit() {

    this.scrollTo.header();

    this.campaignId = this.route.snapshot.paramMap.get('campaignId') || '';
    this.roundId = this.route.snapshot.paramMap.get('roundId') || '';

    if (!this.campaignId || !this.roundId) {
      this.redirectService.goToHome();
    }

    this.appStore.select(fromApp.getLoggedUser)
      .pipe(takeUntil(this._unsubscribeSignal$.asObservable()))
      .subscribe(
        (loggedUser) => {
          if (loggedUser && loggedUser.user) {
            this.loggedUser = loggedUser && loggedUser.user;

            if (this._firstInit) {
              this._firstInit = false;
              this.getCampaign();
            }
          }
        });
  }

  /**
   * Recupera la campagna relativa all'account cliente
   */
  getCampaign() {
    this.rentService.getCampaign(this.campaignId)
      .pipe(takeUntil(this._unsubscribeSignal$.asObservable()))
      .subscribe((result) => {
        if (!result || result.error) {
          this.dispatchModal('080', result.error || '');
          this.resetPagination();
        } else {
          this.campaign = result.response;
          this.getRound();
        }
      }, (err) => {
        if (err && err.message) {
          this.dispatchModal('081', err.message);
        }
        this.resetPagination();
      });
  }

  /**
   * Recupera il round relativo all'account cliente
   */
  getRound() {
    this.rentService.getRound(this.roundId)
      .pipe(takeUntil(this._unsubscribeSignal$.asObservable()))
      .subscribe((result) => {
        if (!result || result.error) {
          this.dispatchModal('080', result.error || '');
          this.resetPagination();
        } else {
          this.round = result.response;
          this.countEntitledListByRound();
        }
        this.isLoadingBase = false;
      }, (err) => {
        if (err && err.message) {
          this.dispatchModal('081', err.message);
        }
        this.resetPagination();
      });
  }

  /**
   * Conteggio degli AD scarti per round
   */
  countEntitledListByRoundScraps() {
    this.isLoadingCount = true;
    this.isLoading = true;

    this.entitleCount = 0;

    this.rentService.countEntitledListByRoundForCustomerScraps(this.roundId, this.searchTextScraps)
      .pipe(takeUntil(this._unsubscribeSignal$.asObservable()))
      .subscribe((result) => {
        if (!result || result.error) {
          this.dispatchModal('082', result.error || '');
          this.resetPagination();
        } else {
          this.entitleCount = result.response;

          if (this.entitleCount === 0) {
            this.resetPagination();
          } else {
            this.hasCountAtLeastOne = true;
            this.getEntitledListByRoundScraps();
          }
        }
        this.isLoadingCount = false;
      }, (err) => {
        if (err && err.message) {
          this.dispatchModal('084', err.message);
        }
        this.resetPagination();
      });
  }

  /**
   * Lista degli AD scarti per round
   */
  getEntitledListByRoundScraps(index?: number) {

    this.isLoading = true;

    const pageSelectedIndex = index ? index : 0;
    if (index === 0 || index) {
      this.scrollTo.header();
    }

    this.rentService.getEntitledListByRoundForCustomerScraps(this.roundId, (pageSelectedIndex * this.paginationLength), this.paginationLength, this.searchTextScraps)
      .pipe(takeUntil(this._unsubscribeSignal$.asObservable()))
      .subscribe((result) => {
        if (!result || result.error) {
          this.dispatchModal('082', result.error || '');
          this.resetPagination();
        } else {
          this.entitles = result.response;
        }
        this.isLoading = false;
      }, (err) => {
        if (err && err.message) {
          this.dispatchModal('084', err.message);
        }
        this.resetPagination();
      });
  }

  /**
   * Conteggio degli AD per round
   */
  countEntitledListByRound() {
    this.isLoadingCount = true;
    this.isLoading = true;

    this.entitleCount = 0;

    this.rentService.countEntitledListByRoundForCustomer(this.roundId, this.searchText)
      .pipe(takeUntil(this._unsubscribeSignal$.asObservable()))
      .subscribe((result) => {
        if (!result || result.error) {
          this.dispatchModal('082', result.error || '');
          this.resetPagination();
        } else {
          this.entitleCount = result.response;
          this.setDropdownActions();

          if (this.entitleCount === 0) {
            this.resetPagination();
          } else {
            this.hasCountAtLeastOne = true;
            this.getEntitledListByRound();
          }
        }
        this.isLoadingCount = false;
      }, (err) => {
        if (err && err.message) {
          this.dispatchModal('084', err.message);
        }
        this.resetPagination();
      });
  }

  /**
   * Lista degli AD per round
   */
  getEntitledListByRound(index?: number) {

    this.isLoading = true;

    const pageSelectedIndex = index ? index : 0;
    if (index === 0 || index) {
      this.scrollTo.header();
    }

    this.rentService.getEntitledListByRoundForCustomer(this.roundId, (pageSelectedIndex * this.paginationLength), this.paginationLength, this.searchText)
      .pipe(takeUntil(this._unsubscribeSignal$.asObservable()))
      .subscribe((result) => {
        if (!result || result.error) {
          this.dispatchModal('082', result.error || '');
          this.resetPagination();
        } else {
          this.entitles = result.response;
        }
        this.isLoading = false;
      }, (err) => {
        if (err && err.message) {
          this.dispatchModal('084', err.message);
        }
        this.resetPagination();
      });
  }

  resetPagination() {
    this.isLoadingBase = false;
    this.isLoadingCount = false;
    this.isLoading = false;

    this.entitles = [];
    this.entitleCount = 0;
  }

  searchTextChange(searchText?: string) {
    this.searchText = searchText;
  }

  searchTextChangeScraps(searchText?: string) {
    this.searchTextScraps = searchText;
  }

  searchEntitled() {
    this.countEntitledListByRound();
  }

  searchEntitledScraps() {
    this.countEntitledListByRoundScraps();
  }

  goBackToRoundList() {
    this.redirectService.goToHome();
  }

  /**
   * @description Mostra una modale con un messaggio di errore
   * @param modalId
   * @param error
   */
  dispatchModal(modalId: string, error: string) {
    const messageObj = getWarningModal(this.translate, modalId, error);
    this.appStore.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
  }

  ngOnDestroy() {
    this._unsubscribeSignal$.next();
    this._unsubscribeSignal$.unsubscribe();
  }

  /**
   * @description Mostra una modale con un messaggio di errore
   * @param modalId
   * @param error
   */
  dispatchWarningModal(modalId: string, error: string) {
    const messageObj = getWarningModal(this.translate, modalId, error);
    this.appStore.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
  }

  /**
   * @description Mostra una modale con titolo e messaggio
   * @param modalId
   * @param title
   * @param message
   */
  dispatchMessageModal(title: string, message: string) {
    const messageObj = getModalMessageData(this.translate, title, message);
    this.appStore.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
  }

  /**
   * @description Apre la modale per la cancellazione degli AD in transito
   */
  openDeleteAllEntitledsModal() {
    this.modalService.open('deleteAllEntitledsFirstModal');
  }

  /**
   * @description Chiude la modale per la cancellazione degli AD in transito
   */
  closeDeleteAllEntitledsModal() {
    this.modalService.close('deleteAllEntitledsFirstModal');
  }

  /**
   * @description Chiude la modale per conferma la cancellazione parziale degli AD tramite excel
   */
  closeConfirmExcelPartialDelete() {
    this.modalService.close('confirmExcelPartialDelete');
  }

  openConfirmExcelPartialDelete() {
    this.modalService.open('confirmExcelPartialDelete');
  }

  openDeleteAllEntitledsFinalModal() {
    this.modalService.open('deleteAllEntitledSecondModal');
  }

  closeDeleteAllEntitledsFinalModal() {
    this.modalService.close('deleteAllEntitledSecondModal');
  }

  confirmDeleteAllEntitledsFinal() {
    this.rentService.deleteUnprocessedEntitledForCustomer(this.roundId)
      .pipe(takeUntil(this._unsubscribeSignal$.asObservable()))
      .subscribe((result) => {
        this.closeDeleteAllEntitledsFinalModal();
        if (!result || result.error) {
          this.dispatchModal('082', result.error || '');
        } else {
          this.entitles = [];
          this.countEntitledListByRound();
        }
      }, (err) => {
        if (err && err.message) {
          this.closeDeleteAllEntitledsFinalModal();
          this.dispatchModal('084', err.message);
        }
        this.resetPagination();
      });
  }

  exportEntitledsList() {
    this.isLoading = true;

    this.exportService.exportEntitledList(this.campaignId, this.roundId)
      .pipe(
        switchMap((senecaResponse) => {
          if (senecaResponse && senecaResponse.error) {
            this.dispatchWarningModal('030', senecaResponse.error);
            this.isLoading = false;

            return of(null);
          }
          return this.exportService.getDownloadTempFileUrl(senecaResponse.response, false);
        }),
        takeUntil(this._unsubscribeSignal$.asObservable())
      )
      .subscribe(downloadUrl => {
        if (downloadUrl) {
          const timeout = setTimeout(() => {
            clearTimeout(timeout);
            document.location.assign(downloadUrl);
            this.isLoading = false;
          }, 500);
        }
      }, (err) => {
        this.isLoading = false;
        if (err && err.message) {
          this.dispatchWarningModal('031', err.message);
        }
      });
  }

  /**
   * Recupera token dalla chiave dell'url
   * @param callback
   */
  getTokenFromSsortkqp(callback: (value: string) => void) {
    this.authService.crateRetrieveTokenAfterLogin()
      .pipe(takeUntil(this._unsubscribeSignal$.asObservable()))
      .subscribe(result => {
        if (result && result.error) {
          this.isLoading = false;
          this.dispatchWarningModal('028', result.error);
        } else {
          callback(result.response);
        }
      }, (err) => {
        if (err && err.message) {
          this.isLoading = false;
          this.dispatchWarningModal('029', err.message);
        }
      });
  }

  //#region Caricamento massivo

  /**
   * @description Apre la modale per il caricamento massivo
   */
  openMassiveLoadingModal() {
    this.resetUploadStructureData();

    this.rentService.getVenueList(this.roundId, 0, 100, undefined, undefined, true)
      .pipe(
        takeUntil(this._unsubscribeSignal$.asObservable()))
      .subscribe((result) => {

        this.venues = [{
          id: '',
          name: '-'
        }];

        this.selectedVenue = this.venues[0];

        if (!result || result.error) {
          this.dispatchModal('092', result.error || '');
        } else {

          if (result.response && result.response.length) {
            result.response.forEach(structure => {
              let title = structure.name + ' - ' + structure.address;

              this.venues.push({
                id: structure.venueId,
                name: title,
                content: structure
              });
            });
          } else {
            this.venues = [];
          }
          this.modalService.open('massiveLoad');
        }
      }, (err) => {
        if (err && err.message) {
          this.dispatchModal('093', err.message);
        }
        this.resetPagination();
      });

  }

  // Apri modale eliminazione parziale persone
  openModalPartialDelete() {
    this.resetUploadPartialDeleteData();
    this.modalService.open('partialDelete');
  }

  /**
   * @description Chiude la modale per il caricamento massivo
   */
  closeMassiveLoadingModal() {
    this.modalService.close('massiveLoad');
  }

  /**
   * @description Chiude la modale per la cancellazione parziale
   */
  closePartialDeleteLoadingModal() {
    this.modalService.close('partialDelete');
  }

  /**
   * @description Verifica se il pulsante per confermare l'import massivo delle strutture è disabilitato
   */
  isConfirmUploadAttendancesBtnDisabled(): boolean {
    return !this.fileObject || (this.fileObject && !this.fileObject.fileExtension);
  }

  /**
   * @description Verifica se il pulsante per confermare l'import delle persone da eliminare è disabilitato
   */
  isConfirmUploadPartialDeleteBtnDisabled(): boolean {
    return !this.partialDeleteFileObject || (this.partialDeleteFileObject && !this.partialDeleteFileObject.fileExtension);
  }

  /**
   * @description Pulisce i dati del form di upload delle strutture
   */
  resetUploadStructureData() {
    this.fileObject = null;
    this.isToResetUploadComponent = true;
    this.selectedVenue = undefined;
  }

  /**
   * @description Pulisce i dati del form di upload dell'eliminazione parziale delle persone
   */
  resetUploadPartialDeleteData() {
    this.partialDeleteFileObject = null;
    this.isToResetUploadPartialDeleteComponent = true;
  }

  /**
   * @description Effettua l'upload massivo delle strutture
   */
  uploadAttendancesFile() {
    // Chiudo la modale, mostro il loader e chiamo il servizio di upload
    this.closeMassiveLoadingModal();
    this.isLoading = true;

    if (this.selectedVenue) {
      this.importService.importEntitledForCustomer(this.roundId, this.selectedVenue.id, this.fileObject)
        .subscribe((data: any) => {
          if (data.error) {
            if (data.error === '') {
              this.dispatchWarningModal('013', 'USER_WITHOUT_AUTHS_IMPORT');
            } else {
              this.dispatchWarningModal('013', data.error);
            }
            this.isLoading = false;
          } else {
            this.dispatchMessageModal('generic.MASSIVE_LOADING', 'generic.PEOPLE_UPLOADED');
            this.countEntitledListByRound();
          }
        },
          (err: string) => {
            this.dispatchWarningModal('015', err);
            this.isLoading = false;
          });
    }
  }

  /**
   * @description Effettua l'eliminazione parziale delle persone
   */
  uploadPartialDeleteFile() {
    // Chiudo la modale, mostro il loader e chiamo il servizio di upload
    this.closePartialDeleteLoadingModal();
    this.isLoading = true;

    this.importService.importEntitledForPartialDelete(this.roundId, this.partialDeleteFileObject)
      .subscribe((data: any) => {
        const senecaRes = data && data.body;
        if (senecaRes) {
          if (senecaRes.error) {
            if (senecaRes.error === '') {
              this.dispatchWarningModal('013', 'USER_WITHOUT_AUTHS_IMPORT');
            } else {
              this.dispatchWarningModal('013', senecaRes.error);
            }
            this.isLoading = false;
          } else {
            this.dispatchMessageModal('generic.PARTIAL_DELETE', 'generic.PEOPLE_DELETED');
            this.countEntitledListByRound();
          }
        }
      },
        (err: string) => {
          this.dispatchWarningModal('015', err);
          this.isLoading = false;
        });
  }

  onVenueSelected(structure?: DropdownItem) {
    this.selectedVenue = structure;
  }

  /**
   * @description Salva localmente il file contenente tutte le strutture da caricare
   * @param file
   */
  saveAttendancesFile(file: any) {
    if (this.isToResetUploadPartialDeleteComponent) {
      this.partialDeleteFileObject = file;
      this.isToResetUploadPartialDeleteComponent = false;
    } else {
      this.fileObject = file;
      this.isToResetUploadComponent = false;
    }
  }

  /**
   * @description Salva localmente il file contenente le persone da eliminare
   * @param file
   */
  savePartialDeleteFile(file: any) {
    this.partialDeleteFileObject = file;
    this.isToResetUploadPartialDeleteComponent = false;
  }

  // Cambio tab della tabella
  onTabClick(tabNumber: number) {
    this.selectedTab = tabNumber;

    this.hasCountAtLeastOne = false;
    this.searchText = '';
    this.searchTextScraps = '';

    this.resetPagination();

    if (tabNumber == 0) {
      this.countEntitledListByRound();
    } else {
      this.countEntitledListByRoundScraps();
    }
  }

  // Options della select elimina
  setDropdownActions() {
    this.dropdownDeleteOptions = [
      {
        label: 'client.PARTIAL_DELETE',
        onClick: () => {
          this.openModalPartialDelete();
        },
        icon: 'assets/img/icons/icon-excel.svg'
      }, {
        label: 'admin.campaignRoundDetails.DELETE_ALL',
        onClick: () => {
          this.openDeleteAllEntitledsModal();
        },
        icon: 'assets/img/icons/icon-bin.svg',
        strokeIcon: true
      }
    ];
  }

  fileImportPeople() {
    window.open('/assets/documents/templateImportAventiDiritto.xlsx');
  }

  fileImportDeletePeople() {
    window.open('/assets/documents/templateImportAventiDirittoEliminazioneParziale.xlsx');
  }

  openAttendenceCard(attendance: any) {
    this.selectedAttendance = attendance;

    this.sidebarService.setShowNav(true);

  }

  closeAttendanceCard() {
    this.sidebarService.setShowNav(false);
  }

  // Report scarti
  exportScrapsList() {
    this.isLoading = true;

    this.exportService.exportClientScrapsList(this.roundId)
      .pipe(
        switchMap((senecaResponse) => {
          if (senecaResponse && senecaResponse.error) {
            this.dispatchWarningModal('030', senecaResponse.error);
            this.isLoading = false;

            return of(null);
          }
          return this.exportService.getDownloadTempFileUrl(senecaResponse.response, false);
        }),
        takeUntil(this._unsubscribeSignal$.asObservable())
      )
      .subscribe(downloadUrl => {
        if (downloadUrl) {
          const timeout = setTimeout(() => {
            clearTimeout(timeout);
            document.location.assign(downloadUrl);
            this.isLoading = false;
          }, 500);
        }
      }, (err) => {
        this.isLoading = false;
        if (err && err.message) {
          this.dispatchWarningModal('031', err.message);
        }
      });
  }

  closeDeletePartialEntitledsModal() {
    this.modalService.close('deletePartialEntitledsModal');
  }

  openDeletePartialEntitledsModal() {
    this.modalService.open('deletePartialEntitledsModal');
  }

  //#endregion

}

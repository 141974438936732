import { FormControl, FormGroup, Validators } from "@angular/forms";
import { Component, OnDestroy, OnInit, Renderer2, ViewChild } from '@angular/core';
import { Store } from "@ngrx/store";
import { TranslateService } from '@ngx-translate/core';
import { Subject } from 'rxjs';
import { takeUntil } from "rxjs/operators";

import { RedirectService } from 'src/app/shared/services/redirect.service';
import { getModalMessageData, getWarningModal } from "src/app/utils/utils";
import { ScrollTo } from "src/app/core/services/scroll-to.service";
import { DropdownItem } from "src/app/shared/models/dropdown.model";
import { NoteTypes } from "atfcore-commonclasses";
import * as fromApp from "../../ngrx/app.reducers";
import { UserService } from "src/app/shared/services/user.service";
import * as CoreActions from "../../core/ngrx/core.actions";
import { ActivatedRoute } from "@angular/router";
import { Title } from "@angular/platform-browser";
import { NgSelectComponent } from "@ng-select/ng-select";

@Component({
  selector: 'app-add-ticket',
  templateUrl: './addTicket.component.html',
  styleUrls: ['./addTicket.component.scss']
})
export class AddTicketComponent implements OnInit, OnDestroy {
  isLoading: boolean = false;
  roundId: string = '';
  userId: string = '';

  addTicketForm = new FormGroup({
    'name': new FormControl(undefined, Validators.required),
    'surname': new FormControl(undefined, Validators.required),
    'fiscalCode': new FormControl(undefined, { validators: [Validators.required, Validators.pattern('^[A-Z0-9]+$')]}),
    'company': new FormControl(undefined, Validators.required),
    'mail': new FormControl(undefined, [Validators.required, Validators.email]),
    'phoneNumber': new FormControl(undefined, [
      Validators.required,
      Validators.pattern(/^[-+0-9 ]*$/)
    ]),
    'ticketType': new FormControl(undefined, Validators.required),
    'ticketObject': new FormControl(undefined, Validators.required),
    'ticketText': new FormControl(undefined, Validators.required)
  });

  get name() {
    return this.addTicketForm.get('name') as FormControl;
  }

  get surname() {
    return this.addTicketForm.get('surname') as FormControl;
  }

  get fiscalCode() {
    return this.addTicketForm.get('fiscalCode') as FormControl;
  }

  get company() {
    return this.addTicketForm.get('company') as FormControl;
  }

  get mail() {
    return this.addTicketForm.get('mail') as FormControl;
  }

  get phoneNumber() {
    return this.addTicketForm.get('phoneNumber') as FormControl;
  }

  get ticketType() {
    return this.addTicketForm.get('ticketType') as FormControl;
  }

  get ticketObject() {
    return this.addTicketForm.get('ticketObject') as FormControl;
  }

  get ticketText() {
    return this.addTicketForm.get('ticketText') as FormControl;
  }

  ticketTypes: DropdownItem[] = [];
  ticketObjectsFirst: DropdownItem[] = [];
  ticketObjectsSecond: DropdownItem[] = [];
  ticketObjectsThird: DropdownItem[] = [];
  ticketObjectsOther: DropdownItem[] = [];

  ticketObjectsToUse: DropdownItem[] = [];
  loggedUser: any;

  private _unsubscribeSignal$: Subject<void> = new Subject();

  constructor(
    private translate: TranslateService,
    private redirectService: RedirectService,
    private scrollTo: ScrollTo,
    private appStore: Store<fromApp.AppState>,
    private userService: UserService,
    private route: ActivatedRoute,
    private titleService: Title
  ) {
    this.translate.get(
      [
        "addTicket.ASK_SUPPORT"
      ]).subscribe(translations => {
        this.titleService.setTitle(translations["addTicket.ASK_SUPPORT"]);
      });
    this.appStore.select(fromApp.getLoggedUser)
      .pipe(takeUntil(this._unsubscribeSignal$.asObservable()))
      .subscribe(
        (loggedUser) => {
          if (loggedUser && loggedUser.user) {
            this.loggedUser = loggedUser && loggedUser.user;
            this.name.setValue(this.loggedUser.forename || '');
            this.surname.setValue(this.loggedUser.surname || '');
            this.fiscalCode.setValue(this.loggedUser.codFisc || '');
            this.company.setValue(this.loggedUser.company || this.loggedUser.socDistacco || '');
            this.mail.setValue(this.loggedUser.email || '');
            this.phoneNumber.setValue(this.loggedUser.phoneNumber || '');

            // Se non c'è il nome dell'azienda, la recupero dai servizi
            if (!this.company.value || !this.company.value.length || !this.phoneNumber.value || !this.phoneNumber.value.length) {
              this.getSupplierByUser();
            }
          }
        });
  }

  // Vereifica se l'utente loggato sta creando il ticket
  isUserCreatingTicket() {
    return this.redirectService.isThisCurrentPage('user') && this.loggedUser;
  }

  ngOnInit() {
    this.scrollTo.header();

    this.roundId = this.route.snapshot.paramMap.get('roundId') || '';
    this.userId = this.route.snapshot.paramMap.get('userId') || '';

    this.translate.get(
      [
        'addTicket.PLATFORM_SIGNUP',
        'addTicket.VACCINATION_PRENOTATION',
        'addTicket.VACCINATION_SOMMINISTRATION',
        'addTicket.OTHER',
        'addTicket.COMPILATION_REGISTRATION_FORM',
        'addTicket.NO_MAIL',
        'addTicket.NEW_PRENOTATION',
        'addTicket.UPDATE_PRENOTATION',
        'addTicket.DELETE_PRENOTATION',
        'addTicket.DISSERVICE_STRUCTURE',
        'addTicket.DISSERVICE_COMPANY',
        'addTicket.MANAGE_UNDERAGE_PARENT_BOOKING',
        'addTicket.MANAGE_PARENT_BOOKING'
      ])
      .pipe(takeUntil(this._unsubscribeSignal$.asObservable()))
      .subscribe(translations => {
        this.ticketTypes = [
          {
            id: NoteTypes.PLATFORM_SIGNUP,
            name: translations['addTicket.PLATFORM_SIGNUP']
          },
          {
            id: NoteTypes.VACCINATION_BOOKING,
            name: translations['addTicket.VACCINATION_PRENOTATION']
          },
          {
            id: NoteTypes.VACCINE_ADMINISTRATION,
            name: translations['addTicket.VACCINATION_SOMMINISTRATION']
          },
          {
            id: NoteTypes.OTHER,
            name: translations['addTicket.OTHER']
          }
        ]

        this.ticketObjectsFirst = [
          {
            id: NoteTypes.FORM_COMPILATION,
            name: translations['addTicket.COMPILATION_REGISTRATION_FORM']
          },
          {
            id: NoteTypes.MISSING_CONFIRMATION_MAIL,
            name: translations['addTicket.NO_MAIL']
          },
          {
            id: NoteTypes.OTHER,
            name: translations['addTicket.OTHER']
          }
        ]

        this.ticketObjectsSecond = [
          {
            id: NoteTypes.NEW_BOOKING,
            name: translations['addTicket.NEW_PRENOTATION']
          },
          {
            id: NoteTypes.BOOKING_UPDATE,
            name: translations['addTicket.UPDATE_PRENOTATION']
          },
          {
            id: NoteTypes.BOOKING_CANCELLATION,
            name: translations['addTicket.DELETE_PRENOTATION']
          },
          {
            id: NoteTypes.MANAGE_UNDERAGE_PARENT_BOOKING,
            name: translations['addTicket.MANAGE_UNDERAGE_PARENT_BOOKING']
          },
          {
            id: NoteTypes.MANAGE_PARENT_BOOKING,
            name: translations['addTicket.MANAGE_PARENT_BOOKING']
          },
          {
            id: NoteTypes.OTHER,
            name: translations['addTicket.OTHER']
          }
        ]

        this.ticketObjectsThird = [
          {
            id: NoteTypes.STRUCTURE_DISSERVICE,
            name: translations['addTicket.DISSERVICE_STRUCTURE']
          },
          {
            id: NoteTypes.COMPANY_DISSERVICE,
            name: translations['addTicket.DISSERVICE_COMPANY']
          },
          {
            id: NoteTypes.OTHER,
            name: translations['addTicket.OTHER']
          }
        ]

        this.ticketObjectsOther = [
          {
            id: NoteTypes.OTHER,
            name: translations['addTicket.OTHER']
          }
        ]
      });
  }

  ngOnDestroy() {
    this._unsubscribeSignal$.next();
    this._unsubscribeSignal$.unsubscribe();
  }

  /**
   * @description Va all'homepage delle faq
   */
  goBack() {
    window.history.back();
  }

  // Invia segnalazione
  confirmAddTicket() {
    if (this.addTicketForm.valid) {
      this.isLoading = true;

      let ticketTypeId: string = '';
      if (this.ticketType.value && (this.ticketType.value as DropdownItem).id) {
        ticketTypeId = (this.ticketType.value as DropdownItem).id;
      }

      let ticketObjectId: string = '';
      if (this.ticketObject.value && (this.ticketObject.value as DropdownItem).id) {
        ticketObjectId = (this.ticketObject.value as DropdownItem).id;
      }

      this.userService.sendTicket(
        this.name.value,
        this.surname.value,
        this.fiscalCode.value,
        this.company.value,
        this.mail.value,
        this.phoneNumber.value,
        ticketTypeId,
        ticketObjectId,
        this.ticketText.value).pipe(takeUntil(this._unsubscribeSignal$.asObservable()))
        .subscribe((result: any) => {
          if (!result || result.error) {
            this.dispatchWarningModal('041', result.error || '');
          } else {
            // Prima di tornare indietro, mostro un messaggio di conferma all'utente
            const title = this.translate.instant("addTicket.REQUEST_SENT");
            let message = this.translate.instant("addTicket.REQUEST_SENT_INFO") + ' ' + this.mail.value;
            const messageObj = getModalMessageData(this.translate, title, message);
            this.appStore.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));

            window.history.back();
          }
          this.isLoading = false;
        }, (err: any) => {
          if (err && err.message) {
            this.dispatchWarningModal('042', err.message);
          }
          this.isLoading = false;
        });
    }
  }

  // Recupera il nome dell'azienda dell'utente loggato
  getSupplierByUser() {
    this.isLoading = true;

    this.userService.getSupplierByUser().pipe(takeUntil(this._unsubscribeSignal$.asObservable()))
      .subscribe((result: any) => {
        if (!result || result.error) {
          this.dispatchWarningModal('041', result.error || '');
        } else if(result.response) {
          this.company.setValue(result.response.customerName || '');
          this.phoneNumber.setValue(result.response.phoneNumber || '');
        }
        this.isLoading = false;
      }, (err: any) => {
        if (err && err.message) {
          this.dispatchWarningModal('042', err.message);
        }
        this.isLoading = false;
      });
  }

  onChangeTicketType(value: DropdownItem) {
    this.ticketType.setValue(value);
    if (this.ticketType.value.id == NoteTypes.PLATFORM_SIGNUP) {
      this.ticketObjectsToUse = this.ticketObjectsFirst;
    } else if (this.ticketType.value.id == NoteTypes.VACCINATION_BOOKING) {
      this.ticketObjectsToUse = this.ticketObjectsSecond;
    } else if (this.ticketType.value.id == NoteTypes.VACCINE_ADMINISTRATION) {
      this.ticketObjectsToUse = this.ticketObjectsThird;
    } else if (this.ticketType.value.id == NoteTypes.OTHER) {
      this.ticketObjectsToUse = this.ticketObjectsOther;
    }
  }

  onChangeTicketObject(value: DropdownItem) {
    this.ticketObject.setValue(value);
  }

  /**
   * @description Mostra una modale con un messaggio di errore
   * @param modalId
   * @param error
   */
  dispatchWarningModal(modalId: string, error: string) {
    const messageObj = getWarningModal(this.translate, modalId, error);
    this.appStore.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
  }
}

import { SenecaResponse } from 'atfcore-commonclasses';
import * as moment from 'moment';
import { AuthService } from 'src/app/auth/services/auth.service';

export * from "atfcore-commonclasses/bin/classes/auth";
export * from "atfcore-commonclasses/bin/classes/anag";
export * from "atfcore-commonclasses/bin/classes/common";
export * from "atfcore-commonclasses/bin/classes/performancecare";
export * from "atfcore-commonclasses/bin/classes/performancemanagement";
export * from "atfcore-commonclasses/bin/classes/rent";
export * from "atfcore-commonclasses/bin/classes/tag";
export * from "atfcore-commonclasses/bin/classes/utils";
export * from "atfcore-commonclasses/bin/classes/campaignmanager";

export class DateUtil {
  static getDateWithoutSeconds(value: any): any {
    if (!value) {
      return null;
    }
    if (typeof value === 'string') {
      return new Date(new Date(value).setSeconds(0, 0));
    }
    if (value instanceof Date) {
      return new Date(value.setSeconds(0, 0));
    }
  }

  static convertModelToUTC(value: any): any {
    const _date = DateUtil.getDateWithoutSeconds(value);
    if (_date && _date instanceof Date) {
      return new Date(_date.toUTCString()).toISOString();
    }
    return null;
  }

  static calculateDateTimeByDay(dayDate: string, time: string) {
    let resultFromMoment = null;
    if (dayDate && time) {
      resultFromMoment = moment(dayDate).add(new Date(time).getHours(), 'hours').toISOString();
      resultFromMoment = moment(resultFromMoment).add(new Date(time).getMinutes(), 'minutes').toISOString();
    } else {
      resultFromMoment = time && moment(time).toISOString();
    }
    return resultFromMoment;
  }
}

export function getTokenFromSsortkqp(key: string, authService: AuthService): Promise<string | null> {
  return new Promise((resolve, reject) => {
    authService.retrieveTokenAfterLogin(key)
      .subscribe((senecaResponse: SenecaResponse<string>) => {
        if (senecaResponse.error) {
          reject(senecaResponse.error);
        } else {
          if (senecaResponse && senecaResponse.response) {
            resolve(senecaResponse.response);
          } else {
            resolve(null);
          }
        }
      },
        (err: any) => {
          reject(err);
        })
  })
}

export function removeURLParameter(url: string, parameter: string) {
  //prefer to use l.search if you have a location/link object
  var urlparts = url.split('?');
  if (urlparts.length >= 2) {

    var prefix = encodeURIComponent(parameter) + '=';
    var pars = urlparts[1].split(/[&;]/g);

    //reverse iteration as may be destructive
    for (var i = pars.length; i-- > 0;) {
      //idiom for string.startsWith
      if (pars[i].lastIndexOf(prefix, 0) !== -1) {
        pars.splice(i, 1);
      }
    }

    return urlparts[0] + (pars.length > 0 ? '?' + pars.join('&') : '');
  }
  return url;
}

export function parseBoolean(val: string | boolean | number): boolean {
  return !!(val && (val === true || val === "true" || val === "1" || val === 1));
}

export function readBooleanInput(flag: any): boolean {
  return !!flag && (flag === true || flag.toString() === 'true');
}

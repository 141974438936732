import { Component, OnDestroy } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Store } from '@ngrx/store';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import * as fromApp from "../../../ngrx/app.reducers";
import { Lang } from 'atfcore-commonclasses/bin/classes/anag';
import { Title } from '@angular/platform-browser';
@Component({
  selector: 'app-accessibility',
  templateUrl: './accessibility.component.html',
  styleUrls: ['./accessibility.component.scss']
})
export class AccessibilityComponent implements OnDestroy {
langs?: Lang[];

  private _unsubscribeSignal$: Subject<void> = new Subject();

  constructor(
    private store: Store<fromApp.AppState>,
    public translate: TranslateService,
    private titleService: Title
  ) {
    this.translate.get(
      [
        "generic.ACCESSIBILITY"
      ]).subscribe(translations => {
        this.titleService.setTitle(translations["generic.ACCESSIBILITY"]);
      });
    this.store.select(fromApp.getAvailableLangs)
      .pipe(takeUntil(this._unsubscribeSignal$.asObservable()))
      .subscribe(
        (langs) => {
          this.langs = langs as Lang[];
        });
  }

  ngOnDestroy() {
    this._unsubscribeSignal$.next();
    this._unsubscribeSignal$.unsubscribe();
  }

  openLink(url:string){
    window.open(url);
  }
}

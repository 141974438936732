import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { StructureComponent } from './structure.component';
import { StructureHomeComponent } from './home/home.component';
import { StructureGuard } from '../shared/services/structure-guard.service';
import { ActiveCampaignsComponent } from './activeCampaigns/activeCampaigns.component';
import { CampaignDetailsComponent } from './campaignDetails/campaignDetails.component';
import { RoundStructuresComponent } from './roundStructures/roundStructures.component';
import { RoundAttendancesComponent } from './roundAttendances/roundAttendances.component';
import { AddSlotsComponent } from './addSlots/addSlots.component';

const structureRoutes: Routes = [
  {
    path: '', component: StructureComponent, children: [
      { path: 'home', component: StructureHomeComponent, canActivate: [StructureGuard] },
      { path: 'home/:officeId', component: StructureHomeComponent, canActivate: [StructureGuard] },
      { path: 'activeCampaigns', component: ActiveCampaignsComponent, canActivate: [StructureGuard] },
      { path: 'activeCampaigns/:officeId', component: ActiveCampaignsComponent, canActivate: [StructureGuard] },
      { path: 'activeCampaigns/details/:campaignId', component: CampaignDetailsComponent, canActivate: [StructureGuard] },
      { path: 'activeCampaigns/:officeId/details/:campaignId', component: CampaignDetailsComponent, canActivate: [StructureGuard] },
      { path: 'round/slots/:roundId', component: RoundStructuresComponent, canActivate: [StructureGuard] },
      { path: 'round/slots/:roundId/:officeId', component: RoundStructuresComponent, canActivate: [StructureGuard] },
      { path: 'round/slots/add/:roundId/:venueId', component: AddSlotsComponent, canActivate: [StructureGuard] },
      { path: 'round/attendance/:roundId', component: RoundAttendancesComponent, canActivate: [StructureGuard] },
      { path: 'round/attendance/:roundId/:officeId', component: RoundAttendancesComponent, canActivate: [StructureGuard] },
      { path: "", redirectTo: "home", pathMatch: "full" }
    ]
  }
];

@NgModule({
  imports: [
    RouterModule.forChild(structureRoutes)
  ],
  providers: [
  ],
  exports: [RouterModule]
})
export class StructureRoutingModule { }

<main aria-label="Contenuto principale della pagina" tabindex="-1">
    <ng-container *ngIf="langs && langs.length">
        <div class="main-content-accessibility" fxLayout="column" fxLayoutAlign="center center">
            <div class="accessibility-wrapper" fxLayout="column">
                <div fxLayout="row" fxLayoutAlign="center">
                    <svg-icon src="assets/img/icons/app-logo.svg" [applyClass]="true"></svg-icon>
                </div>

                <h1 fxFlexOffset="12px" class="primary bold">
                    Dichiarazione
                    di Accessibilità
                </h1>

                <div fxFlexOffset="20px" fxLayout="row" class="accessibility-box readOnly">

                    <div class="accessibility-container">
                        <h2 class="title-accessibility-container">Linee guida sull’accessibilità degli strumenti informatici</h2>
                        <p>
                            <br>
                            <strong>In breve</strong><br><br>
                            Modello di dichiarazione di accessibilità̀ sito web e applicazione mobile per i soggetti di
                            cui
                            all’art. 3 comma 1-bis della legge 9 gennaio 2004, n.4 <br><br>
                            Campagna salute: <span class="bold underline cursor" tabindex="0" clickKeyBoard
                                (onEnterKeyboard)="openLink('https://campagnesalute.welion.it/#/accessibilita')"
                                role="button">https://campagnesalute.welion.it/#/accessibilita</span>
                        </p>
                        <br><br>
                        <strong>PREMESSA</strong>
                        <div class="divisor"></div>
                        <p>Generali Welion S.C.A.R.L. si impegna a rendere il proprio portale Campagne salute
                            accessibile,
                            conformemente al D.lgs 10 agosto 2018, n. 106 che ha recepito la direttiva UE 2016/2102 del
                            Parlamento europeo e del Consiglio.<br><br>
                            La presente dichiarazione di accessibilità si applica a “Generali Welion
                            S.C.A.R.L.”.<br><br>
                            Campagne salute: <span class="underline bold cursor" tabindex="0" clickKeyBoard
                                (onEnterKeyboard)="openLink('https://campagnesalute.welion.it/#/accessibilita')"
                                role="button">https://campagnesalute.welion.it/#/accessibilita</span>
                        </p>

                        <br><br>
                        <strong>STATO DI CONFORMITÀ</strong>
                        <div class="divisor"></div>
                        <p>Il portale Campagne salute <span class="underline bold cursor" tabindex="0" clickKeyBoard
                                (onEnterKeyboard)="openLink('https://campagnesalute.welion.it/#/accessibilita')"
                                role="button">https://campagnesalute.welion.it/#/accessibilita</span>
                            è <b>parzialmente conforme</b> ai requisiti previsti dall’allegato A alla norma UNI
                            EN301549:2018 (WCAG2.1) in
                            ragione dei casi di non conformità e/o delle deroghe elencate di seguito.<br><br>
                            La Compagnia ha in corso un’analisi finalizzata a individuare gli interventi necessari al
                            fine
                            di rendere
                            il sito in oggetto accessibile e conforme alla normativa.</p>
                        <br><br>
                        <strong>CONTENUTI NON ACCESSIBILI</strong>
                        <div class="divisor"></div>
                        <p>
                            Di seguito sono elencati i contenuti attualmente online non completamente accessibili per
                            inosservanza della legge 4/2004.<br><br>
                            <strong>Metodologia di analisi</strong><br><br>
                            La presente Dichiarazione di Accessibilità prende in considerazione gli standard WCAG
                            2.1.<br><br>
                            Sono stati utilizzati strumenti con tecnologie assistive come i principali screen reader
                            (JAWS o
                            VoiceOver) utili per identificare il testo mostrato sullo schermo, presentandolo come output
                            in
                            sintesi
                            vocale. Gli screen reader sono strumenti utilizzati da persone non vedenti, ipovedenti e da
                            chi
                            ha una
                            DSA (dislessia).<br><br>
                            Alcune utenti potrebbero incontrare difficoltà nella fruizione di alcune pagine o funzioni
                            di
                            questo sito, in quanto solo in parte accessibili.
                        </p>
                        <ul>
                            <li><strong>Contenuti non testuali</strong>: il testo alternativo che rende accessibili gli
                                elementi grafici (eg.
                                Immagini) alle tecnologie assistive, in numerosi casi, non è presente.</li>
                            <li><strong>Contrasto (minimo)</strong>: In alcune pagine del sito, alcuni elementi non
                                soddisfano il contrasto
                                minimo di colore/luminosità richiesto dagli standard di accessibilità.</li>
                            <li><strong>Accessibilità da tastiera</strong>: In diverse pagine del sito alcune
                                funzionalità
                                non risultano
                                accessibili da tastiera e il focus della tastiera non è sempre visibile e/o non segue
                                l’ordine logico
                                di navigazione. Tramite tastiera è possibile navigare le voci principali del menu, ma
                                non il
                                menu
                                espanso.</li>
                            <li><strong>Salto di blocchi</strong>: nel sito non sono presenti i link interni che
                                favoriscono
                                la navigazione da
                                tastiera.</li>
                            <li><strong>Headings</strong>: In diverse pagine del sito non sono stati utilizzati tutti i
                                livelli di intestazione in modo
                                coerente, e in alcuni casi non sono stati utilizzati per nulla.</li>
                            <li><strong>Struttura della pagina</strong>: In diverse pagine del sito, la struttura
                                semantica
                                non rispetta gli
                                standard richiesti di accessibilità.</li>
                            <li><strong>Etichette e istruzioni</strong>: alcuni elementi dell’interfaccia sono privi di
                                etichette. Pertanto, alcune
                                interazioni specifiche potrebbero risultare difficili per gli utenti che si servono di
                                tecnologie
                                assistive.</li>
                            <li><strong>Titolo di pagina</strong>: In diverse pagine del sito non è presente il titolo
                                di
                                pagina, secondo gli
                                standard di accessibilità.</li>
                            <li><strong>Documenti</strong>: Tutti i documenti PDF scaricabili dal sito sono parzialmente
                                leggibili per chi si
                                serve di screen reader, perché privi di struttura gerarchica (sommario, intestazioni,
                                link
                                interni)
                                e alternative testuali.</li>
                            <li><strong>Forms</strong>: In diverse pagine del sito, alcuni campi di testo sono privi di
                                descrizione, e in alcuni
                                casi non fanno parte di un gruppo logico.</li>
                        </ul>
                        <br>
                        <strong>REDAZIONE DELLA DICHIARAZIONE DI ACCESSIBILITÀ</strong>
                        <div class="divisor"></div>
                        <p>
                            La dichiarazione è stata redatta il <b>
                                {{'<\03 /11/2022>'}}
                            </b><br><br>
                            La dichiarazione è stata effettuata utilizzando una valutazione conforme alle prescrizioni
                            della
                            direttiva
                            (UE) 2016/2012 mediante autovalutazione effettuata direttamente dal soggetto erogatore.
                        </p>
                        <br><br>
                        <strong>FEEDBACK SULL’ACCESSIBILITÀ E RECAPITI</strong>
                        <div class="divisor"></div>
                        <p>
                            È possibile segnalare eventuali casi di difficoltà ad accedere ai contenuti del sito.
                            La segnalazione può essere fatta tramite posta elettronica all’indirizzo
                            e-mail: <span class="bold underline cursor email"><a
                                    [href]="'mailto:SegnalazioniAccessibilita@welion.com'">SegnalazioniAccessibilita@welion.com</a></span><br><br>
                            Nell'e-mail è necessario indicare:
                        </p>
                        <ul>
                            <li>Nome e cognome;</li>
                            <li>Indirizzo della pagina web o sezioni del sito o area clienti oggetto della segnalazione;
                            </li>
                            <li>Descrizione chiara e sintetica del problema riscontrato;</li>
                            <li>Strumenti utilizzati (sistema operativo, browser, tecnologie assistive).</li>
                        </ul>
                        <p class="blue cursor underline"><a target="_blank" href="https://www.generali.it/">
                                Scarica l'informativa sul trattamento dei dati personali ai sensi degli articoli 13 e 14
                                del
                                Regolamento UE n. 679/2016 del 27 aprile 2016</a>
                        </p>
                        <br>
                        <strong>RESTIAMO SEMPRE IN ASCOLTO</strong>
                        <br><br>
                        <p>
                            Per eventuali segnalazioni è possibile scriverci a <span
                                class="bold underline cursor email"><a
                                    [href]="'mailto:SegnalazioniAccessibilita@welion.com'">SegnalazioniAccessibilita@welion.com</a></span>
                        </p>
                        <br><br>
                        <strong>MODALITÀ DI INVIO DELLE SEGNALAZIONI ALL’AGENZIA PER L’ITALIA DIGITALE (AGID)</strong>
                        <br><br>
                        <p>
                            In caso di risposta insoddisfacente o di mancata risposta, nel termine di trenta giorni,
                            alla
                            notifica o alla
                            richiesta, l’interessato può inoltrare una segnalazione a protocollo@pec.agid.gov.it
                        </p>
                        <br>
                        <strong>INFORMAZIONI SUL SITO</strong>
                        <div class="divisor"></div>

                        <ol>
                            <li>Data dell’ultima pubblicazione del sito: <b>{{'<\05 /11/2022>'}}

                                </b></li>
                            <li>Data dell’ultima pubblicazione del sito: <b>
                                    {{'<\si>'}}
                                </b></li>
                            <li>Data dell’ultima pubblicazione del sito: <b>
                                    {{'<\Angular_Node.JS>'}}
                                </b></li>
                        </ol>

                        <strong>INFORMAZIONI SULLA STRUTTURA</strong>
                        <div class="divisor"></div>
                        <ol>
                            <li>Numero di dipendenti con disabilità presenti nella società: <b>4</b></li>
                            <li>Numero di postazioni di lavoro per dipendenti con disabilità: <b>0</b></li>
                        </ol>
                        <p>Il dato si riferisce al totale di dipendenti assunti per legge come categorie
                            protette e
                            come
                            disabili
                            dal gruppo aggiornato al 15/08/2022.</p>
                    </div>

                </div>
            </div>
        </div>
    </ng-container>

    <!-- Nessuna lingua disponibile a sistema -->
    <div *ngIf="!langs || !langs.length">
        <p translate="errors.NO_AVAILABLE_LANG"></p>
    </div>
</main>
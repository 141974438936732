<div *ngIf="_campaign" fxLayout="column" class="card-campaign">

  <div class="card-campaign-header cursor-pointer" [ngClass]="{'active': _isActive }" fxLayout="row"
    fxLayoutAlign="center center" (click)="goToCampaign()">

    <!-- Campagna Antinfluenzale 2021  -->
    <h4 class="white"
      [innerHTML]="'component.cardCampaign.CAMPAIGN' | translate: { value: _campaign.title | boldText } | uppercase">
    </h4>

    <svg-icon fxFlexOffset="18px" [applyClass]="true" class="medium-size white-svg"
      src="assets/img/icons/arrow-right.svg">
    </svg-icon>

  </div>

  <div class="card-campaign-container" fxLayout="column">

    <!-- Campagna non attiva -->
    <ng-container *ngIf="!_isActive">

      <!-- Slot offerti e saturazione disponibilità -->
      <ng-container [ngTemplateOutlet]="doubleData" [ngTemplateOutletContext]="{
        offset: 0,
        label: 'SLOTS_OFFERED',
        value: _campaign.slotsOffered,
        secondLabel: 'SATURATION_AVAILABILITY',
        secondValue: _campaign.saturationAvailability
      }"></ng-container>

      <!-- Slot disponibili -->
      <ng-container [ngTemplateOutlet]="singleData" [ngTemplateOutletContext]="{
        label: 'SLOTS_AVAILABLE',
        value: (_campaign.slotsOffered - _campaign.bookingsNumb)
      }"></ng-container>

      <!-- Prenotazioni -->
      <ng-container [ngTemplateOutlet]="singleData" [ngTemplateOutletContext]="{
        label: 'BOOKINGS',
        value: _campaign.bookingsNumb
      }"></ng-container>

      <!-- Presenti -->
      <ng-container [ngTemplateOutlet]="singleData" [ngTemplateOutletContext]="{
        label: 'ATTENDANT',
        value: _campaign.attendantNumb
      }"></ng-container>

      <!-- 2/6 Round -->
      <ng-container [ngTemplateOutlet]="singleData" [ngTemplateOutletContext]="{
        label: 'ROUND',
        value: (_campaign.currentRound || 0) + '/' + (_campaign.totalRounds || 0)
      }"></ng-container>

      <h6 fxFlexOffset="4px" class="black" [innerHTML]="'component.cardCampaign.NOT_ACTIVE' | translate">
      </h6>
    </ng-container>

    <!-- Campagna attiva -->
    <ng-container *ngIf="_isActive">

      <!-- Slot offerti e saturazione disponibilità -->
      <ng-container [ngTemplateOutlet]="doubleData" [ngTemplateOutletContext]="{
        offset: 0,
        label: 'SLOTS_OFFERED',
        value: _campaign.slotsOffered,
        secondLabel: 'SATURATION_AVAILABILITY',
        secondValue: _campaign.saturationAvailability
      }"></ng-container>

      <!-- Slot disponibili -->
      <ng-container [ngTemplateOutlet]="singleData" [ngTemplateOutletContext]="{
        label: 'SLOTS_AVAILABLE',
        value: (_campaign.slotsOffered - _campaign.bookingsNumb)
      }"></ng-container>

      <!-- Prenotazioni -->
      <ng-container [ngTemplateOutlet]="singleData" [ngTemplateOutletContext]="{
        label: 'BOOKINGS',
        value: _campaign.bookingsNumb
      }"></ng-container>

      <!-- Presenti -->
      <ng-container [ngTemplateOutlet]="singleData" [ngTemplateOutletContext]="{
        label: 'ATTENDANT',
        value: _campaign.attendantNumb
      }"></ng-container>

      <!-- 2/6 Round -->
      <ng-container [ngTemplateOutlet]="singleData" [ngTemplateOutletContext]="{
        label: 'ROUND',
        value: (_campaign.currentRound || 0) + '/' + (_campaign.totalRounds || 0)
      }"></ng-container>

      <!-- Attivo: inizio - fine -->
      <h6 fxFlexOffset="4px" class="black" [innerHTML]="'component.cardCampaign.ACTIVE' | translate:
        { value: ((_startDate | date: 'dd MMMM yyyy' | uppercase) + ' - '
        + (_endDate | date: 'dd MMMM yyyy' | uppercase)) | boldText }">
      </h6>

    </ng-container>

  </div>

</div>

<ng-template #singleData let-label='label' let-value='value' let-offset='offset'>
  <div fxFlexOffset="{{ offset !== undefined ? offset : 16 }}px" fxLayout="row" fxLayoutAlign="start center">
    <h4 class="bold">{{ value || 0 }}</h4>
    <h6 fxFlexOffset="4px" class="black" translate="component.cardCampaign.{{label}}"></h6>
  </div>
</ng-template>

<ng-template #doubleData let-label='label' let-value='value' let-secondLabel='secondLabel' let-secondValue='secondValue'
  let-offset='offset'>

  <div fxFlexOffset="{{ offset !== undefined ? offset : 16 }}px" fxLayout="row" fxLayoutAlign="space-between center">
    <ng-container [ngTemplateOutlet]="singleData" [ngTemplateOutletContext]="{
      label: label, value: value, offset: 0 }">
    </ng-container>

    <div fxLayout="column" fxLayoutAlign="end">
      <h6 class="extra-small right" translate="component.cardCampaign.{{secondLabel}}"></h6>
      <h4 fxFlexOffset="1px" class="primary right">{{ secondValue || 0 }}%</h4>
    </div>
  </div>
</ng-template>

<main aria-label="Contenuto principale della pagina" tabindex="-1">
  <div class="page-content-wrapper" fxLayout="column">

    <div *ngIf="userFAQ" fxFlexOffset="40px" fxLayout="row" fxLayoutAlign="space-between center">
      <bread-crumbs firstLevel="FAQ"></bread-crumbs>

      <welion-button fxHide fxShow.xs hasLeftIcon width="160px" label="generic.BACK" type="primary" size="medium"
        (onClick)="goBack()">
      </welion-button>
    </div>

    <div *ngIf="!userFAQ" fxFlexOffset.gt-xs="40px" fxLayout="row" fxLayoutAlign="center">
      <svg-icon src="assets/img/icons/app-logo.svg" class="welion-icon cursor-pointer" [applyClass]="true">
      </svg-icon>
    </div>

    <div fxFlexOffset="40px" fxFlexOffset.xs="12px" class="user-faq-container" fxLayout="column">

      <div fxLayout="row" fxLayoutAlign="space-between center">
        <h3 translate="userFAQ.TITLE" ngClass.xs="center" [ngClass.gt-xs]="{'center': !userFAQ}"
          [fxFlex]="userFAQ ? '20' : ''" fxFlex.xs>
        </h3>
        <!-- Indietro -->
        <welion-button *ngIf="userFAQ" fxShow fxHide.xs hasLeftIcon width="160px" label="generic.BACK" type="primary"
          size="medium" (onClick)="goBack()">
        </welion-button>
      </div>

      <div fxLayout="column">

        <!-- Sezione 1: Accesso al portale -->
        <h4 class="bold center" fxFlexOffset="20px" translate="userFAQ.TITLE_1"></h4>

        <!-- 1.1 Come accedo al portale dal PC? -->
        <welion-accordion noInfo [title]="'userFAQ.QUESTION1_1' | translate" fxFlexOffset="20px">
          <div class="answer-container">
            <span translate="userFAQ.RESPONSE1_1A"></span>
            <p></p>
            <p class="margin-bottom16"></p>
            <span translate="userFAQ.RESPONSE1_1B"></span>
          </div>
        </welion-accordion>
        <!-- 1.2 Posso accedere al portale anche da tablet o smartphone? -->
        <welion-accordion noInfo [title]="'userFAQ.QUESTION1_2' | translate" fxFlexOffset="20px">
          <p class="p-container">
            <span translate="userFAQ.RESPONSE1_2"></span>
          </p>
        </welion-accordion>

        <!-- 1.3 Come posso registrarmi e accedere al portale? -->
        <welion-accordion noInfo [title]="'userFAQ.QUESTION1_3' | translate" fxFlexOffset="20px">
          <div class="answer-container">
            <span *ngIf="!isGenerali" translate="userFAQ.RESPONSE1_3A"></span>
            <span *ngIf="isGenerali" [innerHTML]="'userFAQ.RESPONSE1_3A_GEN' | translate"></span>
            <p></p>
            <p class="margin-bottom16"></p>
            <span *ngIf="!isGenerali" translate="userFAQ.RESPONSE1_3B"></span>
            <span *ngIf="isGenerali" [innerHTML]="'userFAQ.RESPONSE1_3B_GEN' | translate"></span>
            <p class="margin-bottom16" *ngIf="!isGenerali"></p>
            <p class="margin-bottom16" *ngIf="!isGenerali"></p>
            <span *ngIf="!isGenerali" translate="userFAQ.RESPONSE1_3C"></span>
            <p class="margin-bottom16" *ngIf="!isGenerali"></p>
            <p class="margin-bottom16" *ngIf="!isGenerali"></p>
            <span *ngIf="!isGenerali" translate="userFAQ.RESPONSE1_3E"></span>
            <p class="margin-bottom16" *ngIf="!isGenerali"></p>
            <p class="margin-bottom16" *ngIf="!isGenerali"></p>
            <span *ngIf="!isGenerali" translate="userFAQ.RESPONSE1_3D"></span>
          </div>
        </welion-accordion>

        <!-- 1.4 Ho concluso la fase di registrazione, ma non ho ricevuto la mail di conferma, come posso fare? -->
        <welion-accordion *ngIf="!isGenerali" noInfo [title]="'userFAQ.QUESTION1_4' | translate" fxFlexOffset="20px">
          <div class="answer-container">
            <span translate="userFAQ.RESPONSE1_4A"></span>
            <p></p>
            <p class="margin-bottom16"></p>
            <span translate="userFAQ.RESPONSE1_4B"></span>
            <p></p>
            <p class="margin-bottom16"></p>
            <span translate="userFAQ.RESPONSE1_4C"></span>
          </div>
        </welion-accordion>

        <!-- 1.5 Non riesco ad accedere al portale: cosa devo fare? -->
        <welion-accordion noInfo [title]="'userFAQ.QUESTION1_5' | translate" fxFlexOffset="20px">
          <div class="answer-container">
            <span translate="userFAQ.RESPONSE1_5A"></span>
            <p></p>
            <p class="margin-bottom16"></p>
            <span translate="userFAQ.RESPONSE1_5B"></span>
            <p></p>
            <p class="margin-bottom16"></p>
            <span translate="userFAQ.RESPONSE1_5C"></span>
            <p></p>
            <p class="margin-bottom16"></p>
            <span translate="userFAQ.RESPONSE1_5D"></span>

            <span *ngIf="!isGenerali" translate="userFAQ.RESPONSE1_5E"></span>
            <span *ngIf="isGenerali" translate="userFAQ.RESPONSE1_5E_GEN"></span>
          </div>
        </welion-accordion>

        <!-- 1.6 Quali username e password devo impostare per registrarmi? -->
        <welion-accordion noInfo [title]="'userFAQ.QUESTION1_6' | translate" fxFlexOffset="20px">
          <div class="answer-container">
            <span *ngIf="!isGenerali" translate="userFAQ.RESPONSE1_6A"></span>
            <span *ngIf="isGenerali" translate="userFAQ.RESPONSE1_6A_GEN"></span>
            <p class="margin-bottom16" *ngIf="!isGenerali"></p>
            <p class="margin-bottom16" *ngIf="!isGenerali"></p>
            <span *ngIf="!isGenerali" translate="userFAQ.RESPONSE1_6B"></span>
            <p class="margin-bottom16" *ngIf="!isGenerali"></p>
            <p class="margin-bottom16" *ngIf="!isGenerali"></p>
            <span *ngIf="!isGenerali" [innerHTML]="'userFAQ.RESPONSE1_6C' | translate"></span>
          </div>
        </welion-accordion>

        <!-- 1.7 Ho dimenticato la password, è possibile reimpostarla? -->
        <welion-accordion *ngIf="!isGenerali" noInfo [title]="'userFAQ.QUESTION1_7' | translate" fxFlexOffset="20px">
          <div class="answer-container">
            <span [innerHTML]="'userFAQ.RESPONSE1_7A_NOT_GEN' | translate"></span>
            <p></p>
            <p class="margin-bottom16"></p>
            <span translate="userFAQ.RESPONSE1_7B"></span>
            <p></p>
            <p class="margin-bottom16"></p>
            <span [innerHTML]="'userFAQ.RESPONSE1_7C' | translate"></span>
            <p></p>
            <p class="margin-bottom16"></p>
            <span translate="userFAQ.RESPONSE1_7D"></span>
          </div>
        </welion-accordion>

        <!-- 1.8 Ho dimenticato la password, è possibile reimpostarla? -->
        <welion-accordion *ngIf="!isGenerali" noInfo [title]="'userFAQ.QUESTION1_8' | translate" fxFlexOffset="20px">
          <div class="answer-container">
            <span [innerHTML]="'userFAQ.RESPONSE1_8A' | translate"></span>
            <p></p>
            <p class="margin-bottom16"></p>
            <span translate="userFAQ.RESPONSE1_8B"></span>
            <p></p>
            <p class="margin-bottom16"></p>
            <span [innerHTML]="'userFAQ.RESPONSE1_8C' | translate"></span>
          </div>
        </welion-accordion>

        <!-- Sezione 2: Prenotazione -->
        <h4 class="bold center" fxFlexOffset="20px" translate="userFAQ.TITLE_2">
        </h4>

        <!-- 2.1 Come posso prenotare la vaccinazione antinfluenzale? -->
        <welion-accordion noInfo [title]="'userFAQ.QUESTION2_1' | translate" fxFlexOffset="20px">
          <div class="answer-container tableBelow">
            <span translate="userFAQ.RESPONSE2_1A"></span>
            <p></p>
            <p class="margin-bottom16"></p>
            <span translate="userFAQ.RESPONSE2_1B"></span>
          </div>
          <ul>
            <li translate="userFAQ.RESPONSE2_1C"></li>
            <li translate="userFAQ.RESPONSE2_1D"></li>
            <li translate="userFAQ.RESPONSE2_1E"></li>
          </ul>
        </welion-accordion>

        <!-- 2.2 Come posso impostare la ricerca per prenotare la vaccinazione? -->
        <welion-accordion noInfo [title]="'userFAQ.QUESTION2_2' | translate" fxFlexOffset="20px">
          <p class="p-container tableBelow">
            <span translate="userFAQ.RESPONSE2_2A"></span>
          </p>
          <ul>
            <li translate="userFAQ.RESPONSE2_2B"></li>
            <li translate="userFAQ.RESPONSE2_2C"></li>
            <li translate="userFAQ.RESPONSE2_2D"></li>
          </ul>
          <p class="p-container">
            <span translate="userFAQ.RESPONSE2_2E"></span>
          </p>
        </welion-accordion>

        <!-- 2.3 Come posso scegliere la struttura / sede in cui effettuare la vaccinazione? -->
        <welion-accordion noInfo [title]="'userFAQ.QUESTION2_3' | translate" fxFlexOffset="20px">
          <div class="answer-container">
            <span translate="userFAQ.RESPONSE2_3A"></span>
            <p></p>
            <p class="margin-bottom16"></p>
            <span translate="userFAQ.RESPONSE2_3B"></span>
            <p></p>
            <p class="margin-bottom16"></p>
            <span translate="userFAQ.RESPONSE2_3C"></span>
          </div>
        </welion-accordion>

        <!-- 2.4 Come posso scegliere la data e l’orario in cui effettuare la vaccinazione? -->
        <welion-accordion noInfo [title]="'userFAQ.QUESTION2_4' | translate" fxFlexOffset="20px">
          <div class="answer-container">
            <span translate="userFAQ.RESPONSE2_4A"></span>
            <p></p>
            <p class="margin-bottom16"></p>
            <span translate="userFAQ.RESPONSE2_4B"></span>
            <p></p>
            <p class="margin-bottom16"></p>
            <span translate="userFAQ.RESPONSE2_4C"></span>
          </div>
        </welion-accordion>

        <!-- 2.5 Non sono riuscito/a a trovare una struttura / sede o una data disponibile per la vaccinazione. Cosa posso fare? -->
        <welion-accordion noInfo [title]="'userFAQ.QUESTION2_5' | translate" fxFlexOffset="20px">
          <p class="p-container tableBelow">
            <span translate="userFAQ.RESPONSE2_5A"></span>
          </p>
          <ul>
            <li translate="userFAQ.RESPONSE2_5B"></li>
            <li translate="userFAQ.RESPONSE2_5C"></li>
            <li translate="userFAQ.RESPONSE2_5D"></li>
          </ul>
          <p class="p-container">
            <span translate="userFAQ.RESPONSE2_5E"></span>
          </p>
        </welion-accordion>

        <!-- 2.6 Ho concluso la prenotazione ma non posso più presentarmi all’appuntamento. Posso effettuare una nuova prenotazione? -->
        <welion-accordion noInfo [title]="'userFAQ.QUESTION2_6' | translate" fxFlexOffset="20px">
          <p class="p-container tableBelow">
            <span translate="userFAQ.RESPONSE2_6A"></span>
          </p>
          <ul>
            <li [innerHTML]="'userFAQ.RESPONSE2_6B' | translate"></li>
            <li [innerHTML]="'userFAQ.RESPONSE2_6C' | translate"></li>
            <li [innerHTML]="'userFAQ.RESPONSE2_6D' | translate"></li>
          </ul>
        </welion-accordion>

        <!-- 2.7 Ho effettuato la prenotazione, ma non ho ricevuto la mail di conferma, cosa devo fare? -->
        <welion-accordion noInfo [title]="'userFAQ.QUESTION2_7' | translate" fxFlexOffset="20px">
          <div class="answer-container">
            <span translate="userFAQ.RESPONSE2_7A"></span>
            <p></p>
            <p class="margin-bottom16"></p>
            <span translate="userFAQ.RESPONSE2_7B"></span>
            <p></p>
            <p class="margin-bottom16"></p>
            <span translate="userFAQ.RESPONSE2_7C"></span>
          </div>
        </welion-accordion>

        <!-- 2.8 Ho effettuato la prenotazione, ma non ho ricevuto la mail di conferma, cosa devo fare? -->
        <welion-accordion *ngIf="isGenerali" noInfo [title]="'userFAQ.QUESTION2_8' | translate" fxFlexOffset="20px">
          <p class="p-container">
            <span translate="userFAQ.RESPONSE2_8A"></span>
          </p>
        </welion-accordion>

        <!-- 2.9 Ho un contratto di stage o di somministrazione, posso beneficiare del servizio di vaccinazione antinfluenzale? -->
        <welion-accordion *ngIf="isGenerali" noInfo [title]="'userFAQ.QUESTION2_9' | translate" fxFlexOffset="20px">
          <p class="p-container">
            <span translate="userFAQ.RESPONSE2_9A"></span>
          </p>
        </welion-accordion>

        <!-- Sezione 3: Vaccinazione -->
        <h4 class="bold center" fxFlexOffset="20px" translate="userFAQ.TITLE_3">
        </h4>

        <!-- 3.1 Come posso sapere se la vaccinazione sia consigliata per il mio stato di salute? -->
        <welion-accordion *ngIf="!isGenerali" noInfo [title]="'userFAQ.QUESTION3_1' | translate" fxFlexOffset="20px">
          <p class="p-container">
            <span translate="userFAQ.RESPONSE3_1"></span>
          </p>
        </welion-accordion>

        <!-- 3.1 Quale vaccino antinfluenzale mi verrà somministrato il giorno della vaccinazione? -->
        <welion-accordion *ngIf="isGenerali" noInfo [title]="'userFAQ.QUESTION3_1_GEN' | translate" fxFlexOffset="20px">
          <p class="p-container">
            <span [innerHTML]="'userFAQ.RESPONSE3_1_GEN' | translate"></span>
            <a target="_blank" href="/assets/documents/RCP_Influvac_S_Tetra_2024-2025.pdf">link</a>
            <span [innerHTML]="'userFAQ.RESPONSE3_1_BIS_GEN' | translate"></span>
            <!--span translate="userFAQ.RESPONSE3_2_GEN"></span-->
          </p>
        </welion-accordion>

        <!-- 3.2 Come posso sapere se la vaccinazione sia consigliata per il mio stato di salute? -->
        <welion-accordion *ngIf="isGenerali" noInfo [title]="'userFAQ.QUESTION3_1' | translate" fxFlexOffset="20px">
          <p class="p-container">
            <span translate="userFAQ.RESPONSE3_1"></span>
          </p>
        </welion-accordion>

        <!-- 3.2 Cosa devo portare con me il giorno della vaccinazione? -->
        <welion-accordion *ngIf="!isGenerali" noInfo [title]="'userFAQ.QUESTION3_2' | translate" fxFlexOffset="20px">
          <p class="p-container tableBelow">
            <span translate="userFAQ.RESPONSE3_2A"></span>
          </p>
          <ul>
            <li translate="userFAQ.RESPONSE3_2B"></li>
            <li translate="userFAQ.RESPONSE3_2C"></li>
            <li translate="userFAQ.RESPONSE3_2C_BIS"></li>
          </ul>
          <!--p>
            "RESPONSE3_2D": "Il giorno della vaccinazione ti sarà richiesto di firmare un modulo privacy da consegnare
            al personale sanitario. Qualora previsto, potresti ricevere tale modulo alla tua casella e-mail prima
            dell’appuntamento, in modo da portarlo con te il giorno della vaccinazione.",
            "RESPONSE3_2E": "A seguito dell'emergenza epidemiologica dovuta agli effetti del covid 19, ti chiediamo di
            presentarti da solo/a all’appuntamento, 10 minuti prima dell’orario previsto e munito/a di idonea
            mascherina.",
            <span translate="userFAQ.RESPONSE3_2D"></span>
            <br>
            <br>
            <span translate="userFAQ.RESPONSE3_2E"></span>
          </p-->
        </welion-accordion>

        <!-- 3.3 Cosa devo portare con me il giorno della vaccinazione? -->
        <welion-accordion *ngIf="isGenerali" noInfo [title]="'userFAQ.QUESTION3_2' | translate" fxFlexOffset="20px">
          <p class="p-container tableBelow">
            <span translate="userFAQ.RESPONSE3_2A"></span>
          </p>
          <ul>
            <li translate="userFAQ.RESPONSE3_2B"></li>
            <li translate="userFAQ.RESPONSE3_2B_BIS"></li>
            <li translate="userFAQ.RESPONSE3_2C_BIS"></li>
          </ul>
          <!-- p>
            <span translate="userFAQ.RESPONSE3_2D"></span>
            <br>
            <br>
            <span translate="userFAQ.RESPONSE3_2E"></span>
          </p-->
        </welion-accordion>

        <!-- 3.3 Come si svolgerà la giornata di vaccinazione? -->
        <welion-accordion *ngIf="!isGenerali" noInfo [title]="'userFAQ.QUESTION3_3' | translate" fxFlexOffset="20px">
          <div class="answer-container">
            <span translate="userFAQ.RESPONSE3_3B"></span>
            <p></p>
            <p class="margin-bottom16"></p>
            <span translate="userFAQ.RESPONSE3_3C"></span>
            <p></p>
            <p class="margin-bottom16"></p>
            <span translate="userFAQ.RESPONSE3_3D"></span>
            <p></p>
            <p class="margin-bottom16"></p>
            <span translate="userFAQ.RESPONSE3_3E"></span>
          </div>
        </welion-accordion>

        <!-- 3.4 Come si svolgerà la giornata di vaccinazione? -->
        <welion-accordion *ngIf="isGenerali" noInfo [title]="'userFAQ.QUESTION3_3' | translate" fxFlexOffset="20px">
          <div class="answer-container">
            <span translate="userFAQ.RESPONSE3_3B"></span>
            <p></p>
            <p class="margin-bottom16"></p>
            <span translate="userFAQ.RESPONSE3_3C"></span>
            <p></p>
            <p class="margin-bottom16"></p>
            <span translate="userFAQ.RESPONSE3_3D"></span>
            <p></p>
            <p class="margin-bottom16"></p>
            <span translate="userFAQ.RESPONSE3_3E"></span>
          </div>
        </welion-accordion>

        <!-- 3.4 Mi sarà consegnato un certificato di avvenuta vaccinazione? -->
        <welion-accordion *ngIf="!isGenerali" noInfo [title]="'userFAQ.QUESTION3_4' | translate" fxFlexOffset="20px">
          <p class="p-container">
            <span translate="userFAQ.RESPONSE3_4"></span>
          </p>
        </welion-accordion>

        <!-- 3.5 Come posso giustificare la mia assenza durante l’orario lavorativo per recarmi all’appuntamento vaccinale? -->
        <welion-accordion *ngIf="isGenerali" noInfo [title]="'userFAQ.QUESTION3_5_GEN' | translate" fxFlexOffset="20px">
          <p class="p-container tableBelow">
            <span translate="userFAQ.RESPONSE3_5A_GEN"></span>
          </p>
          <ul>
            <li translate="userFAQ.RESPONSE3_5B_GEN"></li>
            <li translate="userFAQ.RESPONSE3_5C_GEN"></li>
            <li translate="userFAQ.RESPONSE3_5D_GEN"></li>
            <li translate="userFAQ.RESPONSE3_5E_GEN"></li>
          </ul>
        </welion-accordion>

        <!-- 3.5 Il certificato di vaccinazione antinfluenzale verrà caricata sul mio fascicolo sanitario personale? -->
        <welion-accordion *ngIf="!isGenerali" noInfo [title]="'userFAQ.QUESTION3_5' | translate" fxFlexOffset="20px">
          <div class="answer-container">
            <span translate="userFAQ.RESPONSE3_5A"></span>
            <p></p>
            <p class="margin-bottom16"></p>
            <span translate="userFAQ.RESPONSE3_5B"></span>
          </div>
        </welion-accordion>

        <!-- 3.6 Mi sarà consegnato un certificato di avvenuta vaccinazione? -->
        <welion-accordion *ngIf="isGenerali" noInfo [title]="'userFAQ.QUESTION3_6_GEN' | translate" fxFlexOffset="20px">
          <p class="p-container">
            <span translate="userFAQ.RESPONSE3_6_GEN"></span>
          </p>
        </welion-accordion>

        <!-- 3.6 Non ho potuto recarmi all’appuntamento programmato. Posso effettuare una nuova prenotazione collegandomi al portale? -->
        <welion-accordion *ngIf="!isGenerali" noInfo [title]="'userFAQ.QUESTION3_6' | translate" fxFlexOffset="20px">
          <div class="answer-container">
            <span translate="userFAQ.RESPONSE3_6A"></span>
            <p></p>
            <p class="margin-bottom16"></p>
            <span translate="userFAQ.RESPONSE3_6B"></span>
            <p></p>
            <p class="margin-bottom16"></p>
            <span translate="userFAQ.RESPONSE3_6C"></span>
          </div>
        </welion-accordion>

        <!-- 3.7 Il certificato di vaccinazione antinfluenzale verrà caricata sul mio fascicolo sanitario personale? -->
        <welion-accordion *ngIf="isGenerali" noInfo [title]="'userFAQ.QUESTION3_7_GEN' | translate" fxFlexOffset="20px">
          <div class="answer-container">
            <span translate="userFAQ.RESPONSE3_7A_GEN"></span>
            <p></p>
            <p class="margin-bottom16"></p>
            <span translate="userFAQ.RESPONSE3_7B_GEN"></span>
          </div>
        </welion-accordion>

        <!-- 3.8 Non ho potuto recarmi all’appuntamento programmato. Posso effettuare una nuova prenotazione collegandomi al portale? -->
        <welion-accordion *ngIf="isGenerali" noInfo [title]="'userFAQ.QUESTION3_8_GEN' | translate" fxFlexOffset="20px">
          <div class="answer-container">
            <span translate="userFAQ.RESPONSE3_8A_GEN"></span>
            <p></p>
            <p class="margin-bottom16"></p>
            <span translate="userFAQ.RESPONSE3_8B_GEN"></span>
            <p></p>
            <p class="margin-bottom16"></p>
            <span translate="userFAQ.RESPONSE3_8C_GEN"></span>
          </div>
        </welion-accordion>

        <div *ngIf="canSeeTicketBox" fxFlexOffset="48px" fxLayoutAlign="center center" class="box-ticket-container">
          <p class="p-container">
            <span translate="faq.BOX_TICKET_DESC_1"></span>
            <span class="ticket-link height-24" (click)="goToTicketPage()" tabindex="0" clickKeyBoard
              (onEnterKeyboard)="goToTicketPage()" role="button">{{'faq.BOX_TICKET_DESC_2' | translate}}</span>
            <span>.</span>
          </p>
        </div>
      </div>

    </div>
  </div>

  <app-footer *ngIf="!userFAQ"></app-footer>

  <ng-template #mailTo let-underline='underline'>
    <a class="bold" [ngClass]="{'underline': underline}" (click)="clickMailTo($event)"
      href="mailto:campagnesalute.welion@winflow.it?subject=OGGETTO: «ERRORE FASE DI REGISTRAZIONE»/«ERRORE ACCESSO PIATTAFORMA»/«PROBLEMA PRENOTAZIONE»/«PROBLEMA VACCINAZIONE»&body=NOME, COGNOME, CODICE FISCALE, AZIENDA, NUMERO DI TELEFONO, DESCRIZIONE DEL PROBLEMA">campagnesalute.welion@winflow.it</a>
  </ng-template>
</main>
import { CommonConstants } from "../../../commonclasses";

export interface ApplicationUrl {
  url: string,
  baseUrl: string,
  protocol: string,
  domain: string,
}

/*
*    Servizio che costruisce e tornag li URL delle varie applicazioni
*/
export class UrlService {
  constructor() {
    this.checkHostName();
  }

  checkHostName() {
    // Recupero il nome del dominio per caricare l'iFrame di cross-domain local storage
    let firstDot = 0;
    let lastDot = window.location.hostname.lastIndexOf(".");
    if (lastDot != -1) {
      firstDot = window.location.hostname.lastIndexOf(".", lastDot - 1);
    }
    if (firstDot == -1) {
      firstDot = 0;
    }
  }

  // Ritorna l'URL dell'applicazione
  getApplicationUrl(): ApplicationUrl {
    let applicationUrl: ApplicationUrl = {
      url: '',
      baseUrl: '',
      protocol: '',
      domain: ''
    }

    // Recupero il nome del dominio per caricare l'iFrame di cross-domain local storage
    let firstDot = 0;
    let lastDot = window.location.hostname.lastIndexOf(".");
    if (lastDot != -1) {
      firstDot = window.location.hostname.lastIndexOf(".", lastDot - 1);
    }
    if (firstDot == -1) {
      firstDot = 0;
    }
    let secondLevelDomain = window.location.hostname.substring(firstDot, window.location.hostname.length);
    if (secondLevelDomain.charAt(0) == ".") {
      secondLevelDomain = secondLevelDomain.substring(1, window.location.hostname.length);
    }

    const isLocalhost = window.location.hostname.indexOf("localhost") == 0 || window.location.hostname.indexOf("192.168.1.16") == 0 || window.location.hostname.indexOf("192.168.1.50") == 0;

    if (isLocalhost) {
      applicationUrl.domain = "atfwcs.alloy.it";
    } else {
      let url = window.location.hostname.substring(0, firstDot);
      if (url) {
        url = url + ".";
      }
      applicationUrl.domain = url + secondLevelDomain;
    }

    // Calcolo il dominio di appartenenza per chiamare i servizi Rest
    applicationUrl.protocol = window.location.protocol === "http:" ? "http" : "https";

    // Forzo https nel caso di localhost per evitare problemi di CORS
    if (isLocalhost) {
      applicationUrl.protocol = "https";
    }
    applicationUrl.baseUrl = applicationUrl.protocol + "://" + applicationUrl.domain + "/";
    applicationUrl.url = applicationUrl.protocol + "://" + applicationUrl.domain;
    applicationUrl.domain = applicationUrl.domain;

    return applicationUrl;
  }

  getServiceMediatorUrl(): string {
    return this.getApplicationUrl().baseUrl + 'rest-api/campaignmanager-mediator/';
  }

  /**
   * Ritorna l'url SSO Accenture dell'applicazione
   * @param deviceType
   * @param userAgent
   * @param urlExtension
   * @returns
   */
  getSSOAccentureUrl(deviceType: string, userAgent: string, urlExtension?: string) {
    let domain = "";
    let ssoUrl: string = '';
    let ssoReturnPage: string = '';

    // Calcolo il dominio di appartenenza
    const protocol = window.location.protocol === "http:" ? "http" : "https";

    // Recupero il nome del dominio per caricare l'iFrame di cross-domain local storage
    let firstDot = 0;
    const lastDot = window.location.hostname.lastIndexOf(".");
    if (lastDot != -1) {
      firstDot = window.location.hostname.lastIndexOf(".", lastDot - 1);
    }
    if (firstDot == -1) {
      firstDot = 0;
    }
    // let secondLevelDomain = window.location.hostname.substring(firstDot, window.location.hostname.length);
    // if (secondLevelDomain.charAt(0) == ".") {
    //   secondLevelDomain = secondLevelDomain.substring(1, window.location.hostname.length);
    // }
    // Per debug verifico se il primo pezzo del dominio è "localhost o atf", allora tengo questo invece del "www"
    if (window.location.hostname.indexOf("localhost") == 0) {
      domain = "localhosts.alloy.it";
    }
    else if ((window.location.hostname.indexOf("atf") == 0 && firstDot > 0)) {
      domain = "atfs.alloy.it";
    }
    else if (window.location.hostname.indexOf("test") == 0 && firstDot > 0) {
      // Staging
      domain = "testaccenturesso.welion.training";
    }
    else {
      //Produzione
      // domain = "sso." + secondLevelDomain;
      domain = "accenturesso.welion.training";
    }
    ssoReturnPage = this.getApplicationUrl().url;
    if (urlExtension && urlExtension.length) {
      ssoReturnPage = ssoReturnPage + urlExtension;
    };
    ssoUrl = protocol + "://" + domain + "/login?originApplicationName=" + encodeURIComponent(CommonConstants.APPLICATION_CAMPAIGN_MANAGER) + "&applicationUrl=" + encodeURIComponent(ssoReturnPage) + "&deviceType=" + encodeURIComponent(deviceType) + "&userAgent=" + encodeURIComponent(userAgent);
    return ssoUrl;
  }

  /**
   * Ritorna l'url SSO Enel dell'applicazione
   * @param deviceType
   * @param userAgent
   * @param urlExtension
   * @returns
   */
  getSSOEnelUrl(deviceType: string, userAgent: string, urlExtension?: string) {
    let domain = "";
    let ssoUrl: string = '';
    let ssoReturnPage: string = '';

    // Calcolo il dominio di appartenenza
    const protocol = window.location.protocol === "http:" ? "http" : "https";

    // Recupero il nome del dominio per caricare l'iFrame di cross-domain local storage
    let firstDot = 0;
    const lastDot = window.location.hostname.lastIndexOf(".");
    if (lastDot != -1) {
      firstDot = window.location.hostname.lastIndexOf(".", lastDot - 1);
    }
    if (firstDot == -1) {
      firstDot = 0;
    }
    // let secondLevelDomain = window.location.hostname.substring(firstDot, window.location.hostname.length);
    // if (secondLevelDomain.charAt(0) == ".") {
    //   secondLevelDomain = secondLevelDomain.substring(1, window.location.hostname.length);
    // }
    // Per debug verifico se il primo pezzo del dominio è "localhost o atf", allora tengo questo invece del "www"
    if (window.location.hostname.indexOf("localhost") == 0) {
      domain = "localhosts.alloy.it";
    }
    else if ((window.location.hostname.indexOf("atf") == 0 && firstDot > 0)) {
      domain = "atfs.alloy.it";
    }
    else if (window.location.hostname.indexOf("test") == 0 && firstDot > 0) {
      // Staging
      domain = "testenelwcssso.welion.training";
    }
    else {
      //Produzione
      // domain = "sso." + secondLevelDomain;
      domain = "enelwcssso.welion.training";
    }
    ssoReturnPage = this.getApplicationUrl().url;
    if (urlExtension && urlExtension.length) {
      ssoReturnPage = ssoReturnPage + urlExtension;
    };
    ssoUrl = protocol + "://" + domain + "/login?originApplicationName=" + encodeURIComponent(CommonConstants.APPLICATION_CAMPAIGN_MANAGER) + "&applicationUrl=" + encodeURIComponent(ssoReturnPage) + "&deviceType=" + encodeURIComponent(deviceType) + "&userAgent=" + encodeURIComponent(userAgent);
    return ssoUrl;
  }

  // Ritorna l'URL dell'applicazione
  getSSOUrl(deviceType: string, userAgent: string, urlExtension?: string) {
    let domain = '';
    let ssoAdditionalParam = '';
    let ssoUrl: string = '';
    let ssoReturnPage: string = '';
    // Calcolo il dominio di appartenenza
    const protocol = window.location.protocol === 'http:' ? 'http' : 'https';

    // Recupero il nome del dominio per caricare l'iFrame di cross-domain local storage
    let firstDot = 0;
    const lastDot = window.location.hostname.lastIndexOf('.');
    if (lastDot !== -1) {
      firstDot = window.location.hostname.lastIndexOf('.', lastDot - 1);
    }
    if (firstDot === -1) {
      firstDot = 0;
    }
    let secondLevelDomain = window.location.hostname.substring(
      firstDot,
      window.location.hostname.length
    );
    if (secondLevelDomain.charAt(0) === '.') {
      secondLevelDomain = secondLevelDomain.substring(
        1,
        window.location.hostname.length
      );
    }
    // Per debug verifico se il primo pezzo del dominio è "localhost o atf", allora tengo questo invece del "www"
    if (window.location.hostname.indexOf('localhost') === 0) {
      domain = 'localhosts.alloy.it';
    } else if (window.location.hostname.indexOf('atf') === 0 && firstDot > 0) {
      domain = 'testgenwcssso.welion.training';
      // domain = window.location.hostname;
    } else if (window.location.hostname.indexOf('test') === 0 && firstDot > 0) {
      domain = 'testgenwcssso.welion.training';
    } else {
      // SSO configurato nelle macchine Generali
      // ssoAdditionalParam = 'jwt=no&';
      // domain = 'sso.generalilearningplatform.com';
      // SSO configurato nelle macchine Welion
      domain = 'generaliwcssso.welion.training';
    }
    ssoReturnPage = this.getApplicationUrl().url;
    if (urlExtension && urlExtension.length) {
      ssoReturnPage = ssoReturnPage + urlExtension;
    }

    ssoUrl =
      protocol +
      '://' +
      domain +
      '/login?' +
      ssoAdditionalParam +
      'originApplicationName=' +
      encodeURIComponent(CommonConstants.APPLICATION_CAMPAIGN_MANAGER) +
      '&applicationUrl=' +
      encodeURIComponent(ssoReturnPage) +
      '&deviceType=' +
      encodeURIComponent(deviceType) +
      '&userAgent=' +
      encodeURIComponent(userAgent);
    return ssoUrl;
  }

  /**
 * Ritorna l'url SSO Leroy Merlin dell'applicazione
 * @param deviceType
 * @param userAgent
 * @param urlExtension
 * @returns
 */
  getSSOLeroyMerlinUrl(deviceType: string, userAgent: string, urlExtension?: string) {
    let domain = "";
    let ssoUrl: string = '';
    let ssoReturnPage: string = '';

    // Calcolo il dominio di appartenenza
    const protocol = window.location.protocol === "http:" ? "http" : "https";

    // Recupero il nome del dominio per caricare l'iFrame di cross-domain local storage
    let firstDot = 0;
    const lastDot = window.location.hostname.lastIndexOf(".");
    if (lastDot != -1) {
      firstDot = window.location.hostname.lastIndexOf(".", lastDot - 1);
    }
    if (firstDot == -1) {
      firstDot = 0;
    }
    // let secondLevelDomain = window.location.hostname.substring(firstDot, window.location.hostname.length);
    // if (secondLevelDomain.charAt(0) == ".") {
    //   secondLevelDomain = secondLevelDomain.substring(1, window.location.hostname.length);
    // }
    // Per debug verifico se il primo pezzo del dominio è "localhost o atf", allora tengo questo invece del "www"
    if (window.location.hostname.indexOf("localhost") == 0) {
      domain = "localhosts.alloy.it";
    }
    else if ((window.location.hostname.indexOf("atf") == 0 && firstDot > 0)) {
      domain = "atfs.alloy.it";
    }
    else if (window.location.hostname.indexOf("test") == 0 && firstDot > 0) {
      // Staging
      domain = "testleroymerlinwcssso.welion.training";
    }
    else {
      //Produzione
      // domain = "sso." + secondLevelDomain;
      domain = "leroymerlinwcssso.welion.training";
    }
    ssoReturnPage = this.getApplicationUrl().url;
    if (urlExtension && urlExtension.length) {
      ssoReturnPage = ssoReturnPage + urlExtension;
    };
    ssoUrl = protocol + "://" + domain + "/login?originApplicationName=" + encodeURIComponent(CommonConstants.APPLICATION_CAMPAIGN_MANAGER) + "&applicationUrl=" + encodeURIComponent(ssoReturnPage) + "&deviceType=" + encodeURIComponent(deviceType) + "&userAgent=" + encodeURIComponent(userAgent);
    return ssoUrl;
  }

  // Proxa l'url di un'immagine
  getExternalResourceName = (requestUrl: string, requestName: string) => {
    return this.getApplicationUrl().baseUrl + 'rest-api/coursemanager-mediator/proxy-url/' + encodeURIComponent(requestName) + '?requestUrl=' + encodeURIComponent(requestUrl);
  }

  // Torna la pagina di default per il redirect (ad esempio dopo il login) in base all'auth dell'utente
  getDefaultRedirectPage = (isStructure?: boolean, isUser?: boolean, isAdmin?: boolean, isHelpDesk?: boolean, isClient?: boolean, isLimitedAdmin?: boolean) => {
    if (isStructure) {
      return "/structure/home";
    } else if (isAdmin || isLimitedAdmin) {
      return "/admin/home";
    } else if (isHelpDesk) {
      return "/helpDesk/home";
    } else if (isClient) {
      return "/client/home";
    } else if (isUser) {
      return "/user/home";
    } else {
      return "/signin";
    }
  }
}

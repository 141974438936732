<div class="card-round" fxLayout="column" fxLayoutAlign="space-between" fxLayoutAlign.xs="start">

  <ng-container *ngIf="round">

    <div fxLayout="row" fxLayoutAlign="space-between center">

      <div fxLayout="row">
        <div *ngIf="_adminMood && _isActive" class="card-round-status" fxLayout="row" fxLayoutAlign="center center">
          <h6 class="small white" translate="component.cardRound.ACTIVE"></h6>
        </div>

        <!-- Inizio - Fine -->
        <h4 [fxFlexOffset]="_adminMood && _isActive ? '8px' : '0px'" [innerHTML]="((round.startDate | date: 'dd MMMM yyyy' | uppercase)
        + ' - ' + (round.endDate | date: 'dd MMMM yyyy' | uppercase)) | boldText">
        </h4>
      </div>

      <div *ngIf="_adminMood" fxLayout="row">
        <welion-button fxFlexOffset="8px" class="adminMood" type="primary bordless" size="medium"
          (onClick)="goToAdminRoundDetails()" label="component.cardRound.DETAILS">
        </welion-button>
      </div>

    </div>

    <div *ngIf="!_adminMood" fxLayout="row" fxLayout.xs="column">

      <div fxLayout="column">
        <div fxLayout="row" fxLayout.xs="column">

          <!-- Slot caricati -->
          <ng-container [ngTemplateOutlet]="cardRound" [ngTemplateOutletContext]="{
            offset: 0,
            label: 'LOADED_SLOTS',
            value: (round.offeredSlots || 0)  + (round.approvationRequestCount || 0)
          }"></ng-container>

          <!-- Slot prenotati e saturazione slot -->
          <ng-container [ngTemplateOutlet]="cardRound" [ngTemplateOutletContext]="{
            label: 'BOOKED_SLOTS',
            value: round.bookings,
            secondLabel: 'SATURATION_SLOTS',
            secondValue: round.saturation
          }"></ng-container>

          <!-- Slot disponibili e % slot disponibili -->
          <ng-container [ngTemplateOutlet]="cardRound" [ngTemplateOutletContext]="{
            label: 'AVAILABLE_SLOTS',
            value: round.slotsAvailable,
            secondLabel: 'AVAILABLE_SLOTS_PERC',
            secondValue: round.slotsAvailablePercentage
          }"></ng-container>

          <!-- Teste reportizzate e % completamento reporting -->
          <ng-container [ngTemplateOutlet]="cardRound" [ngTemplateOutletContext]="{
            label: 'REPORTED_HEADS',
            value: round.reportedHeads,
            secondLabel: 'COMPLETION_REPORTING',
            secondValue: round.reportingCompletionPercentage
          }"></ng-container>

          <!-- Teste vaccinate e % teste vaccinate / teste reportizzate -->
          <ng-container [ngTemplateOutlet]="cardRound" [ngTemplateOutletContext]="{
            label: 'VACCINATED_HEADS',
            value: round.presents,
            secondLabel: 'VACCINATED_HEADS_PERC',
            secondValue: round.vaccinatedReportedPercentage
          }"></ng-container>

        </div>

        <div fxFlexOffset="12px" fxFlexOffset.xs="0px" fxLayout="row" fxLayout.xs="column">

          <!-- Teste non vaccinate e % no-vaccinati / teste reportizzate -->
          <ng-container [ngTemplateOutlet]="cardRound" [ngTemplateOutletContext]="{
            offset: 0,
            label: 'NOT_VACCINATED_HEADS',
            value: round.absent,
            secondLabel: 'NOT_VACCINATED_HEADS_PERC',
            secondValue: round.notVaccinatedReportedPercentage
          }"></ng-container>

        </div>
      </div>

      <div fxFlexOffset="16px" fxFlexOffset.xs="0px" fxLayout="column">
        <welion-button fxFlexOffset.xs="8px" type="primary bordless black" size="medium" (onClick)="goToSlots()"
          label="component.cardRound.SLOT">
        </welion-button>

        <welion-button fxFlexOffset="8px" fxFlexOffset.xs="8px" type="primary bordless black" size="medium"
          (onClick)="goToAttendances()" label="component.cardRound.ATTENDANCES">
        </welion-button>
      </div>

    </div>

    <div *ngIf="_adminMood" fxLayout="row" fxLayout.xs="column" fxFlexOffset="12px">
      <!-- Slot caricati -->
      <ng-container [ngTemplateOutlet]="cardRound" [ngTemplateOutletContext]="{
        offset: 0,
        label: 'LOADED_SLOTS',
        value: round.offeredSlots
      }"></ng-container>

      <!-- Slot prenotati e saturazione slot -->
      <ng-container [ngTemplateOutlet]="cardRound" [ngTemplateOutletContext]="{
        label: 'BOOKED_SLOTS',
        value: round.bookings,
        secondLabel: 'SATURATION_SLOTS',
        secondValue: round.saturation
      }"></ng-container>

      <!-- Slot disponibili e % slot disponibili -->
      <ng-container [ngTemplateOutlet]="cardRound" [ngTemplateOutletContext]="{
        label: 'AVAILABLE_SLOTS',
        value: round.slotsAvailable,
        secondLabel: 'AVAILABLE_SLOTS_PERC',
        secondValue: round.slotsAvailablePercentage
      }"></ng-container>

      <!-- Assenti -->
      <ng-container [ngTemplateOutlet]="cardRound" [ngTemplateOutletContext]="{
        label: 'ABSENT',
        value: round.absent,
        secondLabel: 'INCIDENCE',
        secondValue: round.incidence
      }"></ng-container>

      <!-- slot da approvare  -->
      <ng-container [ngTemplateOutlet]="cardRound" [ngTemplateOutletContext]="{
        label: 'SLOT_TO_APPROVE',
        value: round.approvationRequestCount || 0,
        highlight: (round && round.approvationRequestCount ? round.approvationRequestCount > 0 : false)
      }"></ng-container>

      <!--  -->
      <ng-container [ngTemplateOutlet]="cardRound" [ngTemplateOutletContext]="{
        label: 'SLOT_IN_REVOKE',
        value: round.revokeRequestCount || 0,
        highlight: (round && round.revokeRequestCount ? round.revokeRequestCount > 0 : false)
      }"></ng-container>

    </div>

  </ng-container>
</div>

<ng-template #cardRound let-label='label' let-value='value' let-secondLabel='secondLabel' let-secondValue='secondValue'
  let-offset='offset' let-highlight='highlight'>
  <div fxFlexOffset="{{offset !== undefined ? offset : 8 }}px" fxFlexOffset.xs="8px" class="card-round-box"
    [ngClass]="{'highlight': highlight}" fxLayout="column" fxLayout.xs="row" fxLayoutAlign="space-between">
    <div fxLayout="column">
      <h4 class="bold">{{ value }}</h4>
      <!-- Assenti -->
      <h6 class="black" translate="component.cardRound.{{label}}"></h6>
    </div>
    <!-- Incidenza -->
    <div *ngIf="secondLabel" fxLayout="column" fxLayoutAlign.xs="start end">
      <h6 fxFlexOffset="8px" class="extra-small" translate="component.cardRound.{{secondLabel}}"></h6>
      <h6 fxFlexOffset="4px">{{ secondValue }}%</h6>
    </div>
  </div>
</ng-template>

<ng-template #doubleCardRound let-label='label' let-value='value' let-secondLabel='secondLabel'
  let-secondValue='secondValue' let-offset='offset' let-highlight='highlight'>
  <div fxFlexOffset="{{offset !== undefined ? offset : 8 }}px" fxFlexOffset.xs="8px" class="card-round-box"
    [ngClass]="{'highlight': highlight}" fxLayout="column" fxLayout.xs="row" fxLayoutAlign="space-between">
    <div fxLayout="column">
      <h4 class="bold">{{ value }}</h4>
      <!-- Slot offerti -->
      <h6 class="black" translate="component.cardRound.{{label}}"></h6>

      <h4 fxFlexOffset="8px" class="bold">{{ secondValue }}</h4>
      <!-- Slot disponibili -->
      <h6 class="black" translate="component.cardRound.{{secondLabel}}"></h6>
    </div>
  </div>
</ng-template>
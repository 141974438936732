import * as CoreActions from "../../core/ngrx/core.actions";
import * as fromApp from "../../ngrx/app.reducers";

import { DatePipe, TitleCasePipe } from "@angular/common";
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { ActivatedRoute } from '@angular/router';
import { Store } from "@ngrx/store";
import { TranslateService } from '@ngx-translate/core';
import { Observable, Subject, of } from 'rxjs';
import { catchError, map, switchMap, takeUntil } from 'rxjs/operators';

import { AttendanceStatuses, CampaignManagerMinorVaccinationType, DayAttendances, InfoAttendance, InfoRound, InfoSlot, InfoVenue, RecallStatuses } from "atfcore-commonclasses/bin/classes/campaignmanager";
import { SenecaResponse } from "atfcore-commonclasses/bin/classes/common";

import { AuthService } from "src/app/auth/services/auth.service";
import { ScrollTo } from "src/app/core/services/scroll-to.service";
import { WelionInputComponent } from "src/app/shared/components/welion-input/welion-input.component";
import { DropdownItem } from "src/app/shared/models/dropdown.model";
import { RedirectService } from 'src/app/shared/services/redirect.service';
import { SidebarService } from "src/app/shared/services/sidebar.service";
import { AttendanceDayMenu } from "src/app/utils/classes.utils";
import { getWarningModal } from "src/app/utils/utils";
import { ExportService } from "../services/export.service";
import { RentService } from "../services/rent.service";
import moment = require("moment");

@Component({
  selector: 'app-round-attendances',
  templateUrl: './roundAttendances.component.html',
  styleUrls: ['./roundAttendances.component.scss']
})
export class RoundAttendancesComponent implements OnInit, OnDestroy {

  roundId: string = '';

  round?: InfoRound;
  private _isEndSlotsLoading: boolean = false;
  isLoadingAttendances = true;

  selectedAttendanceForm?: InfoAttendance;
  selectedAttendanceAge?: number;
  underNineYearsOld: boolean = false;
  underEighteenBirthday?: Date;
  recognizedFC: boolean = false;
  fromNewAttendance: boolean = false;

  statusDropDown: DropdownItem[] = [
    { id: AttendanceStatuses.RESERVED, name: 'PRENOTATO' },
    { id: AttendanceStatuses.YES, name: 'SI' },
    { id: AttendanceStatuses.NO, name: 'NO' }
  ];

  // Per minori con opzione di consegna
  statusDeliveredDropDown: DropdownItem[] = [
    { id: AttendanceStatuses.RESERVED, name: 'PRENOTATO' },
    { id: AttendanceStatuses.DELIVERED, name: 'CONSEGNATO' },
    { id: AttendanceStatuses.NO, name: 'NO' }
  ];

  recallStatusDropDown: DropdownItem[] = [
    { id: '', name: '' },
    { id: RecallStatuses.YES, name: 'SI' },
    { id: RecallStatuses.NO, name: 'NO' }
  ];

  // Per minori con opzione di consegna
  recallStatusDeliveredDropDown: DropdownItem[] = [
    { id: '', name: '' },
    { id: RecallStatuses.DELIVERED, name: 'CONSEGNATO' },
    { id: RecallStatuses.NO, name: 'NO' }
  ];

  selectedRecallStatusDropDown?: DropdownItem;

  availableSlotsForm: InfoSlot[] = [];
  selectedSlotDropDown?: DropdownItem;

  selectedVaccinationDate: Date = new Date();
  minVaccinationDate?: Date;
  maxVaccinationDate?: Date;

  selectedStatusDropDown?: DropdownItem;
  // Per minori con sedi che hanno opzione di consegna consegnato per la fascia di età
  hasDeliveryStatus: boolean = false;

  selectedRecallDate?: Date;

  //#region Structures

  private _structuresLoaded: number = 0;
  private _structureSearchedText: string = '';
  private _resetStructureResultList: boolean = false;

  disableStructureSelect: boolean = false;

  /**
   * When the list ended
   */
  private _isEndStructureList: boolean = false;

  isLoadingInfo: boolean = true;
  isLoadingFiscalCodeSearch: boolean = false;
  isLoadingStructures: boolean = true;
  structureList: DropdownItem[] = [];
  structureServive$: Subject<string> = new Subject<string>();
  selectedStructure?: DropdownItem;
  venueId: string = '';

  // Dropdown for mobile
  dayDropdown: DropdownItem[] = [];
  selectedDayDropdown?: DropdownItem;

  indexSelectedDaySlots: number = 0;

  //#endregion

  private _firstLoad: boolean = true;

  // Data that incorporate slot and attendances of a day
  attendanceDays: DayAttendances[] = [];
  slotsDropDown?: DropdownItem[];

  attendances: InfoAttendance[] = [];

  attendeeForm: FormGroup = new FormGroup({
    'fiscalCode': new FormControl(undefined),
    'validFiscalCode': new FormControl(false, Validators.requiredTrue),
    'description': new FormControl(undefined),
    'vaccinationDate': new FormControl(undefined),
    'vaccinationTimeFrom': new FormControl(undefined, [Validators.pattern('([0-1]?[0-9]|2[0-3]):[0-5][0-9]$')]),
    'vaccinationTimeTo': new FormControl(undefined, [Validators.pattern('([0-1]?[0-9]|2[0-3]):[0-5][0-9]$')]),
    'requiredRecall': new FormControl(false),
    'recallDate': new FormControl(undefined),
    'fullNameSearch': new FormControl(undefined)
  });
  isLoadingfullNameSearch: boolean = false;
  firstFullNameSearch: boolean = false;
  selectedUserSearch: any;
  userService$: Subject<any> = new Subject<any>();
  usersList: any;
  resetUserList: boolean = false;
  usersLoaded: number = 0;
  isLoadingUsers: boolean = false;
  userFromRecord: number = 0;
  userNumRecords: number = 10;
  isUserEndList: boolean = false;

  get fiscalCode(): FormControl {
    return this.attendeeForm.get('fiscalCode') as FormControl;
  }

  firstFiscalCodeSearch: boolean = true;

  get validFiscalCode(): FormControl {
    return this.attendeeForm.get('validFiscalCode') as FormControl;
  }

  get description(): FormControl {
    return this.attendeeForm.get('description') as FormControl;
  }

  get vaccinationDate(): FormControl {
    return this.attendeeForm.get('vaccinationDate') as FormControl;
  }

  get vaccinationTimeFrom(): FormControl {
    return this.attendeeForm.get('vaccinationTimeFrom') as FormControl;
  }

  get fullNameSearch(): FormControl {
    return this.attendeeForm.get('fullNameSearch') as FormControl;
  }

  @ViewChild('vaccinationTimeFromInput') vaccinationTimeFromInput?: WelionInputComponent;

  get vaccinationTimeTo(): FormControl {
    return this.attendeeForm.get('vaccinationTimeTo') as FormControl;
  }

  @ViewChild('vaccinationTimeToInput') vaccinationTimeToInput?: WelionInputComponent;

  get requiredRecall(): FormControl {
    return this.attendeeForm.get('requiredRecall') as FormControl;
  }

  get recallDate(): FormControl {
    return this.attendeeForm.get('recallDate') as FormControl;
  }

  hasAlreadyHadFluVaccine: boolean = false;

  private _unsubscribeSignal$: Subject<void> = new Subject();

  constructor(
    private route: ActivatedRoute,
    private appStore: Store<fromApp.AppState>,
    private translate: TranslateService,
    private rentService: RentService,
    private redirectService: RedirectService,
    private sidebarService: SidebarService,
    private scrollTo: ScrollTo,
    private datePipe: DatePipe,
    private authService: AuthService,
    private exportService: ExportService,
    private titleCasePipe: TitleCasePipe
  ) { }

  //#region Inizializzazione round

  ngOnInit() {
    this.scrollTo.header();
    const roundId = this.route.snapshot.paramMap.get('roundId');
    this.venueId = this.route.snapshot.paramMap.get('officeId') || '';

    if (!roundId) {
      this.scrollTo.header();
      this.redirectService.goToHome();
    } else {
      this.roundId = roundId;
      this.getRound();
    }
  }

  ngOnDestroy() {
    this._unsubscribeSignal$.next();
    this._unsubscribeSignal$.unsubscribe();
  }

  /**
   * Recupera i dati del round sulla base dell'id
   * @param roundId
   */
  getRound() {
    this.rentService.getRound(this.roundId)
      .pipe(takeUntil(this._unsubscribeSignal$.asObservable()))
      .subscribe(
        (result: SenecaResponse<InfoRound>) => {
          if (result && result.error) {
            this.dispatchWarningModal('010', result.error);
          } else {
            this.round = result.response;
            if (this.round.startDate) {
              this.minVaccinationDate = new Date(this.round.startDate);
              this.minVaccinationDate.setHours(0, 0, 0, 0);
            }
            if (this.round.endDate) {
              this.maxVaccinationDate = new Date(this.round.endDate);
              this.maxVaccinationDate.setHours(23, 59, 59, 0);
            }

            this.initStructures();
            this.initUsers();
            // Avvio una prima ricerca
            this.structureServive$.next('');
            this.userService$.next('');
          }
        }, (err) => {
          if (err && err.message) {
            this.dispatchWarningModal('011', err.message);
          }
        });
  }

  //#endregion

  //#region Structures

  /**
   * @description Carica le strutture e imposta per l'infinite scroll
   */
  initStructures() {
    this.structureList = this.structureList || [];
    this.structureServive$.pipe(
      switchMap(
        (text: string) => {
          if (text !== this._structureSearchedText) {
            this._resetStructureResultList = true;
            this._structuresLoaded = 0;
          } else {
            this._resetStructureResultList = false;
          }
          this._structureSearchedText = text || '';
          return this.getVenueList(this._structuresLoaded, 10, this._structureSearchedText);
        }
      )
      , catchError((err, caught) => {
        return caught;
      })
    )
      .subscribe({
        next: (eventData: Array<DropdownItem>) => {
          if (this._resetStructureResultList) {
            this.clearStructureResults();
          }
          if (eventData && eventData.length) {
            this._structuresLoaded += eventData.length;
            this.structureList = this.structureList.concat(eventData);
            if (this._structuresLoaded === 1 && !this._structureSearchedText) {
              this.disableStructureSelect = true;
            }
            if (this._structuresLoaded > 0 && this._firstLoad) {
              // Select della sede
              if (this.venueId) {
                let venueFinded: boolean = false;
                for (let i = 0; i < this.structureList.length; i++) {
                  if (this.structureList[i].id === this.venueId) {
                    this.onStructureChange(this.structureList[i]);
                    venueFinded = true;
                    break;
                  }
                }

                if (!venueFinded) {
                  this.onStructureChange(this.structureList[0]);
                }
              } else {
                this.onStructureChange(this.structureList[0]);
              }
            }
          }
        },
        error: (err: any) => {
          if (err && err.message) {
            this.dispatchWarningModal('009', err.message);
          }
          this.isLoadingInfo = false;
          this.isLoadingStructures = false;
        }
      });
  }

  /**
   * @description Carica e prepara gli elementi per la select con infinite scroll
   * @param fromRecord
   * @param numRecords
   * @param searchedText
   */
  getVenueList(fromRecord: number, numRecords: number, searchedText: string): Observable<DropdownItem[]> {

    this.isLoadingStructures = true;

    return this.rentService.getVenueList(this.roundId, fromRecord, numRecords, searchedText)
      .pipe(
        takeUntil(this._unsubscribeSignal$.asObservable())
        , map((resultData: SenecaResponse<InfoVenue[]>) => {
          if (resultData.error) {
            this.dispatchWarningModal('007', resultData.error);
            this.isLoadingInfo = false;
            this.isLoadingStructures = false;
          } else if (resultData.response && resultData.response.length) {
            let formattedData: DropdownItem[] = [];

            resultData.response.forEach(structure => {
              let title = structure.name + ' - ' + structure.address;

              formattedData.push({
                id: structure.venueId,
                name: title,
                content: structure
              });
            });

            // Caricando n elementi per volta (itemsToLoad) se
            // ne carica di meno significa che sono a fine lista
            if (resultData.response.length < numRecords) {
              this._isEndStructureList = true;
            }

            this.isLoadingStructures = false;

            return formattedData;
          }
          this.isLoadingStructures = false;
          this._isEndStructureList = true;
          return [];
        })
      );
  }

  /**
   * @description Alla selezione di una struttura
   * @param structure
   */
  onStructureChange(structure?: DropdownItem) {
    if (!this.selectedStructure || !structure || this.selectedStructure.id !== structure.id) {
      this.isLoadingInfo = true;
      this.selectedStructure = structure;

      if (this.selectedStructure) {
        this.venueId = (this.selectedStructure.content as InfoVenue).venueId;
      }

      this._firstLoad = true;
      this.getDatesMenu();
    }
  }

  /**
   * @description Chiamata quando viene effettuata una nuova ricerca differente
   */
  clearStructureResults(): void {
    this._structuresLoaded = 0;
    this.structureList = [];
    this._resetStructureResultList = false;
  }

  /**
   * @description Chiamata allo scroll
   * @param scrollEvent
   */
  fetchMoreStructures(scrollEvent: { start: number; end: number }) {
    if (!this._isEndStructureList && scrollEvent.end === this._structuresLoaded) {
      this.structureServive$.next(this._structureSearchedText);
    }
  }

  //#endregion

  //#region Slots

  /**
   * @description Recupera gli slots di una struttura suddivisi per giorni con il numero
   * di prenotazioni da elaborare
   */
  getDatesMenu() {

    if (this.selectedStructure) {

      this.attendanceDays = [];
      this.dayDropdown = [];
      this.selectedDayDropdown = undefined;

      if (this._firstLoad) {
        this.indexSelectedDaySlots = 0;
        this.attendances = [];
      }

      this.rentService.getDatesWithToBeProcessedNumber(this.roundId, this.selectedStructure.id)
        .pipe(takeUntil(this._unsubscribeSignal$.asObservable()))
        .subscribe(
          (result: SenecaResponse<AttendanceDayMenu[]>) => {
            if (result && result.error) {
              this.dispatchWarningModal('025', result.error);
              this.isLoadingInfo = false;
            } else {
              let tmpResult = result.response;
              tmpResult.sort((a: AttendanceDayMenu, b: AttendanceDayMenu) => {
                return new Date(a.date).getTime() - new Date(b.date).getTime();
              });

              // Per la select mobile
              const structureSlots = tmpResult;
              if (structureSlots && structureSlots.length) {
                structureSlots.forEach((daySlot, index) => {

                  this.attendanceDays.push({
                    roundId: this.roundId,
                    date: new Date(daySlot.date),
                    toBeProcessedNumber: daySlot.data.toBeProcessedCount,
                    attendances: []
                  });

                  this.dayDropdown.push({
                    id: index.toString(),
                    name: this.getDateForSelect(daySlot.date) + ' - ' + daySlot.data.toBeProcessedCount + ' da processare'
                  });

                });

                this.onSelectDay(this._firstLoad ? 0 : this.indexSelectedDaySlots);
              } else {
                this.isLoadingInfo = false;
              }
            }
          }, (err) => {
            if (err && err.message) {
              this.dispatchWarningModal('009', err.message);
            }
            this.isLoadingInfo = false;
          });
    }
  }

  onSelectDaySlotMobile(item: DropdownItem) {
    this.onSelectDay(Number.parseInt(item.id));
  }

  /**
   * @description Chiamata alla selezione di una giornata
   * @param indexDaySlot
   */
  onSelectDay(indexDaySlot?: number) {

    if (indexDaySlot !== undefined) {
      this.indexSelectedDaySlots = indexDaySlot;
    }

    this.attendances = [];
    this.selectedDayDropdown = this.dayDropdown[this.indexSelectedDaySlots];
    this.isLoadingInfo = false;
    this._isEndSlotsLoading = false;
    this.getDaySlots(true);
    if (!this._firstLoad) {
      this.scrollTo.elementWithDelay('round-attendance-anchor');
    } else {
      this._firstLoad = false;
    }
  }

  //#endregion

  //#region Raggruppamento giorni e slot

  getDaySlots(firstCall?: boolean) {

    if (this.selectedStructure && (!this.isLoadingAttendances || firstCall) && !this._isEndSlotsLoading) {

      this.isLoadingAttendances = true;

      this.rentService.getAttendanceList(
        this.roundId,
        this.selectedStructure.id,
        this.attendanceDays[this.indexSelectedDaySlots].date,
        this.attendances.length,
        96)
        .pipe(takeUntil(this._unsubscribeSignal$.asObservable()))
        .subscribe(
          (result: SenecaResponse<DayAttendances[]>) => {
            const response = result.response;
            if (result && result.error) {
              this.dispatchWarningModal('026', result.error);
              this._isEndSlotsLoading = true;
            } else {

              const attendances = response.length ? response[0].attendances : [];
              if (attendances && attendances.length) {
                this.attendances = this.attendances.concat(attendances);

                if (attendances.length < 96) {
                  this._isEndSlotsLoading = true;
                }

              } else {
                this._isEndSlotsLoading = true;
              }
            }

            this.isLoadingAttendances = false;
          }, (err) => {
            if (err && err.message) {
              this.dispatchWarningModal('027', err.message);
            }
            this._isEndSlotsLoading = true;
            this.isLoadingAttendances = false;
          });

    }
  }

  /**
   * @param date Data da trasformare
   * @returns Stringa con formato Lunedì 1 Marzo
   */
  getDateForSelect(date: Date | string): string {
    return this.titleCasePipe.transform(this.datePipe.transform(date, 'EEEE dd MMMM') || '');
  }

  getTimeForSelect(date: Date): string {
    return this.datePipe.transform(date, 'HH:mm') || '';
  }

  //#endregion

  //#region Gestione persone

  managePeople() {

  }

  bookedPeople() {
    this.isLoadingInfo = true;

    this.exportService.exportBookedUsers(this.roundId, this.venueId)
    .pipe(
      switchMap((senecaResponse) => {
        if (senecaResponse && senecaResponse.error) {
          this.dispatchWarningModal('030', senecaResponse.error);
          this.isLoadingInfo = false;

          return of(null);
        }
        return this.exportService.getDownloadTempFileUrl(senecaResponse.response, false);
      }),
      takeUntil(this._unsubscribeSignal$.asObservable())
    )
    .subscribe(downloadUrl => {
      if (downloadUrl) {
        const timeout = setTimeout(() => {
          clearTimeout(timeout);
          document.location.assign(downloadUrl);
          this.isLoadingInfo = false;
        }, 500);
      }
    }, (err) => {
      this.isLoadingInfo = false;
      if (err && err.message) {
        this.dispatchWarningModal('031', err.message);
      }
    });
  }

  // Recupera token dalla chiave dell'url
  getTokenFromSsortkqp(callback: (value: string) => void) {
    this.authService.crateRetrieveTokenAfterLogin()
      .pipe(takeUntil(this._unsubscribeSignal$.asObservable()))
      .subscribe(result => {
        if (result && result.error) {
          this.dispatchWarningModal('028', result.error);
          this.isLoadingInfo = false;
        } else {
          callback(result.response);
        }
      }, (err) => {
        if (err && err.message) {
          this.dispatchWarningModal('029', err.message);
          this.isLoadingInfo = false;
        }
      });
  }

  resetFullNameSearch() {
    this.fullNameSearch.setValue("");
    this.fullNameSearch.reset();
    this.fullNameSearch.updateValueAndValidity()
    this.fullNameSearch.clearValidators();
    this.usersList = [];
    this.selectedUserSearch = null; 1
  }

  openAttendanceCard(attendance?: InfoAttendance) {
    this.selectedAttendanceForm = attendance;
    this.fromNewAttendance = !attendance;


    if (this.fromNewAttendance) {
      this.fiscalCode.setValidators([Validators.minLength(16), Validators.maxLength(16)]);
    } else {
      this.fiscalCode.clearValidators();
    }
    this.fiscalCode.updateValueAndValidity();
    this.resetFullNameSearch();

    this.slotsDropDown = [];

    this.firstFiscalCodeSearch = true;
    this.firstFullNameSearch = true;

    this.validFiscalCode.setValue(!this.fromNewAttendance);

    if (attendance && this.attendanceDays) {

      this.setFiscalCodeUnderage(attendance);

      this.fiscalCode.setValue(attendance.fiscalCode);
      this.description.setValue(attendance.description);

      this.selectedSlotDropDown = {
        id: attendance.slotId,
        name: this.getTimeForSelect(attendance.startDate) + ' - ' + this.getTimeForSelect(attendance.endDate)
      };

      if (attendance.vaccinationSlotStartDate && attendance.vaccinationSlotEndDate) {
        this.vaccinationTimeFrom.setValue(this.getTimeForSelect(attendance.vaccinationSlotStartDate));
        this.vaccinationTimeTo.setValue(this.getTimeForSelect(attendance.vaccinationSlotEndDate));
        this.onVaccinationDateChange(attendance.vaccinationSlotStartDate);
      } else {
        this.onVaccinationDateChange(this.attendanceDays[this.indexSelectedDaySlots].date);
      }

      switch (attendance.status) {
        case AttendanceStatuses.YES:
          this.selectedStatusDropDown = this.statusDropDown[1];
          break;
        case AttendanceStatuses.DELIVERED:
          this.selectedStatusDropDown = this.statusDeliveredDropDown[1];
          break;
        case AttendanceStatuses.NO:
          this.selectedStatusDropDown = this.statusDropDown[2];
          break;
        case AttendanceStatuses.RESERVED:
        default:
          this.selectedStatusDropDown = this.statusDropDown[0];
          break;
      }

      this.hasAlreadyHadFluVaccine = false;
      this.requiredRecall.setValue(attendance.isRecallMandatory);

      this.onRecallDateChange(attendance.recallDate);

      if (attendance.anamnesis && attendance.anamnesis.length > 0) {
        attendance.anamnesis.forEach(anamnesi => {
          if (anamnesi.questionKey === 'HAS_ALREADY_HAD_FLU_VACCINE') {
            this.hasAlreadyHadFluVaccine = anamnesi.answer;
          }
        });
      } else
        if (attendance.isRecallMandatory !== undefined || attendance.isRecallMandatory !== null) {

          attendance.anamnesis.forEach(anamnesi => {
            if (anamnesi.questionKey === 'HAS_ALREADY_HAD_FLU_VACCINE') {
              this.hasAlreadyHadFluVaccine = anamnesi.answer;
            }
          });
        }

      switch (attendance.recallStatus) {
        case RecallStatuses.YES:
          this.selectedRecallStatusDropDown = this.recallStatusDropDown[1];
          break;
        case RecallStatuses.DELIVERED:
          this.selectedRecallStatusDropDown = this.recallStatusDeliveredDropDown[1];
          break;
        case RecallStatuses.NO:
          this.selectedRecallStatusDropDown = this.recallStatusDropDown[2];
          break;
        default:
          this.selectedRecallStatusDropDown = this.recallStatusDropDown[0];
          break;
      }

      this.setVaccinationValidators();

      this.sidebarService.setShowNav(true);

    } else {
      this.getSlotsNewAttendee();
    }

  }

  /* checkAnamnesisExists(selectedAttendance: any) {
    if(selectedAttendance && selectedAttendance.anamnesis && selectedAttendance.anamnesis.length) {
      return true;
    } else {
      return false;
    }

  } */

  /**
   * Recupera gli anni di una persona dalla data di compleanno
   * @param birthDate
   * @returns
   */
  getBirthdayAge(birthDate: Date) {
    const today = new Date();
    var age = today.getFullYear() - birthDate.getFullYear();
    var month = today.getMonth() - birthDate.getMonth();
    if (month < 0 || (month === 0 && today.getDate() < birthDate.getDate())) {
      age--;
    }
    return age;
  }

  setFiscalCodeUnderage(attendance: InfoAttendance) {
    // Se non riconosce il CF mostro la domanda se ha meno di 9 anni..
    this.recognizedFC = false;

    // Codice italiano
    if (attendance.fiscalCode && attendance.fiscalCode.length === 16) {
      // L'età è codificata dal 7o al 12o carattere
      const year: number = parseInt(attendance.fiscalCode.slice(6, 8));
      const month: string = attendance.fiscalCode.slice(8, 9);
      let day: number = parseInt(attendance.fiscalCode.slice(9, 11));
      if (day > 40) {
        day -= 40;
      }

      if (!isNaN(year) && !isNaN(day)) {
        const currentYear: number = parseInt(new Date().getFullYear().toString());
        const nineYearsOldDate: Date = new Date();
        nineYearsOldDate.setFullYear(currentYear - 9);
        nineYearsOldDate.setHours(0, 0, 0, 0);

        const eighteenYearsOldDate: Date = new Date();
        eighteenYearsOldDate.setFullYear(currentYear - 18);
        eighteenYearsOldDate.setHours(0, 0, 0, 0);

        const currentYearShort: number = parseInt(new Date().getFullYear().toString().slice(2, 4));
        // Nel 2021 se year = 21 -> neonato - 22 -> 99 anni
        // I centenari gli trattiamo come bambini - non c'è un modo per distinguerli..

        let fullYear: string = year.toString();
        if (fullYear.length === 1) {
          fullYear = '0' + fullYear;
        }
        if (year > currentYearShort) {
          fullYear = '19' + fullYear;
        } else {
          fullYear = '20' + fullYear;
        }

        const birthDate: Date = new Date();
        birthDate.setHours(0, 0, 0, 0);
        birthDate.setFullYear(parseInt(fullYear));
        switch (month) {
          // Gennaio
          case 'A':
            birthDate.setMonth(0);
            break;
          // Febbraio
          case 'B':
            birthDate.setMonth(1);
            break;
          // Marzo
          case 'C':
            birthDate.setMonth(2);
            break;
          // Aprile
          case 'D':
            birthDate.setMonth(3);
            break;
          // Maggio
          case 'E':
            birthDate.setMonth(4);
            break;
          // Giugno
          case 'H':
            birthDate.setMonth(5);
            break;
          // Luglio
          case 'L':
            birthDate.setMonth(6);
            break;
          // Agosto
          case 'M':
            birthDate.setMonth(7);
            break;
          // Settempre
          case 'P':
            birthDate.setMonth(8);
            break;
          // Ottobre
          case 'R':
            birthDate.setMonth(9);
            break;
          // Novembre
          case 'S':
            birthDate.setMonth(10);
            break;
          // Dicembre
          case 'T':
            birthDate.setMonth(11);
            break;
        }
        birthDate.setDate(day);

        this.recognizedFC = true;

        if (eighteenYearsOldDate.getTime() < birthDate.getTime()) {
          this.underEighteenBirthday = new Date(birthDate);
        }
        this.underNineYearsOld = nineYearsOldDate.getTime() < birthDate.getTime();
        this.selectedAttendanceAge = this.getBirthdayAge(birthDate);

        this.hasDeliveryStatus = false;

        if (this.selectedStructure) {
          const validMinorTypes = (this.selectedStructure.content as InfoVenue).validMinorTypes;

          if (validMinorTypes && validMinorTypes.length) {
            validMinorTypes.forEach(validMinorType => {
              if (validMinorType.age === this.selectedAttendanceAge && validMinorType.vaccinationType === CampaignManagerMinorVaccinationType.DELIVERY) {
                this.hasDeliveryStatus = true;
              }
            });
          }
        }

      }
    }

    this.hasAlreadyHadFluVaccine = false;
    if (!this.recognizedFC) {
      this.underEighteenBirthday = undefined;
      this.underNineYearsOld = false;
      this.selectedAttendanceAge = undefined;
    } else {
      if (attendance.anamnesis && attendance.anamnesis.length > 0) {
        attendance.anamnesis.forEach(anamnesi => {
          if (anamnesi.questionKey === 'HAS_ALREADY_HAD_FLU_VACCINE') {
            this.hasAlreadyHadFluVaccine = anamnesi.answer;
            this.requiredRecall.setValue(!anamnesi.answer);
          }
        });
      }
    }
  }

  /**
   * Recupera gli slots disponibili per una nuova prenotazione
   */
  getSlotsNewAttendee() {

    const selectedSlotAttendanceDays = this.attendanceDays[this.indexSelectedDaySlots];

    if (this.slotsDropDown && this.slotsDropDown.length > 0) {
      this.selectedSlotDropDown = this.slotsDropDown[0];
      this.vaccinationTimeFrom.setValue(this.getVaccinationSlotStartString(this.selectedSlotDropDown.name));
      this.vaccinationTimeTo.setValue(this.getVaccinationSlotEndString(this.selectedSlotDropDown.name));
    }

    this.fiscalCode.setValue(undefined);
    this.description.setValue(undefined);

    this.selectedStatusDropDown = this.statusDropDown[0];
    this.setVaccinationValidators();
    this.onVaccinationDateChange(selectedSlotAttendanceDays.date);
    this.sidebarService.setShowNav(true);
  }

  closeAttendanceCard() {
    this.selectedAttendanceForm = undefined;
    this.firstFiscalCodeSearch = true;
    this.firstFullNameSearch = true;
    this.validFiscalCode.setValue(false);
    this.sidebarService.setShowNav(false);
  }

  /**
   * Salva una prenotazione
   */
  saveAttendee() {
    if (this.attendeeForm.valid) {
      this.updateAttendee();
    }
  }

  /**
   * Aggiorna una prenotazione
   */
  updateAttendee() {

    let vaccinationSlotStartString = '';
    let vaccinationSlotEndString = '';
    let recallDate = '';

    if (this.selectedStatusDropDown &&
      (this.selectedStatusDropDown.id === AttendanceStatuses.YES || this.selectedStatusDropDown.id === AttendanceStatuses.DELIVERED)) {
      const vaccinationSlotStart = this.getVaccinationSlotStart();
      const vaccinationSlotEnd = this.getVaccinationSlotEnd();

      if (vaccinationSlotStart.getTime() > vaccinationSlotEnd.getTime()) {
        this.dispatchWarningModal('018', 'TIME_ERROR');
        return;
      }

      vaccinationSlotStartString = vaccinationSlotStart.toISOString();
      vaccinationSlotEndString = vaccinationSlotEnd.toISOString();
    }

    if (this.recallDate.value) {
      recallDate = (this.recallDate.value as Date).toISOString();
    }

    if (this.selectedStatusDropDown && this.selectedAttendanceForm) {

      this.isLoadingInfo = true;

      this.rentService.updateAttendance(
        this.selectedAttendanceForm.attendanceId,
        this.selectedStatusDropDown.id as AttendanceStatuses,
        vaccinationSlotStartString,
        vaccinationSlotEndString,
        this.description.value,
        this.selectedRecallStatusDropDown ? (this.selectedRecallStatusDropDown.id as RecallStatuses) : undefined,
        this.requiredRecall.value,
        recallDate
      )
        .pipe(takeUntil(this._unsubscribeSignal$.asObservable()))
        .subscribe(
          (result: SenecaResponse<null>) => {
            if (result && result.error) {
              this.dispatchWarningModal('014', result.error);
              this.isLoadingInfo = false;
            } else {
              this.getDatesMenu();
              this.selectedAttendanceForm = undefined;
              this.firstFiscalCodeSearch = true;
              this.firstFullNameSearch = true;
              this.closeAttendanceCard();
            }
          }, (err) => {
            if (err && err.message) {
              this.dispatchWarningModal('015', err.message);
            }
            this.isLoadingInfo = false;
          });
    }
  }

  /**
   * Sbianco i campi alla ricerca del CF
   */
  searchEntitledChange() {
    this.resetFullNameSearch();
    if (this.fromNewAttendance && this.selectedAttendanceForm && this.selectedAttendanceForm.fiscalCode !== this.fiscalCode.value) {
      this.selectedAttendanceForm = undefined;
      this.vaccinationTimeFrom.setValue(undefined);
      this.vaccinationTimeTo.setValue(undefined);
      this.validFiscalCode.setValue(false);
      this.firstFiscalCodeSearch = true;
      this.firstFullNameSearch = true;
      this.setVaccinationValidators();
    }
    if (this.fiscalCode.invalid) {
      this.firstFiscalCodeSearch = true;
      this.firstFullNameSearch = true;
      this.validFiscalCode.setValue(false);
    }
  }

  /**
   * Sbianco i campi alla ricerca di nome e cognome
   */
  searchNameSurnameChange() {
    if (this.fromNewAttendance && this.selectedAttendanceForm && this.selectedAttendanceForm.name !== this.fullNameSearch.value) {
      this.selectedAttendanceForm = undefined;
      this.vaccinationTimeFrom.setValue(undefined);
      this.vaccinationTimeTo.setValue(undefined);
      this.validFiscalCode.setValue(false);
      this.firstFiscalCodeSearch = true;
      this.firstFullNameSearch = true;
      this.setVaccinationValidators();
    }
    if (this.fiscalCode.invalid) {
      this.firstFiscalCodeSearch = true;
      this.firstFullNameSearch = true;
      this.validFiscalCode.setValue(false);
    }
  }

  /**
   * Cerca un AD tramite codice fiscale e popola il campo nome, cognome e società
   */
  searchEntitledByCodfisc = () => {
    if (this.fiscalCode.value && this.fiscalCode.valid) {
      this.isLoadingFiscalCodeSearch = true;

      this.rentService.searchEntitledByCodfisc(
        this.roundId,
        this.fiscalCode.value,
        this.venueId)
        .pipe(takeUntil(this._unsubscribeSignal$.asObservable()))
        .subscribe(
          (result: SenecaResponse<InfoAttendance>) => {
            this.firstFiscalCodeSearch = false;
            if (result && result.error) {
              this.validFiscalCode.setValue(false);
              if (result.error !== 'USER_NOT_FOUND') {
                this.dispatchWarningModal('017', result.error);
              }
              this.selectedAttendanceForm = undefined;
            } else if (result && result.response) {
              this.validFiscalCode.setValue(true);

              this.selectedAttendanceForm = result.response;

              this.setFiscalCodeUnderage(this.selectedAttendanceForm);

              this.description.setValue(this.selectedAttendanceForm.description);

              this.selectedSlotDropDown = {
                id: this.selectedAttendanceForm.slotId,
                name: this.getTimeForSelect(this.selectedAttendanceForm.startDate) + ' - ' + this.getTimeForSelect(this.selectedAttendanceForm.endDate)
              };

              if (this.selectedAttendanceForm.vaccinationSlotStartDate && this.selectedAttendanceForm.vaccinationSlotEndDate) {
                this.vaccinationTimeFrom.setValue(this.getTimeForSelect(this.selectedAttendanceForm.vaccinationSlotStartDate));
                this.vaccinationTimeTo.setValue(this.getTimeForSelect(this.selectedAttendanceForm.vaccinationSlotEndDate));
              } else {
                this.vaccinationTimeFrom.setValue(this.getTimeForSelect(this.selectedAttendanceForm.startDate));
                this.vaccinationTimeTo.setValue(this.getTimeForSelect(this.selectedAttendanceForm.endDate));
              }
              if (this.vaccinationTimeFromInput) {
                this.vaccinationTimeFromInput._inputValue.updateValueAndValidity();
              }
              if (this.vaccinationTimeToInput) {
                this.vaccinationTimeToInput._inputValue.updateValueAndValidity();
              }

            } else {
              this.validFiscalCode.setValue(false);
              this.selectedAttendanceForm = undefined;
              this.hasAlreadyHadFluVaccine = false;
            }

            this.isLoadingFiscalCodeSearch = false;
          }, (err) => {
            if (err && err.message) {
              this.dispatchWarningModal('018', err.message);
            }
            this.firstFiscalCodeSearch = false;
            this.isLoadingFiscalCodeSearch = false;
            this.validFiscalCode.setValue(false);
            this.selectedAttendanceForm = undefined;
            this.hasAlreadyHadFluVaccine = false;
          });
    }
  }

  /**
  * @description Alla selezione di una struttura
  * @param structure
  */
  onFullNameChosen(item?: any) {
    this.validFiscalCode.setValue(true);
    this.selectedAttendanceForm = item.content as InfoAttendance;
    this.setFiscalCodeUnderage(this.selectedAttendanceForm);

    this.description.setValue(this.selectedAttendanceForm.description);

    this.selectedSlotDropDown = {
      id: this.selectedAttendanceForm.slotId,
      name: this.getTimeForSelect(this.selectedAttendanceForm.startDate) + ' - ' + this.getTimeForSelect(this.selectedAttendanceForm.endDate)
    };

    if (this.selectedAttendanceForm.vaccinationSlotStartDate && this.selectedAttendanceForm.vaccinationSlotEndDate) {
      this.vaccinationTimeFrom.setValue(this.getTimeForSelect(this.selectedAttendanceForm.vaccinationSlotStartDate));
      this.vaccinationTimeTo.setValue(this.getTimeForSelect(this.selectedAttendanceForm.vaccinationSlotEndDate));
    } else {
      this.vaccinationTimeFrom.setValue(this.getTimeForSelect(this.selectedAttendanceForm.startDate));
      this.vaccinationTimeTo.setValue(this.getTimeForSelect(this.selectedAttendanceForm.endDate));
    }
    if (this.vaccinationTimeFromInput) {
      this.vaccinationTimeFromInput._inputValue.updateValueAndValidity();
    }
    if (this.vaccinationTimeToInput) {
      this.vaccinationTimeToInput._inputValue.updateValueAndValidity();
    }
    this.selectedUserSearch = item;
  }


  /**
   * @description Chiamata allo scroll
   * @param scrollEvent
   */
  fetchMoreUsers(scrollEvent: { start: number; end: number }) {
    if (!this.isUserEndList && scrollEvent.end === this.usersLoaded) {
      this.userService$.next(this.fullNameSearch.value);
    }
  }

  /**
     * @description Carica le strutture e imposta per l'infinite scroll
     */
  initUsers() {
    this.usersList = this.usersList || [];
    this.userService$.pipe(
      switchMap(
        (text: string) => {
          this.isLoadingUsers = true;
          if (text !== this.fullNameSearch.value) {
            this.fullNameSearch.setValue(text);
            this.resetUserList = true;
            this.usersLoaded = 0;
          } else {
            this.resetUserList = false;
          }
          this.fullNameSearch.setValue(text || '');
          return this.searchByFullName();
        }
      )
      , catchError((err, caught) => {
        return caught;
      })
    )
      .subscribe({
        next: (eventData: any) => {
          if (this.resetUserList) {
            this.usersLoaded = 0;
            this.usersList = [];
          }
          if (eventData && eventData.length) {
            this.usersLoaded += eventData.length;
            this.usersList = this.usersList.concat(eventData);
          }
          this.isLoadingUsers = false;
        },
        error: (err: any) => {
          if (err && err.message) {
            this.dispatchWarningModal('009', err.message);
          }
          this.isLoadingUsers = false;
        }
      });
  }


  searchByFullName() {
    this.isLoadingUsers = true;
    return this.rentService.searchEntitledByFullName(
      this.roundId,
      this.venueId,
      this.fullNameSearch.value,
      this.userFromRecord,
      this.userNumRecords)
      .pipe(
        takeUntil(this._unsubscribeSignal$.asObservable())
        , map((resultData: SenecaResponse<any>) => {
          if (resultData.error) {
            // this.dispatchWarningModal('007', resultData.error);
            this.isLoadingUsers = false;
          } else if (resultData.response && resultData.response.length) {
            let formattedData: DropdownItem[] = [];

            resultData.response.forEach((user: any) => {
              let title = user.name + ' ' + user.surname;

              formattedData.push({
                id: user.venueId,
                name: title,
                content: user
              });
            });


            // Caricando n elementi per volta (itemsToLoad) se
            // ne carica di meno significa che sono a fine lista
            if (resultData.response.length < this.userNumRecords) {
              this.isUserEndList = true;
            }

            this.isLoadingStructures = false;
            return formattedData;
          }
          this.isUserEndList = true;
          this.isLoadingUsers = false;
          return [];
        })
      );
  }

  /**
   * Crea una prenotazione
   * @deprecated
   */
  createAttendee() {

    const vaccinationSlotStart = this.getVaccinationSlotStart();
    const vaccinationSlotEnd = this.getVaccinationSlotEnd();

    if (vaccinationSlotStart.getTime() > vaccinationSlotEnd.getTime()) {
      this.dispatchWarningModal('018', 'TIME_ERROR');
      return;
    }

    if (this.selectedStatusDropDown && this.selectedSlotDropDown && this.selectedAttendanceForm) {

      this.isLoadingInfo = true;

      this.rentService.addAttendeeForStructure(
        this.selectedSlotDropDown.id,
        this.roundId,
        this.selectedAttendanceForm.attendanceId,
        this.selectedStatusDropDown.id as AttendanceStatuses,
        vaccinationSlotStart.toISOString(),
        vaccinationSlotEnd.toISOString(),
        this.description.value)
        .pipe(takeUntil(this._unsubscribeSignal$.asObservable()))
        .subscribe(
          (result: SenecaResponse<null>) => {
            if (result && result.error) {
              this.dispatchWarningModal('017', result.error);
              this.isLoadingInfo = false;
            } else {
              this.getDatesMenu();
              this.selectedAttendanceForm = undefined;
              this.closeAttendanceCard();
            }
          }, (err) => {
            if (err && err.message) {
              this.dispatchWarningModal('018', err.message);
            }
            this.isLoadingInfo = false;
          });
    }
  }

  onDeliveryStatusChange(value: boolean) {
    this.hasDeliveryStatus = value;

    // Aggiorna il valore dello stato
    if (value && this.selectedStatusDropDown && this.selectedStatusDropDown.id === AttendanceStatuses.YES) {
      this.selectedStatusDropDown = this.statusDeliveredDropDown[1];
    } else if (!value && this.selectedStatusDropDown && this.selectedStatusDropDown.id === AttendanceStatuses.DELIVERED) {
      this.selectedStatusDropDown = this.statusDropDown[1];
    }

    // Aggiorna il valore dello stato richiamo
    if (value && this.selectedRecallStatusDropDown && this.selectedRecallStatusDropDown.id === RecallStatuses.YES) {
      this.selectedRecallStatusDropDown = this.recallStatusDeliveredDropDown[1];
    } else if (!value && this.selectedRecallStatusDropDown && this.selectedRecallStatusDropDown.id === RecallStatuses.DELIVERED) {
      this.selectedRecallStatusDropDown = this.recallStatusDropDown[1];
    }
  }

  onStatusChange(value: DropdownItem) {
    this.selectedStatusDropDown = value;
    this.setVaccinationValidators();
    if (this.selectedStatusDropDown && (this.selectedStatusDropDown.id === AttendanceStatuses.YES || this.selectedStatusDropDown.id === AttendanceStatuses.DELIVERED) && this.selectedAttendanceForm) {

      if (this.selectedAttendanceForm.vaccinationSlotStartDate && this.selectedAttendanceForm.vaccinationSlotEndDate) {
        this.vaccinationTimeFrom.setValue(this.getTimeForSelect(this.selectedAttendanceForm.vaccinationSlotStartDate));
        this.vaccinationTimeTo.setValue(this.getTimeForSelect(this.selectedAttendanceForm.vaccinationSlotEndDate));
      } else {
        this.vaccinationTimeFrom.setValue(this.getTimeForSelect(this.selectedAttendanceForm.startDate));
        this.vaccinationTimeTo.setValue(this.getTimeForSelect(this.selectedAttendanceForm.endDate));
      }
      this.setVaccinationValidators();
    }
  }

  onRequiredRecallChange(value: boolean) {
    this.requiredRecall.setValue(value);
  }

  onStatusRecallChange(value: DropdownItem) {
    this.selectedRecallStatusDropDown = value;
  }

  setVaccinationValidators() {
    if (this.selectedStatusDropDown) {
      if (this.selectedStatusDropDown.id === AttendanceStatuses.YES || this.selectedStatusDropDown.id === AttendanceStatuses.DELIVERED) {
        this.vaccinationDate.setValidators(Validators.required);
        this.vaccinationTimeFrom.setValidators([Validators.required, Validators.pattern('([0-1]?[0-9]|2[0-3]):[0-5][0-9]$')]);
        this.vaccinationTimeTo.setValidators([Validators.required, Validators.pattern('([0-1]?[0-9]|2[0-3]):[0-5][0-9]$')]);
      } else {
        this.vaccinationDate.clearValidators();
        this.vaccinationTimeFrom.clearValidators();
        this.vaccinationTimeTo.clearValidators();
      }

      this.vaccinationDate.updateValueAndValidity();
      this.vaccinationTimeFrom.updateValueAndValidity();
      this.vaccinationTimeTo.updateValueAndValidity();
    }
  }

  onSlotChange(value: DropdownItem) {
    this.selectedSlotDropDown = value;
  }

  onVaccinationDateChange(date: Date) {
    this.selectedVaccinationDate = new Date(date);
    this.selectedVaccinationDate.setHours(0, 0, 0, 0);
    this.vaccinationDate.setValue(this.selectedVaccinationDate);
  }

  onRecallDateChange(date?: Date) {
    if (date) {
      this.selectedRecallDate = new Date(date);
      this.selectedRecallDate.setHours(0, 0, 0, 0);
      this.recallDate.setValue(this.selectedRecallDate);
    } else {
      this.selectedRecallDate = undefined;
      this.recallDate.setValue(undefined);
    }
  }

  //#endregion

  //#region Funzioni generali

  /**
   * @description Va all'homepage
   */
  goToHome() {
    this.redirectService.goToHome();
  }

  /**
   * @description Mostra una modale con un messaggio di errore
   * @param modalId
   * @param error
   */
  dispatchWarningModal(modalId: string, error: string) {
    const messageObj = getWarningModal(this.translate, modalId, error);
    this.appStore.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
  }

  //#endregion

  getVaccinationSlotStartString(time: string) {
    return time.slice(0, 2) + ':' + time.slice(3, 5);
  }

  getVaccinationSlotEndString(time: string): string {
    return time.slice(8, 10) + ':' + time.slice(11, 13);
  }

  getVaccinationSlotStart(): Date {
    const time = this.vaccinationTimeFrom.value;
    const startHour: number = parseInt(time.slice(0, 2));
    const startMinutes: number = parseInt(time.slice(3, 5));

    let vaccinationSlotStartDate = new Date(this.selectedVaccinationDate);
    vaccinationSlotStartDate.setHours(startHour, startMinutes, 0, 0);
    return vaccinationSlotStartDate;
  }

  getVaccinationSlotEnd(): Date {
    const time = this.vaccinationTimeTo.value;
    const endHour: number = parseInt(time.slice(0, 2));
    const endMinutes: number = parseInt(time.slice(3, 5));

    let vaccinationSlotEndDate = new Date(this.selectedVaccinationDate);
    vaccinationSlotEndDate.setHours(endHour, endMinutes, 0, 0);
    return vaccinationSlotEndDate;
  }

}

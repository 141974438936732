<div class="page-content-wrapper" fxLayout="column">

  <ng-container *ngIf="isLoadingBase">
    <!-- Caricamento richiesta -->
    <h1 fxFlexOffset="40px" class="primary" translate="admin.campaignPendingRequest.LOADING_TITLE"></h1>
    <bread-crumbs fxFlexOffset="12px" firstLevel="PENDING_REQUESTS"
      [secondLevel]="'component.breadCrumbs.LOADING' | translate">
    </bread-crumbs>
  </ng-container>

  <ng-container *ngIf="!isLoadingBase && request">
    <ng-container *ngIf="isApproveSlot">
      <!-- Slot da approvare -->
      <h1 fxFlexOffset="40px" class="primary" translate="admin.campaignPendingRequest.SLOT_TO_APPROVE"></h1>
      <bread-crumbs fxFlexOffset="12px" firstLevel="PENDING_REQUESTS"
        [secondLevel]="'component.breadCrumbs.SLOT_TO_APPROVE' | translate"></bread-crumbs>
    </ng-container>
    <ng-container *ngIf="isRevokeSlot">
      <!-- Slot da revocare -->
      <h1 fxFlexOffset="40px" class="primary" translate="admin.campaignPendingRequest.SLOT_TO_REJECT"></h1>
      <bread-crumbs fxFlexOffset="12px" firstLevel="PENDING_REQUESTS"
        [secondLevel]="'component.breadCrumbs.SLOT_TO_REJECT' | translate"></bread-crumbs>
    </ng-container>
    <ng-container *ngIf="isApproveDescription">
      <!-- Dettagli da approvare -->
      <h1 fxFlexOffset="40px" class="primary" translate="admin.campaignPendingRequest.DETAILS_TO_APPROVE"></h1>
      <bread-crumbs fxFlexOffset="12px" firstLevel="PENDING_REQUESTS"
        [secondLevel]="'component.breadCrumbs.DETAILS_TO_APPROVE' | translate"></bread-crumbs>
    </ng-container>
  </ng-container>

  <div fxFlexOffset="40px" class="pending-request-container" fxLayout="column">

    <!-- Loader -->
    <div *ngIf="isLoadingBase" class="loader spinner-big"></div>

    <ng-container *ngIf="!isLoadingBase && request && (isApproveSlot || isRevokeSlot)">

      <!-- Nome struttura sanitaria - nome sede -->
      <h3>{{ request.healthFacilitySupplierName + ' - ' + request.name }}</h3>

      <!-- 15 giornate inserite per un totale di 556 POSTI e 200 PRENOTAZIONI -->
      <h6 fxFlexOffset="28px" class="black" [innerHTML]="'admin.campaignPendingRequest.RESUME' | translate: {
        value1: ('admin.campaignPendingRequest.DAY' + (structureDaySlots.length !== 1 ? 'S' : '')) | translate: { value: structureDaySlots.length || 0 } | boldText,
        value2: ('admin.campaignPendingRequest.SEAT' + (request.totalSeats !== 1 ? 'S' : '')) | translate: { value: request.totalSeats || 0 } | boldText,
        value3: ('admin.campaignPendingRequest.BOOKING' + (request.totalReservations !== 1 ? 'S' : '')) | translate: { value: request.totalReservations || 0 } | boldText
      }"></h6>

      <!-- Ancoraggio per lo scroll -->
      <div id="admin-campaign-pending-anchor" fxFlexOffset="16px" fxFlexOffset.xs="12px"></div>

      <ng-container *ngIf="!isLoadingBase && structureDaySlots.length; else noDays">

        <div fxFlexOffset="8px" fxLayout="row" fxLayout.xs="column" fxLayoutAlign="space-between center">

          <!-- Indicazioni -->
          <div fxFlex fxLayout="row" fxLayoutAlign="start center">

            <!-- Indicazioni visive -->
            <div fxLayout="row" fxLayoutAlign="start" class="full-width">

              <!-- Da approvare -->
              <ng-container *ngIf="isApproveSlot" [ngTemplateOutlet]="roundStatus" [ngTemplateOutletContext]="{
                fullOffset: true,
                label: 'PENDING'
              }"></ng-container>

              <!-- Approvati -->
              <ng-container [ngTemplateOutlet]="roundStatus" [ngTemplateOutletContext]="{
                fullOffset: !isApproveSlot,
                label: 'AVAILABLE',
                color: 'green'
              }"></ng-container>

              <!-- Negato -->
              <ng-container *ngIf="isApproveSlot" [ngTemplateOutlet]="roundStatus" [ngTemplateOutletContext]="{
                label: 'REFUSED',
                color: 'refused'
              }"></ng-container>

              <!-- In revoca -->
              <ng-container *ngIf="isRevokeSlot" [ngTemplateOutlet]="roundStatus" [ngTemplateOutletContext]="{
                label: 'REVOKING',
                color: 'orange'
              }"></ng-container>

              <!-- Occupato -->
              <ng-container *ngIf="isRevokeSlot" [ngTemplateOutlet]="roundStatus" [ngTemplateOutletContext]="{
                label: 'BUSY',
                color: 'red'
              }"></ng-container>

              <!-- Revocato -->
              <ng-container *ngIf="isRevokeSlot" [ngTemplateOutlet]="roundStatus" [ngTemplateOutletContext]="{
                label: 'REVOKED',
                color: 'revoked'
              }"></ng-container>

            </div>

            <!-- Informazioni -->
            <svg-icon fxFlexOffset="28px" fxFlexOffset.xs="20px" class="cursor-pointer medium-size" [applyClass]="true"
              (click)="showHelper()" src="assets/img/icons/question-mark-outline.svg">
            </svg-icon>

          </div>

          <!-- Select mobile -->
          <div fxHide fxShow.xs fxFlex="232px" fxFlex.xs="100" fxFlexOffset.xs="16px" fxLayout="row" fxLayoutAlign="end"
            fxLayoutAlign.xs="space-between" ngClass.xs="full-width">

            <div fxHide fxShow.xs class="full-width">
              <!-- Lista giorni modalità mobile -->
              <welion-select [items]="structureDaySlotsDropdown" [selectedItem]="selectedStructureDaySlotsDropdown"
                (change)="onSelectDaySlotMobile($event)">
              </welion-select>
            </div>
          </div>
        </div>

        <div fxFlexOffset="8px" fxLayout="row">

          <!-- Lista giorni - nascosto in modalità mobile -->
          <div fxShow fxHide.xs fxLayout="column" fxLayoutAlign="start start" class="day-slots-cards">
            <div *ngFor="let daySlot of structureDaySlots; let first = first; let index = index"
              [fxFlexOffset]="first ? '0px' : '4px'"
              [ngClass]="{'selected-day': index === indexSelectedStructureDaySlots}" class="day-slots"
              (click)="onSelectDaySlot(index)">
              <!-- Lunedì 1 Marzo -->
              <h5>{{ daySlot.date | date: 'EEEE dd MMMM' | titlecase }}</h5>
            </div>
          </div>

          <!-- Lista slots -->
          <div fxFlexOffset="40px" fxFlexOffset.xs="0px" fxLayout="column" class="slot-list full-width">

            <!-- Slots -->
            <div fxFlexOffset="4px" fxLayout="row wrap" class="tile-container" infiniteScroll
              [infiniteScrollDistance]="2" [infiniteScrollThrottle]="300" (scrolled)="getDaySlots()">

              <slot-component-tile *ngFor="let slot of slots; let index = index" fxFlexOffset="8px"
                fxFlexOffset.xs="4px" [slot]="slot" [status]="slot.status" [selected]="slotIsSelected(slot)" [ngClass]="{
                  'cursor-pointer': isApproveSlot ?
                  (slot.status === 'APPROVATION_REQUEST') :
                  (slot.status === 'REVOKE_REQUEST'),
                  'no-pointer': isLimitedAdmin
                }" (click)="selectSlotClick(index)">
              </slot-component-tile>

              <ng-container *ngIf="isLoading">
                <div fxFlex class="loader spinner-medium"></div>
              </ng-container>

              <ng-container *ngIf="!isLoading && !slots.length" [ngTemplateOutlet]="noSlots">
              </ng-container>

            </div>
          </div>
          <!-- Margine a lato -->
          <div fxFlexOffset="20px" fxFlexOffset.xs="0px"></div>

        </div>

        <div fxLayout="row" fxFlexOffset="32px" fxLayoutAlign="space-between center">
          <div fxLayout="row" fxLayoutAlign="start center">
            <!-- Nega tutti -->
            <welion-button *ngIf="!isLimitedAdmin" width="140px" label="generic.REJECT_ALL" type="primary bordless"
              size="medium" (onClick)="openRejectAllRequestModal()">
            </welion-button>
            <!-- Nega selezionati -->
            <welion-button *ngIf="!isLimitedAdmin" width="200px" fxFlexOffset="8px" label="generic.REJECT_SELECTED"
              type="primary bordless" size="medium" (onClick)="openRejectSelectedRequestModal()"
              [disabled]="selectedSlots.length === 0">
            </welion-button>
          </div>

          <div fxLayout="row" fxLayoutAlign="start center">
            <!-- Conferma selezionati -->
            <welion-button *ngIf="!isLimitedAdmin" width="232px" label="generic.CONFIRM_SELECTED" type="primary"
              size="medium" (onClick)="confirmSelected()" [disabled]="selectedSlots.length === 0">
            </welion-button>
            <!-- Conferma tutti -->
            <welion-button *ngIf="!isLimitedAdmin" width="180px" fxFlexOffset="8px" label="generic.CONFIRM_ALL"
              type="primary" size="medium" (onClick)="confirmAll()">
            </welion-button>
          </div>
        </div>
      </ng-container>


    </ng-container>

    <ng-container *ngIf="!isLoadingBase && request && isApproveDescription">

      <div fxLayout="row" fxFlexOffset="40px">
        <h3 fxFlexOffset="8px">{{ request.healthFacilitySupplierName + ' - ' + request.name }}</h3>
      </div>

      <div fxFlexOffset="20px" class="pending-request-divider"></div>

      <!-- Note -->
      <input-container fxFlexOffset="32px" class="pending-description full-width"
        [title]="'admin.campaignPendingRequest.NOTE' | translate">
        <welion-text-area [bindValue]="request.requestedDescriptionUpdate" [disabled]="true"></welion-text-area>
      </input-container>

      <div fxLayout="row" fxLayoutAlign="end" fxFlexOffset="42px">
        <!-- Non approvare -->
        <welion-button width="180px" label="admin.campaignPendingRequest.REJECT" type="third" size="medium"
          (onClick)="rejectVenueDescription()">
        </welion-button>
        <!-- Approva -->
        <welion-button fxFlexOffset="8px" width="120px" label="admin.campaignPendingRequest.APPROVE" type="primary"
          size="medium" (onClick)="approveVenueDescription()">
        </welion-button>
      </div>

      <!-- Indirizzo -->
      <div fxLayout="column" fxFlexOffset="32px">
        <h3 class="small bold" translate="admin.campaignPendingRequest.ADDRESS"></h3>
        <h5>{{ request.name }}</h5>
        <h5>{{ request.address }}</h5>
      </div>

    </ng-container>

  </div>

</div>

<!-- Placeholder nessuna giornata disponibile -->
<ng-template #noDays>
  <h6 fxFlexOffset="60px" class="black" translate="admin.campaignRoundVenueDetails.slots.NO_DATAS"></h6>
</ng-template>

<!-- Placeholder nessuno slot disponibile -->
<ng-template #noSlots>
  <h6 fxFlexOffset="40px" class="black" translate="admin.campaignRoundVenueDetails.slots.NO_SLOTS"></h6>
</ng-template>

<!-- Template indicatore status degli slot -->
<ng-template #roundStatus let-fullOffset='fullOffset' let-label='label' let-color='color'>
  <div fxFlexOffset="{{fullOffset ? 320 : 20}}px" fxFlexOffset.xs="{{fullOffset ? 20 : 20}}px" fxLayout="row"
    fxLayoutAlign="start center">
    <div class="round-point {{color}}"></div>
    <h6 fxFlexOffset="4px" class="extra-small" translate="admin.campaignPendingRequest.status.{{label}}">
    </h6>
  </div>
</ng-template>

<!-- Modale di rifiuto di una richiesta -->
<modal id="rejectSelectedRequest" class="reject-request-modal" (onClose)="closeRejectSelectedRequestModal()"
  (onCancel)="closeRejectSelectedRequestModal()">

  <form fxLayout="column" class="full-width" (ngSubmit)="confirmRejectSelected()" [formGroup]="rejectForm">

    <!-- Rifiuta richiesta -->
    <h2 fxFlexOffset="24px" class="small primary" translate="admin.campaignPendingRequest.reject.TITLE"></h2>

    <!-- Nota -->
    <input-container fxFlexOffset="24px" [isFromTextArea]="true" [numberCharsTextArea]="200"
      [title]="'admin.campaignPendingRequest.reject.NOTE' | translate">
      <welion-text-area [placeholder]="'admin.campaignPendingRequest.reject.NOTE_PLACEHOLDER' | translate"
        [inputValue]="rejectNote">
      </welion-text-area>
    </input-container>

    <div fxFlexOffset="24px" fxLayout="row" fxLayoutAlign="center">
      <!-- Chiudi -->
      <welion-button fxFlex label="generic.CLOSE" type="third" size="medium" buttonType="button"
        (onClick)="closeRejectSelectedRequestModal()">
      </welion-button>
      <!-- Conferma -->
      <welion-button fxFlexOffset="8px" fxFlex label="generic.CONFIRM" type="primary" size="medium" buttonType="submit"
        [disabled]="rejectForm.invalid">
      </welion-button>
    </div>

  </form>

</modal>

<!-- Modale di rifiuto totale di una richiesta -->
<modal id="rejectAllRequest" class="reject-request-modal" (onClose)="closeRejectAllRequestModal()"
  (onCancel)="closeRejectAllRequestModal()">

  <form fxLayout="column" class="full-width" (ngSubmit)="confirmRejectAll()" [formGroup]="rejectForm">

    <!-- Rifiuta richiesta -->
    <h2 fxFlexOffset="24px" class="small primary" translate="admin.campaignPendingRequest.reject.TITLE_ALL"></h2>

    <!-- Nota -->
    <input-container fxFlexOffset="24px" [isFromTextArea]="true" [numberCharsTextArea]="200"
      [title]="'admin.campaignPendingRequest.reject.NOTE' | translate">
      <welion-text-area [placeholder]="'admin.campaignPendingRequest.reject.NOTE_PLACEHOLDER' | translate"
        [inputValue]="rejectNote">
      </welion-text-area>
    </input-container>

    <div fxFlexOffset="24px" fxLayout="row" fxLayoutAlign="center">
      <!-- Chiudi -->
      <welion-button fxFlex label="generic.CLOSE" type="third" size="medium" buttonType="button"
        (onClick)="closeRejectAllRequestModal()">
      </welion-button>
      <!-- Conferma -->
      <welion-button fxFlexOffset="8px" fxFlex label="generic.CONFIRM" type="primary" size="medium" buttonType="submit"
        [disabled]="rejectForm.invalid">
      </welion-button>
    </div>

  </form>

</modal>
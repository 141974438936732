import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { Store } from '@ngrx/store';
import { takeUntil } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';

import * as CoreActions from "../../../core/ngrx/core.actions";
import * as fromApp from "../../../ngrx/app.reducers";
import { GetCampaignListForHelpDeskResponse } from 'atfcore-commonclasses/bin/classes/campaignmanager/serviceResponses';

import { RedirectService } from 'src/app/shared/services/redirect.service';
import { getWarningModal } from 'src/app/utils/utils';
import { RentService } from 'src/app/structure/services/rent.service';
import { ScrollTo } from 'src/app/core/services/scroll-to.service';

@Component({
  selector: 'app-admin-campaigns',
  templateUrl: './campaigns.component.html',
  styleUrls: ['./campaigns.component.scss']
})
export class AdminCampaignsComponent implements OnInit, OnDestroy {

  isLoadingCount: boolean = true;
  isLoading: boolean = true;

  searchText?: string;

  private _unsubscribeSignal$: Subject<void> = new Subject();

  readonly paginationLength: number = 25;

  campaignsCount: number = 0;
  campaigns: GetCampaignListForHelpDeskResponse[] = [];

  isLimitedAdmin: boolean = false;

  constructor(
    private appStore: Store<fromApp.AppState>,
    private translate: TranslateService,
    private redirectService: RedirectService,
    private rentService: RentService,
    private scrollTo: ScrollTo
  ) {
  }

  ngOnInit() {
    this.appStore.select(fromApp.getIsLimitedAdmin)
      .pipe(takeUntil(this._unsubscribeSignal$.asObservable()))
      .subscribe(
        (isLimitedAdmin) => {
          this.isLimitedAdmin = isLimitedAdmin;
        });

    this.countCampaigns();
  }

  ngOnDestroy() {
    this._unsubscribeSignal$.next();
    this._unsubscribeSignal$.unsubscribe();
  }

  /**
   * @description Mostra una modale con un messaggio di errore
   * @param modalId
   * @param error
   */
  dispatchWarningModal(modalId: string, error: string) {
    const messageObj = getWarningModal(this.translate, modalId, error);
    this.appStore.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
  }

  countCampaigns() {

    this.isLoadingCount = true;
    this.isLoading = true;

    this.rentService.countCampaignListForAdmin(this.searchText)
      .pipe(takeUntil(this._unsubscribeSignal$.asObservable()))
      .subscribe((result) => {
        if (!result || result.error) {
          this.dispatchWarningModal('082', result.error || '');
          this.resetPagination();
        } else {
          this.campaignsCount = result.response;

          if (this.campaignsCount === 0) {
            this.resetPagination();
          } else {
            this.getCampaigns();
          }
        }
        this.isLoadingCount = false;
      }, (err) => {
        if (err && err.message) {
          this.dispatchWarningModal('084', err.message);
        }
        this.resetPagination();
      });
  }

  getCampaigns(index?: number) {

    this.isLoading = true;

    const pageSelectedIndex = index ? index : 0;
    if (index === 0 || index) {
      this.scrollTo.element('admin-campaigns-anchor');
    }

    this.rentService.getCampaignListForAdmin((pageSelectedIndex * this.paginationLength), this.paginationLength, this.searchText)
      .pipe(takeUntil(this._unsubscribeSignal$.asObservable()))
      .subscribe((result) => {
        if (!result || result.error) {
          this.dispatchWarningModal('082', result.error || '');
          this.resetPagination();
        } else {
          this.campaigns = result.response;
          this.isLoading = false;
        }
      }, (err) => {
        if (err && err.message) {
          this.dispatchWarningModal('084', err.message);
        }
        this.resetPagination();
      });
  }

  resetPagination() {
    this.isLoadingCount = false;
    this.isLoading = false;

    this.campaignsCount = 0;
    this.campaigns = [];
  }

  newCampaign() {
    this.redirectService.goToAdminNewCampaign();
  }

  onCampaignClick(campaign: GetCampaignListForHelpDeskResponse) {
    this.redirectService.goToAdminCampaignTabRounds(campaign.campaignId);
  }

  searchTextChange(searchText?: string) {
    this.searchText = searchText;
  }

  searchCampaigns() {
    this.countCampaigns();
  }
}

/*
 * Servizio che gestisce le chiamate ai servizi per le lingue
*/

import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { SenecaResponse } from 'atfcore-commonclasses/bin/classes/common';
import { Observable, of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { AuthService } from 'src/app/auth/services/auth.service';
import { UrlService } from 'src/app/shared/services/url.service';

@Injectable()
export class ExportService {

  _mediatorUrl: string;

  constructor(private http: HttpClient, private urlService: UrlService, private authService: AuthService) {
    this._mediatorUrl = this.urlService.getServiceMediatorUrl();
  }

  /**
   * Servizio per export utenti prenotati in una sede di un round
   * @param {string} roundId
   * @param {string} venueId
   * @return {string} exportPathName
   */
  exportBookedUsers(roundId: string, venueId: string): Observable<SenecaResponse<string>> {
    let httpParams = new HttpParams();

    httpParams = httpParams.append('roundId', roundId);
    httpParams = httpParams.append('venueId', venueId);

    return this.http.get<SenecaResponse<string>>(
      this._mediatorUrl + 'export-booked-users', { params: httpParams }
    );
  }

  /**
   * Scarica il link per il report della dashboard
   * @return {string}
   */
  exportDashboard(supplierId?: string, campaignId?: string, roundId?: string): Observable<SenecaResponse<string>> {
    let httpParams = new HttpParams();

    if (supplierId) {
      httpParams = httpParams.append('supplierId', supplierId);
    }
    if (campaignId) {
      httpParams = httpParams.append('campaignId', campaignId);
    }
    if (roundId) {
      httpParams = httpParams.append('roundId', roundId);
    }

    return this.http.get<SenecaResponse<string>>(
      this._mediatorUrl + 'export-dashboard', {
      params: httpParams
    })
  }

  /**
   * Servizio per export AD su un round - usato dagli admin
   * @param {string} roundId
   * @return {string} exportPathName
   */
  exportEntitledListForAdmin(roundId: string): Observable<SenecaResponse<string>> {
    let httpParams = new HttpParams();

    httpParams = httpParams.append('roundId', roundId);

    return this.http.get<SenecaResponse<string>>(
      this._mediatorUrl + 'export-entitled-list-for-admin', { params: httpParams }
    );
  }

  /**
   * Servizio per export AD su una campagna e round - usato nei clienti
   * @param {string} campaignId
   * @param {string} roundId
   * @return {string} exportPathName
   */
  exportEntitledList(campaignId: string, roundId: string): Observable<SenecaResponse<string>> {
    let httpParams = new HttpParams();

    httpParams = httpParams.append('campaignId', campaignId);
    httpParams = httpParams.append('roundId', roundId);

    return this.http.get<SenecaResponse<string>>(
      this._mediatorUrl + 'export-entitled-list', { params: httpParams }
    );
  }

  /**
   * Servizio per export sedi con saturazione
   * @param {string} roundId
   * @return {string} exportPathName
   */
  exportVenueList(roundId: string): Observable<SenecaResponse<string>> {
    let httpParams = new HttpParams();

    httpParams = httpParams.append('roundId', roundId);

    return this.http.get<SenecaResponse<string>>(
      this._mediatorUrl + 'export-venue-list', { params: httpParams }
    );
  }

  /**
   * Servizio per export slot di un round
   * @param {string} roundId
   * @return {string} exportPathName
   */
  exportRoundSlots(roundId: string): Observable<SenecaResponse<string>> {
    let httpParams = new HttpParams();

    httpParams = httpParams.append('roundId', roundId);

    return this.http.get<SenecaResponse<string>>(
      this._mediatorUrl + 'export-round-slots', { params: httpParams }
    );
  }

  // Report scarti cliente
  exportClientScrapsList(roundId: string): Observable<SenecaResponse<string>> {
    let httpParams = new HttpParams();

    httpParams = httpParams.append('roundId', roundId);

    return this.http.get<SenecaResponse<string>>(
      this._mediatorUrl + 'export-client-import-logs', { params: httpParams }
    );
  }


  /** Effettua il download di un file temporaneo, eventualmente utilizzando l'ssorqtp
   * 
   * NOTA: Dato che questo url verrà assegnato ad una nuova pagina del browser (o alla pagina corrente, non cambia)
   * l'eventuale Authorization Bearer non sarà disponibile, di conseguenza in assenza dell'ssorqtp è necessario usare i cookie
   */
  getDownloadTempFileUrl(filename: string, withSsortkqp: boolean) {
    const call = of(null)
      .pipe(
        switchMap(() => withSsortkqp ? this.authService.crateRetrieveTokenAfterLogin() : of(null)),
        map((ssorqtp) => `${this._mediatorUrl}download-temp-file?filename=${filename}` + (ssorqtp ? `&ssortkqp=${ssorqtp}` : ''))
      )

    return call;
  }


}

<div class="card-round-home" [ngClass]="{'active': _isActive}" fxLayout="column">

  <div fxLayout="row" fxLayoutAlign="start center">

    <svg-icon [applyClass]="true" src="/assets/img/icons/note-icon-red.svg"></svg-icon>

    <div *ngIf="_round" fxLayout="column" class="full-width">
      <!-- Round attivo / Prossimo round -->
      <h3 [translate]="'component.cardRoundHome.' + (_isActive ? 'ACTIVE_ROUND' : 'NEXT_ROUND')"></h3>
      <!-- Campagna vaccinale 2021 -->
      <h5>{{ _round.title }}</h5>
      <!-- 01 Aprile 2021 - 01 Giugno 2021 -->
      <h5 class="nowrapDesk">
        {{ _round.startDate| date: 'dd MMMM yyyy' | titlecase }} - {{ _round.endDate| date: 'dd MMMM yyyy' | titlecase
        }}</h5>

      <div fxShow fxHide.xs fxFlexOffset="8px" fxLayout="row" fxLayoutAlign="end">
        <ng-container [ngTemplateOutlet]="cardRoundHomeButton"></ng-container>
      </div>
    </div>
  </div>

  <div fxHide fxShow.xs fxFlexOffset="4px" fxLayout="row" fxLayoutAlign="center">
    <ng-container [ngTemplateOutlet]="cardRoundHomeButton"></ng-container>
  </div>

</div>

<ng-template #cardRoundHomeButton>
  <!-- Definisci disponibilità -->
  <ng-container *ngIf="!_isActive">
    <welion-button type="primary bordless" size="medium" (onClick)="defineAvailability()"
      label="component.cardRoundHome.DEFINE_AVAILABILITY">
    </welion-button>
  </ng-container>

  <!-- Gestisci presenze -->
  <ng-container *ngIf="_isActive">
    <welion-button type="primary" size="medium" (onClick)="manageAttendance()"
      label="component.cardRoundHome.MANAGE_ATTENDANCE">
    </welion-button>
  </ng-container>
</ng-template>

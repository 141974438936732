<div class="page-content-wrapper" fxLayout="column">

  <!-- Campagne -->
  <h1 fxFlexOffset="40px" class="primary" translate="admin.campaigns.TITLE"></h1>

  <bread-crumbs fxFlexOffset="12px" firstLevel="ADMIN_CAMPAIGNS"></bread-crumbs>

  <div fxFlexOffset="14px" fxFlexOffset.xs="12px" class="campaigns-container" fxLayout="column">

    <!-- Nome campagna -->
    <input-container [title]="'admin.campaigns.SEARCH_CAMPAIGN' | translate">
      <welion-input [bindValue]="searchText" (onChange)="searchTextChange($event)" (onIconClicked)="searchCampaigns()"
        [placeholder]="'admin.campaigns.SEARCH_CAMPAIGN_PLACEHOLDER' | translate" [disabled]="isLoading"
        iconRight="assets/img/icons/icon-search.svg">
      </welion-input>
    </input-container>

    <div fxLayout="row" fxFlexOffset="20px" fxLayoutAlign="space-between center">
      <!-- 4 campagne -->
      <h3 fxFlexOffset="16px">
        <ng-container *ngIf="!isLoadingCount && campaignsCount === 1">
          {{ 'admin.campaigns.table.CAMPAIGN_TITLE' | translate }}
        </ng-container>
        <ng-container *ngIf="!isLoadingCount && campaignsCount !== 1">
          {{ 'admin.campaigns.table.CAMPAIGNS_TITLE' | translate: { value: campaignsCount || 0 }
          }}
        </ng-container>
        <ng-container *ngIf="isLoadingCount">
          {{ 'admin.campaigns.table.CAMPAIGNS_TITLE_LOADING' | translate }}
        </ng-container>
      </h3>

      <!-- Crea campagna -->
      <welion-button *ngIf="!isLimitedAdmin" width="172px" size="medium" type="primary" (onClick)="newCampaign()"
        label="admin.campaigns.table.NEW_CAMPAIGN">
      </welion-button>
    </div>

    <div id="admin-campaigns-anchor" fxFlexOffset="10px"></div>

    <!-- Table header -->
    <div fxFlexOffset="10px">
      <!-- Campagna -->
      <h5 class="bold" translate="admin.campaigns.table.CAMPAIGN" fxFlex fxFlexOffset="16px"></h5>
      <!-- Strutture -->
      <h5 class="bold" translate="admin.campaigns.table.VENUES" fxFlex="12"></h5>
      <!-- Aventi diritto Totali -->
      <h5 class="bold" translate="admin.campaigns.table.TOTAL_ENTITLEDS" fxFlex="18"></h5>
      <!-- Aventi diritto Lavorati -->
      <h5 class="bold" translate="admin.campaigns.table.WORKED_ENTITLEDS" fxFlex="18"></h5>
      <!-- Stato -->
      <h5 class="bold" translate="admin.campaigns.table.STATE" fxFlex="12"></h5>

      <div fxFlex="10"></div>
    </div>

    <!-- Loader -->
    <div *ngIf="isLoading" class="loader spinner-big"></div>

    <!-- Table rows -->
    <ng-container *ngIf="!isLoading">
      <div *ngFor="let campaign of campaigns; let first = first; let odd = odd" class="campaign-row"
        [ngClass]="{'odd': odd}" fxLayout="row" fxLayoutAlign="start center" [fxFlexOffset]="first ? '8px' : '0px'">
        <!-- Campagna -->
        <h6 class="black" fxFlex fxFlexOffset="16px">{{ campaign.title }}</h6>
        <!-- Strutture -->
        <h6 class="black" fxFlex="12">{{ campaign.venueCount || 0 }}</h6>
        <!-- Aventi diritto Totali -->
        <h6 class="black" fxFlex="18">{{ campaign.totalAttendance || 0 }}</h6>
        <!-- Aventi diritto lavorati -->
        <h6 class="black" fxFlex="18">{{ campaign.workedAttendance || 0 }}</h6>
        <!-- Stato -->
        <h6 class="black" fxFlex="12">{{ ('generic.' + (campaign.isCampaignActive ? 'ACTIVE': 'INACTIVE')) |
          translate }}</h6>

        <div fxFlex="10" fxLayout="row" fxLayoutAlign="end">
          <svg-icon src="assets/img/icons/arrow-right-red.svg" class="cursor-pointer" [applyClass]="true"
            (click)="onCampaignClick(campaign)">
          </svg-icon>
          <div fxFlex="16px"></div>
        </div>
      </div>
    </ng-container>

    <!-- Paginatore -->
    <welion-paginator fxFlexOffset="20px" [isLoading]="isLoading" [paginationLength]="paginationLength"
      [itemsCount]="campaignsCount" (onClickPage)="getCampaigns($event)"></welion-paginator>

  </div>

</div>
<div class="add-round-container" fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="start start"
  fxLayoutAlign.lt-md="start center">

  <div class="add-round-side" fxLayout="column" fxLayoutAlign="space-between">

    <div fxLayout="column" fxLayoutAlign="start start">
      <div class="add-round-back" fxLayout="row" fxLayoutAlign="start center" fxFlexOffset="40px" (click)="goBack()">
        <svg-icon fxFlexOffset="40px" [applyClass]="true" src="./assets/img/icons/arrow-white-left.svg"></svg-icon>
        <h5 class="white" fxFlexOffset="12px">{{ 'generic.GO_BACK' | translate | uppercase}}</h5>
      </div>

      <p fxFlexOffset="24px">
        <span class="bold" translate="admin.addOfflineAd.SIDE_TITLE"></span>
      </p>

      <div fxFlexOffset="24px" class="add-round-tick"></div>

      <div fxShow fxHide.lt-md class="add-round-faq" fxFlexOffset="48px" fxLayout="row">
        <h5 translate="admin.addOfflineAd.SIDE_DESC"></h5>
      </div>
    </div>

    <svg-icon fxShow fxHide.lt-md class="welion-logo" [applyClass]="true" src="./assets/img/icons/app-logo.svg">
    </svg-icon>

    <div fxShow fxHide.lt-md fxFlexOffset="40px"></div>

  </div>

  <div fxFlex fxLayout="row" fxLayout.xs="column" fxLayoutAlign="center" fxLayoutAlign.xs="start"
    class="add-round-body-container">

    <form class="add-round-body" [formGroup]="addEntitledForm" fxLayout="column" fxLayoutAlign="start center"
      (ngSubmit)="confirmAddEntitled(true)">

      <!-- Nome AD -->
      <input-container fxFlexOffset="24px" class="full-width" [title]="'admin.addOfflineAd.AD_NAME' | translate">
        <welion-input [inputValue]="forename" [placeholder]="'admin.addOfflineAd.AD_NAME_PLACEHOLDER' | translate"
          [disabled]="isLoading">
        </welion-input>
      </input-container>

      <!-- Cognome AD -->
      <input-container fxFlexOffset="24px" class="full-width" [title]="'admin.addOfflineAd.AD_SURNAME' | translate">
        <welion-input [inputValue]="surname" [placeholder]="'admin.addOfflineAd.AD_SURNAME_PLACEHOLDER' | translate"
          [disabled]="isLoading">
        </welion-input>
      </input-container>

      <!-- Codice fiscale AD -->
      <input-container fxFlexOffset="24px" class="full-width" [title]="'admin.addOfflineAd.AD_COD_FISC' | translate">
        <welion-input [inputValue]="codFisc" [placeholder]="'admin.addOfflineAd.AD_COD_FISC_PLACEHOLDER' | translate"
          [disabled]="isLoading">
        </welion-input>
      </input-container>

      <!-- Codice fiscale genitore -->
      <input-container fxFlexOffset="24px" class="full-width" [title]="'admin.addOfflineAd.AD_COD_FISC_PARENT' | translate">
        <welion-input [inputValue]="codFiscParent" [placeholder]="'admin.addOfflineAd.AD_COD_FISC_PARENT_PLACEHOLDER' | translate"
          [disabled]="isLoading">
        </welion-input>
      </input-container>

      <!-- Azienda -->
      <input-container fxFlexOffset="24px" class="full-width" [title]="'admin.addOfflineAd.COMPANY' | translate">
        <welion-select mediumText [bindLabel]="'title'" [selectedItem]="selectedCompany" searchable
          [loading]="isLoadingCompanyList" (change)="onCompanySelected($event)" [items]="companiesList"
          (scroll)="fetchMoreCompanies($event)" [placeholder]="'admin.addOfflineAd.COMPANY_PLACEHOLDER' | translate"
          [noDataFoundLabel]="'admin.addOfflineAd.NO_COMPANY' | translate" [typeahead]="companyServive$"
          [attrAriaLabel]="'admin.addOfflineAd.COMPANY_PLACEHOLDER' | translate">
        </welion-select>
      </input-container>

      <!-- Sede -->
      <input-container fxFlexOffset="24px" class="full-width"
        [title]="'admin.addOfflineAd.PRENOTATION_OFFICE' | translate">
        <welion-select fxFlexOffset.xs="12px" mediumText [bindLabel]="'title'" [selectedItem]="selectedStructure"
          searchable [loading]="isLoadingStructureList" (change)="onStructureSelected($event)" [items]="structureList"
          (scroll)="fetchMoreStructures($event)" [placeholder]="'admin.addOfflineAd.COMPANY_PLACEHOLDER' | translate"
          [noDataFoundLabel]="'structures.round.select.NO_STRUCTURE' | translate" [typeahead]="structureServive$"
          [attrAriaLabel]="'admin.addOfflineAd.COMPANY_PLACEHOLDER' | translate">
        </welion-select>
      </input-container>

      <!-- Data prenotazioni -->
      <input-container fxFlexOffset="24px" class="full-width"
        [title]="'admin.addOfflineAd.PRENOTATION_DATE' | translate">
        <welion-datepicker class="full-width" placeholder="admin.addOfflineAd.PLACEHOLDER_PRENOTATION_DATE"
          [value]="prenotationDate.value" (dateChanged)="onPrenotationDateChange($event)">
        </welion-datepicker>
      </input-container>

      <!-- Ora prenotazione -->
      <input-container fxFlexOffset="24px" class="full-width"
        [title]="'admin.addOfflineAd.PRENOTATION_HOUR' | translate">
        <welion-input hasTimePicker [placeholder]="'generic.HHMM' | translate" [inputValue]="prenotationHour"
          withTransparency>
        </welion-input>
      </input-container>

      <!-- Stato AD -->
      <input-container fxFlexOffset="24px" class="full-width" [title]="'admin.addOfflineAd.STATUS' | translate">
        <welion-select [placeholder]="'admin.addOfflineAd.COMPANY_PLACEHOLDER' | translate" toTranslate
          [disabled]="isLoading" [items]="entitledStatuses" [searchable]="false" [selectedItem]="entitledStatus.value"
          (change)="onEntitledStatusChange($event)">
        </welion-select>
      </input-container>

      <!-- ESPRIMO il mio consenso al trattamento ... -->
      <h5 fxFlexOffset="24px" class="full-width bold-text" translate="admin.addOfflineAd.PRIVACY_CONSENS"></h5>

      <div fxLayout="row" fxLayoutAlign="start start" fxFlexOffset="24px">
        <!-- Checkbox -->
        <welion-check class="margin-right16" (onCheck)="onCheckPrivacy($event)">
        </welion-check>
        <p>
          <span>
            <strong>CON RIFERIMENTO AL TRATTAMENTO DEI DATI PERSONALI PER L’EROGAZIONE DEL SERVIZIO</strong>
            ESPRIME il proprio consenso al trattamento dei propri dati personali, appartenenti alle categorie
            particolari, tra cui quelli relativi alla salute, per le finalità del trattamento illustrate
            nell’informativa privacy sub (i), (ii), (iii), (iv) e (v), per quanto necessario all’erogazione dei Servizi
            richiesti o in suo favore previsti.
          </span>
        </p>
      </div>

      <input-container isWithoutInfoIcon fxFlexOffset="24px" fromEuropUpload isObbligatory
        instructions="admin.addOfflineAd.YOU_CAN_ADD_PRIVACY_TYPE_FILE" [title]="('admin.addOfflineAd.IMPORT_PRIVACY_DOC_DESC')">

        <welion-upload onlyDocs (onUpload)="savePrivacyFile($event)">
        </welion-upload>
      </input-container>

      <div *ngIf="isLoading" class="loader spinner-big"></div>

      <!-- Crea AD -->
      <div fxFlexOffset="24px" class="full-width" fxLayout="row" fxLayoutAlign="end">
        <welion-button width="264px" type="primary" size="large" [label]="'admin.addOfflineAd.CONFIRM'" [disabled]="addEntitledForm.invalid || isLoading || !selectedCompany || !selectedCompany.id ||
          !selectedStructure || !selectedStructure.id" buttonType="submit">
        </welion-button>
      </div>

      <div fxHide fxShow.xs fxFlex.xs="40px"></div>

    </form>

  </div>

</div>

<modal id="addEntitledErrorsModal" (onClose)="closeAddEntitledErrorsModal()" (onCancel)="closeAddEntitledErrorsModal()"
  [cancelLabel]="'generic.CLOSE' | translate | uppercase">
  <div class="modal-entitled-errors" fxLayout="column" fxLayoutAlign="center center">
    <div class="title-container" fxLayout="column" fxLayoutAlign="center center">
      <h3 tabindex="0" translate="admin.addOfflineAd.ERROR_ADD_OFFLINE_ENTITLED_MODAL_TITLE"></h3>
    </div>

    <div fxLayout="column" fxLayoutAlign="start start" class="full-width scrollable-table">
      <div class="full-width">
        <div fxLayout="column" fxLayoutAlign="center center" class="full-width">
          <table class="primary-table modal-errors-add-entitleds">
            <thead>
              <tr>
                <th>
                  <p tabindex="0" class="header-title bold" translate="admin.addOfflineAd.NAME"></p>
                </th>
                <th>
                  <p tabindex="0" class="header-title bold" translate="admin.addOfflineAd.COD_FISC"></p>
                </th>
                <th>
                  <p tabindex="0" class="header-title bold" translate="admin.addOfflineAd.REVELATE_ERROR"></p>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let error of addOfflineEntitledErrors">
                <td>
                  <p tabindex="0" class="header-title">{{ error.name || '-' }}</p>
                </td>
                <td>
                  <p tabindex="0" class="header-title">
                    {{ error.cf || '-'}}</p>
                </td>
                <td>
                  <p tabindex="0" class="header-title">
                    {{('admin.addOfflineAd.addEntitledErrors.' + error.reason | translate) || '-'}}</p>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</modal>
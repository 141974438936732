import * as fromApp from '../../../ngrx/app.reducers';

import { Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Store } from '@ngrx/store';
import { Subject } from 'rxjs';
import { ModalService } from '../modal/modal.service';
import { ModalComponent } from '../modal/modal.component';
import { DropdownItem } from '../../models/dropdown.model';

@Component({
  selector: 'modal-upload',
  templateUrl: './modal-upload.component.html',
  styleUrls: ['./modal-upload.component.scss']
})

export class ModalUploadComponent extends ModalComponent implements OnInit, OnDestroy {

  @Output() onConfirm = new EventEmitter<any>();

  @Input() uploadType: 'STRUCTURE' | 'PEOPLE' | 'VENUE' = 'STRUCTURE';

  @Input() customAcceptFile: string = '.csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel';
  @Input() isToResetUploadComponent: boolean = true;
  @Input() isConfirmBtnDisabled: boolean = true;

  @Input() venues: DropdownItem[] = [];
  @Input() selectedVenue?: DropdownItem;

  @Output() onSelectVenue: EventEmitter<DropdownItem> = new EventEmitter<DropdownItem>();
  @Output() assignFile: EventEmitter<any> = new EventEmitter<any>();

  private _unsubscribeSignal$: Subject<void> = new Subject();

  constructor(
    private _modalService: ModalService,
    private _store: Store<fromApp.AppState>,
    private _el: ElementRef) {
    super(_modalService, _store, _el);
  }

  ngOnInit(): void {

  }

  ngOnDestroy() {
    this._unsubscribeSignal$.next();
    this._unsubscribeSignal$.unsubscribe();
  }

  // Scarica il template per l'import massivo delle strutture
  downloadImportStructuresTemplate() {
    if (this.uploadType === 'STRUCTURE') {
      window.open('/assets/documents/templateImportSlots.xlsx');
    } else if (this.uploadType === 'PEOPLE') {
      window.open('/assets/documents/templateImportAventiDiritto.xlsx');
    } else if (this.uploadType === 'VENUE') {
      window.open('/assets/documents/templateImportMassivoSedi.xlsx');
    }
  }

  emitAssignFile(data?: any) {
    this.assignFile.emit(data);
    this.isConfirmBtnDisabled = false;
  }

  closeSelf() {
    this._modalService.close(this.id);
  }
}

<button fxLayout="row" fxLayoutAlign="center center" class="welion-button" [ngClass]="type + ' ' + size + ' ' +
(disabled ? 'disabled ': ' ') + (_hasLeftIcon ? 'left-icon' : ' ') + (_hasRightIcon ? 'right-icon' : ' ') "
  (click)="emitOnClick()" [attr.aria-disabled]="disabled" [ngStyle]="{'min-width': width, 'width': width}" [type]="buttonType" autofocus>

  <!-- Freccia o icona sx -->
  <svg-icon *ngIf="_hasLeftIcon" [src]="customIcon ? customIcon : 'assets/img/icons/arrow-left.svg'" class="medium-size"
    [applyClass]="true">
  </svg-icon>
  <div class="empty-left"></div>

  <!-- Label uppercase o come da input -->
  <ng-container *ngIf="_noUpperCase">
    <p class="center">{{ label | translate }}</p>
  </ng-container>
  <ng-container *ngIf="!_noUpperCase">
    <p class="center">{{ label | translate | uppercase }}</p>
  </ng-container>

  <!-- Freccia o icona dx -->
  <svg-icon *ngIf="_hasRightIcon" [src]="customIcon ? customIcon : 'assets/img/icons/arrow-right.svg'"
    class="medium-size" [applyClass]="true">
  </svg-icon>
  <div class="empty-right"></div>

</button>

/*
 * Servizio che gestisce le chiamate ai servizi per le lingue
*/

import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpRequest } from '@angular/common/http';
import { UrlService } from "../../shared/services/url.service";
import { Observable } from 'rxjs';

@Injectable()
export class ImportService {

  _mediatorUrl: string;

  constructor(private http: HttpClient, private urlService: UrlService) {
    this._mediatorUrl = this.urlService.getServiceMediatorUrl();
  }

  /**
   * Imports the performance management data for the specified year. This import is an incremental one, as it will check all the rows every time:
   * - if a row is new, it will be persisted
   * - if a row was already persisted, it will be checked if the current data is equal to the one defined in the file. If it differs, it will be updated
   * This service can also try to match the imported rows with the courses defined in our databases (@see {getSimilarityIndex} function, defined at the bottom of the file).
   * This match will be made using the Sørensen–Dice coefficient to compare the text defined in the imported row and the syllabus's title.
   * @param {formData} file - Required: File that will be imported. It can be both a xlsx or a csv.
   * @param {boolean} hasHeader - Optional: It must be true if the uploaded file has a header row (dafault is true)
   * @param {string} roundId
   * @param {string} preMatchedVenueId
   * @return {void} Nothing
   */
  importEntitledForCustomer(roundId: string, preMatchedVenueId: string, uploadObj: any): Observable<any> {
    let formData = new FormData();
    formData.append('file', uploadObj);
    formData.append('hasHeader', 'true');
    formData.append('roundId', roundId);
    formData.append('preMatchedVenueId', preMatchedVenueId);

    const req = new HttpRequest('POST', this._mediatorUrl + 'import-entitled-for-customer', formData);
    return this.http.request(req);
  }

  // Import file per l'eliminazione parziale nei clienti
  importEntitledForPartialDelete(roundId: string, uploadObj: any): Observable<any> {
    let formData = new FormData();
    formData.append('file', uploadObj);
    formData.append('hasHeader', 'true');
    formData.append('roundId', roundId);

    const req = new HttpRequest('POST', this._mediatorUrl + 'delete-entitled-for-customer', formData);
    return this.http.request(req);
  }

  /**
   * Imports the performance management data for the specified year. This import is an incremental one, as it will check all the rows every time:
   * - if a row is new, it will be persisted
   * - if a row was already persisted, it will be checked if the current data is equal to the one defined in the file. If it differs, it will be updated
   * This service can also try to match the imported rows with the courses defined in our databases (@see {getSimilarityIndex} function, defined at the bottom of the file).
   * This match will be made using the Sørensen–Dice coefficient to compare the text defined in the imported row and the syllabus's title.
   * @param {formData} file - Required: File that will be imported. It can be both a xlsx or a csv.
   * @param {boolean} hasHeader - Optional: It must be true if the uploaded file has a header row (dafault is true)
   * @param {string} roundId
   * @param {string} venueId
   * @return {void} Nothing
   */
  importSlots(roundId: string, venueId: string, uploadObj: any): Observable<any> {
    let formData = new FormData();
    formData.append('file', uploadObj);
    formData.append('hasHeader', 'true');
    formData.append('roundId', roundId);
    formData.append('venueId', venueId);

    const req = new HttpRequest('POST', this._mediatorUrl + 'import-slots', formData);
    return this.http.request(req);
  }

  /**
   * @param {string} file - Required: File that will be imported. It can be both a xlsx or a csv.
   * @param {string} supplierId
   * @param {boolean} simulate
   * @return {void}
   */
  importVenueByHealthFacilitySupplierForAdmin(supplierId: string, uploadObj: any, simulate?: boolean): Observable<any> {
    let formData = new FormData();
    formData.append('file', uploadObj);
    formData.append('hasHeader', 'true');
    formData.append('supplierId', supplierId);

    if (simulate) {
      formData.append('simulate', 'true');
    }

    const req = new HttpRequest('POST', this._mediatorUrl + 'import-venue-by-health-facility-supplier-for-admin', formData);
    return this.http.request(req);
  }

  importOfflineEntitledForAdmin(uploadObj: any, roundId: string, simulate?: boolean): Observable<any> {
    let formData = new FormData();
    formData.append('file', uploadObj);
    formData.append('roundId', roundId);
    if(simulate) {
      formData.append('simulate', 'true');
    }

    const req = new HttpRequest('POST', this._mediatorUrl + 'import-offline-entitled-for-admin', formData);
    return this.http.request(req);
  }

  // Upload del file privacy per un singolo AD
  uploadEntitledPrivacyFile(attendanceId: string, file: any) {
    let formData = new FormData();
    formData.append('attendanceId', attendanceId);
    formData.append('file', file);

    const req = new HttpRequest('POST', this._mediatorUrl + 'upload-privacy-doc-file-for-entitled', formData);
    return this.http.request(req);
  }
}

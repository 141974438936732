import { ActivatedRoute } from "@angular/router";
import { Component, OnDestroy, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Subject } from 'rxjs';
import { Store } from "@ngrx/store";
import { takeUntil } from "rxjs/operators";

import * as fromApp from "../../../ngrx/app.reducers";
import * as CoreActions from "../../../core/ngrx/core.actions";

import { RedirectService } from 'src/app/shared/services/redirect.service';
import { getWarningModal } from "src/app/utils/utils";
import { ScrollTo } from "src/app/core/services/scroll-to.service";
import { RentService } from "src/app/structure/services/rent.service";
import { InfoCampaign, InfoRound } from "src/commonclasses";
import { AnagService, UserGroupAdmin } from "src/app/auth/services/anag.service";
import { DropdownItem } from "src/app/shared/models/dropdown.model";
import { DatePipe } from "@angular/common";

@Component({
  selector: 'app-admin-import-entitleds-from-round',
  templateUrl: './importEntitledsFromRound.component.html',
  styleUrls: ['./importEntitledsFromRound.component.scss']
})
export class AdminImportEntitledsFromRoundComponent implements OnInit, OnDestroy {

  isLoading: boolean = true;
  isLoadingCount: boolean = true;
  isLoadingBase: boolean = true;

  campaignId: string = '';
  campaign?: InfoCampaign;

  roundId: string = '';
  round?: InfoRound;

  rounds: DropdownItem[] = [];
  roundCounts: number = 0;
  selectedRound?: DropdownItem;

  clients: UserGroupAdmin[] = [];
  clientsCount: number = 0;
  selectedClients: string[] = [];
  selectAllClients: boolean = false;

  isVerified: boolean = false;
  entitledsCount: number = 0;

  readonly paginationLength: number = 25;

  private _unsubscribeSignal$: Subject<void> = new Subject();

  constructor(
    private route: ActivatedRoute,
    private appStore: Store<fromApp.AppState>,
    private translate: TranslateService,
    private redirectService: RedirectService,
    private scrollTo: ScrollTo,
    private rentService: RentService,
    private anagService: AnagService,
    private datePipe: DatePipe
  ) {
  }

  ngOnInit() {
    this.scrollTo.header();

    this.campaignId = this.route.snapshot.paramMap.get('campaignId') || '';
    this.roundId = this.route.snapshot.paramMap.get('roundId') || '';

    if (this.campaignId && this.roundId) {
      this.getCampaign();
    } else {
      this.redirectService.goToHome();
    }
  }

  getCampaign() {
    this.rentService.getCampaign(this.campaignId)
      .pipe(takeUntil(this._unsubscribeSignal$.asObservable()))
      .subscribe((result) => {
        if (!result || result.error) {
          this.dispatchWarningModal('044', result.error || '');
          this.resetPagination();
        } else if (result.response) {
          this.campaign = result.response;
          this.getRound();
        }
      }, (err) => {
        if (err && err.message) {
          this.dispatchWarningModal('045', err.message);
        }
        this.resetPagination();
      });
  }

  getRound() {
    this.rentService.getRound(this.roundId)
      .pipe(takeUntil(this._unsubscribeSignal$.asObservable()))
      .subscribe((result) => {
        if (!result || result.error) {
          this.dispatchWarningModal('100', result.error || '');
          this.resetPagination();
        } else {
          this.round = result.response;
          this.countRounds();
        }
        this.isLoadingBase = false;
      }, (err) => {
        if (err && err.message) {
          this.dispatchWarningModal('101', err.message);
        }
        this.resetPagination();
      });
  }

  countRounds() {

    this.isLoadingCount = true;
    this.isLoading = true;

    this.rentService.countRoundByCampaignForAdmin(this.campaignId)
      .subscribe((result) => {

        if (!result || result.error) {
          this.dispatchWarningModal('100', result.error || '');
          this.resetPagination();
        } else {
          this.roundCounts = result.response.roundCount;

          if (this.roundCounts > 1) {
            this.getRounds();
          } else {
            this.resetPagination();
          }
        }
      }, (err) => {
        if (err && err.message) {
          this.dispatchWarningModal('101', err.message);
        }
        this.resetPagination();
      });
  }

  getRounds() {
    this.rentService.getRoundListByCampaignForAdmin(this.campaignId, 0, this.roundCounts)
      .pipe(takeUntil(this._unsubscribeSignal$.asObservable()))
      .subscribe((result) => {

        if (!result || result.error) {
          this.dispatchWarningModal('102', result.error || '');
          this.resetPagination();
        } else {
          const rounds: InfoRound[] = result.response;
          // Rimuovo il round corrente
          const indexRound = rounds.findIndex(round => round.roundId === this.roundId);
          if (indexRound !== -1) {
            rounds.splice(indexRound, 1);
          }

          this.rounds = [];
          rounds.forEach(round => {
            const startDate = this.datePipe.transform(round.startDate, 'dd MMMM yyyy');
            const endDate = this.datePipe.transform(round.endDate, 'dd MMMM yyyy');
            this.rounds.push({
              id: round.roundId,
              content: round,
              name: startDate + ' - ' + endDate
            });
          });

          this.countClients();
        }
      }, (err) => {
        if (err && err.message) {
          this.dispatchWarningModal('103', err.message);
        }
        this.resetPagination();
      });
  }

  countClients() {
    this.isLoadingCount = true;
    this.isLoading = true;

    this.anagService.countCustomersForAdmin(undefined, this.campaignId)
      .subscribe((result) => {

        if (!result || result.error) {
          this.dispatchWarningModal('100', result.error || '');
          this.resetPagination();
        } else {
          this.clientsCount = result.response;

          if (this.clientsCount > 0) {
            this.getClients();
          } else {
            this.resetPagination();
          }
          this.isLoadingCount = false;
        }
      }, (err) => {
        if (err && err.message) {
          this.dispatchWarningModal('101', err.message);
        }
        this.resetPagination();
      });
  }

  getClients(index?: number) {

    this.isLoading = true;

    const pageSelectedIndex = index ? index : 0;
    if (index === 0 || index) {
      this.scrollTo.header();
    }

    this.anagService.listCustomersForAdmin((pageSelectedIndex * this.paginationLength), this.paginationLength, undefined, this.campaignId)
      .pipe(takeUntil(this._unsubscribeSignal$.asObservable()))
      .subscribe((result) => {
        if (!result || result.error) {
          this.dispatchWarningModal('102', result.error || '');
          this.resetPagination();
        } else {
          this.clients = result.response;
        }
        this.isLoading = false;
      }, (err) => {
        if (err && err.message) {
          this.dispatchWarningModal('103', err.message);
        }
        this.resetPagination();
      });
  }

  ngOnDestroy() {
    this._unsubscribeSignal$.next();
    this._unsubscribeSignal$.unsubscribe();
  }

  goBack() {
    this.redirectService.goToAdminCampaignRoundTabEntitleds(this.campaignId, this.roundId);
  }

  confirmCopyEntitleds() {
    if (this.selectedRound && this.entitledsCount > 0) {
      this.isLoading = true;

      this.rentService.copyEntitledsFromRound(this.selectedRound.id, this.roundId, this.selectedClients)
        .pipe(takeUntil(this._unsubscribeSignal$.asObservable()))
        .subscribe((result) => {
          if (!result || result.error) {
            this.dispatchWarningModal('041', result.error || '');
          } else if (result.response) {
            this.redirectService.goToAdminCampaignRoundTabEntitleds(this.campaignId, this.roundId);
          }
          this.isLoading = false;
        }, (err) => {
          if (err && err.message) {
            this.dispatchWarningModal('042', err.message);
          }
          this.isLoading = false;
        });
    }
  }

  onVerifyingCopy() {
    if (!this.isVerified && this.selectedRound && (this.selectAllClients || this.selectedClients.length > 0)) {
      this.countRoundEntitleds();
    }
  }

  countRoundEntitleds() {
    this.isLoading = true;

    if (this.selectedRound) {

      this.rentService.countEntitledListByRoundClientsForAdmin(this.selectedRound.id, this.roundId, this.selectedClients)
        .subscribe((result) => {

          if (!result || result.error) {
            this.dispatchWarningModal('100', result.error || '');
            this.resetVerified();
          } else {
            this.entitledsCount = result.response;

            this.isVerified = true;
            this.isLoading = false;
          }
        }, (err) => {
          if (err && err.message) {
            this.dispatchWarningModal('101', err.message);
          }
          this.resetVerified();
        });
    }
  }

  clientIsChecked(client: UserGroupAdmin): boolean {
    return this.selectAllClients || this.selectedClients.filter(selectedClient => selectedClient === client.groupId).length > 0;
  }

  onClientChecked(client: UserGroupAdmin, checked: boolean) {
    if (checked) {
      this.selectedClients.push(client.groupId);
    } else {
      let index = this.selectedClients.findIndex(selectedClient => selectedClient === client.groupId);
      this.selectedClients.splice(index, 1);
    }
    this.resetVerified();
  }

  onSelectAllClients(checked: boolean) {
    this.selectAllClients = checked;
    this.selectedClients = [];

    this.resetVerified();
  }

  onChangeRound(value: DropdownItem) {
    this.selectedRound = value;
    this.resetVerified();
  }

  /**
   * @description Mostra una modale con un messaggio di errore
   * @param modalId
   * @param error
   */
  dispatchWarningModal(modalId: string, error: string) {
    const messageObj = getWarningModal(this.translate, modalId, error);
    this.appStore.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
  }

  resetPagination() {
    this.isLoadingBase = false;
    this.isLoadingCount = false;
    this.isLoading = false;

    this.rounds = [];
    this.roundCounts = 0;

    this.clients = [];
    this.clientsCount = 0;

    this.resetVerified();
  }

  resetVerified() {
    this.isVerified = false;
    this.entitledsCount = 0;
    this.isLoading = false;
  }
}
